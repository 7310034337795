import aa from 'search-insights';

import ENV from '@/env';
import { useGetSearchIndexName } from '@/lib/algolia-utils';
import { useAuth } from '@/providers/AuthProvider';

// Search Results
export const ALGOLIA_DISCOVER_HIT_CLICKED = 'Discover Hit Click';
export const ALGOLIA_SEARCH_HIT_CLICKED = 'Search Hit Click';
// Register Clicks
export const ALGOLIA_REGISTER_CLICKED = 'Register Click';
// Conversions
export const ALGOLIA_CONVERSION = 'Conversion';
// firebase hosting:channel:deploy new-awesome-feature --expires 2d
// firebase hosting:clone SOURCE_SITE_ID:SOURCE_CHANNEL_ID TARGET_SITE_ID:TARGET_CHANNEL_ID

/**
 * https://coda.io/d/_dqRj210EWsz/Intro-page_suxKP#_lubyB
 * WHAT WE ARE TRACKING
 * - Search Results View - Autotracked
 * - Search Results Click - Discover & Search via sendEvent
 * - Item Register/Request Lesson/Start Checkout - via reportAlgoliaClickedObjectIDs
 * - - Event
 * - - Private Lesson
 * - - Online Course
 * - Item Converted (bought) - via reportAlgoliaConvertedObjectIDs
 * - - onMount of purchase page, onComplete of mutation for free online course
 *
 * TODO - Add filter tracking
 */

type ReportClickProps = {
  eventName: string;
  objectIds: string[];
};
export const useReportAlgoliaEvent = (): {
  reportAlgoliaClickedFilters: () => void;
  reportAlgoliaClickedObjectIDs: ({ eventName, objectIds }: ReportClickProps) => void;
  reportAlgoliaConvertedFilters: () => void;
  reportAlgoliaConvertedObjectIDs: ({ eventName, objectIds }: ReportClickProps) => void;
  reportAlgoliaViewedFilters: () => void;
  reportAlgoliaViewedObjectIDs: ({ eventName, objectIds }: ReportClickProps) => void;
} => {
  const auth = useAuth();
  const indexName = useGetSearchIndexName();

  // Using sendEvent for now.
  // /**
  //  * Report a click event after searching for a query.
  //  *
  //  * index	string	The name of the index related to the event
  //  * eventName	string	The name of the event
  //  * objectIDs	string[]	The list of IDs of the result that was clicked
  //  * positions	number[]	The list of the absolute positions of the HTML element that was clicked (1-based and not 0-based)
  //  * queryID	string	The queryID of the search sent from Algolia
  //  */
  // const reportAlgoliaClickedObjectIDsAfterSearch = (): void => {
  //   aa('clickedObjectIDsAfterSearch', {
  //     index: REGGY_SEARCH_INDEX_NAME,
  //     eventName: 'Click item',
  //     queryID: getQueryID(),
  //     objectIDs: ['object1'],
  //     positions: [42],
  //   });
  // };
  // /**
  //  * Report a conversion event after searching for a query.
  //  *
  //  *  index	string	The name of the index related to the event
  //  *  eventName	string	The name of the event
  //  *  objectIDs	string[]	The list of IDs of the result that was clicked
  //  *  queryID	string	The queryID of the search sent from Algolia
  //  */
  // const reportAlgoliaConvertedObjectIDsAfterSearch = (): void => {
  //   aa('convertedObjectIDsAfterSearch', {
  //     index: REGGY_SEARCH_INDEX_NAME,
  //     eventName: 'Add to basket',
  //     queryID: getQueryID(),
  //     objectIDs: ['object1'],
  //   });
  // };

  /**
   * Report a click event
   *
   * index    string    The name of the index related to the event
   * eventName    string    The name of the event
   * objectIDs    string[]    The list of IDs of the result that was clicked
   * filters    string[]    The list of filters that was clicked as '${attr}${op}${value}'
   */
  const reportAlgoliaClickedObjectIDs = ({ eventName, objectIds }: ReportClickProps): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('clickedObjectIDs', {
      index: indexName,
      eventName,
      objectIDs: objectIds,
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };
  const reportAlgoliaClickedFilters = (): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('clickedFilters', {
      index: indexName,
      eventName: 'Filter on facet',
      filters: ['brand:Apple'],
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };

  /**
   * Report a conversion event
   *
   * index    string    The name of the index related to the event
   * eventName    string    The name of the event
   * objectIDs    string[]    The list of IDs of the result that was clicked
   * filters    string[]    The list of filters that was clicked as '${attr}${op}${value}'
   */
  const reportAlgoliaConvertedObjectIDs = ({ eventName, objectIds }: ReportClickProps): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('convertedObjectIDs', {
      index: indexName,
      eventName,
      objectIDs: objectIds,
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };
  const reportAlgoliaConvertedFilters = (): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('convertedFilters', {
      index: indexName,
      eventName: 'Filter on facet',
      filters: ['brand:Apple'],
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };

  /**
   * Report a view event
   *
   * index    string    The name of the index related to the event
   * eventName    string    The name of the event
   * objectIDs    string[]    The list of IDs of the result that was clicked
   * filters    string[]    The list of filters that was clicked as '${attr}${op}${value}'
   */
  const reportAlgoliaViewedObjectIDs = ({ eventName, objectIds }: ReportClickProps): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('viewedObjectIDs', {
      index: indexName,
      eventName,
      objectIDs: objectIds,
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };
  const reportAlgoliaViewedFilters = (): void => {
    if (ENV.IS_NODE_ENV_LOCAL) {
      return;
    }
    aa('viewedFilters', {
      index: indexName,
      eventName: 'Filter on facet',
      filters: ['brand:Apple'],
      userToken: auth.firebaseUser?.uid,
      timestamp: new Date().getTime(),
    });
  };

  return {
    reportAlgoliaClickedObjectIDs,
    reportAlgoliaClickedFilters,
    reportAlgoliaConvertedObjectIDs,
    reportAlgoliaConvertedFilters,
    reportAlgoliaViewedObjectIDs,
    reportAlgoliaViewedFilters,
  };
};
