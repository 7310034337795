/*
Disable a component based on a condition
Optionally display a loading spinner
 */
import React from 'react';
import { isElement } from 'react-is';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { DEFAULT_LOADING_SPINNER_DELAY } from '@/const';
import { ReactFCC } from '@/lib/type-defs/utility';

const useStyles = makeStyles({
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
interface DisableToggleProps {
  noComponent?: boolean;
  disabled: boolean;
  Component?: React.ElementType;
  showLoadingWhileDisabled?: boolean;
}
const DisableToggle: ReactFCC<DisableToggleProps> = React.forwardRef(
  (
    { children, Component = 'div', disabled, noComponent, showLoadingWhileDisabled, ...rest },
    ref,
  ) => {
    const classes = useStyles();
    if (noComponent) {
      return (
        <>
          {isElement(children) &&
            React.cloneElement(children, {
              className: disabled
                ? `${children?.props.className ?? ''} ${classes.disabled}`
                : children?.props.className,
            })}
          {showLoadingWhileDisabled && (
            <LoadingPlaceholder
              className={classes.loading}
              delay={DEFAULT_LOADING_SPINNER_DELAY}
              isLoading={disabled}
            />
          )}
        </>
      );
    }
    return (
      <Component {...rest} className={disabled ? classes.disabled : undefined} ref={ref}>
        {children}
        {showLoadingWhileDisabled && (
          <LoadingPlaceholder
            className={classes.loading}
            delay={DEFAULT_LOADING_SPINNER_DELAY}
            isLoading={disabled}
          />
        )}
      </Component>
    );
  },
);

export default DisableToggle;
