import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useCountUp } from 'use-count-up';

interface CountUpProps {
  setResetCount?: Dispatch<SetStateAction<(() => void) | undefined>>;
  endValue?: number;
}
const CountUp: React.FC<CountUpProps> = ({ endValue, setResetCount }) => {
  const { reset, value } = useCountUp({
    isCounting: true,
    end: endValue ?? 0,
    duration: 1.5,
  });

  /**
   * Reset on endValue change
   */
  useEffect(() => {
    reset();
  }, [endValue]);

  /**
   * Pass reset function to parent
   */
  useEffect(() => {
    if (setResetCount) {
      setResetCount(reset);
    }
  }, [!!setResetCount]);

  return <>{value}</>;
};

export default CountUp;
