/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getEventCuid_event = {
    readonly cuid: string;
    readonly " $refType": "helpers_getEventCuid_event";
};
export type helpers_getEventCuid_event$data = helpers_getEventCuid_event;
export type helpers_getEventCuid_event$key = {
    readonly " $data"?: helpers_getEventCuid_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getEventCuid_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getEventCuid_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cuid",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '7e99fbeb4ab4e3540397f78fafa1ed2c';
export default node;
