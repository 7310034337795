import { Theme } from '@material-ui/core';

export const MuiAutocomplete = (theme: Theme): Record<string, unknown> => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"],&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: 0,
      paddingRight: '62px',
      paddingLeft: '6px',
    },
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: '14.5px 14px !important',
      // paddingLeft: '14px',
    },
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input.MuiOutlinedInput-inputMarginDense, &[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child.MuiOutlinedInput-inputMarginDense': {
      paddingTop: '14.5px',
      paddingBottom: '14.5px',
    },

    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: '18.5px 14px',
      paddingLeft: '14px !important',
    },

    '&[class*="MuiFilledInput-root"].MuiInputBase-marginDense': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  paper: {
    boxShadow: theme.shadows[24],
    // border: '2px solid #03465c45',
    // border: `2px solid ${theme.palette.primary.main}`,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    // position: 'relative',
    // top: '-6px',
    // left: '1px',
  },
  popper: {
    // zIndex: theme.zIndex.mobileStepper + 1,
    zIndex: theme.zIndex.modal,
    // transform: 'translateY(-12px)',
    // 'body > &': {
    //   top: '-7px !important',
    // },
  },
  hasClearIcon: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: `${theme.spacing(2.5)}px !important`,
    },
  },
  root: {
    '& .MuiFormControl-root': {
      borderRadius: theme.spacing(0.5),

      background: theme.palette.common.white,
      zIndex: theme.zIndex.mobileStepper,
    },
  },
});
