/*
Recoil atoms, selectors and hooks for Search
 */
import { Hit } from 'react-instantsearch-core';
import { atom, SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  ABILITY_LEVEL_MIN_MAX,
  AGE_MIN_MAX,
  SEARCH_PRICE_MIN_MAX,
  SEARCH_RADIUS_MIN_MAX,
} from '@/const';
import {AlgoliaSearchResult, AlgoliaSearchResultHit} from '@/lib/algolia';

/** ************************************
 * Global Search Properties
 * Which result is hovered, etc
 ************************************* */
const hoveredHitState = atom({
  key: 'hoveredHitState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const useHoveredHitGlobal = (): [
  null | AlgoliaSearchResultHit,
  SetterOrUpdater<null | AlgoliaSearchResultHit>,
] => {
  return useRecoilState<null | AlgoliaSearchResultHit>(hoveredHitState);
};

/** ************************************
 * Is the mobile search drawer open
 ************************************* */
const isSearchFilterDrawerOpen = atom<boolean>({
  key: 'isSearchFilterDrawerOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetSearchFilterDrawerOpenGlobal = (): boolean => {
  return useRecoilValue(isSearchFilterDrawerOpen);
};
export const useSetIsSearchFilterDrawerOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState<boolean>(isSearchFilterDrawerOpen);
};

/** ************************************
 * Search Filters
 ************************************* */
interface SearchFilterProps {
  abilityMin: number;
  abilityMax: number;
  ageMin: number;
  ageMax: number;
  bounds: [[number, number], [number, number]] | undefined;
  disciplines: string[];
  amenities: string[];
  from: undefined | null | Date;
  to: undefined | null | Date;
  showPastEvents: boolean;
  showVirtualEvents: boolean;
  priceMin: number;
  priceMax: number;
  activities: string[];
  radius: number;
  query: string;
}
export const searchFilterDefault = {
  abilityMin: ABILITY_LEVEL_MIN_MAX[0],
  abilityMax: ABILITY_LEVEL_MIN_MAX[1],
  ageMin: AGE_MIN_MAX[0],
  ageMax: AGE_MIN_MAX[1],
  bounds: undefined,
  disciplines: [],
  amenities: [],
  from: undefined,
  to: undefined,
  showPastEvents: false,
  showVirtualEvents: false,
  priceMin: SEARCH_PRICE_MIN_MAX[0],
  priceMax: SEARCH_PRICE_MIN_MAX[1],
  activities: [],
  radius: SEARCH_RADIUS_MIN_MAX[1],
  query: '',
};
const searchFilter = atom<SearchFilterProps>({
  key: 'searchFilter', // unique ID (with respect to other atoms/selectors)
  default: searchFilterDefault, // default value (aka initial value)
});
export const useGetSearchFiltersGlobal = (): SearchFilterProps => {
  return useRecoilValue(searchFilter);
};
export const useSetSearchFiltersGlobal = (): SetterOrUpdater<SearchFilterProps> => {
  return useSetRecoilState<SearchFilterProps>(searchFilter);
};
