import { createMuiTheme } from '@material-ui/core';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';

import { Colors, palette } from './colors';
import * as overrides from './overrides';

import { MAIN_HEADER_HEIGHT } from '@/containers/MainHeader/MainHeader';

const headerFonts = [
  // 'CircularStd',
  // 'proxima-nova',
  '-apple-system',
  'BlinkMacSystemFont',
  'Open Sans',
  'Sans-Serif',
].join(',');

export const theme = responsiveFontSizes(
  createMuiTheme({
    overrides: {},
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
    palette,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: `${MAIN_HEADER_HEIGHT}px !important`,
        //       @media (min-width:0px) and (orientation: landscape):
        // {
        //   minHeight: 48
        // },
        //       @media (min-width:600px):
        // {
        //   minHeight: 64
        // },
      },
    },
    typography: {
      fontFamily: [
        // 'CircularStd',
        '-apple-system',
        'BlinkMacSystemFont',
        'Open Sans',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      htmlFontSize: 16,
      fontWeightBold: 700,
      fontWeightMedium: 600,
      fontWeightRegular: 400,
      h1: {
        fontFamily: headerFonts,
        fontWeight: 600,
        fontSize: '28px',
        // lineHeight: '38px',
        color: Colors.DarkText,
      },
      h2: {
        fontFamily: headerFonts,
        fontWeight: 600,
        fontSize: '22.5px',
        // lineHeight: '27px',
        color: Colors.DarkText,
      },
      h3: {
        fontFamily: headerFonts,
        fontWeight: 600,
        fontSize: '18px',
        // lineHeight: '25px',
        color: Colors.DarkText,
      },
      h4: {
        fontFamily: headerFonts,
        fontWeight: 'bold',
        fontSize: '14px',
        // lineHeight: '18px',
        color: Colors.DarkText,
      },
      h5: {
        fontFamily: headerFonts,
        fontWeight: 600,
        // color: palette?.grey ? palette.grey[800] : undefined,
        color: Colors.DarkText,
      },
      h6: {
        fontFamily: headerFonts,
        fontWeight: 600,
        // color: palette?.grey ? palette.grey[800] : undefined,
        color: Colors.DarkText,
      },
      subtitle1: {
        color: palette?.grey ? palette.grey[500] : undefined,
        lineHeight: 1.5,
      },
      subtitle2: {
        color: palette?.grey ? palette.grey[500] : undefined,
        fontWeight: 400,
      },
      overline: {},
      body1: {},
      body2: {
        color: Colors.DarkText,
      },
      button: {},
    },
    shadows: [
      'none',
      // https://tailwindcss.com/docs/box-shadow/ 1-6
      '0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)', // 1
      '0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)', // 2
      '0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05)', // 3
      '0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)', // 4
      '0 25px 50px -12px rgba(0, 0, 0, .25)', // 5
      '0 35px 60px -15px rgba(0, 0, 0, .3)', // 6
      // https://tailwindcss.com/docs/box-shadow/ 1-6 Darker
      '0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 2px 0 rgba(0, 0, 0, .26)', // 7
      '0 4px 10px -1px rgba(0, 0, 0, .2), 0 2px 4px -1px rgba(0, 0, 0, .26)', // 8
      '0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .25)', // 9
      '0 20px 25px -5px rgba(0, 0, 0, .2), 0 10px 10px -5px rgba(0, 0, 0, .24)', // 10
      '0 25px 50px -12px rgba(0, 0, 0, .3)', // 11
      '0 35px 60px -15px rgba(0, 0, 0, .3)', // 12
      //
      // Large Shadows
      //
      // AirBnB Search Dropdown inspiration
      '0 15px 46px -10px rgba(26, 26, 29, 0.3)', // 13
      // Apple Light inspiration
      '0 37.125px 70px -12.125px rgba(0,0,0,0.1)', // 14
      '0 37.125px 70px -12.125px rgba(0,0,0,0.2)', // 15
      // Lighter version of 2
      '0 4px 6px -1px rgba(0, 0, 0, .05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)', // 16
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)', // 17
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)', // 18
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)', // 19
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)', // 20
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)', // 21
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)', // 22
      // Reggy Shadows
      // Card
      '0 8px 13px rgba(0, 0, 0, 0.06), 0 15px 5px -14px rgba(60, 66, 87, 0.07), 0 0px 6px 0 rgba(0, 0, 0, 0.07)', // 23
      '0 8px 13px rgba(0, 0, 0, 0.1), 0 15px 5px -14px rgba(60, 66, 87, 0.11), 0 0px 6px 0 rgba(0, 0, 0, 0.07)', // 24
    ],
  }),
);

// for overrides that require the theme object...
if (!theme.overrides) theme.overrides = {};
theme.overrides.MuiAccordion = overrides.MuiAccordion(theme);
theme.overrides.MuiAppBar = overrides.MuiAppBar(theme);
theme.overrides.MuiAutocomplete = overrides.MuiAutocomplete(theme);
theme.overrides.MuiBackdrop = overrides.MuiBackdrop(theme);
theme.overrides.MuiButton = overrides.MuiButton(theme);
theme.overrides.MuiButtonBase = overrides.MuiButtonBase(theme);
theme.overrides.MuiCssBaseline = overrides.MuiCssBaseline(theme);
theme.overrides.MuiDialogActions = overrides.MuiDialogActions(theme);
theme.overrides.MuiDialogTitle = overrides.MuiDialogTitle(theme);
theme.overrides.MuiDrawer = overrides.MuiDrawer(theme);
theme.overrides.MuiFilledInput = overrides.MuiFilledInput(theme);
theme.overrides.MuiFormLabel = overrides.MuiFormLabel(theme);
theme.overrides.MuiFab = overrides.MuiFab(theme);
theme.overrides.MuiIconButton = overrides.MuiIconButton(theme);
theme.overrides.MuiInput = overrides.MuiInput(theme);
theme.overrides.MuiInputAdornment = overrides.MuiInputAdornment(theme);
theme.overrides.MuiInputLabel = overrides.MuiInputLabel(theme);
theme.overrides.MuiLinearProgress = overrides.MuiLinearProgress(theme);
theme.overrides.MuiLink = overrides.MuiLink(theme);
theme.overrides.MuiListItem = overrides.MuiListItem(theme);
theme.overrides.MuiListItemIcon = overrides.MuiListItemIcon(theme);
theme.overrides.MuiListSubheader = overrides.MuiListSubheader(theme);
theme.overrides.MuiMenu = overrides.MuiMenu(theme);
theme.overrides.MuiMenuItem = overrides.MuiMenuItem(theme);
theme.overrides.MuiOutlinedInput = overrides.MuiOutlinedInput(theme);
theme.overrides.MuiPopover = overrides.MuiPopover(theme);
theme.overrides.MuiRating = overrides.MuiRating(theme);
theme.overrides.MuiRadio = overrides.MuiRadio(theme);
theme.overrides.MuiSelect = overrides.MuiSelect(theme);
theme.overrides.MuiSkeleton = overrides.MuiSkeleton(theme);
theme.overrides.MuiSnackbarContent = overrides.MuiSnackbarContent(theme);
theme.overrides.MuiTable = overrides.MuiTable(theme);
theme.overrides.MuiTabs = overrides.MuiTabs(theme);
theme.overrides.MuiTextField = overrides.MuiTextField(theme);
theme.overrides.MuiToggleButtonGroup = overrides.MuiToggleButtonGroup(theme);
theme.overrides.MuiToolbar = overrides.MuiToolbar(theme);
