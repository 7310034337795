/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type helpers_IsEventAtCapacity_QueryVariables = {
    eventCuid: string;
    userId: string;
};
export type helpers_IsEventAtCapacity_QueryResponse = {
    readonly event_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly spotsAvailable: number | null;
                readonly registrations_aggregate: {
                    readonly aggregate: {
                        readonly count: number;
                    } | null;
                };
            };
        }>;
    };
};
export type helpers_IsEventAtCapacity_Query = {
    readonly response: helpers_IsEventAtCapacity_QueryResponse;
    readonly variables: helpers_IsEventAtCapacity_QueryVariables;
};



/*
query helpers_IsEventAtCapacity_Query(
  $eventCuid: String!
  $userId: String!
) {
  event_connection(where: {cuid: {_eq: $eventCuid}}) {
    edges {
      node {
        spotsAvailable
        registrations_aggregate(where: {_and: [{_or: [{_and: [{userId: {_neq: $userId}}, {status: {_in: [complete, in_progress]}}]}, {_and: [{userId: {_eq: $userId}}, {status: {_in: [complete]}}]}]}, {_not: {registrationCategories: {categoryCuid: {_is_null: true}}}}]}) {
          aggregate {
            count
          }
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventCuid"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "eventCuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "cuid"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "spotsAvailable",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": [
            {
                "fields": [
                    {
                        "items": [
                            {
                                "fields": [
                                    {
                                        "items": [
                                            {
                                                "fields": [
                                                    {
                                                        "items": [
                                                            {
                                                                "fields": [
                                                                    {
                                                                        "fields": [
                                                                            {
                                                                                "kind": "Variable",
                                                                                "name": "_neq",
                                                                                "variableName": "userId"
                                                                            }
                                                                        ],
                                                                        "kind": "ObjectValue",
                                                                        "name": "userId"
                                                                    }
                                                                ],
                                                                "kind": "ObjectValue",
                                                                "name": "_and.0"
                                                            },
                                                            {
                                                                "kind": "Literal",
                                                                "name": "_and.1",
                                                                "value": {
                                                                    "status": {
                                                                        "_in": [
                                                                            "complete",
                                                                            "in_progress"
                                                                        ]
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                        "kind": "ListValue",
                                                        "name": "_and"
                                                    }
                                                ],
                                                "kind": "ObjectValue",
                                                "name": "_or.0"
                                            },
                                            {
                                                "fields": [
                                                    {
                                                        "items": [
                                                            {
                                                                "fields": [
                                                                    {
                                                                        "fields": [
                                                                            {
                                                                                "kind": "Variable",
                                                                                "name": "_eq",
                                                                                "variableName": "userId"
                                                                            }
                                                                        ],
                                                                        "kind": "ObjectValue",
                                                                        "name": "userId"
                                                                    }
                                                                ],
                                                                "kind": "ObjectValue",
                                                                "name": "_and.0"
                                                            },
                                                            {
                                                                "kind": "Literal",
                                                                "name": "_and.1",
                                                                "value": {
                                                                    "status": {
                                                                        "_in": [
                                                                            "complete"
                                                                        ]
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                        "kind": "ListValue",
                                                        "name": "_and"
                                                    }
                                                ],
                                                "kind": "ObjectValue",
                                                "name": "_or.1"
                                            }
                                        ],
                                        "kind": "ListValue",
                                        "name": "_or"
                                    }
                                ],
                                "kind": "ObjectValue",
                                "name": "_and.0"
                            },
                            {
                                "kind": "Literal",
                                "name": "_and.1",
                                "value": {
                                    "_not": {
                                        "registrationCategories": {
                                            "categoryCuid": {
                                                "_is_null": true
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "kind": "ListValue",
                        "name": "_and"
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "registration_aggregate",
        "kind": "LinkedField",
        "name": "registrations_aggregate",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "registration_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "helpers_IsEventAtCapacity_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "helpers_IsEventAtCapacity_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "dba342ee08fc7e5f520ae6f2f973a98e",
            "id": null,
            "metadata": {},
            "name": "helpers_IsEventAtCapacity_Query",
            "operationKind": "query",
            "text": "query helpers_IsEventAtCapacity_Query(\n  $eventCuid: String!\n  $userId: String!\n) {\n  event_connection(where: {cuid: {_eq: $eventCuid}}) {\n    edges {\n      node {\n        spotsAvailable\n        registrations_aggregate(where: {_and: [{_or: [{_and: [{userId: {_neq: $userId}}, {status: {_in: [complete, in_progress]}}]}, {_and: [{userId: {_eq: $userId}}, {status: {_in: [complete]}}]}]}, {_not: {registrationCategories: {categoryCuid: {_is_null: true}}}}]}) {\n          aggregate {\n            count\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '555a61fa990719c411e7282883e8c5b2';
export default node;
