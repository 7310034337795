/*
Recoil atoms, selectors and hooks for Host Event Page
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * A global start version of is event published so we can get it for the page title
 ************************************* */
const isEventPublished = atom({
  key: 'isEventPublished', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsEventPublishedGlobal = (): boolean => {
  return useRecoilValue(isEventPublished);
};
export const useSetIsEventPublishedGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isEventPublished);
};

