/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PersonalizationServiceQueries_loadUserPreferences_QueryVariables = {
    userId: string;
};
export type PersonalizationServiceQueries_loadUserPreferences_QueryResponse = {
    readonly userPersonalization_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly preferences: Record<string, unknown> | unknown[];
            };
        }>;
    };
};
export type PersonalizationServiceQueries_loadUserPreferences_Query = {
    readonly response: PersonalizationServiceQueries_loadUserPreferences_QueryResponse;
    readonly variables: PersonalizationServiceQueries_loadUserPreferences_QueryVariables;
};



/*
query PersonalizationServiceQueries_loadUserPreferences_Query(
  $userId: String!
) {
  userPersonalization_connection(where: {userId: {_eq: $userId}}, order_by: {updated_at: desc}, first: 1) {
    edges {
      node {
        id
        preferences
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                },
                {
                    "kind": "Literal",
                    "name": "order_by",
                    "value": {
                        "updated_at": "desc"
                    }
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "userId"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "userPersonalizationConnection",
            "kind": "LinkedField",
            "name": "userPersonalization_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "userPersonalizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "userPersonalization",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "preferences",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PersonalizationServiceQueries_loadUserPreferences_Query",
            "selections": (v1 /*: any*/),
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PersonalizationServiceQueries_loadUserPreferences_Query",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "6750e2cbcb2e58cef6489eedd8a71319",
            "id": null,
            "metadata": {},
            "name": "PersonalizationServiceQueries_loadUserPreferences_Query",
            "operationKind": "query",
            "text": "query PersonalizationServiceQueries_loadUserPreferences_Query(\n  $userId: String!\n) {\n  userPersonalization_connection(where: {userId: {_eq: $userId}}, order_by: {updated_at: desc}, first: 1) {\n    edges {\n      node {\n        id\n        preferences\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '9f98ec77e7be8547159af231c7539199';
export default node;
