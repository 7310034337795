/*
Recoil atoms, selectors and hooks for the entire Reggy application
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * Value for the header search
 ************************************* */
const headerSearchState = atom({
  key: 'headerSearch', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});
export const useGetHeaderSearchValueGlobal = (): string => {
  return useRecoilValue(headerSearchState);
};
export const useSetHeaderSearchValueGlobal = (): SetterOrUpdater<string> => {
  return useSetRecoilState(headerSearchState);
};

/** ************************************
 * Search query
 * Set this so we don't have to reload the page
 ************************************* */
const searchQueryState = atom({
  key: 'searchQueryState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});
export const useGetSearchQueryGlobal = (): string => {
  return useRecoilValue(searchQueryState);
};
export const useSetSearchQueryGlobal = (): SetterOrUpdater<string> => {
  return useSetRecoilState(searchQueryState);
};

/** ************************************
 * Total autocomplete hits
 ************************************* */
type TotalAutocompleteHitsType = { [x: string]: number };
const totalAutocompleteHits = atom({
  key: 'totalAutocompleteHits', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});
export const useGetTotalAutocompleteHitsGlobal = (): TotalAutocompleteHitsType => {
  return useRecoilValue(totalAutocompleteHits);
};
export const useSetTotalAutocompleteHitsGlobal = (): SetterOrUpdater<TotalAutocompleteHitsType> => {
  return useSetRecoilState(totalAutocompleteHits);
};
