import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';

import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import ExtendedRoute from '../components/ExtendedRoute';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { AppRouteService } from '../RouteService';

import CoachProfileCheckerProvider from '@/containers/CoachProfile/CoachProfileCheckerProvider';
import PageNotFound from '@/pages/ErrorPages/PageNotFound';
import CoachProfilePage from '@/pages/Profile/CoachProfilePage';

const ProfileView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "ProfileView" */
      '@/containers/CoachProfile/Profile/ProfileView'
    ),
);

const OnlineCoursesLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCoursesLoader" */ '@/containers/CoachProfile/OnlineCourses/OnlineCoursesLoader'
    ),
);
const OnlineCourseDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseDetail/OnlineCourseDetailLoader'
    ),
);
const OnlineCourseOverviewDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseOverviewDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseOverviewDetail/OnlineCourseOverviewDetailLoader'
    ),
);
const OnlineCourseSectionDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseSectionDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseSectionDetail/OnlineCourseSectionDetailLoader'
    ),
);
const OnlineCourseLessonDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseLessonDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseLessonDetail/OnlineCourseLessonDetailLoader'
    ),
);

const CoachProfileRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Redirect back home if they go to /coach. */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <div>
              <Navigate replace to={AppRouteService.getRelativeUrl('Home')} />
            </div>
          </ExtendedRoute>
        }
        path="/"
      />

      {/* Coach Profile App */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <Suspense
              fallback={<LoadingPlaceholder absoluteCenter disableShrink isLoading delay={200} />}
            >
              <CoachProfilePage>
                <ProfileView />
              </CoachProfilePage>
            </Suspense>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('CoachProfile')}
      />
      <Route
        element={
          <ExtendedRoute>
            <Routes>
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <Suspense
                      fallback={
                        <LoadingPlaceholder absoluteCenter disableShrink isLoading delay={200} />
                      }
                    >
                      <CoachProfileCheckerProvider>
                        <CoachProfilePage>
                          <OnlineCoursesLoader />
                        </CoachProfilePage>
                      </CoachProfileCheckerProvider>
                    </Suspense>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('CoachProfile_OnlineCourses')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <CoachProfileCheckerProvider>
                      <CoachProfilePage>
                        <OnlineCourseDetailLoader />
                      </CoachProfilePage>
                    </CoachProfileCheckerProvider>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('CoachProfile_OnlineCourses_Detail')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <CoachProfileCheckerProvider>
                      <CoachProfilePage>
                        <OnlineCourseOverviewDetailLoader />
                      </CoachProfilePage>
                    </CoachProfileCheckerProvider>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath(
                  'CoachProfile_OnlineCourses_OverviewDetail',
                )}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <CoachProfileCheckerProvider>
                      <CoachProfilePage>
                        <OnlineCourseSectionDetailLoader />
                      </CoachProfilePage>
                    </CoachProfileCheckerProvider>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath(
                  'CoachProfile_OnlineCourses_SectionDetail',
                )}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <CoachProfileCheckerProvider>
                      <CoachProfilePage>
                        <OnlineCourseLessonDetailLoader />
                      </CoachProfilePage>
                    </CoachProfileCheckerProvider>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('CoachProfile_OnlineCourses_LessonDetail')}
              />
            </Routes>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('CoachProfile_OnlineCourses')}
      />

      {/* 404 Page */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <PageNotFound />
          </ExtendedRoute>
        }
        path="*"
      />
    </Routes>
  );
};
export default CoachProfileRoutes;
