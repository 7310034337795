import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';

import PersonalizationService from './review/questions/PersonalizationService';
import ReviewService from './review/ReviewService';

import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';

export interface IServicesContext {
  personalization?: PersonalizationService;
  review: ReviewService;
}

export const ServicesContext = createContext<IServicesContext | undefined>(undefined);

export const ServicesContextProvider: ReactFCC = ({ children }) => {
  const relayEnv = useRelayEnvironment();
  const auth = useAuth();

  const [services, setServices] = useState<IServicesContext>({
    personalization: undefined,
    review: new ReviewService(relayEnv),
  });

  useEffect(() => {
    if (auth.user) {
      const personalization = new PersonalizationService(relayEnv, auth.user);
      const review = new ReviewService(relayEnv, auth.user, personalization);

      setServices({ personalization, review });
    }
  }, [auth.user]);

  return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
};

export const useServicesContext = (): IServicesContext =>
  useContext(ServicesContext) as IServicesContext;
