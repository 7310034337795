import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import ExtendedButton from '@/ui/Button/ExtendedButton';

import { useHideSetupCoachMutation } from './setupCoachMutations';

import { NO_COACH_ANIMATE_DELAY } from '@/containers/MainHeader/Desktop/CoachingMenuItem';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#FFFAE7',
    display: 'flex',
    height: 124,
    padding: theme.spacing(3),
    margin: '-8px 0',
    position: 'relative',
    opacity: 1,
    overflow: 'hidden',
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  hidden: {
    borderColorTop: 'transparent',
    height: 0,
    opacity: 0,
    padding: 0,
    transition: `all 200ms ${NO_COACH_ANIMATE_DELAY}ms`,
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    '& svg': {
      color: '#898989',
    },
  },
}));

interface CreateCoachProps {
  handleCloseMenu: () => void;
}
const SetupCoach: React.FC<CreateCoachProps> = ({ handleCloseMenu }) => {
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(false);
  const updateShowCreateCoachMutation = useHideSetupCoachMutation();

  /**
   * Hide the 'Create Coach' menu
   */
  const handleToggleHide = (): void => {
    updateShowCreateCoachMutation();
    setIsHidden(true);
  };

  /**
   * Go to 'Create Coach' flow
   */
  const handleGoToCreateCoach = (): void => {
    handleCloseMenu();
    updateShowCreateCoachMutation();
    navigate(AppRouteService.getRelativeUrl('User_BecomeCoach'));
  };

  // Check to see if we just closed the dialog (isClosed) or we have already gone though this flow
  if (!auth.user?.showSetupCoach && !isHidden) {
    return null;
  }

  return (
    <Box className={classNames(classes.root, { [classes.hidden]: isHidden })}>
      {isHidden ? (
        <div>
          <Typography gutterBottom variant="body2">
            <b>No problem.</b>
          </Typography>
          <Typography variant="subtitle2">
            If you want to set it up later, you can do so in your 'Account Settings'.
          </Typography>
        </div>
      ) : (
        <div>
          <IconButton className={classes.iconButton} onClick={handleToggleHide} size="small">
            <CloseIcon />
          </IconButton>
          <Box mb={2}>
            <Typography variant="body1">
              <b>Are you a coach?</b>
            </Typography>
          </Box>
          <ExtendedButton
            fullWidth
            color="primary"
            onClick={handleGoToCreateCoach}
            size="medium"
            variant="contained"
          >
            Set up Coaching
          </ExtendedButton>
        </div>
      )}
    </Box>
  );
};

export default SetupCoach;
