import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
// Icons
import Close from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SuccessOutlined from '@material-ui/lab/internal/svg-icons/SuccessOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import { OptionsObject, SnackbarProvider, useSnackbar, WithSnackbarProps } from 'notistack';

import { getParsedQueryParams } from '@/lib/path-helpers';
import { ReactFCC } from '@/lib/type-defs/utility';
import { Colors } from '@/themes/colors';

const NotificationCloseButton: React.FC<{ snackbarKey?: string | number }> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton aria-label="close" color="inherit" onClick={(): void => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
};
const IconWrapper: React.FC = ({ children }) => (
  <div style={{ alignItems: 'center', display: 'flex', marginRight: 8 }}>{children}</div>
);

const useStyles = makeStyles(() => ({
  error: {
    backgroundColor: Colors.DangerBackground,
    border: `1px solid ${Colors.Danger}`,
    color: Colors.Danger,
  },
  success: {
    backgroundColor: Colors.SuccessBackground,
    border: `1px solid ${Colors.Success}`,
    color: Colors.Success,
  },
  warning: {
    backgroundColor: Colors.WarningBackground,
    border: `1px solid ${Colors.Warning}`,
    color: Colors.Warning,
  },
}));

const NotificationProvider: ReactFCC = ({ children }) => {
  const classes = useStyles();
  const ref = useRef<{
    closeSnackbar: WithSnackbarProps['closeSnackbar'];
    state: { snacks: OptionsObject[] };
  }>();
  return (
    <SnackbarProvider
      action={(key): React.ReactNode => [
        <NotificationCloseButton key={`close-${key}`} snackbarKey={key} />,
      ]}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={3333}
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      iconVariant={{
        error: (
          <IconWrapper>
            <ErrorOutlineIcon />
          </IconWrapper>
        ),
        info: (
          <IconWrapper>
            <InfoOutlinedIcon />
          </IconWrapper>
        ),
        success: (
          <IconWrapper>
            <SuccessOutlined />
          </IconWrapper>
        ),
        warning: (
          <IconWrapper>
            <ReportProblemOutlinedIcon />
          </IconWrapper>
        ),
      }}
      maxSnack={5}
      onClose={(event, reason, key): void => {
        if (reason === 'clickaway') {
          if (!ref.current) {
            return;
          }
          const currentSnack = ref.current.state.snacks.filter((snack) => snack.key === key);
          if (currentSnack?.[0] && !currentSnack[0].persist) {
            ref.current.closeSnackbar(key);
          }
        }
      }}
      ref={ref}
    >
      <GlobalToasts>{children}</GlobalToasts>
    </SnackbarProvider>
  );
};

const GlobalToasts: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const queryParams = getParsedQueryParams();

  /**
   * Global toasts
   */
  useEffect(() => {
    if (!queryParams.errorToast && !queryParams.successToast && !queryParams.defaultToast) {
      return;
    }
    if (queryParams.errorToast) {
      enqueueSnackbar(queryParams.errorToast, { variant: 'error' });
    } else if (queryParams.successToast) {
      enqueueSnackbar(queryParams.successToast, { variant: 'success' });
    } else if (queryParams.defaultToast) {
      enqueueSnackbar(queryParams.errorToast);
    }

    // Clear URL params
    navigate(window.location.pathname, { replace: true });
  }, [queryParams.errorToast, queryParams.successToast, queryParams.defaultToast]);

  return <div>{children}</div>;
};

export default NotificationProvider;
