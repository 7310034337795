import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
// eslint-disable-next-line import/no-unresolved
import { UseMutationConfig } from 'react-relay/lib/relay-experimental/useMutation';
import { Navigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { useSnackbar } from 'notistack';
import {
  commitLocalUpdate,
  Disposable,
  graphql,
  MutationParameters,
  PayloadError,
  StoreUpdater,
} from 'relay-runtime';

import GlobalLoadingBackdrop from '@/ui/GlobalLoadingBackdrop';

import {
  HostEventProviderQuery,
  HostEventProviderQueryResponse,
} from './__generated__/HostEventProviderQuery.graphql';

import { useTrackSentryError } from '@/analytics/sentry';
import {
  useGetEventHasUnSavedChangesGlobal,
  useSetEventHasUnSavedChangesGlobal,
  useSetIsEventSavingGlobal,
  useSetSaveQueueGlobal,
} from '@/containers/HostEvent/recoilStore';
import { isActivityClinic, isActivityRace, isActivityRide } from '@/lib/event-info-utils';
import {
  updateAlgoliaCategoriedRideIndexCF,
  updateAlgoliaClinicIndexCF,
  updateAlgoliaNonCategoriedRideIndexCF,
  updateAlgoliaRaceIndexCF,
} from '@/lib/firebase';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { ReactFCC } from '@/lib/type-defs/utility';
import { usePrompt } from '@/lib/ui-utils';
import { useSetIsEventPublishedGlobal } from '@/pages/HostEvent/recoilStore';
import {
  HostEventProviderSeriesQuery,
  HostEventProviderSeriesQueryResponse,
} from '@/providers/HostEventProvider/__generated__/HostEventProviderSeriesQuery.graphql';
import GlobalSaveErrorDialog from '@/providers/HostEventProvider/GlobalSaveErrorDialog';
import { AppRouteService } from '@/routes/RouteService';

type SaveMutationConfig<TMutation extends MutationParameters> = UseMutationConfig<TMutation> & {
  // Add the mutation name so we can de-dup existing mutations
  mutationName: string;
};

type SaveMutation<TMutation extends MutationParameters> = (
  config: SaveMutationConfig<TMutation>,
) => Disposable;

type MutationAndConfig = {
  config: SaveMutationConfig<Record<string, unknown> & MutationParameters>;
  mutation: SaveMutation<Record<string, unknown> & MutationParameters>;
}[];

export interface HostEventQuery {
  event: HostEventProviderQueryResponse['event_connection']['edges'][0]['node'];
}
const HostEventProvider: ReactFCC = ({ children }) => {
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const { enqueueSnackbar } = useSnackbar();
  const trackSentryError = useTrackSentryError();
  const setSaveQueueGlobal = useSetSaveQueueGlobal();
  const setIsEventSavingGlobal = useSetIsEventSavingGlobal();
  const setIsEventPublishedGlobal = useSetIsEventPublishedGlobal();
  const eventHasUnSavedChanges = useGetEventHasUnSavedChangesGlobal();
  const setEventHasUnSavedChanges = useSetEventHasUnSavedChangesGlobal();
  const [hasGlobalError, setHasGlobalError] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const saveQueueRef = useRef<MutationAndConfig>([]);

  // Notify the user if there are unsaved changes if they try to reload or leave
  useBeforeUnload(
    eventHasUnSavedChanges,
    `You have unsaved changes, are you sure you want to leave?`,
  );

  const { eventCuid } = useTypedParams(['eventCuid']);
  const relayEnvironment = useRelayEnvironment();
  const eventData = useLazyLoadQuery<HostEventProviderQuery>(
    graphql`
      query HostEventProviderQuery($cuid: String!, $organizerSlug: String!) {
        event_connection(where: { cuid: { _eq: $cuid } }) {
          edges {
            node {
              ...HostEventPage_event
              ...HostEventMainView_event
              ...ValidityChecker_event
              ...HeaderContainer_event
              ...rulesDates_RuleEventEndAfterRegClose_event
              ...rulesDates_RuleScheduleDatesInsideEventDate_event
              ...rulesCategories_RuleAllCategoriesHaveCourse_event
              ...helpers_useSyncSectionValidity_event
              ...helpers_getUserTypeFromActivity_event
              ...helpers_getEventActivity_event
              ...helpers_getEventCuid_event
              ...helpers_getEventSlug_event
              ...helpers_getEventLink_event
              ...helpers_useUpdateAlgoliaSearchIndexes_event
              ...helpers_getEncodedEventId_event
              ...helpers_getEncodedEventMetadataId_event
              ...helpers_getIsEventPublished_event
              ...helpers_getOrganizerCuid_event
              ...hostEventMutations_useUnPublishEventMutation_event
              ...EditEventNameDialog_event
              ...OccurrencesDialog_event
              ...OccurrenceInfo_event
              ...CustomQuestionsContainer_event
              ...SaveCustomQuestionDialog_event
              ...IntroSection_event
              ...GeneralSection_event
              ...generalSectionSideEffects_event
              ...LocationContactSection_event
              ...VirtualVenueContainer_event
              ...CourseSection_event
              ...ProgramSection_event
              ...AssessmentSection_event
              ...ChecklistSection_event
              ...VibeSection_event
              ...SeriesSection_event
              ...SanctionSection_event
              ...TicketsSection_event
              ...CategoryPresetContainer_event
              ...CategoriesSection_event
              ...CreateCategoryDialog_event
              ...EditMultipleCategories_event
              ...CoursesColumn_event
              ...categoriesSectionSideEffects_scheduleItemOnCategoryDelete_event
              ...categoriesSectionSideEffects_customQuestionOnCategoryDelete_event
              ...categoriesSectionSideEffects_eventSanctionOnCategoryDelete_event
              ...categoriesSectionSideEffects_eventSeriesOnCategoryDelete_event
              ...categoriesSectionSideEffects_eventBasicWaiverOnCategoryDelete_event
              ...DisciplineTabContainer_event
              ...DisciplineTable_event
              ...VolunteersSection_event
              ...MerchandiseSection_event
              ...RegistrationSection_event
              ...EventCapacityContainer_event
              ...PromoCodesContainer_event
              ...PricingSection_event
              ...ScheduleSection_event
              ...ScheduleTypePicker_event
              ...ImagesSection_event
              ...AmenitiesSection_event
              ...AwardsSection_event
              ...SponsorsSection_event
              ...AdditionalInfoSection_event
              ...WaiverSection_event
              ...BasicWaiverSection_event
              ...ScheduleClinicSection_event
              ...ScheduleClinicDialog_event
              ...ScheduleRideSection_event
              ...ScheduleRideDialog_event
              ...FinishSection_event
              id
              publishedAt
              activity
              eventMetadata {
                isCategoriedEvent
              }
            }
          }
        }
        sanction_connection(order_by: { id: asc }) {
          ...SanctionSection_sanctions
        }
        #        merchandise_connection(
        #          order_by: { created_at: asc }
        #          where: {
        #            _and: [
        #              { deleted_at: { _is_null: true } }
        #              { organizer: { slug: { _eq: $organizerSlug } } }
        #            ]
        #          }
        #        ) {
        #          ...MerchandiseSection_merchandise
        #        }
        sponsor_connection(
          order_by: { created_at: asc }
          where: {
            _and: [
              { deleted_at: { _is_null: true } }
              { organizer: { slug: { _eq: $organizerSlug } } }
            ]
          }
        ) {
          ...SponsorTierItem_sponsors
        }
        scheduleTypePreset_connection(order_by: [{ activity: desc }, { id: asc }]) {
          ...SaveScheduleItemDialog_scheduleTypePreset
        }
        amenity_connection(order_by: { id: asc }) {
          ...AmenitiesSection_amenities
        }
        #        volunteerTaskDifficulties_connection(order_by: { id: asc }) {
        #          ...Task_difficulties
        #        }
        #        volunteerTaskPriorities_connection(order_by: { id: asc }) {
        #          ...Task_priorities
        #        }
        globalPresetWaivers: waiver_connection(
          where: { _and: { deleted_at: { _is_null: true }, ownerType: { _eq: REGGY } } }
        ) {
          ...WaiverSection_waiverConnection
        }
      }
    `,
    { cuid: eventCuid, organizerSlug },
  );
  const event = eventData.event_connection.edges[0].node;

  const seriesData = useLazyLoadQuery<HostEventProviderSeriesQuery>(
    graphql`
      query HostEventProviderSeriesQuery($activity: String!, $organizerSlug: String!) {
        series_connection(
          order_by: { created_at: asc }
          where: {
            _and: [
              { deleted_at: { _is_null: true } }
              { organizer: { slug: { _eq: $organizerSlug } } }
              { seriesActivity: { _eq: $activity } }
            ]
          }
        ) {
          ...SeriesSection_series
        }
      }
    `,
    { activity: event?.activity, organizerSlug },
  );

  const isEventPublished = !!event?.publishedAt;
  // For use in HostEventPage
  useEffect(() => {
    setIsEventPublishedGlobal(isEventPublished);
  }, [isEventPublished]);

  usePrompt('You have unsaved changes, are you sure you want to leave?', eventHasUnSavedChanges);

  // No Event Data
  if (!eventData) {
    return (
      <Navigate
        replace
        to={AppRouteService.getRelativeUrl('OrganizerApp_Events', { organizerSlug })}
      />
    );
  }

  /**
   * Add to queue so we can commit all mutations at once or immediately save change
   */
  const saveHostEventHandler = <TMutation extends MutationParameters>(
    mutation: SaveMutation<TMutation>,
    config: SaveMutationConfig<TMutation>,
  ): void => {
    if (!config.optimisticUpdater) {
      throw new Error('Please provide an updater function for commitLocalUpdate');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore We removed the type. Make sure someone doesn't use it on accident
    if (config.optimisticResponse) {
      throw new Error('Use optimisticUpdater instead of optimisticResponse');
    }

    // Explicitly set setIsEventSavingGlobal to false
    const updatedConfig = {
      ...config,
      onCompleted: (response: TMutation['response'], errors: PayloadError[]): void => {
        if (config?.onCompleted) {
          config.onCompleted(response, errors);
        }
        setIsEventSavingGlobal(false);
        if (!isEventPublished) {
          enqueueSnackbar('Event saved!', { variant: 'success' });
        }
      },
    };

    if (!isEventPublished) {
      // Commit the mutation immediately
      mutation(updatedConfig);
      return;
    }

    // Update local Relay cache. This gets executed even if we don't add the mutation to the queue
    commitLocalUpdate(relayEnvironment, config.optimisticUpdater as StoreUpdater);

    // TODO: Not sure how to fix this type error
    saveQueueRef.current.push({ mutation, config: updatedConfig });
    setSaveQueueGlobal((queue) => [...queue, config.mutationName]);

    if (saveQueueRef.current.length >= 1) {
      setEventHasUnSavedChanges(true);
    }

    // TODO: Above could cause a re-render but below eventHasUnSavedChanges is stale
    // if (saveQueueRef.current.length >= 1 && !eventHasUnSavedChanges) {
    //   setEventHasUnSavedChanges(true);
    // }

    // If the user has too many mutations in the queue, we commit them so we
    // don't hit the rate limit and so the user doesnt lose any changes
    if (saveQueueRef.current.length > 20) {
      commitSaveQueue().then();
    }
  };

  /**
   * Function to be executed when an event is published and a user wants to save all of their changes
   */
  const commitSaveQueue = async (): Promise<void> => {
    if (!eventHasUnSavedChanges) {
      return;
    }
    setIsSavingChanges(true);
    // must use old fashioned for loop instead of the foreach method
    for (const { config, mutation } of saveQueueRef.current) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve, reject) => {
        const configWithGlobalErrorHandling = {
          ...config,
          onError: (err: Error): void => {
            trackSentryError(err);
            if (config.onError) {
              config.onError(err);
            }
            setHasGlobalError(true);
            reject(err);
          },
          onCompleted: (response: Record<string, unknown>, errors: PayloadError[]): void => {
            if (config.onCompleted) {
              config.onCompleted(response, errors);
            }
            // single arg that contains both response + payload errors
            resolve({ response, errors });
          },
        };
        mutation(configWithGlobalErrorHandling);
      });
    }

    enqueueSnackbar('Event saved!', { variant: 'success' });

    // Update Algolia Event index
    // We don't have to worry about race conditions because we promisified all the mutations.
    // We should also ALWAYS have event data at this point. We want an error if we don't
    if (event.publishedAt) {
      if (isActivityClinic(event.activity)) {
        updateAlgoliaClinicIndexCF({
          eventCuid,
        }).then();
      } else if (isActivityRide(event.activity)) {
        if (event.eventMetadata.isCategoriedEvent) {
          updateAlgoliaCategoriedRideIndexCF({
            eventCuid,
          }).then();
        } else {
          updateAlgoliaNonCategoriedRideIndexCF({
            eventCuid,
          }).then();
        }
      } else if (isActivityRace(event.activity)) {
        updateAlgoliaRaceIndexCF({
          eventCuid,
        }).then();
      }
    }

    setIsSavingChanges(false);
    saveQueueRef.current = [];
    setSaveQueueGlobal([]);
    setEventHasUnSavedChanges(false);
  };

  return (
    <hostEventContext.Provider
      value={{
        ...event,
        allSanctions: eventData.sanction_connection,
        allSeries: seriesData.series_connection,
        allScheduleTypePresets: eventData.scheduleTypePreset_connection,
        // allMerchandise: eventData.merchandise_connection,
        // allVolunteerDifficulties: eventData.volunteerTaskDifficulties_connection,
        // allVolunteerPriorities: eventData.volunteerTaskPriorities_connection,
        allSponsors: eventData.sponsor_connection,
        allAmenities: eventData.amenity_connection,
        globalPresetWaivers: eventData.globalPresetWaivers,
        saveHostEventHandler,
        commitSaveQueue,
      }}
    >
      {children}
      {/* <Prompt */}
      {/*  message="You have unsaved changes, are you sure you want to leave?" */}
      {/*  when={eventHasUnSavedChanges} */}
      {/* /> */}
      <GlobalSaveErrorDialog isOpen={hasGlobalError} />
      <GlobalLoadingBackdrop
        isLoading={isSavingChanges}
        title={isEventPublished ? 'Publishing Changes' : `Saving ${event?.activity || 'Event'}`}
      />
    </hostEventContext.Provider>
  );
};

const hostEventContext = createContext({});

// Hook for child components to get the root relay query.
export const useHostEventData = (): HostEventQuery['event'] & {
  allAmenities: HostEventProviderQueryResponse['amenity_connection'];
  allSanctions: HostEventProviderQueryResponse['sanction_connection'];
  allScheduleTypePresets: HostEventProviderQueryResponse['scheduleTypePreset_connection'];
  allSeries: HostEventProviderSeriesQueryResponse['series_connection'];
  // allMerchandise: HostEventProviderQueryResponse['merchandise_connection'];
  // allVolunteerPriorities: HostEventProviderQueryResponse['volunteerTaskPriorities_connection'];
  // allVolunteerDifficulties: HostEventProviderQueryResponse['volunteerTaskDifficulties_connection'];
  allSponsors: HostEventProviderQueryResponse['sponsor_connection'];
  globalPresetWaivers: HostEventProviderQueryResponse['globalPresetWaivers'];
} => {
  return useContext(hostEventContext) as HostEventQuery['event'] & {
    allAmenities: HostEventProviderQueryResponse['amenity_connection'];
    allSanctions: HostEventProviderQueryResponse['sanction_connection'];
    allScheduleTypePresets: HostEventProviderQueryResponse['scheduleTypePreset_connection'];
    allSeries: HostEventProviderSeriesQueryResponse['series_connection'];
    // allMerchandise: HostEventProviderQueryResponse['merchandise_connection'];
    // allVolunteerPriorities: HostEventProviderQueryResponse['volunteerTaskPriorities_connection'];
    // allVolunteerDifficulties: HostEventProviderQueryResponse['volunteerTaskDifficulties_connection'];
    allSponsors: HostEventProviderQueryResponse['sponsor_connection'];
    globalPresetWaivers: HostEventProviderQueryResponse['globalPresetWaivers'];
  };
};

// Hook for child components to get save event functions.
export const useSaveHostEvent = (): {
  commitSaveQueue: () => void;
  saveHostEventHandler: <TMutation extends MutationParameters>(
    mutation: SaveMutation<TMutation>,
    config: SaveMutationConfig<TMutation>,
  ) => void;
} => {
  return useContext(hostEventContext) as {
    commitSaveQueue: () => void;
    saveHostEventHandler: <TMutation extends MutationParameters>(
      mutation: SaveMutation<TMutation>,
      config: SaveMutationConfig<TMutation>,
    ) => void;
  };
};

export default HostEventProvider;

/** ****
Shared updater example
*/
// updater: (store) => {
//
//     function sharedUpdater(
//       store: RecordSourceProxy,
//       recordToAdd: RecordProxy,
//       isServerReturn: boolean,
//     ) {
//       const event = store.get(encodedEventId);
//       if (event) {
//         // Get initial records.
//         const eventSanctions = event.getLinkedRecords('eventSanctions');
//         // Add new records.
//         // If the event is published, we will have a duplicate record from our commitLocalUpdate
//         // We need to dedup this by the tables PK since all records are objects
//         let newRecords = R.uniqBy((record) => record.getValue('cuid'), [
//           ...eventSanctions,
//           recordToAdd,
//         ]);
//
//         if (isServerReturn) {
//           // If the event is published and we are done with our mutation,
//           // we need to remove all temporary items we added to the cache manually.
//           // We use 'client:temp*' to specify the exact mutation we want to target
//           // so we don't remove another mutations client state too early.
//           newRecords = newRecords.filter((record) => {
//             const dataId = record.getDataID();
//             return !dataId.toLowerCase().includes(createTempClientPrefix(TABLE_NAME));
//           });
//         }
//         // Set new records in the cache
//         event.setLinkedRecords(newRecords, TABLE_NAME);
//       }
//     }
//         const payload = store.getRootField('insert_eventSanction_one');
//         if (!payload) {
//           return;
//         }
//         sharedUpdater(store, payload, true);
//       },
//     });
