import React from 'react';

import Page from '../Page';

import '@/../public/css/arrow.css';

import OnboardingLayout from '@/layouts/OnboardingLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const OnboardingPage: ReactFCC = ({ children }) => {
  return (
    <Page noIndex title="Onboarding • Reggy">
      <OnboardingLayout>{children}</OnboardingLayout>
    </Page>
  );
};

export default OnboardingPage;
