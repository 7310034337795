/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HostEventPage_event = {
    readonly occurrenceLabel: string | null;
    readonly eventMetadata: {
        readonly name: string;
    };
    readonly " $refType": "HostEventPage_event";
};
export type HostEventPage_event$data = HostEventPage_event;
export type HostEventPage_event$key = {
    readonly " $data"?: HostEventPage_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"HostEventPage_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HostEventPage_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "occurrenceLabel",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "eventMetadata",
            "kind": "LinkedField",
            "name": "eventMetadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'f1c221f1682461da441582530d7a5fa9';
export default node;
