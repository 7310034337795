import { useParams } from 'react-router';
import { useLocalStorage } from 'react-use';
import cuid from 'cuid';

import { ALGOLIA_REGISTER_CLICKED, useReportAlgoliaEvent } from '@/analytics/algolia/algolia';
import { useSegmentAnalyticsContext } from '@/analytics/segment/SegmentAnalyticsProvider';
import { SegmentEventName, SegmentEvents } from '@/analytics/segment/SegmentEventTypes';
import { useTrackSentryError } from '@/analytics/sentry';
import { useGetIsAdBlockerRunningGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { sendSegmentEventCF } from '@/lib/firebase';
import { useAuth } from '@/providers/AuthProvider';

/**
 * Hook to track, report and capture errors
 */
export const useSegmentTrack = (): {
  trackCoachConnectStripeClick: (props: SegmentEvents['Coach_ConnectStripe_Click']) => void;
  trackCoachSectionEditClick: (props: SegmentEvents['Coach_SectionEdit_Click']) => void;
  trackCoachSectionSaveClick: (props: SegmentEvents['Coach_SectionSave_Click']) => void;
  trackCreatorUpsellClick: (props: SegmentEvents['Creator_CreatorUpsell_Click']) => void;
  trackCreateCoachFinishClick: (props: SegmentEvents['CreateCoach_Finish_Click']) => void;
  trackCreateCoachStepCompleted: (props: SegmentEvents['CreateCoach_Step_Completed']) => void;
  trackCreateCoachStepViewed: (props: SegmentEvents['CreateCoach_Step_Viewed']) => void;
  trackCreateEventFinishClick: (props: SegmentEvents['CreateEvent_Finish_Click']) => void;
  trackCreateOnlineCourseFinishClick: (
    props: SegmentEvents['CreateOnlineCourse_Finish_Click'],
  ) => void;
  trackCreateOnlineCourseStepCompleted: (
    props: SegmentEvents['CreateOnlineCourse_Step_Completed'],
  ) => void;
  trackCreateOnlineCourseStepViewed: (
    props: SegmentEvents['CreateOnlineCourse_Step_Viewed'],
  ) => void;
  trackDiscoverCategoryClick: (props: SegmentEvents['Discover_Category_Click']) => void;
  trackDiscoverHitClick: (props: SegmentEvents['Discover_Hit_Click']) => void;
  trackEventRegisterClick: (props: SegmentEvents['Event_Register_Click']) => void;
  trackEventRegistrationFinishClick: (
    props: SegmentEvents['EventRegistration_Finish_Click'],
  ) => void;
  trackEventRegistrationPaymentSuccess: (
    props: SegmentEvents['EventRegistration_Payment_Success'],
  ) => void;
  trackEventRegistrationStepCompleted: (
    props: SegmentEvents['EventRegistration_Step_Completed'],
  ) => void;
  trackEventRegistrationStepViewed: (props: SegmentEvents['EventRegistration_Step_Viewed']) => void;
  trackHomeBecomeACoachClick: (props: SegmentEvents['Home_BecomeACoach_Click']) => void;
  trackHomeBecomeAnOrganizerClick: (props: SegmentEvents['Home_BecomeAnOrganizer_Click']) => void;
  trackHomeDiscoverClick: (props: SegmentEvents['Home_Discover_Click']) => void;
  trackHostEventPublishClick: (props: SegmentEvents['HostEvent_Publish_Click']) => void;
  trackHostEventSectionCompleted: (props: SegmentEvents['HostEvent_Section_Completed']) => void;
  trackHostEventUnPublishClick: (props: SegmentEvents['HostEvent_UnPublish_Click']) => void;
  trackLessonRequestLessonClick: (props: SegmentEvents['Lesson_RequestLesson_Click']) => void;
  trackOnlineCoursePaymentSuccess: (props: SegmentEvents['OnlineCourse_Payment_Success']) => void;
  trackOnlineCourseRegisterClick: (props: SegmentEvents['OnlineCourse_Register_Click']) => void;
  trackOrganizerConnectStripeClick: (props: SegmentEvents['Organizer_ConnectStripe_Click']) => void;
  trackSearchHitClick: (props: SegmentEvents['Search_Hit_Click']) => void;
  trackSiteErrorView: (props: SegmentEvents['Site_Error_View']) => void;
  trackUserRequestEventRefundClick: (props: SegmentEvents['User_RequestEventRefund_Click']) => void;
  trackUserRequestLessonRefundClick: (
    props: SegmentEvents['User_RequestLessonRefund_Click'],
  ) => void;
  trackUserSignUpViewed: (props: SegmentEvents['User_SignUp_Viewed']) => void;
  trackUserSignUpWithEmailClick: (props: SegmentEvents['User_SignUpWithEmail_Click']) => void;
} => {
  const auth = useAuth();
  const params = useParams();
  const segmentAnalytics = useSegmentAnalyticsContext();
  const trackSentryError = useTrackSentryError();
  const isAdBlockRunning = useGetIsAdBlockerRunningGlobal();
  const { reportAlgoliaClickedObjectIDs } = useReportAlgoliaEvent();
  const [reggyAnonymousId] = useLocalStorage('reggy-anonymousId', cuid());

  /**
   * Fires a Segment track call.
   * @param {String} eventName - The name of the analytics property that will be sent to Segment.
   * @param {Object} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics call is fired.
   */
  const trackSegmentEvent = ({
    callback,
    eventName,
    options,
    props,
  }: {
    callback?: Segment.Callback;
    eventName: SegmentEventName;
    options?: Segment.Options;
    props: SegmentEvents[SegmentEventName];
    // includeContext?: { userProps; orgProps };
  }): void => {
    try {
      const propsToTrack = { userId: auth.firebaseUser?.uid, ...(props || {}), ...params };
      if (isAdBlockRunning) {
        sendSegmentEventCF({
          functionName: 'track',
          message: {
            properties: propsToTrack,
            anonymousId: segmentAnalytics?.user?.()?.anonymousId?.() || reggyAnonymousId,
            event: eventName,
            isServer: false
          },
        }).then();
      } else if (isAdBlockRunning === false) {
        segmentAnalytics?.track(eventName, propsToTrack, options, callback);
      }
    } catch (err) {
      trackSentryError(err);
    }
  };

  /** ********************************************************************
   * Site
   ********************************************************************* */
  /**
   * Tracks when a user changes the category on the Discover page
   */
  const trackSiteErrorView = (props: SegmentEvents['Site_Error_View']): void => {
    trackSegmentEvent({
      eventName: 'Site_Error_View',
      props,
    });
  };

  /** ********************************************************************
   * Search/Discover
   ********************************************************************* */
  /**
   * Tracks when a user changes the category on the Discover page
   */
  const trackDiscoverCategoryClick = (props: SegmentEvents['Discover_Category_Click']): void => {
    trackSegmentEvent({
      eventName: 'Discover_Category_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks an item Discover page
   */
  const trackDiscoverHitClick = (props: SegmentEvents['Discover_Hit_Click']): void => {
    trackSegmentEvent({
      eventName: 'Discover_Hit_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks an item Search page
   */
  const trackSearchHitClick = (props: SegmentEvents['Search_Hit_Click']): void => {
    trackSegmentEvent({
      eventName: 'Search_Hit_Click',
      props,
    });
  };
  /** ********************************************************************
   * User
   ********************************************************************* */
  /**
   * Tracks when a user views the signup container. This could be in a modal so we track it.
   */
  const trackUserSignUpViewed = (props: SegmentEvents['User_SignUp_Viewed']): void => {
    trackSegmentEvent({
      eventName: 'User_SignUp_Viewed',
      props,
    });
  };
  /**
   * Tracks when a user clicks the "Signup with Email" button.
   * Tracking this so we can see if there is dropoff when people see they have to put in their name
   */
  const trackUserSignUpWithEmailClick = (
    props: SegmentEvents['User_SignUpWithEmail_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'User_SignUpWithEmail_Click',
      props,
    });
  };
  /**
   * Tracks when a user requests a refund for a lesson
   */
  const trackUserRequestLessonRefundClick = (
    props: SegmentEvents['User_RequestLessonRefund_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'User_RequestLessonRefund_Click',
      props,
    });
  };
  /**
   * Tracks when a user requests a refund for a registration or lesson
   */
  const trackUserRequestEventRefundClick = (
    props: SegmentEvents['User_RequestEventRefund_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'User_RequestEventRefund_Click',
      props,
    });
  };

  /** ********************************************************************
   * Creator
   ********************************************************************* */
  /**
   * Tracks when a user clicks Become a Creator after a registration
   */
  const trackCreatorUpsellClick = (props: SegmentEvents['Organizer_ConnectStripe_Click']): void => {
    trackSegmentEvent({
      eventName: 'Organizer_ConnectStripe_Click',
      props,
    });
  };

  /** ********************************************************************
   * Organizer
   ********************************************************************* */
  /**
   * Tracks when a user tries to connect a stripe account to an organization
   */
  const trackOrganizerConnectStripeClick = (
    props: SegmentEvents['Organizer_ConnectStripe_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'Organizer_ConnectStripe_Click',
      props,
    });
  };

  /** ********************************************************************
   * Coach
   ********************************************************************* */
  /**
   * Tracks when a user views a create coach step
   */
  const trackCreateCoachStepViewed = (props: SegmentEvents['CreateCoach_Step_Viewed']): void => {
    trackSegmentEvent({
      eventName: 'CreateCoach_Step_Viewed',
      props,
    });
  };
  /**
   * Tracks when a user completes a create coach step
   */
  const trackCreateCoachStepCompleted = (
    props: SegmentEvents['CreateCoach_Step_Completed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'CreateCoach_Step_Completed',
      props,
    });
  };
  /**
   * Tracks when a user finishes the create coach workflow
   */
  const trackCreateCoachFinishClick = (props: SegmentEvents['CreateCoach_Finish_Click']): void => {
    trackSegmentEvent({
      eventName: 'CreateCoach_Finish_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks edit in their coach profile
   */
  const trackCoachSectionEditClick = (props: SegmentEvents['Coach_SectionEdit_Click']): void => {
    trackSegmentEvent({
      eventName: 'Coach_SectionEdit_Click',
      props,
    });
  };
  /**
   * racks when a user clicks save in their coach profile for a section
   */
  const trackCoachSectionSaveClick = (props: SegmentEvents['Coach_SectionSave_Click']): void => {
    trackSegmentEvent({
      eventName: 'Coach_SectionSave_Click',
      props,
    });
  };
  /**
   * Tracks when a user tries to connect a coach stripe account
   */
  const trackCoachConnectStripeClick = (
    props: SegmentEvents['Coach_ConnectStripe_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'Coach_ConnectStripe_Click',
      props,
    });
  };

  /** ********************************************************************
   * Home Page
   ********************************************************************* */
  /**
   * Tracks when a user clicks the Discover Experiences button on home page
   */
  const trackHomeDiscoverClick = (props: SegmentEvents['Home_Discover_Click']): void => {
    trackSegmentEvent({
      eventName: 'Home_Discover_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks the Become a Coach button on home page
   */
  const trackHomeBecomeACoachClick = (props: SegmentEvents['Home_BecomeACoach_Click']): void => {
    trackSegmentEvent({
      eventName: 'Home_BecomeACoach_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks the Become an Organizer button on home page
   */
  const trackHomeBecomeAnOrganizerClick = (
    props: SegmentEvents['Home_BecomeAnOrganizer_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'Home_BecomeAnOrganizer_Click',
      props,
    });
  };

  /** ********************************************************************
   * Events
   ********************************************************************* */
  /**
   * Tracks when a user clicks "Register" or "Book" on an event page
   */
  const trackEventRegisterClick = (props: SegmentEvents['Event_Register_Click']): void => {
    trackSegmentEvent({
      eventName: 'Event_Register_Click',
      props,
    });
    reportAlgoliaClickedObjectIDs({
      eventName: ALGOLIA_REGISTER_CLICKED,
      objectIds: [props.eventCuid],
    });
  };
  /**
   * Tracks when a user views a registration step
   */
  const trackEventRegistrationStepViewed = (
    props: SegmentEvents['EventRegistration_Step_Viewed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'EventRegistration_Step_Viewed',
      props,
    });
  };
  /**
   * Tracks when a user completes a registration step
   */
  const trackEventRegistrationStepCompleted = (
    props: SegmentEvents['EventRegistration_Step_Completed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'EventRegistration_Step_Completed',
      props,
    });
  };
  /**
   * Tracks when a user clicks "Finish" to either go to payment or complete reg if event is free
   */
  const trackEventRegistrationFinishClick = (
    props: SegmentEvents['EventRegistration_Finish_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'EventRegistration_Finish_Click',
      props,
    });
  };
  /**
   * Tracks when a user completes registration (successfully paid)
   */
  const trackEventRegistrationPaymentSuccess = (
    props: SegmentEvents['EventRegistration_Payment_Success'],
  ): void => {
    trackSegmentEvent({
      eventName: 'EventRegistration_Payment_Success',
      props,
    });
  };
  /** ********************************************************************
   * Host/Create Event
   ********************************************************************* */
  /**
   * Tracks when a user clicks finish on the create event workflow
   */
  const trackCreateEventFinishClick = (props: SegmentEvents['CreateEvent_Finish_Click']): void => {
    trackSegmentEvent({
      eventName: 'CreateEvent_Finish_Click',
      props,
    });
  };
  /**
   * Tracks when a section in Host Event becomes valid for the first time
   */
  const trackHostEventSectionCompleted = (
    props: SegmentEvents['HostEvent_Section_Completed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'HostEvent_Section_Completed',
      props,
    });
  };
  /**
   * Tracks when a user publishes an event
   */
  const trackHostEventPublishClick = (props: SegmentEvents['HostEvent_Publish_Click']): void => {
    trackSegmentEvent({
      eventName: 'HostEvent_Publish_Click',
      props,
    });
  };
  /**
   * Tracks when a user publishes an event
   */
  const trackHostEventUnPublishClick = (
    props: SegmentEvents['HostEvent_UnPublish_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'HostEvent_UnPublish_Click',
      props,
    });
  };

  /** ********************************************************************
   * Lessons
   ********************************************************************* */
  /**
   * Tracks when user clicks request lesson on coach page
   */
  const trackLessonRequestLessonClick = (
    props: SegmentEvents['Lesson_RequestLesson_Click'],
  ): void => {
    // dateToMixpanelFormat
    trackSegmentEvent({
      eventName: 'Lesson_RequestLesson_Click',
      props,
    });
    reportAlgoliaClickedObjectIDs({
      eventName: ALGOLIA_REGISTER_CLICKED,
      objectIds: [props.coachLessonCuid!],
    });
  };

  /** ********************************************************************
   * Online Courses
   ********************************************************************* */
  /**
   * Tracks when user views a step in the create online course flow
   */
  const trackCreateOnlineCourseStepViewed = (
    props: SegmentEvents['CreateOnlineCourse_Step_Viewed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'CreateOnlineCourse_Step_Viewed',
      props,
    });
  };
  /**
   * Tracks when user completes a step in the create online course flow
   */
  const trackCreateOnlineCourseStepCompleted = (
    props: SegmentEvents['CreateOnlineCourse_Step_Completed'],
  ): void => {
    trackSegmentEvent({
      eventName: 'CreateOnlineCourse_Step_Completed',
      props,
    });
  };
  /**
   * Tracks when a user finishes the create online course workflow
   */
  const trackCreateOnlineCourseFinishClick = (
    props: SegmentEvents['CreateOnlineCourse_Finish_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'CreateOnlineCourse_Finish_Click',
      props,
    });
  };
  /**
   * Tracks when a user clicks register on an online course
   */
  const trackOnlineCourseRegisterClick = (
    props: SegmentEvents['OnlineCourse_Register_Click'],
  ): void => {
    trackSegmentEvent({
      eventName: 'OnlineCourse_Register_Click',
      props,
    });
    reportAlgoliaClickedObjectIDs({
      eventName: ALGOLIA_REGISTER_CLICKED,
      objectIds: [props.onlineCourseCuid],
    });
  };
  /**
   * Tracks when a user completes payment for an online course
   */
  const trackOnlineCoursePaymentSuccess = (
    props: SegmentEvents['OnlineCourse_Payment_Success'],
  ): void => {
    trackSegmentEvent({
      eventName: 'OnlineCourse_Payment_Success',
      props,
    });
  };
  return {
    trackSearchHitClick,
    trackSiteErrorView,
    trackCoachConnectStripeClick,
    trackCreatorUpsellClick,
    trackCreateCoachFinishClick,
    trackCreateCoachStepCompleted,
    trackCreateCoachStepViewed,
    trackCreateEventFinishClick,
    trackCreateOnlineCourseFinishClick,
    trackCreateOnlineCourseStepCompleted,
    trackCreateOnlineCourseStepViewed,
    trackEventRegisterClick,
    trackEventRegistrationFinishClick,
    trackEventRegistrationPaymentSuccess,
    trackEventRegistrationStepCompleted,
    trackEventRegistrationStepViewed,
    trackHomeBecomeACoachClick,
    trackHomeBecomeAnOrganizerClick,
    trackHomeDiscoverClick,
    trackHostEventPublishClick,
    trackHostEventSectionCompleted,
    trackHostEventUnPublishClick,
    trackLessonRequestLessonClick,
    trackOnlineCoursePaymentSuccess,
    trackOnlineCourseRegisterClick,
    trackOrganizerConnectStripeClick,
    trackUserRequestEventRefundClick,
    trackUserRequestLessonRefundClick,
    trackUserSignUpViewed,
    trackUserSignUpWithEmailClick,
    trackDiscoverCategoryClick,
    trackDiscoverHitClick,
    trackCoachSectionEditClick,
    trackCoachSectionSaveClick,
  };
};
