import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import LoadingBounce from '@/ui/LoadingPlaceholders/LoadingBounce';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { RegistrationErrorTypes } from '@/containers/Registration/RegistrationSteps/RegistrationSteps';

export interface LoadingStatusDialogProps {
  open: boolean;
  onClose: () => void;
  status: 'loading' | 'error';
  errorType?: RegistrationErrorTypes | undefined;
  errors?: {
    [key: string]: ReactNode;
  };
  useLoadingBounce?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
  },
  container: {
    flexGrow: 1,
    textAlign: 'center',
    width: 300,
    minHeight: 150,
  },
  pleaseWait: {
    marginBottom: theme.spacing(4),
  },
}));

const LoadingStatusDialog: React.FC<LoadingStatusDialogProps> = ({
  errors,
  errorType,
  onClose,
  open,
  status,
  useLoadingBounce,
}) => {
  const classes = useStyles();

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle className={classes.title}>{status === 'error' && 'Error'}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" className={classes.container} justify="center">
          {status === 'loading' && (
            <Grid item>
              {useLoadingBounce ? (
                <LoadingBounce />
              ) : (
                <>
                  <LoadingPlaceholder isLoading size={48} verticalMargin="md" />
                  <Typography className={classes.pleaseWait} variant="body2">
                    Please wait, this may take a moment.
                  </Typography>
                </>
              )}
            </Grid>
          )}
          {status === 'error' && (
            <>
              {Object.entries(errors ?? []).map(([key, ErrorComponent]) => {
                if (key === errorType) {
                  return (
                    <Grid item key={key}>
                      {ErrorComponent}
                    </Grid>
                  );
                }
                return null;
              })}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {status === 'error' && (
          <Button autoFocus fullWidth color="primary" onClick={onClose} variant="outlined">
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LoadingStatusDialog;
