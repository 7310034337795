/**
 * Container page for coach profile page
 */
import React from 'react';
import { useParams } from 'react-router';

import Page from '../Page';

import MainLayout from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const CoachProfilePage: ReactFCC = ({ children }) => {
  const params = useParams();
  return (
    <Page description="A cycling coach on Reggy" title="Coach • Reggy">
      <MainLayout key={params.coachSlug} stickyHeader={false}>
        {children}
      </MainLayout>
    </Page>
  );
};

export default CoachProfilePage;
