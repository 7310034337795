/*
Route that has extra props on it for authentication, tracking, page title, etc
 */
import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteProps, useLocation } from 'react-router';

import ConditionalWrapper from '@/hoc/ConditionalWrapper';
import ProhibitLogin from '@/hoc/ProhibitLogin';
import RequireLogin from '@/hoc/RequireLogin';

import CheckRoutePermissions from '@/routes/components/CheckRoutePermissions';
import RouteAnalytics from '@/routes/components/RouteAnalytics';

import {
  useSetIsLoginDialogOpenGlobal,
  useSetIsLoginSignupDialogBlockingGlobal,
  useSetIsSignupDialogOpenGlobal,
  useSetLoginSignupDialogBlockingModeGlobal,
} from '@/containers/Auth/recoilStore';
import ENV from '@/env';
import { ReactFCC } from '@/lib/type-defs/utility';
import { AppRole } from '@/routes/route-metadata';

const EmptyComponent: ReactFCC = ({ children }) => <>{children}</>;

interface ExtendedRouteProps extends RouteProps {
  scrollToTop?: boolean;
  pageName?: string;
  // This flag should be used to track the lowest level route in the tree. We have to manually do
  // this because useParams only works on routes that have matched. Meaning we can't get param
  // info from any parent components.
  trackPageView?: boolean;
  loginRequired?: boolean;
  loginProhibited?: boolean;
  loginViaDialog?: boolean;
  blockWithSignup?: boolean;
  adminRequired?: boolean;
  children: ReactElement;
  validRoles?: readonly AppRole[];
}
const ExtendedRoute: React.FC<ExtendedRouteProps> = ({
  adminRequired = false,
  blockWithSignup = false,
  children,
  loginProhibited = false,
  loginRequired = false,
  loginViaDialog = false,
  scrollToTop = true,
  trackPageView = false,
  validRoles,
}) => {
  const location = useLocation();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const setIsLoginSignupDialogBlockingGlobal = useSetIsLoginSignupDialogBlockingGlobal();
  const setLoginSignupDialogBlockingModeGlobal = useSetLoginSignupDialogBlockingModeGlobal();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore keepScrollPosition exists
    if (scrollToTop && !location?.state?.keepScrollPosition) {
      window.scrollTo(0, 0);
    }
    // Close login/signup on route change
    setIsLoginDialogOpenGlobal(false);
    setIsSignupDialogOpenGlobal(false);
    setIsLoginSignupDialogBlockingGlobal(false);
  }, [JSON.stringify(location)]);

  useEffect(() => {
    if (blockWithSignup) {
      setLoginSignupDialogBlockingModeGlobal('signup');
    }
  }, [blockWithSignup]);

  const hasWrapperComponent = loginRequired || loginProhibited || adminRequired;
  let wrapperComponent = EmptyComponent;
  const wrapperProps: Record<string, unknown> = {};
  if (loginRequired || blockWithSignup || adminRequired) {
    wrapperComponent = RequireLogin;
    if (adminRequired) {
      wrapperProps.adminRequired = true;
    }
    if (loginViaDialog) {
      wrapperProps.viaDialog = true;
    }
  } else if (loginProhibited) {
    wrapperComponent = ProhibitLogin;
  }

  return (
    // Wrap our route in a blocker if login is required or login is prohibited
    <ConditionalWrapper
      WrapperComponent={wrapperComponent}
      condition={hasWrapperComponent}
      wrapperProps={wrapperProps}
    >
      <>
        {trackPageView && <RouteAnalytics />}
        <CheckRoutePermissions validRoles={validRoles}>
          <Helmet meta={[{ property: 'og:url', content: `${ENV.APP_URL}${location.pathname}` }]}>
            <link href={`${ENV.APP_URL}${location.pathname}`} rel="canonical" />
          </Helmet>
          {children}
        </CheckRoutePermissions>
      </>
    </ConditionalWrapper>
  );
};

// {React.cloneElement(children, {
//   disableGlobalTopNav,
//   disableGlobalSideNav,
//   disableGlobalBottomNav,
// })}
//
export default ExtendedRoute;
