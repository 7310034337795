/*
Constants for the whole of Host Event
 */
/* eslint-disable typescript-sort-keys/string-enum */

export const HEADER_HEIGHT = 75;

export const MIN_TABLE_ROWS = 7;

export const SCROLL_TO_SECTION_QUERY_PARAM = 'scrollTo';

export enum SectionHelpJourneyUrlEnum {
  Intro = 'https://reggy.journey.io/e/bb5a0e4bea59444db89fae1be45978a7',
  GeneralCategoried = 'https://reggy.journey.io/e/1eeed4fcf9fa4fb8bd785f989f46ac25',
  GeneralNonCategoried = 'https://reggy.journey.io/e/ca959412f452405292966daaa08bda0c',
  LocationContact = 'https://reggy.journey.io/e/a19d81864d21446ab205c42eed762cb3',
  Courses = 'https://reggy.journey.io/e/35b1903e90374cd88d4b9ef11bc29c9a',
  Program = 'https://reggy.journey.io/e/ca86dd4c2d564d038011d3fccb5888f1',
  Checklist = 'https://reggy.journey.io/e/2366d966094c4b23847b5eb14e5abcc0',
  Amenities = 'https://reggy.journey.io/e/12fd74e01f8f4c068fdaea09707b7017',
  Series = 'https://reggy.journey.io/e/12fd74e01f8f4c068fdaea09707b7017',
}

export type HostEventSectionName =
  | 'Intro'
  | 'General'
  | 'Location/Contact'
  | 'Course'
  | 'Checklist'
  | 'Program'
  // | 'Assessment'
  | 'Vibe'
  | 'Categories'
  | 'Series'
  | 'Sanction'
  // | 'Volunteers'
  | 'Tickets'
  // | 'Merchandise'
  | 'Registration'
  // | 'Pricing'
  | 'Schedule'
  | 'Waiver'
  | 'Images'
  | 'Sponsors'
  | 'Amenities'
  | 'Clinic Dates'
  | 'Ride Dates'
  | 'Additional Info'
  | 'Finish';

// Order of this list determines order on page
export const hostClinicSectionsOrdered: HostEventSectionName[] = [
  'Intro',
  'General',
  'Location/Contact',
  'Amenities',
  'Program',
  // 'Assessment',
  'Checklist',
  'Series',
  'Sponsors',
  'Waiver',
  'Registration',
  'Images',
  'Clinic Dates',
  'Additional Info',
  'Finish',
];
// Order of this list determines order on page
export const hostClinicVirtualSectionsOrdered: HostEventSectionName[] = [
  'Intro',
  'General',
  'Location/Contact',
  'Program',
  // 'Assessment',
  'Sponsors',
  'Waiver',
  'Registration',
  'Images',
  'Clinic Dates',
  'Additional Info',
  'Finish',
];

// Order of this list determines order on page
export const hostCategoriedRideSectionsOrdered: HostEventSectionName[] = [
  'Intro',
  'General',
  'Location/Contact',
  'Amenities',
  'Vibe',
  'Course',
  'Categories',
  'Checklist',
  'Tickets',
  'Sanction',
  'Series',
  'Sponsors',
  'Waiver',
  'Registration',
  'Schedule',
  'Images',
  'Additional Info',
  'Finish',
];

// Order of this list determines order on page
export const hostNonCategoriedRideSectionsOrdered: HostEventSectionName[] = [
  'Intro',
  'General',
  'Location/Contact',
  'Amenities',
  'Vibe',
  'Checklist',
  'Course',
  'Series',
  'Sponsors',
  'Waiver',
  'Registration',
  'Images',
  'Ride Dates',
  'Additional Info',
  'Finish',
];

// Order of this list determines order on page
export const hostRaceSectionsOrdered: HostEventSectionName[] = [
  'Intro',
  'General',
  'Location/Contact',
  'Amenities',
  'Course',
  'Categories',
  'Checklist',
  'Tickets',
  'Sanction',
  'Series',
  'Sponsors',
  'Waiver',
  'Registration',
  'Schedule',
  'Images',
  'Additional Info',
  'Finish',

  // 'Volunteers',
  // 'Merchandise',
  // 'Pricing',
];
// Future
// // What chat channels get added when an organizer creates a event?
// export const DEFAULT_EVENT_CHANNELS: object[] = [
//   // General
//   {
//     name: 'General',
//     permanent: true,
//     position: 0,
//     topic: 'This channel is for question/comments/carpools and anything else you can think of.',
//   },
//   {
//     name: 'Event Updates',
//     permanent: true,
//     position: 1,
//     topic:
//       'The event organizer will post any and all updates to the event here. i.e. Category changes, weather delays, etc. You can subscribe to notifications to see what is happening.',
//   },
//   {
//     name: 'Support',
//     permanent: true,
//     position: 2,
//     topic: 'Have a question about the event? Ask it here!',
//   },
//   // Registered Racers
//   {
//     categoryName: 'Racers',
//     name: 'Racers',
//     permanent: true,
//     position: 0,
//     private: true,
//     topic: 'This channel is for all the racers who have registered for this event!',
//   },
//   // Staff
//   {
//     categoryName: 'Staff',
//     name: 'Staff General',
//     permanent: true,
//     position: 0,
//     private: true,
//   },
//   {
//     categoryName: 'Staff',
//     name: 'Staff Tasks',
//     position: 1,
//     permanent: true,
//     private: true,
//   },
// ];
