/**
 * Close button for a dialog
 */
import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  closeBtn: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
  },
  smallCloseBtn: {
    position: 'absolute !important' as 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: '3px',
  },
}));

interface DialogCloseButtonProps {
  handleClose: () => void;
  small?: boolean;
  iconButtonProps?: IconButtonProps;
}
const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({
  handleClose,
  iconButtonProps,
  small,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="close"
      className={classNames({ [classes.closeBtn]: !small }, { [classes.smallCloseBtn]: small })}
      onClick={handleClose}
      {...iconButtonProps}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default DialogCloseButton;
