import React from 'react';
import {
  InputLabel as InputLabelBase,
  InputLabelProps as InputLabelBaseProps,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

export interface InputLabelProps extends InputLabelBaseProps {
  readOnly?: boolean;
  inline?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold as number,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(0.5)}px`,
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  readOnly: {
    textTransform: 'uppercase',
    fontSize: '.875rem',
    color: theme.palette.grey[500],
  },
  error: {
    color: theme.palette.error.main,
  },
  inline: {
    display: 'inline-block',
  },
}));

const InputLabel: React.FC<InputLabelProps> = ({
  children,
  className,
  error,
  inline = false,
  readOnly = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <InputLabelBase
      {...props}
      className={classNames(
        classes.root,
        { [classes.readOnly]: readOnly, [classes.inline]: inline, [classes.error]: error },
        className,
      )}
    >
      {children}
    </InputLabelBase>
  );
};

export default InputLabel;
