import { Theme } from '@material-ui/core';

import { darken } from '@/themes/colors';

export const MuiFilledInput = (theme: Theme): Record<string, unknown> => ({
  root: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#F4F4F4',
    // backgroundColor: theme.palette.grey.A100,
    // backgroundColor: '#f4f8fb',
    color: 'black',
    '&:hover': {
      backgroundColor: '#EAEAEA',
      // backgroundColor: darken('#f4f8fb', 0.03),
    },
    '&.Mui-focused': {
      backgroundColor: '#EAEAEA',
      // backgroundColor: darken('#f4f8fb', 0.03),
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-filled.MuiInputAdornment-positionStart.MuiInputAdornment-marginDense': {
      marginTop: 0,
    },
  },
  inputMarginDense: {
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
  },
  multiline: {
    '&.MuiFilledInput-marginDense': {
      paddingTop: '11.5px',
      paddingBottom: '11.5px',
    },
  },
  inputMultiline: {
    '&.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  underline: {
    '&:not(.Mui-error):after, &:not(.Mui-error):before': {
      display: 'none !important',
    },
  },
});
