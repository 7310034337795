import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import HeaderContainer from '@/containers/HostEvent/Navigation/HeaderContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.pageBackground.grey,
    minHeight: '100vh',
  },
  content: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const HostEventLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <HeaderContainer />
        {children}
      </Box>
    </Box>
  );
};

export default HostEventLayout;
