/**
 * Create Event Mad Libs - Organizer App
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import cuid from 'cuid';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { graphql } from 'relay-runtime';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import FormRainbowDatePicker from '@/ui/Date/FormRainbowDatePicker';
import ExplainerPopover from '@/ui/ExplainerPopover';
import ExtendedLink from '@/ui/ExtendedLink';
import FormSelect from '@/ui/TextField/FormSelect';
import FormTextField from '@/ui/TextField/FormTextField';

import LoadingStatusDialog from '@/components/Dialogs/LoadingStatusDialog';

import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import { useTrackSentryError } from '@/analytics/sentry';
import { MAX_EVENT_NAME_LENGTH, NON_EXISTENT_CUID } from '@/const';
import { CreateEventMadLibsQuery } from '@/containers/Organizer/CreateEvent/__generated__/CreateEventMadLibsQuery.graphql';
import ClinicDayDuration, {
  ClinicDayDurationType,
} from '@/containers/Organizer/CreateEvent/ClinicDayDuration/ClinicDayDuration';
import { useGetOrganizerSlugFromURL } from '@/containers/Organizer/helpers';
import { ActivityTypeEnum } from '@/graphql/__generated__/graphql';
import { dateWithoutTime, formatToISODateString } from '@/lib/date-helpers/date-utils';
import { isActivityClinic, isActivityRace, isActivityRide } from '@/lib/event-info-utils';
import {
  createCategoriedRideCF,
  createClinicCF,
  createNonCategoriedRideCF,
  createRaceCF,
  getValidEventMetadataSlugCF,
} from '@/lib/firebase';
import { CreateEventFormSchema } from '@/lib/form-schema/organizer';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { activityType_enum } from '@/providers/__generated__/AuthProviderQuery.graphql';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  largeFont: {
    '& *:not(.MuiFormHelperText-root):not(.cat-explainer)': {
      fontSize: '1.3rem',
    },
    '& *:not(.MuiInput-input)': {
      // whiteSpace: 'nowrap',
    },
  },
  card: {
    alignItems: 'center',
    border: '2px solid rgb(219, 222, 225)',
    boxShadow: theme.shadows[16],
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    background: 'white',
    padding: theme.spacing(2),
    // minHeight: '94px',
    // '&:hover': {
    //   boxShadow: theme.shadows[2],
    // },
  },
}));

export type IsCategoriedEventOption = 'does' | 'does not';
export const IS_CATEGORIED_EVENT: IsCategoriedEventOption = 'does';
export const IS_NOT_CATEGORIED_EVENT: IsCategoriedEventOption = 'does not';

interface CreateEventFormSchemaProps {
  activity: activityType_enum;
  // For errors only
  clinicDayDurations: unknown;
  dateRange: Date[];
  isCategoriedEvent: IsCategoriedEventOption | undefined;
  name: string;
  numberOfDays: number;
}
const CreateEventMadLibs: React.FC = () => {
  const organizerSlug = useGetOrganizerSlugFromURL();
  const organizerData = useLazyLoadQuery<CreateEventMadLibsQuery>(
    graphql`
      query CreateEventMadLibsQuery($organizerSlug: String!) {
        organizer_connection(where: { slug: { _eq: $organizerSlug } }) {
          edges {
            node {
              cuid
              defaultCurrencyCode
            }
          }
        }
      }
    `,
    { organizerSlug },
  ).organizer_connection.edges[0].node;

  const formMethods = useForm<CreateEventFormSchemaProps>({
    defaultValues: {
      activity: getParsedQueryParams()?.activity as activityType_enum,
    },
    resolver: yupResolver(CreateEventFormSchema),
    reValidateMode: 'onSubmit',
    shouldUnregister: true,
  });
  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setError,
    setValue,
    trigger,
    watch,
  } = formMethods;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const trackSentryError = useTrackSentryError();
  const isSmallScreenDown = useIsSmallScreenDown();
  const { trackCreateEventFinishClick } = useSegmentTrack();
  const [isLoading, setIsLoading] = useState(false);
  const [clinicDayDurations, setClinicDayDurations] = useState<ClinicDayDurationType[]>([]);

  const watchActivity = watch('activity');
  const watchNumberOfDays = watch('numberOfDays');
  const watchIsCategoriedEvent = watch('isCategoriedEvent');
  const isRide = isActivityRide(watchActivity);
  const isRace = isActivityRace(watchActivity);
  const isClinic = isActivityClinic(watchActivity);
  const isCategoriedEvent = watchIsCategoriedEvent === IS_CATEGORIED_EVENT;

  /**
   * Clear errors on change
   */
  useEffect(() => {
    clearErrors('clinicDayDurations');
  }, [clinicDayDurations.length]);

  /**
   * Set categoried event on activity change
   */
  useEffect(() => {
    if (isClinic) {
      setValue('isCategoriedEvent', IS_NOT_CATEGORIED_EVENT);
    } else if (isRace) {
      setValue('isCategoriedEvent', IS_CATEGORIED_EVENT);
    } else if (isRide) {
      setValue('isCategoriedEvent', undefined);
    }
  }, [isRide, isRace, isClinic]);

  /**
   * Create event
   */
  const handleCreateEvent = async (): Promise<void> => {
    // Trigger first so we can also validate the clinicDayDurations
    await trigger();
    if (isActivityClinic(watchActivity) && clinicDayDurations.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - ErrorOption - We only want to know if there are errors, not the actual values
      setError('clinicDayDurations', true);
      return;
    }

    // Call handleSubmit
    await handleSubmit(async (data) => {
      try {
        setIsLoading(true);
        const response = await getValidEventMetadataSlugCF({
          eventName: data.name,
          eventMetadataCuid: NON_EXISTENT_CUID,
          organizerCuid: organizerData.cuid,
        });
        const newEventCuid = cuid();

        trackCreateEventFinishClick({
          organizerCuid: organizerData.cuid,
          activity: watchActivity,
          isCategoriedEvent,
          eventCuid: newEventCuid,
          startDate: data.dateRange ? formatToISODateString(data.dateRange[0]) : undefined,
          title: data.name,
        });
        if (isCategoriedEvent) {
          const categoriedData = {
            cuid: newEventCuid,
            // Validated by RHF
            endDate: formatToISODateString(data.dateRange?.[1] || data.dateRange[0]),
            name: data.name,
            organizerCuid: organizerData.cuid,
            slug: response.data.slug,
            defaultCurrencyCode: organizerData.defaultCurrencyCode,
            // Validated by RHF
            startDate: formatToISODateString(data.dateRange[0]),
          };
          if (isRace) {
            await createRaceCF(categoriedData);
          }
          if (isRide) {
            await createCategoriedRideCF(categoriedData);
          }
        } else if (!isCategoriedEvent) {
          const nonCategoriedData = {
            cuid: newEventCuid,
            name: data.name,
            organizerCuid: organizerData.cuid,
            defaultCurrencyCode: organizerData.defaultCurrencyCode,
            slug: response.data.slug,
          };
          if (isClinic) {
            await createClinicCF({
              ...nonCategoriedData,
              eventClinicDayDurations: clinicDayDurations,
            });
          }
          if (isRide) {
            await createNonCategoriedRideCF({
              ...nonCategoriedData,
              numberOfDays: data.numberOfDays,
            });
          }
        }
        // Success
        window.location.href = AppRouteService.getAbsoluteUrl('OrganizerApp_EditEvent', {
          organizerSlug,
          eventCuid: newEventCuid,
        });
      } catch (err) {
        trackSentryError(err);
        if (err.details?.[0].extensions.path === '$.selectionSet.insert_event_one.args.object[0]') {
          enqueueSnackbar('Event exists. Please refresh the page and try again.', {
            variant: 'error',
          });
        }
        enqueueSnackbar('Unable to create event. Please try again or contact us!', {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    <Grid container alignItems="center" justify="center" spacing={4}>
      <LoadingStatusDialog
        useLoadingBounce
        onClose={(): void => undefined}
        open={isLoading}
        status="loading"
      />
      <Grid item xs={12}>
        <Box className={classes.card}>
          {!watchActivity && (
            <Box display="flex" justifyContent="center" width="100%">
              <Typography align="center" style={{ wordBreak: 'break-word' }} variant="body2">
                Select an activity to see if it fits your needs!
              </Typography>
            </Box>
          )}
          {isClinic && (
            <div>
              <Typography gutterBottom style={{ wordBreak: 'break-word' }} variant="body2">
                <b>Clinic</b> - Share your expertise with cyclists
              </Typography>
              <Typography style={{ wordBreak: 'break-word' }} variant="caption">
                <ul>
                  <li>
                    This could be a Virtual Q & A with a Maxxis Tires, Women's Jumping Clinic, or
                    How to Take MTB Photos in-peron or virtually.
                  </li>
                  <li>
                    We call this a 'Clinic' but it's really about connecting with people over your
                    expertise!
                  </li>
                  <li>Good for creators who want to do something virtually or teach in person.</li>
                  <li>
                    <i>Templated (Non-Categoried)</i> - You create your clinic once and then people
                    choose the date they want to attend.
                  </li>
                </ul>
              </Typography>
            </div>
          )}
          {isRide && (
            <div>
              <Typography gutterBottom style={{ wordBreak: 'break-word' }} variant="body2">
                <b>Ride</b> - In-person cycling events/meet-ups
              </Typography>
              <Typography style={{ wordBreak: 'break-word' }} variant="caption">
                <ul>
                  <li>
                    Rides can be anything from a large structured Gran Fondo to a casual "Coffee and
                    Ride" meet-up with friends.
                  </li>
                  <li>Good for creators who want to do anything in-person on the bike.</li>
                  <li>
                    <i>Templated (Non-Categoried)</i> - Typically for smaller/less structured rides.
                    You create your ride once and then people choose the date they want to attend.
                  </li>
                  <Typography variant="caption">
                    <b>or</b>
                  </Typography>
                  <li>
                    <i>Occurrences (Categoried)</i> - Typically for larger/structured rides. You
                    create your ride and people choose a category to sign up for (i.e. Cat 1 30-39
                    or Women's 100 Mi). You can easily duplicate the event to keep the same url for
                    next for next time (e.g. 2021 -&gt; 2022)
                  </li>
                </ul>
              </Typography>
            </div>
          )}
          {isRace && (
            <div>
              <Typography gutterBottom style={{ wordBreak: 'break-word' }} variant="body2">
                <b>Race</b> - A timed competition
              </Typography>
              <Typography style={{ wordBreak: 'break-word' }} variant="caption">
                <ul>
                  <li>
                    These are traditional races. Think National Championships, California Enduro
                    Series, or a Crit Race.
                  </li>
                  <li>
                    Typically, difference between a race and a ride is everyone competing, rather
                    than participating, in a race. Think Belgium Waffle <i>Ride</i> vs MTB World Cup{' '}
                    <i>Race</i>
                  </li>
                  <li>Good for creators who want to host a competitive cycling experience.</li>
                  <li>
                    <i>Occurrences (Categoried)</i> - You create your race and people choose a
                    category to sign up for (i.e. Cat 1 30-39 or Women's 100 Mi). You can easily
                    duplicate the event to keep the same url for next time (e.g. 2021 -&gt; 2022)
                  </li>
                </ul>
              </Typography>
            </div>
          )}
        </Box>
      </Grid>
      <Grid item className={classes.largeFont} style={{ maxWidth: 600 }} xs={12}>
        <Box alignItems="baseline" display="flex">
          <Typography>I am hosting a </Typography>
          <Box display="flex" ml={1}>
            <FormSelect
              error={!!errors.activity}
              formMethods={formMethods}
              margin="dense"
              minWidth="tiny"
              name="activity"
              onChange={(): void => clearErrors('activity')}
            >
              {Object.values(ActivityTypeEnum).map((option) => {
                return (
                  <MenuItem key={`discipline-select-${option}`} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </FormSelect>
          </Box>
        </Box>
        {errors.activity?.message && (
          <Box mt={1}>
            <FormHelperText error>{errors.activity?.message}</FormHelperText>
          </Box>
        )}
        {isRide && (
          <>
            <Box
              alignItems="baseline"
              display="flex"
              flexWrap={isSmallScreenDown ? 'wrap' : undefined}
            >
              <Typography>that</Typography>
              <Box display="flex" mx={1}>
                <FormSelect
                  error={!!errors.isCategoriedEvent}
                  formMethods={formMethods}
                  margin="dense"
                  minWidth="tiny"
                  name="isCategoriedEvent"
                  onChange={(): void => clearErrors('isCategoriedEvent')}
                >
                  <MenuItem value={IS_CATEGORIED_EVENT}>does</MenuItem>
                  <MenuItem value={IS_NOT_CATEGORIED_EVENT}>does not</MenuItem>
                </FormSelect>
              </Box>
              <Box display="flex">
                <Typography component="span">have categories</Typography>
                <ExplainerPopover clickToOpen boxProps={{ className: 'cat-explainer', mt: '4px' }}>
                  <Typography
                    className="cat-explainer"
                    style={{ marginLeft: '4px' }}
                    variant="body2"
                  >
                    A ride that <b>DOES NOT</b> have categories is typically for smaller/less
                    structured rides. e.g. Local Wednesday group ride.
                    <br />A ride that <b>DOES</b> have categories is typically for larger/structured
                    rides. e.g.{' '}
                    <ExtendedLink
                      shouldOpenInNewTab
                      showOpenInNewIcon
                      href="https://belgianwaffleride.bike/"
                    >
                      Belgium Waffle Ride
                    </ExtendedLink>
                  </Typography>
                </ExplainerPopover>
              </Box>
            </Box>
            {errors.isCategoriedEvent?.message && (
              <Box mt={1}>
                <FormHelperText error>{errors.isCategoriedEvent?.message}</FormHelperText>
              </Box>
            )}
          </>
        )}
        <Box alignItems="baseline" display="flex">
          <Typography>named </Typography>
          <Box display="flex" ml={1} width="100%">
            <FormTextField
              fullWidth
              multiline
              control={control}
              error={!!errors.name}
              getValues={getValues}
              inputProps={{ maxLength: MAX_EVENT_NAME_LENGTH }}
              margin="dense"
              minWidth="sm"
              name="name"
              onChange={(): void => clearErrors('name')}
              placeholder={
                isRace
                  ? 'Downhill Southeast #1 - Windrock Bike Park'
                  : isClinic
                  ? 'Sweetlines Beginner Jumping Skills at Duthie'
                  : isRide && !isCategoriedEvent
                  ? 'Sunday Morning Malibu Group Ride'
                  : isRide && isCategoriedEvent
                  ? 'Mammoth Tuff Gravel Ride'
                  : ''
              }
              rowsMax={Infinity}
            />
          </Box>
        </Box>
        {errors.name?.message && (
          <Box mt={1}>
            <FormHelperText error>{errors.name?.message}</FormHelperText>
          </Box>
        )}
        {isRide && !isCategoriedEvent && (
          <>
            <Box alignItems="baseline" display="flex">
              <Typography>that happens over</Typography>
              <Box display="flex" mx={1}>
                <FormSelect
                  error={!!errors.numberOfDays}
                  formMethods={formMethods}
                  margin="dense"
                  // minWidth="tiny"
                  name="numberOfDays"
                  onChange={(): void => clearErrors('numberOfDays')}
                >
                  {R.range(1, 8).map((day) => (
                    <MenuItem key={`day-select-${day}`} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Box>
              <Typography>{pluralize('day', watchNumberOfDays)}</Typography>
            </Box>
            {errors.numberOfDays?.message && (
              <Box mt={1}>
                <FormHelperText error>{errors.numberOfDays?.message}</FormHelperText>
              </Box>
            )}
            <Box mt={1}>
              <FormHelperText style={{ color: Colors.Warning }}>
                After you create your event, you will not be able to change the number of days.
              </FormHelperText>
            </Box>
          </>
        )}
        {isClinic && (
          <Box display={isSmallScreenDown ? '' : 'flex'}>
            <Box display="flex" mr={0.5} position="relative" top={10}>
              <Typography noWrap={!isSmallScreenDown}>that happens over</Typography>
            </Box>
            <Box ml={1.5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {(clinicDayDurations ?? []).map((clinicDuration, index) => (
                    <ClinicDayDuration
                      clinicDayDuration={clinicDuration}
                      isFirstItem={index === 0}
                      key={clinicDuration.cuid}
                      setClinicDayDurations={setClinicDayDurations}
                    />
                  ))}
                  <ClinicDayDuration
                    hasError={!!errors.clinicDayDurations}
                    isFirstItem={clinicDayDurations.length === 0}
                    setClinicDayDurations={setClinicDayDurations}
                  />
                </Grid>
              </Grid>
              <FormHelperText style={{ color: Colors.Warning }}>
                After you create your event, you can change the duration of a day but not the total
                number of days.
              </FormHelperText>
            </Box>
          </Box>
        )}
        {isCategoriedEvent && (
          <Box alignItems="baseline" display="flex">
            <Typography noWrap>on the dates</Typography>
            <Box display="flex" minWidth={288} ml={1}>
              <FormRainbowDatePicker
                allowSameDate
                hideLabel
                underlinedInput
                error={errors.dateRange?.message}
                formMethods={formMethods}
                minDate={dateWithoutTime()}
                name="dateRange"
                selectionType="range"
              />
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs="auto">
        <ExtendedButton
          color="primary"
          isLoading={isLoading}
          minWidth="xs"
          onClick={handleCreateEvent}
          size="large"
          variant="contained"
        >
          Create {watchActivity}
        </ExtendedButton>
      </Grid>
    </Grid>
  );
};

export default CreateEventMadLibs;
