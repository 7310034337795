/*
Conditionally wrap a component
 */
import React from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  WrapperComponent: React.ComponentType<any> | string;
  wrapperProps?: { [x: string]: unknown };
  children?: React.ReactNode;
}

const ConditionalWrapper = React.forwardRef<any, ConditionalWrapperProps>(
  ({ children, condition, WrapperComponent, wrapperProps }, ref) => {
    return (
      <>
        {condition ? (
          <WrapperComponent {...wrapperProps} ref={ref}>
            {children}
          </WrapperComponent>
        ) : (
          children
        )}
      </>
    );
  },
);

export default ConditionalWrapper;
