import React from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';

import BrandTypography from '@/ui/BrandTypography';

import { useRegistrationLayoutContext } from './RegistrationLayoutProvider';

import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useGetEventProfileNameGlobal } from '@/pages/EventProfile/recoilStore';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.pageBackground.white,
    '& a:not(#add-promo-code)': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  grow: {
    flexGrow: 1,
  },
  chevronLeftIcon: {
    verticalAlign: 'middle',
    marginBottom: 4,
  },
  mobileTitle: {
    minWidth: 0,
    textAlign: 'center',
    flexBasis: '80%',
  },
  mobileIconBtnContainer: {
    flex: '0 0 40px',
  },
  mobileNextButtonContainer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.pageBackground.white,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const RegistrationLayout: ReactFCC = ({ children }) => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const RLC = useRegistrationLayoutContext();
  const isMobileView = useIsSmallScreenDown();
  const { eventSlug, organizerSlug } = useParams();
  const eventProfileNameGlobal = useGetEventProfileNameGlobal();

  const isDesktopView = !isMobileView;
  const occurrence = searchParams.get('occurrence');
  const queryId = searchParams.get('queryId');
  const eventLink = AppRouteService.getRelativeUrl(
    'OrganizerProfile_Event',
    {
      organizerSlug,
      eventSlug,
    },
    { ...(occurrence ? { occurrence } : {}), ...(queryId ? { queryId } : {}) },
  );
  const goToEvent = (): void => navigate(eventLink);

  return (
    <Box className={classes.root}>
      <AppBar
        className={classNames(headerClasses.appBar, classes.appBar)}
        color="inherit"
        position="relative"
      >
        <Toolbar>
          {isDesktopView && (
            <Container>
              <Grid container>
                <Grid item>
                  <BrandTypography noLink variant="h5">
                    Reggy
                  </BrandTypography>
                </Grid>
                <Grid item className={classes.grow} />
                <Grid item>
                  <Link to={eventLink}>
                    <ChevronLeftIcon className={classes.chevronLeftIcon} />
                    {eventProfileNameGlobal}
                  </Link>
                </Grid>
              </Grid>
            </Container>
          )}
          {/* Mobile Nav */}
          {isMobileView && (
            <Grid container wrap="nowrap">
              <Grid
                container
                item
                alignItems="center"
                className={classes.mobileIconBtnContainer}
                direction="column"
                justify="center"
              >
                <Grid item>{RLC.BackButton}</Grid>
              </Grid>
              <Grid item className={classNames(classes.grow, classes.mobileTitle)}>
                <Typography variant="h4">Registration</Typography>
                <Typography noWrap variant="subtitle2">
                  {eventProfileNameGlobal}
                </Typography>
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                className={classes.mobileIconBtnContainer}
                direction="column"
                justify="center"
              >
                <Grid item>
                  <IconButton onClick={goToEvent} size="small">
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Container>{children}</Container>
      {/* Mobile Bottom Nav */}
      {isMobileView && (
        <Box className={classes.mobileNextButtonContainer}>
          <Toolbar>{RLC.NextButton}</Toolbar>
        </Box>
      )}
    </Box>
  );
};

export default RegistrationLayout;
