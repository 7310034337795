/*
Hook used for publishing our event. Used at the header and on the finish

The error just outlines the help expansion panel.
The panel already has the requirements on it so no need to show more than that
 */

import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import { useSnackbar } from 'notistack';
import { RecordSourceProxy } from 'relay-runtime/lib/store/RelayStoreTypes';

import {
  useGetEncodedEventId,
  useGetEventCuid,
  useGetEventValidity,
  useGetOrganizerCuid,
  useToggleIsEventSavingGlobal,
  useUpdateAlgoliaSearchIndexes,
} from './helpers';

import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import { hostEventMutations_publishEvent_Mutation } from '@/containers/HostEvent/__generated__/hostEventMutations_publishEvent_Mutation.graphql';
import { hostEventMutations_unPublishEvent_Mutation } from '@/containers/HostEvent/__generated__/hostEventMutations_unPublishEvent_Mutation.graphql';
import { hostEventMutations_updateCompletedSections_Mutation } from '@/containers/HostEvent/__generated__/hostEventMutations_updateCompletedSections_Mutation.graphql';
import { hostEventMutations_useUnPublishEventMutation_event$key } from '@/containers/HostEvent/__generated__/hostEventMutations_useUnPublishEventMutation_event.graphql';
import { HostEventSectionName } from '@/containers/HostEvent/const';
import { useHostEventData } from '@/providers/HostEventProvider';

export const useUpdateCompletedSectionsMutation = (): ((
  completedSections: HostEventSectionName[],
) => void) => {
  const eventCuid = useGetEventCuid();
  const encodedEventId = useGetEncodedEventId();
  const [commitMutation] = useMutation<hostEventMutations_updateCompletedSections_Mutation>(
    graphql`
      mutation hostEventMutations_updateCompletedSections_Mutation(
        $eventCuid: String!
        $completedSections: jsonb!
      ) {
        update_event(
          where: { cuid: { _eq: $eventCuid } }
          _set: { completedSections: $completedSections, lastSavedAt: "now()" }
        ) {
          affected_rows
        }
      }
    `,
  );
  return (completedSections: HostEventSectionName[]): void => {
    const sharedUpdater = (store: RecordSourceProxy): void => {
      const eventRecord = store.get<Event>(encodedEventId);
      if (!eventRecord) {
        return;
      }
      // Relay doesn't support setting non-primitive values: https://github.com/facebook/relay/issues/2441
      eventRecord.setValue(JSON.stringify(completedSections), 'completedSections');
    };
    commitMutation({
      variables: { eventCuid, completedSections },
      optimisticUpdater: sharedUpdater,
      updater: sharedUpdater,
    });
  };
};

export const usePublishEventMutation = (): (() => void) => {
  const eventCuid = useGetEventCuid();
  const organizerCuid = useGetOrganizerCuid();
  const { trackHostEventPublishClick } = useSegmentTrack();
  const { isEventValid } = useGetEventValidity();
  const updateAlgoliaIndexes = useUpdateAlgoliaSearchIndexes();
  const [
    commitMutation,
    isPublishEventMutationInFlight,
  ] = useMutation<hostEventMutations_publishEvent_Mutation>(
    graphql`
      mutation hostEventMutations_publishEvent_Mutation($eventCuid: String!) {
        update_event(
          where: { cuid: { _eq: $eventCuid } }
          _set: { publishedAt: "now()", isEventCreationComplete: true, lastSavedAt: "now()" }
        ) {
          returning {
            id
            lastSavedAt
            publishedAt
            isEventCreationComplete
          }
        }
      }
    `,
  );
  useToggleIsEventSavingGlobal(isPublishEventMutationInFlight);
  return (): void => {
    if (isEventValid) {
      // Publish event!
      commitMutation({
        variables: { eventCuid },
        onCompleted: (): void => {
          trackHostEventPublishClick({
            eventCuid,
            organizerCuid,
          });
          // Update Algolia Event index
          updateAlgoliaIndexes(eventCuid);
        },
      });
    }
  };
};

export const useUnPublishEventMutation = (): (() => void) => {
  const hostEventData = useHostEventData();
  const eventData = useFragment<hostEventMutations_useUnPublishEventMutation_event$key>(
    graphql`
      fragment hostEventMutations_useUnPublishEventMutation_event on event {
        registrations(
          where: {
            _and: [
              { _or: [{ status: { _eq: complete } }, { status: { _eq: canceled } }] }
              { event: { deleted_at: { _is_null: true } } }
            ]
          }
        ) {
          userId
        }
      }
    `,
    hostEventData,
  );
  const eventCuid = useGetEventCuid();
  const { enqueueSnackbar } = useSnackbar();
  const organizerCuid = useGetOrganizerCuid();
  const { trackHostEventUnPublishClick } = useSegmentTrack();
  const updateAlgoliaIndexes = useUpdateAlgoliaSearchIndexes();
  const hasRegistrations = eventData.registrations.length > 0;
  const [
    commitMutation,
    isUnPublishEventMutationInFlight,
  ] = useMutation<hostEventMutations_unPublishEvent_Mutation>(
    graphql`
      mutation hostEventMutations_unPublishEvent_Mutation($eventCuid: String!) {
        update_event(
          where: { cuid: { _eq: $eventCuid } }
          _set: { publishedAt: null, lastSavedAt: "now()" }
        ) {
          returning {
            id
            lastSavedAt
            publishedAt
          }
        }
      }
    `,
  );
  useToggleIsEventSavingGlobal(isUnPublishEventMutationInFlight);
  return (): void => {
    if (hasRegistrations) {
      enqueueSnackbar(
        'Cannot un-publish event with registrations. Cyclists need to be able to access event data.',
        { variant: 'error' },
      );
      return;
    }
    // Un-Publish event :(
    commitMutation({
      variables: { eventCuid },
      onCompleted: (): void => {
        trackHostEventUnPublishClick({
          eventCuid,
          organizerCuid,
        });
        // Update Algolia Event index
        updateAlgoliaIndexes(eventCuid);
      },
    });
  };
};
//
// /**
//  * Has the user completed the event creation process?
//  */
// export const useUpdateIsEventCreationCompleteMutation = (): (() => void) => {
//   const eventCuid = useGetEventCuid();
//   const [commitMutation] = useMutation<hostEventMutations_updateIsEventCreationComplete_Mutation>(
//     graphql`
//       mutation hostEventMutations_updateIsEventCreationComplete_Mutation($eventCuid: String!) {
//         update_event(
//           where: { cuid: { _eq: $eventCuid } }
//           _set: { isEventCreationComplete: true, lastSavedAt: "now()" }
//         ) {
//           returning {
//             id
//             lastSavedAt
//             isEventCreationComplete
//           }
//         }
//       }
//     `,
//   );
//   return (): void => {
//     commitMutation({
//       variables: { eventCuid },
//     });
//   };
// };
