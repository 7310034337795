import React, { ReactElement, useState } from 'react';
import { BoxProps, SvgIconProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CreateCSSProperties } from '@material-ui/styles';
import classNames from 'classnames';

import { BoopAnimation } from '@/ui/BoopAnimation';

import { ReactFCC } from '@/lib/type-defs/utility';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  pointerEventsNone: {
    pointerEvents: 'none',
  },
  icon: {
    filter: 'drop-shadow(0px 2px 3px #49505619)',
  },
  paper: (props: { maxWidth: string | number | undefined }): CreateCSSProperties => ({
    maxWidth: props.maxWidth,
  }),
}));

interface ExplainerPopoverProps {
  IconComponent?: OverridableComponent<SvgIconTypeMap>;
  boxProps?: BoxProps;
  clickToOpen?: boolean;
  componentToWrap?: ReactElement;
  iconColor?: SvgIconProps['color'];
  iconSize?: SvgIconProps['fontSize'];
  maxWidth?: string | number | undefined;
}
const ExplainerPopover: ReactFCC<ExplainerPopoverProps> = ({
  IconComponent = InfoOutlinedIcon,
  componentToWrap,
  boxProps,
  children,
  clickToOpen = false,
  iconColor = 'action',
  iconSize = 'small',
  maxWidth = 300,
}) => {
  const classes = useStyles({ maxWidth });
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | HTMLElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const handlePopoverOpen = (
    e: React.MouseEvent<SVGSVGElement | HTMLElement, MouseEvent>,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root} ml={1} {...boxProps}>
      {componentToWrap ? (
        <Box
          onClick={clickToOpen ? handlePopoverOpen : undefined}
          onMouseEnter={clickToOpen ? undefined : handlePopoverOpen}
          onMouseLeave={clickToOpen ? undefined : handlePopoverClose}
          style={{ cursor: clickToOpen ? 'pointer' : 'default' }}
        >
          {componentToWrap}
        </Box>
      ) : (
        <BoopAnimation>
          <IconComponent
            className={classes.icon}
            color={iconColor}
            component="svg"
            fontSize={iconSize}
            onClick={clickToOpen ? handlePopoverOpen : undefined}
            onMouseEnter={clickToOpen ? undefined : handlePopoverOpen}
            onMouseLeave={clickToOpen ? undefined : handlePopoverClose}
            style={{ cursor: clickToOpen ? 'pointer' : 'default' }}
          />
        </BoopAnimation>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={clickToOpen ? undefined : classes.pointerEventsNone}
        classes={{
          paper: classNames(classes.paper, 'nicePopover'),
        }}
        onClose={handlePopoverClose}
        open={isOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

export default ExplainerPopover;
