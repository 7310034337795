/**
 * Search App
 */
import React, { useEffect, useState } from 'react';
import { Configure, useInfiniteHits } from 'react-instantsearch-hooks';
import { useMount } from 'react-use';
import { Box, Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import CountUp from '@/ui/CountUp';

import ReviewRequest from '@/components/ReviewRequest/ReviewRequest';

import {
  ABILITY_LEVEL_MIN_MAX,
  AGE_MIN_MAX,
  ONE_MILE_IN_METERS,
  SEARCH_PRICE_MIN_MAX,
  SEARCH_RADIUS_MIN_MAX,
} from '@/const';
import SearchFiltersContainer from '@/containers/Search/Filters/EventsSearchFiltersContainer';
import MobileSearchFilters from '@/containers/Search/Filters/MobileSearchFilters';
import { useGetSearchQueryGlobal } from '@/containers/Search/HeaderSearch/recoilStore';
import { correctlyTypeBounds } from '@/containers/Search/helpers';
import SearchMap from '@/containers/Search/Map/SearchMap';
import {
  searchFilterDefault,
  useGetSearchFiltersGlobal,
  useSetSearchFiltersGlobal,
} from '@/containers/Search/recoilStore';
import DesktopResultPlaceholder from '@/containers/Search/ResultsList/DesktopResultPlaceholder';
import MobileResultPlaceholder from '@/containers/Search/ResultsList/MobileResultPlaceholder';
import NoExperiencesSearchResults from '@/containers/Search/ResultsList/NoExperiencesSearchResults';
import SearchResultHit from '@/containers/Search/ResultsList/SearchResultHit';
import ENV from '@/env';
import { useGetClearAlgoliaCacheGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { AlgoliaSearchResult } from '@/lib/algolia';
import {
  getParsedQueryParams,
  QueryObjectType,
  useClearQueryParams,
  useSetQueryParams,
} from '@/lib/path-helpers';
import { ObjectIndexer } from '@/lib/type-defs/utility';
import { textEllipsisProps, useIsScreenBelow, useIsSmallScreenDown } from '@/lib/ui-utils';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '60vw',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 72px)',
    position: 'relative',
    zIndex: 1,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
      maxWidth: 'none',
      width: '100%',
      boxShadow: 'none',
    },
  },
  resultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
    overflowY: 'auto',
    padding: theme.spacing(0, 3, 2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  desktopFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    position: 'sticky',
    paddingRight: theme.spacing(5),
    overflowY: 'auto',
    width: '260px',
    zIndex: 999,
  },
  mapContainer: {
    width: '40vw',
  },
}));

const getSearchParams = (): QueryObjectType => {
  const queryParams = getParsedQueryParams();
  if (queryParams.activities) {
    return {
      ...queryParams,
      ...{ activities: RA.ensureArray(queryParams.activities) as string[] },
    };
  }
  return queryParams;
};

const SearchView: React.FC = () => {
  const classes = useStyles();
  const setQueryParams = useSetQueryParams();
  const clearQueryParams = useClearQueryParams();
  const isSmallScreenDown = useIsSmallScreenDown();
  const isLargeScreenDown = useIsScreenBelow(1480);
  const searchQueryGlobal = useGetSearchQueryGlobal();
  const searchFiltersGlobal = useGetSearchFiltersGlobal();
  const setSearchFiltersGlobal = useSetSearchFiltersGlobal();
  const clearAlgoliaCacheGlobal = useGetClearAlgoliaCacheGlobal();
  const { hits, isLastPage, results, sendEvent, showMore } = useInfiniteHits<
    AlgoliaSearchResult & ObjectIndexer
  >();

  const [facetFilters, setFacetFilters] = useState<string[][]>([]);
  const [numericFilters, setNumericFilters] = useState<string[]>([]);
  const [radiusFilter, setRadiusFilter] = useState<number | 'all'>('all');

  // eslint-disable-next-line no-underscore-dangle
  const hasRefinements = Boolean(R.flatten(results?._state.facetFilters ?? []).length);
  const hasResults = Boolean(hits.length);
  const queryParams = getSearchParams();
  // eslint-disable-next-line no-underscore-dangle
  const isSearchLoading = results?._state.query !== searchQueryGlobal;

  useMount(() => {
    if (clearAlgoliaCacheGlobal) {
      clearAlgoliaCacheGlobal().then();
    }
  });

  /**
   * TODO
   * https://www.algolia.com/doc/api-reference/api-parameters/optionalFilters/
   * Use user object and add gender, ability & age.
   * https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filter-scoring/
   * https://www.algolia.com/doc/api-reference/api-parameters/sortFacetValuesBy/
   * https://www.algolia.com/doc/guides/managing-results/rules/rules-overview/how-to/customize-search-results-by-platform/#creating-contextual-rules
   * https://www.algolia.com/doc/guides/managing-results/rules/rules-overview/in-depth/implementing-rules/#filters-can-trigger-rules
   */

  /**
   * Set search filters to query params on mount
   */
  useEffect(() => {
    setSearchFiltersGlobal({
      activities: RA.ensureArray((queryParams.activities as string[] | undefined) ?? []),
      amenities: RA.ensureArray((queryParams.amenities as string[] | undefined) ?? []),
      bounds: correctlyTypeBounds(queryParams.bounds),
      disciplines: RA.ensureArray((queryParams.disciplines as string[] | undefined) ?? []),
      from: queryParams.from ? parseISO(queryParams.from as string) : undefined,
      to: queryParams.to ? parseISO(queryParams.to as string) : undefined,
      abilityMin: queryParams.abilityMin
        ? parseInt(queryParams.abilityMin as string, 10)
        : ABILITY_LEVEL_MIN_MAX[0],
      abilityMax: queryParams.abilityMax
        ? parseInt(queryParams.abilityMax as string, 10)
        : ABILITY_LEVEL_MIN_MAX[1],
      ageMin: queryParams.ageMin ? parseInt(queryParams.ageMin as string, 10) : AGE_MIN_MAX[0],
      ageMax: queryParams.ageMax ? parseInt(queryParams.ageMax as string, 10) : AGE_MIN_MAX[1],
      priceMin: queryParams.priceMin
        ? parseInt(queryParams.priceMin as string, 10)
        : SEARCH_PRICE_MIN_MAX[0],
      priceMax: queryParams.priceMax
        ? parseInt(queryParams.priceMax as string, 10)
        : SEARCH_PRICE_MIN_MAX[1],
      radius: queryParams.radius
        ? parseInt(queryParams.radius as string, 10)
        : SEARCH_RADIUS_MIN_MAX[1],
      showPastEvents: queryParams.showPastEvents as boolean,
      showVirtualEvents: (queryParams.showVirtualEvents as boolean) ?? true,
      query: (queryParams.query as string | null) ?? '',
    });
  }, [JSON.stringify(queryParams)]);

  /**
   * Build facet filters
   */
  useEffect(() => {
    const amenities = RA.compact(searchFiltersGlobal.amenities ?? []).map((a) => `amenity:${a}`);
    const disciplines = RA.compact(searchFiltersGlobal.disciplines ?? []).map(
      (a) => `discipline:${a}`,
    );
    const activities = RA.compact(searchFiltersGlobal.activities).map((a) => `activity:${a}`);
    const removePastEvents = RA.compact([
      searchFiltersGlobal.showPastEvents ? undefined : 'hasPassed:false',
    ]);
    const removeVirtualEvents = searchFiltersGlobal.showVirtualEvents
      ? ['isVirtual:true', 'isVirtual:false']
      : ['isVirtual:false'];

    // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
    // (attribute1:value OR attribute2:value) AND attribute3:value (combined strings and arrays)
    // equals
    // ['attribute1:value', 'attribute2:value'], 'attribute3:value',
    setFacetFilters([amenities, disciplines, activities, removePastEvents, removeVirtualEvents]);
  }, [JSON.stringify(searchFiltersGlobal)]);

  /**
   * Build numeric filters
   */
  useEffect(() => {
    const priceMin = searchFiltersGlobal.priceMin
      ? `priceMax >= ${searchFiltersGlobal.priceMin}`
      : undefined;
    const priceMax =
      searchFiltersGlobal.priceMax !== SEARCH_PRICE_MIN_MAX[1]
        ? `priceMin <= ${searchFiltersGlobal.priceMax}`
        : undefined;
    const from = searchFiltersGlobal.from
      ? `startDateTimestamp >= ${searchFiltersGlobal.from.getTime()}`
      : undefined;
    const to = searchFiltersGlobal.to
      ? `startDateTimestamp <= ${searchFiltersGlobal.to.getTime()}`
      : undefined;
    const abilityMin = searchFiltersGlobal.abilityMin
      ? `abilityMax >= ${searchFiltersGlobal.abilityMin}`
      : undefined;
    const abilityMax = searchFiltersGlobal.abilityMax
      ? `abilityMin <= ${searchFiltersGlobal.abilityMax}`
      : undefined;
    const ageMin = searchFiltersGlobal.ageMin
      ? `ageMax >= ${searchFiltersGlobal.ageMin}`
      : undefined;
    const ageMax = searchFiltersGlobal.ageMax
      ? `ageMin <= ${searchFiltersGlobal.ageMax}`
      : undefined;
    // https://www.algolia.com/doc/api-reference/api-parameters/filters/
    const filters = [priceMin, priceMax, from, to, abilityMin, abilityMax, ageMin, ageMax].filter(
      Boolean,
    ) as string[];
    setNumericFilters([...filters]);
    // Radius
    // to turn back on aroundRadius={radiusFilter}
    setRadiusFilter(
      searchFiltersGlobal.radius !== SEARCH_RADIUS_MIN_MAX[1]
        ? Math.round(searchFiltersGlobal.radius * ONE_MILE_IN_METERS)
        : 'all',
    );
  }, [JSON.stringify(searchFiltersGlobal)]);

  const handleResetFilters = (): void => {
    const currentQuery = (queryParams.query as string) ?? '';
    clearQueryParams();
    setSearchFiltersGlobal({ ...searchFilterDefault, query: currentQuery });
    setQueryParams({ query: currentQuery });
  };

  return (
    <Box display="flex" flexGrow={1} overflow="hidden">
      <Configure
        aroundLatLngViaIP
        facetFilters={facetFilters}
        // TODO: 180th meridian still is broken when "searching this area"
        // insideBoundingBox={
        //   searchFiltersGlobal.bounds
        //     ? fix180thMeridianForAlgolia(searchFiltersGlobal.bounds)
        //     : undefined
        // }
        numericFilters={numericFilters}
        query={searchQueryGlobal}
      />
      <Box className={classes.mainContainer}>
        <Box display="flex" flexDirection={isLargeScreenDown ? 'column' : 'row'} height="100%">
          {isLargeScreenDown ? (
            <Box mb={2} mt={1}>
              <MobileSearchFilters />
            </Box>
          ) : (
            <Box className={classes.desktopFilterContainer}>
              <Box mb={0} mt={2} width="100%">
                <Typography style={textEllipsisProps} variant="body2">
                  <CountUp endValue={results?.nbHits} /> {searchQueryGlobal}{' '}
                  {pluralize('experience', results?.nbHits)}
                </Typography>
              </Box>
              <SearchFiltersContainer />
            </Box>
          )}
          <Box className={classes.resultsContainer}>
            {ENV.IS_NODE_ENV_LOCAL && (
              <Box mb={3}>
                <ReviewRequest />
              </Box>
            )}
            {!hasResults && !isSearchLoading && (
              <NoExperiencesSearchResults
                clearFilters={handleResetFilters}
                hasRefinements={hasRefinements}
              />
            )}
            <Grid container spacing={3}>
              {isSearchLoading ? (
                <>
                  {R.range(0, 20).map((num) => (
                    <Grid item key={num} xl={6} xs={12}>
                      {isSmallScreenDown ? (
                        <MobileResultPlaceholder />
                      ) : (
                        <DesktopResultPlaceholder />
                      )}
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {hits.map((hit) => {
                    return (
                      <Grid item key={hit.objectID} xl={6} xs={12}>
                        <SearchResultHit hit={hit} sendEvent={sendEvent} />
                      </Grid>
                    );
                  })}
                  {!isLastPage && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <ExtendedButton
                          color="primary"
                          onClick={showMore}
                          size="medium"
                          variant="outlined"
                        >
                          Show More
                        </ExtendedButton>
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Hidden smDown>
        <Box className={classes.mapContainer}>
          <SearchMap height={`calc(100vh - ${72}px)`} />
        </Box>
      </Hidden>
    </Box>
  );
};

export default SearchView;
