/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type hostEventMutations_useUnPublishEventMutation_event = {
    readonly registrations: ReadonlyArray<{
        readonly userId: string;
    }>;
    readonly " $refType": "hostEventMutations_useUnPublishEventMutation_event";
};
export type hostEventMutations_useUnPublishEventMutation_event$data = hostEventMutations_useUnPublishEventMutation_event;
export type hostEventMutations_useUnPublishEventMutation_event$key = {
    readonly " $data"?: hostEventMutations_useUnPublishEventMutation_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"hostEventMutations_useUnPublishEventMutation_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "hostEventMutations_useUnPublishEventMutation_event",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "_and": [
                            {
                                "_or": [
                                    {
                                        "status": {
                                            "_eq": "complete"
                                        }
                                    },
                                    {
                                        "status": {
                                            "_eq": "canceled"
                                        }
                                    }
                                ]
                            },
                            {
                                "event": {
                                    "deleted_at": {
                                        "_is_null": true
                                    }
                                }
                            }
                        ]
                    }
                }
            ],
            "concreteType": "registration",
            "kind": "LinkedField",
            "name": "registrations",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                }
            ],
            "storageKey": "registrations(where:{\"_and\":[{\"_or\":[{\"status\":{\"_eq\":\"complete\"}},{\"status\":{\"_eq\":\"canceled\"}}]},{\"event\":{\"deleted_at\":{\"_is_null\":true}}}]})"
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'dfe35af53f73c44b70e1f5a2cca28ab7';
export default node;
