import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { IConfettiOptions } from 'react-confetti/dist/types/Confetti';
import { Helmet } from 'react-helmet';
import { useUnmount } from 'react-use';

import Confetti from '@/ui/Confetti';

import {
  useGetIsConfettiGlobal,
  useSetIsConfettiGlobal,
} from '@/globalRecoilStore/globalRecoilStore';
import { ReactFCC } from '@/lib/type-defs/utility';

export interface PageProps {
  title: string;
  noIndex?: boolean;
  description?: string;
}

const Page: ReactFCC<PageProps> = ({ children, description, noIndex = false, title }) => {
  const isConfettiGlobal = useGetIsConfettiGlobal();
  const setIsConfettiGlobal = useSetIsConfettiGlobal();

  const onConfettiComplete: IConfettiOptions['onConfettiComplete'] = (confetti) => {
    setIsConfettiGlobal(false);
    confetti?.reset();
  };
  // Use this just in case the user navigates away while confetti is still running
  useUnmount(() => {
    setIsConfettiGlobal(false);
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Helmet>
        <title>{title}</title>
        {noIndex ? (
          <meta content="noindex, nofollow" name="robots" />
        ) : (
          <meta content={description} name="description" />
        )}
        <meta content="width=device-width,initial-scale=1.0" name="viewport" />
      </Helmet>
      <Confetti
        gravity={0.22764}
        numberOfPieces={isConfettiGlobal ? 400 : 0}
        onConfettiComplete={onConfettiComplete}
        recycle={false}
        style={{ pointerEvents: 'none', position: 'fixed', zIndex: 99999999 }}
        wind={-0.008}
      />
      {children}
    </div>
  );
};

export default Page;
