import { isDate } from 'date-fns';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

/**
 * Check if two arrays are equal regardless of order
 */
export const isArrayElementsEqual = <T>(arr1: T, arr2: T): boolean => {
  return R.symmetricDifference<T>(arr1, arr2).length === 0;
};

/**
 * [1, 2] padded with fill of 3 to length of 5 is [1, 2, 3, 3, 3]
 */
export const padArray = <T>(array: T[], length: number, fill: unknown): T[] => {
  return length > array.length ? R.concat(array, Array(length - array.length).fill(fill)) : array;
};

export const getRandomArrayElement = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const filterWithKeys = <T>(pred: Function, obj: T): T =>
  R.pipe(R.toPairs, R.filter(R.apply(pred)), R.fromPairs)(obj);

/**
 * Returns a new object excluding any references to the given keys.
 */
export const omitDeep = R.curry((keys, obj) =>
  R.when(R.is(Object), R.pipe(R.unless(R.is(Array), R.omit(keys)), R.map(omitDeep(keys))))(obj),
);

/**
 * Find the lowest number in an array that hasn't been used.
 * e.g. [1, 2, 5] -> 3
 */
export const getLowestUnusedNumberInArray = (
  arrayToCheck: number[],
  startingFrom: number,
): number => {
  const sortedArrayToCheck = [...arrayToCheck].sort((a, b) => a - b);
  return sortedArrayToCheck.reduce((lowest, num, i) => {
    const seqIndex = i + startingFrom;
    return num !== seqIndex && seqIndex < lowest ? seqIndex : lowest;
  }, sortedArrayToCheck.length + startingFrom);
};

/**
 * Cartesian product
 */
export const getCartesianProduct = <T>(sets: T[][]): T[][] => {
  return sets.reduce((acc, set) => acc.flatMap((x) => set.map((y) => [...x, y])), [[]]);
};

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export function arrayContainsArray<T>(superset: T[], subset: T[]): boolean {
  return subset.every((value) => {
    return superset.indexOf(value) >= 0;
  });
}

/**
 * Update all of the keys in the left array with the ones from the right array
 */
export function updateLeftArrayByRightArrayKeys<T>(
  arrayOfObjectsA: T[],
  arrayOfObjectsB: T[],
  keys: string[],
  uniqueKey: string,
): T[] {
  return (arrayOfObjectsA ?? []).map((objA) => {
    // Get the obj from arrayB that has the same uniqueKey as this object in arrayA
    const objB = R.find(R.propEq(uniqueKey, objA[uniqueKey]), arrayOfObjectsB);
    if (!objB) {
      return objA;
    }
    // Update all items from objB
    if (R.isEmpty(keys)) {
      return { ...objA, ...objB };
    }
    // Update keys individually
    let newObj = objA;
    keys.forEach((key) => {
      newObj = { ...newObj, [key]: objB[key] };
    });
    return newObj;
  });
}

/**
 * Update an object in an array by the key
 * The new object can contain all or none of the properties of the old object
 */
export function updateObjectInArray<T>(
  booleanFunction: (obj: T) => boolean,
  newObject: Partial<T> | ((obj: T) => Partial<T>),
  arrayOfObjects: readonly T[] | T[],
): T[] {
  if (typeof newObject === 'function') {
    return [...arrayOfObjects].map((obj) =>
      booleanFunction(obj) ? { ...obj, ...newObject(obj) } : obj,
    );
  }
  return [...arrayOfObjects].map((obj) => (booleanFunction(obj) ? { ...obj, ...newObject } : obj));
}

/**
 * Update an object in an array by the key
 * Add the object to the array if it doesn't exist
 * The new object can contain all or none of the properties of the old object
 */
interface ObjectIndexer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}
export function updateOrCreateObjectInArray<T>(
  arrayOfObjects: (T & ObjectIndexer)[],
  newObject: T & ObjectIndexer,
  uniqueField: string,
  addToFront = false,
): T[] {
  let didUpdate = false;
  const updatedRecords = (arrayOfObjects ?? []).map((obj) => {
    if (obj[uniqueField] === newObject[uniqueField]) {
      didUpdate = true;
      return newObject;
    }
    return obj;
  });
  if (didUpdate) {
    return updatedRecords;
  }
  // No objects updated. Add new
  if (addToFront) {
    return [newObject, ...(arrayOfObjects ?? [])];
  }
  return [...(arrayOfObjects ?? []), newObject];
}

/**
 * Create an object from an enum
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const objectFromEnum = <T, Y>(enumToConvert: T, defaultValue?: Y): Record<T, Y> => {
  return R.zipObj<Y, T>(
    Object.keys(enumToConvert),
    Object.values(enumToConvert).map(() => defaultValue ?? null),
  );
};

/**
 * Look up a keyname based on a value
 */
export const getKeyFromValue = <T>(value: string | number, object: T): string => {
  return R.zipObj(Object.values(object), Object.keys(object))[value];
};

/**
 * Sort an array asc
 */
export function sortArrayAsc(arrayToSort: number[]): number[] {
  const diff = (a: number, b: number): number => {
    return a - b;
  };
  return R.sort(diff, arrayToSort);
}
export function sortArrayAscByKey<T>(arrayToSort: T[] | readonly T[], key: string): T[] {
  const diff = (a: T, b: T): number => {
    if (isDate(a[key]) && isDate(b[key])) {
      return a[key].getTime() - b[key].getTime();
    }
    return a[key] - b[key];
  };
  return R.sort(diff, arrayToSort);
}
export const sortTextArrayAsc = R.sort((a: string, b: string) => a.localeCompare(b)); // alphabetically

/**
 * Sort an array desc
 */
export const sortArrayDesc = (arrayToSort: number[]): number[] => {
  const diff = (a: number, b: number): number => {
    return b - a;
  };
  return R.sort(diff, arrayToSort);
};

export function sortArrayDescByKey<T>(arrayToSort: T[] | readonly T[], key: string): T[] {
  const diff = (a: T, b: T): number => {
    if (isDate(a[key]) && isDate(b[key])) {
      return b[key].getTime() - a[key].getTime();
    }
    return b[key] - a[key];
  };
  return R.sort(diff, arrayToSort);
}
export const sortTextArrayDesc = R.pipe(sortTextArrayAsc, R.reverse); // alphabetically

/**
 * find the path to a key in an object
 */
export function findPathsToKey(options: {
  key: string;
  obj: Record<string, any>;
  pathToKey?: string;
}): { pathToKey: string; value: any }[] {
  const results: { pathToKey: string; value: string }[] = [];

  const findKey = ({
    key,
    obj,
    pathToKey,
  }: {
    key: string;
    obj: Record<string, any>;
    pathToKey: string;
  }): void => {
    const oldPath = `${pathToKey ? `${pathToKey}.` : ''}`;
    if (obj?.[key]) {
      results.push({ pathToKey: `${oldPath}${key}`, value: obj[key] });
      return;
    }
    if (Array.isArray(obj)) {
      for (let j = 0; j < obj.length; j++) {
        findKey({
          obj: obj[j],
          key,
          pathToKey: `${oldPath}[${j}]`,
        });
      }
    } else if (RA.isPlainObj(obj)) {
      Object.keys(obj).forEach((k) => {
        findKey({
          obj: obj[k],
          key,
          pathToKey: `${oldPath}${k}`,
        });
      });
    }
  };
  findKey(options);
  return results;
}
