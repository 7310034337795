import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Grid, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ButtonLink from '@/ui/Button/ButtonLink';
import ExtendedButton from '@/ui/Button/ExtendedButton';

import VideoWithHeroText from '@/components/Video/VideoWithHeroText';

import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  mainText: {
    color: Colors.OffWhite,
    fontWeight: 'bold',
    // transform: 'scale(1.2)',
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    zIndex: 2,
  },
  exploreContainer: {
    minHeight: '180px',
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    backgroundColor: Colors.Blue,
    borderRadius: theme.spacing(1),
    transition: 'box-shadow 0.15s ease-in-out',
    // color: 'white !important',
    padding: theme.spacing(2),
    '&:hover': {
      // boxShadow: theme.shadows[4],
    },
    '&:hover img': {
      // opacity: 0.3,
    },
  },
  creatorContainer: {
    minHeight: '200px',
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(2),
    backgroundColor: Colors.Orange,
    borderRadius: theme.spacing(1),
    transition: 'box-shadow 0.15s ease-in-out',
    '&:hover': {
      // boxShadow: theme.shadows[4],
    },
    '&:hover img': {
      // opacity: 0.2,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '400px',
    width: '100%',
  },
}));
const HomeAboveFold: React.FC = () => {
  const classes = useStyles();
  const {
    trackHomeBecomeACoachClick,
    trackHomeBecomeAnOrganizerClick,
    trackHomeDiscoverClick,
  } = useSegmentTrack();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  /**
   * Handle medium screen creator menu
   */
  const showCreatorMenuOptions = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };
  const closeCreatorMenuOptions = (): void => {
    setMenuAnchorEl(null);
  };

  return (
    <Box display="flex" minHeight="calc(100vh - 72px)" p={2} width="100%">
      <Grid container spacing={2}>
        <Grid item md={9} xs={12}>
          <VideoWithHeroText
            roundedBorder
            imageUrl="https://res.cloudinary.com/goreggy/image/upload/v1658509569/site/siteImages/q6iia5mot9bkw2za7g4h.jpg"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Grid container spacing={2} style={{ height: 'calc(100% + 16px)' }}>
            <Grid item md={12} sm={6} xs={12}>
              <div className={classes.exploreContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="h5">Learn, Ride, Race</Typography>
                  <Typography gutterBottom component="div" variant="body1">
                    From gravel rides to downhill races, from learning to jump to breaking into the
                    cycling industry, we make it possible to discover the{' '}
                    <i>perfect cycling experience</i>.
                  </Typography>
                  <Box mt={1.5}>
                    <ButtonLink
                      color="primary"
                      minWidth="sm"
                      onClick={(): void => trackHomeDiscoverClick(null)}
                      to={AppRouteService.getRelativeUrl('Discover', {}, { category: 'All' })}
                      variant="contained"
                    >
                      Discover Experiences
                    </ButtonLink>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item md={12} sm={6} xs={12}>
              <div className={classes.creatorContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="h5">Make it more than a hobby</Typography>
                  <Typography gutterBottom variant="body1">
                    The world class tools you need to create races, coach students, build online
                    courses and host rides. <b>It's 100% free</b>.
                  </Typography>
                  <Box mt={1.5}>
                    <ButtonLink
                      color="primary"
                      // endIcon={<ExpandMoreIcon />}
                      minWidth="sm"
                      style={{ marginRight: 'auto' }}
                      // onClick={showCreatorMenuOptions}
                      to={AppRouteService.getRelativeUrl('BecomeACreator')}
                      variant="contained"
                    >
                      Become a Creator
                    </ButtonLink>
                    {/* {Boolean(menuAnchorEl) && ( */}
                    {/*  <Menu */}
                    {/*    anchorEl={menuAnchorEl} */}
                    {/*    anchorOrigin={{ */}
                    {/*      vertical: 'bottom', */}
                    {/*      horizontal: 'left', */}
                    {/*    }} */}
                    {/*    getContentAnchorEl={null} */}
                    {/*    onBackdropClick={closeCreatorMenuOptions} */}
                    {/*    open={Boolean(menuAnchorEl)} */}
                    {/*  > */}
                    {/*    <MenuItem */}
                    {/*      component={NavLink} */}
                    {/*      onClick={(): void => { */}
                    {/*        trackHomeBecomeACoachClick(null); */}
                    {/*        closeCreatorMenuOptions(); */}
                    {/*      }} */}
                    {/*      style={{ color: 'inherit' }} */}
                    {/*      to={AppRouteService.getRelativeUrl('User_Business_BecomeCoach')} */}
                    {/*    > */}
                    {/*      Become a Coach */}
                    {/*    </MenuItem> */}
                    {/*    <MenuItem */}
                    {/*      component={NavLink} */}
                    {/*      key={3} */}
                    {/*      onClick={(): void => { */}
                    {/*        trackHomeBecomeAnOrganizerClick(null); */}
                    {/*        closeCreatorMenuOptions(); */}
                    {/*      }} */}
                    {/*      style={{ color: 'inherit' }} */}
                    {/*      to={AppRouteService.getRelativeUrl('Organizer_Start')} */}
                    {/*    > */}
                    {/*      Become an Organizer */}
                    {/*    </MenuItem> */}
                    {/*  </Menu> */}
                    {/* )} */}
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeAboveFold;
