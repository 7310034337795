/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type activityType_enum = "Clinic" | "Race" | "Ride" | "%future added value";
export type helpers_getUserTypeFromActivity_event = {
    readonly activity: activityType_enum;
    readonly " $refType": "helpers_getUserTypeFromActivity_event";
};
export type helpers_getUserTypeFromActivity_event$data = helpers_getUserTypeFromActivity_event;
export type helpers_getUserTypeFromActivity_event$key = {
    readonly " $data"?: helpers_getUserTypeFromActivity_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getUserTypeFromActivity_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getUserTypeFromActivity_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activity",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '2bda8d4e76b02725dc55b25c7257bddd';
export default node;
