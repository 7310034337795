import { makeStyles, Theme } from '@material-ui/core';

export const useContainerStyles = makeStyles({
  root: {
    margin: 'auto',
    maxWidth: '700px',
  },
});

export const useShowEmailButton = makeStyles({
  root: {
    height: '61px',
    opacity: '1',
    overflow: 'hidden',
    transition: 'height 300ms ease, opacity 300ms ease',
  },
  hidden: {
    height: '0',
    opacity: '0',
  },
});

export const useEmailControls = makeStyles((theme) => ({
  root: {
    opacity: '0',
    height: '0',
    overflow: 'hidden',
    tabIndex: '-999',
    transition: 'height 250ms ease, opacity 700ms ease',
    '&.open': {
      opacity: '1 !important',
      tabIndex: 'unset',
      paddingTop: theme.spacing(1),
      height: '260px',
      [theme.breakpoints.down('xs')]: {
        height: '330px',
      },
    },
    '&.open.signup': {
      height: '306px',
      //   height: '310px',
      //   [theme.breakpoints.down('xs')]: {
      //     height: '388px',
      //   },
    },
  },
}));

export const useAuthPageHeader = makeStyles((theme) => ({
  root: {
    // hide this for now
    display: 'none',

    color: '#04759C',
    fontFamily: "'Varela Round',-apple-system,BlinkMacSystemFont,Open Sans,sans-serif",
    fontSize: '43px',
    fontWeight: 'bold',
    margin: '0',
    position: 'fixed',
    left: '0',
    padding: '10px 0',
    top: '0',
    background: '#ffffff63',
    width: '100vw',
    textAlign: 'center',
    marginBottom: '29px',
    boxShadow: '0 -101px 113px 85px #ffffff',
    textShadow: '1px 2px 3px #00000038, 0px 0px 31px #00000030',
    textDecoration: 'none !important',
    '@media (max-width: 599px), (max-height: 800px)': {
      display: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

// Sign in with Apple, Continue, etc
export const useAuthButtonStyles = makeStyles({
  root: {
    boxShadow: '0 4px 10px -6px rgba(36, 50, 66, 0.7), 0 4px 7px -7px #243242',
    '&:hover': {
      boxShadow: '0 3px 5px -2px rgba(36, 50, 66, 0.15)',
    },
    '&:hover .MuiButton-startIcon': {
      transform: 'scale(1.55)',
    },
  },
  appleRoot: {
    // fontFamily: '"SF Pro Text"',
    background: 'black',
    color: 'white',
    border: '1px solid #000000a1;',
    '&:hover': {
      background: 'black',
    },
  },
  facebookRoot: {
    background: '#3b5998',
    color: 'white',
    '&:hover': {
      background: '#3b5998',
    },
  },
  googleRoot: {
    background: '#4285F4',
    color: 'white',
    '&:hover': {
      background: '#4285F4',
    },
    '&:hover .MuiButton-label span': {
      transform: 'scale(1.25)',
    },
    '& div': {
      // Background for Google logo
      backgroundColor: 'white',
      border: '1px solid #4285f475',
      borderRadius: '4px',
      boxShadow: 'inset -6px 0 15px -13px #4285f4ad',
      display: 'inline-block',
      height: '100%',
      left: 0,
      position: 'absolute',
      width: '50px',
      zIndex: 1,
      top: 0,
    },
  },
  googleIcon: {
    height: '26px',
    left: '13px',
    position: 'absolute',
    top: '11px',
    transition: 'transform 0.25s',
    transformOrigin: 'center',
    width: '26px',
    zIndex: 2,
    '& img': {
      marginBottom: '25px',
      filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, .2))',
    },
  },
  startIcon: {
    left: '20px',
    marginRight: 0,
    padding: '0 !important',
    position: 'absolute',
    transform: 'scale(1.3)',
    transition: 'transform 300ms ease',
    '& svg': {
      fontSize: '25px !important',
    },
  },
});

export const useAuthErrorContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(226, 23, 63, .5)',
    // border: 1px solid rgba(169, 68, 66, .6);
    borderRadius: '3px',
    // text-shadow: 1px 1px 1px rgba(36, 50, 66, .1);
    boxShadow: '0 0 10px 0 rgba(36, 50, 66, .2)',
    color: 'rgb(14, 17, 20)',
    display: 'block',
    fontSize: '14px',
    padding: theme.spacing(2),
    margin: '0 auto 24px auto',
    textAlign: 'center',
    width: '80%',
    // border:2px solid rgba(169, 21, 57, .4) !important;
    // color:rgba(169, 21, 57, .9) !important;
    '& > div': {
      wordBreak: 'break-word',
    },
  },
}));
