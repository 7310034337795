import React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { parseISO, parseJSON } from "date-fns";
import { graphql } from 'relay-runtime';

import BrandTypography from '@/ui/BrandTypography';
import TextChip from '@/ui/Chips/TextChip';

import MainLayout from '@/layouts/MainLayout';
import { readableDate } from '@/lib/date-helpers/date-utils';
import Page from '@/pages/Page';
import { ChangeLogPage_changeLog_Query } from '@/pages/Static/__generated__/ChangeLogPage_changeLog_Query.graphql';

enum ChangeLogChangeTypeEnum {
  BugFix = 'Bug Fix',
  NewFeature = 'New Feature',
  Improvement = 'Improvement',
}
const ChangeLogPage: React.FC = () => {
  const changeData = useLazyLoadQuery<ChangeLogPage_changeLog_Query>(
    graphql`
      query ChangeLogPage_changeLog_Query {
        changeLogDate_connection(order_by: { date: desc }) {
          edges {
            node {
              id
              date
              changeLogItems {
                id
                changeType
                changeText
              }
            }
          }
        }
      }
    `,
    {},
  ).changeLogDate_connection.edges.map((edge) => edge.node);

  return (
    <Page description="A log of the updates made to the Reggy site." title="Change Log • Reggy">
      <MainLayout>
        <Container maxWidth="md" style={{ padding: '40px 32px' }}>
          <BrandTypography gutterBottom align="center" variant="h3">
            Reggy Changelog
          </BrandTypography>
          <Typography align="center">New updates and improvements to Reggy.</Typography>
          <Box py={7}>
            {changeData.map((changeDate) => (
              <ChangeLogDate
                date={readableDate(parseISO(changeDate.date), true, false)}
                key={changeDate.id}
              >
                {changeDate.changeLogItems.map((changeItem) => (
                  <ChangeLogItem changeType={changeItem.changeType} key={changeItem.id}>
                    {changeItem.changeText}
                  </ChangeLogItem>
                ))}
              </ChangeLogDate>
            ))}
          </Box>
        </Container>
      </MainLayout>
    </Page>
  );
};

interface ChangeLogDateProps {
  date: string;
}
const ChangeLogDate: React.FC<ChangeLogDateProps> = ({ children, date }) => {
  return (
    <div>
      <Divider />
      <Box my={6}>
        <div>
          <Typography variant="h5">{date}</Typography>
        </div>
        <Box ml={2}>{children}</Box>
      </Box>
    </div>
  );
};

const changeLogItems: Record<
  string,
  { color: 'error' | 'success' | 'warning'; text: ChangeLogChangeTypeEnum }
> = {
  BugFix: {
    color: 'error',
    text: ChangeLogChangeTypeEnum.BugFix,
  },
  Feature: {
    color: 'success',
    text: ChangeLogChangeTypeEnum.NewFeature,
  },
  Improvement: {
    color: 'warning',
    text: ChangeLogChangeTypeEnum.Improvement,
  },
};
interface ChangeLogItemProps {
  changeType: string;
}
const ChangeLogItem: React.FC<ChangeLogItemProps> = ({ changeType, children }) => {
  return (
    <Box my={1}>
      <Box display="flex">
        <Box alignItems="center" display="flex" justifyContent="center" minWidth={90} mr={2}>
          <TextChip
            color={changeLogItems[changeType].color}
            title={changeLogItems[changeType].text}
          />
        </Box>
        <Typography>{children}</Typography>
      </Box>
    </Box>
  );
};

export default ChangeLogPage;
