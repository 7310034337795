import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
// eslint-disable-next-line import/no-unresolved
import { Event, EventHint } from '@sentry/types/types/event';
import { useSnackbar, VariantType } from 'notistack';

import ENV from '@/env';
import { getIsAdBlockerRunning } from '@/globalRecoilStore/globalRecoilStore';
import { firebaseAuth, sendSentryErrorCF } from '@/lib/firebase';

/**
 * Initialize Sentry
 */
const SENTRY_ORG_SLUG = 'reggy';
export const initSentry = (): void => {
  Sentry.init({
    dsn: ENV.SENTRY_URL,
    release: ENV.APP_VERSION,
    integrations: [
      ...[
        ENV.IS_NODE_ENV_LOCAL
          ? new Sentry.Integrations.Breadcrumbs({ console: false })
          : new Sentry.Integrations.Breadcrumbs(),
      ],
      new Integrations.Dedupe(),
      new Integrations.HttpContext(),
      new Integrations.FunctionToString(),
      new SentryFullStory(SENTRY_ORG_SLUG),
    ],
    beforeSend: async (event: Event, hint: EventHint) => {
      // Modify the event here
      if (event.user) {
        // Don't send user's email address
        delete event.user.email;
        delete event.user.firstName;
        delete event.user.lastName;
        delete event.user.dob;
      }
      if (ENV.IS_NODE_ENV_LOCAL) {
        return null;
      }
      const isAdBlockerRunning = await getIsAdBlockerRunning();
      if (isAdBlockerRunning) {
        // Sometimes ad-blockers block the Sentry URL
        // This is a backup cloud function that sends errors if we detect an ad-blocker
        sendSentryErrorCF({ ...event, ...hint }).then();
        return null;
      }
      return event;
    },
    // NOTE: taken from https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // https://github.com/getsentry/sentry-javascript/issues/2292
      'Non-Error exception captured',
      // Ignore firebase auth errors
      'Firebase: Error (auth',
    ],
    denyUrls: [
      // URLS
      // filter out any ad blocked content from Segment
      /cdn\.segment\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
};

/**
 * Hook to track, report and capture errors
 */
type TrackSentryOptionsType = {
  notify?: boolean;
  notifyWithMessage?: boolean;
  variant?: VariantType;
};
export const useTrackSentryError = (): ((err: Error, options?: TrackSentryOptionsType) => void) => {
  const { currentUser } = firebaseAuth;
  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar;
  return (err: Error, options: TrackSentryOptionsType = { notify: true }): void => {
    try {
      // typewriter.javaScriptError({
      //   ...getErrorMetadata([error]),
      //   ...payload,
      // });
      Sentry.withScope((scope) => {
        if (currentUser) {
          scope.setUser(currentUser);
        }
        Sentry.captureException(err);
      });
      if ((options?.notify || options?.notifyWithMessage) && enqueueSnackbar) {
        enqueueSnackbar(
          !ENV.IS_NODE_ENV_PRODUCTION || options?.notifyWithMessage
            ? err?.message
            : 'Error occurred. Please try again.',
          { variant: options?.variant || 'default' },
        );
      }
      if (!ENV.IS_NODE_ENV_PRODUCTION) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
};

//
// /*
//   GraphQL errors are usually validation errors or common invalid paths
//   usually related to invalid requests to GraphQL.
// */
// export function trackGraphQLErrors(graphqlErrors, operation = '') {
//   try {
//     const timezone = moment.tz.guess();
//     const sentryId = Raven.lastEventId();
//     const fullStory = ErrorTracker.getFullStory();
//     const route = RouteTracker.currentRoute;
//     const team = RouteTracker.currentTeam;
//     const appVersion = APP_VERSION;
//
//     const payload = {
//       ...getErrorMetadata(graphqlErrors),
//       fullStory,
//       timezone,
//       sentryId,
//       route,
//       team,
//       operation,
//       graphqlErrors,
//       appVersion,
//     };
//
//     // Log the errors individually so the objects are expanded by default in the devtools
//     for (const graphqlError of graphqlErrors) {
//       console.error('GraphQL Error', operation, graphqlError);
//     }
//
//     ErrorTracker.logFullStory('GraphQL Error');
//     typewriter.graphQlError(payload);
//
//     // Metric via statsd proxy
//     trackAppCrash({
//       type: GRAPHQL_CRASH,
//       componentName: operation,
//       route,
//       team,
//     });
//   } catch (e) {
//     console.error(e);
//   }
// }

export default initSentry;
