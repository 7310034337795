import React, { useState } from 'react';
import { Box, Hidden, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import * as RA from 'ramda-adjunct';

import BrandTypography from '@/ui/BrandTypography';
import ButtonLink from '@/ui/Button/ButtonLink';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import ConditionalWrapper from '@/hoc/ConditionalWrapper';
import DisableToggle from '@/hoc/DisableToggle';
import FadeComponent from '@/hoc/FadeComponent';

import ElevationScroll from '@/components/Header/ElevationScroll';
import MobileNavigation from '@/components/Header/MobileNavigation';

import DesktopNavigation from './Desktop/DesktopNavigation';

import SearchContainer from '../Search/HeaderSearch/SearchContainer';

import CourierNotifications from '@/containers/MainHeader/CourierNotifications';
import MobileMenu from '@/containers/MainHeader/Mobile/MobileMenu';
import UserAvatar from '@/containers/User/UserAvatar/UserAvatar';
import { useOpenLoginDialog, useOpenSignupDialog } from '@/lib/path-helpers';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

export const MAIN_HEADER_HEIGHT = 72;

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: Colors.White,
    zIndex: theme.zIndex.appBar + 1,
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  menuItem: {
    '& .MuiListItem-root': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  navigateButton: {
    '& .MuiButton-label': {
      color: Colors.DarkText,
      fontWeight: 700,
      letterSpacing: 0,
      whiteSpace: 'nowrap',
    },
  },
  navigationButtons: {
    display: 'flex',
    '& > a': {
      display: 'flex !important',
    },
    '& > .MuiButton-root,& > a': {
      marginLeft: theme.spacing(1),
      '& .MuiButton-label': {
        color: Colors.DarkText,
        fontWeight: 700,
        letterSpacing: 0,
        whiteSpace: 'nowrap',
      },
      '& .MuiButton-endIcon': {
        display: 'flex',
        marginLeft: theme.spacing(0.25),
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          fontSize: theme.spacing(2.5),
        },
      },
    },
  },
}));

interface MainHeaderProps {
  shouldUsePositionRelativeHeader?: boolean;
}
const MainHeader: React.FC<MainHeaderProps> = ({ shouldUsePositionRelativeHeader }) => {
  const classes = useHeaderStyles();
  const auth = useAuth();
  const openLoginDialog = useOpenLoginDialog();
  const openSignupDialog = useOpenSignupDialog();
  const isSmallScreenDown = useIsSmallScreenDown();
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const isCoach = auth.user?.isCoach;
  const partOfOrganization = RA.isNotNilOrEmpty(auth.user?.userOrganizerRoles);

  const isLoggedInWithoutData = Boolean(auth.firebaseUser) && !auth.isLoggedIn;

  /**
   * Close the mobile menu
   */
  const handleMobileMenuClose = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <ConditionalWrapper
        WrapperComponent={ElevationScroll}
        condition={!shouldUsePositionRelativeHeader}
      >
        <AppBar
          className={classes.appBar}
          color="inherit"
          position={shouldUsePositionRelativeHeader ? 'relative' : 'fixed'}
        >
          <Toolbar className={classes.toolbar}>
            {(!isSmallScreenDown || (isSmallScreenDown && !isSearchFocused)) && (
              <Box mr={3}>
                <BrandTypography onClick={handleMobileMenuClose} variant="h5">
                  Reggy
                </BrandTypography>
              </Box>
            )}
            <SearchContainer
              isMobileMenuOpen={isOpen}
              isSearchFocused={isSearchFocused}
              setIsSearchFocused={setIsSearchFocused}
            />
            <Hidden smDown>
              <FadeComponent duration={100} shouldFade={!isSearchFocused} tension={1} width="100%">
                <Box display="flex" justifyContent="space-between" width="100%">
                  <DesktopNavigation />
                  <Box alignItems="center" display="flex">
                    {auth.isLoggedIn && partOfOrganization ? (
                      <Box display="flex">
                        <NoUnderlineRouterLink
                          grey
                          // Refetch to make sure we will have permission
                          onClick={(): void => auth.fetchUser({ userId: auth.firebaseUser?.uid })}
                          style={{ textAlign: 'center' }}
                          to={AppRouteService.getRelativeUrl('Organizer_Start')}
                        >
                          Switch to Organizer
                        </NoUnderlineRouterLink>
                      </Box>
                    ) : !isCoach ? (
                      <ButtonLink
                        disabled={auth.isInitializing || isLoggedInWithoutData}
                        style={{ whiteSpace: 'nowrap' }}
                        to={AppRouteService.getRelativeUrl('BecomeACreator')}
                      >
                        Become a Creator
                      </ButtonLink>
                    ) : undefined}
                    {auth.isLoggedIn ? (
                      <Box alignItems="center" className={classes.navigationButtons}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          mx={2.5}
                          position="relative"
                        >
                          <CourierNotifications />
                        </Box>
                        <div>
                          <UserAvatar size="small" />
                        </div>
                      </Box>
                    ) : (
                      <DisableToggle disabled={auth.isInitializing || isLoggedInWithoutData}>
                        <Box alignItems="center" display="flex">
                          <Box mx={1}>
                            <ExtendedButton
                              onClick={(): void => openLoginDialog()}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Log In
                            </ExtendedButton>
                          </Box>
                          <ExtendedButton
                            color="primary"
                            minWidth="xs"
                            onClick={(): void => openSignupDialog()}
                            style={{ whiteSpace: 'nowrap' }}
                            variant="contained"
                          >
                            Sign Up
                          </ExtendedButton>
                        </Box>
                      </DisableToggle>
                    )}
                  </Box>
                </Box>
              </FadeComponent>
            </Hidden>
            <Hidden mdUp>
              {!isSearchFocused && (
                <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen}>
                  <MobileMenu handleMobileMenuClose={handleMobileMenuClose} />
                </MobileNavigation>
              )}
            </Hidden>
          </Toolbar>
        </AppBar>
      </ConditionalWrapper>
      {!shouldUsePositionRelativeHeader && <Toolbar />}
    </>
  );
};

export default MainHeader;
