import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, BoxProps, Menu } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import * as RA from 'ramda-adjunct';

import { getUserInitials } from '@/lib/selectors/user';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  small: {
    width: 32,
    height: 32,
    boxShadow: '0 4px 10px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .15)',
  },
  medium: {
    boxShadow: theme.shadows[9],
    width: 64,
    height: 64,
  },
  large: {
    boxShadow: theme.shadows[9],
    fontSize: theme.typography.h3.fontSize,
    height: 128,
    width: 128,
  },
  noShadow: {
    boxShadow: 'none !important',
  },
  menuItem: {
    '& .MuiListItem-root': {
      fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiMenu-paper': {
      marginTop: theme.spacing(1),
      width: 262,
    },
  },
  avatarContainer: {
    border: Colors.LightBorder,
    borderRadius: theme.spacing(3),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 1),
    position: 'relative',
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      boxShadow: theme.shadows[2],
    },
  },
  avatarContainerShadow: {
    boxShadow: theme.shadows[2],
  },
  menuIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

interface UserAvatarProps {
  boxProps?: BoxProps;
  noShadow?: boolean;
  size: 'small' | 'medium' | 'large';
}
const UserAvatar: React.FC<UserAvatarProps> = ({ boxProps, noShadow, size }) => {
  const classes = useStyles();
  const auth = useAuth();
  const [userBtnAnchorEl, setDashboardBtnAnchorEl] = useState<HTMLDivElement | null>(null);

  // See if we have a profile pic
  const profilePicUrl = auth.user?.profilePicUrl ?? undefined;
  const initials = getUserInitials(auth.user);
  const avatarSize =
    size === 'small' ? classes.small : size === 'medium' ? classes.medium : classes.large;
  const hasOrganization = RA.isNotNilOrEmpty(auth.user?.userOrganizerRoles);
  const showCreatorControls = auth.user?.isCreator && (!hasOrganization || !auth.user?.isCoach);

  // Saved Events
  const savedLink = ''; // AppRouteService.getAbsoluteUrl('User_Saved', {
  //   organizerSlug: result.organizer.slug,
  //   organizerSlug: result.eventMetadata.slug,
  // });

  // Dashboard menu options
  const showDashboardOptions = (e: React.MouseEvent<HTMLDivElement>): void =>
    setDashboardBtnAnchorEl(e.currentTarget);
  const hideDashboardOptions = (): void => setDashboardBtnAnchorEl(null);

  /**
   * Logout
   */
  const logout = (): void => {
    auth.logout().then();
    hideDashboardOptions();
  };

  return (
    <Box {...boxProps}>
      <Box
        className={classNames(classes.avatarContainer, {
          [classes.avatarContainerShadow]: Boolean(userBtnAnchorEl),
        })}
        ml={0.5}
        onClick={showDashboardOptions}
      >
        <div className={classes.menuIcon}>
          <MenuIcon />
        </div>
        <Avatar
          alt="Avatar"
          className={classNames(
            avatarSize,
            classes.root,
            { [classes.transparent]: profilePicUrl },
            { [classes.noShadow]: noShadow },
          )}
          imgProps={{ referrerPolicy: 'no-referrer' }}
          src={profilePicUrl}
        >
          {!profilePicUrl && initials}
        </Avatar>
      </Box>
      <Menu
        anchorEl={userBtnAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.menuItem}
        getContentAnchorEl={null}
        id="show-user-user-menu"
        onBackdropClick={hideDashboardOptions}
        open={Boolean(userBtnAnchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem */}
        {/*  activeStyle={{ */}
        {/*    fontWeight: 'bold', */}
        {/*  }} */}
        {/*  component={NavLink} */}
        {/*  onClick={hideDashboardOptions} */}
        {/*  style={{ color: 'inherit' }} */}
        {/*  to={AppRouteService.getRelativeUrl('CyclistProfile', { cyclistSlug: auth.user?.slug })} */}
        {/* > */}
        {/*  My Profile */}
        {/* </MenuItem> */}
        {/* <ListSubheader component="div" id="nested-list-subheader"> */}
        {/*  <Divider /> */}
        {/* </ListSubheader> */}
        <MenuItem
          component={NavLink}
          onClick={hideDashboardOptions}
          style={({ isActive }): React.CSSProperties => ({
            color: 'inherit',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          to={AppRouteService.getRelativeUrl('User_Registrations')}
        >
          Registrations & Lessons
        </MenuItem>
        <MenuItem
          component={NavLink}
          onClick={hideDashboardOptions}
          style={({ isActive }): React.CSSProperties => ({
            color: 'inherit',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          to={AppRouteService.getRelativeUrl('User_OnlineCourses')}
        >
          Purchased Online Courses
        </MenuItem>
        <Divider />
        <MenuItem
          component={NavLink}
          onClick={hideDashboardOptions}
          style={({ isActive }): React.CSSProperties => ({
            color: 'inherit',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          to={AppRouteService.getRelativeUrl('User_Messages')}
        >
          Direct Messages
        </MenuItem>
        <MenuItem
          component={NavLink}
          onClick={hideDashboardOptions}
          style={({ isActive }): React.CSSProperties => ({
            color: 'inherit',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          to={AppRouteService.getRelativeUrl('User_EditProfile')}
        >
          Edit Profile
        </MenuItem>
        <MenuItem
          component={NavLink}
          onClick={hideDashboardOptions}
          style={({ isActive }): React.CSSProperties => ({
            color: 'inherit',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          to={AppRouteService.getRelativeUrl('User_AccountSettings')}
        >
          Account Settings
        </MenuItem>

        {showCreatorControls && (
          <>
            <Divider />
            {!hasOrganization && (
              <MenuItem
                component={NavLink}
                onClick={hideDashboardOptions}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('Organizer_Start')}
              >
                Become an Organizer
              </MenuItem>
            )}
            {!auth.user?.isCoach && (
              <MenuItem
                component={NavLink}
                onClick={hideDashboardOptions}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('User_BecomeCoach')}
              >
                Become a Coach
              </MenuItem>
            )}
          </>
        )}
        <Divider />
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAvatar;
