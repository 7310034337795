/*
 * Constants for the validity checker
 * IMPORTANT: Add to validityCheckerDefaults AND ValidityReturnProps. ValidityReturnProps is just
 * the types for the defaults
 */
import { HostEventSectionName } from '@/containers/HostEvent/const';
import { ObjectIndexer } from '@/lib/type-defs/utility';

export enum ValidityTopicsEnum {
  Dates = 'Dates',
  Categories = 'Categories',
}
export enum ValidityDateRulesEnum {
  EventEndAfterRegClose = 'EventEndAfterRegClose',
  ScheduleDatesInsideEventDate = 'ScheduleDatesInsideEventDate',
}
export enum ValidityCategoriesRulesEnum {
  AllCategoriesHaveCourse = 'AllCategoriesHaveCourse',
}

/** ************************************
 * Defaults and default types
 ************************************ */
// Defaults
export const validityCheckerDefaults = {
  [ValidityTopicsEnum.Dates]: {
    [ValidityDateRulesEnum.EventEndAfterRegClose]: {
      ruleName: ValidityDateRulesEnum.EventEndAfterRegClose,
      isValid: null,
      errorMessage: '',
      sectionNames: [],
      remedy: '',
    },
    [ValidityDateRulesEnum.ScheduleDatesInsideEventDate]: {
      ruleName: ValidityDateRulesEnum.ScheduleDatesInsideEventDate,
      isValid: null,
      errorMessage: '',
      sectionNames: [],
      remedy: '',
      invalidDates: [],
    },
  },
  [ValidityTopicsEnum.Categories]: {
    [ValidityCategoriesRulesEnum.AllCategoriesHaveCourse]: {
      ruleName: ValidityCategoriesRulesEnum.AllCategoriesHaveCourse,
      isValid: null,
      errorMessage: '',
      sectionNames: [],
      remedy: '',
      invalidCategories: [],
    },
  },
};
// Types for defaults
export type ValidityErrorProps = {
  ruleName: ValidityDateRulesEnum | ValidityCategoriesRulesEnum;
  errorMessage: string;
  isValid: null | boolean;
  remedy: string;
  sectionNames: HostEventSectionName[];
};
export type ValidityReturnProps = ObjectIndexer & {
  [ValidityTopicsEnum.Dates]: {
    [ValidityDateRulesEnum.EventEndAfterRegClose]: ValidityErrorProps;
    [ValidityDateRulesEnum.ScheduleDatesInsideEventDate]: ValidityErrorProps & {
      invalidDates: { cuid: string; date: string }[];
    };
  };
  [ValidityTopicsEnum.Categories]: {
    [ValidityCategoriesRulesEnum.AllCategoriesHaveCourse]: ValidityErrorProps & {
      invalidCategories: { cuid: string; name: string }[];
    };
  };
};
