/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EventProfileProvider_GetUpcomingOccurrencesQueryVariables = {
    slug: string;
    organizerSlug: string;
};
export type EventProfileProvider_GetUpcomingOccurrencesQueryResponse = {
    readonly event_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly startDate: string | null;
                readonly cuid: string;
            };
        }>;
    };
};
export type EventProfileProvider_GetUpcomingOccurrencesQuery = {
    readonly response: EventProfileProvider_GetUpcomingOccurrencesQueryResponse;
    readonly variables: EventProfileProvider_GetUpcomingOccurrencesQueryVariables;
};



/*
query EventProfileProvider_GetUpcomingOccurrencesQuery(
  $slug: String!
  $organizerSlug: String!
) {
  event_connection(where: {_and: [{eventMetadata: {slug: {_eq: $slug}}}, {deleted_at: {_is_null: true}}, {organizer: {slug: {_eq: $organizerSlug}}}]}, order_by: [{startDate: asc_nulls_last}]) {
    edges {
      node {
        startDate
        cuid
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "organizerSlug"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "slug"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "order_by",
            "value": [
                {
                    "startDate": "asc_nulls_last"
                }
            ]
        } as any,
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_eq",
                                                    "variableName": "slug"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "slug"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "eventMetadata"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "deleted_at": {
                                    "_is_null": true
                                }
                            }
                        },
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_eq",
                                                    "variableName": "organizerSlug"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "slug"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "organizer"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.2"
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "EventProfileProvider_GetUpcomingOccurrencesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "EventProfileProvider_GetUpcomingOccurrencesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f3bcf9cdb648ee9f25cde3370b98d567",
            "id": null,
            "metadata": {},
            "name": "EventProfileProvider_GetUpcomingOccurrencesQuery",
            "operationKind": "query",
            "text": "query EventProfileProvider_GetUpcomingOccurrencesQuery(\n  $slug: String!\n  $organizerSlug: String!\n) {\n  event_connection(where: {_and: [{eventMetadata: {slug: {_eq: $slug}}}, {deleted_at: {_is_null: true}}, {organizer: {slug: {_eq: $organizerSlug}}}]}, order_by: [{startDate: asc_nulls_last}]) {\n    edges {\n      node {\n        startDate\n        cuid\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '6f31dbabbb66ff5a5f34de32f14bc27b';
export default node;
