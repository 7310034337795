import { graphql } from 'relay-runtime';

export const GET_RECENT_CLINICS_QUERY = graphql`
  query ReviewServiceQueries_getRecentClinics_Query(
    $todayDate: timestamp!
    $twoWeeksAgoDate: timestamp!
    $userId: String!
  ) {
    registration_connection(
      where: {
        userId: { _eq: $userId }
        clinic: {
          canceledAt: { _is_null: true }
          # where all clinic days are in the past, i.e. clinic is fully completed
          _not: { clinicDays: { startTime: { _gt: $todayDate } } }
          # at least one date happened within two weeks
          clinicDays: { startTime: { _gte: $twoWeeksAgoDate } }
        }
      }
    ) {
      edges {
        node {
          clinic {
            cuid
            clinicDays(order_by: { startTime: asc }, limit: 1) {
              startTime
            }
          }
          event {
            eventMetadata {
              cuid
              name
            }
            eventImages(where: { position: { _eq: 1 } }) {
              image {
                relativeUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RECENT_LESSONS_COACHES_QUERY = graphql`
  query ReviewServiceQueries_getRecentLessonsCoaches_Query(
    $twoWeeksAgoDate: timestamp!
    $userId: String!
  ) {
    coachCustomerLessonDate_connection(
      where: {
        canceledDate: { _is_null: true }
        startDate: { _gte: $twoWeeksAgoDate }
        coachCustomerLesson: { coachCustomer: { coachCustomerUsers: { userId: { _eq: $userId } } } }
      }
    ) {
      edges {
        node {
          id
          startDate
          coachCustomerLesson {
            coachCuid
            coachLesson {
              cuid
              title
            }
            coach {
              user {
                firstName
                lastName
                profilePicUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RECENT_REVIEWS_QUERY = graphql`
  query ReviewServiceQueries_getRecentReviews_Query(
    $userId: String!
    $oneYearAgoDate: timestamptz!
  ) {
    review_connection(
      where: { reviewerId: { _eq: $userId }, created_at: { _gte: $oneYearAgoDate } }
    ) {
      edges {
        node {
          id
          reviewSubjectCuid
        }
      }
    }
  }
`;

export const SAVE_REVIEW_MUTATION = graphql`
  mutation ReviewServiceQueries_saveReview_Mutation(
    $userId: String!
    $reviewSubjectCuid: String!
    $reviewSubjectType: reviewSubjectType_enum!
    $review: jsonb
    $reviewSubjectInstanceCuid: String
  ) {
    insert_review_one(
      object: {
        reviewerId: $userId
        reviewSubjectType: $reviewSubjectType
        reviewSubjectCuid: $reviewSubjectCuid
        review: $review
        reviewSubjectInstanceCuid: $reviewSubjectInstanceCuid
      }
    ) {
      id
    }
  }
`;

export const GET_REVIEWS_QUERY = graphql`
  query ReviewServiceQueries_getReviews_Query($reviewSubjectCuid: String!) {
    review_connection(
      where: { reviewSubjectCuid: { _eq: $reviewSubjectCuid } }
      order_by: { updated_at: desc }
    ) {
      edges {
        node {
          id
          updated_at
          reviewer {
            firstName
            lastName
            profilePicUrl
            slug
          }
          review
        }
      }
    }
    reviewTotals_connection(where: { reviewSubjectCuid: { _eq: $reviewSubjectCuid } }) {
      edges {
        node {
          count
          recAverage
          recPercentage
        }
      }
    }
  }
`;

export const UPDATE_REVIEW_TO_LATEST_VERSION_MUTATION = graphql`
  mutation ReviewServiceQueries_updateReviewToLatestVersion_Mutation($review: jsonb, $id: Int!) {
    update_review_by_pk(_set: { review: $review }, pk_columns: { id: $id }) {
      id
    }
  }
`;
