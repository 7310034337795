/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewServiceQueries_updateReviewToLatestVersion_MutationVariables = {
    review?: Record<string, unknown> | unknown[] | null | undefined;
    id: number;
};
export type ReviewServiceQueries_updateReviewToLatestVersion_MutationResponse = {
    readonly update_review_by_pk: {
        readonly id: string;
    } | null;
};
export type ReviewServiceQueries_updateReviewToLatestVersion_Mutation = {
    readonly response: ReviewServiceQueries_updateReviewToLatestVersion_MutationResponse;
    readonly variables: ReviewServiceQueries_updateReviewToLatestVersion_MutationVariables;
};



/*
mutation ReviewServiceQueries_updateReviewToLatestVersion_Mutation(
  $review: jsonb
  $id: Int!
) {
  update_review_by_pk(_set: {review: $review}, pk_columns: {id: $id}) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "review"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "review",
                            "variableName": "review"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "_set"
                },
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "pk_columns"
                }
            ],
            "concreteType": "review",
            "kind": "LinkedField",
            "name": "update_review_by_pk",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_updateReviewToLatestVersion_Mutation",
            "selections": (v2 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ReviewServiceQueries_updateReviewToLatestVersion_Mutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "415a950442393a1b54638b3eac4852e8",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_updateReviewToLatestVersion_Mutation",
            "operationKind": "mutation",
            "text": "mutation ReviewServiceQueries_updateReviewToLatestVersion_Mutation(\n  $review: jsonb\n  $id: Int!\n) {\n  update_review_by_pk(_set: {review: $review}, pk_columns: {id: $id}) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0591fc490148fab90f0d4c0be6eda665';
export default node;
