/**
 * Container page for event profile page
 */
import React from 'react';
import { useParams } from 'react-router';

import Page from '../Page';

import MainLayout from '@/layouts/MainLayout';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useGetEventProfileNameGlobal } from '@/pages/EventProfile/recoilStore';

const EventProfilePage: ReactFCC = ({ children }) => {
  const params = useParams();
  const eventProfileNameGlobal = useGetEventProfileNameGlobal();
  const parsedQueryParams = getParsedQueryParams();

  const key = `${params.organizerSlug}-${params.eventSlug}-${parsedQueryParams.occurence}`;

  return (
    <Page description="The Best Event Ever" title={`${eventProfileNameGlobal}`}>
      <MainLayout key={key} stickyHeader={false}>
        {children}
      </MainLayout>
    </Page>
  );
};

export default EventProfilePage;
