/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type activityType_enum = "Clinic" | "Race" | "Ride" | "%future added value";
export type helpers_useSyncSectionValidity_event = {
    readonly cuid: string;
    readonly activity: activityType_enum;
    readonly startDate: string | null;
    readonly regCloseDate: string | null;
    readonly isVirtualEvent: boolean;
    readonly publishedAt: string | null;
    readonly currencyCode: string;
    readonly completedSections: Record<string, unknown> | unknown[];
    readonly occurrenceLabel: string | null;
    readonly eventMetadata: {
        readonly isCategoriedEvent: boolean;
        readonly name: string;
    };
    readonly organizer: {
        readonly cuid: string;
    };
    readonly categories: ReadonlyArray<{
        readonly entryFee: number | null;
    }>;
    readonly rides: ReadonlyArray<{
        readonly priceOverride: number | null;
    }>;
    readonly clinics: ReadonlyArray<{
        readonly priceOverride: number | null;
    }>;
    readonly eventPrograms: ReadonlyArray<{
        readonly defaultPrice: number | null;
    }>;
    readonly " $refType": "helpers_useSyncSectionValidity_event";
};
export type helpers_useSyncSectionValidity_event$data = helpers_useSyncSectionValidity_event;
export type helpers_useSyncSectionValidity_event$key = {
    readonly " $data"?: helpers_useSyncSectionValidity_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_useSyncSectionValidity_event">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v1 = {
        "_is_null": true
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "where",
            "value": {
                "canceledAt": (v1 /*: any*/)
            }
        } as any
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceOverride",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "helpers_useSyncSectionValidity_event",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activity",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regCloseDate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVirtualEvent",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currencyCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedSections",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "occurrenceLabel",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "eventMetadata",
                "kind": "LinkedField",
                "name": "eventMetadata",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCategoriedEvent",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "organizer",
                "kind": "LinkedField",
                "name": "organizer",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                            "position": "asc"
                        }
                    },
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "deleted_at": (v1 /*: any*/)
                        }
                    }
                ],
                "concreteType": "category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entryFee",
                        "storageKey": null
                    }
                ],
                "storageKey": "categories(order_by:{\"position\":\"asc\"},where:{\"deleted_at\":{\"_is_null\":true}})"
            },
            {
                "alias": null,
                "args": (v2 /*: any*/),
                "concreteType": "ride",
                "kind": "LinkedField",
                "name": "rides",
                "plural": true,
                "selections": (v3 /*: any*/),
                "storageKey": "rides(where:{\"canceledAt\":{\"_is_null\":true}})"
            },
            {
                "alias": null,
                "args": (v2 /*: any*/),
                "concreteType": "clinic",
                "kind": "LinkedField",
                "name": "clinics",
                "plural": true,
                "selections": (v3 /*: any*/),
                "storageKey": "clinics(where:{\"canceledAt\":{\"_is_null\":true}})"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "eventProgram",
                "kind": "LinkedField",
                "name": "eventPrograms",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultPrice",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "event",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'c5fc2b91a0310d92037914cd10c8c67b';
export default node;
