/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type activityType_enum = "Clinic" | "Race" | "Ride" | "%future added value";
export type HeaderContainer_event = {
    readonly activity: activityType_enum;
    readonly startDate: string | null;
    readonly endDate: string | null;
    readonly lastSavedAt: string;
    readonly canceledAt: string | null;
    readonly isEventCreationComplete: boolean;
    readonly registrations_aggregate: {
        readonly aggregate: {
            readonly count: number;
        } | null;
    };
    readonly eventMetadata: {
        readonly cuid: string;
        readonly name: string;
        readonly isCategoriedEvent: boolean;
        readonly events: ReadonlyArray<{
            readonly cuid: string;
        }>;
    };
    readonly " $refType": "HeaderContainer_event";
};
export type HeaderContainer_event$data = HeaderContainer_event;
export type HeaderContainer_event$key = {
    readonly " $data"?: HeaderContainer_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"HeaderContainer_event">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "HeaderContainer_event",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activity",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastSavedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canceledAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isEventCreationComplete",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "_and": [
                                {
                                    "_or": [
                                        {
                                            "status": {
                                                "_eq": "complete"
                                            }
                                        },
                                        {
                                            "status": {
                                                "_eq": "canceled"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ],
                "concreteType": "registration_aggregate",
                "kind": "LinkedField",
                "name": "registrations_aggregate",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "registration_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "count",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "registrations_aggregate(where:{\"_and\":[{\"_or\":[{\"status\":{\"_eq\":\"complete\"}},{\"status\":{\"_eq\":\"canceled\"}}]}]})"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "eventMetadata",
                "kind": "LinkedField",
                "name": "eventMetadata",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCategoriedEvent",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "where",
                                "value": {
                                    "deleted_at": {
                                        "_is_null": true
                                    }
                                }
                            }
                        ],
                        "concreteType": "event",
                        "kind": "LinkedField",
                        "name": "events",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/)
                        ],
                        "storageKey": "events(where:{\"deleted_at\":{\"_is_null\":true}})"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "event",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7d15fe9fcd2aac15480608375e73d464';
export default node;
