/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OccurrenceInfo_event = {
    readonly organizerCuid: string;
    readonly occurrenceLabel: string | null;
    readonly endDate: string | null;
    readonly registrations_aggregate: {
        readonly aggregate: {
            readonly count: number;
        } | null;
    };
    readonly eventMetadata: {
        readonly name: string;
    };
    readonly " $refType": "OccurrenceInfo_event";
};
export type OccurrenceInfo_event$data = OccurrenceInfo_event;
export type OccurrenceInfo_event$key = {
    readonly " $data"?: OccurrenceInfo_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OccurrenceInfo_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrenceInfo_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizerCuid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "occurrenceLabel",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "_and": [
                            {
                                "_or": [
                                    {
                                        "status": {
                                            "_eq": "complete"
                                        }
                                    },
                                    {
                                        "status": {
                                            "_eq": "canceled"
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }
            ],
            "concreteType": "registration_aggregate",
            "kind": "LinkedField",
            "name": "registrations_aggregate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "registration_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "registrations_aggregate(where:{\"_and\":[{\"_or\":[{\"status\":{\"_eq\":\"complete\"}},{\"status\":{\"_eq\":\"canceled\"}}]}]})"
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "eventMetadata",
            "kind": "LinkedField",
            "name": "eventMetadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '3de7b1efee2de382e9fd7949b4876715';
export default node;
