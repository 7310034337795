import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Theme,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DialogCloseButton from '@/ui/Button/DialogCloseButton';
import ExtendedButton from '@/ui/Button/ExtendedButton';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    // maxWidth: 850,
    maxWidth: 650,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      height: '100%',
      margin: 0,
      maxHeight: 'none',
      width: '100%',
    },
  },
}));

interface InfoDialogProps {
  cancelText?: string;
  hideDialog: () => void;
  isOpen: boolean;
  okText?: string;
  onCancel?: () => void;
  onOK?: (e?: React.MouseEvent<unknown>) => void;
  title: ReactNode;
  showCloseButton?: boolean;
}
const InfoDialog: React.FC<InfoDialogProps & Omit<DialogProps, 'open'>> = ({
  cancelText = 'Cancel',
  children,
  hideDialog,
  isOpen,
  okText = 'OK',
  onCancel,
  onOK,
  showCloseButton,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const onOKHandler = (): void => {
    if (onOK) {
      onOK();
    }
    hideDialog();
  };
  const onCancelHandler = (): void => {
    if (onCancel) {
      onCancel();
    }
    hideDialog();
  };
  return (
    <Dialog {...rest} classes={{ paper: classes.dialog }} onClose={hideDialog} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      {showCloseButton && <DialogCloseButton handleClose={hideDialog} />}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onCancel && (
          <ExtendedButton minWidth="md" onClick={onCancelHandler} variant="outlined">
            {cancelText}
          </ExtendedButton>
        )}
        {onOK && (
          <ExtendedButton color="primary" minWidth="md" onClick={onOKHandler} variant="contained">
            {okText}
          </ExtendedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
