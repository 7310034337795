/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CoachProfileCheckerProviderQueryVariables = {
    coachSlug: string;
};
export type CoachProfileCheckerProviderQueryResponse = {
    readonly user_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly coach: {
                    readonly cuid: string;
                } | null;
            };
        }>;
    };
};
export type CoachProfileCheckerProviderQuery = {
    readonly response: CoachProfileCheckerProviderQueryResponse;
    readonly variables: CoachProfileCheckerProviderQueryVariables;
};



/*
query CoachProfileCheckerProviderQuery(
  $coachSlug: String!
) {
  user_connection(where: {_and: [{slug: {_eq: $coachSlug}}, {isCoach: {_eq: true}}, {deleted_at: {_is_null: true}}]}) {
    edges {
      node {
        id
        coach {
          cuid
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "coachSlug"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "kind": "Variable",
                                            "name": "_eq",
                                            "variableName": "coachSlug"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "slug"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "isCoach": {
                                    "_eq": true
                                }
                            }
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.2",
                            "value": {
                                "deleted_at": {
                                    "_is_null": true
                                }
                            }
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CoachProfileCheckerProviderQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "userConnection",
                    "kind": "LinkedField",
                    "name": "user_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "userEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "user",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "coach",
                                            "kind": "LinkedField",
                                            "name": "coach",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CoachProfileCheckerProviderQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "userConnection",
                    "kind": "LinkedField",
                    "name": "user_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "userEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "user",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "coach",
                                            "kind": "LinkedField",
                                            "name": "coach",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "259e0ec13056208845f83b56cf0f6451",
            "id": null,
            "metadata": {},
            "name": "CoachProfileCheckerProviderQuery",
            "operationKind": "query",
            "text": "query CoachProfileCheckerProviderQuery(\n  $coachSlug: String!\n) {\n  user_connection(where: {_and: [{slug: {_eq: $coachSlug}}, {isCoach: {_eq: true}}, {deleted_at: {_is_null: true}}]}) {\n    edges {\n      node {\n        id\n        coach {\n          cuid\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8e9e45fa9e44cf2f120e6ec60acf80c1';
export default node;
