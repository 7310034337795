/*
Recoil atoms, selectors and hooks for the entire Reggy application
 */
import { atom, selector, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * Global Route Properties
 ************************************* */
// const currentAlgoliaQueryId = atom({
//   key: 'currentAlgoliaQueryId', // unique ID (with respect to other atoms/selectors)
//   default: '', // default value (aka initial value)
// });
// export const useGetCurrentAlgoliaQueryIdGlobal = (): string => {
//   return useRecoilValue(currentAlgoliaQueryId);
// };
// export const useSetCurrentAlgoliaQueryIdGlobal = (): SetterOrUpdater<string> => {
//   return useSetRecoilState(currentAlgoliaQueryId);
// };

/** ************************************
 * Clear Algolia Cache
 * The useInstantSearch() hook doesn't work yet so
 * we need to use global state to grab it from initialization
 ************************************* */
type ClearAlgoliaCacheType = null | (() => Promise<void>);
const clearAlgoliaCacheGlobal = atom<ClearAlgoliaCacheType>({
  key: 'clearAlgoliaCacheGlobal', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const useGetClearAlgoliaCacheGlobal = (): ClearAlgoliaCacheType => {
  return useRecoilValue(clearAlgoliaCacheGlobal);
};
export const useSetClearAlgoliaCacheGlobal = (): SetterOrUpdater<ClearAlgoliaCacheType> => {
  return useSetRecoilState(clearAlgoliaCacheGlobal);
};

/** ************************************
 * Confetti!
 * Confetti Component lives in ExtendedRoute Component
 ************************************* */
const isConfetti = atom({
  key: 'isConfetti', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsConfettiGlobal = (): boolean => {
  return useRecoilValue(isConfetti);
};
export const useSetIsConfettiGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isConfetti);
};

/** ************************************
 * Edit Profile Dialog
 ************************************* */
const editProfileDialog = atom({
  key: 'editProfileDialog', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsEditProfileDialogOpenGlobal = (): boolean => {
  return useRecoilValue(editProfileDialog);
};
export const useSetIsEditProfileDialogOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(editProfileDialog);
};

/** ************************************
 * Is adblocker running
 ************************************* */
const IS_ADBLOCKER_RUNNING_KEY = 'verifiedUser';
export const getIsAdBlockerRunning = async (): Promise<boolean> => {
  // If we do this, we need to expire it.
  // const isAdBlockerRunning = sessionStorage.getItem(IS_ADBLOCKER_RUNNING_KEY);
  // if (isAdBlockerRunning === 'true') {
  //   return true;
  // }
  // if (isAdBlockerRunning === 'false') {
  //   return false;
  // }
  // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
  const segmentUrl = 'https://api.segment.io';
  const segmentCDNUrl = 'https://cdn.segment.com';
  const sentryUrl = 'https://o267005.ingest.sentry.io';
  try {
    await fetch(segmentUrl, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    });
    await fetch(segmentCDNUrl, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    });
    await fetch(sentryUrl, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    });
    sessionStorage.setItem(IS_ADBLOCKER_RUNNING_KEY, String(false));
    return false;
  } catch (error) {
    sessionStorage.setItem(IS_ADBLOCKER_RUNNING_KEY, String(true));
    return true;
  }
};
export const isAdBlockerRunningState = selector<boolean | undefined>({
  key: 'isAdBlockerRunningState',
  get: getIsAdBlockerRunning,
});
const isAdBlockerRunning = atom({
  key: 'isAdBlockerRunning', // unique ID (with respect to other atoms/selectors)
  default: isAdBlockerRunningState, // default value (aka initial value)
});
export const useGetIsAdBlockerRunningGlobal = (): boolean | undefined => {
  return useRecoilValue(isAdBlockerRunning);
};
// Not used. Set above
// export const useSetIsAdBlockerRunningGlobal = (): SetterOrUpdater<boolean | undefined> => {
//   return useSetRecoilState(isAdBlockerRunning);
// };
