import React, { CanvasHTMLAttributes } from 'react';
import ReactConfetti from 'react-confetti';
// eslint-disable-next-line import/no-unresolved
import { IConfettiOptions } from 'react-confetti/dist/types/Confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

export default React.forwardRef<
  HTMLCanvasElement,
  Partial<IConfettiOptions> &
    CanvasHTMLAttributes<HTMLCanvasElement> & {
      canvasRef?: React.Ref<HTMLCanvasElement>;
    }
>((passedProps, ref) => {
  const { height, width } = useWindowSize();
  return <ReactConfetti height={height} width={width} {...passedProps} ref={ref} />;
});
