/**
 * react-hook-form doesn't validate the correct date so we have to manually set the values
 * Add reactHookFormProps to pass down react-hook-form values
 * Select made from TextField
 */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Control, Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { TextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { useExtendedSelectStyles } from '@/ui/TextField/ExtendedSelect';

import ExtendedTextField, { ExtendedTextFieldProps } from './ExtendedTextField';
import MultiSelectChip from './MultiSelectChip';

import { reactChildrenToFlatArray } from '@/lib/ui-utils';

export interface FormSelectProps<T> {
  centerChips?: boolean;
  dontAllowEmptyMessage?: string;
  isLoading?: boolean;
  minWidth?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg';
  multiple?: boolean;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions;
  size?: 'small';
  zeroWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any, object>;
  onChange?: (event: React.ChangeEvent<{ value: string }>, oldValue: string) => void;
}
function FormSelect<T>({
  centerChips,
  children,
  className,
  formMethods,
  multiple,
  name,
  onChange,
  placeholder,
  ...rest
}: FormSelectProps<T> &
  Omit<ExtendedTextFieldProps, 'onChange'> &
  Omit<TextFieldProps, 'onChange'>): ReactElement {
  const classes = useExtendedSelectStyles();
  const { control, getValues } = formMethods;

  /**
   * Controlled so we con close on a multiselect
   */
  const [isOpen, setIsOpen] = useState(false);
  const onClose = (): void => {
    setIsOpen(false);
    if (rest.SelectProps && rest.SelectProps.onClose) {
      rest.SelectProps.onClose();
    }
  };
  const onOpen = (): void => {
    setIsOpen(true);
  };

  if (!control) {
    Error('No control prop. Pass react-hook-form context');
  }

  // Flag so we can remove some padding once a multiple select has items
  const isMultipleAndHasItems = multiple && RA.isNotNilOrEmpty(getValues(name));

  /**
   * We don't have the labels for the menu items when we use multiple select.
   * To get the label, take the "children" from each menu item. To the get the
   * value we take the value prop
   * We make an object array [{value: child}] that we use to make the Chips
   */
  const [childrenValueLabels, setChildrenValueLabels] = useState<
    { [key in string | number]: string | number | ReactElement }[] | []
  >([]);

  useEffect(() => {
    if (multiple && RA.isNonEmptyArray(children)) {
      setChildrenValueLabels(
        children.map((child) => {
          return { [(child as ReactElement).props.value]: (child as ReactElement).props.children };
        }),
      );
    }
  }, [String(reactChildrenToFlatArray(children)), multiple]);

  const multipleSelectProps = {
    SelectProps: {
      ...rest.SelectProps,
      onClose,
      onOpen,
      open: isOpen,
      classes: { ...rest.SelectProps?.classes, select: classes.multiSelectPadding },
      multiple: true,
      renderValue: (selected: string[] & number[]): ReactNode => {
        if (RA.isNotArray(selected)) {
          throw Error(`Value: ${selected} is not an array`);
        }
        return (
          <Box textAlign={centerChips ? 'center' : ''}>
            {selected.map((val) => {
              const stringVal = val.toString();
              const label = R.values(R.find(R.has(stringVal), childrenValueLabels) ?? {});
              if (!label) {
                throw Error('No label found');
              }
              return <MultiSelectChip key={`MultiSelectChip${val}`} label={label[0]} />;
            })}
          </Box>
        );
      },
      // },
      MenuProps: {
        classes: { ...rest.SelectProps?.MenuProps?.classes, paper: classes.popover },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    } as SelectProps,
  };

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue}
      name={name}
      render={({ field: { onBlur, onChange: rhfOnChange, value } }): ReactElement => (
        <>
          {placeholder &&
            RA.isNilOrEmpty(value) &&
            ((rest.InputLabelProps?.shrink && rest.label) || !rest.label) && (
              <Typography
                className={classNames(
                  classes.placeholderText,
                  { [classes.placeholderTextFilled]: rest.variant === 'filled' },
                  { [classes.placeholderTextOutlined]: rest.variant === 'outlined' },
                  {
                    [classes.placeholderTextUnderlined]:
                      !rest.variant || rest.variant === 'underlined',
                  },
                )}
                color="textSecondary"
              >
                {placeholder}
              </Typography>
            )}
          <ExtendedTextField
            {...rest}
            select
            SelectProps={{
              ...rest.SelectProps,
              MenuProps: {
                classes: { paper: classes.popover, ...rest.SelectProps?.MenuProps?.classes },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            className={classNames(
              {
                [classes.smallPadding]: isMultipleAndHasItems,
              },
              className,
            )}
            onBlur={onBlur}
            onChange={(e): void => {
              rhfOnChange(e);
              if (onChange) {
                onChange(e, value);
              }
              // For multiple select
              onClose();
            }}
            value={value ?? ''}
            {...(multiple && multipleSelectProps)}
          >
            <option style={{ display: 'none' }}>{value}</option>
            {children}
          </ExtendedTextField>
        </>
      )}
    />
  );
}

export default FormSelect;
