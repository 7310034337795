import React, { useState } from 'react';
import { Box, Button, Link, makeStyles, Slide, Theme, Typography } from '@material-ui/core';
import classNames from 'classnames';

import ResponseInput from './ResponseInput';

import ProfileImage from '../User/ProfileImage';

import { useIsSmallScreenUp } from '@/lib/ui-utils';
import { Category, Question, Response, SubjectType } from '@/services/review/questions';
import { Review } from '@/services/review/ReviewService';
import { useServicesContext } from '@/services/Services';

export interface ReviewItemProps {
  name: string;
  initials?: string;
  imageUrl?: string;
  questions: Question<Category>[];
  subjectCuid: string;
  subjectType: SubjectType;
  subjectInstanceCuid?: string;
  onSave: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  responseInputWrapper: {
    position: 'relative',
  },
  navLink: {
    display: 'inline-block',
    marginRight: theme.spacing(3),
  },
}));

const ReviewItem: React.FC<ReviewItemProps> = ({
  imageUrl,
  initials,
  name,
  onSave,
  questions,
  subjectCuid,
  subjectInstanceCuid,
  subjectType,
}) => {
  const classes = useStyles();
  const services = useServicesContext();
  const isSmallScreenUp = useIsSmallScreenUp();
  const [responses, setResponses] = useState<Record<string, Response>>({});

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [reviewCompleted, setReviewCompleted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onChange = (response: Response): void => {
    const newResponses = { ...responses };
    newResponses[response.questionId] = response;
    setResponses(newResponses);

    if (response.value !== undefined && currentQuestion !== questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const save = async (): Promise<void> => {
    setIsSaving(true);
    let questionsKey: keyof Review;
    if (subjectType === 'clinic') {
      questionsKey = 'clinicQuestions';
    } else if (subjectType === 'coach') {
      questionsKey = 'coachQuestions';
    } else {
      return;
    }
    const review = {
      [questionsKey]: questions,
      responses,
    };
    await services.review.saveReview(
      subjectCuid,
      subjectType,
      review as Review,
      subjectInstanceCuid,
    );
    setIsSaving(false);
    setReviewCompleted(true);

    setTimeout(() => {
      onSave();
      setResponses({});
      setCurrentQuestion(0);
      setReviewCompleted(false);
    }, 2500);
  };

  const goToPreviousQuestion = (): void => setCurrentQuestion(currentQuestion - 1);
  const goToNextQuestion = (): void => setCurrentQuestion(currentQuestion + 1);

  const onFirstQuestion = currentQuestion === 0;
  const onLastQuestion = currentQuestion === questions.length - 1;
  const currentQuestionAnswered = responses[questions[currentQuestion].id]?.value !== undefined;

  const reviewIsValid = questions.every((q) => {
    const r = responses[q.id];
    if (r) {
      if (r.type === 'y/n' || r.type === 'open') {
        return r.value !== undefined;
      }
      return r.value !== undefined && r.why !== undefined && r.why.trim().length > 10;
    }
    return false;
  });

  return (
    <Box alignItems="flexStart" display="flex">
      {isSmallScreenUp && subjectType === 'coach' && (
        <Box mr={2}>
          <ProfileImage small imageUrl={imageUrl} initials={initials} />
        </Box>
      )}
      <Box flexGrow={1}>
        {!reviewCompleted && (
          <Box mb={1}>
            <Typography variant="overline">
              Question {currentQuestion + 1} of {questions.length}
            </Typography>
            {questions.map((q, i) => (
              <Slide
                mountOnEnter
                unmountOnExit
                direction="left"
                exit={false}
                in={currentQuestion === i}
                key={q.id}
              >
                <Box className={classes.responseInputWrapper}>
                  <ResponseInput onChange={onChange} question={q} response={responses[q.id]} />
                </Box>
              </Slide>
            ))}
            {onLastQuestion && (
              <Box mt={1}>
                <Button
                  color="primary"
                  disabled={!reviewIsValid || isSaving}
                  onClick={save}
                  variant="contained"
                >
                  Submit Review
                </Button>
              </Box>
            )}
            <Box mt={3}>
              {!onFirstQuestion && (
                <Link
                  className={classNames(classes.navLink, 'secondary')}
                  component="button"
                  onClick={goToPreviousQuestion}
                >
                  <Typography color="textSecondary" display="inline" variant="body2">
                    Back
                  </Typography>
                </Link>
              )}
              {!onLastQuestion && currentQuestionAnswered && (
                <Link
                  className={classNames(classes.navLink, 'secondary')}
                  component="button"
                  onClick={goToNextQuestion}
                >
                  <Typography color="textSecondary" display="inline" variant="body2">
                    Next
                  </Typography>
                </Link>
              )}
            </Box>
          </Box>
        )}
        {reviewCompleted && (
          <Box mt={3}>
            <Typography paragraph variant="body1">
              Thank you for reviewing your experience with <span className="strong">{name}</span>!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewItem;
