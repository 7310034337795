import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';
import * as RA from 'ramda-adjunct';

import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import ExtendedRoute from '../../components/ExtendedRoute';
import FullScreenLoadingPlaceholder from '@/components/LoadingPlaceholder/FullScreenLoadingPlaceholder';

import OrganizerLayout from '@/layouts/OrganizerLayout';
import { replacePathNameWithoutReloadingPage } from '@/lib/path-helpers/routing';
import CreatorCheckPage from '@/pages/CreatorCheckPage';
import PageNotFound from '@/pages/ErrorPages/PageNotFound';
import HostEventPage from '@/pages/HostEvent/HostEventPage';
import OrganizerPage from '@/pages/Organizer/OrganizerPage';
import Page from '@/pages/Page';
import { useAuth } from '@/providers/AuthProvider';
import HostEventProvider from '@/providers/HostEventProvider';
import { AppRouteService } from '@/routes/RouteService';

const CreateEventOnboardingView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateEventOnboardingView" */ '@/containers/Organizer/OrganizerOnboarding/CreateEventOnboardingView'
    ),
);

const CreateOrganizerView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateOrganizerView" */ '@/containers/Organizer/CreateOrganizer/CreateOrganizerView'
    ),
);
const HostEventMainView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "HostEventMainView" */ '@/containers/HostEvent/Main/HostEventMainView'
    ),
);
const AllEventsView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AllEventsView" */ '@/containers/Organizer/AllEvents/AllEventsView'
    ),
);

const BillingView = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "BillingView" */ '@/containers/Organizer/Billing/BillingView'),
);

const CreateEventView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateEventView" */ '@/containers/Organizer/CreateEvent/CreateEventView'
    ),
);
const EventsView = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "EventsView" */ '@/containers/Organizer/Events/EventsView'),
);

const ManageEventView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "ManageEventView" */ '@/containers/Organizer/Manage/ManageEventView'
    ),
);

const ManageRegistrationView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "ManageRegistrationView" */ '@/containers/Organizer/ManageRegistration/ManageRegistrationView'
    ),
);

const AnalyticsView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AnalyticsView" */ '@/containers/Organizer/Analytics/AnalyticsView'
    ),
);

// const ScheduleView = LazyloadComponentWithRetry(
//   () =>
//     import(/* webpackChunkName: "ScheduleView" */ '@/containers/Organizer/Schedule/ScheduleView'),
// );

const TeamView = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "TeamView" */ '@/containers/Organizer/Team/TeamView'),
);

const OrganizerMessagesView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OrganizerMessagesView" */ '@/containers/Organizer/Messages/OrganizerMessagesView'
    ),
);

const SeriesLoader = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "SeriesLoader" */ '@/containers/Organizer/Series/SeriesLoader'),
);

const CreateOnlineCourseView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateOnlineCourseView" */ '@/containers/OnlineCourse/CreateOnlineCourse/CreateOnlineCourseView'
    ),
);

const OrganizerRoutes: React.FC = () => {
  const auth = useAuth();
  const hasOrganization = RA.isNotEmpty(auth.user?.userOrganizerRoles);

  // User doesn't have an organization yet. Make them create a new one.
  if (!hasOrganization) {
    replacePathNameWithoutReloadingPage(AppRouteService.getRelativeUrl('Organizer_Start'));
    return (
      <CreatorCheckPage>
        <Page noIndex title="Create Organization • Reggy">
          <OrganizerLayout showHeader={false}>
            <CreateOrganizerView />
          </OrganizerLayout>
        </Page>
      </CreatorCheckPage>
    );
  }

  // find organizer slug from userOrganizerRoles based on selectedOrganizerSlug
  // For use below. We check permissions in a wrapper
  const selectedOrganizerRole = auth.user?.userOrganizerRoles.find(
    (uor) => uor.organizer?.slug === auth.user?.selectedOrganizerSlug,
  );
  const selectedOrganizerSlug =
    selectedOrganizerRole?.organizer?.slug ||
    auth.user?.userOrganizerRoles[0]?.organizer?.slug ||
    null;

  return (
    <Routes>
      {/* Redirect to the users most recent selected organization. */}
      <Route
        element={
          <ExtendedRoute
            trackPageView
            validRoles={AppRouteService.getRouteValidRoles('OrganizerApp_Events')}
          >
            <div>
              <Navigate
                replace
                to={AppRouteService.getRelativeUrl('OrganizerApp_Events', {
                  // Cant be null because we check in ExtendedRoute
                  organizerSlug: selectedOrganizerSlug!,
                })}
              />
            </div>
          </ExtendedRoute>
        }
        path="/"
      />

      <Route
        element={
          <ExtendedRoute validRoles={AppRouteService.getRouteValidRoles('OrganizerApp')}>
            <Suspense fallback={<FullScreenLoadingPlaceholder />}>
              <Routes>
                {/* Become an organizer */}
                <Route
                  element={
                    <ExtendedRoute
                      trackPageView
                      validRoles={AppRouteService.getRouteValidRoles(
                        'OrganizerApp_OrganizerOnboarding',
                      )}
                    >
                      <CreateEventOnboardingView />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('OrganizerApp_OrganizerOnboarding')}
                />

                {/* Host Event App */}
                <Route
                  element={
                    <ExtendedRoute
                      trackPageView
                      validRoles={AppRouteService.getRouteValidRoles('OrganizerApp_EditEvent')}
                    >
                      <HostEventProvider>
                        <HostEventPage>
                          <HostEventMainView />
                        </HostEventPage>
                      </HostEventProvider>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('OrganizerApp_EditEvent')}
                />

                {/* Organizer App */}
                <Route
                  element={
                    <ExtendedRoute>
                      <OrganizerPage>
                        <Suspense fallback={<FullScreenLoadingPlaceholder />}>
                          <Routes>
                            <Route
                              element={
                                <div>
                                  <Navigate
                                    replace
                                    to={AppRouteService.getRelativeUrl('OrganizerApp_Events', {
                                      // Cant be null because we check in ExtendedRoute
                                      organizerSlug: selectedOrganizerSlug!,
                                    })}
                                  />
                                </div>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp')}
                            />

                            <Route
                              element={
                                <ExtendedRoute>
                                  <Routes>
                                    <Route
                                      element={
                                        <ExtendedRoute trackPageView>
                                          <EventsView />
                                        </ExtendedRoute>
                                      }
                                      path={AppRouteService.getRouterRoutePath(
                                        'OrganizerApp_Events',
                                      )}
                                    />
                                    <Route
                                      element={
                                        <ExtendedRoute
                                          trackPageView
                                          validRoles={AppRouteService.getRouteValidRoles(
                                            'OrganizerApp_ManageEvent',
                                          )}
                                        >
                                          <ManageEventView />
                                        </ExtendedRoute>
                                      }
                                      path={AppRouteService.getRouterRoutePath(
                                        'OrganizerApp_ManageEvent',
                                      )}
                                    />
                                    <Route
                                      element={
                                        <ExtendedRoute
                                          trackPageView
                                          validRoles={AppRouteService.getRouteValidRoles(
                                            'OrganizerApp_ManageRegistration',
                                          )}
                                        >
                                          <ManageRegistrationView />
                                        </ExtendedRoute>
                                      }
                                      path={AppRouteService.getRouterRoutePath(
                                        'OrganizerApp_ManageRegistration',
                                      )}
                                    />

                                    <Route
                                      element={
                                        <ExtendedRoute trackPageView>
                                          <PageNotFound />
                                        </ExtendedRoute>
                                      }
                                      path="*"
                                    />
                                  </Routes>
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterDirectoryPath('Organizer_App_Events')}
                            />

                            <Route
                              element={
                                <ExtendedRoute trackPageView>
                                  <AllEventsView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_AllEvents')}
                            />

                            {/* <ExtendedRoute
                          path={AppRouteService.getRouterRoutePath('OrganizerApp_Schedule')}
                        >
                          <ScheduleView />
                        </ExtendedRoute> */}

                            <Route
                              element={
                                <ExtendedRoute
                                  trackPageView
                                  validRoles={AppRouteService.getRouteValidRoles(
                                    'OrganizerApp_Analytics',
                                  )}
                                >
                                  <AnalyticsView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_Analytics')}
                            />

                            <Route
                              element={
                                <ExtendedRoute trackPageView>
                                  <TeamView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_Team')}
                            />

                            <Route
                              element={
                                <ExtendedRoute
                                  trackPageView
                                  validRoles={AppRouteService.getRouteValidRoles(
                                    'OrganizerApp_Billing',
                                  )}
                                >
                                  <BillingView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_Billing')}
                            />

                            <Route
                              element={
                                <ExtendedRoute>
                                  <Routes>
                                    {/* React Router doesn't support optional parameter paths so we have to make two routes */}
                                    {/* https://github.com/remix-run/react-router/issues/7285 */}
                                    <Route
                                      element={
                                        <ExtendedRoute trackPageView>
                                          <OrganizerMessagesView />
                                        </ExtendedRoute>
                                      }
                                      path={AppRouteService.getRouterRoutePath(
                                        'OrganizerApp_Messages',
                                      )}
                                    />
                                    <Route
                                      element={
                                        <ExtendedRoute trackPageView>
                                          <OrganizerMessagesView />
                                        </ExtendedRoute>
                                      }
                                      path={AppRouteService.getRouterRoutePath(
                                        'OrganizerApp_Messages_Channel',
                                      )}
                                    />

                                    <Route
                                      element={
                                        <ExtendedRoute trackPageView>
                                          <PageNotFound />
                                        </ExtendedRoute>
                                      }
                                      path="*"
                                    />
                                  </Routes>
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterDirectoryPath(
                                'Organizer_App_Messages',
                              )}
                            />

                            <Route
                              element={
                                <ExtendedRoute trackPageView>
                                  <CreateOrganizerView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath(
                                'OrganizerApp_CreateOrganizer',
                              )}
                            />

                            <Route
                              element={
                                <ExtendedRoute
                                  trackPageView
                                  validRoles={AppRouteService.getRouteValidRoles(
                                    'OrganizerApp_CreateEvent',
                                  )}
                                >
                                  <CreateEventView />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_CreateEvent')}
                            />

                            <Route
                              element={
                                <ExtendedRoute trackPageView>
                                  <SeriesLoader />
                                </ExtendedRoute>
                              }
                              path={AppRouteService.getRouterRoutePath('OrganizerApp_Series')}
                            />
                            {/* 404 Page */}
                            <Route
                              element={
                                <ExtendedRoute trackPageView>
                                  <PageNotFound />
                                </ExtendedRoute>
                              }
                              path="*"
                            />
                          </Routes>
                        </Suspense>
                      </OrganizerPage>
                    </ExtendedRoute>
                  }
                  path="*"
                />

                <Route
                  element={
                    <ExtendedRoute
                      trackPageView
                      validRoles={AppRouteService.getRouteValidRoles(
                        'OrganizerApp_CreateOnlineCourse_New',
                      )}
                    >
                      <Page noIndex title="Create Online Course • Reggy">
                        <CreateOnlineCourseView />
                      </Page>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('OrganizerApp_CreateOnlineCourse_New')}
                />
                <Route
                  element={
                    <ExtendedRoute
                      trackPageView
                      validRoles={AppRouteService.getRouteValidRoles(
                        'OrganizerApp_CreateOnlineCourse_Existing',
                      )}
                    >
                      <Page noIndex title="Create Online Course • Reggy">
                        <CreateOnlineCourseView />
                      </Page>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath(
                    'OrganizerApp_CreateOnlineCourse_Existing',
                  )}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <PageNotFound />
                    </ExtendedRoute>
                  }
                  path="*"
                />
              </Routes>
            </Suspense>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('Organizer_App')}
      />

      <Route
        element={
          <ExtendedRoute trackPageView>
            <PageNotFound />
          </ExtendedRoute>
        }
        path="*"
      />
    </Routes>
  );
};

export default OrganizerRoutes;
