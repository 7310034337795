import { Theme } from '@material-ui/core';

export const MuiInputLabel = (theme: Theme): Record<string, unknown> => ({
  outlined: {
    '&:not(.MuiInputLabel-shrink).MuiInputLabel-marginDense': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '&.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      transform: 'translate(16px, -6px) scale(.75)',
    },
  },
  filled: {
    '&.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      transform: 'translate(0px, -14px) scale(0.75) !important',
    },
    '&:not(.MuiInputLabel-shrink).MuiInputLabel-marginDense': {
      transform: 'translate(12px, 12px) scale(1)',
    },
  },
});
