/**
 * Make sure the user is a creator before they move on to coach or organizer apps
 */
import React, { useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { commitLocalUpdate } from 'relay-runtime';

import BrandTypography from '@/ui/BrandTypography';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import ExtendedLink from '@/ui/ExtendedLink';
import FormOrDivider from '@/ui/FormOrDivider';
import LoadingBounce from '@/ui/LoadingPlaceholders/LoadingBounce';
import ExtendedTextField from '@/ui/TextField/ExtendedTextField';

import { useTrackSentryError } from '@/analytics/sentry';
import { CreatorTypeEnum } from '@/const';
import {
  useSetAuthRedirectURLGlobal,
  useSetLoginSignupTeaserMessageGlobal,
} from '@/containers/Auth/recoilStore';
import { checkAcceptCreatorInviteCF, validateCreatorCodeCF } from '@/lib/firebase';
import { getParsedQueryParams, useOpenSignupDialog } from '@/lib/path-helpers';
import { getUserDefaultAddress, getUserFullName, makeUserCityState } from '@/lib/selectors/user';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(6),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  image: {
    width: '100%',
    height: '248px',
    maxWidth: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: 4,
    backgroundImage: `url('https://res.cloudinary.com/goreggy/image/upload/v1641355054/site/siteImages/xfh1pjpiydsbplbf4s9a.jpg')`,
  },
  textContainer: {
    zIndex: 2,
  },

  discord: {
    transition: 'all 300ms',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      transform: 'translateY(-4px)',
    },
  },
}));

const CreatorCheckContainer: React.FC = () => {
  const classes = useStyles();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const queryParams = getParsedQueryParams();
  const openSignupDialog = useOpenSignupDialog();
  const trackSentryError = useTrackSentryError();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setLoginSignupTeaserMessageGlobal = useSetLoginSignupTeaserMessageGlobal();
  const [codeValue, setCodeValue] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isNewCreator, setIsNewCreator] = useState(false);
  const [isValidateLoading, setIsValidateLoading] = useState(false);

  const defaultAddress = getUserDefaultAddress(auth.user);
  const airTableLink = `https://airtable.com/shrPCxfoxD8FvCT6R?prefill_creatortype=${
    queryParams?.creatorType || ''
  }&prefill_location=${
    makeUserCityState({ includeCountry: true, userAddress: defaultAddress }) ?? ''
  }&prefill_Name=${getUserFullName(auth.user) ?? ''}&prefill_Email=${auth.user?.email ?? ''}`;

  const updateUserLocally = (isCreator: boolean): void => {
    commitLocalUpdate(environment, (store) => {
      const userRecord = store.get(auth.user!.id);
      if (!userRecord) {
        return;
      }
      userRecord.setValue(isCreator, 'isCreator');
    });
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (auth.user && !auth.user?.isCreator) {
          setIsLoading(true);
          const response = await checkAcceptCreatorInviteCF();
          // returns true if the user is a creator, false if not
          setIsNewCreator(response.data);
          updateUserLocally(response.data);
        }
      } catch (err) {
        trackSentryError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [!!auth.user]);

  /**
   * handle code change
   */
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCodeValue(e.target.value);
    setCodeError('');
  };

  /**
   * Validate the code and redirect to the correct app
   */
  const handleValidateCode = async (): Promise<void> => {
    if (!auth.isLoggedIn) {
      openSignupDialog();
      setAuthRedirectURLGlobal(location.pathname + location.search);
      setLoginSignupTeaserMessageGlobal('waitlistCode');
      return;
    }
    try {
      setIsValidateLoading(true);
      const response = await validateCreatorCodeCF({ code: codeValue });
      if (response.data) {
        // code is valid
        updateUserLocally(true);
        if (queryParams?.creatorType === CreatorTypeEnum.Coach) {
          navigate(AppRouteService.getRelativeUrl('User_BecomeCoach'));
        } else if (queryParams?.creatorType === CreatorTypeEnum.Organizer) {
          navigate(AppRouteService.getRelativeUrl('Organizer_Start'));
        } else if (AppRouteService.matchesRoute('JoinWaitlist')) {
          enqueueSnackbar('You are now a creator!', { variant: 'success' });
          navigate(AppRouteService.getRelativeUrl('Home'));
        } else {
          // User is on a creator blocked page already, this blocker page will disappear and they
          // will be able to continue
          return;
        }
      }
      // code is invalid
      setCodeError('Invalid code');
    } catch (err) {
      trackSentryError(err, { notify: false });
    } finally {
      setIsValidateLoading(false);
    }
  };

  // User is not a creator, and does not have an invitation.
  return (
    <>
      {isLoading || isNewCreator ? (
        <Box className={classes.loadingContainer}>
          <LoadingBounce />
        </Box>
      ) : (
        <Box className={classes.root}>
          <div className={classes.image} />
          <Box className={classes.textContainer}>
            <Typography gutterBottom align="center" variant="h1">
              <BrandTypography inline noLink variant="h1">
                Reggy
              </BrandTypography>{' '}
              is currently invite-only
            </Typography>
            <Typography align="center">
              We are working <i>super</i> hard to get{' '}
              <BrandTypography inline noLink>
                Reggy
              </BrandTypography>{' '}
              ready for everyone.
              <br />
              Until then, join our waiting list and we'll let you know when we're ready for you!
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <ExtendedLink noUnderline shouldOpenInNewTab href={airTableLink}>
                <ExtendedButton
                  color="primary"
                  size="large"
                  style={{ color: 'white' }}
                  variant="contained"
                >
                  Join the Waitlist!
                </ExtendedButton>
              </ExtendedLink>
            </Box>
            <FormOrDivider />
            <Typography align="center">Enter and validate your VIP code.</Typography>
            <Box mt={1.5}>
              <Grid container alignItems="center" justify="center" spacing={2}>
                <Grid item sm="auto" xs={12}>
                  <ExtendedTextField
                    fullWidth
                    error={!!codeError}
                    helperText={codeError}
                    label="Code"
                    margin="dense"
                    onChange={handleCodeChange}
                    value={codeValue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <ExtendedButton
                    color="primary"
                    isLoading={isValidateLoading}
                    minWidth="sm"
                    onClick={handleValidateCode}
                    style={{ color: 'white' }}
                    variant="contained"
                  >
                    Validate
                  </ExtendedButton>
                </Grid>
              </Grid>
            </Box>
            {/* <FormOrDivider dividerText="and" /> */}
            {/* <Box m={2}> */}
            {/*  <Box display="flex" flexDirection="column" mb={4} mt={2.5} width="100%"> */}
            {/*    <Typography gutterBottom align="center" variant="body2"> */}
            {/*      Click the image below to join our Discord community. */}
            {/*    </Typography> */}
            {/*    <PaperBox */}
            {/*      border */}
            {/*      borderRadius={4} */}
            {/*      className={classes.discord} */}
            {/*      display="flex" */}
            {/*      m="auto" */}
            {/*      mt={2} */}
            {/*      shadow={24} */}
            {/*      width={168} */}
            {/*    > */}
            {/*      <a */}
            {/*        href="https://discord.gg/h42GBrWuuh" */}
            {/*        rel="noreferrer noopener" */}
            {/*        style={{ display: 'flex' }} */}
            {/*        target="_blank" */}
            {/*      > */}
            {/*        <img */}
            {/*          alt="Discord" */}
            {/*          src="https://1000logos.net/wp-content/uploads/2020/10/Discord-logo.png" */}
            {/*          style={{ width: '100%' }} */}
            {/*        /> */}
            {/*      </a> */}
            {/*    </PaperBox> */}
            {/*  </Box> */}
            {/* </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CreatorCheckContainer;
