import { addDays, addMinutes, format, isAfter, isBefore, parseISO, subSeconds } from 'date-fns';
import addHours from 'date-fns/addHours';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { DeepReadonly } from 'utility-types';

import {
  dateFromFloatingDateString,
  initializeDateToTimezone,
  readableDateRange,
  setTimeToMidnight,
} from './date-helpers/date-utils';

import { DEFAULT_EVENT_IMAGE } from '@/const';
import {
  sortClinicsByFirstStartDateAsc,
  sortRidesByFirstStartDateAsc,
} from '@/containers/EventProfile/RefineRegister/helpers';
import { NonCategoriedRegistrationCard_public_event } from '@/containers/EventProfile/RefineRegister/NonCategoried/__generated__/NonCategoriedRegistrationCard_public_event.graphql';
import { isTodayBeforeNonCategoriedDeadlineFactory } from '@/containers/Registration/helpers';
import { ClinicDatesEventData } from '@/containers/Registration/RegistrationSteps/ClinicDate/helpers';
import { RideDatesEventData } from '@/containers/Registration/RegistrationSteps/RideDate/helpers';
import { ActivityTypeEnum } from '@/graphql/__generated__/graphql';
import { applyMediaCardCloudinaryTransform } from '@/lib/cloudinary';
import { formatNumberAsCurrency, formatNumberAsCurrencyOrFree } from '@/lib/currency-utils';
import { sortArrayAsc } from '@/lib/object-utils';
import { arrayToCommaString } from '@/lib/string-utils';
import { Maybe } from '@/lib/type-defs/utility';
import { activityType_enum } from '@/providers/__generated__/AuthProviderQuery.graphql';

interface EventTimezoneShape {
  readonly isVirtualEvent?: boolean;
  readonly eventVirtualVenues?:
    | readonly {
        readonly timezone: string;
      }[]
    | undefined;
  readonly eventVenues:
    | readonly {
        readonly venue:
          | {
              timezone: string;
            }
          | undefined;
      }[]
    | undefined;
}
type ClinicDatesType = {
  readonly clinicDays:
    | readonly {
        readonly startTime: string | null | undefined;
      }[]
    | undefined;
};
type RideDatesType = {
  readonly rideDays:
    | readonly {
        readonly startTime: string | null | undefined;
      }[]
    | undefined;
};
type RideStartDateType = {
  readonly rideDays:
    | readonly {
        readonly dayNumber: number;
        readonly startTime: string | null | undefined;
      }[]
    | undefined;
};
type ClinicStartDateType = {
  readonly clinicDays:
    | readonly {
        readonly eventClinicDayDuration:
          | {
              readonly dayNumber: number;
            }
          | undefined;
        readonly startTime: string | null | undefined;
      }[]
    | undefined;
};
type FormattedStartDateType = {
  activity: string;
  clinic: ClinicStartDateType | undefined | null;
  eventMetadata: {
    isCategoriedEvent: boolean;
  };
  ride: RideStartDateType | undefined | null;
  startDate: Date | string | null | undefined;
};
type ActivityType = Maybe<ActivityTypeEnum | activityType_enum | string>;
export const isActivityRace = (activity: ActivityType): boolean => {
  return activity === ActivityTypeEnum.Race;
};
export const isActivityClinic = (activity: ActivityType): boolean => {
  return activity === ActivityTypeEnum.Clinic;
};
export const isActivityRide = (activity: ActivityType): boolean => {
  return activity === ActivityTypeEnum.Ride;
};

type EventMetadataType = {
  readonly events: ReadonlyArray<{
    readonly activity: activityType_enum;
  }>;
  readonly name: string;
  readonly slug: string;
};
export const isEventMetadataRace = (eventMetadata: EventMetadataType): boolean => {
  return R.all((event) => isActivityRace(event.activity), eventMetadata.events);
};
export const isEventMetadataRide = (eventMetadata: EventMetadataType): boolean => {
  return R.all((event) => isActivityRide(event.activity), eventMetadata.events);
};
export const isEventMetadataClinic = (eventMetadata: EventMetadataType): boolean => {
  return R.all((event) => isActivityClinic(event.activity), eventMetadata.events);
};

/**
 * Coerce items available to null or number
 */
export const correctlyTypeItemsAvailable = (
  itemsAvailable: string | number | null | undefined,
): number | null => {
  if (!itemsAvailable || itemsAvailable === '0') {
    return null;
  }
  return Number(itemsAvailable);
};

/**
 * Get the activity purchase word
 */
export const getEventActivityItemText = (event: {
  activity: string;
  eventMetadata: { isCategoriedEvent: boolean } | null;
}): string => {
  if (event.eventMetadata && event.eventMetadata.isCategoriedEvent) {
    return 'Category';
  }
  return event.activity;
};

/**
 * Get event name from event metadata and occurrence label if it exists
 */
export const getEventName = (
  event:
    | {
        eventMetadata: { name: string } | null;
        occurrenceLabel?: string | null | undefined;
      }
    | null
    | undefined,
): string => {
  if (!event) {
    return '';
  }
  const { eventMetadata, occurrenceLabel } = event;
  if (!eventMetadata) {
    return '';
  }
  if (!occurrenceLabel) {
    return eventMetadata.name;
  }
  return `${eventMetadata.name} - ${occurrenceLabel}`;
};

export const getEventTimezone = (event: EventTimezoneShape): string | undefined => {
  let tz;
  if (event.isVirtualEvent && event.eventVirtualVenues && event.eventVirtualVenues.length > 0) {
    tz = event.eventVirtualVenues[0].timezone;
  } else if (event.eventVenues && event.eventVenues.length > 0 && event.eventVenues[0].venue) {
    tz = event.eventVenues[0].venue.timezone;
  }
  return tz;
};

/**
 * Make default image be first and return a list of image urls
 */
type EventImagesType = {
  readonly eventImages:
    | readonly {
        readonly image: {
          readonly relativeUrl: string;
        };
        readonly position: number;
      }[]
    | undefined;
};
export const getEventImageUrls = (event: EventImagesType): string[] => {
  if (!event?.eventImages?.length) {
    return [DEFAULT_EVENT_IMAGE];
  }
  return R.sortBy((ei) => ei.position, event.eventImages).map((ei) =>
    applyMediaCardCloudinaryTransform(ei.image.relativeUrl),
  );
};

/**
 * Returns a string representing the event's city, state, and country.
 * Or returns 'Virtual Event' if the virtual event flag is detected.
 */
type EventLocationType = {
  readonly eventVenues:
    | readonly {
        readonly venue: {
          readonly city: string | null;
          readonly country: string | null;
          readonly state: string | null;
        };
      }[]
    | undefined;
  readonly isVirtualEvent: boolean;
};
export function getEventLocation(ev: EventLocationType): string | null {
  if (ev.eventVenues?.length) {
    const { venue } = ev.eventVenues[0];
    if (venue) {
      const { city, country, state } = venue;
      return [city, state, country].reduce(
        (loc, part) => (loc && part ? `${loc}, ${part}` : loc || part || ''),
        '',
      );
    }
  }
  if (ev.isVirtualEvent) {
    return 'Virtual Event';
  }
  return null;
}

/**
 * Returns a string representing the event's address or falls back to city, state, and country.
 */

type EventLocationWithAddressType = {
  readonly eventVenues:
    | readonly {
        readonly venue: {
          readonly address: string | null;
          readonly city: string | null;
          readonly country: string | null;
          readonly fullAddress: string | null;
          readonly state: string | null;
        };
      }[]
    | undefined;
  readonly isVirtualEvent: boolean;
};
export function getEventLocationWithAddress(ev: EventLocationWithAddressType): string | null {
  if (ev.eventVenues?.length) {
    const { venue } = ev.eventVenues[0];
    if (venue.address) {
      return venue.address;
    }
    if (venue.fullAddress) {
      return venue.fullAddress;
    }
    return getEventLocation(ev);
  }
  return null;
}

export function getEventDates(ev: {
  endDate: Date | string | null | undefined;
  startDate: Date | string | null | undefined;
}): string | undefined {
  if (ev.startDate && ev.endDate) {
    return readableDateRange(ev.startDate, ev.endDate);
  }
  return undefined;
}

export function getEventStartDateFormatted(
  ev: {
    startDate: Date | string | null | undefined;
  },
  formatTemplate: string,
): string | undefined {
  if (ev.startDate) {
    if (typeof ev.startDate === 'string') {
      return format(parseISO(ev.startDate), formatTemplate);
    }
    return format(ev.startDate, formatTemplate);
  }
  return undefined;
}

export function getClinicDates(clinic: ClinicDatesType): string | undefined {
  if (clinic && clinic.clinicDays && clinic.clinicDays.length > 0) {
    const firstDay = clinic.clinicDays[0];
    const lastDayIndex = clinic.clinicDays.length - 1;
    const lastDay = clinic.clinicDays[lastDayIndex];
    if (firstDay && lastDay) {
      return readableDateRange(firstDay.startTime, lastDay.startTime);
    }
  }
  return undefined;
}

export function getClinicStartDateFormatted(
  clinic: ClinicStartDateType,
  formatTemplate: string,
): string | undefined {
  if (clinic && clinic.clinicDays && clinic.clinicDays.length > 0) {
    const startDay = clinic.clinicDays.find((cd) => cd.eventClinicDayDuration?.dayNumber === 1);
    if (startDay && startDay.startTime) {
      return format(parseISO(startDay.startTime), formatTemplate);
    }
  }
  return undefined;
}

export function getRideDates(ride: RideDatesType): string | undefined {
  if (ride && ride.rideDays && ride.rideDays.length > 0) {
    const firstDay = ride.rideDays[0];
    const lastDayIndex = ride.rideDays.length - 1;
    const lastDay = ride.rideDays[lastDayIndex];
    if (firstDay && lastDay) {
      return readableDateRange(firstDay.startTime, lastDay.startTime);
    }
  }
  return undefined;
}

export function getRideStartDateFormatted(
  ride: RideStartDateType,
  formatTemplate: string,
): string | undefined {
  if (ride && ride.rideDays && ride.rideDays.length > 0) {
    const startDay = ride.rideDays.find((rd) => rd.dayNumber === 1);
    if (startDay && startDay.startTime) {
      return format(parseISO(startDay.startTime), formatTemplate);
    }
  }
  return undefined;
}

/**
 * Get start date from a ride or clinic or event
 */
export const getFormattedEventClinicOrRideStartDate = (event: FormattedStartDateType): string => {
  let date;
  const fm = 'EEEE, MMMM do, y';
  if (event.eventMetadata.isCategoriedEvent) {
    date = getEventStartDateFormatted(event, fm) || '-';
  } else if (isActivityClinic(event.activity)) {
    date = getClinicStartDateFormatted(event.clinic!, fm) || '-';
  } else {
    date = getRideStartDateFormatted(event.ride!, fm) || '-';
  }
  return date;
};

/**
 * Get the minimum price for an event
 */
export const getNonCategoriedEventMinPriceAsString = (
  instances: { priceOverride: number | null }[],
  currencyCode: string,
  defPrice?: number | undefined,
): { hasMultiplePrices: boolean; minPrice: string; maxPrice: string } => {
  const defaultPrice = defPrice || 0;
  const allPrices = R.uniq(
    (instances ?? []).map((instance) => {
      if (instance.priceOverride) {
        return instance.priceOverride;
      }
      return defaultPrice;
    }) as number[],
  );
  const hasMultiplePrices = allPrices.length > 1;
  const minPrice = sortArrayAsc(allPrices)?.[0] ?? defaultPrice;
  const maxPrice = R.last(sortArrayAsc(allPrices)) ?? defaultPrice;
  return {
    hasMultiplePrices,
    minPrice: minPrice ? formatNumberAsCurrency(minPrice, currencyCode) : 'Free',
    maxPrice: maxPrice ? formatNumberAsCurrency(maxPrice, currencyCode) : 'Free',
  };
};

/**
 * Get the minimum price, maximum price and isFree for an event
 */
export const getEventMinMaxPriceAndIsFree = (eventData: {
  categories?: { entryFee: number | null }[];
  clinics?: { priceOverride: number }[];
  eventPrograms?: { defaultPrice: number }[];
  rides?: { priceOverride: number }[];
}): { isFree: boolean; maxPrice: number | null; minPrice: number | null } => {
  const { categories, clinics, eventPrograms, rides } = eventData;
  const prices = RA.compact([
    ...(categories?.map((category) => category.entryFee) ?? []),
    ...(rides?.map((ride) => ride.priceOverride) ?? []),
    ...(clinics?.map((clinic) => clinic.priceOverride) ?? []),
    ...(eventPrograms?.map((eventProgram) => eventProgram.defaultPrice) ?? []),
  ]) as number[];
  if (RA.isNilOrEmpty(prices)) {
    return { minPrice: null, maxPrice: null, isFree: true };
  }
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  return { minPrice, maxPrice, isFree: false };
};

/**
 * Clinic date dropdown options.
 *
 * Saturday at 9am | May 25, 2021 | John Davis | $150
 * Sat-Sun | May 25-26, 2021 | John Davis | $150
 * May 31 - June 1, 2021, Dec 31, 2021 - Jan 1, 2022
 * John D, Louisa K & Mary S
 */
export function getClinicDateOptions(
  event: ClinicDatesEventData,
  options?: { showClinicsAfterDeadline?: boolean; showSpotsRemaining?: boolean },
): Record<'key' | 'value' | 'label', string>[] {
  const { currencyCode } = event;
  const eventTimezone = getEventTimezone(event);
  if (!eventTimezone) {
    return [];
  }
  const isTodayBeforeClinicDeadline = isTodayBeforeNonCategoriedDeadlineFactory(
    event.nonCategoriedRegDeadline,
    eventTimezone,
  );

  return event.clinics
    .filter((clinic) =>
      options?.showClinicsAfterDeadline ? true : isTodayBeforeClinicDeadline(clinic),
    )
    .filter(isTodayBeforeClinicDeadline)
    .sort(sortClinicsByFirstStartDateAsc)
    .map((clinic) => {
      const isSingleDay = clinic.clinicDays.length === 1;
      const startDate = dateFromFloatingDateString(clinic.clinicDays[0].startTime);
      let dayOfWeek;
      let fullDate;
      if (isSingleDay) {
        dayOfWeek = format(startDate, "EEEE 'at' haaa");
        fullDate = readableDateRange(startDate, startDate);
      } else {
        const endDate = dateFromFloatingDateString(
          clinic.clinicDays[clinic.clinicDays.length - 1].startTime,
        );
        dayOfWeek = `${format(startDate, 'EEE')}-${format(endDate, 'EEE')}`;
        fullDate = readableDateRange(startDate, endDate);
      }

      let coach;
      const isSingleCoach = clinic.clinicCoaches.length === 1;
      if (isSingleCoach) {
        let { firstName, lastName } = clinic.clinicCoaches[0].coach.user;
        if (firstName === null) {
          firstName = '(No Name)';
        }
        if (lastName === null) {
          lastName = 'Coach';
        }
        coach = `${firstName} ${lastName}`;
      } else {
        const coaches = clinic.clinicCoaches.map((c) => {
          let { firstName } = c.coach.user;
          const { lastName } = c.coach.user;
          if (firstName === null) {
            firstName = '(No Name)';
          }
          const lastInitial = lastName === null ? 'Coach' : lastName[0];
          return `${firstName} ${lastInitial}`;
        });
        coach = arrayToCommaString(coaches, '&');
      }

      let price;
      const program = event.eventPrograms[0];
      const defaultPriceAsNumber = program.defaultPrice as number;
      const defaultPrice = formatNumberAsCurrencyOrFree(defaultPriceAsNumber, currencyCode);
      if (clinic.priceOverride) {
        const priceOverrideAsNumber = clinic.priceOverride as number;
        price = formatNumberAsCurrencyOrFree(priceOverrideAsNumber, currencyCode);
      } else {
        price = defaultPrice;
      }

      const spotsRemainingText = options?.showSpotsRemaining
        ? ` | ${clinic.spotsRemaining || 'Unlimited'} ${pluralize(
            'spot',
            clinic.spotsRemaining || 0,
          )} left`
        : '';
      const isAfterDeadline = !isTodayBeforeClinicDeadline(clinic);
      const afterDeadlineText = isAfterDeadline ? ' | After Deadline' : '';
      return {
        key: clinic.id,
        value: clinic.cuid,
        label: `${dayOfWeek} | ${fullDate} | ${coach} | ${price}${spotsRemainingText}${afterDeadlineText}`,
      };
    });
}

/**
 * Ride date dropdown options.
 *
 * Saturday at 9am | May 25, 2021 | $150
 * Sat-Sun | May 25-26, 2021 | $150
 * May 31 - June 1, 2021, Dec 31, 2021 - Jan 1, 2022
 */
export function getRideDateOptions(
  event: RideDatesEventData,
  options?: { showRidesAfterDeadline?: boolean; showSpotsRemaining?: boolean },
): Record<'key' | 'value' | 'label', string>[] {
  const { currencyCode } = event;
  const eventTimezone = getEventTimezone(event);
  if (!eventTimezone) {
    return [];
  }
  const isTodayBeforeRideDeadline = isTodayBeforeNonCategoriedDeadlineFactory(
    event.nonCategoriedRegDeadline,
    eventTimezone,
  );

  return (event.rides ?? [])
    .filter((ride) => (options?.showRidesAfterDeadline ? true : isTodayBeforeRideDeadline(ride)))
    .sort(sortRidesByFirstStartDateAsc)
    .map((ride) => {
      const isSingleDay = ride.rideDays.length === 1;
      const startDate = dateFromFloatingDateString(ride.rideDays[0].startTime);
      let dayOfWeek;
      let fullDate;
      if (isSingleDay) {
        dayOfWeek = format(startDate, "EEEE 'at' haaa");
        fullDate = readableDateRange(startDate, startDate);
      } else {
        const endDate = dateFromFloatingDateString(
          ride.rideDays[ride.rideDays.length - 1].startTime,
        );
        dayOfWeek = `${format(startDate, 'EEE')}-${format(endDate, 'EEE')}`;
        fullDate = readableDateRange(startDate, endDate);
      }

      let price;
      const defaultPriceAsNumber = 0 as number;
      const defaultPrice = formatNumberAsCurrencyOrFree(defaultPriceAsNumber, currencyCode);
      if (ride.priceOverride) {
        const priceOverrideAsNumber = ride.priceOverride as number;
        price = formatNumberAsCurrencyOrFree(priceOverrideAsNumber, currencyCode);
      } else {
        price = defaultPrice;
      }

      const spotsRemainingText = options?.showSpotsRemaining
        ? ` | ${ride.spotsRemaining || 'Unlimited'} ${pluralize(
            'spot',
            ride.spotsRemaining || 0,
          )} left`
        : '';
      const isAfterDeadline = !isTodayBeforeRideDeadline(ride);
      const afterDeadlineText = isAfterDeadline ? ' | After Deadline' : '';

      return {
        key: ride.id,
        value: ride.cuid,
        label: `${dayOfWeek} | ${fullDate} | ${price}${spotsRemainingText}${afterDeadlineText}`,
      };
    });
}

/**
 * Is clinic or ride past the registration deadline?
 */
export const getIsTodayBeforeRegDeadline = (
  instance:
    | NonCategoriedRegistrationCard_public_event['clinics'][0]
    | NonCategoriedRegistrationCard_public_event['rides'][0],
  timezone: string,
): boolean => {
  const startTime =
    'clinicDays' in instance
      ? instance.clinicDays?.[0].startTime
      : instance.rideDays?.[0].startTime;
  if (!startTime) {
    return false;
  }
  return isBefore(
    new Date(),
    subSeconds(
      initializeDateToTimezone(dateFromFloatingDateString(startTime), timezone),
      instance.event.nonCategoriedRegDeadline || 0,
    ),
  );
};

/**
 * Is a categoried event 48 hours passed the end of the event and does it have registrations
 */
export const getHasCategoriedEventEventPassed = (event: {
  endDate: string | null;
  registrations_aggregate: {
    aggregate: { count: number } | null;
  } | null;
}): boolean => {
  const registrationCount = event.registrations_aggregate?.aggregate?.count ?? 0;
  return event.endDate
    ? isAfter(new Date(), addHours(parseISO(event.endDate), 48)) && registrationCount > 0
    : false;
};

/**
 * Convert a promocode to text
 */
export const getPromoCodeText = (
  promoCode: { percentOff: number | null | undefined; amountOff: number | null | undefined },
  currencyCode: string,
): string => {
  if (!promoCode.amountOff && !promoCode.percentOff) {
    throw new Error('Promo code needs at least one of: amountOff, percentOff');
  }
  return promoCode.percentOff
    ? `${promoCode.percentOff}%`
    : formatNumberAsCurrency(promoCode.amountOff!, currencyCode);
};

/**
 * Get the first date of an event
 */
export const getEventStartFromRegistration = (
  registration: DeepReadonly<{
    event: {
      endDate: string | null;
      startDate: string | null;
      eventVenues: { venue: { timezone: string } }[];
      eventVirtualVenues: { timezone: string }[];
    };
    clinic: {
      clinicDays: {
        startTime: string;
      }[];
    } | null;
    ride: {
      rideDays: {
        startTime: string;
      }[];
    } | null;
  }>,
): Date => {
  let startDate: Date | null | undefined;
  if (registration.event.startDate) {
    // Race or Categoried Ride
    startDate = parseISO(registration.event.startDate);
  } else if (registration.clinic?.clinicDays?.[0]?.startTime) {
    // Clinic
    startDate = initializeDateToTimezone(
      dateFromFloatingDateString(registration.clinic!.clinicDays[0].startTime),
      getEventTimezone(registration.event),
    );
  } else {
    // Non-Categoried Ride
    startDate = initializeDateToTimezone(
      dateFromFloatingDateString(registration.ride!.rideDays[0].startTime),
      getEventTimezone(registration.event),
    );
  }
  return startDate;
};

/**
 * Get the last date of an event
 */
export const getEventEndFromRegistration = (
  registration: DeepReadonly<{
    event: {
      endDate: string | null;
      startDate: string | null;
      eventVenues: { venue: { timezone: string } }[];
      eventVirtualVenues: { timezone: string }[];
    };
    clinic: {
      clinicDays: {
        startTime: string;
        eventClinicDayDuration: {
          duration: number;
        };
      }[];
    } | null;
    ride: {
      rideDays: {
        startTime: string;
      }[];
    } | null;
  }>,
): Date => {
  let endDate: Date | null | undefined;
  if (registration.event.endDate) {
    // Race or Categoried Ride
    endDate = parseISO(registration.event.endDate);
  } else if (registration.clinic?.clinicDays?.[0]?.startTime) {
    // Clinic - Last day
    const startTime = dateFromFloatingDateString(
      R.last(registration.clinic?.clinicDays)!.startTime,
    );
    endDate = initializeDateToTimezone(
      addMinutes(
        startTime,
        R.last(registration.clinic?.clinicDays)?.eventClinicDayDuration.duration as number,
      ),
      getEventTimezone(registration.event),
    );
  } else {
    // Non-Categoried Ride - Midnight of the day after the last day
    const startTime = setTimeToMidnight(
      dateFromFloatingDateString(R.last(registration.ride!.rideDays)!.startTime),
    );
    endDate = initializeDateToTimezone(addDays(startTime, 1), getEventTimezone(registration.event));
  }
  return endDate;
};
