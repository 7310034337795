/**
 * Circle that can be used for image display
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';

import { BoopAnimation } from '@/ui/BoopAnimation';
import ConditionalWrapper from '@/hoc/ConditionalWrapper';

import { DEFAULT_PROFILE_IMAGE } from '@/const';
import { applyProfileCloudinaryTransform, getTransformedImageUrl } from '@/lib/cloudinary';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: 'relative',
    height: '150px',
    width: '150px',
    borderRadius: '50%',
    '& div': {
      alignItems: 'center',
      border: '1px solid #EBEBEB',
      borderRadius: '50%',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, .1)',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100%',
    },
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      // marginLeft: theme.spacing(0),
    },
  },
  imageContainerText: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    '& p': {
      color: theme.palette.common.white,
      fontSize: '3em',
    },
  },
  transformUp: {
    top: '-75px',
    marginBottom: '-75px',
    [theme.breakpoints.down('sm')]: {
      top: '-50px',
      marginBottom: '-50px',
    },
  },
  transformUpSmall: {
    top: '-50px',
    marginBottom: '-50px',
  },
  small: {
    height: '100px',
    width: '100px',
  },
  container: {
    position: 'relative',
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  containerSmall: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noMargin: {
    margin: '0',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}));

interface ProfileImageProps {
  boxProps?: BoxProps;
  center?: boolean;
  imageUrl?: string | undefined | null;
  linkUrl?: string;
  noMargin?: boolean;
  small?: boolean;
  transformUp?: boolean;
  initials?: string | undefined | null;
}
const ProfileImage: React.FC<ProfileImageProps> = ({
  boxProps,
  center,
  imageUrl,
  initials,
  linkUrl,
  noMargin,
  small,
  transformUp,
}) => {
  const classes = useStyles();
  const src = getTransformedImageUrl(
    imageUrl,
    applyProfileCloudinaryTransform,
    DEFAULT_PROFILE_IMAGE,
  );

  return (
    <Box
      {...boxProps}
      className={classNames(
        classes.container,
        { [classes.cursorPointer]: !!boxProps?.onClick },
        { [classes.containerSmall]: small },
        { [classes.center]: center },
        { [classes.noMargin]: noMargin },
        { [classes.transformUp]: transformUp },
        { [classes.transformUpSmall]: small && transformUp },
        boxProps?.className,
      )}
    >
      <BoopAnimation boopConfig={{ rotation: 1 }}>
        <ConditionalWrapper
          WrapperComponent={Link}
          condition={!!linkUrl}
          wrapperProps={{ to: linkUrl, style: { borderRadius: '50%', display: 'block' } }}
        >
          <Box className={classNames(classes.imageContainer, { [classes.small]: small })}>
            {initials && !imageUrl ? (
              <div className={classes.imageContainerText}>
                <Typography>{initials}</Typography>
              </div>
            ) : (
              <div>
                <img alt="Profile Circle" src={src} />
              </div>
            )}
          </Box>
        </ConditionalWrapper>
      </BoopAnimation>
    </Box>
  );
};

export default ProfileImage;
