/*
Recoil atoms, selectors and hooks for Event Profile Page
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * Page title for a event profile route
 ************************************* */
// What is the event name? For use in Page component for page title metadata.
const eventProfileNameState = atom({
  key: 'eventProfileNameState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});
export const useGetEventProfileNameGlobal = (): string => {
  return useRecoilValue(eventProfileNameState);
};
export const useSetEventProfileNameGlobal = (): SetterOrUpdater<string> => {
  return useSetRecoilState(eventProfileNameState);
};
