import { migrateFromV1ToV2 } from './types/V2';
import { migrateFromV2ToV3, V3 } from './types/V3';

import { MigrationRules, PartialVersioned } from '../../Versions';

export * from './types/V3';
export * from './store/V3';

export const LATEST_VERSION = 3;
export type LatestVersion = V3;
export type LatestPartialVersioned = PartialVersioned<3, LatestVersion['contents']>;

export const MIGRATION_RULES: MigrationRules = {
  1: migrateFromV1ToV2,
  2: migrateFromV2ToV3,
};
