/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getIsEventPublished_event = {
    readonly publishedAt: string | null;
    readonly " $refType": "helpers_getIsEventPublished_event";
};
export type helpers_getIsEventPublished_event$data = helpers_getIsEventPublished_event;
export type helpers_getIsEventPublished_event$key = {
    readonly " $data"?: helpers_getIsEventPublished_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getIsEventPublished_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getIsEventPublished_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'eab6d338bf04691a0fc7d96932e52544';
export default node;
