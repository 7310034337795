interface EnvProps {
  ALGOLIA_APP_ID: string;
  ALGOLIA_INSIGHTS_APP_ID: string;
  ALGOLIA_INSIGHTS_PUBLIC_KEY: string;
  ALGOLIA_PUBLIC_KEY: string;
  APP_URL: string;
  EMBED_URL: string;
  APP_VERSION: string;
  CLOUDINARY_CLOUD_NAME: string;
  CLOUDINARY_PUBLIC_KEY: string;
  CLOUDINARY_URL: string;
  CONTENTFUL_ACCESS_TOKEN: string;
  CONTENTFUL_SPACE_ID: string;
  COURIER_CLIENT_KEY: string;
  FULLSTORY_ORG_ID: string;
  GOOGLE_IDENTITY_API_KEY: string;
  GOOGLE_MAPS_JS_API_KEY: string;
  GOOGLE_PLACES_PUBLIC_KEY: string;
  GRAPHQL_RELAY_URL: string;
  GRAPHQL_RELAY_WS_URL: string;
  GRAPHQL_URL: string;
  GRAPHQL_WS_URL: string;
  MAPBOX_PUBLIC_KEY: string;
  HELLOSIGN_CLIENT_ID: string;
  IS_NODE_ENV_LOCAL: boolean;
  IS_NODE_ENV_PRODUCTION: boolean;
  IS_NODE_ENV_STAGING: boolean;
  LOCAL_ALGOLIA_INDEX_ID: string | undefined;
  PUBLIC_FUNCTIONS_DOMAIN: string;
  RECAPTCHA_ENTERPRISE_PUBLIC_KEY: string;
  SEGMENT_WRITE_KEY: string;
  SENTRY_URL: string;
  STREAM_PUBLIC_KEY: string;
  STRIPE_PUBLIC_KEY: string;
  USE_LOCAL_DB_AND_FIREBASE_EMULATORS: boolean;
}

// Do not place anything here that wouldn't want users to see!
const IS_NODE_ENV_LOCAL = process.env.NODE_ENV === 'local';
const IS_NODE_ENV_STAGING = process.env.NODE_ENV === 'staging';
const IS_NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';
const USE_LOCAL_DB_AND_FIREBASE_EMULATORS =
  process.env.USE_LOCAL_DB_AND_FIREBASE_EMULATORS === 'true';
const ENV: EnvProps = {
  IS_NODE_ENV_LOCAL,
  IS_NODE_ENV_STAGING,
  IS_NODE_ENV_PRODUCTION,
  USE_LOCAL_DB_AND_FIREBASE_EMULATORS,
  APP_VERSION: __REGGY_VERSION__,
  APP_URL: process.env.APP_URL as string,
  EMBED_URL: process.env.EMBED_URL as string,
  PUBLIC_FUNCTIONS_DOMAIN: process.env.PUBLIC_FUNCTIONS_DOMAIN as string,
  COURIER_CLIENT_KEY: process.env.COURIER_CLIENT_KEY as string,
  CLOUDINARY_URL: process.env.CLOUDINARY_URL as string,
  CLOUDINARY_PUBLIC_KEY: process.env.CLOUDINARY_PUBLIC_KEY as string,
  CLOUDINARY_CLOUD_NAME: process.env.CLOUDINARY_CLOUD_NAME as string,
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID as string,
  CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN as string,
  FULLSTORY_ORG_ID: process.env.FULLSTORY_ORG_ID as string,
  GOOGLE_IDENTITY_API_KEY: process.env.GOOGLE_IDENTITY_API_KEY as string,
  GOOGLE_MAPS_JS_API_KEY: process.env.GOOGLE_MAPS_JS_API_KEY as string,
  GOOGLE_PLACES_PUBLIC_KEY: process.env.GOOGLE_PLACES_PUBLIC_KEY as string,
  GRAPHQL_URL: USE_LOCAL_DB_AND_FIREBASE_EMULATORS
    ? process.env.LOCAL_GRAPHQL_URL || 'http://localhost:8080/v1/graphql'
    : (process.env.GRAPHQL_URL as string),
  GRAPHQL_WS_URL: USE_LOCAL_DB_AND_FIREBASE_EMULATORS
    ? process.env.LOCAL_GRAPHQL_WS_URL || 'ws://localhost:8080/v1/graphql'
    : (process.env.GRAPHQL_WS_URL as string),
  GRAPHQL_RELAY_URL: USE_LOCAL_DB_AND_FIREBASE_EMULATORS
    ? process.env.LOCAL_RELAY_URL || 'http://localhost:8080/v1beta1/relay'
    : (process.env.GRAPHQL_RELAY_URL as string),
  GRAPHQL_RELAY_WS_URL: USE_LOCAL_DB_AND_FIREBASE_EMULATORS
    ? process.env.LOCAL_GRAPHQL_RELAY_WS_URL || 'ws://localhost:8080/v1beta1/relay'
    : (process.env.GRAPHQL_RELAY_WS_URL as string),
  MAPBOX_PUBLIC_KEY: process.env.MAPBOX_PUBLIC_KEY as string,
  ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID as string,
  ALGOLIA_PUBLIC_KEY: process.env.ALGOLIA_PUBLIC_KEY as string,
  ALGOLIA_INSIGHTS_APP_ID: process.env.ALGOLIA_INSIGHTS_APP_ID as string,
  ALGOLIA_INSIGHTS_PUBLIC_KEY: process.env.ALGOLIA_INSIGHTS_PUBLIC_KEY as string,
  LOCAL_ALGOLIA_INDEX_ID: process.env.LOCAL_ALGOLIA_INDEX_ID,
  HELLOSIGN_CLIENT_ID: process.env.HELLOSIGN_CLIENT_ID as string,
  RECAPTCHA_ENTERPRISE_PUBLIC_KEY: process.env.RECAPTCHA_ENTERPRISE_PUBLIC_KEY as string,
  SENTRY_URL: process.env.SENTRY_URL as string,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY as string,
  STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY as string,
  STREAM_PUBLIC_KEY: process.env.STREAM_PUBLIC_KEY as string,
};

export default ENV;
