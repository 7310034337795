import { differenceInDays, isAfter, isBefore, parseISO, sub } from 'date-fns';
import invariant from 'invariant';
import * as R from 'ramda';
import { commitMutation, Environment, fetchQuery } from 'relay-runtime';

import {
  ReviewServiceQueries_getRecentClinics_Query,
  ReviewServiceQueries_getRecentClinics_QueryResponse,
} from './__generated__/ReviewServiceQueries_getRecentClinics_Query.graphql';
import {
  ReviewServiceQueries_getRecentLessonsCoaches_Query,
  ReviewServiceQueries_getRecentLessonsCoaches_QueryResponse,
} from './__generated__/ReviewServiceQueries_getRecentLessonsCoaches_Query.graphql';
import { ReviewServiceQueries_getRecentReviews_Query } from './__generated__/ReviewServiceQueries_getRecentReviews_Query.graphql';
import {
  ReviewServiceQueries_getReviews_Query,
  ReviewServiceQueries_getReviews_QueryResponse,
} from './__generated__/ReviewServiceQueries_getReviews_Query.graphql';
import { ReviewServiceQueries_saveReview_Mutation } from './__generated__/ReviewServiceQueries_saveReview_Mutation.graphql';
import { ReviewServiceQueries_updateReviewToLatestVersion_Mutation } from './__generated__/ReviewServiceQueries_updateReviewToLatestVersion_Mutation.graphql';
import PersonalizationService from './questions/PersonalizationService';
import {
  Category,
  CLINIC_QUESTIONS,
  ClinicCategory,
  CoachCategory,
  LATEST_VERSION,
  LatestPartialVersioned,
  LatestVersion,
  MIGRATION_RULES,
  OpenResponse,
  Question,
  RecommendationResponse,
  Response,
  SubjectType,
  UserPreferences,
  YesNoResponse,
} from './questions';
import {
  GET_RECENT_CLINICS_QUERY,
  GET_RECENT_LESSONS_COACHES_QUERY,
  GET_RECENT_REVIEWS_QUERY,
  GET_REVIEWS_QUERY,
  SAVE_REVIEW_MUTATION,
  UPDATE_REVIEW_TO_LATEST_VERSION_MUTATION,
} from './ReviewServiceQueries';

import VersionedLoader from '../VersionedLoader';
import { UnknownPartialVersioned, Versioned } from '../Versions';

import { ReviewSubjectTypeEnum } from '@/graphql/__generated__/graphql';
import { formatToISODateString } from '@/lib/date-helpers/date-utils';
import { decodeRelayId, STRING_ID } from '@/lib/relay-utils';
import { AuthContextProps } from '@/providers/AuthProvider';

export type ClinicInfo = ReviewServiceQueries_getRecentClinics_QueryResponse['registration_connection']['edges'][0]['node'];
export type LessonInfo = ReviewServiceQueries_getRecentLessonsCoaches_QueryResponse['coachCustomerLessonDate_connection']['edges'][0]['node'];

export type Review = Pick<
  LatestVersion['contents'],
  'coachQuestions' | 'clinicQuestions' | 'responses'
>;
export type CoachReview = Pick<Review, 'coachQuestions' | 'responses'>;
export type ClinicReview = Pick<Review, 'clinicQuestions' | 'responses'>;
export type VersionedReview = Versioned<number, Review>;

type ReviewNode = ReviewServiceQueries_getReviews_QueryResponse['review_connection']['edges'][0]['node'];
export interface ReviewResult extends Omit<ReviewNode, 'review'> {
  review: VersionedReview;
}
export interface ReviewTotals {
  count: number;
  recAverage: number;
  recPercentage: number;
}
export interface ReviewsAggregate {
  totals: ReviewTotals;
  reviews: ReviewResult[];
}

export type Sort = 'personalized' | 'most recent';

type User = NonNullable<AuthContextProps['user']>;

const TOTAL_QUESTIONS = 4;
const MAX_PREFS = PersonalizationService.getMaxNumberPreferences();
const WEIGHTS = new Array(MAX_PREFS).fill(0).map((_v, i) => MAX_PREFS - i);
const DAYS_IN_TWO_YEARS = 365 * 2;

export default class ReviewService {
  private relayEnv: Environment;

  private user?: User;

  private userId?: string;

  private personalizationService?: PersonalizationService;

  private reviewedSubjectCuids?: string[];

  constructor(relayEnv: Environment, user?: User, personalizationService?: PersonalizationService) {
    this.relayEnv = relayEnv;
    this.user = user;
    this.personalizationService = personalizationService;

    if (this.user) {
      this.userId = decodeRelayId(this.user.id, STRING_ID);
    }
  }

  public static isYesNoResponse(response: Response): response is YesNoResponse {
    return response.type === 'y/n';
  }

  public static isRecommendationResponse(response: Response): response is RecommendationResponse {
    return response.type === 'recommendation';
  }

  public static isOpenResponse(response: Response): response is OpenResponse {
    return response.type === 'open';
  }

  public static getRecommendationValueToLabels(): Record<number, string> {
    return {
      1: 'Strongly not recommended',
      2: 'Not recommended',
      3: 'Recommended',
      4: 'Highly recommended',
    };
  }

  public static isClinicInfo(x: unknown): x is ClinicInfo {
    return (
      x !== null &&
      x !== undefined &&
      typeof x === 'object' &&
      Object.prototype.hasOwnProperty.call(x, 'clinic')
    );
  }

  public static isLessonInfo(x: unknown): x is LessonInfo {
    return (
      x !== null &&
      x !== undefined &&
      typeof x === 'object' &&
      Object.prototype.hasOwnProperty.call(x, 'coachCustomerLesson')
    );
  }

  private getReviewedSubjectCuids(forceFetch?: boolean): Promise<string[]> {
    return new Promise((resolve, reject) => {
      invariant(
        this.userId,
        'fetching reviews for coaches written by a user is only available for users with accounts',
      );

      if (!forceFetch && this.reviewedSubjectCuids) {
        resolve(this.reviewedSubjectCuids);
        return;
      }

      const oneYearAgoDate = formatToISODateString(sub(new Date(), { years: 1 }));
      const variables = { userId: this.userId, oneYearAgoDate };

      fetchQuery<ReviewServiceQueries_getRecentReviews_Query>(
        this.relayEnv,
        GET_RECENT_REVIEWS_QUERY,
        variables,
      ).subscribe({
        error: (error: Error) => reject(error),
        next: (data): void => {
          const reviewSubjectCuids = data.review_connection.edges.map(
            (edge) => edge.node.reviewSubjectCuid,
          );
          this.reviewedSubjectCuids = reviewSubjectCuids;
          resolve(reviewSubjectCuids);
        },
      });
    });
  }

  /**
   * Returns the info for the requested subject if it reviewable (subject was taken by
   * the user in the last two weeks and hasn't been reviewed yet).
   */
  async getReviewable(
    subjectCuid: string,
    subjectType: SubjectType,
  ): Promise<ClinicInfo | LessonInfo | undefined> {
    if (subjectType === 'clinic') {
      const reviewableClinics = await this.getReviewableClinics();
      return reviewableClinics.find((info) => info.event.eventMetadata.cuid === subjectCuid);
    }
    if (subjectType === 'coach') {
      const reviewableCoaches = await this.getReviewableCoaches();
      return reviewableCoaches.find((info) => info.coachCustomerLesson.coachCuid === subjectCuid);
    }
    throw new Error('unsupported subject type');
  }

  /**
   * Returns info on clinics that the user has taken in the past two weeks but
   * has not submitted reviews for in the past year.
   */
  async getReviewableClinics(): Promise<ClinicInfo[]> {
    if (!this.user) {
      return [];
    }

    const clinics = await this.fetchRecentClinics();
    if (clinics.length === 0) {
      return [];
    }

    const reviewedSubjectCuids = await this.getReviewedSubjectCuids();
    const unreviewedClinics = clinics.filter((c) => !reviewedSubjectCuids.includes(c.clinic!.cuid));

    const byDateDesc = (a: ClinicInfo, b: ClinicInfo): number => {
      const aDate = parseISO(a.clinic!.clinicDays[0].startTime);
      const bDate = parseISO(b.clinic!.clinicDays[0].startTime);
      return isAfter(aDate, bDate) ? -1 : isBefore(aDate, bDate) ? 1 : 0;
    };
    return R.sort(byDateDesc, unreviewedClinics);
  }

  private fetchRecentClinics(): Promise<ClinicInfo[]> {
    return new Promise((resolve, reject) => {
      invariant(this.userId, 'fetching recent clinics is only available for users with accounts');

      const today = new Date();
      const variables = {
        todayDate: formatToISODateString(today),
        twoWeeksAgoDate: formatToISODateString(sub(today, { weeks: 2 })),
        userId: this.userId,
      };

      fetchQuery<ReviewServiceQueries_getRecentClinics_Query>(
        this.relayEnv,
        GET_RECENT_CLINICS_QUERY,
        variables,
      ).subscribe({
        error: (error: Error) => reject(error),
        next: (data): void => {
          const clinicInfo = data.registration_connection.edges.map((edge) => edge.node);
          resolve(clinicInfo);
        },
      });
    });
  }

  /**
   * Returns recent coach and lesson information for lessons that the user has taken
   * in the past two weeks but has not submitted a review for the coach in the past year.
   */
  async getReviewableCoaches(): Promise<LessonInfo[]> {
    if (!this.user) {
      return [];
    }

    const lessons = await this.fetchRecentLessons();
    if (lessons.length === 0) {
      return [];
    }

    const reviewedSubjectCuids = await this.getReviewedSubjectCuids();

    const unreviewedLessons = lessons.filter(
      (l) => !reviewedSubjectCuids.includes(l.coachCustomerLesson.coachCuid),
    );

    // user can take multiple lessons from same coach, so pick out the latest lesson
    const coachToLesson: Record<string, string> = {};
    unreviewedLessons.forEach((ul) => {
      const { coachCuid } = ul.coachCustomerLesson;
      const lessonId = ul.id;

      if (!coachToLesson[coachCuid]) {
        coachToLesson[coachCuid] = lessonId;
        return;
      }

      const existingLesson = unreviewedLessons.find((l) => l.id === coachToLesson[coachCuid]);
      if (existingLesson && isAfter(parseISO(ul.startDate), parseISO(existingLesson.startDate))) {
        coachToLesson[coachCuid] = lessonId;
      }
    });

    const lessonsToRequestReview = Object.values(coachToLesson).map(
      (lessonId) => unreviewedLessons.find((ul) => ul.id === lessonId)!,
    );
    const byDateDesc = (a: LessonInfo, b: LessonInfo): number => {
      const aDate = parseISO(a.startDate);
      const bDate = parseISO(b.startDate);
      return isAfter(aDate, bDate) ? -1 : isBefore(aDate, bDate) ? 1 : 0;
    };
    return R.sort(byDateDesc, lessonsToRequestReview);
  }

  private fetchRecentLessons(): Promise<LessonInfo[]> {
    return new Promise((resolve, reject) => {
      invariant(this.userId, 'fetching recent lessons is only available for users with accounts');

      const variables = {
        twoWeeksAgoDate: formatToISODateString(sub(new Date(), { weeks: 2 })),
        userId: this.userId,
      };

      fetchQuery<ReviewServiceQueries_getRecentLessonsCoaches_Query>(
        this.relayEnv,
        GET_RECENT_LESSONS_COACHES_QUERY,
        variables,
      ).subscribe({
        error: (error: Error) => reject(error),
        next: (data): void => {
          const lessonInfo = data.coachCustomerLessonDate_connection.edges.map((edge) => edge.node);
          resolve(lessonInfo);
        },
      });
    });
  }

  /**
   * Returns review questions for the given type that the user must complete.
   */
  async getReviewQuestions(subjectType: SubjectType): Promise<Question<Category>[]> {
    invariant(
      this.userId,
      'getting questions for reviewing is only available for users with accounts',
    );

    if (subjectType === 'clinic') {
      return Promise.resolve(ReviewService.getClinicQuestions());
    }
    if (subjectType === 'coach') {
      return this.getCoachQuestions();
    }

    throw new Error('reviews for other subject types not supported');
  }

  /**
   * Returns the required clinic questions along with the randomized question.
   */
  private static getClinicQuestions(): Question<ClinicCategory>[] {
    const questionsByRequired = R.groupBy(
      (q) => (q.alwaysAsk ? 'required' : 'notRequired'),
      CLINIC_QUESTIONS,
    );
    const required = CLINIC_QUESTIONS.filter((q) => q.alwaysAsk);
    const numRandomQuestions = TOTAL_QUESTIONS - required.length;

    const availableQuestions = questionsByRequired.notRequired;
    const randomQuestions: Question<ClinicCategory>[] = [];
    while (randomQuestions.length < numRandomQuestions) {
      const randomNum = Math.random();
      const randomIndex = Math.round(randomNum * (availableQuestions.length - 1));
      const question = availableQuestions[randomIndex];
      if (!randomQuestions.find((q) => q.id === question.id)) {
        randomQuestions.push(question);
      }
    }

    return [...randomQuestions, ...required];
  }

  /**
   * Returns the personalized coach questions for the user.
   *
   * If personalized preferences aren't set (or not fully set), then fill in with
   * random questions.
   */
  private async getCoachQuestions(): Promise<Question<CoachCategory>[]> {
    const userQuestionPrefs = this.personalizationService
      ? await this.personalizationService.getUserPreferencesAsQuestions()
      : undefined;
    const numRandomQuestions = !userQuestionPrefs
      ? PersonalizationService.getMaxNumberPreferences()
      : userQuestionPrefs.filter((q) => q === undefined).length;

    const requiredGeneralQuestions = PersonalizationService.getQuestionsByCategory().general;

    if (numRandomQuestions === 0) {
      return [
        ...((userQuestionPrefs as unknown) as Question<CoachCategory>[]),
        ...requiredGeneralQuestions,
      ];
    }

    const availableQuestions = PersonalizationService.getQuestions().filter(
      (q) => q.category.category !== 'general',
    );
    const randomQuestions: Question<CoachCategory>[] = [];
    while (randomQuestions.length < numRandomQuestions) {
      const randomNum = Math.random();
      const randomIndex = Math.round(randomNum * (availableQuestions.length - 1));
      const question = availableQuestions[randomIndex];
      const questionNotInPrefs =
        !userQuestionPrefs || !userQuestionPrefs.some((q) => q?.id === question.id);
      const alreadyAdded = randomQuestions.some((q) => q.id === question.id);
      if (questionNotInPrefs && !alreadyAdded) {
        randomQuestions.push(question);
      }
    }

    return [
      ...((userQuestionPrefs?.filter((q) => q !== undefined) as Question<CoachCategory>[]) || []),
      ...randomQuestions,
      ...requiredGeneralQuestions,
    ];
  }

  /**
   * Save review.
   */
  async saveReview(
    reviewSubjectCuid: string,
    subjectType: SubjectType,
    review: Review,
    reviewSubjectInstanceCuid?: string,
  ): Promise<string> {
    let questionsKey: keyof Review;
    let reviewSubjectType: ReviewSubjectTypeEnum;
    if (subjectType === 'clinic') {
      questionsKey = 'clinicQuestions';
      reviewSubjectType = ReviewSubjectTypeEnum.Clinic;
    } else if (subjectType === 'coach') {
      questionsKey = 'coachQuestions';
      reviewSubjectType = ReviewSubjectTypeEnum.Coach;
    } else {
      throw new Error('unsupported review type');
    }
    const questions = review[questionsKey];
    const questionIds = questions.map((q) => q.id);
    const { responses } = review;
    const everyQuestionAnswered = Object.entries(responses).every(
      ([qId, response]) => response?.questionId === qId && questionIds.includes(qId),
    );
    invariant(everyQuestionAnswered, "responses don't match the questions supplied");

    return new Promise((resolve, reject) => {
      invariant(this.userId, 'saving a review is only available for users with accounts');

      const versionedReview: LatestPartialVersioned = {
        version: LATEST_VERSION,
        contents: {
          [questionsKey]: questions,
          responses,
        },
      };

      commitMutation<ReviewServiceQueries_saveReview_Mutation>(this.relayEnv, {
        mutation: SAVE_REVIEW_MUTATION,
        variables: {
          userId: this.userId,
          reviewSubjectCuid,
          reviewSubjectType,
          review: (versionedReview as unknown) as Record<string, unknown>,
          reviewSubjectInstanceCuid,
        },
        onError: (error) => reject(error),
        onCompleted: (data) => {
          const id = data?.insert_review_one?.id;
          if (id) {
            if (this.reviewedSubjectCuids) {
              this.reviewedSubjectCuids.push(id);
            } else {
              this.reviewedSubjectCuids = [id];
            }
            resolve(id);
          } else {
            reject(new Error('Could not save review'));
          }
        },
      });
    });
  }

  private migrateToLatestVersion(reviewResults: ReviewResult[]): ReviewResult[] {
    const reviewResultsToUpdate: ReviewResult[] = [];

    const latestVersions = reviewResults.map((rr) => {
      const unknownVersionedReview = rr.review as UnknownPartialVersioned;
      const VL = new VersionedLoader<LatestPartialVersioned>(
        unknownVersionedReview,
        MIGRATION_RULES,
        LATEST_VERSION,
      );
      const latestVersionReview = VL.getLatestVersion();
      const latestVersionReviewResult = {
        ...rr,
        review: latestVersionReview as VersionedReview,
      };

      if (!VL.isLoadedVersionLatest()) {
        reviewResultsToUpdate.push(latestVersionReviewResult);
      }

      return latestVersionReviewResult;
    });

    reviewResultsToUpdate.forEach((rr, i) => {
      const variables = {
        id: decodeRelayId(rr.id),
        review: (latestVersions[i].review as unknown) as Record<string, unknown>,
      };
      commitMutation<ReviewServiceQueries_updateReviewToLatestVersion_Mutation>(this.relayEnv, {
        mutation: UPDATE_REVIEW_TO_LATEST_VERSION_MUTATION,
        variables,
      });
    });

    return latestVersions;
  }

  private static personalizedSort(
    reviewResults: ReviewResult[],
    userPrefs: UserPreferences,
  ): ReviewResult[] {
    // add weights to review if questions match user preferences
    const scoresToAdd: Record<string, number> = {};
    reviewResults.forEach((rr) => {
      let score = 0;
      rr.review.contents.coachQuestions.forEach((q) => {
        const i = userPrefs.findIndex((id) => id === q.id);
        if (i > -1) {
          score += WEIGHTS[i];
        }
      });
      scoresToAdd[rr.id] = score;
    });

    // depreciate scores the older the reviews are (up to 2 years ago)
    const scores: Record<string, number> = {};
    const today = new Date();
    reviewResults.forEach((rr) => {
      const reviewDate = parseISO(rr.updated_at);
      const reviewedDaysAgo = differenceInDays(today, reviewDate);
      const depreciationCoeff =
        reviewedDaysAgo > DAYS_IN_TWO_YEARS
          ? 0
          : (DAYS_IN_TWO_YEARS - reviewedDaysAgo) / DAYS_IN_TWO_YEARS;
      scores[rr.id] = scoresToAdd[rr.id] * depreciationCoeff + 1;
    });

    // sort by weighted scores, tiebreak by recency
    const byScores = (a: ReviewResult, b: ReviewResult): number => scores[a.id] - scores[b.id];
    const byDateDesc = (a: ReviewResult, b: ReviewResult): number => {
      const aDate = parseISO(a.updated_at);
      const bDate = parseISO(b.updated_at);
      return isAfter(aDate, bDate) ? -1 : isBefore(aDate, bDate) ? 1 : 0;
    };

    const personalizedSort = R.sortWith([byScores, byDateDesc]);
    return personalizedSort(reviewResults);
  }

  /**
   * Get reviews.
   */
  getReviews(reviewSubjectCuid: string, sort: Sort = 'personalized'): Promise<ReviewsAggregate> {
    return new Promise((resolve, reject) => {
      const variables = { reviewSubjectCuid };

      fetchQuery<ReviewServiceQueries_getReviews_Query>(
        this.relayEnv,
        GET_REVIEWS_QUERY,
        variables,
      ).subscribe({
        error: (error: Error) => reject(error),
        next: async (data): Promise<void> => {
          const unknownVersionedReviewResults = data.review_connection.edges.map(
            (e) => (e.node as unknown) as ReviewResult,
          );
          const reviewResults = this.migrateToLatestVersion(unknownVersionedReviewResults);
          const reviewTotals = data.reviewTotals_connection.edges[0]?.node || {
            count: 0,
            recAverage: 0,
            recPercentage: 0,
          };
          const totals: ReviewTotals = { ...reviewTotals };
          const result: ReviewsAggregate = { reviews: reviewResults, totals };

          if (sort === 'most recent') {
            resolve(result);
            return;
          }

          const userPrefs = this.personalizationService
            ? await this.personalizationService.getUserPreferences()
            : undefined;
          if (!userPrefs) {
            resolve(result);
            return;
          }
          const sortedResults = ReviewService.personalizedSort(reviewResults, userPrefs);
          resolve({ totals, reviews: sortedResults });
        },
      });
    });
  }
}
