import * as R from 'ramda';

import { CURRENCY } from '@/const/currency';

export const getSymbolFromCurrencyCode = (currencyCode: string): string => {
  return R.find(R.propEq('code', currencyCode.toUpperCase()), CURRENCY)?.symbol ?? '$';
};

export function formatNumberAsCurrency(numberToFormat: number, currencyCode: string): string {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(numberToFormat); /* $2,500 */
}
export function formatNumberAsCurrencyWithCents(
  numberToFormat: number,
  currencyCode: string,
): string {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(numberToFormat); /* $2,500.00 */
}
export function formatNumberAsCurrencyOrFree(
  numberToFormat: number | null | undefined,
  currencyCode: string,
): string {
  return numberToFormat ? formatNumberAsCurrency(numberToFormat, currencyCode) : 'Free';
}
export function formatNumberAsCurrencyWithCentsOrFree(
  numberToFormat: number | null | undefined,
  currencyCode: string,
): string {
  return numberToFormat ? formatNumberAsCurrencyWithCents(numberToFormat, currencyCode) : 'Free';
}
