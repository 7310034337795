/*
React Slick.
 */

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore It exists.
import rightArrowSVG from '@/../public/images/icons/arrow-right.svg';

export const useReactSlickStyles = makeStyles((theme: Theme) => ({
  reactSlickPreviewImages: {
    '& .slick-dots li button:before': {
      color: theme.palette.common.white,
    },
    '& .slick-dots li.slick-active button:before': {
      color: theme.palette.common.white,
    },
    '& .slick-prev, .slick-next': {
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
    },
    '& .slick-prev:before, .slick-next:before': {
      fontSize: '24px',
    },
    '& .slick-prev': {
      left: theme.spacing(1.5),
      zIndex: 1,
    },
    '& .slick-next': {
      right: theme.spacing(1.5),
    },
    '& .slick-dots': {
      bottom: theme.spacing(1),
    },
  },
  reactSlickInstances: {
    // '& .slick-dots li button:before': {
    //   color: theme.palette.common.white,
    //
    // },
    // '& .slick-dots li.slick-active button:before': {
    //   color: theme.palette.common.white,
    // },
    '& .slick-prev, .slick-next': {
      display: 'flex !important',
      height: '20px',
      // top: 'auto',
      // bottom: '-42px',
      opacity: 0.75,
      zIndex: 1,
    },
    '& .slick-prev:before, .slick-next:before': {
      color: '#999999',
      content: '""',
      position: 'absolute',
      backgroundImage: `url(${rightArrowSVG})`,
      height: '12px',
      width: '12px',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
    },
    '& .slick-prev': {
      left: '-20px',
      '&:before': {
        right: 0,
        left: 'auto',
        transform: 'rotate(180deg)',
      },
    },
    '& .slick-next': {
      right: '-20px',
      '&:before': {
        left: 0,
        right: 'auto',
      },
    },
    '& .slick-list': {
      marginBottom: theme.spacing(2.5),
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    '& .slick-dots li button:before': {
      fontSize: theme.spacing(1),
    },
    '& .slick-dots': {
      bottom: -22,
      padding: theme.spacing(0, 2),
      '& button:before, & button:before': {
        color: '#898989 !important',
      },
    },
  },
}));
