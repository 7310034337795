/**
 * User page
 */
import React from 'react';

import Page from '../Page';

import MainLayout, { IMainLayout } from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const UserPage: ReactFCC<IMainLayout> = ({ children, ...rest }) => {
  return (
    <Page noIndex title="User • Reggy">
      <MainLayout stickyHeader={false} {...rest}>
        {children}
      </MainLayout>
    </Page>
  );
};

export default UserPage;
