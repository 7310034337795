/*
 A Material UI Box with "PaperProps" or really just a box with box shadows and border radius
 */
import React from 'react';
import { Theme } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import RootRef from '@material-ui/core/RootRef';
import makeStyles from '@material-ui/styles/makeStyles';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import classNames from 'classnames';

import ConditionalWrapper from '@/hoc/ConditionalWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: PaperBoxProps): CreateCSSProperties<PaperBoxProps> => ({
    borderRadius: props.roundness ? theme.spacing(props.roundness) : '',
    boxShadow: props.shadow ? theme.shadows[props.shadow] : '',
    border: props.border ? '1px solid rgb(219, 222, 225)' : '',
  }),
}));

interface PaperBoxProps extends BoxProps {
  border?: boolean;
  roundness?: number;
  shadow?: number;
}
const PaperBox: React.FC<PaperBoxProps> = React.forwardRef(
  ({ border, children, className, roundness, shadow, ...rest }, ref) => {
    const classes = useStyles({ border, roundness, shadow });
    return (
      <ConditionalWrapper WrapperComponent={RootRef} condition={!!ref} wrapperProps={{ ref }}>
        <Box className={classNames(classes.root, className)} {...rest}>
          {children}
        </Box>
      </ConditionalWrapper>
    );
  },
);

export default PaperBox;
