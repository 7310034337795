import invariant from 'invariant';
import * as R from 'ramda';
import { commitMutation, Environment, fetchQuery } from 'relay-runtime';

import { PersonalizationServiceQueries_insertNewPrefs_Mutation } from './__generated__/PersonalizationServiceQueries_insertNewPrefs_Mutation.graphql';
import { PersonalizationServiceQueries_loadUserPreferences_Query } from './__generated__/PersonalizationServiceQueries_loadUserPreferences_Query.graphql';
import { PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation } from './__generated__/PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation.graphql';
import {
  INSERT_NEW_PREFS_MUTATION,
  LOAD_USER_PREFERENCES_QUERY,
  UPDATE_PREFS_TO_LATEST_VERSION_MUTATION,
} from './PersonalizationServiceQueries';
import {
  CoachCategory,
  LATEST_VERSION,
  LatestPartialVersioned,
  LatestVersion,
  MIGRATION_RULES,
  PRIVATE_LESSON_QUESTIONS,
  Question,
  QuestionId,
} from '.';

import VersionedLoader from '../../VersionedLoader';
import { UnknownPartialVersioned } from '../../Versions';

import { decodeRelayId, STRING_ID } from '@/lib/relay-utils';
import { AuthContextProps } from '@/providers/AuthProvider';

// NOTE: pick a number that is divisible by 3 to allow for user to demarcate their
// preferences by most important, very important, and important
const MAX_NUMBER_OF_PREFERENCES = 3;

type User = NonNullable<AuthContextProps['user']>;
type PersonalizableQuestion = Question<CoachCategory>;

export default class PersonalizationService {
  private relayEnv: Environment;

  private user: User;

  private preferences?: LatestVersion['contents']['studentPrefs'];

  static getMaxNumberPreferences(): number {
    return MAX_NUMBER_OF_PREFERENCES;
  }

  static getQuestions(): PersonalizableQuestion[] {
    return PRIVATE_LESSON_QUESTIONS.slice();
  }

  static getQuestionsById(): Record<QuestionId, PersonalizableQuestion> {
    const questions = PersonalizationService.getQuestions();
    return R.indexBy<PersonalizableQuestion>(R.prop('id'), questions);
  }

  static getQuestionsByCategory(): Record<string, PersonalizableQuestion[]> {
    const questions = PersonalizationService.getQuestions();
    return R.groupBy<PersonalizableQuestion>(R.pathOr('Misc', ['category', 'category']), questions);
  }

  constructor(relayEnv: Environment, user: User) {
    this.relayEnv = relayEnv;
    this.user = user;
  }

  private updateSavedPrefs(relayId: string, latestVersion: LatestPartialVersioned): void {
    if (!latestVersion) {
      return;
    }

    const id = decodeRelayId(relayId);
    commitMutation<PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation>(
      this.relayEnv,
      {
        mutation: UPDATE_PREFS_TO_LATEST_VERSION_MUTATION,
        variables: {
          preferences: (latestVersion as unknown) as Record<string, unknown>,
          id,
        },
      },
    );
  }

  private getPreferences(
    forceFetch?: boolean,
  ): Promise<LatestPartialVersioned['contents']['studentPrefs']> {
    return new Promise((resolve, reject) => {
      if (!forceFetch) {
        if (this.preferences) {
          resolve(this.preferences);
          return;
        }
      }

      const variables = { userId: decodeRelayId(this.user.id, STRING_ID) };

      fetchQuery<PersonalizationServiceQueries_loadUserPreferences_Query>(
        this.relayEnv,
        LOAD_USER_PREFERENCES_QUERY,
        variables,
      ).subscribe({
        error: (error: Error) => reject(error),
        next: (data): void => {
          const hasPreferences = data.userPersonalization_connection.edges.length > 0;
          if (!hasPreferences) {
            resolve(undefined);
            return;
          }

          const queryResult = data.userPersonalization_connection.edges[0].node;
          const unknownVersionedPrefs = (queryResult.preferences as unknown) as UnknownPartialVersioned;
          const VL = new VersionedLoader<LatestPartialVersioned>(
            unknownVersionedPrefs,
            MIGRATION_RULES,
            LATEST_VERSION,
          );
          const latestVersion = VL.getLatestVersion();

          if (!VL.isLoadedVersionLatest()) {
            this.updateSavedPrefs(queryResult.id, latestVersion);
          }

          this.preferences = latestVersion.contents.studentPrefs!;
          resolve(this.preferences);
        },
      });
    });
  }

  getUserPreferences(
    forceFetch?: boolean,
  ): Promise<LatestPartialVersioned['contents']['studentPrefs']> {
    return this.getPreferences(forceFetch);
  }

  async getUserPreferencesAsQuestions(
    forceFetch?: boolean,
  ): Promise<Array<PersonalizableQuestion | undefined> | undefined> {
    const preferences = await this.getUserPreferences(forceFetch);
    if (!preferences) {
      return undefined;
    }

    const questionsById = PersonalizationService.getQuestionsById();
    return preferences.map((id) => (id ? questionsById[id] : undefined));
  }

  private savePreferences(
    newPreferences: LatestVersion['contents']['studentPrefs'],
  ): Promise<LatestPartialVersioned['contents']['studentPrefs']> {
    const { length } = newPreferences;
    invariant(
      length > 0 && length <= MAX_NUMBER_OF_PREFERENCES,
      `user preferences requires at least 1 choice or a max of ${MAX_NUMBER_OF_PREFERENCES}`,
    );

    const newPrefsIsDifferentFromExisting =
      this.preferences &&
      (this.preferences.length !== length ||
        this.preferences.some((id, i) => id !== newPreferences[i]));
    const shouldSave = !this.preferences || newPrefsIsDifferentFromExisting;

    return new Promise((resolve, reject) => {
      if (!shouldSave) {
        resolve(undefined);
      }

      const versionedUserPreferences: LatestPartialVersioned = {
        version: LATEST_VERSION,
        contents: {
          studentPrefs: newPreferences,
        },
      };

      commitMutation<PersonalizationServiceQueries_insertNewPrefs_Mutation>(this.relayEnv, {
        mutation: INSERT_NEW_PREFS_MUTATION,
        variables: {
          userId: decodeRelayId(this.user.id, STRING_ID),
          preferences: (versionedUserPreferences as unknown) as Record<string, unknown>,
        },
        onError: (error) => reject(error),
        onCompleted: () => {
          this.preferences = newPreferences;
          resolve(newPreferences);
        },
      });
    });
  }

  saveUserPreferences(
    newUserPreferences: LatestVersion['contents']['studentPrefs'],
  ): Promise<LatestPartialVersioned['contents']['studentPrefs']> {
    return this.savePreferences(newUserPreferences);
  }
}
