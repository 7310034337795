import { Theme } from '@material-ui/core';

export const MuiSelect = (theme: Theme): Record<string, unknown> => ({
  selectMenu: {
    whiteSpace: 'normal',
  },
  select: {
    '&.Mui-disabled .MuiChip-root': {
      opacity: 0.38,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});
