import {
  authMiddleware,
  AuthMiddlewareOpts,
  cacheMiddleware,
  ConcreteBatch,
  Disposable,
  errorMiddleware,
  loggerMiddleware,
  perfMiddleware,
  progressMiddleware,
  QueryPayload,
  RelayNetworkLayer,
  RelayNetworkLayerResponse,
  RelayRequestAny,
  retryMiddleware,
  urlMiddleware,
  Variables,
  // Import from node8 so we don't have to worry about core-js dependencies
} from 'react-relay-network-modern/node8';
import { createClient } from 'graphql-ws';
import * as RA from 'ramda-adjunct';
import { Environment, Observable, RecordSource, Store } from 'relay-runtime';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';

import ENV from '../env';

import {
  firebaseAuth,
  getIndexDBOrNewFirebaseToken,
  getLocallySignedTokenCF,
} from '@/lib/firebase';

const tokenToJSON = (token: string): { uid: string; exp: number } => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};
// If token expires within 30 seconds
function isTokenExpired(token: string | undefined): boolean {
  if (RA.isNilOrEmpty(token) || !token) {
    return true;
  }
  const { exp } = tokenToJSON(token);
  return new Date().getTime() >= exp * 1000 - 30000;
}
export const LOCAL_AUTH_STORAGE_KEY = 'local-reggy-token';
const getLocallySignedToken = async (
  refresh: boolean,
  request?: RelayRequestAny | null,
): Promise<string> => {
  const localToken = sessionStorage.getItem(LOCAL_AUTH_STORAGE_KEY);
  // const { headers } = request.fetchOpts;
  // console.log(headers);
  // let isNewUser = false;
  // if (localToken && request && request.fetchOpts.headers?.Authorization) {
  //   console.log(tokenToJSON(localToken));
  //   console.log(tokenToJSON(request.fetchOpts.headers.Authorization?.split(' ')));
  //   isNewUser =
  //     tokenToJSON(request.fetchOpts.headers.Authorization?.split(' ')?.[1]).uid !==
  //     tokenToJSON(localToken).uid;
  // }
  if (refresh || !localToken || isTokenExpired(localToken)) {
    const response = await getLocallySignedTokenCF();
    sessionStorage.setItem(LOCAL_AUTH_STORAGE_KEY, response.data);
    return response.data;
  }
  return localToken;
};

export const authMiddlewareOpts: AuthMiddlewareOpts = {
  // These functions actually return string | undefined but 'token' and 'tokenRefreshPromise'
  // only take strings. Under the hood 'allowEmptyToken' allows for these functions to be
  // string | undefined
  token: (getIndexDBOrNewFirebaseToken as unknown) as string,
  // Below never gets called (I dont think) because graphql always returns 200 and below only
  // runs on a 401. The way we get around this is to decode the token in our function and
  // check if it is close to expiring.
  tokenRefreshPromise: () => firebaseAuth.currentUser?.getIdToken(true) as Promise<string>,
  allowEmptyToken: true,
};
export const localAuthMiddlewareOpts: AuthMiddlewareOpts = {
  token: (request) => getLocallySignedToken(false, request),
  // Below never gets called (I dont think) because graphql always returns 200 and below only
  // runs on a 401. The way we get around this is to decode the token in our function and
  // check if it is close to expiring.
  tokenRefreshPromise: () => getLocallySignedToken(true, null),
  allowEmptyToken: true,
};

// Subscriptions
const wsClient = createClient({
  url: ENV.GRAPHQL_RELAY_WS_URL,
  connectionParams: async () => {
    const authorization = ENV.USE_LOCAL_DB_AND_FIREBASE_EMULATORS
      ? await getLocallySignedToken(true)
      : await firebaseAuth.currentUser?.getIdToken(true);
    const header = `Bearer ${authorization}`;
    return authorization ? { authorization: header, headers: { authorization: header } } : {};
  },
});
const subscribe: (
  operation: ConcreteBatch,
  variables: Variables,
) => RelayObservable<QueryPayload> | Disposable = (
  operation,
  variables,
): RelayObservable<QueryPayload> | Disposable => {
  return Observable.create<QueryPayload>((sink) => {
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text ?? '',
        variables,
      },
      sink,
    );
  });
};

const network = new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 100, // max 100 requests
      ttl: 900000, // 15 minutes
    }),
    urlMiddleware({
      url: () => Promise.resolve(ENV.GRAPHQL_RELAY_URL),
    }),
    ENV.IS_NODE_ENV_LOCAL ? loggerMiddleware() : null,
    ENV.IS_NODE_ENV_LOCAL ? errorMiddleware() : null,
    ENV.IS_NODE_ENV_LOCAL ? perfMiddleware() : null,
    ENV.IS_NODE_ENV_LOCAL
      ? progressMiddleware({
          onProgress: (current, total) => {
            // eslint-disable-next-line no-console
            console.log(`Downloaded: ${current} B, total: ${total} B`);
          },
        })
      : null,
    retryMiddleware({
      fetchTimeout: 15000,
      retryDelays: (attempt) => (2 ** attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
      beforeRetry: ({ abort, attempt, delay, forceRetry }) => {
        if (attempt > 10) abort();
        // Sets to window so we can use it anywhere is the app
        window.forceRelayRetry = forceRetry;
        if (ENV.IS_NODE_ENV_LOCAL) {
          // eslint-disable-next-line no-console
          console.log(`call \`forceRelayRetry()\` for immediately retry! Or wait ${delay} ms.`);
        }
      },
      statusCodes: [500, 503, 504],
    }),
    authMiddleware(
      ENV.USE_LOCAL_DB_AND_FIREBASE_EMULATORS ? localAuthMiddlewareOpts : authMiddlewareOpts,
    ),
    // Error handling
    (next) => async (req): Promise<RelayNetworkLayerResponse> => {
      // TODO add noThrow: true to opts for error handling here. Right now error boundary handles it.
      // https://hasura.io/blog/handling-graphql-hasura-errors-with-react/
      // https://hasura.io/blog/access-control-patterns-with-hasura-graphql-engine/
      // const response = await next(req);
      // if (response?.errors) {
      //   response?.errors.map(({ extensions, message }) => {
      //     switch (extensions.code) {
      //       case 'data-exception':
      //       case 'validation-failed':
      //         // props.history.push("/something-went-wrong"); // redirect to something-went-wrong page
      //         break;
      //       default:
      //         // default case
      //         console.log(extensions.code);
      //     }
      //   });
      // }
      return next(req);
    },
  ],
  {
    subscribeFn: subscribe,
    // noThrow: true
  },
);

// Export a singleton instance of Relay Environment configured with our network layer:
export default new Environment({
  network,
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});
