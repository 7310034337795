import React from 'react';

import { useGetEventProfileNameGlobal } from './recoilStore';

import Page from '../Page';

import RegistrationLayout from '@/layouts/RegistrationLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const RegistrationPage: ReactFCC = ({ children }) => {
  const eventProfileNameGlobal = useGetEventProfileNameGlobal();
  return (
    <Page noIndex title={`Register • ${eventProfileNameGlobal} • Reggy`}>
      <RegistrationLayout>{children}</RegistrationLayout>
    </Page>
  );
};

export default RegistrationPage;
