/**
 * Search Filters
 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import PaperBox from '@/ui/PaperBox';

import EventsActivityFilter from '@/containers/Search/Filters/EventsActivityFilter';
import EventsDateFilter from '@/containers/Search/Filters/EventsDateFilter';
import EventsMoreFilter from '@/containers/Search/Filters/EventsMoreFilter';
import EventsPriceFilter from '@/containers/Search/Filters/EventsPriceFilter';
import EventsRadiusFilter from '@/containers/Search/Filters/EventsRadiusFilter';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 0.4),
    borderBottom: Colors.LightBorder,
  },
}));

const SearchFiltersContainer: React.FC = () => {
  const classes = useStyles();
  // const searchFiltersGlobal = useGetSearchFiltersGlobal();
  // const isRaceClinicRide =
  //   searchFiltersGlobal.activities.some(isActivityRace) ||
  //   searchFiltersGlobal.activities.some(isActivityClinic) ||
  //   searchFiltersGlobal.activities.some(isActivityRide);

  return (
    <PaperBox className={classes.root}>
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12}>
          <EventsActivityFilter />
        </Grid>
        <Grid item xs={12}>
          <EventsDateFilter />
        </Grid>
        <Grid item xs={12}>
          <EventsPriceFilter />
        </Grid>
        {/* <Grid item xs={12}> */}
        {/*  <EventsRadiusFilter /> */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <EventsMoreFilter />
        </Grid>
      </Grid>
    </PaperBox>
  );
};

export default SearchFiltersContainer;
