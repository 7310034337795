/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type eventMetadataMutations_deleteEventAndOccurrences_MutationVariables = {
    eventMetadataCuid: string;
};
export type eventMetadataMutations_deleteEventAndOccurrences_MutationResponse = {
    readonly update_eventMetadata: {
        readonly affected_rows: number;
    } | null;
};
export type eventMetadataMutations_deleteEventAndOccurrences_Mutation = {
    readonly response: eventMetadataMutations_deleteEventAndOccurrences_MutationResponse;
    readonly variables: eventMetadataMutations_deleteEventAndOccurrences_MutationVariables;
};



/*
mutation eventMetadataMutations_deleteEventAndOccurrences_Mutation(
  $eventMetadataCuid: String!
) {
  update_eventMetadata(where: {cuid: {_eq: $eventMetadataCuid}}, _set: {deleted_at: "now()"}) {
    affected_rows
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventMetadataCuid"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "_set",
                    "value": {
                        "deleted_at": "now()"
                    }
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "eventMetadataCuid"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "cuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "eventMetadata_mutation_response",
            "kind": "LinkedField",
            "name": "update_eventMetadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "affected_rows",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "eventMetadataMutations_deleteEventAndOccurrences_Mutation",
            "selections": (v1 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "eventMetadataMutations_deleteEventAndOccurrences_Mutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "59274ac83000e17222381d37419c6789",
            "id": null,
            "metadata": {},
            "name": "eventMetadataMutations_deleteEventAndOccurrences_Mutation",
            "operationKind": "mutation",
            "text": "mutation eventMetadataMutations_deleteEventAndOccurrences_Mutation(\n  $eventMetadataCuid: String!\n) {\n  update_eventMetadata(where: {cuid: {_eq: $eventMetadataCuid}}, _set: {deleted_at: \"now()\"}) {\n    affected_rows\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1ed97705d853fd87c8af6ed60146ac26';
export default node;
