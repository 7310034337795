/*
Redirect to home page if user is logged in
 */
import React from 'react';
import { Navigate } from 'react-router-dom';

import LoadingPlaceholder from '../components/LoadingPlaceholder';

import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const ProhibitLogin: ReactFCC = ({ children }) => {
  const auth = useAuth();
  // If user is logged in, redirect to home
  if (!auth.isInitializing && !auth.isLoading && auth.user) {
    return <Navigate replace to={AppRouteService.getRelativeUrl('Home')} />;
  }

  return (
    // <LoadingPlaceholder isLoading={Auth.isInitializing || Auth.isLoading}>
    <LoadingPlaceholder disableShrink isLoading={auth.isInitializing}>
      {children}
    </LoadingPlaceholder>
  );
};

export default ProhibitLogin;
