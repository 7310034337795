// Set up user data
import { useLocalStorage } from 'react-use';
import * as FullStory from '@fullstory/browser';
import cuid from 'cuid';
import aa from 'search-insights';

import { useSegmentAnalyticsContext } from '@/analytics/segment/SegmentAnalyticsProvider';
import { useTrackSentryError } from '@/analytics/sentry';
import ENV from '@/env';
import { useGetIsAdBlockerRunningGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { RoleOrganizerEnum } from '@/graphql/__generated__/graphql';
import { sendSegmentEventCF } from '@/lib/firebase';
import { decodeRelayId, STRING_ID } from '@/lib/relay-utils';
import { AuthContextProps } from '@/providers/AuthProvider';

export const useIdentifyUserForAnalytics = (): ((
  user: NonNullable<AuthContextProps['user']>,
) => void) => {
  const trackSentryError = useTrackSentryError();
  const segmentAnalytics = useSegmentAnalyticsContext();
  const isAdBlockRunning = useGetIsAdBlockerRunningGlobal();
  const [reggyAnonymousId] = useLocalStorage('reggy-anonymousId', cuid());

  return (user: NonNullable<AuthContextProps['user']>): void => {
    try {
      const userId = decodeRelayId(user.id, STRING_ID);
      const traits = {
        isAdmin: user.isAdmin,
        isCreator: user.isCreator,
        isCoach: user.isCoach,
        isPartOfOrganization: Boolean(user.userOrganizerRoles?.length),
        isOwnerOfOrganization: Boolean(
          user.userOrganizerRoles?.find((role) => role.role === RoleOrganizerEnum.Owner),
        ),
        email: user.email,
        hasDOB: !!user.dob,
        hasPhone: !!user.phone,
        hasFullName: !!user.firstName && !!user.lastName,
        numberOfAddresses: user.userAddresses?.length,
        hasProfilePicture: Boolean(user.profilePicUrl),
        timezone: user.timezone,
        currency: user.currency,
        city: user.userAddresses.find((address) => address.isDefault)?.city,
        state: user.userAddresses.find((address) => address.isDefault)?.state,
        country: user.userAddresses.find((address) => address.isDefault)?.country,
        countryCode: user.userAddresses.find((address) => address.isDefault)?.countryCode,

        numberOfRegistrations: user.registrations?.length,
        numberOfCompleteRegistrations: user.registrations.filter(
          (registration) => registration.status === 'complete',
        ).length,
        numberOfStateRegistrations: user.registrations.filter(
          (registration) => registration.status === 'canceled_stale',
        ).length,
        numberOfClinicRegistrations: user.registrations.filter(
          (registration) => !!registration.clinicCuid,
        ).length,
        numberOfRideRegistrations: user.registrations.filter(
          (registration) => !!registration.rideCuid,
        ).length,
        numberOfCategoriedRegistrations: user.registrations.filter(
          (registration) => !registration.clinicCuid && !registration.rideCuid,
        ).length,
        numberOfRegistrationsForSelf: user.registrations.filter(
          (registration) => registration.for === 'self',
        ).length,
        numberOfRegistrationsForMinor: user.registrations.filter(
          (registration) => registration.for === 'minor',
        ).length,
        numberOfRegistrationsForOther: user.registrations.filter(
          (registration) => registration.for === 'other_user',
        ).length,

        numberOfRidingInterests: user.userRidingInterests.length,
        userRidingInterests: user.userRidingInterests,

        coachCuid: user.coach?.cuid,
        coachAllowRequests: user.coach?.allowRequests,
        coachFirstDisciplineName: user.coach?.coachDisciplines?.[0]?.name,
        coachFirstDisciplineSkills: user.coach?.coachDisciplines?.[0]?.skills ?? [],
        hasStripeConnectAccount: Boolean(user.stripeConnectId),

        numberOfOrganizations: user.userOrganizerRoles?.length || 0,
        organizerCuids: user.userOrganizerRoles?.map((role) => role.organizer?.cuid) || [],
        organizerRoles: user.userOrganizerRoles?.map((role) => role.role) || [],
        organizerSlugs: user.userOrganizerRoles?.map((role) => role.slug) || [],
      };

      /**
       * Segment.io
       */
      if (segmentAnalytics) {
        if (isAdBlockRunning) {
          sendSegmentEventCF({
            functionName: 'identify',
            message: {
              anonymousId: segmentAnalytics.user().anonymousId() || reggyAnonymousId,
              traits,
            },
          }).then();
        } else if (isAdBlockRunning === false) {
          segmentAnalytics.identify(userId, traits).then();
        }
      }

      /**
       * If we are in development mode, we don't want to send any data to
       * anything other than Segment.io
       */
      if (ENV.IS_NODE_ENV_LOCAL) {
        return;
      }

      /**
       * Fresh Chat
       */
      if (window.fcWidget) {
        window.fcWidget.setExternalId(userId);
        window.fcWidget.user.setFirstName(user.firstName);
        window.fcWidget.user.setEmail(user.email);
        // To set user properties
        // window.fcWidget.user.setProperties({
        //   plan: "Pro",                 // meta property 1
        //   status: "Active"                // meta property 2
        // });
      }

      /**
       * Algolia
       * The `insights` middleware receives a notification
       *   and attaches the `userToken` to search calls onwards.
       */
      aa('setUserToken', userId);

      /**
       * FullStory
       */
      if (FullStory) {
        FullStory.identify(decodeRelayId(user.id, STRING_ID), traits);
      }
    } catch (err) {
      trackSentryError(err);
    }
  };
};
