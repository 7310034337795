import { Theme } from '@material-ui/core';

export const MuiOutlinedInput = (theme: Theme): Record<string, unknown> => ({
  root: {
    backgroundColor: theme.palette.common.white,
    '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03465c95',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(26, 26, 29, 0.08) !important',
    },
  },
  input: {
    paddingTop: '15px',
    paddingBottom: '14px',
  },
  inputMarginDense: {
    paddingTop: '14.5px',
    paddingBottom: '14.5px',
  },
  multiline: {
    '&.MuiOutlinedInput-marginDense': {
      paddingTop: '13.5px',
      paddingBottom: '13.5px',
    },
  },
  notchedOutline: {
    borderColor: '#03465c45',
    borderWidth: 1,
    // boxShadow: 'rgba(0, 0, 0, 0.0764) 0px 3px 5px -1px',
    transition:
      'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
});
