import { subDays } from 'date-fns';
import addYears from 'date-fns/addYears';
import numeral from 'numeral';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as yup from 'yup';
import { RequiredNumberSchema } from 'yup/es/number';
import { SchemaLike } from 'yup/lib/types';

import { isNilEmptyOrNaN } from '../helper-utils';
import {
  PHONE_REG_EXP,
  trimAndKeep2NewLines,
  trimAndRemoveNewLines,
  URL_REG_EXP,
} from '../string-utils';

import {
  EventRecurrenceIntervalEnum,
  MAX_ADDITIONAL_CONTACT_INFO_LENGTH,
  MAX_ADDITIONAL_INFO_ABOUT_LENGTH,
  MAX_ADDITIONAL_INFO_DESCRIPTION_LENGTH,
  MAX_ADDITIONAL_INFO_TITLE_LENGTH,
  MAX_ADDITIONAL_LOCATION_INFO_LENGTH,
  MAX_BASIC_WAIVER_NAME_LENGTH,
  MAX_CATEGORY_NAME_LENGTH,
  MAX_CATEGORY_SPOTS_AVAILABLE,
  MAX_CHECKLIST_INFO_LENGTH,
  MAX_COURSE_LENGTH,
  MAX_COURSE_NAME_LENGTH,
  MAX_CUSTOM_QUESTION_OPTION_ITEMS,
  MAX_ENTRY_FEE,
  MAX_EVENT_NAME_LENGTH,
  MAX_EVENT_WEBSITE_LENGTH,
  MAX_EVENTS_IN_A_SERIES,
  MAX_MEETING_LINK_LENGTH,
  MAX_MEETUP_INSTRUCTIONS_INFO_LENGTH,
  MAX_MERCHANDISE_PRICE,
  MAX_MERCHANDISE_TITLE_LENGTH,
  MAX_OCCURRENCE_LABEL_LENGTH,
  MAX_PARKING_INFO_LENGTH,
  MAX_PIT_SPACE_NAME_ADD_ON_LENGTH,
  MAX_PIT_SPACE_NAME_LENGTH,
  MAX_PIT_SPACE_PRICE,
  MAX_PIT_SPACE_SIZE,
  MAX_POINTS_STRUCTURE_NAME_LENGTH,
  MAX_REFUND_INFO_LENGTH,
  MAX_SANCTION_NAME_LENGTH,
  MAX_SANCTION_PERMIT_LENGTH,
  MAX_SCHEDULE_TITLE_LENGTH,
  MAX_SCHEDULE_TYPE_NAME_LENGTH,
  MAX_SERIES_NAME_LENGTH,
  MAX_SPONSOR_NAME_LENGTH,
  MAX_SPONSOR_WEBSITE_LENGTH,
  MAX_TICKET_NAME_LENGTH,
  MAX_TICKET_PRICE,
  MAX_VARIANT_OPTION_NAME_LENGTH,
  MAX_VARIANT_OPTION_VALUE_NAME_LENGTH,
  MAX_VARIANT_OPTION_VALUES,
  MAX_VENUE_NAME_LENGTH,
  MAX_VOLUNTEER_LOCATION_INFO_LENGTH,
  MAX_VOLUNTEER_SHIFT_NAME_LENGTH,
  MAX_VOLUNTEER_TITLE_LENGTH,
  MAX_YEARS_IN_FUTURE,
  profanityFilter,
  PromoTypeEnum,
  QuestionTypes,
  SANCTION_OTHER_CUID,
} from '@/const';
import {
  PitSpaceSizeOptionsLinear,
  PitSpaceSizeOptionsSquared,
} from '@/containers/HostEvent/Main/Sections/LocationContact/PitSpace/const';
import { RegistrantClinicSelectionSchema } from '@/containers/Registration/RegistrationSteps/RegistrationFormSchema';
import { ActivityTypeEnum } from '@/graphql/__generated__/graphql';
import { yupEmail } from '@/lib/form-schema/auth';
// export const yupAny = yup.mixed().notRequired();

/** ******************************************************************************
 * Global
 ******************************************************************************** */
const yupSpotsAvailable = yup
  .number()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  )
  .nullable();

/** ******************************************************************************
 * EventMetadata
 ******************************************************************************** */

// Event Name;
const yupEventName = yup
  .string()
  .min(3, '3 characters min')
  .max(MAX_EVENT_NAME_LENGTH, `${MAX_EVENT_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your event name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase() || ''),
  )
  .test(
    'has-characters',
    'Enter at least 3 letters and/or numbers',
    (value) => (value?.match(/\w|\d/g) || []).length >= 3,
  );
export const yupEventNameRequired = yupEventName.required('We need a name. :)');

const yupOccurrenceLabelRequired = yup
  .string()
  .typeError('Required')
  .max(
    MAX_OCCURRENCE_LABEL_LENGTH,
    `We can't have more than ${MAX_OCCURRENCE_LABEL_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .required('Enter a label');
// Schema
export const EventMetadataFormSchema = yup.object().shape({
  name: yupEventNameRequired,
});
export const OccurrencesLabelFormSchema = yup.object().shape({
  occurrenceLabel: yupOccurrenceLabelRequired,
});

/** ******************************************************************************
 * General
 ******************************************************************************** */
// Event Discipline
export const yupDisciplineNamesRequired = yup
  .array()
  .test('empty-check', 'Please select a discipline', (discipline) => !R.isEmpty(discipline))
  .required('Please select a discipline');

// Event Organizer
export const yupOrganizerRequired = yup.number().required('Please select an organization');
// export const yupHostTypeRequired = yup.string().required('Please select an host type');
export const yupActivityTypeRequired = yup
  .string()
  .ensure()
  .required('Please select an activity type');
export const yupCurrencyCodeRequired = yup.string().required('Please select an currency');

// Event Dates
export const yupDateYears = yup
  .date()
  .min(subDays(new Date(), 1), 'Please enter a date today or in the future.')
  .max(
    addYears(new Date(), MAX_YEARS_IN_FUTURE),
    `We're all for planning ahead, but ${MAX_YEARS_IN_FUTURE} years is too far!`,
  );
export const yupDateRequired = yupDateYears
  .typeError('Please enter a valid date')
  .required('Please enter a valid date');

// Schema
export const GeneralFormSchema = yup.object().shape({
  activity: yupActivityTypeRequired,
  currencyCode: yupCurrencyCodeRequired,
  disciplineNames: yupDisciplineNamesRequired,
  dateRange: yup.mixed().when('activity', {
    is: ActivityTypeEnum.Race,
    then: yup.array().min(1, 'Please select a date').of(yupDateRequired),
  }),
  name: yupEventNameRequired,
});

/** ******************************************************************************
 * Location/Contact
 ******************************************************************************** */
// export const yupSanctionRequired = yup.number().required();
export const yupVenueNameRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_VENUE_NAME_LENGTH, `We can't have more than ${MAX_VENUE_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your venue name',
    (value) => !profanityFilter.isProfane((value ?? '').toLowerCase()),
  )
  .required('Required');
export const yupVenueAdditionalInfo = yup
  .string()
  .max(
    MAX_ADDITIONAL_LOCATION_INFO_LENGTH,
    `We can't have more than ${MAX_ADDITIONAL_LOCATION_INFO_LENGTH} characters`,
  )
  .nullable();
export const yupVenueParkingInfo = yup
  .string()
  .max(MAX_PARKING_INFO_LENGTH, `We can't have more than ${MAX_PARKING_INFO_LENGTH} characters`)
  .nullable();
export const yupVenueAdditionalContactInfo = yup
  .string()
  .max(
    MAX_ADDITIONAL_CONTACT_INFO_LENGTH,
    `We can't have more than ${MAX_ADDITIONAL_CONTACT_INFO_LENGTH} characters`,
  )
  .nullable();
export const yupVenueMeetupInstructions = yup
  .string()
  .typeError('Please enter instructions')
  .required('Please enter instructions')
  .max(
    MAX_MEETUP_INSTRUCTIONS_INFO_LENGTH,
    `We can't have more than ${MAX_MEETUP_INSTRUCTIONS_INFO_LENGTH} characters`,
  )
  .transform(trimAndKeep2NewLines);

export const LocationContactFormSchema = yup.object().shape({
  meetupInstructions: yup.mixed().when(['isCategoriedEvent'], {
    is: (isCategoriedEvent) => !isCategoriedEvent,
    then: yupVenueMeetupInstructions,
  }),
  additionalInfo: yupVenueAdditionalInfo,
  parkingInfo: yupVenueParkingInfo,
  additionalContactInfo: yupVenueAdditionalContactInfo,
  email: yupEmail,
  phone: yup.string().ensure().matches(PHONE_REG_EXP, {
    message: 'Please enter a valid phone number',
    excludeEmptyString: true,
  }),
});

/** *******************************************************************************************
 * Checklist
 ********************************************************************************************* */
const yupChecklistInfo = yup
  .string()
  .max(MAX_CHECKLIST_INFO_LENGTH, `We can't have more than ${MAX_CHECKLIST_INFO_LENGTH} characters`)
  .transform(trimAndRemoveNewLines);
export const ChecklistItemFormSchema = yup.object().shape({
  info: yupChecklistInfo,
});

/** ******************************************************************************
 * Course
 ******************************************************************************** */
export const yupCourseName = yup
  .string()
  .typeError('Required')
  .max(MAX_COURSE_NAME_LENGTH, `We can't have more than ${MAX_COURSE_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your course name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupCourseLength = yup
  .number()
  .min(0, 'Min value is 0')
  .max(MAX_COURSE_LENGTH, `That's a bit too long. Use a larger Course Length Type`)
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );
export const yupCourseLengthWithSecondary = yup
  .mixed()
  .when(['courseLengthSecondary', 'shouldSaveCourseLengthSecondary'], {
    is: (courseLengthSecondary, shouldSaveCourseLengthSecondary) =>
      RA.isTruthy(courseLengthSecondary) &&
      // For Create Category. Only require an course length if we have a secondary course length
      (RA.isUndefined(shouldSaveCourseLengthSecondary) ||
        // For Edit Multiple Categories. Only require an course length if we have a secondary
        // course length AND we have the secondary course length switched on
        RA.isTrue(shouldSaveCourseLengthSecondary)),
    then: yup
      .number()
      .required('Required with Secondary')
      .typeError('Required with Secondary')
      .min(0, 'Min value is 0')
      .max(MAX_COURSE_LENGTH, `That's a bit too long. Use a larger Course Length Type`),
    otherwise: yupCourseLength,
  });
export const yupCourseLengthTypeRequired = yup.string().typeError('Required').required('Required');

export const SaveCourseFormSchema = yup.object().shape({
  name: yupCourseName,
  courseLength: yupCourseLengthWithSecondary,
  courseLengthType: yupCourseLengthTypeRequired,
  courseLengthSecondary: yupCourseLength,
});

/** ******************************************************************************
 * Series
 ******************************************************************************** */
export const yupSeriesNameRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_SERIES_NAME_LENGTH, `We can't have more than ${MAX_SERIES_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your series name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase() || ''),
  )
  .test(
    'has-characters',
    'Enter at least 3 letters and/or numbers',
    (value) => (value?.match(/\w|\d/g) || []).length >= 3,
  )
  .required('Required');
export const yupSeriesRoundNumberRequired = yup
  .number()
  .typeError('Required')
  .required('Required')
  .min(1, 'There needs to be at least one event in a series')
  .max(
    MAX_EVENTS_IN_A_SERIES,
    `Can only have ${MAX_EVENTS_IN_A_SERIES} rounds in a series. Contact us and let us know your use case.`,
  )
  .when('totalRounds', {
    is: Boolean,
    then: yup
      .number()
      .typeError('Required')
      .required('Required')
      .min(1, 'There needs to be at least one event in a series')
      .max(yup.ref('totalRounds'), `Position in Series can't be greater than Total Rounds`),
  });
export const yupSeriesTotalRounds = yup
  .number()
  .nullable()
  .max(
    MAX_EVENTS_IN_A_SERIES,
    `Can only have ${MAX_EVENTS_IN_A_SERIES} rounds in a series. Contact us and let us know your use case.`,
  )
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );

export const yupPointsStructureNameRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_POINTS_STRUCTURE_NAME_LENGTH,
    `We can't have more than ${MAX_POINTS_STRUCTURE_NAME_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your points structure name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .required('Required');
const yupSeriesCategories = yup.mixed().when(['appliesToAllCategories'], {
  is: (val) => !val,
  then: yup.array().min(1, 'Please select a category').required('Please select a category'),
});

export const SeriesFormSchema = yup.object().shape({
  name: yupSeriesNameRequired,
  totalRounds: yupSeriesTotalRounds,
  roundNumber: yupSeriesRoundNumberRequired,
  eventSeriesCategories: yupSeriesCategories,
});
export const CreateNewSeriesFormSchema = yup.object().shape({
  name: yupSeriesNameRequired,
  totalRounds: yupSeriesTotalRounds,
  eventSeriesCategories: yupSeriesCategories,
});

export const SeriesAddExistingFormSchema = yup.object().shape({
  joinKey: yup.string(),
  series: yup
    .array()
    .test('empty-check', 'Please choose at least one series', (series) => !R.isEmpty(series)),
});

/** ******************************************************************************
 * Sanction
 ******************************************************************************** */
export const yupOtherSanctionNameRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_SANCTION_NAME_LENGTH, `We can't have more than ${MAX_SANCTION_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your sanction name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .required('Required');
export const yupSanctionPermit = yup
  .string()
  .max(
    MAX_SANCTION_PERMIT_LENGTH,
    `The permit # needs to be ${MAX_SANCTION_PERMIT_LENGTH} characters max`,
  );

export const SanctionFormSchema = yup.object().shape({
  otherSanctionName: yup.mixed().when('sanctionCuid', {
    is: (sanctionCuid) => sanctionCuid === SANCTION_OTHER_CUID,
    then: yupOtherSanctionNameRequired,
  }),
  permit: yupSanctionPermit,
});

/** *******************************************************************************************
 * Categories
 ********************************************************************************************* */
const yupCategoryName = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_CATEGORY_NAME_LENGTH, `We can't have more than ${MAX_CATEGORY_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your category name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupCategoryNameRequired = yupCategoryName.required('We need a name. :)');
export const yupCategoryEntry = yup
  .number()
  .min(0, 'Min value is 0')
  // TODO: Adjust based on currency
  .max(MAX_ENTRY_FEE, 'Contact us for larger pricing')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );
export const yupCategoryCourseRequired = yup
  .array()
  .min(1, 'Please select a course')
  .required('Please select a course');
export const yupCategorySeries = yup.array();
export const yupCategorySeriesPointsStructure = yup.array();
export const yupCategoryAccessRequired = yup.string().required('Required');

// Create a new category
export const CreateCategoryFormSchema = yup.object().shape({
  name: yupCategoryNameRequired,
  entryFee: yupCategoryEntry,
  course: yupCategoryCourseRequired,
});
// Edit multiple categories
export const EditGroupCategoriesFormSchema = yup.object().shape({
  entryFee: yupCategoryEntry,
  course: yup.mixed().when(['shouldSaveCourse'], {
    is: Boolean,
    then: yupCategoryCourseRequired,
  }),
});
export const SeriesColumnFormSchema = yup.object().shape({
  series: yupCategorySeries,
  pointsStructure: yupCategorySeriesPointsStructure,
});

// Name
export const CategoryNameColumnFormSchema = yup.object().shape({
  name: yupCategoryNameRequired,
});
// Entry Fee
export const CategoryEntryFeeColumnFormSchema = yup.object().shape({
  entryFee: yupCategoryEntry,
});
// Availability
const yupCategoryAvailability = yup
  .number()
  .max(
    MAX_CATEGORY_SPOTS_AVAILABLE,
    `Enter a number < ${numeral(MAX_CATEGORY_SPOTS_AVAILABLE).format()}`,
  )
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  )
  .nullable();
export const CategoryAvailabilityColumnFormSchema = yup.object().shape({
  spotsAvailable: yupCategoryAvailability,
});
// Expanded Panel
export const ExpandedCategoryFormSchema = yup.object().shape({
  description: yup.string().nullable(),
  additionalPrizes: yup.string().nullable(),
  minAge: yup.number(),
  maxAge: yup.number(),
  spotsAvailable: yup.number().nullable(),
});

/** ******************************************************************************
 * Pit Space
 ******************************************************************************** */
// Price Column Popover
export const yupPitSpacePrice = yup
  .number()
  .min(0, 'Min value is 0')
  // TODO: Adjust based on currency
  .max(MAX_PIT_SPACE_PRICE, 'Contact us for larger pricing')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : numeral(value).value(),
  );
export const PriceColumnFormSchema = yup.object().shape({
  price: yupPitSpacePrice,
});

// Add-on Column Popover
const yupPitSpaceAddOnName = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_PIT_SPACE_NAME_ADD_ON_LENGTH,
    `We can't have more than ${MAX_PIT_SPACE_NAME_ADD_ON_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your pit space name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const AddOnColumnFormSchema = yup.object().shape({
  addOn: yup.array().of(
    yup.object().shape({
      name: yupPitSpaceAddOnName,
      price: yupPitSpacePrice,
    }),
  ),
  // .required('Required')
  // .min(1, 'Need at least one Add-on'),
});

// Size Column Popover
// Plot Type
export const yupPitSpacePlot = yup.mixed().when(['shouldUsePlotSize'], {
  // is: sizeType => sizeType === PitSpaceSizeTypesEnum.Plot,
  is: Boolean,
  then: yup
    .number()
    .required('Required')
    .typeError('Required')
    .min(1, 'Required')
    .max(MAX_PIT_SPACE_SIZE, 'Need to be less than a square mile'),
});
export const yupPitSpacePlotType = yup.mixed().when(['shouldUsePlotSize'], {
  is: Boolean,
  then: yup
    .string()
    .required('Required')
    .typeError('Required')
    .oneOf(Object.keys(PitSpaceSizeOptionsLinear), 'Required'),
});
// Unit Size
export const yupPitSpaceUnit = yup.mixed().when(['shouldUseUnitSize'], {
  is: Boolean,
  then: yup
    .number()
    .required('Required')
    .typeError('Required')
    .min(1, 'Required')
    .max(MAX_PIT_SPACE_SIZE, 'Need to be less than a square mile'),
});
export const yupPitSpaceUnitType = yup.mixed().when(['shouldUseUnitSize'], {
  is: Boolean,
  then: yup
    .string()
    .required('Required')
    .typeError('Required')
    .oneOf(Object.keys(PitSpaceSizeOptionsSquared), 'Required'),
});
export const SizeColumnFormSchema = yup.object().shape({
  plotLength: yupPitSpacePlot,
  plotLengthType: yupPitSpacePlotType,
  plotWidth: yupPitSpacePlot,
  plotWidthType: yupPitSpacePlotType,
  unitSize: yupPitSpaceUnit,
  unitSizeType: yupPitSpaceUnitType,
});

// Availability Column Popover
const yupPitSpaceSpots = yup
  .number()
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );
export const AvailabilityColumnFormSchema = yup.object().shape({
  spotsAvailable: yupPitSpaceSpots,
});

// Create Pit Space
const yupPitSpaceName = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_PIT_SPACE_NAME_LENGTH, `We can't have more than ${MAX_PIT_SPACE_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your pit space name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupPitSpaceNameRequired = yupPitSpaceName.required('We need a name. :)');
export const CreatePitSpaceFormSchema = yup.object().shape({
  name: yupPitSpaceNameRequired,
  plotLength: yupPitSpacePlot,
  plotLengthType: yupPitSpacePlotType,
  plotWidth: yupPitSpacePlot,
  plotWidthType: yupPitSpacePlotType,
  unitSize: yupPitSpaceUnit,
  unitSizeType: yupPitSpaceUnitType,
  price: yupPitSpacePrice,
  spotsAvailable: yupPitSpaceSpots,
});

/** ******************************************************************************
 * Volunteer
 ******************************************************************************** */
// Job Title
const yupVolunteerJobTitle = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_VOLUNTEER_TITLE_LENGTH,
    `We can't have more than ${MAX_VOLUNTEER_TITLE_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your job title',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupVolunteerJobTitleRequired = yupVolunteerJobTitle.required('We need a title. :)');
// Job Location info
const yupVolunteerJobLocationInfo = yup
  .string()
  .max(
    MAX_VOLUNTEER_LOCATION_INFO_LENGTH,
    `We can't have more than ${MAX_VOLUNTEER_LOCATION_INFO_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines);
export const VolunteerJobFormSchema = yup.object().shape({
  title: yupVolunteerJobTitleRequired,
  locationInfo: yupVolunteerJobLocationInfo,
});
// Shift Name
const yupVolunteerShiftName = yup
  .string()
  .min(3, '3 characters min')
  .max(MAX_VOLUNTEER_SHIFT_NAME_LENGTH, `${MAX_VOLUNTEER_SHIFT_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your shift name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .required('We need a name. :)');

export const VolunteerShiftFormSchema = yup.object().shape({
  startDate: yupDateRequired,
  endDate: yupDateRequired,
  name: yupVolunteerShiftName,
});

/** *******************************************************************************************
 * Tickets
 ********************************************************************************************* */
// Name
const yupTicketName = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_TICKET_NAME_LENGTH, `We can't have more than ${MAX_TICKET_NAME_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your ticket name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupTicketNameRequired = yupTicketName.required('We need a title. :)');
export const TicketNameFormSchema = yup.object().shape({
  name: yupTicketNameRequired,
});

// Price
export const yupTicketPrice = yup
  .number()
  .min(0, 'Min value is 0')
  // TODO: Adjust based on currency
  .max(MAX_TICKET_PRICE, 'Contact us for larger pricing')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );
export const TicketPriceFormSchema = yup.object().shape({
  price: yupTicketPrice,
});

export const yupTicketTypeRequired = yup.string().typeError('Required').required('Required');

// Availability Column Popover
const yupTicketAvailability = yup
  .number()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  )
  .nullable();
export const TicketAvailabilityColumnFormSchema = yup.object().shape({
  ticketsAvailable: yupTicketAvailability,
});
// Schema
export const TicketCreateNewFormSchema = yup.object().shape({
  name: yupTicketName,
  price: yupTicketPrice,
  ticketsAvailable: yupTicketAvailability,
});
export const TicketAddExistingFormSchema = yup.object().shape({
  tickets: yup
    .array()
    .test('empty-check', 'Please choose at least one ticket', (tickets) => !R.isEmpty(tickets)),
});

/** *******************************************************************************************
 * Merchandise
 ********************************************************************************************* */
const yupMerchandiseTitle = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_MERCHANDISE_TITLE_LENGTH,
    `We can't have more than ${MAX_MERCHANDISE_TITLE_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your merchandise title',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  );
export const yupMerchandiseTitleRequired = yupMerchandiseTitle.required('We need a title. :)');
export const MerchandiseTitleFormSchema = yup.object().shape({
  title: yupMerchandiseTitleRequired,
});
export const yupMerchandiseDeliveryTypeRequired = yup
  .string()
  .typeError('Required')
  .required('Please enter a delivery type.');
export const MerchandiseDeliveryTypeFormSchema = yup.object().shape({
  deliveryType: yupMerchandiseDeliveryTypeRequired,
});
export const yupMerchandisePrice = yup
  .number()
  .min(0, 'Min value is 0')
  .max(MAX_MERCHANDISE_PRICE, 'Contact us for larger pricing')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : numeral(value).value(),
  );
export const MerchandisePriceFormSchema = yup.object().shape({
  price: yupMerchandisePrice,
});
export const yupMerchandiseStock = yup
  .number()
  .min(1, 'Enter at least one or delete the value for Unlimited')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : numeral(value).value(),
  );
export const MerchandiseStockFormSchema = yup.object().shape({
  stock: yupMerchandiseStock,
});
// Schema
export const MerchandiseCreateNewFormSchema = yup.object().shape({
  title: yupMerchandiseTitleRequired,
  stock: yupMerchandiseStock,
  price: yupMerchandisePrice,
  deliveryType: yupMerchandiseDeliveryTypeRequired,
});
export const MerchandiseAddExistingFormSchema = yup.object().shape({
  merchandise: yup
    .array()
    .test(
      'empty-check',
      'Please choose at least one piece of merchandise',
      (merchandise) => !R.isEmpty(merchandise),
    ),
});

// Merch options
const yupMerchOptionValueRequired = yup
  .string()
  .typeError('Required')
  .max(
    MAX_VARIANT_OPTION_VALUE_NAME_LENGTH,
    `We can't have more than ${MAX_VARIANT_OPTION_VALUE_NAME_LENGTH} characters`,
  )
  .required('Required');
const yupMerchOptionNameRequired = yup
  .string()
  .typeError('Required')
  .max(
    MAX_VARIANT_OPTION_NAME_LENGTH,
    `We can't have more than ${MAX_VARIANT_OPTION_NAME_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .required('Required');
const yupMerchOptionValuesRequired = yup
  .array()
  .of(
    yup.object().shape({
      value: yupMerchOptionValueRequired,
    }),
  )
  .min(1, 'Need at least one option')
  .max(MAX_VARIANT_OPTION_VALUES, `Need ${MAX_VARIANT_OPTION_VALUES} or fewer item.`)
  .required('Required')
  .test(
    'unique',
    'All values must be unique.',
    function test(list: Record<string, unknown>[]): boolean {
      return list.length === R.uniqBy((item) => item.value, list).length;
    },
  );
// Schema
export const MerchandiseVariantOptionFormSchema = yup.object().shape({
  name: yupMerchOptionNameRequired,
  values: yupMerchOptionValuesRequired,
});
export const MerchandiseVariantOptionNameFormSchema = yup.object().shape({
  name: yupMerchOptionNameRequired,
});
export const MerchandiseVariantOptionValuesFormSchema = yup.object().shape({
  value: yupMerchOptionValueRequired,
});

/** ******************************************************************************
 * Registration
 ******************************************************************************** */
export const EventCapacitySpotsAvailableFormSchema = yup.object().shape({
  spotsAvailable: yupSpotsAvailable,
});

// Create or Edit a custom form question
const yupIsRequired = yup.mixed().required('Required');
const yupOptionItemsRequired = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required('Required'),
    }),
  )
  .min(2, 'Need at least two options')
  .max(
    MAX_CUSTOM_QUESTION_OPTION_ITEMS,
    `Need ${MAX_CUSTOM_QUESTION_OPTION_ITEMS} or fewer item. Try adding another question.`,
  )
  .required('Required')
  .test(
    'unique',
    'All options must be unique.',
    function test(list: Record<string, unknown>[]): boolean {
      return list.length === R.uniqBy((item) => item.name, list).length;
    },
  );
export const SaveCustomQuestionFormSchema = yup.object().shape({
  isRequired: yupIsRequired,
  eventCustomQuestionCategories: yup.mixed().when(['appliesToAllCategories'], {
    is: (val) => !val,
    then: yup.array().min(1, 'Please select a category').required('Please select a category'),
  }),
  inputLabel: yup.mixed().when(['questionType'], {
    is: (questionType) =>
      questionType && questionType === QuestionTypes.Text && questionType === QuestionTypes.Number,
    then: yup.string().typeError('Required').required('Required'),
  }),
  questionType: yup.mixed().required('Required'),
  eventCustomQuestionOptions: yup.mixed().when(['questionType'], {
    is: (questionType: QuestionTypes) =>
      questionType && questionType !== QuestionTypes.Text && questionType !== QuestionTypes.Number,
    then: yupOptionItemsRequired,
  }),
  title: yup.string().typeError('Required').required('Required'),
});
const yupRefundInfoRequired = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_REFUND_INFO_LENGTH, `We can't have more than ${MAX_REFUND_INFO_LENGTH} characters`);
export const TempRefundContainerFormSchema = yup.object().shape({
  refundInfo: yupRefundInfoRequired,
});

const yupPromoCodeRequired = yup
  .string()
  .min(3, 'Code must be at least 3 characters')
  .typeError('Required')
  .required('Required');
export const PromoCodeCreateNewFormSchema = yup.object().shape({
  amountOff: yup.mixed().when('discountType', {
    is: (discountType: PromoTypeEnum) => discountType === PromoTypeEnum.AMOUNT,
    then: yup.number().required('Required').typeError('Required'),
  }),
  percentOff: yup.mixed().when('discountType', {
    is: (discountType: PromoTypeEnum) => discountType === PromoTypeEnum.PERCENT,
    then: yup
      .number()
      .max(100, `Enter a number less than or equal to 100`)
      .required('Required')
      .typeError('Required'),
  }),
  code: yupPromoCodeRequired.when('$promoCodeNames', (promoCodeNames: string[], schema: any) => {
    return schema.test('unique', 'Code already exists', (code: string) => {
      return !promoCodeNames.includes(code);
    });
  }),
  validFrom: yup.mixed().when('availableImmediately', {
    is: (availableImmediately: boolean) => !availableImmediately,
    then: yupDateRequired,
  }),
  validTo: yup.mixed().when('availableForever', {
    is: (availableForever: boolean) => !availableForever,
    then: yupDateRequired,
  }),
});

/** *******************************************************************************************
 * Schedule
 ********************************************************************************************* */
// Registration Dates

export const yupDateFutureOrPast = yup
  .date()
  .max(
    addYears(new Date(), MAX_YEARS_IN_FUTURE),
    `We're all for planning ahead, but ${MAX_YEARS_IN_FUTURE} years is too far!`,
  );
export const yupDateFutureOrPastRequired = yupDateFutureOrPast
  .typeError('Please enter a valid date')
  .required('Please enter a valid date');
export const RegistrationDatesFormSchema = yup.object().shape({
  regOpenDate: yupDateFutureOrPastRequired,
  regCloseDate: yupDateFutureOrPastRequired,
  // regDates: yup
  //   .array()
  //   .min(2, 'Please enter an end date or click-away to reset')
  //   .of(
  //     yup
  //       .date()
  //       .max(
  //         addYears(new Date(), MAX_YEARS_IN_FUTURE),
  //         `We're all for planning ahead, but ${MAX_YEARS_IN_FUTURE} years is too far!`,
  //       )
  //       .typeError('Please enter a valid date')
  //       .required('Please enter a valid date'),
  //   ),
});

// Self Edit Options
export const SelfEditOptionsFormSchema = yup.object().shape({
  selfEditEndDate: yupDateRequired,
});

// Additional Questions
const yupScheduleTypeNameRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_SCHEDULE_TYPE_NAME_LENGTH,
    `We can't have more than ${MAX_SCHEDULE_TYPE_NAME_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .required('Enter a type');
const yupScheduleTitleRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_SCHEDULE_TITLE_LENGTH, `We can't have more than ${MAX_SCHEDULE_TITLE_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your schedule title',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .required('We need a title. :)');
export const CreateScheduleItemFormSchema = yup.object().shape({
  title: yupScheduleTitleRequired,
  // endTime: yup.mixed().required('Enter a end time.'),
  // startTime: yup.mixed().required('Enter a start time.'),
  scheduleItemCategories: yup.mixed().when(['appliesToAllCategories'], {
    is: (val) => !val,
    then: yup.array().min(1, 'Please select a category').required('Please select a category'),
  }),
});

export const ScheduleTypeNameFormSchema = yup.object().shape({
  type: yupScheduleTypeNameRequired,
});

/** ******************************************************************************
 * Sponsors
 ******************************************************************************** */
const yupSponsorName = yup
  .string()
  .max(MAX_SPONSOR_NAME_LENGTH, `${MAX_SPONSOR_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines);
export const yupSponsorNameRequired = yupSponsorName.required('Enter a name');
const yupSponsorWebsite = yup
  .string()
  .max(
    MAX_SPONSOR_WEBSITE_LENGTH,
    `The website needs to be ${MAX_SPONSOR_WEBSITE_LENGTH} characters max`,
  )
  .transform(trimAndRemoveNewLines)
  .matches(URL_REG_EXP, 'Enter a valid URL');
export const yupSponsorWebsiteRequired = yupSponsorWebsite.required('Enter a website.');

export const CreateEditFormSchema = yup.object().shape({
  name: yupSponsorNameRequired,
  website: yupSponsorWebsiteRequired,
});

/** *******************************************************************************************
 * Basic Waiver
 ********************************************************************************************* */
const yupBasicWaiverName = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_BASIC_WAIVER_NAME_LENGTH,
    `We can't have more than ${MAX_BASIC_WAIVER_NAME_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your waiver name',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .test('hasExistingName', 'This name already exists', (value, context) => {
    const duplicateNameCount: number = (context.options?.context?.existingNames ?? []).filter(
      (existingName) => existingName === value,
    ).length;
    return !duplicateNameCount;
  });
export const yupBasicWaiverNameRequired = yupBasicWaiverName.required('We need a name. :)');
export const BasicWaiverFormSchema = yup.object().shape({
  name: yupBasicWaiverNameRequired,
});
/** *******************************************************************************************
 * Additional Info
 ********************************************************************************************* */
const yupAdditionalInfoTitleRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_ADDITIONAL_INFO_TITLE_LENGTH,
    `We can't have more than ${MAX_ADDITIONAL_INFO_TITLE_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your additional info title',
    (value) => !profanityFilter.isProfane(value?.toLowerCase()),
  )
  .required('We need a title. :)');
const yupAdditionalInfoAboutRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_ADDITIONAL_INFO_ABOUT_LENGTH,
    `We can't have more than ${MAX_ADDITIONAL_INFO_ABOUT_LENGTH} characters`,
  )
  .transform(trimAndKeep2NewLines)
  .required('Enter a description.');
const yupEventWebsite = yup
  .string()
  .nullable()
  .max(
    MAX_EVENT_WEBSITE_LENGTH,
    `The website needs to be ${MAX_EVENT_WEBSITE_LENGTH} characters max`,
  )
  .transform(trimAndRemoveNewLines)
  .matches(URL_REG_EXP, {
    excludeEmptyString: true,
    message: 'Please enter a valid URL',
  });
export const AdditionalInfoFormSchema = yup.object().shape({
  description: yupAdditionalInfoAboutRequired,
  eventWebsite: yupEventWebsite,
});
export const AdditionalInfoContainerFormSchema = yup.object().shape({
  title: yupAdditionalInfoTitleRequired,
});
/** *******************************************************************************************
 * Schedule Clinic or Ride
 ********************************************************************************************* */
const yupScheduleClinicMeetingLink = yup
  .string()
  .typeError('Enter a meeting link')
  .min(3, 'Please enter a url')
  .max(MAX_MEETING_LINK_LENGTH, `We can't have more than ${MAX_MEETING_LINK_LENGTH} characters`)
  .transform(trimAndRemoveNewLines)
  .matches(URL_REG_EXP, 'Enter a valid URL');
export const yupScheduleClinicMeetingLinkRequired = yupScheduleClinicMeetingLink.required(
  'Required',
);

// Price Override
export const yupScheduleInstancePriceOverride = yup
  .number()
  .min(0, 'Min value is 0')
  // TODO: Adjust based on currency
  .max(MAX_ENTRY_FEE, 'Contact us for larger pricing')
  .nullable()
  .transform((value: string, originalValue: string) =>
    isNilEmptyOrNaN(originalValue) ? null : value,
  );
export const ScheduleInstancePriceOverrideColumnFormSchema = yup.object().shape({
  price: yupScheduleInstancePriceOverride,
});
// Spot Availability
export const ScheduleInstanceSpotsAvailableColumnFormSchema = yup.object().shape({
  spotsAvailable: yupSpotsAvailable,
});
// Meeting Link
export const ScheduleClinicMeetingLinkColumnFormSchema = yup.object().shape({
  meetingLink: yupScheduleClinicMeetingLinkRequired,
});
// Day-of Contact
const yupDayOfContact = yup.string().ensure().matches(PHONE_REG_EXP, {
  message: 'Please enter a valid phone number',
  excludeEmptyString: true,
});
export const ScheduleInstanceDayOfContactColumnFormSchema = yup.object().shape({
  dayOfContact: yupDayOfContact,
});
// Recurrence
const getYupInstanceRecurrenceOccurrences = (interval: EventRecurrenceIntervalEnum): SchemaLike => {
  //
  let maxOccurrences = 12;
  if (interval === EventRecurrenceIntervalEnum.Weekly) {
    maxOccurrences = 52;
  }
  if (interval === EventRecurrenceIntervalEnum.BiWeekly) {
    maxOccurrences = 26;
  }
  return yup
    .number()
    .required('Required')
    .typeError('Required')
    .min(1, 'Required')
    .max(maxOccurrences, `Can only schedule 1 year in advance`);
};
const yupInstanceRecurrenceInterval = yup.string().required().typeError('Required');
const yupInstanceRecurrenceOccurrences = yup
  .mixed()
  .when('recurrenceInterval', {
    is: (recurrenceInterval: EventRecurrenceIntervalEnum) =>
      recurrenceInterval === EventRecurrenceIntervalEnum.Weekly,
    then: getYupInstanceRecurrenceOccurrences(EventRecurrenceIntervalEnum.Weekly),
  })
  .when('recurrenceInterval', {
    is: (recurrenceInterval: EventRecurrenceIntervalEnum) =>
      recurrenceInterval === EventRecurrenceIntervalEnum.BiWeekly,
    then: getYupInstanceRecurrenceOccurrences(EventRecurrenceIntervalEnum.BiWeekly),
  })
  .when('recurrenceInterval', {
    is: (recurrenceInterval: EventRecurrenceIntervalEnum) =>
      recurrenceInterval === EventRecurrenceIntervalEnum.Monthly,
    then: getYupInstanceRecurrenceOccurrences(EventRecurrenceIntervalEnum.Monthly),
  });

// Schedule Ride
export const ScheduleRideFormSchema = yup.object().shape({
  dayOfContact: yupDayOfContact,
  recurrenceInterval: yupInstanceRecurrenceInterval,
  recurrenceOccurrences: yupInstanceRecurrenceOccurrences,
});

// Schedule Clinic
export const ScheduleClinicFormSchema = yup.object().shape({
  recurrenceInterval: yupInstanceRecurrenceInterval,
  recurrenceOccurrences: yupInstanceRecurrenceOccurrences,
  meetingLink: yup.mixed().when('isVirtualEvent', {
    is: Boolean, // isVirtualEvent is a boolean
    then: yupScheduleClinicMeetingLinkRequired,
  }),
});
