import React, { useState } from 'react';
import { useFragment } from 'react-relay/hooks';
import { Card, Divider, Grid, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { graphql } from 'relay-runtime';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import BoxShadowScrollIndicator from '@/hoc/BoxShadowScrollIndicator';

import DeleteConfirmClausesDialog from '@/components/Dialogs/DeleteConfirmationClausesDialog';
import InfoDialog from '@/components/Dialogs/InfoDialog/InfoDialog';

import { useTrackSentryError } from '@/analytics/sentry';
import {
  OccurrencesDialog_event,
  OccurrencesDialog_event$key,
} from '@/containers/HostEvent/EventMetadataDialog/__generated__/OccurrencesDialog_event.graphql';
import { useDeleteEventAndOccurrencesMutation } from '@/containers/HostEvent/EventMetadataDialog/eventMetadataMutations';
import OccurrenceInfo, {
  sortEvents,
} from '@/containers/HostEvent/EventMetadataDialog/OccurrenceInfo';
import { useGetEventCuid } from '@/containers/HostEvent/helpers';
import { getHasCategoriedEventEventPassed } from '@/lib/event-info-utils';
import { useHostEventData } from '@/providers/HostEventProvider';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  occurrenceContainer: {
    maxHeight: '190px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    overflowY: 'auto',
  },
  occurrence: {
    minHeight: '60px',
    height: '100%',
  },
  selectedOccurrence: {
    borderColor: Colors.Success,
  },
}));

export type MetadataEvent = OccurrencesDialog_event['eventMetadata']['events'][0];

interface OccurrencesDialogProps {
  handleClose: () => void;
  isOpen: boolean;
}
const OccurrencesDialog: React.FC<OccurrencesDialogProps> = ({ handleClose, isOpen }) => {
  // Section Query
  const hostEventData = useHostEventData();
  const dialogData = useFragment<OccurrencesDialog_event$key>(
    graphql`
      fragment OccurrencesDialog_event on event {
        organizerCuid
        eventMetadata {
          cuid
          events(where: { deleted_at: { _is_null: true } }) {
            cuid
            startDate
            endDate
            lastSavedAt
            canceledAt
            publishedAt
            cancellationReason
            occurrenceLabel
            registrations(
              where: {
                _and: [
                  { _or: [{ status: { _eq: complete } }, { status: { _eq: canceled } }] }
                  { event: { deleted_at: { _is_null: true } } }
                ]
              }
            ) {
              userId
            }
          }
        }
      }
    `,
    hostEventData,
  );
  const classes = useStyles();
  const eventCuid = useGetEventCuid();
  const { enqueueSnackbar } = useSnackbar();
  const trackSentryError = useTrackSentryError();
  const [events, setEvents] = useState<MetadataEvent[]>(
    dialogData.eventMetadata.events ? sortEvents(dialogData.eventMetadata.events) : [],
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { deleteEventAndOccurrencesMutation } = useDeleteEventAndOccurrencesMutation();

  const totalRegistrations = dialogData.eventMetadata.events.reduce(
    (total, next) => total + next.registrations.length,
    0,
  );
  const hasRegistrations = totalRegistrations > 0;

  /**
   * Handle Close Dialog
   */
  const closeDialog = (): void => {
    handleClose();
  };

  /**
   * Delete event and all occurrences
   * A webhook fires to delete all the events (occurrences) from the database
   */
  const showDeleteConfirmationDialog = (): void => {
    if (hasRegistrations) {
      enqueueSnackbar('Cannot delete event with registrations', { variant: 'error' });
      return;
    }
    setIsDeleteDialogOpen(true);
  };
  const hideDeleteConfirmationDialog = (): void => {
    setIsDeleteDialogOpen(false);
  };
  const deleteEvent = async (): Promise<void> => {
    try {
      await deleteEventAndOccurrencesMutation(
        dialogData.eventMetadata.cuid,
        events.map((e) => e.cuid),
      );
    } catch (err) {
      trackSentryError(err);
    }
  };

  return (
    <InfoDialog
      showCloseButton
      hideDialog={closeDialog}
      isOpen={isOpen}
      maxWidth="xs"
      title="Occurrences"
    >
      {isDeleteDialogOpen && (
        <DeleteConfirmClausesDialog
          clauses={[
            'I will not be able to recover them.',
            'I will still be able to see my registration/revenue stats and the cyclist will still be able to see their history for this event.',
            "I'm very sure I want to delete this event.",
          ]}
          clausesPre="I understand that once I delete this event and all of it's occurrences,"
          isOpen={isDeleteDialogOpen}
          onClose={hideDeleteConfirmationDialog}
          onDelete={deleteEvent}
          title="Delete Event"
        />
      )}
      <Box display="flex" flexDirection="column">
        <Typography gutterBottom variant="body2">
          Use occurrences to use the same structure different dates. For example, you could have a
          "Summer Event", or a "Winter Event", at the same venue.
        </Typography>
        <Typography gutterBottom variant="body2">
          Occurrences are sorted descending by "Last Saved" date.
        </Typography>
        <Typography gutterBottom variant="body2">
          Occurrences and events with registrations can only canceled, they cannot be deleted.
        </Typography>
        <Typography gutterBottom variant="body2">
          <b>To edit an occurrence label, click the three dots and choose "Change Label".</b>
        </Typography>
        <div>
          <BoxShadowScrollIndicator noBorder dependency={events.length}>
            <Grid container className={classes.occurrenceContainer} spacing={2}>
              {events.map((event) => {
                const isCurrentOccurrence = eventCuid === event.cuid;
                return (
                  <Grid item key={event.cuid} xs={12}>
                    <Card
                      className={classNames(classes.occurrence, {
                        [classes.selectedOccurrence]: isCurrentOccurrence,
                      })}
                      variant="outlined"
                    >
                      <OccurrenceInfo
                        event={event}
                        isCurrentOccurrence={isCurrentOccurrence}
                        isLastOccurrence={events.length === 1}
                        setEvents={setEvents}
                      />
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </BoxShadowScrollIndicator>
        </div>
        <Box mb={2}>
          <Divider />
        </Box>
        <div>
          <Typography variant="h3">Delete Event</Typography>
          <Typography>You can delete this event and all of it's occurrences.</Typography>
          {!hasRegistrations ? (
            <Box display="flex" flexDirection="column" justifyContent="center" my={2}>
              <ExtendedButton
                disabled={hasRegistrations}
                minWidth="sm"
                onClick={showDeleteConfirmationDialog}
                variant="outlined"
              >
                Delete Event & Occurrences
              </ExtendedButton>
            </Box>
          ) : (
            <Typography style={{ color: Colors.Warning }} variant="body2">
              Cannot delete event with registrations ({totalRegistrations} Registrations)
            </Typography>
          )}
        </div>
      </Box>
    </InfoDialog>
  );
};

export default OccurrencesDialog;
