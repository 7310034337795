/**
 * Create a chip with colors and text round
 */
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box/Box';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { CreateCSSProperties } from '@material-ui/styles';
import classNames from 'classnames';

import { Colors } from '@/themes/colors';

type ChipColorProps = { backgroundColor: string; outlined: boolean; small: boolean };
const useStyles = makeStyles((theme: Theme) => ({
  textChip: ({
    backgroundColor,
    outlined,
    small,
  }: ChipColorProps): CreateCSSProperties<ChipColorProps> => ({
    backgroundColor: outlined ? 'transparent' : backgroundColor,
    borderRadius: '40px',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '60px',
    padding: small ? theme.spacing(0, 1) : theme.spacing('1px', 1.25),
    border: outlined ? `1px solid ${backgroundColor}` : undefined,
  }),
}));

export interface RoundTextChipProps {
  backgroundColor?: string;
  outlined?: boolean;
  small?: boolean;
  tinyFont?: boolean;
  title: string;
  wrapText?: boolean;
}
const RoundTextChip: React.FC<RoundTextChipProps & BoxProps> = ({
  backgroundColor,
  outlined,
  small,
  tinyFont,
  title,
  wrapText,
  ...rest
}) => {
  const classes = useStyles({
    backgroundColor: backgroundColor ?? (outlined ? Colors.GunPowder : 'white'),
    outlined: Boolean(outlined),
    small: Boolean(small),
  });
  return (
    <Box {...rest} color={outlined ? backgroundColor : 'white'} display="inline-flex">
      <Typography
        className={classNames(classes.textChip)}
        color="inherit"
        noWrap={!wrapText}
        style={tinyFont ? { fontSize: '0.65rem' } : undefined}
        variant={small ? 'caption' : 'body2'}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default RoundTextChip;
