/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ChangeLogPage_changeLog_QueryVariables = {};
export type ChangeLogPage_changeLog_QueryResponse = {
    readonly changeLogDate_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly changeLogItems: ReadonlyArray<{
                    readonly id: string;
                    readonly changeType: string;
                    readonly changeText: string;
                }>;
            };
        }>;
    };
};
export type ChangeLogPage_changeLog_Query = {
    readonly response: ChangeLogPage_changeLog_QueryResponse;
    readonly variables: ChangeLogPage_changeLog_QueryVariables;
};



/*
query ChangeLogPage_changeLog_Query {
  changeLogDate_connection(order_by: {date: desc}) {
    edges {
      node {
        id
        date
        changeLogItems {
          id
          changeType
          changeText
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "order_by",
                    "value": {
                        "date": "desc"
                    }
                }
            ],
            "concreteType": "changeLogDateConnection",
            "kind": "LinkedField",
            "name": "changeLogDate_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "changeLogDateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "changeLogDate",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "date",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "changeLogItem",
                                    "kind": "LinkedField",
                                    "name": "changeLogItems",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "changeType",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "changeText",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "changeLogDate_connection(order_by:{\"date\":\"desc\"})"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeLogPage_changeLog_Query",
            "selections": (v1 /*: any*/),
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ChangeLogPage_changeLog_Query",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "aaa69f391208c2016969fdbbe35fb07f",
            "id": null,
            "metadata": {},
            "name": "ChangeLogPage_changeLog_Query",
            "operationKind": "query",
            "text": "query ChangeLogPage_changeLog_Query {\n  changeLogDate_connection(order_by: {date: desc}) {\n    edges {\n      node {\n        id\n        date\n        changeLogItems {\n          id\n          changeType\n          changeText\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1cf8f915dd8c2cd0ac21faf9cc94d2e3';
export default node;
