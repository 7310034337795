import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import ExtendedButton from '@/ui/Button/ExtendedButton';

import { ReactFCC } from '@/lib/type-defs/utility';

export interface DeleteConfirmClausesDialogProps {
  disableProgressiveAgreementOrder?: boolean;
  isOpen: boolean;
  cancelLabel?: string;
  deleteLabel?: string;
  title: React.ReactChild;
  clauses: React.ReactChild[];
  clausesPre?: React.ReactChild;
  clausesPost?: React.ReactChild;
  onDelete: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onClose: () => void | Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: { fontSize: theme.typography.body1.fontSize },
  actions: { justifyContent: 'space-between' },
  agreement: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
  },
}));

const DeleteConfirmClausesDialog: ReactFCC<DeleteConfirmClausesDialogProps> = ({
  cancelLabel = 'Cancel',
  children,
  clauses,
  clausesPost,
  clausesPre,
  deleteLabel = 'Delete',
  disableProgressiveAgreementOrder,
  isOpen,
  onCancel,
  onClose,
  onDelete,
  title,
}) => {
  const classes = useStyles();

  const [checkedStatuses, setCheckedStatuses] = useState(new Array(clauses.length).fill(false));
  const allClausesAgreedTo = checkedStatuses.every((checked) => checked);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const deleteHandler = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setError(false);
      await onDelete();
      closeHandler();
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelHandler = async (): Promise<void> => {
    if (onCancel) {
      await onCancel();
    }
    closeHandler();
  };

  const closeHandler = (): void => {
    setCheckedStatuses(new Array(clauses.length).fill(false));
    setError(false);
    onClose();
  };

  const onCheck = (index: number, checked: boolean): void => {
    const newCheckedStatuses = checkedStatuses.slice();
    newCheckedStatuses[index] = checked;
    setCheckedStatuses(newCheckedStatuses);
  };

  const firstUncheckedIndex = checkedStatuses.findIndex((c) => !c);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={closeHandler} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {children}
        <Box className={classes.agreement} my={1}>
          {clausesPre && <Box mb={1}>{clausesPre}</Box>}
          <Box>
            {clauses.map((clause, i) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedStatuses[i]}
                    disabled={
                      !checkedStatuses[i] &&
                      !disableProgressiveAgreementOrder &&
                      i > firstUncheckedIndex
                    }
                    onChange={(e): void => onCheck(i, e.target.checked)}
                  />
                }
                label={<span className="strong">{clause}</span>}
              />
            ))}
          </Box>
          {clausesPost && <Box mt={1}>{clausesPost}</Box>}
        </Box>
        {error && <Typography color="error">There was an error. Please try again.</Typography>}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button disabled={isLoading} onClick={cancelHandler} variant="text">
          {cancelLabel}
        </Button>
        <ExtendedButton
          errorColor
          disabled={!allClausesAgreedTo || isLoading}
          isLoading={isLoading}
          onClick={deleteHandler}
          variant="contained"
        >
          {deleteLabel}
        </ExtendedButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmClausesDialog;
