import { Theme } from '@material-ui/core';

export const MuiPopover = (theme: Theme): Record<string, unknown> => ({
  root: {
    maxHeight: '60vh',
  },
  paper: {
    transition: 'none !important',
    '&.nicePopover': {
      border: '1px solid rgba(60, 66, 87, 0.11)',
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[24],
      padding: theme.spacing(3),
    },
  },
});
