/**
 * Discover Result Hit
 */
import React, { ReactElement, ReactNode } from 'react';
import { Box, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import PersonIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import SportsIcon from '@material-ui/icons/Sports';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import * as R from 'ramda';

import RoundTextChip from '@/ui/Chips/RoundTextChip';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';

import { getAttributesText } from '@/components/Search/DesktopHit';

import { DEFAULT_COACH_IMAGE, DEFAULT_EVENT_IMAGE, SearchTypeEnum } from '@/const';
import { AlgoliaSearchResultHit } from '@/lib/algolia';
import { applyDefaultCloudinaryTransform } from '@/lib/cloudinary';
import { getCoachingSummary, getReadableCoachVibe } from '@/lib/coach-info-utils';
import { getLessonSummaryNode } from '@/lib/lesson-info-utils';
import { addQueryParamsToUrl } from '@/lib/path-helpers';
import { getVenueLocationText } from '@/lib/places-utils';
import { arrayToCommaAndString, numberArrayToPriceRange } from '@/lib/string-utils';
import { textEllipsisProps } from '@/lib/ui-utils';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    border: Colors.LightBorder,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(3),
    height: 443,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    borderRadius: theme.spacing(0.25),
    height: 227,
    margin: theme.spacing(1.5, 0),
    '& > img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
      transition: 'transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      willChange: 'transform',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
  },
}));

const getActivityIcon = (activity: SearchTypeEnum): ReactElement | null => {
  switch (activity) {
    case SearchTypeEnum.Coach:
      return (
        <Tooltip title={SearchTypeEnum.Coach}>
          <PersonIcon />
        </Tooltip>
      );
    case SearchTypeEnum.PrivateLesson:
      return (
        <Tooltip title={SearchTypeEnum.PrivateLesson}>
          <SportsIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Clinic:
      return (
        <Tooltip title={SearchTypeEnum.Clinic}>
          <SchoolIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Ride:
      return (
        <Tooltip title={SearchTypeEnum.Ride}>
          <DirectionsBikeIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Race:
      return (
        <Tooltip title={SearchTypeEnum.Race}>
          <svg
            aria-hidden="true"
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root"
            data-testid="MilitaryTechIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="M17 10.43V2H7v8.43c0 .35.18.68.49.86l4.18 2.51-.99 2.34-3.41.29 2.59 2.24L9.07 22 12 20.23 14.93 22l-.78-3.33 2.59-2.24-3.41-.29-.99-2.34 4.18-2.51c.3-.18.48-.5.48-.86zm-4 1.8-1 .6-1-.6V3h2v9.23z" />
          </svg>
        </Tooltip>
      );
    case SearchTypeEnum.OnlineCourse:
      return (
        <Tooltip title={SearchTypeEnum.OnlineCourse}>
          <CastForEducationIcon />
        </Tooltip>
      );
    default:
      return null;
  }
};

/**
 * Get the url for a hit
 * @param hit
 */
const getImageUrls = (hit: AlgoliaSearchResultHit): string[] => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.images.length
        ? hit.images.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_EVENT_IMAGE];
    case SearchTypeEnum.Coach:
      return hit.profilePicUrl ? [hit.profilePicUrl] : [DEFAULT_COACH_IMAGE];
    case SearchTypeEnum.PrivateLesson:
      return hit.coachImages.length
        ? hit.coachImages.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_COACH_IMAGE];
    default:
      return [''];
  }
};

/**
 * Get the hostname for a hit
 * @param hit
 */
const getReviewText = (hit: AlgoliaSearchResultHit): ReactNode => {
  if (R.isNil(hit.reviewRecPct)) {
    return <b>New</b>;
  }
  return (
    <>
      {hit.reviewRecPct}% <ThumbUpAltIcon fontSize="inherit" />
    </>
  );
};

/**
 * Get the hostname for a hit
 * @param hit
 */
const getHostText = (hit: AlgoliaSearchResultHit): string => {
  switch (hit.activity) {
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.PrivateLesson:
      return `${hit.hostName || hit.organizerName}`;
    case SearchTypeEnum.Coach:
      return getReadableCoachVibe(hit?.coachingVibe);
    default:
      return '';
  }
};

/**
 * Get the description for a hit
 * @param hit
 */
const getDescriptionText = (hit: AlgoliaSearchResultHit): string | ReactNode => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.description;
    case SearchTypeEnum.Coach:
      return (
        hit.coachTagline ||
        hit.coachAbout ||
        getCoachingSummary({
          coachingVibe: hit.coachingVibe,
          coachDisciplines: hit.coachDisciplines,
        })
      );
    case SearchTypeEnum.PrivateLesson:
      return getLessonSummaryNode(hit);
    default:
      return <Typography variant="subtitle2">No description</Typography>;
  }
};

/**
 * Get the location for a hit
 * @param hit
 */
const getPriceText = (hit: AlgoliaSearchResultHit): string => {
  const prices = hit.entryFees || hit.prices || [hit.price];
  return numberArrayToPriceRange(prices, hit.currencyCode);
};

/**
 * Get a list of disciplines
 */
const getDisciplinesText = (hit: AlgoliaSearchResultHit): string => {
  return arrayToCommaAndString(hit.disciplines || hit.coachDisciplines.map((d) => d.name));
};

interface DiscoverHitProps {
  hit: AlgoliaSearchResultHit;
  sendClickEvent: () => void;
}
const MobileHit: React.FC<DiscoverHitProps> = ({ hit, sendClickEvent }) => {
  const classes = useStyles();
  const url = hit.onlineCourseUrl || hit.lessonUrl || hit.coachUrl || hit.eventUrl;
  // eslint-disable-next-line no-underscore-dangle
  const hitLink = addQueryParamsToUrl(url, { queryId: hit.__queryID });

  const activityIcon = getActivityIcon(hit.activity as SearchTypeEnum);
  const imageUrls = getImageUrls(hit);
  const titleText = hit.name || hit.hostName;
  const locationText = hit.isVirtual ? 'Virtual' : getVenueLocationText(hit);
  const hostText = getHostText(hit);
  const descriptionText = getDescriptionText(hit);
  const disciplinesText = getDisciplinesText(hit);
  const attributesText = getAttributesText(hit);
  const reviewText = getReviewText(hit);

  return (
    <NoUnderlineRouterLink onClick={sendClickEvent} to={hitLink}>
      <Box className={classes.root} width="auto">
        <Box display="flex" justifyContent="space-between">
          <Box minWidth={0}>
            <Typography
              style={{
                ...textEllipsisProps,
                color: Colors.DarkText,
                marginRight: '4px',
                marginBottom: '8px',
              }}
              variant="h3"
            >
              {titleText}
            </Typography>
            <Typography style={{ ...textEllipsisProps, color: Colors.DarkText }} variant="body1">
              {hostText}
            </Typography>
          </Box>
          <Box alignItems="center" ml={1}>
            <RoundTextChip backgroundColor={Colors.DarkBlue} title={hit.activity} />
            {/* <SVGIconBackground>{activityIcon}</SVGIconBackground> */}
          </Box>
        </Box>
        <Box className={classes.imageContainer} key={imageUrls[0]}>
          <img alt="Event Preview" src={imageUrls[0]} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={0.5}>
            <Typography style={{ ...textEllipsisProps }} variant="subtitle2">
              {attributesText}
            </Typography>
            <Typography style={{ whiteSpace: 'nowrap', marginRight: '4px' }} variant="subtitle2">
              {getPriceText(hit)}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography style={{ ...textEllipsisProps }} variant="subtitle2">
              {locationText}
            </Typography>
            <Typography style={{ whiteSpace: 'nowrap', marginRight: '4px' }} variant="subtitle2">
              {reviewText}
            </Typography>
          </Box>
          <Typography
            style={{ maxHeight: 60, overflow: 'hidden', wordBreak: 'break-word' }}
            variant="body2"
          >
            {descriptionText}
          </Typography>
        </Box>
      </Box>
    </NoUnderlineRouterLink>
  );
};

export default MobileHit;
