import React, { Suspense } from 'react';
import { Box, Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { DEFAULT_LOADING_SPINNER_DELAY } from '@/const';
import OrganizerHeader from '@/containers/Organizer/Navigation/OrganizerHeader';
import { ReactFCC } from '@/lib/type-defs/utility';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.pageBackground.grey,
  },
  content: {
    alignItems: 'stretch',
    display: 'flex',
    flex: '1 0 auto',
  },
}));

export interface IOrganizerLayout {
  removeContainer?: boolean;
  showHeader?: boolean;
}
const OrganizerLayout: ReactFCC<IOrganizerLayout> = ({
  children,
  removeContainer,
  showHeader = true,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {showHeader && <OrganizerHeader />}
      {removeContainer ? (
        <Suspense
          fallback={
            <LoadingPlaceholder disableShrink isLoading delay={DEFAULT_LOADING_SPINNER_DELAY} />
          }
        >
          {children}
        </Suspense>
      ) : (
        <Container className={classes.content} maxWidth="lg">
          <Box
            display="flex"
            flexDirection="column"
            mb={4}
            pb={4}
            px={{ xs: 0, sm: 2 }}
            width="100%"
          >
            <Suspense
              fallback={
                <LoadingPlaceholder disableShrink isLoading delay={DEFAULT_LOADING_SPINNER_DELAY} />
              }
            >
              {children}
            </Suspense>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default OrganizerLayout;
