import React, { useState } from 'react';
import { Hit } from 'react-instantsearch-core';
import { Marker } from 'react-map-gl';
// eslint-disable-next-line import/no-unresolved
import { MapboxEvent } from 'react-map-gl/src/types';
import { Box, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RoomIcon from '@material-ui/icons/Room';
import classNames from 'classnames';

import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';

import SmallSlickCarousel from '@/components/Cards/SmallSlickCarousel';

import { useHoveredHitGlobal } from '@/containers/Search/recoilStore';
import {
  getHitImageUrls,
  getHitSubtitleText,
} from '@/containers/Search/ResultsList/SearchResultHit';
import {AlgoliaSearchResult, AlgoliaSearchResultHit} from '@/lib/algolia';
import { numberArrayToPriceRange } from '@/lib/string-utils';
import { textEllipsisProps } from '@/lib/ui-utils';

const useStyles = makeStyles((theme: Theme) => ({
  marker: {
    display: 'flex',
    position: 'relative',
    '& svg': {
      width: '35px',
      height: '35px',
      filter: 'drop-shadow(0px 1px 3px #06759c5c)',
      color: theme.palette.primary.main,
      transition: 'all 200ms',
      transformOrigin: 'bottom',
      cursor: 'pointer',
    },
    '& > div': {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  markerHovered: {
    '& svg': {
      transform: 'scale(1.3) !important',
    },
  },
  markerDiscipline: {
    fontWeight: 600,
    textAlign: 'center',
    background: 'white',
    boxShadow: '0 2px 4px #0000002b',
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    top: '1px',
    opacity: 1,
    padding: theme.spacing(0, 0.5),
    transition: 'all 100ms',
  },
  markerDisciplineHovered: {
    opacity: 0,
  },
  markerBase: {
    background: '#e1e3e4',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    position: 'relative',
    top: '-8px',
    filter: 'drop-shadow(0px 2px 2px #00000038)',
    zIndex: -1,
  },
  popoverPaper: {
    border: 'none',
    marginTop: '12px !important',
    padding: '0 !important',
  },
  slickContainer: {
    borderRadius: 0,
    border: 'none',
  },
  subtitleText: {
    display: '-webkit-box',
    lineClamp: 4,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
}));

interface SearchMarkerProps {
  hit: AlgoliaSearchResultHit;
}
const SearchMarker: React.FC<SearchMarkerProps> = ({ hit }) => {
  const classes = useStyles();
  const [hoveredHitGlobal, setHoveredHit] = useHoveredHitGlobal();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const isHitHovered = hoveredHitGlobal && hoveredHitGlobal.objectID === hit.objectID;
  const priceRange = numberArrayToPriceRange(hit.prices, hit.currencyCode);
  const url = hit.onlineCourseUrl || hit.lessonUrl || hit.coachUrl || hit.eventUrl;
  const imageUrls = getHitImageUrls(hit);
  const subtitleText = getHitSubtitleText(hit);
  const titleText = hit.name || hit.hostName;
  // eslint-disable-next-line no-underscore-dangle
  const latitude = hit?._geoloc?.lat;
  // eslint-disable-next-line no-underscore-dangle
  const longitude = hit?._geoloc?.lng;
  if (!latitude || !longitude) {
    return null;
  }

  const isOpen = Boolean(anchorEl);

  const handlePopoverOpen = (e: MapboxEvent<MouseEvent>): void => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    setAnchorEl(e.originalEvent.target as Element);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * When a user enters a marker
   */
  const onHover = (): void => {
    setHoveredHit(hit);
  };

  /**
   * When a user leaves a marker
   */
  const onLeave = (): void => {
    setHoveredHit(null);
  };

  return (
    <Marker latitude={latitude} longitude={longitude} onClick={handlePopoverOpen}>
      <Box
        className={classNames(classes.marker, {
          [classes.markerHovered]: isHitHovered,
        })}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
      >
        <div>
          <Box
            className={classNames(classes.markerDiscipline, {
              [classes.markerDisciplineHovered]: isHitHovered,
            })}
          >
            {hit.activity}
          </Box>
          <RoomIcon />
          <div className={classes.markerBase} />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            classes={{
              paper: classNames(classes.popoverPaper, 'nicePopover'),
            }}
            onClose={handlePopoverClose}
            open={isOpen}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <NoUnderlineRouterLink noColor to={url}>
              <SmallSlickCarousel
                containerClassName={classes.slickContainer}
                imageUrls={imageUrls}
              />
              <Box px={1.5} py={1} width={300}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Typography style={textEllipsisProps}>
                    <b>{titleText}</b>
                  </Typography>
                  <Typography style={{ marginLeft: '4px' }}>{priceRange}</Typography>
                </Box>
                <Box mb={0.5}>
                  <Typography className={classes.subtitleText} variant="subtitle2">
                    {subtitleText}
                  </Typography>
                </Box>
              </Box>
            </NoUnderlineRouterLink>
          </Popover>
        </div>
      </Box>
    </Marker>
  );
};

export default SearchMarker;
