/**
 * Material-UI TextField with the ability to show/hide password by clicking on an eye
 */
import React, { useState } from 'react';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ExtendedTextField, { ExtendedTextFieldProps } from '@/ui/TextField/ExtendedTextField';

const useStyles = makeStyles({
  eye: {
    cursor: 'pointer',
  },
});

const PasswordField: React.FC<ExtendedTextFieldProps & TextFieldProps> = React.forwardRef(
  (props, ref) => {
    const [isMasked, setIsMasked] = useState(true);
    const classes = useStyles();

    return (
      <ExtendedTextField
        ref={ref}
        type={isMasked ? 'password' : 'text'}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isMasked ? (
                <Visibility className={classes.eye} onClick={(): void => setIsMasked(!isMasked)} />
              ) : (
                <VisibilityOff
                  className={classes.eye}
                  onClick={(): void => setIsMasked(!isMasked)}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  },
);

export default PasswordField;
