import { Theme } from '@material-ui/core';

import { Colors } from '../colors';

export const MuiCssBaseline = (theme: Theme): Record<string, unknown> => ({
  '@global': {
    'a, a:visited, a:active': {
      cursor: 'pointer',
      color: Colors.Brand,
      textDecoration: 'none',
    },
    'a:hover': {
      color: Colors.Brand,
      textDecoration: 'underline',
    },
    '.dropzone': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[400],
      outline: 'none',
    },
    '.react-resizable-handle': {
      width: 10,
      height: 10,
    },
    '.read-more-button': {
      display: 'inline-block',
      margin: '0 4px',
      color: Colors.Brand,
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.no-wrap': {
      whiteSpace: 'nowrap',
    },
    '.strong': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '.emphasized': {
      fontStyle: 'italic',
    },
  },
});
