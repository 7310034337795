import React, { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';

import ButtonLink from '@/ui/Button/ButtonLink';

import { KEY_CODE, SearchTypeEnum } from '@/const';
import { useSetSearchQueryGlobal } from '@/containers/Search/HeaderSearch/recoilStore';
import { useGeoSearch } from '@/lib/algolia-utils';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    position: 'relative',
    transition: 'all 250ms',
    backgroundColor: '#F4F4F4',
    border: '2px solid transparent',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  searchFocused: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.common.white,
    borderColor: '#03465c45',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    borderBottomColor: '#03465c45 !important',
  },
  searchHovered: {
    // borderColor: '#03465c45',
    // backgroundColor:  fade(theme.palette.common.white, 0.25),
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(26, 26, 29, 0.08) !important',
  },
  searchHoveredFocused: {
    borderColor: '#03465c95',
  },
  mobileSearchIcon: {
    color: '#999999',
    margin: theme.spacing('auto', 1, 'auto', 0),
  },
  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    // color: theme.palette.primary.main,
    color: '#C8C8C8',
    // filter: 'drop-shadow(0px 1px 1px rgba(20,200,255,.26))',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    pointerEvents: 'none',
    transform: 'scale(1.3)',
    width: theme.spacing(7),
  },
  inputRoot: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputInput: {
    // fontWeight: 600,
    // color: '#999999',
    padding: theme.spacing(1, 1, 1, 2),
    height: '28px',
    textOverflow: 'ellipsis',
    transition: theme.transitions.create('width'),
    width: '100%',
    '&::placeholder': {
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  inputFocused: {
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.sm,
    },
  },
}));

// const getActivity = (value: string): string | undefined => {
//   if (value.toLowerCase().includes(SearchTypeEnum.Race.toLowerCase())) {
//     return SearchTypeEnum.Race;
//   }
//   if (value.toLowerCase().includes(SearchTypeEnum.Clinic.toLowerCase())) {
//     return SearchTypeEnum.Clinic;
//   }
//   if (value.toLowerCase().includes(SearchTypeEnum.PrivateLesson.toLowerCase())) {
//     return SearchTypeEnum.PrivateLesson;
//   }
//   if (value.toLowerCase().includes(SearchTypeEnum.Coach.toLowerCase())) {
//     return SearchTypeEnum.Coach;
//   }
//   if (value.toLowerCase().includes(SearchTypeEnum.PrivateLesson.toLowerCase())) {
//     return SearchTypeEnum.Coach;
//   }
//   return undefined;
// };

export const getActivitiesFromQuery = (query: string): string[] | undefined => {
  const lowerCaseQuery = query.toLowerCase();
  const allActivities = [];
  if (lowerCaseQuery.includes(SearchTypeEnum.Clinic.toLowerCase())) {
    allActivities.push(SearchTypeEnum.Clinic);
  }
  if (lowerCaseQuery.includes(SearchTypeEnum.Race.toLowerCase())) {
    allActivities.push(SearchTypeEnum.Race);
  }
  if (lowerCaseQuery.includes(SearchTypeEnum.PrivateLesson.toLowerCase())) {
    allActivities.push(SearchTypeEnum.PrivateLesson);
  }
  if (lowerCaseQuery.includes(SearchTypeEnum.Ride.toLowerCase())) {
    allActivities.push(SearchTypeEnum.Ride);
  }
  if (lowerCaseQuery.includes(SearchTypeEnum.Coach.toLowerCase())) {
    allActivities.push(SearchTypeEnum.Coach);
  }
  return allActivities.length > 0 ? allActivities : undefined;
};

interface HeaderSearchFieldProps extends InputBaseProps {
  isSearchFocused: boolean;
  isSearchHovered: boolean;
  isMobileMenuOpen: boolean;
  handleChange(value: string): void;
  setIsSearchFocused: Dispatch<SetStateAction<boolean>>;
  setIsSearchHovered: Dispatch<SetStateAction<boolean>>;
  value: string;
}
const HeaderSearchField: React.FC<HeaderSearchFieldProps> = ({
  handleChange,
  isMobileMenuOpen,
  isSearchFocused,
  isSearchHovered,
  placeholder,
  setIsSearchFocused,
  setIsSearchHovered,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParams = getParsedQueryParams();
  const isSmallScreenDown = useIsSmallScreenDown();
  const setSearchQueryGlobal = useSetSearchQueryGlobal();
  const { clearMapRefinement } = useGeoSearch();

  const searchLink = AppRouteService.getRelativeUrl('Search', {}, { ...queryParams, query: value });

  /**
   * Execute search
   */
  const search = (): void => {
    clearMapRefinement();
    setSearchQueryGlobal(value);
    setIsSearchFocused(false);
    setIsSearchHovered(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setIsSearchFocused(true);
    handleChange(e.currentTarget.value);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const code = e.keyCode || e.charCode;
    if (code === KEY_CODE.Enter) {
      e.preventDefault();
      e.stopPropagation();
      search();
      navigate(searchLink);
    }
  };
  if (isMobileMenuOpen) {
    return null;
  }
  return (
    <div
      className={classNames(
        classes.search,
        { [classes.searchFocused]: isSearchFocused },
        { [classes.searchHovered]: isSearchHovered },
        { [classes.searchHoveredFocused]: isSearchHovered && isSearchFocused },
      )}
    >
      {/* <div className={classes.searchIcon}> */}
      {/*  <SearchIcon /> */}
      {/* </div> */}
      <InputBase
        name="search"
        {...rest}
        classes={{
          root: classNames(classes.inputRoot),
          input: classNames(classes.inputInput, { [classes.inputFocused]: isSearchFocused }),
        }}
        inputProps={{
          id: 'header-search-input',
          autoComplete: 'off',
          'aria-label': 'search',
          onChange: handleInputChange,
          onKeyDown: handleInputKeyDown,
          value,
        }}
        placeholder={
          placeholder ?? isSmallScreenDown ? 'Try "MTB Ride"' : 'Try "Jumping" or "MTB Ride"'
        }
      />
      {!isSearchFocused && (
        <Hidden smUp>
          <SearchIcon className={classes.mobileSearchIcon} />
        </Hidden>
      )}
      {isSearchFocused && (
        <ButtonLink
          color="primary"
          minWidth={isSmallScreenDown ? undefined : 'xs'}
          onClick={search}
          style={{
            padding: '9px 16px',
            borderRadius: '2px',
            top: '1px',
            right: '1px',
          }}
          to={searchLink}
          variant="contained"
        >
          {isSmallScreenDown ? <SearchIcon /> : 'Search'}
        </ButtonLink>
      )}
    </div>
  );
};

export default HeaderSearchField;
