/**
 * Section errors for host event
 * From ValidityChecker
 */
import React from 'react';
import { Card, lighten, Link, Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import * as RA from 'ramda-adjunct';

import { HostEventSectionName } from '@/containers/HostEvent/const';
import { scrollToSection } from '@/containers/HostEvent/helpers';
import { useGetErrorsForSection } from '@/containers/HostEvent/validityChecker/helpers';
import { arrayToCommaAndString } from '@/lib/string-utils';
import { arrayToCommaAndFragment } from '@/lib/ui-utils';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: lighten(Colors.Red, 0.8),
    border: `1px solid ${Colors.Red}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
}));
interface SectionErrorsProps {
  sectionName: HostEventSectionName;
  showSectionName?: boolean;
}
const SectionErrors: React.FC<SectionErrorsProps> = ({ sectionName, showSectionName }) => {
  const classes = useStyles();
  const sectionErrors = useGetErrorsForSection(sectionName);

  return (
    <>
      {RA.isNotEmpty(sectionErrors) && (
        <Box mb={4}>
          <Card variant="outlined">
            <Box p={2.5}>
              <Typography gutterBottom variant="h2">
                Section Errors{showSectionName && `: ${sectionName}`}
              </Typography>
              <Box className={classes.list}>
                {sectionErrors.map((sectionError) => (
                  <Box key={sectionError.errorMessage} ml={1}>
                    <Typography variant="body2">
                      <b>Error:</b>
                    </Typography>
                    <Box display="flex" ml={1.5}>
                      <Box display="flex" mr={0.5}>
                        <WarningRoundedIcon color="error" fontSize="small" />
                      </Box>
                      <Typography gutterBottom color="error" variant="subtitle2">
                        {sectionError.errorMessage}
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      <b>Actions you can take:</b>
                    </Typography>
                    <Box ml={1.5}>
                      <Typography gutterBottom variant="subtitle2">
                        {sectionError.remedy}
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      <b>Relevant Sections:</b>
                    </Typography>
                    <Box ml={1.5}>
                      <Typography gutterBottom variant="subtitle2">
                        {arrayToCommaAndFragment(
                          sectionError.sectionNames.map((sectionName) => (
                            <Link
                              component="button"
                              onClick={(): void => scrollToSection(sectionName)}
                              style={{ fontSize: 'inherit' }}
                            >
                              {sectionName}
                            </Link>
                          )),
                        )}
                      </Typography>
                    </Box>
                    {sectionName === 'Categories' && (
                      <>
                        <Typography variant="body2">
                          <b>Invalid Categories:</b>
                        </Typography>
                        <Box ml={1.5}>
                          <Typography gutterBottom variant="subtitle2">
                            {arrayToCommaAndString(
                              sectionError.invalidCategories.map((id) => id.name),
                            )}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {sectionName === 'Schedule' && (
                      <>
                        <Typography variant="body2">
                          <b>Invalid Dates:</b>
                        </Typography>
                        <Box ml={1.5}>
                          <Typography gutterBottom variant="subtitle2">
                            {arrayToCommaAndString(sectionError.invalidDates.map((id) => id.date))}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Card>
        </Box>
      )}
    </>
  );
};

export default SectionErrors;
