
export const MuiTextField = (): Record<string, unknown> => ({
  root: {
    '& .MuiFormHelperText-contained': {
      margin: '2px 2px 0',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink).MuiInputLabel-marginDense': {
      transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
});
