import React, { useState } from 'react';
import { Box, Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';

import BrandTypography from '@/ui/BrandTypography';

import { useElevationStyles } from '@/components/Header/ElevationScroll';
import MobileNavigation from '@/components/Header/MobileNavigation';

import CourierNotifications from '@/containers/MainHeader/CourierNotifications';
import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import OrganizerNavigationDesktop from '@/containers/Organizer/Navigation/OrganizerNavigationDesktop';
import OrganizerNavigationMobile from '@/containers/Organizer/Navigation/OrganizerNavigationMobile';
import UserAvatar from '@/containers/User/UserAvatar/UserAvatar';
import { AppRouteService } from '@/routes/RouteService';

const OrganizerHeader: React.FC = () => {
  const classes = useHeaderStyles();
  const elevationClasses = useElevationStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuClose = (): void => {
    setIsMobileMenuOpen(false);
  };

  return (
    <AppBar classes={{ root: classes.appBar, positionFixed: classes.appBar }} position="relative">
      <Toolbar className={classNames(elevationClasses.shadow, classes.toolbar)}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <Box mr={3}>
            <BrandTypography linkTo={AppRouteService.getRelativeUrl('Home')} variant="h5">
              Reggy
            </BrandTypography>
          </Box>
          <Hidden smDown>
            <OrganizerNavigationDesktop />
          </Hidden>
          <Hidden mdUp>
            <Box alignItems="center" display="flex">
              <Hidden smDown>
                <Box alignItems="center" display="flex" justifyContent="center" ml={1} mr={2}>
                  <CourierNotifications />
                </Box>
                <UserAvatar noShadow size="small" />
              </Hidden>
              <MobileNavigation isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
                <OrganizerNavigationMobile handleMobileMenuClose={handleMobileMenuClose} />
              </MobileNavigation>
            </Box>
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default OrganizerHeader;
