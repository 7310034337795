/**
 * Header Container for Create Event
 */
import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useInterval } from 'react-use';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { formatDistance, parseJSON } from 'date-fns';

import UnPublishConfirmDialog from '@/components/HostEvent/Components/UnPublishConfirmDialog';

import { HeaderContainer_event$key } from './__generated__/HeaderContainer_event.graphql';
import Header from './Header';

import { useGetIsEventPublished } from '../helpers';
import { useGetSectionInfoGlobal } from '../recoilStore';

import { useUnPublishEventMutation } from '@/containers/HostEvent/hostEventMutations';
import { readableDateRange } from '@/lib/date-helpers/date-utils';
import { getHasCategoriedEventEventPassed } from '@/lib/event-info-utils';
import { useHostEventData } from '@/providers/HostEventProvider';

const useStyles = makeStyles((theme: Theme) => ({
  toolbarPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));
const HeaderContainer: React.FC = () => {
  const hostEventData = useHostEventData();
  const headerData = useFragment<HeaderContainer_event$key>(
    graphql`
      fragment HeaderContainer_event on event {
        activity
        startDate
        endDate
        lastSavedAt
        canceledAt
        isEventCreationComplete
        registrations_aggregate(
          where: { _and: [{ _or: [{ status: { _eq: complete } }, { status: { _eq: canceled } }] }] }
        ) {
          aggregate {
            count
          }
        }
        eventMetadata {
          cuid
          name
          isCategoriedEvent
          events(where: { deleted_at: { _is_null: true } }) {
            cuid
          }
        }
      }
    `,
    hostEventData,
  );
  const classes = useStyles();
  const isEventPublished = useGetIsEventPublished();
  const unPublishEvent = useUnPublishEventMutation();
  const { allSectionsForCurrentActivity, availableSections } = useGetSectionInfoGlobal();
  const [lastSavedAt, setLastSavedAt] = useState<string>('...');
  const [isUnPublishEventDialogOpen, setIsUnPublishEventDialogOpen] = useState(false);

  // This only applies to categoried events
  const isEventPassed = getHasCategoriedEventEventPassed(headerData);
  const eventDateRange = readableDateRange(headerData.startDate, headerData.endDate);
  // Use min just in case we remove a section in the future and
  // availableSections.length > totalNumberOfSections for some users
  const currentProgress =
    allSectionsForCurrentActivity.length > 2
      ? (Math.min(availableSections.length, allSectionsForCurrentActivity.length) /
          allSectionsForCurrentActivity.length) *
        100
      : 0;

  const totalRegistrations = headerData.registrations_aggregate.aggregate?.count || 0;
  const hasRegistrations = totalRegistrations > 0;

  // We use the database as a source of truth to update 'Last saved at' wording.
  // Subscribe to any changes
  useEffect(() => {
    if (
      headerData.lastSavedAt &&
      lastSavedAt !== formatDistance(parseJSON(headerData.lastSavedAt), new Date())
    ) {
      // New wording received
      setLastSavedAt(formatDistance(parseJSON(headerData.lastSavedAt), new Date()));
    }
  }, [headerData.lastSavedAt]);
  useInterval(() => {
    if (
      headerData.lastSavedAt &&
      lastSavedAt !== formatDistance(parseJSON(headerData.lastSavedAt), new Date())
    ) {
      // New wording received
      setLastSavedAt(formatDistance(parseJSON(headerData.lastSavedAt), new Date()));
    }
  }, 5000);

  /**
   * Un-Publish event
   */
  const showUnPublishEventConfirmationDialog = (): void => {
    setIsUnPublishEventDialogOpen(true);
  };
  const hideUnPublishEventConfirmationDialog = (): void => {
    setIsUnPublishEventDialogOpen(false);
  };
  const handleUnPublishEvent = (): void => {
    unPublishEvent();
    setIsUnPublishEventDialogOpen(false);
  };

  return (
    <>
      {isUnPublishEventDialogOpen && (
        <UnPublishConfirmDialog
          hideDialog={hideUnPublishEventConfirmationDialog}
          isOpen={isUnPublishEventDialogOpen}
          onUnPublish={handleUnPublishEvent}
        />
      )}
      <div className={classes.toolbarPlaceholder} />
      <Header
        activity={headerData.activity}
        currentProgress={currentProgress}
        eventDateRange={eventDateRange}
        eventLastSavedAt={lastSavedAt}
        eventMetadata={headerData.eventMetadata}
        hasRegistrations={hasRegistrations}
        isEventCanceled={Boolean(headerData.canceledAt)}
        isEventCreationComplete={headerData.isEventCreationComplete}
        isEventPassed={isEventPassed}
        isEventPublished={isEventPublished}
        showUnPublishEventConfirmationDialog={showUnPublishEventConfirmationDialog}
      />
    </>
  );
};

export default HeaderContainer;
