/*
A Button that looks like a link
 */
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonBase, ButtonBaseProps, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import ExtendedLink, { ExtendedLinkProps } from '@/ui/ExtendedLink';

import { Colors } from '@/themes/colors';
import {ReactFCC} from "@/lib/type-defs/utility";

const useStyles = makeStyles((theme: Theme) => ({
  defaultColor: {
    color: Colors.Brand,
  },
  buttonText: {
    ...theme.typography.button,
    textTransform: 'uppercase',
  },
  grey: {
    color: '#666666',
    '&:hover': {
      color: '#999999',
    },
  },
}));

interface LinkButtonProps {
  // Show the link text look like button text?
  useButtonText?: boolean;
  // Should text be grey?
  grey?: boolean;
}
const LinkButton: ReactFCC<LinkButtonProps & ButtonBaseProps & (ExtendedLinkProps | LinkProps)> = ({
  children,
  className,
  grey,
  useButtonText,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={classNames(
        { [classes.defaultColor]: !grey },
        { [classes.buttonText]: useButtonText },
        { [classes.grey]: grey },
        className,
      )}
      component={rest.to ? Link : ExtendedLink}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

export default LinkButton;
