import { Theme } from '@material-ui/core';

export const MuiDialogActions = (theme: Theme): Record<string, unknown> => ({
  root: {
    padding: '0 16px 16px',
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
});
