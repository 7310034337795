import React, { ReactElement } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import RainbowDatePicker, { RainbowDatePickerProps } from '@/ui/Date/RainbowDatePicker';

interface FormRainbowDatePickerProps {
  // React-hook-form props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any>;
  name: string;
}
const FormRainbowDatePicker: React.FC<FormRainbowDatePickerProps & RainbowDatePickerProps> = ({
  formMethods,
  name,
  onBlur,
  onChange,
  ...rest
}) => {
  const { control } = formMethods;
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onBlur: rhfOnBlur, onChange: rhfOnChange, ref, value },
      }): ReactElement => (
        <RainbowDatePicker
          {...rest}
          onBlur={(e): void => {
            rhfOnBlur();
            if (onBlur) {
              onBlur(e);
            }
          }}
          onChange={(e): void => {
            rhfOnChange(e);
            if (onChange) {
              onChange(e);
            }
          }}
          ref={ref}
          value={value}
        />
      )}
    />
  );
};

export default FormRainbowDatePicker;
