import * as yup from 'yup';

import { trimAndKeep2NewLines, trimAndRemoveNewLines, URL_REG_EXP } from '../string-utils';

import {
  MAX_EVENT_ANSWER_LENGTH,
  MAX_EVENT_UPDATE_BODY_LENGTH,
  MAX_EVENT_UPDATE_COMMENT_LENGTH, MAX_EVENT_UPDATE_TITLE_LENGTH,
  MAX_ORGANIZER_ABOUT_LENGTH,
  MAX_ORGANIZER_MESSAGE_LENGTH,
  MAX_ORGANIZER_NAME_LENGTH,
  MAX_ORGANIZER_SUBJECT_LENGTH,
  MAX_ORGANIZER_WEBSITE_LENGTH,
  profanityFilter,
} from '@/const';
import {
  IS_CATEGORIED_EVENT,
  IS_NOT_CATEGORIED_EVENT,
  IsCategoriedEventOption,
} from '@/containers/Organizer/CreateEvent/CreateEventMadLibs';
import { ActivityTypeEnum } from '@/graphql/__generated__/graphql';
import { yupEmailRequired } from '@/lib/form-schema/auth';
import {
  yupActivityTypeRequired,
  yupDateRequired,
  yupEventNameRequired,
  yupSeriesNameRequired,
  yupSeriesTotalRounds,
} from '@/lib/form-schema/host-event';
import { activityType_enum } from '@/providers/__generated__/AuthProviderQuery.graphql';

/** ******************************************************************************
 * Create Organizer
 ******************************************************************************** */
// Organization Name
const yupOrganizerName = yup
  .string()
  .min(3, '3 characters min')
  .max(MAX_ORGANIZER_NAME_LENGTH, `${MAX_ORGANIZER_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your organizer name',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  )
  .test(
    'has-characters',
    'Enter at least 3 letters and/or numbers',
    (value) => (value?.match(/\w|\d/g) || []).length >= 3,
  );
export const yupOrganizerNameRequired = yupOrganizerName.required('We need a name. :)');

const yupOrganizerAbout = yup
  .string()
  .max(MAX_ORGANIZER_ABOUT_LENGTH, `${MAX_ORGANIZER_ABOUT_LENGTH} characters max`)
  .transform(trimAndKeep2NewLines);

// Create Organizer
export const CreateOrganizerFormSchema = yup.object().shape({
  name: yupOrganizerNameRequired,
  about: yupOrganizerAbout,
});

/** ******************************************************************************
 * Create Event
 ******************************************************************************** */

export const yupNumberOfDaysRequired = yup
  .number()
  .typeError('Please select the number of days')
  .required('Please select the number of days');
// // Create Event Screen 1 (Start Page)
export const CreateEventFormSchema = yup.object().shape({
  activity: yupActivityTypeRequired,
  name: yupEventNameRequired,
  dateRange: yup.mixed().when('isCategoriedEvent', {
    is: IS_CATEGORIED_EVENT,
    then: yup
      .array()
      .required('Please select a date')
      .min(1, 'Please select a date')
      .of(yupDateRequired),
  }),
  isCategoriedEvent: yup.mixed().when('activity', {
    is: ActivityTypeEnum.Ride,
    then: yup.string().required('Required'),
  }),
  numberOfDays: yup.mixed().when('activity', {
    is: (activity: activityType_enum, isCategoriedEvent: IsCategoriedEventOption) =>
      activity === ActivityTypeEnum.Ride && isCategoriedEvent === IS_NOT_CATEGORIED_EVENT,
    then: yupNumberOfDaysRequired,
  }),
});

/** ******************************************************************************
 * Teams
 ******************************************************************************** */
export const InviteEditTeamFormSchema = yup.object().shape({
  email: yupEmailRequired,
});

/** ******************************************************************************
 * Series
 ******************************************************************************** */

export const CreateEditSeriesFormSchema = yup.object().shape({
  seriesActivity: yupActivityTypeRequired,
  name: yupSeriesNameRequired,
  totalRounds: yupSeriesTotalRounds,
});
/** ******************************************************************************
 * Organizer Updates & Q/A
 ******************************************************************************** */
// Organization Name
const yupOrganizerUpdateTitle = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_EVENT_UPDATE_TITLE_LENGTH, `${MAX_EVENT_UPDATE_TITLE_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your update',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  );
const yupOrganizerUpdateBody = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_EVENT_UPDATE_BODY_LENGTH, `${MAX_EVENT_UPDATE_BODY_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your update',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  );
const yupOrganizerComment = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_EVENT_UPDATE_COMMENT_LENGTH, `${MAX_EVENT_UPDATE_COMMENT_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your comment',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  );
const yupOrganizerQuestion = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_EVENT_UPDATE_COMMENT_LENGTH, `${MAX_EVENT_UPDATE_COMMENT_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your question',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  );
const yupOrganizerAnswer = yup
  .string()
  .required('Required')
  .typeError('Required')
  .min(3, '3 characters min')
  .max(MAX_EVENT_ANSWER_LENGTH, `${MAX_EVENT_ANSWER_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-bad-words',
    'Please remove any profanity from your answer',
    (value) => Boolean(value) && !profanityFilter.isProfane(value?.toLowerCase() || ''),
  );
export const OrganizerUpdateFormSchema = yup.object().shape({
  title: yupOrganizerUpdateTitle,
});
export const OrganizerCommentFormSchema = yup.object().shape({
  comment: yupOrganizerComment,
});
export const OrganizerQuestionFormSchema = yup.object().shape({
  question: yupOrganizerQuestion,
});
export const OrganizerAnswerFormSchema = yup.object().shape({
  answer: yupOrganizerAnswer,
});

/** ******************************************************************************
 * Send Message
 ******************************************************************************** */
const yupOrganizerSubjectRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_ORGANIZER_SUBJECT_LENGTH,
    `We can't have more than ${MAX_ORGANIZER_SUBJECT_LENGTH} characters`,
  )
  .transform(trimAndRemoveNewLines)
  .required('Required');
const yupOrganizerMessageRequired = yup
  .string()
  .typeError('Required')
  .min(3, '3 characters min')
  .max(
    MAX_ORGANIZER_MESSAGE_LENGTH,
    `We can't have more than ${MAX_ORGANIZER_MESSAGE_LENGTH} characters`,
  )
  .transform(trimAndKeep2NewLines)
  .required('Required');
export const SendMessageFormSchema = yup.object().shape({
  subject: yupOrganizerSubjectRequired,
  message: yupOrganizerMessageRequired,
});
/** ******************************************************************************
 * Edit About
 ******************************************************************************** */
const yupOrganizerWebsite = yup
  .string()
  .nullable()
  .max(
    MAX_ORGANIZER_WEBSITE_LENGTH,
    `The website needs to be ${MAX_ORGANIZER_WEBSITE_LENGTH} characters max`,
  )
  .transform(trimAndRemoveNewLines)
  .matches(URL_REG_EXP, {
    excludeEmptyString: true,
    message: 'Please enter a valid URL',
  });
export const EditOrganizerAboutFormSchema = yup.object().shape({
  name: yupOrganizerNameRequired,
  about: yupOrganizerAbout,
  website: yupOrganizerWebsite,
});
export type EditOrganizerAboutFormSchemaType = yup.TypeOf<typeof EditOrganizerAboutFormSchema>;
