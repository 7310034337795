import React, { useEffect, useState } from 'react';
import { Box, Collapse, makeStyles, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import pluralize from 'pluralize';

import {
  CardAccordion,
  CardAccordionDetails,
  CardAccordionSummary,
} from '@/ui/Accordion/CardAccordion';

import ReviewItem from './ReviewItem';

import { getUserFullName, getUserInitials } from '@/lib/selectors/user';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { Category, Question, SubjectType } from '@/services/review/questions';
import ReviewService, { ClinicInfo, LessonInfo } from '@/services/review/ReviewService';
import { useServicesContext } from '@/services/Services';

export interface ReviewRequest {
  forSubject?: {
    cuid: string;
    type: SubjectType;
    instanceCuid?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  cardAccordion: {
    borderWidth: 1,
  },
}));

const ReviewRequest: React.FC<ReviewRequest> = ({ forSubject }) => {
  const classes = useStyles();
  const services = useServicesContext();
  const isSmallScreenDown = useIsSmallScreenDown();
  const [reviewableSubjects, setReviewableSubjects] = useState<Array<LessonInfo | ClinicInfo>>([]);
  const [reviewQuestions, setReviewQuestions] = useState<Question<Category>[] | undefined>();
  const [currentlyReviewingIndex, setCurrentlyReviewingIndex] = useState(-1);
  const [finishedReviewIndex, setFinishedReviewIndex] = useState(-1);
  const [collapseIn, setCollapseIn] = useState(true);

  const getReviewableSubject = async (): Promise<void> => {
    if (services.review && forSubject) {
      const reviewable = await services.review.getReviewable(forSubject.cuid, forSubject.type);
      if (reviewable) {
        setReviewableSubjects([reviewable]);
        setCurrentlyReviewingIndex(0);
      }
    }
  };

  const getReviewableSubjects = async (): Promise<void> => {
    if (services.review) {
      const fetchReviewables = [
        services.review.getReviewableCoaches(),
        services.review.getReviewableClinics(),
      ];
      const results = await Promise.all(fetchReviewables);
      const newReviewableSubjects = results.flat();
      setReviewableSubjects(newReviewableSubjects);

      if (newReviewableSubjects.length > 0) {
        setCurrentlyReviewingIndex(0);
      }
    }
  };

  // check if there are any subjects for the user to review
  useEffect((): void => {
    if (forSubject) {
      getReviewableSubject();
    } else {
      getReviewableSubjects();
    }
  }, []);

  const getSubjectType = (): SubjectType => {
    if (forSubject) {
      return forSubject.type;
    }

    const subject = reviewableSubjects[currentlyReviewingIndex];
    let subjectType: SubjectType;
    if (ReviewService.isLessonInfo(subject)) {
      subjectType = 'coach';
    } else if (ReviewService.isClinicInfo(subject)) {
      subjectType = 'clinic';
    } else {
      throw new Error('unsupported subject type');
    }
    return subjectType;
  };

  // fetch questions for the current subject
  useEffect((): void => {
    if (currentlyReviewingIndex > -1) {
      (async (): Promise<void> => {
        const subjectType = getSubjectType();
        const questions = await services.review.getReviewQuestions(subjectType);
        setReviewQuestions(questions);
      })();
    }
  }, [currentlyReviewingIndex]);

  const onSave = (): void => {
    const finishedIndex = finishedReviewIndex + 1;
    setFinishedReviewIndex(finishedIndex);

    const nextIndex = finishedIndex + 1;
    if (nextIndex <= reviewableSubjects.length - 1) {
      setCurrentlyReviewingIndex(nextIndex);
    } else {
      setCollapseIn(false);
    }
  };

  if (reviewableSubjects.length === 0) {
    return null;
  }

  const showNumItemsToReview = reviewableSubjects.length > 1;

  const subjectToReview = reviewableSubjects[currentlyReviewingIndex];
  const subjectType = getSubjectType();

  // extract the subject info and renderable data based on the subject type
  let name = 'Untitled';
  let initials: string | undefined;
  let imageUrl: string | undefined;
  let subjectCuid = '';
  let subjectInstanceCuid: string | undefined;
  if (ReviewService.isLessonInfo(subjectToReview)) {
    const { user } = subjectToReview.coachCustomerLesson.coach;
    name = getUserFullName(user)!;
    if (subjectToReview.coachCustomerLesson.coachLesson?.title) {
      name += ` - ${subjectToReview.coachCustomerLesson.coachLesson.title}`;
    }
    initials = getUserInitials({ ...user, email: '' })!;
    imageUrl = user.profilePicUrl !== null ? user.profilePicUrl : undefined;
    subjectCuid = subjectToReview.coachCustomerLesson.coachCuid;
    if (subjectToReview.coachCustomerLesson.coachLesson?.cuid) {
      subjectInstanceCuid = subjectToReview.coachCustomerLesson.coachLesson.cuid;
    }
  }
  if (ReviewService.isClinicInfo(subjectToReview)) {
    // eslint-disable-next-line prefer-destructuring
    name = subjectToReview.event.eventMetadata.name;
    subjectCuid = subjectToReview.event.eventMetadata.cuid;
    subjectInstanceCuid = subjectToReview.clinic!.cuid;
    imageUrl = subjectToReview.event.eventImages[0].image.relativeUrl;
  }

  return (
    <Collapse enter={false} in={collapseIn}>
      <Box>
        <CardAccordion defaultExpanded className={classes.cardAccordion}>
          <CardAccordionSummary expandIcon={<ExpandMoreIcon />} id="review-request">
            <Typography variant="h3">
              Review your recent {pluralize('experience', reviewableSubjects.length)}
              {showNumItemsToReview &&
                ` (${currentlyReviewingIndex + 1} of ${reviewableSubjects.length})`}
              {isSmallScreenDown ? <br /> : ' - '}
              {name}
            </Typography>
          </CardAccordionSummary>
          <CardAccordionDetails>
            {subjectToReview && reviewQuestions && (
              <Box>
                <ReviewItem
                  imageUrl={imageUrl}
                  initials={initials}
                  name={name}
                  onSave={onSave}
                  questions={reviewQuestions}
                  subjectCuid={subjectCuid}
                  subjectInstanceCuid={subjectInstanceCuid}
                  subjectType={subjectType}
                />
              </Box>
            )}
          </CardAccordionDetails>
        </CardAccordion>
      </Box>
    </Collapse>
  );
};

export default ReviewRequest;
