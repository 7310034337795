/**
 * Create a chip with colors and text
 */
import React from 'react';
import { lighten } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';
import { CreateCSSProperties } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import classNames from 'classnames';

import { Colors, StatusColorNames } from '@/themes/colors';
import { theme } from '@/themes/theme';

export type ColorType = StatusColorNames | Colors;
type ChipColorProps = { textColor: string; backgroundColor: string };
const useStyles = makeStyles(() => ({
  textChip: ({
    backgroundColor,
    textColor,
  }: ChipColorProps): CreateCSSProperties<ChipColorProps> => ({
    alignSelf: 'flex-start',
    backgroundColor,
    borderRadius: '3px',
    color: textColor,
    display: 'flex',
    fontSize: '0.7rem',
    justifyContent: 'center',
    minWidth: '60px',
    // padding: theme.spacing(0.25, 0.75),
    padding: '2px 6px',
  }),
  small: {
    fontSize: '0.6rem !important',
    padding: '1px 4px !important',
  },
}));

export const getTextColor = (color?: ColorType): string => {
  switch (color) {
    case StatusColorNames.Info:
      return Colors.Info;
    case StatusColorNames.Success:
      return Colors.Success;
    case StatusColorNames.Warning:
      return Colors.Warning;
    case StatusColorNames.Error:
      return Colors.Danger;
    case undefined:
      return 'black';
    default:
      return color;
  }
};
export const getBackgroundColor = (color?: ColorType): string => {
  const lightenAmount = 0.8;
  switch (color) {
    case StatusColorNames.Info:
      return Colors.InfoBackground;
    case StatusColorNames.Success:
      return Colors.SuccessBackground;
    case StatusColorNames.Warning:
      return Colors.WarningBackground;
    case StatusColorNames.Error:
      return Colors.DangerBackground;
    case undefined:
      return theme.palette.grey['300'];
    default:
      return lighten(color, lightenAmount);
  }
};

export interface TextChipProps {
  title: string;
  color?: ColorType;
  wrapText?: boolean;
  small?: boolean;
}
const TextChip: React.FC<TextChipProps & BoxProps> = ({
  color,
  small,
  title,
  wrapText,
  ...rest
}) => {
  const backgroundColor = getBackgroundColor(color);
  const textColor = getTextColor(color);
  const classes = useStyles({ backgroundColor, textColor });
  return (
    <Box {...rest} display="flex">
      <Typography
        className={classNames(classes.textChip, { [classes.small]: small })}
        noWrap={!wrapText}
        variant="caption"
      >
        <b>{title}</b>
      </Typography>
    </Box>
  );
};

export default TextChip;
