/**
 * Mobile header container
 */
import React, { Dispatch, SetStateAction } from 'react';
import { Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HamburgerButton from '@/components/Header/HamburgerButton';

import CourierNotifications from '@/containers/MainHeader/CourierNotifications';
import { MAIN_HEADER_HEIGHT } from '@/containers/MainHeader/MainHeader';
import { ReactFCC } from '@/lib/type-defs/utility';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    top: `${MAIN_HEADER_HEIGHT}px !important`,
    '& .MuiBackdrop-root': {
      top: MAIN_HEADER_HEIGHT,
    },
    '& .MuiDrawer-paper': {
      top: MAIN_HEADER_HEIGHT,
    },
  },
}));
interface MobileNavigationProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const MobileNavigation: ReactFCC<MobileNavigationProps> = ({ children, isOpen, setIsOpen }) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" ml={{ xs: 1.5, sm: 2, md: 1 }}>
        {!isOpen && <CourierNotifications />}
      </Box>
      <HamburgerButton
        isOpen={isOpen}
        onHamburgerClicked={(): void => setIsOpen((prevState) => !prevState)}
      />
      <Drawer
        anchor="bottom"
        className={classes.root}
        onClose={(): void => setIsOpen(false)}
        open={isOpen}
      >
        {/* <LockBodyScroll display="flex" flexGrow={1}> */}
        {children}
        {/* </LockBodyScroll> */}
      </Drawer>
    </>
  );
};

export default MobileNavigation;
