/**
 * Types
 */
export type RouteMetadataLabel = 'label' | 'breadcrumbLabel';
export enum AppRole {
  OrganizerOwner = 'OrganizerOwner',
  OrganizerAdmin = 'OrganizerAdmin',
  OrganizerSupport = 'OrganizerSupport',
  OrganizerCoach = 'OrganizerCoach',
  OrganizerCoachTeachingEventClinic = 'OrganizerCoachTeachingEventClinic',
  Coach = 'Coach',
  Creator = 'Creator',
}
export const AllOrganizerAppRoles = [
  AppRole.OrganizerOwner,
  AppRole.OrganizerAdmin,
  AppRole.OrganizerSupport,
  AppRole.OrganizerCoach,
];
export const OrganizerEditAppRoles = [AppRole.OrganizerOwner, AppRole.OrganizerAdmin];
export const OrganizerManageAppRoles = [
  AppRole.OrganizerOwner,
  AppRole.OrganizerAdmin,
  AppRole.OrganizerSupport,
  AppRole.OrganizerCoachTeachingEventClinic,
];
export interface RouterMetadata {
  directory?: string;
  path: string;
}

export interface RouteMetadata {
  breadcrumbLabel?: string;
  label: string;
  router: RouterMetadata;
  validRoles?: readonly AppRole[];
}

export interface RouteDomain {
  [routeName: string]: RouteMetadata;
}

/**
 * Api Routes
 */
export const ApiRoutes = {
  CheckInQRCode: {
    label: 'Check In QR Code',
    router: {
      path: '/http-registrationDetail-getCheckInQRCode/:orgSlug/:eventCuid/:referenceNo',
    },
  },
} as const;
export type ApiRouteName = keyof typeof ApiRoutes;

/**
 * App Route Directories
 *
 * a subdirectory's name/key must start with its parent directory's name, then concatenated
 * with an underscore (_)
 */
export const AppDirectories = {
  Insights: '/insights',

  Organizer: '/o',
  Organizer_App: '/o/:organizerSlug',
  Organizer_App_Events: '/o/:organizerSlug/events',
  Organizer_App_Messages: '/o/:organizerSlug/messages',

  OrganizerProfile: '/org/:organizerSlug',
  OrganizerProfile_OnlineCourses: '/org/:organizerSlug/online-courses',
  OrganizerProfile_Series: '/org/:organizerSlug/series',
  OrganizerProfile_Event: '/org/:organizerSlug/events/:eventSlug',

  CoachProfile: '/coach',
  CoachProfile_OnlineCourses: '/coach/:coachSlug/online-courses',
  CyclistProfile: '/cyclist',

  Checkout: '/checkout',

  User: '/u',
  User_Registrations: '/u/registrations',
  User_OnlineCourses: '/u/online-courses',
  User_Messages: '/u/messages',
  User_Business: '/u/business',
  User_Business_Students: '/u/business/students',
} as const;
export type AppDirectoryName = keyof typeof AppDirectories;

/**
 * App Routes
 */
export const AppRoutes = {
  Admin: {
    label: 'Admin',
    router: {
      path: '/admin',
    },
  },

  Home: {
    label: 'Home',
    router: {
      path: '/',
    },
  },

  Search: {
    label: 'Explore',
    router: {
      path: '/search',
    },
  },
  Discover: {
    label: 'Discover',
    router: {
      path: '/discover',
    },
  },

  BecomeACreator: {
    label: 'Become a Creator',
    router: {
      path: '/become-a-creator',
    },
  },
  JoinWaitlist: {
    label: 'Join Waitlist',
    router: {
      path: '/join-waitlist',
    },
  },
  SignWaivers: {
    label: 'Sign Waivers',
    router: {
      path: '/sign-waivers/:successToken',
    },
  },

  Login: {
    label: 'Login',
    router: {
      path: '/login',
    },
  },

  Signup: {
    label: 'Sign Up',
    router: {
      path: '/signup',
    },
  },
  EmailVerificationRequired: {
    label: 'Email Verification Required',
    router: {
      path: '/email-verification-required',
    },
  },
  ForgotPassword: {
    label: 'Forgot Password',
    router: {
      path: '/forgot-password',
    },
  },
  ResetPassword: {
    label: 'Reset Password',
    router: {
      path: '/reset-password',
    },
  },

  NotFound: {
    label: 'Not Found',
    router: {
      path: '/not-found',
    },
  },
  ServerError: {
    label: 'Server Error',
    router: {
      path: '/server-error',
    },
  },

  About: {
    label: 'About',
    router: {
      path: '/about',
    },
  },

  ChangeLog: {
    label: 'Change Log',
    router: {
      path: '/changelog',
    },
  },

  PrivacyPolicy: {
    label: 'Privacy Policy',
    router: {
      path: '/privacy-policy',
    },
  },

  TermsOfService: {
    label: 'Terms of Service',
    breadcrumbLabel: 'ToS',
    router: {
      path: '/terms-of-service',
    },
  },
  TermsOfServiceOrganizer: {
    label: 'Organizer Terms of Service',
    breadcrumbLabel: 'Organizer ToS',
    router: {
      path: '/terms-of-service-organizers',
    },
  },

  Insights: {
    label: 'Insights',
    breadcrumbLabel: 'Insights',
    router: {
      path: '/',
      directory: AppDirectories.Insights,
    },
  },
  Insights_Post: {
    label: '{postTitle}',
    breadcrumbLabel: '{postTitle}',
    router: {
      path: '/:postSlug',
      directory: AppDirectories.Insights,
    },
  },

  Organizer_Start: {
    label: 'Organizer Start',
    breadcrumbLabel: 'Organization',
    router: {
      path: '/',
      directory: AppDirectories.Organizer,
    },
    validRoles: [AppRole.Creator],
  },
  OrganizerApp: {
    label: '{orgName}',
    breadcrumbLabel: 'Organization',
    router: {
      path: '/',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_OrganizerOnboarding: {
    label: 'Organizer Onboarding',
    router: {
      path: '/organizer-onboarding',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_Events: {
    label: 'Events',
    router: {
      path: '/',
      directory: AppDirectories.Organizer_App_Events,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_ManageEvent: {
    label: 'Manage {eventName}',
    breadcrumbLabel: 'Manage Event',
    router: {
      path: '/:eventCuid/manage',
      directory: AppDirectories.Organizer_App_Events,
    },
    validRoles: OrganizerManageAppRoles,
  },
  OrganizerApp_ManageRegistration: {
    label: 'Manage Registration',
    router: {
      path: '/:eventCuid/manage/:refNo',
      directory: AppDirectories.Organizer_App_Events,
    },
    validRoles: OrganizerManageAppRoles,
  },
  OrganizerApp_EditEvent: {
    label: 'Edit {eventName}',
    breadcrumbLabel: 'Edit Event',
    router: {
      path: '/host/:eventCuid',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_AllEvents: {
    label: 'All Events',
    router: {
      path: '/all-events',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_Analytics: {
    label: '{orgName} Analytics',
    breadcrumbLabel: 'Analytics',
    router: {
      path: '/analytics',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_Schedule: {
    label: 'Schedule',
    router: {
      path: '/schedule',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_Team: {
    label: 'Team',
    router: {
      path: '/team',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_Billing: {
    label: 'Billing',
    router: {
      path: '/billing',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: [AppRole.OrganizerOwner],
  },
  OrganizerApp_Messages: {
    label: 'Messages',
    router: {
      path: '/',
      directory: AppDirectories.Organizer_App_Messages,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_Messages_Channel: {
    label: 'Messages',
    router: {
      path: '/:channelId',
      directory: AppDirectories.Organizer_App_Messages,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_CreateOnlineCourse_New: {
    label: 'Create Online Course',
    router: {
      path: '/create-online-course',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_CreateOnlineCourse_Existing: {
    label: 'Create Online Course',
    router: {
      path: '/create-online-course/:onlineCourseCuid',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_CreateOrganizer: {
    label: 'Create Organization',
    breadcrumbLabel: 'Create Org',
    router: {
      path: '/create',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },
  OrganizerApp_CreateEvent: {
    label: 'Create Event',
    router: {
      path: '/create-event',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: OrganizerEditAppRoles,
  },
  OrganizerApp_Series: {
    label: 'Series',
    router: {
      path: '/series',
      directory: AppDirectories.Organizer_App,
    },
    validRoles: AllOrganizerAppRoles,
  },

  OrganizerProfile: {
    label: '{orgName}',
    breadcrumbLabel: '{orgName} (Organizer)',
    router: {
      path: '/',
      directory: AppDirectories.OrganizerProfile,
    },
  },
  OrganizerProfile_OnlineCourses: {
    label: '{orgName} Online Courses',
    breadcrumbLabel: 'Online Courses',
    router: {
      path: '/',
      directory: AppDirectories.OrganizerProfile_OnlineCourses,
    },
  },
  OrganizerProfile_OnlineCourses_Detail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{onlineCourseTitle}',
    router: {
      path: '/:onlineCourseCuid',
      directory: AppDirectories.OrganizerProfile_OnlineCourses,
    },
  },
  OrganizerProfile_OnlineCourses_OverviewDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: 'Overview',
    router: {
      path: '/:onlineCourseCuid/overview',
      directory: AppDirectories.OrganizerProfile_OnlineCourses,
    },
  },
  OrganizerProfile_OnlineCourses_SectionDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{sectionTitle}',
    router: {
      path: '/:onlineCourseCuid/section/:sectionCuid',
      directory: AppDirectories.OrganizerProfile_OnlineCourses,
    },
  },
  OrganizerProfile_OnlineCourses_LessonDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{lessonTitle}',
    router: {
      path: '/:onlineCourseCuid/lesson/:lessonCuid',
      directory: AppDirectories.OrganizerProfile_OnlineCourses,
    },
  },
  OrganizerProfile_Series: {
    label: 'Series',
    router: {
      path: '/',
      directory: AppDirectories.OrganizerProfile_Series,
    },
  },
  OrganizerProfile_SeriesDetail: {
    label: '{seriesName}',
    breadcrumbLabel: '{seriesName}',
    router: {
      path: '/:seriesSlug',
      directory: AppDirectories.OrganizerProfile_Series,
    },
  },
  OrganizerProfile_Event: {
    label: '{eventName}',
    breadcrumbLabel: 'Event',
    router: {
      path: '/',
      directory: AppDirectories.OrganizerProfile_Event,
    },
  },
  OrganizerProfile_Event_Registration: {
    label: '{eventName} Registration',
    breadcrumbLabel: 'Registration',
    router: {
      path: '/register',
      directory: AppDirectories.OrganizerProfile_Event,
    },
  },

  CoachProfile: {
    label: '{coachName}',
    breadcrumbLabel: '{coachName} (Coach)',
    router: {
      path: '/:coachSlug',
      directory: AppDirectories.CoachProfile,
    },
  },
  CoachProfile_OnlineCourses: {
    label: '{coachName} Online Courses',
    breadcrumbLabel: 'Online Courses',
    router: {
      path: '/',
      directory: AppDirectories.CoachProfile_OnlineCourses,
    },
  },
  CoachProfile_OnlineCourses_Detail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{onlineCourseTitle}',
    router: {
      path: '/:onlineCourseCuid',
      directory: AppDirectories.CoachProfile_OnlineCourses,
    },
  },
  CoachProfile_OnlineCourses_OverviewDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: 'Overview',
    router: {
      path: '/:onlineCourseCuid/overview',
      directory: AppDirectories.CoachProfile_OnlineCourses,
    },
  },
  CoachProfile_OnlineCourses_SectionDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{sectionTitle}',
    router: {
      path: '/:onlineCourseCuid/section/:sectionCuid',
      directory: AppDirectories.CoachProfile_OnlineCourses,
    },
  },
  CoachProfile_OnlineCourses_LessonDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{lessonTitle}',
    router: {
      path: '/:onlineCourseCuid/lesson/:lessonCuid',
      directory: AppDirectories.CoachProfile_OnlineCourses,
    },
  },

  CyclistProfile: {
    label: '{cyclistName}',
    breadcrumbLabel: 'Cyclist',
    router: {
      path: '/:cyclistSlug',
      directory: AppDirectories.CyclistProfile,
    },
  },

  Checkout_RegistrationComplete: {
    label: 'Registration Complete',
    router: {
      path: '/registration/:successToken',
      directory: AppDirectories.Checkout,
    },
  },

  Checkout_LessonPaymentComplete: {
    label: 'Lesson Payment Complete',
    router: {
      path: '/lesson/:successToken',
      directory: AppDirectories.Checkout,
    },
  },

  Checkout_OnlineCoursePurchaseComplete: {
    label: 'Online Course Purchase Complete',
    router: {
      path: '/online-course/:successToken',
      directory: AppDirectories.Checkout,
    },
  },

  User_BecomeCoach: {
    label: 'Become a Coach',
    router: {
      path: '/become-a-coach',
      directory: AppDirectories.User,
    },
    validRoles: [AppRole.Creator],
  },

  User_Feed: {
    label: 'My Feed',
    router: {
      path: '/feed',
      directory: AppDirectories.User,
    },
  },

  User_Dashboard: {
    label: 'Dashboard',
    router: {
      path: '/',
      directory: AppDirectories.User,
    },
  },

  User_AccountSettings: {
    label: 'Account Settings',
    router: {
      path: '/account-settings',
      directory: AppDirectories.User,
    },
  },

  User_EditPreferences: {
    label: 'Edit Preferences',
    router: {
      path: '/edit-preferences',
      directory: AppDirectories.User,
    },
  },

  User_EditProfile: {
    label: 'Edit Profile',
    router: {
      path: '/edit-profile',
      directory: AppDirectories.User,
    },
  },

  User_Messages: {
    label: 'Messages',
    router: {
      path: '/',
      directory: AppDirectories.User_Messages,
    },
  },
  User_Messages_Channel: {
    label: 'Messages',
    router: {
      path: '/:channelId',
      directory: AppDirectories.User_Messages,
    },
  },

  User_ReportNoShow: {
    label: 'Report No Show',
    router: {
      path: '/report-no-show',
      directory: AppDirectories.User,
    },
  },

  User_Registrations: {
    label: 'Registrations & Lessons',
    breadcrumbLabel: 'Registrations',
    router: {
      path: '/',
      directory: AppDirectories.User_Registrations,
    },
  },
  User_Registrations_Detail: {
    label: 'Registration for {eventName}',
    breadcrumbLabel: 'Registration',
    router: {
      path: '/:eventCuid/:refNo',
      directory: AppDirectories.User_Registrations,
    },
  },
  User_Registrations_LessonDetail: {
    label: 'Private Lesson with {coachName}',
    breadcrumbLabel: 'Private Lesson',
    router: {
      path: '/:lessonDateCuid',
      directory: AppDirectories.User_Registrations,
    },
  },

  User_OnlineCourses: {
    label: 'Online Courses',
    breadcrumbLabel: 'Online Course Purchases',
    router: {
      path: '/',
      directory: AppDirectories.User_OnlineCourses,
    },
  },
  User_OnlineCourses_Detail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{onlineCourseTitle}',
    router: {
      path: '/:onlineCourseCuid',
      directory: AppDirectories.User_OnlineCourses,
    },
  },
  User_OnlineCourses_OverviewDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: 'Overview',
    router: {
      path: '/:onlineCourseCuid/overview',
      directory: AppDirectories.User_OnlineCourses,
    },
  },
  User_OnlineCourses_SectionDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{sectionTitle}',
    router: {
      path: '/:onlineCourseCuid/section/:sectionCuid',
      directory: AppDirectories.User_OnlineCourses,
    },
  },
  User_OnlineCourses_LessonDetail: {
    label: '{onlineCourseTitle}',
    breadcrumbLabel: '{lessonTitle}',
    router: {
      path: '/:onlineCourseCuid/lesson/:lessonCuid',
      directory: AppDirectories.User_OnlineCourses,
    },
  },

  User_Business_App: {
    label: 'Coach',
    router: {
      path: '/',
      directory: AppDirectories.User_Business,
    },
    validRoles: [AppRole.Coach],
  },

  User_Business_Dashboard: {
    label: 'Dashboard',
    router: {
      path: '/dashboard',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_Analytics: {
    label: 'Analytics',
    router: {
      path: '/analytics',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_Accounting: {
    label: 'Accounting',
    router: {
      path: '/accounting',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_Schedule: {
    label: 'Schedule',
    router: {
      path: '/schedule',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_Students: {
    label: 'Students',
    router: {
      path: '/',
      directory: AppDirectories.User_Business_Students,
    },
  },
  User_Business_Students_Channel: {
    label: 'Students',
    router: {
      path: '/:channelId',
      directory: AppDirectories.User_Business_Students,
    },
  },

  User_Business_AllLessons: {
    label: 'All Lessons',
    router: {
      path: '/all-lessons',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_Availability: {
    label: 'Availability',
    router: {
      path: '/availability',
      directory: AppDirectories.User_Business,
    },
  },

  User_Business_CreateOnlineCourse_New: {
    label: 'Create Online Course',
    router: {
      path: '/create-online-course',
      directory: AppDirectories.User_Business,
    },
  },
  User_Business_CreateOnlineCourse_Existing: {
    label: 'Create Online Course',
    router: {
      path: '/create-online-course/:onlineCourseCuid',
      directory: AppDirectories.User_Business,
    },
  },
} as const;
export type AppRouteName = keyof typeof AppRoutes;
