/*
Selectors for the 'user' object from the database
 */

import { activityType_enum } from '@/containers/HostEvent/Main/Sections/General/__generated__/GeneralSection_event.graphql';
import { ActivityTypeEnum } from '@/graphql/__generated__/graphql';
import { AuthContextProps } from '@/providers/AuthProvider';

/**
 * Get the user name
 */
export const getUserFullName = (
  user: { firstName: string | null; lastName: string | null } | null | undefined,
  options?: { shortenLastName: boolean },
): string | null => {
  if (!user) {
    return null;
  }
  if (!user.firstName && !user.lastName) {
    return null;
  }
  if (user.firstName && !user.lastName) {
    return user.firstName;
  }
  if (!user.firstName && user.lastName) {
    return user.lastName;
  }
  if (options?.shortenLastName) {
    return `${user.firstName} ${user.lastName?.substring(0, 1)}.`;
  }
  return `${user.firstName} ${user.lastName}`;
};

/**
 * Get the user initials
 */
export const getUserInitials = (
  user: { firstName: string | null; lastName: string | null; email: string } | null,
): string | null => {
  if (!user) {
    return null;
  }
  // We use first and last name initials if there is no profile pic
  const firstInitial = user?.firstName ? user.firstName[0].toUpperCase() : '';
  const lastInitial = user?.lastName ? user.lastName[0].toUpperCase() : '';
  const emailInitial = user?.email ? user.email[0].toUpperCase() : '';
  return `${firstInitial}${lastInitial}` || emailInitial;
};

/**
 * Get the default address from the user object
 */
export const getUserDefaultAddress = (
  user: AuthContextProps['user'],
): NonNullable<AuthContextProps['user']>['userAddresses'][0] | undefined => {
  return (user?.userAddresses ?? []).find((a) => a.isDefault);
};

/**
 * Make a city, state country
 * Los Angeles, CA USA
 */
export const makeUserCityState = ({
  includeCountry,
  userAddress,
}: {
  userAddress: NonNullable<AuthContextProps['user']>['userAddresses'][0] | undefined;
  includeCountry?: boolean;
}): string => {
  if (!userAddress) {
    return '';
  }
  return `${userAddress?.city ? `${userAddress?.city}, ` : ''}${
    userAddress?.state ? `${userAddress?.state}` : ''
  }${includeCountry ? ` ${userAddress.countryCode}` : ''}`;
};

/**
 * Make a city, state country zipCode
 * Los Angeles, CA, USA 91362
 */
export const getLocationFromAddress = (
  address: NonNullable<AuthContextProps['user']>['userAddresses'][0] | undefined,
): string => {
  if (!address) {
    return '';
  }
  const { city, country, state, zipCode } = address;
  return `${city ? `${city}, ` : ''}${state || ''}${zipCode ? ` ${zipCode}` : ''}${
    country ? `, ${country}` : ''
  }`;
};

/**
 * Get organizer role object from user object
 */
export const getUserOrganizerRoleObjects = (
  user: AuthContextProps['user'],
  organizerCuid: string | undefined,
): NonNullable<AuthContextProps['user']>['userOrganizerRoles'][0][] | undefined => {
  if (!user) {
    return undefined;
  }
  if (!organizerCuid) {
    return undefined;
  }
  return user.userOrganizerRoles.filter(
    (userOrganizerRole) => userOrganizerRole.organizer?.cuid === organizerCuid,
  );
};

/**
 * Get organizer role object from user object using organizer slug
 */
export const getUserOrganizerRoleObjectsBySlug = (
  user: AuthContextProps['user'],
  organizerSlug: string | undefined,
): NonNullable<AuthContextProps['user']>['userOrganizerRoles'][0][] | undefined => {
  if (!user) {
    return undefined;
  }
  if (!organizerSlug) {
    return undefined;
  }
  return user.userOrganizerRoles.filter(
    (userOrganizerRole) => userOrganizerRole.organizer?.slug === organizerSlug,
  );
};

/**
 * Get the cyclist type from an activity
 */
export type CyclistType = 'rider' | 'racer' | 'student' | 'cyclist';
export type CyclistTypePlural = 'riders' | 'racers' | 'students' | 'cyclists';
export const getCyclistTypeFromActivity = (
  activity: activityType_enum | ActivityTypeEnum | null | undefined,
): { singular: CyclistType; plural: CyclistTypePlural } => {
  switch (activity) {
    case ActivityTypeEnum.Clinic:
      return { singular: 'student', plural: 'students' };
    case ActivityTypeEnum.Race:
      return { singular: 'racer', plural: 'racers' };
    case ActivityTypeEnum.Ride:
      return { singular: 'rider', plural: 'riders' };
    default:
      return { singular: 'rider', plural: 'riders' };
  }
};
