import React from 'react';
import { To } from 'history';

import ExtendedButton, { ExtendedButtonProps } from '@/ui/Button/ExtendedButton';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';

const ButtonLink: React.FC<
  ExtendedButtonProps & {
    to: To;
  }
> = ({ children, onClick, to, ...rest }) => {
  return (
    <NoUnderlineRouterLink style={rest.disabled ? { pointerEvents: 'none' } : {}} to={to}>
      <ExtendedButton {...rest} onClick={onClick}>
        {children}
      </ExtendedButton>
    </NoUnderlineRouterLink>
  );
};

export default ButtonLink;
