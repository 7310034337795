/*
Require a login before rendering a component
 */
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingPlaceholder from '../components/LoadingPlaceholder';

import {
  useGetLoginSignupDialogBlockingModeGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsLoginDialogOpenGlobal,
  useSetIsLoginSignupDialogBlockingGlobal,
  useSetIsReAuthDialogOpenGlobal,
  useSetIsSignupDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import { firebaseAuth } from '@/lib/firebase';
import { ReactFCC } from '@/lib/type-defs/utility';
import PageNotFound from '@/pages/ErrorPages/PageNotFound';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

interface RequireLoginProps {
  adminRequired?: boolean;
  viaDialog?: boolean;
  // mode?: 'login' | 'signup';
}

const RequireLogin: ReactFCC<RequireLoginProps> = ({
  adminRequired,
  children,
  // mode = 'login',
  viaDialog,
}) => {
  const auth = useAuth();
  const location = useLocation();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const setIsReAuthDialogOpenGlobal = useSetIsReAuthDialogOpenGlobal();
  const setIsLoginSignupDialogBlockingGlobal = useSetIsLoginSignupDialogBlockingGlobal();
  const loginSignupDialogBlockingModeGlobal = useGetLoginSignupDialogBlockingModeGlobal();

  /**
   * If we login, close all dialogs
   */
  useEffect(() => {
    if (auth.isLoggedIn) {
      setIsLoginDialogOpenGlobal(false);
      setIsSignupDialogOpenGlobal(false);
      setIsReAuthDialogOpenGlobal(false);
      setIsLoginSignupDialogBlockingGlobal(false);
    }
  }, [auth.isLoggedIn]);

  // Require email to be verified
  const { currentUser } = firebaseAuth;
  if (currentUser?.emailVerified === false && !auth.isLoading) {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    return <Navigate replace to={AppRouteService.getRelativeUrl('EmailVerificationRequired')} />;
  }
  // If user is not logged in, redirect to login page and set the redirect url
  if (!auth.isInitializing && !auth.isLoading && !auth.user) {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    if (viaDialog) {
      setIsLoginSignupDialogBlockingGlobal(true);
      if (loginSignupDialogBlockingModeGlobal === 'login') {
        setIsLoginDialogOpenGlobal(true);
        setIsSignupDialogOpenGlobal(false);
      } else {
        setIsSignupDialogOpenGlobal(true);
        setIsLoginDialogOpenGlobal(false);
      }
    } else {
      return (
        <Navigate
          replace
          to={
            loginSignupDialogBlockingModeGlobal === 'login'
              ? AppRouteService.getRelativeUrl('Login')
              : AppRouteService.getRelativeUrl('Signup')
          }
        />
      );
    }
  }
  if (adminRequired && !auth.isInitializing && auth.isLoggedIn && !auth.user?.isAdmin) {
    return <PageNotFound />;
  }

  return (
    <LoadingPlaceholder disableShrink isLoading={auth.isInitializing || auth.isLoading}>
      {children}
    </LoadingPlaceholder>
  );
};

export default RequireLogin;
