/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_useGetIsEventAtCapacity_public_event = {
    readonly spotsAvailable: number | null;
    readonly registrations_aggregate: {
        readonly aggregate: {
            readonly count: number;
        } | null;
    };
    readonly " $refType": "helpers_useGetIsEventAtCapacity_public_event";
};
export type helpers_useGetIsEventAtCapacity_public_event$data = helpers_useGetIsEventAtCapacity_public_event;
export type helpers_useGetIsEventAtCapacity_public_event$key = {
    readonly " $data"?: helpers_useGetIsEventAtCapacity_public_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_useGetIsEventAtCapacity_public_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "userId"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_useGetIsEventAtCapacity_public_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spotsAvailable",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "items": [
                                {
                                    "fields": [
                                        {
                                            "items": [
                                                {
                                                    "fields": [
                                                        {
                                                            "items": [
                                                                {
                                                                    "fields": [
                                                                        {
                                                                            "fields": [
                                                                                {
                                                                                    "kind": "Variable",
                                                                                    "name": "_neq",
                                                                                    "variableName": "userId"
                                                                                }
                                                                            ],
                                                                            "kind": "ObjectValue",
                                                                            "name": "userId"
                                                                        }
                                                                    ],
                                                                    "kind": "ObjectValue",
                                                                    "name": "_and.0"
                                                                },
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "_and.1",
                                                                    "value": {
                                                                        "status": {
                                                                            "_in": [
                                                                                "complete",
                                                                                "in_progress"
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ],
                                                            "kind": "ListValue",
                                                            "name": "_and"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "_or.0"
                                                },
                                                {
                                                    "fields": [
                                                        {
                                                            "items": [
                                                                {
                                                                    "fields": [
                                                                        {
                                                                            "fields": [
                                                                                {
                                                                                    "kind": "Variable",
                                                                                    "name": "_eq",
                                                                                    "variableName": "userId"
                                                                                }
                                                                            ],
                                                                            "kind": "ObjectValue",
                                                                            "name": "userId"
                                                                        }
                                                                    ],
                                                                    "kind": "ObjectValue",
                                                                    "name": "_and.0"
                                                                },
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "_and.1",
                                                                    "value": {
                                                                        "status": {
                                                                            "_in": [
                                                                                "complete"
                                                                            ]
                                                                        }
                                                                    }
                                                                }
                                                            ],
                                                            "kind": "ListValue",
                                                            "name": "_and"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "_or.1"
                                                }
                                            ],
                                            "kind": "ListValue",
                                            "name": "_or"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "_and.0"
                                },
                                {
                                    "kind": "Literal",
                                    "name": "_and.1",
                                    "value": {
                                        "_not": {
                                            "registrationCategories": {
                                                "categoryCuid": {
                                                    "_is_null": true
                                                }
                                            }
                                        }
                                    }
                                }
                            ],
                            "kind": "ListValue",
                            "name": "_and"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "registration_aggregate",
            "kind": "LinkedField",
            "name": "registrations_aggregate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "registration_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'c8d320aac156cba7b463e37685e70aef';
export default node;
