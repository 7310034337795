/**
 * Make sure the user is a creator before they move on page
 */
import React from 'react';

import CreatorCheckContainer from '@/containers/User/CreatorCheckContainer';
import MainLayout from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';
import Page from '@/pages/Page';
import { useAuth } from '@/providers/AuthProvider';

const CreatorCheckPage: ReactFCC = ({ children }) => {
  const auth = useAuth();

  // User is already a creator or just accepted their invite. Cruise on!
  if (auth.user?.isCreator) {
    return <>{children}</>;
  }

  return (
    <Page noIndex title="Join Waitlist • Reggy">
      <MainLayout>
        <CreatorCheckContainer />
      </MainLayout>
    </Page>
  );
};

export default CreatorCheckPage;
