/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OccurrencesDialog_event = {
    readonly organizerCuid: string;
    readonly eventMetadata: {
        readonly cuid: string;
        readonly events: ReadonlyArray<{
            readonly cuid: string;
            readonly startDate: string | null;
            readonly endDate: string | null;
            readonly lastSavedAt: string;
            readonly canceledAt: string | null;
            readonly publishedAt: string | null;
            readonly cancellationReason: string | null;
            readonly occurrenceLabel: string | null;
            readonly registrations: ReadonlyArray<{
                readonly userId: string;
            }>;
        }>;
    };
    readonly " $refType": "OccurrencesDialog_event";
};
export type OccurrencesDialog_event$data = OccurrencesDialog_event;
export type OccurrencesDialog_event$key = {
    readonly " $data"?: OccurrencesDialog_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OccurrencesDialog_event">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v1 = {
        "deleted_at": {
            "_is_null": true
        }
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "OccurrencesDialog_event",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizerCuid",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "eventMetadata",
                "kind": "LinkedField",
                "name": "eventMetadata",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "where",
                                "value": (v1 /*: any*/)
                            }
                        ],
                        "concreteType": "event",
                        "kind": "LinkedField",
                        "name": "events",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastSavedAt",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "canceledAt",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishedAt",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cancellationReason",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "occurrenceLabel",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": [
                                    {
                                        "kind": "Literal",
                                        "name": "where",
                                        "value": {
                                            "_and": [
                                                {
                                                    "_or": [
                                                        {
                                                            "status": {
                                                                "_eq": "complete"
                                                            }
                                                        },
                                                        {
                                                            "status": {
                                                                "_eq": "canceled"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "event": (v1 /*: any*/)
                                                }
                                            ]
                                        }
                                    }
                                ],
                                "concreteType": "registration",
                                "kind": "LinkedField",
                                "name": "registrations",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "userId",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": "registrations(where:{\"_and\":[{\"_or\":[{\"status\":{\"_eq\":\"complete\"}},{\"status\":{\"_eq\":\"canceled\"}}]},{\"event\":{\"deleted_at\":{\"_is_null\":true}}}]})"
                            }
                        ],
                        "storageKey": "events(where:{\"deleted_at\":{\"_is_null\":true}})"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "event",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'ee7fe5b2bdd6ee38e3c78180566ad2c8';
export default node;
