import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFragment } from 'react-relay/hooks';
import { useUpdateEffect } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { graphql } from 'relay-runtime';

import FormTextField from '@/ui/TextField/FormTextField';

import DialogWithSave from '@/components/Dialogs/DialogWithSave';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { useTrackSentryError } from '@/analytics/sentry';
import { DEFAULT_LOADING_SPINNER_DELAY, MAX_EVENT_NAME_LENGTH } from '@/const';
import { EditEventNameDialog_event$key } from '@/containers/HostEvent/EventMetadataDialog/__generated__/EditEventNameDialog_event.graphql';
import { useUpdateEventNameMutation } from '@/containers/HostEvent/EventMetadataDialog/eventMetadataMutations';
import { getEventName } from '@/lib/event-info-utils';
import { getValidEventMetadataSlugCF } from '@/lib/firebase';
import { EventMetadataFormSchema } from '@/lib/form-schema/host-event';
import { useDebounceState } from '@/lib/hooks/hooks';
import { useHostEventData } from '@/providers/HostEventProvider';
import { AppRouteService } from '@/routes/RouteService';

export interface EventMetadataFormProps {
  name: string;
  slug: string;
}
interface EditEventNameDialogProps {
  handleClose: () => void;
  isOpen: boolean;
}
const EditEventNameDialog: React.FC<EditEventNameDialogProps> = ({ handleClose, isOpen }) => {
  // Section Query
  const hostEventData = useHostEventData();
  const dialogData = useFragment<EditEventNameDialog_event$key>(
    graphql`
      fragment EditEventNameDialog_event on event {
        cuid
        eventMetadata {
          cuid
          name
          slug
          isCategoriedEvent
          organizer {
            slug
            cuid
          }
        }
      }
    `,
    hostEventData,
  );
  const { isCategoriedEvent, organizer, slug } = dialogData.eventMetadata;
  // Form
  const formMethods = useForm<EventMetadataFormProps>({
    defaultValues: {
      name: getEventName(dialogData),
      slug,
    },
    resolver: yupResolver(EventMetadataFormSchema),
  });
  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = formMethods;
  const trackSentryError = useTrackSentryError();
  const [isLoading, setIsLoading] = useState(false);
  const [isSlugLoading, setIsSlugLoading] = useState(false);

  const { updateEventNameMutation } = useUpdateEventNameMutation();

  const watchName = watch('name');
  const watchSlug = watch('slug');
  const debouncedWatchName = useDebounceState(watchName, 500);
  const eventPreviewUrl = AppRouteService.getAbsoluteUrl(
    'OrganizerProfile_Event',
    {
      organizerSlug: organizer.slug,
      eventSlug: watchSlug,
    },
    isCategoriedEvent ? { occurrence: dialogData.cuid } : undefined,
  );

  /**
   * Handle Close Dialog
   */
  const closeDialog = (): void => {
    handleClose();
  };

  /**
   * Get new slug on name change
   */
  useUpdateEffect(() => {
    (async (): Promise<void> => {
      const isNameValid = await trigger('name');
      if (!isNameValid) {
        return;
      }
      await getEventSlug(debouncedWatchName);
    })();
  }, [debouncedWatchName]);

  /**
   * Change name on lose focus
   */
  const handleQuerySlugOnBlur = async (): Promise<void> => {
    await trigger('name');
  };

  /**
   * Get an updated event slug
   */
  const getEventSlug = async (eventName: string): Promise<void> => {
    try {
      setIsSlugLoading(true);
      // Get updated slug
      let newSlug = slug;
      const hasNameChanged = eventName !== getEventName(dialogData);
      if (hasNameChanged) {
        const slugData = (
          await getValidEventMetadataSlugCF({
            eventName,
            eventMetadataCuid: dialogData.eventMetadata.cuid,
            organizerCuid: organizer.cuid,
          })
        ).data;
        newSlug = slugData.slug;
      }

      // Solve for a race condition
      if (eventName !== getValues('name')) {
        await getEventSlug(getValues('name'));
        return;
      }
      setValue('slug', newSlug);
    } catch (err) {
      trackSentryError(err);
    } finally {
      setIsSlugLoading(false);
    }
  };

  /**
   * Save event metadata
   */
  const handleSaveEventMetadata = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const isNameValid = await trigger('name');
      if (!isNameValid) {
        return;
      }
      const hasNameChanged = getValues('name') !== getEventName(dialogData);
      if (hasNameChanged) {
        await getEventSlug(getValues('name'));
        await updateEventNameMutation(getValues('name'), getValues('slug'));
      }
      handleClose();
    } catch (err) {
      trackSentryError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogWithSave
      noMarginBottom
      closeDialog={closeDialog}
      handleSave={handleSaveEventMetadata}
      isDialogOpen={isOpen}
      isLoading={isLoading || isSlugLoading}
      title="Edit Event Name"
    >
      {isCategoriedEvent && (
        <Typography gutterBottom variant="subtitle2">
          NOTE: Changing the name of the event will effect ALL occurrences of the event.
        </Typography>
      )}
      <div>
        <FormTextField
          fullWidth
          multiline
          control={control}
          error={!!errors.name}
          getValues={getValues}
          helperText={errors?.name?.message}
          inputProps={{ maxLength: MAX_EVENT_NAME_LENGTH }}
          label="Event Name"
          margin="dense"
          name="name"
          onBlur={handleQuerySlugOnBlur}
          onChange={(): void => clearErrors('name')}
          rowsMax={Infinity}
          variant="outlined"
        />
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Typography variant="caption">
            <b>You event will be available at: </b>
          </Typography>
          {watchName && (
            <Box alignItems="center" display="flex" minHeight={24} ml={0.5}>
              {isSlugLoading ? (
                <LoadingPlaceholder
                  isLoading
                  delay={DEFAULT_LOADING_SPINNER_DELAY}
                  shouldCenter={false}
                  size={16}
                />
              ) : (
                <Typography variant="caption">{eventPreviewUrl}</Typography>
              )}
            </Box>
          )}
        </Box>
      </div>
    </DialogWithSave>
  );
};

export default EditEventNameDialog;
