import { QueryType } from '@/lib/path-helpers';

/**
 * From ['lat', 'lng', 'lat', 'lng'] to [lat, lng, lat, lng]
 */
export const correctlyTypeBounds = (
  bounds: QueryType,
): [[number, number], [number, number]] | undefined => {
  if (!bounds || !Array.isArray(bounds)) {
    return undefined;
  }
  if (bounds.length !== 2) {
    return undefined;
  }
  // return bounds.map((b) => parseFloat(b)) as [number, number, number, number];
  return bounds.map((b) => [parseFloat(b.split(',')[0]), parseFloat(b.split(',')[1])]) as [
    [number, number],
    [number, number],
  ];
};
