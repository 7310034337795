import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { Divider, Menu, Tooltip, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as RA from 'ramda-adjunct';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import ShowHide from '@/hoc/ShowHide';

import { ChooseOrganizerMenuQuery } from './__generated__/ChooseOrganizerMenuQuery.graphql';

import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import { useGetOrganizerSlugFromURL } from '@/containers/Organizer/helpers';
import { ChooseOrganizerMenu_saveSelectedOrganizerSlug_Mutation } from '@/containers/Organizer/Navigation/__generated__/ChooseOrganizerMenu_saveSelectedOrganizerSlug_Mutation.graphql';
import { useIsMediumScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles(() => ({
  buttonLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'inherit',
    fontSize: 'inherit',
  },
}));

export const useSaveSelectedOrganizerSlugMutation = (): ((
  selectedOrganizerSlug: string,
) => void) => {
  const auth = useAuth();
  const [commitMutation] = useMutation<ChooseOrganizerMenu_saveSelectedOrganizerSlug_Mutation>(
    graphql`
      mutation ChooseOrganizerMenu_saveSelectedOrganizerSlug_Mutation(
        $selectedOrganizerSlug: String!
        $userId: String!
      ) {
        update_user(
          where: { id: { _eq: $userId } }
          _set: { selectedOrganizerSlug: $selectedOrganizerSlug }
        ) {
          returning {
            selectedOrganizerSlug
          }
        }
      }
    `,
  );
  return (selectedOrganizerSlug: string): void => {
    if (selectedOrganizerSlug === auth.user!.selectedOrganizerSlug) {
      return;
    }
    const sharedUpdater = (store: RecordSourceSelectorProxy): void => {
      const userRecord = store.get(auth.user!.id);
      if (!userRecord) {
        return;
      }
      userRecord.setValue(selectedOrganizerSlug, 'selectedOrganizerSlug');
    };
    commitMutation({
      variables: {
        userId: auth.firebaseUser!.uid,
        selectedOrganizerSlug,
      },
      updater: sharedUpdater,
      optimisticUpdater: sharedUpdater,
    });
  };
};

interface handleMobileMenuCloseProps {
  handleMobileMenuClose?: () => void;
}
const ChooseOrganizerMenu: React.FC<handleMobileMenuCloseProps> = ({ handleMobileMenuClose }) => {
  const organizerSlug = useGetOrganizerSlugFromURL();
  const headerData = useLazyLoadQuery<ChooseOrganizerMenuQuery>(
    graphql`
      query ChooseOrganizerMenuQuery($organizerSlug: String!) {
        organizer_connection(where: { slug: { _eq: $organizerSlug } }) {
          edges {
            node {
              name
              cuid
            }
          }
        }
      }
    `,
    { organizerSlug },
  ).organizer_connection.edges[0].node;
  const classes = useStyles();
  const headerClasses = useHeaderStyles();

  const auth = useAuth();
  const isMediumScreenDown = useIsMediumScreenDown();
  const [
    chooseOrganizerBtnAnchorEl,
    setChooseOrganizerBtnAnchorEl,
  ] = useState<HTMLButtonElement | null>(null);

  const saveSelectedOrganizerSlugMutation = useSaveSelectedOrganizerSlugMutation();

  const otherOrganizations = (auth.user?.userOrganizerRoles ?? [])
    .filter((role) => role.organizer?.cuid !== headerData.cuid)
    .map((role) => role.organizer!);
  const hasOtherOrganizations = RA.isNotEmpty(otherOrganizations);

  /**
   * Set the selectedOrganizerSlug in the user's profile if the page changed.
   */
  useEffect(() => {
    saveSelectedOrganizerSlugMutation(organizerSlug);
  }, [organizerSlug]);

  /**
   * Choose an organizer that the current user is a part of
   */
  const showChooseOrganizerOptions = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setChooseOrganizerBtnAnchorEl(e.currentTarget);
  const hideChooseOrganizerOptions = (): void => {
    setChooseOrganizerBtnAnchorEl(null);
    if (handleMobileMenuClose) {
      handleMobileMenuClose();
    }
  };

  return (
    <>
      <ExtendedButton
        endIcon={<ExpandMoreIcon />}
        onClick={showChooseOrganizerOptions}
        style={{ maxWidth: isMediumScreenDown ? undefined : 152 }}
      >
        <Typography className={classes.buttonLabel}>{headerData.name}</Typography>
      </ExtendedButton>
      <Menu
        anchorEl={chooseOrganizerBtnAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={headerClasses.menuItem}
        getContentAnchorEl={null}
        id="choose-organizer-menu"
        onBackdropClick={hideChooseOrganizerOptions}
        open={Boolean(chooseOrganizerBtnAnchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Tooltip title={"To edit your profile, go to 'More' -> 'Organizer Profile'"}>
          <MenuItem disabled>{headerData.name}</MenuItem>
        </Tooltip>
        <ShowHide show={hasOtherOrganizations}>
          {otherOrganizations.map((organizer) => (
            <NoUnderlineRouterLink
              noColor
              key={organizer.cuid}
              // Refetch to make sure we will have permission
              onClick={(): void => auth.fetchUser({ userId: auth.firebaseUser?.uid })}
              to={AppRouteService.getRelativeUrl('OrganizerApp_Events', {
                organizerSlug: organizer.slug,
              })}
            >
              <MenuItem key={organizer.cuid} onClick={hideChooseOrganizerOptions}>
                {organizer.name}
              </MenuItem>
            </NoUnderlineRouterLink>
          ))}
        </ShowHide>
        <Divider />
        <NoUnderlineRouterLink
          noColor
          to={AppRouteService.getRelativeUrl('OrganizerApp_CreateOrganizer', {
            organizerSlug,
          })}
        >
          <MenuItem onClick={hideChooseOrganizerOptions}>Create New Organization</MenuItem>
        </NoUnderlineRouterLink>
      </Menu>
    </>
  );
};

export default ChooseOrganizerMenu;
