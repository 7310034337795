import React from 'react';
import { LinkProps, makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { getValidUrl } from '@/lib/path-helpers';
import { useIsSmallScreenDown } from '@/lib/ui-utils';

const useStyles = makeStyles({
  openInNewIcon: {
    marginLeft: '2px',
    verticalAlign: 'middle',
    fontSize: 'inherit',
  },
});
export interface ExtendedLinkProps extends LinkProps {
  ellipsisOverflow?: boolean;
  maxWidth?: string;
  noUnderline?: boolean;
  shouldOpenInNewTab?: boolean | 'always';
  showOpenInNewIcon?: boolean;
  wrapText?: boolean;
}
const ExtendedLink: React.FC<ExtendedLinkProps> = ({
  children,
  ellipsisOverflow,
  maxWidth,
  noUnderline,
  shouldOpenInNewTab,
  showOpenInNewIcon,
  wrapText,
  ...rest
}) => {
  const classes = useStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const openInNewTab =
    (shouldOpenInNewTab && !isSmallScreenDown) || shouldOpenInNewTab === 'always'
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : {};
  let newStyle = {};
  if (wrapText) {
    newStyle = { overflowWrap: wrapText ? 'break-word' : 'normal' };
  } else if (ellipsisOverflow) {
    if (maxWidth && !wrapText) {
      newStyle = {
        display: 'block',
        maxWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };
    } else {
      throw Error('You need to set a max-width and no wrapText with ellipsisOverflow');
    }
  }

  return (
    <a
      {...openInNewTab}
      style={{ ...newStyle, textDecoration: noUnderline ? 'none' : undefined }}
      {...rest}
      href={shouldOpenInNewTab ? getValidUrl(rest.href) : rest.href}
      rel="noreferrer noopener"
    >
      {children}
      {shouldOpenInNewTab && showOpenInNewIcon && (
        <OpenInNewIcon className={classes.openInNewIcon} />
      )}
    </a>
  );
};

export default ExtendedLink;
