import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { Box, ClickAwayListener, Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import AutoCompletePanel from '@/containers/Search/HeaderSearch/AutoCompleteResults/AutoCompletePanel';
import DiscoverEventsChips from '@/containers/Search/HeaderSearch/AutoCompleteResults/DiscoverEventsChips';
import HeaderSearchField from '@/containers/Search/HeaderSearch/HeaderSearchField';
import {
  useGetHeaderSearchValueGlobal,
  useGetSearchQueryGlobal,
  useSetHeaderSearchValueGlobal,
  useSetSearchQueryGlobal,
} from '@/containers/Search/HeaderSearch/recoilStore';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { Colors } from '@/themes/colors';

export const useResponsiveSearchStyles = makeStyles((theme) => ({
  responsiveSearch: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  surpriseMeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    boxShadow: theme.shadows[4],
    border: Colors.LightBorder,
    color: Colors.Purple,
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
}));

interface SearchContainerProps {
  isSearchFocused: boolean;
  setIsSearchFocused: Dispatch<SetStateAction<boolean>>;
  isMobileMenuOpen: boolean;
}
const SearchContainer: React.FC<SearchContainerProps> = ({
  isMobileMenuOpen,
  isSearchFocused,
  setIsSearchFocused,
}) => {
  const classes = useResponsiveSearchStyles();
  const searchQueryGlobal = useGetSearchQueryGlobal();
  const setSearchQueryGlobal = useSetSearchQueryGlobal();
  const searchFieldValueGlobal = useGetHeaderSearchValueGlobal();
  const setSearchFieldValueGlobal = useSetHeaderSearchValueGlobal();
  const [isSearchHovered, setIsSearchHovered] = useState(false);
  // const totalAutocompleteHitsGlobal = useGetTotalAutocompleteHitsGlobal();

  const queryParams = getParsedQueryParams();

  // Each search result list updates its own "total hits" based on the index name.
  // We sum them all up here to get the total hits for the search results.
  // const totalHits = R.sum(R.values(totalAutocompleteHitsGlobal));

  // const { refine } = useConfigure({
  //   hitsPerPage: 2,
  //   aroundRadius: undefined,
  //   facetFilters: [],
  //   numericFilters: [],
  //   // query: debouncedSearchValue,
  // });

  /**
   * Set input value from querystring
   */
  useMount(() => {
    setSearchFieldValueGlobal((queryParams?.query as string | null) ?? '');
    setSearchQueryGlobal((queryParams?.query as string | null) ?? '');
  });

  const handleMobileSearchClick = (): void => {
    setIsSearchFocused(true);
    const searchInput = document.getElementById('header-search-input');
    if (!searchInput) {
      return;
    }
    // Give the input time to show up
    setTimeout(() => {
      searchInput.focus();
    }, 20);
  };

  const handleSearchBlur = (): void => {
    setIsSearchFocused(false);
    setSearchFieldValueGlobal(searchQueryGlobal);
  };

  return (
    <ClickAwayListener onClickAway={handleSearchBlur}>
      <Box className={classes.responsiveSearch} display="flex">
        {/* <Hidden smUp> */}
        {/*  {!isSearchFocused && !isMobileMenuOpen && ( */}
        {/*    <Box alignItems="center" display="flex" ml="auto" onClick={handleMobileSearchClick}> */}
        {/*      <Typography variant="body2">Search</Typography> */}
        {/*      <IconButton> */}
        {/*        <SearchIcon /> */}
        {/*      </IconButton> */}
        {/*    </Box> */}
        {/*  )} */}
        {/* </Hidden> */}
        {/* <Hidden xsDown={!isSearchFocused}> */}
        <Box
          className={classes.responsiveSearch}
          onFocus={(): void => setIsSearchFocused(true)}
          onMouseEnter={(): void => setIsSearchHovered(true)}
          onMouseLeave={(): void => setIsSearchHovered(false)}
          position="relative"
        >
          <HeaderSearchField
            handleChange={setSearchFieldValueGlobal}
            isMobileMenuOpen={isMobileMenuOpen}
            isSearchFocused={isSearchFocused}
            isSearchHovered={isSearchHovered}
            setIsSearchFocused={setIsSearchFocused}
            setIsSearchHovered={setIsSearchHovered}
            value={searchFieldValueGlobal}
          />
          <AutoCompletePanel isHovered={isSearchHovered} isShown={isSearchFocused}>
            <Box display="flex" flexDirection="column" width="100%">
              <DiscoverEventsChips setIsSearchFocused={setIsSearchFocused} />
              {/* <FormOrDivider my={1} /> */}
              {/* <Box p={2}> */}
              {/*  <Box className={classes.surpriseMeButton}> */}
              {/*    <Typography>Surprise Me</Typography> */}
              {/*  </Box> */}
              {/* </Box> */}
            </Box>
          </AutoCompletePanel>
        </Box>
        {/* </Hidden> */}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchContainer;
