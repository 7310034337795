/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type reviewSubjectType_enum = "clinic" | "coach" | "%future added value";
export type ReviewServiceQueries_saveReview_MutationVariables = {
    userId: string;
    reviewSubjectCuid: string;
    reviewSubjectType: reviewSubjectType_enum;
    review?: Record<string, unknown> | unknown[] | null | undefined;
    reviewSubjectInstanceCuid?: string | null | undefined;
};
export type ReviewServiceQueries_saveReview_MutationResponse = {
    readonly insert_review_one: {
        readonly id: string;
    } | null;
};
export type ReviewServiceQueries_saveReview_Mutation = {
    readonly response: ReviewServiceQueries_saveReview_MutationResponse;
    readonly variables: ReviewServiceQueries_saveReview_MutationVariables;
};



/*
mutation ReviewServiceQueries_saveReview_Mutation(
  $userId: String!
  $reviewSubjectCuid: String!
  $reviewSubjectType: reviewSubjectType_enum!
  $review: jsonb
  $reviewSubjectInstanceCuid: String
) {
  insert_review_one(object: {reviewerId: $userId, reviewSubjectType: $reviewSubjectType, reviewSubjectCuid: $reviewSubjectCuid, review: $review, reviewSubjectInstanceCuid: $reviewSubjectInstanceCuid}) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "review"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "reviewSubjectCuid"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "reviewSubjectInstanceCuid"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "reviewSubjectType"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    } as any, v5 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "review",
                            "variableName": "review"
                        },
                        {
                            "kind": "Variable",
                            "name": "reviewSubjectCuid",
                            "variableName": "reviewSubjectCuid"
                        },
                        {
                            "kind": "Variable",
                            "name": "reviewSubjectInstanceCuid",
                            "variableName": "reviewSubjectInstanceCuid"
                        },
                        {
                            "kind": "Variable",
                            "name": "reviewSubjectType",
                            "variableName": "reviewSubjectType"
                        },
                        {
                            "kind": "Variable",
                            "name": "reviewerId",
                            "variableName": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "object"
                }
            ],
            "concreteType": "review",
            "kind": "LinkedField",
            "name": "insert_review_one",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_saveReview_Mutation",
            "selections": (v5 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v4 /*: any*/),
                (v1 /*: any*/),
                (v3 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ReviewServiceQueries_saveReview_Mutation",
            "selections": (v5 /*: any*/)
        },
        "params": {
            "cacheID": "56539d2d68578477f533223d0a75d70e",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_saveReview_Mutation",
            "operationKind": "mutation",
            "text": "mutation ReviewServiceQueries_saveReview_Mutation(\n  $userId: String!\n  $reviewSubjectCuid: String!\n  $reviewSubjectType: reviewSubjectType_enum!\n  $review: jsonb\n  $reviewSubjectInstanceCuid: String\n) {\n  insert_review_one(object: {reviewerId: $userId, reviewSubjectType: $reviewSubjectType, reviewSubjectCuid: $reviewSubjectCuid, review: $review, reviewSubjectInstanceCuid: $reviewSubjectInstanceCuid}) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0987f0b3236d893bbe6f2e3cc3482873';
export default node;
