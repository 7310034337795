/**
 * Discover page
 */
import React from 'react';

import Page from '../Page';

import DiscoverView from '@/containers/Discover/DiscoverView';
import MainLayout from '@/layouts/MainLayout';

const DiscoverPage: React.FC = () => {
  return (
    <Page
      description="Find MTB, road, gravel, and all cycling experiences and events."
      title="Discover Cycling Experiences • Reggy"
    >
      <MainLayout showFooter={false} stickyHeader={false}>
        <DiscoverView />
      </MainLayout>
    </Page>
  );
};

export default DiscoverPage;
