import { Theme } from '@material-ui/core';

import { Colors } from '../colors';

export const MuiLink = (theme: Theme): Record<string, unknown> => ({
  root: {
    color: Colors.Brand,
    textDeocration: 'none',
    '&:hover': {
      color: Colors.Brand,
      textDeoration: 'underline',
    },
    '&.MuiLink-button': {
      verticalAlign: 'baseline',
    },
    '&.MuiLink-button[disabled]': {
      color: theme.palette.grey[400],
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '&.secondary': {
      color: theme.palette.grey[500],
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
});
