/*
Recoil atoms, selectors and hooks for Validity Checker
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  validityCheckerDefaults,
  ValidityReturnProps,
} from '@/containers/HostEvent/validityChecker/const';

/** ************************************
 * Global Event Validity Properties
 ************************************* */
const validityInfoState = atom<ValidityReturnProps>({
  key: 'validityInfo', // unique ID (with respect to other atoms/selectors)
  default: validityCheckerDefaults,
});
export const useGetValidityInfoGlobal = (): ValidityReturnProps => {
  return <ValidityReturnProps>useRecoilValue(validityInfoState);
};
export const useSetValidityInfoGlobal = (): SetterOrUpdater<ValidityReturnProps> => {
  return useSetRecoilState(validityInfoState);
};
