import * as React from 'react';
import { ReactElement, ReactNode, useLayoutEffect, useRef } from 'react';
import { getTrackBackground, Range, useThumbOverlap } from 'react-range';
import { Direction, IProps, IThumbProps, ITrackProps } from 'react-range/lib/types';
import { useMount } from 'react-use';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useForceUpdate from 'use-force-update';

import { Colors } from '@/themes/colors';

const THUMB_SIZE = 36;
const SMALL_THUMB_SIZE = 26;
const THUMB_LABEL_HEIGHT = 30;
const SMALL_THUMB_LABEL_HEIGHT = 22;

/**
 * Ignore slider errors
 */
class SliderErrorBoundary extends React.Component {
  componentDidCatch(): void {
    // console.log(error);
    // throw error;
  }

  render(): ReactNode {
    const { children } = this.props;
    return <>{children}</>;
  }
}

const ThumbLabel: React.FC<{
  rangeRef: Range | null;
  values: number[];
  index: number;
  small: boolean | undefined;
  step: number;
  valueToLabel?: (value: string) => string;
  viewOnly?: boolean;
}> = ({ index, rangeRef, small, step, values, valueToLabel, viewOnly }) => {
  const [labelValue, labelStyle] = useThumbOverlap(
    rangeRef,
    values,
    index,
    step,
    ' - ',
    valueToLabel ?? ((value): string => value),
  );
  return (
    <div
      data-label={index}
      style={{
        ...(labelStyle as React.CSSProperties),
        display: 'block',
        position: 'absolute',
        top: viewOnly ? '-16px' : '-30px',
        // color: '#fff',
        fontWeight: 500,
        fontSize: small ? '12px' : '13px',
        padding: '4px',
        borderRadius: '4px',
        border: viewOnly ? undefined : Colors.LightBorder,
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        boxShadow: viewOnly ? undefined : (labelStyle as React.CSSProperties).boxShadow,
        // width: viewOnly ? 0 : labelStyle.width,
      }}
    >
      {labelValue}
    </div>
  );
};

interface SliderProps
  extends Omit<
    IProps,
    | 'renderThumb'
    | 'renderTrack'
    | 'direction'
    | 'draggableTrack'
    | 'disabled'
    | 'rtl'
    | 'allowOverlap'
  > {
  allowOverlap?: boolean;
  disabled?: boolean;
  direction?: Direction;
  draggableTrack?: boolean;
  rtl?: boolean;
  valueToLabel?: (value: string) => string;
  hideThumbLabel?: boolean;
  small?: boolean;
  viewOnly?: boolean;
}
const Slider: React.FC<SliderProps> = ({
  hideThumbLabel,
  max,
  min,
  small,
  step,
  values,
  valueToLabel,
  viewOnly,
  ...rest
}) => {
  const theme = useTheme();
  const forceUpdate = useForceUpdate();
  const rangeRef = useRef<Range | null>(null);

  /**
   * Fix a bug where label doesn't combine with thumb
   */
  useMount(() => {
    forceUpdate();
  });

  /**
   * if not unmounted, causes an error
   */
  useLayoutEffect(() => {
    return (): void => {
      rangeRef.current = null;
    };
  }, []);

  /**
   * Build thumb
   */
  const RenderThumb = ({
    index,
    isDragged,
    props,
  }: {
    props: IThumbProps;
    value: number;
    index: number;
    isDragged: boolean;
  }): ReactElement => {
    const { style } = props;
    const isFirstAndLastValueTheSame = values[0] === values[values.length - 1];
    return (
      <div
        {...props}
        style={{
          ...style,
          height: `${small ? SMALL_THUMB_SIZE : THUMB_SIZE}px`,
          width: viewOnly ? 4 : `${small ? SMALL_THUMB_SIZE : THUMB_SIZE}px`,
          borderRadius: '4px',
          backgroundColor: '#FFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: viewOnly ? undefined : '0px 2px 6px #AAA',
        }}
      >
        {!hideThumbLabel && (
          <ThumbLabel
            index={index}
            rangeRef={rangeRef.current}
            small={small}
            step={step}
            valueToLabel={valueToLabel}
            values={values}
            viewOnly={viewOnly}
          />
        )}
        {(!viewOnly || (viewOnly && isFirstAndLastValueTheSame)) && (
          <div
            style={{
              height: viewOnly ? '5px' : small ? '13px' : '16px',
              width: small ? '3px' : '5px',
              borderRadius: viewOnly ? '2px' : undefined,
              backgroundColor:
                isDragged || viewOnly ? theme.palette.primary.main : Colors.LightBorderColor,
            }}
          />
        )}
      </div>
    );
  };

  /**
   * Build track
   */
  const RenderTrack = ({
    children,
    props,
  }: {
    props: ITrackProps;
    children: React.ReactNode;
    isDragged: boolean;
    disabled: boolean;
  }): ReactElement => {
    const { onMouseDown, onTouchStart, ref, style } = props;
    const colors =
      values.length > 1
        ? [Colors.LightBorderColor, theme.palette.primary.main, Colors.LightBorderColor]
        : [theme.palette.primary.main, Colors.LightBorderColor];
    return (
      <div
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        role="button"
        style={{
          ...style,
          height: '36px',
          display: 'flex',
          width: '100%',
        }}
        tabIndex={-1}
      >
        <div
          ref={ref}
          style={{
            height: '4px',
            width: '100%',
            borderRadius: '4px',
            background: getTrackBackground({
              values,
              colors,
              min,
              max,
            }),
            alignSelf: 'center',
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <Box
      pt={
        !hideThumbLabel
          ? `${viewOnly ? 16 : small ? SMALL_THUMB_LABEL_HEIGHT : THUMB_LABEL_HEIGHT}px`
          : undefined
      }
    >
      <SliderErrorBoundary>
        <Range
          {...rest}
          max={max}
          min={min}
          ref={rangeRef}
          renderThumb={RenderThumb}
          renderTrack={RenderTrack}
          step={step}
          values={values}
        />
      </SliderErrorBoundary>
    </Box>
  );
};

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hasError: false,
//     };
//   }
//
//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }
//
//   componentDidCatch(error, errorInfo) {
//     // Can be used to log to any logging service like sentry
//     console.log('Catched error', errorInfo);
//   }
//
//   render() {
//     if (this.state.hasError) {
//       return (
//         // <h3>Something went wrong!</h3>
//         // Can be a static or a fall-back component passed as a prop.
//         this.props.fallBackUIComponent
//       );
//     }
//
//     return this.props.children;
//   }
// }

export default Slider;
