import { V1 } from './V1';

import { Migration, Versioned } from '../../../Versions';

/**
 * Response
 */
export type ResponseType = 'y/n' | 'recommendation' | 'open';
export interface ResponseBase<T extends ResponseType> {
  type: T;
  questionId: QuestionId;
}
export interface YesNoResponse extends ResponseBase<'y/n'> {
  value: 0 | 1;
}
export interface RecommendationResponse extends ResponseBase<'recommendation'> {
  value: 1 | 2 | 3 | 4;
  why: string;
}
export interface OpenResponse extends ResponseBase<'open'> {
  value: string;
}
export type Response = YesNoResponse | RecommendationResponse | OpenResponse;

/**
 * Questions
 */
export type QuestionId = string;
export type Category = 'communication' | 'expertise' | 'vibe' | 'general';
export type User = 'student' | 'coach';

export interface QuestionBase<T extends User> {
  id: QuestionId;
  category: Category;
  responseType: ResponseType;
  attribute: string;
  alwaysAsk: boolean;
  user: T;
  label: string;
}
export type StudentQuestion = QuestionBase<'student'>;
export type CoachQuestion = QuestionBase<'coach'>;
export type Question = StudentQuestion | CoachQuestion;

/**
 * Preferences
 */
export type UserPreferences = Array<QuestionId | undefined>;

/**
 * Versions
 */

export type V2 = Versioned<
  2,
  {
    questions: Question[];
    responses: Record<QuestionId, Response>;
    studentPrefs: UserPreferences;
    coachPrefs: UserPreferences;
  }
>;

/**
 * Migration
 *
 * Type Changes:
 * - new 'recommendation' ResponseType with values starting at index 1 instead of index 0
 * - change remaining 'boolean scale' type questions (that are not recommendation) into new 'y/n' ResponseType
 * - replaced 'responseConfig' with 'responseType' in Question
 *
 * Question Changes:
 * - removed 'sq_coachDescription' question
 *   - preserve existing answers by appending response to 'sq_recommendation' open response
 * - renamed 'cq_approval' -> 'cq_recommendation'
 */
export const migrateFromV1ToV2: Migration = (lastVersion) => {
  const version1 = lastVersion as V1;
  const contents: Record<string, unknown> = {};

  if (version1.contents.questions) {
    const questions: Question[] = [];
    version1.contents.questions.forEach((q): void => {
      // remove coach description question
      if (q.id === 'sq_coachDescription') {
        return;
      }

      // rename cq_approval => cq_recommendation
      const id = q.id === 'cq_approval' ? 'cq_recommendation' : q.id;

      // replace responseConfig with responseType attribute
      let responseType: ResponseType;
      if (q.responseConfig?.type === 'open') {
        responseType = 'open';
      } else if (
        q.responseConfig?.type === 'boolean scale' &&
        q.responseConfig.scale.length === 4
      ) {
        responseType = 'recommendation';
      } else {
        responseType = 'y/n';
      }

      const question = {
        id,
        category: q.category,
        responseType,
        attribute: q.attribute,
        alwaysAsk: q.alwaysAsk,
        user: q.user,
        label: q.label,
      };
      questions.push(question);
    });
    contents.questions = questions;
  }

  if (version1.contents.responses) {
    const responses: Record<QuestionId, Response> = {};

    let foundCoachDescription = false;

    Object.entries(version1.contents.responses).forEach(([qId, response]) => {
      // skip coach description response
      if (qId === 'sq_coachDescription') {
        foundCoachDescription = true;
        return;
      }

      const isRecommendationType =
        response.type === 'boolean scale' &&
        (response.questionId === 'sq_recommendation' || response.questionId === 'cq_approval');

      if (response.type === 'open') {
        responses[qId] = { ...response };
      } else if (isRecommendationType) {
        // previous recommendation values started at 0, so shift up by 1
        responses[qId] = {
          type: 'recommendation',
          questionId: qId,
          value: (response.value + 1) as 1 | 2 | 3 | 4,
          why: response.explanation || '',
        };
      } else {
        responses[qId] = {
          type: 'y/n',
          questionId: qId,
          value: response.value === 0 || response.value === 1 ? response.value : 0,
        };
      }
    });

    // preserve coach description response by appending it into the recommendation open response
    if (foundCoachDescription) {
      let { why } = responses.sq_recommendation as RecommendationResponse;
      if (why && why.length > 0) {
        why += '\n\n';
      }
      const coachDescriptionResponse = version1.contents.responses.sq_coachDescription.value;
      responses.sq_recommendation = {
        ...responses.sq_recommendation,
        why: `${why}${coachDescriptionResponse}`,
      } as RecommendationResponse;
    }

    contents.responses = responses;
  }

  if (version1.contents.studentPrefs) {
    const studentPrefs: UserPreferences = version1.contents.studentPrefs.map((qId) =>
      qId === 'sq_coachDescription' ? undefined : qId,
    );
    contents.studentPrefs = studentPrefs;
  }

  if (version1.contents.coachPrefs) {
    const coachPrefs: UserPreferences = version1.contents.coachPrefs.map((qId) =>
      qId === 'cq_approval' ? 'cq_recommendation' : qId,
    );
    contents.coachPrefs = coachPrefs;
  }

  return {
    version: 2,
    contents,
  };
};
