/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, Suspense } from 'react';
import pRetry, { AbortError } from 'p-retry';

import ErrorBoundary from '../components/ErrorBoundary';
import ErrorMessage from '../components/ErrorMessage';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

const PromptReloadPage: React.FC = () => {
  return (
    <ErrorMessage
      buttonFunction={(): void => window.location.reload()}
      buttonTitle="Reload Page"
      message="Wanna give us another chance at that?"
      title="Error Loading Page"
    />
  );
};

export default function LazyloadComponentWithRetry(
  componentToLoad: () => Promise<any>,
): React.ComponentClass<{ [x: string]: any }> {
  function retryRequest(): Promise<{ default: ComponentType<any> }> {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    return componentToLoad()
      .then((component) => {
        // Reset the window refresh flag as we have successfully loaded the component
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        return component;
      })
      .catch((error: Error & { request: any }) => {
        if (error.name === 'ChunkLoadError' && !hasRefreshed) {
          // Failed to load the component, so set the window refresh flag so we don't try again
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          window.location.reload();
          throw new AbortError(error);
        }
        // Only retry network errors
        if (typeof error.request !== 'string') {
          // sentryTrackError(error)
          throw new AbortError(error);
        }
        // webpack won't retry fetching them
        if (!error.request.endsWith('.js')) {
          throw new AbortError(error);
        }

        throw error;
      });
  }

  // Load Component
  const AsyncComponent = React.lazy(() => pRetry(retryRequest, { retries: 3, minTimeout: 300 }));

  return class LoadComponent extends React.Component {
    static preload(): void {
      componentToLoad().then();
    }

    render(): React.ReactNode {
      return (
        <ErrorBoundary errorComponent={PromptReloadPage}>
          <Suspense fallback={<LoadingPlaceholder disableShrink isLoading delay={200} />}>
            <AsyncComponent {...this.props} />
          </Suspense>
        </ErrorBoundary>
      );
    }
  };
}
