/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccessName_constraint = "AccessName_pkey" | "%future added value";
export type AccessName_enum = "Invite" | "Public" | "Unlisted" | "%future added value";
export type AccessName_update_column = "name" | "%future added value";
export type activityType_constraint = "activityType_pkey" | "%future added value";
export type activityType_enum = "Clinic" | "Race" | "Ride" | "%future added value";
export type activityType_update_column = "name" | "%future added value";
export type amenity_constraint = "PK_amenities" | "amenity_cuid_key" | "amenity_pkey" | "%future added value";
export type amenity_update_column = "cuid" | "id" | "imageUrl" | "info" | "name" | "%future added value";
export type basicSignatureRequest_constraint = "basicSignatureRequest_cuid_key" | "basicSignatureRequest_pkey" | "%future added value";
export type basicSignatureRequest_select_column = "created_at" | "cuid" | "id" | "registrationCuid" | "signerEmail" | "signerName" | "signerType" | "status" | "successToken" | "updated_at" | "waiverFileUrl" | "waiverName" | "%future added value";
export type basicSignatureRequest_update_column = "created_at" | "cuid" | "id" | "registrationCuid" | "signerEmail" | "signerName" | "signerType" | "status" | "successToken" | "updated_at" | "waiverFileUrl" | "waiverName" | "%future added value";
export type categoryCourse_constraint = "categoryCourse_categoryCuid_courseCuid_key" | "categoryCourse_pkey" | "%future added value";
export type categoryCourse_select_column = "categoryCuid" | "courseCuid" | "created_at" | "cuid" | "updated_at" | "%future added value";
export type categoryCourse_update_column = "categoryCuid" | "courseCuid" | "created_at" | "cuid" | "updated_at" | "%future added value";
export type category_constraint = "category_cuid_key" | "category_pkey" | "%future added value";
export type category_select_column = "abilityLevelRange" | "additionalPrizes" | "ageAsOf" | "created_at" | "cuid" | "deleted_at" | "description" | "entryFee" | "eventCuid" | "eventDisciplineCuid" | "gender" | "id" | "maxAge" | "minAge" | "name" | "position" | "spotsAvailable" | "updated_at" | "visible" | "%future added value";
export type category_select_column_category_aggregate_bool_exp_bool_and_arguments_columns = "visible" | "%future added value";
export type category_select_column_category_aggregate_bool_exp_bool_or_arguments_columns = "visible" | "%future added value";
export type category_update_column = "abilityLevelRange" | "additionalPrizes" | "ageAsOf" | "created_at" | "cuid" | "deleted_at" | "description" | "entryFee" | "eventCuid" | "eventDisciplineCuid" | "gender" | "id" | "maxAge" | "minAge" | "name" | "position" | "spotsAvailable" | "updated_at" | "visible" | "%future added value";
export type clinicCoach_constraint = "clinicCoach_coachCuid_clinicCuid_key" | "clinicCoach_cuid_key" | "clinicCoach_pkey" | "%future added value";
export type clinicCoach_select_column = "clinicCuid" | "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "%future added value";
export type clinicCoach_update_column = "clinicCuid" | "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "%future added value";
export type clinicDay_constraint = "clinicDay_cuid_key" | "clinicDay_pkey" | "%future added value";
export type clinicDay_select_column = "clinicCuid" | "created_at" | "cuid" | "eventClinicDayDurationCuid" | "id" | "startTime" | "updated_at" | "%future added value";
export type clinicDay_update_column = "clinicCuid" | "created_at" | "cuid" | "eventClinicDayDurationCuid" | "id" | "startTime" | "updated_at" | "%future added value";
export type clinic_constraint = "clinic_cuid_key" | "clinic_pkey" | "%future added value";
export type clinic_select_column = "canceledAt" | "cancellationReason" | "created_at" | "cuid" | "dayOfContact" | "eventCuid" | "id" | "meetingLink" | "priceOverride" | "registrationStatus" | "spotsAvailable" | "updated_at" | "%future added value";
export type clinic_update_column = "canceledAt" | "cancellationReason" | "created_at" | "cuid" | "dayOfContact" | "eventCuid" | "id" | "meetingLink" | "priceOverride" | "registrationStatus" | "spotsAvailable" | "updated_at" | "%future added value";
export type coachAvailability_constraint = "coachAvailability_cuid_key" | "coachAvailability_pkey" | "%future added value";
export type coachAvailability_select_column = "coachCuid" | "created_at" | "cuid" | "dayOfTheWeek" | "endSeconds" | "id" | "isAvailable" | "startSeconds" | "updated_at" | "%future added value";
export type coachAvailability_select_column_coachAvailability_aggregate_bool_exp_bool_and_arguments_columns = "isAvailable" | "%future added value";
export type coachAvailability_select_column_coachAvailability_aggregate_bool_exp_bool_or_arguments_columns = "isAvailable" | "%future added value";
export type coachAvailability_update_column = "coachCuid" | "created_at" | "cuid" | "dayOfTheWeek" | "endSeconds" | "id" | "isAvailable" | "startSeconds" | "updated_at" | "%future added value";
export type coachCertification_constraint = "coachCertification_cuid_key" | "userCoachCertification_pkey" | "%future added value";
export type coachCertification_select_column = "certification" | "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "year" | "%future added value";
export type coachCertification_update_column = "certification" | "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "year" | "%future added value";
export type coachCustomerLessonDateLocation_constraint = "coachCustomerLessonDateLocation_cuid_key" | "serviceDateLocation_pkey" | "%future added value";
export type coachCustomerLessonDateLocation_select_column = "addressLine1" | "city" | "coachCustomerLessonDateCuid" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "googlePlaceId" | "id" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type coachCustomerLessonDateLocation_update_column = "addressLine1" | "city" | "coachCustomerLessonDateCuid" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "googlePlaceId" | "id" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type coachCustomerLessonDateVirtualLocation_constraint = "coachCustomerLessonDateVirtualLocation_cuid_key" | "coachCustomerLessonDateVirtualLocation_pkey" | "%future added value";
export type coachCustomerLessonDateVirtualLocation_select_column = "coachCustomerLessonDateCuid" | "created_at" | "cuid" | "id" | "meetingLink" | "timezone" | "updated_at" | "%future added value";
export type coachCustomerLessonDateVirtualLocation_update_column = "coachCustomerLessonDateCuid" | "created_at" | "cuid" | "id" | "meetingLink" | "timezone" | "updated_at" | "%future added value";
export type coachCustomerLessonDate_constraint = "coachCustomerLessonDate_cuid_key" | "coachCustomerLessonDate_pkey" | "%future added value";
export type coachCustomerLessonDate_select_column = "canceledDate" | "coachCustomerLessonCuid" | "created_at" | "cuid" | "duration" | "id" | "notes" | "originalStartDate" | "originalTimezone" | "refundedDate" | "startDate" | "updated_at" | "%future added value";
export type coachCustomerLessonDate_update_column = "canceledDate" | "coachCustomerLessonCuid" | "created_at" | "cuid" | "duration" | "id" | "notes" | "originalStartDate" | "originalTimezone" | "refundedDate" | "startDate" | "updated_at" | "%future added value";
export type coachCustomerLessonOneTimeCharge_constraint = "coachCustomerLessonOneTimeCharge_cuid_key" | "coachCustomerLessonOneTimeCharge_pkey" | "%future added value";
export type coachCustomerLessonOneTimeCharge_select_column = "coachCustomerLessonDateCuid" | "created_at" | "cuid" | "description" | "id" | "offerMessageId" | "paidDate" | "price" | "refundedDate" | "status" | "updated_at" | "%future added value";
export type coachCustomerLessonOneTimeCharge_update_column = "coachCustomerLessonDateCuid" | "created_at" | "cuid" | "description" | "id" | "offerMessageId" | "paidDate" | "price" | "refundedDate" | "status" | "updated_at" | "%future added value";
export type coachCustomerLessonPauseResume_constraint = "coachCustomerLessonPauseResume_cuid_key" | "coachCustomerLessonPauseResume_pkey" | "%future added value";
export type coachCustomerLessonPauseResume_select_column = "actionDate" | "actionType" | "coachCustomerLessonCuid" | "created_at" | "cuid" | "id" | "pauseOnDate" | "pausedUntilDate" | "updated_at" | "%future added value";
export type coachCustomerLessonPauseResume_update_column = "actionDate" | "actionType" | "coachCustomerLessonCuid" | "created_at" | "cuid" | "id" | "pauseOnDate" | "pausedUntilDate" | "updated_at" | "%future added value";
export type coachCustomerLessonTransaction_constraint = "coachCustomerLessonTransaction_cuid_key" | "coachCustomerLessonTransaction_pkey" | "%future added value";
export type coachCustomerLessonTransaction_select_column = "coachCustomerLessonCuid" | "coachCustomerLessonDateCuid" | "coachCustomerLessonOneTimeChargeCuid" | "created_at" | "cuid" | "id" | "referenceNo" | "stripeCheckoutId" | "stripeInvoiceUrl" | "stripePaymentIntentId" | "successToken" | "transactionStatus" | "transactionType" | "updated_at" | "%future added value";
export type coachCustomerLessonTransaction_update_column = "coachCustomerLessonCuid" | "coachCustomerLessonDateCuid" | "coachCustomerLessonOneTimeChargeCuid" | "created_at" | "cuid" | "id" | "referenceNo" | "stripeCheckoutId" | "stripeInvoiceUrl" | "stripePaymentIntentId" | "successToken" | "transactionStatus" | "transactionType" | "updated_at" | "%future added value";
export type coachCustomerLesson_constraint = "coachCustomerLesson_cuid_key" | "coachCustomerLesson_pkey" | "%future added value";
export type coachCustomerLesson_select_column = "abilityLevelRange" | "cancellationReason" | "coachCuid" | "coachCustomerCuid" | "coachLessonCuid" | "created_at" | "cuid" | "currency" | "disciplineNames" | "id" | "isVirtual" | "offerCanceledDate" | "offerMessageId" | "offerRejectedDate" | "paidDate" | "price" | "recurrenceType" | "recurringInterval" | "resumeRecurringLessonTriggerId" | "skills" | "status" | "stripeSubscriptionId" | "subscriptionCanceledDate" | "subscriptionPausedUntil" | "title" | "updated_at" | "%future added value";
export type coachCustomerLesson_select_column_coachCustomerLesson_aggregate_bool_exp_bool_and_arguments_columns = "isVirtual" | "%future added value";
export type coachCustomerLesson_select_column_coachCustomerLesson_aggregate_bool_exp_bool_or_arguments_columns = "isVirtual" | "%future added value";
export type coachCustomerLesson_update_column = "abilityLevelRange" | "cancellationReason" | "coachCuid" | "coachCustomerCuid" | "coachLessonCuid" | "created_at" | "cuid" | "currency" | "disciplineNames" | "id" | "isVirtual" | "offerCanceledDate" | "offerMessageId" | "offerRejectedDate" | "paidDate" | "price" | "recurrenceType" | "recurringInterval" | "resumeRecurringLessonTriggerId" | "skills" | "status" | "stripeSubscriptionId" | "subscriptionCanceledDate" | "subscriptionPausedUntil" | "title" | "updated_at" | "%future added value";
export type coachCustomerMessageRequest_constraint = "coachCustomerMessageRequest_cuid_key" | "coachCustomerMessageRequest_pkey" | "%future added value";
export type coachCustomerMessageRequest_select_column = "coachCustomerChannelId" | "coachCustomerUserCuid" | "created_at" | "cuid" | "id" | "message" | "streamMessageId" | "updated_at" | "%future added value";
export type coachCustomerMessageRequest_update_column = "coachCustomerChannelId" | "coachCustomerUserCuid" | "created_at" | "cuid" | "id" | "message" | "streamMessageId" | "updated_at" | "%future added value";
export type coachCustomerUser_constraint = "coachCustomerUser_coachCustomerCuid_userId_key" | "coachCustomerUser_cuid_key" | "coachCustomerUser_pkey" | "%future added value";
export type coachCustomerUser_select_column = "age" | "coachCustomerCuid" | "created_at" | "cuid" | "firstName" | "id" | "inviteStatus" | "updated_at" | "userId" | "%future added value";
export type coachCustomerUser_update_column = "age" | "coachCustomerCuid" | "created_at" | "cuid" | "firstName" | "id" | "inviteStatus" | "updated_at" | "userId" | "%future added value";
export type coachCustomer_constraint = "coachCustomer_channelId_key" | "coachCustomer_cuid_key" | "coachCustomer_pkey" | "%future added value";
export type coachCustomer_select_column = "channelId" | "coachCuid" | "created_at" | "cuid" | "id" | "notes" | "requestedCoachLessonCuid" | "requestedStartTime" | "status" | "updated_at" | "%future added value";
export type coachCustomer_update_column = "channelId" | "coachCuid" | "created_at" | "cuid" | "id" | "notes" | "requestedCoachLessonCuid" | "requestedStartTime" | "status" | "updated_at" | "%future added value";
export type coachDiscipline_constraint = "coachDiscipline_cuid_key" | "coachDiscipline_pkey" | "%future added value";
export type coachDiscipline_select_column = "abilityLevelRange" | "coachCuid" | "created_at" | "cuid" | "id" | "name" | "skills" | "updated_at" | "%future added value";
export type coachDiscipline_update_column = "abilityLevelRange" | "coachCuid" | "created_at" | "cuid" | "id" | "name" | "skills" | "updated_at" | "%future added value";
export type coachImage_constraint = "coachImage_cuid_key" | "coachImage_pkey" | "%future added value";
export type coachImage_select_column = "coachCuid" | "created_at" | "cuid" | "id" | "imageUserPublicId" | "position" | "updated_at" | "%future added value";
export type coachImage_update_column = "coachCuid" | "created_at" | "cuid" | "id" | "imageUserPublicId" | "position" | "updated_at" | "%future added value";
export type coachLessonLocation_constraint = "coachLessonLocation_coachLessonCuid_lessonLocationCuid_key" | "coachLessonLocation_cuid_key" | "coachLessonLocation_pkey" | "%future added value";
export type coachLessonLocation_select_column = "coachLessonCuid" | "created_at" | "cuid" | "id" | "lessonLocationCuid" | "updated_at" | "%future added value";
export type coachLessonLocation_update_column = "coachLessonCuid" | "created_at" | "cuid" | "id" | "lessonLocationCuid" | "updated_at" | "%future added value";
export type coachLesson_constraint = "coachLesson_cuid_key" | "coachLesson_pkey" | "%future added value";
export type coachLesson_select_column = "abilityLevelRange" | "coachCuid" | "created_at" | "cuid" | "description" | "disciplineNames" | "duration" | "highlightedPosition" | "id" | "isBundle" | "isHidden" | "isVirtual" | "lessonsPerBundle" | "participantRange" | "position" | "price" | "recurrenceType" | "recurringInterval" | "requestToBook" | "skills" | "title" | "updated_at" | "%future added value";
export type coachLesson_select_column_coachLesson_aggregate_bool_exp_bool_and_arguments_columns = "isBundle" | "isHidden" | "isVirtual" | "requestToBook" | "%future added value";
export type coachLesson_select_column_coachLesson_aggregate_bool_exp_bool_or_arguments_columns = "isBundle" | "isHidden" | "isVirtual" | "requestToBook" | "%future added value";
export type coachLesson_update_column = "abilityLevelRange" | "coachCuid" | "created_at" | "cuid" | "description" | "disciplineNames" | "duration" | "highlightedPosition" | "id" | "isBundle" | "isHidden" | "isVirtual" | "lessonsPerBundle" | "participantRange" | "position" | "price" | "recurrenceType" | "recurringInterval" | "requestToBook" | "skills" | "title" | "updated_at" | "%future added value";
export type coachLocation_constraint = "coachLocation_cuid_key" | "coachLocation_pkey" | "%future added value";
export type coachLocation_select_column = "addressLine1" | "city" | "coachCuid" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "googlePlaceId" | "id" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type coachLocation_update_column = "addressLine1" | "city" | "coachCuid" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "googlePlaceId" | "id" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type coachModerationFlag_constraint = "coachModerationFlag_cuid_key" | "coachModerationFlags_pkey" | "%future added value";
export type coachModerationFlag_select_column = "coachCuid" | "created_at" | "cuid" | "id" | "isReportOpen" | "reason" | "reasonDetails" | "reportPriority" | "updated_at" | "userId" | "%future added value";
export type coachModerationFlag_select_column_coachModerationFlag_aggregate_bool_exp_bool_and_arguments_columns = "isReportOpen" | "%future added value";
export type coachModerationFlag_select_column_coachModerationFlag_aggregate_bool_exp_bool_or_arguments_columns = "isReportOpen" | "%future added value";
export type coachModerationFlag_update_column = "coachCuid" | "created_at" | "cuid" | "id" | "isReportOpen" | "reason" | "reasonDetails" | "reportPriority" | "updated_at" | "userId" | "%future added value";
export type coachStudent_constraint = "coachStudent_cuid_key" | "coachStudent_pkey" | "%future added value";
export type coachStudent_select_column = "ageRange" | "coachCuid" | "created_at" | "cuid" | "focus" | "genders" | "id" | "languages" | "updated_at" | "%future added value";
export type coachStudent_update_column = "ageRange" | "coachCuid" | "created_at" | "cuid" | "focus" | "genders" | "id" | "languages" | "updated_at" | "%future added value";
export type coachUnavailableDate_constraint = "coachUnavailability_pkey" | "coachUnavailableDate_cuid_key" | "%future added value";
export type coachUnavailableDate_select_column = "coachCuid" | "created_at" | "cuid" | "date" | "endSeconds" | "id" | "isAllDay" | "startSeconds" | "updated_at" | "%future added value";
export type coachUnavailableDate_select_column_coachUnavailableDate_aggregate_bool_exp_bool_and_arguments_columns = "isAllDay" | "%future added value";
export type coachUnavailableDate_select_column_coachUnavailableDate_aggregate_bool_exp_bool_or_arguments_columns = "isAllDay" | "%future added value";
export type coachUnavailableDate_update_column = "coachCuid" | "created_at" | "cuid" | "date" | "endSeconds" | "id" | "isAllDay" | "startSeconds" | "updated_at" | "%future added value";
export type coach_constraint = "coach_cuid_key" | "coach_userId_key" | "userCoach_pkey" | "%future added value";
export type coach_select_column = "about" | "addRacingExp" | "allowRequests" | "calendarId" | "coachingRadius" | "coachingSince" | "coachingVibe" | "created_at" | "cuid" | "currencyCode" | "experience" | "id" | "introVideoThumbnailUrl" | "introVideoUrl" | "isCPRCertified" | "isFirstAidCertified" | "isSuperCoach" | "offerCustomLesson" | "openToTravel" | "organizerCuidToDisplay" | "racingExp" | "showProfileHelpBanner" | "syncLocationToProfile" | "tagline" | "updated_at" | "userId" | "yearStarted" | "%future added value";
export type coach_select_column_coach_aggregate_bool_exp_bool_and_arguments_columns = "addRacingExp" | "allowRequests" | "isCPRCertified" | "isFirstAidCertified" | "isSuperCoach" | "offerCustomLesson" | "openToTravel" | "showProfileHelpBanner" | "syncLocationToProfile" | "%future added value";
export type coach_select_column_coach_aggregate_bool_exp_bool_or_arguments_columns = "addRacingExp" | "allowRequests" | "isCPRCertified" | "isFirstAidCertified" | "isSuperCoach" | "offerCustomLesson" | "openToTravel" | "showProfileHelpBanner" | "syncLocationToProfile" | "%future added value";
export type coach_update_column = "about" | "addRacingExp" | "allowRequests" | "calendarId" | "coachingRadius" | "coachingSince" | "coachingVibe" | "created_at" | "cuid" | "currencyCode" | "experience" | "id" | "introVideoThumbnailUrl" | "introVideoUrl" | "isCPRCertified" | "isFirstAidCertified" | "isSuperCoach" | "offerCustomLesson" | "openToTravel" | "organizerCuidToDisplay" | "racingExp" | "showProfileHelpBanner" | "syncLocationToProfile" | "tagline" | "updated_at" | "userId" | "yearStarted" | "%future added value";
export type course_constraint = "course_cuid_key" | "course_id_key" | "course_pkey" | "%future added value";
export type course_select_column = "city" | "country" | "courseLength" | "courseLengthSecondary" | "courseLengthType" | "courseLengthTypeSecondary" | "created_at" | "cuid" | "description" | "difficultyName" | "distance" | "elevationHigh" | "elevationLow" | "elevationUnit" | "endLat" | "endLng" | "eventCuid" | "eventDisciplineCuid" | "fullName" | "geoJson" | "id" | "maxGrade" | "minGrade" | "name" | "platform" | "platformId" | "platformUrl" | "startLat" | "startLng" | "state" | "totalElevationGain" | "totalElevationLoss" | "updated_at" | "%future added value";
export type course_update_column = "city" | "country" | "courseLength" | "courseLengthSecondary" | "courseLengthType" | "courseLengthTypeSecondary" | "created_at" | "cuid" | "description" | "difficultyName" | "distance" | "elevationHigh" | "elevationLow" | "elevationUnit" | "endLat" | "endLng" | "eventCuid" | "eventDisciplineCuid" | "fullName" | "geoJson" | "id" | "maxGrade" | "minGrade" | "name" | "platform" | "platformId" | "platformUrl" | "startLat" | "startLng" | "state" | "totalElevationGain" | "totalElevationLoss" | "updated_at" | "%future added value";
export type creatorInvite_constraint = "creatorInvite_cuid_key" | "creatorInvite_pkey" | "%future added value";
export type creatorInvite_select_column = "acceptedAt" | "acceptedUserId" | "created_at" | "cuid" | "id" | "inviteEmail" | "inviterUserId" | "updated_at" | "%future added value";
export type creatorInvite_update_column = "acceptedAt" | "acceptedUserId" | "created_at" | "cuid" | "id" | "inviteEmail" | "inviterUserId" | "updated_at" | "%future added value";
export type eventAdditionalInfo_constraint = "eventAdditionalInfo_pkey" | "%future added value";
export type eventAdditionalInfo_select_column = "created_at" | "cuid" | "description" | "eventCuid" | "id" | "title" | "updated_at" | "%future added value";
export type eventAdditionalInfo_update_column = "created_at" | "cuid" | "description" | "eventCuid" | "id" | "title" | "updated_at" | "%future added value";
export type eventAmenity_constraint = "PK_raceAmenity" | "eventAmenity_cuid_key" | "eventAmenity_eventCuid_amenityCuid_key" | "eventAmenity_pkey" | "%future added value";
export type eventAmenity_select_column = "amenityCuid" | "cuid" | "eventCuid" | "id" | "%future added value";
export type eventAmenity_update_column = "amenityCuid" | "cuid" | "eventCuid" | "id" | "%future added value";
export type eventBasicWaiverCategory_constraint = "eventBasicWaiverCategory_cuid_key" | "eventBasicWaiverCategory_pkey" | "%future added value";
export type eventBasicWaiverCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventBasicWaiverCuid" | "id" | "updated_at" | "%future added value";
export type eventBasicWaiverCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventBasicWaiverCuid" | "id" | "updated_at" | "%future added value";
export type eventBasicWaiver_constraint = "eventBasicWaiver_cuid_key" | "eventBasicWaiver_pkey" | "%future added value";
export type eventBasicWaiver_select_column = "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "fileRef" | "id" | "name" | "updated_at" | "%future added value";
export type eventBasicWaiver_select_column_eventBasicWaiver_aggregate_bool_exp_bool_and_arguments_columns = "appliesToAllCategories" | "%future added value";
export type eventBasicWaiver_select_column_eventBasicWaiver_aggregate_bool_exp_bool_or_arguments_columns = "appliesToAllCategories" | "%future added value";
export type eventBasicWaiver_update_column = "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "fileRef" | "id" | "name" | "updated_at" | "%future added value";
export type eventChecklistItem_constraint = "eventChecklistItem_cuid_key" | "eventChecklistItem_pkey" | "%future added value";
export type eventChecklistItem_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "info" | "name" | "updated_at" | "%future added value";
export type eventChecklistItem_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "info" | "name" | "updated_at" | "%future added value";
export type eventClinicDayDuration_constraint = "eventClinicDuration_cuid_key" | "eventClinicDuration_pkey" | "%future added value";
export type eventClinicDayDuration_select_column = "created_at" | "cuid" | "dayNumber" | "duration" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventClinicDayDuration_update_column = "created_at" | "cuid" | "dayNumber" | "duration" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventCustomQuestionCategory_constraint = "eventCustomQuestionCategory_cuid_key" | "eventCustomQuestionCategory_pkey" | "%future added value";
export type eventCustomQuestionCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventCustomQuestionCuid" | "id" | "updated_at" | "%future added value";
export type eventCustomQuestionCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventCustomQuestionCuid" | "id" | "updated_at" | "%future added value";
export type eventCustomQuestionOption_constraint = "eventCustomQuestionOption_cuid_key" | "eventCustomQuestionOption_pkey" | "%future added value";
export type eventCustomQuestionOption_select_column = "created_at" | "cuid" | "eventCustomQuestionCuid" | "id" | "name" | "position" | "updated_at" | "%future added value";
export type eventCustomQuestionOption_update_column = "created_at" | "cuid" | "eventCustomQuestionCuid" | "id" | "name" | "position" | "updated_at" | "%future added value";
export type eventCustomQuestion_constraint = "eventCustomQuestion_cuid_key" | "eventCustomQuestion_pkey" | "%future added value";
export type eventCustomQuestion_select_column = "additionalInfo" | "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "id" | "internalPurpose" | "isRequired" | "position" | "questionType" | "title" | "updated_at" | "%future added value";
export type eventCustomQuestion_select_column_eventCustomQuestion_aggregate_bool_exp_bool_and_arguments_columns = "appliesToAllCategories" | "isRequired" | "%future added value";
export type eventCustomQuestion_select_column_eventCustomQuestion_aggregate_bool_exp_bool_or_arguments_columns = "appliesToAllCategories" | "isRequired" | "%future added value";
export type eventCustomQuestion_update_column = "additionalInfo" | "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "id" | "internalPurpose" | "isRequired" | "position" | "questionType" | "title" | "updated_at" | "%future added value";
export type eventDiscipline_constraint = "PK_raceDiscipline" | "eventDiscipline_cuid_key" | "eventDiscipline_id_key" | "eventDiscipline_pkey" | "%future added value";
export type eventDiscipline_select_column = "allowWaitlist" | "created_at" | "cuid" | "disciplineName" | "eventCuid" | "fieldLimit" | "id" | "updated_at" | "%future added value";
export type eventDiscipline_select_column_eventDiscipline_aggregate_bool_exp_bool_and_arguments_columns = "allowWaitlist" | "%future added value";
export type eventDiscipline_select_column_eventDiscipline_aggregate_bool_exp_bool_or_arguments_columns = "allowWaitlist" | "%future added value";
export type eventDiscipline_update_column = "allowWaitlist" | "created_at" | "cuid" | "disciplineName" | "eventCuid" | "fieldLimit" | "id" | "updated_at" | "%future added value";
export type eventImage_constraint = "eventImage_imagePublicId_key" | "eventImage_pkey" | "%future added value";
export type eventImage_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "imagePublicId" | "position" | "updated_at" | "%future added value";
export type eventImage_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "imagePublicId" | "position" | "updated_at" | "%future added value";
export type eventMerchandise_constraint = "eventMerchandise_pkey" | "%future added value";
export type eventMerchandise_select_column = "availableTo" | "created_at" | "cuid" | "eventCuid" | "merchandiseCuid" | "updated_at" | "visible" | "%future added value";
export type eventMerchandise_select_column_eventMerchandise_aggregate_bool_exp_bool_and_arguments_columns = "visible" | "%future added value";
export type eventMerchandise_select_column_eventMerchandise_aggregate_bool_exp_bool_or_arguments_columns = "visible" | "%future added value";
export type eventMerchandise_update_column = "availableTo" | "created_at" | "cuid" | "eventCuid" | "merchandiseCuid" | "updated_at" | "visible" | "%future added value";
export type eventMetadata_constraint = "eventMetadata_cuid_key" | "eventMetadata_pkey" | "%future added value";
export type eventMetadata_select_column = "created_at" | "cuid" | "deleted_at" | "id" | "isCategoriedEvent" | "name" | "numberOfDays" | "organizerCuid" | "slug" | "updated_at" | "%future added value";
export type eventMetadata_select_column_eventMetadata_aggregate_bool_exp_bool_and_arguments_columns = "isCategoriedEvent" | "%future added value";
export type eventMetadata_select_column_eventMetadata_aggregate_bool_exp_bool_or_arguments_columns = "isCategoriedEvent" | "%future added value";
export type eventMetadata_update_column = "created_at" | "cuid" | "deleted_at" | "id" | "isCategoriedEvent" | "name" | "numberOfDays" | "organizerCuid" | "slug" | "updated_at" | "%future added value";
export type eventModerationFlag_constraint = "eventModerationFlag_cuid_key" | "eventModerationFlag_pkey" | "%future added value";
export type eventModerationFlag_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "isReportOpen" | "reason" | "reasonDetails" | "reportPriority" | "updated_at" | "userId" | "%future added value";
export type eventModerationFlag_select_column_eventModerationFlag_aggregate_bool_exp_bool_and_arguments_columns = "isReportOpen" | "%future added value";
export type eventModerationFlag_select_column_eventModerationFlag_aggregate_bool_exp_bool_or_arguments_columns = "isReportOpen" | "%future added value";
export type eventModerationFlag_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "isReportOpen" | "reason" | "reasonDetails" | "reportPriority" | "updated_at" | "userId" | "%future added value";
export type eventPitSpaceAddOn_constraint = "eventPitSpaceAddOn_pkey" | "%future added value";
export type eventPitSpaceAddOn_select_column = "created_at" | "cuid" | "eventPitSpaceCuid" | "name" | "price" | "updated_at" | "%future added value";
export type eventPitSpaceAddOn_update_column = "created_at" | "cuid" | "eventPitSpaceCuid" | "name" | "price" | "updated_at" | "%future added value";
export type eventPitSpace_constraint = "eventPitSpace_pkey" | "%future added value";
export type eventPitSpace_select_column = "allowWaitlist" | "created_at" | "cuid" | "description" | "eventCuid" | "name" | "pitSpaceAccess" | "plotLength" | "plotLengthType" | "plotWidth" | "plotWidthType" | "position" | "price" | "requireDimensions" | "shouldContactForPrice" | "shouldUseContactSize" | "shouldUsePlotSize" | "shouldUseUnitSize" | "spotsAvailable" | "spotsReserved" | "unitSize" | "unitSizeType" | "updated_at" | "%future added value";
export type eventPitSpace_select_column_eventPitSpace_aggregate_bool_exp_bool_and_arguments_columns = "allowWaitlist" | "requireDimensions" | "shouldContactForPrice" | "shouldUseContactSize" | "shouldUsePlotSize" | "shouldUseUnitSize" | "%future added value";
export type eventPitSpace_select_column_eventPitSpace_aggregate_bool_exp_bool_or_arguments_columns = "allowWaitlist" | "requireDimensions" | "shouldContactForPrice" | "shouldUseContactSize" | "shouldUsePlotSize" | "shouldUseUnitSize" | "%future added value";
export type eventPitSpace_update_column = "allowWaitlist" | "created_at" | "cuid" | "description" | "eventCuid" | "name" | "pitSpaceAccess" | "plotLength" | "plotLengthType" | "plotWidth" | "plotWidthType" | "position" | "price" | "requireDimensions" | "shouldContactForPrice" | "shouldUseContactSize" | "shouldUsePlotSize" | "shouldUseUnitSize" | "spotsAvailable" | "spotsReserved" | "unitSize" | "unitSizeType" | "updated_at" | "%future added value";
export type eventPricingChange_constraint = "eventPricingChange_cuid_key" | "eventPricingChange_pkey" | "%future added value";
export type eventPricingChange_select_column = "amount" | "created_at" | "cuid" | "eventCuid" | "eventPricingDateCuid" | "eventPricingGroupCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingChange_update_column = "amount" | "created_at" | "cuid" | "eventCuid" | "eventPricingDateCuid" | "eventPricingGroupCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingDate_constraint = "eventPricingDate_cuid_key" | "eventPricingDate_pkey" | "%future added value";
export type eventPricingDate_select_column = "created_at" | "cuid" | "date" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingDate_update_column = "created_at" | "cuid" | "date" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingGroupCategory_constraint = "eventPricingGroupCategory_cuid_key" | "eventPricingGroupCategory_pkey" | "%future added value";
export type eventPricingGroupCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventPricingGroupCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingGroupCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventPricingGroupCuid" | "id" | "updated_at" | "%future added value";
export type eventPricingGroup_constraint = "eventPricingGroup_cuid_key" | "eventPricingGroup_pkey" | "%future added value";
export type eventPricingGroup_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "name" | "updated_at" | "%future added value";
export type eventPricingGroup_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "name" | "updated_at" | "%future added value";
export type eventPrivateLink_constraint = "eventPrivateLink_pkey" | "%future added value";
export type eventPrivateLink_select_column = "capacity" | "capacityUsed" | "created_at" | "cuid" | "endDate" | "eventCuid" | "eventId" | "link" | "paused" | "startDate" | "updated_at" | "%future added value";
export type eventPrivateLink_select_column_eventPrivateLink_aggregate_bool_exp_bool_and_arguments_columns = "paused" | "%future added value";
export type eventPrivateLink_select_column_eventPrivateLink_aggregate_bool_exp_bool_or_arguments_columns = "paused" | "%future added value";
export type eventPrivateLink_update_column = "capacity" | "capacityUsed" | "created_at" | "cuid" | "endDate" | "eventCuid" | "eventId" | "link" | "paused" | "startDate" | "updated_at" | "%future added value";
export type eventProgram_constraint = "eventProgram_cuid_key" | "eventProgram_pkey" | "%future added value";
export type eventProgram_select_column = "abilityLevelRange" | "ageRange" | "created_at" | "cuid" | "defaultPrice" | "eventCuid" | "focus" | "genders" | "id" | "overview" | "skills" | "updated_at" | "%future added value";
export type eventProgram_update_column = "abilityLevelRange" | "ageRange" | "created_at" | "cuid" | "defaultPrice" | "eventCuid" | "focus" | "genders" | "id" | "overview" | "skills" | "updated_at" | "%future added value";
export type eventPromoCode_constraint = "eventPromoCode_code_eventCuid_key" | "eventPromoCode_cuid_key" | "eventPromoCode_pkey" | "%future added value";
export type eventPromoCode_select_column = "amountOff" | "code" | "codesAvailable" | "created_at" | "cuid" | "description" | "eventCuid" | "id" | "isInactive" | "percentOff" | "updated_at" | "validFrom" | "validTo" | "%future added value";
export type eventPromoCode_select_column_eventPromoCode_aggregate_bool_exp_bool_and_arguments_columns = "isInactive" | "%future added value";
export type eventPromoCode_select_column_eventPromoCode_aggregate_bool_exp_bool_or_arguments_columns = "isInactive" | "%future added value";
export type eventPromoCode_update_column = "amountOff" | "code" | "codesAvailable" | "created_at" | "cuid" | "description" | "eventCuid" | "id" | "isInactive" | "percentOff" | "updated_at" | "validFrom" | "validTo" | "%future added value";
export type eventQuestionAnswer_constraint = "eventQuestionAnswer_cuid_key" | "eventQuestionAnswer_pkey" | "eventQuestionAnswer_userId_eventQuestionCuid_key" | "%future added value";
export type eventQuestionAnswer_select_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventQuestionCuid" | "id" | "isOrganizer" | "updated_at" | "userId" | "%future added value";
export type eventQuestionAnswer_select_column_eventQuestionAnswer_aggregate_bool_exp_bool_and_arguments_columns = "isOrganizer" | "%future added value";
export type eventQuestionAnswer_select_column_eventQuestionAnswer_aggregate_bool_exp_bool_or_arguments_columns = "isOrganizer" | "%future added value";
export type eventQuestionAnswer_update_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventQuestionCuid" | "id" | "isOrganizer" | "updated_at" | "userId" | "%future added value";
export type eventQuestion_constraint = "eventQuestion_cuid_key" | "eventQuestion_pkey" | "eventQuestion_userId_eventCuid_key" | "%future added value";
export type eventQuestion_select_column = "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventCuid" | "id" | "title" | "updated_at" | "userId" | "%future added value";
export type eventQuestion_update_column = "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventCuid" | "id" | "title" | "updated_at" | "userId" | "%future added value";
export type eventSanctionCategory_constraint = "eventSanctionCategory_cuid_key" | "eventSanctionCategory_pkey" | "%future added value";
export type eventSanctionCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventSanctionCuid" | "id" | "updated_at" | "%future added value";
export type eventSanctionCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventSanctionCuid" | "id" | "updated_at" | "%future added value";
export type eventSanction_constraint = "eventSanction_id_key" | "eventSanction_pkey" | "%future added value";
export type eventSanction_select_column = "allowOneDayLicense" | "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "excludedWaiverCuids" | "id" | "isLicenseRequired" | "oneDayFee" | "otherSanctionName" | "permit" | "sanctionCuid" | "updated_at" | "%future added value";
export type eventSanction_select_column_eventSanction_aggregate_bool_exp_bool_and_arguments_columns = "allowOneDayLicense" | "appliesToAllCategories" | "isLicenseRequired" | "%future added value";
export type eventSanction_select_column_eventSanction_aggregate_bool_exp_bool_or_arguments_columns = "allowOneDayLicense" | "appliesToAllCategories" | "isLicenseRequired" | "%future added value";
export type eventSanction_update_column = "allowOneDayLicense" | "appliesToAllCategories" | "created_at" | "cuid" | "eventCuid" | "excludedWaiverCuids" | "id" | "isLicenseRequired" | "oneDayFee" | "otherSanctionName" | "permit" | "sanctionCuid" | "updated_at" | "%future added value";
export type eventScheduleCategory_constraint = "eventScheduleCategories_cuid_key" | "eventScheduleCategories_pkey" | "%future added value";
export type eventScheduleCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventScheduleCuid" | "id" | "updated_at" | "%future added value";
export type eventScheduleCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventScheduleCuid" | "id" | "updated_at" | "%future added value";
export type eventScheduleDate_constraint = "eventScheduleDate_date_eventCuid_key" | "eventScheduleDate_pkey" | "%future added value";
export type eventScheduleDate_select_column = "created_at" | "cuid" | "date" | "dayNumber" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventScheduleDate_update_column = "created_at" | "cuid" | "date" | "dayNumber" | "eventCuid" | "id" | "updated_at" | "%future added value";
export type eventScheduleTypeCustom_constraint = "eventScheduleType_pkey" | "%future added value";
export type eventScheduleTypeCustom_select_column = "color" | "created_at" | "cuid" | "eventCuid" | "id" | "isPreset" | "type" | "updated_at" | "%future added value";
export type eventScheduleTypeCustom_select_column_eventScheduleTypeCustom_aggregate_bool_exp_bool_and_arguments_columns = "isPreset" | "%future added value";
export type eventScheduleTypeCustom_select_column_eventScheduleTypeCustom_aggregate_bool_exp_bool_or_arguments_columns = "isPreset" | "%future added value";
export type eventScheduleTypeCustom_update_column = "color" | "created_at" | "cuid" | "eventCuid" | "id" | "isPreset" | "type" | "updated_at" | "%future added value";
export type eventScheduleTypePreset_constraint = "eventScheduleTypePreset_pkey" | "%future added value";
export type eventScheduleTypePreset_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "isPreset" | "scheduleTypePresetCuid" | "updated_at" | "%future added value";
export type eventScheduleTypePreset_select_column_eventScheduleTypePreset_aggregate_bool_exp_bool_and_arguments_columns = "isPreset" | "%future added value";
export type eventScheduleTypePreset_select_column_eventScheduleTypePreset_aggregate_bool_exp_bool_or_arguments_columns = "isPreset" | "%future added value";
export type eventScheduleTypePreset_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "isPreset" | "scheduleTypePresetCuid" | "updated_at" | "%future added value";
export type eventSchedule_constraint = "eventSchedule_pkey" | "%future added value";
export type eventSchedule_select_column = "addedInternally" | "appliesToAllCategories" | "created_at" | "cuid" | "description" | "endTime" | "eventCuid" | "eventScheduleDateCuid" | "eventScheduleTypeCustomCuid" | "eventScheduleTypePresetCuid" | "id" | "startTime" | "title" | "updated_at" | "%future added value";
export type eventSchedule_select_column_eventSchedule_aggregate_bool_exp_bool_and_arguments_columns = "addedInternally" | "appliesToAllCategories" | "%future added value";
export type eventSchedule_select_column_eventSchedule_aggregate_bool_exp_bool_or_arguments_columns = "addedInternally" | "appliesToAllCategories" | "%future added value";
export type eventSchedule_update_column = "addedInternally" | "appliesToAllCategories" | "created_at" | "cuid" | "description" | "endTime" | "eventCuid" | "eventScheduleDateCuid" | "eventScheduleTypeCustomCuid" | "eventScheduleTypePresetCuid" | "id" | "startTime" | "title" | "updated_at" | "%future added value";
export type eventSeriesCategory_constraint = "eventSeriesCategory_cuid_key" | "eventSeriesCategory_pkey" | "%future added value";
export type eventSeriesCategory_select_column = "categoryCuid" | "created_at" | "cuid" | "eventSeriesCuid" | "id" | "updated_at" | "%future added value";
export type eventSeriesCategory_update_column = "categoryCuid" | "created_at" | "cuid" | "eventSeriesCuid" | "id" | "updated_at" | "%future added value";
export type eventSeries_constraint = "eventSeries_cuid_key" | "eventSeries_id_key" | "eventSeries_pkey" | "%future added value";
export type eventSeries_select_column = "appliesToAllCategories" | "created_at" | "cuid" | "deleted_at" | "eventCuid" | "id" | "roundNumber" | "seriesCuid" | "updated_at" | "%future added value";
export type eventSeries_select_column_eventSeries_aggregate_bool_exp_bool_and_arguments_columns = "appliesToAllCategories" | "%future added value";
export type eventSeries_select_column_eventSeries_aggregate_bool_exp_bool_or_arguments_columns = "appliesToAllCategories" | "%future added value";
export type eventSeries_update_column = "appliesToAllCategories" | "created_at" | "cuid" | "deleted_at" | "eventCuid" | "id" | "roundNumber" | "seriesCuid" | "updated_at" | "%future added value";
export type eventSponsor_constraint = "eventSponsor_cuid_key" | "eventSponsor_pkey" | "eventSponsor_position_tierName_eventCuid_cuid_key" | "%future added value";
export type eventSponsor_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "position" | "sponsorCuid" | "tierName" | "updated_at" | "%future added value";
export type eventSponsor_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "position" | "sponsorCuid" | "tierName" | "updated_at" | "%future added value";
export type eventTicket_constraint = "eventTicket_pkey" | "%future added value";
export type eventTicket_select_column = "allowWaitlist" | "created_at" | "cuid" | "deleted_at" | "description" | "eventCuid" | "id" | "name" | "price" | "ticketCuid" | "ticketsAvailable" | "ticketsReserved" | "updated_at" | "visible" | "%future added value";
export type eventTicket_select_column_eventTicket_aggregate_bool_exp_bool_and_arguments_columns = "allowWaitlist" | "visible" | "%future added value";
export type eventTicket_select_column_eventTicket_aggregate_bool_exp_bool_or_arguments_columns = "allowWaitlist" | "visible" | "%future added value";
export type eventTicket_update_column = "allowWaitlist" | "created_at" | "cuid" | "deleted_at" | "description" | "eventCuid" | "id" | "name" | "price" | "ticketCuid" | "ticketsAvailable" | "ticketsReserved" | "updated_at" | "visible" | "%future added value";
export type eventUpdateComment_constraint = "eventUpdateComment_cuid_key" | "eventUpdateComment_eventUpdateCuid_userId_key" | "eventUpdateComment_pkey" | "%future added value";
export type eventUpdateComment_select_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventUpdateCuid" | "id" | "isOrganizer" | "updated_at" | "userId" | "%future added value";
export type eventUpdateComment_select_column_eventUpdateComment_aggregate_bool_exp_bool_and_arguments_columns = "isOrganizer" | "%future added value";
export type eventUpdateComment_select_column_eventUpdateComment_aggregate_bool_exp_bool_or_arguments_columns = "isOrganizer" | "%future added value";
export type eventUpdateComment_update_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventUpdateCuid" | "id" | "isOrganizer" | "updated_at" | "userId" | "%future added value";
export type eventUpdate_constraint = "eventUpdate_cuid_key" | "eventUpdate_pkey" | "%future added value";
export type eventUpdate_select_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventCuid" | "id" | "title" | "updated_at" | "userId" | "%future added value";
export type eventUpdate_update_column = "body" | "created_at" | "cuid" | "deletedByUserId" | "deleted_at" | "eventCuid" | "id" | "title" | "updated_at" | "userId" | "%future added value";
export type eventVenue_constraint = "eventVenue_cuid_key" | "eventVenue_id_key" | "eventVenue_pkey" | "eventVenue_venueCuid_key" | "%future added value";
export type eventVenue_select_column = "additionalInfo" | "campingDescription" | "created_at" | "cuid" | "eventCuid" | "id" | "meetupInstructions" | "parkingInfo" | "updated_at" | "venueCuid" | "%future added value";
export type eventVenue_update_column = "additionalInfo" | "campingDescription" | "created_at" | "cuid" | "eventCuid" | "id" | "meetupInstructions" | "parkingInfo" | "updated_at" | "venueCuid" | "%future added value";
export type eventVibe_constraint = "eventVibe_cuid_key" | "eventVibe_pkey" | "%future added value";
export type eventVibe_select_column = "abilityLevelRange" | "averageSpeedRange" | "created_at" | "cuid" | "eventCuid" | "genders" | "hasAverageSpeed" | "id" | "intensityRange" | "moreInfo" | "rideType" | "ridingTimePerDayRange" | "speedType" | "updated_at" | "%future added value";
export type eventVibe_select_column_eventVibe_aggregate_bool_exp_bool_and_arguments_columns = "hasAverageSpeed" | "%future added value";
export type eventVibe_select_column_eventVibe_aggregate_bool_exp_bool_or_arguments_columns = "hasAverageSpeed" | "%future added value";
export type eventVibe_update_column = "abilityLevelRange" | "averageSpeedRange" | "created_at" | "cuid" | "eventCuid" | "genders" | "hasAverageSpeed" | "id" | "intensityRange" | "moreInfo" | "rideType" | "ridingTimePerDayRange" | "speedType" | "updated_at" | "%future added value";
export type eventVirtualVenue_constraint = "eventVirtualVenue_cuid_key" | "eventVirtualVenue_pkey" | "%future added value";
export type eventVirtualVenue_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "timezone" | "updated_at" | "%future added value";
export type eventVirtualVenue_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "timezone" | "updated_at" | "%future added value";
export type eventVolunteerJobShiftTask_constraint = "eventVolunteerJobShiftTask_pkey" | "%future added value";
export type eventVolunteerJobShiftTask_select_column = "created_at" | "cuid" | "eventVolunteerJobShiftCuid" | "eventVolunteerJobTaskCuid" | "id" | "updated_at" | "%future added value";
export type eventVolunteerJobShiftTask_update_column = "created_at" | "cuid" | "eventVolunteerJobShiftCuid" | "eventVolunteerJobTaskCuid" | "id" | "updated_at" | "%future added value";
export type eventVolunteerJobShift_constraint = "eventVolunteerJobShift_pkey" | "%future added value";
export type eventVolunteerJobShift_select_column = "allowWaitlist" | "created_at" | "cuid" | "endDate" | "eventVolunteerJobCuid" | "id" | "name" | "shiftAccess" | "spotsAvailable" | "spotsReserved" | "startDate" | "updated_at" | "%future added value";
export type eventVolunteerJobShift_select_column_eventVolunteerJobShift_aggregate_bool_exp_bool_and_arguments_columns = "allowWaitlist" | "%future added value";
export type eventVolunteerJobShift_select_column_eventVolunteerJobShift_aggregate_bool_exp_bool_or_arguments_columns = "allowWaitlist" | "%future added value";
export type eventVolunteerJobShift_update_column = "allowWaitlist" | "created_at" | "cuid" | "endDate" | "eventVolunteerJobCuid" | "id" | "name" | "shiftAccess" | "spotsAvailable" | "spotsReserved" | "startDate" | "updated_at" | "%future added value";
export type eventVolunteerJobTask_constraint = "eventVolunteerJobTask_pkey" | "%future added value";
export type eventVolunteerJobTask_select_column = "created_at" | "cuid" | "description" | "eventVolunteerJobCuid" | "id" | "updated_at" | "volunteerTaskDifficultyCuid" | "volunteerTaskPriorityCuid" | "%future added value";
export type eventVolunteerJobTask_update_column = "created_at" | "cuid" | "description" | "eventVolunteerJobCuid" | "id" | "updated_at" | "volunteerTaskDifficultyCuid" | "volunteerTaskPriorityCuid" | "%future added value";
export type eventVolunteerJob_constraint = "eventVolunteerJob_pkey" | "%future added value";
export type eventVolunteerJob_select_column = "created_at" | "cuid" | "deleted_at" | "description" | "eventCuid" | "id" | "locationInfo" | "title" | "updated_at" | "%future added value";
export type eventVolunteerJob_update_column = "created_at" | "cuid" | "deleted_at" | "description" | "eventCuid" | "id" | "locationInfo" | "title" | "updated_at" | "%future added value";
export type eventWaiverType_constraint = "eventWaiverType_pkey" | "%future added value";
export type eventWaiverType_enum = "EVENT_SANCTION" | "GENERAL" | "%future added value";
export type eventWaiverType_update_column = "description" | "type" | "%future added value";
export type eventWaiver_constraint = "eventWaiver_pkey" | "%future added value";
export type eventWaiver_select_column = "eventCuid" | "eventSanctionCuid" | "type" | "waiverCuid" | "%future added value";
export type eventWaiver_update_column = "eventCuid" | "eventSanctionCuid" | "type" | "waiverCuid" | "%future added value";
export type event_constraint = "PK_race" | "event_cuid_key" | "event_pkey" | "event_slug_organizerCuid_key" | "%future added value";
export type event_select_column = "activity" | "additionalContactInfo" | "canceledAt" | "cancellationReason" | "completedSections" | "created_at" | "cuid" | "currencyCode" | "deleted_at" | "description" | "email" | "endDate" | "eventAccess" | "eventMetadataCuid" | "eventWebsite" | "hasDynamicPricing" | "hasGeneralWaivers" | "hasMerchandise" | "hasVolunteers" | "id" | "isCourseEvent" | "isEventCreationComplete" | "isRegistrationPaused" | "isSanctionedEvent" | "isSeriesEvent" | "isSponsoredEvent" | "isTicketedEvent" | "isVirtualEvent" | "lastSavedAt" | "name" | "nonCategoriedRegDeadline" | "occurrenceLabel" | "organizerCuid" | "phone" | "publishedAt" | "refundInfo" | "regCloseDate" | "regOpenDate" | "selfEditEndDate" | "slug" | "spotsAvailable" | "startDate" | "updated_at" | "%future added value";
export type event_select_column_event_aggregate_bool_exp_bool_and_arguments_columns = "hasDynamicPricing" | "hasGeneralWaivers" | "hasMerchandise" | "hasVolunteers" | "isCourseEvent" | "isEventCreationComplete" | "isRegistrationPaused" | "isSanctionedEvent" | "isSeriesEvent" | "isSponsoredEvent" | "isTicketedEvent" | "isVirtualEvent" | "%future added value";
export type event_select_column_event_aggregate_bool_exp_bool_or_arguments_columns = "hasDynamicPricing" | "hasGeneralWaivers" | "hasMerchandise" | "hasVolunteers" | "isCourseEvent" | "isEventCreationComplete" | "isRegistrationPaused" | "isSanctionedEvent" | "isSeriesEvent" | "isSponsoredEvent" | "isTicketedEvent" | "isVirtualEvent" | "%future added value";
export type event_update_column = "activity" | "additionalContactInfo" | "canceledAt" | "cancellationReason" | "completedSections" | "created_at" | "cuid" | "currencyCode" | "deleted_at" | "description" | "email" | "endDate" | "eventAccess" | "eventMetadataCuid" | "eventWebsite" | "hasDynamicPricing" | "hasGeneralWaivers" | "hasMerchandise" | "hasVolunteers" | "id" | "isCourseEvent" | "isEventCreationComplete" | "isRegistrationPaused" | "isSanctionedEvent" | "isSeriesEvent" | "isSponsoredEvent" | "isTicketedEvent" | "isVirtualEvent" | "lastSavedAt" | "name" | "nonCategoriedRegDeadline" | "occurrenceLabel" | "organizerCuid" | "phone" | "publishedAt" | "refundInfo" | "regCloseDate" | "regOpenDate" | "selfEditEndDate" | "slug" | "spotsAvailable" | "startDate" | "updated_at" | "%future added value";
export type imageOnlineCourse_constraint = "imageOnlineCourse_pkey" | "imageOnlineCourse_publicId_key" | "imageOnlineCourse_relativeUrl_key" | "imageOnlineCourse_version_key" | "%future added value";
export type imageOnlineCourse_select_column = "bytes" | "colors" | "created_at" | "format" | "height" | "id" | "onlineCourseCuid" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "version" | "width" | "%future added value";
export type imageOnlineCourse_update_column = "bytes" | "colors" | "created_at" | "format" | "height" | "id" | "onlineCourseCuid" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "version" | "width" | "%future added value";
export type imageUser_constraint = "imageUser_pkey" | "imageUser_publicId_key" | "imageUser_relativeUrl_key" | "imageUser_url_key" | "%future added value";
export type imageUser_select_column = "bytes" | "colors" | "created_at" | "format" | "height" | "id" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "userId" | "version" | "width" | "%future added value";
export type imageUser_update_column = "bytes" | "colors" | "created_at" | "format" | "height" | "id" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "userId" | "version" | "width" | "%future added value";
export type image_constraint = "image_imageUrl_key" | "image_pkey" | "image_publicId_key" | "image_relativeFile_key" | "%future added value";
export type image_select_column = "bytes" | "colors" | "created_at" | "format" | "height" | "organizerCuid" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "version" | "width" | "%future added value";
export type image_update_column = "bytes" | "colors" | "created_at" | "format" | "height" | "organizerCuid" | "originalFilename" | "publicId" | "relativeUrl" | "resourceType" | "updated_at" | "url" | "version" | "width" | "%future added value";
export type lessonLocation_constraint = "lessonLocation_cuid_key" | "lessonLocation_googlePlaceId_coachCuid_key" | "lessonLocation_pkey" | "%future added value";
export type lessonLocation_select_column = "address" | "addressLine1" | "addressLine2" | "city" | "coachCuid" | "country" | "countryCode" | "created_at" | "cuid" | "deleted_at" | "googlePlaceId" | "id" | "isDefault" | "label" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type lessonLocation_select_column_lessonLocation_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type lessonLocation_select_column_lessonLocation_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type lessonLocation_update_column = "address" | "addressLine1" | "addressLine2" | "city" | "coachCuid" | "country" | "countryCode" | "created_at" | "cuid" | "deleted_at" | "googlePlaceId" | "id" | "isDefault" | "label" | "lat" | "lng" | "state" | "timezone" | "updated_at" | "zipCode" | "%future added value";
export type merchandiseDeliveryType_constraint = "merchandiseDeliveryType_pkey" | "%future added value";
export type merchandiseDeliveryType_enum = "OnSite" | "Shipped" | "Virtual" | "%future added value";
export type merchandiseDeliveryType_update_column = "name" | "%future added value";
export type merchandiseImage_constraint = "merchandiseImage_pkey" | "%future added value";
export type merchandiseImage_select_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "merchandiseCuid" | "updated_at" | "%future added value";
export type merchandiseImage_select_column_merchandiseImage_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type merchandiseImage_select_column_merchandiseImage_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type merchandiseImage_update_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "merchandiseCuid" | "updated_at" | "%future added value";
export type merchandiseVariantOptionValue_constraint = "merchandiseVariantOptionValue_pkey" | "%future added value";
export type merchandiseVariantOptionValue_select_column = "created_at" | "cuid" | "id" | "merchandiseVariantOptionCuid" | "updated_at" | "value" | "%future added value";
export type merchandiseVariantOptionValue_update_column = "created_at" | "cuid" | "id" | "merchandiseVariantOptionCuid" | "updated_at" | "value" | "%future added value";
export type merchandiseVariantOption_constraint = "merchandiseVariantOptions_pkey" | "%future added value";
export type merchandiseVariantOption_select_column = "created_at" | "cuid" | "id" | "merchandiseCuid" | "optionName" | "updated_at" | "%future added value";
export type merchandiseVariantOption_update_column = "created_at" | "cuid" | "id" | "merchandiseCuid" | "optionName" | "updated_at" | "%future added value";
export type merchandiseVariant_constraint = "merchandiseVariant_pkey" | "%future added value";
export type merchandiseVariant_select_column = "created_at" | "cuid" | "id" | "imagePublicId" | "merchandiseCuid" | "optionCuidValueCuid" | "price" | "stock" | "updated_at" | "%future added value";
export type merchandiseVariant_update_column = "created_at" | "cuid" | "id" | "imagePublicId" | "merchandiseCuid" | "optionCuidValueCuid" | "price" | "stock" | "updated_at" | "%future added value";
export type merchandise_constraint = "merchandise_pkey" | "%future added value";
export type merchandise_select_column = "created_at" | "cuid" | "deleted_at" | "deliveryType" | "description" | "organizerCuid" | "price" | "stock" | "title" | "updated_at" | "vendorId" | "%future added value";
export type merchandise_update_column = "created_at" | "cuid" | "deleted_at" | "deliveryType" | "description" | "organizerCuid" | "price" | "stock" | "title" | "updated_at" | "vendorId" | "%future added value";
export type onlineCourseOwner_constraint = "onlineCourseOwner_pkey" | "%future added value";
export type onlineCourseOwner_enum = "COACH" | "ORGANIZER" | "%future added value";
export type onlineCourseOwner_update_column = "description" | "owner" | "%future added value";
export type onlineCourseRevision_constraint = "onlineCourseRevision_cuid_key" | "onlineCourseRevision_onlineCourseCuid_revision_key" | "onlineCourseRevision_pkey" | "%future added value";
export type onlineCourseRevision_select_column = "contents" | "created_at" | "cuid" | "currencyCode" | "deleted_at" | "id" | "onlineCourseCuid" | "onlineCourseDefinition" | "overviewContent" | "price" | "revision" | "tagline" | "title" | "updated_at" | "%future added value";
export type onlineCourseRevision_update_column = "contents" | "created_at" | "cuid" | "currencyCode" | "deleted_at" | "id" | "onlineCourseCuid" | "onlineCourseDefinition" | "overviewContent" | "price" | "revision" | "tagline" | "title" | "updated_at" | "%future added value";
export type onlineCourse_constraint = "onlineCourse_cuid_key" | "onlineCourse_pkey" | "%future added value";
export type onlineCourse_select_column = "created_at" | "cuid" | "deleted_at" | "id" | "latestRevisionNum" | "ownerCuid" | "ownerType" | "publishedAt" | "updated_at" | "%future added value";
export type onlineCourse_update_column = "created_at" | "cuid" | "deleted_at" | "id" | "latestRevisionNum" | "ownerCuid" | "ownerType" | "publishedAt" | "updated_at" | "%future added value";
export type organizerChannel_constraint = "organizerChannel_channelId_key" | "organizerChannel_cuid_key" | "organizerChannel_pkey" | "%future added value";
export type organizerChannel_select_column = "channelId" | "created_at" | "cuid" | "eventCuid" | "id" | "organizerCuid" | "updated_at" | "userId" | "%future added value";
export type organizerChannel_update_column = "channelId" | "created_at" | "cuid" | "eventCuid" | "id" | "organizerCuid" | "updated_at" | "userId" | "%future added value";
export type organizerInvite_constraint = "organizerInvite_cuid_key" | "organizerInvite_organizerCuid_inviteeUserId_role_key" | "organizerInvite_pkey" | "%future added value";
export type organizerInvite_select_column = "created_at" | "cuid" | "id" | "inviteStatus" | "inviteeUserId" | "inviterUserId" | "organizerCuid" | "role" | "updated_at" | "%future added value";
export type organizerInvite_update_column = "created_at" | "cuid" | "id" | "inviteStatus" | "inviteeUserId" | "inviterUserId" | "organizerCuid" | "role" | "updated_at" | "%future added value";
export type organizer_constraint = "PK_organizer" | "organizer_cuid_key" | "organizer_pkey" | "organizer_slug_key" | "%future added value";
export type organizer_select_column = "about" | "created_at" | "cuid" | "defaultCurrencyCode" | "deleted_at" | "id" | "imagePublicId" | "name" | "showOnboarding" | "slug" | "stripeConnectId" | "updated_at" | "website" | "%future added value";
export type organizer_select_column_organizer_aggregate_bool_exp_bool_and_arguments_columns = "showOnboarding" | "%future added value";
export type organizer_select_column_organizer_aggregate_bool_exp_bool_or_arguments_columns = "showOnboarding" | "%future added value";
export type organizer_update_column = "about" | "created_at" | "cuid" | "defaultCurrencyCode" | "deleted_at" | "id" | "imagePublicId" | "name" | "showOnboarding" | "slug" | "stripeConnectId" | "updated_at" | "website" | "%future added value";
export type registrationActivity_constraint = "registrationActivity_eventCuid_referenceNo_key" | "registrationActivity_pkey" | "%future added value";
export type registrationActivity_update_column = "created_at" | "eventCuid" | "id" | "log" | "notes" | "referenceNo" | "updated_at" | "%future added value";
export type registrationAdditionalPayment_constraint = "registrationAdditionalPayment_cuid_key" | "registrationAdditionalPayment_pkey" | "%future added value";
export type registrationAdditionalPayment_select_column = "created_at" | "cuid" | "description" | "id" | "paymentType" | "registrationCuid" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "%future added value";
export type registrationAdditionalPayment_update_column = "created_at" | "cuid" | "description" | "id" | "paymentType" | "registrationCuid" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "%future added value";
export type registrationCategory_constraint = "registrationCategory_pkey" | "registrationCategory_registrationCuid_categoryCuid_key" | "%future added value";
export type registrationCategory_select_column = "categoryCuid" | "id" | "registrationCuid" | "%future added value";
export type registrationCategory_update_column = "categoryCuid" | "id" | "registrationCuid" | "%future added value";
export type registrationFor_constraint = "registrationFor_pkey" | "%future added value";
export type registrationFor_enum = "minor" | "other_user" | "self" | "%future added value";
export type registrationFor_update_column = "for" | "%future added value";
export type registrationSeries_constraint = "registrationSeries_eventSeriesCuid_registrationCuid_key" | "registrationSeries_pkey" | "%future added value";
export type registrationSeries_select_column = "eventSeriesCuid" | "id" | "registrationCuid" | "%future added value";
export type registrationSeries_update_column = "eventSeriesCuid" | "id" | "registrationCuid" | "%future added value";
export type registrationStatus_constraint = "registrationStatus_pkey" | "%future added value";
export type registrationStatus_enum = "canceled" | "canceled_stale" | "complete" | "in_progress" | "%future added value";
export type registrationStatus_update_column = "status" | "%future added value";
export type registrationTicket_constraint = "registrationTicket_pkey" | "registrationTicket_registrationCuid_eventTicketCuid_key" | "%future added value";
export type registrationTicket_select_column = "eventTicketCuid" | "id" | "number" | "registrationCuid" | "%future added value";
export type registrationTicket_update_column = "eventTicketCuid" | "id" | "number" | "registrationCuid" | "%future added value";
export type registration_constraint = "PK_registration" | "registration_cuid_key" | "registration_pkey" | "%future added value";
export type registration_select_column = "address" | "addressCity" | "addressCountry" | "addressLine1" | "addressLine2" | "addressState" | "addressZip" | "clinicCuid" | "completedAt" | "contactName" | "contactPhone" | "contactRelationship" | "context" | "created_at" | "cuid" | "customQuestions" | "dob" | "email" | "eventCuid" | "firstName" | "for" | "id" | "isCheckedIn" | "lastName" | "lat" | "lng" | "payingUserId" | "phone" | "promoCode" | "referenceNo" | "rideCuid" | "sanctionLicenses" | "sponsors" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "userId" | "%future added value";
export type registration_select_column_registration_aggregate_bool_exp_bool_and_arguments_columns = "isCheckedIn" | "%future added value";
export type registration_select_column_registration_aggregate_bool_exp_bool_or_arguments_columns = "isCheckedIn" | "%future added value";
export type registration_update_column = "address" | "addressCity" | "addressCountry" | "addressLine1" | "addressLine2" | "addressState" | "addressZip" | "clinicCuid" | "completedAt" | "contactName" | "contactPhone" | "contactRelationship" | "context" | "created_at" | "cuid" | "customQuestions" | "dob" | "email" | "eventCuid" | "firstName" | "for" | "id" | "isCheckedIn" | "lastName" | "lat" | "lng" | "payingUserId" | "phone" | "promoCode" | "referenceNo" | "rideCuid" | "sanctionLicenses" | "sponsors" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "userId" | "%future added value";
export type reportClinicNoShow_constraint = "reportClinicNoShow_cuid_key" | "reportClinicNoShow_pkey" | "reportClinicNoShow_userId_reportedUserId_clinicDayCuid_key" | "%future added value";
export type reportClinicNoShow_select_column = "clinicDayCuid" | "created_at" | "cuid" | "id" | "reportedUserId" | "updated_at" | "userDistance" | "userId" | "userIsCoach" | "userLocation" | "%future added value";
export type reportClinicNoShow_select_column_reportClinicNoShow_aggregate_bool_exp_bool_and_arguments_columns = "userIsCoach" | "%future added value";
export type reportClinicNoShow_select_column_reportClinicNoShow_aggregate_bool_exp_bool_or_arguments_columns = "userIsCoach" | "%future added value";
export type reportClinicNoShow_update_column = "clinicDayCuid" | "created_at" | "cuid" | "id" | "reportedUserId" | "updated_at" | "userDistance" | "userId" | "userIsCoach" | "userLocation" | "%future added value";
export type reportLessonNoShow_constraint = "reportLessonNoShow_cuid_key" | "reportLessonNoShow_lessonDateCuid_userId_reportedUserId_key" | "reportLessonNoShow_pkey" | "%future added value";
export type reportLessonNoShow_select_column = "created_at" | "cuid" | "id" | "lessonDateCuid" | "reportedUserId" | "updated_at" | "userDistance" | "userId" | "userIsCoach" | "userLocation" | "%future added value";
export type reportLessonNoShow_select_column_reportLessonNoShow_aggregate_bool_exp_bool_and_arguments_columns = "userIsCoach" | "%future added value";
export type reportLessonNoShow_select_column_reportLessonNoShow_aggregate_bool_exp_bool_or_arguments_columns = "userIsCoach" | "%future added value";
export type reportLessonNoShow_update_column = "created_at" | "cuid" | "id" | "lessonDateCuid" | "reportedUserId" | "updated_at" | "userDistance" | "userId" | "userIsCoach" | "userLocation" | "%future added value";
export type reviewSubjectType_constraint = "reviewSubjectType_pkey" | "%future added value";
export type reviewSubjectType_enum = "clinic" | "coach" | "%future added value";
export type reviewSubjectType_update_column = "type" | "%future added value";
export type reviewTotals_constraint = "reviewTotals_pkey" | "reviewTotals_reviewSubjectCuid_key" | "%future added value";
export type reviewTotals_select_column = "count" | "created_at" | "id" | "recAverage" | "recPercentage" | "reviewSubjectCuid" | "reviewSubjectType" | "updated_at" | "%future added value";
export type reviewTotals_update_column = "count" | "created_at" | "id" | "recAverage" | "recPercentage" | "reviewSubjectCuid" | "reviewSubjectType" | "updated_at" | "%future added value";
export type review_constraint = "review_pkey" | "%future added value";
export type review_select_column = "created_at" | "id" | "review" | "reviewSubjectCuid" | "reviewSubjectInstanceCuid" | "reviewSubjectType" | "reviewerId" | "updated_at" | "%future added value";
export type review_update_column = "created_at" | "id" | "review" | "reviewSubjectCuid" | "reviewSubjectInstanceCuid" | "reviewSubjectType" | "reviewerId" | "updated_at" | "%future added value";
export type rideDay_constraint = "rideDay_cuid_key" | "rideDay_pkey" | "%future added value";
export type rideDay_select_column = "created_at" | "cuid" | "dayNumber" | "id" | "rideCuid" | "startTime" | "updated_at" | "%future added value";
export type rideDay_update_column = "created_at" | "cuid" | "dayNumber" | "id" | "rideCuid" | "startTime" | "updated_at" | "%future added value";
export type ride_constraint = "ride_cuid_key" | "ride_pkey" | "%future added value";
export type ride_select_column = "canceledAt" | "cancellationReason" | "created_at" | "cuid" | "dayOfContact" | "eventCuid" | "id" | "priceOverride" | "registrationStatus" | "spotsAvailable" | "updated_at" | "%future added value";
export type ride_update_column = "canceledAt" | "cancellationReason" | "created_at" | "cuid" | "dayOfContact" | "eventCuid" | "id" | "priceOverride" | "registrationStatus" | "spotsAvailable" | "updated_at" | "%future added value";
export type roleOrganizer_constraint = "roleOrganizer_pkey" | "%future added value";
export type roleOrganizer_enum = "Admin" | "Coach" | "Owner" | "Support" | "Timing" | "Volunteer" | "%future added value";
export type roleOrganizer_update_column = "role" | "%future added value";
export type sanctionWaivers_constraint = "sanctionWaivers_pkey" | "%future added value";
export type sanctionWaivers_select_column = "sanctionCuid" | "waiverCuid" | "%future added value";
export type sanctionWaivers_update_column = "sanctionCuid" | "waiverCuid" | "%future added value";
export type sanction_constraint = "PK_sanction" | "sanction_cuid_key" | "sanction_name_key" | "sanction_pkey" | "%future added value";
export type sanction_update_column = "classification" | "country" | "created_at" | "cuid" | "hasPermit" | "id" | "isOneDayAvailable" | "name" | "oneDayFee" | "updated_at" | "waiverLink" | "website" | "%future added value";
export type scheduleTypePreset_constraint = "scheduleTypePreset_cuid_key" | "scheduleTypePreset_pkey" | "%future added value";
export type scheduleTypePreset_update_column = "activity" | "color" | "created_at" | "cuid" | "id" | "isPreset" | "type" | "updated_at" | "%future added value";
export type seriesImage_constraint = "seriesImage_cuid_key" | "seriesImage_pkey" | "%future added value";
export type seriesImage_select_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "seriesCuid" | "updated_at" | "%future added value";
export type seriesImage_select_column_seriesImage_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type seriesImage_select_column_seriesImage_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type seriesImage_update_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "seriesCuid" | "updated_at" | "%future added value";
export type seriesPointsStructureCustom_constraint = "seriesPointsStructure_pkey" | "%future added value";
export type seriesPointsStructureCustom_select_column = "created_at" | "cuid" | "id" | "isPreset" | "isQualifying" | "points" | "pointsName" | "seriesCuid" | "updated_at" | "%future added value";
export type seriesPointsStructureCustom_select_column_seriesPointsStructureCustom_aggregate_bool_exp_bool_and_arguments_columns = "isPreset" | "isQualifying" | "%future added value";
export type seriesPointsStructureCustom_select_column_seriesPointsStructureCustom_aggregate_bool_exp_bool_or_arguments_columns = "isPreset" | "isQualifying" | "%future added value";
export type seriesPointsStructureCustom_update_column = "created_at" | "cuid" | "id" | "isPreset" | "isQualifying" | "points" | "pointsName" | "seriesCuid" | "updated_at" | "%future added value";
export type seriesPointsStructurePreset_constraint = "seriesPointsStructurePreset_pkey" | "%future added value";
export type seriesPointsStructurePreset_select_column = "created_at" | "id" | "isPreset" | "presetCuid" | "seriesCuid" | "updated_at" | "%future added value";
export type seriesPointsStructurePreset_select_column_seriesPointsStructurePreset_aggregate_bool_exp_bool_and_arguments_columns = "isPreset" | "%future added value";
export type seriesPointsStructurePreset_select_column_seriesPointsStructurePreset_aggregate_bool_exp_bool_or_arguments_columns = "isPreset" | "%future added value";
export type seriesPointsStructurePreset_update_column = "created_at" | "id" | "isPreset" | "presetCuid" | "seriesCuid" | "updated_at" | "%future added value";
export type series_constraint = "series_cuid_key" | "series_organizerCuid_slug_key" | "series_pkey" | "series_uniqueId_key" | "%future added value";
export type series_select_column = "created_at" | "cuid" | "deleted_at" | "description" | "id" | "isJoinable" | "joinKey" | "name" | "organizerCuid" | "seriesActivity" | "slug" | "totalRounds" | "updated_at" | "%future added value";
export type series_select_column_series_aggregate_bool_exp_bool_and_arguments_columns = "isJoinable" | "%future added value";
export type series_select_column_series_aggregate_bool_exp_bool_or_arguments_columns = "isJoinable" | "%future added value";
export type series_update_column = "created_at" | "cuid" | "deleted_at" | "description" | "id" | "isJoinable" | "joinKey" | "name" | "organizerCuid" | "seriesActivity" | "slug" | "totalRounds" | "updated_at" | "%future added value";
export type signatureRequestSignerType_constraint = "sigatureRequestSignerType_pkey" | "%future added value";
export type signatureRequestSignerType_enum = "EMAILED" | "EMAILED_PARENT_GUARDIAN" | "PARENT_GUARDIAN" | "SIGNER" | "%future added value";
export type signatureRequestSignerType_update_column = "type" | "%future added value";
export type signatureRequestStatus_constraint = "signatureRequestStatus_pkey" | "%future added value";
export type signatureRequestStatus_enum = "CANCELED" | "COMPLETE" | "OPEN" | "%future added value";
export type signatureRequestStatus_update_column = "description" | "type" | "%future added value";
export type signatureRequest_constraint = "signatureRequests_cuid_key" | "signatureRequests_pkey" | "signatureRequests_signatureId_key" | "%future added value";
export type signatureRequest_select_column = "created_at" | "cuid" | "id" | "registrationCuid" | "signatureId" | "signatureRequestId" | "signerEmail" | "signerName" | "signerType" | "status" | "updated_at" | "waiverRevisionCuid" | "%future added value";
export type signatureRequest_update_column = "created_at" | "cuid" | "id" | "registrationCuid" | "signatureId" | "signatureRequestId" | "signerEmail" | "signerName" | "signerType" | "status" | "updated_at" | "waiverRevisionCuid" | "%future added value";
export type sponsorImage_constraint = "sponsorImage_cuid_key" | "sponsorImage_pkey" | "%future added value";
export type sponsorImage_select_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "sponsorCuid" | "updated_at" | "%future added value";
export type sponsorImage_select_column_sponsorImage_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type sponsorImage_select_column_sponsorImage_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type sponsorImage_update_column = "created_at" | "cuid" | "id" | "imagePublicId" | "isDefault" | "sponsorCuid" | "updated_at" | "%future added value";
export type sponsor_constraint = "sponsor_cuid_key" | "sponsor_pkey" | "%future added value";
export type sponsor_select_column = "created_at" | "cuid" | "deleted_at" | "id" | "name" | "organizerCuid" | "updated_at" | "website" | "%future added value";
export type sponsor_update_column = "created_at" | "cuid" | "deleted_at" | "id" | "name" | "organizerCuid" | "updated_at" | "website" | "%future added value";
export type stripeConnectAccount_constraint = "stripeConnectAccount_accountId_key" | "stripeConnectAccount_accountId_ownerUserId_ownerOrganizerCuid_k" | "stripeConnectAccount_pkey" | "%future added value";
export type stripeConnectAccount_select_column = "accountId" | "chargesEnabled" | "created_at" | "id" | "ownerOrganizerCuid" | "ownerUserId" | "payoutsEnabled" | "updated_at" | "%future added value";
export type stripeConnectAccount_select_column_stripeConnectAccount_aggregate_bool_exp_bool_and_arguments_columns = "chargesEnabled" | "payoutsEnabled" | "%future added value";
export type stripeConnectAccount_select_column_stripeConnectAccount_aggregate_bool_exp_bool_or_arguments_columns = "chargesEnabled" | "payoutsEnabled" | "%future added value";
export type stripeConnectAccount_update_column = "accountId" | "chargesEnabled" | "created_at" | "id" | "ownerOrganizerCuid" | "ownerUserId" | "payoutsEnabled" | "updated_at" | "%future added value";
export type ticket_constraint = "ticket_pkey" | "%future added value";
export type ticket_select_column = "created_at" | "cuid" | "deleted_at" | "description" | "id" | "name" | "organizerCuid" | "price" | "updated_at" | "%future added value";
export type ticket_update_column = "created_at" | "cuid" | "deleted_at" | "description" | "id" | "name" | "organizerCuid" | "price" | "updated_at" | "%future added value";
export type userAddress_constraint = "userAddress_cuid_key" | "userAddress_pkey" | "userAddress_userId_googlePlaceId_key" | "%future added value";
export type userAddress_select_column = "address" | "addressLine1" | "addressLine2" | "city" | "country" | "countryCode" | "created_at" | "cuid" | "googlePlaceId" | "id" | "isDefault" | "label" | "lat" | "lng" | "phone" | "state" | "updated_at" | "userId" | "zipCode" | "%future added value";
export type userAddress_select_column_userAddress_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type userAddress_select_column_userAddress_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type userAddress_update_column = "address" | "addressLine1" | "addressLine2" | "city" | "country" | "countryCode" | "created_at" | "cuid" | "googlePlaceId" | "id" | "isDefault" | "label" | "lat" | "lng" | "phone" | "state" | "updated_at" | "userId" | "zipCode" | "%future added value";
export type userBikeRevision_constraint = "userBikeRevision_cuid_key" | "userBikeRevision_pkey" | "userBikeRevision_userBikeCuid_revision_key" | "%future added value";
export type userBikeRevision_select_column = "bike" | "created_at" | "cuid" | "description" | "id" | "name" | "revision" | "updated_at" | "userBikeCuid" | "%future added value";
export type userBikeRevision_update_column = "bike" | "created_at" | "cuid" | "description" | "id" | "name" | "revision" | "updated_at" | "userBikeCuid" | "%future added value";
export type userBike_constraint = "userBike_cuid_key" | "userBike_pkey" | "%future added value";
export type userBike_select_column = "created_at" | "cuid" | "id" | "latestRevisionNum" | "updated_at" | "userId" | "%future added value";
export type userBike_update_column = "created_at" | "cuid" | "id" | "latestRevisionNum" | "updated_at" | "userId" | "%future added value";
export type userEmergencyContact_constraint = "userEmergencyContact_cuid_key" | "userEmergencyContact_pkey" | "%future added value";
export type userEmergencyContact_select_column = "created_at" | "cuid" | "id" | "isDefault" | "name" | "phone" | "relationship" | "updated_at" | "userId" | "%future added value";
export type userEmergencyContact_select_column_userEmergencyContact_aggregate_bool_exp_bool_and_arguments_columns = "isDefault" | "%future added value";
export type userEmergencyContact_select_column_userEmergencyContact_aggregate_bool_exp_bool_or_arguments_columns = "isDefault" | "%future added value";
export type userEmergencyContact_update_column = "created_at" | "cuid" | "id" | "isDefault" | "name" | "phone" | "relationship" | "updated_at" | "userId" | "%future added value";
export type userImage_constraint = "userImage_pkey" | "%future added value";
export type userImage_select_column = "created_at" | "cuid" | "id" | "imagePublicId" | "updated_at" | "userId" | "%future added value";
export type userImage_update_column = "created_at" | "cuid" | "id" | "imagePublicId" | "updated_at" | "userId" | "%future added value";
export type userOnlineCourseStatus_constraint = "userOnlineCourseStatus_pkey" | "%future added value";
export type userOnlineCourseStatus_enum = "canceled" | "completed" | "in_progress" | "%future added value";
export type userOnlineCourseStatus_update_column = "status" | "%future added value";
export type userOnlineCourse_constraint = "userOnlineCourse_cuid_key" | "userOnlineCourse_onlineCourseCuid_userId_key" | "userOnlineCourse_pkey" | "%future added value";
export type userOnlineCourse_select_column = "completedLessons" | "created_at" | "cuid" | "id" | "lastViewedLessonCuid" | "onlineCourseCuid" | "referenceNo" | "revision" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "userId" | "%future added value";
export type userOnlineCourse_update_column = "completedLessons" | "created_at" | "cuid" | "id" | "lastViewedLessonCuid" | "onlineCourseCuid" | "referenceNo" | "revision" | "status" | "stripeCheckoutId" | "stripePaymentIntentId" | "successToken" | "updated_at" | "userId" | "%future added value";
export type userOrganizerRole_constraint = "userOrganizerRole_cuid_key" | "userOrganizerRole_userId_organizerCuid_role_key" | "userOrganizerRoles_pkey" | "%future added value";
export type userOrganizerRole_select_column = "created_at" | "cuid" | "id" | "organizerCuid" | "role" | "updated_at" | "userId" | "%future added value";
export type userOrganizerRole_update_column = "created_at" | "cuid" | "id" | "organizerCuid" | "role" | "updated_at" | "userId" | "%future added value";
export type userPersonalization_constraint = "userPersonalization_pkey" | "%future added value";
export type userPersonalization_select_column = "created_at" | "id" | "preferences" | "updated_at" | "userId" | "%future added value";
export type userPersonalization_update_column = "created_at" | "id" | "preferences" | "updated_at" | "userId" | "%future added value";
export type userRidingInterest_constraint = "userRidingInterest_cuid_key" | "userRidingInterest_pkey" | "%future added value";
export type userRidingInterest_select_column = "abilityLevel" | "abilityLevelGoal" | "created_at" | "cuid" | "disciplineName" | "focus" | "hasAbilityGoal" | "id" | "updated_at" | "userId" | "%future added value";
export type userRidingInterest_select_column_userRidingInterest_aggregate_bool_exp_bool_and_arguments_columns = "hasAbilityGoal" | "%future added value";
export type userRidingInterest_select_column_userRidingInterest_aggregate_bool_exp_bool_or_arguments_columns = "hasAbilityGoal" | "%future added value";
export type userRidingInterest_update_column = "abilityLevel" | "abilityLevelGoal" | "created_at" | "cuid" | "disciplineName" | "focus" | "hasAbilityGoal" | "id" | "updated_at" | "userId" | "%future added value";
export type userSanctionLicense_constraint = "userSanctionLicense_cuid_key" | "userSanctionLicense_pkey" | "userSanctionLicense_sanctionCuid_userId_key" | "%future added value";
export type userSanctionLicense_select_column = "created_at" | "cuid" | "id" | "licenseNumber" | "sanctionCuid" | "updated_at" | "userId" | "%future added value";
export type userSanctionLicense_update_column = "created_at" | "cuid" | "id" | "licenseNumber" | "sanctionCuid" | "updated_at" | "userId" | "%future added value";
export type userSavedCoach_constraint = "userSavedCoach_cuid_key" | "userSavedCoach_pkey" | "%future added value";
export type userSavedCoach_select_column = "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "userId" | "%future added value";
export type userSavedCoach_update_column = "coachCuid" | "created_at" | "cuid" | "id" | "updated_at" | "userId" | "%future added value";
export type userSavedEvent_constraint = "userSavedEvents_cuid_key" | "userSavedEvents_pkey" | "%future added value";
export type userSavedEvent_select_column = "created_at" | "cuid" | "eventCuid" | "id" | "updated_at" | "userId" | "%future added value";
export type userSavedEvent_update_column = "created_at" | "cuid" | "eventCuid" | "id" | "updated_at" | "userId" | "%future added value";
export type userSavedLesson_constraint = "userSavedLesson_cuid_key" | "userSavedLesson_pkey" | "%future added value";
export type userSavedLesson_select_column = "coachLessonCuid" | "created_at" | "cuid" | "id" | "updated_at" | "userId" | "%future added value";
export type userSavedLesson_update_column = "coachLessonCuid" | "created_at" | "cuid" | "id" | "updated_at" | "userId" | "%future added value";
export type user_constraint = "PK_users" | "user_id_key" | "user_pkey" | "user_slug_key" | "%future added value";
export type user_select_column = "coachCuid" | "coachSetupStep" | "coachSetupType" | "created_at" | "currency" | "cyclingGoal" | "deleted_at" | "dob" | "email" | "facebookProfile" | "firstName" | "gender" | "id" | "idealRide" | "instagramProfile" | "isAdmin" | "isCoach" | "isCreator" | "lastName" | "lastSavedAt" | "location" | "phone" | "profilePicPublicId" | "profilePicUrl" | "pronouns" | "ridingSince" | "selectedOrganizerSlug" | "showSetupCoach" | "slug" | "sponsors" | "stravaProfile" | "stripeConnectId" | "stripeCustomerId" | "stripeIdentityVerificationError" | "stripeIdentityVerificationSessionId" | "stripeIdentityVerificationStatus" | "timezone" | "twitterProfile" | "updated_at" | "%future added value";
export type user_select_column_user_aggregate_bool_exp_bool_and_arguments_columns = "isAdmin" | "isCoach" | "isCreator" | "showSetupCoach" | "%future added value";
export type user_select_column_user_aggregate_bool_exp_bool_or_arguments_columns = "isAdmin" | "isCoach" | "isCreator" | "showSetupCoach" | "%future added value";
export type user_update_column = "coachCuid" | "coachSetupStep" | "coachSetupType" | "created_at" | "currency" | "cyclingGoal" | "deleted_at" | "dob" | "email" | "facebookProfile" | "firstName" | "gender" | "id" | "idealRide" | "instagramProfile" | "isAdmin" | "isCoach" | "isCreator" | "lastName" | "lastSavedAt" | "location" | "phone" | "profilePicPublicId" | "profilePicUrl" | "pronouns" | "ridingSince" | "selectedOrganizerSlug" | "showSetupCoach" | "slug" | "sponsors" | "stravaProfile" | "stripeConnectId" | "stripeCustomerId" | "stripeIdentityVerificationError" | "stripeIdentityVerificationSessionId" | "stripeIdentityVerificationStatus" | "timezone" | "twitterProfile" | "updated_at" | "%future added value";
export type venue_constraint = "venue_pkey" | "%future added value";
export type venue_select_column = "address" | "addressWithName" | "city" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "id" | "lat" | "lng" | "name" | "organizerCuid" | "placeId" | "postalCode" | "state" | "timezone" | "updated_at" | "%future added value";
export type venue_update_column = "address" | "addressWithName" | "city" | "country" | "countryCode" | "created_at" | "cuid" | "fullAddress" | "id" | "lat" | "lng" | "name" | "organizerCuid" | "placeId" | "postalCode" | "state" | "timezone" | "updated_at" | "%future added value";
export type volunteerTaskDifficulties_constraint = "volunteerTaskDifficulties_cuid_key" | "volunteerTaskDifficulties_pkey" | "%future added value";
export type volunteerTaskDifficulties_update_column = "chipColor" | "className" | "color" | "created_at" | "cuid" | "id" | "name" | "updated_at" | "%future added value";
export type volunteerTaskPriorities_constraint = "volunteerTaskPriorities_cuid_key" | "volunteerTaskPriorities_pkey" | "%future added value";
export type volunteerTaskPriorities_update_column = "chipColor" | "className" | "color" | "created_at" | "cuid" | "id" | "name" | "updated_at" | "%future added value";
export type waiverOwner_constraint = "waiverOwner_pkey" | "%future added value";
export type waiverOwner_enum = "ORG" | "REGGY" | "%future added value";
export type waiverOwner_update_column = "description" | "owner" | "%future added value";
export type waiverRevision_constraint = "waiverRevision_cuid_key" | "waiverRevision_pkey" | "waiverRevision_waiverCuid_revision_key" | "%future added value";
export type waiverRevision_select_column = "createdBy" | "created_at" | "cuid" | "fieldsConfiguration" | "fileRef" | "id" | "name" | "revision" | "waiverCuid" | "%future added value";
export type waiverRevision_update_column = "createdBy" | "created_at" | "cuid" | "fieldsConfiguration" | "fileRef" | "id" | "name" | "revision" | "waiverCuid" | "%future added value";
export type waiver_constraint = "waiver_cuid_key" | "waiver_pkey" | "%future added value";
export type waiver_select_column = "created_at" | "cuid" | "deleted_at" | "id" | "latestRevisionNum" | "ownerCuid" | "ownerType" | "updated_at" | "%future added value";
export type waiver_update_column = "created_at" | "cuid" | "deleted_at" | "id" | "latestRevisionNum" | "ownerCuid" | "ownerType" | "updated_at" | "%future added value";
export type organizer_insert_input = {
    about?: string | null | undefined;
    coaches?: coach_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    defaultCurrencyCode?: string | null | undefined;
    deleted_at?: string | null | undefined;
    eventMetadata?: eventMetadata_arr_rel_insert_input | null | undefined;
    events?: event_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    images?: image_arr_rel_insert_input | null | undefined;
    merchandises?: merchandise_arr_rel_insert_input | null | undefined;
    name?: string | null | undefined;
    onlineCourses?: onlineCourse_arr_rel_insert_input | null | undefined;
    organizerChannels?: organizerChannel_arr_rel_insert_input | null | undefined;
    organizerInvites?: organizerInvite_arr_rel_insert_input | null | undefined;
    series?: series_arr_rel_insert_input | null | undefined;
    showOnboarding?: boolean | null | undefined;
    slug?: string | null | undefined;
    sponsors?: sponsor_arr_rel_insert_input | null | undefined;
    stripeConnectAccount?: stripeConnectAccount_obj_rel_insert_input | null | undefined;
    stripeConnectAccounts?: stripeConnectAccount_arr_rel_insert_input | null | undefined;
    stripeConnectId?: string | null | undefined;
    tickets?: ticket_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    userOrganizerRoles?: userOrganizerRole_arr_rel_insert_input | null | undefined;
    venues?: venue_arr_rel_insert_input | null | undefined;
    website?: string | null | undefined;
};
export type coach_arr_rel_insert_input = {
    data: Array<coach_insert_input>;
    on_conflict?: coach_on_conflict | null | undefined;
};
export type coach_insert_input = {
    about?: string | null | undefined;
    addRacingExp?: boolean | null | undefined;
    allowRequests?: boolean | null | undefined;
    calendarId?: string | null | undefined;
    clinicCoaches?: clinicCoach_arr_rel_insert_input | null | undefined;
    coachAvailabilities?: coachAvailability_arr_rel_insert_input | null | undefined;
    coachCertifications?: coachCertification_arr_rel_insert_input | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_arr_rel_insert_input | null | undefined;
    coachCustomers?: coachCustomer_arr_rel_insert_input | null | undefined;
    coachDisciplines?: coachDiscipline_arr_rel_insert_input | null | undefined;
    coachImages?: coachImage_arr_rel_insert_input | null | undefined;
    coachLessons?: coachLesson_arr_rel_insert_input | null | undefined;
    coachLocations?: coachLocation_arr_rel_insert_input | null | undefined;
    coachModerationFlags?: coachModerationFlag_arr_rel_insert_input | null | undefined;
    coachStudents?: coachStudent_arr_rel_insert_input | null | undefined;
    coachUnavailableDates?: coachUnavailableDate_arr_rel_insert_input | null | undefined;
    coachingRadius?: number | null | undefined;
    coachingSince?: number | null | undefined;
    coachingVibe?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    currencyCode?: string | null | undefined;
    experience?: string | null | undefined;
    id?: number | null | undefined;
    introVideoThumbnailUrl?: string | null | undefined;
    introVideoUrl?: string | null | undefined;
    isCPRCertified?: boolean | null | undefined;
    isFirstAidCertified?: boolean | null | undefined;
    isSuperCoach?: boolean | null | undefined;
    lessonLocations?: lessonLocation_arr_rel_insert_input | null | undefined;
    offerCustomLesson?: boolean | null | undefined;
    onlineCourses?: onlineCourse_arr_rel_insert_input | null | undefined;
    openToTravel?: boolean | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuidToDisplay?: string | null | undefined;
    racingExp?: string | null | undefined;
    reviews?: review_arr_rel_insert_input | null | undefined;
    showProfileHelpBanner?: boolean | null | undefined;
    syncLocationToProfile?: boolean | null | undefined;
    tagline?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
    userSavedCoaches?: userSavedCoach_arr_rel_insert_input | null | undefined;
    users?: user_arr_rel_insert_input | null | undefined;
    yearStarted?: number | null | undefined;
};
export type clinicCoach_arr_rel_insert_input = {
    data: Array<clinicCoach_insert_input>;
    on_conflict?: clinicCoach_on_conflict | null | undefined;
};
export type clinicCoach_insert_input = {
    clinic?: clinic_obj_rel_insert_input | null | undefined;
    clinicCuid?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type clinic_obj_rel_insert_input = {
    data: clinic_insert_input;
    on_conflict?: clinic_on_conflict | null | undefined;
};
export type clinic_insert_input = {
    canceledAt?: string | null | undefined;
    cancellationReason?: string | null | undefined;
    clinicCoaches?: clinicCoach_arr_rel_insert_input | null | undefined;
    clinicDays?: clinicDay_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    dayOfContact?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    meetingLink?: string | null | undefined;
    priceOverride?: number | null | undefined;
    registrationStatus?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    spotsAvailable?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type clinicDay_arr_rel_insert_input = {
    data: Array<clinicDay_insert_input>;
    on_conflict?: clinicDay_on_conflict | null | undefined;
};
export type clinicDay_insert_input = {
    clinic?: clinic_obj_rel_insert_input | null | undefined;
    clinicCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventClinicDayDuration?: eventClinicDayDuration_obj_rel_insert_input | null | undefined;
    eventClinicDayDurationCuid?: string | null | undefined;
    id?: number | null | undefined;
    reportClinicNoShows?: reportClinicNoShow_arr_rel_insert_input | null | undefined;
    startTime?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventClinicDayDuration_obj_rel_insert_input = {
    data: eventClinicDayDuration_insert_input;
    on_conflict?: eventClinicDayDuration_on_conflict | null | undefined;
};
export type eventClinicDayDuration_insert_input = {
    clinicDays?: clinicDay_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    dayNumber?: number | null | undefined;
    duration?: unknown | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type event_obj_rel_insert_input = {
    data: event_insert_input;
    on_conflict?: event_on_conflict | null | undefined;
};
export type event_insert_input = {
    AccessName?: AccessName_obj_rel_insert_input | null | undefined;
    activity?: activityType_enum | null | undefined;
    activityType?: activityType_obj_rel_insert_input | null | undefined;
    additionalContactInfo?: string | null | undefined;
    canceledAt?: string | null | undefined;
    cancellationReason?: string | null | undefined;
    categories?: category_arr_rel_insert_input | null | undefined;
    clinics?: clinic_arr_rel_insert_input | null | undefined;
    completedSections?: Record<string, unknown> | unknown[] | null | undefined;
    courses?: course_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    currencyCode?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    email?: string | null | undefined;
    endDate?: string | null | undefined;
    eventAccess?: AccessName_enum | null | undefined;
    eventAdditionalInfos?: eventAdditionalInfo_arr_rel_insert_input | null | undefined;
    eventAmenities?: eventAmenity_arr_rel_insert_input | null | undefined;
    eventBasicWaivers?: eventBasicWaiver_arr_rel_insert_input | null | undefined;
    eventChecklistItems?: eventChecklistItem_arr_rel_insert_input | null | undefined;
    eventClinicDayDurations?: eventClinicDayDuration_arr_rel_insert_input | null | undefined;
    eventCustomQuestions?: eventCustomQuestion_arr_rel_insert_input | null | undefined;
    eventDisciplines?: eventDiscipline_arr_rel_insert_input | null | undefined;
    eventImages?: eventImage_arr_rel_insert_input | null | undefined;
    eventMerchandises?: eventMerchandise_arr_rel_insert_input | null | undefined;
    eventMetadata?: eventMetadata_obj_rel_insert_input | null | undefined;
    eventMetadataCuid?: string | null | undefined;
    eventModerationFlags?: eventModerationFlag_arr_rel_insert_input | null | undefined;
    eventPitSpaces?: eventPitSpace_arr_rel_insert_input | null | undefined;
    eventPricingChanges?: eventPricingChange_arr_rel_insert_input | null | undefined;
    eventPricingDates?: eventPricingDate_arr_rel_insert_input | null | undefined;
    eventPricingGroups?: eventPricingGroup_arr_rel_insert_input | null | undefined;
    eventPrivateLinks?: eventPrivateLink_arr_rel_insert_input | null | undefined;
    eventPrivateLinksByEventcuid?: eventPrivateLink_arr_rel_insert_input | null | undefined;
    eventPrograms?: eventProgram_arr_rel_insert_input | null | undefined;
    eventPromoCodes?: eventPromoCode_arr_rel_insert_input | null | undefined;
    eventQuestions?: eventQuestion_arr_rel_insert_input | null | undefined;
    eventSanctions?: eventSanction_arr_rel_insert_input | null | undefined;
    eventScheduleDates?: eventScheduleDate_arr_rel_insert_input | null | undefined;
    eventScheduleTypeCustoms?: eventScheduleTypeCustom_arr_rel_insert_input | null | undefined;
    eventScheduleTypePresets?: eventScheduleTypePreset_arr_rel_insert_input | null | undefined;
    eventSchedules?: eventSchedule_arr_rel_insert_input | null | undefined;
    eventSeries?: eventSeries_arr_rel_insert_input | null | undefined;
    eventSponsors?: eventSponsor_arr_rel_insert_input | null | undefined;
    eventTickets?: eventTicket_arr_rel_insert_input | null | undefined;
    eventUpdates?: eventUpdate_arr_rel_insert_input | null | undefined;
    eventVenues?: eventVenue_arr_rel_insert_input | null | undefined;
    eventVibes?: eventVibe_arr_rel_insert_input | null | undefined;
    eventVirtualVenues?: eventVirtualVenue_arr_rel_insert_input | null | undefined;
    eventVolunteerJobs?: eventVolunteerJob_arr_rel_insert_input | null | undefined;
    eventWaivers?: eventWaiver_arr_rel_insert_input | null | undefined;
    eventWebsite?: string | null | undefined;
    hasDynamicPricing?: boolean | null | undefined;
    hasGeneralWaivers?: boolean | null | undefined;
    hasMerchandise?: boolean | null | undefined;
    hasVolunteers?: boolean | null | undefined;
    id?: number | null | undefined;
    isCourseEvent?: boolean | null | undefined;
    isEventCreationComplete?: boolean | null | undefined;
    isRegistrationPaused?: boolean | null | undefined;
    isSanctionedEvent?: boolean | null | undefined;
    isSeriesEvent?: boolean | null | undefined;
    isSponsoredEvent?: boolean | null | undefined;
    isTicketedEvent?: boolean | null | undefined;
    isVirtualEvent?: boolean | null | undefined;
    lastSavedAt?: string | null | undefined;
    name?: string | null | undefined;
    nonCategoriedRegDeadline?: number | null | undefined;
    occurrenceLabel?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerChannels?: organizerChannel_arr_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    phone?: string | null | undefined;
    publishedAt?: string | null | undefined;
    refundInfo?: string | null | undefined;
    regCloseDate?: string | null | undefined;
    regOpenDate?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    rides?: ride_arr_rel_insert_input | null | undefined;
    selfEditEndDate?: string | null | undefined;
    slug?: string | null | undefined;
    spotsAvailable?: number | null | undefined;
    startDate?: string | null | undefined;
    updated_at?: string | null | undefined;
    userSavedEvents?: userSavedEvent_arr_rel_insert_input | null | undefined;
};
export type AccessName_obj_rel_insert_input = {
    data: AccessName_insert_input;
    on_conflict?: AccessName_on_conflict | null | undefined;
};
export type AccessName_insert_input = {
    eventPitSpaces?: eventPitSpace_arr_rel_insert_input | null | undefined;
    eventVolunteerJobShifts?: eventVolunteerJobShift_arr_rel_insert_input | null | undefined;
    events?: event_arr_rel_insert_input | null | undefined;
    name?: string | null | undefined;
};
export type eventPitSpace_arr_rel_insert_input = {
    data: Array<eventPitSpace_insert_input>;
    on_conflict?: eventPitSpace_on_conflict | null | undefined;
};
export type eventPitSpace_insert_input = {
    AccessName?: AccessName_obj_rel_insert_input | null | undefined;
    allowWaitlist?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventPitSpaceAddOns?: eventPitSpaceAddOn_arr_rel_insert_input | null | undefined;
    name?: string | null | undefined;
    pitSpaceAccess?: AccessName_enum | null | undefined;
    plotLength?: number | null | undefined;
    plotLengthType?: string | null | undefined;
    plotWidth?: number | null | undefined;
    plotWidthType?: string | null | undefined;
    position?: number | null | undefined;
    price?: number | null | undefined;
    requireDimensions?: boolean | null | undefined;
    shouldContactForPrice?: boolean | null | undefined;
    shouldUseContactSize?: boolean | null | undefined;
    shouldUsePlotSize?: boolean | null | undefined;
    shouldUseUnitSize?: boolean | null | undefined;
    spotsAvailable?: number | null | undefined;
    spotsReserved?: number | null | undefined;
    unitSize?: number | null | undefined;
    unitSizeType?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPitSpaceAddOn_arr_rel_insert_input = {
    data: Array<eventPitSpaceAddOn_insert_input>;
    on_conflict?: eventPitSpaceAddOn_on_conflict | null | undefined;
};
export type eventPitSpaceAddOn_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventPitSpace?: eventPitSpace_obj_rel_insert_input | null | undefined;
    eventPitSpaceCuid?: string | null | undefined;
    name?: string | null | undefined;
    price?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPitSpace_obj_rel_insert_input = {
    data: eventPitSpace_insert_input;
    on_conflict?: eventPitSpace_on_conflict | null | undefined;
};
export type eventPitSpace_on_conflict = {
    constraint: eventPitSpace_constraint;
    update_columns: Array<eventPitSpace_update_column>;
    where?: eventPitSpace_bool_exp | null | undefined;
};
export type eventPitSpace_bool_exp = {
    AccessName?: AccessName_bool_exp | null | undefined;
    _and?: Array<eventPitSpace_bool_exp> | null | undefined;
    _not?: eventPitSpace_bool_exp | null | undefined;
    _or?: Array<eventPitSpace_bool_exp> | null | undefined;
    allowWaitlist?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventPitSpaceAddOns?: eventPitSpaceAddOn_bool_exp | null | undefined;
    eventPitSpaceAddOns_aggregate?: eventPitSpaceAddOn_aggregate_bool_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    pitSpaceAccess?: AccessName_enum_comparison_exp | null | undefined;
    plotLength?: numeric_comparison_exp | null | undefined;
    plotLengthType?: String_comparison_exp | null | undefined;
    plotWidth?: numeric_comparison_exp | null | undefined;
    plotWidthType?: String_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    requireDimensions?: Boolean_comparison_exp | null | undefined;
    shouldContactForPrice?: Boolean_comparison_exp | null | undefined;
    shouldUseContactSize?: Boolean_comparison_exp | null | undefined;
    shouldUsePlotSize?: Boolean_comparison_exp | null | undefined;
    shouldUseUnitSize?: Boolean_comparison_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    spotsReserved?: Int_comparison_exp | null | undefined;
    unitSize?: numeric_comparison_exp | null | undefined;
    unitSizeType?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type AccessName_bool_exp = {
    _and?: Array<AccessName_bool_exp> | null | undefined;
    _not?: AccessName_bool_exp | null | undefined;
    _or?: Array<AccessName_bool_exp> | null | undefined;
    eventPitSpaces?: eventPitSpace_bool_exp | null | undefined;
    eventPitSpaces_aggregate?: eventPitSpace_aggregate_bool_exp | null | undefined;
    eventVolunteerJobShifts?: eventVolunteerJobShift_bool_exp | null | undefined;
    eventVolunteerJobShifts_aggregate?: eventVolunteerJobShift_aggregate_bool_exp | null | undefined;
    events?: event_bool_exp | null | undefined;
    events_aggregate?: event_aggregate_bool_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
};
export type eventPitSpace_aggregate_bool_exp = {
    bool_and?: eventPitSpace_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventPitSpace_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventPitSpace_aggregate_bool_exp_count | null | undefined;
};
export type eventPitSpace_aggregate_bool_exp_bool_and = {
    arguments: eventPitSpace_select_column_eventPitSpace_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPitSpace_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type Boolean_comparison_exp = {
    _eq?: boolean | null | undefined;
    _gt?: boolean | null | undefined;
    _gte?: boolean | null | undefined;
    _in?: Array<boolean> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: boolean | null | undefined;
    _lte?: boolean | null | undefined;
    _neq?: boolean | null | undefined;
    _nin?: Array<boolean> | null | undefined;
};
export type eventPitSpace_aggregate_bool_exp_bool_or = {
    arguments: eventPitSpace_select_column_eventPitSpace_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPitSpace_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventPitSpace_aggregate_bool_exp_count = {
    arguments?: Array<eventPitSpace_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPitSpace_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type Int_comparison_exp = {
    _eq?: number | null | undefined;
    _gt?: number | null | undefined;
    _gte?: number | null | undefined;
    _in?: Array<number> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: number | null | undefined;
    _lte?: number | null | undefined;
    _neq?: number | null | undefined;
    _nin?: Array<number> | null | undefined;
};
export type eventVolunteerJobShift_bool_exp = {
    AccessName?: AccessName_bool_exp | null | undefined;
    _and?: Array<eventVolunteerJobShift_bool_exp> | null | undefined;
    _not?: eventVolunteerJobShift_bool_exp | null | undefined;
    _or?: Array<eventVolunteerJobShift_bool_exp> | null | undefined;
    allowWaitlist?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    endDate?: timestamptz_comparison_exp | null | undefined;
    eventVolunteerJob?: eventVolunteerJob_bool_exp | null | undefined;
    eventVolunteerJobCuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobShiftTasks?: eventVolunteerJobShiftTask_bool_exp | null | undefined;
    eventVolunteerJobShiftTasks_aggregate?: eventVolunteerJobShiftTask_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    shiftAccess?: AccessName_enum_comparison_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    spotsReserved?: Int_comparison_exp | null | undefined;
    startDate?: timestamptz_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type timestamptz_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
};
export type String_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _ilike?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _iregex?: string | null | undefined;
    _is_null?: boolean | null | undefined;
    _like?: string | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nilike?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
    _niregex?: string | null | undefined;
    _nlike?: string | null | undefined;
    _nregex?: string | null | undefined;
    _nsimilar?: string | null | undefined;
    _regex?: string | null | undefined;
    _similar?: string | null | undefined;
};
export type eventVolunteerJob_bool_exp = {
    _and?: Array<eventVolunteerJob_bool_exp> | null | undefined;
    _not?: eventVolunteerJob_bool_exp | null | undefined;
    _or?: Array<eventVolunteerJob_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobShifts?: eventVolunteerJobShift_bool_exp | null | undefined;
    eventVolunteerJobShifts_aggregate?: eventVolunteerJobShift_aggregate_bool_exp | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_bool_exp | null | undefined;
    eventVolunteerJobTasks_aggregate?: eventVolunteerJobTask_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    locationInfo?: String_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type event_bool_exp = {
    AccessName?: AccessName_bool_exp | null | undefined;
    _and?: Array<event_bool_exp> | null | undefined;
    _not?: event_bool_exp | null | undefined;
    _or?: Array<event_bool_exp> | null | undefined;
    activity?: activityType_enum_comparison_exp | null | undefined;
    activityType?: activityType_bool_exp | null | undefined;
    additionalContactInfo?: String_comparison_exp | null | undefined;
    canceledAt?: timestamptz_comparison_exp | null | undefined;
    cancellationReason?: String_comparison_exp | null | undefined;
    categories?: category_bool_exp | null | undefined;
    categories_aggregate?: category_aggregate_bool_exp | null | undefined;
    clinics?: clinic_bool_exp | null | undefined;
    clinics_aggregate?: clinic_aggregate_bool_exp | null | undefined;
    completedSections?: jsonb_comparison_exp | null | undefined;
    courses?: course_bool_exp | null | undefined;
    courses_aggregate?: course_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    currencyCode?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    email?: String_comparison_exp | null | undefined;
    endDate?: date_comparison_exp | null | undefined;
    eventAccess?: AccessName_enum_comparison_exp | null | undefined;
    eventAdditionalInfos?: eventAdditionalInfo_bool_exp | null | undefined;
    eventAdditionalInfos_aggregate?: eventAdditionalInfo_aggregate_bool_exp | null | undefined;
    eventAmenities?: eventAmenity_bool_exp | null | undefined;
    eventAmenities_aggregate?: eventAmenity_aggregate_bool_exp | null | undefined;
    eventBasicWaivers?: eventBasicWaiver_bool_exp | null | undefined;
    eventBasicWaivers_aggregate?: eventBasicWaiver_aggregate_bool_exp | null | undefined;
    eventChecklistItems?: eventChecklistItem_bool_exp | null | undefined;
    eventChecklistItems_aggregate?: eventChecklistItem_aggregate_bool_exp | null | undefined;
    eventClinicDayDurations?: eventClinicDayDuration_bool_exp | null | undefined;
    eventClinicDayDurations_aggregate?: eventClinicDayDuration_aggregate_bool_exp | null | undefined;
    eventCustomQuestions?: eventCustomQuestion_bool_exp | null | undefined;
    eventCustomQuestions_aggregate?: eventCustomQuestion_aggregate_bool_exp | null | undefined;
    eventDisciplines?: eventDiscipline_bool_exp | null | undefined;
    eventDisciplines_aggregate?: eventDiscipline_aggregate_bool_exp | null | undefined;
    eventImages?: eventImage_bool_exp | null | undefined;
    eventImages_aggregate?: eventImage_aggregate_bool_exp | null | undefined;
    eventMerchandises?: eventMerchandise_bool_exp | null | undefined;
    eventMerchandises_aggregate?: eventMerchandise_aggregate_bool_exp | null | undefined;
    eventMetadata?: eventMetadata_bool_exp | null | undefined;
    eventMetadataCuid?: String_comparison_exp | null | undefined;
    eventModerationFlags?: eventModerationFlag_bool_exp | null | undefined;
    eventModerationFlags_aggregate?: eventModerationFlag_aggregate_bool_exp | null | undefined;
    eventPitSpaces?: eventPitSpace_bool_exp | null | undefined;
    eventPitSpaces_aggregate?: eventPitSpace_aggregate_bool_exp | null | undefined;
    eventPricingChanges?: eventPricingChange_bool_exp | null | undefined;
    eventPricingChanges_aggregate?: eventPricingChange_aggregate_bool_exp | null | undefined;
    eventPricingDates?: eventPricingDate_bool_exp | null | undefined;
    eventPricingDates_aggregate?: eventPricingDate_aggregate_bool_exp | null | undefined;
    eventPricingGroups?: eventPricingGroup_bool_exp | null | undefined;
    eventPricingGroups_aggregate?: eventPricingGroup_aggregate_bool_exp | null | undefined;
    eventPrivateLinks?: eventPrivateLink_bool_exp | null | undefined;
    eventPrivateLinksByEventcuid?: eventPrivateLink_bool_exp | null | undefined;
    eventPrivateLinksByEventcuid_aggregate?: eventPrivateLink_aggregate_bool_exp | null | undefined;
    eventPrivateLinks_aggregate?: eventPrivateLink_aggregate_bool_exp | null | undefined;
    eventPrograms?: eventProgram_bool_exp | null | undefined;
    eventPrograms_aggregate?: eventProgram_aggregate_bool_exp | null | undefined;
    eventPromoCodes?: eventPromoCode_bool_exp | null | undefined;
    eventPromoCodes_aggregate?: eventPromoCode_aggregate_bool_exp | null | undefined;
    eventQuestions?: eventQuestion_bool_exp | null | undefined;
    eventQuestions_aggregate?: eventQuestion_aggregate_bool_exp | null | undefined;
    eventSanctions?: eventSanction_bool_exp | null | undefined;
    eventSanctions_aggregate?: eventSanction_aggregate_bool_exp | null | undefined;
    eventScheduleDates?: eventScheduleDate_bool_exp | null | undefined;
    eventScheduleDates_aggregate?: eventScheduleDate_aggregate_bool_exp | null | undefined;
    eventScheduleTypeCustoms?: eventScheduleTypeCustom_bool_exp | null | undefined;
    eventScheduleTypeCustoms_aggregate?: eventScheduleTypeCustom_aggregate_bool_exp | null | undefined;
    eventScheduleTypePresets?: eventScheduleTypePreset_bool_exp | null | undefined;
    eventScheduleTypePresets_aggregate?: eventScheduleTypePreset_aggregate_bool_exp | null | undefined;
    eventSchedules?: eventSchedule_bool_exp | null | undefined;
    eventSchedules_aggregate?: eventSchedule_aggregate_bool_exp | null | undefined;
    eventSeries?: eventSeries_bool_exp | null | undefined;
    eventSeries_aggregate?: eventSeries_aggregate_bool_exp | null | undefined;
    eventSponsors?: eventSponsor_bool_exp | null | undefined;
    eventSponsors_aggregate?: eventSponsor_aggregate_bool_exp | null | undefined;
    eventTickets?: eventTicket_bool_exp | null | undefined;
    eventTickets_aggregate?: eventTicket_aggregate_bool_exp | null | undefined;
    eventUpdates?: eventUpdate_bool_exp | null | undefined;
    eventUpdates_aggregate?: eventUpdate_aggregate_bool_exp | null | undefined;
    eventVenues?: eventVenue_bool_exp | null | undefined;
    eventVenues_aggregate?: eventVenue_aggregate_bool_exp | null | undefined;
    eventVibes?: eventVibe_bool_exp | null | undefined;
    eventVibes_aggregate?: eventVibe_aggregate_bool_exp | null | undefined;
    eventVirtualVenues?: eventVirtualVenue_bool_exp | null | undefined;
    eventVirtualVenues_aggregate?: eventVirtualVenue_aggregate_bool_exp | null | undefined;
    eventVolunteerJobs?: eventVolunteerJob_bool_exp | null | undefined;
    eventVolunteerJobs_aggregate?: eventVolunteerJob_aggregate_bool_exp | null | undefined;
    eventWaivers?: eventWaiver_bool_exp | null | undefined;
    eventWaivers_aggregate?: eventWaiver_aggregate_bool_exp | null | undefined;
    eventWebsite?: String_comparison_exp | null | undefined;
    hasDynamicPricing?: Boolean_comparison_exp | null | undefined;
    hasGeneralWaivers?: Boolean_comparison_exp | null | undefined;
    hasMerchandise?: Boolean_comparison_exp | null | undefined;
    hasVolunteers?: Boolean_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isCourseEvent?: Boolean_comparison_exp | null | undefined;
    isEventCreationComplete?: Boolean_comparison_exp | null | undefined;
    isRegistrationPaused?: Boolean_comparison_exp | null | undefined;
    isSanctionedEvent?: Boolean_comparison_exp | null | undefined;
    isSeriesEvent?: Boolean_comparison_exp | null | undefined;
    isSponsoredEvent?: Boolean_comparison_exp | null | undefined;
    isTicketedEvent?: Boolean_comparison_exp | null | undefined;
    isVirtualEvent?: Boolean_comparison_exp | null | undefined;
    lastSavedAt?: timestamptz_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    nonCategoriedRegDeadline?: Int_comparison_exp | null | undefined;
    occurrenceLabel?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerChannels?: organizerChannel_bool_exp | null | undefined;
    organizerChannels_aggregate?: organizerChannel_aggregate_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    phone?: String_comparison_exp | null | undefined;
    publishedAt?: timestamptz_comparison_exp | null | undefined;
    refundInfo?: String_comparison_exp | null | undefined;
    regCloseDate?: timestamp_comparison_exp | null | undefined;
    regOpenDate?: timestamp_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    rides?: ride_bool_exp | null | undefined;
    rides_aggregate?: ride_aggregate_bool_exp | null | undefined;
    selfEditEndDate?: timestamptz_comparison_exp | null | undefined;
    slug?: String_comparison_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    startDate?: date_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userSavedEvents?: userSavedEvent_bool_exp | null | undefined;
    userSavedEvents_aggregate?: userSavedEvent_aggregate_bool_exp | null | undefined;
};
export type activityType_enum_comparison_exp = {
    _eq?: activityType_enum | null | undefined;
    _in?: Array<activityType_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: activityType_enum | null | undefined;
    _nin?: Array<activityType_enum> | null | undefined;
};
export type activityType_bool_exp = {
    _and?: Array<activityType_bool_exp> | null | undefined;
    _not?: activityType_bool_exp | null | undefined;
    _or?: Array<activityType_bool_exp> | null | undefined;
    events?: event_bool_exp | null | undefined;
    events_aggregate?: event_aggregate_bool_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
};
export type event_aggregate_bool_exp = {
    bool_and?: event_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: event_aggregate_bool_exp_bool_or | null | undefined;
    count?: event_aggregate_bool_exp_count | null | undefined;
};
export type event_aggregate_bool_exp_bool_and = {
    arguments: event_select_column_event_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: event_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type event_aggregate_bool_exp_bool_or = {
    arguments: event_select_column_event_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: event_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type event_aggregate_bool_exp_count = {
    arguments?: Array<event_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: event_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type category_bool_exp = {
    _and?: Array<category_bool_exp> | null | undefined;
    _not?: category_bool_exp | null | undefined;
    _or?: Array<category_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    additionalPrizes?: String_comparison_exp | null | undefined;
    ageAsOf?: String_comparison_exp | null | undefined;
    categoryCourses?: categoryCourse_bool_exp | null | undefined;
    categoryCourses_aggregate?: categoryCourse_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    entryFee?: numeric_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventBasicWaiverCategories?: eventBasicWaiverCategory_bool_exp | null | undefined;
    eventBasicWaiverCategories_aggregate?: eventBasicWaiverCategory_aggregate_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventCustomQuestionCategories?: eventCustomQuestionCategory_bool_exp | null | undefined;
    eventCustomQuestionCategories_aggregate?: eventCustomQuestionCategory_aggregate_bool_exp | null | undefined;
    eventDiscipline?: eventDiscipline_bool_exp | null | undefined;
    eventDisciplineCuid?: String_comparison_exp | null | undefined;
    eventPricingGroupCategories?: eventPricingGroupCategory_bool_exp | null | undefined;
    eventPricingGroupCategories_aggregate?: eventPricingGroupCategory_aggregate_bool_exp | null | undefined;
    eventSanctionCategories?: eventSanctionCategory_bool_exp | null | undefined;
    eventSanctionCategories_aggregate?: eventSanctionCategory_aggregate_bool_exp | null | undefined;
    eventScheduleCategories?: eventScheduleCategory_bool_exp | null | undefined;
    eventScheduleCategories_aggregate?: eventScheduleCategory_aggregate_bool_exp | null | undefined;
    eventSeriesCategories?: eventSeriesCategory_bool_exp | null | undefined;
    eventSeriesCategories_aggregate?: eventSeriesCategory_aggregate_bool_exp | null | undefined;
    gender?: jsonb_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    maxAge?: Int_comparison_exp | null | undefined;
    minAge?: Int_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    registrationCategories?: registrationCategory_bool_exp | null | undefined;
    registrationCategories_aggregate?: registrationCategory_aggregate_bool_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    spotsRemaining?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    visible?: Boolean_comparison_exp | null | undefined;
};
export type jsonb_comparison_exp = {
    _cast?: jsonb_cast_exp | null | undefined;
    _contained_in?: Record<string, unknown> | unknown[] | null | undefined;
    _contains?: Record<string, unknown> | unknown[] | null | undefined;
    _eq?: Record<string, unknown> | unknown[] | null | undefined;
    _gt?: Record<string, unknown> | unknown[] | null | undefined;
    _gte?: Record<string, unknown> | unknown[] | null | undefined;
    _has_key?: string | null | undefined;
    _has_keys_all?: Array<string> | null | undefined;
    _has_keys_any?: Array<string> | null | undefined;
    _in?: Array<Record<string, unknown> | unknown[]> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: Record<string, unknown> | unknown[] | null | undefined;
    _lte?: Record<string, unknown> | unknown[] | null | undefined;
    _neq?: Record<string, unknown> | unknown[] | null | undefined;
    _nin?: Array<Record<string, unknown> | unknown[]> | null | undefined;
};
export type jsonb_cast_exp = {
    String?: String_comparison_exp | null | undefined;
};
export type categoryCourse_bool_exp = {
    _and?: Array<categoryCourse_bool_exp> | null | undefined;
    _not?: categoryCourse_bool_exp | null | undefined;
    _or?: Array<categoryCourse_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    course?: course_bool_exp | null | undefined;
    courseCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type course_bool_exp = {
    _and?: Array<course_bool_exp> | null | undefined;
    _not?: course_bool_exp | null | undefined;
    _or?: Array<course_bool_exp> | null | undefined;
    categoryCourses?: categoryCourse_bool_exp | null | undefined;
    categoryCourses_aggregate?: categoryCourse_aggregate_bool_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    courseLength?: numeric_comparison_exp | null | undefined;
    courseLengthSecondary?: numeric_comparison_exp | null | undefined;
    courseLengthType?: String_comparison_exp | null | undefined;
    courseLengthTypeSecondary?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    difficultyName?: String_comparison_exp | null | undefined;
    distance?: numeric_comparison_exp | null | undefined;
    elevationHigh?: numeric_comparison_exp | null | undefined;
    elevationLow?: numeric_comparison_exp | null | undefined;
    elevationUnit?: String_comparison_exp | null | undefined;
    endLat?: numeric_comparison_exp | null | undefined;
    endLng?: numeric_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventDiscipline?: eventDiscipline_bool_exp | null | undefined;
    eventDisciplineCuid?: String_comparison_exp | null | undefined;
    fullName?: String_comparison_exp | null | undefined;
    geoJson?: jsonb_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    maxGrade?: numeric_comparison_exp | null | undefined;
    minGrade?: Int_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    platform?: String_comparison_exp | null | undefined;
    platformId?: Int_comparison_exp | null | undefined;
    platformUrl?: String_comparison_exp | null | undefined;
    startLat?: numeric_comparison_exp | null | undefined;
    startLng?: numeric_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    totalElevationGain?: numeric_comparison_exp | null | undefined;
    totalElevationLoss?: numeric_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type categoryCourse_aggregate_bool_exp = {
    count?: categoryCourse_aggregate_bool_exp_count | null | undefined;
};
export type categoryCourse_aggregate_bool_exp_count = {
    arguments?: Array<categoryCourse_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: categoryCourse_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type numeric_comparison_exp = {
    _eq?: number | null | undefined;
    _gt?: number | null | undefined;
    _gte?: number | null | undefined;
    _in?: Array<number> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: number | null | undefined;
    _lte?: number | null | undefined;
    _neq?: number | null | undefined;
    _nin?: Array<number> | null | undefined;
};
export type eventDiscipline_bool_exp = {
    _and?: Array<eventDiscipline_bool_exp> | null | undefined;
    _not?: eventDiscipline_bool_exp | null | undefined;
    _or?: Array<eventDiscipline_bool_exp> | null | undefined;
    allowWaitlist?: Boolean_comparison_exp | null | undefined;
    categories?: category_bool_exp | null | undefined;
    categories_aggregate?: category_aggregate_bool_exp | null | undefined;
    courses?: course_bool_exp | null | undefined;
    courses_aggregate?: course_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    disciplineName?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    fieldLimit?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type category_aggregate_bool_exp = {
    bool_and?: category_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: category_aggregate_bool_exp_bool_or | null | undefined;
    count?: category_aggregate_bool_exp_count | null | undefined;
};
export type category_aggregate_bool_exp_bool_and = {
    arguments: category_select_column_category_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: category_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type category_aggregate_bool_exp_bool_or = {
    arguments: category_select_column_category_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: category_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type category_aggregate_bool_exp_count = {
    arguments?: Array<category_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: category_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type course_aggregate_bool_exp = {
    count?: course_aggregate_bool_exp_count | null | undefined;
};
export type course_aggregate_bool_exp_count = {
    arguments?: Array<course_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: course_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type bigint_comparison_exp = {
    _eq?: number | null | undefined;
    _gt?: number | null | undefined;
    _gte?: number | null | undefined;
    _in?: Array<number> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: number | null | undefined;
    _lte?: number | null | undefined;
    _neq?: number | null | undefined;
    _nin?: Array<number> | null | undefined;
};
export type eventBasicWaiverCategory_bool_exp = {
    _and?: Array<eventBasicWaiverCategory_bool_exp> | null | undefined;
    _not?: eventBasicWaiverCategory_bool_exp | null | undefined;
    _or?: Array<eventBasicWaiverCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventBasicWaiver?: eventBasicWaiver_bool_exp | null | undefined;
    eventBasicWaiverCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventBasicWaiver_bool_exp = {
    _and?: Array<eventBasicWaiver_bool_exp> | null | undefined;
    _not?: eventBasicWaiver_bool_exp | null | undefined;
    _or?: Array<eventBasicWaiver_bool_exp> | null | undefined;
    appliesToAllCategories?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventBasicWaiverCategories?: eventBasicWaiverCategory_bool_exp | null | undefined;
    eventBasicWaiverCategories_aggregate?: eventBasicWaiverCategory_aggregate_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    fileRef?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventBasicWaiverCategory_aggregate_bool_exp = {
    count?: eventBasicWaiverCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventBasicWaiverCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventBasicWaiverCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventBasicWaiverCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventCustomQuestionCategory_bool_exp = {
    _and?: Array<eventCustomQuestionCategory_bool_exp> | null | undefined;
    _not?: eventCustomQuestionCategory_bool_exp | null | undefined;
    _or?: Array<eventCustomQuestionCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventCustomQuestion?: eventCustomQuestion_bool_exp | null | undefined;
    eventCustomQuestionCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventCustomQuestion_bool_exp = {
    _and?: Array<eventCustomQuestion_bool_exp> | null | undefined;
    _not?: eventCustomQuestion_bool_exp | null | undefined;
    _or?: Array<eventCustomQuestion_bool_exp> | null | undefined;
    additionalInfo?: String_comparison_exp | null | undefined;
    appliesToAllCategories?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventCustomQuestionCategories?: eventCustomQuestionCategory_bool_exp | null | undefined;
    eventCustomQuestionCategories_aggregate?: eventCustomQuestionCategory_aggregate_bool_exp | null | undefined;
    eventCustomQuestionOptions?: eventCustomQuestionOption_bool_exp | null | undefined;
    eventCustomQuestionOptions_aggregate?: eventCustomQuestionOption_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    internalPurpose?: String_comparison_exp | null | undefined;
    isRequired?: Boolean_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    questionType?: String_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventCustomQuestionCategory_aggregate_bool_exp = {
    count?: eventCustomQuestionCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventCustomQuestionCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventCustomQuestionCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventCustomQuestionCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventCustomQuestionOption_bool_exp = {
    _and?: Array<eventCustomQuestionOption_bool_exp> | null | undefined;
    _not?: eventCustomQuestionOption_bool_exp | null | undefined;
    _or?: Array<eventCustomQuestionOption_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventCustomQuestion?: eventCustomQuestion_bool_exp | null | undefined;
    eventCustomQuestionCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventCustomQuestionOption_aggregate_bool_exp = {
    count?: eventCustomQuestionOption_aggregate_bool_exp_count | null | undefined;
};
export type eventCustomQuestionOption_aggregate_bool_exp_count = {
    arguments?: Array<eventCustomQuestionOption_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventCustomQuestionOption_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPricingGroupCategory_bool_exp = {
    _and?: Array<eventPricingGroupCategory_bool_exp> | null | undefined;
    _not?: eventPricingGroupCategory_bool_exp | null | undefined;
    _or?: Array<eventPricingGroupCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventPricingGroup?: eventPricingGroup_bool_exp | null | undefined;
    eventPricingGroupCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPricingGroup_bool_exp = {
    _and?: Array<eventPricingGroup_bool_exp> | null | undefined;
    _not?: eventPricingGroup_bool_exp | null | undefined;
    _or?: Array<eventPricingGroup_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventPricingChanges?: eventPricingChange_bool_exp | null | undefined;
    eventPricingChanges_aggregate?: eventPricingChange_aggregate_bool_exp | null | undefined;
    eventPricingGroupCategories?: eventPricingGroupCategory_bool_exp | null | undefined;
    eventPricingGroupCategories_aggregate?: eventPricingGroupCategory_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPricingChange_bool_exp = {
    _and?: Array<eventPricingChange_bool_exp> | null | undefined;
    _not?: eventPricingChange_bool_exp | null | undefined;
    _or?: Array<eventPricingChange_bool_exp> | null | undefined;
    amount?: numeric_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventPricingDate?: eventPricingDate_bool_exp | null | undefined;
    eventPricingDateCuid?: String_comparison_exp | null | undefined;
    eventPricingGroup?: eventPricingGroup_bool_exp | null | undefined;
    eventPricingGroupCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPricingDate_bool_exp = {
    _and?: Array<eventPricingDate_bool_exp> | null | undefined;
    _not?: eventPricingDate_bool_exp | null | undefined;
    _or?: Array<eventPricingDate_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    date?: timestamptz_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventPricingChanges?: eventPricingChange_bool_exp | null | undefined;
    eventPricingChanges_aggregate?: eventPricingChange_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPricingChange_aggregate_bool_exp = {
    count?: eventPricingChange_aggregate_bool_exp_count | null | undefined;
};
export type eventPricingChange_aggregate_bool_exp_count = {
    arguments?: Array<eventPricingChange_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPricingChange_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPricingGroupCategory_aggregate_bool_exp = {
    count?: eventPricingGroupCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventPricingGroupCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventPricingGroupCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPricingGroupCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventSanctionCategory_bool_exp = {
    _and?: Array<eventSanctionCategory_bool_exp> | null | undefined;
    _not?: eventSanctionCategory_bool_exp | null | undefined;
    _or?: Array<eventSanctionCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventSanction?: eventSanction_bool_exp | null | undefined;
    eventSanctionCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSanction_bool_exp = {
    _and?: Array<eventSanction_bool_exp> | null | undefined;
    _not?: eventSanction_bool_exp | null | undefined;
    _or?: Array<eventSanction_bool_exp> | null | undefined;
    allowOneDayLicense?: Boolean_comparison_exp | null | undefined;
    appliesToAllCategories?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSanctionCategories?: eventSanctionCategory_bool_exp | null | undefined;
    eventSanctionCategories_aggregate?: eventSanctionCategory_aggregate_bool_exp | null | undefined;
    eventWaivers?: eventWaiver_bool_exp | null | undefined;
    eventWaivers_aggregate?: eventWaiver_aggregate_bool_exp | null | undefined;
    excludedWaiverCuids?: jsonb_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isLicenseRequired?: Boolean_comparison_exp | null | undefined;
    oneDayFee?: numeric_comparison_exp | null | undefined;
    otherSanctionName?: String_comparison_exp | null | undefined;
    permit?: String_comparison_exp | null | undefined;
    sanction?: sanction_bool_exp | null | undefined;
    sanctionCuid?: String_comparison_exp | null | undefined;
    sanctionWaivers?: sanctionWaivers_bool_exp | null | undefined;
    sanctionWaivers_aggregate?: sanctionWaivers_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSanctionCategory_aggregate_bool_exp = {
    count?: eventSanctionCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventSanctionCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventSanctionCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSanctionCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventWaiver_bool_exp = {
    _and?: Array<eventWaiver_bool_exp> | null | undefined;
    _not?: eventWaiver_bool_exp | null | undefined;
    _or?: Array<eventWaiver_bool_exp> | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSanction?: eventSanction_bool_exp | null | undefined;
    eventSanctionCuid?: String_comparison_exp | null | undefined;
    eventWaiverType?: eventWaiverType_bool_exp | null | undefined;
    type?: eventWaiverType_enum_comparison_exp | null | undefined;
    waiver?: waiver_bool_exp | null | undefined;
    waiverCuid?: String_comparison_exp | null | undefined;
};
export type eventWaiverType_bool_exp = {
    _and?: Array<eventWaiverType_bool_exp> | null | undefined;
    _not?: eventWaiverType_bool_exp | null | undefined;
    _or?: Array<eventWaiverType_bool_exp> | null | undefined;
    description?: String_comparison_exp | null | undefined;
    eventWaivers?: eventWaiver_bool_exp | null | undefined;
    eventWaivers_aggregate?: eventWaiver_aggregate_bool_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
};
export type eventWaiver_aggregate_bool_exp = {
    count?: eventWaiver_aggregate_bool_exp_count | null | undefined;
};
export type eventWaiver_aggregate_bool_exp_count = {
    arguments?: Array<eventWaiver_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventWaiver_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventWaiverType_enum_comparison_exp = {
    _eq?: eventWaiverType_enum | null | undefined;
    _in?: Array<eventWaiverType_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: eventWaiverType_enum | null | undefined;
    _nin?: Array<eventWaiverType_enum> | null | undefined;
};
export type waiver_bool_exp = {
    _and?: Array<waiver_bool_exp> | null | undefined;
    _not?: waiver_bool_exp | null | undefined;
    _or?: Array<waiver_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    latestRevision?: waiverRevision_bool_exp | null | undefined;
    latestRevisionNum?: Int_comparison_exp | null | undefined;
    ownerCuid?: String_comparison_exp | null | undefined;
    ownerType?: waiverOwner_enum_comparison_exp | null | undefined;
    revisions?: waiverRevision_bool_exp | null | undefined;
    revisions_aggregate?: waiverRevision_aggregate_bool_exp | null | undefined;
    sanctionWaivers?: sanctionWaivers_bool_exp | null | undefined;
    sanctionWaivers_aggregate?: sanctionWaivers_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    waiverOwner?: waiverOwner_bool_exp | null | undefined;
};
export type waiverRevision_bool_exp = {
    _and?: Array<waiverRevision_bool_exp> | null | undefined;
    _not?: waiverRevision_bool_exp | null | undefined;
    _or?: Array<waiverRevision_bool_exp> | null | undefined;
    createdBy?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    fieldsConfiguration?: jsonb_comparison_exp | null | undefined;
    fileRef?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    revision?: Int_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    waiver?: waiver_bool_exp | null | undefined;
    waiverCuid?: String_comparison_exp | null | undefined;
    waivers?: waiver_bool_exp | null | undefined;
    waivers_aggregate?: waiver_aggregate_bool_exp | null | undefined;
};
export type user_bool_exp = {
    _and?: Array<user_bool_exp> | null | undefined;
    _not?: user_bool_exp | null | undefined;
    _or?: Array<user_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachById?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    coachCustomerUsers?: coachCustomerUser_bool_exp | null | undefined;
    coachCustomerUsers_aggregate?: coachCustomerUser_aggregate_bool_exp | null | undefined;
    coachModerationFlags?: coachModerationFlag_bool_exp | null | undefined;
    coachModerationFlags_aggregate?: coachModerationFlag_aggregate_bool_exp | null | undefined;
    coachSetupStep?: Int_comparison_exp | null | undefined;
    coachSetupType?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    creatorInvitesByAcceptor?: creatorInvite_bool_exp | null | undefined;
    creatorInvitesByAcceptor_aggregate?: creatorInvite_aggregate_bool_exp | null | undefined;
    creatorInvitesByInviter?: creatorInvite_bool_exp | null | undefined;
    creatorInvitesByInviter_aggregate?: creatorInvite_aggregate_bool_exp | null | undefined;
    currency?: String_comparison_exp | null | undefined;
    cyclingGoal?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    dob?: date_comparison_exp | null | undefined;
    email?: String_comparison_exp | null | undefined;
    eventModerationFlags?: eventModerationFlag_bool_exp | null | undefined;
    eventModerationFlags_aggregate?: eventModerationFlag_aggregate_bool_exp | null | undefined;
    eventQuestionAnswers?: eventQuestionAnswer_bool_exp | null | undefined;
    eventQuestionAnswersDeletedByUser?: eventQuestionAnswer_bool_exp | null | undefined;
    eventQuestionAnswersDeletedByUser_aggregate?: eventQuestionAnswer_aggregate_bool_exp | null | undefined;
    eventQuestionAnswers_aggregate?: eventQuestionAnswer_aggregate_bool_exp | null | undefined;
    eventQuestions?: eventQuestion_bool_exp | null | undefined;
    eventQuestionsDeletedByUser?: eventQuestion_bool_exp | null | undefined;
    eventQuestionsDeletedByUser_aggregate?: eventQuestion_aggregate_bool_exp | null | undefined;
    eventQuestions_aggregate?: eventQuestion_aggregate_bool_exp | null | undefined;
    eventUpdateComments?: eventUpdateComment_bool_exp | null | undefined;
    eventUpdateCommentsDeletedByUser?: eventUpdateComment_bool_exp | null | undefined;
    eventUpdateCommentsDeletedByUser_aggregate?: eventUpdateComment_aggregate_bool_exp | null | undefined;
    eventUpdateComments_aggregate?: eventUpdateComment_aggregate_bool_exp | null | undefined;
    eventUpdates?: eventUpdate_bool_exp | null | undefined;
    eventUpdatesDeletedByUser?: eventUpdate_bool_exp | null | undefined;
    eventUpdatesDeletedByUser_aggregate?: eventUpdate_aggregate_bool_exp | null | undefined;
    eventUpdates_aggregate?: eventUpdate_aggregate_bool_exp | null | undefined;
    facebookProfile?: String_comparison_exp | null | undefined;
    firstName?: String_comparison_exp | null | undefined;
    gender?: jsonb_comparison_exp | null | undefined;
    id?: String_comparison_exp | null | undefined;
    idealRide?: String_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imageUsers?: imageUser_bool_exp | null | undefined;
    imageUsers_aggregate?: imageUser_aggregate_bool_exp | null | undefined;
    instagramProfile?: String_comparison_exp | null | undefined;
    isAdmin?: Boolean_comparison_exp | null | undefined;
    isCoach?: Boolean_comparison_exp | null | undefined;
    isCreator?: Boolean_comparison_exp | null | undefined;
    lastName?: String_comparison_exp | null | undefined;
    lastSavedAt?: timestamptz_comparison_exp | null | undefined;
    location?: String_comparison_exp | null | undefined;
    organizerChannels?: organizerChannel_bool_exp | null | undefined;
    organizerChannels_aggregate?: organizerChannel_aggregate_bool_exp | null | undefined;
    organizerInvitesByInvitee?: organizerInvite_bool_exp | null | undefined;
    organizerInvitesByInvitee_aggregate?: organizerInvite_aggregate_bool_exp | null | undefined;
    organizerInvitesByInviter?: organizerInvite_bool_exp | null | undefined;
    organizerInvitesByInviter_aggregate?: organizerInvite_aggregate_bool_exp | null | undefined;
    phone?: String_comparison_exp | null | undefined;
    profilePicPublicId?: String_comparison_exp | null | undefined;
    profilePicUrl?: String_comparison_exp | null | undefined;
    pronouns?: String_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrationsByPayinguserid?: registration_bool_exp | null | undefined;
    registrationsByPayinguserid_aggregate?: registration_aggregate_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    reportClinicNoShows?: reportClinicNoShow_bool_exp | null | undefined;
    reportClinicNoShows_aggregate?: reportClinicNoShow_aggregate_bool_exp | null | undefined;
    reportLessonNoShows?: reportLessonNoShow_bool_exp | null | undefined;
    reportLessonNoShows_aggregate?: reportLessonNoShow_aggregate_bool_exp | null | undefined;
    reportedAsClinicNoShows?: reportClinicNoShow_bool_exp | null | undefined;
    reportedAsClinicNoShows_aggregate?: reportClinicNoShow_aggregate_bool_exp | null | undefined;
    reportedAsLessonNoShows?: reportLessonNoShow_bool_exp | null | undefined;
    reportedAsLessonNoShows_aggregate?: reportLessonNoShow_aggregate_bool_exp | null | undefined;
    reviews?: review_bool_exp | null | undefined;
    reviews_aggregate?: review_aggregate_bool_exp | null | undefined;
    ridingSince?: Int_comparison_exp | null | undefined;
    selectedOrganizerSlug?: String_comparison_exp | null | undefined;
    showSetupCoach?: Boolean_comparison_exp | null | undefined;
    slug?: String_comparison_exp | null | undefined;
    sponsors?: String_comparison_exp | null | undefined;
    stravaProfile?: String_comparison_exp | null | undefined;
    stripeConnectAccount?: stripeConnectAccount_bool_exp | null | undefined;
    stripeConnectAccounts?: stripeConnectAccount_bool_exp | null | undefined;
    stripeConnectAccounts_aggregate?: stripeConnectAccount_aggregate_bool_exp | null | undefined;
    stripeConnectId?: String_comparison_exp | null | undefined;
    stripeCustomerId?: String_comparison_exp | null | undefined;
    stripeIdentityVerificationError?: String_comparison_exp | null | undefined;
    stripeIdentityVerificationSessionId?: String_comparison_exp | null | undefined;
    stripeIdentityVerificationStatus?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    twitterProfile?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userAddresses?: userAddress_bool_exp | null | undefined;
    userAddresses_aggregate?: userAddress_aggregate_bool_exp | null | undefined;
    userBikes?: userBike_bool_exp | null | undefined;
    userBikes_aggregate?: userBike_aggregate_bool_exp | null | undefined;
    userCoaches?: coach_bool_exp | null | undefined;
    userCoaches_aggregate?: coach_aggregate_bool_exp | null | undefined;
    userEmergencyContacts?: userEmergencyContact_bool_exp | null | undefined;
    userEmergencyContacts_aggregate?: userEmergencyContact_aggregate_bool_exp | null | undefined;
    userImages?: userImage_bool_exp | null | undefined;
    userImages_aggregate?: userImage_aggregate_bool_exp | null | undefined;
    userOnlineCourses?: userOnlineCourse_bool_exp | null | undefined;
    userOnlineCourses_aggregate?: userOnlineCourse_aggregate_bool_exp | null | undefined;
    userOrganizerRoles?: userOrganizerRole_bool_exp | null | undefined;
    userOrganizerRoles_aggregate?: userOrganizerRole_aggregate_bool_exp | null | undefined;
    userPersonalizations?: userPersonalization_bool_exp | null | undefined;
    userPersonalizations_aggregate?: userPersonalization_aggregate_bool_exp | null | undefined;
    userRidingInterests?: userRidingInterest_bool_exp | null | undefined;
    userRidingInterests_aggregate?: userRidingInterest_aggregate_bool_exp | null | undefined;
    userSanctionLicenses?: userSanctionLicense_bool_exp | null | undefined;
    userSanctionLicenses_aggregate?: userSanctionLicense_aggregate_bool_exp | null | undefined;
    userSavedCoaches?: userSavedCoach_bool_exp | null | undefined;
    userSavedCoaches_aggregate?: userSavedCoach_aggregate_bool_exp | null | undefined;
    userSavedEvents?: userSavedEvent_bool_exp | null | undefined;
    userSavedEvents_aggregate?: userSavedEvent_aggregate_bool_exp | null | undefined;
    userSavedLessons?: userSavedLesson_bool_exp | null | undefined;
    userSavedLessons_aggregate?: userSavedLesson_aggregate_bool_exp | null | undefined;
    waiverRevisions?: waiverRevision_bool_exp | null | undefined;
    waiverRevisions_aggregate?: waiverRevision_aggregate_bool_exp | null | undefined;
};
export type coach_bool_exp = {
    _and?: Array<coach_bool_exp> | null | undefined;
    _not?: coach_bool_exp | null | undefined;
    _or?: Array<coach_bool_exp> | null | undefined;
    about?: String_comparison_exp | null | undefined;
    addRacingExp?: Boolean_comparison_exp | null | undefined;
    allowRequests?: Boolean_comparison_exp | null | undefined;
    calendarId?: String_comparison_exp | null | undefined;
    clinicCoaches?: clinicCoach_bool_exp | null | undefined;
    clinicCoaches_aggregate?: clinicCoach_aggregate_bool_exp | null | undefined;
    coachAvailabilities?: coachAvailability_bool_exp | null | undefined;
    coachAvailabilities_aggregate?: coachAvailability_aggregate_bool_exp | null | undefined;
    coachCertifications?: coachCertification_bool_exp | null | undefined;
    coachCertifications_aggregate?: coachCertification_aggregate_bool_exp | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessons_aggregate?: coachCustomerLesson_aggregate_bool_exp | null | undefined;
    coachCustomers?: coachCustomer_bool_exp | null | undefined;
    coachCustomers_aggregate?: coachCustomer_aggregate_bool_exp | null | undefined;
    coachDisciplines?: coachDiscipline_bool_exp | null | undefined;
    coachDisciplines_aggregate?: coachDiscipline_aggregate_bool_exp | null | undefined;
    coachImages?: coachImage_bool_exp | null | undefined;
    coachImages_aggregate?: coachImage_aggregate_bool_exp | null | undefined;
    coachLessons?: coachLesson_bool_exp | null | undefined;
    coachLessons_aggregate?: coachLesson_aggregate_bool_exp | null | undefined;
    coachLocations?: coachLocation_bool_exp | null | undefined;
    coachLocations_aggregate?: coachLocation_aggregate_bool_exp | null | undefined;
    coachModerationFlags?: coachModerationFlag_bool_exp | null | undefined;
    coachModerationFlags_aggregate?: coachModerationFlag_aggregate_bool_exp | null | undefined;
    coachStudents?: coachStudent_bool_exp | null | undefined;
    coachStudents_aggregate?: coachStudent_aggregate_bool_exp | null | undefined;
    coachUnavailableDates?: coachUnavailableDate_bool_exp | null | undefined;
    coachUnavailableDates_aggregate?: coachUnavailableDate_aggregate_bool_exp | null | undefined;
    coachingRadius?: Int_comparison_exp | null | undefined;
    coachingSince?: Int_comparison_exp | null | undefined;
    coachingVibe?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    currencyCode?: String_comparison_exp | null | undefined;
    experience?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    introVideoThumbnailUrl?: String_comparison_exp | null | undefined;
    introVideoUrl?: String_comparison_exp | null | undefined;
    isCPRCertified?: Boolean_comparison_exp | null | undefined;
    isFirstAidCertified?: Boolean_comparison_exp | null | undefined;
    isSuperCoach?: Boolean_comparison_exp | null | undefined;
    lessonLocations?: lessonLocation_bool_exp | null | undefined;
    lessonLocations_aggregate?: lessonLocation_aggregate_bool_exp | null | undefined;
    offerCustomLesson?: Boolean_comparison_exp | null | undefined;
    onlineCourses?: onlineCourse_bool_exp | null | undefined;
    onlineCourses_aggregate?: onlineCourse_aggregate_bool_exp | null | undefined;
    openToTravel?: Boolean_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuidToDisplay?: String_comparison_exp | null | undefined;
    racingExp?: String_comparison_exp | null | undefined;
    reviews?: review_bool_exp | null | undefined;
    reviews_aggregate?: review_aggregate_bool_exp | null | undefined;
    showProfileHelpBanner?: Boolean_comparison_exp | null | undefined;
    syncLocationToProfile?: Boolean_comparison_exp | null | undefined;
    tagline?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    userSavedCoaches?: userSavedCoach_bool_exp | null | undefined;
    userSavedCoaches_aggregate?: userSavedCoach_aggregate_bool_exp | null | undefined;
    users?: user_bool_exp | null | undefined;
    users_aggregate?: user_aggregate_bool_exp | null | undefined;
    yearStarted?: Int_comparison_exp | null | undefined;
};
export type clinicCoach_bool_exp = {
    _and?: Array<clinicCoach_bool_exp> | null | undefined;
    _not?: clinicCoach_bool_exp | null | undefined;
    _or?: Array<clinicCoach_bool_exp> | null | undefined;
    clinic?: clinic_bool_exp | null | undefined;
    clinicCuid?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type clinic_bool_exp = {
    _and?: Array<clinic_bool_exp> | null | undefined;
    _not?: clinic_bool_exp | null | undefined;
    _or?: Array<clinic_bool_exp> | null | undefined;
    canceledAt?: timestamptz_comparison_exp | null | undefined;
    cancellationReason?: String_comparison_exp | null | undefined;
    clinicCoaches?: clinicCoach_bool_exp | null | undefined;
    clinicCoaches_aggregate?: clinicCoach_aggregate_bool_exp | null | undefined;
    clinicDays?: clinicDay_bool_exp | null | undefined;
    clinicDays_aggregate?: clinicDay_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    dayOfContact?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    meetingLink?: String_comparison_exp | null | undefined;
    priceOverride?: numeric_comparison_exp | null | undefined;
    registrationStatus?: String_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    spotsRemaining?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type clinicCoach_aggregate_bool_exp = {
    count?: clinicCoach_aggregate_bool_exp_count | null | undefined;
};
export type clinicCoach_aggregate_bool_exp_count = {
    arguments?: Array<clinicCoach_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: clinicCoach_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type clinicDay_bool_exp = {
    _and?: Array<clinicDay_bool_exp> | null | undefined;
    _not?: clinicDay_bool_exp | null | undefined;
    _or?: Array<clinicDay_bool_exp> | null | undefined;
    clinic?: clinic_bool_exp | null | undefined;
    clinicCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventClinicDayDuration?: eventClinicDayDuration_bool_exp | null | undefined;
    eventClinicDayDurationCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    reportClinicNoShows?: reportClinicNoShow_bool_exp | null | undefined;
    reportClinicNoShows_aggregate?: reportClinicNoShow_aggregate_bool_exp | null | undefined;
    startTime?: timestamp_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventClinicDayDuration_bool_exp = {
    _and?: Array<eventClinicDayDuration_bool_exp> | null | undefined;
    _not?: eventClinicDayDuration_bool_exp | null | undefined;
    _or?: Array<eventClinicDayDuration_bool_exp> | null | undefined;
    clinicDays?: clinicDay_bool_exp | null | undefined;
    clinicDays_aggregate?: clinicDay_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    dayNumber?: Int_comparison_exp | null | undefined;
    duration?: smallint_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type clinicDay_aggregate_bool_exp = {
    count?: clinicDay_aggregate_bool_exp_count | null | undefined;
};
export type clinicDay_aggregate_bool_exp_count = {
    arguments?: Array<clinicDay_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: clinicDay_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type smallint_comparison_exp = {
    _eq?: unknown | null | undefined;
    _gt?: unknown | null | undefined;
    _gte?: unknown | null | undefined;
    _in?: Array<unknown> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: unknown | null | undefined;
    _lte?: unknown | null | undefined;
    _neq?: unknown | null | undefined;
    _nin?: Array<unknown> | null | undefined;
};
export type reportClinicNoShow_bool_exp = {
    _and?: Array<reportClinicNoShow_bool_exp> | null | undefined;
    _not?: reportClinicNoShow_bool_exp | null | undefined;
    _or?: Array<reportClinicNoShow_bool_exp> | null | undefined;
    clinicDay?: clinicDay_bool_exp | null | undefined;
    clinicDayCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    reportedUser?: user_bool_exp | null | undefined;
    reportedUserId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userDistance?: numeric_comparison_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    userIsCoach?: Boolean_comparison_exp | null | undefined;
    userLocation?: jsonb_comparison_exp | null | undefined;
};
export type reportClinicNoShow_aggregate_bool_exp = {
    bool_and?: reportClinicNoShow_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: reportClinicNoShow_aggregate_bool_exp_bool_or | null | undefined;
    count?: reportClinicNoShow_aggregate_bool_exp_count | null | undefined;
};
export type reportClinicNoShow_aggregate_bool_exp_bool_and = {
    arguments: reportClinicNoShow_select_column_reportClinicNoShow_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: reportClinicNoShow_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type reportClinicNoShow_aggregate_bool_exp_bool_or = {
    arguments: reportClinicNoShow_select_column_reportClinicNoShow_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: reportClinicNoShow_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type reportClinicNoShow_aggregate_bool_exp_count = {
    arguments?: Array<reportClinicNoShow_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: reportClinicNoShow_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type timestamp_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
};
export type registration_bool_exp = {
    _and?: Array<registration_bool_exp> | null | undefined;
    _not?: registration_bool_exp | null | undefined;
    _or?: Array<registration_bool_exp> | null | undefined;
    activity?: registrationActivity_bool_exp | null | undefined;
    address?: String_comparison_exp | null | undefined;
    addressCity?: String_comparison_exp | null | undefined;
    addressCountry?: String_comparison_exp | null | undefined;
    addressLine1?: String_comparison_exp | null | undefined;
    addressLine2?: String_comparison_exp | null | undefined;
    addressState?: String_comparison_exp | null | undefined;
    addressZip?: String_comparison_exp | null | undefined;
    basicSignatureRequests?: basicSignatureRequest_bool_exp | null | undefined;
    basicSignatureRequests_aggregate?: basicSignatureRequest_aggregate_bool_exp | null | undefined;
    clinic?: clinic_bool_exp | null | undefined;
    clinicCuid?: String_comparison_exp | null | undefined;
    completedAt?: timestamptz_comparison_exp | null | undefined;
    contactName?: String_comparison_exp | null | undefined;
    contactPhone?: String_comparison_exp | null | undefined;
    contactRelationship?: String_comparison_exp | null | undefined;
    context?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    customQuestions?: jsonb_comparison_exp | null | undefined;
    dob?: date_comparison_exp | null | undefined;
    email?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    firstName?: String_comparison_exp | null | undefined;
    for?: registrationFor_enum_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isCheckedIn?: Boolean_comparison_exp | null | undefined;
    lastName?: String_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    payingUser?: user_bool_exp | null | undefined;
    payingUserId?: String_comparison_exp | null | undefined;
    phone?: String_comparison_exp | null | undefined;
    promoCode?: jsonb_comparison_exp | null | undefined;
    referenceNo?: String_comparison_exp | null | undefined;
    registrationAdditionalPayments?: registrationAdditionalPayment_bool_exp | null | undefined;
    registrationAdditionalPayments_aggregate?: registrationAdditionalPayment_aggregate_bool_exp | null | undefined;
    registrationCategories?: registrationCategory_bool_exp | null | undefined;
    registrationCategories_aggregate?: registrationCategory_aggregate_bool_exp | null | undefined;
    registrationFor?: registrationFor_bool_exp | null | undefined;
    registrationSeries?: registrationSeries_bool_exp | null | undefined;
    registrationSeries_aggregate?: registrationSeries_aggregate_bool_exp | null | undefined;
    registrationStatus?: registrationStatus_bool_exp | null | undefined;
    registrationTickets?: registrationTicket_bool_exp | null | undefined;
    registrationTickets_aggregate?: registrationTicket_aggregate_bool_exp | null | undefined;
    ride?: ride_bool_exp | null | undefined;
    rideCuid?: String_comparison_exp | null | undefined;
    sanctionLicenses?: jsonb_comparison_exp | null | undefined;
    signatureRequests?: signatureRequest_bool_exp | null | undefined;
    signatureRequests_aggregate?: signatureRequest_aggregate_bool_exp | null | undefined;
    sponsors?: String_comparison_exp | null | undefined;
    status?: registrationStatus_enum_comparison_exp | null | undefined;
    stripeCheckoutId?: String_comparison_exp | null | undefined;
    stripePaymentIntentId?: String_comparison_exp | null | undefined;
    successToken?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type registrationActivity_bool_exp = {
    _and?: Array<registrationActivity_bool_exp> | null | undefined;
    _not?: registrationActivity_bool_exp | null | undefined;
    _or?: Array<registrationActivity_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    log?: jsonb_comparison_exp | null | undefined;
    notes?: String_comparison_exp | null | undefined;
    referenceNo?: String_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type registration_aggregate_bool_exp = {
    bool_and?: registration_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: registration_aggregate_bool_exp_bool_or | null | undefined;
    count?: registration_aggregate_bool_exp_count | null | undefined;
};
export type registration_aggregate_bool_exp_bool_and = {
    arguments: registration_select_column_registration_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: registration_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type registration_aggregate_bool_exp_bool_or = {
    arguments: registration_select_column_registration_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: registration_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type registration_aggregate_bool_exp_count = {
    arguments?: Array<registration_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: registration_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type basicSignatureRequest_bool_exp = {
    _and?: Array<basicSignatureRequest_bool_exp> | null | undefined;
    _not?: basicSignatureRequest_bool_exp | null | undefined;
    _or?: Array<basicSignatureRequest_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
    signatureRequestSignerType?: signatureRequestSignerType_bool_exp | null | undefined;
    signatureRequestStatus?: signatureRequestStatus_bool_exp | null | undefined;
    signerEmail?: String_comparison_exp | null | undefined;
    signerName?: String_comparison_exp | null | undefined;
    signerType?: signatureRequestSignerType_enum_comparison_exp | null | undefined;
    status?: signatureRequestStatus_enum_comparison_exp | null | undefined;
    successToken?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    waiverFileUrl?: String_comparison_exp | null | undefined;
    waiverName?: String_comparison_exp | null | undefined;
};
export type signatureRequestSignerType_bool_exp = {
    _and?: Array<signatureRequestSignerType_bool_exp> | null | undefined;
    _not?: signatureRequestSignerType_bool_exp | null | undefined;
    _or?: Array<signatureRequestSignerType_bool_exp> | null | undefined;
    basicSignatureRequests?: basicSignatureRequest_bool_exp | null | undefined;
    basicSignatureRequests_aggregate?: basicSignatureRequest_aggregate_bool_exp | null | undefined;
    signatureRequests?: signatureRequest_bool_exp | null | undefined;
    signatureRequests_aggregate?: signatureRequest_aggregate_bool_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
};
export type basicSignatureRequest_aggregate_bool_exp = {
    count?: basicSignatureRequest_aggregate_bool_exp_count | null | undefined;
};
export type basicSignatureRequest_aggregate_bool_exp_count = {
    arguments?: Array<basicSignatureRequest_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: basicSignatureRequest_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type signatureRequest_bool_exp = {
    _and?: Array<signatureRequest_bool_exp> | null | undefined;
    _not?: signatureRequest_bool_exp | null | undefined;
    _or?: Array<signatureRequest_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
    signatureId?: String_comparison_exp | null | undefined;
    signatureRequestId?: String_comparison_exp | null | undefined;
    signatureRequestSignerType?: signatureRequestSignerType_bool_exp | null | undefined;
    signatureRequestStatus?: signatureRequestStatus_bool_exp | null | undefined;
    signerEmail?: String_comparison_exp | null | undefined;
    signerName?: String_comparison_exp | null | undefined;
    signerType?: signatureRequestSignerType_enum_comparison_exp | null | undefined;
    status?: signatureRequestStatus_enum_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    waiverRevision?: waiverRevision_bool_exp | null | undefined;
    waiverRevisionCuid?: String_comparison_exp | null | undefined;
};
export type signatureRequestStatus_bool_exp = {
    _and?: Array<signatureRequestStatus_bool_exp> | null | undefined;
    _not?: signatureRequestStatus_bool_exp | null | undefined;
    _or?: Array<signatureRequestStatus_bool_exp> | null | undefined;
    basicSignatureRequests?: basicSignatureRequest_bool_exp | null | undefined;
    basicSignatureRequests_aggregate?: basicSignatureRequest_aggregate_bool_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    signatureRequests?: signatureRequest_bool_exp | null | undefined;
    signatureRequests_aggregate?: signatureRequest_aggregate_bool_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
};
export type signatureRequest_aggregate_bool_exp = {
    count?: signatureRequest_aggregate_bool_exp_count | null | undefined;
};
export type signatureRequest_aggregate_bool_exp_count = {
    arguments?: Array<signatureRequest_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: signatureRequest_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type signatureRequestSignerType_enum_comparison_exp = {
    _eq?: signatureRequestSignerType_enum | null | undefined;
    _in?: Array<signatureRequestSignerType_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: signatureRequestSignerType_enum | null | undefined;
    _nin?: Array<signatureRequestSignerType_enum> | null | undefined;
};
export type signatureRequestStatus_enum_comparison_exp = {
    _eq?: signatureRequestStatus_enum | null | undefined;
    _in?: Array<signatureRequestStatus_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: signatureRequestStatus_enum | null | undefined;
    _nin?: Array<signatureRequestStatus_enum> | null | undefined;
};
export type date_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
};
export type registrationFor_enum_comparison_exp = {
    _eq?: registrationFor_enum | null | undefined;
    _in?: Array<registrationFor_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: registrationFor_enum | null | undefined;
    _nin?: Array<registrationFor_enum> | null | undefined;
};
export type registrationAdditionalPayment_bool_exp = {
    _and?: Array<registrationAdditionalPayment_bool_exp> | null | undefined;
    _not?: registrationAdditionalPayment_bool_exp | null | undefined;
    _or?: Array<registrationAdditionalPayment_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    paymentType?: String_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
    registrationStatus?: registrationStatus_bool_exp | null | undefined;
    status?: registrationStatus_enum_comparison_exp | null | undefined;
    stripeCheckoutId?: String_comparison_exp | null | undefined;
    stripePaymentIntentId?: String_comparison_exp | null | undefined;
    successToken?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type registrationStatus_bool_exp = {
    _and?: Array<registrationStatus_bool_exp> | null | undefined;
    _not?: registrationStatus_bool_exp | null | undefined;
    _or?: Array<registrationStatus_bool_exp> | null | undefined;
    registrationAdditionalPayments?: registrationAdditionalPayment_bool_exp | null | undefined;
    registrationAdditionalPayments_aggregate?: registrationAdditionalPayment_aggregate_bool_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    status?: String_comparison_exp | null | undefined;
};
export type registrationAdditionalPayment_aggregate_bool_exp = {
    count?: registrationAdditionalPayment_aggregate_bool_exp_count | null | undefined;
};
export type registrationAdditionalPayment_aggregate_bool_exp_count = {
    arguments?: Array<registrationAdditionalPayment_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: registrationAdditionalPayment_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type registrationStatus_enum_comparison_exp = {
    _eq?: registrationStatus_enum | null | undefined;
    _in?: Array<registrationStatus_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: registrationStatus_enum | null | undefined;
    _nin?: Array<registrationStatus_enum> | null | undefined;
};
export type registrationCategory_bool_exp = {
    _and?: Array<registrationCategory_bool_exp> | null | undefined;
    _not?: registrationCategory_bool_exp | null | undefined;
    _or?: Array<registrationCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
};
export type registrationCategory_aggregate_bool_exp = {
    count?: registrationCategory_aggregate_bool_exp_count | null | undefined;
};
export type registrationCategory_aggregate_bool_exp_count = {
    arguments?: Array<registrationCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: registrationCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type registrationFor_bool_exp = {
    _and?: Array<registrationFor_bool_exp> | null | undefined;
    _not?: registrationFor_bool_exp | null | undefined;
    _or?: Array<registrationFor_bool_exp> | null | undefined;
    for?: String_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
};
export type registrationSeries_bool_exp = {
    _and?: Array<registrationSeries_bool_exp> | null | undefined;
    _not?: registrationSeries_bool_exp | null | undefined;
    _or?: Array<registrationSeries_bool_exp> | null | undefined;
    eventSeries?: eventSeries_bool_exp | null | undefined;
    eventSeriesCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
};
export type eventSeries_bool_exp = {
    _and?: Array<eventSeries_bool_exp> | null | undefined;
    _not?: eventSeries_bool_exp | null | undefined;
    _or?: Array<eventSeries_bool_exp> | null | undefined;
    appliesToAllCategories?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSeriesCategories?: eventSeriesCategory_bool_exp | null | undefined;
    eventSeriesCategories_aggregate?: eventSeriesCategory_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    registrationSeries?: registrationSeries_bool_exp | null | undefined;
    registrationSeries_aggregate?: registrationSeries_aggregate_bool_exp | null | undefined;
    roundNumber?: Int_comparison_exp | null | undefined;
    series?: series_bool_exp | null | undefined;
    seriesCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSeriesCategory_bool_exp = {
    _and?: Array<eventSeriesCategory_bool_exp> | null | undefined;
    _not?: eventSeriesCategory_bool_exp | null | undefined;
    _or?: Array<eventSeriesCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventSeries?: eventSeries_bool_exp | null | undefined;
    eventSeriesCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSeriesCategory_aggregate_bool_exp = {
    count?: eventSeriesCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventSeriesCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventSeriesCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSeriesCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type registrationSeries_aggregate_bool_exp = {
    count?: registrationSeries_aggregate_bool_exp_count | null | undefined;
};
export type registrationSeries_aggregate_bool_exp_count = {
    arguments?: Array<registrationSeries_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: registrationSeries_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type series_bool_exp = {
    _and?: Array<series_bool_exp> | null | undefined;
    _not?: series_bool_exp | null | undefined;
    _or?: Array<series_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    eventSeries?: eventSeries_bool_exp | null | undefined;
    eventSeries_aggregate?: eventSeries_aggregate_bool_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isJoinable?: Boolean_comparison_exp | null | undefined;
    joinKey?: String_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    seriesActivity?: String_comparison_exp | null | undefined;
    seriesImages?: seriesImage_bool_exp | null | undefined;
    seriesImages_aggregate?: seriesImage_aggregate_bool_exp | null | undefined;
    seriesPointsStructureCustoms?: seriesPointsStructureCustom_bool_exp | null | undefined;
    seriesPointsStructureCustoms_aggregate?: seriesPointsStructureCustom_aggregate_bool_exp | null | undefined;
    seriesPointsStructurePresets?: seriesPointsStructurePreset_bool_exp | null | undefined;
    seriesPointsStructurePresets_aggregate?: seriesPointsStructurePreset_aggregate_bool_exp | null | undefined;
    slug?: String_comparison_exp | null | undefined;
    totalRounds?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSeries_aggregate_bool_exp = {
    bool_and?: eventSeries_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventSeries_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventSeries_aggregate_bool_exp_count | null | undefined;
};
export type eventSeries_aggregate_bool_exp_bool_and = {
    arguments: eventSeries_select_column_eventSeries_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSeries_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSeries_aggregate_bool_exp_bool_or = {
    arguments: eventSeries_select_column_eventSeries_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSeries_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSeries_aggregate_bool_exp_count = {
    arguments?: Array<eventSeries_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSeries_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type organizer_bool_exp = {
    _and?: Array<organizer_bool_exp> | null | undefined;
    _not?: organizer_bool_exp | null | undefined;
    _or?: Array<organizer_bool_exp> | null | undefined;
    about?: String_comparison_exp | null | undefined;
    coaches?: coach_bool_exp | null | undefined;
    coaches_aggregate?: coach_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    defaultCurrencyCode?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    eventMetadata?: eventMetadata_bool_exp | null | undefined;
    eventMetadata_aggregate?: eventMetadata_aggregate_bool_exp | null | undefined;
    events?: event_bool_exp | null | undefined;
    events_aggregate?: event_aggregate_bool_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    images?: image_bool_exp | null | undefined;
    images_aggregate?: image_aggregate_bool_exp | null | undefined;
    merchandises?: merchandise_bool_exp | null | undefined;
    merchandises_aggregate?: merchandise_aggregate_bool_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    onlineCourses?: onlineCourse_bool_exp | null | undefined;
    onlineCourses_aggregate?: onlineCourse_aggregate_bool_exp | null | undefined;
    organizerChannels?: organizerChannel_bool_exp | null | undefined;
    organizerChannels_aggregate?: organizerChannel_aggregate_bool_exp | null | undefined;
    organizerInvites?: organizerInvite_bool_exp | null | undefined;
    organizerInvites_aggregate?: organizerInvite_aggregate_bool_exp | null | undefined;
    series?: series_bool_exp | null | undefined;
    series_aggregate?: series_aggregate_bool_exp | null | undefined;
    showOnboarding?: Boolean_comparison_exp | null | undefined;
    slug?: String_comparison_exp | null | undefined;
    sponsors?: sponsor_bool_exp | null | undefined;
    sponsors_aggregate?: sponsor_aggregate_bool_exp | null | undefined;
    stripeConnectAccount?: stripeConnectAccount_bool_exp | null | undefined;
    stripeConnectAccounts?: stripeConnectAccount_bool_exp | null | undefined;
    stripeConnectAccounts_aggregate?: stripeConnectAccount_aggregate_bool_exp | null | undefined;
    stripeConnectId?: String_comparison_exp | null | undefined;
    tickets?: ticket_bool_exp | null | undefined;
    tickets_aggregate?: ticket_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userOrganizerRoles?: userOrganizerRole_bool_exp | null | undefined;
    userOrganizerRoles_aggregate?: userOrganizerRole_aggregate_bool_exp | null | undefined;
    venues?: venue_bool_exp | null | undefined;
    venues_aggregate?: venue_aggregate_bool_exp | null | undefined;
    website?: String_comparison_exp | null | undefined;
};
export type coach_aggregate_bool_exp = {
    bool_and?: coach_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coach_aggregate_bool_exp_bool_or | null | undefined;
    count?: coach_aggregate_bool_exp_count | null | undefined;
};
export type coach_aggregate_bool_exp_bool_and = {
    arguments: coach_select_column_coach_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coach_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coach_aggregate_bool_exp_bool_or = {
    arguments: coach_select_column_coach_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coach_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coach_aggregate_bool_exp_count = {
    arguments?: Array<coach_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coach_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventMetadata_bool_exp = {
    _and?: Array<eventMetadata_bool_exp> | null | undefined;
    _not?: eventMetadata_bool_exp | null | undefined;
    _or?: Array<eventMetadata_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    events?: event_bool_exp | null | undefined;
    events_aggregate?: event_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isCategoriedEvent?: Boolean_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    numberOfDays?: Int_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    slug?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventMetadata_aggregate_bool_exp = {
    bool_and?: eventMetadata_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventMetadata_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventMetadata_aggregate_bool_exp_count | null | undefined;
};
export type eventMetadata_aggregate_bool_exp_bool_and = {
    arguments: eventMetadata_select_column_eventMetadata_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventMetadata_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventMetadata_aggregate_bool_exp_bool_or = {
    arguments: eventMetadata_select_column_eventMetadata_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventMetadata_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventMetadata_aggregate_bool_exp_count = {
    arguments?: Array<eventMetadata_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventMetadata_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type image_bool_exp = {
    _and?: Array<image_bool_exp> | null | undefined;
    _not?: image_bool_exp | null | undefined;
    _or?: Array<image_bool_exp> | null | undefined;
    bytes?: Int_comparison_exp | null | undefined;
    colors?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    eventImage?: eventImage_bool_exp | null | undefined;
    eventImages?: eventImage_bool_exp | null | undefined;
    eventImages_aggregate?: eventImage_aggregate_bool_exp | null | undefined;
    format?: String_comparison_exp | null | undefined;
    height?: Int_comparison_exp | null | undefined;
    merchandiseImages?: merchandiseImage_bool_exp | null | undefined;
    merchandiseImages_aggregate?: merchandiseImage_aggregate_bool_exp | null | undefined;
    merchandiseVariants?: merchandiseVariant_bool_exp | null | undefined;
    merchandiseVariants_aggregate?: merchandiseVariant_aggregate_bool_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    organizers?: organizer_bool_exp | null | undefined;
    organizers_aggregate?: organizer_aggregate_bool_exp | null | undefined;
    originalFilename?: String_comparison_exp | null | undefined;
    publicId?: String_comparison_exp | null | undefined;
    relativeUrl?: String_comparison_exp | null | undefined;
    resourceType?: String_comparison_exp | null | undefined;
    seriesImages?: seriesImage_bool_exp | null | undefined;
    seriesImages_aggregate?: seriesImage_aggregate_bool_exp | null | undefined;
    sponsorImages?: sponsorImage_bool_exp | null | undefined;
    sponsorImages_aggregate?: sponsorImage_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    url?: String_comparison_exp | null | undefined;
    userImages?: userImage_bool_exp | null | undefined;
    userImages_aggregate?: userImage_aggregate_bool_exp | null | undefined;
    users?: user_bool_exp | null | undefined;
    users_aggregate?: user_aggregate_bool_exp | null | undefined;
    version?: Int_comparison_exp | null | undefined;
    width?: Int_comparison_exp | null | undefined;
};
export type eventImage_bool_exp = {
    _and?: Array<eventImage_bool_exp> | null | undefined;
    _not?: eventImage_bool_exp | null | undefined;
    _or?: Array<eventImage_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventImage_aggregate_bool_exp = {
    count?: eventImage_aggregate_bool_exp_count | null | undefined;
};
export type eventImage_aggregate_bool_exp_count = {
    arguments?: Array<eventImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseImage_bool_exp = {
    _and?: Array<merchandiseImage_bool_exp> | null | undefined;
    _not?: merchandiseImage_bool_exp | null | undefined;
    _or?: Array<merchandiseImage_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    merchandise?: merchandise_bool_exp | null | undefined;
    merchandiseCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type merchandise_bool_exp = {
    _and?: Array<merchandise_bool_exp> | null | undefined;
    _not?: merchandise_bool_exp | null | undefined;
    _or?: Array<merchandise_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    deliveryType?: merchandiseDeliveryType_enum_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    eventMerchandises?: eventMerchandise_bool_exp | null | undefined;
    eventMerchandises_aggregate?: eventMerchandise_aggregate_bool_exp | null | undefined;
    merchandiseDeliveryType?: merchandiseDeliveryType_bool_exp | null | undefined;
    merchandiseImages?: merchandiseImage_bool_exp | null | undefined;
    merchandiseImages_aggregate?: merchandiseImage_aggregate_bool_exp | null | undefined;
    merchandiseVariantOptions?: merchandiseVariantOption_bool_exp | null | undefined;
    merchandiseVariantOptions_aggregate?: merchandiseVariantOption_aggregate_bool_exp | null | undefined;
    merchandiseVariants?: merchandiseVariant_bool_exp | null | undefined;
    merchandiseVariants_aggregate?: merchandiseVariant_aggregate_bool_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    stock?: Int_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    vendorId?: Int_comparison_exp | null | undefined;
};
export type merchandiseDeliveryType_enum_comparison_exp = {
    _eq?: merchandiseDeliveryType_enum | null | undefined;
    _in?: Array<merchandiseDeliveryType_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: merchandiseDeliveryType_enum | null | undefined;
    _nin?: Array<merchandiseDeliveryType_enum> | null | undefined;
};
export type eventMerchandise_bool_exp = {
    _and?: Array<eventMerchandise_bool_exp> | null | undefined;
    _not?: eventMerchandise_bool_exp | null | undefined;
    _or?: Array<eventMerchandise_bool_exp> | null | undefined;
    availableTo?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    merchandise?: merchandise_bool_exp | null | undefined;
    merchandiseCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    visible?: Boolean_comparison_exp | null | undefined;
};
export type eventMerchandise_aggregate_bool_exp = {
    bool_and?: eventMerchandise_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventMerchandise_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventMerchandise_aggregate_bool_exp_count | null | undefined;
};
export type eventMerchandise_aggregate_bool_exp_bool_and = {
    arguments: eventMerchandise_select_column_eventMerchandise_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventMerchandise_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventMerchandise_aggregate_bool_exp_bool_or = {
    arguments: eventMerchandise_select_column_eventMerchandise_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventMerchandise_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventMerchandise_aggregate_bool_exp_count = {
    arguments?: Array<eventMerchandise_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventMerchandise_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseDeliveryType_bool_exp = {
    _and?: Array<merchandiseDeliveryType_bool_exp> | null | undefined;
    _not?: merchandiseDeliveryType_bool_exp | null | undefined;
    _or?: Array<merchandiseDeliveryType_bool_exp> | null | undefined;
    merchandises?: merchandise_bool_exp | null | undefined;
    merchandises_aggregate?: merchandise_aggregate_bool_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
};
export type merchandise_aggregate_bool_exp = {
    count?: merchandise_aggregate_bool_exp_count | null | undefined;
};
export type merchandise_aggregate_bool_exp_count = {
    arguments?: Array<merchandise_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: merchandise_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseImage_aggregate_bool_exp = {
    bool_and?: merchandiseImage_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: merchandiseImage_aggregate_bool_exp_bool_or | null | undefined;
    count?: merchandiseImage_aggregate_bool_exp_count | null | undefined;
};
export type merchandiseImage_aggregate_bool_exp_bool_and = {
    arguments: merchandiseImage_select_column_merchandiseImage_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: merchandiseImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type merchandiseImage_aggregate_bool_exp_bool_or = {
    arguments: merchandiseImage_select_column_merchandiseImage_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: merchandiseImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type merchandiseImage_aggregate_bool_exp_count = {
    arguments?: Array<merchandiseImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: merchandiseImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseVariantOption_bool_exp = {
    _and?: Array<merchandiseVariantOption_bool_exp> | null | undefined;
    _not?: merchandiseVariantOption_bool_exp | null | undefined;
    _or?: Array<merchandiseVariantOption_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    merchandise?: merchandise_bool_exp | null | undefined;
    merchandiseCuid?: String_comparison_exp | null | undefined;
    merchandiseVariantOptionValues?: merchandiseVariantOptionValue_bool_exp | null | undefined;
    merchandiseVariantOptionValues_aggregate?: merchandiseVariantOptionValue_aggregate_bool_exp | null | undefined;
    optionName?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type merchandiseVariantOptionValue_bool_exp = {
    _and?: Array<merchandiseVariantOptionValue_bool_exp> | null | undefined;
    _not?: merchandiseVariantOptionValue_bool_exp | null | undefined;
    _or?: Array<merchandiseVariantOptionValue_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    merchandiseVariantOption?: merchandiseVariantOption_bool_exp | null | undefined;
    merchandiseVariantOptionCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    value?: String_comparison_exp | null | undefined;
};
export type merchandiseVariantOptionValue_aggregate_bool_exp = {
    count?: merchandiseVariantOptionValue_aggregate_bool_exp_count | null | undefined;
};
export type merchandiseVariantOptionValue_aggregate_bool_exp_count = {
    arguments?: Array<merchandiseVariantOptionValue_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: merchandiseVariantOptionValue_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseVariantOption_aggregate_bool_exp = {
    count?: merchandiseVariantOption_aggregate_bool_exp_count | null | undefined;
};
export type merchandiseVariantOption_aggregate_bool_exp_count = {
    arguments?: Array<merchandiseVariantOption_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: merchandiseVariantOption_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type merchandiseVariant_bool_exp = {
    _and?: Array<merchandiseVariant_bool_exp> | null | undefined;
    _not?: merchandiseVariant_bool_exp | null | undefined;
    _or?: Array<merchandiseVariant_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    merchandise?: merchandise_bool_exp | null | undefined;
    merchandiseCuid?: String_comparison_exp | null | undefined;
    optionCuidValueCuid?: jsonb_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    stock?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type merchandiseVariant_aggregate_bool_exp = {
    count?: merchandiseVariant_aggregate_bool_exp_count | null | undefined;
};
export type merchandiseVariant_aggregate_bool_exp_count = {
    arguments?: Array<merchandiseVariant_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: merchandiseVariant_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type organizer_aggregate_bool_exp = {
    bool_and?: organizer_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: organizer_aggregate_bool_exp_bool_or | null | undefined;
    count?: organizer_aggregate_bool_exp_count | null | undefined;
};
export type organizer_aggregate_bool_exp_bool_and = {
    arguments: organizer_select_column_organizer_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: organizer_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type organizer_aggregate_bool_exp_bool_or = {
    arguments: organizer_select_column_organizer_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: organizer_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type organizer_aggregate_bool_exp_count = {
    arguments?: Array<organizer_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: organizer_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type seriesImage_bool_exp = {
    _and?: Array<seriesImage_bool_exp> | null | undefined;
    _not?: seriesImage_bool_exp | null | undefined;
    _or?: Array<seriesImage_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    series?: series_bool_exp | null | undefined;
    seriesCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type seriesImage_aggregate_bool_exp = {
    bool_and?: seriesImage_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: seriesImage_aggregate_bool_exp_bool_or | null | undefined;
    count?: seriesImage_aggregate_bool_exp_count | null | undefined;
};
export type seriesImage_aggregate_bool_exp_bool_and = {
    arguments: seriesImage_select_column_seriesImage_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesImage_aggregate_bool_exp_bool_or = {
    arguments: seriesImage_select_column_seriesImage_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesImage_aggregate_bool_exp_count = {
    arguments?: Array<seriesImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: seriesImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type sponsorImage_bool_exp = {
    _and?: Array<sponsorImage_bool_exp> | null | undefined;
    _not?: sponsorImage_bool_exp | null | undefined;
    _or?: Array<sponsorImage_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    sponsor?: sponsor_bool_exp | null | undefined;
    sponsorCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type sponsor_bool_exp = {
    _and?: Array<sponsor_bool_exp> | null | undefined;
    _not?: sponsor_bool_exp | null | undefined;
    _or?: Array<sponsor_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    eventSponsors?: eventSponsor_bool_exp | null | undefined;
    eventSponsors_aggregate?: eventSponsor_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    sponsorImages?: sponsorImage_bool_exp | null | undefined;
    sponsorImages_aggregate?: sponsorImage_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    website?: String_comparison_exp | null | undefined;
};
export type eventSponsor_bool_exp = {
    _and?: Array<eventSponsor_bool_exp> | null | undefined;
    _not?: eventSponsor_bool_exp | null | undefined;
    _or?: Array<eventSponsor_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    sponsor?: sponsor_bool_exp | null | undefined;
    sponsorCuid?: String_comparison_exp | null | undefined;
    tierName?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSponsor_aggregate_bool_exp = {
    count?: eventSponsor_aggregate_bool_exp_count | null | undefined;
};
export type eventSponsor_aggregate_bool_exp_count = {
    arguments?: Array<eventSponsor_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSponsor_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type sponsorImage_aggregate_bool_exp = {
    bool_and?: sponsorImage_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: sponsorImage_aggregate_bool_exp_bool_or | null | undefined;
    count?: sponsorImage_aggregate_bool_exp_count | null | undefined;
};
export type sponsorImage_aggregate_bool_exp_bool_and = {
    arguments: sponsorImage_select_column_sponsorImage_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: sponsorImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type sponsorImage_aggregate_bool_exp_bool_or = {
    arguments: sponsorImage_select_column_sponsorImage_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: sponsorImage_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type sponsorImage_aggregate_bool_exp_count = {
    arguments?: Array<sponsorImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: sponsorImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userImage_bool_exp = {
    _and?: Array<userImage_bool_exp> | null | undefined;
    _not?: userImage_bool_exp | null | undefined;
    _or?: Array<userImage_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    image?: image_bool_exp | null | undefined;
    imagePublicId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userImage_aggregate_bool_exp = {
    count?: userImage_aggregate_bool_exp_count | null | undefined;
};
export type userImage_aggregate_bool_exp_count = {
    arguments?: Array<userImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type user_aggregate_bool_exp = {
    bool_and?: user_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: user_aggregate_bool_exp_bool_or | null | undefined;
    count?: user_aggregate_bool_exp_count | null | undefined;
};
export type user_aggregate_bool_exp_bool_and = {
    arguments: user_select_column_user_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: user_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type user_aggregate_bool_exp_bool_or = {
    arguments: user_select_column_user_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: user_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type user_aggregate_bool_exp_count = {
    arguments?: Array<user_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: user_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type image_aggregate_bool_exp = {
    count?: image_aggregate_bool_exp_count | null | undefined;
};
export type image_aggregate_bool_exp_count = {
    arguments?: Array<image_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: image_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type onlineCourse_bool_exp = {
    _and?: Array<onlineCourse_bool_exp> | null | undefined;
    _not?: onlineCourse_bool_exp | null | undefined;
    _or?: Array<onlineCourse_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    imageOnlineCourses?: imageOnlineCourse_bool_exp | null | undefined;
    imageOnlineCourses_aggregate?: imageOnlineCourse_aggregate_bool_exp | null | undefined;
    latestRevision?: onlineCourseRevision_bool_exp | null | undefined;
    latestRevisionNum?: Int_comparison_exp | null | undefined;
    onlineCourseOwner?: onlineCourseOwner_bool_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    ownerCuid?: String_comparison_exp | null | undefined;
    ownerType?: onlineCourseOwner_enum_comparison_exp | null | undefined;
    publishedAt?: timestamptz_comparison_exp | null | undefined;
    revisions?: onlineCourseRevision_bool_exp | null | undefined;
    revisions_aggregate?: onlineCourseRevision_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userOnlineCourses?: userOnlineCourse_bool_exp | null | undefined;
    userOnlineCourses_aggregate?: userOnlineCourse_aggregate_bool_exp | null | undefined;
};
export type imageOnlineCourse_bool_exp = {
    _and?: Array<imageOnlineCourse_bool_exp> | null | undefined;
    _not?: imageOnlineCourse_bool_exp | null | undefined;
    _or?: Array<imageOnlineCourse_bool_exp> | null | undefined;
    bytes?: Int_comparison_exp | null | undefined;
    colors?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    format?: String_comparison_exp | null | undefined;
    height?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    onlineCourse?: onlineCourse_bool_exp | null | undefined;
    onlineCourseCuid?: String_comparison_exp | null | undefined;
    originalFilename?: String_comparison_exp | null | undefined;
    publicId?: String_comparison_exp | null | undefined;
    relativeUrl?: String_comparison_exp | null | undefined;
    resourceType?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    url?: String_comparison_exp | null | undefined;
    version?: Int_comparison_exp | null | undefined;
    width?: Int_comparison_exp | null | undefined;
};
export type imageOnlineCourse_aggregate_bool_exp = {
    count?: imageOnlineCourse_aggregate_bool_exp_count | null | undefined;
};
export type imageOnlineCourse_aggregate_bool_exp_count = {
    arguments?: Array<imageOnlineCourse_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: imageOnlineCourse_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type onlineCourseRevision_bool_exp = {
    _and?: Array<onlineCourseRevision_bool_exp> | null | undefined;
    _not?: onlineCourseRevision_bool_exp | null | undefined;
    _or?: Array<onlineCourseRevision_bool_exp> | null | undefined;
    contents?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    currencyCode?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    onlineCourse?: onlineCourse_bool_exp | null | undefined;
    onlineCourseCuid?: String_comparison_exp | null | undefined;
    onlineCourseDefinition?: jsonb_comparison_exp | null | undefined;
    onlineCourses?: onlineCourse_bool_exp | null | undefined;
    onlineCourses_aggregate?: onlineCourse_aggregate_bool_exp | null | undefined;
    overviewContent?: jsonb_comparison_exp | null | undefined;
    price?: Int_comparison_exp | null | undefined;
    revision?: Int_comparison_exp | null | undefined;
    tagline?: String_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userOnlineCourses?: userOnlineCourse_bool_exp | null | undefined;
    userOnlineCourses_aggregate?: userOnlineCourse_aggregate_bool_exp | null | undefined;
};
export type onlineCourse_aggregate_bool_exp = {
    count?: onlineCourse_aggregate_bool_exp_count | null | undefined;
};
export type onlineCourse_aggregate_bool_exp_count = {
    arguments?: Array<onlineCourse_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: onlineCourse_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userOnlineCourse_bool_exp = {
    _and?: Array<userOnlineCourse_bool_exp> | null | undefined;
    _not?: userOnlineCourse_bool_exp | null | undefined;
    _or?: Array<userOnlineCourse_bool_exp> | null | undefined;
    completedLessons?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    lastViewedLessonCuid?: String_comparison_exp | null | undefined;
    onlineCourse?: onlineCourse_bool_exp | null | undefined;
    onlineCourseCuid?: String_comparison_exp | null | undefined;
    purchasedRevision?: onlineCourseRevision_bool_exp | null | undefined;
    referenceNo?: String_comparison_exp | null | undefined;
    revision?: Int_comparison_exp | null | undefined;
    status?: userOnlineCourseStatus_enum_comparison_exp | null | undefined;
    stripeCheckoutId?: String_comparison_exp | null | undefined;
    stripePaymentIntentId?: String_comparison_exp | null | undefined;
    successToken?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    userOnlineCourseStatus?: userOnlineCourseStatus_bool_exp | null | undefined;
};
export type userOnlineCourseStatus_enum_comparison_exp = {
    _eq?: userOnlineCourseStatus_enum | null | undefined;
    _in?: Array<userOnlineCourseStatus_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: userOnlineCourseStatus_enum | null | undefined;
    _nin?: Array<userOnlineCourseStatus_enum> | null | undefined;
};
export type userOnlineCourseStatus_bool_exp = {
    _and?: Array<userOnlineCourseStatus_bool_exp> | null | undefined;
    _not?: userOnlineCourseStatus_bool_exp | null | undefined;
    _or?: Array<userOnlineCourseStatus_bool_exp> | null | undefined;
    status?: String_comparison_exp | null | undefined;
    userOnlineCourses?: userOnlineCourse_bool_exp | null | undefined;
    userOnlineCourses_aggregate?: userOnlineCourse_aggregate_bool_exp | null | undefined;
};
export type userOnlineCourse_aggregate_bool_exp = {
    count?: userOnlineCourse_aggregate_bool_exp_count | null | undefined;
};
export type userOnlineCourse_aggregate_bool_exp_count = {
    arguments?: Array<userOnlineCourse_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userOnlineCourse_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type onlineCourseOwner_bool_exp = {
    _and?: Array<onlineCourseOwner_bool_exp> | null | undefined;
    _not?: onlineCourseOwner_bool_exp | null | undefined;
    _or?: Array<onlineCourseOwner_bool_exp> | null | undefined;
    description?: String_comparison_exp | null | undefined;
    onlineCourses?: onlineCourse_bool_exp | null | undefined;
    onlineCourses_aggregate?: onlineCourse_aggregate_bool_exp | null | undefined;
    owner?: String_comparison_exp | null | undefined;
};
export type onlineCourseOwner_enum_comparison_exp = {
    _eq?: onlineCourseOwner_enum | null | undefined;
    _in?: Array<onlineCourseOwner_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: onlineCourseOwner_enum | null | undefined;
    _nin?: Array<onlineCourseOwner_enum> | null | undefined;
};
export type onlineCourseRevision_aggregate_bool_exp = {
    count?: onlineCourseRevision_aggregate_bool_exp_count | null | undefined;
};
export type onlineCourseRevision_aggregate_bool_exp_count = {
    arguments?: Array<onlineCourseRevision_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: onlineCourseRevision_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type organizerChannel_bool_exp = {
    _and?: Array<organizerChannel_bool_exp> | null | undefined;
    _not?: organizerChannel_bool_exp | null | undefined;
    _or?: Array<organizerChannel_bool_exp> | null | undefined;
    channelId?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type organizerChannel_aggregate_bool_exp = {
    count?: organizerChannel_aggregate_bool_exp_count | null | undefined;
};
export type organizerChannel_aggregate_bool_exp_count = {
    arguments?: Array<organizerChannel_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: organizerChannel_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type organizerInvite_bool_exp = {
    _and?: Array<organizerInvite_bool_exp> | null | undefined;
    _not?: organizerInvite_bool_exp | null | undefined;
    _or?: Array<organizerInvite_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    inviteStatus?: String_comparison_exp | null | undefined;
    inviteeUserId?: String_comparison_exp | null | undefined;
    inviterUserId?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    role?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userByInvitee?: user_bool_exp | null | undefined;
    userByInviter?: user_bool_exp | null | undefined;
};
export type organizerInvite_aggregate_bool_exp = {
    count?: organizerInvite_aggregate_bool_exp_count | null | undefined;
};
export type organizerInvite_aggregate_bool_exp_count = {
    arguments?: Array<organizerInvite_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: organizerInvite_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type series_aggregate_bool_exp = {
    bool_and?: series_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: series_aggregate_bool_exp_bool_or | null | undefined;
    count?: series_aggregate_bool_exp_count | null | undefined;
};
export type series_aggregate_bool_exp_bool_and = {
    arguments: series_select_column_series_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: series_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type series_aggregate_bool_exp_bool_or = {
    arguments: series_select_column_series_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: series_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type series_aggregate_bool_exp_count = {
    arguments?: Array<series_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: series_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type sponsor_aggregate_bool_exp = {
    count?: sponsor_aggregate_bool_exp_count | null | undefined;
};
export type sponsor_aggregate_bool_exp_count = {
    arguments?: Array<sponsor_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: sponsor_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type stripeConnectAccount_bool_exp = {
    _and?: Array<stripeConnectAccount_bool_exp> | null | undefined;
    _not?: stripeConnectAccount_bool_exp | null | undefined;
    _or?: Array<stripeConnectAccount_bool_exp> | null | undefined;
    accountId?: String_comparison_exp | null | undefined;
    chargesEnabled?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizers?: organizer_bool_exp | null | undefined;
    organizers_aggregate?: organizer_aggregate_bool_exp | null | undefined;
    ownerOrganizerCuid?: String_comparison_exp | null | undefined;
    ownerUserId?: String_comparison_exp | null | undefined;
    payoutsEnabled?: Boolean_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    users?: user_bool_exp | null | undefined;
    users_aggregate?: user_aggregate_bool_exp | null | undefined;
};
export type stripeConnectAccount_aggregate_bool_exp = {
    bool_and?: stripeConnectAccount_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: stripeConnectAccount_aggregate_bool_exp_bool_or | null | undefined;
    count?: stripeConnectAccount_aggregate_bool_exp_count | null | undefined;
};
export type stripeConnectAccount_aggregate_bool_exp_bool_and = {
    arguments: stripeConnectAccount_select_column_stripeConnectAccount_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: stripeConnectAccount_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type stripeConnectAccount_aggregate_bool_exp_bool_or = {
    arguments: stripeConnectAccount_select_column_stripeConnectAccount_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: stripeConnectAccount_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type stripeConnectAccount_aggregate_bool_exp_count = {
    arguments?: Array<stripeConnectAccount_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: stripeConnectAccount_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type ticket_bool_exp = {
    _and?: Array<ticket_bool_exp> | null | undefined;
    _not?: ticket_bool_exp | null | undefined;
    _or?: Array<ticket_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    eventTickets?: eventTicket_bool_exp | null | undefined;
    eventTickets_aggregate?: eventTicket_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventTicket_bool_exp = {
    _and?: Array<eventTicket_bool_exp> | null | undefined;
    _not?: eventTicket_bool_exp | null | undefined;
    _or?: Array<eventTicket_bool_exp> | null | undefined;
    allowWaitlist?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    registrationTickets?: registrationTicket_bool_exp | null | undefined;
    registrationTickets_aggregate?: registrationTicket_aggregate_bool_exp | null | undefined;
    ticket?: ticket_bool_exp | null | undefined;
    ticketCuid?: String_comparison_exp | null | undefined;
    ticketsAvailable?: Int_comparison_exp | null | undefined;
    ticketsRemaining?: bigint_comparison_exp | null | undefined;
    ticketsReserved?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    visible?: Boolean_comparison_exp | null | undefined;
};
export type registrationTicket_bool_exp = {
    _and?: Array<registrationTicket_bool_exp> | null | undefined;
    _not?: registrationTicket_bool_exp | null | undefined;
    _or?: Array<registrationTicket_bool_exp> | null | undefined;
    eventTicket?: eventTicket_bool_exp | null | undefined;
    eventTicketCuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    number?: Int_comparison_exp | null | undefined;
    registration?: registration_bool_exp | null | undefined;
    registrationCuid?: String_comparison_exp | null | undefined;
};
export type registrationTicket_aggregate_bool_exp = {
    count?: registrationTicket_aggregate_bool_exp_count | null | undefined;
};
export type registrationTicket_aggregate_bool_exp_count = {
    arguments?: Array<registrationTicket_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: registrationTicket_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventTicket_aggregate_bool_exp = {
    bool_and?: eventTicket_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventTicket_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventTicket_aggregate_bool_exp_count | null | undefined;
};
export type eventTicket_aggregate_bool_exp_bool_and = {
    arguments: eventTicket_select_column_eventTicket_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventTicket_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventTicket_aggregate_bool_exp_bool_or = {
    arguments: eventTicket_select_column_eventTicket_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventTicket_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventTicket_aggregate_bool_exp_count = {
    arguments?: Array<eventTicket_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventTicket_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type ticket_aggregate_bool_exp = {
    count?: ticket_aggregate_bool_exp_count | null | undefined;
};
export type ticket_aggregate_bool_exp_count = {
    arguments?: Array<ticket_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: ticket_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userOrganizerRole_bool_exp = {
    _and?: Array<userOrganizerRole_bool_exp> | null | undefined;
    _not?: userOrganizerRole_bool_exp | null | undefined;
    _or?: Array<userOrganizerRole_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    role?: roleOrganizer_enum_comparison_exp | null | undefined;
    roleOrganizer?: roleOrganizer_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type roleOrganizer_enum_comparison_exp = {
    _eq?: roleOrganizer_enum | null | undefined;
    _in?: Array<roleOrganizer_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: roleOrganizer_enum | null | undefined;
    _nin?: Array<roleOrganizer_enum> | null | undefined;
};
export type roleOrganizer_bool_exp = {
    _and?: Array<roleOrganizer_bool_exp> | null | undefined;
    _not?: roleOrganizer_bool_exp | null | undefined;
    _or?: Array<roleOrganizer_bool_exp> | null | undefined;
    role?: String_comparison_exp | null | undefined;
    userOrganizerRoles?: userOrganizerRole_bool_exp | null | undefined;
    userOrganizerRoles_aggregate?: userOrganizerRole_aggregate_bool_exp | null | undefined;
};
export type userOrganizerRole_aggregate_bool_exp = {
    count?: userOrganizerRole_aggregate_bool_exp_count | null | undefined;
};
export type userOrganizerRole_aggregate_bool_exp_count = {
    arguments?: Array<userOrganizerRole_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userOrganizerRole_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type venue_bool_exp = {
    _and?: Array<venue_bool_exp> | null | undefined;
    _not?: venue_bool_exp | null | undefined;
    _or?: Array<venue_bool_exp> | null | undefined;
    address?: String_comparison_exp | null | undefined;
    addressWithName?: String_comparison_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    countryCode?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventVenue?: eventVenue_bool_exp | null | undefined;
    eventVenues?: eventVenue_bool_exp | null | undefined;
    eventVenues_aggregate?: eventVenue_aggregate_bool_exp | null | undefined;
    fullAddress?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    organizer?: organizer_bool_exp | null | undefined;
    organizerCuid?: String_comparison_exp | null | undefined;
    placeId?: String_comparison_exp | null | undefined;
    postalCode?: String_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventVenue_bool_exp = {
    _and?: Array<eventVenue_bool_exp> | null | undefined;
    _not?: eventVenue_bool_exp | null | undefined;
    _or?: Array<eventVenue_bool_exp> | null | undefined;
    additionalInfo?: String_comparison_exp | null | undefined;
    campingDescription?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    meetupInstructions?: String_comparison_exp | null | undefined;
    parkingInfo?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    venue?: venue_bool_exp | null | undefined;
    venueCuid?: String_comparison_exp | null | undefined;
};
export type eventVenue_aggregate_bool_exp = {
    count?: eventVenue_aggregate_bool_exp_count | null | undefined;
};
export type eventVenue_aggregate_bool_exp_count = {
    arguments?: Array<eventVenue_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVenue_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type venue_aggregate_bool_exp = {
    count?: venue_aggregate_bool_exp_count | null | undefined;
};
export type venue_aggregate_bool_exp_count = {
    arguments?: Array<venue_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: venue_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type seriesPointsStructureCustom_bool_exp = {
    _and?: Array<seriesPointsStructureCustom_bool_exp> | null | undefined;
    _not?: seriesPointsStructureCustom_bool_exp | null | undefined;
    _or?: Array<seriesPointsStructureCustom_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isPreset?: Boolean_comparison_exp | null | undefined;
    isQualifying?: Boolean_comparison_exp | null | undefined;
    points?: jsonb_comparison_exp | null | undefined;
    pointsName?: String_comparison_exp | null | undefined;
    series?: series_bool_exp | null | undefined;
    seriesCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type seriesPointsStructureCustom_aggregate_bool_exp = {
    bool_and?: seriesPointsStructureCustom_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: seriesPointsStructureCustom_aggregate_bool_exp_bool_or | null | undefined;
    count?: seriesPointsStructureCustom_aggregate_bool_exp_count | null | undefined;
};
export type seriesPointsStructureCustom_aggregate_bool_exp_bool_and = {
    arguments: seriesPointsStructureCustom_select_column_seriesPointsStructureCustom_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructureCustom_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesPointsStructureCustom_aggregate_bool_exp_bool_or = {
    arguments: seriesPointsStructureCustom_select_column_seriesPointsStructureCustom_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructureCustom_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesPointsStructureCustom_aggregate_bool_exp_count = {
    arguments?: Array<seriesPointsStructureCustom_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructureCustom_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type seriesPointsStructurePreset_bool_exp = {
    _and?: Array<seriesPointsStructurePreset_bool_exp> | null | undefined;
    _not?: seriesPointsStructurePreset_bool_exp | null | undefined;
    _or?: Array<seriesPointsStructurePreset_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isPreset?: Boolean_comparison_exp | null | undefined;
    presetCuid?: String_comparison_exp | null | undefined;
    series?: series_bool_exp | null | undefined;
    seriesCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type seriesPointsStructurePreset_aggregate_bool_exp = {
    bool_and?: seriesPointsStructurePreset_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: seriesPointsStructurePreset_aggregate_bool_exp_bool_or | null | undefined;
    count?: seriesPointsStructurePreset_aggregate_bool_exp_count | null | undefined;
};
export type seriesPointsStructurePreset_aggregate_bool_exp_bool_and = {
    arguments: seriesPointsStructurePreset_select_column_seriesPointsStructurePreset_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructurePreset_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesPointsStructurePreset_aggregate_bool_exp_bool_or = {
    arguments: seriesPointsStructurePreset_select_column_seriesPointsStructurePreset_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructurePreset_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type seriesPointsStructurePreset_aggregate_bool_exp_count = {
    arguments?: Array<seriesPointsStructurePreset_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: seriesPointsStructurePreset_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type ride_bool_exp = {
    _and?: Array<ride_bool_exp> | null | undefined;
    _not?: ride_bool_exp | null | undefined;
    _or?: Array<ride_bool_exp> | null | undefined;
    canceledAt?: timestamptz_comparison_exp | null | undefined;
    cancellationReason?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    dayOfContact?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    priceOverride?: Int_comparison_exp | null | undefined;
    registrationStatus?: String_comparison_exp | null | undefined;
    registrations?: registration_bool_exp | null | undefined;
    registrations_aggregate?: registration_aggregate_bool_exp | null | undefined;
    rideDays?: rideDay_bool_exp | null | undefined;
    rideDays_aggregate?: rideDay_aggregate_bool_exp | null | undefined;
    spotsAvailable?: Int_comparison_exp | null | undefined;
    spotsRemaining?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type rideDay_bool_exp = {
    _and?: Array<rideDay_bool_exp> | null | undefined;
    _not?: rideDay_bool_exp | null | undefined;
    _or?: Array<rideDay_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    dayNumber?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    ride?: ride_bool_exp | null | undefined;
    rideCuid?: String_comparison_exp | null | undefined;
    startTime?: timestamp_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type rideDay_aggregate_bool_exp = {
    count?: rideDay_aggregate_bool_exp_count | null | undefined;
};
export type rideDay_aggregate_bool_exp_count = {
    arguments?: Array<rideDay_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: rideDay_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachAvailability_bool_exp = {
    _and?: Array<coachAvailability_bool_exp> | null | undefined;
    _not?: coachAvailability_bool_exp | null | undefined;
    _or?: Array<coachAvailability_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    dayOfTheWeek?: String_comparison_exp | null | undefined;
    endSeconds?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isAvailable?: Boolean_comparison_exp | null | undefined;
    startSeconds?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachAvailability_aggregate_bool_exp = {
    bool_and?: coachAvailability_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coachAvailability_aggregate_bool_exp_bool_or | null | undefined;
    count?: coachAvailability_aggregate_bool_exp_count | null | undefined;
};
export type coachAvailability_aggregate_bool_exp_bool_and = {
    arguments: coachAvailability_select_column_coachAvailability_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachAvailability_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachAvailability_aggregate_bool_exp_bool_or = {
    arguments: coachAvailability_select_column_coachAvailability_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachAvailability_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachAvailability_aggregate_bool_exp_count = {
    arguments?: Array<coachAvailability_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachAvailability_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCertification_bool_exp = {
    _and?: Array<coachCertification_bool_exp> | null | undefined;
    _not?: coachCertification_bool_exp | null | undefined;
    _or?: Array<coachCertification_bool_exp> | null | undefined;
    certification?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    year?: Int_comparison_exp | null | undefined;
};
export type coachCertification_aggregate_bool_exp = {
    count?: coachCertification_aggregate_bool_exp_count | null | undefined;
};
export type coachCertification_aggregate_bool_exp_count = {
    arguments?: Array<coachCertification_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCertification_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLesson_bool_exp = {
    _and?: Array<coachCustomerLesson_bool_exp> | null | undefined;
    _not?: coachCustomerLesson_bool_exp | null | undefined;
    _or?: Array<coachCustomerLesson_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    cancellationReason?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    coachCustomer?: coachCustomer_bool_exp | null | undefined;
    coachCustomerCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessonDates?: coachCustomerLessonDate_bool_exp | null | undefined;
    coachCustomerLessonDates_aggregate?: coachCustomerLessonDate_aggregate_bool_exp | null | undefined;
    coachCustomerLessonPauseResumes?: coachCustomerLessonPauseResume_bool_exp | null | undefined;
    coachCustomerLessonPauseResumes_aggregate?: coachCustomerLessonPauseResume_aggregate_bool_exp | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_bool_exp | null | undefined;
    coachCustomerLessonTransactions_aggregate?: coachCustomerLessonTransaction_aggregate_bool_exp | null | undefined;
    coachLesson?: coachLesson_bool_exp | null | undefined;
    coachLessonCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    currency?: String_comparison_exp | null | undefined;
    disciplineNames?: jsonb_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isVirtual?: Boolean_comparison_exp | null | undefined;
    offerCanceledDate?: timestamptz_comparison_exp | null | undefined;
    offerMessageId?: String_comparison_exp | null | undefined;
    offerRejectedDate?: timestamptz_comparison_exp | null | undefined;
    paidDate?: timestamptz_comparison_exp | null | undefined;
    price?: Int_comparison_exp | null | undefined;
    recurrenceType?: String_comparison_exp | null | undefined;
    recurringInterval?: String_comparison_exp | null | undefined;
    resumeRecurringLessonTriggerId?: String_comparison_exp | null | undefined;
    skills?: jsonb_comparison_exp | null | undefined;
    status?: String_comparison_exp | null | undefined;
    stripeSubscriptionId?: String_comparison_exp | null | undefined;
    subscriptionCanceledDate?: timestamptz_comparison_exp | null | undefined;
    subscriptionPausedUntil?: timestamptz_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomer_bool_exp = {
    _and?: Array<coachCustomer_bool_exp> | null | undefined;
    _not?: coachCustomer_bool_exp | null | undefined;
    _or?: Array<coachCustomer_bool_exp> | null | undefined;
    channelId?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessons_aggregate?: coachCustomerLesson_aggregate_bool_exp | null | undefined;
    coachCustomerMessageRequests?: coachCustomerMessageRequest_bool_exp | null | undefined;
    coachCustomerMessageRequests_aggregate?: coachCustomerMessageRequest_aggregate_bool_exp | null | undefined;
    coachCustomerUsers?: coachCustomerUser_bool_exp | null | undefined;
    coachCustomerUsers_aggregate?: coachCustomerUser_aggregate_bool_exp | null | undefined;
    coachLesson?: coachLesson_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    notes?: String_comparison_exp | null | undefined;
    requestedCoachLessonCuid?: String_comparison_exp | null | undefined;
    requestedStartTime?: timestamp_comparison_exp | null | undefined;
    status?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLesson_aggregate_bool_exp = {
    bool_and?: coachCustomerLesson_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coachCustomerLesson_aggregate_bool_exp_bool_or | null | undefined;
    count?: coachCustomerLesson_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLesson_aggregate_bool_exp_bool_and = {
    arguments: coachCustomerLesson_select_column_coachCustomerLesson_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLesson_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachCustomerLesson_aggregate_bool_exp_bool_or = {
    arguments: coachCustomerLesson_select_column_coachCustomerLesson_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLesson_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachCustomerLesson_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLesson_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLesson_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerMessageRequest_bool_exp = {
    _and?: Array<coachCustomerMessageRequest_bool_exp> | null | undefined;
    _not?: coachCustomerMessageRequest_bool_exp | null | undefined;
    _or?: Array<coachCustomerMessageRequest_bool_exp> | null | undefined;
    coachCustomer?: coachCustomer_bool_exp | null | undefined;
    coachCustomerChannelId?: String_comparison_exp | null | undefined;
    coachCustomerUser?: coachCustomerUser_bool_exp | null | undefined;
    coachCustomerUserCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    message?: String_comparison_exp | null | undefined;
    streamMessageId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerUser_bool_exp = {
    _and?: Array<coachCustomerUser_bool_exp> | null | undefined;
    _not?: coachCustomerUser_bool_exp | null | undefined;
    _or?: Array<coachCustomerUser_bool_exp> | null | undefined;
    age?: Int_comparison_exp | null | undefined;
    coachCustomer?: coachCustomer_bool_exp | null | undefined;
    coachCustomerCuid?: String_comparison_exp | null | undefined;
    coachCustomerMessageRequests?: coachCustomerMessageRequest_bool_exp | null | undefined;
    coachCustomerMessageRequests_aggregate?: coachCustomerMessageRequest_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    firstName?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    inviteStatus?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type coachCustomerMessageRequest_aggregate_bool_exp = {
    count?: coachCustomerMessageRequest_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerMessageRequest_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerMessageRequest_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerMessageRequest_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerUser_aggregate_bool_exp = {
    count?: coachCustomerUser_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerUser_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerUser_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerUser_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachLesson_bool_exp = {
    _and?: Array<coachLesson_bool_exp> | null | undefined;
    _not?: coachLesson_bool_exp | null | undefined;
    _or?: Array<coachLesson_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessons_aggregate?: coachCustomerLesson_aggregate_bool_exp | null | undefined;
    coachCustomers?: coachCustomer_bool_exp | null | undefined;
    coachCustomers_aggregate?: coachCustomer_aggregate_bool_exp | null | undefined;
    coachLessonLocations?: coachLessonLocation_bool_exp | null | undefined;
    coachLessonLocations_aggregate?: coachLessonLocation_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    disciplineNames?: jsonb_comparison_exp | null | undefined;
    duration?: Int_comparison_exp | null | undefined;
    highlightedPosition?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isBundle?: Boolean_comparison_exp | null | undefined;
    isHidden?: Boolean_comparison_exp | null | undefined;
    isVirtual?: Boolean_comparison_exp | null | undefined;
    lessonsPerBundle?: Int_comparison_exp | null | undefined;
    participantRange?: jsonb_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    price?: Int_comparison_exp | null | undefined;
    recurrenceType?: String_comparison_exp | null | undefined;
    recurringInterval?: String_comparison_exp | null | undefined;
    requestToBook?: Boolean_comparison_exp | null | undefined;
    skills?: jsonb_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userSavedLessons?: userSavedLesson_bool_exp | null | undefined;
    userSavedLessons_aggregate?: userSavedLesson_aggregate_bool_exp | null | undefined;
};
export type coachCustomer_aggregate_bool_exp = {
    count?: coachCustomer_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomer_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomer_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomer_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachLessonLocation_bool_exp = {
    _and?: Array<coachLessonLocation_bool_exp> | null | undefined;
    _not?: coachLessonLocation_bool_exp | null | undefined;
    _or?: Array<coachLessonLocation_bool_exp> | null | undefined;
    coachLesson?: coachLesson_bool_exp | null | undefined;
    coachLessonCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    lessonLocation?: lessonLocation_bool_exp | null | undefined;
    lessonLocationCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type lessonLocation_bool_exp = {
    _and?: Array<lessonLocation_bool_exp> | null | undefined;
    _not?: lessonLocation_bool_exp | null | undefined;
    _or?: Array<lessonLocation_bool_exp> | null | undefined;
    address?: String_comparison_exp | null | undefined;
    addressLine1?: String_comparison_exp | null | undefined;
    addressLine2?: String_comparison_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    coachLessonLocations?: coachLessonLocation_bool_exp | null | undefined;
    coachLessonLocations_aggregate?: coachLessonLocation_aggregate_bool_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    countryCode?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    googlePlaceId?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    label?: String_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    zipCode?: String_comparison_exp | null | undefined;
};
export type coachLessonLocation_aggregate_bool_exp = {
    count?: coachLessonLocation_aggregate_bool_exp_count | null | undefined;
};
export type coachLessonLocation_aggregate_bool_exp_count = {
    arguments?: Array<coachLessonLocation_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachLessonLocation_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userSavedLesson_bool_exp = {
    _and?: Array<userSavedLesson_bool_exp> | null | undefined;
    _not?: userSavedLesson_bool_exp | null | undefined;
    _or?: Array<userSavedLesson_bool_exp> | null | undefined;
    coachLesson?: coachLesson_bool_exp | null | undefined;
    coachLessonCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userSavedLesson_aggregate_bool_exp = {
    count?: userSavedLesson_aggregate_bool_exp_count | null | undefined;
};
export type userSavedLesson_aggregate_bool_exp_count = {
    arguments?: Array<userSavedLesson_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userSavedLesson_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonDate_bool_exp = {
    _and?: Array<coachCustomerLessonDate_bool_exp> | null | undefined;
    _not?: coachCustomerLessonDate_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonDate_bool_exp> | null | undefined;
    canceledDate?: timestamptz_comparison_exp | null | undefined;
    coachCustomerLesson?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessonCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessonDateLocations?: coachCustomerLessonDateLocation_bool_exp | null | undefined;
    coachCustomerLessonDateLocations_aggregate?: coachCustomerLessonDateLocation_aggregate_bool_exp | null | undefined;
    coachCustomerLessonDateVirtualLocations?: coachCustomerLessonDateVirtualLocation_bool_exp | null | undefined;
    coachCustomerLessonDateVirtualLocations_aggregate?: coachCustomerLessonDateVirtualLocation_aggregate_bool_exp | null | undefined;
    coachCustomerLessonOneTimeCharges?: coachCustomerLessonOneTimeCharge_bool_exp | null | undefined;
    coachCustomerLessonOneTimeCharges_aggregate?: coachCustomerLessonOneTimeCharge_aggregate_bool_exp | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_bool_exp | null | undefined;
    coachCustomerLessonTransactions_aggregate?: coachCustomerLessonTransaction_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    duration?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    notes?: String_comparison_exp | null | undefined;
    originalStartDate?: timestamp_comparison_exp | null | undefined;
    originalTimezone?: String_comparison_exp | null | undefined;
    refundedDate?: timestamptz_comparison_exp | null | undefined;
    reportLessonNoShows?: reportLessonNoShow_bool_exp | null | undefined;
    reportLessonNoShows_aggregate?: reportLessonNoShow_aggregate_bool_exp | null | undefined;
    startDate?: timestamp_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLessonDateLocation_bool_exp = {
    _and?: Array<coachCustomerLessonDateLocation_bool_exp> | null | undefined;
    _not?: coachCustomerLessonDateLocation_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonDateLocation_bool_exp> | null | undefined;
    addressLine1?: String_comparison_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_bool_exp | null | undefined;
    coachCustomerLessonDateCuid?: String_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    countryCode?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    fullAddress?: String_comparison_exp | null | undefined;
    googlePlaceId?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    zipCode?: String_comparison_exp | null | undefined;
};
export type coachCustomerLessonDateLocation_aggregate_bool_exp = {
    count?: coachCustomerLessonDateLocation_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonDateLocation_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonDateLocation_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonDateLocation_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonDateVirtualLocation_bool_exp = {
    _and?: Array<coachCustomerLessonDateVirtualLocation_bool_exp> | null | undefined;
    _not?: coachCustomerLessonDateVirtualLocation_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonDateVirtualLocation_bool_exp> | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_bool_exp | null | undefined;
    coachCustomerLessonDateCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    meetingLink?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLessonDateVirtualLocation_aggregate_bool_exp = {
    count?: coachCustomerLessonDateVirtualLocation_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonDateVirtualLocation_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonDateVirtualLocation_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonDateVirtualLocation_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonOneTimeCharge_bool_exp = {
    _and?: Array<coachCustomerLessonOneTimeCharge_bool_exp> | null | undefined;
    _not?: coachCustomerLessonOneTimeCharge_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonOneTimeCharge_bool_exp> | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_bool_exp | null | undefined;
    coachCustomerLessonDateCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_bool_exp | null | undefined;
    coachCustomerLessonTransactions_aggregate?: coachCustomerLessonTransaction_aggregate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    offerMessageId?: String_comparison_exp | null | undefined;
    paidDate?: timestamptz_comparison_exp | null | undefined;
    price?: Int_comparison_exp | null | undefined;
    refundedDate?: timestamptz_comparison_exp | null | undefined;
    status?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLessonTransaction_bool_exp = {
    _and?: Array<coachCustomerLessonTransaction_bool_exp> | null | undefined;
    _not?: coachCustomerLessonTransaction_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonTransaction_bool_exp> | null | undefined;
    coachCustomerLesson?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessonCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_bool_exp | null | undefined;
    coachCustomerLessonDateCuid?: String_comparison_exp | null | undefined;
    coachCustomerLessonOneTimeCharge?: coachCustomerLessonOneTimeCharge_bool_exp | null | undefined;
    coachCustomerLessonOneTimeChargeCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    referenceNo?: String_comparison_exp | null | undefined;
    stripeCheckoutId?: String_comparison_exp | null | undefined;
    stripeInvoiceUrl?: String_comparison_exp | null | undefined;
    stripePaymentIntentId?: String_comparison_exp | null | undefined;
    successToken?: String_comparison_exp | null | undefined;
    transactionStatus?: String_comparison_exp | null | undefined;
    transactionType?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLessonTransaction_aggregate_bool_exp = {
    count?: coachCustomerLessonTransaction_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonTransaction_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonTransaction_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonTransaction_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonOneTimeCharge_aggregate_bool_exp = {
    count?: coachCustomerLessonOneTimeCharge_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonOneTimeCharge_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonOneTimeCharge_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonOneTimeCharge_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type reportLessonNoShow_bool_exp = {
    _and?: Array<reportLessonNoShow_bool_exp> | null | undefined;
    _not?: reportLessonNoShow_bool_exp | null | undefined;
    _or?: Array<reportLessonNoShow_bool_exp> | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    lessonDateCuid?: String_comparison_exp | null | undefined;
    reportedUser?: user_bool_exp | null | undefined;
    reportedUserId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userDistance?: numeric_comparison_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    userIsCoach?: Boolean_comparison_exp | null | undefined;
    userLocation?: jsonb_comparison_exp | null | undefined;
};
export type reportLessonNoShow_aggregate_bool_exp = {
    bool_and?: reportLessonNoShow_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: reportLessonNoShow_aggregate_bool_exp_bool_or | null | undefined;
    count?: reportLessonNoShow_aggregate_bool_exp_count | null | undefined;
};
export type reportLessonNoShow_aggregate_bool_exp_bool_and = {
    arguments: reportLessonNoShow_select_column_reportLessonNoShow_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: reportLessonNoShow_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type reportLessonNoShow_aggregate_bool_exp_bool_or = {
    arguments: reportLessonNoShow_select_column_reportLessonNoShow_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: reportLessonNoShow_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type reportLessonNoShow_aggregate_bool_exp_count = {
    arguments?: Array<reportLessonNoShow_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: reportLessonNoShow_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonDate_aggregate_bool_exp = {
    count?: coachCustomerLessonDate_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonDate_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonDate_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonDate_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachCustomerLessonPauseResume_bool_exp = {
    _and?: Array<coachCustomerLessonPauseResume_bool_exp> | null | undefined;
    _not?: coachCustomerLessonPauseResume_bool_exp | null | undefined;
    _or?: Array<coachCustomerLessonPauseResume_bool_exp> | null | undefined;
    actionDate?: timestamp_comparison_exp | null | undefined;
    actionType?: String_comparison_exp | null | undefined;
    coachCustomerLesson?: coachCustomerLesson_bool_exp | null | undefined;
    coachCustomerLessonCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    pauseOnDate?: timestamptz_comparison_exp | null | undefined;
    pausedUntilDate?: timestamptz_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachCustomerLessonPauseResume_aggregate_bool_exp = {
    count?: coachCustomerLessonPauseResume_aggregate_bool_exp_count | null | undefined;
};
export type coachCustomerLessonPauseResume_aggregate_bool_exp_count = {
    arguments?: Array<coachCustomerLessonPauseResume_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachCustomerLessonPauseResume_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachDiscipline_bool_exp = {
    _and?: Array<coachDiscipline_bool_exp> | null | undefined;
    _not?: coachDiscipline_bool_exp | null | undefined;
    _or?: Array<coachDiscipline_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    skills?: jsonb_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachDiscipline_aggregate_bool_exp = {
    count?: coachDiscipline_aggregate_bool_exp_count | null | undefined;
};
export type coachDiscipline_aggregate_bool_exp_count = {
    arguments?: Array<coachDiscipline_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachDiscipline_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachImage_bool_exp = {
    _and?: Array<coachImage_bool_exp> | null | undefined;
    _not?: coachImage_bool_exp | null | undefined;
    _or?: Array<coachImage_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    imageUser?: imageUser_bool_exp | null | undefined;
    imageUserPublicId?: String_comparison_exp | null | undefined;
    position?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type imageUser_bool_exp = {
    _and?: Array<imageUser_bool_exp> | null | undefined;
    _not?: imageUser_bool_exp | null | undefined;
    _or?: Array<imageUser_bool_exp> | null | undefined;
    bytes?: Int_comparison_exp | null | undefined;
    coachImages?: coachImage_bool_exp | null | undefined;
    coachImages_aggregate?: coachImage_aggregate_bool_exp | null | undefined;
    colors?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    format?: String_comparison_exp | null | undefined;
    height?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    originalFilename?: String_comparison_exp | null | undefined;
    publicId?: String_comparison_exp | null | undefined;
    relativeUrl?: String_comparison_exp | null | undefined;
    resourceType?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    url?: String_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    version?: Int_comparison_exp | null | undefined;
    width?: Int_comparison_exp | null | undefined;
};
export type coachImage_aggregate_bool_exp = {
    count?: coachImage_aggregate_bool_exp_count | null | undefined;
};
export type coachImage_aggregate_bool_exp_count = {
    arguments?: Array<coachImage_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachImage_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachLesson_aggregate_bool_exp = {
    bool_and?: coachLesson_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coachLesson_aggregate_bool_exp_bool_or | null | undefined;
    count?: coachLesson_aggregate_bool_exp_count | null | undefined;
};
export type coachLesson_aggregate_bool_exp_bool_and = {
    arguments: coachLesson_select_column_coachLesson_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachLesson_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachLesson_aggregate_bool_exp_bool_or = {
    arguments: coachLesson_select_column_coachLesson_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachLesson_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachLesson_aggregate_bool_exp_count = {
    arguments?: Array<coachLesson_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachLesson_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachLocation_bool_exp = {
    _and?: Array<coachLocation_bool_exp> | null | undefined;
    _not?: coachLocation_bool_exp | null | undefined;
    _or?: Array<coachLocation_bool_exp> | null | undefined;
    addressLine1?: String_comparison_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    countryCode?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    fullAddress?: String_comparison_exp | null | undefined;
    googlePlaceId?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    zipCode?: String_comparison_exp | null | undefined;
};
export type coachLocation_aggregate_bool_exp = {
    count?: coachLocation_aggregate_bool_exp_count | null | undefined;
};
export type coachLocation_aggregate_bool_exp_count = {
    arguments?: Array<coachLocation_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachLocation_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachModerationFlag_bool_exp = {
    _and?: Array<coachModerationFlag_bool_exp> | null | undefined;
    _not?: coachModerationFlag_bool_exp | null | undefined;
    _or?: Array<coachModerationFlag_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isReportOpen?: Boolean_comparison_exp | null | undefined;
    reason?: String_comparison_exp | null | undefined;
    reasonDetails?: String_comparison_exp | null | undefined;
    reportPriority?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type coachModerationFlag_aggregate_bool_exp = {
    bool_and?: coachModerationFlag_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coachModerationFlag_aggregate_bool_exp_bool_or | null | undefined;
    count?: coachModerationFlag_aggregate_bool_exp_count | null | undefined;
};
export type coachModerationFlag_aggregate_bool_exp_bool_and = {
    arguments: coachModerationFlag_select_column_coachModerationFlag_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachModerationFlag_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachModerationFlag_aggregate_bool_exp_bool_or = {
    arguments: coachModerationFlag_select_column_coachModerationFlag_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachModerationFlag_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachModerationFlag_aggregate_bool_exp_count = {
    arguments?: Array<coachModerationFlag_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachModerationFlag_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachStudent_bool_exp = {
    _and?: Array<coachStudent_bool_exp> | null | undefined;
    _not?: coachStudent_bool_exp | null | undefined;
    _or?: Array<coachStudent_bool_exp> | null | undefined;
    ageRange?: jsonb_comparison_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    focus?: jsonb_comparison_exp | null | undefined;
    genders?: jsonb_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    languages?: jsonb_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachStudent_aggregate_bool_exp = {
    count?: coachStudent_aggregate_bool_exp_count | null | undefined;
};
export type coachStudent_aggregate_bool_exp_count = {
    arguments?: Array<coachStudent_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachStudent_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type coachUnavailableDate_bool_exp = {
    _and?: Array<coachUnavailableDate_bool_exp> | null | undefined;
    _not?: coachUnavailableDate_bool_exp | null | undefined;
    _or?: Array<coachUnavailableDate_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    date?: date_comparison_exp | null | undefined;
    endSeconds?: Int_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isAllDay?: Boolean_comparison_exp | null | undefined;
    startSeconds?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type coachUnavailableDate_aggregate_bool_exp = {
    bool_and?: coachUnavailableDate_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: coachUnavailableDate_aggregate_bool_exp_bool_or | null | undefined;
    count?: coachUnavailableDate_aggregate_bool_exp_count | null | undefined;
};
export type coachUnavailableDate_aggregate_bool_exp_bool_and = {
    arguments: coachUnavailableDate_select_column_coachUnavailableDate_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachUnavailableDate_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachUnavailableDate_aggregate_bool_exp_bool_or = {
    arguments: coachUnavailableDate_select_column_coachUnavailableDate_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: coachUnavailableDate_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type coachUnavailableDate_aggregate_bool_exp_count = {
    arguments?: Array<coachUnavailableDate_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: coachUnavailableDate_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type lessonLocation_aggregate_bool_exp = {
    bool_and?: lessonLocation_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: lessonLocation_aggregate_bool_exp_bool_or | null | undefined;
    count?: lessonLocation_aggregate_bool_exp_count | null | undefined;
};
export type lessonLocation_aggregate_bool_exp_bool_and = {
    arguments: lessonLocation_select_column_lessonLocation_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: lessonLocation_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type lessonLocation_aggregate_bool_exp_bool_or = {
    arguments: lessonLocation_select_column_lessonLocation_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: lessonLocation_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type lessonLocation_aggregate_bool_exp_count = {
    arguments?: Array<lessonLocation_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: lessonLocation_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type review_bool_exp = {
    _and?: Array<review_bool_exp> | null | undefined;
    _not?: review_bool_exp | null | undefined;
    _or?: Array<review_bool_exp> | null | undefined;
    clinic?: eventMetadata_bool_exp | null | undefined;
    clinicInstance?: clinic_bool_exp | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    review?: jsonb_comparison_exp | null | undefined;
    reviewSubjectCuid?: String_comparison_exp | null | undefined;
    reviewSubjectInstanceCuid?: String_comparison_exp | null | undefined;
    reviewSubjectType?: reviewSubjectType_enum_comparison_exp | null | undefined;
    reviewSubjectTypeByReviewSubjectType?: reviewSubjectType_bool_exp | null | undefined;
    reviewer?: user_bool_exp | null | undefined;
    reviewerId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type reviewSubjectType_enum_comparison_exp = {
    _eq?: reviewSubjectType_enum | null | undefined;
    _in?: Array<reviewSubjectType_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: reviewSubjectType_enum | null | undefined;
    _nin?: Array<reviewSubjectType_enum> | null | undefined;
};
export type reviewSubjectType_bool_exp = {
    _and?: Array<reviewSubjectType_bool_exp> | null | undefined;
    _not?: reviewSubjectType_bool_exp | null | undefined;
    _or?: Array<reviewSubjectType_bool_exp> | null | undefined;
    reviewTotals?: reviewTotals_bool_exp | null | undefined;
    reviewTotals_aggregate?: reviewTotals_aggregate_bool_exp | null | undefined;
    reviews?: review_bool_exp | null | undefined;
    reviews_aggregate?: review_aggregate_bool_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
};
export type reviewTotals_bool_exp = {
    _and?: Array<reviewTotals_bool_exp> | null | undefined;
    _not?: reviewTotals_bool_exp | null | undefined;
    _or?: Array<reviewTotals_bool_exp> | null | undefined;
    count?: Int_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    recAverage?: Float_comparison_exp | null | undefined;
    recPercentage?: Float_comparison_exp | null | undefined;
    reviewSubjectCuid?: String_comparison_exp | null | undefined;
    reviewSubjectType?: reviewSubjectType_enum_comparison_exp | null | undefined;
    reviewSubjectTypeByReviewSubjectType?: reviewSubjectType_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type Float_comparison_exp = {
    _eq?: number | null | undefined;
    _gt?: number | null | undefined;
    _gte?: number | null | undefined;
    _in?: Array<number> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: number | null | undefined;
    _lte?: number | null | undefined;
    _neq?: number | null | undefined;
    _nin?: Array<number> | null | undefined;
};
export type reviewTotals_aggregate_bool_exp = {
    count?: reviewTotals_aggregate_bool_exp_count | null | undefined;
};
export type reviewTotals_aggregate_bool_exp_count = {
    arguments?: Array<reviewTotals_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: reviewTotals_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type review_aggregate_bool_exp = {
    count?: review_aggregate_bool_exp_count | null | undefined;
};
export type review_aggregate_bool_exp_count = {
    arguments?: Array<review_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: review_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userSavedCoach_bool_exp = {
    _and?: Array<userSavedCoach_bool_exp> | null | undefined;
    _not?: userSavedCoach_bool_exp | null | undefined;
    _or?: Array<userSavedCoach_bool_exp> | null | undefined;
    coach?: coach_bool_exp | null | undefined;
    coachCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userSavedCoach_aggregate_bool_exp = {
    count?: userSavedCoach_aggregate_bool_exp_count | null | undefined;
};
export type userSavedCoach_aggregate_bool_exp_count = {
    arguments?: Array<userSavedCoach_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userSavedCoach_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type creatorInvite_bool_exp = {
    _and?: Array<creatorInvite_bool_exp> | null | undefined;
    _not?: creatorInvite_bool_exp | null | undefined;
    _or?: Array<creatorInvite_bool_exp> | null | undefined;
    acceptedAt?: timestamptz_comparison_exp | null | undefined;
    acceptedUserId?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    inviteEmail?: String_comparison_exp | null | undefined;
    inviterUserId?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userByAcceptor?: user_bool_exp | null | undefined;
    userByInviter?: user_bool_exp | null | undefined;
};
export type creatorInvite_aggregate_bool_exp = {
    count?: creatorInvite_aggregate_bool_exp_count | null | undefined;
};
export type creatorInvite_aggregate_bool_exp_count = {
    arguments?: Array<creatorInvite_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: creatorInvite_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventModerationFlag_bool_exp = {
    _and?: Array<eventModerationFlag_bool_exp> | null | undefined;
    _not?: eventModerationFlag_bool_exp | null | undefined;
    _or?: Array<eventModerationFlag_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isReportOpen?: Boolean_comparison_exp | null | undefined;
    reason?: String_comparison_exp | null | undefined;
    reasonDetails?: String_comparison_exp | null | undefined;
    reportPriority?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type eventModerationFlag_aggregate_bool_exp = {
    bool_and?: eventModerationFlag_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventModerationFlag_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventModerationFlag_aggregate_bool_exp_count | null | undefined;
};
export type eventModerationFlag_aggregate_bool_exp_bool_and = {
    arguments: eventModerationFlag_select_column_eventModerationFlag_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventModerationFlag_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventModerationFlag_aggregate_bool_exp_bool_or = {
    arguments: eventModerationFlag_select_column_eventModerationFlag_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventModerationFlag_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventModerationFlag_aggregate_bool_exp_count = {
    arguments?: Array<eventModerationFlag_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventModerationFlag_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventQuestionAnswer_bool_exp = {
    _and?: Array<eventQuestionAnswer_bool_exp> | null | undefined;
    _not?: eventQuestionAnswer_bool_exp | null | undefined;
    _or?: Array<eventQuestionAnswer_bool_exp> | null | undefined;
    body?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deletedByUser?: user_bool_exp | null | undefined;
    deletedByUserId?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    eventQuestion?: eventQuestion_bool_exp | null | undefined;
    eventQuestionCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isOrganizer?: Boolean_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type eventQuestion_bool_exp = {
    _and?: Array<eventQuestion_bool_exp> | null | undefined;
    _not?: eventQuestion_bool_exp | null | undefined;
    _or?: Array<eventQuestion_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deletedByUser?: user_bool_exp | null | undefined;
    deletedByUserId?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventQuestionAnswers?: eventQuestionAnswer_bool_exp | null | undefined;
    eventQuestionAnswers_aggregate?: eventQuestionAnswer_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type eventQuestionAnswer_aggregate_bool_exp = {
    bool_and?: eventQuestionAnswer_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventQuestionAnswer_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventQuestionAnswer_aggregate_bool_exp_count | null | undefined;
};
export type eventQuestionAnswer_aggregate_bool_exp_bool_and = {
    arguments: eventQuestionAnswer_select_column_eventQuestionAnswer_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventQuestionAnswer_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventQuestionAnswer_aggregate_bool_exp_bool_or = {
    arguments: eventQuestionAnswer_select_column_eventQuestionAnswer_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventQuestionAnswer_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventQuestionAnswer_aggregate_bool_exp_count = {
    arguments?: Array<eventQuestionAnswer_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventQuestionAnswer_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventQuestion_aggregate_bool_exp = {
    count?: eventQuestion_aggregate_bool_exp_count | null | undefined;
};
export type eventQuestion_aggregate_bool_exp_count = {
    arguments?: Array<eventQuestion_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventQuestion_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventUpdateComment_bool_exp = {
    _and?: Array<eventUpdateComment_bool_exp> | null | undefined;
    _not?: eventUpdateComment_bool_exp | null | undefined;
    _or?: Array<eventUpdateComment_bool_exp> | null | undefined;
    body?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deletedByUser?: user_bool_exp | null | undefined;
    deletedByUserId?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    eventUpdate?: eventUpdate_bool_exp | null | undefined;
    eventUpdateCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isOrganizer?: Boolean_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type eventUpdate_bool_exp = {
    _and?: Array<eventUpdate_bool_exp> | null | undefined;
    _not?: eventUpdate_bool_exp | null | undefined;
    _or?: Array<eventUpdate_bool_exp> | null | undefined;
    body?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    deletedByUser?: user_bool_exp | null | undefined;
    deletedByUserId?: String_comparison_exp | null | undefined;
    deleted_at?: timestamptz_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventUpdateComments?: eventUpdateComment_bool_exp | null | undefined;
    eventUpdateComments_aggregate?: eventUpdateComment_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type eventUpdateComment_aggregate_bool_exp = {
    bool_and?: eventUpdateComment_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventUpdateComment_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventUpdateComment_aggregate_bool_exp_count | null | undefined;
};
export type eventUpdateComment_aggregate_bool_exp_bool_and = {
    arguments: eventUpdateComment_select_column_eventUpdateComment_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventUpdateComment_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventUpdateComment_aggregate_bool_exp_bool_or = {
    arguments: eventUpdateComment_select_column_eventUpdateComment_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventUpdateComment_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventUpdateComment_aggregate_bool_exp_count = {
    arguments?: Array<eventUpdateComment_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventUpdateComment_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventUpdate_aggregate_bool_exp = {
    count?: eventUpdate_aggregate_bool_exp_count | null | undefined;
};
export type eventUpdate_aggregate_bool_exp_count = {
    arguments?: Array<eventUpdate_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventUpdate_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type imageUser_aggregate_bool_exp = {
    count?: imageUser_aggregate_bool_exp_count | null | undefined;
};
export type imageUser_aggregate_bool_exp_count = {
    arguments?: Array<imageUser_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: imageUser_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userAddress_bool_exp = {
    _and?: Array<userAddress_bool_exp> | null | undefined;
    _not?: userAddress_bool_exp | null | undefined;
    _or?: Array<userAddress_bool_exp> | null | undefined;
    address?: String_comparison_exp | null | undefined;
    addressLine1?: String_comparison_exp | null | undefined;
    addressLine2?: String_comparison_exp | null | undefined;
    city?: String_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    countryCode?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    googlePlaceId?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    label?: String_comparison_exp | null | undefined;
    lat?: numeric_comparison_exp | null | undefined;
    lng?: numeric_comparison_exp | null | undefined;
    phone?: String_comparison_exp | null | undefined;
    state?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
    zipCode?: String_comparison_exp | null | undefined;
};
export type userAddress_aggregate_bool_exp = {
    bool_and?: userAddress_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: userAddress_aggregate_bool_exp_bool_or | null | undefined;
    count?: userAddress_aggregate_bool_exp_count | null | undefined;
};
export type userAddress_aggregate_bool_exp_bool_and = {
    arguments: userAddress_select_column_userAddress_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userAddress_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userAddress_aggregate_bool_exp_bool_or = {
    arguments: userAddress_select_column_userAddress_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userAddress_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userAddress_aggregate_bool_exp_count = {
    arguments?: Array<userAddress_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userAddress_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userBike_bool_exp = {
    _and?: Array<userBike_bool_exp> | null | undefined;
    _not?: userBike_bool_exp | null | undefined;
    _or?: Array<userBike_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    latestRevision?: userBikeRevision_bool_exp | null | undefined;
    latestRevisionNum?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userBikeRevisions?: userBikeRevision_bool_exp | null | undefined;
    userBikeRevisions_aggregate?: userBikeRevision_aggregate_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userBikeRevision_bool_exp = {
    _and?: Array<userBikeRevision_bool_exp> | null | undefined;
    _not?: userBikeRevision_bool_exp | null | undefined;
    _or?: Array<userBikeRevision_bool_exp> | null | undefined;
    bike?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    revision?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userBike?: userBike_bool_exp | null | undefined;
    userBikeCuid?: String_comparison_exp | null | undefined;
    userBikes?: userBike_bool_exp | null | undefined;
    userBikes_aggregate?: userBike_aggregate_bool_exp | null | undefined;
};
export type userBike_aggregate_bool_exp = {
    count?: userBike_aggregate_bool_exp_count | null | undefined;
};
export type userBike_aggregate_bool_exp_count = {
    arguments?: Array<userBike_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userBike_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userBikeRevision_aggregate_bool_exp = {
    count?: userBikeRevision_aggregate_bool_exp_count | null | undefined;
};
export type userBikeRevision_aggregate_bool_exp_count = {
    arguments?: Array<userBikeRevision_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userBikeRevision_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userEmergencyContact_bool_exp = {
    _and?: Array<userEmergencyContact_bool_exp> | null | undefined;
    _not?: userEmergencyContact_bool_exp | null | undefined;
    _or?: Array<userEmergencyContact_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isDefault?: Boolean_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    phone?: String_comparison_exp | null | undefined;
    relationship?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userEmergencyContact_aggregate_bool_exp = {
    bool_and?: userEmergencyContact_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: userEmergencyContact_aggregate_bool_exp_bool_or | null | undefined;
    count?: userEmergencyContact_aggregate_bool_exp_count | null | undefined;
};
export type userEmergencyContact_aggregate_bool_exp_bool_and = {
    arguments: userEmergencyContact_select_column_userEmergencyContact_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userEmergencyContact_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userEmergencyContact_aggregate_bool_exp_bool_or = {
    arguments: userEmergencyContact_select_column_userEmergencyContact_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userEmergencyContact_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userEmergencyContact_aggregate_bool_exp_count = {
    arguments?: Array<userEmergencyContact_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userEmergencyContact_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userPersonalization_bool_exp = {
    _and?: Array<userPersonalization_bool_exp> | null | undefined;
    _not?: userPersonalization_bool_exp | null | undefined;
    _or?: Array<userPersonalization_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    preferences?: jsonb_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userPersonalization_aggregate_bool_exp = {
    count?: userPersonalization_aggregate_bool_exp_count | null | undefined;
};
export type userPersonalization_aggregate_bool_exp_count = {
    arguments?: Array<userPersonalization_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userPersonalization_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userRidingInterest_bool_exp = {
    _and?: Array<userRidingInterest_bool_exp> | null | undefined;
    _not?: userRidingInterest_bool_exp | null | undefined;
    _or?: Array<userRidingInterest_bool_exp> | null | undefined;
    abilityLevel?: jsonb_comparison_exp | null | undefined;
    abilityLevelGoal?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    disciplineName?: String_comparison_exp | null | undefined;
    focus?: jsonb_comparison_exp | null | undefined;
    hasAbilityGoal?: Boolean_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userRidingInterest_aggregate_bool_exp = {
    bool_and?: userRidingInterest_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: userRidingInterest_aggregate_bool_exp_bool_or | null | undefined;
    count?: userRidingInterest_aggregate_bool_exp_count | null | undefined;
};
export type userRidingInterest_aggregate_bool_exp_bool_and = {
    arguments: userRidingInterest_select_column_userRidingInterest_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userRidingInterest_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userRidingInterest_aggregate_bool_exp_bool_or = {
    arguments: userRidingInterest_select_column_userRidingInterest_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: userRidingInterest_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type userRidingInterest_aggregate_bool_exp_count = {
    arguments?: Array<userRidingInterest_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userRidingInterest_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userSanctionLicense_bool_exp = {
    _and?: Array<userSanctionLicense_bool_exp> | null | undefined;
    _not?: userSanctionLicense_bool_exp | null | undefined;
    _or?: Array<userSanctionLicense_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    licenseNumber?: String_comparison_exp | null | undefined;
    sanction?: sanction_bool_exp | null | undefined;
    sanctionCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type sanction_bool_exp = {
    _and?: Array<sanction_bool_exp> | null | undefined;
    _not?: sanction_bool_exp | null | undefined;
    _or?: Array<sanction_bool_exp> | null | undefined;
    classification?: jsonb_comparison_exp | null | undefined;
    country?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventSanctions?: eventSanction_bool_exp | null | undefined;
    eventSanctions_aggregate?: eventSanction_aggregate_bool_exp | null | undefined;
    hasPermit?: Boolean_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isOneDayAvailable?: Boolean_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    oneDayFee?: numeric_comparison_exp | null | undefined;
    sanctionWaivers?: sanctionWaivers_bool_exp | null | undefined;
    sanctionWaivers_aggregate?: sanctionWaivers_aggregate_bool_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    userSanctionLicenses?: userSanctionLicense_bool_exp | null | undefined;
    userSanctionLicenses_aggregate?: userSanctionLicense_aggregate_bool_exp | null | undefined;
    waiverLink?: String_comparison_exp | null | undefined;
    website?: String_comparison_exp | null | undefined;
};
export type eventSanction_aggregate_bool_exp = {
    bool_and?: eventSanction_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventSanction_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventSanction_aggregate_bool_exp_count | null | undefined;
};
export type eventSanction_aggregate_bool_exp_bool_and = {
    arguments: eventSanction_select_column_eventSanction_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSanction_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSanction_aggregate_bool_exp_bool_or = {
    arguments: eventSanction_select_column_eventSanction_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSanction_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSanction_aggregate_bool_exp_count = {
    arguments?: Array<eventSanction_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSanction_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type sanctionWaivers_bool_exp = {
    _and?: Array<sanctionWaivers_bool_exp> | null | undefined;
    _not?: sanctionWaivers_bool_exp | null | undefined;
    _or?: Array<sanctionWaivers_bool_exp> | null | undefined;
    sanction?: sanction_bool_exp | null | undefined;
    sanctionCuid?: String_comparison_exp | null | undefined;
    waiver?: waiver_bool_exp | null | undefined;
    waiverCuid?: String_comparison_exp | null | undefined;
};
export type sanctionWaivers_aggregate_bool_exp = {
    count?: sanctionWaivers_aggregate_bool_exp_count | null | undefined;
};
export type sanctionWaivers_aggregate_bool_exp_count = {
    arguments?: Array<sanctionWaivers_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: sanctionWaivers_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userSanctionLicense_aggregate_bool_exp = {
    count?: userSanctionLicense_aggregate_bool_exp_count | null | undefined;
};
export type userSanctionLicense_aggregate_bool_exp_count = {
    arguments?: Array<userSanctionLicense_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userSanctionLicense_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type userSavedEvent_bool_exp = {
    _and?: Array<userSavedEvent_bool_exp> | null | undefined;
    _not?: userSavedEvent_bool_exp | null | undefined;
    _or?: Array<userSavedEvent_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    user?: user_bool_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type userSavedEvent_aggregate_bool_exp = {
    count?: userSavedEvent_aggregate_bool_exp_count | null | undefined;
};
export type userSavedEvent_aggregate_bool_exp_count = {
    arguments?: Array<userSavedEvent_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: userSavedEvent_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type waiverRevision_aggregate_bool_exp = {
    count?: waiverRevision_aggregate_bool_exp_count | null | undefined;
};
export type waiverRevision_aggregate_bool_exp_count = {
    arguments?: Array<waiverRevision_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: waiverRevision_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type waiver_aggregate_bool_exp = {
    count?: waiver_aggregate_bool_exp_count | null | undefined;
};
export type waiver_aggregate_bool_exp_count = {
    arguments?: Array<waiver_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: waiver_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type waiverOwner_enum_comparison_exp = {
    _eq?: waiverOwner_enum | null | undefined;
    _in?: Array<waiverOwner_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: waiverOwner_enum | null | undefined;
    _nin?: Array<waiverOwner_enum> | null | undefined;
};
export type waiverOwner_bool_exp = {
    _and?: Array<waiverOwner_bool_exp> | null | undefined;
    _not?: waiverOwner_bool_exp | null | undefined;
    _or?: Array<waiverOwner_bool_exp> | null | undefined;
    description?: String_comparison_exp | null | undefined;
    owner?: String_comparison_exp | null | undefined;
    waivers?: waiver_bool_exp | null | undefined;
    waivers_aggregate?: waiver_aggregate_bool_exp | null | undefined;
};
export type eventScheduleCategory_bool_exp = {
    _and?: Array<eventScheduleCategory_bool_exp> | null | undefined;
    _not?: eventScheduleCategory_bool_exp | null | undefined;
    _or?: Array<eventScheduleCategory_bool_exp> | null | undefined;
    category?: category_bool_exp | null | undefined;
    categoryCuid?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventSchedule?: eventSchedule_bool_exp | null | undefined;
    eventScheduleCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSchedule_bool_exp = {
    _and?: Array<eventSchedule_bool_exp> | null | undefined;
    _not?: eventSchedule_bool_exp | null | undefined;
    _or?: Array<eventSchedule_bool_exp> | null | undefined;
    addedInternally?: Boolean_comparison_exp | null | undefined;
    appliesToAllCategories?: Boolean_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    endTime?: time_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventScheduleCategories?: eventScheduleCategory_bool_exp | null | undefined;
    eventScheduleCategories_aggregate?: eventScheduleCategory_aggregate_bool_exp | null | undefined;
    eventScheduleDate?: eventScheduleDate_bool_exp | null | undefined;
    eventScheduleDateCuid?: String_comparison_exp | null | undefined;
    eventScheduleTypeCustom?: eventScheduleTypeCustom_bool_exp | null | undefined;
    eventScheduleTypeCustomCuid?: String_comparison_exp | null | undefined;
    eventScheduleTypePreset?: eventScheduleTypePreset_bool_exp | null | undefined;
    eventScheduleTypePresetCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    startTime?: time_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type time_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
};
export type eventScheduleCategory_aggregate_bool_exp = {
    count?: eventScheduleCategory_aggregate_bool_exp_count | null | undefined;
};
export type eventScheduleCategory_aggregate_bool_exp_count = {
    arguments?: Array<eventScheduleCategory_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleCategory_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventScheduleDate_bool_exp = {
    _and?: Array<eventScheduleDate_bool_exp> | null | undefined;
    _not?: eventScheduleDate_bool_exp | null | undefined;
    _or?: Array<eventScheduleDate_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    date?: date_comparison_exp | null | undefined;
    dayNumber?: Int_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSchedules?: eventSchedule_bool_exp | null | undefined;
    eventSchedules_aggregate?: eventSchedule_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventSchedule_aggregate_bool_exp = {
    bool_and?: eventSchedule_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventSchedule_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventSchedule_aggregate_bool_exp_count | null | undefined;
};
export type eventSchedule_aggregate_bool_exp_bool_and = {
    arguments: eventSchedule_select_column_eventSchedule_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSchedule_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSchedule_aggregate_bool_exp_bool_or = {
    arguments: eventSchedule_select_column_eventSchedule_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventSchedule_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventSchedule_aggregate_bool_exp_count = {
    arguments?: Array<eventSchedule_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventSchedule_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventScheduleTypeCustom_bool_exp = {
    _and?: Array<eventScheduleTypeCustom_bool_exp> | null | undefined;
    _not?: eventScheduleTypeCustom_bool_exp | null | undefined;
    _or?: Array<eventScheduleTypeCustom_bool_exp> | null | undefined;
    color?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSchedules?: eventSchedule_bool_exp | null | undefined;
    eventSchedules_aggregate?: eventSchedule_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isPreset?: Boolean_comparison_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventScheduleTypePreset_bool_exp = {
    _and?: Array<eventScheduleTypePreset_bool_exp> | null | undefined;
    _not?: eventScheduleTypePreset_bool_exp | null | undefined;
    _or?: Array<eventScheduleTypePreset_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventSchedules?: eventSchedule_bool_exp | null | undefined;
    eventSchedules_aggregate?: eventSchedule_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isPreset?: Boolean_comparison_exp | null | undefined;
    scheduleTypePreset?: scheduleTypePreset_bool_exp | null | undefined;
    scheduleTypePresetCuid?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type scheduleTypePreset_bool_exp = {
    _and?: Array<scheduleTypePreset_bool_exp> | null | undefined;
    _not?: scheduleTypePreset_bool_exp | null | undefined;
    _or?: Array<scheduleTypePreset_bool_exp> | null | undefined;
    activity?: String_comparison_exp | null | undefined;
    color?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventScheduleTypePresets?: eventScheduleTypePreset_bool_exp | null | undefined;
    eventScheduleTypePresets_aggregate?: eventScheduleTypePreset_aggregate_bool_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    isPreset?: Boolean_comparison_exp | null | undefined;
    type?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventScheduleTypePreset_aggregate_bool_exp = {
    bool_and?: eventScheduleTypePreset_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventScheduleTypePreset_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventScheduleTypePreset_aggregate_bool_exp_count | null | undefined;
};
export type eventScheduleTypePreset_aggregate_bool_exp_bool_and = {
    arguments: eventScheduleTypePreset_select_column_eventScheduleTypePreset_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypePreset_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventScheduleTypePreset_aggregate_bool_exp_bool_or = {
    arguments: eventScheduleTypePreset_select_column_eventScheduleTypePreset_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypePreset_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventScheduleTypePreset_aggregate_bool_exp_count = {
    arguments?: Array<eventScheduleTypePreset_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypePreset_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type clinic_aggregate_bool_exp = {
    count?: clinic_aggregate_bool_exp_count | null | undefined;
};
export type clinic_aggregate_bool_exp_count = {
    arguments?: Array<clinic_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: clinic_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type AccessName_enum_comparison_exp = {
    _eq?: AccessName_enum | null | undefined;
    _in?: Array<AccessName_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: AccessName_enum | null | undefined;
    _nin?: Array<AccessName_enum> | null | undefined;
};
export type eventAdditionalInfo_bool_exp = {
    _and?: Array<eventAdditionalInfo_bool_exp> | null | undefined;
    _not?: eventAdditionalInfo_bool_exp | null | undefined;
    _or?: Array<eventAdditionalInfo_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventAdditionalInfo_aggregate_bool_exp = {
    count?: eventAdditionalInfo_aggregate_bool_exp_count | null | undefined;
};
export type eventAdditionalInfo_aggregate_bool_exp_count = {
    arguments?: Array<eventAdditionalInfo_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventAdditionalInfo_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventAmenity_bool_exp = {
    _and?: Array<eventAmenity_bool_exp> | null | undefined;
    _not?: eventAmenity_bool_exp | null | undefined;
    _or?: Array<eventAmenity_bool_exp> | null | undefined;
    amenity?: amenity_bool_exp | null | undefined;
    amenityCuid?: String_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
};
export type amenity_bool_exp = {
    _and?: Array<amenity_bool_exp> | null | undefined;
    _not?: amenity_bool_exp | null | undefined;
    _or?: Array<amenity_bool_exp> | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventAmenities?: eventAmenity_bool_exp | null | undefined;
    eventAmenities_aggregate?: eventAmenity_aggregate_bool_exp | null | undefined;
    id?: Int_comparison_exp | null | undefined;
    imageUrl?: String_comparison_exp | null | undefined;
    info?: String_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
};
export type eventAmenity_aggregate_bool_exp = {
    count?: eventAmenity_aggregate_bool_exp_count | null | undefined;
};
export type eventAmenity_aggregate_bool_exp_count = {
    arguments?: Array<eventAmenity_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventAmenity_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventBasicWaiver_aggregate_bool_exp = {
    bool_and?: eventBasicWaiver_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventBasicWaiver_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventBasicWaiver_aggregate_bool_exp_count | null | undefined;
};
export type eventBasicWaiver_aggregate_bool_exp_bool_and = {
    arguments: eventBasicWaiver_select_column_eventBasicWaiver_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventBasicWaiver_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventBasicWaiver_aggregate_bool_exp_bool_or = {
    arguments: eventBasicWaiver_select_column_eventBasicWaiver_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventBasicWaiver_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventBasicWaiver_aggregate_bool_exp_count = {
    arguments?: Array<eventBasicWaiver_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventBasicWaiver_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventChecklistItem_bool_exp = {
    _and?: Array<eventChecklistItem_bool_exp> | null | undefined;
    _not?: eventChecklistItem_bool_exp | null | undefined;
    _or?: Array<eventChecklistItem_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    info?: String_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventChecklistItem_aggregate_bool_exp = {
    count?: eventChecklistItem_aggregate_bool_exp_count | null | undefined;
};
export type eventChecklistItem_aggregate_bool_exp_count = {
    arguments?: Array<eventChecklistItem_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventChecklistItem_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventClinicDayDuration_aggregate_bool_exp = {
    count?: eventClinicDayDuration_aggregate_bool_exp_count | null | undefined;
};
export type eventClinicDayDuration_aggregate_bool_exp_count = {
    arguments?: Array<eventClinicDayDuration_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventClinicDayDuration_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventCustomQuestion_aggregate_bool_exp = {
    bool_and?: eventCustomQuestion_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventCustomQuestion_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventCustomQuestion_aggregate_bool_exp_count | null | undefined;
};
export type eventCustomQuestion_aggregate_bool_exp_bool_and = {
    arguments: eventCustomQuestion_select_column_eventCustomQuestion_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventCustomQuestion_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventCustomQuestion_aggregate_bool_exp_bool_or = {
    arguments: eventCustomQuestion_select_column_eventCustomQuestion_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventCustomQuestion_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventCustomQuestion_aggregate_bool_exp_count = {
    arguments?: Array<eventCustomQuestion_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventCustomQuestion_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventDiscipline_aggregate_bool_exp = {
    bool_and?: eventDiscipline_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventDiscipline_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventDiscipline_aggregate_bool_exp_count | null | undefined;
};
export type eventDiscipline_aggregate_bool_exp_bool_and = {
    arguments: eventDiscipline_select_column_eventDiscipline_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventDiscipline_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventDiscipline_aggregate_bool_exp_bool_or = {
    arguments: eventDiscipline_select_column_eventDiscipline_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventDiscipline_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventDiscipline_aggregate_bool_exp_count = {
    arguments?: Array<eventDiscipline_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventDiscipline_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPricingDate_aggregate_bool_exp = {
    count?: eventPricingDate_aggregate_bool_exp_count | null | undefined;
};
export type eventPricingDate_aggregate_bool_exp_count = {
    arguments?: Array<eventPricingDate_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPricingDate_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPricingGroup_aggregate_bool_exp = {
    count?: eventPricingGroup_aggregate_bool_exp_count | null | undefined;
};
export type eventPricingGroup_aggregate_bool_exp_count = {
    arguments?: Array<eventPricingGroup_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPricingGroup_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPrivateLink_bool_exp = {
    _and?: Array<eventPrivateLink_bool_exp> | null | undefined;
    _not?: eventPrivateLink_bool_exp | null | undefined;
    _or?: Array<eventPrivateLink_bool_exp> | null | undefined;
    capacity?: Int_comparison_exp | null | undefined;
    capacityUsed?: Int_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    endDate?: timestamptz_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventByEventcuid?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    eventId?: Int_comparison_exp | null | undefined;
    link?: String_comparison_exp | null | undefined;
    paused?: Boolean_comparison_exp | null | undefined;
    startDate?: timestamptz_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPrivateLink_aggregate_bool_exp = {
    bool_and?: eventPrivateLink_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventPrivateLink_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventPrivateLink_aggregate_bool_exp_count | null | undefined;
};
export type eventPrivateLink_aggregate_bool_exp_bool_and = {
    arguments: eventPrivateLink_select_column_eventPrivateLink_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPrivateLink_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventPrivateLink_aggregate_bool_exp_bool_or = {
    arguments: eventPrivateLink_select_column_eventPrivateLink_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPrivateLink_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventPrivateLink_aggregate_bool_exp_count = {
    arguments?: Array<eventPrivateLink_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPrivateLink_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventProgram_bool_exp = {
    _and?: Array<eventProgram_bool_exp> | null | undefined;
    _not?: eventProgram_bool_exp | null | undefined;
    _or?: Array<eventProgram_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    ageRange?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    defaultPrice?: numeric_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    focus?: jsonb_comparison_exp | null | undefined;
    genders?: jsonb_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    overview?: String_comparison_exp | null | undefined;
    skills?: jsonb_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventProgram_aggregate_bool_exp = {
    count?: eventProgram_aggregate_bool_exp_count | null | undefined;
};
export type eventProgram_aggregate_bool_exp_count = {
    arguments?: Array<eventProgram_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventProgram_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPromoCode_bool_exp = {
    _and?: Array<eventPromoCode_bool_exp> | null | undefined;
    _not?: eventPromoCode_bool_exp | null | undefined;
    _or?: Array<eventPromoCode_bool_exp> | null | undefined;
    amountOff?: numeric_comparison_exp | null | undefined;
    code?: String_comparison_exp | null | undefined;
    codesAvailable?: Int_comparison_exp | null | undefined;
    codesRemaining?: bigint_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    isInactive?: Boolean_comparison_exp | null | undefined;
    percentOff?: Int_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    validFrom?: timestamp_comparison_exp | null | undefined;
    validTo?: timestamp_comparison_exp | null | undefined;
};
export type eventPromoCode_aggregate_bool_exp = {
    bool_and?: eventPromoCode_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventPromoCode_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventPromoCode_aggregate_bool_exp_count | null | undefined;
};
export type eventPromoCode_aggregate_bool_exp_bool_and = {
    arguments: eventPromoCode_select_column_eventPromoCode_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPromoCode_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventPromoCode_aggregate_bool_exp_bool_or = {
    arguments: eventPromoCode_select_column_eventPromoCode_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventPromoCode_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventPromoCode_aggregate_bool_exp_count = {
    arguments?: Array<eventPromoCode_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPromoCode_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventScheduleDate_aggregate_bool_exp = {
    count?: eventScheduleDate_aggregate_bool_exp_count | null | undefined;
};
export type eventScheduleDate_aggregate_bool_exp_count = {
    arguments?: Array<eventScheduleDate_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleDate_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventScheduleTypeCustom_aggregate_bool_exp = {
    bool_and?: eventScheduleTypeCustom_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventScheduleTypeCustom_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventScheduleTypeCustom_aggregate_bool_exp_count | null | undefined;
};
export type eventScheduleTypeCustom_aggregate_bool_exp_bool_and = {
    arguments: eventScheduleTypeCustom_select_column_eventScheduleTypeCustom_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypeCustom_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventScheduleTypeCustom_aggregate_bool_exp_bool_or = {
    arguments: eventScheduleTypeCustom_select_column_eventScheduleTypeCustom_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypeCustom_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventScheduleTypeCustom_aggregate_bool_exp_count = {
    arguments?: Array<eventScheduleTypeCustom_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventScheduleTypeCustom_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventVibe_bool_exp = {
    _and?: Array<eventVibe_bool_exp> | null | undefined;
    _not?: eventVibe_bool_exp | null | undefined;
    _or?: Array<eventVibe_bool_exp> | null | undefined;
    abilityLevelRange?: jsonb_comparison_exp | null | undefined;
    averageSpeedRange?: jsonb_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    genders?: jsonb_comparison_exp | null | undefined;
    hasAverageSpeed?: Boolean_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    intensityRange?: jsonb_comparison_exp | null | undefined;
    moreInfo?: String_comparison_exp | null | undefined;
    rideType?: String_comparison_exp | null | undefined;
    ridingTimePerDayRange?: jsonb_comparison_exp | null | undefined;
    speedType?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventVibe_aggregate_bool_exp = {
    bool_and?: eventVibe_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventVibe_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventVibe_aggregate_bool_exp_count | null | undefined;
};
export type eventVibe_aggregate_bool_exp_bool_and = {
    arguments: eventVibe_select_column_eventVibe_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventVibe_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventVibe_aggregate_bool_exp_bool_or = {
    arguments: eventVibe_select_column_eventVibe_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventVibe_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventVibe_aggregate_bool_exp_count = {
    arguments?: Array<eventVibe_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVibe_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventVirtualVenue_bool_exp = {
    _and?: Array<eventVirtualVenue_bool_exp> | null | undefined;
    _not?: eventVirtualVenue_bool_exp | null | undefined;
    _or?: Array<eventVirtualVenue_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    event?: event_bool_exp | null | undefined;
    eventCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    timezone?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventVirtualVenue_aggregate_bool_exp = {
    count?: eventVirtualVenue_aggregate_bool_exp_count | null | undefined;
};
export type eventVirtualVenue_aggregate_bool_exp_count = {
    arguments?: Array<eventVirtualVenue_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVirtualVenue_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventVolunteerJob_aggregate_bool_exp = {
    count?: eventVolunteerJob_aggregate_bool_exp_count | null | undefined;
};
export type eventVolunteerJob_aggregate_bool_exp_count = {
    arguments?: Array<eventVolunteerJob_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJob_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type ride_aggregate_bool_exp = {
    count?: ride_aggregate_bool_exp_count | null | undefined;
};
export type ride_aggregate_bool_exp_count = {
    arguments?: Array<ride_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: ride_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventVolunteerJobShift_aggregate_bool_exp = {
    bool_and?: eventVolunteerJobShift_aggregate_bool_exp_bool_and | null | undefined;
    bool_or?: eventVolunteerJobShift_aggregate_bool_exp_bool_or | null | undefined;
    count?: eventVolunteerJobShift_aggregate_bool_exp_count | null | undefined;
};
export type eventVolunteerJobShift_aggregate_bool_exp_bool_and = {
    arguments: eventVolunteerJobShift_select_column_eventVolunteerJobShift_aggregate_bool_exp_bool_and_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJobShift_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventVolunteerJobShift_aggregate_bool_exp_bool_or = {
    arguments: eventVolunteerJobShift_select_column_eventVolunteerJobShift_aggregate_bool_exp_bool_or_arguments_columns;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJobShift_bool_exp | null | undefined;
    predicate: Boolean_comparison_exp;
};
export type eventVolunteerJobShift_aggregate_bool_exp_count = {
    arguments?: Array<eventVolunteerJobShift_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJobShift_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventVolunteerJobTask_bool_exp = {
    _and?: Array<eventVolunteerJobTask_bool_exp> | null | undefined;
    _not?: eventVolunteerJobTask_bool_exp | null | undefined;
    _or?: Array<eventVolunteerJobTask_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    description?: String_comparison_exp | null | undefined;
    eventVolunteerJob?: eventVolunteerJob_bool_exp | null | undefined;
    eventVolunteerJobCuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobShiftTasks?: eventVolunteerJobShiftTask_bool_exp | null | undefined;
    eventVolunteerJobShiftTasks_aggregate?: eventVolunteerJobShiftTask_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
    volunteerTaskDifficulty?: volunteerTaskDifficulties_bool_exp | null | undefined;
    volunteerTaskDifficultyCuid?: String_comparison_exp | null | undefined;
    volunteerTaskPriority?: volunteerTaskPriorities_bool_exp | null | undefined;
    volunteerTaskPriorityCuid?: String_comparison_exp | null | undefined;
};
export type eventVolunteerJobShiftTask_bool_exp = {
    _and?: Array<eventVolunteerJobShiftTask_bool_exp> | null | undefined;
    _not?: eventVolunteerJobShiftTask_bool_exp | null | undefined;
    _or?: Array<eventVolunteerJobShiftTask_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobShift?: eventVolunteerJobShift_bool_exp | null | undefined;
    eventVolunteerJobShiftCuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobTask?: eventVolunteerJobTask_bool_exp | null | undefined;
    eventVolunteerJobTaskCuid?: String_comparison_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventVolunteerJobShiftTask_aggregate_bool_exp = {
    count?: eventVolunteerJobShiftTask_aggregate_bool_exp_count | null | undefined;
};
export type eventVolunteerJobShiftTask_aggregate_bool_exp_count = {
    arguments?: Array<eventVolunteerJobShiftTask_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJobShiftTask_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type volunteerTaskDifficulties_bool_exp = {
    _and?: Array<volunteerTaskDifficulties_bool_exp> | null | undefined;
    _not?: volunteerTaskDifficulties_bool_exp | null | undefined;
    _or?: Array<volunteerTaskDifficulties_bool_exp> | null | undefined;
    chipColor?: String_comparison_exp | null | undefined;
    className?: String_comparison_exp | null | undefined;
    color?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_bool_exp | null | undefined;
    eventVolunteerJobTasks_aggregate?: eventVolunteerJobTask_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventVolunteerJobTask_aggregate_bool_exp = {
    count?: eventVolunteerJobTask_aggregate_bool_exp_count | null | undefined;
};
export type eventVolunteerJobTask_aggregate_bool_exp_count = {
    arguments?: Array<eventVolunteerJobTask_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventVolunteerJobTask_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type volunteerTaskPriorities_bool_exp = {
    _and?: Array<volunteerTaskPriorities_bool_exp> | null | undefined;
    _not?: volunteerTaskPriorities_bool_exp | null | undefined;
    _or?: Array<volunteerTaskPriorities_bool_exp> | null | undefined;
    chipColor?: String_comparison_exp | null | undefined;
    className?: String_comparison_exp | null | undefined;
    color?: String_comparison_exp | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_bool_exp | null | undefined;
    eventVolunteerJobTasks_aggregate?: eventVolunteerJobTask_aggregate_bool_exp | null | undefined;
    id?: bigint_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPitSpaceAddOn_bool_exp = {
    _and?: Array<eventPitSpaceAddOn_bool_exp> | null | undefined;
    _not?: eventPitSpaceAddOn_bool_exp | null | undefined;
    _or?: Array<eventPitSpaceAddOn_bool_exp> | null | undefined;
    created_at?: timestamptz_comparison_exp | null | undefined;
    cuid?: String_comparison_exp | null | undefined;
    eventPitSpace?: eventPitSpace_bool_exp | null | undefined;
    eventPitSpaceCuid?: String_comparison_exp | null | undefined;
    name?: String_comparison_exp | null | undefined;
    price?: numeric_comparison_exp | null | undefined;
    updated_at?: timestamptz_comparison_exp | null | undefined;
};
export type eventPitSpaceAddOn_aggregate_bool_exp = {
    count?: eventPitSpaceAddOn_aggregate_bool_exp_count | null | undefined;
};
export type eventPitSpaceAddOn_aggregate_bool_exp_count = {
    arguments?: Array<eventPitSpaceAddOn_select_column> | null | undefined;
    distinct?: boolean | null | undefined;
    filter?: eventPitSpaceAddOn_bool_exp | null | undefined;
    predicate: Int_comparison_exp;
};
export type eventPitSpaceAddOn_on_conflict = {
    constraint: eventPitSpaceAddOn_constraint;
    update_columns: Array<eventPitSpaceAddOn_update_column>;
    where?: eventPitSpaceAddOn_bool_exp | null | undefined;
};
export type eventVolunteerJobShift_arr_rel_insert_input = {
    data: Array<eventVolunteerJobShift_insert_input>;
    on_conflict?: eventVolunteerJobShift_on_conflict | null | undefined;
};
export type eventVolunteerJobShift_insert_input = {
    AccessName?: AccessName_obj_rel_insert_input | null | undefined;
    allowWaitlist?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    endDate?: string | null | undefined;
    eventVolunteerJob?: eventVolunteerJob_obj_rel_insert_input | null | undefined;
    eventVolunteerJobCuid?: string | null | undefined;
    eventVolunteerJobShiftTasks?: eventVolunteerJobShiftTask_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    shiftAccess?: AccessName_enum | null | undefined;
    spotsAvailable?: number | null | undefined;
    spotsReserved?: number | null | undefined;
    startDate?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVolunteerJob_obj_rel_insert_input = {
    data: eventVolunteerJob_insert_input;
    on_conflict?: eventVolunteerJob_on_conflict | null | undefined;
};
export type eventVolunteerJob_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventVolunteerJobShifts?: eventVolunteerJobShift_arr_rel_insert_input | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    locationInfo?: string | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVolunteerJobTask_arr_rel_insert_input = {
    data: Array<eventVolunteerJobTask_insert_input>;
    on_conflict?: eventVolunteerJobTask_on_conflict | null | undefined;
};
export type eventVolunteerJobTask_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    eventVolunteerJob?: eventVolunteerJob_obj_rel_insert_input | null | undefined;
    eventVolunteerJobCuid?: string | null | undefined;
    eventVolunteerJobShiftTasks?: eventVolunteerJobShiftTask_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    volunteerTaskDifficulty?: volunteerTaskDifficulties_obj_rel_insert_input | null | undefined;
    volunteerTaskDifficultyCuid?: string | null | undefined;
    volunteerTaskPriority?: volunteerTaskPriorities_obj_rel_insert_input | null | undefined;
    volunteerTaskPriorityCuid?: string | null | undefined;
};
export type eventVolunteerJobShiftTask_arr_rel_insert_input = {
    data: Array<eventVolunteerJobShiftTask_insert_input>;
    on_conflict?: eventVolunteerJobShiftTask_on_conflict | null | undefined;
};
export type eventVolunteerJobShiftTask_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventVolunteerJobShift?: eventVolunteerJobShift_obj_rel_insert_input | null | undefined;
    eventVolunteerJobShiftCuid?: string | null | undefined;
    eventVolunteerJobTask?: eventVolunteerJobTask_obj_rel_insert_input | null | undefined;
    eventVolunteerJobTaskCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVolunteerJobShift_obj_rel_insert_input = {
    data: eventVolunteerJobShift_insert_input;
    on_conflict?: eventVolunteerJobShift_on_conflict | null | undefined;
};
export type eventVolunteerJobShift_on_conflict = {
    constraint: eventVolunteerJobShift_constraint;
    update_columns: Array<eventVolunteerJobShift_update_column>;
    where?: eventVolunteerJobShift_bool_exp | null | undefined;
};
export type eventVolunteerJobTask_obj_rel_insert_input = {
    data: eventVolunteerJobTask_insert_input;
    on_conflict?: eventVolunteerJobTask_on_conflict | null | undefined;
};
export type eventVolunteerJobTask_on_conflict = {
    constraint: eventVolunteerJobTask_constraint;
    update_columns: Array<eventVolunteerJobTask_update_column>;
    where?: eventVolunteerJobTask_bool_exp | null | undefined;
};
export type eventVolunteerJobShiftTask_on_conflict = {
    constraint: eventVolunteerJobShiftTask_constraint;
    update_columns: Array<eventVolunteerJobShiftTask_update_column>;
    where?: eventVolunteerJobShiftTask_bool_exp | null | undefined;
};
export type volunteerTaskDifficulties_obj_rel_insert_input = {
    data: volunteerTaskDifficulties_insert_input;
    on_conflict?: volunteerTaskDifficulties_on_conflict | null | undefined;
};
export type volunteerTaskDifficulties_insert_input = {
    chipColor?: string | null | undefined;
    className?: string | null | undefined;
    color?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type volunteerTaskDifficulties_on_conflict = {
    constraint: volunteerTaskDifficulties_constraint;
    update_columns: Array<volunteerTaskDifficulties_update_column>;
    where?: volunteerTaskDifficulties_bool_exp | null | undefined;
};
export type volunteerTaskPriorities_obj_rel_insert_input = {
    data: volunteerTaskPriorities_insert_input;
    on_conflict?: volunteerTaskPriorities_on_conflict | null | undefined;
};
export type volunteerTaskPriorities_insert_input = {
    chipColor?: string | null | undefined;
    className?: string | null | undefined;
    color?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventVolunteerJobTasks?: eventVolunteerJobTask_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type volunteerTaskPriorities_on_conflict = {
    constraint: volunteerTaskPriorities_constraint;
    update_columns: Array<volunteerTaskPriorities_update_column>;
    where?: volunteerTaskPriorities_bool_exp | null | undefined;
};
export type eventVolunteerJob_on_conflict = {
    constraint: eventVolunteerJob_constraint;
    update_columns: Array<eventVolunteerJob_update_column>;
    where?: eventVolunteerJob_bool_exp | null | undefined;
};
export type event_arr_rel_insert_input = {
    data: Array<event_insert_input>;
    on_conflict?: event_on_conflict | null | undefined;
};
export type event_on_conflict = {
    constraint: event_constraint;
    update_columns: Array<event_update_column>;
    where?: event_bool_exp | null | undefined;
};
export type AccessName_on_conflict = {
    constraint: AccessName_constraint;
    update_columns: Array<AccessName_update_column>;
    where?: AccessName_bool_exp | null | undefined;
};
export type activityType_obj_rel_insert_input = {
    data: activityType_insert_input;
    on_conflict?: activityType_on_conflict | null | undefined;
};
export type activityType_insert_input = {
    events?: event_arr_rel_insert_input | null | undefined;
    name?: string | null | undefined;
};
export type activityType_on_conflict = {
    constraint: activityType_constraint;
    update_columns: Array<activityType_update_column>;
    where?: activityType_bool_exp | null | undefined;
};
export type category_arr_rel_insert_input = {
    data: Array<category_insert_input>;
    on_conflict?: category_on_conflict | null | undefined;
};
export type category_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    additionalPrizes?: string | null | undefined;
    ageAsOf?: string | null | undefined;
    categoryCourses?: categoryCourse_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    entryFee?: number | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventBasicWaiverCategories?: eventBasicWaiverCategory_arr_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventCustomQuestionCategories?: eventCustomQuestionCategory_arr_rel_insert_input | null | undefined;
    eventDiscipline?: eventDiscipline_obj_rel_insert_input | null | undefined;
    eventDisciplineCuid?: string | null | undefined;
    eventPricingGroupCategories?: eventPricingGroupCategory_arr_rel_insert_input | null | undefined;
    eventSanctionCategories?: eventSanctionCategory_arr_rel_insert_input | null | undefined;
    eventScheduleCategories?: eventScheduleCategory_arr_rel_insert_input | null | undefined;
    eventSeriesCategories?: eventSeriesCategory_arr_rel_insert_input | null | undefined;
    gender?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    maxAge?: number | null | undefined;
    minAge?: number | null | undefined;
    name?: string | null | undefined;
    position?: number | null | undefined;
    registrationCategories?: registrationCategory_arr_rel_insert_input | null | undefined;
    spotsAvailable?: number | null | undefined;
    updated_at?: string | null | undefined;
    visible?: boolean | null | undefined;
};
export type categoryCourse_arr_rel_insert_input = {
    data: Array<categoryCourse_insert_input>;
    on_conflict?: categoryCourse_on_conflict | null | undefined;
};
export type categoryCourse_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    course?: course_obj_rel_insert_input | null | undefined;
    courseCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type category_obj_rel_insert_input = {
    data: category_insert_input;
    on_conflict?: category_on_conflict | null | undefined;
};
export type category_on_conflict = {
    constraint: category_constraint;
    update_columns: Array<category_update_column>;
    where?: category_bool_exp | null | undefined;
};
export type course_obj_rel_insert_input = {
    data: course_insert_input;
    on_conflict?: course_on_conflict | null | undefined;
};
export type course_insert_input = {
    categoryCourses?: categoryCourse_arr_rel_insert_input | null | undefined;
    city?: string | null | undefined;
    country?: string | null | undefined;
    courseLength?: number | null | undefined;
    courseLengthSecondary?: number | null | undefined;
    courseLengthType?: string | null | undefined;
    courseLengthTypeSecondary?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    difficultyName?: string | null | undefined;
    distance?: number | null | undefined;
    elevationHigh?: number | null | undefined;
    elevationLow?: number | null | undefined;
    elevationUnit?: string | null | undefined;
    endLat?: number | null | undefined;
    endLng?: number | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventDiscipline?: eventDiscipline_obj_rel_insert_input | null | undefined;
    eventDisciplineCuid?: string | null | undefined;
    fullName?: string | null | undefined;
    geoJson?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    maxGrade?: number | null | undefined;
    minGrade?: number | null | undefined;
    name?: string | null | undefined;
    platform?: string | null | undefined;
    platformId?: number | null | undefined;
    platformUrl?: string | null | undefined;
    startLat?: number | null | undefined;
    startLng?: number | null | undefined;
    state?: string | null | undefined;
    totalElevationGain?: number | null | undefined;
    totalElevationLoss?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventDiscipline_obj_rel_insert_input = {
    data: eventDiscipline_insert_input;
    on_conflict?: eventDiscipline_on_conflict | null | undefined;
};
export type eventDiscipline_insert_input = {
    allowWaitlist?: boolean | null | undefined;
    categories?: category_arr_rel_insert_input | null | undefined;
    courses?: course_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    disciplineName?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    fieldLimit?: number | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type course_arr_rel_insert_input = {
    data: Array<course_insert_input>;
    on_conflict?: course_on_conflict | null | undefined;
};
export type course_on_conflict = {
    constraint: course_constraint;
    update_columns: Array<course_update_column>;
    where?: course_bool_exp | null | undefined;
};
export type eventDiscipline_on_conflict = {
    constraint: eventDiscipline_constraint;
    update_columns: Array<eventDiscipline_update_column>;
    where?: eventDiscipline_bool_exp | null | undefined;
};
export type categoryCourse_on_conflict = {
    constraint: categoryCourse_constraint;
    update_columns: Array<categoryCourse_update_column>;
    where?: categoryCourse_bool_exp | null | undefined;
};
export type eventBasicWaiverCategory_arr_rel_insert_input = {
    data: Array<eventBasicWaiverCategory_insert_input>;
    on_conflict?: eventBasicWaiverCategory_on_conflict | null | undefined;
};
export type eventBasicWaiverCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventBasicWaiver?: eventBasicWaiver_obj_rel_insert_input | null | undefined;
    eventBasicWaiverCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventBasicWaiver_obj_rel_insert_input = {
    data: eventBasicWaiver_insert_input;
    on_conflict?: eventBasicWaiver_on_conflict | null | undefined;
};
export type eventBasicWaiver_insert_input = {
    appliesToAllCategories?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventBasicWaiverCategories?: eventBasicWaiverCategory_arr_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    fileRef?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventBasicWaiver_on_conflict = {
    constraint: eventBasicWaiver_constraint;
    update_columns: Array<eventBasicWaiver_update_column>;
    where?: eventBasicWaiver_bool_exp | null | undefined;
};
export type eventBasicWaiverCategory_on_conflict = {
    constraint: eventBasicWaiverCategory_constraint;
    update_columns: Array<eventBasicWaiverCategory_update_column>;
    where?: eventBasicWaiverCategory_bool_exp | null | undefined;
};
export type eventCustomQuestionCategory_arr_rel_insert_input = {
    data: Array<eventCustomQuestionCategory_insert_input>;
    on_conflict?: eventCustomQuestionCategory_on_conflict | null | undefined;
};
export type eventCustomQuestionCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventCustomQuestion?: eventCustomQuestion_obj_rel_insert_input | null | undefined;
    eventCustomQuestionCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventCustomQuestion_obj_rel_insert_input = {
    data: eventCustomQuestion_insert_input;
    on_conflict?: eventCustomQuestion_on_conflict | null | undefined;
};
export type eventCustomQuestion_insert_input = {
    additionalInfo?: string | null | undefined;
    appliesToAllCategories?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventCustomQuestionCategories?: eventCustomQuestionCategory_arr_rel_insert_input | null | undefined;
    eventCustomQuestionOptions?: eventCustomQuestionOption_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    internalPurpose?: string | null | undefined;
    isRequired?: boolean | null | undefined;
    position?: number | null | undefined;
    questionType?: string | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventCustomQuestionOption_arr_rel_insert_input = {
    data: Array<eventCustomQuestionOption_insert_input>;
    on_conflict?: eventCustomQuestionOption_on_conflict | null | undefined;
};
export type eventCustomQuestionOption_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventCustomQuestion?: eventCustomQuestion_obj_rel_insert_input | null | undefined;
    eventCustomQuestionCuid?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    position?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventCustomQuestionOption_on_conflict = {
    constraint: eventCustomQuestionOption_constraint;
    update_columns: Array<eventCustomQuestionOption_update_column>;
    where?: eventCustomQuestionOption_bool_exp | null | undefined;
};
export type eventCustomQuestion_on_conflict = {
    constraint: eventCustomQuestion_constraint;
    update_columns: Array<eventCustomQuestion_update_column>;
    where?: eventCustomQuestion_bool_exp | null | undefined;
};
export type eventCustomQuestionCategory_on_conflict = {
    constraint: eventCustomQuestionCategory_constraint;
    update_columns: Array<eventCustomQuestionCategory_update_column>;
    where?: eventCustomQuestionCategory_bool_exp | null | undefined;
};
export type eventPricingGroupCategory_arr_rel_insert_input = {
    data: Array<eventPricingGroupCategory_insert_input>;
    on_conflict?: eventPricingGroupCategory_on_conflict | null | undefined;
};
export type eventPricingGroupCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventPricingGroup?: eventPricingGroup_obj_rel_insert_input | null | undefined;
    eventPricingGroupCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPricingGroup_obj_rel_insert_input = {
    data: eventPricingGroup_insert_input;
    on_conflict?: eventPricingGroup_on_conflict | null | undefined;
};
export type eventPricingGroup_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventPricingChanges?: eventPricingChange_arr_rel_insert_input | null | undefined;
    eventPricingGroupCategories?: eventPricingGroupCategory_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPricingChange_arr_rel_insert_input = {
    data: Array<eventPricingChange_insert_input>;
    on_conflict?: eventPricingChange_on_conflict | null | undefined;
};
export type eventPricingChange_insert_input = {
    amount?: number | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventPricingDate?: eventPricingDate_obj_rel_insert_input | null | undefined;
    eventPricingDateCuid?: string | null | undefined;
    eventPricingGroup?: eventPricingGroup_obj_rel_insert_input | null | undefined;
    eventPricingGroupCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPricingDate_obj_rel_insert_input = {
    data: eventPricingDate_insert_input;
    on_conflict?: eventPricingDate_on_conflict | null | undefined;
};
export type eventPricingDate_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    date?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventPricingChanges?: eventPricingChange_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPricingDate_on_conflict = {
    constraint: eventPricingDate_constraint;
    update_columns: Array<eventPricingDate_update_column>;
    where?: eventPricingDate_bool_exp | null | undefined;
};
export type eventPricingChange_on_conflict = {
    constraint: eventPricingChange_constraint;
    update_columns: Array<eventPricingChange_update_column>;
    where?: eventPricingChange_bool_exp | null | undefined;
};
export type eventPricingGroup_on_conflict = {
    constraint: eventPricingGroup_constraint;
    update_columns: Array<eventPricingGroup_update_column>;
    where?: eventPricingGroup_bool_exp | null | undefined;
};
export type eventPricingGroupCategory_on_conflict = {
    constraint: eventPricingGroupCategory_constraint;
    update_columns: Array<eventPricingGroupCategory_update_column>;
    where?: eventPricingGroupCategory_bool_exp | null | undefined;
};
export type eventSanctionCategory_arr_rel_insert_input = {
    data: Array<eventSanctionCategory_insert_input>;
    on_conflict?: eventSanctionCategory_on_conflict | null | undefined;
};
export type eventSanctionCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventSanction?: eventSanction_obj_rel_insert_input | null | undefined;
    eventSanctionCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSanction_obj_rel_insert_input = {
    data: eventSanction_insert_input;
    on_conflict?: eventSanction_on_conflict | null | undefined;
};
export type eventSanction_insert_input = {
    allowOneDayLicense?: boolean | null | undefined;
    appliesToAllCategories?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSanctionCategories?: eventSanctionCategory_arr_rel_insert_input | null | undefined;
    eventWaivers?: eventWaiver_arr_rel_insert_input | null | undefined;
    excludedWaiverCuids?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    isLicenseRequired?: boolean | null | undefined;
    oneDayFee?: number | null | undefined;
    otherSanctionName?: string | null | undefined;
    permit?: string | null | undefined;
    sanction?: sanction_obj_rel_insert_input | null | undefined;
    sanctionCuid?: string | null | undefined;
    sanctionWaivers?: sanctionWaivers_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventWaiver_arr_rel_insert_input = {
    data: Array<eventWaiver_insert_input>;
    on_conflict?: eventWaiver_on_conflict | null | undefined;
};
export type eventWaiver_insert_input = {
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSanction?: eventSanction_obj_rel_insert_input | null | undefined;
    eventSanctionCuid?: string | null | undefined;
    eventWaiverType?: eventWaiverType_obj_rel_insert_input | null | undefined;
    type?: eventWaiverType_enum | null | undefined;
    waiver?: waiver_obj_rel_insert_input | null | undefined;
    waiverCuid?: string | null | undefined;
};
export type eventWaiverType_obj_rel_insert_input = {
    data: eventWaiverType_insert_input;
    on_conflict?: eventWaiverType_on_conflict | null | undefined;
};
export type eventWaiverType_insert_input = {
    description?: string | null | undefined;
    eventWaivers?: eventWaiver_arr_rel_insert_input | null | undefined;
    type?: string | null | undefined;
};
export type eventWaiverType_on_conflict = {
    constraint: eventWaiverType_constraint;
    update_columns: Array<eventWaiverType_update_column>;
    where?: eventWaiverType_bool_exp | null | undefined;
};
export type waiver_obj_rel_insert_input = {
    data: waiver_insert_input;
    on_conflict?: waiver_on_conflict | null | undefined;
};
export type waiver_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    id?: number | null | undefined;
    latestRevision?: waiverRevision_obj_rel_insert_input | null | undefined;
    latestRevisionNum?: number | null | undefined;
    ownerCuid?: string | null | undefined;
    ownerType?: waiverOwner_enum | null | undefined;
    revisions?: waiverRevision_arr_rel_insert_input | null | undefined;
    sanctionWaivers?: sanctionWaivers_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    waiverOwner?: waiverOwner_obj_rel_insert_input | null | undefined;
};
export type waiverRevision_obj_rel_insert_input = {
    data: waiverRevision_insert_input;
    on_conflict?: waiverRevision_on_conflict | null | undefined;
};
export type waiverRevision_insert_input = {
    createdBy?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    fieldsConfiguration?: Record<string, unknown> | unknown[] | null | undefined;
    fileRef?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    revision?: number | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    waiver?: waiver_obj_rel_insert_input | null | undefined;
    waiverCuid?: string | null | undefined;
    waivers?: waiver_arr_rel_insert_input | null | undefined;
};
export type user_obj_rel_insert_input = {
    data: user_insert_input;
    on_conflict?: user_on_conflict | null | undefined;
};
export type user_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachById?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    coachCustomerUsers?: coachCustomerUser_arr_rel_insert_input | null | undefined;
    coachModerationFlags?: coachModerationFlag_arr_rel_insert_input | null | undefined;
    coachSetupStep?: number | null | undefined;
    coachSetupType?: string | null | undefined;
    created_at?: string | null | undefined;
    creatorInvitesByAcceptor?: creatorInvite_arr_rel_insert_input | null | undefined;
    creatorInvitesByInviter?: creatorInvite_arr_rel_insert_input | null | undefined;
    currency?: string | null | undefined;
    cyclingGoal?: string | null | undefined;
    deleted_at?: string | null | undefined;
    dob?: string | null | undefined;
    email?: string | null | undefined;
    eventModerationFlags?: eventModerationFlag_arr_rel_insert_input | null | undefined;
    eventQuestionAnswers?: eventQuestionAnswer_arr_rel_insert_input | null | undefined;
    eventQuestionAnswersDeletedByUser?: eventQuestionAnswer_arr_rel_insert_input | null | undefined;
    eventQuestions?: eventQuestion_arr_rel_insert_input | null | undefined;
    eventQuestionsDeletedByUser?: eventQuestion_arr_rel_insert_input | null | undefined;
    eventUpdateComments?: eventUpdateComment_arr_rel_insert_input | null | undefined;
    eventUpdateCommentsDeletedByUser?: eventUpdateComment_arr_rel_insert_input | null | undefined;
    eventUpdates?: eventUpdate_arr_rel_insert_input | null | undefined;
    eventUpdatesDeletedByUser?: eventUpdate_arr_rel_insert_input | null | undefined;
    facebookProfile?: string | null | undefined;
    firstName?: string | null | undefined;
    gender?: Record<string, unknown> | unknown[] | null | undefined;
    id?: string | null | undefined;
    idealRide?: string | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imageUsers?: imageUser_arr_rel_insert_input | null | undefined;
    instagramProfile?: string | null | undefined;
    isAdmin?: boolean | null | undefined;
    isCoach?: boolean | null | undefined;
    isCreator?: boolean | null | undefined;
    lastName?: string | null | undefined;
    lastSavedAt?: string | null | undefined;
    location?: string | null | undefined;
    organizerChannels?: organizerChannel_arr_rel_insert_input | null | undefined;
    organizerInvitesByInvitee?: organizerInvite_arr_rel_insert_input | null | undefined;
    organizerInvitesByInviter?: organizerInvite_arr_rel_insert_input | null | undefined;
    phone?: string | null | undefined;
    profilePicPublicId?: string | null | undefined;
    profilePicUrl?: string | null | undefined;
    pronouns?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    registrationsByPayinguserid?: registration_arr_rel_insert_input | null | undefined;
    reportClinicNoShows?: reportClinicNoShow_arr_rel_insert_input | null | undefined;
    reportLessonNoShows?: reportLessonNoShow_arr_rel_insert_input | null | undefined;
    reportedAsClinicNoShows?: reportClinicNoShow_arr_rel_insert_input | null | undefined;
    reportedAsLessonNoShows?: reportLessonNoShow_arr_rel_insert_input | null | undefined;
    reviews?: review_arr_rel_insert_input | null | undefined;
    ridingSince?: number | null | undefined;
    selectedOrganizerSlug?: string | null | undefined;
    showSetupCoach?: boolean | null | undefined;
    slug?: string | null | undefined;
    sponsors?: string | null | undefined;
    stravaProfile?: string | null | undefined;
    stripeConnectAccount?: stripeConnectAccount_obj_rel_insert_input | null | undefined;
    stripeConnectAccounts?: stripeConnectAccount_arr_rel_insert_input | null | undefined;
    stripeConnectId?: string | null | undefined;
    stripeCustomerId?: string | null | undefined;
    stripeIdentityVerificationError?: string | null | undefined;
    stripeIdentityVerificationSessionId?: string | null | undefined;
    stripeIdentityVerificationStatus?: string | null | undefined;
    timezone?: string | null | undefined;
    twitterProfile?: string | null | undefined;
    updated_at?: string | null | undefined;
    userAddresses?: userAddress_arr_rel_insert_input | null | undefined;
    userBikes?: userBike_arr_rel_insert_input | null | undefined;
    userCoaches?: coach_arr_rel_insert_input | null | undefined;
    userEmergencyContacts?: userEmergencyContact_arr_rel_insert_input | null | undefined;
    userImages?: userImage_arr_rel_insert_input | null | undefined;
    userOnlineCourses?: userOnlineCourse_arr_rel_insert_input | null | undefined;
    userOrganizerRoles?: userOrganizerRole_arr_rel_insert_input | null | undefined;
    userPersonalizations?: userPersonalization_arr_rel_insert_input | null | undefined;
    userRidingInterests?: userRidingInterest_arr_rel_insert_input | null | undefined;
    userSanctionLicenses?: userSanctionLicense_arr_rel_insert_input | null | undefined;
    userSavedCoaches?: userSavedCoach_arr_rel_insert_input | null | undefined;
    userSavedEvents?: userSavedEvent_arr_rel_insert_input | null | undefined;
    userSavedLessons?: userSavedLesson_arr_rel_insert_input | null | undefined;
    waiverRevisions?: waiverRevision_arr_rel_insert_input | null | undefined;
};
export type coach_obj_rel_insert_input = {
    data: coach_insert_input;
    on_conflict?: coach_on_conflict | null | undefined;
};
export type coach_on_conflict = {
    constraint: coach_constraint;
    update_columns: Array<coach_update_column>;
    where?: coach_bool_exp | null | undefined;
};
export type coachCustomerUser_arr_rel_insert_input = {
    data: Array<coachCustomerUser_insert_input>;
    on_conflict?: coachCustomerUser_on_conflict | null | undefined;
};
export type coachCustomerUser_insert_input = {
    age?: number | null | undefined;
    coachCustomer?: coachCustomer_obj_rel_insert_input | null | undefined;
    coachCustomerCuid?: string | null | undefined;
    coachCustomerMessageRequests?: coachCustomerMessageRequest_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    firstName?: string | null | undefined;
    id?: number | null | undefined;
    inviteStatus?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type coachCustomer_obj_rel_insert_input = {
    data: coachCustomer_insert_input;
    on_conflict?: coachCustomer_on_conflict | null | undefined;
};
export type coachCustomer_insert_input = {
    channelId?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_arr_rel_insert_input | null | undefined;
    coachCustomerMessageRequests?: coachCustomerMessageRequest_arr_rel_insert_input | null | undefined;
    coachCustomerUsers?: coachCustomerUser_arr_rel_insert_input | null | undefined;
    coachLesson?: coachLesson_obj_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    notes?: string | null | undefined;
    requestedCoachLessonCuid?: string | null | undefined;
    requestedStartTime?: string | null | undefined;
    status?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLesson_arr_rel_insert_input = {
    data: Array<coachCustomerLesson_insert_input>;
    on_conflict?: coachCustomerLesson_on_conflict | null | undefined;
};
export type coachCustomerLesson_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    cancellationReason?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    coachCustomer?: coachCustomer_obj_rel_insert_input | null | undefined;
    coachCustomerCuid?: string | null | undefined;
    coachCustomerLessonDates?: coachCustomerLessonDate_arr_rel_insert_input | null | undefined;
    coachCustomerLessonPauseResumes?: coachCustomerLessonPauseResume_arr_rel_insert_input | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_arr_rel_insert_input | null | undefined;
    coachLesson?: coachLesson_obj_rel_insert_input | null | undefined;
    coachLessonCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    currency?: string | null | undefined;
    disciplineNames?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    isVirtual?: boolean | null | undefined;
    offerCanceledDate?: string | null | undefined;
    offerMessageId?: string | null | undefined;
    offerRejectedDate?: string | null | undefined;
    paidDate?: string | null | undefined;
    price?: number | null | undefined;
    recurrenceType?: string | null | undefined;
    recurringInterval?: string | null | undefined;
    resumeRecurringLessonTriggerId?: string | null | undefined;
    skills?: Record<string, unknown> | unknown[] | null | undefined;
    status?: string | null | undefined;
    stripeSubscriptionId?: string | null | undefined;
    subscriptionCanceledDate?: string | null | undefined;
    subscriptionPausedUntil?: string | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLessonDate_arr_rel_insert_input = {
    data: Array<coachCustomerLessonDate_insert_input>;
    on_conflict?: coachCustomerLessonDate_on_conflict | null | undefined;
};
export type coachCustomerLessonDate_insert_input = {
    canceledDate?: string | null | undefined;
    coachCustomerLesson?: coachCustomerLesson_obj_rel_insert_input | null | undefined;
    coachCustomerLessonCuid?: string | null | undefined;
    coachCustomerLessonDateLocations?: coachCustomerLessonDateLocation_arr_rel_insert_input | null | undefined;
    coachCustomerLessonDateVirtualLocations?: coachCustomerLessonDateVirtualLocation_arr_rel_insert_input | null | undefined;
    coachCustomerLessonOneTimeCharges?: coachCustomerLessonOneTimeCharge_arr_rel_insert_input | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    duration?: number | null | undefined;
    id?: number | null | undefined;
    notes?: string | null | undefined;
    originalStartDate?: string | null | undefined;
    originalTimezone?: string | null | undefined;
    refundedDate?: string | null | undefined;
    reportLessonNoShows?: reportLessonNoShow_arr_rel_insert_input | null | undefined;
    startDate?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLesson_obj_rel_insert_input = {
    data: coachCustomerLesson_insert_input;
    on_conflict?: coachCustomerLesson_on_conflict | null | undefined;
};
export type coachCustomerLesson_on_conflict = {
    constraint: coachCustomerLesson_constraint;
    update_columns: Array<coachCustomerLesson_update_column>;
    where?: coachCustomerLesson_bool_exp | null | undefined;
};
export type coachCustomerLessonDateLocation_arr_rel_insert_input = {
    data: Array<coachCustomerLessonDateLocation_insert_input>;
    on_conflict?: coachCustomerLessonDateLocation_on_conflict | null | undefined;
};
export type coachCustomerLessonDateLocation_insert_input = {
    addressLine1?: string | null | undefined;
    city?: string | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_obj_rel_insert_input | null | undefined;
    coachCustomerLessonDateCuid?: string | null | undefined;
    country?: string | null | undefined;
    countryCode?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    fullAddress?: string | null | undefined;
    googlePlaceId?: string | null | undefined;
    id?: number | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    state?: string | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
    zipCode?: string | null | undefined;
};
export type coachCustomerLessonDate_obj_rel_insert_input = {
    data: coachCustomerLessonDate_insert_input;
    on_conflict?: coachCustomerLessonDate_on_conflict | null | undefined;
};
export type coachCustomerLessonDate_on_conflict = {
    constraint: coachCustomerLessonDate_constraint;
    update_columns: Array<coachCustomerLessonDate_update_column>;
    where?: coachCustomerLessonDate_bool_exp | null | undefined;
};
export type coachCustomerLessonDateLocation_on_conflict = {
    constraint: coachCustomerLessonDateLocation_constraint;
    update_columns: Array<coachCustomerLessonDateLocation_update_column>;
    where?: coachCustomerLessonDateLocation_bool_exp | null | undefined;
};
export type coachCustomerLessonDateVirtualLocation_arr_rel_insert_input = {
    data: Array<coachCustomerLessonDateVirtualLocation_insert_input>;
    on_conflict?: coachCustomerLessonDateVirtualLocation_on_conflict | null | undefined;
};
export type coachCustomerLessonDateVirtualLocation_insert_input = {
    coachCustomerLessonDate?: coachCustomerLessonDate_obj_rel_insert_input | null | undefined;
    coachCustomerLessonDateCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    meetingLink?: string | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLessonDateVirtualLocation_on_conflict = {
    constraint: coachCustomerLessonDateVirtualLocation_constraint;
    update_columns: Array<coachCustomerLessonDateVirtualLocation_update_column>;
    where?: coachCustomerLessonDateVirtualLocation_bool_exp | null | undefined;
};
export type coachCustomerLessonOneTimeCharge_arr_rel_insert_input = {
    data: Array<coachCustomerLessonOneTimeCharge_insert_input>;
    on_conflict?: coachCustomerLessonOneTimeCharge_on_conflict | null | undefined;
};
export type coachCustomerLessonOneTimeCharge_insert_input = {
    coachCustomerLessonDate?: coachCustomerLessonDate_obj_rel_insert_input | null | undefined;
    coachCustomerLessonDateCuid?: string | null | undefined;
    coachCustomerLessonTransactions?: coachCustomerLessonTransaction_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    id?: number | null | undefined;
    offerMessageId?: string | null | undefined;
    paidDate?: string | null | undefined;
    price?: number | null | undefined;
    refundedDate?: string | null | undefined;
    status?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLessonTransaction_arr_rel_insert_input = {
    data: Array<coachCustomerLessonTransaction_insert_input>;
    on_conflict?: coachCustomerLessonTransaction_on_conflict | null | undefined;
};
export type coachCustomerLessonTransaction_insert_input = {
    coachCustomerLesson?: coachCustomerLesson_obj_rel_insert_input | null | undefined;
    coachCustomerLessonCuid?: string | null | undefined;
    coachCustomerLessonDate?: coachCustomerLessonDate_obj_rel_insert_input | null | undefined;
    coachCustomerLessonDateCuid?: string | null | undefined;
    coachCustomerLessonOneTimeCharge?: coachCustomerLessonOneTimeCharge_obj_rel_insert_input | null | undefined;
    coachCustomerLessonOneTimeChargeCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    referenceNo?: string | null | undefined;
    stripeCheckoutId?: string | null | undefined;
    stripeInvoiceUrl?: string | null | undefined;
    stripePaymentIntentId?: string | null | undefined;
    successToken?: string | null | undefined;
    transactionStatus?: string | null | undefined;
    transactionType?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLessonOneTimeCharge_obj_rel_insert_input = {
    data: coachCustomerLessonOneTimeCharge_insert_input;
    on_conflict?: coachCustomerLessonOneTimeCharge_on_conflict | null | undefined;
};
export type coachCustomerLessonOneTimeCharge_on_conflict = {
    constraint: coachCustomerLessonOneTimeCharge_constraint;
    update_columns: Array<coachCustomerLessonOneTimeCharge_update_column>;
    where?: coachCustomerLessonOneTimeCharge_bool_exp | null | undefined;
};
export type coachCustomerLessonTransaction_on_conflict = {
    constraint: coachCustomerLessonTransaction_constraint;
    update_columns: Array<coachCustomerLessonTransaction_update_column>;
    where?: coachCustomerLessonTransaction_bool_exp | null | undefined;
};
export type reportLessonNoShow_arr_rel_insert_input = {
    data: Array<reportLessonNoShow_insert_input>;
    on_conflict?: reportLessonNoShow_on_conflict | null | undefined;
};
export type reportLessonNoShow_insert_input = {
    coachCustomerLessonDate?: coachCustomerLessonDate_obj_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    lessonDateCuid?: string | null | undefined;
    reportedUser?: user_obj_rel_insert_input | null | undefined;
    reportedUserId?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userDistance?: number | null | undefined;
    userId?: string | null | undefined;
    userIsCoach?: boolean | null | undefined;
    userLocation?: Record<string, unknown> | unknown[] | null | undefined;
};
export type reportLessonNoShow_on_conflict = {
    constraint: reportLessonNoShow_constraint;
    update_columns: Array<reportLessonNoShow_update_column>;
    where?: reportLessonNoShow_bool_exp | null | undefined;
};
export type coachCustomerLessonPauseResume_arr_rel_insert_input = {
    data: Array<coachCustomerLessonPauseResume_insert_input>;
    on_conflict?: coachCustomerLessonPauseResume_on_conflict | null | undefined;
};
export type coachCustomerLessonPauseResume_insert_input = {
    actionDate?: string | null | undefined;
    actionType?: string | null | undefined;
    coachCustomerLesson?: coachCustomerLesson_obj_rel_insert_input | null | undefined;
    coachCustomerLessonCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    pauseOnDate?: string | null | undefined;
    pausedUntilDate?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerLessonPauseResume_on_conflict = {
    constraint: coachCustomerLessonPauseResume_constraint;
    update_columns: Array<coachCustomerLessonPauseResume_update_column>;
    where?: coachCustomerLessonPauseResume_bool_exp | null | undefined;
};
export type coachLesson_obj_rel_insert_input = {
    data: coachLesson_insert_input;
    on_conflict?: coachLesson_on_conflict | null | undefined;
};
export type coachLesson_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    coachCustomerLessons?: coachCustomerLesson_arr_rel_insert_input | null | undefined;
    coachCustomers?: coachCustomer_arr_rel_insert_input | null | undefined;
    coachLessonLocations?: coachLessonLocation_arr_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    disciplineNames?: Record<string, unknown> | unknown[] | null | undefined;
    duration?: number | null | undefined;
    highlightedPosition?: number | null | undefined;
    id?: number | null | undefined;
    isBundle?: boolean | null | undefined;
    isHidden?: boolean | null | undefined;
    isVirtual?: boolean | null | undefined;
    lessonsPerBundle?: number | null | undefined;
    participantRange?: Record<string, unknown> | unknown[] | null | undefined;
    position?: number | null | undefined;
    price?: number | null | undefined;
    recurrenceType?: string | null | undefined;
    recurringInterval?: string | null | undefined;
    requestToBook?: boolean | null | undefined;
    skills?: Record<string, unknown> | unknown[] | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
    userSavedLessons?: userSavedLesson_arr_rel_insert_input | null | undefined;
};
export type coachCustomer_arr_rel_insert_input = {
    data: Array<coachCustomer_insert_input>;
    on_conflict?: coachCustomer_on_conflict | null | undefined;
};
export type coachCustomer_on_conflict = {
    constraint: coachCustomer_constraint;
    update_columns: Array<coachCustomer_update_column>;
    where?: coachCustomer_bool_exp | null | undefined;
};
export type coachLessonLocation_arr_rel_insert_input = {
    data: Array<coachLessonLocation_insert_input>;
    on_conflict?: coachLessonLocation_on_conflict | null | undefined;
};
export type coachLessonLocation_insert_input = {
    coachLesson?: coachLesson_obj_rel_insert_input | null | undefined;
    coachLessonCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    lessonLocation?: lessonLocation_obj_rel_insert_input | null | undefined;
    lessonLocationCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type lessonLocation_obj_rel_insert_input = {
    data: lessonLocation_insert_input;
    on_conflict?: lessonLocation_on_conflict | null | undefined;
};
export type lessonLocation_insert_input = {
    address?: string | null | undefined;
    addressLine1?: string | null | undefined;
    addressLine2?: string | null | undefined;
    city?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    coachLessonLocations?: coachLessonLocation_arr_rel_insert_input | null | undefined;
    country?: string | null | undefined;
    countryCode?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    googlePlaceId?: string | null | undefined;
    id?: number | null | undefined;
    isDefault?: boolean | null | undefined;
    label?: string | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    state?: string | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
    zipCode?: string | null | undefined;
};
export type lessonLocation_on_conflict = {
    constraint: lessonLocation_constraint;
    update_columns: Array<lessonLocation_update_column>;
    where?: lessonLocation_bool_exp | null | undefined;
};
export type coachLessonLocation_on_conflict = {
    constraint: coachLessonLocation_constraint;
    update_columns: Array<coachLessonLocation_update_column>;
    where?: coachLessonLocation_bool_exp | null | undefined;
};
export type userSavedLesson_arr_rel_insert_input = {
    data: Array<userSavedLesson_insert_input>;
    on_conflict?: userSavedLesson_on_conflict | null | undefined;
};
export type userSavedLesson_insert_input = {
    coachLesson?: coachLesson_obj_rel_insert_input | null | undefined;
    coachLessonCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userSavedLesson_on_conflict = {
    constraint: userSavedLesson_constraint;
    update_columns: Array<userSavedLesson_update_column>;
    where?: userSavedLesson_bool_exp | null | undefined;
};
export type coachLesson_on_conflict = {
    constraint: coachLesson_constraint;
    update_columns: Array<coachLesson_update_column>;
    where?: coachLesson_bool_exp | null | undefined;
};
export type coachCustomerMessageRequest_arr_rel_insert_input = {
    data: Array<coachCustomerMessageRequest_insert_input>;
    on_conflict?: coachCustomerMessageRequest_on_conflict | null | undefined;
};
export type coachCustomerMessageRequest_insert_input = {
    coachCustomer?: coachCustomer_obj_rel_insert_input | null | undefined;
    coachCustomerChannelId?: string | null | undefined;
    coachCustomerUser?: coachCustomerUser_obj_rel_insert_input | null | undefined;
    coachCustomerUserCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    message?: string | null | undefined;
    streamMessageId?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachCustomerUser_obj_rel_insert_input = {
    data: coachCustomerUser_insert_input;
    on_conflict?: coachCustomerUser_on_conflict | null | undefined;
};
export type coachCustomerUser_on_conflict = {
    constraint: coachCustomerUser_constraint;
    update_columns: Array<coachCustomerUser_update_column>;
    where?: coachCustomerUser_bool_exp | null | undefined;
};
export type coachCustomerMessageRequest_on_conflict = {
    constraint: coachCustomerMessageRequest_constraint;
    update_columns: Array<coachCustomerMessageRequest_update_column>;
    where?: coachCustomerMessageRequest_bool_exp | null | undefined;
};
export type coachModerationFlag_arr_rel_insert_input = {
    data: Array<coachModerationFlag_insert_input>;
    on_conflict?: coachModerationFlag_on_conflict | null | undefined;
};
export type coachModerationFlag_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    isReportOpen?: boolean | null | undefined;
    reason?: string | null | undefined;
    reasonDetails?: string | null | undefined;
    reportPriority?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type coachModerationFlag_on_conflict = {
    constraint: coachModerationFlag_constraint;
    update_columns: Array<coachModerationFlag_update_column>;
    where?: coachModerationFlag_bool_exp | null | undefined;
};
export type creatorInvite_arr_rel_insert_input = {
    data: Array<creatorInvite_insert_input>;
    on_conflict?: creatorInvite_on_conflict | null | undefined;
};
export type creatorInvite_insert_input = {
    acceptedAt?: string | null | undefined;
    acceptedUserId?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    inviteEmail?: string | null | undefined;
    inviterUserId?: string | null | undefined;
    updated_at?: string | null | undefined;
    userByAcceptor?: user_obj_rel_insert_input | null | undefined;
    userByInviter?: user_obj_rel_insert_input | null | undefined;
};
export type creatorInvite_on_conflict = {
    constraint: creatorInvite_constraint;
    update_columns: Array<creatorInvite_update_column>;
    where?: creatorInvite_bool_exp | null | undefined;
};
export type eventModerationFlag_arr_rel_insert_input = {
    data: Array<eventModerationFlag_insert_input>;
    on_conflict?: eventModerationFlag_on_conflict | null | undefined;
};
export type eventModerationFlag_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    isReportOpen?: boolean | null | undefined;
    reason?: string | null | undefined;
    reasonDetails?: string | null | undefined;
    reportPriority?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type eventModerationFlag_on_conflict = {
    constraint: eventModerationFlag_constraint;
    update_columns: Array<eventModerationFlag_update_column>;
    where?: eventModerationFlag_bool_exp | null | undefined;
};
export type eventQuestionAnswer_arr_rel_insert_input = {
    data: Array<eventQuestionAnswer_insert_input>;
    on_conflict?: eventQuestionAnswer_on_conflict | null | undefined;
};
export type eventQuestionAnswer_insert_input = {
    body?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deletedByUser?: user_obj_rel_insert_input | null | undefined;
    deletedByUserId?: string | null | undefined;
    deleted_at?: string | null | undefined;
    eventQuestion?: eventQuestion_obj_rel_insert_input | null | undefined;
    eventQuestionCuid?: string | null | undefined;
    id?: number | null | undefined;
    isOrganizer?: boolean | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type eventQuestion_obj_rel_insert_input = {
    data: eventQuestion_insert_input;
    on_conflict?: eventQuestion_on_conflict | null | undefined;
};
export type eventQuestion_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deletedByUser?: user_obj_rel_insert_input | null | undefined;
    deletedByUserId?: string | null | undefined;
    deleted_at?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventQuestionAnswers?: eventQuestionAnswer_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type eventQuestion_on_conflict = {
    constraint: eventQuestion_constraint;
    update_columns: Array<eventQuestion_update_column>;
    where?: eventQuestion_bool_exp | null | undefined;
};
export type eventQuestionAnswer_on_conflict = {
    constraint: eventQuestionAnswer_constraint;
    update_columns: Array<eventQuestionAnswer_update_column>;
    where?: eventQuestionAnswer_bool_exp | null | undefined;
};
export type eventQuestion_arr_rel_insert_input = {
    data: Array<eventQuestion_insert_input>;
    on_conflict?: eventQuestion_on_conflict | null | undefined;
};
export type eventUpdateComment_arr_rel_insert_input = {
    data: Array<eventUpdateComment_insert_input>;
    on_conflict?: eventUpdateComment_on_conflict | null | undefined;
};
export type eventUpdateComment_insert_input = {
    body?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deletedByUser?: user_obj_rel_insert_input | null | undefined;
    deletedByUserId?: string | null | undefined;
    deleted_at?: string | null | undefined;
    eventUpdate?: eventUpdate_obj_rel_insert_input | null | undefined;
    eventUpdateCuid?: string | null | undefined;
    id?: number | null | undefined;
    isOrganizer?: boolean | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type eventUpdate_obj_rel_insert_input = {
    data: eventUpdate_insert_input;
    on_conflict?: eventUpdate_on_conflict | null | undefined;
};
export type eventUpdate_insert_input = {
    body?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deletedByUser?: user_obj_rel_insert_input | null | undefined;
    deletedByUserId?: string | null | undefined;
    deleted_at?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventUpdateComments?: eventUpdateComment_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type eventUpdate_on_conflict = {
    constraint: eventUpdate_constraint;
    update_columns: Array<eventUpdate_update_column>;
    where?: eventUpdate_bool_exp | null | undefined;
};
export type eventUpdateComment_on_conflict = {
    constraint: eventUpdateComment_constraint;
    update_columns: Array<eventUpdateComment_update_column>;
    where?: eventUpdateComment_bool_exp | null | undefined;
};
export type eventUpdate_arr_rel_insert_input = {
    data: Array<eventUpdate_insert_input>;
    on_conflict?: eventUpdate_on_conflict | null | undefined;
};
export type image_obj_rel_insert_input = {
    data: image_insert_input;
    on_conflict?: image_on_conflict | null | undefined;
};
export type image_insert_input = {
    bytes?: number | null | undefined;
    colors?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    eventImage?: eventImage_obj_rel_insert_input | null | undefined;
    eventImages?: eventImage_arr_rel_insert_input | null | undefined;
    format?: string | null | undefined;
    height?: number | null | undefined;
    merchandiseImages?: merchandiseImage_arr_rel_insert_input | null | undefined;
    merchandiseVariants?: merchandiseVariant_arr_rel_insert_input | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    organizers?: organizer_arr_rel_insert_input | null | undefined;
    originalFilename?: string | null | undefined;
    publicId?: string | null | undefined;
    relativeUrl?: string | null | undefined;
    resourceType?: string | null | undefined;
    seriesImages?: seriesImage_arr_rel_insert_input | null | undefined;
    sponsorImages?: sponsorImage_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    url?: string | null | undefined;
    userImages?: userImage_arr_rel_insert_input | null | undefined;
    users?: user_arr_rel_insert_input | null | undefined;
    version?: number | null | undefined;
    width?: number | null | undefined;
};
export type eventImage_obj_rel_insert_input = {
    data: eventImage_insert_input;
    on_conflict?: eventImage_on_conflict | null | undefined;
};
export type eventImage_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    position?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventImage_on_conflict = {
    constraint: eventImage_constraint;
    update_columns: Array<eventImage_update_column>;
    where?: eventImage_bool_exp | null | undefined;
};
export type eventImage_arr_rel_insert_input = {
    data: Array<eventImage_insert_input>;
    on_conflict?: eventImage_on_conflict | null | undefined;
};
export type merchandiseImage_arr_rel_insert_input = {
    data: Array<merchandiseImage_insert_input>;
    on_conflict?: merchandiseImage_on_conflict | null | undefined;
};
export type merchandiseImage_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    isDefault?: boolean | null | undefined;
    merchandise?: merchandise_obj_rel_insert_input | null | undefined;
    merchandiseCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type merchandise_obj_rel_insert_input = {
    data: merchandise_insert_input;
    on_conflict?: merchandise_on_conflict | null | undefined;
};
export type merchandise_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    deliveryType?: merchandiseDeliveryType_enum | null | undefined;
    description?: string | null | undefined;
    eventMerchandises?: eventMerchandise_arr_rel_insert_input | null | undefined;
    merchandiseDeliveryType?: merchandiseDeliveryType_obj_rel_insert_input | null | undefined;
    merchandiseImages?: merchandiseImage_arr_rel_insert_input | null | undefined;
    merchandiseVariantOptions?: merchandiseVariantOption_arr_rel_insert_input | null | undefined;
    merchandiseVariants?: merchandiseVariant_arr_rel_insert_input | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    price?: number | null | undefined;
    stock?: number | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
    vendorId?: number | null | undefined;
};
export type eventMerchandise_arr_rel_insert_input = {
    data: Array<eventMerchandise_insert_input>;
    on_conflict?: eventMerchandise_on_conflict | null | undefined;
};
export type eventMerchandise_insert_input = {
    availableTo?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    merchandise?: merchandise_obj_rel_insert_input | null | undefined;
    merchandiseCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
    visible?: boolean | null | undefined;
};
export type eventMerchandise_on_conflict = {
    constraint: eventMerchandise_constraint;
    update_columns: Array<eventMerchandise_update_column>;
    where?: eventMerchandise_bool_exp | null | undefined;
};
export type merchandiseDeliveryType_obj_rel_insert_input = {
    data: merchandiseDeliveryType_insert_input;
    on_conflict?: merchandiseDeliveryType_on_conflict | null | undefined;
};
export type merchandiseDeliveryType_insert_input = {
    merchandises?: merchandise_arr_rel_insert_input | null | undefined;
    name?: string | null | undefined;
};
export type merchandise_arr_rel_insert_input = {
    data: Array<merchandise_insert_input>;
    on_conflict?: merchandise_on_conflict | null | undefined;
};
export type merchandise_on_conflict = {
    constraint: merchandise_constraint;
    update_columns: Array<merchandise_update_column>;
    where?: merchandise_bool_exp | null | undefined;
};
export type merchandiseDeliveryType_on_conflict = {
    constraint: merchandiseDeliveryType_constraint;
    update_columns: Array<merchandiseDeliveryType_update_column>;
    where?: merchandiseDeliveryType_bool_exp | null | undefined;
};
export type merchandiseVariantOption_arr_rel_insert_input = {
    data: Array<merchandiseVariantOption_insert_input>;
    on_conflict?: merchandiseVariantOption_on_conflict | null | undefined;
};
export type merchandiseVariantOption_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    merchandise?: merchandise_obj_rel_insert_input | null | undefined;
    merchandiseCuid?: string | null | undefined;
    merchandiseVariantOptionValues?: merchandiseVariantOptionValue_arr_rel_insert_input | null | undefined;
    optionName?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type merchandiseVariantOptionValue_arr_rel_insert_input = {
    data: Array<merchandiseVariantOptionValue_insert_input>;
    on_conflict?: merchandiseVariantOptionValue_on_conflict | null | undefined;
};
export type merchandiseVariantOptionValue_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    merchandiseVariantOption?: merchandiseVariantOption_obj_rel_insert_input | null | undefined;
    merchandiseVariantOptionCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
    value?: string | null | undefined;
};
export type merchandiseVariantOption_obj_rel_insert_input = {
    data: merchandiseVariantOption_insert_input;
    on_conflict?: merchandiseVariantOption_on_conflict | null | undefined;
};
export type merchandiseVariantOption_on_conflict = {
    constraint: merchandiseVariantOption_constraint;
    update_columns: Array<merchandiseVariantOption_update_column>;
    where?: merchandiseVariantOption_bool_exp | null | undefined;
};
export type merchandiseVariantOptionValue_on_conflict = {
    constraint: merchandiseVariantOptionValue_constraint;
    update_columns: Array<merchandiseVariantOptionValue_update_column>;
    where?: merchandiseVariantOptionValue_bool_exp | null | undefined;
};
export type merchandiseVariant_arr_rel_insert_input = {
    data: Array<merchandiseVariant_insert_input>;
    on_conflict?: merchandiseVariant_on_conflict | null | undefined;
};
export type merchandiseVariant_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    merchandise?: merchandise_obj_rel_insert_input | null | undefined;
    merchandiseCuid?: string | null | undefined;
    optionCuidValueCuid?: Record<string, unknown> | unknown[] | null | undefined;
    price?: number | null | undefined;
    stock?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type merchandiseVariant_on_conflict = {
    constraint: merchandiseVariant_constraint;
    update_columns: Array<merchandiseVariant_update_column>;
    where?: merchandiseVariant_bool_exp | null | undefined;
};
export type organizer_obj_rel_insert_input = {
    data: organizer_insert_input;
    on_conflict?: organizer_on_conflict | null | undefined;
};
export type organizer_on_conflict = {
    constraint: organizer_constraint;
    update_columns: Array<organizer_update_column>;
    where?: organizer_bool_exp | null | undefined;
};
export type merchandiseImage_on_conflict = {
    constraint: merchandiseImage_constraint;
    update_columns: Array<merchandiseImage_update_column>;
    where?: merchandiseImage_bool_exp | null | undefined;
};
export type organizer_arr_rel_insert_input = {
    data: Array<organizer_insert_input>;
    on_conflict?: organizer_on_conflict | null | undefined;
};
export type seriesImage_arr_rel_insert_input = {
    data: Array<seriesImage_insert_input>;
    on_conflict?: seriesImage_on_conflict | null | undefined;
};
export type seriesImage_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    isDefault?: boolean | null | undefined;
    series?: series_obj_rel_insert_input | null | undefined;
    seriesCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type series_obj_rel_insert_input = {
    data: series_insert_input;
    on_conflict?: series_on_conflict | null | undefined;
};
export type series_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    eventSeries?: eventSeries_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    isJoinable?: boolean | null | undefined;
    joinKey?: string | null | undefined;
    name?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    seriesActivity?: string | null | undefined;
    seriesImages?: seriesImage_arr_rel_insert_input | null | undefined;
    seriesPointsStructureCustoms?: seriesPointsStructureCustom_arr_rel_insert_input | null | undefined;
    seriesPointsStructurePresets?: seriesPointsStructurePreset_arr_rel_insert_input | null | undefined;
    slug?: string | null | undefined;
    totalRounds?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSeries_arr_rel_insert_input = {
    data: Array<eventSeries_insert_input>;
    on_conflict?: eventSeries_on_conflict | null | undefined;
};
export type eventSeries_insert_input = {
    appliesToAllCategories?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSeriesCategories?: eventSeriesCategory_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    registrationSeries?: registrationSeries_arr_rel_insert_input | null | undefined;
    roundNumber?: number | null | undefined;
    series?: series_obj_rel_insert_input | null | undefined;
    seriesCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSeriesCategory_arr_rel_insert_input = {
    data: Array<eventSeriesCategory_insert_input>;
    on_conflict?: eventSeriesCategory_on_conflict | null | undefined;
};
export type eventSeriesCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventSeries?: eventSeries_obj_rel_insert_input | null | undefined;
    eventSeriesCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSeries_obj_rel_insert_input = {
    data: eventSeries_insert_input;
    on_conflict?: eventSeries_on_conflict | null | undefined;
};
export type eventSeries_on_conflict = {
    constraint: eventSeries_constraint;
    update_columns: Array<eventSeries_update_column>;
    where?: eventSeries_bool_exp | null | undefined;
};
export type eventSeriesCategory_on_conflict = {
    constraint: eventSeriesCategory_constraint;
    update_columns: Array<eventSeriesCategory_update_column>;
    where?: eventSeriesCategory_bool_exp | null | undefined;
};
export type registrationSeries_arr_rel_insert_input = {
    data: Array<registrationSeries_insert_input>;
    on_conflict?: registrationSeries_on_conflict | null | undefined;
};
export type registrationSeries_insert_input = {
    eventSeries?: eventSeries_obj_rel_insert_input | null | undefined;
    eventSeriesCuid?: string | null | undefined;
    id?: number | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
};
export type registration_obj_rel_insert_input = {
    data: registration_insert_input;
    on_conflict?: registration_on_conflict | null | undefined;
};
export type registration_insert_input = {
    activity?: registrationActivity_obj_rel_insert_input | null | undefined;
    address?: string | null | undefined;
    addressCity?: string | null | undefined;
    addressCountry?: string | null | undefined;
    addressLine1?: string | null | undefined;
    addressLine2?: string | null | undefined;
    addressState?: string | null | undefined;
    addressZip?: string | null | undefined;
    basicSignatureRequests?: basicSignatureRequest_arr_rel_insert_input | null | undefined;
    clinic?: clinic_obj_rel_insert_input | null | undefined;
    clinicCuid?: string | null | undefined;
    completedAt?: string | null | undefined;
    contactName?: string | null | undefined;
    contactPhone?: string | null | undefined;
    contactRelationship?: string | null | undefined;
    context?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    customQuestions?: Record<string, unknown> | unknown[] | null | undefined;
    dob?: string | null | undefined;
    email?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    firstName?: string | null | undefined;
    for?: registrationFor_enum | null | undefined;
    id?: number | null | undefined;
    isCheckedIn?: boolean | null | undefined;
    lastName?: string | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    payingUser?: user_obj_rel_insert_input | null | undefined;
    payingUserId?: string | null | undefined;
    phone?: string | null | undefined;
    promoCode?: Record<string, unknown> | unknown[] | null | undefined;
    referenceNo?: string | null | undefined;
    registrationAdditionalPayments?: registrationAdditionalPayment_arr_rel_insert_input | null | undefined;
    registrationCategories?: registrationCategory_arr_rel_insert_input | null | undefined;
    registrationFor?: registrationFor_obj_rel_insert_input | null | undefined;
    registrationSeries?: registrationSeries_arr_rel_insert_input | null | undefined;
    registrationStatus?: registrationStatus_obj_rel_insert_input | null | undefined;
    registrationTickets?: registrationTicket_arr_rel_insert_input | null | undefined;
    ride?: ride_obj_rel_insert_input | null | undefined;
    rideCuid?: string | null | undefined;
    sanctionLicenses?: Record<string, unknown> | unknown[] | null | undefined;
    signatureRequests?: signatureRequest_arr_rel_insert_input | null | undefined;
    sponsors?: string | null | undefined;
    status?: registrationStatus_enum | null | undefined;
    stripeCheckoutId?: string | null | undefined;
    stripePaymentIntentId?: string | null | undefined;
    successToken?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type registrationActivity_obj_rel_insert_input = {
    data: registrationActivity_insert_input;
    on_conflict?: registrationActivity_on_conflict | null | undefined;
};
export type registrationActivity_insert_input = {
    created_at?: string | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    log?: Record<string, unknown> | unknown[] | null | undefined;
    notes?: string | null | undefined;
    referenceNo?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
};
export type registration_arr_rel_insert_input = {
    data: Array<registration_insert_input>;
    on_conflict?: registration_on_conflict | null | undefined;
};
export type registration_on_conflict = {
    constraint: registration_constraint;
    update_columns: Array<registration_update_column>;
    where?: registration_bool_exp | null | undefined;
};
export type registrationActivity_on_conflict = {
    constraint: registrationActivity_constraint;
    update_columns: Array<registrationActivity_update_column>;
    where?: registrationActivity_bool_exp | null | undefined;
};
export type basicSignatureRequest_arr_rel_insert_input = {
    data: Array<basicSignatureRequest_insert_input>;
    on_conflict?: basicSignatureRequest_on_conflict | null | undefined;
};
export type basicSignatureRequest_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
    signatureRequestSignerType?: signatureRequestSignerType_obj_rel_insert_input | null | undefined;
    signatureRequestStatus?: signatureRequestStatus_obj_rel_insert_input | null | undefined;
    signerEmail?: string | null | undefined;
    signerName?: string | null | undefined;
    signerType?: signatureRequestSignerType_enum | null | undefined;
    status?: signatureRequestStatus_enum | null | undefined;
    successToken?: string | null | undefined;
    updated_at?: string | null | undefined;
    waiverFileUrl?: string | null | undefined;
    waiverName?: string | null | undefined;
};
export type signatureRequestSignerType_obj_rel_insert_input = {
    data: signatureRequestSignerType_insert_input;
    on_conflict?: signatureRequestSignerType_on_conflict | null | undefined;
};
export type signatureRequestSignerType_insert_input = {
    basicSignatureRequests?: basicSignatureRequest_arr_rel_insert_input | null | undefined;
    signatureRequests?: signatureRequest_arr_rel_insert_input | null | undefined;
    type?: string | null | undefined;
};
export type signatureRequest_arr_rel_insert_input = {
    data: Array<signatureRequest_insert_input>;
    on_conflict?: signatureRequest_on_conflict | null | undefined;
};
export type signatureRequest_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
    signatureId?: string | null | undefined;
    signatureRequestId?: string | null | undefined;
    signatureRequestSignerType?: signatureRequestSignerType_obj_rel_insert_input | null | undefined;
    signatureRequestStatus?: signatureRequestStatus_obj_rel_insert_input | null | undefined;
    signerEmail?: string | null | undefined;
    signerName?: string | null | undefined;
    signerType?: signatureRequestSignerType_enum | null | undefined;
    status?: signatureRequestStatus_enum | null | undefined;
    updated_at?: string | null | undefined;
    waiverRevision?: waiverRevision_obj_rel_insert_input | null | undefined;
    waiverRevisionCuid?: string | null | undefined;
};
export type signatureRequestStatus_obj_rel_insert_input = {
    data: signatureRequestStatus_insert_input;
    on_conflict?: signatureRequestStatus_on_conflict | null | undefined;
};
export type signatureRequestStatus_insert_input = {
    basicSignatureRequests?: basicSignatureRequest_arr_rel_insert_input | null | undefined;
    description?: string | null | undefined;
    signatureRequests?: signatureRequest_arr_rel_insert_input | null | undefined;
    type?: string | null | undefined;
};
export type signatureRequestStatus_on_conflict = {
    constraint: signatureRequestStatus_constraint;
    update_columns: Array<signatureRequestStatus_update_column>;
    where?: signatureRequestStatus_bool_exp | null | undefined;
};
export type signatureRequest_on_conflict = {
    constraint: signatureRequest_constraint;
    update_columns: Array<signatureRequest_update_column>;
    where?: signatureRequest_bool_exp | null | undefined;
};
export type signatureRequestSignerType_on_conflict = {
    constraint: signatureRequestSignerType_constraint;
    update_columns: Array<signatureRequestSignerType_update_column>;
    where?: signatureRequestSignerType_bool_exp | null | undefined;
};
export type basicSignatureRequest_on_conflict = {
    constraint: basicSignatureRequest_constraint;
    update_columns: Array<basicSignatureRequest_update_column>;
    where?: basicSignatureRequest_bool_exp | null | undefined;
};
export type registrationAdditionalPayment_arr_rel_insert_input = {
    data: Array<registrationAdditionalPayment_insert_input>;
    on_conflict?: registrationAdditionalPayment_on_conflict | null | undefined;
};
export type registrationAdditionalPayment_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    id?: number | null | undefined;
    paymentType?: string | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
    registrationStatus?: registrationStatus_obj_rel_insert_input | null | undefined;
    status?: registrationStatus_enum | null | undefined;
    stripeCheckoutId?: string | null | undefined;
    stripePaymentIntentId?: string | null | undefined;
    successToken?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type registrationStatus_obj_rel_insert_input = {
    data: registrationStatus_insert_input;
    on_conflict?: registrationStatus_on_conflict | null | undefined;
};
export type registrationStatus_insert_input = {
    registrationAdditionalPayments?: registrationAdditionalPayment_arr_rel_insert_input | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    status?: string | null | undefined;
};
export type registrationStatus_on_conflict = {
    constraint: registrationStatus_constraint;
    update_columns: Array<registrationStatus_update_column>;
    where?: registrationStatus_bool_exp | null | undefined;
};
export type registrationAdditionalPayment_on_conflict = {
    constraint: registrationAdditionalPayment_constraint;
    update_columns: Array<registrationAdditionalPayment_update_column>;
    where?: registrationAdditionalPayment_bool_exp | null | undefined;
};
export type registrationCategory_arr_rel_insert_input = {
    data: Array<registrationCategory_insert_input>;
    on_conflict?: registrationCategory_on_conflict | null | undefined;
};
export type registrationCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    id?: number | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
};
export type registrationCategory_on_conflict = {
    constraint: registrationCategory_constraint;
    update_columns: Array<registrationCategory_update_column>;
    where?: registrationCategory_bool_exp | null | undefined;
};
export type registrationFor_obj_rel_insert_input = {
    data: registrationFor_insert_input;
    on_conflict?: registrationFor_on_conflict | null | undefined;
};
export type registrationFor_insert_input = {
    for?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
};
export type registrationFor_on_conflict = {
    constraint: registrationFor_constraint;
    update_columns: Array<registrationFor_update_column>;
    where?: registrationFor_bool_exp | null | undefined;
};
export type registrationTicket_arr_rel_insert_input = {
    data: Array<registrationTicket_insert_input>;
    on_conflict?: registrationTicket_on_conflict | null | undefined;
};
export type registrationTicket_insert_input = {
    eventTicket?: eventTicket_obj_rel_insert_input | null | undefined;
    eventTicketCuid?: string | null | undefined;
    id?: number | null | undefined;
    number?: number | null | undefined;
    registration?: registration_obj_rel_insert_input | null | undefined;
    registrationCuid?: string | null | undefined;
};
export type eventTicket_obj_rel_insert_input = {
    data: eventTicket_insert_input;
    on_conflict?: eventTicket_on_conflict | null | undefined;
};
export type eventTicket_insert_input = {
    allowWaitlist?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    price?: number | null | undefined;
    registrationTickets?: registrationTicket_arr_rel_insert_input | null | undefined;
    ticket?: ticket_obj_rel_insert_input | null | undefined;
    ticketCuid?: string | null | undefined;
    ticketsAvailable?: number | null | undefined;
    ticketsReserved?: number | null | undefined;
    updated_at?: string | null | undefined;
    visible?: boolean | null | undefined;
};
export type ticket_obj_rel_insert_input = {
    data: ticket_insert_input;
    on_conflict?: ticket_on_conflict | null | undefined;
};
export type ticket_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    description?: string | null | undefined;
    eventTickets?: eventTicket_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    price?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventTicket_arr_rel_insert_input = {
    data: Array<eventTicket_insert_input>;
    on_conflict?: eventTicket_on_conflict | null | undefined;
};
export type eventTicket_on_conflict = {
    constraint: eventTicket_constraint;
    update_columns: Array<eventTicket_update_column>;
    where?: eventTicket_bool_exp | null | undefined;
};
export type ticket_on_conflict = {
    constraint: ticket_constraint;
    update_columns: Array<ticket_update_column>;
    where?: ticket_bool_exp | null | undefined;
};
export type registrationTicket_on_conflict = {
    constraint: registrationTicket_constraint;
    update_columns: Array<registrationTicket_update_column>;
    where?: registrationTicket_bool_exp | null | undefined;
};
export type ride_obj_rel_insert_input = {
    data: ride_insert_input;
    on_conflict?: ride_on_conflict | null | undefined;
};
export type ride_insert_input = {
    canceledAt?: string | null | undefined;
    cancellationReason?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    dayOfContact?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    priceOverride?: number | null | undefined;
    registrationStatus?: string | null | undefined;
    registrations?: registration_arr_rel_insert_input | null | undefined;
    rideDays?: rideDay_arr_rel_insert_input | null | undefined;
    spotsAvailable?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type rideDay_arr_rel_insert_input = {
    data: Array<rideDay_insert_input>;
    on_conflict?: rideDay_on_conflict | null | undefined;
};
export type rideDay_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    dayNumber?: number | null | undefined;
    id?: number | null | undefined;
    ride?: ride_obj_rel_insert_input | null | undefined;
    rideCuid?: string | null | undefined;
    startTime?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type rideDay_on_conflict = {
    constraint: rideDay_constraint;
    update_columns: Array<rideDay_update_column>;
    where?: rideDay_bool_exp | null | undefined;
};
export type ride_on_conflict = {
    constraint: ride_constraint;
    update_columns: Array<ride_update_column>;
    where?: ride_bool_exp | null | undefined;
};
export type registrationSeries_on_conflict = {
    constraint: registrationSeries_constraint;
    update_columns: Array<registrationSeries_update_column>;
    where?: registrationSeries_bool_exp | null | undefined;
};
export type seriesPointsStructureCustom_arr_rel_insert_input = {
    data: Array<seriesPointsStructureCustom_insert_input>;
    on_conflict?: seriesPointsStructureCustom_on_conflict | null | undefined;
};
export type seriesPointsStructureCustom_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    isPreset?: boolean | null | undefined;
    isQualifying?: boolean | null | undefined;
    points?: Record<string, unknown> | unknown[] | null | undefined;
    pointsName?: string | null | undefined;
    series?: series_obj_rel_insert_input | null | undefined;
    seriesCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type seriesPointsStructureCustom_on_conflict = {
    constraint: seriesPointsStructureCustom_constraint;
    update_columns: Array<seriesPointsStructureCustom_update_column>;
    where?: seriesPointsStructureCustom_bool_exp | null | undefined;
};
export type seriesPointsStructurePreset_arr_rel_insert_input = {
    data: Array<seriesPointsStructurePreset_insert_input>;
    on_conflict?: seriesPointsStructurePreset_on_conflict | null | undefined;
};
export type seriesPointsStructurePreset_insert_input = {
    created_at?: string | null | undefined;
    id?: number | null | undefined;
    isPreset?: boolean | null | undefined;
    presetCuid?: string | null | undefined;
    series?: series_obj_rel_insert_input | null | undefined;
    seriesCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type seriesPointsStructurePreset_on_conflict = {
    constraint: seriesPointsStructurePreset_constraint;
    update_columns: Array<seriesPointsStructurePreset_update_column>;
    where?: seriesPointsStructurePreset_bool_exp | null | undefined;
};
export type series_on_conflict = {
    constraint: series_constraint;
    update_columns: Array<series_update_column>;
    where?: series_bool_exp | null | undefined;
};
export type seriesImage_on_conflict = {
    constraint: seriesImage_constraint;
    update_columns: Array<seriesImage_update_column>;
    where?: seriesImage_bool_exp | null | undefined;
};
export type sponsorImage_arr_rel_insert_input = {
    data: Array<sponsorImage_insert_input>;
    on_conflict?: sponsorImage_on_conflict | null | undefined;
};
export type sponsorImage_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    isDefault?: boolean | null | undefined;
    sponsor?: sponsor_obj_rel_insert_input | null | undefined;
    sponsorCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type sponsor_obj_rel_insert_input = {
    data: sponsor_insert_input;
    on_conflict?: sponsor_on_conflict | null | undefined;
};
export type sponsor_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    eventSponsors?: eventSponsor_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    sponsorImages?: sponsorImage_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    website?: string | null | undefined;
};
export type eventSponsor_arr_rel_insert_input = {
    data: Array<eventSponsor_insert_input>;
    on_conflict?: eventSponsor_on_conflict | null | undefined;
};
export type eventSponsor_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    position?: number | null | undefined;
    sponsor?: sponsor_obj_rel_insert_input | null | undefined;
    sponsorCuid?: string | null | undefined;
    tierName?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSponsor_on_conflict = {
    constraint: eventSponsor_constraint;
    update_columns: Array<eventSponsor_update_column>;
    where?: eventSponsor_bool_exp | null | undefined;
};
export type sponsor_on_conflict = {
    constraint: sponsor_constraint;
    update_columns: Array<sponsor_update_column>;
    where?: sponsor_bool_exp | null | undefined;
};
export type sponsorImage_on_conflict = {
    constraint: sponsorImage_constraint;
    update_columns: Array<sponsorImage_update_column>;
    where?: sponsorImage_bool_exp | null | undefined;
};
export type userImage_arr_rel_insert_input = {
    data: Array<userImage_insert_input>;
    on_conflict?: userImage_on_conflict | null | undefined;
};
export type userImage_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    image?: image_obj_rel_insert_input | null | undefined;
    imagePublicId?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userImage_on_conflict = {
    constraint: userImage_constraint;
    update_columns: Array<userImage_update_column>;
    where?: userImage_bool_exp | null | undefined;
};
export type user_arr_rel_insert_input = {
    data: Array<user_insert_input>;
    on_conflict?: user_on_conflict | null | undefined;
};
export type user_on_conflict = {
    constraint: user_constraint;
    update_columns: Array<user_update_column>;
    where?: user_bool_exp | null | undefined;
};
export type image_on_conflict = {
    constraint: image_constraint;
    update_columns: Array<image_update_column>;
    where?: image_bool_exp | null | undefined;
};
export type imageUser_arr_rel_insert_input = {
    data: Array<imageUser_insert_input>;
    on_conflict?: imageUser_on_conflict | null | undefined;
};
export type imageUser_insert_input = {
    bytes?: number | null | undefined;
    coachImages?: coachImage_arr_rel_insert_input | null | undefined;
    colors?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    format?: string | null | undefined;
    height?: number | null | undefined;
    id?: number | null | undefined;
    originalFilename?: string | null | undefined;
    publicId?: string | null | undefined;
    relativeUrl?: string | null | undefined;
    resourceType?: string | null | undefined;
    updated_at?: string | null | undefined;
    url?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
    version?: number | null | undefined;
    width?: number | null | undefined;
};
export type coachImage_arr_rel_insert_input = {
    data: Array<coachImage_insert_input>;
    on_conflict?: coachImage_on_conflict | null | undefined;
};
export type coachImage_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    imageUser?: imageUser_obj_rel_insert_input | null | undefined;
    imageUserPublicId?: string | null | undefined;
    position?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type imageUser_obj_rel_insert_input = {
    data: imageUser_insert_input;
    on_conflict?: imageUser_on_conflict | null | undefined;
};
export type imageUser_on_conflict = {
    constraint: imageUser_constraint;
    update_columns: Array<imageUser_update_column>;
    where?: imageUser_bool_exp | null | undefined;
};
export type coachImage_on_conflict = {
    constraint: coachImage_constraint;
    update_columns: Array<coachImage_update_column>;
    where?: coachImage_bool_exp | null | undefined;
};
export type organizerChannel_arr_rel_insert_input = {
    data: Array<organizerChannel_insert_input>;
    on_conflict?: organizerChannel_on_conflict | null | undefined;
};
export type organizerChannel_insert_input = {
    channelId?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type organizerChannel_on_conflict = {
    constraint: organizerChannel_constraint;
    update_columns: Array<organizerChannel_update_column>;
    where?: organizerChannel_bool_exp | null | undefined;
};
export type organizerInvite_arr_rel_insert_input = {
    data: Array<organizerInvite_insert_input>;
    on_conflict?: organizerInvite_on_conflict | null | undefined;
};
export type organizerInvite_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    inviteStatus?: string | null | undefined;
    inviteeUserId?: string | null | undefined;
    inviterUserId?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    role?: string | null | undefined;
    updated_at?: string | null | undefined;
    userByInvitee?: user_obj_rel_insert_input | null | undefined;
    userByInviter?: user_obj_rel_insert_input | null | undefined;
};
export type organizerInvite_on_conflict = {
    constraint: organizerInvite_constraint;
    update_columns: Array<organizerInvite_update_column>;
    where?: organizerInvite_bool_exp | null | undefined;
};
export type reportClinicNoShow_arr_rel_insert_input = {
    data: Array<reportClinicNoShow_insert_input>;
    on_conflict?: reportClinicNoShow_on_conflict | null | undefined;
};
export type reportClinicNoShow_insert_input = {
    clinicDay?: clinicDay_obj_rel_insert_input | null | undefined;
    clinicDayCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    reportedUser?: user_obj_rel_insert_input | null | undefined;
    reportedUserId?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userDistance?: number | null | undefined;
    userId?: string | null | undefined;
    userIsCoach?: boolean | null | undefined;
    userLocation?: Record<string, unknown> | unknown[] | null | undefined;
};
export type clinicDay_obj_rel_insert_input = {
    data: clinicDay_insert_input;
    on_conflict?: clinicDay_on_conflict | null | undefined;
};
export type clinicDay_on_conflict = {
    constraint: clinicDay_constraint;
    update_columns: Array<clinicDay_update_column>;
    where?: clinicDay_bool_exp | null | undefined;
};
export type reportClinicNoShow_on_conflict = {
    constraint: reportClinicNoShow_constraint;
    update_columns: Array<reportClinicNoShow_update_column>;
    where?: reportClinicNoShow_bool_exp | null | undefined;
};
export type review_arr_rel_insert_input = {
    data: Array<review_insert_input>;
    on_conflict?: review_on_conflict | null | undefined;
};
export type review_insert_input = {
    clinic?: eventMetadata_obj_rel_insert_input | null | undefined;
    clinicInstance?: clinic_obj_rel_insert_input | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    id?: number | null | undefined;
    review?: Record<string, unknown> | unknown[] | null | undefined;
    reviewSubjectCuid?: string | null | undefined;
    reviewSubjectInstanceCuid?: string | null | undefined;
    reviewSubjectType?: reviewSubjectType_enum | null | undefined;
    reviewSubjectTypeByReviewSubjectType?: reviewSubjectType_obj_rel_insert_input | null | undefined;
    reviewer?: user_obj_rel_insert_input | null | undefined;
    reviewerId?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventMetadata_obj_rel_insert_input = {
    data: eventMetadata_insert_input;
    on_conflict?: eventMetadata_on_conflict | null | undefined;
};
export type eventMetadata_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    events?: event_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    isCategoriedEvent?: boolean | null | undefined;
    name?: string | null | undefined;
    numberOfDays?: number | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    slug?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventMetadata_on_conflict = {
    constraint: eventMetadata_constraint;
    update_columns: Array<eventMetadata_update_column>;
    where?: eventMetadata_bool_exp | null | undefined;
};
export type reviewSubjectType_obj_rel_insert_input = {
    data: reviewSubjectType_insert_input;
    on_conflict?: reviewSubjectType_on_conflict | null | undefined;
};
export type reviewSubjectType_insert_input = {
    reviewTotals?: reviewTotals_arr_rel_insert_input | null | undefined;
    reviews?: review_arr_rel_insert_input | null | undefined;
    type?: string | null | undefined;
};
export type reviewTotals_arr_rel_insert_input = {
    data: Array<reviewTotals_insert_input>;
    on_conflict?: reviewTotals_on_conflict | null | undefined;
};
export type reviewTotals_insert_input = {
    count?: number | null | undefined;
    created_at?: string | null | undefined;
    id?: number | null | undefined;
    recAverage?: number | null | undefined;
    recPercentage?: number | null | undefined;
    reviewSubjectCuid?: string | null | undefined;
    reviewSubjectType?: reviewSubjectType_enum | null | undefined;
    reviewSubjectTypeByReviewSubjectType?: reviewSubjectType_obj_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
};
export type reviewTotals_on_conflict = {
    constraint: reviewTotals_constraint;
    update_columns: Array<reviewTotals_update_column>;
    where?: reviewTotals_bool_exp | null | undefined;
};
export type reviewSubjectType_on_conflict = {
    constraint: reviewSubjectType_constraint;
    update_columns: Array<reviewSubjectType_update_column>;
    where?: reviewSubjectType_bool_exp | null | undefined;
};
export type review_on_conflict = {
    constraint: review_constraint;
    update_columns: Array<review_update_column>;
    where?: review_bool_exp | null | undefined;
};
export type stripeConnectAccount_obj_rel_insert_input = {
    data: stripeConnectAccount_insert_input;
    on_conflict?: stripeConnectAccount_on_conflict | null | undefined;
};
export type stripeConnectAccount_insert_input = {
    accountId?: string | null | undefined;
    chargesEnabled?: boolean | null | undefined;
    created_at?: string | null | undefined;
    id?: number | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizers?: organizer_arr_rel_insert_input | null | undefined;
    ownerOrganizerCuid?: string | null | undefined;
    ownerUserId?: string | null | undefined;
    payoutsEnabled?: boolean | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    users?: user_arr_rel_insert_input | null | undefined;
};
export type stripeConnectAccount_on_conflict = {
    constraint: stripeConnectAccount_constraint;
    update_columns: Array<stripeConnectAccount_update_column>;
    where?: stripeConnectAccount_bool_exp | null | undefined;
};
export type stripeConnectAccount_arr_rel_insert_input = {
    data: Array<stripeConnectAccount_insert_input>;
    on_conflict?: stripeConnectAccount_on_conflict | null | undefined;
};
export type userAddress_arr_rel_insert_input = {
    data: Array<userAddress_insert_input>;
    on_conflict?: userAddress_on_conflict | null | undefined;
};
export type userAddress_insert_input = {
    address?: string | null | undefined;
    addressLine1?: string | null | undefined;
    addressLine2?: string | null | undefined;
    city?: string | null | undefined;
    country?: string | null | undefined;
    countryCode?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    googlePlaceId?: string | null | undefined;
    id?: number | null | undefined;
    isDefault?: boolean | null | undefined;
    label?: string | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    phone?: string | null | undefined;
    state?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
    zipCode?: string | null | undefined;
};
export type userAddress_on_conflict = {
    constraint: userAddress_constraint;
    update_columns: Array<userAddress_update_column>;
    where?: userAddress_bool_exp | null | undefined;
};
export type userBike_arr_rel_insert_input = {
    data: Array<userBike_insert_input>;
    on_conflict?: userBike_on_conflict | null | undefined;
};
export type userBike_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    latestRevision?: userBikeRevision_obj_rel_insert_input | null | undefined;
    latestRevisionNum?: number | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userBikeRevisions?: userBikeRevision_arr_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userBikeRevision_obj_rel_insert_input = {
    data: userBikeRevision_insert_input;
    on_conflict?: userBikeRevision_on_conflict | null | undefined;
};
export type userBikeRevision_insert_input = {
    bike?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    revision?: number | null | undefined;
    updated_at?: string | null | undefined;
    userBike?: userBike_obj_rel_insert_input | null | undefined;
    userBikeCuid?: string | null | undefined;
    userBikes?: userBike_arr_rel_insert_input | null | undefined;
};
export type userBike_obj_rel_insert_input = {
    data: userBike_insert_input;
    on_conflict?: userBike_on_conflict | null | undefined;
};
export type userBike_on_conflict = {
    constraint: userBike_constraint;
    update_columns: Array<userBike_update_column>;
    where?: userBike_bool_exp | null | undefined;
};
export type userBikeRevision_on_conflict = {
    constraint: userBikeRevision_constraint;
    update_columns: Array<userBikeRevision_update_column>;
    where?: userBikeRevision_bool_exp | null | undefined;
};
export type userBikeRevision_arr_rel_insert_input = {
    data: Array<userBikeRevision_insert_input>;
    on_conflict?: userBikeRevision_on_conflict | null | undefined;
};
export type userEmergencyContact_arr_rel_insert_input = {
    data: Array<userEmergencyContact_insert_input>;
    on_conflict?: userEmergencyContact_on_conflict | null | undefined;
};
export type userEmergencyContact_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    isDefault?: boolean | null | undefined;
    name?: string | null | undefined;
    phone?: string | null | undefined;
    relationship?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userEmergencyContact_on_conflict = {
    constraint: userEmergencyContact_constraint;
    update_columns: Array<userEmergencyContact_update_column>;
    where?: userEmergencyContact_bool_exp | null | undefined;
};
export type userOnlineCourse_arr_rel_insert_input = {
    data: Array<userOnlineCourse_insert_input>;
    on_conflict?: userOnlineCourse_on_conflict | null | undefined;
};
export type userOnlineCourse_insert_input = {
    completedLessons?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    lastViewedLessonCuid?: string | null | undefined;
    onlineCourse?: onlineCourse_obj_rel_insert_input | null | undefined;
    onlineCourseCuid?: string | null | undefined;
    purchasedRevision?: onlineCourseRevision_obj_rel_insert_input | null | undefined;
    referenceNo?: string | null | undefined;
    revision?: number | null | undefined;
    status?: userOnlineCourseStatus_enum | null | undefined;
    stripeCheckoutId?: string | null | undefined;
    stripePaymentIntentId?: string | null | undefined;
    successToken?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
    userOnlineCourseStatus?: userOnlineCourseStatus_obj_rel_insert_input | null | undefined;
};
export type onlineCourse_obj_rel_insert_input = {
    data: onlineCourse_insert_input;
    on_conflict?: onlineCourse_on_conflict | null | undefined;
};
export type onlineCourse_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    deleted_at?: string | null | undefined;
    id?: number | null | undefined;
    imageOnlineCourses?: imageOnlineCourse_arr_rel_insert_input | null | undefined;
    latestRevision?: onlineCourseRevision_obj_rel_insert_input | null | undefined;
    latestRevisionNum?: number | null | undefined;
    onlineCourseOwner?: onlineCourseOwner_obj_rel_insert_input | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    ownerCuid?: string | null | undefined;
    ownerType?: onlineCourseOwner_enum | null | undefined;
    publishedAt?: string | null | undefined;
    revisions?: onlineCourseRevision_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    userOnlineCourses?: userOnlineCourse_arr_rel_insert_input | null | undefined;
};
export type imageOnlineCourse_arr_rel_insert_input = {
    data: Array<imageOnlineCourse_insert_input>;
    on_conflict?: imageOnlineCourse_on_conflict | null | undefined;
};
export type imageOnlineCourse_insert_input = {
    bytes?: number | null | undefined;
    colors?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    format?: string | null | undefined;
    height?: number | null | undefined;
    id?: number | null | undefined;
    onlineCourse?: onlineCourse_obj_rel_insert_input | null | undefined;
    onlineCourseCuid?: string | null | undefined;
    originalFilename?: string | null | undefined;
    publicId?: string | null | undefined;
    relativeUrl?: string | null | undefined;
    resourceType?: string | null | undefined;
    updated_at?: string | null | undefined;
    url?: string | null | undefined;
    version?: number | null | undefined;
    width?: number | null | undefined;
};
export type imageOnlineCourse_on_conflict = {
    constraint: imageOnlineCourse_constraint;
    update_columns: Array<imageOnlineCourse_update_column>;
    where?: imageOnlineCourse_bool_exp | null | undefined;
};
export type onlineCourseRevision_obj_rel_insert_input = {
    data: onlineCourseRevision_insert_input;
    on_conflict?: onlineCourseRevision_on_conflict | null | undefined;
};
export type onlineCourseRevision_insert_input = {
    contents?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    currencyCode?: string | null | undefined;
    deleted_at?: string | null | undefined;
    id?: number | null | undefined;
    onlineCourse?: onlineCourse_obj_rel_insert_input | null | undefined;
    onlineCourseCuid?: string | null | undefined;
    onlineCourseDefinition?: Record<string, unknown> | unknown[] | null | undefined;
    onlineCourses?: onlineCourse_arr_rel_insert_input | null | undefined;
    overviewContent?: Record<string, unknown> | unknown[] | null | undefined;
    price?: number | null | undefined;
    revision?: number | null | undefined;
    tagline?: string | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
    userOnlineCourses?: userOnlineCourse_arr_rel_insert_input | null | undefined;
};
export type onlineCourse_arr_rel_insert_input = {
    data: Array<onlineCourse_insert_input>;
    on_conflict?: onlineCourse_on_conflict | null | undefined;
};
export type onlineCourse_on_conflict = {
    constraint: onlineCourse_constraint;
    update_columns: Array<onlineCourse_update_column>;
    where?: onlineCourse_bool_exp | null | undefined;
};
export type onlineCourseRevision_on_conflict = {
    constraint: onlineCourseRevision_constraint;
    update_columns: Array<onlineCourseRevision_update_column>;
    where?: onlineCourseRevision_bool_exp | null | undefined;
};
export type onlineCourseOwner_obj_rel_insert_input = {
    data: onlineCourseOwner_insert_input;
    on_conflict?: onlineCourseOwner_on_conflict | null | undefined;
};
export type onlineCourseOwner_insert_input = {
    description?: string | null | undefined;
    onlineCourses?: onlineCourse_arr_rel_insert_input | null | undefined;
    owner?: string | null | undefined;
};
export type onlineCourseOwner_on_conflict = {
    constraint: onlineCourseOwner_constraint;
    update_columns: Array<onlineCourseOwner_update_column>;
    where?: onlineCourseOwner_bool_exp | null | undefined;
};
export type onlineCourseRevision_arr_rel_insert_input = {
    data: Array<onlineCourseRevision_insert_input>;
    on_conflict?: onlineCourseRevision_on_conflict | null | undefined;
};
export type userOnlineCourseStatus_obj_rel_insert_input = {
    data: userOnlineCourseStatus_insert_input;
    on_conflict?: userOnlineCourseStatus_on_conflict | null | undefined;
};
export type userOnlineCourseStatus_insert_input = {
    status?: string | null | undefined;
    userOnlineCourses?: userOnlineCourse_arr_rel_insert_input | null | undefined;
};
export type userOnlineCourseStatus_on_conflict = {
    constraint: userOnlineCourseStatus_constraint;
    update_columns: Array<userOnlineCourseStatus_update_column>;
    where?: userOnlineCourseStatus_bool_exp | null | undefined;
};
export type userOnlineCourse_on_conflict = {
    constraint: userOnlineCourse_constraint;
    update_columns: Array<userOnlineCourse_update_column>;
    where?: userOnlineCourse_bool_exp | null | undefined;
};
export type userOrganizerRole_arr_rel_insert_input = {
    data: Array<userOrganizerRole_insert_input>;
    on_conflict?: userOrganizerRole_on_conflict | null | undefined;
};
export type userOrganizerRole_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    role?: roleOrganizer_enum | null | undefined;
    roleOrganizer?: roleOrganizer_obj_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type roleOrganizer_obj_rel_insert_input = {
    data: roleOrganizer_insert_input;
    on_conflict?: roleOrganizer_on_conflict | null | undefined;
};
export type roleOrganizer_insert_input = {
    role?: string | null | undefined;
    userOrganizerRoles?: userOrganizerRole_arr_rel_insert_input | null | undefined;
};
export type roleOrganizer_on_conflict = {
    constraint: roleOrganizer_constraint;
    update_columns: Array<roleOrganizer_update_column>;
    where?: roleOrganizer_bool_exp | null | undefined;
};
export type userOrganizerRole_on_conflict = {
    constraint: userOrganizerRole_constraint;
    update_columns: Array<userOrganizerRole_update_column>;
    where?: userOrganizerRole_bool_exp | null | undefined;
};
export type userPersonalization_arr_rel_insert_input = {
    data: Array<userPersonalization_insert_input>;
    on_conflict?: userPersonalization_on_conflict | null | undefined;
};
export type userPersonalization_insert_input = {
    created_at?: string | null | undefined;
    id?: number | null | undefined;
    preferences?: Record<string, unknown> | unknown[] | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userPersonalization_on_conflict = {
    constraint: userPersonalization_constraint;
    update_columns: Array<userPersonalization_update_column>;
    where?: userPersonalization_bool_exp | null | undefined;
};
export type userRidingInterest_arr_rel_insert_input = {
    data: Array<userRidingInterest_insert_input>;
    on_conflict?: userRidingInterest_on_conflict | null | undefined;
};
export type userRidingInterest_insert_input = {
    abilityLevel?: Record<string, unknown> | unknown[] | null | undefined;
    abilityLevelGoal?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    disciplineName?: string | null | undefined;
    focus?: Record<string, unknown> | unknown[] | null | undefined;
    hasAbilityGoal?: boolean | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userRidingInterest_on_conflict = {
    constraint: userRidingInterest_constraint;
    update_columns: Array<userRidingInterest_update_column>;
    where?: userRidingInterest_bool_exp | null | undefined;
};
export type userSanctionLicense_arr_rel_insert_input = {
    data: Array<userSanctionLicense_insert_input>;
    on_conflict?: userSanctionLicense_on_conflict | null | undefined;
};
export type userSanctionLicense_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    licenseNumber?: string | null | undefined;
    sanction?: sanction_obj_rel_insert_input | null | undefined;
    sanctionCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type sanction_obj_rel_insert_input = {
    data: sanction_insert_input;
    on_conflict?: sanction_on_conflict | null | undefined;
};
export type sanction_insert_input = {
    classification?: Record<string, unknown> | unknown[] | null | undefined;
    country?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventSanctions?: eventSanction_arr_rel_insert_input | null | undefined;
    hasPermit?: boolean | null | undefined;
    id?: number | null | undefined;
    isOneDayAvailable?: boolean | null | undefined;
    name?: string | null | undefined;
    oneDayFee?: number | null | undefined;
    sanctionWaivers?: sanctionWaivers_arr_rel_insert_input | null | undefined;
    updated_at?: string | null | undefined;
    userSanctionLicenses?: userSanctionLicense_arr_rel_insert_input | null | undefined;
    waiverLink?: string | null | undefined;
    website?: string | null | undefined;
};
export type eventSanction_arr_rel_insert_input = {
    data: Array<eventSanction_insert_input>;
    on_conflict?: eventSanction_on_conflict | null | undefined;
};
export type eventSanction_on_conflict = {
    constraint: eventSanction_constraint;
    update_columns: Array<eventSanction_update_column>;
    where?: eventSanction_bool_exp | null | undefined;
};
export type sanctionWaivers_arr_rel_insert_input = {
    data: Array<sanctionWaivers_insert_input>;
    on_conflict?: sanctionWaivers_on_conflict | null | undefined;
};
export type sanctionWaivers_insert_input = {
    sanction?: sanction_obj_rel_insert_input | null | undefined;
    sanctionCuid?: string | null | undefined;
    waiver?: waiver_obj_rel_insert_input | null | undefined;
    waiverCuid?: string | null | undefined;
};
export type sanctionWaivers_on_conflict = {
    constraint: sanctionWaivers_constraint;
    update_columns: Array<sanctionWaivers_update_column>;
    where?: sanctionWaivers_bool_exp | null | undefined;
};
export type sanction_on_conflict = {
    constraint: sanction_constraint;
    update_columns: Array<sanction_update_column>;
    where?: sanction_bool_exp | null | undefined;
};
export type userSanctionLicense_on_conflict = {
    constraint: userSanctionLicense_constraint;
    update_columns: Array<userSanctionLicense_update_column>;
    where?: userSanctionLicense_bool_exp | null | undefined;
};
export type userSavedCoach_arr_rel_insert_input = {
    data: Array<userSavedCoach_insert_input>;
    on_conflict?: userSavedCoach_on_conflict | null | undefined;
};
export type userSavedCoach_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userSavedCoach_on_conflict = {
    constraint: userSavedCoach_constraint;
    update_columns: Array<userSavedCoach_update_column>;
    where?: userSavedCoach_bool_exp | null | undefined;
};
export type userSavedEvent_arr_rel_insert_input = {
    data: Array<userSavedEvent_insert_input>;
    on_conflict?: userSavedEvent_on_conflict | null | undefined;
};
export type userSavedEvent_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    user?: user_obj_rel_insert_input | null | undefined;
    userId?: string | null | undefined;
};
export type userSavedEvent_on_conflict = {
    constraint: userSavedEvent_constraint;
    update_columns: Array<userSavedEvent_update_column>;
    where?: userSavedEvent_bool_exp | null | undefined;
};
export type waiverRevision_arr_rel_insert_input = {
    data: Array<waiverRevision_insert_input>;
    on_conflict?: waiverRevision_on_conflict | null | undefined;
};
export type waiverRevision_on_conflict = {
    constraint: waiverRevision_constraint;
    update_columns: Array<waiverRevision_update_column>;
    where?: waiverRevision_bool_exp | null | undefined;
};
export type waiver_arr_rel_insert_input = {
    data: Array<waiver_insert_input>;
    on_conflict?: waiver_on_conflict | null | undefined;
};
export type waiver_on_conflict = {
    constraint: waiver_constraint;
    update_columns: Array<waiver_update_column>;
    where?: waiver_bool_exp | null | undefined;
};
export type waiverOwner_obj_rel_insert_input = {
    data: waiverOwner_insert_input;
    on_conflict?: waiverOwner_on_conflict | null | undefined;
};
export type waiverOwner_insert_input = {
    description?: string | null | undefined;
    owner?: string | null | undefined;
    waivers?: waiver_arr_rel_insert_input | null | undefined;
};
export type waiverOwner_on_conflict = {
    constraint: waiverOwner_constraint;
    update_columns: Array<waiverOwner_update_column>;
    where?: waiverOwner_bool_exp | null | undefined;
};
export type eventWaiver_on_conflict = {
    constraint: eventWaiver_constraint;
    update_columns: Array<eventWaiver_update_column>;
    where?: eventWaiver_bool_exp | null | undefined;
};
export type eventSanctionCategory_on_conflict = {
    constraint: eventSanctionCategory_constraint;
    update_columns: Array<eventSanctionCategory_update_column>;
    where?: eventSanctionCategory_bool_exp | null | undefined;
};
export type eventScheduleCategory_arr_rel_insert_input = {
    data: Array<eventScheduleCategory_insert_input>;
    on_conflict?: eventScheduleCategory_on_conflict | null | undefined;
};
export type eventScheduleCategory_insert_input = {
    category?: category_obj_rel_insert_input | null | undefined;
    categoryCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventSchedule?: eventSchedule_obj_rel_insert_input | null | undefined;
    eventScheduleCuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSchedule_obj_rel_insert_input = {
    data: eventSchedule_insert_input;
    on_conflict?: eventSchedule_on_conflict | null | undefined;
};
export type eventSchedule_insert_input = {
    addedInternally?: boolean | null | undefined;
    appliesToAllCategories?: boolean | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    endTime?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventScheduleCategories?: eventScheduleCategory_arr_rel_insert_input | null | undefined;
    eventScheduleDate?: eventScheduleDate_obj_rel_insert_input | null | undefined;
    eventScheduleDateCuid?: string | null | undefined;
    eventScheduleTypeCustom?: eventScheduleTypeCustom_obj_rel_insert_input | null | undefined;
    eventScheduleTypeCustomCuid?: string | null | undefined;
    eventScheduleTypePreset?: eventScheduleTypePreset_obj_rel_insert_input | null | undefined;
    eventScheduleTypePresetCuid?: string | null | undefined;
    id?: number | null | undefined;
    startTime?: string | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventScheduleDate_obj_rel_insert_input = {
    data: eventScheduleDate_insert_input;
    on_conflict?: eventScheduleDate_on_conflict | null | undefined;
};
export type eventScheduleDate_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    date?: string | null | undefined;
    dayNumber?: number | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSchedules?: eventSchedule_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventSchedule_arr_rel_insert_input = {
    data: Array<eventSchedule_insert_input>;
    on_conflict?: eventSchedule_on_conflict | null | undefined;
};
export type eventSchedule_on_conflict = {
    constraint: eventSchedule_constraint;
    update_columns: Array<eventSchedule_update_column>;
    where?: eventSchedule_bool_exp | null | undefined;
};
export type eventScheduleDate_on_conflict = {
    constraint: eventScheduleDate_constraint;
    update_columns: Array<eventScheduleDate_update_column>;
    where?: eventScheduleDate_bool_exp | null | undefined;
};
export type eventScheduleTypeCustom_obj_rel_insert_input = {
    data: eventScheduleTypeCustom_insert_input;
    on_conflict?: eventScheduleTypeCustom_on_conflict | null | undefined;
};
export type eventScheduleTypeCustom_insert_input = {
    color?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSchedules?: eventSchedule_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    isPreset?: boolean | null | undefined;
    type?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventScheduleTypeCustom_on_conflict = {
    constraint: eventScheduleTypeCustom_constraint;
    update_columns: Array<eventScheduleTypeCustom_update_column>;
    where?: eventScheduleTypeCustom_bool_exp | null | undefined;
};
export type eventScheduleTypePreset_obj_rel_insert_input = {
    data: eventScheduleTypePreset_insert_input;
    on_conflict?: eventScheduleTypePreset_on_conflict | null | undefined;
};
export type eventScheduleTypePreset_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventSchedules?: eventSchedule_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    isPreset?: boolean | null | undefined;
    scheduleTypePreset?: scheduleTypePreset_obj_rel_insert_input | null | undefined;
    scheduleTypePresetCuid?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type scheduleTypePreset_obj_rel_insert_input = {
    data: scheduleTypePreset_insert_input;
    on_conflict?: scheduleTypePreset_on_conflict | null | undefined;
};
export type scheduleTypePreset_insert_input = {
    activity?: string | null | undefined;
    color?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventScheduleTypePresets?: eventScheduleTypePreset_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    isPreset?: boolean | null | undefined;
    type?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventScheduleTypePreset_arr_rel_insert_input = {
    data: Array<eventScheduleTypePreset_insert_input>;
    on_conflict?: eventScheduleTypePreset_on_conflict | null | undefined;
};
export type eventScheduleTypePreset_on_conflict = {
    constraint: eventScheduleTypePreset_constraint;
    update_columns: Array<eventScheduleTypePreset_update_column>;
    where?: eventScheduleTypePreset_bool_exp | null | undefined;
};
export type scheduleTypePreset_on_conflict = {
    constraint: scheduleTypePreset_constraint;
    update_columns: Array<scheduleTypePreset_update_column>;
    where?: scheduleTypePreset_bool_exp | null | undefined;
};
export type eventScheduleCategory_on_conflict = {
    constraint: eventScheduleCategory_constraint;
    update_columns: Array<eventScheduleCategory_update_column>;
    where?: eventScheduleCategory_bool_exp | null | undefined;
};
export type clinic_arr_rel_insert_input = {
    data: Array<clinic_insert_input>;
    on_conflict?: clinic_on_conflict | null | undefined;
};
export type clinic_on_conflict = {
    constraint: clinic_constraint;
    update_columns: Array<clinic_update_column>;
    where?: clinic_bool_exp | null | undefined;
};
export type eventAdditionalInfo_arr_rel_insert_input = {
    data: Array<eventAdditionalInfo_insert_input>;
    on_conflict?: eventAdditionalInfo_on_conflict | null | undefined;
};
export type eventAdditionalInfo_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    title?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventAdditionalInfo_on_conflict = {
    constraint: eventAdditionalInfo_constraint;
    update_columns: Array<eventAdditionalInfo_update_column>;
    where?: eventAdditionalInfo_bool_exp | null | undefined;
};
export type eventAmenity_arr_rel_insert_input = {
    data: Array<eventAmenity_insert_input>;
    on_conflict?: eventAmenity_on_conflict | null | undefined;
};
export type eventAmenity_insert_input = {
    amenity?: amenity_obj_rel_insert_input | null | undefined;
    amenityCuid?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
};
export type amenity_obj_rel_insert_input = {
    data: amenity_insert_input;
    on_conflict?: amenity_on_conflict | null | undefined;
};
export type amenity_insert_input = {
    cuid?: string | null | undefined;
    eventAmenities?: eventAmenity_arr_rel_insert_input | null | undefined;
    id?: number | null | undefined;
    imageUrl?: string | null | undefined;
    info?: string | null | undefined;
    name?: string | null | undefined;
};
export type amenity_on_conflict = {
    constraint: amenity_constraint;
    update_columns: Array<amenity_update_column>;
    where?: amenity_bool_exp | null | undefined;
};
export type eventAmenity_on_conflict = {
    constraint: eventAmenity_constraint;
    update_columns: Array<eventAmenity_update_column>;
    where?: eventAmenity_bool_exp | null | undefined;
};
export type eventBasicWaiver_arr_rel_insert_input = {
    data: Array<eventBasicWaiver_insert_input>;
    on_conflict?: eventBasicWaiver_on_conflict | null | undefined;
};
export type eventChecklistItem_arr_rel_insert_input = {
    data: Array<eventChecklistItem_insert_input>;
    on_conflict?: eventChecklistItem_on_conflict | null | undefined;
};
export type eventChecklistItem_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    info?: string | null | undefined;
    name?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventChecklistItem_on_conflict = {
    constraint: eventChecklistItem_constraint;
    update_columns: Array<eventChecklistItem_update_column>;
    where?: eventChecklistItem_bool_exp | null | undefined;
};
export type eventClinicDayDuration_arr_rel_insert_input = {
    data: Array<eventClinicDayDuration_insert_input>;
    on_conflict?: eventClinicDayDuration_on_conflict | null | undefined;
};
export type eventClinicDayDuration_on_conflict = {
    constraint: eventClinicDayDuration_constraint;
    update_columns: Array<eventClinicDayDuration_update_column>;
    where?: eventClinicDayDuration_bool_exp | null | undefined;
};
export type eventCustomQuestion_arr_rel_insert_input = {
    data: Array<eventCustomQuestion_insert_input>;
    on_conflict?: eventCustomQuestion_on_conflict | null | undefined;
};
export type eventDiscipline_arr_rel_insert_input = {
    data: Array<eventDiscipline_insert_input>;
    on_conflict?: eventDiscipline_on_conflict | null | undefined;
};
export type eventPricingDate_arr_rel_insert_input = {
    data: Array<eventPricingDate_insert_input>;
    on_conflict?: eventPricingDate_on_conflict | null | undefined;
};
export type eventPricingGroup_arr_rel_insert_input = {
    data: Array<eventPricingGroup_insert_input>;
    on_conflict?: eventPricingGroup_on_conflict | null | undefined;
};
export type eventPrivateLink_arr_rel_insert_input = {
    data: Array<eventPrivateLink_insert_input>;
    on_conflict?: eventPrivateLink_on_conflict | null | undefined;
};
export type eventPrivateLink_insert_input = {
    capacity?: number | null | undefined;
    capacityUsed?: number | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    endDate?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventByEventcuid?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    eventId?: number | null | undefined;
    link?: string | null | undefined;
    paused?: boolean | null | undefined;
    startDate?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventPrivateLink_on_conflict = {
    constraint: eventPrivateLink_constraint;
    update_columns: Array<eventPrivateLink_update_column>;
    where?: eventPrivateLink_bool_exp | null | undefined;
};
export type eventProgram_arr_rel_insert_input = {
    data: Array<eventProgram_insert_input>;
    on_conflict?: eventProgram_on_conflict | null | undefined;
};
export type eventProgram_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    ageRange?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    defaultPrice?: number | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    focus?: Record<string, unknown> | unknown[] | null | undefined;
    genders?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    overview?: string | null | undefined;
    skills?: Record<string, unknown> | unknown[] | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventProgram_on_conflict = {
    constraint: eventProgram_constraint;
    update_columns: Array<eventProgram_update_column>;
    where?: eventProgram_bool_exp | null | undefined;
};
export type eventPromoCode_arr_rel_insert_input = {
    data: Array<eventPromoCode_insert_input>;
    on_conflict?: eventPromoCode_on_conflict | null | undefined;
};
export type eventPromoCode_insert_input = {
    amountOff?: number | null | undefined;
    code?: string | null | undefined;
    codesAvailable?: number | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    description?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    isInactive?: boolean | null | undefined;
    percentOff?: number | null | undefined;
    updated_at?: string | null | undefined;
    validFrom?: string | null | undefined;
    validTo?: string | null | undefined;
};
export type eventPromoCode_on_conflict = {
    constraint: eventPromoCode_constraint;
    update_columns: Array<eventPromoCode_update_column>;
    where?: eventPromoCode_bool_exp | null | undefined;
};
export type eventScheduleDate_arr_rel_insert_input = {
    data: Array<eventScheduleDate_insert_input>;
    on_conflict?: eventScheduleDate_on_conflict | null | undefined;
};
export type eventScheduleTypeCustom_arr_rel_insert_input = {
    data: Array<eventScheduleTypeCustom_insert_input>;
    on_conflict?: eventScheduleTypeCustom_on_conflict | null | undefined;
};
export type eventVenue_arr_rel_insert_input = {
    data: Array<eventVenue_insert_input>;
    on_conflict?: eventVenue_on_conflict | null | undefined;
};
export type eventVenue_insert_input = {
    additionalInfo?: string | null | undefined;
    campingDescription?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    meetupInstructions?: string | null | undefined;
    parkingInfo?: string | null | undefined;
    updated_at?: string | null | undefined;
    venue?: venue_obj_rel_insert_input | null | undefined;
    venueCuid?: string | null | undefined;
};
export type venue_obj_rel_insert_input = {
    data: venue_insert_input;
    on_conflict?: venue_on_conflict | null | undefined;
};
export type venue_insert_input = {
    address?: string | null | undefined;
    addressWithName?: string | null | undefined;
    city?: string | null | undefined;
    country?: string | null | undefined;
    countryCode?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    eventVenue?: eventVenue_obj_rel_insert_input | null | undefined;
    eventVenues?: eventVenue_arr_rel_insert_input | null | undefined;
    fullAddress?: string | null | undefined;
    id?: number | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    name?: string | null | undefined;
    organizer?: organizer_obj_rel_insert_input | null | undefined;
    organizerCuid?: string | null | undefined;
    placeId?: string | null | undefined;
    postalCode?: string | null | undefined;
    state?: string | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVenue_obj_rel_insert_input = {
    data: eventVenue_insert_input;
    on_conflict?: eventVenue_on_conflict | null | undefined;
};
export type eventVenue_on_conflict = {
    constraint: eventVenue_constraint;
    update_columns: Array<eventVenue_update_column>;
    where?: eventVenue_bool_exp | null | undefined;
};
export type venue_on_conflict = {
    constraint: venue_constraint;
    update_columns: Array<venue_update_column>;
    where?: venue_bool_exp | null | undefined;
};
export type eventVibe_arr_rel_insert_input = {
    data: Array<eventVibe_insert_input>;
    on_conflict?: eventVibe_on_conflict | null | undefined;
};
export type eventVibe_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    averageSpeedRange?: Record<string, unknown> | unknown[] | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    genders?: Record<string, unknown> | unknown[] | null | undefined;
    hasAverageSpeed?: boolean | null | undefined;
    id?: number | null | undefined;
    intensityRange?: Record<string, unknown> | unknown[] | null | undefined;
    moreInfo?: string | null | undefined;
    rideType?: string | null | undefined;
    ridingTimePerDayRange?: Record<string, unknown> | unknown[] | null | undefined;
    speedType?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVibe_on_conflict = {
    constraint: eventVibe_constraint;
    update_columns: Array<eventVibe_update_column>;
    where?: eventVibe_bool_exp | null | undefined;
};
export type eventVirtualVenue_arr_rel_insert_input = {
    data: Array<eventVirtualVenue_insert_input>;
    on_conflict?: eventVirtualVenue_on_conflict | null | undefined;
};
export type eventVirtualVenue_insert_input = {
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    event?: event_obj_rel_insert_input | null | undefined;
    eventCuid?: string | null | undefined;
    id?: number | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
};
export type eventVirtualVenue_on_conflict = {
    constraint: eventVirtualVenue_constraint;
    update_columns: Array<eventVirtualVenue_update_column>;
    where?: eventVirtualVenue_bool_exp | null | undefined;
};
export type eventVolunteerJob_arr_rel_insert_input = {
    data: Array<eventVolunteerJob_insert_input>;
    on_conflict?: eventVolunteerJob_on_conflict | null | undefined;
};
export type ride_arr_rel_insert_input = {
    data: Array<ride_insert_input>;
    on_conflict?: ride_on_conflict | null | undefined;
};
export type clinicCoach_on_conflict = {
    constraint: clinicCoach_constraint;
    update_columns: Array<clinicCoach_update_column>;
    where?: clinicCoach_bool_exp | null | undefined;
};
export type coachAvailability_arr_rel_insert_input = {
    data: Array<coachAvailability_insert_input>;
    on_conflict?: coachAvailability_on_conflict | null | undefined;
};
export type coachAvailability_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    dayOfTheWeek?: string | null | undefined;
    endSeconds?: number | null | undefined;
    id?: number | null | undefined;
    isAvailable?: boolean | null | undefined;
    startSeconds?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachAvailability_on_conflict = {
    constraint: coachAvailability_constraint;
    update_columns: Array<coachAvailability_update_column>;
    where?: coachAvailability_bool_exp | null | undefined;
};
export type coachCertification_arr_rel_insert_input = {
    data: Array<coachCertification_insert_input>;
    on_conflict?: coachCertification_on_conflict | null | undefined;
};
export type coachCertification_insert_input = {
    certification?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    updated_at?: string | null | undefined;
    year?: number | null | undefined;
};
export type coachCertification_on_conflict = {
    constraint: coachCertification_constraint;
    update_columns: Array<coachCertification_update_column>;
    where?: coachCertification_bool_exp | null | undefined;
};
export type coachDiscipline_arr_rel_insert_input = {
    data: Array<coachDiscipline_insert_input>;
    on_conflict?: coachDiscipline_on_conflict | null | undefined;
};
export type coachDiscipline_insert_input = {
    abilityLevelRange?: Record<string, unknown> | unknown[] | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    id?: number | null | undefined;
    name?: string | null | undefined;
    skills?: Record<string, unknown> | unknown[] | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachDiscipline_on_conflict = {
    constraint: coachDiscipline_constraint;
    update_columns: Array<coachDiscipline_update_column>;
    where?: coachDiscipline_bool_exp | null | undefined;
};
export type coachLesson_arr_rel_insert_input = {
    data: Array<coachLesson_insert_input>;
    on_conflict?: coachLesson_on_conflict | null | undefined;
};
export type coachLocation_arr_rel_insert_input = {
    data: Array<coachLocation_insert_input>;
    on_conflict?: coachLocation_on_conflict | null | undefined;
};
export type coachLocation_insert_input = {
    addressLine1?: string | null | undefined;
    city?: string | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    country?: string | null | undefined;
    countryCode?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    fullAddress?: string | null | undefined;
    googlePlaceId?: string | null | undefined;
    id?: number | null | undefined;
    lat?: number | null | undefined;
    lng?: number | null | undefined;
    state?: string | null | undefined;
    timezone?: string | null | undefined;
    updated_at?: string | null | undefined;
    zipCode?: string | null | undefined;
};
export type coachLocation_on_conflict = {
    constraint: coachLocation_constraint;
    update_columns: Array<coachLocation_update_column>;
    where?: coachLocation_bool_exp | null | undefined;
};
export type coachStudent_arr_rel_insert_input = {
    data: Array<coachStudent_insert_input>;
    on_conflict?: coachStudent_on_conflict | null | undefined;
};
export type coachStudent_insert_input = {
    ageRange?: Record<string, unknown> | unknown[] | null | undefined;
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    focus?: Record<string, unknown> | unknown[] | null | undefined;
    genders?: Record<string, unknown> | unknown[] | null | undefined;
    id?: number | null | undefined;
    languages?: Record<string, unknown> | unknown[] | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachStudent_on_conflict = {
    constraint: coachStudent_constraint;
    update_columns: Array<coachStudent_update_column>;
    where?: coachStudent_bool_exp | null | undefined;
};
export type coachUnavailableDate_arr_rel_insert_input = {
    data: Array<coachUnavailableDate_insert_input>;
    on_conflict?: coachUnavailableDate_on_conflict | null | undefined;
};
export type coachUnavailableDate_insert_input = {
    coach?: coach_obj_rel_insert_input | null | undefined;
    coachCuid?: string | null | undefined;
    created_at?: string | null | undefined;
    cuid?: string | null | undefined;
    date?: string | null | undefined;
    endSeconds?: number | null | undefined;
    id?: number | null | undefined;
    isAllDay?: boolean | null | undefined;
    startSeconds?: number | null | undefined;
    updated_at?: string | null | undefined;
};
export type coachUnavailableDate_on_conflict = {
    constraint: coachUnavailableDate_constraint;
    update_columns: Array<coachUnavailableDate_update_column>;
    where?: coachUnavailableDate_bool_exp | null | undefined;
};
export type lessonLocation_arr_rel_insert_input = {
    data: Array<lessonLocation_insert_input>;
    on_conflict?: lessonLocation_on_conflict | null | undefined;
};
export type eventMetadata_arr_rel_insert_input = {
    data: Array<eventMetadata_insert_input>;
    on_conflict?: eventMetadata_on_conflict | null | undefined;
};
export type image_arr_rel_insert_input = {
    data: Array<image_insert_input>;
    on_conflict?: image_on_conflict | null | undefined;
};
export type series_arr_rel_insert_input = {
    data: Array<series_insert_input>;
    on_conflict?: series_on_conflict | null | undefined;
};
export type sponsor_arr_rel_insert_input = {
    data: Array<sponsor_insert_input>;
    on_conflict?: sponsor_on_conflict | null | undefined;
};
export type ticket_arr_rel_insert_input = {
    data: Array<ticket_insert_input>;
    on_conflict?: ticket_on_conflict | null | undefined;
};
export type venue_arr_rel_insert_input = {
    data: Array<venue_insert_input>;
    on_conflict?: venue_on_conflict | null | undefined;
};
export type organizerProfileMutations_saveOrganizerProfile_MutationVariables = {
    publicIdToDelete: string;
    organizer: organizer_insert_input;
};
export type organizerProfileMutations_saveOrganizerProfile_MutationResponse = {
    readonly insert_organizer_one: {
        readonly id: string;
        readonly name: string;
        readonly about: string | null;
        readonly website: string | null;
        readonly slug: string;
        readonly imagePublicId: string | null;
        readonly defaultCurrencyCode: string;
        readonly image: {
            readonly bytes: number | null;
            readonly format: string | null;
            readonly height: number | null;
            readonly originalFilename: string | null;
            readonly publicId: string;
            readonly relativeUrl: string;
            readonly resourceType: string | null;
            readonly url: string;
            readonly version: number | null;
            readonly width: number | null;
            readonly organizerCuid: string;
        } | null;
    } | null;
    readonly delete_image_by_pk: {
        readonly publicId: string;
    } | null;
};
export type organizerProfileMutations_saveOrganizerProfile_Mutation = {
    readonly response: organizerProfileMutations_saveOrganizerProfile_MutationResponse;
    readonly variables: organizerProfileMutations_saveOrganizerProfile_MutationVariables;
};



/*
mutation organizerProfileMutations_saveOrganizerProfile_Mutation(
  $publicIdToDelete: String!
  $organizer: organizer_insert_input!
) {
  insert_organizer_one(object: $organizer, on_conflict: {constraint: organizer_cuid_key, update_columns: [name, about, website, slug, imagePublicId, defaultCurrencyCode]}) {
    id
    name
    about
    website
    slug
    imagePublicId
    defaultCurrencyCode
    image {
      bytes
      format
      height
      originalFilename
      publicId
      relativeUrl
      resourceType
      url
      version
      width
      organizerCuid
      id
    }
  }
  delete_image_by_pk(publicId: $publicIdToDelete) {
    publicId
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "organizer"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "publicIdToDelete"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "object",
            "variableName": "organizer"
        } as any,
        {
            "kind": "Literal",
            "name": "on_conflict",
            "value": {
                "constraint": "organizer_cuid_key",
                "update_columns": [
                    "name",
                    "about",
                    "website",
                    "slug",
                    "imagePublicId",
                    "defaultCurrencyCode"
                ]
            }
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "about",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imagePublicId",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultCurrencyCode",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bytes",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "format",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originalFilename",
        "storageKey": null
    } as any, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicId",
        "storageKey": null
    } as any, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relativeUrl",
        "storageKey": null
    } as any, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resourceType",
        "storageKey": null
    } as any, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
    } as any, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
    } as any, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organizerCuid",
        "storageKey": null
    } as any, v21 = [
        {
            "kind": "Variable",
            "name": "publicId",
            "variableName": "publicIdToDelete"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "organizerProfileMutations_saveOrganizerProfile_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "organizer",
                    "kind": "LinkedField",
                    "name": "insert_organizer_one",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v21 /*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "delete_image_by_pk",
                    "plural": false,
                    "selections": [
                        (v14 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "organizerProfileMutations_saveOrganizerProfile_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "organizer",
                    "kind": "LinkedField",
                    "name": "insert_organizer_one",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v21 /*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "delete_image_by_pk",
                    "plural": false,
                    "selections": [
                        (v14 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b972ce0b570963dbfb509cff39a81230",
            "id": null,
            "metadata": {},
            "name": "organizerProfileMutations_saveOrganizerProfile_Mutation",
            "operationKind": "mutation",
            "text": "mutation organizerProfileMutations_saveOrganizerProfile_Mutation(\n  $publicIdToDelete: String!\n  $organizer: organizer_insert_input!\n) {\n  insert_organizer_one(object: $organizer, on_conflict: {constraint: organizer_cuid_key, update_columns: [name, about, website, slug, imagePublicId, defaultCurrencyCode]}) {\n    id\n    name\n    about\n    website\n    slug\n    imagePublicId\n    defaultCurrencyCode\n    image {\n      bytes\n      format\n      height\n      originalFilename\n      publicId\n      relativeUrl\n      resourceType\n      url\n      version\n      width\n      organizerCuid\n      id\n    }\n  }\n  delete_image_by_pk(publicId: $publicIdToDelete) {\n    publicId\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd7e3591c25fee8d9f0fd6c29aff11369';
export default node;
