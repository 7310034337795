/**
 React Hook Form Utils
 */
import { MutableRefObject, useEffect } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import * as RA from 'ramda-adjunct';

import { findPathsToKey } from '@/lib/object-utils';
import { arrayToCommaString } from '@/lib/string-utils';
import { scrollWindowToBottom, scrollWindowToElement, scrollWindowToTop } from '@/lib/ui-utils';

/**
 * Scroll to new errors when they show up.
 */
export const useScrollToRHFErrorByContainer = <T extends FieldValues>(
  errors: FieldErrors<T>,
  containerRef?: MutableRefObject<HTMLElement>,
): void => {
  useEffect(() => {
    const errorRefs = findPathsToKey({ obj: errors, key: 'ref' });
    if (!errorRefs.length) {
      // scrollWindowToTop();
      return;
    }
    for (const errorRef of errorRefs) {
      const { value: elementOrObject } = errorRef;
      if (containerRef?.current) {
        containerRef.current.scrollTo({
          top: elementOrObject.offsetTop,
          behavior: 'smooth',
        });
        break;
      }
      if (elementOrObject.scrollIntoView) {
        elementOrObject.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      if (elementOrObject.name) {
        const item = document.getElementsByName(elementOrObject.name)?.[0];
        if (item) {
          item.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      }
    }
  }, [errors, containerRef?.current]);
};

/**
 * Scroll to a react hook form error
 */
export const scrollToRHFError = <T extends FieldValues>(
  err: FieldErrors<T>,
  options?: { defaultScrollToBottom?: boolean; topPadding?: number },
): void => {
  const errorRefs = (findPathsToKey({ obj: err, key: 'ref' }) ?? []).filter(Boolean);
  if (!errorRefs.length) {
    if (options?.defaultScrollToBottom) {
      scrollWindowToBottom();
    } else {
      scrollWindowToTop();
    }
    return;
  }
  const { pathToKey, value } = errorRefs[0];
  let el: HTMLElement | null = null;
  if (RA.isPlainObj(value)) {
    // others.[0].firstName.ref => other.[0].firstName
    let path = pathToKey.split('.ref')[0];
    if (path.includes('other')) {
      // others.[0].firstName => other[0].firstName
      path = path.replace(/\./, '');
    }
    const elByName = document.getElementsByName(path)?.[0];
    if (elByName) {
      el = elByName;
    } else if (options?.defaultScrollToBottom) {
      scrollWindowToBottom();
    } else {
      scrollWindowToTop();
    }
  } else if (window.getComputedStyle(value).display === 'none') {
    // const ele = findPathsToKey({ obj: value, key: 'name' });
    el = document.querySelector(`label[for=${CSS.escape(value.name)}]`);
    if (!el && value.parentElement) {
      el = value.parentElement;
    }
  } else {
    el = value;
  }
  if (el) {
    if (options?.topPadding) {
      scrollWindowToElement(el, options.topPadding);
    } else {
      scrollWindowToElement(el, window.innerHeight / 2);
      // el.scrollIntoView();
    }
  }
};
/**
 * Take a RHF error object and turn the errors into strings
 * This is nice because the errors can eb an array, a string, or nothing
 */
export const rhfErrorsToString = (errors: FieldErrors, name: string): string => {
  const formErrors = errors[name];
  if (RA.isNilOrEmpty(formErrors)) {
    return '';
  }
  if (RA.isArray(formErrors)) {
    return arrayToCommaString(formErrors.map((error) => error.message));
  }
  return formErrors.message ?? '';
};

export type FormPath = Array<string | number>;

/**
 * Returns the name of an input or path that react-hook-form would use to dereference a deeply
 * nested form schema.
 *
 * E.g. ['formSchema', 'subForm', 2, 'inputName'] => "formSchema.subForm[2].inputName"
 * @param path attribute array of a deeply nested form schema
 */
export function convertPathToName(path: FormPath): string {
  if (path.length === 0) {
    throw new Error('empty path');
  }
  if (typeof path[0] === 'number') {
    throw new Error('invalid path: top level form schema cannot be an array');
  }

  const [firstValue, ...rest] = path;

  return rest.reduce(
    (inputName: string, attributeNameOrIndex: string | number): string =>
      typeof attributeNameOrIndex === 'number'
        ? `${inputName}[${attributeNameOrIndex}]`
        : `${inputName}.${attributeNameOrIndex}`,
    firstValue,
  );
}
