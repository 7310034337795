import React, { ReactNode } from 'react';
import { useMount } from 'react-use';
import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { isEqual } from 'date-fns/fp';
import * as RA from 'ramda-adjunct';

import DatePicker, { DatePickerProps } from 'react-rainbow-components/components/DatePicker';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    '& input': {
      borderRadius: theme.spacing(0.5),
      border: 'none !important',
      boxShadow: 'none !important',
      backgroundColor: '#F4F4F4',
      '&:active, &:focus, &:hover': {
        backgroundColor: '#EAEAEA',
      },
      '&::placeholder': {
        color: theme.palette.text.hint,
      },
    },
    '& *': {
      boxShadow: 'none',
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '.75rem',
      marginLeft: '0.125rem',
      marginBottom: 0,
    },
    "& div[id*='error-message']": {
      marginTop: 0,
      alignSelf: 'flex-start',
      textTransform: 'none',
      ...theme.typography.caption,
    },
  },
  datePickerOutlined: {
    '& input': {
      borderRadius: theme.spacing(0.5),
      // boxShadow: 'rgb(0 0 0 / 8%) 0px 3px 5px -1px !important',
      transition:
        'box-shadow 250ms ease, padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #03465c45 !important',
      height: '48px',
      backgroundColor: 'white !important',
    },
    '& input:hover': {
      borderColor: '#03465c95 !important',
    },
  },
  datePickerUnderlined: {
    '& input': {
      borderRadius: 0,
      borderBottom: '1px solid #898989 !important',
      backgroundColor: 'transparent !important',
    },
    '& input:hover': {
      borderBottom: '1px solid #03465c95 !important',
    },
    '& input:active, & input:focus': {
      borderBottom: '1px solid #004650 !important',
    },
  },
}));

export type RainbowDatePickerProps = DatePickerProps & {
  allowSameDate?: boolean;
  outlinedInput?: boolean;
  underlinedInput?: boolean;
};
const RainbowDatePicker: React.FC<RainbowDatePickerProps> = React.forwardRef<
  ReactNode,
  RainbowDatePickerProps
>(
  (
    {
      allowSameDate,
      className,
      onChange,
      outlinedInput,
      selectionType,
      underlinedInput,
      value,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles();

    // Set the tabindex to none or else we cant use the keyboard in this control
    useMount(() => {
      const dialogElement = document.querySelector('.MuiDialog-container');
      if (dialogElement) {
        dialogElement.setAttribute('tabIndex', 'none');
      }
      const popoverElements = [...document.querySelectorAll('.MuiPopover-paper')];
      if (RA.isNotNilOrEmpty(popoverElements)) {
        popoverElements.forEach((el) => el.setAttribute('tabIndex', 'none'));
      }
    });

    /**
     * Date Picker doesnt auto close when the user picks the same date. Add that functionality in
     * @param date
     */
    const handleOnChange = (date: Date | Date[]): void => {
      if (!onChange) {
        return;
      }
      if (
        selectionType === 'range' &&
        allowSameDate &&
        value?.length === 1 &&
        date?.length === 1 &&
        isEqual(value[0], date[0])
      ) {
        onChange([value[0], value[0]]);
        const closeButtonElement = document.getElementById('modal-close-button');
        if (!closeButtonElement) {
          return;
        }
        closeButtonElement.click();
        return;
      }

      if (onChange) {
        onChange(date);
      }
    };
    return (
      <DatePicker
        id="rainbow-date-picker"
        labelAlignment="left"
        {...rest}
        className={classNames(
          classes.datePicker,
          { [classes.datePickerOutlined]: outlinedInput },
          { [classes.datePickerUnderlined]: underlinedInput },
          className,
        )}
        onChange={handleOnChange}
        ref={ref}
        selectionType={selectionType}
        value={value}
      />
    );
  },
);

export default RainbowDatePicker;
