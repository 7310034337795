import { Theme } from '@material-ui/core';

export const MuiAppBar = (theme: Theme): Record<string, unknown> => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
  },
  root: {
    boxShadow: 'none',
    borderBottom: '1px solid transparent',
    '& .MuiButtonBase-root': {
      fontWeight: 700,
    },
  },
});
