import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/browser';

import ENV from '@/env';

const reloadPage = (): void => window.location.reload();

export interface ErrorMessageProps {
  buttonFunction?: () => void;
  buttonTitle?: string;
  errorComponentStack?: string;
  errorMessage?: string;
  message?: string | ReactElement;
  title?: string;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({
  buttonFunction = reloadPage,
  buttonTitle = 'Reload Page',
  errorComponentStack,
  errorMessage,
  message = 'Something went wrong! If you could try reloading the page, that would be great.',
  title = 'Error',
}) => {
  // TODO: This is disabled because all of the providers for the hooks are below the ErrorBoundary
  // const { trackSiteErrorView } = useSegmentTrack();
  // useMount(() => {
  //   trackSiteErrorView({
  //     errorComponentStack,
  //     errorMessage,
  //     title,
  //     message,
  //   });
  // });

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      justifyContent="center"
      margin="auto"
      minHeight={200}
    >
      <Typography gutterBottom align="center" variant="h1">
        {title}
      </Typography>
      <Typography gutterBottom align="center">
        {message}
      </Typography>
      {ENV.IS_NODE_ENV_LOCAL && (
        <>
          <Typography gutterBottom align="center">
            {errorMessage}
          </Typography>
          <Typography gutterBottom align="center">
            {errorComponentStack}
          </Typography>
        </>
      )}
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            onClick={(): void => buttonFunction()}
            size="small"
            variant="contained"
          >
            {buttonTitle}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={(): void => {
              Sentry.showReportDialog({
                title: "We all have mechanical's sometimes",
                subtitle:
                  "The Reggy team has been notified. If you'd like to help, tell us what happened below.",
                subtitle2: '',
                successMessage: 'Your feedback has been sent. Thank you!',
              });
            }}
            size="small"
            variant="outlined"
          >
            Send Feedback
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorMessage;
