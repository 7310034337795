/**
 * User App Routes
 */
import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';

import LoadingBounce from '@/ui/LoadingPlaceholders/LoadingBounce';
import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import ExtendedRoute from '../../components/ExtendedRoute';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import FullScreenLoadingPlaceholder from '@/components/LoadingPlaceholder/FullScreenLoadingPlaceholder';

import OnboardingPage from '@/pages/Coach/OnboardingPage';
import PageNotFound from '@/pages/ErrorPages/PageNotFound';
import Page from '@/pages/Page';
import UserPage from '@/pages/User/UserPage';
import UserRegistrationsPage from '@/pages/User/UserRegistrationsPage';
import CreateCoachProvider from '@/providers/CreateCoachProvider';
import { AppRouteService } from '@/routes/RouteService';

// const FeedView = LazyloadComponentWithRetry(
//   () => import(/* webpackChunkName: "FeedView" */ '@/containers/User/Feed/FeedView'),
// );

const CreateCoachView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateCoachView" */ '@/containers/User/Coach/CreateCoach/CreateCoachView'
    ),
);

const StudentsView = LazyloadComponentWithRetry(
  () =>
    import(/* webpackChunkName: "StudentsView" */ '@/containers/User/Coach/Students/StudentsView'),
);

const DashboardView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "DashboardView" */ '@/containers/User/Coach/Dashboard/DashboardView'
    ),
);
const AnalyticsView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AnalyticsView" */ '@/containers/User/Coach/Analytics/AnalyticsView'
    ),
);

const AllLessonsView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AllLessonsView" */ '@/containers/User/Coach/AllLessons/AllLessonsView'
    ),
);

const CoachAvailabilityView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CoachAvailabilityView" */ '@/containers/User/Coach/Availability/CoachAvailabilityView'
    ),
);

const AccountingView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AccountingView" */ '@/containers/User/Coach/Accounting/AccountingView'
    ),
);

const ScheduleView = LazyloadComponentWithRetry(
  () =>
    import(/* webpackChunkName: "ScheduleView" */ '@/containers/User/Coach/Schedule/ScheduleView'),
);

const CreateOnlineCourseView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreateOnlineCourseView" */ '@/containers/OnlineCourse/CreateOnlineCourse/CreateOnlineCourseView'
    ),
);
const UserOnlineCoursesLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "UserOnlineCoursesLoader" */ '@/containers/User/OnlineCourses/UserOnlineCoursesLoader'
    ),
);
const OnlineCourseDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseDetail/OnlineCourseDetailLoader'
    ),
);
const OnlineCourseOverviewDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseOverviewDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseOverviewDetail/OnlineCourseOverviewDetailLoader'
    ),
);
const OnlineCourseLessonDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseLessonDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseLessonDetail/OnlineCourseLessonDetailLoader'
    ),
);
const OnlineCourseSectionDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseSectionDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseSectionDetail/OnlineCourseSectionDetailLoader'
    ),
);

const AccountSettingsView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "AccountSettingsView" */ '@/containers/User/Settings/AccountSettings/AccountSettingsView'
    ),
);

const EditPreferencesView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "EditPreferencesView" */ '@/containers/User/Settings/EditPreferences/EditPreferencesView'
    ),
);

const EditProfileView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "EditProfileView" */ '@/containers/User/Settings/EditProfile/EditProfileView'
    ),
);

const UserDashboardView = LazyloadComponentWithRetry(
  () =>
    import(/* webpackChunkName: "UserDashboardView" */ '@/containers/User/Dashboard/DashboardView'),
);

const MessagesView = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "MessagesView" */ '@/containers/User/Messages/MessagesView'),
);

const ReportNoShowPage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "ReportNoShowPage" */ '@/pages/ReportNoShow/ReportNoShowPage'),
);

const RegistrationDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegistrationDetailLoader" */ '@/containers/User/Registrations/RegistrationDetail/RegistrationDetailLoader'
    ),
);
const RegistrationLessonDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegistrationLessonDetailLoader" */ '@/containers/User/Registrations/RegistrationLessonDetail/RegistrationLessonDetailLoader'
    ),
);
const RegistrationsLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegistrationsLoader" */ '@/containers/User/Registrations/RegistrationsLoader'
    ),
);

const UserRoutes: React.FC = () => {
  return (
    <Routes>
      {/* <Route */}
      {/*  element={ */}
      {/*    <ExtendedRoute> */}
      {/*      <UserPage> */}
      {/*        <FeedView /> */}
      {/*      </UserPage> */}
      {/*    </ExtendedRoute> */}
      {/*  } */}
      {/*  path={AppRouteService.getRouterRoutePath('User_Feed')} */}
      {/* /> */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <UserPage>
              <UserDashboardView />
            </UserPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_Dashboard')}
      />

      <Route
        element={
          <ExtendedRoute>
            <UserPage>
              <Suspense fallback={<LoadingPlaceholder disableShrink isLoading delay={200} />}>
                <Routes>
                  <Route
                    element={
                      <ExtendedRoute trackPageView>
                        <UserOnlineCoursesLoader />
                      </ExtendedRoute>
                    }
                    path={AppRouteService.getRouterRoutePath('User_OnlineCourses')}
                  />
                  <Route
                    element={
                      <ExtendedRoute trackPageView>
                        <OnlineCourseDetailLoader />
                      </ExtendedRoute>
                    }
                    path={AppRouteService.getRouterRoutePath('User_OnlineCourses_Detail')}
                  />
                  <Route
                    element={
                      <ExtendedRoute trackPageView>
                        <OnlineCourseOverviewDetailLoader />
                      </ExtendedRoute>
                    }
                    path={AppRouteService.getRouterRoutePath('User_OnlineCourses_OverviewDetail')}
                  />
                  <Route
                    element={
                      <ExtendedRoute trackPageView>
                        <OnlineCourseSectionDetailLoader />
                      </ExtendedRoute>
                    }
                    path={AppRouteService.getRouterRoutePath('User_OnlineCourses_SectionDetail')}
                  />
                  <Route
                    element={
                      <ExtendedRoute trackPageView>
                        <OnlineCourseLessonDetailLoader />
                      </ExtendedRoute>
                    }
                    path={AppRouteService.getRouterRoutePath('User_OnlineCourses_LessonDetail')}
                  />
                </Routes>
              </Suspense>
            </UserPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('User_OnlineCourses')}
      />

      <Route
        element={
          <ExtendedRoute trackPageView>
            <UserPage>
              <Suspense fallback={<LoadingPlaceholder disableShrink isLoading delay={200} />}>
                <AccountSettingsView />
              </Suspense>
            </UserPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_AccountSettings')}
      />
      <Route
        element={
          <ExtendedRoute trackPageView>
            <UserPage>
              <EditPreferencesView />
            </UserPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_EditPreferences')}
      />
      <Route
        element={
          <ExtendedRoute trackPageView>
            <UserPage>
              <EditProfileView />
            </UserPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_EditProfile')}
      />

      <Route
        element={
          <ExtendedRoute>
            <Routes>
              {/* React Router doesn't support optional parameter paths so we have to make two routes */}
              {/* https://github.com/remix-run/react-router/issues/7285 */}
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <UserPage showFooter={false}>
                      <Suspense fallback={<LoadingBounce />}>
                        <MessagesView />
                      </Suspense>
                    </UserPage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('User_Messages')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <UserPage showFooter={false}>
                      <Suspense fallback={<LoadingBounce />}>
                        <MessagesView />
                      </Suspense>
                    </UserPage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('User_Messages_Channel')}
              />

              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <PageNotFound />
                  </ExtendedRoute>
                }
                path="*"
              />
            </Routes>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('User_Messages')}
      />

      <Route
        element={
          <ExtendedRoute trackPageView>
            <ReportNoShowPage />
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_ReportNoShow')}
      />

      {/* Registrations */}
      <Route
        element={
          <ExtendedRoute>
            <UserRegistrationsPage>
              <Routes>
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <RegistrationsLoader />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Registrations')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <RegistrationDetailLoader />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Registrations_Detail')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <RegistrationLessonDetailLoader />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Registrations_LessonDetail')}
                />

                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <PageNotFound />
                    </ExtendedRoute>
                  }
                  path="*"
                />
              </Routes>
            </UserRegistrationsPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('User_Registrations')}
      />

      {/* Become a coach */}
      <Route
        element={
          <ExtendedRoute
            trackPageView
            validRoles={AppRouteService.getRouteValidRoles('User_BecomeCoach')}
          >
            <OnboardingPage>
              <Suspense fallback={<FullScreenLoadingPlaceholder />}>
                <CreateCoachProvider>
                  <CreateCoachView />
                </CreateCoachProvider>
              </Suspense>
            </OnboardingPage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('User_BecomeCoach')}
      />

      {/* Business */}
      <Route
        element={
          <ExtendedRoute validRoles={AppRouteService.getRouteValidRoles('User_Business_App')}>
            <Suspense fallback={<FullScreenLoadingPlaceholder />}>
              <Routes>
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <Navigate
                        replace
                        to={AppRouteService.getRelativeUrl('User_Business_Dashboard')}
                      />
                    </ExtendedRoute>
                  }
                  path="/"
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage>
                        <DashboardView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_Dashboard')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage>
                        <AnalyticsView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_Analytics')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage>
                        <AccountingView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_Accounting')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage showFooter={false}>
                        <ScheduleView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_Schedule')}
                />

                <Route
                  element={
                    <ExtendedRoute>
                      <Routes>
                        {/* React Router doesn't support optional parameter paths so we have to make two routes */}
                        {/* https://github.com/remix-run/react-router/issues/7285 */}
                        <Route
                          element={
                            <ExtendedRoute trackPageView>
                              <UserPage showFooter={false}>
                                <Suspense fallback={<LoadingBounce />}>
                                  <StudentsView />
                                </Suspense>
                              </UserPage>
                            </ExtendedRoute>
                          }
                          path={AppRouteService.getRouterRoutePath('User_Business_Students')}
                        />
                        <Route
                          element={
                            <ExtendedRoute trackPageView>
                              <UserPage showFooter={false}>
                                <Suspense fallback={<LoadingBounce />}>
                                  <StudentsView />
                                </Suspense>
                              </UserPage>
                            </ExtendedRoute>
                          }
                          path={AppRouteService.getRouterRoutePath(
                            'User_Business_Students_Channel',
                          )}
                        />

                        <Route
                          element={
                            <ExtendedRoute trackPageView>
                              <PageNotFound />
                            </ExtendedRoute>
                          }
                          path="*"
                        />
                      </Routes>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterDirectoryPath('User_Business_Students')}
                />

                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage showFooter={false}>
                        <AllLessonsView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_AllLessons')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <UserPage>
                        <CoachAvailabilityView />
                      </UserPage>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_Availability')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <Page noIndex title="Create Online Course • Reggy">
                        <CreateOnlineCourseView />
                      </Page>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('User_Business_CreateOnlineCourse_New')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <Page noIndex title="Edit Online Course • Reggy">
                        <CreateOnlineCourseView />
                      </Page>
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath(
                    'User_Business_CreateOnlineCourse_Existing',
                  )}
                />

                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <PageNotFound />
                    </ExtendedRoute>
                  }
                  path="*"
                />
              </Routes>
            </Suspense>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('User_Business')}
      />

      <Route
        element={
          <ExtendedRoute trackPageView>
            <PageNotFound />
          </ExtendedRoute>
        }
        path="*"
      />
    </Routes>
  );
};
export default UserRoutes;
