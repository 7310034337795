/**
 * Typeform contact us button
 */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PopupButton } from '@typeform/embed-react';

import ExtendedButton, { ExtendedButtonProps } from '@/ui/Button/ExtendedButton';

import { TYPEFORM_CONTACT_US_FORM_ID } from '@/const';

const useStyles = makeStyles(() => ({
  typeformButtonText: {
    color: '#666666',
    background: 'transparent',
    border: 'none',
    padding: '0',
    textDecoration: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  typeformButton: {
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent',
  },
}));

interface TypeformContactUsProps {
  button?: boolean;
  buttonProps?: ExtendedButtonProps;
}
const TypeformContactUs: React.FC<TypeformContactUsProps> = ({ button, buttonProps }) => {
  const classes = useStyles();
  return (
    <>
      {button ? (
        <PopupButton className={classes.typeformButton} id={TYPEFORM_CONTACT_US_FORM_ID}>
          <ExtendedButton size="small" variant="outlined" {...buttonProps}>
            Contact Us
          </ExtendedButton>
        </PopupButton>
      ) : (
        <PopupButton className={classes.typeformButtonText} id={TYPEFORM_CONTACT_US_FORM_ID}>
          Contact Us
        </PopupButton>
      )}
    </>
  );
};

export default TypeformContactUs;
