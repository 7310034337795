/**
 * Band typography with different levels of padding
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';

import ConditionalWrapper from '../hoc/ConditionalWrapper';

import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useBrandStyles = makeStyles((theme: Theme) => ({
  brand: {
    color: Colors.Brand,
    fontWeight: 600,
    fontFamily: '"Varela Round"',
  },
  beta: {
    background: '#04759C !important',
    borderRadius: theme.spacing(0.5),
    color: '#ffffff !important',
    display: 'inline-block',
    fontSize: '0.7em',
    fontWeight: 400,
    marginLeft: theme.spacing(0.5),
    padding: '1px 2px',
    verticalAlign: 'top',
  },
  underline: {
    textDecoration: 'none !important',
  },
  inline: {
    display: 'inline-block',
    // transform: 'translateY(-1px)',
  },
  paddingSmall: {
    padding: theme.spacing(1),
  },
  paddingMedium: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  paddingLarge: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

interface BrandTypographyProps extends TypographyProps {
  withBeta?: boolean;
  padding?: 'small' | 'medium' | 'large';
  underline?: boolean;
  inline?: boolean;
  noLink?: boolean;
  linkTo?: string;
}
const BrandTypography: React.FC<BrandTypographyProps> = ({
  children: componentChildren,
  inline,
  linkTo,
  noLink,
  padding,
  underline,
  withBeta,
  ...rest
}) => {
  const brandClasses = useBrandStyles();
  const hasPaddingSmall = padding === 'small';
  const hasPaddingMedium = padding === 'medium';
  const hasPaddingLarge = padding === 'large';

  // Be able to have brand type without linking home
  const HomeLink: React.FC = ({ children: linkChildren }) => (
    <Link
      className={classNames({
        [brandClasses.underline]: !underline,
      })}
      to={linkTo ?? AppRouteService.getRelativeUrl('Home')}
    >
      {linkChildren}
    </Link>
  );

  return (
    <ConditionalWrapper WrapperComponent={HomeLink} condition={!noLink}>
      <Typography
        {...rest}
        className={classNames(brandClasses.brand, {
          [brandClasses.paddingSmall]: hasPaddingSmall,
          [brandClasses.paddingMedium]: hasPaddingMedium,
          [brandClasses.paddingLarge]: hasPaddingLarge,
          [brandClasses.inline]: inline,
        })}
      >
        {componentChildren}
      </Typography>
      {withBeta && <Typography className={brandClasses.beta}>Beta</Typography>}
    </ConditionalWrapper>
  );
};

export default BrandTypography;
