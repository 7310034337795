/**
 * Select made from TextField
 */
import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { TextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import ExtendedTextField, { ExtendedTextFieldProps } from './ExtendedTextField';
import MultiSelectChip from './MultiSelectChip';

import { reactChildrenToFlatArray } from '@/lib/ui-utils';

export const useExtendedSelectStyles = makeStyles((theme) => ({
  popover: {
    // border: '1px solid rgba(0, 69, 156, 0.2)',
    // boxShadow: `${theme.shadows[8]}, ${theme.shadows[10]}`,

    // border: '1px solid #D8D8D8',
    // boxShadow: '0px 8px 30px rgb(0 0 0 / 15%)',

    border: '1px solid rgba(60, 66, 87, 0.11)',
    boxShadow: theme.shadows[24],

    borderRadius: theme.spacing(1),
    // maxHeight: theme.spacing(46.75),
    maxHeight: '60vh',
  },
  smallPadding: {
    '& .MuiOutlinedInput-input,& .MuiFilledInput-input': {
      paddingTop: '12.5px',
      paddingBottom: '12.5px',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: '8.5px',
      paddingBottom: '8.5px',
    },
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '5.5px',
      paddingBottom: '5.5px',
    },
  },
  multiSelectPadding: {
    paddingRight: `${theme.spacing(4)}px !important`,
  },
  centerText: {
    textAlign: 'center',
  },
  placeholderText: {
    position: 'absolute',
    marginLeft: '14px',
    zIndex: 1,
    color: '#949494',
    pointerEvents: 'none',
    marginTop: '4px',
  },
  placeholderTextFilled: {
    'div p:nth-child(2)&, .MuiGrid-root > &': {
      marginTop: '17px',
    },
  },
  placeholderTextOutlined: {
    'div p:nth-child(2)&, .MuiGrid-root > &': {
      marginTop: '20px',
    },
  },
  placeholderTextUnderlined: {
    'div p:nth-child(2)&, .MuiGrid-root > &': {
      marginTop: '20px',
    },
  },
}));

export interface ExtendedSelectProps {
  centerChips?: boolean;
  centerText?: boolean;
  dontAllowEmptyMessage?: string;
  // Should we hide the tooltip on multislect chips
  hideChipTooltips?: boolean;
  isLoading?: boolean;
  minWidth?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg';
  multiple?: boolean;
  placeholder?: string;
  size?: 'small';
  zeroWidth?: boolean;
}
const ExtendedSelect: React.FC<ExtendedSelectProps & ExtendedTextFieldProps & TextFieldProps> = ({
  centerChips,
  centerText,
  children,
  className,
  dontAllowEmptyMessage,
  hideChipTooltips,
  multiple,
  onChange,
  placeholder,
  value,
  ...rest
}) => {
  const classes = useExtendedSelectStyles();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Controlled so we con close on a multiselect
   */
  const [isOpen, setIsOpen] = useState(false);
  const onClose = (): void => {
    setIsOpen(false);
    if (rest.SelectProps && rest.SelectProps.onClose) {
      rest.SelectProps.onClose();
    }
  };
  const onOpen = (): void => {
    setIsOpen(true);
  };

  // Flag so we can remove some padding once a multiple select has items
  const isMultipleAndHasItems = multiple && RA.isNotNilOrEmpty(value);

  /**
   * We don't have the labels for the menu items when we use multiple select.
   * To get the label, take the "children" from each menu item. To the get the
   * value we take the value prop
   * We make an object array [{value: child}] that we use to make the Chips
   */
  const [childrenValueLabels, setChildrenValueLabels] = useState<
    { [key in string | number]: string | number | ReactElement }[] | []
  >([]);

  useEffect(() => {
    if (multiple && RA.isNonEmptyArray(children)) {
      setChildrenValueLabels(
        children.map((child) => {
          return { [(child as ReactElement).props.value]: (child as ReactElement).props.children };
        }),
      );
    }
  }, [String(reactChildrenToFlatArray(children)), multiple]);

  const multipleSelectProps = {
    SelectProps: {
      ...rest.SelectProps,
      onClose,
      onOpen,
      open: isOpen,
      classes: { ...rest.SelectProps?.classes, select: classes.multiSelectPadding },
      multiple: true,
      renderValue: (selected: string[] & number[]): ReactNode => {
        if (RA.isNotArray(selected)) {
          throw Error(`Value: ${selected} is not an array`);
        }
        return (
          <Box textAlign={centerChips ? 'center' : ''}>
            {selected.map((val) => {
              const stringVal = val.toString();
              const label = R.values(R.find(R.has(stringVal), childrenValueLabels) ?? {});
              if (!label) {
                throw Error('No label found');
              }
              return (
                <MultiSelectChip
                  hideChipTooltips={hideChipTooltips}
                  key={`MultiSelectChip${val}`}
                  label={label[0]}
                />
              );
            })}
          </Box>
        );
      },
      // },
      MenuProps: {
        classes: { ...rest.SelectProps?.MenuProps?.classes, paper: classes.popover },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    } as SelectProps,
  };

  const handleChange = async (
    e: ChangeEvent<(HTMLInputElement | HTMLTextAreaElement) & { value: string[] }>,
  ): Promise<void> => {
    const newValue = e.target.value;
    // Should we allow the user to make the select empty
    if (!!dontAllowEmptyMessage && R.isEmpty(newValue)) {
      enqueueSnackbar(dontAllowEmptyMessage, {
        variant: 'error',
      });
      return;
    }
    if (onChange) {
      onChange(e);
    }
    // For multiple select
    onClose();
  };

  return (
    <>
      {placeholder &&
        RA.isNilOrEmpty(value) &&
        ((rest.InputLabelProps?.shrink && rest.label) || !rest.label) && (
          <Typography
            className={classNames(
              classes.placeholderText,
              { [classes.placeholderTextFilled]: rest.variant === 'filled' },
              { [classes.placeholderTextOutlined]: rest.variant === 'outlined' },
              {
                [classes.placeholderTextUnderlined]: !rest.variant || rest.variant === 'underlined',
              },
            )}
            color="textSecondary"
          >
            {placeholder}
          </Typography>
        )}
      <ExtendedTextField
        {...rest}
        select
        SelectProps={{
          ...rest.SelectProps,
          MenuProps: {
            classes: { paper: classes.popover, ...rest.SelectProps?.MenuProps?.classes },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        }}
        className={classNames(
          {
            [classes.smallPadding]: isMultipleAndHasItems,
            [classes.centerText]: !multiple && centerText,
          },
          className,
        )}
        onChange={handleChange}
        value={value}
        {...(multiple && multipleSelectProps)}
      >
        {children}
      </ExtendedTextField>
    </>
  );
};

export default ExtendedSelect;
