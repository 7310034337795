/*
Helper functions for the refine register non-categoried components
 */

import { compareAsc } from 'date-fns';
import * as R from 'ramda';
import { DeepReadonly } from 'utility-types';

import { NonCategoriedRegistrationCard_public_event } from './NonCategoried/__generated__/NonCategoriedRegistrationCard_public_event.graphql';

import { dateFromFloatingDateString } from '@/lib/date-helpers/date-utils';
import { getEventTimezone, getIsTodayBeforeRegDeadline } from '@/lib/event-info-utils';

/**
 * Sort a ride by first ride day's start date
 */
type RideToSortType = DeepReadonly<{
  readonly rideDays: { readonly startTime: string }[];
}>;
export const sortRidesByFirstStartDateAsc = (
  firstRide: RideToSortType,
  secondRide: RideToSortType,
): number =>
  compareAsc(
    dateFromFloatingDateString(firstRide.rideDays?.[0].startTime),
    dateFromFloatingDateString(secondRide.rideDays?.[0].startTime),
  );

/**
 * Sort a clinic by first clinic day's start date
 */
type ClinicToSortType = DeepReadonly<{
  readonly clinicDays: { readonly startTime: string }[];
}>;
export const sortClinicsByFirstStartDateAsc = (
  firstClinic: ClinicToSortType,
  secondClinic: ClinicToSortType,
): number =>
  compareAsc(
    dateFromFloatingDateString(firstClinic.clinicDays?.[0].startTime),
    dateFromFloatingDateString(secondClinic.clinicDays?.[0].startTime),
  );

/**
 * Sort clinics and ride instances that are open first
 */
export const sortOpenInstancesFirst = R.sortBy(
  (
    instance:
      | NonCategoriedRegistrationCard_public_event['clinics'][0]
      | NonCategoriedRegistrationCard_public_event['rides'][0],
  ) =>
    getEventTimezone(instance.event)
      ? !getIsTodayBeforeRegDeadline(instance, getEventTimezone(instance.event)!)
      : false,
);
