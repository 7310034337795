/**
 * Container page for hosting an event
 */
import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';

import Page from '../Page';

import HostEventLayout from '@/layouts/HostEventLayout';
import { getEventName } from '@/lib/event-info-utils';
import { ReactFCC } from '@/lib/type-defs/utility';
import { HostEventPage_event$key } from '@/pages/HostEvent/__generated__/HostEventPage_event.graphql';
import { useGetIsEventPublishedGlobal } from '@/pages/HostEvent/recoilStore';
import { useHostEventData } from '@/providers/HostEventProvider';

const HostEventPage: ReactFCC = ({ children }) => {
  const hostEventData = useHostEventData();
  const hostEventPageData = useFragment<HostEventPage_event$key>(
    graphql`
      fragment HostEventPage_event on event {
        occurrenceLabel
        eventMetadata {
          name
        }
      }
    `,
    hostEventData,
  );

  const isEventPublished = useGetIsEventPublishedGlobal();

  return (
    <Page
      noIndex
      title={`${isEventPublished ? 'Edit' : 'Create'} Event - ${getEventName(
        hostEventPageData,
      )} • Reggy`}
    >
      <HostEventLayout>{children}</HostEventLayout>
    </Page>
  );
};

export default HostEventPage;
