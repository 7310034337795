import React, { ChangeEvent, Suspense, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { NavLink } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ListSubheader,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuListSubheader from '@/ui/MenuListSubheader';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import { useMobileMenuStyles } from '@/ui/styles/mobileMenuStyles';
import DisableToggle from '@/hoc/DisableToggle';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { useAuthContainerStyles } from '@/containers/MainHeader/Mobile/AuthContainer';
import ProfileAccordion from '@/containers/MainHeader/Mobile/ProfileAccordion';
import ChooseOrganizerMenu from '@/containers/Organizer/Navigation/ChooseOrganizerMenu';
import { EditProfileDialog_OrganizerQuery } from '@/containers/OrganizerProfile/Admin/__generated__/EditProfileDialog_OrganizerQuery.graphql';
import {
  EDIT_PROFILE_DIALOG_QUERY,
  EditProfileLoader,
} from '@/containers/OrganizerProfile/Admin/EditProfileDialog';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { useOrganizerPermissions } from '@/lib/role-helpers/organizer-role-utils';
import { AppRouteService } from '@/routes/RouteService';

interface OrganizerNavigationMobileProps {
  handleMobileMenuClose: () => void;
}
const OrganizerNavigationMobile: React.FC<OrganizerNavigationMobileProps> = ({
  handleMobileMenuClose,
}) => {
  const mobileMenuClasses = useMobileMenuStyles();
  const authContainerClasses = useAuthContainerStyles();
  const { isCurrentOrgAdmin, isCurrentOrgOwner } = useOrganizerPermissions();
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);

  const [
    editOrganizerProfileQueryRef,
    loadEditOrganizerProfileQuery,
  ] = useQueryLoader<EditProfileDialog_OrganizerQuery>(EDIT_PROFILE_DIALOG_QUERY);

  const canEditBilling = isCurrentOrgOwner;
  const canViewAnalytics = isCurrentOrgAdmin || isCurrentOrgOwner;
  const canEditProfile = isCurrentOrgAdmin || isCurrentOrgOwner;

  useEffect(() => {
    loadEditOrganizerProfileQuery({
      organizerSlug,
    });
  }, [organizerSlug]);

  /**
   * Handle dialog state
   */
  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
    handleMobileMenuClose();
  };

  /**
   * Handle accordion state
   */
  const handleChange = (panel: string) => (
    event: ChangeEvent<unknown>,
    isExpanded: boolean,
  ): void => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box className={mobileMenuClasses.menu}>
      <Suspense fallback={null}>
        {editOrganizerProfileQueryRef && (
          <EditProfileLoader
            closeDialog={handleDialogClose}
            editOrganizerProfileQueryRef={editOrganizerProfileQueryRef}
            isDialogOpen={isDialogOpen}
          />
        )}
      </Suspense>
      <Box className={mobileMenuClasses.menuItemInnerContainer}>
        <Accordion
          className={mobileMenuClasses.menuItem}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1bh-header"
          >
            <Typography className={mobileMenuClasses.heading}>Organizer</Typography>
            <Typography className={mobileMenuClasses.secondaryHeading}>
              Manage your Organization
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width="100%">
              <Suspense fallback={<LoadingPlaceholder disableShrink isLoading delay={200} />}>
                <ChooseOrganizerMenu handleMobileMenuClose={handleMobileMenuClose} />
              </Suspense>
              <ListSubheader component="div" id="nested-list-subheader" key={10}>
                <Divider />
              </ListSubheader>
              <MenuItem
                component={NavLink}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerApp_Events', { organizerSlug })}
              >
                Events
              </MenuItem>
              {canViewAnalytics && (
                <MenuItem
                  component={NavLink}
                  onClick={handleMobileMenuClose}
                  style={({ isActive }): React.CSSProperties => ({
                    color: 'inherit',
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                  to={AppRouteService.getRelativeUrl('OrganizerApp_Analytics', { organizerSlug })}
                >
                  Analytics
                </MenuItem>
              )}
              <MenuItem
                component={NavLink}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerApp_Messages', { organizerSlug })}
              >
                Messages
              </MenuItem>
              <MenuItem
                component={NavLink}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerApp_Team', { organizerSlug })}
              >
                Team
              </MenuItem>
              <MenuItem
                component={NavLink}
                key={4}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerApp_Series', { organizerSlug })}
              >
                Series
              </MenuItem>
              {canEditBilling && (
                <MenuItem
                  component={NavLink}
                  key={5}
                  onClick={handleMobileMenuClose}
                  style={({ isActive }): React.CSSProperties => ({
                    color: 'inherit',
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                  to={AppRouteService.getRelativeUrl('OrganizerApp_Billing', { organizerSlug })}
                >
                  Billing
                </MenuItem>
              )}
              {canEditProfile && (
                <DisableToggle showLoadingWhileDisabled disabled={!editOrganizerProfileQueryRef}>
                  <MenuItem onClick={handleDialogOpen}>Edit Profile</MenuItem>
                </DisableToggle>
              )}
              <MenuListSubheader>Public</MenuListSubheader>
              <MenuItem
                component={NavLink}
                key={3}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerProfile', { organizerSlug })}
              >
                Organizer Profile
              </MenuItem>
              <MenuItem
                component={NavLink}
                key={3}
                onClick={handleMobileMenuClose}
                style={({ isActive }): React.CSSProperties => ({
                  color: 'inherit',
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
                to={AppRouteService.getRelativeUrl('OrganizerProfile_OnlineCourses', {
                  organizerSlug,
                })}
              >
                Online Courses
              </MenuItem>
            </Box>
          </AccordionDetails>
        </Accordion>

        <ProfileAccordion
          expanded={expanded}
          handleChange={handleChange}
          handleMobileMenuClose={handleMobileMenuClose}
        />
      </Box>
      <Box className={authContainerClasses.authContainer}>
        <Box alignItems="center" display="flex" justifyContent="center">
          <NoUnderlineRouterLink to={AppRouteService.getRelativeUrl('Home')}>
            <Button color="primary" size="medium" variant="outlined">
              Switch to Cycling
            </Button>
          </NoUnderlineRouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizerNavigationMobile;
