import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import { useNavigate } from 'react-router';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/browser';
import cuid from 'cuid';
import { detectIncognito } from 'detectincognitojs';
import {
  AuthError,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  linkWithCredential,
  linkWithPopup,
  OAuthCredential,
  onIdTokenChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  unlink,
  updatePassword as firebaseUpdatePassword,
  updateProfile as firebaseUpdateProfile,
  User,
  UserCredential,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { parse } from 'postgres-array';
import * as R from 'ramda';
import { fetchQuery, graphql, RecordSourceProxy } from 'relay-runtime';

import { AuthProviderCoachScheduledForUpcomingClinicsQuery } from './__generated__/AuthProviderCoachScheduledForUpcomingClinicsQuery.graphql';
import {
  AuthProviderQuery,
  AuthProviderQueryResponse,
} from './__generated__/AuthProviderQuery.graphql';
import { AuthProviderUpcomingCoachLessonsQuery } from './__generated__/AuthProviderUpcomingCoachLessonsQuery.graphql';
import { AuthProviderUpcomingEventsWithRegistrationsQuery } from './__generated__/AuthProviderUpcomingEventsWithRegistrationsQuery.graphql';

import { useIdentifyUserForAnalytics } from '@/analytics/identifyUserForAnalytics';
import { useTrackSentryError } from '@/analytics/sentry';
import { NON_EXISTENT_CUID } from '@/const';
import {
  useGetAuthRedirectURLGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsLoginDialogOpenGlobal,
  useSetIsLoginSignupDialogBlockingGlobal,
  useSetIsReAuthDialogOpenGlobal,
  useSetIsSignupDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import ENV from '@/env';
import { RegistrationStatusEnum, RoleOrganizerEnum } from '@/graphql/__generated__/graphql';
import {
  dateFromFloatingDateString,
  getCurrentTimezone,
  readableDate,
} from '@/lib/date-helpers/date-utils';
import { getEventName } from '@/lib/event-info-utils';
import {
  createUserCF,
  Firebase,
  firebaseAuth,
  formatProviderName,
  getProviderByProviderName,
  getStripeAccountBalanceCF,
  ProviderNames,
  syncCustomClaimsCF,
  updateCourierProfileCF,
  updateStreamUserCF,
  // sendSignInWithEmailLinkCF,
} from '@/lib/firebase';
import { validateRedirectUrl } from '@/lib/path-helpers';
import { arrayToCommaAndString } from '@/lib/string-utils';
import { hasAccountBalance } from '@/lib/stripe-utils';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { AuthProvider_updateUserEmail_Mutation } from '@/providers/__generated__/AuthProvider_updateUserEmail_Mutation.graphql';
import { LOCAL_AUTH_STORAGE_KEY } from '@/relay/RelayEnvironment';
import { AppRouteService } from '@/routes/RouteService';

const LOG_IN_SNACKBAR_MESSAGE = <>You are now logged in. Have fun!</>;

/**
 * Update users email
 */
interface UseUpdateUserEmailMutationProps {
  newUser: User;
  nodeId: string;
  silent?: boolean;
}
const useUpdateUserEmailMutation = (): {
  isUpdateUserEmailMutationInFlight: boolean;
  updateUserEmailMutation: (data: UseUpdateUserEmailMutationProps) => void;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    commitMutation,
    isUpdateUserEmailMutationInFlight,
  ] = useMutation<AuthProvider_updateUserEmail_Mutation>(
    graphql`
      mutation AuthProvider_updateUserEmail_Mutation($userId: String!, $email: String!) {
        update_user(
          where: { id: { _eq: $userId } }
          _set: { lastSavedAt: "now()", email: $email }
        ) {
          returning {
            email
          }
        }
      }
    `,
  );
  const updateUserEmailMutation = (data: UseUpdateUserEmailMutationProps): void => {
    const { newUser, nodeId, silent } = data;
    if (!newUser.email) {
      enqueueSnackbar('Please log in or refresh the page.', { variant: 'error' });
      return;
    }
    const variables = {
      userId: newUser.uid,
      email: newUser.email,
    };
    commitMutation({
      onCompleted: (): void => {
        if (!silent) {
          enqueueSnackbar('Saved!', { variant: 'success' });
        }
      },
      variables,
      optimisticUpdater: (store: RecordSourceProxy) => {
        const userRecord = store.get(nodeId);
        if (!userRecord) {
          return;
        }
        userRecord.setValue(newUser.email, 'email');
      },
    });
  };
  return {
    updateUserEmailMutation,
    isUpdateUserEmailMutationInFlight,
  };
};

const UPCOMING_COACH_LESSONS_QUERY = graphql`
  query AuthProviderUpcomingCoachLessonsQuery($coachCuid: String!) {
    coachCustomerLessonDate_connection(
      where: {
        _and: [
          { coachCustomerLesson: { coachCuid: { _eq: $coachCuid } } }
          { startDate: { _gte: "now()" } }
        ]
      }
    ) {
      edges {
        node {
          startDate
        }
      }
    }
  }
`;
const COACHING_UPCOMING_EVENTS_WITH_REGISTRATIONS_QUERY = graphql`
  query AuthProviderUpcomingEventsWithRegistrationsQuery($userId: String!) {
    registration_connection(
      where: {
        _and: [
          { clinic: { clinicCoaches: { coach: { userId: { _eq: $userId } } } } }
          { clinic: { clinicDays: { startTime: { _gte: "now()" } } } }
        ]
      }
      distinct_on: [eventCuid]
    ) {
      edges {
        node {
          status
          event {
            occurrenceLabel
            eventMetadata {
              name
            }
          }
        }
      }
    }
  }
`;
const COACH_SCHEDULED_FOR_UPCOMING_CLINICS_QUERY = graphql`
  query AuthProviderCoachScheduledForUpcomingClinicsQuery($userId: String!) {
    clinic_connection(
      where: {
        _and: [
          { clinicCoaches: { coach: { userId: { _eq: $userId } } } }
          { clinicDays: { startTime: { _gte: "now()" } } }
          { event: { deleted_at: { _is_null: true } } }
          { event: { publishedAt: { _is_null: false } } }
        ]
      }
    ) {
      edges {
        node {
          event {
            occurrenceLabel
            eventMetadata {
              name
            }
          }
        }
      }
    }
  }
`;
const AuthQuery = graphql`
  query AuthProviderQuery($userId: String!) {
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          id
          timezone
          isAdmin
          isCoach
          isCreator
          slug
          email
          firstName
          lastName
          idealRide
          cyclingGoal
          dob
          phone
          currency
          profilePicUrl
          showSetupCoach
          profilePicUrl
          selectedOrganizerSlug
          stripeConnectId
          stripeIdentityVerificationStatus
          stripeIdentityVerificationError
          stripeIdentityVerificationSessionId
          userAddresses(where: { isDefault: { _eq: true } }) {
            isDefault
            city
            state
            country
            zipCode
            countryCode
          }
          stripeConnectAccount {
            accountId
            ownerUserId
            chargesEnabled
            payoutsEnabled
          }
          coach {
            id
            cuid
            currencyCode
            allowRequests
            showProfileHelpBanner
            coachLocations(limit: 1) {
              timezone
            }
            coachDisciplines(limit: 1) {
              name
              skills
            }
          }
          userSavedEvents(order_by: { created_at: asc }) {
            id
            cuid
            eventCuid
          }
          userOrganizerRoles(
            where: { organizer: { deleted_at: { _is_null: true } } }
            order_by: [{ organizer: { created_at: asc } }]
          ) {
            organizerCuid
            role
            organizer {
              id
              cuid
              name
              slug
              showOnboarding
              events(
                where: {
                  deleted_at: { _is_null: true }
                  clinics: { clinicCoaches: { coach: { userId: { _eq: $userId } } } }
                }
              ) {
                cuid
                eventMetadata {
                  slug
                }
              }
            }
          }
          # For analytics
          registrations {
            status
            for
            eventCuid
            rideCuid
            clinicCuid
          }
          userRidingInterests {
            disciplineName
            focus
            hasAbilityGoal
            abilityLevel
            abilityLevelGoal
          }
        }
      }
    }
  }
`;
interface AuthCredential extends OAuthCredential {
  a: string;
  c: string;
}
interface AuthErrorProps extends AuthError {
  credential: AuthCredential;
  email: string;
  isSignup?: boolean;
}
export interface EmailPasswordProps {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}
export type AuthUser = NonNullable<
  AuthProviderQueryResponse['user_connection']
>['edges'][0]['node'];

export interface AuthContextProps {
  deleteUser(): Promise<void>;
  fetchUser(refreshVariables: { userId: string }): void;
  firebaseError: { errorCode: string; errorMessage: string | ReactNode } | null;
  firebaseUser: User | null | undefined;
  isInitializing: boolean;
  isLoading: boolean;
  isLoggedIn: boolean;
  loginSignupWithProvider(providerName: string, shouldRedirect: boolean): Promise<void>;
  loginWithEmail({
    email,
    password,
  }: EmailPasswordProps & {
    postLoginFunction?: () => void;
    shouldRedirect: boolean;
  }): Promise<void>;
  // loginWithEmailLink: (currentUrl: string, continueUrl: string) => Promise<void>;
  loginWithRedirect(): Promise<void>;
  logout(): Promise<void>;
  manualLinkProvider(providerId: string): Promise<void>;
  sendResetPassword(email: string): Promise<void>;
  sendVerifyEmail({
    continueUrl,
    showSuccessToast,
  }: {
    continueUrl: string | null;
    showSuccessToast?: boolean;
  }): Promise<void>;
  setFirebaseError: Dispatch<SetStateAction<AuthContextProps['firebaseError']>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<AuthUser | null>>;
  signupWithEmail({ email, firstName, lastName, password }: EmailPasswordProps): Promise<void>;
  // resetPassword(oobCode: string, newPassword: string): Promise<void>;
  unLinkProvider(providerId: string): Promise<void>;
  updateEmail(newEmail: string): Promise<void>;
  updatePassword(email: string, password: string, newPassword: string): Promise<void>;
  updateProfile(profile: { displayName?: string | null; photoURL?: string | null }): Promise<void>;
  user: AuthUser | null;
  // sendSignInWithEmailLink: (email: string) => Promise<void>;
}

// Provider hook that creates Auth object and handles state
const useProvideAuth = (): AuthContextProps => {
  const navigate = useNavigate();
  // const location = useLocation();
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const trackSentryError = useTrackSentryError();
  const isSmallScreenDown = useIsSmallScreenDown();
  const authRedirectURLGlobal = useGetAuthRedirectURLGlobal();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const setIsReAuthDialogOpenGlobal = useSetIsReAuthDialogOpenGlobal();
  const setIsLoginSignupDialogBlockingGlobal = useSetIsLoginSignupDialogBlockingGlobal();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setInitializing] = useState(true);
  const [firebaseError, setFirebaseError] = useState<AuthContextProps['firebaseError']>(null);
  // undefined before firebase onIdTokenChanged, null when logged out, User when logged in
  const [firebaseUser, setFirebaseUser] = useState<User | null | undefined>();
  const [user, setUser] = useState<AuthUser | null>(null);
  // const [oneTapIdToken, setOneTapIdToken] = useState<null | string>(null);

  const { updateUserEmailMutation } = useUpdateUserEmailMutation();

  const isLoggedIn = Boolean(firebaseUser) && Boolean(user);

  /**
   * Get user from DB
   * Set the Auth object's "user" to that data.
   * Initializing gets set to false when we either have the user record, or
   * the user is logged out above
   */
  const fetchUser = useCallback((refreshVariables: { userId: string }) => {
    // fetchQuery will fetch the query and write the data to the Relay store.
    fetchQuery<AuthProviderQuery>(environment, AuthQuery, refreshVariables).subscribe({
      next: async (data) => {
        const userFromQuery = data?.user_connection?.edges?.[0]?.node;
        const { currentUser } = firebaseAuth;
        if (!userFromQuery) {
          return;
        }

        // Make sure our custom claims are up to date
        const idTokenResult = await currentUser?.getIdTokenResult();
        // Parse postgres array
        const claimOrgCuids = parse(idTokenResult?.claims?.['org-cuids'] ?? '{}');
        const dbOrgCuids =
          userFromQuery?.userOrganizerRoles?.map((role) => role.organizerCuid) ?? [];
        // DB is the source of truth
        const idsMissingFromClaims = R.difference(dbOrgCuids, claimOrgCuids);
        if (idsMissingFromClaims.length) {
          await syncCustomClaimsCF();
          await currentUser?.getIdToken(true);
        }

        setUser(userFromQuery);
        initializeLoggedInUser();

        // Make sure our email is always in-sync with firebase
        if (currentUser?.email && userFromQuery.email !== currentUser.email) {
          updateUserEmailMutation({ newUser: currentUser, nodeId: userFromQuery.id, silent: true });
          // Updates email in CF from context.auth
          updateStreamUserCF({
            fullName: `${userFromQuery.firstName} ${userFromQuery.lastName}`,
            firstName: userFromQuery.firstName ?? '',
            lastName: userFromQuery.lastName ?? '',
            image: userFromQuery.profilePicUrl,
          }).then();
          updateCourierProfileCF({
            firstName: userFromQuery.firstName ?? '',
            lastName: userFromQuery.lastName ?? '',
            profilePicUrl: userFromQuery.profilePicUrl,
          }).then();
        }
      },
      error: logoutWithError,
    });
  }, []);

  // /////////////////////////////////////////////////////////////
  // Auth Utilities
  // /////////////////////////////////////////////////////////////

  /**
   * Set the user across the app
   */
  const initializeLoggedInUser = (): void => {
    setInitializing(false);
    setIsLoading(false);
  };
  const initializeLoggedOutUser = (): void => {
    setUser(null);
    setFirebaseUser(null);
    setInitializing(false);
    setIsLoading(false);
  };
  const logoutWithError = async (err: Error): Promise<void> => {
    trackSentryError(err);
    initializeLoggedOutUser();
    await logout();
    enqueueSnackbar(
      'Please reload the page and try again. If problem persists, we made a mistake and would love to hear from you so we can fix it.',
      { persist: true, variant: 'error' },
    );
  };

  /**
   * Close all auth related dialogs
   */
  const closeAuthDialogs = (): void => {
    setIsLoginDialogOpenGlobal(false);
    setIsSignupDialogOpenGlobal(false);
    setIsReAuthDialogOpenGlobal(false);
    setIsLoginSignupDialogBlockingGlobal(false);
  };
  /**
   * Redirect user after login or signup. Used when we are on mobile and cant open
   * provider sign in popup
   */
  const handleRedirectAfterAuth = (): void => {
    if (authRedirectURLGlobal) {
      navigate(validateRedirectUrl(authRedirectURLGlobal, AppRouteService.getRelativeUrl('Home')), {
        replace: true,
      });
      setAuthRedirectURLGlobal(null);
      return;
    }
    navigate(AppRouteService.getRelativeUrl('Home'), { replace: true });
  };

  const addUserToDatabaseFromProvider = async (userCredential: UserCredential): Promise<void> => {
    // We updated emailVerified in onCreate cloud function
    // Wait for it propagate
    const { Facebook } = Firebase.ProviderNames;
    if (firebaseAuth.currentUser?.providerData?.[0]?.providerId === Facebook) {
      while (!firebaseAuth.currentUser?.emailVerified) {
        // eslint-disable-next-line no-await-in-loop
        await firebaseAuth.currentUser?.reload();
      }
    }
    if (userCredential?.user) {
      const { displayName, photoURL, providerData } = userCredential.user;
      // Get name
      let firstName = displayName ?? 'Unknown';
      let lastName = 'Cyclist';
      if (displayName?.includes(' ')) {
        // Split fullName into first and last
        [firstName] = displayName.split(' ');
        // If user has multiple last names
        lastName = displayName.split(' ').slice(1).join(' ');
      }

      // Create profile pic
      let profilePicUrl = photoURL;
      if (profilePicUrl && providerData?.[0]?.providerId === 'facebook.com') {
        profilePicUrl = `${profilePicUrl}?height=128&width=128`;
      }

      // Create user in the DB
      try {
        await createUserCF({
          user: { uid: userCredential.user.uid, email: userCredential.user.email!, profilePicUrl },
          firstName,
          lastName,
          timezone: getCurrentTimezone(),
        });
      } catch (err) {
        trackSentryError(err);
        await firebaseAuth.currentUser?.delete();
        enqueueSnackbar('Error creating account. Please reload the page and try again.', {
          persist: true,
          variant: 'error',
        });
      }
    }
  };

  /**
   * Most firebase errors are the same, we can use this function as a catch-all for firebase
   * Auth errors
   */
  const handleFirebaseError = async (error: AuthErrorProps): Promise<void> => {
    trackSentryError(error);
    // Auth Failed. We have to repeat redirectToLinkProvider because there are many other errors,
    // these are just the wants we want to link on.
    const { credential: errorCredential, email, message } = error;
    const errorCode = (error.code ?? '').toLowerCase();
    // In case we get an error that doesn't require an email. Short Circuit
    if (!email) {
      setFirebaseError({ errorCode, errorMessage: `${message} Please try again.` });
      return;
    }
    try {
      const providers = await fetchSignInMethodsForEmail(firebaseAuth, email);
      if (errorCode === 'auth/user-not-found' || !providers[0]) {
        // User has not yet signed up for an account
        setFirebaseError({
          errorCode,
          errorMessage: (
            <>
              <Typography gutterBottom variant="body2">
                There is no account associated with this email, which is great because signing up is
                super easy!
              </Typography>
            </>
          ),
        });
        return;
      }
      if (
        errorCode === 'auth/wrong-password' &&
        providers.includes(Firebase.ProviderNames.Password)
      ) {
        // Wrong password catches invalid password AND an email being associated with
        // a different provider.
        // Already has a password account. Wrong password
        // TODO: Add login attempts. After a certain number add a button linking to Forgot Password
        //  i.e. It's ok if you forgot your password, resetting it is super easy. 'Forgot Password'
        setFirebaseError({
          errorCode,
          errorMessage: `Invalid Password`,
        });
        return;
      }
      if (
        errorCode === 'auth/email-already-in-use' ||
        errorCode === 'auth/account-exists-with-different-credential' ||
        (errorCode === 'auth/wrong-password' &&
          !providers.includes(Firebase.ProviderNames.Password))
      ) {
        const readableProviderList = arrayToCommaAndString(providers.map(formatProviderName));
        setFirebaseError({
          errorCode,
          errorMessage: (
            <Typography variant="body2">
              There is already a <b>{readableProviderList}</b> account associated with this email.
              {!error?.isSignup && (
                <>
                  {' '}
                  Log in and then you can link a{' '}
                  <b>
                    {errorCredential?.providerId
                      ? formatProviderName(errorCredential.providerId)
                      : 'Email/Password'}
                  </b>{' '}
                  in your <b>Account Settings</b>.
                </>
              )}
            </Typography>
          ),
        });
        return;
      }
      if (errorCode === 'auth/provider-already-linked') {
        // Trying to link 'Sign in with Email Link' to 'Email/Password'
        // Let the user know they need to do this from the profile page.
        enqueueSnackbar('Please go to your profile page to set a password for this account.', {
          variant: 'error',
        });
        return;
      }
      // Catch all
      setFirebaseError({ errorCode, errorMessage: `${message} Please try again.` });
    } catch (err) {
      trackSentryError(err);
      // For things like no internet, etc
      setFirebaseError({ errorCode, errorMessage: `${message} Please try again.` });
    } finally {
      setIsLoading(false);
    }
  };

  // ////////////////////////////////////////////////////////////////////////////
  // Log in
  // ////////////////////////////////////////////////////////////////////////////
  /**
   * Firebase login with email and password.
   * @param payload {object} - contains email and password
   */
  const loginWithEmail = async ({
    email,
    password,
    postLoginFunction,
    shouldRedirect,
  }: EmailPasswordProps & {
    postLoginFunction?: () => void;
    shouldRedirect: boolean;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      if (userCredential.user === null) {
        enqueueSnackbar('No result from provider sign in. Did you hit the back button?', {
          variant: 'error',
        });
        setIsLoading(false);
        return;
      }
      fetchUser({ userId: userCredential.user.uid });
      // Success
      closeAuthDialogs();
      setIsLoading(false);
      enqueueSnackbar(LOG_IN_SNACKBAR_MESSAGE, { variant: 'success' });
      if (postLoginFunction) {
        postLoginFunction();
      }
      if (shouldRedirect && authRedirectURLGlobal) {
        handleRedirectAfterAuth();
      }
    } catch (err) {
      // Set email for fetchSignInMethodsForEmail
      err.email = email;
      await handleFirebaseError(err);
      setIsLoading(false);
    }
  };

  /**
   * Firebase only has one function for both logging in AND signing up.
   * We treat both the same. When the user signs up and goes to the dashboard
   * for the first time they will have a different experience based on
   * their 'onboarding' stage
   */
  const loginSignupWithProvider = async (
    providerName: string,
    shouldRedirect: boolean,
    postLoginFunction?: () => void,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const provider = getProviderByProviderName(providerName);
      // Popup doesn't work on mobile or incognito mode
      let isIncognito = false;
      try {
        const browserMode = await detectIncognito();
        isIncognito = browserMode.isPrivate;
      } catch (err) {
        trackSentryError(err, { notify: false });
      }
      if (isSmallScreenDown || isIncognito) {
        // This breaks the flow. We get redirected off the page.
        // On return, we will catch the "PendingRedirectKey" in sessionStorage and continue from there
        await signInWithRedirect(firebaseAuth, provider);
        return;
      }
      const userCredential = await signInWithPopup(firebaseAuth, provider);
      if (userCredential.user === null) {
        enqueueSnackbar('No result from provider sign in. Did you hit the back button?', {
          variant: 'error',
        });
        setIsLoading(false);
        return;
      }
      // Firebase automatically creates a new user whether we come from login or sign up
      // We can use this flag to see whether the user existed or not so we can create
      // a new user in the DB
      if (
        userCredential.additionalUserInfo?.isNewUser ||
        // eslint-disable-next-line no-underscore-dangle
        userCredential._tokenResponse?.isNewUser
      ) {
        await addUserToDatabaseFromProvider(userCredential);
        // Re-Fetch here because we would have tried to fetch from db on login with no user.
        fetchUser({ userId: userCredential.user.uid });
      }
      // Success
      setIsLoading(false);
      closeAuthDialogs();
      enqueueSnackbar(LOG_IN_SNACKBAR_MESSAGE, { variant: 'success' });
      if (postLoginFunction) {
        postLoginFunction();
      }
      if (shouldRedirect && authRedirectURLGlobal) {
        handleRedirectAfterAuth();
      }
    } catch (error) {
      await handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * Continuation of above.
   * On mobile the user will return to the site and be logged in if they we redirected for log in
   */
  const loginWithRedirect = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const userCredential = await getRedirectResult(firebaseAuth);
      // getRedirectResult returns {user: null} if we try to call it without a pending redirect
      if (!userCredential?.user) {
        enqueueSnackbar('No result from provider sign in. Did you hit the back button?', {
          variant: 'error',
        });
        setIsLoading(false);
        return;
      }
      // Firebase automatically creates a new user whether we come from login or sign up
      // We can use this flag to see whether the user existed or not so we can create
      // a new user in the DB
      if (
        userCredential?.additionalUserInfo?.isNewUser ||
        // eslint-disable-next-line no-underscore-dangle
        userCredential?._tokenResponse?.isNewUser
      ) {
        await addUserToDatabaseFromProvider(userCredential);
        fetchUser({ userId: userCredential.user.uid });
      }
      // Success
      setIsLoading(false);
      closeAuthDialogs();
      enqueueSnackbar(LOG_IN_SNACKBAR_MESSAGE, { variant: 'success' });
      handleRedirectAfterAuth();
    } catch (error) {
      // Login Failed
      await handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO
  // /**
  //  * This gets called when a user is coming into the app from clicking the link in their email.
  //  * If the user is completing the flow from the same device we will get it their email from
  //  * localStorage, otherwise prompt them for it. This is a security precaution.
  //  *
  //  * @param currentUrl {string} - link that was generated from generateSignInWithEmailLink. It
  //  * is the url the user is currently on.
  //  * @param continueUrl {string} - url to redirect to
  //  */
  // const loginWithEmailLink = async (currentUrl: string, continueUrl: string): Promise<void> => {
  //   setLoading(true);
  //   // Confirm the link is a sign-in with email link.
  //   const isSignInWithEmailLink = await firebase.Auth().isSignInWithEmailLink(currentUrl);
  //   if (isSignInWithEmailLink) {
  //     // Additional state parameters can also be passed via URL.
  //     // This can be used to continue the user's intended action before triggering
  //     // the sign-in operation.
  //     // Get the email if available. This should be available if the user completes
  //     // the flow on the same device where they started it.
  //     let email = window.localStorage.getItem('emailForSignIn') ?? '';
  //     if (!email) {
  //       // User opened the link on a different device. To prevent session fixation
  //       // attacks, ask the user to provide the associated email again. For example:
  //
  //       // eslint-disable-next-line no-alert
  //       email = window.prompt('Please provide your email for confirmation') ?? '';
  //     }
  //     // The client SDK will parse the code from the link for you.
  //     try {
  //       await firebase.Auth().signInWithEmailLink(email, currentUrl);
  //       // Clear email from storage.
  //       window.localStorage.removeItem('emailForSignIn');
  //       history.replace(continueUrl);
  //       setLoading(false);
  //     } catch (error) {
  //       // Some error occurred, you can inspect the code: error.code
  //       // Common errors could be invalid email and invalid or expired OTPs.
  //       setLoading(false);
  //       await handleFirebaseError(error);
  //     }
  //   } else {
  //     enqueueSnackbar('Invalid email link. Please log in again to get a new one.', {
  //       variant: 'error',
  //     });
  //     setLoading(false);
  //   }
  // };

  // ////////////////////////////////////////////////////////////////////////////
  // Sign up
  // ////////////////////////////////////////////////////////////////////////////
  /**
   * Firebase signup with email and password
   */
  const signupWithEmail = async ({
    email,
    firstName,
    lastName,
    password,
  }: EmailPasswordProps): Promise<void> => {
    try {
      setIsLoading(true);
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      if (!userCredential?.user) {
        enqueueSnackbar('No result from sign in. Please try again.', {
          variant: 'error',
        });
        setIsLoading(false);
        return;
      }
      // Send verify email
      sendEmailVerification(userCredential.user, {
        // After email is verified, the user will be give the ability to go back to this url
        url: `${AppRouteService.getDomain()}${authRedirectURLGlobal ?? ''}`,
        handleCodeInApp: false,
      }).then();
      // Create user in the DB
      await createUserCF({
        user: { uid: userCredential.user.uid, email: userCredential.user.email! },
        firstName,
        lastName,
        timezone: getCurrentTimezone(),
      });
      // Success
      fetchUser({ userId: userCredential.user.uid });
      setIsLoading(false);
      closeAuthDialogs();
      enqueueSnackbar(LOG_IN_SNACKBAR_MESSAGE, { variant: 'success' });
      navigate(AppRouteService.getRelativeUrl('EmailVerificationRequired'), { replace: true });
    } catch (error) {
      setIsLoading(false);
      // Set email for fetchSignInMethodsForEmail
      error.email = email;
      error.isSignup = true;
      await handleFirebaseError(error);
    }
  };

  // /////////////////////////////////////////////////////////////
  // Logout
  // /////////////////////////////////////////////////////////////
  /**
   * Log out of Reggy.
   */
  const logout = async (): Promise<void> => {
    const { currentUser } = firebaseAuth;
    try {
      if (currentUser) {
        await signOut(firebaseAuth);
        // Success
        navigate(AppRouteService.getRelativeUrl('Home'));
        enqueueSnackbar(
          <>
            You are now logged out. <br />
            Thanks for coming :)
          </>,
          { variant: 'success' },
        );
      }
    } catch (error) {
      await handleFirebaseError(error);
    }
  };

  // /////////////////////////////////////////////////////////////
  // Link Providers
  // /////////////////////////////////////////////////////////////
  /**
   * We are linking manually and we create a new credential
   * based on if we are linking email/password or OAuth provider
   * @param providerId {string} - i.e. 'password', 'google.com'
   // * @param email {string} - email we are linking
   // * @param password {string} password we are linking
   */
  const manualLinkProvider = async (providerId: ProviderNames): Promise<void> => {
    // check if we are trying to link an email account or a provider account
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      return;
    }
    let credentialToLink;
    let emailToLink: string | undefined;
    try {
      if (providerId === Firebase.ProviderNames.Password) {
        // Manually linking password account
        // credentialToLink = EmailAuthProvider.credential(email, password);
        // emailToLink = email;
      } else if (
        providerId === Firebase.ProviderNames.Apple ||
        providerId === Firebase.ProviderNames.Facebook ||
        providerId === Firebase.ProviderNames.Google
      ) {
        // Manually linking OAuth account
        const provider = getProviderByProviderName(providerId);
        const result = await linkWithPopup(currentUser, provider);
        // credentialToLink = result?.credential;
        // emailToLink = result?.user?.email;
        if (result.user) {
          await result.user.getIdToken(true);
          enqueueSnackbar(`${formatProviderName(providerId)} Linked!`, { variant: 'success' });
        } else {
          // Had trouble linking, refresh the page
          window.location.reload();
        }

        // TODO:
        // For now we only care about OAuth providers and we also don't care if emails are different
        return;
      }
      if (emailToLink !== currentUser?.email) {
        // Make sure we are trying to link to the original account
        // Otherwise log the user in but don't link the account
        enqueueSnackbar(
          `Looks like you logged in with a different account.
        We didn't link your accounts, but we did log you in!`,
          { variant: 'success' },
        );
        return;
      }
      if (credentialToLink) {
        await linkWithCredential(currentUser, credentialToLink);
        await currentUser.getIdToken(true);
        enqueueSnackbar(`${formatProviderName(providerId)} Linked!`, { variant: 'success' });
      } else {
        throw Error('No providerToLink OR providerName');
      }
    } catch (err) {
      trackSentryError(err);
      enqueueSnackbar(`${err.message}`);
    }
  };

  /**
   * Unlink a given provider from the current user.
   * Don't let the user unlink all of their providers because they wont be able to log in
   * @param providerId {string} - password, google, facebook, apple, etc
   */
  const unLinkProvider = async (providerId: string): Promise<void> => {
    const { currentUser } = firebaseAuth;
    if (currentUser) {
      try {
        // Firebase will unlink all providers if we let it. Don't let it.
        if (currentUser.providerData.length <= 1) {
          enqueueSnackbar(`Can't unlink your only provider.`, { variant: 'error' });
          return;
        }
        const newUser = await unlink(currentUser, providerId);
        await newUser.getIdToken(true);
        enqueueSnackbar(
          `${formatProviderName(providerId)} disconnected. You can re-connect at anytime.`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        trackSentryError(err);
        enqueueSnackbar(err.message);
      }
    } else {
      enqueueSnackbar('You must be logged in to unlink a provider!', { variant: 'error' });
    }
  };

  // /////////////////////////////////////////////////////////////
  // Auth Emails
  // /////////////////////////////////////////////////////////////
  /**
   * Send a post request to an endpoint that generates and sends an email verification link
   * to the specified email
   */
  const sendVerifyEmail = async ({
    continueUrl,
    showSuccessToast = false,
  }: {
    continueUrl: string | null;
    showSuccessToast?: boolean;
  }): Promise<void> => {
    // Use currentUser here because we call sendVerifyEmail before firebaseUser gets set
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      enqueueSnackbar('You must be logged in to send a verification email.', { variant: 'error' });
      return;
    }
    if (currentUser.emailVerified) {
      enqueueSnackbar('Email is already verified.', { variant: 'error' });
      return;
    }
    try {
      sendEmailVerification(currentUser, {
        // After email is verified, the user will be give the ability to go back to this url
        url: `${AppRouteService.getDomain()}${continueUrl ?? ''}`,
        handleCodeInApp: false,
      }).then();
      if (showSuccessToast) {
        // We dont show this when sending on sign up, we do show when sending manually
        enqueueSnackbar(
          'Email Sent! By the time you check your email, you should have a message from us!',
          { variant: 'success' },
        );
      }
    } catch (err) {
      trackSentryError(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  /**
   * When we return from the users email we get an oobCode that is used to verify the users email
   * We apply this code to the current user so that their email is verified
   * @param oobCode {string} - oobCode received from cloud function from generateEmailVerificationCode
   * @param continueUrl {string} - redirect url
   */
  // const verifyEmail = async ({
  //   continueUrl,
  //   oobCode,
  // }: {
  //   oobCode: string;
  //   continueUrl: string;
  // }): Promise<void> => {
  //   try {
  //     await applyActionCode(oobCode);
  //     enqueueSnackbar('Email Successfully Verified!', { variant: 'success' });
  //     navigate(continueUrl);
  //   } catch (error) {
  //     enqueueSnackbar(error.message, { variant: 'error' });
  //   }
  // };

  /**
   * Send a reset password email that links the user to the change password page so
   * they can reset their password. We generate a link using a cloud function using their email
   */
  const sendResetPassword = async (email: string): Promise<void> => {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(firebaseAuth, email);
    } catch (error) {
      // const { message } = error;
      // if (error.message.includes('EMAIL_NOT_FOUND')) {
      //   return;
      //   message = (
      //     <>
      //       No account associated with <b>{email}</b>.
      //     </>
      //   );
      // }
      // setFirebaseError({ errorCode: error.code, errorMessage: message });
    } finally {
      enqueueSnackbar(
        'Email Sent! By the time you check your email, you should have a message from us!',
        { variant: 'success' },
      );
      setIsLoading(false);
    }
  };

  /**
   * Future: Used when we have custom email templates
   * User gets directed to "Reset Password" page, extract oobCode from url and call this
   * function on submit
   */
  // const resetPassword = async (oobCode: string, newPassword: string): Promise<void> => {
  //   try {
  //     // Verify the password reset code is valid.
  //     setIsLoading(true);
  //     const email = await verifyPasswordResetCode(oobCode);
  //     await confirmPasswordReset(oobCode, newPassword);
  //
  //     // Password reset has been confirmed and new password updated.
  //     // Sign the user in
  //     await signInWithEmailAndPassword(email, newPassword);
  //     // Loading gets set to false when the authState changes
  //     navigate(AppRouteService.getRelativeUrl('Home'), { replace: true });
  //     enqueueSnackbar('Password Reset Successfully!', { variant: 'success' });
  //   } catch (err) {
  //     trackSentryError(err);
  //     setIsLoading(false);
  //     setFirebaseError({ errorCode: err.code, errorMessage: err.message });
  //   }
  // };

  // TODO:
  // const sendSignInWithEmailLink = async (email: string): Promise<void> => {
  //   try {
  //     await sendSignInWithEmailLinkCF({ email });
  //     // The link was successfully sent. Inform the user.
  //     // Save the email locally so you don't need to ask the user for it again
  //     // if they open the link on the same device.
  //     localStorage.setItem('emailForSignIn', email);
  //   } catch (error) {
  //     // No feedback on error
  //   }
  //   enqueueSnackbar(
  //     `If there is an account associated with ${email} we will send you a link to Log in!`,
  //     { variant: 'success' },
  //   );
  // };

  // /////////////////////////////////////////////////////////////
  // Update User
  // /////////////////////////////////////////////////////////////

  const updateProfile = async (profile: {
    displayName?: string | null;
    photoURL?: string | null;
  }): Promise<void> => {
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      return;
    }
    await firebaseUpdateProfile(currentUser, profile);
  };

  const updateEmail = async (newEmail: string): Promise<void> => {
    const { currentUser } = firebaseAuth;
    if (!newEmail || !currentUser) {
      return;
    }
    try {
      await verifyBeforeUpdateEmail(currentUser, newEmail, {
        // After email is verified, the user will be give the ability to go back to this url
        url: AppRouteService.getAbsoluteUrl('User_AccountSettings'),
        handleCodeInApp: false,
      });
      enqueueSnackbar(
        `We sent an email to ${newEmail} for verification. Click the link in the email to confirm the change.`,
        { variant: 'success', autoHideDuration: 5000 },
      );
    } catch (err) {
      if (err.code.toLowerCase() === 'auth/requires-recent-login') {
        setIsReAuthDialogOpenGlobal(true);
        return;
      }
      throw err;
    }
  };

  const updatePassword = async (
    email: string,
    password: string,
    newPassword: string,
  ): Promise<void> => {
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      return;
    }
    try {
      const userCredential = EmailAuthProvider.credential(email, password);
      await reauthenticateWithCredential(currentUser, userCredential);
      await firebaseUpdatePassword(currentUser, newPassword);
      enqueueSnackbar(`Password successfully updated!`, { variant: 'success' });
    } catch (err) {
      if (err.code.toLowerCase() === 'auth/requires-recent-login') {
        setIsReAuthDialogOpenGlobal(true);
        return;
      }
      throw err;
    }
  };

  const deleteUser = async (): Promise<void> => {
    const { currentUser } = firebaseAuth;
    if (!currentUser || !user) {
      return;
    }
    try {
      // Check for coach unpaid out funds
      if (user.stripeConnectAccount?.accountId) {
        const balance = await getStripeAccountBalanceCF({
          accountId: user.stripeConnectAccount.accountId,
        });
        if (hasAccountBalance(balance.data)) {
          enqueueSnackbar(`Can't delete account. Your coach account has unpaid-out funds`, {
            variant: 'error',
          });
          return;
        }
      }

      // Check for organizations the user owns
      const organizers = (user.userOrganizerRoles ?? [])
        .filter((uor) => uor.role === RoleOrganizerEnum.Owner)
        .map((uor) => uor.organizer)
        .filter(Boolean);
      if (organizers.length) {
        enqueueSnackbar(
          `Can't delete account. Please delete the organizations you own first: ${arrayToCommaAndString(
            organizers.map((org) => org?.name ?? ''),
          )}`,
          {
            variant: 'error',
          },
        );
        return;
      }

      if (user.coach?.cuid) {
        // Check if there are any upcoming lessons
        const fetchUpcomingCoachLessons = (): Promise<string[] | undefined> => {
          return new Promise((resolve, reject) => {
            fetchQuery<AuthProviderUpcomingCoachLessonsQuery>(
              environment,
              UPCOMING_COACH_LESSONS_QUERY,
              {
                coachCuid: user.coach!.cuid,
              },
            ).subscribe({
              next: (data) => {
                resolve(
                  data.coachCustomerLessonDate_connection.edges.map(({ node }) =>
                    readableDate(dateFromFloatingDateString(node.startDate)),
                  ),
                );
              },
              error: (err: Error) => {
                reject(err);
              },
            });
          });
        };
        const upcomingCoachLessons = await fetchUpcomingCoachLessons();
        if (upcomingCoachLessons?.length) {
          enqueueSnackbar(
            `Can't delete account. You have upcoming private lessons on ${arrayToCommaAndString(
              upcomingCoachLessons,
            )}`,
            {
              variant: 'error',
            },
          );
          return;
        }

        // Check if there are any upcoming events with registrations that this user is coaching
        const fetchCoachingUpcomingEventsWithRegistrations = (): Promise<string[] | undefined> => {
          return new Promise((resolve, reject) => {
            fetchQuery<AuthProviderUpcomingEventsWithRegistrationsQuery>(
              environment,
              COACHING_UPCOMING_EVENTS_WITH_REGISTRATIONS_QUERY,
              {
                userId: currentUser.uid,
              },
            ).subscribe({
              next: (data) => {
                resolve(
                  data.registration_connection.edges
                    .filter(({ node }) => {
                      return node.status === RegistrationStatusEnum.Complete;
                      // We have a non-canceled registration
                    })
                    .map(({ node }) => getEventName(node.event)),
                );
              },
              error: (err: Error) => {
                reject(err);
              },
            });
          });
        };
        const coachingUpcomingEventsWithRegistrations = await fetchCoachingUpcomingEventsWithRegistrations();
        if (coachingUpcomingEventsWithRegistrations?.length) {
          enqueueSnackbar(
            `Can't delete account. You are coaching upcoming events ${arrayToCommaAndString(
              coachingUpcomingEventsWithRegistrations,
            )} that have registrations`,
            {
              variant: 'error',
            },
          );
          return;
        }

        // Check if the coach is schedule for upcoming clinics
        const fetchCoachScheduledForUpcomingClinics = (): Promise<string[] | undefined> => {
          return new Promise((resolve, reject) => {
            fetchQuery<AuthProviderCoachScheduledForUpcomingClinicsQuery>(
              environment,
              COACH_SCHEDULED_FOR_UPCOMING_CLINICS_QUERY,
              {
                userId: currentUser.uid,
              },
            ).subscribe({
              next: (data) => {
                resolve(data.clinic_connection.edges.map(({ node }) => getEventName(node.event)));
              },
              error: (err: Error) => {
                reject(err);
              },
            });
          });
        };
        const coachScheduledForUpcomingClinics = await fetchCoachScheduledForUpcomingClinics();
        if (coachScheduledForUpcomingClinics?.length) {
          enqueueSnackbar(
            `Can't delete account. You are scheduled to coach upcoming events ${arrayToCommaAndString(
              coachScheduledForUpcomingClinics,
            )}`,
            {
              variant: 'error',
            },
          );
          return;
        }
      }

      await currentUser.delete();
      navigate(AppRouteService.getRelativeUrl('Home'));
      enqueueSnackbar(`Account Successfully Deleted. Sorry to see you go :(`, {
        variant: 'success',
      });
    } catch (err) {
      if (err.code.toLowerCase() === 'auth/requires-recent-login') {
        setIsReAuthDialogOpenGlobal(true);
        return;
      }
      throw err;
    }
  };
  // // /////////////////////////////////////////////////////////////
  // // Effects
  // // /////////////////////////////////////////////////////////////

  // Important.
  // Not using this because we can get the user, but the token and cloud functions still wont work.
  // /**
  //  * "Optimistically" log the user in if they exist in the IndexDB.
  //  * This user will get overwritten once the onIdTokenChanged comes back the first
  //  * time
  //  */
  // useEffect(() => {
  //   (async (): Promise<void> => {
  //     const storageUser = await getUserFromIndexDB();
  //     if (storageUser) {
  //       setFirebaseUser(storageUser);
  //       fetchUser({ userId: storageUser.uid });
  //     }
  //   })();
  // }, []);

  // // Show one tap sign in
  // // Add <script src="https://accounts.google.com/gsi/client" async defer></script> when re-enabling
  // useEffect(() => {
  //   if (!window.google) {
  //     return;
  //   }
  //   if (!isInitializing && !isLoggedIn) {
  //     const handleCredentialResponse = (response: CredentialResponse): void => {
  //       setOneTapIdToken(response.credential);
  //     };
  //     window.google.accounts.id.initialize({
  //       client_id: ENV.GOOGLE_IDENTITY_API_KEY,
  //       callback: handleCredentialResponse,
  //       context: 'signin',
  //       native_callback: console.log,
  //       itp_support: true,
  //     });
  //     // Use this to hook into any interactions
  //     // google.accounts.id.prompt((notification) => {
  //     // });
  //     window.google.accounts.id.prompt((notification) => {
  //       console.log('notification is: ', notification.getMomentType());
  //       if (notification.isDisplayMoment()) {
  //         console.log('IS DISPLAY MOMENT');
  //       }
  //
  //       if (notification.isNotDisplayed()) {
  //         console.warn('one-tap did not show because:', notification.getNotDisplayedReason());
  //       }
  //       if (notification.isSkippedMoment()) {
  //         console.warn('one-tap skipped because:', notification.getSkippedReason());
  //       }
  //       if (notification.isDismissedMoment()) {
  //         console.warn('one-tap dismissed because:', notification.getDismissedReason());
  //         if (notification.getDismissedReason() !== 'credential_returned') {
  //         }
  //       }
  //     });
  //   } else {
  //     window.google.accounts.id.cancel();
  //   }
  // }, [isInitializing, isLoggedIn]);
  // // Grab
  // useEffect(() => {
  //   (async (): Promise<void> => {
  //     if (oneTapIdToken) {
  //       // Sign in with credential from the Google user.
  //       try {
  //         console.log('oneTapIdToken', oneTapIdToken);
  //         setIsLoading(true);
  //         const credential = GoogleAuthProvider.credential(oneTapIdToken);
  //         console.log('credential', credential);
  //
  //         const userCredential = await signInWithCredential(firebaseAuth, credential);
  //         console.log('userCredential', userCredential);
  //         if (
  //           userCredential.additionalUserInfo?.isNewUser ||
  //           // eslint-disable-next-line no-underscore-dangle
  //           userCredential._tokenResponse?.isNewUser
  //         ) {
  //           await addUserToDatabaseFromProvider(userCredential);
  //           // Re-Fetch here because we would have tried to fetch from db on login with no user.
  //           fetchUser({ userId: userCredential.user.uid });
  //         }
  //         setOneTapIdToken(null);
  //       } catch (err) {
  //         trackSentryError(err);
  //       }
  //     }
  //   })();
  // }, [oneTapIdToken]);

  // Subscribe to onIdTokenChanged on mount.
  // Listens for any changes in firebase Auth state
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, async (newFirebaseUser) => {
      if (ENV.IS_NODE_ENV_LOCAL) {
        // Clear local storage so we get a fresh token
        sessionStorage.setItem(LOCAL_AUTH_STORAGE_KEY, '');
      }
      if (newFirebaseUser) {
        // User is logged in
        Sentry.configureScope((scope) =>
          scope.setUser({ id: newFirebaseUser.uid, email: newFirebaseUser.email as string }),
        );
        setFirebaseUser({
          ...newFirebaseUser,
        });
        fetchUser({ userId: newFirebaseUser.uid });
      } else {
        // User is logged out
        Sentry.configureScope((scope) => scope.setUser({}));
        initializeLoggedOutUser();
      }
    });
    return (): void => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sign in with redirect (screen too small for pop up)
   * Check the session storage for a pending signInWithRedirect
   * loginWithRedirect gets the result of getRedirectResult which logs us in.
   */
  useEffect(() => {
    (async (): Promise<void> => {
      const { PendingRedirectKey } = Firebase.StorageKeys;
      const redirectValue = sessionStorage[PendingRedirectKey];
      if (redirectValue) {
        await loginWithRedirect();
      }
    })();
  }, [firebaseUser]);

  // /**
  //  * Parse emailAuthAction url. These are actions when we come from an email action
  //  * i.e. EmailLinkSignIn, VerifyEmail, ResetPasswordPage,
  //  */
  // useEffect(() => {
  //   (async (): Promise<void> => {
  //     const { continueUrl, mode, oobCode } = queryString.parse(location.search, {
  //       ignoreQueryPrefix: true,
  //     });
  //     if (oobCode && mode) {
  //       const { EmailLinkSignIn, ResetPassword, VerifyEmail } = Firebase.EmailModes;
  //       switch (mode) {
  //         case EmailLinkSignIn:
  //           // Log in with email link.
  //           // await loginWithEmailLink(window.location.href, continueUrl ?? '');
  //           break;
  //         case VerifyEmail:
  //           // Verify users email
  //           await verifyEmail({ oobCode: oobCode as string, continueUrl: continueUrl as string });
  //           break;
  //         case ResetPassword:
  //           // Happens in change password page
  //           break;
  //         default:
  //         // Error: invalid mode.
  //       }
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // Return the user object and Auth methods
  return {
    user,
    setUser,
    isLoggedIn,
    isLoading,
    setIsLoading,
    isInitializing,
    firebaseUser,
    firebaseError,
    fetchUser,
    setFirebaseError,
    loginWithEmail,
    loginSignupWithProvider,
    // loginWithEmailLink,
    loginWithRedirect,
    signupWithEmail,
    logout,
    sendVerifyEmail,
    sendResetPassword,
    // resetPassword,
    unLinkProvider,
    manualLinkProvider,
    updateProfile,
    updateEmail,
    updatePassword,
    deleteUser,
    // sendSignInWithEmailLink,
  };
};

const authContext = createContext<Partial<AuthContextProps>>({});

// Hook for child components to get the Auth object, re-render when it changes.
export const useAuth = (): AuthContextProps => {
  return useContext(authContext) as AuthContextProps;
};

/**
 * The below query doesn't ever fetch from the DB
 * It's just used to get the user data from the relay store
 */
const UpdateAuthUserFromRelayStore: React.FC = () => {
  const auth = useAuth();
  const identifyUserForAnalytics = useIdentifyUserForAnalytics();
  const user = useLazyLoadQuery<AuthProviderQuery>(
    AuthQuery,
    { userId: auth.firebaseUser?.uid ?? NON_EXISTENT_CUID },
    { fetchKey: cuid(), fetchPolicy: 'store-only' },
  ).user_connection?.edges?.[0]?.node;

  useEffect(() => {
    if (user) {
      auth.setUser(user);
      identifyUserForAnalytics(user);
    }
  }, [JSON.stringify(user)]);

  return null;
};

// Provider component that wraps the app and makes Auth object ...
// ... available to any child component that calls useAuth().
export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      <Suspense fallback={null}>
        <UpdateAuthUserFromRelayStore />
      </Suspense>
      {children}
    </authContext.Provider>
  );
}
