import React, { Suspense, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Box, Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ButtonLink from '@/ui/Button/ButtonLink';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import MenuListSubheader from '@/ui/MenuListSubheader';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import DisableToggle from '@/hoc/DisableToggle';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import CourierNotifications from '@/containers/MainHeader/CourierNotifications';
import { useCoachingMenuStyles } from '@/containers/MainHeader/Desktop/CoachingMenuItem';
import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import ChooseOrganizerMenu from '@/containers/Organizer/Navigation/ChooseOrganizerMenu';
import { EditProfileDialog_OrganizerQuery } from '@/containers/OrganizerProfile/Admin/__generated__/EditProfileDialog_OrganizerQuery.graphql';
import {
  EDIT_PROFILE_DIALOG_QUERY,
  EditProfileLoader,
} from '@/containers/OrganizerProfile/Admin/EditProfileDialog';
import UserAvatar from '@/containers/User/UserAvatar/UserAvatar';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { useOrganizerPermissions } from '@/lib/role-helpers/organizer-role-utils';
import { AppRouteService } from '@/routes/RouteService';

const OrganizerNavigationDesktop: React.FC = () => {
  const headerClasses = useHeaderStyles();
  const coachingMenuStyles = useCoachingMenuStyles();
  const location = useLocation();
  const { isCurrentOrgAdmin, isCurrentOrgOwner } = useOrganizerPermissions();
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const [moreAnchorMenuEl, setMoreAnchorMenuEl] = useState<HTMLButtonElement | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [
    editOrganizerProfileQueryRef,
    loadEditOrganizerProfileQuery,
  ] = useQueryLoader<EditProfileDialog_OrganizerQuery>(EDIT_PROFILE_DIALOG_QUERY);

  const isMoreOpen = Boolean(moreAnchorMenuEl);
  const isOnMorePage = location.pathname.includes(
    AppRouteService.getRelativeUrl('OrganizerApp_Billing', { organizerSlug }),
  );

  const canEditBilling = isCurrentOrgOwner;
  const canViewAnalytics = isCurrentOrgAdmin || isCurrentOrgOwner;
  const canEditProfile = isCurrentOrgAdmin || isCurrentOrgOwner;

  useEffect(() => {
    loadEditOrganizerProfileQuery({
      organizerSlug,
    });
  }, [organizerSlug]);

  /**
   * Handle dialog state
   */
  const handleDialogOpen = (): void => {
    setMoreAnchorMenuEl(null);
    setIsDialogOpen(true);
  };
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  /**
   * Open/Close more menu
   */
  const handleMoreOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setMoreAnchorMenuEl(e.currentTarget);
  };
  const handleMoreClose = (): void => {
    setMoreAnchorMenuEl(null);
  };

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
      <Suspense fallback={null}>
        {editOrganizerProfileQueryRef && (
          <EditProfileLoader
            closeDialog={handleDialogClose}
            editOrganizerProfileQueryRef={editOrganizerProfileQueryRef}
            isDialogOpen={isDialogOpen}
          />
        )}
      </Suspense>
      <div className={headerClasses.navigationButtons}>
        <ButtonLink to={AppRouteService.getRelativeUrl('OrganizerApp_Events', { organizerSlug })}>
          Events
        </ButtonLink>
        {canViewAnalytics && (
          <ButtonLink
            to={AppRouteService.getRelativeUrl('OrganizerApp_Analytics', { organizerSlug })}
          >
            Analytics
          </ButtonLink>
        )}
        <ButtonLink to={AppRouteService.getRelativeUrl('OrganizerApp_Messages', { organizerSlug })}>
          Messages
        </ButtonLink>
        <ButtonLink to={AppRouteService.getRelativeUrl('OrganizerApp_Team', { organizerSlug })}>
          Team
        </ButtonLink>
        <ExtendedButton
          endIcon={<ExpandMoreIcon />}
          onClick={handleMoreOpen}
          style={{ textDecoration: isOnMorePage ? 'underline' : undefined }}
        >
          More
        </ExtendedButton>
        <Menu
          anchorEl={moreAnchorMenuEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={coachingMenuStyles.menuItem}
          getContentAnchorEl={null}
          onClose={handleMoreClose}
          open={isMoreOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuListSubheader>Manage</MenuListSubheader>
          <MenuItem
            component={NavLink}
            onClick={handleMoreClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('OrganizerApp_Series', { organizerSlug })}
          >
            Series
          </MenuItem>
          {canEditBilling && (
            <MenuItem
              component={NavLink}
              onClick={handleMoreClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('OrganizerApp_Billing', { organizerSlug })}
            >
              Billing
            </MenuItem>
          )}
          {canEditProfile && (
            <DisableToggle showLoadingWhileDisabled disabled={!editOrganizerProfileQueryRef}>
              <MenuItem onClick={handleDialogOpen}>Edit Profile</MenuItem>
            </DisableToggle>
          )}
          <MenuListSubheader>Public</MenuListSubheader>
          <MenuItem
            component={NavLink}
            onClick={handleMoreClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('OrganizerProfile', { organizerSlug })}
          >
            Organizer Profile
          </MenuItem>
          <MenuItem
            component={NavLink}
            onClick={handleMoreClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('OrganizerProfile_OnlineCourses', { organizerSlug })}
          >
            Online Courses
          </MenuItem>
        </Menu>
      </div>
      <Box alignItems="center" className={headerClasses.navigationButtons}>
        <NoUnderlineRouterLink
          grey
          style={{ textAlign: 'center' }}
          to={AppRouteService.getRelativeUrl('Home')}
        >
          Switch to Cycling
        </NoUnderlineRouterLink>
        <Box minWidth={60} ml={1}>
          <Suspense fallback={<LoadingPlaceholder disableShrink isLoading size={16} />}>
            <ChooseOrganizerMenu />
          </Suspense>
        </Box>
        <Box alignItems="center" display="flex" justifyContent="center" ml={1} mr={2}>
          <CourierNotifications />
        </Box>
        <div>
          <UserAvatar noShadow size="small" />
        </div>
      </Box>
    </Box>
  );
};

export default OrganizerNavigationDesktop;
