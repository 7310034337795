/*
Constants for the pit space
 */

export enum PitSpaceSizeTypesEnum {
  Contact = 'Contact',
  Unit = 'Unit',
  Plot = 'Plot',
}
export type PitSpaceSizeOptionsLinearType = 'Feet' | 'Meters';
export enum PitSpaceSizeOptionsLinearEnum {
  Feet = 'Feet',
  Meters = 'Meters',
}
export const PitSpaceSizeOptionsLinear = {
  [PitSpaceSizeOptionsLinearEnum.Feet]: 'ft',
  [PitSpaceSizeOptionsLinearEnum.Meters]: 'm',
};
export type PitSpaceSizeOptionsSquaredType = 'FeetSquared' | 'MetersSquared';
export enum PitSpaceSizeOptionsSquaredEnum {
  FeetSquared = 'FeetSquared',
  MetersSquared = 'MetersSquared',
}
export const PitSpaceSizeOptionsSquared = {
  [PitSpaceSizeOptionsSquaredEnum.FeetSquared]: 'ft\t²',
  [PitSpaceSizeOptionsSquaredEnum.MetersSquared]: 'm\t²',
};
