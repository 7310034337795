/**
 * A set of hooks that lets us easily get data for the coach profile app
 */

import { useParams } from 'react-router';
import { useMount } from 'react-use';

import { IS_NEW_COACH_QUERY_PARAM } from '@/const';
import { getParticipantTypeText } from '@/containers/CoachProfile/Profile/Sections/Lessons/helpers';
import { formatNumberAsCurrency } from '@/lib/currency-utils';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { replacePathNameWithoutReloadingPage } from '@/lib/path-helpers/routing';
import { useAuth } from '@/providers/AuthProvider';

/**
 * What is the url slug for the current coach
 */
export const useGetCoachSlugFromURL = (): string => {
  const { coachSlug } = useParams();
  return coachSlug;
};

/**
 * Does the current user own this coach profile
 */
export const useGetIsOwnCoachProfile = (): boolean => {
  const auth = useAuth();
  const { coachSlug } = useParams();
  return coachSlug === auth?.user?.slug;
};

/**
 * What text do we display if a coach hasn't filled in the info
 */
export const useGetEmptyStateText = (sectionName: string): string => {
  const isOwnProfile = useGetIsOwnCoachProfile();
  if (isOwnProfile) {
    return `Click the pencil to finish the ${sectionName} Section`;
  }
  return "I'm not done filling out this section yet.";
};

/**
 * Check the URL params to see if we just created a new coach
 */
export const useIsNewCoachOnMount = (): void => {
  useMount(() => {
    const queryParams = getParsedQueryParams();
    if (queryParams?.[IS_NEW_COACH_QUERY_PARAM]) {
      replacePathNameWithoutReloadingPage(window.location.pathname);
      return true;
    }
    return false;
  });
};

/**
 * Get the price of a lesson
 */
export const getLessonPrice = (
  lesson:
    | {
        coach: { currencyCode: string };
        participantRange: number[];
        price: number | null;
      }
    | null
    | undefined,
): string => {
  if (!lesson?.price) {
    return 'Free';
  }
  return `${formatNumberAsCurrency(
    lesson.price,
    lesson.coach.currencyCode,
  )} / ${getParticipantTypeText(lesson.participantRange)}`;
};
