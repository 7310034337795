import React from 'react';

import Page from '../Page';

import MainLayout from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const UserRegistrationsPage: ReactFCC = ({ children }) => {
  return (
    <Page noIndex title="Registrations & Lessons • Reggy">
      <MainLayout>{children}</MainLayout>
    </Page>
  );
};

export default UserRegistrationsPage;
