/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type registrationStatus_enum = "canceled" | "canceled_stale" | "complete" | "in_progress" | "%future added value";
export type EditProfileDialogEventsWithRegistrationsQueryVariables = {
    organizerCuid: string;
};
export type EditProfileDialogEventsWithRegistrationsQueryResponse = {
    readonly registration_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly status: registrationStatus_enum;
                readonly eventCuid: string;
                readonly referenceNo: string;
                readonly stripeCheckoutId: string | null;
                readonly event: {
                    readonly occurrenceLabel: string | null;
                    readonly eventMetadata: {
                        readonly name: string;
                    };
                };
            };
        }>;
    };
};
export type EditProfileDialogEventsWithRegistrationsQuery = {
    readonly response: EditProfileDialogEventsWithRegistrationsQueryResponse;
    readonly variables: EditProfileDialogEventsWithRegistrationsQueryVariables;
};



/*
query EditProfileDialogEventsWithRegistrationsQuery(
  $organizerCuid: String!
) {
  registration_connection(where: {_and: [{event: {organizer: {cuid: {_eq: $organizerCuid}}}}, {_or: [{event: {startDate: {_gte: "now()"}}}, {ride: {rideDays: {startTime: {_gte: "now()"}}}}, {clinic: {clinicDays: {startTime: {_gte: "now()"}}}}]}, {_or: [{status: {_eq: complete}}, {status: {_eq: canceled}}]}]}, distinct_on: [eventCuid]) {
    edges {
      node {
        status
        eventCuid
        referenceNo
        stripeCheckoutId
        event {
          occurrenceLabel
          eventMetadata {
            name
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "organizerCuid"
        } as any
    ], v1 = {
        "_gte": "now()"
    } as any, v2 = {
        "startTime": (v1 /*: any*/)
    } as any, v3 = [
        {
            "kind": "Literal",
            "name": "distinct_on",
            "value": [
                "eventCuid"
            ]
        } as any,
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "fields": [
                                                        {
                                                            "kind": "Variable",
                                                            "name": "_eq",
                                                            "variableName": "organizerCuid"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "cuid"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "organizer"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "event"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "_or": [
                                    {
                                        "event": {
                                            "startDate": (v1 /*: any*/)
                                        }
                                    },
                                    {
                                        "ride": {
                                            "rideDays": (v2 /*: any*/)
                                        }
                                    },
                                    {
                                        "clinic": {
                                            "clinicDays": (v2 /*: any*/)
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.2",
                            "value": {
                                "_or": [
                                    {
                                        "status": {
                                            "_eq": "complete"
                                        }
                                    },
                                    {
                                        "status": {
                                            "_eq": "canceled"
                                        }
                                    }
                                ]
                            }
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eventCuid",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "referenceNo",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stripeCheckoutId",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "occurrenceLabel",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditProfileDialogEventsWithRegistrationsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v8 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditProfileDialogEventsWithRegistrationsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v8 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v10 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "fbd3276dcd6a305dafc6822eeb45b187",
            "id": null,
            "metadata": {},
            "name": "EditProfileDialogEventsWithRegistrationsQuery",
            "operationKind": "query",
            "text": "query EditProfileDialogEventsWithRegistrationsQuery(\n  $organizerCuid: String!\n) {\n  registration_connection(where: {_and: [{event: {organizer: {cuid: {_eq: $organizerCuid}}}}, {_or: [{event: {startDate: {_gte: \"now()\"}}}, {ride: {rideDays: {startTime: {_gte: \"now()\"}}}}, {clinic: {clinicDays: {startTime: {_gte: \"now()\"}}}}]}, {_or: [{status: {_eq: complete}}, {status: {_eq: canceled}}]}]}, distinct_on: [eventCuid]) {\n    edges {\n      node {\n        status\n        eventCuid\n        referenceNo\n        stripeCheckoutId\n        event {\n          occurrenceLabel\n          eventMetadata {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '77d443c3562fb7a9a60a328053988b3e';
export default node;
