import { makeStyles } from '@material-ui/core/styles';

export const useMobileMenuStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  menuItemInnerContainer: {
    overflowY: 'auto',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(25),
  },
  menuItem: {
    marginTop: theme.spacing(0.5),
  },
  heading: {
    flexBasis: '40%',
    flexShrink: 0,
    fontWeight: 600,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
}));
