/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RouteAnalytics_GetCoachCuidQueryVariables = {
    coachSlug: string;
};
export type RouteAnalytics_GetCoachCuidQueryResponse = {
    readonly coach_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly cuid: string;
            };
        }>;
    };
};
export type RouteAnalytics_GetCoachCuidQuery = {
    readonly response: RouteAnalytics_GetCoachCuidQueryResponse;
    readonly variables: RouteAnalytics_GetCoachCuidQueryVariables;
};



/*
query RouteAnalytics_GetCoachCuidQuery(
  $coachSlug: String!
) {
  coach_connection(where: {user: {slug: {_eq: $coachSlug}}}) {
    edges {
      node {
        cuid
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "coachSlug"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "coachSlug"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "slug"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "user"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RouteAnalytics_GetCoachCuidQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachConnection",
                    "kind": "LinkedField",
                    "name": "coach_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coach",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RouteAnalytics_GetCoachCuidQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachConnection",
                    "kind": "LinkedField",
                    "name": "coach_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coach",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f7509268547bf15cfe13619a43d3bedb",
            "id": null,
            "metadata": {},
            "name": "RouteAnalytics_GetCoachCuidQuery",
            "operationKind": "query",
            "text": "query RouteAnalytics_GetCoachCuidQuery(\n  $coachSlug: String!\n) {\n  coach_connection(where: {user: {slug: {_eq: $coachSlug}}}) {\n    edges {\n      node {\n        cuid\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '966d2d2d97fd5a19a53c87c3f93360aa';
export default node;
