import { useLocation } from 'react-router-dom';

import {
  TeaserMessage,
  TeaserMessageProps,
  useSetAuthRedirectURLGlobal,
  useSetIsLoginDialogOpenGlobal,
  useSetIsLoginSignupDialogBlockingGlobal,
  useSetIsSignupDialogOpenGlobal,
  useSetLoginSignupTeaserMessageGlobal,
  useSetLoginSignupTeaserMessagePropsGlobal,
} from '@/containers/Auth/recoilStore';
import ENV from '@/env';
import { AppRouteService } from '@/routes/RouteService';

export interface AuthLinkObjectProps {
  to: string;
  state: { dialogShown: true } | null;
}

/**
 * A function that opens the signup dialog.
 */
export const useOpenSignupDialog = (): ((props?: {
  teaserMessage?: TeaserMessage;
  teaserMessageProps?: TeaserMessageProps;
}) => void) => {
  const location = useLocation();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const setLoginSignupTeaserMessage = useSetLoginSignupTeaserMessageGlobal();
  const setLoginSignupTeaserMessageProps = useSetLoginSignupTeaserMessagePropsGlobal();
  const setIsLoginSignupDialogBlockingGlobal = useSetIsLoginSignupDialogBlockingGlobal();
  return (props): void => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    setIsLoginSignupDialogBlockingGlobal(false);
    setLoginSignupTeaserMessage(props?.teaserMessage ?? 'default');
    setLoginSignupTeaserMessageProps(props?.teaserMessageProps ?? null);
    setIsSignupDialogOpenGlobal(true);
  };
};

/**
 * A function that opens the login dialog.
 */
export const useOpenLoginDialog = (): ((props?: {
  teaserMessage?: TeaserMessage;
  teaserMessageProps?: TeaserMessageProps;
}) => void) => {
  const location = useLocation();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsLoginSignupDialogBlockingGlobal = useSetIsLoginSignupDialogBlockingGlobal();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setLoginSignupTeaserMessage = useSetLoginSignupTeaserMessageGlobal();
  const setLoginSignupTeaserMessageProps = useSetLoginSignupTeaserMessagePropsGlobal();
  return (props): void => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    setIsLoginSignupDialogBlockingGlobal(false);
    setLoginSignupTeaserMessage(props?.teaserMessage ?? 'default');
    setLoginSignupTeaserMessageProps(props?.teaserMessageProps ?? null);
    setIsLoginDialogOpenGlobal(true);
  };
};

/**
 * Utils
 */
/**
 * Sanitize a URL that we want to redirect to
 * @param redirectUrl
 * @param defaultUrl
 */
export const validateRedirectUrl = (
  redirectUrl: string | null | undefined,
  defaultUrl?: string,
): string => {
  const dUrl = defaultUrl ?? AppRouteService.getRelativeUrl('Home');
  if (!redirectUrl) {
    return dUrl;
  }
  // check for protocol
  let parsedUrl;
  if (redirectUrl.includes('://')) {
    parsedUrl = new URL(redirectUrl);
    // Stop javascript protocols i.e. javascript://
    if (parsedUrl.protocol !== 'https:') {
      return dUrl;
    }
  } else {
    parsedUrl = new URL(redirectUrl, window.location.origin);
  }
  // Make sure we are redirecting to a url on the same domain
  const hostnameRegex = ENV.IS_NODE_ENV_LOCAL
    ? new RegExp(/^(localhost)$/, 'g')
    : ENV.IS_NODE_ENV_STAGING
    ? new RegExp(/(^|.)(reggy-[az]+\.web\.app)$/, 'g')
    : new RegExp(/(^|.)(goreggy\.com)$/, 'g');
  if (!parsedUrl.hostname.match(hostnameRegex)) {
    return dUrl;
  }
  // Multiple forward-slashes are dangerous
  const slashSanitizedPath = parsedUrl.pathname.replace(/\/+/g, '/');
  // Dont allow %0A, %09, %0D, and %AC
  if (slashSanitizedPath[0] !== '/') {
    return dUrl;
  }
  return slashSanitizedPath + parsedUrl.search;
};
