import { Theme, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const CardAccordion = withStyles((theme: Theme) => ({
  root: {
    borderWidth: '1px 0',
    borderColor: '#DEDFDE',
    borderStyle: 'solid',
    boxShadow: 'none',
    '&$expanded': {
      // margin: 0,
    },
    '&:not(.Mui-expanded):not(:last-child)': {
      // borderBottom: 'none',
    },
    '&:hover:not(.Mui-expanded)': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
  expanded: {},
  rounded: { borderRadius: '4px' },
}))(MuiAccordion);

export const CardAccordionSummary = withStyles({
  root: {
    fontWeight: 700,
    marginBottom: '-1px',
    '&$expanded': {
      minHeight: 'initial',
    },
  },
  content: {
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const CardAccordionDetails = withStyles({
  root: {
    display: 'block',
  },
})(MuiAccordionDetails);
