import { Theme } from '@material-ui/core';

export const MuiLinearProgress = (theme: Theme): {} => ({
  bar: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
  },
  root: {
    height: '2px',
  },
});
