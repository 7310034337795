import React from 'react';
import { Box } from '@material-ui/core';

import LoadingPlaceholder from '@/components/LoadingPlaceholder/LoadingPlaceholder';

import { DEFAULT_LOADING_SPINNER_DELAY } from '@/const';

const FullScreenLoadingPlaceholder: React.FC<{ noDelay?: boolean }> = ({ noDelay }) => {
  return (
    <Box display="flex" height="100vh" position="absolute" width="100%">
      <LoadingPlaceholder
        absoluteCenter
        isLoading
        delay={noDelay ? undefined : DEFAULT_LOADING_SPINNER_DELAY}
      />
    </Box>
  );
};

export default FullScreenLoadingPlaceholder;
