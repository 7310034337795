/**
 * 404 Page
 */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from '../Page';

import MainLayout from '@/layouts/MainLayout';
import { AppRouteService } from '@/routes/RouteService';

export const useNotFoundStyles = makeStyles((theme: Theme) => ({
  // notFoundText: {
  //   font: "700 50px/1.45 'Libre Baskerville'",
  //   position: 'relative',
  //   textAlign: 'center',
  //   textShadow: '0px 15px 5px rgba(0, 0, 0, 0.1)',
  //   '&  + h3': {
  //     fontFamily: 'Libre Baskerville',
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: theme.spacing(4),
  //   },
  // },
  root: {
    height: 'calc(100vh - 76px)',
    display: 'flex',
  },
  notFound: {
    lineHeight: 1.4,
    textAlign: 'center',
    '& > div': {
      position: 'relative',
      height: '240px',
      [theme.breakpoints.down('xs')]: {
        height: '200px',
      },
    },
    '& h1': {
      fontFamily: "'Montserrat', sans-serif",
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '252px',
      fontWeight: 900,
      margin: 0,
      color: '#31708f',
      textTransform: 'uppercase',
      letterSpacing: '-40px',
      marginLeft: '-20px',

      [theme.breakpoints.down('xs')]: {
        fontSize: '200px',
      },
      '& > span': {
        textShadow: '-10px 14px 21px rgba(0, 0, 0, .2), -3px 2px 8px rgba(38, 38, 38, 0.3)',
      },
    },
    //
    // @media only screen and (max-width: 480px) {
    //   .notfound .notfound-404 {
    //     height: 162px;
    //   }
    //   .notfound .notfound-404 h1 {
    //     font-size: 162px;
    //     height: 150px;
    //     line-height: 162px;
    //   }
    // },
  },
}));
const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const classes = useNotFoundStyles();

  useMount(() => {
    navigate(AppRouteService.getRelativeUrl('NotFound'), { replace: true });
  });

  return (
    <Page description="This isn't the page you're looking for." title="404 • Page Not Found">
      <MainLayout>
        <Box className={classes.root}>
          <Box m="auto">
            <div className={classes.notFound}>
              <div>
                <h1>
                  <span>4</span>
                  <span>0</span>
                  <span>4</span>
                </h1>
              </div>
            </div>
            <Box mt={2}>
              <Typography align="center" variant="h1">
                404 - Page Not Found
              </Typography>
            </Box>
            <Box p={3}>
              <Typography gutterBottom align="center" variant="body1">
                How did you end up <i>here</i>?!
              </Typography>
              <Typography gutterBottom align="center" variant="body1">
                You weren't trying to cut the track were you?
              </Typography>
              <Typography gutterBottom align="center" variant="body1">
                You can go back and try again. We're giving away free re-runs.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                color="primary"
                onClick={(): void => navigate(-1)}
                size="large"
                variant="outlined"
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Box>
      </MainLayout>
    </Page>
  );
};
export default PageNotFound;
