/*
 * Mutation Hooks used for Organizers Public Profile Page
 */
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { useSnackbar } from 'notistack';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import {
  image_insert_input,
  organizerProfileMutations_saveOrganizerProfile_Mutation,
} from '@/containers/OrganizerProfile/Admin/__generated__/organizerProfileMutations_saveOrganizerProfile_Mutation.graphql';
import { ImageConstraint, ImageUpdateColumn } from '@/graphql/__generated__/graphql';
import { applyProfileCloudinaryTransform } from '@/lib/cloudinary';
import { updateStreamOrganizerUserCF } from '@/lib/firebase';
import { createTempClientPrefix, MutationId } from '@/lib/relay-utils';
import { useAuth } from '@/providers/AuthProvider';

/**
 * Save organizer about page
 */
interface SaveOrganizerProfileMutationProps {
  about: string;
  slug: string;
  website: string;
  image: image_insert_input | null;
  name: string;
  defaultCurrencyCode: string;
  organizerId: MutationId;
  publicIdToDelete: string;
}
export const useSaveOrganizerProfileMutation = (): {
  saveOrganizerProfileMutation: (data: SaveOrganizerProfileMutationProps) => void;
  isSaveOrganizerProfileMutationInFlight: boolean;
} => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [
    commitMutation,
    isSaveOrganizerProfileMutationInFlight,
  ] = useMutation<organizerProfileMutations_saveOrganizerProfile_Mutation>(
    graphql`
      mutation organizerProfileMutations_saveOrganizerProfile_Mutation(
        $publicIdToDelete: String!
        $organizer: organizer_insert_input!
      ) {
        insert_organizer_one(
          object: $organizer
          on_conflict: {
            constraint: organizer_cuid_key
            update_columns: [name, about, website, slug, imagePublicId, defaultCurrencyCode]
          }
        ) {
          id
          name
          about
          website
          slug
          imagePublicId
          defaultCurrencyCode
          image {
            bytes
            format
            height
            originalFilename
            publicId
            relativeUrl
            resourceType
            url
            version
            width
            organizerCuid
          }
        }
        delete_image_by_pk(publicId: $publicIdToDelete) {
          publicId
        }
      }
    `,
  );
  const saveOrganizerProfileMutation = useCallback(
    (data: SaveOrganizerProfileMutationProps): Promise<void> => {
      if (!auth.firebaseUser?.uid) {
        enqueueSnackbar('Please log in or refresh the page.', { variant: 'error' });
        return new Promise((resolve) => resolve());
      }
      const updater = (store: RecordSourceSelectorProxy): void => {
        const organizerRecord = store.get(data.organizerId.nodeId);
        if (!organizerRecord) {
          return;
        }

        organizerRecord.setValue(data.name, 'name');
        organizerRecord.setValue(data.website, 'website');
        organizerRecord.setValue(data.about, 'about');
        organizerRecord.setValue(data.slug, 'slug');
        organizerRecord.setValue(data.defaultCurrencyCode, 'defaultCurrencyCode');

        if (data.image) {
          // Create Image
          const imageId = `${createTempClientPrefix('image')}:${data.image.publicId}`;
          let imageRecord = store.get(imageId);
          if (!imageRecord) {
            imageRecord = store.create(imageId, 'image');
          }
          imageRecord.setValue(imageId, 'id');
          imageRecord.setValue(data.image.bytes, 'bytes');
          imageRecord.setValue(data.image.format, 'format');
          imageRecord.setValue(data.image.height, 'height');
          imageRecord.setValue(data.image.originalFilename, 'originalFilename');
          imageRecord.setValue(data.image.publicId, 'publicId');
          imageRecord.setValue(data.image.resourceType, 'resourceType');
          imageRecord.setValue(data.image.relativeUrl, 'relativeUrl');
          imageRecord.setValue(data.image.url, 'url');
          imageRecord.setValue(data.image.version, 'version');
          imageRecord.setValue(data.image.width, 'width');
          imageRecord.setValue(data.image.organizerCuid, 'organizerCuid');
          organizerRecord.setValue(data.image.publicId, 'imagePublicId');
          organizerRecord.setLinkedRecord(imageRecord, 'image');
        }
      };
      return new Promise((resolve, reject) => {
        commitMutation({
          onCompleted: (): void => {
            updateStreamOrganizerUserCF({
              name: data.name,
              image: data.image
                ? applyProfileCloudinaryTransform(data.image.relativeUrl ?? undefined)
                : undefined,
              organizerCuid: data.organizerId.cuid,
            }).then();
            resolve();
          },
          onError: (error): void => {
            enqueueSnackbar(error.message, { variant: 'error' });
            reject(error);
          },
          variables: {
            // Image to delete
            publicIdToDelete: data.publicIdToDelete,
            organizer: {
              cuid: data.organizerId.cuid,
              name: data.name,
              about: data.about,
              website: data.website,
              slug: data.slug,
              defaultCurrencyCode: data.defaultCurrencyCode,
              image: data.image
                ? {
                    data: data.image,
                    on_conflict: {
                      constraint: ImageConstraint.ImagePkey,
                      update_columns: [ImageUpdateColumn.RelativeUrl],
                    },
                  }
                : undefined,
            },
          },
          updater,
          optimisticUpdater: updater,
        });
      });
    },
    [auth?.firebaseUser?.uid],
  );
  return {
    saveOrganizerProfileMutation,
    isSaveOrganizerProfileMutationInFlight,
  };
};
