import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import pluralize from 'pluralize';

import BrandTypography from '@/ui/BrandTypography';
import ExtendedButton from '@/ui/Button/ExtendedButton';

import { SearchTypeEnum } from '@/const';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '16vh',
    maxWidth: 300,
  },
});

interface ClearRefinementsProps {
  clearFilters: () => void;
}
const ClearRefinements: React.FC<ClearRefinementsProps> = ({ clearFilters }) => (
  <ExtendedButton color="primary" onClick={clearFilters} size="small" variant="contained">
    Clear Filters
  </ExtendedButton>
);

interface NoEventsSearchResultsProps {
  activity?: SearchTypeEnum;
  clearFilters?: () => void;
  hasRefinements: boolean;
}
const NoExperiencesSearchResults: React.FC<NoEventsSearchResultsProps> = ({
  activity,
  clearFilters,
  hasRefinements,
}) => {
  const classes = useStyles();

  const description = hasRefinements
    ? 'Try clearing your applied filters'
    : 'Please try another query';

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        <svg
          height="138"
          width="138"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <linearGradient id="c" x1="50%" x2="50%" y1="100%" y2="0%">
              <stop offset="0%" stopColor="#F5F5FA" />
              <stop offset="100%" stopColor="#FFF" />
            </linearGradient>
            <path d="M68.71 114.25a45.54 45.54 0 1 1 0-91.08 45.54 45.54 0 0 1 0 91.08z" id="b" />
            <filter
              filterUnits="objectBoundingBox"
              height="140.6%"
              id="a"
              width="140.6%"
              x="-20.3%"
              y="-15.9%"
            >
              <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
              <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation="5.5"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
                values="0 0 0 0 0.145098039 0 0 0 0 0.17254902 0 0 0 0 0.380392157 0 0 0 0.15 0"
              />
              <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
              <feGaussianBlur
                in="shadowOffsetOuter2"
                result="shadowBlurOuter2"
                stdDeviation="1.5"
              />
              <feColorMatrix
                in="shadowBlurOuter2"
                result="shadowMatrixOuter2"
                values="0 0 0 0 0.364705882 0 0 0 0 0.392156863 0 0 0 0 0.580392157 0 0 0 0.2 0"
              />
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1" />
                <feMergeNode in="shadowMatrixOuter2" />
              </feMerge>
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle cx="68.85" cy="68.85" fill="#04759C" opacity=".07" r="68.85" />
            <circle cx="68.85" cy="68.85" fill="#04759C" opacity=".08" r="52.95" />
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use fill="url(#c)" xlinkHref="#b" />
            <path
              d="M76.01 75.44c5-5 5.03-13.06.07-18.01a12.73 12.73 0 0 0-18 .07c-5 4.99-5.03 13.05-.07 18a12.73 12.73 0 0 0 18-.06zm2.5 2.5a16.28 16.28 0 0 1-23.02.09A16.29 16.29 0 0 1 55.57 55a16.28 16.28 0 0 1 23.03-.1 16.28 16.28 0 0 1-.08 23.04zm1.08-1.08l-2.15 2.16 8.6 8.6 2.16-2.15-8.6-8.6z"
              fill="#04759C"
            />
          </g>
        </svg>
      </Box>

      <Box my={1}>
        <Typography align="center" variant="h5">
          No{' '}
          <BrandTypography inline variant="h5">
            {activity ? pluralize(activity) : 'Experiences'}
          </BrandTypography>{' '}
          match your search
        </Typography>
      </Box>
      <Typography align="center" variant="body2">
        {description}
      </Typography>
      {hasRefinements && clearFilters && (
        <Box display="flex" justifyContent="center" mt={2}>
          <ClearRefinements clearFilters={clearFilters} />
        </Box>
      )}
    </Box>
  );
};

export default NoExperiencesSearchResults;
