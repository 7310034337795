/*
Utility functions to manipulate and understand the ui
 */
import React, {
  Children,
  Dispatch,
  MutableRefObject,
  ReactChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import { GridSpacing } from '@material-ui/core/Grid/Grid';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import init from 'ramda/src/init';
import last from 'ramda/src/last';
import length from 'ramda/src/length';
import path from 'ramda/src/path';

/**
 * Are the children of a react component empty?
 */
export const isChildrenNull = (children: ReactNode): boolean => {
  return Boolean(children?.type?.() === null);
};

/**
 * Object to expand for ellipsis on a p
 */
export const textEllipsisProps: React.CSSProperties = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

/**
 * Generate a random color
 */
export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// ['apple'] = 'apple'
// ['apple', 'pear'] = 'apple and pear'
// ['apple', 'pear', 'orange'] = 'apple, pear, and orange'
export const arrayToCommaFragment = (
  arrayToConvert: ReactNode[],
  connectingWord: string,
): ReactNode => {
  if (!length(arrayToConvert)) {
    return <></>;
  }
  if (length(arrayToConvert) === 1) {
    return <>{arrayToConvert[0]}</>;
  }
  if (length(arrayToConvert) === 2) {
    return (
      <>
        {arrayToConvert[0]} {connectingWord} {arrayToConvert[1]}
      </>
    );
  }
  return (
    <>
      {init(arrayToConvert).map((item) => (
        <>{item}, </>
      ))}
      {connectingWord} {last(arrayToConvert)}
    </>
  );
};

export const arrayToCommaAndFragment = (arrayToConvert: ReactNode[]): ReactNode =>
  arrayToCommaFragment(arrayToConvert, 'and');

export const arrayToCommaOrFragment = (arrayToConvert: ReactNode[]): ReactNode =>
  arrayToCommaFragment(arrayToConvert, 'or');

export const scrollWindowToTop = (paddingTop?: number): void => {
  window.scrollTo({ top: paddingTop || 0, behavior: 'smooth' });
};
export const scrollWindowToBottom = (paddingBottom?: number): void => {
  window.scrollTo({ top: document.body.scrollHeight - (paddingBottom || 0), behavior: 'smooth' });
};
// export const scrollElementToBottom = (element: HTMLElement): void => {
//   element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
// };
export const scrollWindowToElement = (element: HTMLElement, topPadding?: number): void => {
  const padding = topPadding ?? 0;
  window.scrollTo({
    top: element.getBoundingClientRect().top + window.scrollY - padding,
    behavior: 'smooth',
  });
};

/**
 * Get the Material UI breakpoint key for a given screen width
 */
export const useGetCurrentBreakpointKey = (): Breakpoint => {
  const theme = useTheme();
  let key = path([4], theme.breakpoints.keys);
  if (useMediaQuery(theme.breakpoints.between('xs', 'sm'))) {
    key = path([0], theme.breakpoints.keys);
  }
  if (useMediaQuery(theme.breakpoints.between('sm', 'md'))) {
    key = path([1], theme.breakpoints.keys);
  }
  if (useMediaQuery(theme.breakpoints.between('md', 'lg'))) {
    key = path([2], theme.breakpoints.keys);
  }
  if (useMediaQuery(theme.breakpoints.between('lg', 'xl'))) {
    key = path([3], theme.breakpoints.keys);
  }
  return key as Breakpoint;
};

// Down
export const useIsSmallScreenDown = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

export const useIsMediumScreenDown = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

export const useIsLargeScreenDown = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

export const useIsXLargeScreenDown = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

export const useIsScreenBelow = (screenWidth: number): boolean =>
  useMediaQuery(`(max-width:${screenWidth}px)`);

// Up
export const useIsXSmallScreenUp = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

export const useIsSmallScreenUp = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

export const useIsMediumScreenUp = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

export const useIsLargeScreenUp = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

export const useIsXLargeScreenUp = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

export const useIsScreenAtOrAbove = (screenWidth: number): boolean =>
  useMediaQuery(`(min-width:${screenWidth}px)`);

// Between
export const useScreenBetweenSmMd = (): boolean =>
  useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'sm'));

export const useGetResponsiveFormSpacing = (): GridSpacing => {
  const isSmallScreenDown = useIsSmallScreenDown();
  return isSmallScreenDown ? 1 : 2;
};

/**
 * Function to see if a target or any of it's parents have a property
 */
export const targetHasProp = (
  target: Element | null,
  hasProp: (tar: Element) => boolean,
): boolean => {
  while (target) {
    if (hasProp(target)) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    target = target.parentElement;
  }
  return false;
};

/**
 * Measure a dom node and keep it updated when the window resizes
 */
export const useClientRect = (
  ref: MutableRefObject<HTMLBaseElement | null>,
): { width: number; height: number } | undefined => {
  const [rect, setRect] = useState<{
    width: number;
    height: number;
  }>();

  useEffect(() => {
    const resizeListener = (): void => {
      if (ref.current) {
        setRect({
          width: ref.current.getBoundingClientRect().width,
          height: ref.current.getBoundingClientRect().height,
        });
      }
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return (): void => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [ref === null]);
  return rect;
};

/**
 * Blur the control that is currently focused
 */
export const blurCurrentlyFocusedControl = (): void => {
  setTimeout(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, 1);
};

/**
 * Turn children into a flat array
 */
const getChildrenType = (node: null | unknown[] | Record<string, unknown>): string => {
  if (node === null) return 'null';
  if (Array.isArray(node)) return 'array';
  if (typeof node === 'object') return 'object';
  return 'string';
};
const getElemType = (elem: ReactNode): string => {
  if (typeof elem === 'string') return 'string';
  if (elem?.props?.children === undefined) return 'void';
  return 'normal';
};
export const reactChildrenToFlatArray = (
  children: ReactChildren,
): Array<Exclude<ReactNode, boolean | null | undefined>> => {
  if (!children) {
    return [];
  }
  return Children.toArray(children).map((child) => {
    switch (
      getElemType(child) // Check node type
    ) {
      case 'void': // e.g. <br /> <img />
      case 'string': // normal text
        return child;
      case 'normal': // any other html tag or component
        switch (
          getChildrenType(child.props.children) // Check node content
        ) {
          case 'null': // e.g. <Elem>null</Elem>
          case 'string': // e.g <Elem>foo</Elem>
          case 'object': // e.g. <Elem><Foo /></Elem>
            return child;
          case 'array': // multiple children
            reactChildrenToFlatArray(child.props.children);
            break;
          default:
            return child;
        }
        break;
      default:
        return child;
    }
    return child;
  });
};

export const getScrollbarWidth = (): number => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;',
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

/**
 * Bubble micro-animation
 */
export const useBubbleAnimation = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (shouldAnimate) {
      timer = setTimeout(() => setShouldAnimate(false), 700);
    }
    return (): void => clearTimeout(timer);
  }, [shouldAnimate]);
  return [shouldAnimate, setShouldAnimate];
};

/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export const useBlocker = (blocker: (fn: any) => void, when = true): void => {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!when) {
      return;
    }
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry(): void {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          // TODO: Figure out how to re-enable
          //   this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
};

/**
 * Prompts the user with an Alert before they leave the current screen.
 */
export const usePrompt = (message: string, when = true): void => {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
};
