import React, { createContext, useCallback, useContext, useState } from 'react';
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { closestTo, isEqual, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import { fetchQuery, graphql } from 'relay-runtime';

import FullScreenLoadingPlaceholder from '@/components/LoadingPlaceholder/FullScreenLoadingPlaceholder';

import { NON_EXISTENT_CUID } from '@/const';
import { useResetGlobalStateOnUnmount } from '@/containers/EventProfile/helpers';
import {
  useGetOccurrenceToDisplayGlobal,
  useSetOccurrenceToDisplayGlobal,
} from '@/containers/EventProfile/recoilStore';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { useOrganizerPermissions } from '@/lib/role-helpers/organizer-role-utils';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import { EventProfileProvider_GetUpcomingOccurrencesQuery } from '@/providers/EventProfileProvider/__generated__/EventProfileProvider_GetUpcomingOccurrencesQuery.graphql';
import {
  EventProfileProviderQuery,
  EventProfileProviderQueryResponse,
} from '@/providers/EventProfileProvider/__generated__/EventProfileProviderQuery.graphql';
import { AppRouteService } from '@/routes/RouteService';

const GET_MOST_RECENT_OCCURRENCE_QUERY = graphql`
  query EventProfileProvider_GetUpcomingOccurrencesQuery($slug: String!, $organizerSlug: String!) {
    event_connection(
      where: {
        _and: [
          { eventMetadata: { slug: { _eq: $slug } } }
          { deleted_at: { _is_null: true } }
          { organizer: { slug: { _eq: $organizerSlug } } }
        ]
      }
      order_by: [{ startDate: asc_nulls_last }]
    ) {
      edges {
        node {
          startDate
          cuid
        }
      }
    }
  }
`;

// TODO: For future use when we have private events
//   Check for user permissions and events existence.
//   After we initialize we will know if we should redirect the user back to the homepage
const PermissionAndOccurrenceWrapper: ReactFCC = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const { eventSlug, organizerSlug } = useParams();
  const setOccurrenceToDisplayGlobal = useSetOccurrenceToDisplayGlobal();
  const [isLoading, setIsLoading] = useState(true);

  const occurrence = searchParams.get('occurrence') as string | undefined;

  /**
   * Get occurrences from DB
   */
  const fetchMostRecentOccurrence = useCallback(
    (slug: string, orgSlug: string): Promise<string | undefined> => {
      return new Promise((resolve, reject) => {
        fetchQuery<EventProfileProvider_GetUpcomingOccurrencesQuery>(
          environment,
          GET_MOST_RECENT_OCCURRENCE_QUERY,
          { slug, organizerSlug: orgSlug },
        ).subscribe({
          next: (data) => {
            const events = data.event_connection.edges.map((edge) => edge.node);
            if (!events.length) {
              return resolve(undefined);
            }
            // No published events yet
            if (!events.some((event) => event.startDate)) {
              return resolve(events[0].cuid);
            }
            // Get the closest event start date to today
            const closestDate = closestTo(
              new Date(),
              events
                .map((event) => (event.startDate ? parseISO(event.startDate) : undefined))
                .filter(Boolean) as Date[],
            );
            // Now that we have the closest date, get the event that matches that date
            const closestEvent = events.find(
              (event) =>
                event.startDate && closestDate && isEqual(parseISO(event.startDate), closestDate),
            );
            if (closestEvent) {
              return resolve(closestEvent.cuid);
            }
            // fallback to first event
            return resolve(events[0].cuid);
          },
          error: (err: Error) => {
            reject(err);
          },
        });
      });
    },
    [GET_MOST_RECENT_OCCURRENCE_QUERY],
  );

  // Recoil values will stay if we dont un-set them
  useResetGlobalStateOnUnmount();

  /**
   * Check for an occurrence on mount
   * Don't set the occurrence in the url ever. We use that info after the query.
   */
  useMount(() => {
    // Do we have an occurrence in the URL?
    if (occurrence) {
      setOccurrenceToDisplayGlobal(occurrence);
      setIsLoading(false);
      return;
    }

    // No occurrence, grab the first upcoming event
    (async (): Promise<void> => {
      setIsLoading(true);
      const occurrenceCuid = await fetchMostRecentOccurrence(eventSlug!, organizerSlug!);
      if (occurrenceCuid) {
        setOccurrenceToDisplayGlobal(occurrenceCuid);
      } else {
        enqueueSnackbar('Event not found', { variant: 'error' });
        navigate(AppRouteService.getRelativeUrl('Search'), { replace: true });
      }
      setIsLoading(false);
    })();
  });

  if (isLoading) {
    return <FullScreenLoadingPlaceholder />;
  }
  return <EventProfileProvider>{children}</EventProfileProvider>;
};

const EventProfileProvider: ReactFCC = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { eventSlug } = useTypedParams(['eventSlug']);
  const { isCurrentOrgAdmin, isCurrentOrgOwner } = useOrganizerPermissions();
  const occurrenceToDisplayGlobal = useGetOccurrenceToDisplayGlobal();
  // $userId is used in the helpers_useGetIsEventAtCapacity_public_event fragment
  const eventProfileData = useLazyLoadQuery<EventProfileProviderQuery>(
    graphql`
      query EventProfileProviderQuery($eventCuid: String!, $userId: String!) {
        event_connection(
          where: { _and: [{ cuid: { _eq: $eventCuid } }, { deleted_at: { _is_null: true } }] }
        ) {
          edges {
            node {
              id
              publishedAt
              cuid
              ...helpers_useGetIsEventAtCapacity_public_event
              ...EventProfileView_event
              ...AlreadyRegisteredInfo_event
              ...NonCategoriedRegisterMobile_public_event
              ...NonCategoriedRegistrationCard_public_event
              ...SeeDatesDialog_public_event
              ...CategoriedRegistrationCard_public_event
              ...CategoriedRegisterMobile_public_event
              ...RefineCategoriesCard_public_event
              ...OccurrenceSelector_public_event
              ...EventTitle_public_event
              ...DesktopPicture_public_event
              ...MobilePicture_public_event
              ...OrganizerInfo_public_event
              ...PricesCategoriedPrices_public_event
              ...PricesNonCategoriedPrices_public_event
              ...AboutSection_public_event
              ...AdditionalInformationSection_public_event
              ...CategoriesSection_public_event
              ...CategoryInfo_public_event
              ...ViewParticipantsDialog_public_event
              ...CoursesSection_public_event
              ...ScheduleSection_public_event
              ...SeriesSection_public_event
              ...CoachesSection_public_event
              ...VibeSection_public_event
              ...AmenitiesSection_public_event
              ...VirtualSection_public_event
              ...LocationSection_public_event
              ...ChecklistSection_public_event
              ...ProgramSection_public_event
              ...TicketsSection_public_event
              ...SponsorsSection_public_event
              ...OrganizerInfoSection_public_event
              ...QuestionAnswerContainer_public_event
              ...UpdateContainer_public_event
              ...OrganizerReviewsSection_public_event
              ...RelatedEvents_public_event
              ...ReviewsSection_public_event
            }
          }
        }
      }
    `,
    { eventCuid: occurrenceToDisplayGlobal, userId: auth.firebaseUser?.uid ?? NON_EXISTENT_CUID },
    { fetchKey: `${occurrenceToDisplayGlobal}${eventSlug}` },
  );

  // No Event Data
  if (R.isEmpty(eventProfileData.event_connection.edges)) {
    const occurrence = getParsedQueryParams().occurrence as string | undefined;
    if (occurrence) {
      // Redirect them back to the event page without an occurrence.
      // We do this because a user could go to an invalid occurrence with a valid slug and we
      // would tell them the event is not found
      // window.location.href = window.location.pathname;
      // navigate(window.location.pathname, { replace: true });
      // window.location.reload();
      window.location.replace(window.location.pathname);
      return null;
    }
    enqueueSnackbar('Event not found', { variant: 'error' });
    navigate(AppRouteService.getRelativeUrl('Search'), { replace: true });
  }

  // Only allow editors to view an event before it is published
  const event = eventProfileData.event_connection.edges[0].node;
  const editPermission = isCurrentOrgOwner || isCurrentOrgAdmin;

  // If the event is not published, wait for the user to initialize before we kick them out.
  if (!event.publishedAt && auth.isInitializing) {
    return <FullScreenLoadingPlaceholder />;
  }
  // No Event Data or no permissions
  if (!event.publishedAt && !editPermission) {
    return <Navigate replace to={AppRouteService.getRelativeUrl('NotFound')} />;
  }

  return (
    <eventProfileContext.Provider
      value={{
        ...eventProfileData.event_connection.edges[0].node,
      }}
    >
      {children}
    </eventProfileContext.Provider>
  );
};

const eventProfileContext = createContext({});

// Hook for child components to get the root relay query.
export const useEventProfileData = (): EventProfileProviderQueryResponse['event_connection']['edges'][0]['node'] => {
  return useContext(
    eventProfileContext,
  ) as EventProfileProviderQueryResponse['event_connection']['edges'][0]['node'];
};

export default PermissionAndOccurrenceWrapper;
