/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewServiceQueries_getRecentReviews_QueryVariables = {
    userId: string;
    oneYearAgoDate: string;
};
export type ReviewServiceQueries_getRecentReviews_QueryResponse = {
    readonly review_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly reviewSubjectCuid: string;
            };
        }>;
    };
};
export type ReviewServiceQueries_getRecentReviews_Query = {
    readonly response: ReviewServiceQueries_getRecentReviews_QueryResponse;
    readonly variables: ReviewServiceQueries_getRecentReviews_QueryVariables;
};



/*
query ReviewServiceQueries_getRecentReviews_Query(
  $userId: String!
  $oneYearAgoDate: timestamptz!
) {
  review_connection(where: {reviewerId: {_eq: $userId}, created_at: {_gte: $oneYearAgoDate}}) {
    edges {
      node {
        id
        reviewSubjectCuid
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "oneYearAgoDate"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_gte",
                                    "variableName": "oneYearAgoDate"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "created_at"
                        },
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "userId"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "reviewerId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "reviewConnection",
            "kind": "LinkedField",
            "name": "review_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "reviewEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "review",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "reviewSubjectCuid",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_getRecentReviews_Query",
            "selections": (v2 /*: any*/),
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ReviewServiceQueries_getRecentReviews_Query",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "de1f80cbbc6a1be286c38fa1572e8c6d",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_getRecentReviews_Query",
            "operationKind": "query",
            "text": "query ReviewServiceQueries_getRecentReviews_Query(\n  $userId: String!\n  $oneYearAgoDate: timestamptz!\n) {\n  review_connection(where: {reviewerId: {_eq: $userId}, created_at: {_gte: $oneYearAgoDate}}) {\n    edges {\n      node {\n        id\n        reviewSubjectCuid\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '760f030e44d4bb7ecc14a78c3fd79905';
export default node;
