import { useRelayEnvironment } from 'react-relay/hooks';
import { isBefore, subSeconds } from 'date-fns';
import { fetchQuery, graphql, GraphQLTaggedNode } from 'relay-runtime';

import {
  helpers_IsEventAtCapacity_Query,
  helpers_IsEventAtCapacity_QueryResponse,
} from './__generated__/helpers_IsEventAtCapacity_Query.graphql';

import { NON_EXISTENT_CUID } from '@/const';
import {
  helpers_IsCanceledClinic_Query,
  helpers_IsCanceledClinic_QueryResponse,
} from '@/containers/Registration/__generated__/helpers_IsCanceledClinic_Query.graphql';
import {
  helpers_IsCanceledEvent_Query,
  helpers_IsCanceledEvent_QueryResponse,
} from '@/containers/Registration/__generated__/helpers_IsCanceledEvent_Query.graphql';
import {
  helpers_IsCanceledRide_Query,
  helpers_IsCanceledRide_QueryResponse,
} from '@/containers/Registration/__generated__/helpers_IsCanceledRide_Query.graphql';
import { RegistrationFormSchemaType } from '@/containers/Registration/RegistrationSteps/RegistrationFormSchema';
import {
  dateFromFloatingDateString,
  initializeDateToTimezone,
} from '@/lib/date-helpers/date-utils';
import { useAuth } from '@/providers/AuthProvider';

interface ClinicShape {
  readonly clinicDays:
    | readonly {
        readonly startTime: string;
      }[]
    | undefined;
}
interface RideShape {
  readonly rideDays:
    | readonly {
        readonly startTime: string;
      }[]
    | undefined;
}
export const isTodayBeforeNonCategoriedDeadlineFactory = (
  nonCategoriedRegDeadlineInSeconds: number | null,
  timezone?: string,
) => (instance: ClinicShape | RideShape): boolean => {
  const instanceDays = 'clinicDays' in instance ? instance.clinicDays : instance.rideDays;
  return Boolean(
    instanceDays &&
      instanceDays.length > 0 &&
      instanceDays[0].startTime &&
      isBefore(
        new Date(),
        subSeconds(
          initializeDateToTimezone(dateFromFloatingDateString(instanceDays[0].startTime), timezone),
          nonCategoriedRegDeadlineInSeconds || 0,
        ),
      ),
  );
};

/**
 * Check if the event or clinic has been canceled before we complete registration
 */
const IS_CANCELED_EVENT_QUERY = graphql`
  query helpers_IsCanceledEvent_Query($eventCuid: String!) {
    event_connection(where: { cuid: { _eq: $eventCuid } }) {
      edges {
        node {
          canceledAt
          deleted_at
        }
      }
    }
  }
`;
const IS_CANCELED_CLINIC_QUERY = graphql`
  query helpers_IsCanceledClinic_Query($clinicCuid: String!) {
    clinic_connection(where: { cuid: { _eq: $clinicCuid } }) {
      edges {
        node {
          canceledAt
        }
      }
    }
  }
`;
const IS_CANCELED_RIDE_QUERY = graphql`
  query helpers_IsCanceledRide_Query($rideCuid: String!) {
    ride_connection(where: { cuid: { _eq: $rideCuid } }) {
      edges {
        node {
          canceledAt
        }
      }
    }
  }
`;
export const useCheckIfEventCanceledQuery = (): ((
  formData: RegistrationFormSchemaType,
  eventCuid: string,
  isCategoriedEvent: boolean,
) => Promise<boolean>) => {
  const environment = useRelayEnvironment();
  function fetchData(
    refreshVariables: { clinicCuid: string },
    GQL_QUERY: GraphQLTaggedNode,
  ): Promise<helpers_IsCanceledClinic_QueryResponse>;
  function fetchData(
    refreshVariables: { rideCuid: string },
    GQL_QUERY: GraphQLTaggedNode,
  ): Promise<helpers_IsCanceledRide_QueryResponse>;
  function fetchData(
    refreshVariables: { eventCuid: string },
    GQL_QUERY: GraphQLTaggedNode,
  ): Promise<helpers_IsCanceledEvent_QueryResponse>;
  function fetchData(
    refreshVariables: { eventCuid: string } | { rideCuid: string } | { clinicCuid: string },
    GQL_QUERY: GraphQLTaggedNode,
  ): Promise<
    | helpers_IsCanceledEvent_QueryResponse
    | helpers_IsCanceledRide_QueryResponse
    | helpers_IsCanceledClinic_QueryResponse
  > {
    return new Promise((resolve, reject) => {
      fetchQuery<
        | helpers_IsCanceledEvent_Query
        | helpers_IsCanceledRide_Query
        | helpers_IsCanceledClinic_Query
      >(environment, GQL_QUERY, refreshVariables).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err: Error) => {
          reject(err);
        },
      });
    });
  }

  return async (
    formData: RegistrationFormSchemaType,
    eventCuid: string,
    isCategoriedEvent: boolean,
  ): Promise<boolean> => {
    if (isCategoriedEvent) {
      // Check if event canceled
      const eventCanceled = await fetchData({ eventCuid }, IS_CANCELED_EVENT_QUERY);
      return !!eventCanceled.event_connection.edges[0].node.canceledAt;
    }

    // Get all clinics
    const clinicCuids = [];
    formData.clinicDate.others?.forEach((clinicDate) => {
      if (clinicDate.clinic) {
        clinicCuids.push(clinicDate.clinic);
      }
    });
    if (formData.rideDate.yourself?.clinic) {
      clinicCuids.push(formData.rideDate.yourself.clinic);
    }
    // Get all rides
    const rideCuids = [];
    formData.rideDate.others?.forEach((rideDate) => {
      if (rideDate.ride) {
        rideCuids.push(rideDate.ride);
      }
    });
    if (formData.rideDate.yourself?.ride) {
      rideCuids.push(formData.rideDate.yourself.ride);
    }

    // Check if clinic canceled
    const canceledClinics = await Promise.all(
      clinicCuids.map(async (clinicCuid) => {
        const clinicCanceled = await fetchData({ clinicCuid }, IS_CANCELED_CLINIC_QUERY);
        return !!clinicCanceled.clinic_connection.edges[0].node.canceledAt;
      }),
    );
    // Check if ride canceled
    const canceledRides = await Promise.all(
      rideCuids.map(async (rideCuid) => {
        const rideCanceled = await fetchData({ rideCuid }, IS_CANCELED_RIDE_QUERY);
        return !!rideCanceled.ride_connection.edges[0].node.canceledAt;
      }),
    );
    // There are no canceled events, rides or clinics
    const anyClinicsCanceled = canceledClinics.some((canceled) => canceled);
    const anyRidesCanceled = canceledRides.some((canceled) => canceled);
    const hasClinicsSelected = clinicCuids.length > 0;
    const hasRidesSelected = rideCuids.length > 0;
    const hasSelections = hasClinicsSelected || hasRidesSelected;

    return hasSelections && (anyClinicsCanceled || anyRidesCanceled);
  };
};

/**
 * Check if the event is full before we complete registration
 */
const IS_EVENT_AT_CAPACITY = graphql`
  query helpers_IsEventAtCapacity_Query($eventCuid: String!, $userId: String!) {
    event_connection(where: { cuid: { _eq: $eventCuid } }) {
      edges {
        node {
          spotsAvailable
          registrations_aggregate(
            where: {
              _and: [
                {
                  _or: [
                    # Not our registration
                    {
                      _and: [
                        { userId: { _neq: $userId } }
                        { status: { _in: [complete, in_progress] } }
                      ]
                    }
                    # Our own registration
                    { _and: [{ userId: { _eq: $userId } }, { status: { _in: [complete] } }] }
                  ]
                }
                # All Registrations have a category
                { _not: { registrationCategories: { categoryCuid: { _is_null: true } } } }
              ]
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
export const useCheckIfEventIsAtCapacityQuery = (): ((
  eventCuid: string,
  isCategoriedEvent: boolean,
) => Promise<boolean>) => {
  const auth = useAuth();
  const environment = useRelayEnvironment();
  function fetchData(refreshVariables: {
    eventCuid: string;
    userId: string;
  }): Promise<helpers_IsEventAtCapacity_QueryResponse> {
    return new Promise((resolve, reject) => {
      fetchQuery<helpers_IsEventAtCapacity_Query>(
        environment,
        IS_EVENT_AT_CAPACITY,
        refreshVariables,
      ).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err: Error) => {
          reject(err);
        },
      });
    });
  }

  return async (eventCuid: string, isCategoriedEvent: boolean): Promise<boolean> => {
    if (!isCategoriedEvent) {
      return false;
    }
    // Check if event canceled
    const response = await fetchData({
      eventCuid,
      userId: auth.firebaseUser?.uid ?? NON_EXISTENT_CUID,
    });
    const {
      registrations_aggregate: { aggregate },
      spotsAvailable,
    } = response.event_connection.edges[0].node;

    // Total Registrations
    const totalRegistrations = aggregate?.count || 0;
    return !!spotsAvailable && totalRegistrations >= spotsAvailable;
  };
};
