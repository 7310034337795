/**
 * Header for Create Event
 */
import React, { ReactElement, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router';
import {
  Box,
  Card,
  CircularProgress,
  Hidden,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import * as RA from 'ramda-adjunct';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import LinkButton from '@/ui/Button/LinkButton';
import ExplainerPopover from '@/ui/ExplainerPopover';
import ExtendedLink from '@/ui/ExtendedLink';
import GlobalLoadingBackdrop from '@/ui/GlobalLoadingBackdrop';

import DeleteConfirmClausesDialog from '@/components/Dialogs/DeleteConfirmationClausesDialog';
import ElevationScroll from '@/components/Header/ElevationScroll';
import SectionErrors from '@/components/HostEvent/Components/SectionErrors';

import { useTrackSentryError } from '@/analytics/sentry';
import { IS_NEW_EVENT_QUERY_PARAM } from '@/const';
import EditEventNameDialog from '@/containers/HostEvent/EventMetadataDialog/EditEventNameDialog';
import { useDeleteEventAndOccurrencesMutation } from '@/containers/HostEvent/EventMetadataDialog/eventMetadataMutations';
import OccurrencesDialog from '@/containers/HostEvent/EventMetadataDialog/OccurrencesDialog';
import {
  ADD_OCCURRENCE,
  scrollToSection,
  useGetEventCuid,
  useGetEventLink,
  useGetEventSlug,
  useGetEventValidity,
  useGetOrganizerCuid,
} from '@/containers/HostEvent/helpers';
import { usePublishEventMutation } from '@/containers/HostEvent/hostEventMutations';
import { HeaderContainer_event } from '@/containers/HostEvent/Navigation/__generated__/HeaderContainer_event.graphql';
import {
  useGetEventHasUnSavedChangesGlobal,
  useGetIsEventSavingGlobal,
  useGetSectionInfoGlobal,
} from '@/containers/HostEvent/recoilStore';
import { duplicateEventCF } from '@/lib/firebase';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useSaveHostEvent } from '@/providers/HostEventProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useHeaderStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: Colors.White,
    border: 'none',
    transition: theme.transitions.create(['width', 'margin', 'box-shadow', 'border-color'], {
      easing: theme.transitions.easing.easeOut,
      duration: 0,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  eventName: {
    maxWidth: '25vw',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  lastSavedAt: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      whiteSpace: 'normal',
    },
  },
  lastSavedAtContainer: {
    marginRight: theme.spacing(0.5),
    minWidth: '182px',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    transition: theme.transitions.create(['width', 'margin', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  menuButtonWrapper: {
    paddingLeft: 72,
    position: 'absolute',
  },
  moreButton: {
    padding: theme.spacing(1),
  },
  publishContainer: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
    },
  },
  eventInfo: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    // marginTop: '17vh',
  },
}));

enum PublishButtonTextEnum {
  Publish = 'Publish',
  UnPublish = 'Un-Publish',
  PublishChanges = 'Publish Changes',
  // EventInvalid = 'Event Invalid',
  GoToError = 'Go To Error',
}
interface HeaderProps {
  activity: string;
  currentProgress: number;
  eventDateRange: string;
  eventLastSavedAt: string;
  eventMetadata: HeaderContainer_event['eventMetadata'];
  hasRegistrations: boolean;
  isEventCreationComplete: boolean;
  isEventCanceled: boolean;
  isEventPassed: boolean;
  isEventPublished: boolean;
  showUnPublishEventConfirmationDialog: () => void;
}
const Header: React.FC<HeaderProps> = React.memo(
  ({
    activity,
    currentProgress,
    eventDateRange,
    eventLastSavedAt,
    eventMetadata,
    hasRegistrations,
    isEventCanceled,
    isEventCreationComplete,
    isEventPassed,
    isEventPublished,
    showUnPublishEventConfirmationDialog,
  }) => {
    const { cuid: eventMetadataCuid, isCategoriedEvent, name: eventName } = eventMetadata;
    const classes = useHeaderStyles();
    const navigate = useNavigate();
    const eventSlug = useGetEventSlug();
    const eventCuid = useGetEventCuid();
    const { enqueueSnackbar } = useSnackbar();
    const organizerCuid = useGetOrganizerCuid();
    const trackSentryError = useTrackSentryError();
    const { isEventValid } = useGetEventValidity();
    const { commitSaveQueue } = useSaveHostEvent();
    const publishEvent = usePublishEventMutation();
    const isSmallScreenDown = useIsSmallScreenDown();
    const isEventSaving = useGetIsEventSavingGlobal();
    const { invalidSections } = useGetEventValidity();
    const sectionInfoGlobal = useGetSectionInfoGlobal();
    const eventHasUnSavedChanges = useGetEventHasUnSavedChangesGlobal();
    const { organizerSlug } = useTypedParams(['organizerSlug']);
    const eventLink = useGetEventLink(isCategoriedEvent ? ADD_OCCURRENCE : undefined);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
    const [isOccurrencesDialogOpen, setIsOccurrencesDialogOpen] = useState(false);
    const [isEditEventNameDialogOpen, setIsEditEventNameDialogOpen] = useState(false);

    const firstSectionWithError = invalidSections?.[0];

    // const isButtonDisabled = (eventHasUnSavedChanges || !isEventPublished) && !isEventValid;
    let actionButtonText: PublishButtonTextEnum = PublishButtonTextEnum.Publish;
    if (isEventCreationComplete) {
      if (!isEventValid) {
        actionButtonText = PublishButtonTextEnum.GoToError;
      } else if (eventHasUnSavedChanges) {
        actionButtonText = PublishButtonTextEnum.PublishChanges;
      } else if (isEventPublished) {
        actionButtonText = PublishButtonTextEnum.UnPublish;
      }
    }
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const { deleteEventAndOccurrencesMutation } = useDeleteEventAndOccurrencesMutation();

    /**
     * Delete event and all occurrences
     */
    const showDeleteConfirmationDialog = (): void => {
      setIsDeleteDialogOpen(true);
    };
    const hideDeleteConfirmationDialog = (): void => {
      setIsDeleteDialogOpen(false);
    };
    const deleteEvent = async (): Promise<void> => {
      try {
        if (hasRegistrations) {
          enqueueSnackbar('Cannot delete event with registrations', { variant: 'error' });
          return;
        }
        await deleteEventAndOccurrencesMutation(
          eventMetadataCuid,
          eventMetadata.events.map((e) => e.cuid),
        );
      } catch (err) {
        trackSentryError(err);
      }
    };

    /**
     * Handle settings menu
     */
    const showOccurrenceMenuOptions = (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      setMenuAnchorEl(e.currentTarget);
    };
    const closeOccurrenceMenuOptions = (e: React.MouseEvent<HTMLLIElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      setMenuAnchorEl(null);
    };

    // Select occurrence dialog
    const showOccurrencesDialog = (): void => {
      setIsOccurrencesDialogOpen(true);
      setMenuAnchorEl(null);
    };
    const hideOccurrencesDialog = (): void => setIsOccurrencesDialogOpen(false);

    // Edit event name dialog
    const showEditEventNameDialog = (): void => {
      setIsEditEventNameDialogOpen(true);
      setMenuAnchorEl(null);
    };
    const hideEditEventNameDialog = (): void => setIsEditEventNameDialogOpen(false);

    /**
     * Duplicate event
     */
    const handleDuplicateEvent = async (e: React.MouseEvent<HTMLLIElement>): Promise<void> => {
      try {
        e.preventDefault();
        e.stopPropagation();
        closeOccurrenceMenuOptions(e);
        setIsDuplicateLoading(true);
        await duplicateEventCF({
          eventCuid,
          organizerCuid,
          shouldCreateNewEvent: true,
        });

        window.location.href = AppRouteService.getAbsoluteUrl('OrganizerApp_Events', {
          organizerSlug,
        });
      } catch (error) {
        trackSentryError(error);
      } finally {
        setIsDuplicateLoading(false);
      }
    };

    /**
     * Navigate back to events page
     */
    const navigateToEventsPage = (): void => {
      navigate(AppRouteService.getRelativeUrl('OrganizerApp_Events', { organizerSlug }));
    };
    /**
     * Handle either publish, un-publish, or save changes
     */
    const handleActionButton = (): void => {
      if (!isEventPublished) {
        // Publish Event
        publishEvent();
        if (isEventCreationComplete) {
          enqueueSnackbar('Event published!', { variant: 'success' });
          return;
        }
        navigate(
          AppRouteService.getRelativeUrl(
            'OrganizerProfile_Event',
            { organizerSlug, eventSlug },
            { occurrence: eventCuid, [IS_NEW_EVENT_QUERY_PARAM]: true },
          ),
        );
      } else if (!isEventValid) {
        // Event is Invalid
        scrollToSection(firstSectionWithError);
      } else if (eventHasUnSavedChanges) {
        // Unsaved Changes
        commitSaveQueue();
      } else {
        showUnPublishEventConfirmationDialog();
      }
    };

    return (
      <ElevationScroll>
        <AppBar className={classNames(classes.appBar)} position="fixed">
          <GlobalLoadingBackdrop isLoading={isDuplicateLoading} title="Duplicating Event" />
          {isOccurrencesDialogOpen && (
            <OccurrencesDialog
              handleClose={hideOccurrencesDialog}
              isOpen={isOccurrencesDialogOpen}
            />
          )}
          {isEditEventNameDialogOpen && (
            <EditEventNameDialog
              handleClose={hideEditEventNameDialog}
              isOpen={isEditEventNameDialogOpen}
            />
          )}

          {isDeleteDialogOpen && (
            <DeleteConfirmClausesDialog
              clauses={[
                'I will not be able to recover them.',
                'I will still be able to see my registration/revenue stats and the cyclist will still be able to see their history for this event.',
                "I'm very sure I want to delete this event.",
              ]}
              clausesPre="I understand that once I delete this event and all of it's occurrences,"
              isOpen={isDeleteDialogOpen}
              onClose={hideDeleteConfirmationDialog}
              onDelete={deleteEvent}
              title="Delete Event"
            />
          )}
          <Toolbar className={classes.toolBar}>
            <IconButton
              aria-label="go back"
              className={classNames(classes.menuButton)}
              color="inherit"
              edge="start"
              onClick={navigateToEventsPage}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
              <Hidden smDown>
                <Typography className={classes.eventName} variant="h6">
                  {eventName}
                </Typography>
              </Hidden>
              <Box className={classes.eventInfo}>
                {isSmallScreenDown ? (
                  <IconButton
                    aria-label="more options"
                    color="inherit"
                    onClick={showOccurrenceMenuOptions}
                  >
                    <SettingsIcon />
                  </IconButton>
                ) : (
                  <ExtendedButton
                    onClick={showOccurrenceMenuOptions}
                    size="small"
                    startIcon={<SettingsIcon />}
                    variant="outlined"
                  >
                    {isCategoriedEvent ? eventDateRange : 'Event Settings'}
                  </ExtendedButton>
                )}
                {Boolean(menuAnchorEl) && (
                  <Menu
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom',
                    }}
                    getContentAnchorEl={null}
                    onBackdropClick={closeOccurrenceMenuOptions}
                    open={Boolean(menuAnchorEl)}
                    transformOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom',
                    }}
                  >
                    <MenuItem onClick={showEditEventNameDialog}>Edit Event Name</MenuItem>
                    <MenuItem onClick={handleDuplicateEvent}>Duplicate Event</MenuItem>
                    {isCategoriedEvent && (
                      <>
                        <MenuItem onClick={showOccurrencesDialog}>Delete Event</MenuItem>
                        <ListSubheader component="div">
                          <Divider />
                        </ListSubheader>
                        <MenuItem onClick={showOccurrencesDialog}>
                          Duplicate/Delete Occurrence
                        </MenuItem>
                      </>
                    )}
                    {!isCategoriedEvent && (
                      <>
                        <ListSubheader component="div">
                          <Divider />
                        </ListSubheader>
                        <MenuItem
                          onClick={showDeleteConfirmationDialog}
                          style={{ color: Colors.Danger }}
                        >
                          Delete Event
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                )}
              </Box>
              <Box className={classes.infoContainer}>
                <Box className={classes.publishContainer}>
                  <Hidden xsDown>
                    <Box className={classes.lastSavedAtContainer}>
                      {isEventSaving ? (
                        <Box display="flex">
                          <Box height="16px" m="auto 8px auto auto">
                            <CircularProgress size={16} />
                          </Box>
                          <Typography align="right" variant="subtitle2">
                            Saving
                          </Typography>
                        </Box>
                      ) : (
                        <Typography className={classes.lastSavedAt} variant="subtitle2">
                          Saved {eventLastSavedAt} ago
                        </Typography>
                      )}
                    </Box>
                  </Hidden>
                  {!isEventValid && (
                    <ExplainerPopover
                      clickToOpen
                      IconComponent={WarningRoundedIcon}
                      boxProps={{ ml: 1, mr: 0 }}
                      iconColor="error"
                      maxWidth="none"
                    >
                      {sectionInfoGlobal.availableSections.map(
                        (sectionName): null | ReactElement => {
                          return (
                            <SectionErrors
                              showSectionName
                              key={`${sectionName}header`}
                              sectionName={sectionName}
                            />
                          );
                        },
                      )}
                      {RA.isNotNilOrEmpty(invalidSections) && (
                        <Card variant="outlined">
                          <Box p={1.5}>
                            <Typography gutterBottom variant="h4">
                              Invalid Sections
                            </Typography>
                            <Box display="flex" flexDirection="column">
                              {invalidSections.map((invalidSectionName) => (
                                <Box
                                  display="flex"
                                  key={`${invalidSectionName}invalid-section-name`}
                                >
                                  <LinkButton
                                    useButtonText
                                    onClick={(): void => scrollToSection(invalidSectionName)}
                                  >
                                    {invalidSectionName}
                                  </LinkButton>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Card>
                      )}
                    </ExplainerPopover>
                  )}
                  {!isEventPassed && !isEventCanceled && (
                    <Box ml={1.5}>
                      <ExtendedButton
                        color={
                          actionButtonText === PublishButtonTextEnum.UnPublish ||
                          actionButtonText === PublishButtonTextEnum.GoToError
                            ? undefined
                            : 'primary'
                        }
                        disabled={!isEventValid && !isEventCreationComplete}
                        errorColor={
                          actionButtonText === PublishButtonTextEnum.UnPublish ||
                          actionButtonText === PublishButtonTextEnum.GoToError
                        }
                        onClick={handleActionButton}
                        size="medium"
                        style={{ minWidth: 90, whiteSpace: 'nowrap' }}
                        variant={
                          actionButtonText === PublishButtonTextEnum.UnPublish ||
                          actionButtonText === PublishButtonTextEnum.GoToError
                            ? 'outlined'
                            : 'contained'
                        }
                      >
                        {actionButtonText}
                      </ExtendedButton>
                    </Box>
                  )}
                  <Box ml={1.5}>
                    <ExtendedLink noUnderline href={eventLink}>
                      <ExtendedButton
                        color="primary"
                        size="medium"
                        style={{ whiteSpace: 'nowrap' }}
                        variant="outlined"
                      >
                        {isEventPublished
                          ? `View${isSmallScreenDown ? '' : ` ${activity}`}`
                          : 'Preview'}
                      </ExtendedButton>
                    </ExtendedLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Toolbar>
          <LinearProgress style={{ height: '6px' }} value={currentProgress} variant="determinate" />
        </AppBar>
      </ElevationScroll>
    );
  },
  isEqual,
);

export default Header;
