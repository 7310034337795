/*
Recoil atoms, selectors and hooks for Auth (login, signup, etc)
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * Is login dialog open
 ************************************* */
const isLoginDialogOpen = atom<boolean>({
  key: 'isLoginDialogOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsLoginDialogOpenGlobal = (): boolean => {
  return useRecoilValue(isLoginDialogOpen);
};
export const useSetIsLoginDialogOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState<boolean>(isLoginDialogOpen);
};

/** ************************************
 * Is signup dialog open
 ************************************* */
const isSignupDialogOpen = atom<boolean>({
  key: 'isSignupDialogOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsSignupDialogOpenGlobal = (): boolean => {
  return useRecoilValue(isSignupDialogOpen);
};
export const useSetIsSignupDialogOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState<boolean>(isSignupDialogOpen);
};

/** ************************************
 * Is re-authenticate dialog open
 ************************************* */
const isReAuthDialogOpen = atom<boolean>({
  key: 'isReAuthDialogOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsReAuthDialogOpenGlobal = (): boolean => {
  return useRecoilValue(isReAuthDialogOpen);
};
export const useSetIsReAuthDialogOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState<boolean>(isReAuthDialogOpen);
};

/** ************************************
 * Is the login/signup dialog blocking and undismissable
 ************************************* */
const isLoginSignupDialogBlocking = atom<boolean>({
  key: 'isLoginSignupDialogBlocking',
  default: false,
});
export const useGetIsLoginSignupDialogBlockingGlobal = (): boolean => {
  return useRecoilValue(isLoginSignupDialogBlocking);
};
export const useSetIsLoginSignupDialogBlockingGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState<boolean>(isLoginSignupDialogBlocking);
};
/** ************************************
 * What type of blocking dialog is open
 ************************************* */
type BlockingModeType = 'login' | 'signup';
const loginSignupDialogBlockingMode = atom<BlockingModeType>({
  key: 'loginSignupDialogBlockingMode',
  default: 'login',
});
export const useGetLoginSignupDialogBlockingModeGlobal = (): BlockingModeType => {
  return useRecoilValue(loginSignupDialogBlockingMode);
};
export const useSetLoginSignupDialogBlockingModeGlobal = (): SetterOrUpdater<BlockingModeType> => {
  return useSetRecoilState<BlockingModeType>(loginSignupDialogBlockingMode);
};

/** ************************************
 * Global Redirect URL
 * When we go to a page that requires a login we store the page here
 ************************************* */
const authRedirectURL = atom<string | null>({
  key: 'authRedirectURL', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const useGetAuthRedirectURLGlobal = (): string | null => {
  return useRecoilValue(authRedirectURL);
};
export const useSetAuthRedirectURLGlobal = (): SetterOrUpdater<string | null> => {
  return useSetRecoilState<string | null>(authRedirectURL);
};

/** ************************************
 * Login/sign up teaser message variants
 ************************************* */
export type TeaserMessage =
  | 'empty'
  | 'default'
  | 'registration'
  | 'onlineCourse'
  | 'waitlist'
  | 'waitlistCode';
const loginSignupTeaserMessage = atom<TeaserMessage>({
  key: 'loginSignupTeaserMessage',
  default: 'empty',
});
export const useGetLoginSignupTeaserMessageGlobal = (): TeaserMessage => {
  return useRecoilValue(loginSignupTeaserMessage);
};
export const useSetLoginSignupTeaserMessageGlobal = (): SetterOrUpdater<TeaserMessage> => {
  return useSetRecoilState<TeaserMessage>(loginSignupTeaserMessage);
};

export type TeaserMessageProps = Record<string, unknown> | null;
const loginSignupTeaserMessageProps = atom<TeaserMessageProps>({
  key: 'loginSignupTeaserMessageProps',
  default: null,
});
export const useGetLoginSignupTeaserMessagePropsGlobal = (): TeaserMessageProps => {
  return useRecoilValue(loginSignupTeaserMessageProps);
};
export const useSetLoginSignupTeaserMessagePropsGlobal = (): SetterOrUpdater<TeaserMessageProps> => {
  return useSetRecoilState<TeaserMessageProps>(loginSignupTeaserMessageProps);
};
