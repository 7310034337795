import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { Colors } from '@/themes/colors';

const useStyles = makeStyles(() => ({
  typeformButton: {
    color: '#666666',
    background: 'transparent',
    border: 'none',
    padding: '0',
    textDecoration: 'none',
    // font-size: '1em',
    cursor: 'pointer',
    textAlign: 'left',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  blue: {
    color: Colors.Blue,
  },
}));

export class Frill {
  widget(config: FrillConfig): FrillWidget;
}
class FrillWidget {
  open: () => void;

  close: () => void;

  destroy: () => void;
}
export interface FrillConfig {
  key: string;
  container?: HTMLElement;
  ssoToken?: string;
  callbacks: {
    onReady(frillWidget: FrillWidget): void;
  };
}
interface FrillButtonProps {
  title?: string;
  blue?: boolean;
}
export const FrillButton: React.FC<FrillButtonProps> = React.memo(({ blue, title }) => {
  const classes = useStyles();
  const widgetRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let widget: FrillWidget;
    const config: FrillConfig = {
      key: 'b356c6af-a9a4-4ba9-8bd8-a88913c2e6ae', // <-- Add Widget key here
      container: widgetRef.current!,
      callbacks: {
        onReady: (frillWidget) => {
          widget = frillWidget;
        },
      },
    };
    window.Frill_Config = window.Frill_Config || [];
    window.Frill_Config.push(config);
    if ('Frill' in window) {
      widget = window.Frill.widget(config);
    }

    return () => {
      widget?.destroy();
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
      }
    };
  }, []);

  // We are going to render a button that will be used to open the widget. This is because
  // the example widget is using a "CSS selector" for the "Launcher".
  // If your widget does not use this launcher, you can return null.
  return (
    <Typography
      className={classNames('Frill_Trigger Frill_Trigger_Selector', classes.typeformButton, {
        [classes.blue]: blue,
      })}
      id="frill-widget-preview"
      variant="body2"
    >
      {title || (
        <>
          Report Bug or
          <br />
          Request Feature
        </>
      )}
    </Typography>
  );
});
export default FrillButton;
