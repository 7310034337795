import { Theme } from '@material-ui/core';

export const MuiInputAdornment = (theme: Theme): Record<string, unknown> => ({
  root: {
    // color: theme.palette.grey[500],
    color: 'rgba(0, 0, 0, .54)',
    '&.MuiInputAdornment-hiddenLabel.MuiInputAdornment-marginDense .MuiIconButton-root': {
      padding: 4,
      '&.MuiIconButton-edgeEnd': {
        marginRight: -4,
      },
      '&.MuiIconButton-edgeStart': {
        marginLeft: -4,
      },
    },
  },
});
