import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AppBar, Box, Container, Grid, makeStyles, Theme, Toolbar } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';

import BrandTypography from '@/ui/BrandTypography';
import ExtendedButton from '@/ui/Button/ExtendedButton';

import { IS_NEW_COACH_QUERY_PARAM } from '@/const';
import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.pageBackground.white,
  },
  grow: {
    flexGrow: 1,
  },
}));

const OnboardingLayout: ReactFCC = ({ children }) => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const exitToSite = (): void => {
    // Coach onboarding
    const becomeACoach = AppRouteService.getRelativeUrl('User_BecomeCoach');
    if (location.pathname.includes(becomeACoach)) {
      navigate(
        AppRouteService.getRelativeUrl(
          'CoachProfile',
          { coachSlug: auth.user?.slug },
          { [IS_NEW_COACH_QUERY_PARAM]: true },
        ),
      );
      return;
    }
    navigate(AppRouteService.getRelativeUrl('Home'));
  };

  return (
    <Box className={classes.root}>
      <AppBar className={classNames(headerClasses.appBar)} color="inherit" position="relative">
        <Toolbar>
          <Container>
            <Grid container>
              <Grid item>
                <BrandTypography noLink variant="h5">
                  Reggy
                </BrandTypography>
              </Grid>
              <Grid item className={classes.grow} />
              <Grid item>
                <ExtendedButton endIcon={<SaveIcon fontSize="small" />} onClick={exitToSite}>
                  Save & Exit
                </ExtendedButton>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>{children}</Container>
    </Box>
  );
};

export default OnboardingLayout;
