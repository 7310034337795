import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import ExtendedRoute from '@/routes/components/ExtendedRoute';

import OrganizerRoutes from './Private/Organizer/OrganizerRoutes';
import OrganizerProfileRoutes from './Profile/OrganizerProfileRoutes';
import { AppRouteService } from './RouteService';

import LoginDialog from '@/containers/Auth/Login/LoginDialog';
import ReAuthDialog from '@/containers/Auth/Login/ReAuthDialog';
import {
  useGetIsLoginDialogOpenGlobal,
  useGetIsReAuthDialogOpenGlobal,
  useGetIsSignupDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import SignupDialog from '@/containers/Auth/Signup/SignupDialog';
import BlankLayout from '@/layouts/BlankLayout';
import DiscoverPage from '@/pages/Discover/DiscoverPage';
import HomePage from '@/pages/HomePage';
import JoinWaitlistPage from '@/pages/JoinWaitlistPage';
import Page from '@/pages/Page';
import SearchPage from '@/pages/Search/SearchPage';
import ChangeLogPage from '@/pages/Static/ChangeLogPage';
import UserRoutes from '@/routes/Private/User/UserRoutes';
import CoachProfileRoutes from '@/routes/Profile/CoachProfileRoutes';
import CyclistProfileRoutes from '@/routes/Profile/CyclistProfileRoutes';

const AdminPage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "AdminPage" */ '@/pages/Admin/AdminPage'),
);

// Static
const BecomeACreatorView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "BecomeACreatorView" */ '@/containers/BecomeACreator/BecomeACreatorView'
    ),
);
const About = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "About" */ '@/pages/AboutPage'),
);
const TermsOfServicePage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "TermsOfServicePage" */ '@/pages/Static/TermsOfServicePage'),
);
const TermsOfServiceOrganizersPage = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermsOfServiceOrganizersPage" */ '@/pages/Static/TermsOfServiceOrganizersPage'
    ),
);
const PrivacyPolicyPage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '@/pages/Static/PrivacyPolicyPage'),
);

// Confirmation Pages
const SignWaiversPage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "SignWaiversPage" */ '@/pages/Registration/SignWaiversPage'),
);
const RegistrationCompletedPage = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegistrationCompletedPage" */ '@/pages/Registration/RegistrationCompletedPage'
    ),
);
const LessonPaymentCompletedPage = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "LessonPaymentCompletedPage" */ '@/pages/Registration/LessonRegistrationCompletedPage'
    ),
);
const OnlineCoursePurchaseCompletedPage = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCoursePurchaseCompletedPage" */ '@/pages/OnlineCourse/OnlineCoursePurchaseCompletedPage'
    ),
);

// Auth
const LoginContainer = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "LoginPage" */ '@/pages/Auth/LoginPage'),
);
const SignupContainer = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "SignupPage" */ '@/pages/Auth/SignupPage'),
);
const EmailVerificationRequired = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "EmailVerificationRequired" */
      '@/pages/Auth/EmailVerificationRequiredPage'
    ),
);
const ForgotPassword = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordPage" */
      '@/pages/Auth/ForgotPasswordPage'
    ),
);

// Search
// const SearchPage = LazyloadComponentWithRetry(
//   () =>
//     import(
//       /* webpackChunkName: "SearchPage" */
//       '@/pages/Search/SearchPage'
//     ),
// );
// const DiscoverPage = LazyloadComponentWithRetry(
//   () =>
//     import(
//       /* webpackChunkName: "DiscoverPage" */
//       '@/pages/Discover/DiscoverPage'
//     ),
// );

// Errors
const PageNotFound = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "PageNotFound" */ '@/pages/ErrorPages/PageNotFound'),
);
const ServerErrorPage = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "ServerErrorPage" */ '@/pages/ErrorPages/ServerErrorPage'),
);

// Insight Routes
const InsightsRoutes = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "InsightsRoutes" */ '@/routes/InsightsRoutes'),
);

const MainRoutes: React.FC = () => {
  const isSignupDialogOpenGlobal = useGetIsSignupDialogOpenGlobal();
  const isLoginDialogOpenGlobal = useGetIsLoginDialogOpenGlobal();
  const isReAuthDialogOpenGlobal = useGetIsReAuthDialogOpenGlobal();
  return (
    <>
      {isReAuthDialogOpenGlobal && <ReAuthDialog />}
      {isLoginDialogOpenGlobal && <LoginDialog />}
      {isSignupDialogOpenGlobal && <SignupDialog />}
      <Routes>
        {/* Admin */}
        <Route
          element={
            <ExtendedRoute adminRequired loginRequired trackPageView>
              <AdminPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Admin')}
        />

        {/* Insights */}
        <Route
          element={
            <ExtendedRoute>
              <InsightsRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('Insights')}
        />

        {/* /!* IMPORTANT! Don't change the order of these routes. *!/ */}
        {/* /!* We have /organizer and /org. React-Router needs *!/ */}
        {/* /!* the longer route to appear first in the tree to match both correctly *!/ */}
        {/* /!* Organizer Profile App. All based off of /org *!/ */}
        <Route
          element={
            <ExtendedRoute>
              <OrganizerProfileRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('OrganizerProfile')}
        />
        {/* /!* Organizer App. All based off of /o *!/ */}
        <Route
          element={
            <ExtendedRoute loginRequired>
              <OrganizerRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('Organizer')}
        />

        {/* User App. All based off of /u */}
        <Route
          element={
            <ExtendedRoute loginRequired>
              <UserRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('User')}
        />

        {/* Coach Profile Site. All based off of /coach */}
        <Route
          element={
            <ExtendedRoute>
              <CoachProfileRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('CoachProfile')}
        />

        {/* Cyclist Profile Site. All based off of /cyclist */}
        <Route
          element={
            <ExtendedRoute>
              <CyclistProfileRoutes />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('CyclistProfile')}
        />

        {/* Confirmation Pages */}
        <Route
          element={
            <ExtendedRoute loginRequired>
              <Routes>
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <RegistrationCompletedPage />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('Checkout_RegistrationComplete')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <LessonPaymentCompletedPage />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('Checkout_LessonPaymentComplete')}
                />
                <Route
                  element={
                    <ExtendedRoute trackPageView>
                      <OnlineCoursePurchaseCompletedPage />
                    </ExtendedRoute>
                  }
                  path={AppRouteService.getRouterRoutePath('Checkout_OnlineCoursePurchaseComplete')}
                />
              </Routes>
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterDirectoryPath('Checkout')}
        />

        {/* Static Pages */}
        <Route
          element={
            <ExtendedRoute trackPageView>
              <HomePage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Home')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <Page noIndex title="Become a Creator • Reggy">
                <BlankLayout>
                  <BecomeACreatorView />
                </BlankLayout>
              </Page>
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('BecomeACreator')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <JoinWaitlistPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('JoinWaitlist')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <SignWaiversPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('SignWaivers')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <About />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('About')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <SearchPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Search')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <DiscoverPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Discover')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <Suspense
                fallback={<LoadingPlaceholder absoluteCenter disableShrink isLoading delay={200} />}
              >
                <ChangeLogPage />
              </Suspense>
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('ChangeLog')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <PrivacyPolicyPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('PrivacyPolicy')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <TermsOfServicePage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('TermsOfService')}
        />
        <Route
          element={
            <ExtendedRoute trackPageView>
              <TermsOfServiceOrganizersPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('TermsOfServiceOrganizer')}
        />

        {/* Auth Pages */}
        <Route
          element={
            <ExtendedRoute loginProhibited trackPageView>
              <LoginContainer />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Login')}
        />
        <Route
          element={
            <ExtendedRoute loginProhibited trackPageView>
              <SignupContainer />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('Signup')}
        />
        {/* EmailVerificationRequired Requires firebaseUser login */}
        <Route
          element={
            <ExtendedRoute trackPageView>
              <EmailVerificationRequired />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('EmailVerificationRequired')}
        />
        <Route
          element={
            <ExtendedRoute loginProhibited trackPageView>
              <ForgotPassword />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('ForgotPassword')}
        />

        {/* Used when we have custom email templates */}
        {/* <ExtendedRoute loginProhibited path={AppRouteService.getRouterRoutePath('ResetPassword')}> */}
        {/*  <ResetPassword /> */}
        {/* </ExtendedRoute> */}

        {/* Server Error */}
        <Route
          element={
            <ExtendedRoute trackPageView>
              <ServerErrorPage />
            </ExtendedRoute>
          }
          path={AppRouteService.getRouterRoutePath('ServerError')}
        />

        {/* Not Found */}
        <Route
          element={
            <ExtendedRoute trackPageView>
              <PageNotFound />
            </ExtendedRoute>
          }
          path="*"
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
