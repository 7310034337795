/**
 * Inline calendar with selectable dates
 */
import React, { MutableRefObject, ReactNode } from 'react';
import DayPicker, { DayPickerProps, NavbarElementProps } from 'react-day-picker';
// eslint-disable-next-line import/no-unresolved
import { WeekdayElementProps } from 'react-day-picker/types/Props';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import * as RA from 'ramda-adjunct';

import '@/../public/css/react-day-picker-styles.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .DayPicker-Month': {
      margin: 0,
    },
    '& .DayPicker-Caption': {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(0.75),
    },
    '& .DayPicker-NavButton': {
      top: 0,
      right: theme.spacing(0.5),
    },
    // '& .DayPicker-Day': {
    //   '& > div': {
    //     [theme.breakpoints.down('xs')]: {
    //       width: 'auto',
    //       height: 'auto',
    //       minHeight: 28,
    //       minWidth: 28,
    //     },
    //   },
    //   '&.DayPicker-Day--selected': {},
    // },
  },
  weekday: {
    ...theme.typography.caption,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

export interface SelectableDatesCalendarProps extends DayPickerProps {
  dayPickerRef?: MutableRefObject<DayPicker | null>;
  unavailableDates?: Date[];
}
const SelectableDatesCalendar: React.FC<SelectableDatesCalendarProps> = ({
  dayPickerRef,
  onDayClick,
  selectedDays,
  unavailableDates,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      <DayPicker
        className={classes.root}
        modifiers={{
          selectedSingle: RA.ensureArray(selectedDays),
          unavailableDate: RA.ensureArray(unavailableDates),
        }}
        navbarElement={Navbar}
        onDayClick={onDayClick}
        ref={dayPickerRef}
        renderDay={renderDay}
        selectedDays={selectedDays}
        weekdayElement={Weekday}
        {...rest}
      />
    </div>
  );
};

const Weekday: React.FC<WeekdayElementProps> = ({ className, locale, localeUtils, weekday }) => {
  const classes = useStyles();
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={classNames(className, classes.weekday)} title={weekdayName}>
      {weekdayName.substring(0, 3)}
    </div>
  );
};
const Navbar: React.FC<NavbarElementProps> = ({
  className,
  classNames: navBarClassNames,
  onNextClick,
  onPreviousClick,
}) => {
  return (
    <div className={className}>
      <Box className={navBarClassNames.navButtonPrev} onClick={(): void => onPreviousClick()}>
        <ChevronLeftIcon />
      </Box>
      <Box className={navBarClassNames.navButtonNext} onClick={(): void => onNextClick()}>
        <ChevronRightIcon />
      </Box>
    </div>
  );
};
const renderDay = (day: Date): ReactNode => {
  const date = day.getDate();
  return <div>{date}</div>;
};

export default SelectableDatesCalendar;
