import React, { ReactElement } from 'react';
import { useScrollTrigger } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Colors } from '@/themes/colors';

const boxShadow = '0 1px 16px rgba(84, 87, 90, 0.1)';
export const useElevationStyles = makeStyles({
  shadowAndBorder: {
    // borderBottom: '1px solid rgba(0, 69, 156, 0.2)',
    borderBottom: Colors.LightBorder,
    boxShadow,
  },
  shadow: {
    boxShadow,
  },
});
// Add box shadow and border on scroll
const ElevationScroll: React.FC<{ children: ReactElement }> = (props) => {
  const { children } = props;
  const classes = useElevationStyles();
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    className: classNames({ [classes.shadowAndBorder]: isScrolled }, children.props.className),
  });
};

export default ElevationScroll;
