import { Theme } from '@material-ui/core';

export const MuiFab = (theme: Theme): Record<string, unknown> => ({
  root: {
    '&:not(.MuiFab-primary):not(.MuiFab-secondary)': {
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
    },
  },
});
