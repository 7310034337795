import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';
import setHours from 'date-fns/setHours';
import setMilliseconds from 'date-fns/setMilliseconds';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';

import ENV from '@/env';
import { isDateBetweenInclusive } from '@/lib/date-helpers/date-utils';
import { getLatestReleaseTagNameCF } from '@/lib/firebase';

const latestCheck = ENV.APP_VERSION;
const pageLoadTime = Date.now();
const FIVE_MINUTES_IN_MS = minutesToMilliseconds(5);

/**
 * Check if the current version is the latest version.
 * This doesn't make a network request. The 'setInterval' is used to check every 5 minutes and
 * it updates the latestCheck variable.
 *
 * This function gets used in 'ExtendedRoute'. If it returns false on a route change
 * we reload the page
 */
export function isOnLatest(): boolean {
  return latestCheck === ENV.APP_VERSION;
}

/**
 * Gets the latest release tag name from GitHub.
 * TODO: NOT USED YET
 */
async function getLatestAppVersion(): Promise<void> {
  // const response = await getLatestReleaseTagNameCF();
  // latestCheck = response.data.replace(/^v/, '');
}

/*
  Periodically check for what's the latest version of App.
  This will allow us to do a hard refresh of the page in case the app gets out of sync,
  guaranteeing that we'll always have the latest version available on navigation.
*/
setInterval(async () => {
  try {
    await getLatestAppVersion();
    // Forcibly reload the page if app is out of date and it hasn't been
    // reloaded for over 12 hours. This is to prevent errors from people that
    // just leave the app open in a tab for long periods of time.
    const currentTime = Date.now();
    const millisecondsSincePageLoad = currentTime - pageLoadTime;
    const isSameTabOpenForMoreThan12Hours = millisecondsSincePageLoad > hoursToMilliseconds(12);
    const isBetween4And5Am = isDateBetweenInclusive(new Date(), {
      start: setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 4),
      end: setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 5),
    });
    if (!isOnLatest() && isSameTabOpenForMoreThan12Hours && isBetween4And5Am) {
      window.location.reload();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}, FIVE_MINUTES_IN_MS);
