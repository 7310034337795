import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { Theme } from '@material-ui/core/styles';

import ExtendedButton from '@/ui/Button/ExtendedButton';

import SearchFiltersContainer from '@/containers/Search/Filters/EventsSearchFiltersContainer';
import {
  useGetSearchFilterDrawerOpenGlobal,
  useSetIsSearchFilterDrawerOpenGlobal,
} from '@/containers/Search/recoilStore';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { Colors } from '@/themes/colors';

const MAX_DRAWER_WIDTH = '600px';
const useStyles = makeStyles((theme: Theme) => ({
  filterHeader: {
    borderBottom: Colors.LightBorder,
    background: 'white',
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'center',
    maxWidth: MAX_DRAWER_WIDTH,
    // position: 'fixed',
    // top: 0,
    width: '100%',
    zIndex: 1,
  },
  drawerPaper: {
    maxWidth: MAX_DRAWER_WIDTH,
  },
  searchFooter: {
    maxWidth: MAX_DRAWER_WIDTH,
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    borderTop: Colors.LightBorder,
    display: 'flex',
    justifyContent: 'space-between',
    left: 0,
    padding: theme.spacing(1.5, 2.5),
    // position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
}));
const MobileSearchFilters: React.FC = () => {
  const classes = useStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const isSearchFilterDrawerOpenGlobal = useGetSearchFilterDrawerOpenGlobal();
  const setIsSearchFilterDrawerOpenGlobal = useSetIsSearchFilterDrawerOpenGlobal();

  const handleOpen = (): void => {
    setIsSearchFilterDrawerOpenGlobal(true);
  };
  const handleClose = (): void => {
    setIsSearchFilterDrawerOpenGlobal(false);
  };
  return (
    <div>
      <ExtendedButton
        color="primary"
        fullWidth={isSmallScreenDown}
        minWidth="md"
        onClick={handleOpen}
        variant="outlined"
      >
        Filters
      </ExtendedButton>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleClose}
        open={isSearchFilterDrawerOpenGlobal}
      >
        <Box display="contents" position="relative">
          <Box className={classes.filterHeader}>
            <Typography variant="h6">Filters</Typography>`
          </Box>
          <Box
            px={2.5}
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SearchFiltersContainer />
          </Box>
          <Box className={classes.searchFooter}>
            <ExtendedButton fullWidth color="primary" onClick={handleClose} variant="contained">
              Show Results
            </ExtendedButton>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};
export default MobileSearchFilters;
