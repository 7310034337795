import React, { useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import {
  Category,
  Question,
  RecommendationResponse,
  Response,
  YesNoResponse,
} from '@/services/review/questions';
import ReviewService from '@/services/review/ReviewService';

export interface ResponseInputProps {
  question: Question<Category>;
  response?: Response;
  onChange: (response: Response) => void;
}

/**
 * Y/N input
 */
const YesNoInput: React.FC<ResponseInputProps> = ({ onChange, question, response }) => {
  const handleValueChange = (_e: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    onChange({
      questionId: question.id,
      type: 'y/n',
      value: parseInt(value, 10),
    } as YesNoResponse);
  };

  return (
    <Box>
      <RadioGroup row onChange={handleValueChange} value={response?.value?.toString() || null}>
        <FormControlLabel control={<Radio />} label="Yes" style={{ marginRight: 32 }} value="1" />
        <FormControlLabel control={<Radio />} label="No" value="0" />
      </RadioGroup>
    </Box>
  );
};

/**
 * Recommendation Input
 */
const REC_LABELS = ReviewService.getRecommendationValueToLabels();

const RecommendationInput: React.FC<ResponseInputProps> = ({ onChange, question, response }) => {
  let recResponse: RecommendationResponse | undefined;
  if (response && ReviewService.isRecommendationResponse(response)) {
    recResponse = response;
  }

  const handleValueChange = (_e: React.ChangeEvent<{}>, value: number | null): void => {
    onChange({
      questionId: question.id,
      type: 'recommendation',
      value,
      why: recResponse?.why || '',
    } as RecommendationResponse);
  };

  const [hoverLabel, setHoverLabel] = useState<string | undefined>();
  const handleHoverLabel = (_e: React.ChangeEvent<{}>, hoverValue: number): void =>
    setHoverLabel(REC_LABELS[hoverValue]);

  const handleWhyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    onChange({
      questionId: question.id,
      type: 'recommendation',
      value: recResponse!.value,
      why: value,
    } as RecommendationResponse);
  };

  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1.5}
      >
        <Rating
          max={4}
          onChange={handleValueChange}
          onChangeActive={handleHoverLabel}
          size="large"
          value={(recResponse?.value as number) || null}
        />
        <Box ml={1}>
          <Typography display="inline" variant="body2">
            {hoverLabel || (recResponse?.value && REC_LABELS[recResponse.value])}
          </Typography>
        </Box>
      </Box>
      {recResponse?.value && (
        <TextField
          fullWidth
          hiddenLabel
          multiline
          disabled={!recResponse?.value}
          id="recommendation-why"
          margin="dense"
          onChange={handleWhyChange}
          placeholder={
            !recResponse?.value
              ? 'Decide on your recommendation first'
              : `Why do you ${REC_LABELS[recResponse.value]
                  .toLowerCase()
                  .substring(0, REC_LABELS[recResponse.value].length - 2)} this coach?`
          }
          rows={3}
          rowsMax={8}
          value={recResponse?.why || ''}
          variant="filled"
        />
      )}
    </Box>
  );
};

/**
 * Open Response Input
 */
const OpenResponseInput: React.FC<ResponseInputProps> = ({ onChange, question, response }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    onChange({ questionId: question.id, type: 'open', value });
  };

  return (
    <TextField
      fullWidth
      hiddenLabel
      multiline
      margin="dense"
      name={question.id}
      onChange={handleChange}
      rows={3}
      rowsMax={8}
      value={response?.value || ''}
      variant="filled"
    />
  );
};

/**
 * Response Input
 */
const ResponseInput: React.FC<ResponseInputProps> = (props) => {
  const { question } = props;
  const { responseType } = question;

  return (
    <Box>
      <Typography gutterBottom variant="h3">
        {question.label}
      </Typography>
      {responseType === 'y/n' && <YesNoInput {...props} />}
      {responseType === 'open' && <OpenResponseInput {...props} />}
      {responseType === 'recommendation' && <RecommendationInput {...props} />}
    </Box>
  );
};

export default ResponseInput;
