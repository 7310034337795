/**
 * An editable organizer event card
 */
import React from 'react';
import Slider from 'react-slick';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useReactSlickStyles } from '@/ui/styles/reactSlickStyles';

import { ReactFCC } from '@/lib/type-defs/utility';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  slickContainer: {
    width: 300,
    minWidth: 300,
    height: 200,
    borderRadius: theme.spacing(1),
    border: Colors.LightBorder,
    overflow: 'hidden',
  },
  searchResultImageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    height: 200,
    '& > img': {
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      willChange: 'transform',
      width: '100%',
    },
  },
}));

const reactSlickImageSettings = {
  lazyLoad: 'ondemand' as const,
  dots: true,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface SmallSlickCarouselProps {
  imageUrls?: string[];
  containerClassName?: string;
}
const SmallSlickCarousel: ReactFCC<SmallSlickCarouselProps> = ({
  containerClassName,
  imageUrls,
}) => {
  const classes = useStyles();
  const reactSlickClasses = useReactSlickStyles();

  if (!imageUrls || imageUrls.length === 0) {
    return null;
  }
  return (
    <Box className={classNames(classes.slickContainer, containerClassName)}>
      <Slider className={reactSlickClasses.reactSlickPreviewImages} {...reactSlickImageSettings}>
        {imageUrls.map((image) => (
          <Box className={classes.searchResultImageContainer} key={image}>
            <img alt="Result Preview" src={image} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SmallSlickCarousel;
