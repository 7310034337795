import * as yup from 'yup';

import { trimAndRemoveNewLines } from '../string-utils';

import {
  MAX_EMAIL_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
} from '@/const';

export const yupFirstName = yup
  .string()
  .max(MAX_FIRST_NAME_LENGTH, `First name must be ${MAX_FIRST_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .test(
    'has-characters',
    'Enter at least 1 letter and/or number',
    (value) => (value?.match(/\w|\d/g) || []).length >= 1,
  );
export const yupFirstNameRequired = yupFirstName.required('Please enter a first name');
export const yupLastNameRequired = yup
  .string()
  .max(MAX_LAST_NAME_LENGTH, `Last name must be ${MAX_LAST_NAME_LENGTH} characters max`)
  .transform(trimAndRemoveNewLines)
  .required('Please enter a last name')
  .test(
    'has-characters',
    'Enter at least 1 letter and/or number',
    (value) => (value?.match(/\w|\d/g) || []).length >= 1,
  );

export const yupEmail = yup
  .string()
  .max(MAX_EMAIL_LENGTH, `Email must be ${MAX_EMAIL_LENGTH} characters max`)
  .email('Please enter a valid email address');
export const yupEmailRequired = yupEmail.required('Email address is required');

export const yupPassword = yup
  .string()
  .max(MAX_PASSWORD_LENGTH, `Password must be ${MAX_PASSWORD_LENGTH} characters max`)
  .min(6, 'Password must be at least 6 characters');
export const yupPasswordRequired = yupPassword.required('Please enter a password');

export const yupMatchPassword = yup.string().oneOf([yup.ref('password')], 'Passwords must match');

// export const yupPhoneNumber = yup.string().matches(/^[0-9]{10}$/, {
//   excludeEmptyString: true,
//   message: 'Must be exactly 10 digits',
// })
//
// export const yupPhoneNumberRequired = yupPhoneNumber.required(
//   'Please provide your phone number'
// )

export const LoginSchema = yup.object().shape({
  email: yupEmailRequired,
  password: yupPasswordRequired,
});
export const SignupSchema = yup.object().shape({
  email: yupEmailRequired,
  password: yupPasswordRequired,
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
});

export const ForgotPasswordFormSchema = yup.object().shape({
  email: yupEmailRequired,
});

export const ResetPasswordFormSchema = yup.object().shape({
  password: yupPasswordRequired,
  repeatPassword: yupMatchPassword,
});

export const ChangePasswordFormSchema = yup.object().shape({
  password: yupPasswordRequired,
  newPassword: yupPasswordRequired,
  confirmPassword: yupPasswordRequired.test(
    'passwords-match',
    'Passwords must match',
    (value, context): boolean => {
      return context.parent.newPassword === value;
    },
  ),
});
