// This import loads the firebase namespace along with all its type information.
import { Analytics } from '@segment/analytics-next';
// eslint-disable-next-line import/no-unresolved
import { Event } from '@sentry/types/types/event';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  AuthProvider,
  connectAuthEmulator,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { openDB } from 'idb';
// eslint-disable-next-line import/no-unresolved
import Stripe from 'stripe';

// These imports load individual services into the firebase namespace.
// import 'firebase/firestore';
import { LessonRecurrenceTypeEnum, LessonRecurringIntervalEnum } from '@/const';
import { PlatformCourseProps } from '@/containers/HostEvent/Main/Sections/Courses/helpers';
import { SeriesSection_series } from '@/containers/HostEvent/Main/Sections/Series/__generated__/SeriesSection_series.graphql';
import { AdditionalPersonType } from '@/containers/CoachProfile/Profile/Request/AdditionalPerson';
import {
  LessonDateLocationProps,
  LessonDateProps,
  LessonDateVirtualLocationProps,
} from '@/containers/User/Coach/Students/Detail/CreateLessonOfferDialog';
import ENV from '@/env';
import { FormattedCloudinaryImageDataProps } from '@/lib/cloudinary';

/*
1. Firebase Constants
2. Firebase Initialization
3. Firebase Cloud Functions
4. Firebase Utility Functions
 */

/*
1. Firebase Constants
 */
// /////////////////////////////////////////////////////////////
// Firebase Config Object. Used to initialize firebase
// /////////////////////////////////////////////////////////////
type FIREBASE_CONFIG_TYPE = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};
const LOCAL_CONFIG: FIREBASE_CONFIG_TYPE = {
  apiKey: 'AIzaSyAx7KhiC2kPk1PHki_v7_zipqHJnoGiErw',
  authDomain: 'reggy-242215.firebaseapp.com',
  projectId: 'reggy-242215',
  storageBucket: 'reggy-242215.appspot.com',
  messagingSenderId: '592170632602',
  appId: '1:592170632602:web:ceef5089722f53a9',
  measurementId: 'G-G04YY8FCDN',
};
const STAGING_CONFIG: FIREBASE_CONFIG_TYPE = {
  apiKey: 'AIzaSyDabMx2kJUWzRd9i_ueXaR-wGnNd99NyHs',
  authDomain: 'reggy-staging-e21d7.web.app',
  projectId: 'reggy-staging-e21d7',
  storageBucket: 'reggy-staging-e21d7.appspot.com',
  messagingSenderId: '827497578718',
  appId: '1:827497578718:web:4546434e057e46555d424f',
  measurementId: 'G-YSLRG4RV36',
};
const PRODUCTION_CONFIG: FIREBASE_CONFIG_TYPE = {
  apiKey: 'AIzaSyBNQzr9mr4M2F_QQ2cqF9kcxgXhdSFefDg',
  authDomain: 'goreggy.com',
  projectId: 'reggy-production',
  storageBucket: 'reggy-production.appspot.com',
  messagingSenderId: '44959447363',
  appId: '1:44959447363:web:c7321f0ae9006ac27397f6',
  measurementId: 'G-YD6LPCPQ0W',
};

const FIREBASE_CONFIG = ENV.IS_NODE_ENV_LOCAL
  ? LOCAL_CONFIG
  : ENV.IS_NODE_ENV_STAGING
  ? STAGING_CONFIG
  : PRODUCTION_CONFIG;

// /////////////////////////////////////////////////////////////
// Firebase Project Constants
// /////////////////////////////////////////////////////////////
const APP_NAME = '[DEFAULT]';
const REGION = 'us-central1';
const CLOUD_FUNCTION_URL = `https://${REGION}-${FIREBASE_CONFIG.projectId}.cloudfunctions.net/`;

// /////////////////////////////////////////////////////////////
// Firebase Storage Keys
// /////////////////////////////////////////////////////////////
// REDIRECT_PENDING_SESSION_KEY = Key that gets set on signinWithRedirect
// INDEXED_DB = Where firebase stores user objects
const StorageKeys = {
  PendingRedirectKey: `firebase:pendingRedirect:${FIREBASE_CONFIG.apiKey}:[DEFAULT]`,
  IndexDBKey: `firebase:authUser:${FIREBASE_CONFIG.apiKey}:[DEFAULT]`,
  Store: 'firebaseLocalStorage',
  DB: 'firebaseLocalStorageDb',
};

// /////////////////////////////////////////////////////////////
// Firebase Auth
// /////////////////////////////////////////////////////////////
export enum ProviderNames {
  Apple = 'apple.com',
  EmailLink = 'emailLink',
  Facebook = 'facebook.com',
  Google = 'google.com',
  Password = 'password',
  Redirect = 'redirect',
}

// https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page
enum EmailModes {
  EmailLinkSignIn = 'signIn',
  RecoverEmail = 'recoverEmail',
  ResetPassword = 'resetPassword',
  VerifyEmail = 'verifyEmail',
}

// /////////////////////////////////////////////////////////////
// Firebase Constant Object. Used to export all as default
// /////////////////////////////////////////////////////////////
export const Firebase = {
  CONFIG: FIREBASE_CONFIG,
  APP_NAME,
  REGION,
  CLOUD_FUNCTION_URL,
  StorageKeys,
  ProviderNames,
  EmailModes,
};

/*
// //////////////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////////////////////
 */

/*
2. Firebase Initialization
 */
const firebase = initializeApp(Firebase.CONFIG);
export const firebaseAuth = getAuth(firebase);
const functions = getFunctions(firebase);
export const firebaseStorage = getStorage(firebase);
getAnalytics(firebase);

if (ENV.IS_NODE_ENV_LOCAL || ENV.USE_LOCAL_DB_AND_FIREBASE_EMULATORS) {
  connectStorageEmulator(firebaseStorage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099', { disableWarnings: true });
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// initializeAppCheck(firebase, {
//   provider: new ReCaptchaEnterpriseProvider(ENV.RECAPTCHA_ENTERPRISE_PUBLIC_KEY),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });

export default firebase;

/*
// //////////////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////////////////////
 */

/*
3. Firebase Cloud Functions
 */

/** *********************************************************************************
 * App
 ********************************************************************************** */
export const getLatestReleaseTagNameCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-github-getLatestReleaseTagName',
);

/** *********************************************************************************
 * Invites
 ********************************************************************************** */
export const checkAcceptCreatorInviteCF: () => Promise<{
  data: boolean;
}> = httpsCallable(functions, 'http-invites-checkAcceptCreatorInvite');
export const inviteCreatorCF: (data: {
  inviteEmail: string;
}) => Promise<{
  data: { errorText: string; success: boolean };
}> = httpsCallable(functions, 'http-invites-inviteCreator');
export const validateCreatorCodeCF: (data: {
  code: string;
}) => Promise<{
  data: boolean;
}> = httpsCallable(functions, 'http-invites-validateCreatorCode');

/** *********************************************************************************
 * Analytics
 ********************************************************************************** */
export const sendSentryErrorCF: (event: Event) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-analytics-sendSentryError',
);
export interface SegmentEventProps {
  functionName: keyof InstanceType<typeof Analytics>;
  message: Parameters<InstanceType<typeof Analytics>[keyof InstanceType<typeof Analytics>]>;
}
export const sendSegmentEventCF: (
  data: SegmentEventProps,
) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-analytics-sendSegmentEvent');

export type StripeConnectChargesCFReturn = {
  charges: { amountInCents: number; createdAt: number }[];
  hasPermission: boolean;
};
interface GetStripeConnectChargesCFProps {
  stripeConnectId: string;
  fromUnixTime?: number;
  toUnixTime?: number;
}
export const getStripeConnectChargesCF: ({
  fromUnixTime,
  stripeConnectId,
  toUnixTime,
}: GetStripeConnectChargesCFProps) => Promise<{
  data: StripeConnectChargesCFReturn;
}> = httpsCallable(functions, 'http-analytics-getStripeConnectCharges');

// TODO: Only used for exporting data.
// export type MixpanelEventsCFReturn = {
//   charges: { amountInCents: number; createdAt: number }[];
//   hasPermission: boolean;
// };
// interface GetMixpanelEventsCFProps {
//   fromDate: string;
//   toDate?: number;
//   event?: string;
//   limit?: number;
//   where?: string;
// }
// export const getMixpanelEventsCF: ({}: // fromUnixTime,
// // stripeConnectId,
// // toUnixTime,
// GetStripeConnectChargesCFProps) => Promise<{
//   data: StripeConnectChargesCFReturn;
// }> = httpsCallable(functions, 'http-analytics-getMixpanelEvents');

/** *********************************************************************************
 * Auth
 ********************************************************************************** */
export const createUserCF: (data: {
  firstName: string;
  lastName: string | null;
  timezone: string;
  user: { email: string; profilePicUrl?: string | null; uid: string };
}) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-auth-createUser');
export const getValidUserSlugCF: (data: {
  fullName: string;
}) => Promise<{
  data: {
    fullName: string;
    slug: string;
  };
}> = httpsCallable(functions, 'http-utils-getValidUserSlug');
export const syncCustomClaimsCF: () => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-auth-syncCustomClaims',
);
export const getLocallySignedTokenCF = (): Promise<{ data: string }> => {
  return ENV.USE_LOCAL_DB_AND_FIREBASE_EMULATORS
    ? httpsCallable<unknown, string>(functions, 'http-auth-getLocallySignedToken')()
    : Promise.resolve({ data: 'NOT SUPPORTED' });
};

/** *********************************************************************************
 * Email
 ********************************************************************************** */
// export const sendSignInWithEmailLinkCF = httpsCallable(
//   functions,
//   'email-authActions-sendSignInWithEmailLink',
// );
// Future, we can use these to send custom emails
// export const sendVerifyEmailCF = httpsCallable(functions, 'email-authActions-sendVerifyEmail');
// export const sendResetPasswordCF = httpsCallable(functions, 'email-authActions-sendResetPassword');

/** *********************************************************************************
 * Notifications
 ********************************************************************************** */
export const notifyRegistrationCompleteCF: (data: {
  registrantCuids: string[];
}) => Promise<{ data: string[] }> = httpsCallable(
  functions,
  'http-registration-notifyRegistrationComplete',
);
export const notifyOnlineCoursePurchaseCompleteCF: (data: {
  userOnlineCourseCuid: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-onlineCourse-notifyCoursePurchaseComplete',
);
export const notifyUserOnOrganizerInviteCF: (data: {
  inviteeUserId: string;
  organizerCuid: string;
  organizerName: string;
}) => Promise<{ data: string[] }> = httpsCallable(
  functions,
  'http-notifications-notifyUserOnOrganizerInvite',
);
interface NotifyUserOnOrganizeReplyProps {
  channelId: string;
  message: string;
  organizerCuid: string;
  organizerName: string;
  organizerSlug: string;
  recipientFirstName: string;
  recipientUserId: string;
  senderFullName: string;
}
export const notifyUserOnOrganizeReplyCF: (
  data: NotifyUserOnOrganizeReplyProps,
) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-notifications-notifyUserOnOrganizeReply',
);
/** *********************************************************************************
 * Calendar
 ********************************************************************************** */
export const addCalendarEventCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'calendar-http-addEvent',
);

/** *********************************************************************************
 * Waivers
 ********************************************************************************** */
export const downloadPdfCF: (data: {
  organizerCuid: string | 'REGGY';
  url: string;
}) => Promise<{ data: string }> = httpsCallable(functions, 'http-waivers-downloadPdf');
export const createSignatureRequestCF: (data: {
  defaultFieldValues?: Record<string, string>;
  parentGuardianSigner?: {
    email: string;
    name: string;
  };
  previewMode?: boolean;
  registrantSigner?: {
    email: string;
    name: string;
  };
  registrationCuid?: string;
  waiverCuid: string;
  waiverRevisionNum: number;
}) => Promise<{
  data: { parentGuardian?: string; registrant?: string; signatureRequestId: string };
}> = httpsCallable(functions, 'http-waivers-createSignatureRequest');
export const getSignedWaiverCF: (data: {
  signatureRequestId: string;
}) => Promise<{ data: { file_url: string } }> = httpsCallable(
  functions,
  'http-waivers-getSignedWaiver',
);
export const emailUnsignedBasicWaiversCF: (data: {
  registrationCuid: string;
}) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-basicWaivers-emailUnsignedBasicWaivers',
);

export const updateBasicSignatureRequestStatusCF: (data: {
  basicSignatureRequestCuid: string;
}) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-basicWaivers-updateBasicSignatureRequestStatus',
);

/** *********************************************************************************
 * Registration
 ********************************************************************************** */
export const createCheckoutCF: (data: {
  eventCuid: string;
  referenceNo: string;
  userId: string;
}) => Promise<{ data: string }> = httpsCallable(functions, 'http-registration-createCheckout');
export const validatePromoCodeCF: (data: {
  promoCode: string;
  eventCuid: string;
}) => Promise<{
  data: null | { amountOff: number | null; percentOff: number | null; code: string; cuid: string };
}> = httpsCallable(functions, 'http-registration-validatePromoCode');

/** *********************************************************************************
 * Registration Detail
 ********************************************************************************** */
interface PaymentIntentsChargeData {
  amount: number;
  amount_received: number;
  application_fee_amount: number;
  charge: {
    amount: number;
    amount_refunded: number;
    amount_captured: number;
    billing_details: { email: string | null; name: string | null };
    created: number;
    currency: string;
    receipt_url: string | null;
    refunds: { amount: number; created: number; currency: string; metadata: any }[];
  };
  paymentIntentId: string;
}
export type GetPaymentIntentsCFReturn = { charges: PaymentIntentsChargeData[] };
export const getPaymentIntentsCF: (data: {
  eventCuid: string;
  referenceNo: string;
  paymentIntentIds?: string[];
}) => Promise<{ data: GetPaymentIntentsCFReturn }> = httpsCallable(
  functions,
  'http-registrationDetail-getPaymentIntents',
);
export const getLessonPaymentIntentCF: (data: {
  lessonDateCuid: string;
  referenceNo: string;
}) => Promise<{ data: GetPaymentIntentsCFReturn }> = httpsCallable(
  functions,
  'http-registrationDetail-getLessonPaymentIntent',
);
export const requestEventRefundCF: (data: {
  channelName: string;
  eventCuid: string;
  eventDate: string;
  eventName: string;
  organizerCuid: string;
  paymentIntentId: string;
  senderName: string;
}) => Promise<{ data: { channelId: string } }> = httpsCallable(
  functions,
  'http-registrationDetail-requestEventRefund',
);
export const refundSuccessfulPaymentCF: (data: {
  paymentIntentId: string;
  eventCuid: string;
  refNo: string;
  eventName: string;
  amountInDollars: number | null;
  userIdToRefund: string;
}) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-registrationDetail-refundSuccessfulPayment',
);
export const requestLessonRefundCF: (data: {
  channelId: string;
  lessonDateCuid: string;
  lessonTitle: string;
}) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-registrationDetail-requestLessonRefund',
);
export type ChangeCategoryWithDifferentPriceType = {
  newCategoryCuid: string;
  oldCategoryCuid: string;
  registrationCuid: string;
};
export const changeCategoryWithDifferentPriceCF: (
  data: ChangeCategoryWithDifferentPriceType,
) => Promise<{
  data: {
    changeType: 'charge' | 'refund';
    checkoutUrl: string | null;
  };
}> = httpsCallable(functions, 'http-registrationDetail-changeCatWithDifferentPrice');

/** *********************************************************************************
 * Online Courses
 ********************************************************************************** */
export const createOnlineCourseCheckoutCF: (data: {
  onlineCourseCuid: string;
}) => Promise<{ data: string }> = httpsCallable(functions, 'http-onlineCourse-createCheckout');

/** *********************************************************************************
 * Organizer
 ********************************************************************************** */
export const createOrganizerCF: (data: {
  about: string;
  name: string;
  image: FormattedCloudinaryImageDataProps | null;
  slug: string;
}) => Promise<{
  data: {
    cuid: string;
    organizer: { data: { about: string | null; cuid: string; name: string; slug: string } };
    role: string;
    userId: string;
  };
}> = httpsCallable(functions, 'http-organizer-createOrganizer');
export const acceptOrganizerInviteCF: (data: {
  organizerInviteCuid: string;
  userOrganizerRoleCuid: string;
}) => Promise<{ data: string }> = httpsCallable(functions, 'http-organizer-acceptOrganizerInvite');
export const rejectOrganizerInviteCF: (data: {
  organizerInviteCuid: string;
}) => Promise<{ data: string }> = httpsCallable(functions, 'http-organizer-rejectOrganizerInvite');
export const removeOrganizerMemberCF: (data: {
  organizerCuid: string;
  userIdToRemove: string;
}) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-organizer-removeOrganizerMember');
export const updateOrganizerRoleCF: (data: {
  newRoles: string[];
  organizer: {
    cuid: string;
    name: string;
    slug: string;
  };
  userId: string;
}) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-organizer-updateOrganizerRole');
export const getValidOrganizerSlugCF: (data: {
  organizerCuid?: string;
  organizerName: string;
}) => Promise<{ data: { organizerName: string; slug: string } }> = httpsCallable(
  functions,
  'http-utils-getValidOrganizerSlug',
);
interface SendRegistrantMessageProps {
  eventCuid: string | undefined;
  message: string;
  organizerCuid: string;
  userIds: string[];
}
export const sendRegistrantMessageCF: (
  data: SendRegistrantMessageProps,
) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-organizer-sendRegistrantMessage');
// Events
interface SendOrganizerMessageProps {
  eventCuid: string | undefined;
  message: string;
  organizerCuid: string;
}
export const sendOrganizerMessageCF: (
  data: SendOrganizerMessageProps,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-organizer-sendOrganizerMessage');
// Series
export const querySeriesByJoinKeyCF: (data: {
  joinKey: string;
}) => Promise<{
  data: {
    error: string;
    series: SeriesSection_series['edges'][0]['node'];
  };
}> = httpsCallable(functions, 'http-organizer-querySeriesByJoinKey');
export type OccupiedSeriesRoundProps = {
  eventSeriesCuid: string;
  totalRounds: number;
  roundNumber: number;
  eventName: string;
  eventLink: string;
};
export const getOccupiedSeriesRoundsCF: (data: {
  seriesCuid: string;
}) => Promise<{
  data: OccupiedSeriesRoundProps[];
}> = httpsCallable(functions, 'http-organizer-getOccupiedSeriesRounds');
// Delete
export const deleteOrganizerCF: (data: {
  organizerCuid: string;
}) => Promise<{
  data: boolean;
}> = httpsCallable(functions, 'http-organizer-deleteOrganizer');
export const updateStreamOrganizerUserCF: ({
  image,
  name,
  organizerCuid,
}: {
  organizerCuid: string;
  name: string;
  image: string | undefined | null;
}) => Promise<{ data: void }> = httpsCallable(functions, 'http-stream-updateStreamOrganizerUser');

/** *********************************************************************************
 * Coach
 ********************************************************************************** */
interface RequestPrivateLessonVariables {
  additionalPeople: AdditionalPersonType[];
  coachCuid: string;
  coachLessonCuid: string | undefined;
  isFree: boolean;
  message: string;
  startTime: string;
}
export const createLessonRequestCF: (
  data: RequestPrivateLessonVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-coach-createLessonRequest');
interface SendOfferProps {
  abilityLevelRange: number[];
  channelId: string;
  coachCuid: string;
  coachCustomerCuid: string;
  coachCustomerLessonDates: Omit<
    | LessonDateProps
    | {
        coachCustomerLessonDateVirtualLocation: Omit<
          LessonDateVirtualLocationProps,
          'isVirtual'
        > | null;
        startDate: string;
      },
    'address' | 'isVirtual'
  >[];
  coachLessonCuid?: string;
  currency: string;
  disciplineNames: string[];
  duration: number;
  expiresAtText: string;
  isVirtual: boolean;
  price: number | undefined;
  recurrenceType: LessonRecurrenceTypeEnum;
  recurringInterval: LessonRecurringIntervalEnum;
  referenceNo: string;
  skills: string[];
  title: string;
}
export const createLessonOfferCF: (data: SendOfferProps) => Promise<{ data: null }> = httpsCallable(
  functions,
  'http-coach-createLessonOffer',
);
export const cancelLessonOfferCF: (data: {
  coachCustomerLessonCuid: string;
}) => Promise<{ data: null }> = httpsCallable(functions, 'http-coach-cancelLessonOffer');
interface GetCoachAvailabilityVariables {
  coachCuid?: string;
  coachSlug?: string;
  eventTimezone?: string;
  timeMax: string;
  timeMin: string;
}

// One Time Charge
interface SendOneTimeChargeOfferProps {
  channelId: string;
  coachCustomerLessonCuid: string;
  coachCustomerLessonDateCuid: string;
  currency: string;
  description: string;
  expiresAtText: string;
  oneTimeChargeImageUrl: string | undefined;
  price: number;
  referenceNo: string;
}
export const createOneTimeChargeOfferCF: (
  data: SendOneTimeChargeOfferProps,
) => Promise<{ data: null }> = httpsCallable(functions, 'http-coach-oneTimeCharge-createOffer');
export const cancelOneTimeChargeOfferCF: (data: {
  coachCustomerLessonOneTimeChargeCuid: string;
}) => Promise<{ data: null }> = httpsCallable(functions, 'http-coach-oneTimeCharge-cancelOffer');

// Availability
interface GetCoachAvailabilityVariables {
  coachCuid?: string;
  coachSlug?: string;
  eventTimezone?: string;
  timeMax: string;
  timeMin: string;
}
export type CoachAvailableSegment = {
  endDate: string;
  // Start & End Floating Datetimes
  startDate: string;
};
export interface GetCoachAvailabilityReturn {
  coachAvailabilities: {
    // Date - 12/12/2021
    [x: string]: CoachAvailableSegment[];
    // [y: string]: { start: Date; end: Date }[];
  };
  timezone: string;
}
export const getCoachAvailabilityCF: (
  data: GetCoachAvailabilityVariables,
) => Promise<{
  data: GetCoachAvailabilityReturn;
}> = httpsCallable(functions, 'calendar-coach-getCoachAvailability');

// Edit Lesson
export const cancelRecurringLessonCF: (data: {
  coachCustomerLessonCuid: string;
  refundAmount?: number;
  shouldRefundPayment: boolean;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-cancelRecurringLesson');
export const cancelPaidLessonCF: (data: {
  coachCustomerLessonCuid: string;
  refundAmount?: number;
  shouldRefundPayment: boolean;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-cancelPaidLesson');
export const cancelFreeLessonCF: (data: {
  coachCustomerLessonCuid: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-cancelFreeLesson');
export const changeLessonLocationCF: (data: {
  channelId: string;
  coachCustomerLessonDateLocation: LessonDateLocationProps;
  lessonTitle: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-location-changeLessonLocation');
export const changeLessonVirtualLocationCF: (data: {
  channelId: string;
  coachCustomerLessonDateVirtualLocation: LessonDateVirtualLocationProps;
  lessonTitle: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-location-changeLessonVirtualLocation');
export const changeLessonDateCF: (data: {
  channelId: string;
  coachCustomerLessonDateCuid: string;
  lessonTitle: string;
  startDate: string;
  timezone: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-coach-date-changeLessonDate');

export const pauseRecurringLessonCF: (data: {
  coachCustomerLessonCuid: string;
  subscriptionPausedUntil: number;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-stripe-coach-pauseRecurringLesson');
export const resumeRecurringLessonCF: (data: {
  coachCustomerLessonCuid: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-stripe-coach-resumeRecurringLesson');
export const refundSuccessfulTransactionCF: (data: {
  stripeCheckoutId: string | undefined;
  stripePaymentIntentId: string;
}) => Promise<{
  data: null;
}> = httpsCallable(functions, 'http-stripe-coach-refundSuccessfulTransaction');

// Stripe utils
export interface GetTotalAmountChargedByLessonCFReturn {
  lessonPriceInCents: number;
  servicePriceInCents: number;
  totalPriceInCents: number;
  totalServiceFeeInCents: number;
}
export const getTotalAmountChargedByLessonCF: (data: {
  coachCustomerLessonCuid: string;
}) => Promise<{
  data: GetTotalAmountChargedByLessonCFReturn;
}> = httpsCallable(functions, 'http-stripe-coach-getTotalAmountChargedByLesson');
export const getTotalAmountRefundedByLessonCF: (data: {
  coachCustomerLessonCuid: string;
}) => Promise<{
  data: number;
}> = httpsCallable(functions, 'http-stripe-coach-getTotalAmountRefundedByLesson');
export const getAmountAndFeeByCheckoutOrIntentIdCF: (data: {
  stripeCheckoutId: string | undefined;
  stripePaymentIntentId: string;
}) => Promise<{
  data: {
    amountPriceInCents: number;
    servicePriceInCents: number;
  };
}> = httpsCallable(functions, 'http-stripe-coach-getAmtAndFeeByCheckoutOrIntentId');

/** *********************************************************************************
 * Series
 ********************************************************************************** */
export const getValidSeriesSlugCF: (data: {
  organizerCuid: string;
  seriesCuid: string;
  seriesName: string;
}) => Promise<{ data: { seriesName: string; slug: string } }> = httpsCallable(
  functions,
  'http-utils-getValidSeriesSlug',
);

/** *********************************************************************************
 * Events
 ********************************************************************************** */
export const getValidEventMetadataSlugCF: (data: {
  eventMetadataCuid: string;
  eventName: string;
  organizerCuid: string;
}) => Promise<{ data: { eventName: string; slug: string } }> = httpsCallable(
  functions,
  'http-utils-getValidEventMetadataSlug',
);
export const duplicateEventCF: (data: {
  eventCuid: string;
  organizerCuid: string;
  startDate?: string;
  shouldCreateNewEvent: boolean;
}) => Promise<{
  data: {
    cuid: string;
    endDate: string;
    lastSavedAt: string;
    occurrenceLabel: string;
    startDate: string;
  };
}> = httpsCallable(functions, 'http-event-duplicateEvent');
type DeleteEventMutationVariables = {
  eventCuid: string;
};
export const deleteEventCF: (
  data: DeleteEventMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-deleteEvent');
export const cancelEventCF: (
  data: DeleteEventMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-cancelEvent');
export const unCancelEventCF: (
  data: DeleteEventMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-unCancelEvent');

/** *********************************************************************************
 * Ride
 ********************************************************************************** */
type CreateCategoriedRideMutationVariables = {
  cuid: string;
  defaultCurrencyCode: string;
  endDate: string;
  name: string;
  organizerCuid: string;
  slug: string;
  startDate: string;
};
export const createCategoriedRideCF: (
  data: CreateCategoriedRideMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-createCategoriedRide');
type CreateNonCategoriedRideMutationVariables = {
  cuid: string;
  defaultCurrencyCode: string;
  name: string;
  numberOfDays: number;
  organizerCuid: string;
  slug: string;
};
export const createNonCategoriedRideCF: (
  data: CreateNonCategoriedRideMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-createNonCategoriedRide');
/** *********************************************************************************
 * Clinic
 ********************************************************************************** */
type CreateClinicMutationVariables = {
  cuid: string;
  defaultCurrencyCode: string;
  eventClinicDayDurations: Array<{ cuid: string; dayNumber: number; duration: number | unknown }>;
  name: string;
  organizerCuid: string;
  slug: string;
};
export const createClinicCF: (
  data: CreateClinicMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-createClinic');

/** *********************************************************************************
 * Race
 ********************************************************************************** */
type CreateRaceMutationVariables = {
  cuid: string;
  defaultCurrencyCode: string;
  endDate: string;
  name: string;
  organizerCuid: string;
  slug: string;
  startDate: string;
};
export const createRaceCF: (
  data: CreateRaceMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-event-createRace');

/** *********************************************************************************
 * Algolia
 ********************************************************************************** */
// Update Algolia Index
type UpdateAlgoliaEventsIndexMutationVariables = {
  eventCuid: string;
};
export const updateAlgoliaRaceIndexCF: (
  data: UpdateAlgoliaEventsIndexMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-algolia-updateAlgoliaRaceIndex');
export const updateAlgoliaCategoriedRideIndexCF: (
  data: UpdateAlgoliaEventsIndexMutationVariables,
) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateAlgoliaCategoriedRideIndex',
);
export const updateAlgoliaNonCategoriedRideIndexCF: (
  data: UpdateAlgoliaEventsIndexMutationVariables,
) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateAlgoliaNonCategoriedRideIndex',
);
export const updateAlgoliaClinicIndexCF: (
  data: UpdateAlgoliaEventsIndexMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-algolia-updateAlgoliaClinicIndex');
export const updateAlgoliaLessonsIndexCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateAlgoliaLessonsIndex',
);
export const updateAlgoliaCoachesIndexCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateAlgoliaCoachesIndex',
);
export const updateAlgoliaOnlineCourseIndexCF: (data: {
  onlineCourseCuid: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateAlgoliaOnlineCourseIndex',
);
// Update all the organizers events on name change
export const updateAlgoliaIndexesOnOrganizerSlugChangeCF: ({
  organizerCuid,
}: {
  organizerCuid: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-algolia-updateIndexesOnOrganizerSlugChange',
);

// Delete Algolia Index
type DeleteAlgoliaIndexMutationVariables = {
  cuid: string;
};
export const deleteAlgoliaEventIndexCF: (
  data: DeleteAlgoliaIndexMutationVariables,
) => Promise<{ data: string }> = httpsCallable(functions, 'http-algolia-deleteAlgoliaEventIndex');

/** *********************************************************************************
 * Cloudinary
 ********************************************************************************** */
// Cloudinary Images
// User Owned Images
export const generateUserImageUploadSignatureCF = (httpsCallable(
  functions,
  'http-images-generateUserImageUploadSignature',
) as unknown) as (data: { paramsToSign: Record<string, unknown> }) => Promise<{ data: string }>;
// User Online Course Images
export const generateOnlineCourseImageUploadSignatureCF = (httpsCallable(
  functions,
  'http-images-generateOCImageUploadSignature',
) as unknown) as (data: {
  onlineCourseCuid: string;
  organizerCuid?: string;
  paramsToSign: Record<string, unknown>;
}) => Promise<{ data: string }>;
// Organizer Owned Images
export const generateImageUploadSignatureCF = (httpsCallable(
  functions,
  'http-images-generateImageUploadSignature',
) as unknown) as (data: {
  organizerCuid: string;
  paramsToSign: Record<string, unknown>;
}) => Promise<{ data: string }>;

/** *********************************************************************************
 * Courier
 ********************************************************************************** */
export const getCourierHMACCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-courier-getCourierHMAC',
);
export const updateCourierProfileCF: (data: {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  profilePicUrl: string | null | undefined;
}) => Promise<{ data: boolean }> = httpsCallable(functions, 'http-courier-updateCourierProfile');

/** *********************************************************************************
 * Courses
 ********************************************************************************** */
export const createFatmapCourseCF: (data: {
  routeId: number;
}) => Promise<{ data: PlatformCourseProps }> = httpsCallable(
  functions,
  'http-event-course-createFatmapCourse',
);
export const addFatmapMetricsCF: (data: {
  courseCuid: string;
  routeId: number;
}) => Promise<{ data: null }> = httpsCallable(functions, 'http-event-course-addFatmapMetrics');

export const createRideWithGPSRouteCF: (data: {
  routeId: number;
}) => Promise<{ data: PlatformCourseProps }> = httpsCallable(
  functions,
  'http-event-course-createRideWithGPSRoute',
);
export const addRideWithGPSMetricsCF: (data: {
  courseCuid: string;
  routeId: number;
}) => Promise<{ data: null }> = httpsCallable(functions, 'http-event-course-addRideWithGPSMetrics');

/** *********************************************************************************
 * Stream
 ********************************************************************************** */
export const getStreamUserTokenCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stream-getStreamUserToken',
);
export const toggleFreezeOnStreamChannelCF: (
  channelId: string,
) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-stream-toggleFreezeOnStreamChannel',
);
export const updateStreamUserCF: ({
  firstName,
  fullName,
  image,
  lastName,
}: {
  fullName: string;
  firstName: string;
  lastName: string;
  image: string | undefined | null;
}) => Promise<{ data: void }> = httpsCallable(functions, 'http-stream-updateStreamUser');

/** *********************************************************************************
 * Stripe
 ********************************************************************************** */
// Connect
export const createCoachStripeAccountCF: (data: {
  coachCuid: string;
  returnPathname: string;
  slug: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stripe-connect-createCoachStripeAccount',
);
export const createOrganizerStripeAccountCF: (data: {
  organizerCuid: string | undefined;
  organizerProfileUrl: string;
  returnPathname: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stripe-connect-createOrganizerStripeAccount',
);
export const getConnectOnboardUrlByIdCF: (data: {
  accountId: string;
  returnPathname: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stripe-connect-getConnectOnboardUrlById',
);
export const getConnectDashboardUrlCF: (data: {
  accountId: string;
  returnPathname: string;
}) => Promise<{ data: Stripe.LoginLink }> = httpsCallable(
  functions,
  'http-stripe-connect-getConnectDashboardUrl',
);
export const getIsStripeConnectAccountValidCF: (data: {
  accountId: string;
}) => Promise<{ data: { isAccountValid: boolean; isAccountPending: boolean } }> = httpsCallable(
  functions,
  'http-stripe-connect-getIsStripeConnectAccountValid',
);
export const getStripeAccountBalanceCF: (data: {
  accountId: string;
}) => Promise<{
  data: Stripe.Balance;
}> = httpsCallable(functions, 'http-stripe-account-getAccountBalance');

// User
export const verifyIdentityCF: () => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stripe-identity-createVerificationSession',
);
export const getIdentityVerificationInfoCF: () => Promise<{
  data: {
    lastError: Stripe.Identity.VerificationSession['last_error'];
    status: Stripe.Identity.VerificationSession['status'];
  } | null;
}> = httpsCallable(functions, 'http-stripe-identity-getIdentityVerificationInfo');
// export const updateStripeAccountCF: (
//   data: Stripe.CustomerUpdateParams,
// ) => Promise<{ data: null }> = httpsCallable(functions, 'http-stripe-account-updateAccount');
export const getStripeAccountCF: (data?: {
  organizerCuid: string | undefined;
}) => Promise<{ data: Stripe.Account }> = httpsCallable(
  functions,
  'http-stripe-account-getAccount',
);
export const getCustomerPortalUrlCF: (data: {
  returnPathname: string;
}) => Promise<{ data: string }> = httpsCallable(
  functions,
  'http-stripe-account-getCustomerPortalUrl',
);

// Organizer
export const setOrganizerStripeIdToUserStripeId: (data: {
  organizerCuid: string | undefined;
}) => Promise<{ data: boolean }> = httpsCallable(
  functions,
  'http-stripe-organizer-setOrganizerIdToUserId',
);

/*
// //////////////////////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////////////////////
 */

/*
4. Firebase Utility Functions
 */
/**
 * Convert google provider name to human readable provider name
 * @param provider {string} - google provider name
 * @return {string} - Human readable provider name
 */
export function formatProviderName(provider: string): string {
  const { Apple, EmailLink, Facebook, Google, Password } = Firebase.ProviderNames;
  if (provider === EmailLink) {
    return 'Send Sign-in Link to Email';
  }
  if (provider === Password) {
    return 'Email/Password';
  }
  if (provider === Facebook) {
    return 'Facebook';
  }
  if (provider === Google) {
    return 'Google';
  }
  if (provider === Apple) {
    return 'Apple';
  }
  throw Error('Invalid provider');
}

/**
 * Given a provider name, return a Firebase Provider
 *
 * @param {String} providerName - String with the provider type in it (i.e. google.com or facebook)
 * @returns {Firebase Provider}
 */
export function getProviderByProviderName(providerName: string): AuthProvider {
  const { Apple, Facebook, Google } = Firebase.ProviderNames;
  if (!providerName) {
    throw new Error('Please provide a providerName');
  }
  let provider;
  if (providerName.includes(Facebook)) {
    provider = new FacebookAuthProvider();
  } else if (providerName.includes(Google)) {
    provider = new GoogleAuthProvider();
  } else if (providerName.includes(Apple)) {
    provider = new OAuthProvider('apple.com');
  } else {
    throw Error('Invalid login provider declared');
  }
  return provider;
}

/**
 * Does a user have the given Firebase Provider
 */
export function getUserHasFirebaseProvider(
  user: User | null | undefined,
  providerName: ProviderNames,
): boolean {
  if (!user?.providerData || !providerName) {
    return false;
  }
  return Boolean(user.providerData.find((p) => p?.providerId === providerName));
}

/**
 * Firebase stores its persistent storage in the browsers indexedDB
 * Instead of storing our own object in localStorage to see if the user was logged
 * in the last time the visited, we just use firebase's
 */
export const getUserFromIndexDB = async (): Promise<
  (User & { stsTokenManager: { accessToken: string; expirationTime: number } }) | null
> => {
  const { DB, IndexDBKey, Store } = StorageKeys;
  try {
    const indexedDB = await openDB(DB, 1);
    const tx = indexedDB.transaction(Store);
    const store = await tx.objectStore(Store);
    const value = await store.get(IndexDBKey);
    await tx.done;
    if (value?.value) {
      return value.value as
        | (User & { stsTokenManager: { accessToken: string; expirationTime: number } })
        | null;
    }
    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return null;
  }
};

/**
 * Firebase stores its persistent storage in the browsers indexedDB
 * Instead of storing our own object in localStorage to see if the user was logged
 * in the last time the visited, we just use firebase's
 */
export const getIndexDBOrNewFirebaseToken = async (): Promise<string | undefined> => {
  let token;
  try {
    const storageUser = await getUserFromIndexDB();
    if (!storageUser) {
      token = await firebaseAuth.currentUser?.getIdToken(true);
    } else {
      token = storageUser.stsTokenManager.accessToken as string;
      // If token expires within 30 seconds
      if (new Date().getTime() >= storageUser.stsTokenManager.expirationTime - 30000) {
        token = await firebaseAuth.currentUser?.getIdToken(true);
      }
    }
  } catch (err) {
    token = await firebaseAuth.currentUser?.getIdToken(true);
  }
  return token;
};
