import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';
import debounce, { Cancelable } from '@material-ui/core/utils/debounce';
import * as RA from 'ramda-adjunct';

import { useIsSmallScreenDown } from '../ui-utils';

import { useSetIsConfettiGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { replacePathNameWithoutReloadingPage } from '@/lib/path-helpers/routing';

interface GetMobileDetectProps {
  isAndroid(): boolean;
  isDesktop(): boolean;
  isIos(): boolean;
  isMobile(): boolean;
}
const getMobileDetect = (userAgent: NavigatorID['userAgent']): GetMobileDetectProps => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));

  const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = (): boolean => !isMobile();
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
  };
};
export const useMobileDetect = (): GetMobileDetectProps => {
  return getMobileDetect(navigator.userAgent);
};

export const useIsMobile = (): boolean => {
  const isSmallScreenDown = useIsSmallScreenDown();
  const { isMobile } = useMobileDetect();
  return isMobile() && isSmallScreenDown;
};

export function useDebounceState<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return (): void => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function useDebouncedCallback<T extends (...args: never) => never>(
  callback: T,
  wait?: number,
): T & Cancelable {
  return useMemo(() => debounce(callback, wait), [callback, wait]);
}

// Hook
export function useEventListener(
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (event?: any) => void,
  element: HTMLElement | Window = window,
  triggerOnAdded = false,
): void {
  // Create a ref that stores handler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedHandler = useRef<(event?: any) => void>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported || !savedHandler.current) {
        return (): void => undefined;
      }

      // Create event listener that calls handler function stored in ref
      const eventListener = (event?: Event): void => {
        return savedHandler.current ? savedHandler.current(event) : RA.noop();
      };

      // Call listener when added
      if (triggerOnAdded) {
        eventListener();
      }

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return (): void => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}

/*
Set focus on an input
 */
export const useInputFocus = (): [MutableRefObject<HTMLInputElement | undefined>, () => void] => {
  const inputRef = useRef<HTMLInputElement>();
  const setFocus = (): void => {
    const currentEl = inputRef.current;
    if (currentEl) {
      currentEl.focus();
    }
  };
  return [inputRef, setFocus];
};

/**
 * Show confetti if we have a specific url param.
 * Replace it afterward so we only show it once
 */
export const useShowConfettiOnMount = (urlParam?: string): void => {
  const setIsConfettiGlobal = useSetIsConfettiGlobal();
  useMount(() => {
    const queryParams = getParsedQueryParams();
    if (urlParam) {
      // We are looking for a specific url param to show confetti
      replacePathNameWithoutReloadingPage(window.location.pathname);
      if (!queryParams?.[urlParam]) {
        return;
      }
    }
    setTimeout(() => setIsConfettiGlobal(true), 1);
  });
};

export const useShowConfetti = (): (() => void) => {
  const setIsConfettiGlobal = useSetIsConfettiGlobal();
  return (): void => {
    setTimeout(() => setIsConfettiGlobal(true), 1);
  };
};

/**
 * Countdown timer
 * @param initialCountdown in seconds
 */
export const useCountdown = (initialCountdown: number): [number, () => void] => {
  const [countdown, setCountdown] = useState(0);
  const resetCountdown = (): void => {
    setCountdown(initialCountdown);
  };
  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return (): void => clearInterval(interval);
    }
    return (): void => undefined;
  }, [countdown]);
  return [countdown, resetCountdown];
};
