import React, { ChangeEvent } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useMobileMenuStyles } from '@/ui/styles/mobileMenuStyles';

import { useAuth } from '@/providers/AuthProvider';
import { AppDirectories } from '@/routes/route-metadata';
import { AppRouteService } from '@/routes/RouteService';

interface ProfileAccordionProps {
  handleChange: (panel: string) => (event: ChangeEvent<unknown>, isExpanded: boolean) => void;
  handleMobileMenuClose: () => void;
  expanded: string | null;
}
const ProfileAccordion: React.FC<ProfileAccordionProps> = ({
  expanded,
  handleChange,
  handleMobileMenuClose,
}) => {
  const classes = useMobileMenuStyles();
  const auth = useAuth();
  const location = useLocation();

  const isDashboardPage = location.pathname === AppRouteService.getRelativeUrl('User_Dashboard');
  const isBusinessPage = location.pathname.includes(AppDirectories.User_Business);
  const isOnProfilePage =
    !isDashboardPage &&
    location.pathname.includes(AppRouteService.getRelativeUrl('User_Dashboard')) &&
    !isBusinessPage;

  /**
   * Logout
   */
  const logout = (): void => {
    auth.logout().then();
    handleMobileMenuClose();
  };

  return (
    <Accordion
      className={classes.menuItem}
      // expanded={expanded === 'panel4'}
      // onChange={handleChange('panel4')}
    >
      <AccordionSummary
        aria-controls="panel4bh-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel4bh-header"
      >
        <Typography
          className={classes.heading}
          style={{ textDecoration: isOnProfilePage ? 'underline' : undefined }}
        >
          Profile
        </Typography>
        <Typography
          className={classes.secondaryHeading}
          style={{ textDecoration: isOnProfilePage ? 'underline' : undefined }}
        >
          Your personal information
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          {/* <MenuItem */}
          {/*  activeStyle={{ */}
          {/*    textDecoration: 'underline', */}
          {/*  }} */}
          {/*  component={NavLink} */}
          {/*  onClick={handleMobileMenuClose} */}
          {/*  style={{ color: 'inherit' }} */}
          {/*  to={AppRouteService.getRelativeUrl('CyclistProfile', { cyclistSlug: auth.user.slug })} */}
          {/* > */}
          {/*  My Profile */}
          {/* </MenuItem> */}
          {/* <ListSubheader component="div" id="nested-list-subheader"> */}
          {/*  <Divider /> */}
          {/* </ListSubheader> */}
          <MenuItem
            component={NavLink}
            onClick={handleMobileMenuClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('User_Registrations')}
          >
            Registrations & Lessons
          </MenuItem>
          <MenuItem
            component={NavLink}
            onClick={handleMobileMenuClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('User_OnlineCourses')}
          >
            Purchased Online Courses
          </MenuItem>

          <MenuItem
            component={NavLink}
            onClick={handleMobileMenuClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('User_Messages')}
          >
            Direct Messages
          </MenuItem>
          <MenuItem
            component={NavLink}
            onClick={handleMobileMenuClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('User_EditProfile')}
          >
            Edit Profile
          </MenuItem>
          <MenuItem
            component={NavLink}
            onClick={handleMobileMenuClose}
            style={({ isActive }): React.CSSProperties => ({
              color: 'inherit',
              fontWeight: isActive ? 'bold' : 'normal',
            })}
            to={AppRouteService.getRelativeUrl('User_AccountSettings')}
          >
            Account Settings
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProfileAccordion;
