import React, { useEffect, useRef, useState } from 'react';
import ReactMapGL, { MapRef } from 'react-map-gl';
import { Box, Button, ButtonGroup, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import * as RA from 'ramda-adjunct';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import FadeComponent from '@/hoc/FadeComponent';

import 'mapbox-gl/dist/mapbox-gl.css';

import SearchMarker from '@/containers/Search/Map/SearchMarker';
import ENV from '@/env';
import { useGeoSearch } from '@/lib/algolia-utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .ais-GeoSearch': {
      width: '100%',
      height: '100%',
    },
    '& .ais-GeoSearch-map': {
      width: '100%',
      height: '100%',
    },
  },
  zoomControls: {
    boxShadow: theme.shadows[2],
    zIndex: 1,
    '& button': {
      padding: theme.spacing(1, 1.25),
    },
  },
}));

const ZOOM_AMOUNT = 1.25;

interface SearchMapProps {
  height: string;
}
const SearchMap: React.FC<SearchMapProps> = ({ height }) => {
  // TODO: Fix overlaping markers
  //  https://stackoverflow.com/questions/33158438/spread-overlapping-points-in-a-circle-r
  //  OR
  // TODO Implement clustering
  //  https://github.com/leighhalliday/mapbox-clustering/blob/master/src/App.js
  const classes = useStyles();
  const mapRef = useRef<MapRef>(null);
  // const setQueryParams = useSetQueryParams();
  // const searchFiltersGlobal = useGetSearchFiltersGlobal();
  // const setSearchFiltersGlobal = useSetSearchFiltersGlobal();
  const { currentRefinement, items: hits, refine } = useGeoSearch();
  const [showSearch, setShowSearch] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);

  /**
   * Fit the map to the bounds of the markers
   */
  const fitBounds = (mapboxMap: MapRef | null): void => {
    if (!mapboxMap) {
      return;
    }

    // Do any of the results have geoloc?
    const allPoints: [number, number][] = RA.compact(
      // eslint-disable-next-line no-underscore-dangle
      hits.map((hit) => (hit._geoloc?.lat ? [hit._geoloc.lng, hit._geoloc.lat] : null)),
    );
    if (allPoints.length === 0) {
      setIsInitializing(false);
      return;
    }

    // Make sure we have at least two points by just duplicating if we have one
    const points = allPoints.length === 1 ? [...allPoints, ...allPoints] : allPoints;
    // calculate the bounding box of the feature
    const [minLng, minLat, maxLng, maxLat] = bbox(lineString(points));
    mapboxMap.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 80, duration: 1000, maxZoom: 11 },
    );
    // Wait until animation is done before initializing
    setTimeout(() => {
      setIsInitializing(false);
    }, 1050);
  };

  /**
   * Refit on load and when the search query changes
   */
  useEffect(() => {
    if (mapRef.current && !currentRefinement) {
      // Reset search button
      setIsInitializing(true);
      setShowSearch(false);
      fitBounds(mapRef.current);
    }
  }, [!!mapRef.current, JSON.stringify(hits), !!currentRefinement]);

  const handleSearchThisArea = (): void => {
    const bounds = mapRef.current?.getMap?.()?.getBounds?.();
    if (bounds) {
      setShowSearch(false);
      refine({
        northEast: {
          lat: bounds.getNorthEast().lat,
          lng: bounds.getNorthEast().lng,
        },
        southWest: {
          lat: bounds.getSouthWest().lat,
          lng: bounds.getSouthWest().lng,
        },
      });
    }
  };

  const handleMoveEnd = (): void => {
    if (!isInitializing) {
      setShowSearch(true);
    }
  };

  /**
   * Re-search when user stops moving/zooming map
   * Note: This fires on every move even if not user initiated
   */
  // const handleMoveEnd = (e): void => {
  //   const bounds = mapRef.current?.getMap?.()?.getBounds?.();
  //   if (bounds) {
  //     const flatBounds = [
  //       [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
  //       [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
  //     ] as [[number, number], [number, number]];
  //     // setSearchFiltersGlobal((prevValues) => ({
  //     //   ...prevValues,
  //     //   bounds: flatBounds,
  //     // }));
  //     // setQueryParams({ bounds: flatBounds });
  //     // refine({
  //     //   northEast: {
  //     //     lat: bounds.getNorthEast().lat,
  //     //     lng: bounds.getSouthWest().lat,
  //     //   },
  //     //   southWest: {
  //     //     lat: bounds.getSouthWest().lat,
  //     //     lng: bounds.getSouthWest().lng,
  //     //   },
  //     // });
  //   }
  // };

  /**
   * Zoom in
   */
  const handleZoomIn = (): void => {
    mapRef.current?.zoomTo(mapRef.current?.getZoom() + ZOOM_AMOUNT);
  };

  /**
   * Zoom out
   */
  const handleZoomOut = (): void => {
    mapRef.current?.zoomTo(mapRef.current?.getZoom() - ZOOM_AMOUNT);
  };

  return (
    <Box className={classes.root} height={height} id="search-map">
      <ReactMapGL
        // initialViewState={{
        //   bounds: searchFiltersGlobal.bounds,
        //   fitBoundsOptions: { padding: 56, maxZoom: 14 },
        // }}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        mapboxAccessToken={ENV.MAPBOX_PUBLIC_KEY}
        onDragEnd={handleMoveEnd}
        onResize={(): void => fitBounds(mapRef.current)}
        onZoomEnd={handleMoveEnd}
        ref={mapRef}
        style={{ height: '100vh' }}
      >
        <Box display="flex" justifyContent="space-between" p={3}>
          <div />
          <FadeComponent duration={100} shouldFade={showSearch}>
            <ExtendedButton color="primary" onClick={handleSearchThisArea} variant="contained">
              Search this area
            </ExtendedButton>
          </FadeComponent>
          <ButtonGroup
            disableRipple
            aria-label="vertical outlined primary button group"
            className={classes.zoomControls}
            orientation="vertical"
          >
            <Button onClick={handleZoomIn}>
              <AddIcon fontSize="small" />
            </Button>
            <Button onClick={handleZoomOut}>
              <RemoveIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </Box>

        {hits.map((hit) => (
          <SearchMarker hit={hit} key={hit.objectID} />
        ))}
      </ReactMapGL>
    </Box>
  );
};

export default SearchMap;
