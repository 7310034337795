import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql, PreloadedQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Input, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import { fetchQuery } from 'relay-runtime';

import BrandTypography from '@/ui/BrandTypography';
import DialogCloseButton from '@/ui/Button/DialogCloseButton';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import FormTextField from '@/ui/TextField/FormTextField';
import InputLabel from '@/ui/TextField/InputLabel';
import BoxShadowScrollIndicator from '@/hoc/BoxShadowScrollIndicator';

import DeleteConfirmClausesDialog from '@/components/Dialogs/DeleteConfirmationClausesDialog';
import TypeformContactUs from '@/components/Typeform/TypeformContactUs';
import ProfileImage from '@/components/User/ProfileImage';

import {
  EditProfileDialog_OrganizerQuery,
  EditProfileDialog_OrganizerQueryResponse,
} from './__generated__/EditProfileDialog_OrganizerQuery.graphql';
import {
  EditProfileDialogEventsWithRegistrationsQuery,
  EditProfileDialogEventsWithRegistrationsQueryResponse,
} from './__generated__/EditProfileDialogEventsWithRegistrationsQuery.graphql';
import { EditProfileDialogOrganizerStripeAccountQuery } from './__generated__/EditProfileDialogOrganizerStripeAccountQuery.graphql';

import { useTrackSentryError } from '@/analytics/sentry';
import {
  MAX_ORGANIZER_ABOUT_LENGTH,
  MAX_ORGANIZER_NAME_LENGTH,
  MAX_ORGANIZER_WEBSITE_LENGTH,
  NON_EXISTENT_CUID,
} from '@/const';
import { useSaveOrganizerProfileMutation } from '@/containers/OrganizerProfile/Admin/organizerProfileMutations';
import { RegistrationStatusEnum, RoleOrganizerEnum } from '@/graphql/__generated__/graphql';
import {
  FormattedCloudinaryImageDataProps,
  useCreateCloudinaryUploadWidget,
} from '@/lib/cloudinary';
import { getEventName } from '@/lib/event-info-utils';
import {
  deleteOrganizerCF,
  firebaseAuth,
  getPaymentIntentsCF,
  getStripeAccountBalanceCF,
  getValidOrganizerSlugCF,
  updateAlgoliaIndexesOnOrganizerSlugChangeCF,
} from '@/lib/firebase';
import { EditOrganizerAboutFormSchema } from '@/lib/form-schema/organizer';
import { useScrollToRHFErrorByContainer } from '@/lib/form-schema/react-hook-form-utils';
import { useDebounceState } from '@/lib/hooks/hooks';
import { userHasAllOrganizerRoles } from '@/lib/role-helpers/organizer-role-utils';
import { getUserOrganizerRoleObjectsBySlug } from '@/lib/selectors/user';
import {
  arrayToCommaAndString,
  removeExtraWhitespace,
  trimAndRemoveNewLines,
} from '@/lib/string-utils';
import { hasAccountBalance } from '@/lib/stripe-utils';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: 850,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      height: '100%',
      margin: 0,
      maxHeight: 'none',
      width: '100%',
    },
  },
  centerImage: {
    margin: '0 !important',
    top: '0 !important',
    cursor: 'pointer',
  },
}));

export const EDIT_PROFILE_DIALOG_QUERY = graphql`
  query EditProfileDialog_OrganizerQuery($organizerSlug: String!) {
    organizer_connection(where: { slug: { _eq: $organizerSlug } }) {
      edges {
        node {
          id
          cuid
          name
          about
          website
          slug
          defaultCurrencyCode
          image {
            bytes
            format
            height
            originalFilename
            publicId
            relativeUrl
            resourceType
            url
            version
            width
            organizerCuid
          }
        }
      }
    }
  }
`;
const ORGANIZER_STRIPE_ACCOUNT_QUERY = graphql`
  query EditProfileDialogOrganizerStripeAccountQuery($organizerCuid: String!) {
    organizer_connection(where: { cuid: { _eq: $organizerCuid } }) {
      edges {
        node {
          stripeConnectAccount {
            accountId
          }
        }
      }
    }
  }
`;

const UPCOMING_EVENTS_WITH_REGISTRATIONS_QUERY = graphql`
  query EditProfileDialogEventsWithRegistrationsQuery($organizerCuid: String!) {
    registration_connection(
      where: {
        _and: [
          { event: { organizer: { cuid: { _eq: $organizerCuid } } } }
          {
            _or: [
              { event: { startDate: { _gte: "now()" } } }
              { ride: { rideDays: { startTime: { _gte: "now()" } } } }
              { clinic: { clinicDays: { startTime: { _gte: "now()" } } } }
            ]
          }
          { _or: [{ status: { _eq: complete } }, { status: { _eq: canceled } }] }
        ]
      }
      distinct_on: [eventCuid]
    ) {
      edges {
        node {
          status
          eventCuid
          referenceNo
          stripeCheckoutId
          event {
            occurrenceLabel
            eventMetadata {
              name
            }
          }
        }
      }
    }
  }
`;
interface EditOrganizerAboutFormProps {
  about: string;
  defaultCurrencyCode: string;
  image:
    | EditProfileDialog_OrganizerQueryResponse['organizer_connection']['edges'][0]['node']['image']
    | null;
  name: string;
  website: string;
}
interface EditAboutDialogProps {
  closeDialog: () => void;
  isDialogOpen: boolean;
  profileViewData: EditProfileDialog_OrganizerQueryResponse['organizer_connection']['edges'][0]['node'];
}
const EditProfileDialog: React.FC<EditAboutDialogProps> = ({
  closeDialog,
  isDialogOpen,
  profileViewData,
}) => {
  // Form
  const formMethods = useForm<EditOrganizerAboutFormProps>({
    defaultValues: {
      image: profileViewData.image,
      name: profileViewData.name ?? '',
      about: profileViewData.about ?? '',
      website: profileViewData.website ?? '',
      defaultCurrencyCode: profileViewData.defaultCurrencyCode,
    },
    resolver: yupResolver(EditOrganizerAboutFormSchema),
  });
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = formMethods;

  const classes = useStyles();
  const auth = useAuth();
  useScrollToRHFErrorByContainer(errors);
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const trackSentryError = useTrackSentryError();
  const isSmallScreenDown = useIsSmallScreenDown();
  const [isLoading, setIsLoading] = useState(false);
  const [isSlugLoading, setIsSlugLoading] = useState(false);
  const [updatedSlug, setUpdatedSlug] = useState(profileViewData.slug);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteError, setDeleteError] = useState<ReactNode>('');

  const { saveOrganizerProfileMutation } = useSaveOrganizerProfileMutation();

  const organizerRoles = getUserOrganizerRoleObjectsBySlug(auth.user, profileViewData.slug);
  const canEditBilling = userHasAllOrganizerRoles(
    organizerRoles?.map((userOrganizerRole) => userOrganizerRole.role),
    [RoleOrganizerEnum.Owner],
  );

  const watchImage = watch('image');
  const watchName = watch('name');

  const nameHasChanged =
    trimAndRemoveNewLines(removeExtraWhitespace(watchName)) !== profileViewData.name;

  /**
   * Did the user change their slug?
   */
  const debouncedName = useDebounceState(watchName, 200);
  useEffect(() => {
    let didCancel = false;
    (async (): Promise<void> => {
      try {
        const newSlug = profileViewData.slug;
        if (!nameHasChanged) {
          setUpdatedSlug(newSlug);
          return;
        }
        setIsSlugLoading(true);
        // Get updated slug
        const { data } = await getValidOrganizerSlugCF({
          organizerName: getValues('name'),
          organizerCuid: profileViewData.cuid,
        });
        // Solve for race conditions
        if (!didCancel) {
          setUpdatedSlug(data.slug);
        }
      } catch (err) {
        trackSentryError(err);
      } finally {
        if (!didCancel) {
          setIsSlugLoading(false);
        }
      }
    })();
    return (): void => {
      didCancel = true;
    };
  }, [nameHasChanged, debouncedName]);

  /**
   * Add an image
   */
  const handleUploadSuccess = (data: FormattedCloudinaryImageDataProps): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore we know that we have a organizerCuid
    setValue('image', data);
  };
  const uploadWidget = useCreateCloudinaryUploadWidget({
    organizerCuid: profileViewData.cuid,
    maxFiles: 1,
    uploadPreset: 'organizer_images',
    onSuccess: handleUploadSuccess,
  });
  const openUploadWidget = (): void => {
    uploadWidget?.open();
  };

  /**
   * Delete Organization
   */
  const showDeleteConfirmationDialog = (): void => {
    setIsDeleteDialogOpen(true);
  };
  const hideGenerateConfirmationDialog = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const deleteOrganizer = async (): Promise<void> => {
    try {
      // Don't allow delete with account balance
      const fetchStripeConnectAccountId = (): Promise<string | undefined> => {
        return new Promise((resolve, reject) => {
          fetchQuery<EditProfileDialogOrganizerStripeAccountQuery>(
            environment,
            ORGANIZER_STRIPE_ACCOUNT_QUERY,
            {
              organizerCuid: profileViewData.cuid,
            },
          ).subscribe({
            next: (data) => {
              resolve(data.organizer_connection.edges?.[0]?.node?.stripeConnectAccount?.accountId);
            },
            error: (err: Error) => {
              reject(err);
            },
          });
        });
      };
      const stripeConnectAccountId = await fetchStripeConnectAccountId();
      if (stripeConnectAccountId) {
        const balance = await getStripeAccountBalanceCF({
          accountId: stripeConnectAccountId,
        });
        if (hasAccountBalance(balance.data)) {
          setDeleteError(
            <>
              Can't delete organizer.
              <br />
              The organization you own, <b>{profileViewData.name}</b> has unpaid-out funds.
              <br />
              Please wait until after your next payout date to delete your organization.
            </>,
          );
          return;
        }
      }

      // Check if there are any upcoming events with registrations
      const fetchUpcomingEventsWithRegistrations = (): Promise<
        EditProfileDialogEventsWithRegistrationsQueryResponse['registration_connection']['edges'][0]['node'][]
      > => {
        return new Promise((resolve, reject) => {
          fetchQuery<EditProfileDialogEventsWithRegistrationsQuery>(
            environment,
            UPCOMING_EVENTS_WITH_REGISTRATIONS_QUERY,
            {
              organizerCuid: profileViewData.cuid,
            },
          ).subscribe({
            next: (data) => {
              resolve(data.registration_connection.edges.map(({ node }) => node));
            },
            error: (err: Error) => {
              reject(err);
            },
          });
        });
      };
      const upcomingRegistrations = await fetchUpcomingEventsWithRegistrations();
      const upcomingEventsWithRegistrations = upcomingRegistrations.map((reg) =>
        getEventName(reg.event),
      );
      if (upcomingRegistrations?.length) {
        // Make sure all the registrations are canceled AND refunded
        let isDeletable = true;
        for (const registration of upcomingRegistrations) {
          if (registration.status !== RegistrationStatusEnum.Canceled) {
            // We have a non-canceled registration
            isDeletable = false;
            break;
          }
          if (
            registration.status === RegistrationStatusEnum.Canceled &&
            registration.stripeCheckoutId
          ) {
            // We have a canceled non-refunded registration
            // eslint-disable-next-line no-await-in-loop
            const response = await getPaymentIntentsCF({
              eventCuid: registration.eventCuid,
              referenceNo: registration.referenceNo,
            });
            const chargesWithoutFullRefund = response.data.charges.filter((charge) => {
              const totalAmount = charge.amount - charge.application_fee_amount;
              const totalRefunded = R.sum(
                charge.charge.refunds.map((refund) => refund.amount ?? 0),
              );
              return totalAmount > totalRefunded;
            });
            if (chargesWithoutFullRefund.length) {
              isDeletable = false;
            }
            break;
          }
        }
        if (!isDeletable) {
          setDeleteError(
            <>
              Can't delete organizer.
              <br />
              The upcoming events have registrations:{' '}
              <b>{arrayToCommaAndString(upcomingEventsWithRegistrations)}</b> <br />
              Please wait until after the event, or cancel & refund the registrants before you
              delete your organization.
            </>,
          );
          return;
        }
      }

      await deleteOrganizerCF({ organizerCuid: profileViewData.cuid });
      const { currentUser } = firebaseAuth;
      // Get the new user token with updated claims
      await currentUser?.getIdToken(true);
      window.location.href = AppRouteService.getAbsoluteUrl('Home');
      enqueueSnackbar('Organizer deleted successfully!', { variant: 'success' });
    } catch (err) {
      trackSentryError(err);
    }
  };

  /**
   * Save the organizations about
   */
  const handleSave = handleSubmit(
    async (data): Promise<void> => {
      try {
        setIsLoading(true);
        // If we changed the image, we need to delete the old image from the DB.
        let publicIdToDelete = NON_EXISTENT_CUID;
        if (
          profileViewData.image?.publicId &&
          profileViewData.image.publicId !== data.image?.publicId
        ) {
          publicIdToDelete = profileViewData.image.publicId;
        }

        // Save a previous slug so we can update ir in the relay store without
        // having to worry.
        await saveOrganizerProfileMutation({
          name: data.name,
          website: data.website,
          about: data.about,
          image: data.image,
          defaultCurrencyCode: data.defaultCurrencyCode,
          slug: updatedSlug,
          organizerId: { cuid: profileViewData.cuid, nodeId: profileViewData.id },
          publicIdToDelete,
        });
        // Async update search indexes
        await updateAlgoliaIndexesOnOrganizerSlugChangeCF({
          organizerCuid: profileViewData.cuid,
        }).then();
        // We only want to reload the page if the slug has been updated
        if (nameHasChanged) {
          // Go to the new slug
          window.location.href = AppRouteService.getAbsoluteUrl('OrganizerProfile', {
            organizerSlug: updatedSlug,
          });
          return;
        }
        enqueueSnackbar('Saved!', { variant: 'success' });
        setIsLoading(false);
        closeDialog();
      } catch (err) {
        trackSentryError(err);
        setIsLoading(false);
      }
    },
  );

  return (
    <Dialog
      disableBackdropClick
      aria-labelledby="org-about-dialog-title"
      classes={{ paper: classes.dialog }}
      maxWidth="sm"
      onClose={closeDialog}
      open={isDialogOpen}
    >
      {isDeleteDialogOpen && (
        <DeleteConfirmClausesDialog
          clauses={[
            'I will not be able to recover this organization.',
            "I will no longer be able to access any of the organization's events.",
            "I will no longer be able to access the organization's Stripe account.",
            'Users who interacted with the organization will still have access to their registrations, results, receipts, and purchased online courses.',
          ]}
          clausesPre="I understand that once I delete this organization,"
          isOpen={isDeleteDialogOpen}
          onClose={hideGenerateConfirmationDialog}
          onDelete={deleteOrganizer}
          title={`Delete ${profileViewData.name}`}
        >
          <Typography paragraph>
            Thank you for using{' '}
            <BrandTypography inline noLink>
              Reggy
            </BrandTypography>{' '}
            to run {profileViewData.name}.
          </Typography>
          <Typography paragraph>
            There are a lot of consequences to deleting an organization, so please run through the
            agreement.
          </Typography>
        </DeleteConfirmClausesDialog>
      )}
      <Box p={3} pb={2.5}>
        <Typography variant="h2">Edit Profile</Typography>
      </Box>
      <DialogCloseButton
        handleClose={closeDialog}
        iconButtonProps={{ style: { padding: '16px' } }}
      />
      <BoxShadowScrollIndicator noBorder>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Hide a registered input so we can save our data to RHF */}
            <Input style={{ display: 'none' }} type="hidden" {...register('image')} />
            <Grid item xs={12}>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box mb={1}>
                  <InputLabel>Organization Logo</InputLabel>
                </Box>
                <ProfileImage
                  center
                  boxProps={{ onClick: openUploadWidget }}
                  imageUrl={watchImage?.relativeUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Organization Name</InputLabel>
              <FormTextField
                fullWidth
                control={formMethods.control}
                error={!!errors?.name}
                getValues={formMethods.getValues}
                helperText={errors?.name?.message}
                inputProps={{ maxLength: MAX_ORGANIZER_NAME_LENGTH }}
                margin="dense"
                name="name"
                variant="filled"
              />
            </Grid>
            {nameHasChanged && profileViewData.slug !== updatedSlug && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  Changing your name will also update your organizer profile url to{' '}
                  <b>
                    {AppRouteService.getRelativeUrl('OrganizerProfile', {
                      organizerSlug: updatedSlug,
                    })}
                  </b>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Website</InputLabel>
              <FormTextField
                fullWidth
                control={formMethods.control}
                error={!!errors?.website}
                getValues={formMethods.getValues}
                helperText={errors?.website?.message}
                inputProps={{ maxLength: MAX_ORGANIZER_WEBSITE_LENGTH }}
                margin="dense"
                name="website"
                placeholder="www.mygreatorganization.com"
                variant="filled"
              />
            </Grid>
            {/**/}
            {/* IMPORTANT! If you re-enable this, you need to remove the auto currency update in */}
            {/* updateConnectCapabilities */}
            {/**/}
            {/* <Grid item xs={12}> */}
            {/*  <InputLabel>Default Currency</InputLabel> */}
            {/*  <FormSelect */}
            {/*    fullWidth */}
            {/*    error={!!errors.defaultCurrencyCode} */}
            {/*    formMethods={formMethods} */}
            {/*    helperText={errors?.defaultCurrencyCode?.message} */}
            {/*    margin="dense" */}
            {/*    name="defaultCurrencyCode" */}
            {/*    variant="filled" */}
            {/*  > */}
            {/*    {CURRENCY.map((cur) => { */}
            {/*      if (cur.currency === 'SelectDivider') { */}
            {/*        return ( */}
            {/*          <ListSubheader key={`general-currency-${cur.currency}`}> */}
            {/*            <Box p={1}> */}
            {/*              <Divider /> */}
            {/*            </Box> */}
            {/*          </ListSubheader> */}
            {/*        ); */}
            {/*      } */}
            {/*      return ( */}
            {/*        <MenuItem key={`categories-currency-${cur.currency}`} value={cur.code}> */}
            {/*          {cur.currency} (<b>{cur.symbol}</b>) */}
            {/*        </MenuItem> */}
            {/*      ); */}
            {/*    })} */}
            {/*  </FormSelect> */}
            {/* </Grid> */}
            <Grid item xs={12}>
              <InputLabel>About</InputLabel>
              <FormTextField
                fullWidth
                multiline
                control={formMethods.control}
                error={!!errors?.about}
                getValues={formMethods.getValues}
                helperText={errors?.about?.message}
                inputProps={{ maxLength: MAX_ORGANIZER_ABOUT_LENGTH }}
                margin="dense"
                name="about"
                placeholder="We're Eliot's Awesome Races. Our races feature challenging courses through the beautiful landscapes of Southern California and provide a fun and exciting experience for both participants and spectators. We strive to create a welcoming and inclusive atmosphere for all attendees and are committed to the safety and enjoyment of everyone involved. Whether you are a seasoned pro or new to the sport, we welcome you to join us at one of our exciting mountain bike races."
                rows={4}
                rowsMax={Infinity}
                variant="filled"
              />
            </Grid>
            {canEditBilling && (
              <>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Typography variant="h3">Transfer Ownership</Typography>
                  </Box>
                  <Box mb={1.5} mt={0.5}>
                    <Typography variant="body2">
                      If you would like to transfer your organization to another user, please
                      contact us.
                    </Typography>
                  </Box>
                  <TypeformContactUs button buttonProps={{ minWidth: 'sm' }} />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Typography variant="h3">Delete Organization</Typography>
                  </Box>
                  <Box mb={1.5} mt={0.5}>
                    <Typography variant="body2">Delete your organization permanently</Typography>
                  </Box>
                  <ExtendedButton
                    errorColor
                    minWidth="sm"
                    onClick={showDeleteConfirmationDialog}
                    size="small"
                    variant="outlined"
                  >
                    Delete {profileViewData.name}
                  </ExtendedButton>
                </Grid>
                {deleteError && (
                  <Grid item xs={12}>
                    <Alert severity="error">{deleteError}</Alert>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </BoxShadowScrollIndicator>
      <Box display="flex" justifyContent="space-between" px={3} py={1.5}>
        <Box mr={1}>
          <ExtendedButton
            minWidth={isSmallScreenDown ? 'xs' : 'sm'}
            onClick={closeDialog}
            variant="outlined"
          >
            Cancel
          </ExtendedButton>
        </Box>
        <ExtendedButton
          color="primary"
          isLoading={isLoading || isSlugLoading}
          minWidth={isSmallScreenDown ? 'xs' : 'sm'}
          onClick={handleSave}
          variant="contained"
        >
          Save
        </ExtendedButton>
      </Box>
    </Dialog>
  );
};

/**
 * Used remotely to open this dialog. i.e. Organizer App navigation
 */
interface EditProfileLoaderProps {
  closeDialog: () => void;
  isDialogOpen: boolean;
  editOrganizerProfileQueryRef: PreloadedQuery<EditProfileDialog_OrganizerQuery>;
}
export const EditProfileLoader: React.FC<EditProfileLoaderProps> = ({
  closeDialog,
  editOrganizerProfileQueryRef,
  isDialogOpen,
}) => {
  const profileViewData = usePreloadedQuery<EditProfileDialog_OrganizerQuery>(
    EDIT_PROFILE_DIALOG_QUERY,
    editOrganizerProfileQueryRef,
  );

  return (
    <Suspense fallback={null}>
      <EditProfileDialog
        closeDialog={closeDialog}
        isDialogOpen={isDialogOpen}
        profileViewData={profileViewData.organizer_connection.edges[0].node}
      />
    </Suspense>
  );
};

export default EditProfileDialog;
