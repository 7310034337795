export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamp: any;
  timestamptz: any;
};

/** Names for access to categories/events/volunteeers/etc */
export type AccessName = Node & {
  __typename?: 'AccessName';
  /** An array relationship */
  eventPitSpaces: Array<EventPitSpace>;
  /** An aggregate relationship */
  eventPitSpaces_aggregate: EventPitSpaceAggregate;
  /** An array relationship connection */
  eventPitSpaces_connection: EventPitSpaceConnection;
  /** An array relationship */
  eventVolunteerJobShifts: Array<EventVolunteerJobShift>;
  /** An aggregate relationship */
  eventVolunteerJobShifts_aggregate: EventVolunteerJobShiftAggregate;
  /** An array relationship connection */
  eventVolunteerJobShifts_connection: EventVolunteerJobShiftConnection;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: EventAggregate;
  /** An array relationship connection */
  events_connection: EventConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventPitSpacesArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventPitSpacesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventPitSpacesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventVolunteerJobShiftsArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventVolunteerJobShiftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventVolunteerJobShiftsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventsArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** Names for access to categories/events/volunteeers/etc */
export type AccessNameEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};

/** A Relay connection object on "AccessName" */
export type AccessNameConnection = {
  __typename?: 'AccessNameConnection';
  edges: Array<AccessNameEdge>;
  pageInfo: PageInfo;
};

export type AccessNameEdge = {
  __typename?: 'AccessNameEdge';
  cursor: Scalars['String'];
  node: AccessName;
};

/** Boolean expression to filter rows from the table "AccessName". All fields are combined with a logical 'AND'. */
export type AccessNameBoolExp = {
  _and?: InputMaybe<Array<AccessNameBoolExp>>;
  _not?: InputMaybe<AccessNameBoolExp>;
  _or?: InputMaybe<Array<AccessNameBoolExp>>;
  eventPitSpaces?: InputMaybe<EventPitSpaceBoolExp>;
  eventPitSpaces_aggregate?: InputMaybe<EventPitSpaceAggregateBoolExp>;
  eventVolunteerJobShifts?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  eventVolunteerJobShifts_aggregate?: InputMaybe<EventVolunteerJobShiftAggregateBoolExp>;
  events?: InputMaybe<EventBoolExp>;
  events_aggregate?: InputMaybe<EventAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "AccessName" */
export enum AccessNameConstraint {
  /** unique or primary key constraint on columns "name" */
  AccessNamePkey = 'AccessName_pkey'
}

export enum AccessNameEnum {
  Invite = 'Invite',
  Public = 'Public',
  Unlisted = 'Unlisted'
}

/** Boolean expression to compare columns of type "AccessName_enum". All fields are combined with logical 'AND'. */
export type AccessNameEnumComparisonExp = {
  _eq?: InputMaybe<AccessNameEnum>;
  _in?: InputMaybe<Array<AccessNameEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccessNameEnum>;
  _nin?: InputMaybe<Array<AccessNameEnum>>;
};

/** input type for inserting data into table "AccessName" */
export type AccessNameInsertInput = {
  eventPitSpaces?: InputMaybe<EventPitSpaceArrRelInsertInput>;
  eventVolunteerJobShifts?: InputMaybe<EventVolunteerJobShiftArrRelInsertInput>;
  events?: InputMaybe<EventArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "AccessName" */
export type AccessNameMutationResponse = {
  __typename?: 'AccessName_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessName>;
};

/** input type for inserting object relation for remote table "AccessName" */
export type AccessNameObjRelInsertInput = {
  data: AccessNameInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AccessNameOnConflict>;
};

/** on_conflict condition type for table "AccessName" */
export type AccessNameOnConflict = {
  constraint: AccessNameConstraint;
  update_columns?: Array<AccessNameUpdateColumn>;
  where?: InputMaybe<AccessNameBoolExp>;
};

/** Ordering options when selecting data from "AccessName". */
export type AccessNameOrderBy = {
  eventPitSpaces_aggregate?: InputMaybe<EventPitSpaceAggregateOrderBy>;
  eventVolunteerJobShifts_aggregate?: InputMaybe<EventVolunteerJobShiftAggregateOrderBy>;
  events_aggregate?: InputMaybe<EventAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: AccessName */
export type AccessNamePkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "AccessName" */
export enum AccessNameSelectColumn {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "AccessName" */
export type AccessNameSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccessName" */
export enum AccessNameUpdateColumn {
  /** column name */
  Name = 'name'
}

export type AccessNameUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessNameSetInput>;
  where: AccessNameBoolExp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type FloatComparisonExp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** An object with globally unique ID */
export type Node = {
  /** A globally unique identifier */
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Event activity enum table */
export type ActivityType = Node & {
  __typename?: 'activityType';
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: EventAggregate;
  /** An array relationship connection */
  events_connection: EventConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
};


/** Event activity enum table */
export type ActivityTypeEventsArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** Event activity enum table */
export type ActivityTypeEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** Event activity enum table */
export type ActivityTypeEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};

/** A Relay connection object on "activityType" */
export type ActivityTypeConnection = {
  __typename?: 'activityTypeConnection';
  edges: Array<ActivityTypeEdge>;
  pageInfo: PageInfo;
};

export type ActivityTypeEdge = {
  __typename?: 'activityTypeEdge';
  cursor: Scalars['String'];
  node: ActivityType;
};

/** Boolean expression to filter rows from the table "activityType". All fields are combined with a logical 'AND'. */
export type ActivityTypeBoolExp = {
  _and?: InputMaybe<Array<ActivityTypeBoolExp>>;
  _not?: InputMaybe<ActivityTypeBoolExp>;
  _or?: InputMaybe<Array<ActivityTypeBoolExp>>;
  events?: InputMaybe<EventBoolExp>;
  events_aggregate?: InputMaybe<EventAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "activityType" */
export enum ActivityTypeConstraint {
  /** unique or primary key constraint on columns "name" */
  ActivityTypePkey = 'activityType_pkey'
}

export enum ActivityTypeEnum {
  Clinic = 'Clinic',
  Race = 'Race',
  Ride = 'Ride'
}

/** Boolean expression to compare columns of type "activityType_enum". All fields are combined with logical 'AND'. */
export type ActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<ActivityTypeEnum>;
  _in?: InputMaybe<Array<ActivityTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ActivityTypeEnum>;
  _nin?: InputMaybe<Array<ActivityTypeEnum>>;
};

/** input type for inserting data into table "activityType" */
export type ActivityTypeInsertInput = {
  events?: InputMaybe<EventArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "activityType" */
export type ActivityTypeMutationResponse = {
  __typename?: 'activityType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityType>;
};

/** input type for inserting object relation for remote table "activityType" */
export type ActivityTypeObjRelInsertInput = {
  data: ActivityTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ActivityTypeOnConflict>;
};

/** on_conflict condition type for table "activityType" */
export type ActivityTypeOnConflict = {
  constraint: ActivityTypeConstraint;
  update_columns?: Array<ActivityTypeUpdateColumn>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "activityType". */
export type ActivityTypeOrderBy = {
  events_aggregate?: InputMaybe<EventAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: activityType */
export type ActivityTypePkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "activityType" */
export enum ActivityTypeSelectColumn {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "activityType" */
export type ActivityTypeSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "activityType" */
export enum ActivityTypeUpdateColumn {
  /** column name */
  Name = 'name'
}

export type ActivityTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityTypeSetInput>;
  where: ActivityTypeBoolExp;
};

/** columns and relationships of "amenity" */
export type Amenity = Node & {
  __typename?: 'amenity';
  cuid: Scalars['String'];
  /** An array relationship */
  eventAmenities: Array<EventAmenity>;
  /** An aggregate relationship */
  eventAmenities_aggregate: EventAmenityAggregate;
  /** An array relationship connection */
  eventAmenities_connection: EventAmenityConnection;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  info: Scalars['String'];
  name: Scalars['String'];
};


/** columns and relationships of "amenity" */
export type AmenityEventAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


/** columns and relationships of "amenity" */
export type AmenityEventAmenitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


/** columns and relationships of "amenity" */
export type AmenityEventAmenitiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};

/** A Relay connection object on "amenity" */
export type AmenityConnection = {
  __typename?: 'amenityConnection';
  edges: Array<AmenityEdge>;
  pageInfo: PageInfo;
};

export type AmenityEdge = {
  __typename?: 'amenityEdge';
  cursor: Scalars['String'];
  node: Amenity;
};

/** Boolean expression to filter rows from the table "amenity". All fields are combined with a logical 'AND'. */
export type AmenityBoolExp = {
  _and?: InputMaybe<Array<AmenityBoolExp>>;
  _not?: InputMaybe<AmenityBoolExp>;
  _or?: InputMaybe<Array<AmenityBoolExp>>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventAmenities?: InputMaybe<EventAmenityBoolExp>;
  eventAmenities_aggregate?: InputMaybe<EventAmenityAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  info?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "amenity" */
export enum AmenityConstraint {
  /** unique or primary key constraint on columns "id" */
  PkAmenities = 'PK_amenities',
  /** unique or primary key constraint on columns "cuid" */
  AmenityCuidKey = 'amenity_cuid_key',
  /** unique or primary key constraint on columns "id" */
  AmenityPkey = 'amenity_pkey'
}

/** input type for incrementing numeric columns in table "amenity" */
export type AmenityIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "amenity" */
export type AmenityInsertInput = {
  cuid?: InputMaybe<Scalars['String']>;
  eventAmenities?: InputMaybe<EventAmenityArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "amenity" */
export type AmenityMutationResponse = {
  __typename?: 'amenity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Amenity>;
};

/** input type for inserting object relation for remote table "amenity" */
export type AmenityObjRelInsertInput = {
  data: AmenityInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AmenityOnConflict>;
};

/** on_conflict condition type for table "amenity" */
export type AmenityOnConflict = {
  constraint: AmenityConstraint;
  update_columns?: Array<AmenityUpdateColumn>;
  where?: InputMaybe<AmenityBoolExp>;
};

/** Ordering options when selecting data from "amenity". */
export type AmenityOrderBy = {
  cuid?: InputMaybe<OrderBy>;
  eventAmenities_aggregate?: InputMaybe<EventAmenityAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: amenity */
export type AmenityPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "amenity" */
export enum AmenitySelectColumn {
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "amenity" */
export type AmenitySetInput = {
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "amenity" */
export enum AmenityUpdateColumn {
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name'
}

export type AmenityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AmenityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AmenitySetInput>;
  where: AmenityBoolExp;
};

/** a signature request for a basic waiver. We store the "revision" here as well as it's info */
export type BasicSignatureRequest = Node & {
  __typename?: 'basicSignatureRequest';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  registration: Registration;
  registrationCuid: Scalars['String'];
  /** An object relationship */
  signatureRequestSignerType: SignatureRequestSignerType;
  /** An object relationship */
  signatureRequestStatus: SignatureRequestStatus;
  signerEmail: Scalars['String'];
  signerName: Scalars['String'];
  signerType: SignatureRequestSignerTypeEnum;
  status: SignatureRequestStatusEnum;
  successToken?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  waiverFileUrl: Scalars['String'];
  waiverName: Scalars['String'];
};

/** A Relay connection object on "basicSignatureRequest" */
export type BasicSignatureRequestConnection = {
  __typename?: 'basicSignatureRequestConnection';
  edges: Array<BasicSignatureRequestEdge>;
  pageInfo: PageInfo;
};

export type BasicSignatureRequestEdge = {
  __typename?: 'basicSignatureRequestEdge';
  cursor: Scalars['String'];
  node: BasicSignatureRequest;
};

/** aggregated selection of "basicSignatureRequest" */
export type BasicSignatureRequestAggregate = {
  __typename?: 'basicSignatureRequest_aggregate';
  aggregate?: Maybe<BasicSignatureRequestAggregateFields>;
  nodes: Array<BasicSignatureRequest>;
};

export type BasicSignatureRequestAggregateBoolExp = {
  count?: InputMaybe<BasicSignatureRequestAggregateBoolExpCount>;
};

export type BasicSignatureRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BasicSignatureRequestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "basicSignatureRequest" */
export type BasicSignatureRequestAggregateFields = {
  __typename?: 'basicSignatureRequest_aggregate_fields';
  avg?: Maybe<BasicSignatureRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BasicSignatureRequestMaxFields>;
  min?: Maybe<BasicSignatureRequestMinFields>;
  stddev?: Maybe<BasicSignatureRequestStddevFields>;
  stddev_pop?: Maybe<BasicSignatureRequestStddevPopFields>;
  stddev_samp?: Maybe<BasicSignatureRequestStddevSampFields>;
  sum?: Maybe<BasicSignatureRequestSumFields>;
  var_pop?: Maybe<BasicSignatureRequestVarPopFields>;
  var_samp?: Maybe<BasicSignatureRequestVarSampFields>;
  variance?: Maybe<BasicSignatureRequestVarianceFields>;
};


/** aggregate fields of "basicSignatureRequest" */
export type BasicSignatureRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "basicSignatureRequest" */
export type BasicSignatureRequestAggregateOrderBy = {
  avg?: InputMaybe<BasicSignatureRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BasicSignatureRequestMaxOrderBy>;
  min?: InputMaybe<BasicSignatureRequestMinOrderBy>;
  stddev?: InputMaybe<BasicSignatureRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<BasicSignatureRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<BasicSignatureRequestStddevSampOrderBy>;
  sum?: InputMaybe<BasicSignatureRequestSumOrderBy>;
  var_pop?: InputMaybe<BasicSignatureRequestVarPopOrderBy>;
  var_samp?: InputMaybe<BasicSignatureRequestVarSampOrderBy>;
  variance?: InputMaybe<BasicSignatureRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "basicSignatureRequest" */
export type BasicSignatureRequestArrRelInsertInput = {
  data: Array<BasicSignatureRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BasicSignatureRequestOnConflict>;
};

/** aggregate avg on columns */
export type BasicSignatureRequestAvgFields = {
  __typename?: 'basicSignatureRequest_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "basicSignatureRequest". All fields are combined with a logical 'AND'. */
export type BasicSignatureRequestBoolExp = {
  _and?: InputMaybe<Array<BasicSignatureRequestBoolExp>>;
  _not?: InputMaybe<BasicSignatureRequestBoolExp>;
  _or?: InputMaybe<Array<BasicSignatureRequestBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusBoolExp>;
  signerEmail?: InputMaybe<StringComparisonExp>;
  signerName?: InputMaybe<StringComparisonExp>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnumComparisonExp>;
  status?: InputMaybe<SignatureRequestStatusEnumComparisonExp>;
  successToken?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  waiverFileUrl?: InputMaybe<StringComparisonExp>;
  waiverName?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "basicSignatureRequest" */
export enum BasicSignatureRequestConstraint {
  /** unique or primary key constraint on columns "cuid" */
  BasicSignatureRequestCuidKey = 'basicSignatureRequest_cuid_key',
  /** unique or primary key constraint on columns "id" */
  BasicSignatureRequestPkey = 'basicSignatureRequest_pkey'
}

/** input type for incrementing numeric columns in table "basicSignatureRequest" */
export type BasicSignatureRequestIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "basicSignatureRequest" */
export type BasicSignatureRequestInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeObjRelInsertInput>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusObjRelInsertInput>;
  signerEmail?: InputMaybe<Scalars['String']>;
  signerName?: InputMaybe<Scalars['String']>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnum>;
  status?: InputMaybe<SignatureRequestStatusEnum>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverFileUrl?: InputMaybe<Scalars['String']>;
  waiverName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BasicSignatureRequestMaxFields = {
  __typename?: 'basicSignatureRequest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  registrationCuid?: Maybe<Scalars['String']>;
  signerEmail?: Maybe<Scalars['String']>;
  signerName?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiverFileUrl?: Maybe<Scalars['String']>;
  waiverName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverFileUrl?: InputMaybe<OrderBy>;
  waiverName?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type BasicSignatureRequestMinFields = {
  __typename?: 'basicSignatureRequest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  registrationCuid?: Maybe<Scalars['String']>;
  signerEmail?: Maybe<Scalars['String']>;
  signerName?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiverFileUrl?: Maybe<Scalars['String']>;
  waiverName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverFileUrl?: InputMaybe<OrderBy>;
  waiverName?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "basicSignatureRequest" */
export type BasicSignatureRequestMutationResponse = {
  __typename?: 'basicSignatureRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BasicSignatureRequest>;
};

/** on_conflict condition type for table "basicSignatureRequest" */
export type BasicSignatureRequestOnConflict = {
  constraint: BasicSignatureRequestConstraint;
  update_columns?: Array<BasicSignatureRequestUpdateColumn>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};

/** Ordering options when selecting data from "basicSignatureRequest". */
export type BasicSignatureRequestOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeOrderBy>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusOrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  signerType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverFileUrl?: InputMaybe<OrderBy>;
  waiverName?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: basicSignatureRequest */
export type BasicSignatureRequestPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "basicSignatureRequest" */
export enum BasicSignatureRequestSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  SignerEmail = 'signerEmail',
  /** column name */
  SignerName = 'signerName',
  /** column name */
  SignerType = 'signerType',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverFileUrl = 'waiverFileUrl',
  /** column name */
  WaiverName = 'waiverName'
}

/** input type for updating data in table "basicSignatureRequest" */
export type BasicSignatureRequestSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  signerEmail?: InputMaybe<Scalars['String']>;
  signerName?: InputMaybe<Scalars['String']>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnum>;
  status?: InputMaybe<SignatureRequestStatusEnum>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverFileUrl?: InputMaybe<Scalars['String']>;
  waiverName?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type BasicSignatureRequestStddevFields = {
  __typename?: 'basicSignatureRequest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type BasicSignatureRequestStddevPopFields = {
  __typename?: 'basicSignatureRequest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type BasicSignatureRequestStddevSampFields = {
  __typename?: 'basicSignatureRequest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type BasicSignatureRequestSumFields = {
  __typename?: 'basicSignatureRequest_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "basicSignatureRequest" */
export enum BasicSignatureRequestUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  SignerEmail = 'signerEmail',
  /** column name */
  SignerName = 'signerName',
  /** column name */
  SignerType = 'signerType',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverFileUrl = 'waiverFileUrl',
  /** column name */
  WaiverName = 'waiverName'
}

export type BasicSignatureRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BasicSignatureRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BasicSignatureRequestSetInput>;
  where: BasicSignatureRequestBoolExp;
};

/** aggregate var_pop on columns */
export type BasicSignatureRequestVarPopFields = {
  __typename?: 'basicSignatureRequest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type BasicSignatureRequestVarSampFields = {
  __typename?: 'basicSignatureRequest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type BasicSignatureRequestVarianceFields = {
  __typename?: 'basicSignatureRequest_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "basicSignatureRequest" */
export type BasicSignatureRequestVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "category" */
export type Category = Node & {
  __typename?: 'category';
  abilityLevelRange: Scalars['jsonb'];
  additionalPrizes?: Maybe<Scalars['String']>;
  ageAsOf?: Maybe<Scalars['String']>;
  /** An array relationship */
  categoryCourses: Array<CategoryCourse>;
  /** An aggregate relationship */
  categoryCourses_aggregate: CategoryCourseAggregate;
  /** An array relationship connection */
  categoryCourses_connection: CategoryCourseConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entryFee?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  event: Event;
  /** An array relationship */
  eventBasicWaiverCategories: Array<EventBasicWaiverCategory>;
  /** An aggregate relationship */
  eventBasicWaiverCategories_aggregate: EventBasicWaiverCategoryAggregate;
  /** An array relationship connection */
  eventBasicWaiverCategories_connection: EventBasicWaiverCategoryConnection;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventCustomQuestionCategories: Array<EventCustomQuestionCategory>;
  /** An aggregate relationship */
  eventCustomQuestionCategories_aggregate: EventCustomQuestionCategoryAggregate;
  /** An array relationship connection */
  eventCustomQuestionCategories_connection: EventCustomQuestionCategoryConnection;
  /** An object relationship */
  eventDiscipline: EventDiscipline;
  eventDisciplineCuid: Scalars['String'];
  /** An array relationship */
  eventPricingGroupCategories: Array<EventPricingGroupCategory>;
  /** An aggregate relationship */
  eventPricingGroupCategories_aggregate: EventPricingGroupCategoryAggregate;
  /** An array relationship connection */
  eventPricingGroupCategories_connection: EventPricingGroupCategoryConnection;
  /** An array relationship */
  eventSanctionCategories: Array<EventSanctionCategory>;
  /** An aggregate relationship */
  eventSanctionCategories_aggregate: EventSanctionCategoryAggregate;
  /** An array relationship connection */
  eventSanctionCategories_connection: EventSanctionCategoryConnection;
  /** An array relationship */
  eventScheduleCategories: Array<EventScheduleCategory>;
  /** An aggregate relationship */
  eventScheduleCategories_aggregate: EventScheduleCategoryAggregate;
  /** An array relationship connection */
  eventScheduleCategories_connection: EventScheduleCategoryConnection;
  /** An array relationship */
  eventSeriesCategories: Array<EventSeriesCategory>;
  /** An aggregate relationship */
  eventSeriesCategories_aggregate: EventSeriesCategoryAggregate;
  /** An array relationship connection */
  eventSeriesCategories_connection: EventSeriesCategoryConnection;
  gender: Scalars['jsonb'];
  id: Scalars['ID'];
  maxAge: Scalars['Int'];
  minAge: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  /** An array relationship */
  registrationCategories: Array<RegistrationCategory>;
  /** An aggregate relationship */
  registrationCategories_aggregate: RegistrationCategoryAggregate;
  /** An array relationship connection */
  registrationCategories_connection: RegistrationCategoryConnection;
  spotsAvailable?: Maybe<Scalars['Int']>;
  /** calculates the spots remaining by accounting for registrations that are complete and in progress */
  spotsRemaining?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  visible: Scalars['Boolean'];
};


/** columns and relationships of "category" */
export type CategoryAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "category" */
export type CategoryCategoryCoursesArgs = {
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryCategoryCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryCategoryCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventBasicWaiverCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventBasicWaiverCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventBasicWaiverCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventCustomQuestionCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventCustomQuestionCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventCustomQuestionCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventPricingGroupCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventPricingGroupCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventPricingGroupCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSanctionCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSanctionCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSanctionCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventScheduleCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventScheduleCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventScheduleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSeriesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSeriesCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryEventSeriesCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryGenderArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "category" */
export type CategoryRegistrationCategoriesArgs = {
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryRegistrationCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryRegistrationCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};

/** A Relay connection object on "category" */
export type CategoryConnection = {
  __typename?: 'categoryConnection';
  edges: Array<CategoryEdge>;
  pageInfo: PageInfo;
};

/** columns and relationships of "categoryCourse" */
export type CategoryCourse = Node & {
  __typename?: 'categoryCourse';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  /** An object relationship */
  course: Course;
  courseCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "categoryCourse" */
export type CategoryCourseConnection = {
  __typename?: 'categoryCourseConnection';
  edges: Array<CategoryCourseEdge>;
  pageInfo: PageInfo;
};

export type CategoryCourseEdge = {
  __typename?: 'categoryCourseEdge';
  cursor: Scalars['String'];
  node: CategoryCourse;
};

/** aggregated selection of "categoryCourse" */
export type CategoryCourseAggregate = {
  __typename?: 'categoryCourse_aggregate';
  aggregate?: Maybe<CategoryCourseAggregateFields>;
  nodes: Array<CategoryCourse>;
};

export type CategoryCourseAggregateBoolExp = {
  count?: InputMaybe<CategoryCourseAggregateBoolExpCount>;
};

export type CategoryCourseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryCourseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "categoryCourse" */
export type CategoryCourseAggregateFields = {
  __typename?: 'categoryCourse_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CategoryCourseMaxFields>;
  min?: Maybe<CategoryCourseMinFields>;
};


/** aggregate fields of "categoryCourse" */
export type CategoryCourseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categoryCourse" */
export type CategoryCourseAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryCourseMaxOrderBy>;
  min?: InputMaybe<CategoryCourseMinOrderBy>;
};

/** input type for inserting array relation for remote table "categoryCourse" */
export type CategoryCourseArrRelInsertInput = {
  data: Array<CategoryCourseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryCourseOnConflict>;
};

/** Boolean expression to filter rows from the table "categoryCourse". All fields are combined with a logical 'AND'. */
export type CategoryCourseBoolExp = {
  _and?: InputMaybe<Array<CategoryCourseBoolExp>>;
  _not?: InputMaybe<CategoryCourseBoolExp>;
  _or?: InputMaybe<Array<CategoryCourseBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  course?: InputMaybe<CourseBoolExp>;
  courseCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "categoryCourse" */
export enum CategoryCourseConstraint {
  /** unique or primary key constraint on columns "categoryCuid", "courseCuid" */
  CategoryCourseCategoryCuidCourseCuidKey = 'categoryCourse_categoryCuid_courseCuid_key',
  /** unique or primary key constraint on columns "cuid" */
  CategoryCoursePkey = 'categoryCourse_pkey'
}

/** input type for inserting data into table "categoryCourse" */
export type CategoryCourseInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<CourseObjRelInsertInput>;
  courseCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CategoryCourseMaxFields = {
  __typename?: 'categoryCourse_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  courseCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "categoryCourse" */
export type CategoryCourseMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  courseCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CategoryCourseMinFields = {
  __typename?: 'categoryCourse_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  courseCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "categoryCourse" */
export type CategoryCourseMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  courseCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "categoryCourse" */
export type CategoryCourseMutationResponse = {
  __typename?: 'categoryCourse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CategoryCourse>;
};

/** on_conflict condition type for table "categoryCourse" */
export type CategoryCourseOnConflict = {
  constraint: CategoryCourseConstraint;
  update_columns?: Array<CategoryCourseUpdateColumn>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};

/** Ordering options when selecting data from "categoryCourse". */
export type CategoryCourseOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  course?: InputMaybe<CourseOrderBy>;
  courseCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: categoryCourse */
export type CategoryCoursePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "categoryCourse" */
export enum CategoryCourseSelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CourseCuid = 'courseCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "categoryCourse" */
export type CategoryCourseSetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  courseCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "categoryCourse" */
export enum CategoryCourseUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CourseCuid = 'courseCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CategoryCourseUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CategoryCourseSetInput>;
  where: CategoryCourseBoolExp;
};

export type CategoryEdge = {
  __typename?: 'categoryEdge';
  cursor: Scalars['String'];
  node: Category;
};

/** aggregated selection of "category" */
export type CategoryAggregate = {
  __typename?: 'category_aggregate';
  aggregate?: Maybe<CategoryAggregateFields>;
  nodes: Array<Category>;
};

export type CategoryAggregateBoolExp = {
  bool_and?: InputMaybe<CategoryAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CategoryAggregateBoolExpBoolOr>;
  count?: InputMaybe<CategoryAggregateBoolExpCount>;
};

export type CategoryAggregateBoolExpBoolAnd = {
  arguments: CategorySelectColumnCategoryAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CategoryAggregateBoolExpBoolOr = {
  arguments: CategorySelectColumnCategoryAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "category" */
export type CategoryAggregateFields = {
  __typename?: 'category_aggregate_fields';
  avg?: Maybe<CategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CategoryMaxFields>;
  min?: Maybe<CategoryMinFields>;
  stddev?: Maybe<CategoryStddevFields>;
  stddev_pop?: Maybe<CategoryStddevPopFields>;
  stddev_samp?: Maybe<CategoryStddevSampFields>;
  sum?: Maybe<CategorySumFields>;
  var_pop?: Maybe<CategoryVarPopFields>;
  var_samp?: Maybe<CategoryVarSampFields>;
  variance?: Maybe<CategoryVarianceFields>;
};


/** aggregate fields of "category" */
export type CategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category" */
export type CategoryAggregateOrderBy = {
  avg?: InputMaybe<CategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryMaxOrderBy>;
  min?: InputMaybe<CategoryMinOrderBy>;
  stddev?: InputMaybe<CategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<CategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CategoryStddevSampOrderBy>;
  sum?: InputMaybe<CategorySumOrderBy>;
  var_pop?: InputMaybe<CategoryVarPopOrderBy>;
  var_samp?: InputMaybe<CategoryVarSampOrderBy>;
  variance?: InputMaybe<CategoryVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CategoryAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "category" */
export type CategoryArrRelInsertInput = {
  data: Array<CategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryOnConflict>;
};

/** aggregate avg on columns */
export type CategoryAvgFields = {
  __typename?: 'category_avg_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "category" */
export type CategoryAvgOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "category". All fields are combined with a logical 'AND'. */
export type CategoryBoolExp = {
  _and?: InputMaybe<Array<CategoryBoolExp>>;
  _not?: InputMaybe<CategoryBoolExp>;
  _or?: InputMaybe<Array<CategoryBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  additionalPrizes?: InputMaybe<StringComparisonExp>;
  ageAsOf?: InputMaybe<StringComparisonExp>;
  categoryCourses?: InputMaybe<CategoryCourseBoolExp>;
  categoryCourses_aggregate?: InputMaybe<CategoryCourseAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  entryFee?: InputMaybe<NumericComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventBasicWaiverCategories?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
  eventBasicWaiverCategories_aggregate?: InputMaybe<EventBasicWaiverCategoryAggregateBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventCustomQuestionCategories?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
  eventCustomQuestionCategories_aggregate?: InputMaybe<EventCustomQuestionCategoryAggregateBoolExp>;
  eventDiscipline?: InputMaybe<EventDisciplineBoolExp>;
  eventDisciplineCuid?: InputMaybe<StringComparisonExp>;
  eventPricingGroupCategories?: InputMaybe<EventPricingGroupCategoryBoolExp>;
  eventPricingGroupCategories_aggregate?: InputMaybe<EventPricingGroupCategoryAggregateBoolExp>;
  eventSanctionCategories?: InputMaybe<EventSanctionCategoryBoolExp>;
  eventSanctionCategories_aggregate?: InputMaybe<EventSanctionCategoryAggregateBoolExp>;
  eventScheduleCategories?: InputMaybe<EventScheduleCategoryBoolExp>;
  eventScheduleCategories_aggregate?: InputMaybe<EventScheduleCategoryAggregateBoolExp>;
  eventSeriesCategories?: InputMaybe<EventSeriesCategoryBoolExp>;
  eventSeriesCategories_aggregate?: InputMaybe<EventSeriesCategoryAggregateBoolExp>;
  gender?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  maxAge?: InputMaybe<IntComparisonExp>;
  minAge?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  registrationCategories?: InputMaybe<RegistrationCategoryBoolExp>;
  registrationCategories_aggregate?: InputMaybe<RegistrationCategoryAggregateBoolExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  spotsRemaining?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  visible?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "category" */
export enum CategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CategoryCuidKey = 'category_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CategoryPkey = 'category_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CategoryDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  gender?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CategoryDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CategoryDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "category" */
export type CategoryIncInput = {
  entryFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "category" */
export type CategoryInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  additionalPrizes?: InputMaybe<Scalars['String']>;
  ageAsOf?: InputMaybe<Scalars['String']>;
  categoryCourses?: InputMaybe<CategoryCourseArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entryFee?: InputMaybe<Scalars['numeric']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventBasicWaiverCategories?: InputMaybe<EventBasicWaiverCategoryArrRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestionCategories?: InputMaybe<EventCustomQuestionCategoryArrRelInsertInput>;
  eventDiscipline?: InputMaybe<EventDisciplineObjRelInsertInput>;
  eventDisciplineCuid?: InputMaybe<Scalars['String']>;
  eventPricingGroupCategories?: InputMaybe<EventPricingGroupCategoryArrRelInsertInput>;
  eventSanctionCategories?: InputMaybe<EventSanctionCategoryArrRelInsertInput>;
  eventScheduleCategories?: InputMaybe<EventScheduleCategoryArrRelInsertInput>;
  eventSeriesCategories?: InputMaybe<EventSeriesCategoryArrRelInsertInput>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  registrationCategories?: InputMaybe<RegistrationCategoryArrRelInsertInput>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type CategoryMaxFields = {
  __typename?: 'category_max_fields';
  additionalPrizes?: Maybe<Scalars['String']>;
  ageAsOf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entryFee?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventDisciplineCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  maxAge?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "category" */
export type CategoryMaxOrderBy = {
  additionalPrizes?: InputMaybe<OrderBy>;
  ageAsOf?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  entryFee?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CategoryMinFields = {
  __typename?: 'category_min_fields';
  additionalPrizes?: Maybe<Scalars['String']>;
  ageAsOf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entryFee?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventDisciplineCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  maxAge?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "category" */
export type CategoryMinOrderBy = {
  additionalPrizes?: InputMaybe<OrderBy>;
  ageAsOf?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  entryFee?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "category" */
export type CategoryMutationResponse = {
  __typename?: 'category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category>;
};

/** input type for inserting object relation for remote table "category" */
export type CategoryObjRelInsertInput = {
  data: CategoryInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryOnConflict>;
};

/** on_conflict condition type for table "category" */
export type CategoryOnConflict = {
  constraint: CategoryConstraint;
  update_columns?: Array<CategoryUpdateColumn>;
  where?: InputMaybe<CategoryBoolExp>;
};

/** Ordering options when selecting data from "category". */
export type CategoryOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  additionalPrizes?: InputMaybe<OrderBy>;
  ageAsOf?: InputMaybe<OrderBy>;
  categoryCourses_aggregate?: InputMaybe<CategoryCourseAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  entryFee?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventBasicWaiverCategories_aggregate?: InputMaybe<EventBasicWaiverCategoryAggregateOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCategories_aggregate?: InputMaybe<EventCustomQuestionCategoryAggregateOrderBy>;
  eventDiscipline?: InputMaybe<EventDisciplineOrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  eventPricingGroupCategories_aggregate?: InputMaybe<EventPricingGroupCategoryAggregateOrderBy>;
  eventSanctionCategories_aggregate?: InputMaybe<EventSanctionCategoryAggregateOrderBy>;
  eventScheduleCategories_aggregate?: InputMaybe<EventScheduleCategoryAggregateOrderBy>;
  eventSeriesCategories_aggregate?: InputMaybe<EventSeriesCategoryAggregateOrderBy>;
  gender?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  registrationCategories_aggregate?: InputMaybe<RegistrationCategoryAggregateOrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsRemaining?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  visible?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: category */
export type CategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CategoryPrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "category" */
export enum CategorySelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AdditionalPrizes = 'additionalPrizes',
  /** column name */
  AgeAsOf = 'ageAsOf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EntryFee = 'entryFee',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventDisciplineCuid = 'eventDisciplineCuid',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAge = 'maxAge',
  /** column name */
  MinAge = 'minAge',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

/** select "category_aggregate_bool_exp_bool_and_arguments_columns" columns of table "category" */
export enum CategorySelectColumnCategoryAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Visible = 'visible'
}

/** select "category_aggregate_bool_exp_bool_or_arguments_columns" columns of table "category" */
export enum CategorySelectColumnCategoryAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "category" */
export type CategorySetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  additionalPrizes?: InputMaybe<Scalars['String']>;
  ageAsOf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entryFee?: InputMaybe<Scalars['numeric']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventDisciplineCuid?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type CategoryStddevFields = {
  __typename?: 'category_stddev_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "category" */
export type CategoryStddevOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CategoryStddevPopFields = {
  __typename?: 'category_stddev_pop_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "category" */
export type CategoryStddevPopOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CategoryStddevSampFields = {
  __typename?: 'category_stddev_samp_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "category" */
export type CategoryStddevSampOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CategorySumFields = {
  __typename?: 'category_sum_fields';
  entryFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  maxAge?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "category" */
export type CategorySumOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** update columns of table "category" */
export enum CategoryUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AdditionalPrizes = 'additionalPrizes',
  /** column name */
  AgeAsOf = 'ageAsOf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EntryFee = 'entryFee',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventDisciplineCuid = 'eventDisciplineCuid',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAge = 'maxAge',
  /** column name */
  MinAge = 'minAge',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

export type CategoryUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CategoryAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CategoryDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CategoryDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CategoryDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CategoryIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CategoryPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CategorySetInput>;
  where: CategoryBoolExp;
};

/** aggregate var_pop on columns */
export type CategoryVarPopFields = {
  __typename?: 'category_var_pop_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "category" */
export type CategoryVarPopOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CategoryVarSampFields = {
  __typename?: 'category_var_samp_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "category" */
export type CategoryVarSampOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CategoryVarianceFields = {
  __typename?: 'category_variance_fields';
  entryFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  minAge?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "category" */
export type CategoryVarianceOrderBy = {
  entryFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxAge?: InputMaybe<OrderBy>;
  minAge?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** columns and relationships of "changeLogDate" */
export type ChangeLogDate = Node & {
  __typename?: 'changeLogDate';
  /** An array relationship */
  changeLogItems: Array<ChangeLogItem>;
  /** An aggregate relationship */
  changeLogItems_aggregate: ChangeLogItemAggregate;
  /** An array relationship connection */
  changeLogItems_connection: ChangeLogItemConnection;
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "changeLogDate" */
export type ChangeLogDateChangeLogItemsArgs = {
  distinct_on?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogItemOrderBy>>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};


/** columns and relationships of "changeLogDate" */
export type ChangeLogDateChangeLogItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogItemOrderBy>>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};


/** columns and relationships of "changeLogDate" */
export type ChangeLogDateChangeLogItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogItemOrderBy>>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};

/** A Relay connection object on "changeLogDate" */
export type ChangeLogDateConnection = {
  __typename?: 'changeLogDateConnection';
  edges: Array<ChangeLogDateEdge>;
  pageInfo: PageInfo;
};

export type ChangeLogDateEdge = {
  __typename?: 'changeLogDateEdge';
  cursor: Scalars['String'];
  node: ChangeLogDate;
};

/** Boolean expression to filter rows from the table "changeLogDate". All fields are combined with a logical 'AND'. */
export type ChangeLogDateBoolExp = {
  _and?: InputMaybe<Array<ChangeLogDateBoolExp>>;
  _not?: InputMaybe<ChangeLogDateBoolExp>;
  _or?: InputMaybe<Array<ChangeLogDateBoolExp>>;
  changeLogItems?: InputMaybe<ChangeLogItemBoolExp>;
  changeLogItems_aggregate?: InputMaybe<ChangeLogItemAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  date?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "changeLogDate" */
export enum ChangeLogDateConstraint {
  /** unique or primary key constraint on columns "id" */
  ChangeLogDatePkey = 'changeLogDate_pkey'
}

/** input type for incrementing numeric columns in table "changeLogDate" */
export type ChangeLogDateIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "changeLogDate" */
export type ChangeLogDateInsertInput = {
  changeLogItems?: InputMaybe<ChangeLogItemArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "changeLogDate" */
export type ChangeLogDateMutationResponse = {
  __typename?: 'changeLogDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChangeLogDate>;
};

/** input type for inserting object relation for remote table "changeLogDate" */
export type ChangeLogDateObjRelInsertInput = {
  data: ChangeLogDateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChangeLogDateOnConflict>;
};

/** on_conflict condition type for table "changeLogDate" */
export type ChangeLogDateOnConflict = {
  constraint: ChangeLogDateConstraint;
  update_columns?: Array<ChangeLogDateUpdateColumn>;
  where?: InputMaybe<ChangeLogDateBoolExp>;
};

/** Ordering options when selecting data from "changeLogDate". */
export type ChangeLogDateOrderBy = {
  changeLogItems_aggregate?: InputMaybe<ChangeLogItemAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: changeLogDate */
export type ChangeLogDatePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "changeLogDate" */
export enum ChangeLogDateSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "changeLogDate" */
export type ChangeLogDateSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "changeLogDate" */
export enum ChangeLogDateUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ChangeLogDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChangeLogDateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChangeLogDateSetInput>;
  where: ChangeLogDateBoolExp;
};

/** columns and relationships of "changeLogItem" */
export type ChangeLogItem = Node & {
  __typename?: 'changeLogItem';
  /** An object relationship */
  changeLogDate: ChangeLogDate;
  changeLogDateId: Scalars['Int'];
  changeText: Scalars['String'];
  changeType: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "changeLogItem" */
export type ChangeLogItemConnection = {
  __typename?: 'changeLogItemConnection';
  edges: Array<ChangeLogItemEdge>;
  pageInfo: PageInfo;
};

export type ChangeLogItemEdge = {
  __typename?: 'changeLogItemEdge';
  cursor: Scalars['String'];
  node: ChangeLogItem;
};

/** aggregated selection of "changeLogItem" */
export type ChangeLogItemAggregate = {
  __typename?: 'changeLogItem_aggregate';
  aggregate?: Maybe<ChangeLogItemAggregateFields>;
  nodes: Array<ChangeLogItem>;
};

export type ChangeLogItemAggregateBoolExp = {
  count?: InputMaybe<ChangeLogItemAggregateBoolExpCount>;
};

export type ChangeLogItemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChangeLogItemBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "changeLogItem" */
export type ChangeLogItemAggregateFields = {
  __typename?: 'changeLogItem_aggregate_fields';
  avg?: Maybe<ChangeLogItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChangeLogItemMaxFields>;
  min?: Maybe<ChangeLogItemMinFields>;
  stddev?: Maybe<ChangeLogItemStddevFields>;
  stddev_pop?: Maybe<ChangeLogItemStddevPopFields>;
  stddev_samp?: Maybe<ChangeLogItemStddevSampFields>;
  sum?: Maybe<ChangeLogItemSumFields>;
  var_pop?: Maybe<ChangeLogItemVarPopFields>;
  var_samp?: Maybe<ChangeLogItemVarSampFields>;
  variance?: Maybe<ChangeLogItemVarianceFields>;
};


/** aggregate fields of "changeLogItem" */
export type ChangeLogItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "changeLogItem" */
export type ChangeLogItemAggregateOrderBy = {
  avg?: InputMaybe<ChangeLogItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChangeLogItemMaxOrderBy>;
  min?: InputMaybe<ChangeLogItemMinOrderBy>;
  stddev?: InputMaybe<ChangeLogItemStddevOrderBy>;
  stddev_pop?: InputMaybe<ChangeLogItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChangeLogItemStddevSampOrderBy>;
  sum?: InputMaybe<ChangeLogItemSumOrderBy>;
  var_pop?: InputMaybe<ChangeLogItemVarPopOrderBy>;
  var_samp?: InputMaybe<ChangeLogItemVarSampOrderBy>;
  variance?: InputMaybe<ChangeLogItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "changeLogItem" */
export type ChangeLogItemArrRelInsertInput = {
  data: Array<ChangeLogItemInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChangeLogItemOnConflict>;
};

/** aggregate avg on columns */
export type ChangeLogItemAvgFields = {
  __typename?: 'changeLogItem_avg_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "changeLogItem" */
export type ChangeLogItemAvgOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "changeLogItem". All fields are combined with a logical 'AND'. */
export type ChangeLogItemBoolExp = {
  _and?: InputMaybe<Array<ChangeLogItemBoolExp>>;
  _not?: InputMaybe<ChangeLogItemBoolExp>;
  _or?: InputMaybe<Array<ChangeLogItemBoolExp>>;
  changeLogDate?: InputMaybe<ChangeLogDateBoolExp>;
  changeLogDateId?: InputMaybe<IntComparisonExp>;
  changeText?: InputMaybe<StringComparisonExp>;
  changeType?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "changeLogItem" */
export enum ChangeLogItemConstraint {
  /** unique or primary key constraint on columns "id" */
  ChangeLogItemPkey = 'changeLogItem_pkey'
}

/** input type for incrementing numeric columns in table "changeLogItem" */
export type ChangeLogItemIncInput = {
  changeLogDateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "changeLogItem" */
export type ChangeLogItemInsertInput = {
  changeLogDate?: InputMaybe<ChangeLogDateObjRelInsertInput>;
  changeLogDateId?: InputMaybe<Scalars['Int']>;
  changeText?: InputMaybe<Scalars['String']>;
  changeType?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ChangeLogItemMaxFields = {
  __typename?: 'changeLogItem_max_fields';
  changeLogDateId?: Maybe<Scalars['Int']>;
  changeText?: Maybe<Scalars['String']>;
  changeType?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "changeLogItem" */
export type ChangeLogItemMaxOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  changeText?: InputMaybe<OrderBy>;
  changeType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChangeLogItemMinFields = {
  __typename?: 'changeLogItem_min_fields';
  changeLogDateId?: Maybe<Scalars['Int']>;
  changeText?: Maybe<Scalars['String']>;
  changeType?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "changeLogItem" */
export type ChangeLogItemMinOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  changeText?: InputMaybe<OrderBy>;
  changeType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "changeLogItem" */
export type ChangeLogItemMutationResponse = {
  __typename?: 'changeLogItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChangeLogItem>;
};

/** on_conflict condition type for table "changeLogItem" */
export type ChangeLogItemOnConflict = {
  constraint: ChangeLogItemConstraint;
  update_columns?: Array<ChangeLogItemUpdateColumn>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};

/** Ordering options when selecting data from "changeLogItem". */
export type ChangeLogItemOrderBy = {
  changeLogDate?: InputMaybe<ChangeLogDateOrderBy>;
  changeLogDateId?: InputMaybe<OrderBy>;
  changeText?: InputMaybe<OrderBy>;
  changeType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: changeLogItem */
export type ChangeLogItemPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "changeLogItem" */
export enum ChangeLogItemSelectColumn {
  /** column name */
  ChangeLogDateId = 'changeLogDateId',
  /** column name */
  ChangeText = 'changeText',
  /** column name */
  ChangeType = 'changeType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "changeLogItem" */
export type ChangeLogItemSetInput = {
  changeLogDateId?: InputMaybe<Scalars['Int']>;
  changeText?: InputMaybe<Scalars['String']>;
  changeType?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ChangeLogItemStddevFields = {
  __typename?: 'changeLogItem_stddev_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "changeLogItem" */
export type ChangeLogItemStddevOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChangeLogItemStddevPopFields = {
  __typename?: 'changeLogItem_stddev_pop_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "changeLogItem" */
export type ChangeLogItemStddevPopOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChangeLogItemStddevSampFields = {
  __typename?: 'changeLogItem_stddev_samp_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "changeLogItem" */
export type ChangeLogItemStddevSampOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ChangeLogItemSumFields = {
  __typename?: 'changeLogItem_sum_fields';
  changeLogDateId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "changeLogItem" */
export type ChangeLogItemSumOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "changeLogItem" */
export enum ChangeLogItemUpdateColumn {
  /** column name */
  ChangeLogDateId = 'changeLogDateId',
  /** column name */
  ChangeText = 'changeText',
  /** column name */
  ChangeType = 'changeType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ChangeLogItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChangeLogItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChangeLogItemSetInput>;
  where: ChangeLogItemBoolExp;
};

/** aggregate var_pop on columns */
export type ChangeLogItemVarPopFields = {
  __typename?: 'changeLogItem_var_pop_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "changeLogItem" */
export type ChangeLogItemVarPopOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChangeLogItemVarSampFields = {
  __typename?: 'changeLogItem_var_samp_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "changeLogItem" */
export type ChangeLogItemVarSampOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChangeLogItemVarianceFields = {
  __typename?: 'changeLogItem_variance_fields';
  changeLogDateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "changeLogItem" */
export type ChangeLogItemVarianceOrderBy = {
  changeLogDateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Clinic Instance */
export type Clinic = Node & {
  __typename?: 'clinic';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  /** An array relationship */
  clinicCoaches: Array<ClinicCoach>;
  /** An aggregate relationship */
  clinicCoaches_aggregate: ClinicCoachAggregate;
  /** An array relationship connection */
  clinicCoaches_connection: ClinicCoachConnection;
  /** An array relationship */
  clinicDays: Array<ClinicDay>;
  /** An aggregate relationship */
  clinicDays_aggregate: ClinicDayAggregate;
  /** An array relationship connection */
  clinicDays_connection: ClinicDayConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  dayOfContact?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  meetingLink?: Maybe<Scalars['String']>;
  priceOverride?: Maybe<Scalars['numeric']>;
  registrationStatus: Scalars['String'];
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  spotsAvailable?: Maybe<Scalars['Int']>;
  /** calculates the spots remaining by accounting for registrations that are complete and in progress */
  spotsRemaining?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** Clinic Instance */
export type ClinicClinicCoachesArgs = {
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Clinic Instance */
export type ClinicClinicCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Clinic Instance */
export type ClinicClinicCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Clinic Instance */
export type ClinicClinicDaysArgs = {
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


/** Clinic Instance */
export type ClinicClinicDaysAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


/** Clinic Instance */
export type ClinicClinicDaysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


/** Clinic Instance */
export type ClinicRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Clinic Instance */
export type ClinicRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Clinic Instance */
export type ClinicRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};

/** What coaches are part of what clinics */
export type ClinicCoach = Node & {
  __typename?: 'clinicCoach';
  /** An object relationship */
  clinic: Clinic;
  clinicCuid: Scalars['String'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "clinicCoach" */
export type ClinicCoachConnection = {
  __typename?: 'clinicCoachConnection';
  edges: Array<ClinicCoachEdge>;
  pageInfo: PageInfo;
};

export type ClinicCoachEdge = {
  __typename?: 'clinicCoachEdge';
  cursor: Scalars['String'];
  node: ClinicCoach;
};

/** aggregated selection of "clinicCoach" */
export type ClinicCoachAggregate = {
  __typename?: 'clinicCoach_aggregate';
  aggregate?: Maybe<ClinicCoachAggregateFields>;
  nodes: Array<ClinicCoach>;
};

export type ClinicCoachAggregateBoolExp = {
  count?: InputMaybe<ClinicCoachAggregateBoolExpCount>;
};

export type ClinicCoachAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClinicCoachBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "clinicCoach" */
export type ClinicCoachAggregateFields = {
  __typename?: 'clinicCoach_aggregate_fields';
  avg?: Maybe<ClinicCoachAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicCoachMaxFields>;
  min?: Maybe<ClinicCoachMinFields>;
  stddev?: Maybe<ClinicCoachStddevFields>;
  stddev_pop?: Maybe<ClinicCoachStddevPopFields>;
  stddev_samp?: Maybe<ClinicCoachStddevSampFields>;
  sum?: Maybe<ClinicCoachSumFields>;
  var_pop?: Maybe<ClinicCoachVarPopFields>;
  var_samp?: Maybe<ClinicCoachVarSampFields>;
  variance?: Maybe<ClinicCoachVarianceFields>;
};


/** aggregate fields of "clinicCoach" */
export type ClinicCoachAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinicCoach" */
export type ClinicCoachAggregateOrderBy = {
  avg?: InputMaybe<ClinicCoachAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicCoachMaxOrderBy>;
  min?: InputMaybe<ClinicCoachMinOrderBy>;
  stddev?: InputMaybe<ClinicCoachStddevOrderBy>;
  stddev_pop?: InputMaybe<ClinicCoachStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ClinicCoachStddevSampOrderBy>;
  sum?: InputMaybe<ClinicCoachSumOrderBy>;
  var_pop?: InputMaybe<ClinicCoachVarPopOrderBy>;
  var_samp?: InputMaybe<ClinicCoachVarSampOrderBy>;
  variance?: InputMaybe<ClinicCoachVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "clinicCoach" */
export type ClinicCoachArrRelInsertInput = {
  data: Array<ClinicCoachInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicCoachOnConflict>;
};

/** aggregate avg on columns */
export type ClinicCoachAvgFields = {
  __typename?: 'clinicCoach_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clinicCoach" */
export type ClinicCoachAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "clinicCoach". All fields are combined with a logical 'AND'. */
export type ClinicCoachBoolExp = {
  _and?: InputMaybe<Array<ClinicCoachBoolExp>>;
  _not?: InputMaybe<ClinicCoachBoolExp>;
  _or?: InputMaybe<Array<ClinicCoachBoolExp>>;
  clinic?: InputMaybe<ClinicBoolExp>;
  clinicCuid?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "clinicCoach" */
export enum ClinicCoachConstraint {
  /** unique or primary key constraint on columns "clinicCuid", "coachCuid" */
  ClinicCoachCoachCuidClinicCuidKey = 'clinicCoach_coachCuid_clinicCuid_key',
  /** unique or primary key constraint on columns "cuid" */
  ClinicCoachCuidKey = 'clinicCoach_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ClinicCoachPkey = 'clinicCoach_pkey'
}

/** input type for incrementing numeric columns in table "clinicCoach" */
export type ClinicCoachIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "clinicCoach" */
export type ClinicCoachInsertInput = {
  clinic?: InputMaybe<ClinicObjRelInsertInput>;
  clinicCuid?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClinicCoachMaxFields = {
  __typename?: 'clinicCoach_max_fields';
  clinicCuid?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clinicCoach" */
export type ClinicCoachMaxOrderBy = {
  clinicCuid?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClinicCoachMinFields = {
  __typename?: 'clinicCoach_min_fields';
  clinicCuid?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clinicCoach" */
export type ClinicCoachMinOrderBy = {
  clinicCuid?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clinicCoach" */
export type ClinicCoachMutationResponse = {
  __typename?: 'clinicCoach_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicCoach>;
};

/** on_conflict condition type for table "clinicCoach" */
export type ClinicCoachOnConflict = {
  constraint: ClinicCoachConstraint;
  update_columns?: Array<ClinicCoachUpdateColumn>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};

/** Ordering options when selecting data from "clinicCoach". */
export type ClinicCoachOrderBy = {
  clinic?: InputMaybe<ClinicOrderBy>;
  clinicCuid?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clinicCoach */
export type ClinicCoachPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "clinicCoach" */
export enum ClinicCoachSelectColumn {
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clinicCoach" */
export type ClinicCoachSetInput = {
  clinicCuid?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ClinicCoachStddevFields = {
  __typename?: 'clinicCoach_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clinicCoach" */
export type ClinicCoachStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ClinicCoachStddevPopFields = {
  __typename?: 'clinicCoach_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clinicCoach" */
export type ClinicCoachStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ClinicCoachStddevSampFields = {
  __typename?: 'clinicCoach_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clinicCoach" */
export type ClinicCoachStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ClinicCoachSumFields = {
  __typename?: 'clinicCoach_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "clinicCoach" */
export type ClinicCoachSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "clinicCoach" */
export enum ClinicCoachUpdateColumn {
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ClinicCoachUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicCoachIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicCoachSetInput>;
  where: ClinicCoachBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicCoachVarPopFields = {
  __typename?: 'clinicCoach_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clinicCoach" */
export type ClinicCoachVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ClinicCoachVarSampFields = {
  __typename?: 'clinicCoach_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clinicCoach" */
export type ClinicCoachVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClinicCoachVarianceFields = {
  __typename?: 'clinicCoach_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clinicCoach" */
export type ClinicCoachVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "clinic" */
export type ClinicConnection = {
  __typename?: 'clinicConnection';
  edges: Array<ClinicEdge>;
  pageInfo: PageInfo;
};

/** Day for a Clinic Instance */
export type ClinicDay = Node & {
  __typename?: 'clinicDay';
  /** An object relationship */
  clinic: Clinic;
  clinicCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventClinicDayDuration: EventClinicDayDuration;
  eventClinicDayDurationCuid: Scalars['String'];
  id: Scalars['ID'];
  /** An array relationship */
  reportClinicNoShows: Array<ReportClinicNoShow>;
  /** An aggregate relationship */
  reportClinicNoShows_aggregate: ReportClinicNoShowAggregate;
  /** An array relationship connection */
  reportClinicNoShows_connection: ReportClinicNoShowConnection;
  startTime: Scalars['timestamp'];
  updated_at: Scalars['timestamptz'];
};


/** Day for a Clinic Instance */
export type ClinicDayReportClinicNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Day for a Clinic Instance */
export type ClinicDayReportClinicNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Day for a Clinic Instance */
export type ClinicDayReportClinicNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};

/** A Relay connection object on "clinicDay" */
export type ClinicDayConnection = {
  __typename?: 'clinicDayConnection';
  edges: Array<ClinicDayEdge>;
  pageInfo: PageInfo;
};

export type ClinicDayEdge = {
  __typename?: 'clinicDayEdge';
  cursor: Scalars['String'];
  node: ClinicDay;
};

/** aggregated selection of "clinicDay" */
export type ClinicDayAggregate = {
  __typename?: 'clinicDay_aggregate';
  aggregate?: Maybe<ClinicDayAggregateFields>;
  nodes: Array<ClinicDay>;
};

export type ClinicDayAggregateBoolExp = {
  count?: InputMaybe<ClinicDayAggregateBoolExpCount>;
};

export type ClinicDayAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClinicDaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClinicDayBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "clinicDay" */
export type ClinicDayAggregateFields = {
  __typename?: 'clinicDay_aggregate_fields';
  avg?: Maybe<ClinicDayAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicDayMaxFields>;
  min?: Maybe<ClinicDayMinFields>;
  stddev?: Maybe<ClinicDayStddevFields>;
  stddev_pop?: Maybe<ClinicDayStddevPopFields>;
  stddev_samp?: Maybe<ClinicDayStddevSampFields>;
  sum?: Maybe<ClinicDaySumFields>;
  var_pop?: Maybe<ClinicDayVarPopFields>;
  var_samp?: Maybe<ClinicDayVarSampFields>;
  variance?: Maybe<ClinicDayVarianceFields>;
};


/** aggregate fields of "clinicDay" */
export type ClinicDayAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicDaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinicDay" */
export type ClinicDayAggregateOrderBy = {
  avg?: InputMaybe<ClinicDayAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicDayMaxOrderBy>;
  min?: InputMaybe<ClinicDayMinOrderBy>;
  stddev?: InputMaybe<ClinicDayStddevOrderBy>;
  stddev_pop?: InputMaybe<ClinicDayStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ClinicDayStddevSampOrderBy>;
  sum?: InputMaybe<ClinicDaySumOrderBy>;
  var_pop?: InputMaybe<ClinicDayVarPopOrderBy>;
  var_samp?: InputMaybe<ClinicDayVarSampOrderBy>;
  variance?: InputMaybe<ClinicDayVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "clinicDay" */
export type ClinicDayArrRelInsertInput = {
  data: Array<ClinicDayInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicDayOnConflict>;
};

/** aggregate avg on columns */
export type ClinicDayAvgFields = {
  __typename?: 'clinicDay_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clinicDay" */
export type ClinicDayAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "clinicDay". All fields are combined with a logical 'AND'. */
export type ClinicDayBoolExp = {
  _and?: InputMaybe<Array<ClinicDayBoolExp>>;
  _not?: InputMaybe<ClinicDayBoolExp>;
  _or?: InputMaybe<Array<ClinicDayBoolExp>>;
  clinic?: InputMaybe<ClinicBoolExp>;
  clinicCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventClinicDayDuration?: InputMaybe<EventClinicDayDurationBoolExp>;
  eventClinicDayDurationCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  reportClinicNoShows?: InputMaybe<ReportClinicNoShowBoolExp>;
  reportClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateBoolExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "clinicDay" */
export enum ClinicDayConstraint {
  /** unique or primary key constraint on columns "cuid" */
  ClinicDayCuidKey = 'clinicDay_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ClinicDayPkey = 'clinicDay_pkey'
}

/** input type for incrementing numeric columns in table "clinicDay" */
export type ClinicDayIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "clinicDay" */
export type ClinicDayInsertInput = {
  clinic?: InputMaybe<ClinicObjRelInsertInput>;
  clinicCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventClinicDayDuration?: InputMaybe<EventClinicDayDurationObjRelInsertInput>;
  eventClinicDayDurationCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  reportClinicNoShows?: InputMaybe<ReportClinicNoShowArrRelInsertInput>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClinicDayMaxFields = {
  __typename?: 'clinicDay_max_fields';
  clinicCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventClinicDayDurationCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clinicDay" */
export type ClinicDayMaxOrderBy = {
  clinicCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventClinicDayDurationCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClinicDayMinFields = {
  __typename?: 'clinicDay_min_fields';
  clinicCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventClinicDayDurationCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clinicDay" */
export type ClinicDayMinOrderBy = {
  clinicCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventClinicDayDurationCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clinicDay" */
export type ClinicDayMutationResponse = {
  __typename?: 'clinicDay_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicDay>;
};

/** input type for inserting object relation for remote table "clinicDay" */
export type ClinicDayObjRelInsertInput = {
  data: ClinicDayInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicDayOnConflict>;
};

/** on_conflict condition type for table "clinicDay" */
export type ClinicDayOnConflict = {
  constraint: ClinicDayConstraint;
  update_columns?: Array<ClinicDayUpdateColumn>;
  where?: InputMaybe<ClinicDayBoolExp>;
};

/** Ordering options when selecting data from "clinicDay". */
export type ClinicDayOrderBy = {
  clinic?: InputMaybe<ClinicOrderBy>;
  clinicCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventClinicDayDuration?: InputMaybe<EventClinicDayDurationOrderBy>;
  eventClinicDayDurationCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reportClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clinicDay */
export type ClinicDayPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "clinicDay" */
export enum ClinicDaySelectColumn {
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventClinicDayDurationCuid = 'eventClinicDayDurationCuid',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clinicDay" */
export type ClinicDaySetInput = {
  clinicCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventClinicDayDurationCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ClinicDayStddevFields = {
  __typename?: 'clinicDay_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clinicDay" */
export type ClinicDayStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ClinicDayStddevPopFields = {
  __typename?: 'clinicDay_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clinicDay" */
export type ClinicDayStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ClinicDayStddevSampFields = {
  __typename?: 'clinicDay_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clinicDay" */
export type ClinicDayStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ClinicDaySumFields = {
  __typename?: 'clinicDay_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "clinicDay" */
export type ClinicDaySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "clinicDay" */
export enum ClinicDayUpdateColumn {
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventClinicDayDurationCuid = 'eventClinicDayDurationCuid',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ClinicDayUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicDayIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicDaySetInput>;
  where: ClinicDayBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicDayVarPopFields = {
  __typename?: 'clinicDay_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clinicDay" */
export type ClinicDayVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ClinicDayVarSampFields = {
  __typename?: 'clinicDay_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clinicDay" */
export type ClinicDayVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClinicDayVarianceFields = {
  __typename?: 'clinicDay_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clinicDay" */
export type ClinicDayVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

export type ClinicEdge = {
  __typename?: 'clinicEdge';
  cursor: Scalars['String'];
  node: Clinic;
};

/** aggregated selection of "clinic" */
export type ClinicAggregate = {
  __typename?: 'clinic_aggregate';
  aggregate?: Maybe<ClinicAggregateFields>;
  nodes: Array<Clinic>;
};

export type ClinicAggregateBoolExp = {
  count?: InputMaybe<ClinicAggregateBoolExpCount>;
};

export type ClinicAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClinicSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClinicBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "clinic" */
export type ClinicAggregateFields = {
  __typename?: 'clinic_aggregate_fields';
  avg?: Maybe<ClinicAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicMaxFields>;
  min?: Maybe<ClinicMinFields>;
  stddev?: Maybe<ClinicStddevFields>;
  stddev_pop?: Maybe<ClinicStddevPopFields>;
  stddev_samp?: Maybe<ClinicStddevSampFields>;
  sum?: Maybe<ClinicSumFields>;
  var_pop?: Maybe<ClinicVarPopFields>;
  var_samp?: Maybe<ClinicVarSampFields>;
  variance?: Maybe<ClinicVarianceFields>;
};


/** aggregate fields of "clinic" */
export type ClinicAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinic" */
export type ClinicAggregateOrderBy = {
  avg?: InputMaybe<ClinicAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicMaxOrderBy>;
  min?: InputMaybe<ClinicMinOrderBy>;
  stddev?: InputMaybe<ClinicStddevOrderBy>;
  stddev_pop?: InputMaybe<ClinicStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ClinicStddevSampOrderBy>;
  sum?: InputMaybe<ClinicSumOrderBy>;
  var_pop?: InputMaybe<ClinicVarPopOrderBy>;
  var_samp?: InputMaybe<ClinicVarSampOrderBy>;
  variance?: InputMaybe<ClinicVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "clinic" */
export type ClinicArrRelInsertInput = {
  data: Array<ClinicInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicOnConflict>;
};

/** aggregate avg on columns */
export type ClinicAvgFields = {
  __typename?: 'clinic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clinic" */
export type ClinicAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "clinic". All fields are combined with a logical 'AND'. */
export type ClinicBoolExp = {
  _and?: InputMaybe<Array<ClinicBoolExp>>;
  _not?: InputMaybe<ClinicBoolExp>;
  _or?: InputMaybe<Array<ClinicBoolExp>>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  cancellationReason?: InputMaybe<StringComparisonExp>;
  clinicCoaches?: InputMaybe<ClinicCoachBoolExp>;
  clinicCoaches_aggregate?: InputMaybe<ClinicCoachAggregateBoolExp>;
  clinicDays?: InputMaybe<ClinicDayBoolExp>;
  clinicDays_aggregate?: InputMaybe<ClinicDayAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  dayOfContact?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  meetingLink?: InputMaybe<StringComparisonExp>;
  priceOverride?: InputMaybe<NumericComparisonExp>;
  registrationStatus?: InputMaybe<StringComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  spotsRemaining?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "clinic" */
export enum ClinicConstraint {
  /** unique or primary key constraint on columns "cuid" */
  ClinicCuidKey = 'clinic_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ClinicPkey = 'clinic_pkey'
}

/** input type for incrementing numeric columns in table "clinic" */
export type ClinicIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  priceOverride?: InputMaybe<Scalars['numeric']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clinic" */
export type ClinicInsertInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  clinicCoaches?: InputMaybe<ClinicCoachArrRelInsertInput>;
  clinicDays?: InputMaybe<ClinicDayArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfContact?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  priceOverride?: InputMaybe<Scalars['numeric']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClinicMaxFields = {
  __typename?: 'clinic_max_fields';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfContact?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  meetingLink?: Maybe<Scalars['String']>;
  priceOverride?: Maybe<Scalars['numeric']>;
  registrationStatus?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clinic" */
export type ClinicMaxOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClinicMinFields = {
  __typename?: 'clinic_min_fields';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfContact?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  meetingLink?: Maybe<Scalars['String']>;
  priceOverride?: Maybe<Scalars['numeric']>;
  registrationStatus?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clinic" */
export type ClinicMinOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clinic" */
export type ClinicMutationResponse = {
  __typename?: 'clinic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinic>;
};

/** input type for inserting object relation for remote table "clinic" */
export type ClinicObjRelInsertInput = {
  data: ClinicInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicOnConflict>;
};

/** on_conflict condition type for table "clinic" */
export type ClinicOnConflict = {
  constraint: ClinicConstraint;
  update_columns?: Array<ClinicUpdateColumn>;
  where?: InputMaybe<ClinicBoolExp>;
};

/** Ordering options when selecting data from "clinic". */
export type ClinicOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  clinicCoaches_aggregate?: InputMaybe<ClinicCoachAggregateOrderBy>;
  clinicDays_aggregate?: InputMaybe<ClinicDayAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsRemaining?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clinic */
export type ClinicPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "clinic" */
export enum ClinicSelectColumn {
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfContact = 'dayOfContact',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meetingLink',
  /** column name */
  PriceOverride = 'priceOverride',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clinic" */
export type ClinicSetInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfContact?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  priceOverride?: InputMaybe<Scalars['numeric']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ClinicStddevFields = {
  __typename?: 'clinic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clinic" */
export type ClinicStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ClinicStddevPopFields = {
  __typename?: 'clinic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clinic" */
export type ClinicStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ClinicStddevSampFields = {
  __typename?: 'clinic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clinic" */
export type ClinicStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ClinicSumFields = {
  __typename?: 'clinic_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  priceOverride?: Maybe<Scalars['numeric']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clinic" */
export type ClinicSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** update columns of table "clinic" */
export enum ClinicUpdateColumn {
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfContact = 'dayOfContact',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meetingLink',
  /** column name */
  PriceOverride = 'priceOverride',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ClinicUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicSetInput>;
  where: ClinicBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicVarPopFields = {
  __typename?: 'clinic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clinic" */
export type ClinicVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ClinicVarSampFields = {
  __typename?: 'clinic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clinic" */
export type ClinicVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClinicVarianceFields = {
  __typename?: 'clinic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clinic" */
export type ClinicVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Coach Profile for a User */
export type Coach = Node & {
  __typename?: 'coach';
  about?: Maybe<Scalars['String']>;
  addRacingExp?: Maybe<Scalars['Boolean']>;
  allowRequests: Scalars['Boolean'];
  calendarId: Scalars['String'];
  /** An array relationship */
  clinicCoaches: Array<ClinicCoach>;
  /** An aggregate relationship */
  clinicCoaches_aggregate: ClinicCoachAggregate;
  /** An array relationship connection */
  clinicCoaches_connection: ClinicCoachConnection;
  /** An array relationship */
  coachAvailabilities: Array<CoachAvailability>;
  /** An aggregate relationship */
  coachAvailabilities_aggregate: CoachAvailabilityAggregate;
  /** An array relationship connection */
  coachAvailabilities_connection: CoachAvailabilityConnection;
  /** An array relationship */
  coachCertifications: Array<CoachCertification>;
  /** An aggregate relationship */
  coachCertifications_aggregate: CoachCertificationAggregate;
  /** An array relationship connection */
  coachCertifications_connection: CoachCertificationConnection;
  /** An array relationship */
  coachCustomerLessons: Array<CoachCustomerLesson>;
  /** An aggregate relationship */
  coachCustomerLessons_aggregate: CoachCustomerLessonAggregate;
  /** An array relationship connection */
  coachCustomerLessons_connection: CoachCustomerLessonConnection;
  /** An array relationship */
  coachCustomers: Array<CoachCustomer>;
  /** An aggregate relationship */
  coachCustomers_aggregate: CoachCustomerAggregate;
  /** An array relationship connection */
  coachCustomers_connection: CoachCustomerConnection;
  /** An array relationship */
  coachDisciplines: Array<CoachDiscipline>;
  /** An aggregate relationship */
  coachDisciplines_aggregate: CoachDisciplineAggregate;
  /** An array relationship connection */
  coachDisciplines_connection: CoachDisciplineConnection;
  /** An array relationship */
  coachImages: Array<CoachImage>;
  /** An aggregate relationship */
  coachImages_aggregate: CoachImageAggregate;
  /** An array relationship connection */
  coachImages_connection: CoachImageConnection;
  /** An array relationship */
  coachLessons: Array<CoachLesson>;
  /** An aggregate relationship */
  coachLessons_aggregate: CoachLessonAggregate;
  /** An array relationship connection */
  coachLessons_connection: CoachLessonConnection;
  /** An array relationship */
  coachLocations: Array<CoachLocation>;
  /** An aggregate relationship */
  coachLocations_aggregate: CoachLocationAggregate;
  /** An array relationship connection */
  coachLocations_connection: CoachLocationConnection;
  /** An array relationship */
  coachModerationFlags: Array<CoachModerationFlag>;
  /** An aggregate relationship */
  coachModerationFlags_aggregate: CoachModerationFlagAggregate;
  /** An array relationship connection */
  coachModerationFlags_connection: CoachModerationFlagConnection;
  /** An array relationship */
  coachStudents: Array<CoachStudent>;
  /** An aggregate relationship */
  coachStudents_aggregate: CoachStudentAggregate;
  /** An array relationship connection */
  coachStudents_connection: CoachStudentConnection;
  /** An array relationship */
  coachUnavailableDates: Array<CoachUnavailableDate>;
  /** An aggregate relationship */
  coachUnavailableDates_aggregate: CoachUnavailableDateAggregate;
  /** An array relationship connection */
  coachUnavailableDates_connection: CoachUnavailableDateConnection;
  coachingRadius?: Maybe<Scalars['Int']>;
  coachingSince?: Maybe<Scalars['Int']>;
  coachingVibe?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  currencyCode: Scalars['String'];
  experience?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  introVideoThumbnailUrl?: Maybe<Scalars['String']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  isCPRCertified: Scalars['Boolean'];
  isFirstAidCertified: Scalars['Boolean'];
  isSuperCoach?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  lessonLocations: Array<LessonLocation>;
  /** An aggregate relationship */
  lessonLocations_aggregate: LessonLocationAggregate;
  /** An array relationship connection */
  lessonLocations_connection: LessonLocationConnection;
  offerCustomLesson: Scalars['Boolean'];
  /** An array relationship */
  onlineCourses: Array<OnlineCourse>;
  /** An aggregate relationship */
  onlineCourses_aggregate: OnlineCourseAggregate;
  /** An array relationship connection */
  onlineCourses_connection: OnlineCourseConnection;
  openToTravel: Scalars['Boolean'];
  /** An object relationship */
  organizer?: Maybe<Organizer>;
  organizerCuidToDisplay?: Maybe<Scalars['String']>;
  racingExp?: Maybe<Scalars['String']>;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: ReviewAggregate;
  /** An array relationship connection */
  reviews_connection: ReviewConnection;
  showProfileHelpBanner: Scalars['Boolean'];
  syncLocationToProfile: Scalars['Boolean'];
  tagline?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  /** An array relationship */
  userSavedCoaches: Array<UserSavedCoach>;
  /** An aggregate relationship */
  userSavedCoaches_aggregate: UserSavedCoachAggregate;
  /** An array relationship connection */
  userSavedCoaches_connection: UserSavedCoachConnection;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: UserAggregate;
  /** An array relationship connection */
  users_connection: UserConnection;
  yearStarted?: Maybe<Scalars['Int']>;
};


/** Coach Profile for a User */
export type CoachClinicCoachesArgs = {
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachClinicCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachClinicCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachAvailabilityOrderBy>>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachAvailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachAvailabilityOrderBy>>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachAvailabilitiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachAvailabilityOrderBy>>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCertificationsArgs = {
  distinct_on?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCertificationOrderBy>>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCertificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCertificationOrderBy>>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCertificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCertificationOrderBy>>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomerLessonsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomerLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomerLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomersArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachCustomersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachDisciplinesArgs = {
  distinct_on?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachDisciplineOrderBy>>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachDisciplinesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachDisciplineOrderBy>>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachDisciplinesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachDisciplineOrderBy>>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachImagesArgs = {
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLessonsArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonOrderBy>>;
  where?: InputMaybe<CoachLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonOrderBy>>;
  where?: InputMaybe<CoachLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonOrderBy>>;
  where?: InputMaybe<CoachLessonBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLocationsArgs = {
  distinct_on?: InputMaybe<Array<CoachLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLocationOrderBy>>;
  where?: InputMaybe<CoachLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLocationOrderBy>>;
  where?: InputMaybe<CoachLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLocationOrderBy>>;
  where?: InputMaybe<CoachLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachModerationFlagsArgs = {
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachModerationFlagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachModerationFlagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachStudentsArgs = {
  distinct_on?: InputMaybe<Array<CoachStudentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachStudentOrderBy>>;
  where?: InputMaybe<CoachStudentBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachStudentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachStudentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachStudentOrderBy>>;
  where?: InputMaybe<CoachStudentBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachStudentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachStudentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachStudentOrderBy>>;
  where?: InputMaybe<CoachStudentBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachUnavailableDatesArgs = {
  distinct_on?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachUnavailableDateOrderBy>>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachUnavailableDatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachUnavailableDateOrderBy>>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachUnavailableDatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachUnavailableDateOrderBy>>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};


/** Coach Profile for a User */
export type CoachCoachingVibeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Coach Profile for a User */
export type CoachLessonLocationsArgs = {
  distinct_on?: InputMaybe<Array<LessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LessonLocationOrderBy>>;
  where?: InputMaybe<LessonLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachLessonLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LessonLocationOrderBy>>;
  where?: InputMaybe<LessonLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachLessonLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<LessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LessonLocationOrderBy>>;
  where?: InputMaybe<LessonLocationBoolExp>;
};


/** Coach Profile for a User */
export type CoachOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** Coach Profile for a User */
export type CoachOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** Coach Profile for a User */
export type CoachOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** Coach Profile for a User */
export type CoachReviewsArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Coach Profile for a User */
export type CoachReviewsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Coach Profile for a User */
export type CoachReviewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Coach Profile for a User */
export type CoachUserSavedCoachesArgs = {
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachUserSavedCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachUserSavedCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Coach Profile for a User */
export type CoachUsersArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** Coach Profile for a User */
export type CoachUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** Coach Profile for a User */
export type CoachUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** Availability for a coach */
export type CoachAvailability = Node & {
  __typename?: 'coachAvailability';
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  dayOfTheWeek: Scalars['String'];
  endSeconds: Scalars['Int'];
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  startSeconds: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachAvailability" */
export type CoachAvailabilityConnection = {
  __typename?: 'coachAvailabilityConnection';
  edges: Array<CoachAvailabilityEdge>;
  pageInfo: PageInfo;
};

export type CoachAvailabilityEdge = {
  __typename?: 'coachAvailabilityEdge';
  cursor: Scalars['String'];
  node: CoachAvailability;
};

/** aggregated selection of "coachAvailability" */
export type CoachAvailabilityAggregate = {
  __typename?: 'coachAvailability_aggregate';
  aggregate?: Maybe<CoachAvailabilityAggregateFields>;
  nodes: Array<CoachAvailability>;
};

export type CoachAvailabilityAggregateBoolExp = {
  bool_and?: InputMaybe<CoachAvailabilityAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachAvailabilityAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachAvailabilityAggregateBoolExpCount>;
};

export type CoachAvailabilityAggregateBoolExpBoolAnd = {
  arguments: CoachAvailabilitySelectColumnCoachAvailabilityAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachAvailabilityBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachAvailabilityAggregateBoolExpBoolOr = {
  arguments: CoachAvailabilitySelectColumnCoachAvailabilityAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachAvailabilityBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachAvailabilityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachAvailabilityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachAvailability" */
export type CoachAvailabilityAggregateFields = {
  __typename?: 'coachAvailability_aggregate_fields';
  avg?: Maybe<CoachAvailabilityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachAvailabilityMaxFields>;
  min?: Maybe<CoachAvailabilityMinFields>;
  stddev?: Maybe<CoachAvailabilityStddevFields>;
  stddev_pop?: Maybe<CoachAvailabilityStddevPopFields>;
  stddev_samp?: Maybe<CoachAvailabilityStddevSampFields>;
  sum?: Maybe<CoachAvailabilitySumFields>;
  var_pop?: Maybe<CoachAvailabilityVarPopFields>;
  var_samp?: Maybe<CoachAvailabilityVarSampFields>;
  variance?: Maybe<CoachAvailabilityVarianceFields>;
};


/** aggregate fields of "coachAvailability" */
export type CoachAvailabilityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachAvailability" */
export type CoachAvailabilityAggregateOrderBy = {
  avg?: InputMaybe<CoachAvailabilityAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachAvailabilityMaxOrderBy>;
  min?: InputMaybe<CoachAvailabilityMinOrderBy>;
  stddev?: InputMaybe<CoachAvailabilityStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachAvailabilityStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachAvailabilityStddevSampOrderBy>;
  sum?: InputMaybe<CoachAvailabilitySumOrderBy>;
  var_pop?: InputMaybe<CoachAvailabilityVarPopOrderBy>;
  var_samp?: InputMaybe<CoachAvailabilityVarSampOrderBy>;
  variance?: InputMaybe<CoachAvailabilityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachAvailability" */
export type CoachAvailabilityArrRelInsertInput = {
  data: Array<CoachAvailabilityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachAvailabilityOnConflict>;
};

/** aggregate avg on columns */
export type CoachAvailabilityAvgFields = {
  __typename?: 'coachAvailability_avg_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachAvailability" */
export type CoachAvailabilityAvgOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachAvailability". All fields are combined with a logical 'AND'. */
export type CoachAvailabilityBoolExp = {
  _and?: InputMaybe<Array<CoachAvailabilityBoolExp>>;
  _not?: InputMaybe<CoachAvailabilityBoolExp>;
  _or?: InputMaybe<Array<CoachAvailabilityBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  dayOfTheWeek?: InputMaybe<StringComparisonExp>;
  endSeconds?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isAvailable?: InputMaybe<BooleanComparisonExp>;
  startSeconds?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachAvailability" */
export enum CoachAvailabilityConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachAvailabilityCuidKey = 'coachAvailability_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachAvailabilityPkey = 'coachAvailability_pkey'
}

/** input type for incrementing numeric columns in table "coachAvailability" */
export type CoachAvailabilityIncInput = {
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachAvailability" */
export type CoachAvailabilityInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfTheWeek?: InputMaybe<Scalars['String']>;
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachAvailabilityMaxFields = {
  __typename?: 'coachAvailability_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachAvailability" */
export type CoachAvailabilityMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachAvailabilityMinFields = {
  __typename?: 'coachAvailability_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachAvailability" */
export type CoachAvailabilityMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachAvailability" */
export type CoachAvailabilityMutationResponse = {
  __typename?: 'coachAvailability_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachAvailability>;
};

/** on_conflict condition type for table "coachAvailability" */
export type CoachAvailabilityOnConflict = {
  constraint: CoachAvailabilityConstraint;
  update_columns?: Array<CoachAvailabilityUpdateColumn>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};

/** Ordering options when selecting data from "coachAvailability". */
export type CoachAvailabilityOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAvailable?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachAvailability */
export type CoachAvailabilityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachAvailability" */
export enum CoachAvailabilitySelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfTheWeek = 'dayOfTheWeek',
  /** column name */
  EndSeconds = 'endSeconds',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailable = 'isAvailable',
  /** column name */
  StartSeconds = 'startSeconds',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "coachAvailability_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coachAvailability" */
export enum CoachAvailabilitySelectColumnCoachAvailabilityAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAvailable = 'isAvailable'
}

/** select "coachAvailability_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coachAvailability" */
export enum CoachAvailabilitySelectColumnCoachAvailabilityAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAvailable = 'isAvailable'
}

/** input type for updating data in table "coachAvailability" */
export type CoachAvailabilitySetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfTheWeek?: InputMaybe<Scalars['String']>;
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachAvailabilityStddevFields = {
  __typename?: 'coachAvailability_stddev_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachAvailability" */
export type CoachAvailabilityStddevOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachAvailabilityStddevPopFields = {
  __typename?: 'coachAvailability_stddev_pop_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachAvailability" */
export type CoachAvailabilityStddevPopOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachAvailabilityStddevSampFields = {
  __typename?: 'coachAvailability_stddev_samp_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachAvailability" */
export type CoachAvailabilityStddevSampOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachAvailabilitySumFields = {
  __typename?: 'coachAvailability_sum_fields';
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachAvailability" */
export type CoachAvailabilitySumOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** update columns of table "coachAvailability" */
export enum CoachAvailabilityUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfTheWeek = 'dayOfTheWeek',
  /** column name */
  EndSeconds = 'endSeconds',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailable = 'isAvailable',
  /** column name */
  StartSeconds = 'startSeconds',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachAvailabilityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachAvailabilityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachAvailabilitySetInput>;
  where: CoachAvailabilityBoolExp;
};

/** aggregate var_pop on columns */
export type CoachAvailabilityVarPopFields = {
  __typename?: 'coachAvailability_var_pop_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachAvailability" */
export type CoachAvailabilityVarPopOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachAvailabilityVarSampFields = {
  __typename?: 'coachAvailability_var_samp_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachAvailability" */
export type CoachAvailabilityVarSampOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachAvailabilityVarianceFields = {
  __typename?: 'coachAvailability_variance_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachAvailability" */
export type CoachAvailabilityVarianceOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** Certifications that a coach has */
export type CoachCertification = Node & {
  __typename?: 'coachCertification';
  certification: Scalars['String'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** A Relay connection object on "coachCertification" */
export type CoachCertificationConnection = {
  __typename?: 'coachCertificationConnection';
  edges: Array<CoachCertificationEdge>;
  pageInfo: PageInfo;
};

export type CoachCertificationEdge = {
  __typename?: 'coachCertificationEdge';
  cursor: Scalars['String'];
  node: CoachCertification;
};

/** aggregated selection of "coachCertification" */
export type CoachCertificationAggregate = {
  __typename?: 'coachCertification_aggregate';
  aggregate?: Maybe<CoachCertificationAggregateFields>;
  nodes: Array<CoachCertification>;
};

export type CoachCertificationAggregateBoolExp = {
  count?: InputMaybe<CoachCertificationAggregateBoolExpCount>;
};

export type CoachCertificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCertificationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCertification" */
export type CoachCertificationAggregateFields = {
  __typename?: 'coachCertification_aggregate_fields';
  avg?: Maybe<CoachCertificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCertificationMaxFields>;
  min?: Maybe<CoachCertificationMinFields>;
  stddev?: Maybe<CoachCertificationStddevFields>;
  stddev_pop?: Maybe<CoachCertificationStddevPopFields>;
  stddev_samp?: Maybe<CoachCertificationStddevSampFields>;
  sum?: Maybe<CoachCertificationSumFields>;
  var_pop?: Maybe<CoachCertificationVarPopFields>;
  var_samp?: Maybe<CoachCertificationVarSampFields>;
  variance?: Maybe<CoachCertificationVarianceFields>;
};


/** aggregate fields of "coachCertification" */
export type CoachCertificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCertification" */
export type CoachCertificationAggregateOrderBy = {
  avg?: InputMaybe<CoachCertificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCertificationMaxOrderBy>;
  min?: InputMaybe<CoachCertificationMinOrderBy>;
  stddev?: InputMaybe<CoachCertificationStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCertificationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCertificationStddevSampOrderBy>;
  sum?: InputMaybe<CoachCertificationSumOrderBy>;
  var_pop?: InputMaybe<CoachCertificationVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCertificationVarSampOrderBy>;
  variance?: InputMaybe<CoachCertificationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCertification" */
export type CoachCertificationArrRelInsertInput = {
  data: Array<CoachCertificationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCertificationOnConflict>;
};

/** aggregate avg on columns */
export type CoachCertificationAvgFields = {
  __typename?: 'coachCertification_avg_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCertification" */
export type CoachCertificationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCertification". All fields are combined with a logical 'AND'. */
export type CoachCertificationBoolExp = {
  _and?: InputMaybe<Array<CoachCertificationBoolExp>>;
  _not?: InputMaybe<CoachCertificationBoolExp>;
  _or?: InputMaybe<Array<CoachCertificationBoolExp>>;
  certification?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  year?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "coachCertification" */
export enum CoachCertificationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCertificationCuidKey = 'coachCertification_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserCoachCertificationPkey = 'userCoachCertification_pkey'
}

/** input type for incrementing numeric columns in table "coachCertification" */
export type CoachCertificationIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachCertification" */
export type CoachCertificationInsertInput = {
  certification?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CoachCertificationMaxFields = {
  __typename?: 'coachCertification_max_fields';
  certification?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "coachCertification" */
export type CoachCertificationMaxOrderBy = {
  certification?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCertificationMinFields = {
  __typename?: 'coachCertification_min_fields';
  certification?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "coachCertification" */
export type CoachCertificationMinOrderBy = {
  certification?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCertification" */
export type CoachCertificationMutationResponse = {
  __typename?: 'coachCertification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCertification>;
};

/** on_conflict condition type for table "coachCertification" */
export type CoachCertificationOnConflict = {
  constraint: CoachCertificationConstraint;
  update_columns?: Array<CoachCertificationUpdateColumn>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};

/** Ordering options when selecting data from "coachCertification". */
export type CoachCertificationOrderBy = {
  certification?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCertification */
export type CoachCertificationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCertification" */
export enum CoachCertificationSelectColumn {
  /** column name */
  Certification = 'certification',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "coachCertification" */
export type CoachCertificationSetInput = {
  certification?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoachCertificationStddevFields = {
  __typename?: 'coachCertification_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCertification" */
export type CoachCertificationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCertificationStddevPopFields = {
  __typename?: 'coachCertification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCertification" */
export type CoachCertificationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCertificationStddevSampFields = {
  __typename?: 'coachCertification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCertification" */
export type CoachCertificationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCertificationSumFields = {
  __typename?: 'coachCertification_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachCertification" */
export type CoachCertificationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCertification" */
export enum CoachCertificationUpdateColumn {
  /** column name */
  Certification = 'certification',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

export type CoachCertificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCertificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCertificationSetInput>;
  where: CoachCertificationBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCertificationVarPopFields = {
  __typename?: 'coachCertification_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCertification" */
export type CoachCertificationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCertificationVarSampFields = {
  __typename?: 'coachCertification_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCertification" */
export type CoachCertificationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCertificationVarianceFields = {
  __typename?: 'coachCertification_variance_fields';
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCertification" */
export type CoachCertificationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "coach" */
export type CoachConnection = {
  __typename?: 'coachConnection';
  edges: Array<CoachEdge>;
  pageInfo: PageInfo;
};

/** Customer of a coach */
export type CoachCustomer = Node & {
  __typename?: 'coachCustomer';
  channelId: Scalars['String'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerLessons: Array<CoachCustomerLesson>;
  /** An aggregate relationship */
  coachCustomerLessons_aggregate: CoachCustomerLessonAggregate;
  /** An array relationship connection */
  coachCustomerLessons_connection: CoachCustomerLessonConnection;
  /** An array relationship */
  coachCustomerMessageRequests: Array<CoachCustomerMessageRequest>;
  /** An aggregate relationship */
  coachCustomerMessageRequests_aggregate: CoachCustomerMessageRequestAggregate;
  /** An array relationship connection */
  coachCustomerMessageRequests_connection: CoachCustomerMessageRequestConnection;
  /** An array relationship */
  coachCustomerUsers: Array<CoachCustomerUser>;
  /** An aggregate relationship */
  coachCustomerUsers_aggregate: CoachCustomerUserAggregate;
  /** An array relationship connection */
  coachCustomerUsers_connection: CoachCustomerUserConnection;
  /** An object relationship */
  coachLesson?: Maybe<CoachLesson>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  requestedCoachLessonCuid?: Maybe<Scalars['String']>;
  requestedStartTime?: Maybe<Scalars['timestamp']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerLessonsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerMessageRequestsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerMessageRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerMessageRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


/** Customer of a coach */
export type CoachCustomerCoachCustomerUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};

/** A Relay connection object on "coachCustomer" */
export type CoachCustomerConnection = {
  __typename?: 'coachCustomerConnection';
  edges: Array<CoachCustomerEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerEdge = {
  __typename?: 'coachCustomerEdge';
  cursor: Scalars['String'];
  node: CoachCustomer;
};

/** A private Lesson */
export type CoachCustomerLesson = Node & {
  __typename?: 'coachCustomerLesson';
  abilityLevelRange: Scalars['jsonb'];
  cancellationReason?: Maybe<Scalars['String']>;
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  /** An object relationship */
  coachCustomer: CoachCustomer;
  coachCustomerCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerLessonDates: Array<CoachCustomerLessonDate>;
  /** An aggregate relationship */
  coachCustomerLessonDates_aggregate: CoachCustomerLessonDateAggregate;
  /** An array relationship connection */
  coachCustomerLessonDates_connection: CoachCustomerLessonDateConnection;
  /** An array relationship */
  coachCustomerLessonPauseResumes: Array<CoachCustomerLessonPauseResume>;
  /** An aggregate relationship */
  coachCustomerLessonPauseResumes_aggregate: CoachCustomerLessonPauseResumeAggregate;
  /** An array relationship connection */
  coachCustomerLessonPauseResumes_connection: CoachCustomerLessonPauseResumeConnection;
  /** An array relationship */
  coachCustomerLessonTransactions: Array<CoachCustomerLessonTransaction>;
  /** An aggregate relationship */
  coachCustomerLessonTransactions_aggregate: CoachCustomerLessonTransactionAggregate;
  /** An array relationship connection */
  coachCustomerLessonTransactions_connection: CoachCustomerLessonTransactionConnection;
  /** An object relationship */
  coachLesson?: Maybe<CoachLesson>;
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  currency: Scalars['String'];
  disciplineNames: Scalars['jsonb'];
  id: Scalars['ID'];
  isVirtual: Scalars['Boolean'];
  offerCanceledDate?: Maybe<Scalars['timestamptz']>;
  offerMessageId: Scalars['String'];
  offerRejectedDate?: Maybe<Scalars['timestamptz']>;
  paidDate?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  recurrenceType: Scalars['String'];
  recurringInterval?: Maybe<Scalars['String']>;
  resumeRecurringLessonTriggerId?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionCanceledDate?: Maybe<Scalars['timestamptz']>;
  subscriptionPausedUntil?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** A private Lesson */
export type CoachCustomerLessonAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonDatesArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonDatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonDatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonPauseResumesArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonPauseResumeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonPauseResumesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonPauseResumeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonPauseResumesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonPauseResumeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonTransactionsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonCoachCustomerLessonTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** A private Lesson */
export type CoachCustomerLessonDisciplineNamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A private Lesson */
export type CoachCustomerLessonSkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "coachCustomerLesson" */
export type CoachCustomerLessonConnection = {
  __typename?: 'coachCustomerLessonConnection';
  edges: Array<CoachCustomerLessonEdge>;
  pageInfo: PageInfo;
};

/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDate = Node & {
  __typename?: 'coachCustomerLessonDate';
  canceledDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  coachCustomerLesson: CoachCustomerLesson;
  coachCustomerLessonCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerLessonDateLocations: Array<CoachCustomerLessonDateLocation>;
  /** An aggregate relationship */
  coachCustomerLessonDateLocations_aggregate: CoachCustomerLessonDateLocationAggregate;
  /** An array relationship connection */
  coachCustomerLessonDateLocations_connection: CoachCustomerLessonDateLocationConnection;
  /** An array relationship */
  coachCustomerLessonDateVirtualLocations: Array<CoachCustomerLessonDateVirtualLocation>;
  /** An aggregate relationship */
  coachCustomerLessonDateVirtualLocations_aggregate: CoachCustomerLessonDateVirtualLocationAggregate;
  /** An array relationship connection */
  coachCustomerLessonDateVirtualLocations_connection: CoachCustomerLessonDateVirtualLocationConnection;
  /** An array relationship */
  coachCustomerLessonOneTimeCharges: Array<CoachCustomerLessonOneTimeCharge>;
  /** An aggregate relationship */
  coachCustomerLessonOneTimeCharges_aggregate: CoachCustomerLessonOneTimeChargeAggregate;
  /** An array relationship connection */
  coachCustomerLessonOneTimeCharges_connection: CoachCustomerLessonOneTimeChargeConnection;
  /** An array relationship */
  coachCustomerLessonTransactions: Array<CoachCustomerLessonTransaction>;
  /** An aggregate relationship */
  coachCustomerLessonTransactions_aggregate: CoachCustomerLessonTransactionAggregate;
  /** An array relationship connection */
  coachCustomerLessonTransactions_connection: CoachCustomerLessonTransactionConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate: Scalars['timestamp'];
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone: Scalars['String'];
  refundedDate?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  reportLessonNoShows: Array<ReportLessonNoShow>;
  /** An aggregate relationship */
  reportLessonNoShows_aggregate: ReportLessonNoShowAggregate;
  /** An array relationship connection */
  reportLessonNoShows_connection: ReportLessonNoShowConnection;
  startDate: Scalars['timestamp'];
  updated_at: Scalars['timestamptz'];
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateLocationsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateVirtualLocationsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateVirtualLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonDateVirtualLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonOneTimeChargesArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonOneTimeChargesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonOneTimeChargesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonTransactionsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateCoachCustomerLessonTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateReportLessonNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateReportLessonNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** An instance of a coachCustomerLesson */
export type CoachCustomerLessonDateReportLessonNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};

/** A Relay connection object on "coachCustomerLessonDate" */
export type CoachCustomerLessonDateConnection = {
  __typename?: 'coachCustomerLessonDateConnection';
  edges: Array<CoachCustomerLessonDateEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonDateEdge = {
  __typename?: 'coachCustomerLessonDateEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonDate;
};

/** Where do we meet for a service? */
export type CoachCustomerLessonDateLocation = Node & {
  __typename?: 'coachCustomerLessonDateLocation';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  coachCustomerLessonDate: CoachCustomerLessonDate;
  coachCustomerLessonDateCuid: Scalars['String'];
  country: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  fullAddress: Scalars['String'];
  googlePlaceId: Scalars['String'];
  id: Scalars['ID'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  state?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zipCode?: Maybe<Scalars['String']>;
};

/** A Relay connection object on "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationConnection = {
  __typename?: 'coachCustomerLessonDateLocationConnection';
  edges: Array<CoachCustomerLessonDateLocationEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonDateLocationEdge = {
  __typename?: 'coachCustomerLessonDateLocationEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonDateLocation;
};

/** aggregated selection of "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationAggregate = {
  __typename?: 'coachCustomerLessonDateLocation_aggregate';
  aggregate?: Maybe<CoachCustomerLessonDateLocationAggregateFields>;
  nodes: Array<CoachCustomerLessonDateLocation>;
};

export type CoachCustomerLessonDateLocationAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonDateLocationAggregateBoolExpCount>;
};

export type CoachCustomerLessonDateLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationAggregateFields = {
  __typename?: 'coachCustomerLessonDateLocation_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonDateLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonDateLocationMaxFields>;
  min?: Maybe<CoachCustomerLessonDateLocationMinFields>;
  stddev?: Maybe<CoachCustomerLessonDateLocationStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonDateLocationStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonDateLocationStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonDateLocationSumFields>;
  var_pop?: Maybe<CoachCustomerLessonDateLocationVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonDateLocationVarSampFields>;
  variance?: Maybe<CoachCustomerLessonDateLocationVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonDateLocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonDateLocationMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonDateLocationMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonDateLocationStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonDateLocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonDateLocationStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonDateLocationSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonDateLocationVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonDateLocationVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonDateLocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationArrRelInsertInput = {
  data: Array<CoachCustomerLessonDateLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonDateLocationOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonDateLocationAvgFields = {
  __typename?: 'coachCustomerLessonDateLocation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonDateLocation". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonDateLocationBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonDateLocationBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonDateLocationBoolExp>>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  coachCustomerLessonDateCuid?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  fullAddress?: InputMaybe<StringComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonDateLocation" */
export enum CoachCustomerLessonDateLocationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonDateLocationCuidKey = 'coachCustomerLessonDateLocation_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ServiceDateLocationPkey = 'serviceDateLocation_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationInsertInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateObjRelInsertInput>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonDateLocationMaxFields = {
  __typename?: 'coachCustomerLessonDateLocation_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationMaxOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonDateLocationMinFields = {
  __typename?: 'coachCustomerLessonDateLocation_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationMinOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationMutationResponse = {
  __typename?: 'coachCustomerLessonDateLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonDateLocation>;
};

/** on_conflict condition type for table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationOnConflict = {
  constraint: CoachCustomerLessonDateLocationConstraint;
  update_columns?: Array<CoachCustomerLessonDateLocationUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonDateLocation". */
export type CoachCustomerLessonDateLocationOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateOrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonDateLocation */
export type CoachCustomerLessonDateLocationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonDateLocation" */
export enum CoachCustomerLessonDateLocationSelectColumn {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  City = 'city',
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationSetInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonDateLocationStddevFields = {
  __typename?: 'coachCustomerLessonDateLocation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonDateLocationStddevPopFields = {
  __typename?: 'coachCustomerLessonDateLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonDateLocationStddevSampFields = {
  __typename?: 'coachCustomerLessonDateLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonDateLocationSumFields = {
  __typename?: 'coachCustomerLessonDateLocation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonDateLocation" */
export enum CoachCustomerLessonDateLocationUpdateColumn {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  City = 'city',
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

export type CoachCustomerLessonDateLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonDateLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonDateLocationSetInput>;
  where: CoachCustomerLessonDateLocationBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonDateLocationVarPopFields = {
  __typename?: 'coachCustomerLessonDateLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonDateLocationVarSampFields = {
  __typename?: 'coachCustomerLessonDateLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonDateLocationVarianceFields = {
  __typename?: 'coachCustomerLessonDateLocation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonDateLocation" */
export type CoachCustomerLessonDateLocationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** A virtual location for a service */
export type CoachCustomerLessonDateVirtualLocation = Node & {
  __typename?: 'coachCustomerLessonDateVirtualLocation';
  /** An object relationship */
  coachCustomerLessonDate: CoachCustomerLessonDate;
  coachCustomerLessonDateCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  meetingLink: Scalars['String'];
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationConnection = {
  __typename?: 'coachCustomerLessonDateVirtualLocationConnection';
  edges: Array<CoachCustomerLessonDateVirtualLocationEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonDateVirtualLocationEdge = {
  __typename?: 'coachCustomerLessonDateVirtualLocationEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonDateVirtualLocation;
};

/** aggregated selection of "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationAggregate = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_aggregate';
  aggregate?: Maybe<CoachCustomerLessonDateVirtualLocationAggregateFields>;
  nodes: Array<CoachCustomerLessonDateVirtualLocation>;
};

export type CoachCustomerLessonDateVirtualLocationAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonDateVirtualLocationAggregateBoolExpCount>;
};

export type CoachCustomerLessonDateVirtualLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationAggregateFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonDateVirtualLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonDateVirtualLocationMaxFields>;
  min?: Maybe<CoachCustomerLessonDateVirtualLocationMinFields>;
  stddev?: Maybe<CoachCustomerLessonDateVirtualLocationStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonDateVirtualLocationStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonDateVirtualLocationStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonDateVirtualLocationSumFields>;
  var_pop?: Maybe<CoachCustomerLessonDateVirtualLocationVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonDateVirtualLocationVarSampFields>;
  variance?: Maybe<CoachCustomerLessonDateVirtualLocationVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonDateVirtualLocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonDateVirtualLocationMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonDateVirtualLocationMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonDateVirtualLocationStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonDateVirtualLocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonDateVirtualLocationStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonDateVirtualLocationSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonDateVirtualLocationVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonDateVirtualLocationVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonDateVirtualLocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationArrRelInsertInput = {
  data: Array<CoachCustomerLessonDateVirtualLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonDateVirtualLocationOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonDateVirtualLocationAvgFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonDateVirtualLocation". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonDateVirtualLocationBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationBoolExp>>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  coachCustomerLessonDateCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  meetingLink?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonDateVirtualLocation" */
export enum CoachCustomerLessonDateVirtualLocationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonDateVirtualLocationCuidKey = 'coachCustomerLessonDateVirtualLocation_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonDateVirtualLocationPkey = 'coachCustomerLessonDateVirtualLocation_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationInsertInput = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateObjRelInsertInput>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonDateVirtualLocationMaxFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_max_fields';
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  meetingLink?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationMaxOrderBy = {
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonDateVirtualLocationMinFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_min_fields';
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  meetingLink?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationMinOrderBy = {
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationMutationResponse = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonDateVirtualLocation>;
};

/** on_conflict condition type for table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationOnConflict = {
  constraint: CoachCustomerLessonDateVirtualLocationConstraint;
  update_columns?: Array<CoachCustomerLessonDateVirtualLocationUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonDateVirtualLocation". */
export type CoachCustomerLessonDateVirtualLocationOrderBy = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateOrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetingLink?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonDateVirtualLocation */
export type CoachCustomerLessonDateVirtualLocationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonDateVirtualLocation" */
export enum CoachCustomerLessonDateVirtualLocationSelectColumn {
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meetingLink',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationSetInput = {
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonDateVirtualLocationStddevFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonDateVirtualLocationStddevPopFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonDateVirtualLocationStddevSampFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonDateVirtualLocationSumFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonDateVirtualLocation" */
export enum CoachCustomerLessonDateVirtualLocationUpdateColumn {
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meetingLink',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonDateVirtualLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonDateVirtualLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonDateVirtualLocationSetInput>;
  where: CoachCustomerLessonDateVirtualLocationBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonDateVirtualLocationVarPopFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonDateVirtualLocationVarSampFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonDateVirtualLocationVarianceFields = {
  __typename?: 'coachCustomerLessonDateVirtualLocation_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonDateVirtualLocation" */
export type CoachCustomerLessonDateVirtualLocationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "coachCustomerLessonDate" */
export type CoachCustomerLessonDateAggregate = {
  __typename?: 'coachCustomerLessonDate_aggregate';
  aggregate?: Maybe<CoachCustomerLessonDateAggregateFields>;
  nodes: Array<CoachCustomerLessonDate>;
};

export type CoachCustomerLessonDateAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonDateAggregateBoolExpCount>;
};

export type CoachCustomerLessonDateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonDate" */
export type CoachCustomerLessonDateAggregateFields = {
  __typename?: 'coachCustomerLessonDate_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonDateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonDateMaxFields>;
  min?: Maybe<CoachCustomerLessonDateMinFields>;
  stddev?: Maybe<CoachCustomerLessonDateStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonDateStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonDateStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonDateSumFields>;
  var_pop?: Maybe<CoachCustomerLessonDateVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonDateVarSampFields>;
  variance?: Maybe<CoachCustomerLessonDateVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonDate" */
export type CoachCustomerLessonDateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonDateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonDateMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonDateMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonDateStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonDateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonDateStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonDateSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonDateVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonDateVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonDateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateArrRelInsertInput = {
  data: Array<CoachCustomerLessonDateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonDateOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonDateAvgFields = {
  __typename?: 'coachCustomerLessonDate_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateAvgOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonDate". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonDateBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonDateBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonDateBoolExp>>;
  canceledDate?: InputMaybe<TimestamptzComparisonExp>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessonCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonDateLocations?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
  coachCustomerLessonDateLocations_aggregate?: InputMaybe<CoachCustomerLessonDateLocationAggregateBoolExp>;
  coachCustomerLessonDateVirtualLocations?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
  coachCustomerLessonDateVirtualLocations_aggregate?: InputMaybe<CoachCustomerLessonDateVirtualLocationAggregateBoolExp>;
  coachCustomerLessonOneTimeCharges?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
  coachCustomerLessonOneTimeCharges_aggregate?: InputMaybe<CoachCustomerLessonOneTimeChargeAggregateBoolExp>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  originalStartDate?: InputMaybe<TimestampComparisonExp>;
  originalTimezone?: InputMaybe<StringComparisonExp>;
  refundedDate?: InputMaybe<TimestamptzComparisonExp>;
  reportLessonNoShows?: InputMaybe<ReportLessonNoShowBoolExp>;
  reportLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateBoolExp>;
  startDate?: InputMaybe<TimestampComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonDate" */
export enum CoachCustomerLessonDateConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonDateCuidKey = 'coachCustomerLessonDate_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonDatePkey = 'coachCustomerLessonDate_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateIncInput = {
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateInsertInput = {
  canceledDate?: InputMaybe<Scalars['timestamptz']>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonObjRelInsertInput>;
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDateLocations?: InputMaybe<CoachCustomerLessonDateLocationArrRelInsertInput>;
  coachCustomerLessonDateVirtualLocations?: InputMaybe<CoachCustomerLessonDateVirtualLocationArrRelInsertInput>;
  coachCustomerLessonOneTimeCharges?: InputMaybe<CoachCustomerLessonOneTimeChargeArrRelInsertInput>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: InputMaybe<Scalars['timestamp']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: InputMaybe<Scalars['String']>;
  refundedDate?: InputMaybe<Scalars['timestamptz']>;
  reportLessonNoShows?: InputMaybe<ReportLessonNoShowArrRelInsertInput>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonDateMaxFields = {
  __typename?: 'coachCustomerLessonDate_max_fields';
  canceledDate?: Maybe<Scalars['timestamptz']>;
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: Maybe<Scalars['timestamp']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: Maybe<Scalars['String']>;
  refundedDate?: Maybe<Scalars['timestamptz']>;
  startDate?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateMaxOrderBy = {
  canceledDate?: InputMaybe<OrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: InputMaybe<OrderBy>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonDateMinFields = {
  __typename?: 'coachCustomerLessonDate_min_fields';
  canceledDate?: Maybe<Scalars['timestamptz']>;
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: Maybe<Scalars['timestamp']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: Maybe<Scalars['String']>;
  refundedDate?: Maybe<Scalars['timestamptz']>;
  startDate?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateMinOrderBy = {
  canceledDate?: InputMaybe<OrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: InputMaybe<OrderBy>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateMutationResponse = {
  __typename?: 'coachCustomerLessonDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonDate>;
};

/** input type for inserting object relation for remote table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateObjRelInsertInput = {
  data: CoachCustomerLessonDateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonDateOnConflict>;
};

/** on_conflict condition type for table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateOnConflict = {
  constraint: CoachCustomerLessonDateConstraint;
  update_columns?: Array<CoachCustomerLessonDateUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonDate". */
export type CoachCustomerLessonDateOrderBy = {
  canceledDate?: InputMaybe<OrderBy>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonOrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonDateLocations_aggregate?: InputMaybe<CoachCustomerLessonDateLocationAggregateOrderBy>;
  coachCustomerLessonDateVirtualLocations_aggregate?: InputMaybe<CoachCustomerLessonDateVirtualLocationAggregateOrderBy>;
  coachCustomerLessonOneTimeCharges_aggregate?: InputMaybe<CoachCustomerLessonOneTimeChargeAggregateOrderBy>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  originalStartDate?: InputMaybe<OrderBy>;
  originalTimezone?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  reportLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateOrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonDate */
export type CoachCustomerLessonDatePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonDate" */
export enum CoachCustomerLessonDateSelectColumn {
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalStartDate = 'originalStartDate',
  /** column name */
  OriginalTimezone = 'originalTimezone',
  /** column name */
  RefundedDate = 'refundedDate',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateSetInput = {
  canceledDate?: InputMaybe<Scalars['timestamptz']>;
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalStartDate?: InputMaybe<Scalars['timestamp']>;
  /** Used to calculate upcoming lessons if lesson is recurring */
  originalTimezone?: InputMaybe<Scalars['String']>;
  refundedDate?: InputMaybe<Scalars['timestamptz']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonDateStddevFields = {
  __typename?: 'coachCustomerLessonDate_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateStddevOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonDateStddevPopFields = {
  __typename?: 'coachCustomerLessonDate_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateStddevPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonDateStddevSampFields = {
  __typename?: 'coachCustomerLessonDate_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateStddevSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonDateSumFields = {
  __typename?: 'coachCustomerLessonDate_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateSumOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonDate" */
export enum CoachCustomerLessonDateUpdateColumn {
  /** column name */
  CanceledDate = 'canceledDate',
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalStartDate = 'originalStartDate',
  /** column name */
  OriginalTimezone = 'originalTimezone',
  /** column name */
  RefundedDate = 'refundedDate',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonDateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonDateSetInput>;
  where: CoachCustomerLessonDateBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonDateVarPopFields = {
  __typename?: 'coachCustomerLessonDate_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateVarPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonDateVarSampFields = {
  __typename?: 'coachCustomerLessonDate_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateVarSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonDateVarianceFields = {
  __typename?: 'coachCustomerLessonDate_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonDate" */
export type CoachCustomerLessonDateVarianceOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export type CoachCustomerLessonEdge = {
  __typename?: 'coachCustomerLessonEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLesson;
};

/** A one time charge for a lesson */
export type CoachCustomerLessonOneTimeCharge = Node & {
  __typename?: 'coachCustomerLessonOneTimeCharge';
  /** An object relationship */
  coachCustomerLessonDate: CoachCustomerLessonDate;
  coachCustomerLessonDateCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerLessonTransactions: Array<CoachCustomerLessonTransaction>;
  /** An aggregate relationship */
  coachCustomerLessonTransactions_aggregate: CoachCustomerLessonTransactionAggregate;
  /** An array relationship connection */
  coachCustomerLessonTransactions_connection: CoachCustomerLessonTransactionConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  offerMessageId: Scalars['String'];
  paidDate?: Maybe<Scalars['timestamptz']>;
  price: Scalars['Int'];
  refundedDate?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** A one time charge for a lesson */
export type CoachCustomerLessonOneTimeChargeCoachCustomerLessonTransactionsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** A one time charge for a lesson */
export type CoachCustomerLessonOneTimeChargeCoachCustomerLessonTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


/** A one time charge for a lesson */
export type CoachCustomerLessonOneTimeChargeCoachCustomerLessonTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};

/** A Relay connection object on "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeConnection = {
  __typename?: 'coachCustomerLessonOneTimeChargeConnection';
  edges: Array<CoachCustomerLessonOneTimeChargeEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonOneTimeChargeEdge = {
  __typename?: 'coachCustomerLessonOneTimeChargeEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonOneTimeCharge;
};

/** aggregated selection of "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeAggregate = {
  __typename?: 'coachCustomerLessonOneTimeCharge_aggregate';
  aggregate?: Maybe<CoachCustomerLessonOneTimeChargeAggregateFields>;
  nodes: Array<CoachCustomerLessonOneTimeCharge>;
};

export type CoachCustomerLessonOneTimeChargeAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonOneTimeChargeAggregateBoolExpCount>;
};

export type CoachCustomerLessonOneTimeChargeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeAggregateFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonOneTimeChargeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonOneTimeChargeMaxFields>;
  min?: Maybe<CoachCustomerLessonOneTimeChargeMinFields>;
  stddev?: Maybe<CoachCustomerLessonOneTimeChargeStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonOneTimeChargeStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonOneTimeChargeStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonOneTimeChargeSumFields>;
  var_pop?: Maybe<CoachCustomerLessonOneTimeChargeVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonOneTimeChargeVarSampFields>;
  variance?: Maybe<CoachCustomerLessonOneTimeChargeVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonOneTimeChargeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonOneTimeChargeMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonOneTimeChargeMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonOneTimeChargeStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonOneTimeChargeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonOneTimeChargeStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonOneTimeChargeSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonOneTimeChargeVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonOneTimeChargeVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonOneTimeChargeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeArrRelInsertInput = {
  data: Array<CoachCustomerLessonOneTimeChargeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonOneTimeChargeOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonOneTimeChargeAvgFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonOneTimeCharge". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonOneTimeChargeBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeBoolExp>>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  coachCustomerLessonDateCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  offerMessageId?: InputMaybe<StringComparisonExp>;
  paidDate?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  refundedDate?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonOneTimeCharge" */
export enum CoachCustomerLessonOneTimeChargeConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonOneTimeChargeCuidKey = 'coachCustomerLessonOneTimeCharge_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonOneTimeChargePkey = 'coachCustomerLessonOneTimeCharge_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeInsertInput = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateObjRelInsertInput>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  offerMessageId?: InputMaybe<Scalars['String']>;
  paidDate?: InputMaybe<Scalars['timestamptz']>;
  price?: InputMaybe<Scalars['Int']>;
  refundedDate?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonOneTimeChargeMaxFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_max_fields';
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  offerMessageId?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  refundedDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeMaxOrderBy = {
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonOneTimeChargeMinFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_min_fields';
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  offerMessageId?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  refundedDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeMinOrderBy = {
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeMutationResponse = {
  __typename?: 'coachCustomerLessonOneTimeCharge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonOneTimeCharge>;
};

/** input type for inserting object relation for remote table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeObjRelInsertInput = {
  data: CoachCustomerLessonOneTimeChargeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonOneTimeChargeOnConflict>;
};

/** on_conflict condition type for table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeOnConflict = {
  constraint: CoachCustomerLessonOneTimeChargeConstraint;
  update_columns?: Array<CoachCustomerLessonOneTimeChargeUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonOneTimeCharge". */
export type CoachCustomerLessonOneTimeChargeOrderBy = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateOrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  refundedDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonOneTimeCharge */
export type CoachCustomerLessonOneTimeChargePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonOneTimeCharge" */
export enum CoachCustomerLessonOneTimeChargeSelectColumn {
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OfferMessageId = 'offerMessageId',
  /** column name */
  PaidDate = 'paidDate',
  /** column name */
  Price = 'price',
  /** column name */
  RefundedDate = 'refundedDate',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeSetInput = {
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  offerMessageId?: InputMaybe<Scalars['String']>;
  paidDate?: InputMaybe<Scalars['timestamptz']>;
  price?: InputMaybe<Scalars['Int']>;
  refundedDate?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonOneTimeChargeStddevFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonOneTimeChargeStddevPopFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonOneTimeChargeStddevSampFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonOneTimeChargeSumFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonOneTimeCharge" */
export enum CoachCustomerLessonOneTimeChargeUpdateColumn {
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OfferMessageId = 'offerMessageId',
  /** column name */
  PaidDate = 'paidDate',
  /** column name */
  Price = 'price',
  /** column name */
  RefundedDate = 'refundedDate',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonOneTimeChargeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonOneTimeChargeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonOneTimeChargeSetInput>;
  where: CoachCustomerLessonOneTimeChargeBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonOneTimeChargeVarPopFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonOneTimeChargeVarSampFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonOneTimeChargeVarianceFields = {
  __typename?: 'coachCustomerLessonOneTimeCharge_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonOneTimeCharge" */
export type CoachCustomerLessonOneTimeChargeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** A history of all the pauses and resumes a lesson has */
export type CoachCustomerLessonPauseResume = Node & {
  __typename?: 'coachCustomerLessonPauseResume';
  actionDate: Scalars['timestamp'];
  actionType: Scalars['String'];
  /** An object relationship */
  coachCustomerLesson: CoachCustomerLesson;
  coachCustomerLessonCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  pauseOnDate?: Maybe<Scalars['timestamptz']>;
  pausedUntilDate?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeConnection = {
  __typename?: 'coachCustomerLessonPauseResumeConnection';
  edges: Array<CoachCustomerLessonPauseResumeEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonPauseResumeEdge = {
  __typename?: 'coachCustomerLessonPauseResumeEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonPauseResume;
};

/** aggregated selection of "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeAggregate = {
  __typename?: 'coachCustomerLessonPauseResume_aggregate';
  aggregate?: Maybe<CoachCustomerLessonPauseResumeAggregateFields>;
  nodes: Array<CoachCustomerLessonPauseResume>;
};

export type CoachCustomerLessonPauseResumeAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonPauseResumeAggregateBoolExpCount>;
};

export type CoachCustomerLessonPauseResumeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeAggregateFields = {
  __typename?: 'coachCustomerLessonPauseResume_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonPauseResumeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonPauseResumeMaxFields>;
  min?: Maybe<CoachCustomerLessonPauseResumeMinFields>;
  stddev?: Maybe<CoachCustomerLessonPauseResumeStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonPauseResumeStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonPauseResumeStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonPauseResumeSumFields>;
  var_pop?: Maybe<CoachCustomerLessonPauseResumeVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonPauseResumeVarSampFields>;
  variance?: Maybe<CoachCustomerLessonPauseResumeVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonPauseResumeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonPauseResumeMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonPauseResumeMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonPauseResumeStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonPauseResumeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonPauseResumeStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonPauseResumeSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonPauseResumeVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonPauseResumeVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonPauseResumeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeArrRelInsertInput = {
  data: Array<CoachCustomerLessonPauseResumeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonPauseResumeOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonPauseResumeAvgFields = {
  __typename?: 'coachCustomerLessonPauseResume_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonPauseResume". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonPauseResumeBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonPauseResumeBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonPauseResumeBoolExp>>;
  actionDate?: InputMaybe<TimestampComparisonExp>;
  actionType?: InputMaybe<StringComparisonExp>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessonCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  pauseOnDate?: InputMaybe<TimestamptzComparisonExp>;
  pausedUntilDate?: InputMaybe<TimestamptzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonPauseResume" */
export enum CoachCustomerLessonPauseResumeConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonPauseResumeCuidKey = 'coachCustomerLessonPauseResume_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonPauseResumePkey = 'coachCustomerLessonPauseResume_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeInsertInput = {
  actionDate?: InputMaybe<Scalars['timestamp']>;
  actionType?: InputMaybe<Scalars['String']>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonObjRelInsertInput>;
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  pauseOnDate?: InputMaybe<Scalars['timestamptz']>;
  pausedUntilDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonPauseResumeMaxFields = {
  __typename?: 'coachCustomerLessonPauseResume_max_fields';
  actionDate?: Maybe<Scalars['timestamp']>;
  actionType?: Maybe<Scalars['String']>;
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pauseOnDate?: Maybe<Scalars['timestamptz']>;
  pausedUntilDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeMaxOrderBy = {
  actionDate?: InputMaybe<OrderBy>;
  actionType?: InputMaybe<OrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pauseOnDate?: InputMaybe<OrderBy>;
  pausedUntilDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonPauseResumeMinFields = {
  __typename?: 'coachCustomerLessonPauseResume_min_fields';
  actionDate?: Maybe<Scalars['timestamp']>;
  actionType?: Maybe<Scalars['String']>;
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pauseOnDate?: Maybe<Scalars['timestamptz']>;
  pausedUntilDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeMinOrderBy = {
  actionDate?: InputMaybe<OrderBy>;
  actionType?: InputMaybe<OrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pauseOnDate?: InputMaybe<OrderBy>;
  pausedUntilDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeMutationResponse = {
  __typename?: 'coachCustomerLessonPauseResume_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonPauseResume>;
};

/** on_conflict condition type for table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeOnConflict = {
  constraint: CoachCustomerLessonPauseResumeConstraint;
  update_columns?: Array<CoachCustomerLessonPauseResumeUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonPauseResume". */
export type CoachCustomerLessonPauseResumeOrderBy = {
  actionDate?: InputMaybe<OrderBy>;
  actionType?: InputMaybe<OrderBy>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonOrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pauseOnDate?: InputMaybe<OrderBy>;
  pausedUntilDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonPauseResume */
export type CoachCustomerLessonPauseResumePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonPauseResume" */
export enum CoachCustomerLessonPauseResumeSelectColumn {
  /** column name */
  ActionDate = 'actionDate',
  /** column name */
  ActionType = 'actionType',
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  PauseOnDate = 'pauseOnDate',
  /** column name */
  PausedUntilDate = 'pausedUntilDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeSetInput = {
  actionDate?: InputMaybe<Scalars['timestamp']>;
  actionType?: InputMaybe<Scalars['String']>;
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  pauseOnDate?: InputMaybe<Scalars['timestamptz']>;
  pausedUntilDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonPauseResumeStddevFields = {
  __typename?: 'coachCustomerLessonPauseResume_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonPauseResumeStddevPopFields = {
  __typename?: 'coachCustomerLessonPauseResume_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonPauseResumeStddevSampFields = {
  __typename?: 'coachCustomerLessonPauseResume_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonPauseResumeSumFields = {
  __typename?: 'coachCustomerLessonPauseResume_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonPauseResume" */
export enum CoachCustomerLessonPauseResumeUpdateColumn {
  /** column name */
  ActionDate = 'actionDate',
  /** column name */
  ActionType = 'actionType',
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  PauseOnDate = 'pauseOnDate',
  /** column name */
  PausedUntilDate = 'pausedUntilDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonPauseResumeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonPauseResumeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonPauseResumeSetInput>;
  where: CoachCustomerLessonPauseResumeBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonPauseResumeVarPopFields = {
  __typename?: 'coachCustomerLessonPauseResume_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonPauseResumeVarSampFields = {
  __typename?: 'coachCustomerLessonPauseResume_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonPauseResumeVarianceFields = {
  __typename?: 'coachCustomerLessonPauseResume_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonPauseResume" */
export type CoachCustomerLessonPauseResumeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A list of transactions for made by coachCustomers for a lesson */
export type CoachCustomerLessonTransaction = Node & {
  __typename?: 'coachCustomerLessonTransaction';
  /** An object relationship */
  coachCustomerLesson: CoachCustomerLesson;
  coachCustomerLessonCuid: Scalars['String'];
  /** An object relationship */
  coachCustomerLessonDate?: Maybe<CoachCustomerLessonDate>;
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  coachCustomerLessonOneTimeCharge?: Maybe<CoachCustomerLessonOneTimeCharge>;
  coachCustomerLessonOneTimeChargeCuid?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  referenceNo: Scalars['String'];
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken: Scalars['String'];
  transactionStatus: Scalars['String'];
  transactionType: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionConnection = {
  __typename?: 'coachCustomerLessonTransactionConnection';
  edges: Array<CoachCustomerLessonTransactionEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerLessonTransactionEdge = {
  __typename?: 'coachCustomerLessonTransactionEdge';
  cursor: Scalars['String'];
  node: CoachCustomerLessonTransaction;
};

/** aggregated selection of "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionAggregate = {
  __typename?: 'coachCustomerLessonTransaction_aggregate';
  aggregate?: Maybe<CoachCustomerLessonTransactionAggregateFields>;
  nodes: Array<CoachCustomerLessonTransaction>;
};

export type CoachCustomerLessonTransactionAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerLessonTransactionAggregateBoolExpCount>;
};

export type CoachCustomerLessonTransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionAggregateFields = {
  __typename?: 'coachCustomerLessonTransaction_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonTransactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonTransactionMaxFields>;
  min?: Maybe<CoachCustomerLessonTransactionMinFields>;
  stddev?: Maybe<CoachCustomerLessonTransactionStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonTransactionStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonTransactionStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonTransactionSumFields>;
  var_pop?: Maybe<CoachCustomerLessonTransactionVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonTransactionVarSampFields>;
  variance?: Maybe<CoachCustomerLessonTransactionVarianceFields>;
};


/** aggregate fields of "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonTransactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonTransactionMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonTransactionMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonTransactionStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonTransactionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonTransactionStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonTransactionSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonTransactionVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonTransactionVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionArrRelInsertInput = {
  data: Array<CoachCustomerLessonTransactionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonTransactionOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonTransactionAvgFields = {
  __typename?: 'coachCustomerLessonTransaction_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLessonTransaction". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonTransactionBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonTransactionBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonTransactionBoolExp>>;
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessonCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  coachCustomerLessonDateCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonOneTimeCharge?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  referenceNo?: InputMaybe<StringComparisonExp>;
  stripeCheckoutId?: InputMaybe<StringComparisonExp>;
  stripeInvoiceUrl?: InputMaybe<StringComparisonExp>;
  stripePaymentIntentId?: InputMaybe<StringComparisonExp>;
  successToken?: InputMaybe<StringComparisonExp>;
  transactionStatus?: InputMaybe<StringComparisonExp>;
  transactionType?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLessonTransaction" */
export enum CoachCustomerLessonTransactionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonTransactionCuidKey = 'coachCustomerLessonTransaction_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonTransactionPkey = 'coachCustomerLessonTransaction_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionInsertInput = {
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonObjRelInsertInput>;
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateObjRelInsertInput>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonOneTimeCharge?: InputMaybe<CoachCustomerLessonOneTimeChargeObjRelInsertInput>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripeInvoiceUrl?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  transactionStatus?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonTransactionMaxFields = {
  __typename?: 'coachCustomerLessonTransaction_max_fields';
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  coachCustomerLessonOneTimeChargeCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  referenceNo?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionMaxOrderBy = {
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripeInvoiceUrl?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  transactionStatus?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonTransactionMinFields = {
  __typename?: 'coachCustomerLessonTransaction_min_fields';
  coachCustomerLessonCuid?: Maybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: Maybe<Scalars['String']>;
  coachCustomerLessonOneTimeChargeCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  referenceNo?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionMinOrderBy = {
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripeInvoiceUrl?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  transactionStatus?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionMutationResponse = {
  __typename?: 'coachCustomerLessonTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLessonTransaction>;
};

/** on_conflict condition type for table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionOnConflict = {
  constraint: CoachCustomerLessonTransactionConstraint;
  update_columns?: Array<CoachCustomerLessonTransactionUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLessonTransaction". */
export type CoachCustomerLessonTransactionOrderBy = {
  coachCustomerLesson?: InputMaybe<CoachCustomerLessonOrderBy>;
  coachCustomerLessonCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateOrderBy>;
  coachCustomerLessonDateCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonOneTimeCharge?: InputMaybe<CoachCustomerLessonOneTimeChargeOrderBy>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripeInvoiceUrl?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  transactionStatus?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLessonTransaction */
export type CoachCustomerLessonTransactionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerLessonTransaction" */
export enum CoachCustomerLessonTransactionSelectColumn {
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CoachCustomerLessonOneTimeChargeCuid = 'coachCustomerLessonOneTimeChargeCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripeInvoiceUrl = 'stripeInvoiceUrl',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  TransactionStatus = 'transactionStatus',
  /** column name */
  TransactionType = 'transactionType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionSetInput = {
  coachCustomerLessonCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDateCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonOneTimeChargeCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripeInvoiceUrl?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  transactionStatus?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonTransactionStddevFields = {
  __typename?: 'coachCustomerLessonTransaction_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonTransactionStddevPopFields = {
  __typename?: 'coachCustomerLessonTransaction_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonTransactionStddevSampFields = {
  __typename?: 'coachCustomerLessonTransaction_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonTransactionSumFields = {
  __typename?: 'coachCustomerLessonTransaction_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLessonTransaction" */
export enum CoachCustomerLessonTransactionUpdateColumn {
  /** column name */
  CoachCustomerLessonCuid = 'coachCustomerLessonCuid',
  /** column name */
  CoachCustomerLessonDateCuid = 'coachCustomerLessonDateCuid',
  /** column name */
  CoachCustomerLessonOneTimeChargeCuid = 'coachCustomerLessonOneTimeChargeCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripeInvoiceUrl = 'stripeInvoiceUrl',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  TransactionStatus = 'transactionStatus',
  /** column name */
  TransactionType = 'transactionType',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonTransactionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonTransactionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonTransactionSetInput>;
  where: CoachCustomerLessonTransactionBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonTransactionVarPopFields = {
  __typename?: 'coachCustomerLessonTransaction_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonTransactionVarSampFields = {
  __typename?: 'coachCustomerLessonTransaction_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonTransactionVarianceFields = {
  __typename?: 'coachCustomerLessonTransaction_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLessonTransaction" */
export type CoachCustomerLessonTransactionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "coachCustomerLesson" */
export type CoachCustomerLessonAggregate = {
  __typename?: 'coachCustomerLesson_aggregate';
  aggregate?: Maybe<CoachCustomerLessonAggregateFields>;
  nodes: Array<CoachCustomerLesson>;
};

export type CoachCustomerLessonAggregateBoolExp = {
  bool_and?: InputMaybe<CoachCustomerLessonAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachCustomerLessonAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachCustomerLessonAggregateBoolExpCount>;
};

export type CoachCustomerLessonAggregateBoolExpBoolAnd = {
  arguments: CoachCustomerLessonSelectColumnCoachCustomerLessonAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachCustomerLessonAggregateBoolExpBoolOr = {
  arguments: CoachCustomerLessonSelectColumnCoachCustomerLessonAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachCustomerLessonAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerLessonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerLesson" */
export type CoachCustomerLessonAggregateFields = {
  __typename?: 'coachCustomerLesson_aggregate_fields';
  avg?: Maybe<CoachCustomerLessonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerLessonMaxFields>;
  min?: Maybe<CoachCustomerLessonMinFields>;
  stddev?: Maybe<CoachCustomerLessonStddevFields>;
  stddev_pop?: Maybe<CoachCustomerLessonStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerLessonStddevSampFields>;
  sum?: Maybe<CoachCustomerLessonSumFields>;
  var_pop?: Maybe<CoachCustomerLessonVarPopFields>;
  var_samp?: Maybe<CoachCustomerLessonVarSampFields>;
  variance?: Maybe<CoachCustomerLessonVarianceFields>;
};


/** aggregate fields of "coachCustomerLesson" */
export type CoachCustomerLessonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerLesson" */
export type CoachCustomerLessonAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerLessonAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerLessonMaxOrderBy>;
  min?: InputMaybe<CoachCustomerLessonMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerLessonStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerLessonStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerLessonStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerLessonSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerLessonVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerLessonVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerLessonVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CoachCustomerLessonAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coachCustomerLesson" */
export type CoachCustomerLessonArrRelInsertInput = {
  data: Array<CoachCustomerLessonInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerLessonAvgFields = {
  __typename?: 'coachCustomerLesson_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerLesson". All fields are combined with a logical 'AND'. */
export type CoachCustomerLessonBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerLessonBoolExp>>;
  _not?: InputMaybe<CoachCustomerLessonBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerLessonBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  cancellationReason?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  coachCustomer?: InputMaybe<CoachCustomerBoolExp>;
  coachCustomerCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessonDates?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  coachCustomerLessonDates_aggregate?: InputMaybe<CoachCustomerLessonDateAggregateBoolExp>;
  coachCustomerLessonPauseResumes?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
  coachCustomerLessonPauseResumes_aggregate?: InputMaybe<CoachCustomerLessonPauseResumeAggregateBoolExp>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateBoolExp>;
  coachLesson?: InputMaybe<CoachLessonBoolExp>;
  coachLessonCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<StringComparisonExp>;
  disciplineNames?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isVirtual?: InputMaybe<BooleanComparisonExp>;
  offerCanceledDate?: InputMaybe<TimestamptzComparisonExp>;
  offerMessageId?: InputMaybe<StringComparisonExp>;
  offerRejectedDate?: InputMaybe<TimestamptzComparisonExp>;
  paidDate?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  recurrenceType?: InputMaybe<StringComparisonExp>;
  recurringInterval?: InputMaybe<StringComparisonExp>;
  resumeRecurringLessonTriggerId?: InputMaybe<StringComparisonExp>;
  skills?: InputMaybe<JsonbComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeSubscriptionId?: InputMaybe<StringComparisonExp>;
  subscriptionCanceledDate?: InputMaybe<TimestamptzComparisonExp>;
  subscriptionPausedUntil?: InputMaybe<TimestamptzComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerLesson" */
export enum CoachCustomerLessonConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerLessonCuidKey = 'coachCustomerLesson_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerLessonPkey = 'coachCustomerLesson_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CoachCustomerLessonDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  disciplineNames?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CoachCustomerLessonDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  disciplineNames?: InputMaybe<Scalars['Int']>;
  skills?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CoachCustomerLessonDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "coachCustomerLesson" */
export type CoachCustomerLessonIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachCustomerLesson" */
export type CoachCustomerLessonInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachCustomer?: InputMaybe<CoachCustomerObjRelInsertInput>;
  coachCustomerCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessonDates?: InputMaybe<CoachCustomerLessonDateArrRelInsertInput>;
  coachCustomerLessonPauseResumes?: InputMaybe<CoachCustomerLessonPauseResumeArrRelInsertInput>;
  coachCustomerLessonTransactions?: InputMaybe<CoachCustomerLessonTransactionArrRelInsertInput>;
  coachLesson?: InputMaybe<CoachLessonObjRelInsertInput>;
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  offerCanceledDate?: InputMaybe<Scalars['timestamptz']>;
  offerMessageId?: InputMaybe<Scalars['String']>;
  offerRejectedDate?: InputMaybe<Scalars['timestamptz']>;
  paidDate?: InputMaybe<Scalars['timestamptz']>;
  price?: InputMaybe<Scalars['Int']>;
  recurrenceType?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  resumeRecurringLessonTriggerId?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionCanceledDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionPausedUntil?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerLessonMaxFields = {
  __typename?: 'coachCustomerLesson_max_fields';
  cancellationReason?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  coachCustomerCuid?: Maybe<Scalars['String']>;
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  offerCanceledDate?: Maybe<Scalars['timestamptz']>;
  offerMessageId?: Maybe<Scalars['String']>;
  offerRejectedDate?: Maybe<Scalars['timestamptz']>;
  paidDate?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  recurrenceType?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Scalars['String']>;
  resumeRecurringLessonTriggerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionCanceledDate?: Maybe<Scalars['timestamptz']>;
  subscriptionPausedUntil?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonMaxOrderBy = {
  cancellationReason?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  offerCanceledDate?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  offerRejectedDate?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  resumeRecurringLessonTriggerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  subscriptionCanceledDate?: InputMaybe<OrderBy>;
  subscriptionPausedUntil?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerLessonMinFields = {
  __typename?: 'coachCustomerLesson_min_fields';
  cancellationReason?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  coachCustomerCuid?: Maybe<Scalars['String']>;
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  offerCanceledDate?: Maybe<Scalars['timestamptz']>;
  offerMessageId?: Maybe<Scalars['String']>;
  offerRejectedDate?: Maybe<Scalars['timestamptz']>;
  paidDate?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  recurrenceType?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Scalars['String']>;
  resumeRecurringLessonTriggerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionCanceledDate?: Maybe<Scalars['timestamptz']>;
  subscriptionPausedUntil?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonMinOrderBy = {
  cancellationReason?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  offerCanceledDate?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  offerRejectedDate?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  resumeRecurringLessonTriggerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  subscriptionCanceledDate?: InputMaybe<OrderBy>;
  subscriptionPausedUntil?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerLesson" */
export type CoachCustomerLessonMutationResponse = {
  __typename?: 'coachCustomerLesson_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerLesson>;
};

/** input type for inserting object relation for remote table "coachCustomerLesson" */
export type CoachCustomerLessonObjRelInsertInput = {
  data: CoachCustomerLessonInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerLessonOnConflict>;
};

/** on_conflict condition type for table "coachCustomerLesson" */
export type CoachCustomerLessonOnConflict = {
  constraint: CoachCustomerLessonConstraint;
  update_columns?: Array<CoachCustomerLessonUpdateColumn>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerLesson". */
export type CoachCustomerLessonOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomer?: InputMaybe<CoachCustomerOrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  coachCustomerLessonDates_aggregate?: InputMaybe<CoachCustomerLessonDateAggregateOrderBy>;
  coachCustomerLessonPauseResumes_aggregate?: InputMaybe<CoachCustomerLessonPauseResumeAggregateOrderBy>;
  coachCustomerLessonTransactions_aggregate?: InputMaybe<CoachCustomerLessonTransactionAggregateOrderBy>;
  coachLesson?: InputMaybe<CoachLessonOrderBy>;
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  disciplineNames?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isVirtual?: InputMaybe<OrderBy>;
  offerCanceledDate?: InputMaybe<OrderBy>;
  offerMessageId?: InputMaybe<OrderBy>;
  offerRejectedDate?: InputMaybe<OrderBy>;
  paidDate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  resumeRecurringLessonTriggerId?: InputMaybe<OrderBy>;
  skills?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  subscriptionCanceledDate?: InputMaybe<OrderBy>;
  subscriptionPausedUntil?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerLesson */
export type CoachCustomerLessonPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoachCustomerLessonPrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coachCustomerLesson" */
export enum CoachCustomerLessonSelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CoachCustomerCuid = 'coachCustomerCuid',
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisciplineNames = 'disciplineNames',
  /** column name */
  Id = 'id',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  OfferCanceledDate = 'offerCanceledDate',
  /** column name */
  OfferMessageId = 'offerMessageId',
  /** column name */
  OfferRejectedDate = 'offerRejectedDate',
  /** column name */
  PaidDate = 'paidDate',
  /** column name */
  Price = 'price',
  /** column name */
  RecurrenceType = 'recurrenceType',
  /** column name */
  RecurringInterval = 'recurringInterval',
  /** column name */
  ResumeRecurringLessonTriggerId = 'resumeRecurringLessonTriggerId',
  /** column name */
  Skills = 'skills',
  /** column name */
  Status = 'status',
  /** column name */
  StripeSubscriptionId = 'stripeSubscriptionId',
  /** column name */
  SubscriptionCanceledDate = 'subscriptionCanceledDate',
  /** column name */
  SubscriptionPausedUntil = 'subscriptionPausedUntil',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "coachCustomerLesson_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coachCustomerLesson" */
export enum CoachCustomerLessonSelectColumnCoachCustomerLessonAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsVirtual = 'isVirtual'
}

/** select "coachCustomerLesson_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coachCustomerLesson" */
export enum CoachCustomerLessonSelectColumnCoachCustomerLessonAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsVirtual = 'isVirtual'
}

/** input type for updating data in table "coachCustomerLesson" */
export type CoachCustomerLessonSetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachCustomerCuid?: InputMaybe<Scalars['String']>;
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  offerCanceledDate?: InputMaybe<Scalars['timestamptz']>;
  offerMessageId?: InputMaybe<Scalars['String']>;
  offerRejectedDate?: InputMaybe<Scalars['timestamptz']>;
  paidDate?: InputMaybe<Scalars['timestamptz']>;
  price?: InputMaybe<Scalars['Int']>;
  recurrenceType?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  resumeRecurringLessonTriggerId?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionCanceledDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionPausedUntil?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerLessonStddevFields = {
  __typename?: 'coachCustomerLesson_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerLessonStddevPopFields = {
  __typename?: 'coachCustomerLesson_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerLessonStddevSampFields = {
  __typename?: 'coachCustomerLesson_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerLessonSumFields = {
  __typename?: 'coachCustomerLesson_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerLesson" */
export enum CoachCustomerLessonUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CoachCustomerCuid = 'coachCustomerCuid',
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisciplineNames = 'disciplineNames',
  /** column name */
  Id = 'id',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  OfferCanceledDate = 'offerCanceledDate',
  /** column name */
  OfferMessageId = 'offerMessageId',
  /** column name */
  OfferRejectedDate = 'offerRejectedDate',
  /** column name */
  PaidDate = 'paidDate',
  /** column name */
  Price = 'price',
  /** column name */
  RecurrenceType = 'recurrenceType',
  /** column name */
  RecurringInterval = 'recurringInterval',
  /** column name */
  ResumeRecurringLessonTriggerId = 'resumeRecurringLessonTriggerId',
  /** column name */
  Skills = 'skills',
  /** column name */
  Status = 'status',
  /** column name */
  StripeSubscriptionId = 'stripeSubscriptionId',
  /** column name */
  SubscriptionCanceledDate = 'subscriptionCanceledDate',
  /** column name */
  SubscriptionPausedUntil = 'subscriptionPausedUntil',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerLessonUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CoachCustomerLessonAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CoachCustomerLessonDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CoachCustomerLessonDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CoachCustomerLessonDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerLessonIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoachCustomerLessonPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerLessonSetInput>;
  where: CoachCustomerLessonBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerLessonVarPopFields = {
  __typename?: 'coachCustomerLesson_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerLessonVarSampFields = {
  __typename?: 'coachCustomerLesson_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerLessonVarianceFields = {
  __typename?: 'coachCustomerLesson_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerLesson" */
export type CoachCustomerLessonVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** The message that the customer sent the coach when they requested a lesson */
export type CoachCustomerMessageRequest = Node & {
  __typename?: 'coachCustomerMessageRequest';
  /** An object relationship */
  coachCustomer: CoachCustomer;
  coachCustomerChannelId: Scalars['String'];
  /** An object relationship */
  coachCustomerUser: CoachCustomerUser;
  coachCustomerUserCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  streamMessageId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestConnection = {
  __typename?: 'coachCustomerMessageRequestConnection';
  edges: Array<CoachCustomerMessageRequestEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerMessageRequestEdge = {
  __typename?: 'coachCustomerMessageRequestEdge';
  cursor: Scalars['String'];
  node: CoachCustomerMessageRequest;
};

/** aggregated selection of "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestAggregate = {
  __typename?: 'coachCustomerMessageRequest_aggregate';
  aggregate?: Maybe<CoachCustomerMessageRequestAggregateFields>;
  nodes: Array<CoachCustomerMessageRequest>;
};

export type CoachCustomerMessageRequestAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerMessageRequestAggregateBoolExpCount>;
};

export type CoachCustomerMessageRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestAggregateFields = {
  __typename?: 'coachCustomerMessageRequest_aggregate_fields';
  avg?: Maybe<CoachCustomerMessageRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerMessageRequestMaxFields>;
  min?: Maybe<CoachCustomerMessageRequestMinFields>;
  stddev?: Maybe<CoachCustomerMessageRequestStddevFields>;
  stddev_pop?: Maybe<CoachCustomerMessageRequestStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerMessageRequestStddevSampFields>;
  sum?: Maybe<CoachCustomerMessageRequestSumFields>;
  var_pop?: Maybe<CoachCustomerMessageRequestVarPopFields>;
  var_samp?: Maybe<CoachCustomerMessageRequestVarSampFields>;
  variance?: Maybe<CoachCustomerMessageRequestVarianceFields>;
};


/** aggregate fields of "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerMessageRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerMessageRequestMaxOrderBy>;
  min?: InputMaybe<CoachCustomerMessageRequestMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerMessageRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerMessageRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerMessageRequestStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerMessageRequestSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerMessageRequestVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerMessageRequestVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerMessageRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestArrRelInsertInput = {
  data: Array<CoachCustomerMessageRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerMessageRequestOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerMessageRequestAvgFields = {
  __typename?: 'coachCustomerMessageRequest_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerMessageRequest". All fields are combined with a logical 'AND'. */
export type CoachCustomerMessageRequestBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerMessageRequestBoolExp>>;
  _not?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerMessageRequestBoolExp>>;
  coachCustomer?: InputMaybe<CoachCustomerBoolExp>;
  coachCustomerChannelId?: InputMaybe<StringComparisonExp>;
  coachCustomerUser?: InputMaybe<CoachCustomerUserBoolExp>;
  coachCustomerUserCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  message?: InputMaybe<StringComparisonExp>;
  streamMessageId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerMessageRequest" */
export enum CoachCustomerMessageRequestConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerMessageRequestCuidKey = 'coachCustomerMessageRequest_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerMessageRequestPkey = 'coachCustomerMessageRequest_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestInsertInput = {
  coachCustomer?: InputMaybe<CoachCustomerObjRelInsertInput>;
  coachCustomerChannelId?: InputMaybe<Scalars['String']>;
  coachCustomerUser?: InputMaybe<CoachCustomerUserObjRelInsertInput>;
  coachCustomerUserCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  streamMessageId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerMessageRequestMaxFields = {
  __typename?: 'coachCustomerMessageRequest_max_fields';
  coachCustomerChannelId?: Maybe<Scalars['String']>;
  coachCustomerUserCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  streamMessageId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestMaxOrderBy = {
  coachCustomerChannelId?: InputMaybe<OrderBy>;
  coachCustomerUserCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  streamMessageId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerMessageRequestMinFields = {
  __typename?: 'coachCustomerMessageRequest_min_fields';
  coachCustomerChannelId?: Maybe<Scalars['String']>;
  coachCustomerUserCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  streamMessageId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestMinOrderBy = {
  coachCustomerChannelId?: InputMaybe<OrderBy>;
  coachCustomerUserCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  streamMessageId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestMutationResponse = {
  __typename?: 'coachCustomerMessageRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerMessageRequest>;
};

/** on_conflict condition type for table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestOnConflict = {
  constraint: CoachCustomerMessageRequestConstraint;
  update_columns?: Array<CoachCustomerMessageRequestUpdateColumn>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerMessageRequest". */
export type CoachCustomerMessageRequestOrderBy = {
  coachCustomer?: InputMaybe<CoachCustomerOrderBy>;
  coachCustomerChannelId?: InputMaybe<OrderBy>;
  coachCustomerUser?: InputMaybe<CoachCustomerUserOrderBy>;
  coachCustomerUserCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  streamMessageId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerMessageRequest */
export type CoachCustomerMessageRequestPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerMessageRequest" */
export enum CoachCustomerMessageRequestSelectColumn {
  /** column name */
  CoachCustomerChannelId = 'coachCustomerChannelId',
  /** column name */
  CoachCustomerUserCuid = 'coachCustomerUserCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  StreamMessageId = 'streamMessageId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestSetInput = {
  coachCustomerChannelId?: InputMaybe<Scalars['String']>;
  coachCustomerUserCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  message?: InputMaybe<Scalars['String']>;
  streamMessageId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerMessageRequestStddevFields = {
  __typename?: 'coachCustomerMessageRequest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerMessageRequestStddevPopFields = {
  __typename?: 'coachCustomerMessageRequest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerMessageRequestStddevSampFields = {
  __typename?: 'coachCustomerMessageRequest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerMessageRequestSumFields = {
  __typename?: 'coachCustomerMessageRequest_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerMessageRequest" */
export enum CoachCustomerMessageRequestUpdateColumn {
  /** column name */
  CoachCustomerChannelId = 'coachCustomerChannelId',
  /** column name */
  CoachCustomerUserCuid = 'coachCustomerUserCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  StreamMessageId = 'streamMessageId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerMessageRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerMessageRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerMessageRequestSetInput>;
  where: CoachCustomerMessageRequestBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerMessageRequestVarPopFields = {
  __typename?: 'coachCustomerMessageRequest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerMessageRequestVarSampFields = {
  __typename?: 'coachCustomerMessageRequest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerMessageRequestVarianceFields = {
  __typename?: 'coachCustomerMessageRequest_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerMessageRequest" */
export type CoachCustomerMessageRequestVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Prrivate lesson group */
export type CoachCustomerUser = Node & {
  __typename?: 'coachCustomerUser';
  age?: Maybe<Scalars['Int']>;
  /** An object relationship */
  coachCustomer: CoachCustomer;
  coachCustomerCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerMessageRequests: Array<CoachCustomerMessageRequest>;
  /** An aggregate relationship */
  coachCustomerMessageRequests_aggregate: CoachCustomerMessageRequestAggregate;
  /** An array relationship connection */
  coachCustomerMessageRequests_connection: CoachCustomerMessageRequestConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteStatus: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


/** Prrivate lesson group */
export type CoachCustomerUserCoachCustomerMessageRequestsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


/** Prrivate lesson group */
export type CoachCustomerUserCoachCustomerMessageRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


/** Prrivate lesson group */
export type CoachCustomerUserCoachCustomerMessageRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};

/** A Relay connection object on "coachCustomerUser" */
export type CoachCustomerUserConnection = {
  __typename?: 'coachCustomerUserConnection';
  edges: Array<CoachCustomerUserEdge>;
  pageInfo: PageInfo;
};

export type CoachCustomerUserEdge = {
  __typename?: 'coachCustomerUserEdge';
  cursor: Scalars['String'];
  node: CoachCustomerUser;
};

/** aggregated selection of "coachCustomerUser" */
export type CoachCustomerUserAggregate = {
  __typename?: 'coachCustomerUser_aggregate';
  aggregate?: Maybe<CoachCustomerUserAggregateFields>;
  nodes: Array<CoachCustomerUser>;
};

export type CoachCustomerUserAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerUserAggregateBoolExpCount>;
};

export type CoachCustomerUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerUserBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomerUser" */
export type CoachCustomerUserAggregateFields = {
  __typename?: 'coachCustomerUser_aggregate_fields';
  avg?: Maybe<CoachCustomerUserAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerUserMaxFields>;
  min?: Maybe<CoachCustomerUserMinFields>;
  stddev?: Maybe<CoachCustomerUserStddevFields>;
  stddev_pop?: Maybe<CoachCustomerUserStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerUserStddevSampFields>;
  sum?: Maybe<CoachCustomerUserSumFields>;
  var_pop?: Maybe<CoachCustomerUserVarPopFields>;
  var_samp?: Maybe<CoachCustomerUserVarSampFields>;
  variance?: Maybe<CoachCustomerUserVarianceFields>;
};


/** aggregate fields of "coachCustomerUser" */
export type CoachCustomerUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomerUser" */
export type CoachCustomerUserAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerUserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerUserMaxOrderBy>;
  min?: InputMaybe<CoachCustomerUserMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerUserStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerUserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerUserStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerUserSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerUserVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerUserVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerUserVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomerUser" */
export type CoachCustomerUserArrRelInsertInput = {
  data: Array<CoachCustomerUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerUserOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerUserAvgFields = {
  __typename?: 'coachCustomerUser_avg_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomerUser" */
export type CoachCustomerUserAvgOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomerUser". All fields are combined with a logical 'AND'. */
export type CoachCustomerUserBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerUserBoolExp>>;
  _not?: InputMaybe<CoachCustomerUserBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerUserBoolExp>>;
  age?: InputMaybe<IntComparisonExp>;
  coachCustomer?: InputMaybe<CoachCustomerBoolExp>;
  coachCustomerCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerMessageRequests?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
  coachCustomerMessageRequests_aggregate?: InputMaybe<CoachCustomerMessageRequestAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inviteStatus?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomerUser" */
export enum CoachCustomerUserConstraint {
  /** unique or primary key constraint on columns "coachCustomerCuid", "userId" */
  CoachCustomerUserCoachCustomerCuidUserIdKey = 'coachCustomerUser_coachCustomerCuid_userId_key',
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerUserCuidKey = 'coachCustomerUser_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerUserPkey = 'coachCustomerUser_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomerUser" */
export type CoachCustomerUserIncInput = {
  age?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomerUser" */
export type CoachCustomerUserInsertInput = {
  age?: InputMaybe<Scalars['Int']>;
  coachCustomer?: InputMaybe<CoachCustomerObjRelInsertInput>;
  coachCustomerCuid?: InputMaybe<Scalars['String']>;
  coachCustomerMessageRequests?: InputMaybe<CoachCustomerMessageRequestArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CoachCustomerUserMaxFields = {
  __typename?: 'coachCustomerUser_max_fields';
  age?: Maybe<Scalars['Int']>;
  coachCustomerCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteStatus?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coachCustomerUser" */
export type CoachCustomerUserMaxOrderBy = {
  age?: InputMaybe<OrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerUserMinFields = {
  __typename?: 'coachCustomerUser_min_fields';
  age?: Maybe<Scalars['Int']>;
  coachCustomerCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteStatus?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coachCustomerUser" */
export type CoachCustomerUserMinOrderBy = {
  age?: InputMaybe<OrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomerUser" */
export type CoachCustomerUserMutationResponse = {
  __typename?: 'coachCustomerUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomerUser>;
};

/** input type for inserting object relation for remote table "coachCustomerUser" */
export type CoachCustomerUserObjRelInsertInput = {
  data: CoachCustomerUserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerUserOnConflict>;
};

/** on_conflict condition type for table "coachCustomerUser" */
export type CoachCustomerUserOnConflict = {
  constraint: CoachCustomerUserConstraint;
  update_columns?: Array<CoachCustomerUserUpdateColumn>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};

/** Ordering options when selecting data from "coachCustomerUser". */
export type CoachCustomerUserOrderBy = {
  age?: InputMaybe<OrderBy>;
  coachCustomer?: InputMaybe<CoachCustomerOrderBy>;
  coachCustomerCuid?: InputMaybe<OrderBy>;
  coachCustomerMessageRequests_aggregate?: InputMaybe<CoachCustomerMessageRequestAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomerUser */
export type CoachCustomerUserPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomerUser" */
export enum CoachCustomerUserSelectColumn {
  /** column name */
  Age = 'age',
  /** column name */
  CoachCustomerCuid = 'coachCustomerCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'inviteStatus',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "coachCustomerUser" */
export type CoachCustomerUserSetInput = {
  age?: InputMaybe<Scalars['Int']>;
  coachCustomerCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CoachCustomerUserStddevFields = {
  __typename?: 'coachCustomerUser_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomerUser" */
export type CoachCustomerUserStddevOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerUserStddevPopFields = {
  __typename?: 'coachCustomerUser_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomerUser" */
export type CoachCustomerUserStddevPopOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerUserStddevSampFields = {
  __typename?: 'coachCustomerUser_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomerUser" */
export type CoachCustomerUserStddevSampOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerUserSumFields = {
  __typename?: 'coachCustomerUser_sum_fields';
  age?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomerUser" */
export type CoachCustomerUserSumOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomerUser" */
export enum CoachCustomerUserUpdateColumn {
  /** column name */
  Age = 'age',
  /** column name */
  CoachCustomerCuid = 'coachCustomerCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'inviteStatus',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type CoachCustomerUserUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerUserIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerUserSetInput>;
  where: CoachCustomerUserBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerUserVarPopFields = {
  __typename?: 'coachCustomerUser_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomerUser" */
export type CoachCustomerUserVarPopOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerUserVarSampFields = {
  __typename?: 'coachCustomerUser_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomerUser" */
export type CoachCustomerUserVarSampOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerUserVarianceFields = {
  __typename?: 'coachCustomerUser_variance_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomerUser" */
export type CoachCustomerUserVarianceOrderBy = {
  age?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "coachCustomer" */
export type CoachCustomerAggregate = {
  __typename?: 'coachCustomer_aggregate';
  aggregate?: Maybe<CoachCustomerAggregateFields>;
  nodes: Array<CoachCustomer>;
};

export type CoachCustomerAggregateBoolExp = {
  count?: InputMaybe<CoachCustomerAggregateBoolExpCount>;
};

export type CoachCustomerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachCustomerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachCustomer" */
export type CoachCustomerAggregateFields = {
  __typename?: 'coachCustomer_aggregate_fields';
  avg?: Maybe<CoachCustomerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachCustomerMaxFields>;
  min?: Maybe<CoachCustomerMinFields>;
  stddev?: Maybe<CoachCustomerStddevFields>;
  stddev_pop?: Maybe<CoachCustomerStddevPopFields>;
  stddev_samp?: Maybe<CoachCustomerStddevSampFields>;
  sum?: Maybe<CoachCustomerSumFields>;
  var_pop?: Maybe<CoachCustomerVarPopFields>;
  var_samp?: Maybe<CoachCustomerVarSampFields>;
  variance?: Maybe<CoachCustomerVarianceFields>;
};


/** aggregate fields of "coachCustomer" */
export type CoachCustomerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachCustomer" */
export type CoachCustomerAggregateOrderBy = {
  avg?: InputMaybe<CoachCustomerAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachCustomerMaxOrderBy>;
  min?: InputMaybe<CoachCustomerMinOrderBy>;
  stddev?: InputMaybe<CoachCustomerStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachCustomerStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachCustomerStddevSampOrderBy>;
  sum?: InputMaybe<CoachCustomerSumOrderBy>;
  var_pop?: InputMaybe<CoachCustomerVarPopOrderBy>;
  var_samp?: InputMaybe<CoachCustomerVarSampOrderBy>;
  variance?: InputMaybe<CoachCustomerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachCustomer" */
export type CoachCustomerArrRelInsertInput = {
  data: Array<CoachCustomerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerOnConflict>;
};

/** aggregate avg on columns */
export type CoachCustomerAvgFields = {
  __typename?: 'coachCustomer_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachCustomer" */
export type CoachCustomerAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachCustomer". All fields are combined with a logical 'AND'. */
export type CoachCustomerBoolExp = {
  _and?: InputMaybe<Array<CoachCustomerBoolExp>>;
  _not?: InputMaybe<CoachCustomerBoolExp>;
  _or?: InputMaybe<Array<CoachCustomerBoolExp>>;
  channelId?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateBoolExp>;
  coachCustomerMessageRequests?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
  coachCustomerMessageRequests_aggregate?: InputMaybe<CoachCustomerMessageRequestAggregateBoolExp>;
  coachCustomerUsers?: InputMaybe<CoachCustomerUserBoolExp>;
  coachCustomerUsers_aggregate?: InputMaybe<CoachCustomerUserAggregateBoolExp>;
  coachLesson?: InputMaybe<CoachLessonBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  requestedCoachLessonCuid?: InputMaybe<StringComparisonExp>;
  requestedStartTime?: InputMaybe<TimestampComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachCustomer" */
export enum CoachCustomerConstraint {
  /** unique or primary key constraint on columns "channelId" */
  CoachCustomerChannelIdKey = 'coachCustomer_channelId_key',
  /** unique or primary key constraint on columns "cuid" */
  CoachCustomerCuidKey = 'coachCustomer_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachCustomerPkey = 'coachCustomer_pkey'
}

/** input type for incrementing numeric columns in table "coachCustomer" */
export type CoachCustomerIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachCustomer" */
export type CoachCustomerInsertInput = {
  channelId?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonArrRelInsertInput>;
  coachCustomerMessageRequests?: InputMaybe<CoachCustomerMessageRequestArrRelInsertInput>;
  coachCustomerUsers?: InputMaybe<CoachCustomerUserArrRelInsertInput>;
  coachLesson?: InputMaybe<CoachLessonObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  requestedCoachLessonCuid?: InputMaybe<Scalars['String']>;
  requestedStartTime?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachCustomerMaxFields = {
  __typename?: 'coachCustomer_max_fields';
  channelId?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  requestedCoachLessonCuid?: Maybe<Scalars['String']>;
  requestedStartTime?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachCustomer" */
export type CoachCustomerMaxOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  requestedCoachLessonCuid?: InputMaybe<OrderBy>;
  requestedStartTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachCustomerMinFields = {
  __typename?: 'coachCustomer_min_fields';
  channelId?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  requestedCoachLessonCuid?: Maybe<Scalars['String']>;
  requestedStartTime?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachCustomer" */
export type CoachCustomerMinOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  requestedCoachLessonCuid?: InputMaybe<OrderBy>;
  requestedStartTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachCustomer" */
export type CoachCustomerMutationResponse = {
  __typename?: 'coachCustomer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachCustomer>;
};

/** input type for inserting object relation for remote table "coachCustomer" */
export type CoachCustomerObjRelInsertInput = {
  data: CoachCustomerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachCustomerOnConflict>;
};

/** on_conflict condition type for table "coachCustomer" */
export type CoachCustomerOnConflict = {
  constraint: CoachCustomerConstraint;
  update_columns?: Array<CoachCustomerUpdateColumn>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};

/** Ordering options when selecting data from "coachCustomer". */
export type CoachCustomerOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateOrderBy>;
  coachCustomerMessageRequests_aggregate?: InputMaybe<CoachCustomerMessageRequestAggregateOrderBy>;
  coachCustomerUsers_aggregate?: InputMaybe<CoachCustomerUserAggregateOrderBy>;
  coachLesson?: InputMaybe<CoachLessonOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  requestedCoachLessonCuid?: InputMaybe<OrderBy>;
  requestedStartTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachCustomer */
export type CoachCustomerPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachCustomer" */
export enum CoachCustomerSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  RequestedCoachLessonCuid = 'requestedCoachLessonCuid',
  /** column name */
  RequestedStartTime = 'requestedStartTime',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachCustomer" */
export type CoachCustomerSetInput = {
  channelId?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  notes?: InputMaybe<Scalars['String']>;
  requestedCoachLessonCuid?: InputMaybe<Scalars['String']>;
  requestedStartTime?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachCustomerStddevFields = {
  __typename?: 'coachCustomer_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachCustomer" */
export type CoachCustomerStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachCustomerStddevPopFields = {
  __typename?: 'coachCustomer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachCustomer" */
export type CoachCustomerStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachCustomerStddevSampFields = {
  __typename?: 'coachCustomer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachCustomer" */
export type CoachCustomerStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachCustomerSumFields = {
  __typename?: 'coachCustomer_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachCustomer" */
export type CoachCustomerSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachCustomer" */
export enum CoachCustomerUpdateColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  RequestedCoachLessonCuid = 'requestedCoachLessonCuid',
  /** column name */
  RequestedStartTime = 'requestedStartTime',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachCustomerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachCustomerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachCustomerSetInput>;
  where: CoachCustomerBoolExp;
};

/** aggregate var_pop on columns */
export type CoachCustomerVarPopFields = {
  __typename?: 'coachCustomer_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachCustomer" */
export type CoachCustomerVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachCustomerVarSampFields = {
  __typename?: 'coachCustomer_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachCustomer" */
export type CoachCustomerVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachCustomerVarianceFields = {
  __typename?: 'coachCustomer_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachCustomer" */
export type CoachCustomerVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A discipline that a coach teaches */
export type CoachDiscipline = Node & {
  __typename?: 'coachDiscipline';
  abilityLevelRange: Scalars['jsonb'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  skills: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** A discipline that a coach teaches */
export type CoachDisciplineAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A discipline that a coach teaches */
export type CoachDisciplineSkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "coachDiscipline" */
export type CoachDisciplineConnection = {
  __typename?: 'coachDisciplineConnection';
  edges: Array<CoachDisciplineEdge>;
  pageInfo: PageInfo;
};

export type CoachDisciplineEdge = {
  __typename?: 'coachDisciplineEdge';
  cursor: Scalars['String'];
  node: CoachDiscipline;
};

/** aggregated selection of "coachDiscipline" */
export type CoachDisciplineAggregate = {
  __typename?: 'coachDiscipline_aggregate';
  aggregate?: Maybe<CoachDisciplineAggregateFields>;
  nodes: Array<CoachDiscipline>;
};

export type CoachDisciplineAggregateBoolExp = {
  count?: InputMaybe<CoachDisciplineAggregateBoolExpCount>;
};

export type CoachDisciplineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachDisciplineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachDiscipline" */
export type CoachDisciplineAggregateFields = {
  __typename?: 'coachDiscipline_aggregate_fields';
  avg?: Maybe<CoachDisciplineAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachDisciplineMaxFields>;
  min?: Maybe<CoachDisciplineMinFields>;
  stddev?: Maybe<CoachDisciplineStddevFields>;
  stddev_pop?: Maybe<CoachDisciplineStddevPopFields>;
  stddev_samp?: Maybe<CoachDisciplineStddevSampFields>;
  sum?: Maybe<CoachDisciplineSumFields>;
  var_pop?: Maybe<CoachDisciplineVarPopFields>;
  var_samp?: Maybe<CoachDisciplineVarSampFields>;
  variance?: Maybe<CoachDisciplineVarianceFields>;
};


/** aggregate fields of "coachDiscipline" */
export type CoachDisciplineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachDiscipline" */
export type CoachDisciplineAggregateOrderBy = {
  avg?: InputMaybe<CoachDisciplineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachDisciplineMaxOrderBy>;
  min?: InputMaybe<CoachDisciplineMinOrderBy>;
  stddev?: InputMaybe<CoachDisciplineStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachDisciplineStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachDisciplineStddevSampOrderBy>;
  sum?: InputMaybe<CoachDisciplineSumOrderBy>;
  var_pop?: InputMaybe<CoachDisciplineVarPopOrderBy>;
  var_samp?: InputMaybe<CoachDisciplineVarSampOrderBy>;
  variance?: InputMaybe<CoachDisciplineVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CoachDisciplineAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coachDiscipline" */
export type CoachDisciplineArrRelInsertInput = {
  data: Array<CoachDisciplineInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachDisciplineOnConflict>;
};

/** aggregate avg on columns */
export type CoachDisciplineAvgFields = {
  __typename?: 'coachDiscipline_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachDiscipline" */
export type CoachDisciplineAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachDiscipline". All fields are combined with a logical 'AND'. */
export type CoachDisciplineBoolExp = {
  _and?: InputMaybe<Array<CoachDisciplineBoolExp>>;
  _not?: InputMaybe<CoachDisciplineBoolExp>;
  _or?: InputMaybe<Array<CoachDisciplineBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  skills?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachDiscipline" */
export enum CoachDisciplineConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachDisciplineCuidKey = 'coachDiscipline_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachDisciplinePkey = 'coachDiscipline_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CoachDisciplineDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CoachDisciplineDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  skills?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CoachDisciplineDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "coachDiscipline" */
export type CoachDisciplineIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachDiscipline" */
export type CoachDisciplineInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachDisciplineMaxFields = {
  __typename?: 'coachDiscipline_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachDiscipline" */
export type CoachDisciplineMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachDisciplineMinFields = {
  __typename?: 'coachDiscipline_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachDiscipline" */
export type CoachDisciplineMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachDiscipline" */
export type CoachDisciplineMutationResponse = {
  __typename?: 'coachDiscipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachDiscipline>;
};

/** on_conflict condition type for table "coachDiscipline" */
export type CoachDisciplineOnConflict = {
  constraint: CoachDisciplineConstraint;
  update_columns?: Array<CoachDisciplineUpdateColumn>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};

/** Ordering options when selecting data from "coachDiscipline". */
export type CoachDisciplineOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  skills?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachDiscipline */
export type CoachDisciplinePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoachDisciplinePrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coachDiscipline" */
export enum CoachDisciplineSelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Skills = 'skills',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachDiscipline" */
export type CoachDisciplineSetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachDisciplineStddevFields = {
  __typename?: 'coachDiscipline_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachDiscipline" */
export type CoachDisciplineStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachDisciplineStddevPopFields = {
  __typename?: 'coachDiscipline_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachDiscipline" */
export type CoachDisciplineStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachDisciplineStddevSampFields = {
  __typename?: 'coachDiscipline_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachDiscipline" */
export type CoachDisciplineStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachDisciplineSumFields = {
  __typename?: 'coachDiscipline_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachDiscipline" */
export type CoachDisciplineSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachDiscipline" */
export enum CoachDisciplineUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Skills = 'skills',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachDisciplineUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CoachDisciplineAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CoachDisciplineDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CoachDisciplineDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CoachDisciplineDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachDisciplineIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoachDisciplinePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachDisciplineSetInput>;
  where: CoachDisciplineBoolExp;
};

/** aggregate var_pop on columns */
export type CoachDisciplineVarPopFields = {
  __typename?: 'coachDiscipline_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachDiscipline" */
export type CoachDisciplineVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachDisciplineVarSampFields = {
  __typename?: 'coachDiscipline_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachDiscipline" */
export type CoachDisciplineVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachDisciplineVarianceFields = {
  __typename?: 'coachDiscipline_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachDiscipline" */
export type CoachDisciplineVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

export type CoachEdge = {
  __typename?: 'coachEdge';
  cursor: Scalars['String'];
  node: Coach;
};

/** Image for a Coach's profile */
export type CoachImage = Node & {
  __typename?: 'coachImage';
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  imageUser: ImageUser;
  imageUserPublicId: Scalars['String'];
  position: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachImage" */
export type CoachImageConnection = {
  __typename?: 'coachImageConnection';
  edges: Array<CoachImageEdge>;
  pageInfo: PageInfo;
};

export type CoachImageEdge = {
  __typename?: 'coachImageEdge';
  cursor: Scalars['String'];
  node: CoachImage;
};

/** aggregated selection of "coachImage" */
export type CoachImageAggregate = {
  __typename?: 'coachImage_aggregate';
  aggregate?: Maybe<CoachImageAggregateFields>;
  nodes: Array<CoachImage>;
};

export type CoachImageAggregateBoolExp = {
  count?: InputMaybe<CoachImageAggregateBoolExpCount>;
};

export type CoachImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachImage" */
export type CoachImageAggregateFields = {
  __typename?: 'coachImage_aggregate_fields';
  avg?: Maybe<CoachImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachImageMaxFields>;
  min?: Maybe<CoachImageMinFields>;
  stddev?: Maybe<CoachImageStddevFields>;
  stddev_pop?: Maybe<CoachImageStddevPopFields>;
  stddev_samp?: Maybe<CoachImageStddevSampFields>;
  sum?: Maybe<CoachImageSumFields>;
  var_pop?: Maybe<CoachImageVarPopFields>;
  var_samp?: Maybe<CoachImageVarSampFields>;
  variance?: Maybe<CoachImageVarianceFields>;
};


/** aggregate fields of "coachImage" */
export type CoachImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachImage" */
export type CoachImageAggregateOrderBy = {
  avg?: InputMaybe<CoachImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachImageMaxOrderBy>;
  min?: InputMaybe<CoachImageMinOrderBy>;
  stddev?: InputMaybe<CoachImageStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachImageStddevSampOrderBy>;
  sum?: InputMaybe<CoachImageSumOrderBy>;
  var_pop?: InputMaybe<CoachImageVarPopOrderBy>;
  var_samp?: InputMaybe<CoachImageVarSampOrderBy>;
  variance?: InputMaybe<CoachImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachImage" */
export type CoachImageArrRelInsertInput = {
  data: Array<CoachImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachImageOnConflict>;
};

/** aggregate avg on columns */
export type CoachImageAvgFields = {
  __typename?: 'coachImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachImage" */
export type CoachImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachImage". All fields are combined with a logical 'AND'. */
export type CoachImageBoolExp = {
  _and?: InputMaybe<Array<CoachImageBoolExp>>;
  _not?: InputMaybe<CoachImageBoolExp>;
  _or?: InputMaybe<Array<CoachImageBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  imageUser?: InputMaybe<ImageUserBoolExp>;
  imageUserPublicId?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachImage" */
export enum CoachImageConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachImageCuidKey = 'coachImage_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachImagePkey = 'coachImage_pkey'
}

/** input type for incrementing numeric columns in table "coachImage" */
export type CoachImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachImage" */
export type CoachImageInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageUser?: InputMaybe<ImageUserObjRelInsertInput>;
  imageUserPublicId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachImageMaxFields = {
  __typename?: 'coachImage_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imageUserPublicId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachImage" */
export type CoachImageMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUserPublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachImageMinFields = {
  __typename?: 'coachImage_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imageUserPublicId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachImage" */
export type CoachImageMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUserPublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachImage" */
export type CoachImageMutationResponse = {
  __typename?: 'coachImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachImage>;
};

/** on_conflict condition type for table "coachImage" */
export type CoachImageOnConflict = {
  constraint: CoachImageConstraint;
  update_columns?: Array<CoachImageUpdateColumn>;
  where?: InputMaybe<CoachImageBoolExp>;
};

/** Ordering options when selecting data from "coachImage". */
export type CoachImageOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUser?: InputMaybe<ImageUserOrderBy>;
  imageUserPublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachImage */
export type CoachImagePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachImage" */
export enum CoachImageSelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUserPublicId = 'imageUserPublicId',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachImage" */
export type CoachImageSetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageUserPublicId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachImageStddevFields = {
  __typename?: 'coachImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachImage" */
export type CoachImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachImageStddevPopFields = {
  __typename?: 'coachImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachImage" */
export type CoachImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachImageStddevSampFields = {
  __typename?: 'coachImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachImage" */
export type CoachImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachImageSumFields = {
  __typename?: 'coachImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachImage" */
export type CoachImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "coachImage" */
export enum CoachImageUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUserPublicId = 'imageUserPublicId',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachImageSetInput>;
  where: CoachImageBoolExp;
};

/** aggregate var_pop on columns */
export type CoachImageVarPopFields = {
  __typename?: 'coachImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachImage" */
export type CoachImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachImageVarSampFields = {
  __typename?: 'coachImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachImage" */
export type CoachImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachImageVarianceFields = {
  __typename?: 'coachImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachImage" */
export type CoachImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** A lesson that a coach provides to students */
export type CoachLesson = Node & {
  __typename?: 'coachLesson';
  abilityLevelRange: Scalars['jsonb'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  /** An array relationship */
  coachCustomerLessons: Array<CoachCustomerLesson>;
  /** An aggregate relationship */
  coachCustomerLessons_aggregate: CoachCustomerLessonAggregate;
  /** An array relationship connection */
  coachCustomerLessons_connection: CoachCustomerLessonConnection;
  /** An array relationship */
  coachCustomers: Array<CoachCustomer>;
  /** An aggregate relationship */
  coachCustomers_aggregate: CoachCustomerAggregate;
  /** An array relationship connection */
  coachCustomers_connection: CoachCustomerConnection;
  /** An array relationship */
  coachLessonLocations: Array<CoachLessonLocation>;
  /** An aggregate relationship */
  coachLessonLocations_aggregate: CoachLessonLocationAggregate;
  /** An array relationship connection */
  coachLessonLocations_connection: CoachLessonLocationConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disciplineNames: Scalars['jsonb'];
  duration: Scalars['Int'];
  highlightedPosition?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isBundle: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  lessonsPerBundle?: Maybe<Scalars['Int']>;
  participantRange: Scalars['jsonb'];
  position: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  recurrenceType: Scalars['String'];
  recurringInterval?: Maybe<Scalars['String']>;
  requestToBook: Scalars['Boolean'];
  skills: Scalars['jsonb'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userSavedLessons: Array<UserSavedLesson>;
  /** An aggregate relationship */
  userSavedLessons_aggregate: UserSavedLessonAggregate;
  /** An array relationship connection */
  userSavedLessons_connection: UserSavedLessonConnection;
};


/** A lesson that a coach provides to students */
export type CoachLessonAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomerLessonsArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomerLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomerLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomersArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachCustomersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachLessonLocationsArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachLessonLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonCoachLessonLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonDisciplineNamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A lesson that a coach provides to students */
export type CoachLessonParticipantRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A lesson that a coach provides to students */
export type CoachLessonSkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** A lesson that a coach provides to students */
export type CoachLessonUserSavedLessonsArgs = {
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonUserSavedLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


/** A lesson that a coach provides to students */
export type CoachLessonUserSavedLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};

/** A Relay connection object on "coachLesson" */
export type CoachLessonConnection = {
  __typename?: 'coachLessonConnection';
  edges: Array<CoachLessonEdge>;
  pageInfo: PageInfo;
};

export type CoachLessonEdge = {
  __typename?: 'coachLessonEdge';
  cursor: Scalars['String'];
  node: CoachLesson;
};

/** join table for coachLesson and lessonLocation */
export type CoachLessonLocation = Node & {
  __typename?: 'coachLessonLocation';
  /** An object relationship */
  coachLesson: CoachLesson;
  coachLessonCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  lessonLocation: LessonLocation;
  lessonLocationCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachLessonLocation" */
export type CoachLessonLocationConnection = {
  __typename?: 'coachLessonLocationConnection';
  edges: Array<CoachLessonLocationEdge>;
  pageInfo: PageInfo;
};

export type CoachLessonLocationEdge = {
  __typename?: 'coachLessonLocationEdge';
  cursor: Scalars['String'];
  node: CoachLessonLocation;
};

/** aggregated selection of "coachLessonLocation" */
export type CoachLessonLocationAggregate = {
  __typename?: 'coachLessonLocation_aggregate';
  aggregate?: Maybe<CoachLessonLocationAggregateFields>;
  nodes: Array<CoachLessonLocation>;
};

export type CoachLessonLocationAggregateBoolExp = {
  count?: InputMaybe<CoachLessonLocationAggregateBoolExpCount>;
};

export type CoachLessonLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachLessonLocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachLessonLocation" */
export type CoachLessonLocationAggregateFields = {
  __typename?: 'coachLessonLocation_aggregate_fields';
  avg?: Maybe<CoachLessonLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachLessonLocationMaxFields>;
  min?: Maybe<CoachLessonLocationMinFields>;
  stddev?: Maybe<CoachLessonLocationStddevFields>;
  stddev_pop?: Maybe<CoachLessonLocationStddevPopFields>;
  stddev_samp?: Maybe<CoachLessonLocationStddevSampFields>;
  sum?: Maybe<CoachLessonLocationSumFields>;
  var_pop?: Maybe<CoachLessonLocationVarPopFields>;
  var_samp?: Maybe<CoachLessonLocationVarSampFields>;
  variance?: Maybe<CoachLessonLocationVarianceFields>;
};


/** aggregate fields of "coachLessonLocation" */
export type CoachLessonLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachLessonLocation" */
export type CoachLessonLocationAggregateOrderBy = {
  avg?: InputMaybe<CoachLessonLocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachLessonLocationMaxOrderBy>;
  min?: InputMaybe<CoachLessonLocationMinOrderBy>;
  stddev?: InputMaybe<CoachLessonLocationStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachLessonLocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachLessonLocationStddevSampOrderBy>;
  sum?: InputMaybe<CoachLessonLocationSumOrderBy>;
  var_pop?: InputMaybe<CoachLessonLocationVarPopOrderBy>;
  var_samp?: InputMaybe<CoachLessonLocationVarSampOrderBy>;
  variance?: InputMaybe<CoachLessonLocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachLessonLocation" */
export type CoachLessonLocationArrRelInsertInput = {
  data: Array<CoachLessonLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachLessonLocationOnConflict>;
};

/** aggregate avg on columns */
export type CoachLessonLocationAvgFields = {
  __typename?: 'coachLessonLocation_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachLessonLocation" */
export type CoachLessonLocationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachLessonLocation". All fields are combined with a logical 'AND'. */
export type CoachLessonLocationBoolExp = {
  _and?: InputMaybe<Array<CoachLessonLocationBoolExp>>;
  _not?: InputMaybe<CoachLessonLocationBoolExp>;
  _or?: InputMaybe<Array<CoachLessonLocationBoolExp>>;
  coachLesson?: InputMaybe<CoachLessonBoolExp>;
  coachLessonCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lessonLocation?: InputMaybe<LessonLocationBoolExp>;
  lessonLocationCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachLessonLocation" */
export enum CoachLessonLocationConstraint {
  /** unique or primary key constraint on columns "lessonLocationCuid", "coachLessonCuid" */
  CoachLessonLocationCoachLessonCuidLessonLocationCuidKey = 'coachLessonLocation_coachLessonCuid_lessonLocationCuid_key',
  /** unique or primary key constraint on columns "cuid" */
  CoachLessonLocationCuidKey = 'coachLessonLocation_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachLessonLocationPkey = 'coachLessonLocation_pkey'
}

/** input type for incrementing numeric columns in table "coachLessonLocation" */
export type CoachLessonLocationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachLessonLocation" */
export type CoachLessonLocationInsertInput = {
  coachLesson?: InputMaybe<CoachLessonObjRelInsertInput>;
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lessonLocation?: InputMaybe<LessonLocationObjRelInsertInput>;
  lessonLocationCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachLessonLocationMaxFields = {
  __typename?: 'coachLessonLocation_max_fields';
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lessonLocationCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachLessonLocation" */
export type CoachLessonLocationMaxOrderBy = {
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonLocationCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachLessonLocationMinFields = {
  __typename?: 'coachLessonLocation_min_fields';
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lessonLocationCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachLessonLocation" */
export type CoachLessonLocationMinOrderBy = {
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonLocationCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachLessonLocation" */
export type CoachLessonLocationMutationResponse = {
  __typename?: 'coachLessonLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachLessonLocation>;
};

/** on_conflict condition type for table "coachLessonLocation" */
export type CoachLessonLocationOnConflict = {
  constraint: CoachLessonLocationConstraint;
  update_columns?: Array<CoachLessonLocationUpdateColumn>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};

/** Ordering options when selecting data from "coachLessonLocation". */
export type CoachLessonLocationOrderBy = {
  coachLesson?: InputMaybe<CoachLessonOrderBy>;
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonLocation?: InputMaybe<LessonLocationOrderBy>;
  lessonLocationCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachLessonLocation */
export type CoachLessonLocationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "coachLessonLocation" */
export enum CoachLessonLocationSelectColumn {
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LessonLocationCuid = 'lessonLocationCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachLessonLocation" */
export type CoachLessonLocationSetInput = {
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lessonLocationCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachLessonLocationStddevFields = {
  __typename?: 'coachLessonLocation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachLessonLocation" */
export type CoachLessonLocationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachLessonLocationStddevPopFields = {
  __typename?: 'coachLessonLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachLessonLocation" */
export type CoachLessonLocationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachLessonLocationStddevSampFields = {
  __typename?: 'coachLessonLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachLessonLocation" */
export type CoachLessonLocationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachLessonLocationSumFields = {
  __typename?: 'coachLessonLocation_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachLessonLocation" */
export type CoachLessonLocationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachLessonLocation" */
export enum CoachLessonLocationUpdateColumn {
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LessonLocationCuid = 'lessonLocationCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachLessonLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachLessonLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachLessonLocationSetInput>;
  where: CoachLessonLocationBoolExp;
};

/** aggregate var_pop on columns */
export type CoachLessonLocationVarPopFields = {
  __typename?: 'coachLessonLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachLessonLocation" */
export type CoachLessonLocationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachLessonLocationVarSampFields = {
  __typename?: 'coachLessonLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachLessonLocation" */
export type CoachLessonLocationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachLessonLocationVarianceFields = {
  __typename?: 'coachLessonLocation_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachLessonLocation" */
export type CoachLessonLocationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "coachLesson" */
export type CoachLessonAggregate = {
  __typename?: 'coachLesson_aggregate';
  aggregate?: Maybe<CoachLessonAggregateFields>;
  nodes: Array<CoachLesson>;
};

export type CoachLessonAggregateBoolExp = {
  bool_and?: InputMaybe<CoachLessonAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachLessonAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachLessonAggregateBoolExpCount>;
};

export type CoachLessonAggregateBoolExpBoolAnd = {
  arguments: CoachLessonSelectColumnCoachLessonAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachLessonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachLessonAggregateBoolExpBoolOr = {
  arguments: CoachLessonSelectColumnCoachLessonAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachLessonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachLessonAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachLessonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachLesson" */
export type CoachLessonAggregateFields = {
  __typename?: 'coachLesson_aggregate_fields';
  avg?: Maybe<CoachLessonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachLessonMaxFields>;
  min?: Maybe<CoachLessonMinFields>;
  stddev?: Maybe<CoachLessonStddevFields>;
  stddev_pop?: Maybe<CoachLessonStddevPopFields>;
  stddev_samp?: Maybe<CoachLessonStddevSampFields>;
  sum?: Maybe<CoachLessonSumFields>;
  var_pop?: Maybe<CoachLessonVarPopFields>;
  var_samp?: Maybe<CoachLessonVarSampFields>;
  variance?: Maybe<CoachLessonVarianceFields>;
};


/** aggregate fields of "coachLesson" */
export type CoachLessonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachLesson" */
export type CoachLessonAggregateOrderBy = {
  avg?: InputMaybe<CoachLessonAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachLessonMaxOrderBy>;
  min?: InputMaybe<CoachLessonMinOrderBy>;
  stddev?: InputMaybe<CoachLessonStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachLessonStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachLessonStddevSampOrderBy>;
  sum?: InputMaybe<CoachLessonSumOrderBy>;
  var_pop?: InputMaybe<CoachLessonVarPopOrderBy>;
  var_samp?: InputMaybe<CoachLessonVarSampOrderBy>;
  variance?: InputMaybe<CoachLessonVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CoachLessonAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  participantRange?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coachLesson" */
export type CoachLessonArrRelInsertInput = {
  data: Array<CoachLessonInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachLessonOnConflict>;
};

/** aggregate avg on columns */
export type CoachLessonAvgFields = {
  __typename?: 'coachLesson_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachLesson" */
export type CoachLessonAvgOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachLesson". All fields are combined with a logical 'AND'. */
export type CoachLessonBoolExp = {
  _and?: InputMaybe<Array<CoachLessonBoolExp>>;
  _not?: InputMaybe<CoachLessonBoolExp>;
  _or?: InputMaybe<Array<CoachLessonBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateBoolExp>;
  coachCustomers?: InputMaybe<CoachCustomerBoolExp>;
  coachCustomers_aggregate?: InputMaybe<CoachCustomerAggregateBoolExp>;
  coachLessonLocations?: InputMaybe<CoachLessonLocationBoolExp>;
  coachLessonLocations_aggregate?: InputMaybe<CoachLessonLocationAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  disciplineNames?: InputMaybe<JsonbComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  highlightedPosition?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isBundle?: InputMaybe<BooleanComparisonExp>;
  isHidden?: InputMaybe<BooleanComparisonExp>;
  isVirtual?: InputMaybe<BooleanComparisonExp>;
  lessonsPerBundle?: InputMaybe<IntComparisonExp>;
  participantRange?: InputMaybe<JsonbComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  recurrenceType?: InputMaybe<StringComparisonExp>;
  recurringInterval?: InputMaybe<StringComparisonExp>;
  requestToBook?: InputMaybe<BooleanComparisonExp>;
  skills?: InputMaybe<JsonbComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userSavedLessons?: InputMaybe<UserSavedLessonBoolExp>;
  userSavedLessons_aggregate?: InputMaybe<UserSavedLessonAggregateBoolExp>;
};

/** unique or primary key constraints on table "coachLesson" */
export enum CoachLessonConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachLessonCuidKey = 'coachLesson_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachLessonPkey = 'coachLesson_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CoachLessonDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  disciplineNames?: InputMaybe<Array<Scalars['String']>>;
  participantRange?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CoachLessonDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  disciplineNames?: InputMaybe<Scalars['Int']>;
  participantRange?: InputMaybe<Scalars['Int']>;
  skills?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CoachLessonDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['String']>;
  participantRange?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "coachLesson" */
export type CoachLessonIncInput = {
  duration?: InputMaybe<Scalars['Int']>;
  highlightedPosition?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lessonsPerBundle?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachLesson" */
export type CoachLessonInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonArrRelInsertInput>;
  coachCustomers?: InputMaybe<CoachCustomerArrRelInsertInput>;
  coachLessonLocations?: InputMaybe<CoachLessonLocationArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  duration?: InputMaybe<Scalars['Int']>;
  highlightedPosition?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isBundle?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  lessonsPerBundle?: InputMaybe<Scalars['Int']>;
  participantRange?: InputMaybe<Scalars['jsonb']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  recurrenceType?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  requestToBook?: InputMaybe<Scalars['Boolean']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userSavedLessons?: InputMaybe<UserSavedLessonArrRelInsertInput>;
};

/** aggregate max on columns */
export type CoachLessonMaxFields = {
  __typename?: 'coachLesson_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  highlightedPosition?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lessonsPerBundle?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  recurrenceType?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachLesson" */
export type CoachLessonMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachLessonMinFields = {
  __typename?: 'coachLesson_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  highlightedPosition?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lessonsPerBundle?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  recurrenceType?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachLesson" */
export type CoachLessonMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachLesson" */
export type CoachLessonMutationResponse = {
  __typename?: 'coachLesson_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachLesson>;
};

/** input type for inserting object relation for remote table "coachLesson" */
export type CoachLessonObjRelInsertInput = {
  data: CoachLessonInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachLessonOnConflict>;
};

/** on_conflict condition type for table "coachLesson" */
export type CoachLessonOnConflict = {
  constraint: CoachLessonConstraint;
  update_columns?: Array<CoachLessonUpdateColumn>;
  where?: InputMaybe<CoachLessonBoolExp>;
};

/** Ordering options when selecting data from "coachLesson". */
export type CoachLessonOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateOrderBy>;
  coachCustomers_aggregate?: InputMaybe<CoachCustomerAggregateOrderBy>;
  coachLessonLocations_aggregate?: InputMaybe<CoachLessonLocationAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  disciplineNames?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isBundle?: InputMaybe<OrderBy>;
  isHidden?: InputMaybe<OrderBy>;
  isVirtual?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  participantRange?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  recurrenceType?: InputMaybe<OrderBy>;
  recurringInterval?: InputMaybe<OrderBy>;
  requestToBook?: InputMaybe<OrderBy>;
  skills?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userSavedLessons_aggregate?: InputMaybe<UserSavedLessonAggregateOrderBy>;
};

/** primary key columns input for table: coachLesson */
export type CoachLessonPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoachLessonPrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  participantRange?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coachLesson" */
export enum CoachLessonSelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  DisciplineNames = 'disciplineNames',
  /** column name */
  Duration = 'duration',
  /** column name */
  HighlightedPosition = 'highlightedPosition',
  /** column name */
  Id = 'id',
  /** column name */
  IsBundle = 'isBundle',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  LessonsPerBundle = 'lessonsPerBundle',
  /** column name */
  ParticipantRange = 'participantRange',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  RecurrenceType = 'recurrenceType',
  /** column name */
  RecurringInterval = 'recurringInterval',
  /** column name */
  RequestToBook = 'requestToBook',
  /** column name */
  Skills = 'skills',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "coachLesson_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coachLesson" */
export enum CoachLessonSelectColumnCoachLessonAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsBundle = 'isBundle',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  RequestToBook = 'requestToBook'
}

/** select "coachLesson_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coachLesson" */
export enum CoachLessonSelectColumnCoachLessonAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsBundle = 'isBundle',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  RequestToBook = 'requestToBook'
}

/** input type for updating data in table "coachLesson" */
export type CoachLessonSetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disciplineNames?: InputMaybe<Scalars['jsonb']>;
  duration?: InputMaybe<Scalars['Int']>;
  highlightedPosition?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isBundle?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  lessonsPerBundle?: InputMaybe<Scalars['Int']>;
  participantRange?: InputMaybe<Scalars['jsonb']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  recurrenceType?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  requestToBook?: InputMaybe<Scalars['Boolean']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachLessonStddevFields = {
  __typename?: 'coachLesson_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachLesson" */
export type CoachLessonStddevOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachLessonStddevPopFields = {
  __typename?: 'coachLesson_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachLesson" */
export type CoachLessonStddevPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachLessonStddevSampFields = {
  __typename?: 'coachLesson_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachLesson" */
export type CoachLessonStddevSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachLessonSumFields = {
  __typename?: 'coachLesson_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  highlightedPosition?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lessonsPerBundle?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachLesson" */
export type CoachLessonSumOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "coachLesson" */
export enum CoachLessonUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  DisciplineNames = 'disciplineNames',
  /** column name */
  Duration = 'duration',
  /** column name */
  HighlightedPosition = 'highlightedPosition',
  /** column name */
  Id = 'id',
  /** column name */
  IsBundle = 'isBundle',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  IsVirtual = 'isVirtual',
  /** column name */
  LessonsPerBundle = 'lessonsPerBundle',
  /** column name */
  ParticipantRange = 'participantRange',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  RecurrenceType = 'recurrenceType',
  /** column name */
  RecurringInterval = 'recurringInterval',
  /** column name */
  RequestToBook = 'requestToBook',
  /** column name */
  Skills = 'skills',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachLessonUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CoachLessonAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CoachLessonDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CoachLessonDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CoachLessonDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachLessonIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoachLessonPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachLessonSetInput>;
  where: CoachLessonBoolExp;
};

/** aggregate var_pop on columns */
export type CoachLessonVarPopFields = {
  __typename?: 'coachLesson_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachLesson" */
export type CoachLessonVarPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachLessonVarSampFields = {
  __typename?: 'coachLesson_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachLesson" */
export type CoachLessonVarSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachLessonVarianceFields = {
  __typename?: 'coachLesson_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  highlightedPosition?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lessonsPerBundle?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachLesson" */
export type CoachLessonVarianceOrderBy = {
  duration?: InputMaybe<OrderBy>;
  highlightedPosition?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonsPerBundle?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Where does a coach teach */
export type CoachLocation = Node & {
  __typename?: 'coachLocation';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  fullAddress: Scalars['String'];
  googlePlaceId: Scalars['String'];
  id: Scalars['ID'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  state?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zipCode?: Maybe<Scalars['String']>;
};

/** A Relay connection object on "coachLocation" */
export type CoachLocationConnection = {
  __typename?: 'coachLocationConnection';
  edges: Array<CoachLocationEdge>;
  pageInfo: PageInfo;
};

export type CoachLocationEdge = {
  __typename?: 'coachLocationEdge';
  cursor: Scalars['String'];
  node: CoachLocation;
};

/** aggregated selection of "coachLocation" */
export type CoachLocationAggregate = {
  __typename?: 'coachLocation_aggregate';
  aggregate?: Maybe<CoachLocationAggregateFields>;
  nodes: Array<CoachLocation>;
};

export type CoachLocationAggregateBoolExp = {
  count?: InputMaybe<CoachLocationAggregateBoolExpCount>;
};

export type CoachLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachLocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachLocation" */
export type CoachLocationAggregateFields = {
  __typename?: 'coachLocation_aggregate_fields';
  avg?: Maybe<CoachLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachLocationMaxFields>;
  min?: Maybe<CoachLocationMinFields>;
  stddev?: Maybe<CoachLocationStddevFields>;
  stddev_pop?: Maybe<CoachLocationStddevPopFields>;
  stddev_samp?: Maybe<CoachLocationStddevSampFields>;
  sum?: Maybe<CoachLocationSumFields>;
  var_pop?: Maybe<CoachLocationVarPopFields>;
  var_samp?: Maybe<CoachLocationVarSampFields>;
  variance?: Maybe<CoachLocationVarianceFields>;
};


/** aggregate fields of "coachLocation" */
export type CoachLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachLocation" */
export type CoachLocationAggregateOrderBy = {
  avg?: InputMaybe<CoachLocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachLocationMaxOrderBy>;
  min?: InputMaybe<CoachLocationMinOrderBy>;
  stddev?: InputMaybe<CoachLocationStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachLocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachLocationStddevSampOrderBy>;
  sum?: InputMaybe<CoachLocationSumOrderBy>;
  var_pop?: InputMaybe<CoachLocationVarPopOrderBy>;
  var_samp?: InputMaybe<CoachLocationVarSampOrderBy>;
  variance?: InputMaybe<CoachLocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachLocation" */
export type CoachLocationArrRelInsertInput = {
  data: Array<CoachLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachLocationOnConflict>;
};

/** aggregate avg on columns */
export type CoachLocationAvgFields = {
  __typename?: 'coachLocation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachLocation" */
export type CoachLocationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachLocation". All fields are combined with a logical 'AND'. */
export type CoachLocationBoolExp = {
  _and?: InputMaybe<Array<CoachLocationBoolExp>>;
  _not?: InputMaybe<CoachLocationBoolExp>;
  _or?: InputMaybe<Array<CoachLocationBoolExp>>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  fullAddress?: InputMaybe<StringComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "coachLocation" */
export enum CoachLocationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachLocationCuidKey = 'coachLocation_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachLocationPkey = 'coachLocation_pkey'
}

/** input type for incrementing numeric columns in table "coachLocation" */
export type CoachLocationIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "coachLocation" */
export type CoachLocationInsertInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CoachLocationMaxFields = {
  __typename?: 'coachLocation_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coachLocation" */
export type CoachLocationMaxOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachLocationMinFields = {
  __typename?: 'coachLocation_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coachLocation" */
export type CoachLocationMinOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachLocation" */
export type CoachLocationMutationResponse = {
  __typename?: 'coachLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachLocation>;
};

/** on_conflict condition type for table "coachLocation" */
export type CoachLocationOnConflict = {
  constraint: CoachLocationConstraint;
  update_columns?: Array<CoachLocationUpdateColumn>;
  where?: InputMaybe<CoachLocationBoolExp>;
};

/** Ordering options when selecting data from "coachLocation". */
export type CoachLocationOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachLocation */
export type CoachLocationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachLocation" */
export enum CoachLocationSelectColumn {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  City = 'city',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "coachLocation" */
export type CoachLocationSetInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CoachLocationStddevFields = {
  __typename?: 'coachLocation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachLocation" */
export type CoachLocationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachLocationStddevPopFields = {
  __typename?: 'coachLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachLocation" */
export type CoachLocationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachLocationStddevSampFields = {
  __typename?: 'coachLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachLocation" */
export type CoachLocationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachLocationSumFields = {
  __typename?: 'coachLocation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "coachLocation" */
export type CoachLocationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "coachLocation" */
export enum CoachLocationUpdateColumn {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  City = 'city',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

export type CoachLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachLocationSetInput>;
  where: CoachLocationBoolExp;
};

/** aggregate var_pop on columns */
export type CoachLocationVarPopFields = {
  __typename?: 'coachLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachLocation" */
export type CoachLocationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachLocationVarSampFields = {
  __typename?: 'coachLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachLocation" */
export type CoachLocationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachLocationVarianceFields = {
  __typename?: 'coachLocation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachLocation" */
export type CoachLocationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** All coaches that need moderation */
export type CoachModerationFlag = Node & {
  __typename?: 'coachModerationFlag';
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  isReportOpen: Scalars['Boolean'];
  reason: Scalars['String'];
  reasonDetails: Scalars['String'];
  reportPriority: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "coachModerationFlag" */
export type CoachModerationFlagConnection = {
  __typename?: 'coachModerationFlagConnection';
  edges: Array<CoachModerationFlagEdge>;
  pageInfo: PageInfo;
};

export type CoachModerationFlagEdge = {
  __typename?: 'coachModerationFlagEdge';
  cursor: Scalars['String'];
  node: CoachModerationFlag;
};

/** aggregated selection of "coachModerationFlag" */
export type CoachModerationFlagAggregate = {
  __typename?: 'coachModerationFlag_aggregate';
  aggregate?: Maybe<CoachModerationFlagAggregateFields>;
  nodes: Array<CoachModerationFlag>;
};

export type CoachModerationFlagAggregateBoolExp = {
  bool_and?: InputMaybe<CoachModerationFlagAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachModerationFlagAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachModerationFlagAggregateBoolExpCount>;
};

export type CoachModerationFlagAggregateBoolExpBoolAnd = {
  arguments: CoachModerationFlagSelectColumnCoachModerationFlagAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachModerationFlagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachModerationFlagAggregateBoolExpBoolOr = {
  arguments: CoachModerationFlagSelectColumnCoachModerationFlagAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachModerationFlagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachModerationFlagAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachModerationFlagBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachModerationFlag" */
export type CoachModerationFlagAggregateFields = {
  __typename?: 'coachModerationFlag_aggregate_fields';
  avg?: Maybe<CoachModerationFlagAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachModerationFlagMaxFields>;
  min?: Maybe<CoachModerationFlagMinFields>;
  stddev?: Maybe<CoachModerationFlagStddevFields>;
  stddev_pop?: Maybe<CoachModerationFlagStddevPopFields>;
  stddev_samp?: Maybe<CoachModerationFlagStddevSampFields>;
  sum?: Maybe<CoachModerationFlagSumFields>;
  var_pop?: Maybe<CoachModerationFlagVarPopFields>;
  var_samp?: Maybe<CoachModerationFlagVarSampFields>;
  variance?: Maybe<CoachModerationFlagVarianceFields>;
};


/** aggregate fields of "coachModerationFlag" */
export type CoachModerationFlagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachModerationFlag" */
export type CoachModerationFlagAggregateOrderBy = {
  avg?: InputMaybe<CoachModerationFlagAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachModerationFlagMaxOrderBy>;
  min?: InputMaybe<CoachModerationFlagMinOrderBy>;
  stddev?: InputMaybe<CoachModerationFlagStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachModerationFlagStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachModerationFlagStddevSampOrderBy>;
  sum?: InputMaybe<CoachModerationFlagSumOrderBy>;
  var_pop?: InputMaybe<CoachModerationFlagVarPopOrderBy>;
  var_samp?: InputMaybe<CoachModerationFlagVarSampOrderBy>;
  variance?: InputMaybe<CoachModerationFlagVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachModerationFlag" */
export type CoachModerationFlagArrRelInsertInput = {
  data: Array<CoachModerationFlagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachModerationFlagOnConflict>;
};

/** aggregate avg on columns */
export type CoachModerationFlagAvgFields = {
  __typename?: 'coachModerationFlag_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachModerationFlag" */
export type CoachModerationFlagAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachModerationFlag". All fields are combined with a logical 'AND'. */
export type CoachModerationFlagBoolExp = {
  _and?: InputMaybe<Array<CoachModerationFlagBoolExp>>;
  _not?: InputMaybe<CoachModerationFlagBoolExp>;
  _or?: InputMaybe<Array<CoachModerationFlagBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isReportOpen?: InputMaybe<BooleanComparisonExp>;
  reason?: InputMaybe<StringComparisonExp>;
  reasonDetails?: InputMaybe<StringComparisonExp>;
  reportPriority?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "coachModerationFlag" */
export enum CoachModerationFlagConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachModerationFlagCuidKey = 'coachModerationFlag_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachModerationFlagsPkey = 'coachModerationFlags_pkey'
}

/** input type for incrementing numeric columns in table "coachModerationFlag" */
export type CoachModerationFlagIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachModerationFlag" */
export type CoachModerationFlagInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isReportOpen?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  reportPriority?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CoachModerationFlagMaxFields = {
  __typename?: 'coachModerationFlag_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  reasonDetails?: Maybe<Scalars['String']>;
  reportPriority?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coachModerationFlag" */
export type CoachModerationFlagMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachModerationFlagMinFields = {
  __typename?: 'coachModerationFlag_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  reasonDetails?: Maybe<Scalars['String']>;
  reportPriority?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coachModerationFlag" */
export type CoachModerationFlagMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachModerationFlag" */
export type CoachModerationFlagMutationResponse = {
  __typename?: 'coachModerationFlag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachModerationFlag>;
};

/** on_conflict condition type for table "coachModerationFlag" */
export type CoachModerationFlagOnConflict = {
  constraint: CoachModerationFlagConstraint;
  update_columns?: Array<CoachModerationFlagUpdateColumn>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};

/** Ordering options when selecting data from "coachModerationFlag". */
export type CoachModerationFlagOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isReportOpen?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachModerationFlag */
export type CoachModerationFlagPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachModerationFlag" */
export enum CoachModerationFlagSelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsReportOpen = 'isReportOpen',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReasonDetails = 'reasonDetails',
  /** column name */
  ReportPriority = 'reportPriority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "coachModerationFlag_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coachModerationFlag" */
export enum CoachModerationFlagSelectColumnCoachModerationFlagAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsReportOpen = 'isReportOpen'
}

/** select "coachModerationFlag_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coachModerationFlag" */
export enum CoachModerationFlagSelectColumnCoachModerationFlagAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsReportOpen = 'isReportOpen'
}

/** input type for updating data in table "coachModerationFlag" */
export type CoachModerationFlagSetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isReportOpen?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  reportPriority?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CoachModerationFlagStddevFields = {
  __typename?: 'coachModerationFlag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachModerationFlag" */
export type CoachModerationFlagStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachModerationFlagStddevPopFields = {
  __typename?: 'coachModerationFlag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachModerationFlag" */
export type CoachModerationFlagStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachModerationFlagStddevSampFields = {
  __typename?: 'coachModerationFlag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachModerationFlag" */
export type CoachModerationFlagStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachModerationFlagSumFields = {
  __typename?: 'coachModerationFlag_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachModerationFlag" */
export type CoachModerationFlagSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachModerationFlag" */
export enum CoachModerationFlagUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsReportOpen = 'isReportOpen',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReasonDetails = 'reasonDetails',
  /** column name */
  ReportPriority = 'reportPriority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type CoachModerationFlagUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachModerationFlagIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachModerationFlagSetInput>;
  where: CoachModerationFlagBoolExp;
};

/** aggregate var_pop on columns */
export type CoachModerationFlagVarPopFields = {
  __typename?: 'coachModerationFlag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachModerationFlag" */
export type CoachModerationFlagVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachModerationFlagVarSampFields = {
  __typename?: 'coachModerationFlag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachModerationFlag" */
export type CoachModerationFlagVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachModerationFlagVarianceFields = {
  __typename?: 'coachModerationFlag_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachModerationFlag" */
export type CoachModerationFlagVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** type of student a coach teaches  */
export type CoachStudent = Node & {
  __typename?: 'coachStudent';
  ageRange: Scalars['jsonb'];
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  focus: Scalars['jsonb'];
  genders: Scalars['jsonb'];
  id: Scalars['ID'];
  languages: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** type of student a coach teaches  */
export type CoachStudentAgeRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** type of student a coach teaches  */
export type CoachStudentFocusArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** type of student a coach teaches  */
export type CoachStudentGendersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** type of student a coach teaches  */
export type CoachStudentLanguagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "coachStudent" */
export type CoachStudentConnection = {
  __typename?: 'coachStudentConnection';
  edges: Array<CoachStudentEdge>;
  pageInfo: PageInfo;
};

export type CoachStudentEdge = {
  __typename?: 'coachStudentEdge';
  cursor: Scalars['String'];
  node: CoachStudent;
};

/** aggregated selection of "coachStudent" */
export type CoachStudentAggregate = {
  __typename?: 'coachStudent_aggregate';
  aggregate?: Maybe<CoachStudentAggregateFields>;
  nodes: Array<CoachStudent>;
};

export type CoachStudentAggregateBoolExp = {
  count?: InputMaybe<CoachStudentAggregateBoolExpCount>;
};

export type CoachStudentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachStudentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachStudentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachStudent" */
export type CoachStudentAggregateFields = {
  __typename?: 'coachStudent_aggregate_fields';
  avg?: Maybe<CoachStudentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachStudentMaxFields>;
  min?: Maybe<CoachStudentMinFields>;
  stddev?: Maybe<CoachStudentStddevFields>;
  stddev_pop?: Maybe<CoachStudentStddevPopFields>;
  stddev_samp?: Maybe<CoachStudentStddevSampFields>;
  sum?: Maybe<CoachStudentSumFields>;
  var_pop?: Maybe<CoachStudentVarPopFields>;
  var_samp?: Maybe<CoachStudentVarSampFields>;
  variance?: Maybe<CoachStudentVarianceFields>;
};


/** aggregate fields of "coachStudent" */
export type CoachStudentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachStudentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachStudent" */
export type CoachStudentAggregateOrderBy = {
  avg?: InputMaybe<CoachStudentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachStudentMaxOrderBy>;
  min?: InputMaybe<CoachStudentMinOrderBy>;
  stddev?: InputMaybe<CoachStudentStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachStudentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachStudentStddevSampOrderBy>;
  sum?: InputMaybe<CoachStudentSumOrderBy>;
  var_pop?: InputMaybe<CoachStudentVarPopOrderBy>;
  var_samp?: InputMaybe<CoachStudentVarSampOrderBy>;
  variance?: InputMaybe<CoachStudentVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CoachStudentAppendInput = {
  ageRange?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  languages?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coachStudent" */
export type CoachStudentArrRelInsertInput = {
  data: Array<CoachStudentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachStudentOnConflict>;
};

/** aggregate avg on columns */
export type CoachStudentAvgFields = {
  __typename?: 'coachStudent_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachStudent" */
export type CoachStudentAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachStudent". All fields are combined with a logical 'AND'. */
export type CoachStudentBoolExp = {
  _and?: InputMaybe<Array<CoachStudentBoolExp>>;
  _not?: InputMaybe<CoachStudentBoolExp>;
  _or?: InputMaybe<Array<CoachStudentBoolExp>>;
  ageRange?: InputMaybe<JsonbComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  focus?: InputMaybe<JsonbComparisonExp>;
  genders?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  languages?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachStudent" */
export enum CoachStudentConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachStudentCuidKey = 'coachStudent_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CoachStudentPkey = 'coachStudent_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CoachStudentDeleteAtPathInput = {
  ageRange?: InputMaybe<Array<Scalars['String']>>;
  focus?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CoachStudentDeleteElemInput = {
  ageRange?: InputMaybe<Scalars['Int']>;
  focus?: InputMaybe<Scalars['Int']>;
  genders?: InputMaybe<Scalars['Int']>;
  languages?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CoachStudentDeleteKeyInput = {
  ageRange?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "coachStudent" */
export type CoachStudentIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coachStudent" */
export type CoachStudentInsertInput = {
  ageRange?: InputMaybe<Scalars['jsonb']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  languages?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachStudentMaxFields = {
  __typename?: 'coachStudent_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachStudent" */
export type CoachStudentMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachStudentMinFields = {
  __typename?: 'coachStudent_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachStudent" */
export type CoachStudentMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachStudent" */
export type CoachStudentMutationResponse = {
  __typename?: 'coachStudent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachStudent>;
};

/** on_conflict condition type for table "coachStudent" */
export type CoachStudentOnConflict = {
  constraint: CoachStudentConstraint;
  update_columns?: Array<CoachStudentUpdateColumn>;
  where?: InputMaybe<CoachStudentBoolExp>;
};

/** Ordering options when selecting data from "coachStudent". */
export type CoachStudentOrderBy = {
  ageRange?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  focus?: InputMaybe<OrderBy>;
  genders?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  languages?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachStudent */
export type CoachStudentPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoachStudentPrependInput = {
  ageRange?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  languages?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coachStudent" */
export enum CoachStudentSelectColumn {
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Focus = 'focus',
  /** column name */
  Genders = 'genders',
  /** column name */
  Id = 'id',
  /** column name */
  Languages = 'languages',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "coachStudent" */
export type CoachStudentSetInput = {
  ageRange?: InputMaybe<Scalars['jsonb']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  languages?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachStudentStddevFields = {
  __typename?: 'coachStudent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachStudent" */
export type CoachStudentStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachStudentStddevPopFields = {
  __typename?: 'coachStudent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachStudent" */
export type CoachStudentStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachStudentStddevSampFields = {
  __typename?: 'coachStudent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachStudent" */
export type CoachStudentStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachStudentSumFields = {
  __typename?: 'coachStudent_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coachStudent" */
export type CoachStudentSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "coachStudent" */
export enum CoachStudentUpdateColumn {
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Focus = 'focus',
  /** column name */
  Genders = 'genders',
  /** column name */
  Id = 'id',
  /** column name */
  Languages = 'languages',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachStudentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CoachStudentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CoachStudentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CoachStudentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CoachStudentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachStudentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoachStudentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachStudentSetInput>;
  where: CoachStudentBoolExp;
};

/** aggregate var_pop on columns */
export type CoachStudentVarPopFields = {
  __typename?: 'coachStudent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachStudent" */
export type CoachStudentVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachStudentVarSampFields = {
  __typename?: 'coachStudent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachStudent" */
export type CoachStudentVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachStudentVarianceFields = {
  __typename?: 'coachStudent_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachStudent" */
export type CoachStudentVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Each row is a day of unavailability */
export type CoachUnavailableDate = Node & {
  __typename?: 'coachUnavailableDate';
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  date: Scalars['date'];
  endSeconds: Scalars['Int'];
  id: Scalars['ID'];
  isAllDay: Scalars['Boolean'];
  startSeconds: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "coachUnavailableDate" */
export type CoachUnavailableDateConnection = {
  __typename?: 'coachUnavailableDateConnection';
  edges: Array<CoachUnavailableDateEdge>;
  pageInfo: PageInfo;
};

export type CoachUnavailableDateEdge = {
  __typename?: 'coachUnavailableDateEdge';
  cursor: Scalars['String'];
  node: CoachUnavailableDate;
};

/** aggregated selection of "coachUnavailableDate" */
export type CoachUnavailableDateAggregate = {
  __typename?: 'coachUnavailableDate_aggregate';
  aggregate?: Maybe<CoachUnavailableDateAggregateFields>;
  nodes: Array<CoachUnavailableDate>;
};

export type CoachUnavailableDateAggregateBoolExp = {
  bool_and?: InputMaybe<CoachUnavailableDateAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachUnavailableDateAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachUnavailableDateAggregateBoolExpCount>;
};

export type CoachUnavailableDateAggregateBoolExpBoolAnd = {
  arguments: CoachUnavailableDateSelectColumnCoachUnavailableDateAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachUnavailableDateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachUnavailableDateAggregateBoolExpBoolOr = {
  arguments: CoachUnavailableDateSelectColumnCoachUnavailableDateAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachUnavailableDateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachUnavailableDateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachUnavailableDateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coachUnavailableDate" */
export type CoachUnavailableDateAggregateFields = {
  __typename?: 'coachUnavailableDate_aggregate_fields';
  avg?: Maybe<CoachUnavailableDateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachUnavailableDateMaxFields>;
  min?: Maybe<CoachUnavailableDateMinFields>;
  stddev?: Maybe<CoachUnavailableDateStddevFields>;
  stddev_pop?: Maybe<CoachUnavailableDateStddevPopFields>;
  stddev_samp?: Maybe<CoachUnavailableDateStddevSampFields>;
  sum?: Maybe<CoachUnavailableDateSumFields>;
  var_pop?: Maybe<CoachUnavailableDateVarPopFields>;
  var_samp?: Maybe<CoachUnavailableDateVarSampFields>;
  variance?: Maybe<CoachUnavailableDateVarianceFields>;
};


/** aggregate fields of "coachUnavailableDate" */
export type CoachUnavailableDateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coachUnavailableDate" */
export type CoachUnavailableDateAggregateOrderBy = {
  avg?: InputMaybe<CoachUnavailableDateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachUnavailableDateMaxOrderBy>;
  min?: InputMaybe<CoachUnavailableDateMinOrderBy>;
  stddev?: InputMaybe<CoachUnavailableDateStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachUnavailableDateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachUnavailableDateStddevSampOrderBy>;
  sum?: InputMaybe<CoachUnavailableDateSumOrderBy>;
  var_pop?: InputMaybe<CoachUnavailableDateVarPopOrderBy>;
  var_samp?: InputMaybe<CoachUnavailableDateVarSampOrderBy>;
  variance?: InputMaybe<CoachUnavailableDateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coachUnavailableDate" */
export type CoachUnavailableDateArrRelInsertInput = {
  data: Array<CoachUnavailableDateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachUnavailableDateOnConflict>;
};

/** aggregate avg on columns */
export type CoachUnavailableDateAvgFields = {
  __typename?: 'coachUnavailableDate_avg_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateAvgOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coachUnavailableDate". All fields are combined with a logical 'AND'. */
export type CoachUnavailableDateBoolExp = {
  _and?: InputMaybe<Array<CoachUnavailableDateBoolExp>>;
  _not?: InputMaybe<CoachUnavailableDateBoolExp>;
  _or?: InputMaybe<Array<CoachUnavailableDateBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<DateComparisonExp>;
  endSeconds?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isAllDay?: InputMaybe<BooleanComparisonExp>;
  startSeconds?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coachUnavailableDate" */
export enum CoachUnavailableDateConstraint {
  /** unique or primary key constraint on columns "id" */
  CoachUnavailabilityPkey = 'coachUnavailability_pkey',
  /** unique or primary key constraint on columns "cuid" */
  CoachUnavailableDateCuidKey = 'coachUnavailableDate_cuid_key'
}

/** input type for incrementing numeric columns in table "coachUnavailableDate" */
export type CoachUnavailableDateIncInput = {
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coachUnavailableDate" */
export type CoachUnavailableDateInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CoachUnavailableDateMaxFields = {
  __typename?: 'coachUnavailableDate_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachUnavailableDateMinFields = {
  __typename?: 'coachUnavailableDate_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coachUnavailableDate" */
export type CoachUnavailableDateMutationResponse = {
  __typename?: 'coachUnavailableDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachUnavailableDate>;
};

/** on_conflict condition type for table "coachUnavailableDate" */
export type CoachUnavailableDateOnConflict = {
  constraint: CoachUnavailableDateConstraint;
  update_columns?: Array<CoachUnavailableDateUpdateColumn>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};

/** Ordering options when selecting data from "coachUnavailableDate". */
export type CoachUnavailableDateOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAllDay?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coachUnavailableDate */
export type CoachUnavailableDatePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "coachUnavailableDate" */
export enum CoachUnavailableDateSelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  EndSeconds = 'endSeconds',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllDay = 'isAllDay',
  /** column name */
  StartSeconds = 'startSeconds',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "coachUnavailableDate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coachUnavailableDate" */
export enum CoachUnavailableDateSelectColumnCoachUnavailableDateAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAllDay = 'isAllDay'
}

/** select "coachUnavailableDate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coachUnavailableDate" */
export enum CoachUnavailableDateSelectColumnCoachUnavailableDateAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAllDay = 'isAllDay'
}

/** input type for updating data in table "coachUnavailableDate" */
export type CoachUnavailableDateSetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  endSeconds?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  startSeconds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CoachUnavailableDateStddevFields = {
  __typename?: 'coachUnavailableDate_stddev_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateStddevOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachUnavailableDateStddevPopFields = {
  __typename?: 'coachUnavailableDate_stddev_pop_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateStddevPopOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachUnavailableDateStddevSampFields = {
  __typename?: 'coachUnavailableDate_stddev_samp_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateStddevSampOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachUnavailableDateSumFields = {
  __typename?: 'coachUnavailableDate_sum_fields';
  endSeconds?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  startSeconds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateSumOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** update columns of table "coachUnavailableDate" */
export enum CoachUnavailableDateUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  EndSeconds = 'endSeconds',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllDay = 'isAllDay',
  /** column name */
  StartSeconds = 'startSeconds',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CoachUnavailableDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachUnavailableDateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachUnavailableDateSetInput>;
  where: CoachUnavailableDateBoolExp;
};

/** aggregate var_pop on columns */
export type CoachUnavailableDateVarPopFields = {
  __typename?: 'coachUnavailableDate_var_pop_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateVarPopOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachUnavailableDateVarSampFields = {
  __typename?: 'coachUnavailableDate_var_samp_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateVarSampOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachUnavailableDateVarianceFields = {
  __typename?: 'coachUnavailableDate_variance_fields';
  endSeconds?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  startSeconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coachUnavailableDate" */
export type CoachUnavailableDateVarianceOrderBy = {
  endSeconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startSeconds?: InputMaybe<OrderBy>;
};

/** aggregated selection of "coach" */
export type CoachAggregate = {
  __typename?: 'coach_aggregate';
  aggregate?: Maybe<CoachAggregateFields>;
  nodes: Array<Coach>;
};

export type CoachAggregateBoolExp = {
  bool_and?: InputMaybe<CoachAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CoachAggregateBoolExpBoolOr>;
  count?: InputMaybe<CoachAggregateBoolExpCount>;
};

export type CoachAggregateBoolExpBoolAnd = {
  arguments: CoachSelectColumnCoachAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachAggregateBoolExpBoolOr = {
  arguments: CoachSelectColumnCoachAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CoachAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "coach" */
export type CoachAggregateFields = {
  __typename?: 'coach_aggregate_fields';
  avg?: Maybe<CoachAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoachMaxFields>;
  min?: Maybe<CoachMinFields>;
  stddev?: Maybe<CoachStddevFields>;
  stddev_pop?: Maybe<CoachStddevPopFields>;
  stddev_samp?: Maybe<CoachStddevSampFields>;
  sum?: Maybe<CoachSumFields>;
  var_pop?: Maybe<CoachVarPopFields>;
  var_samp?: Maybe<CoachVarSampFields>;
  variance?: Maybe<CoachVarianceFields>;
};


/** aggregate fields of "coach" */
export type CoachAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coach" */
export type CoachAggregateOrderBy = {
  avg?: InputMaybe<CoachAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachMaxOrderBy>;
  min?: InputMaybe<CoachMinOrderBy>;
  stddev?: InputMaybe<CoachStddevOrderBy>;
  stddev_pop?: InputMaybe<CoachStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoachStddevSampOrderBy>;
  sum?: InputMaybe<CoachSumOrderBy>;
  var_pop?: InputMaybe<CoachVarPopOrderBy>;
  var_samp?: InputMaybe<CoachVarSampOrderBy>;
  variance?: InputMaybe<CoachVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CoachAppendInput = {
  coachingVibe?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coach" */
export type CoachArrRelInsertInput = {
  data: Array<CoachInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachOnConflict>;
};

/** aggregate avg on columns */
export type CoachAvgFields = {
  __typename?: 'coach_avg_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coach" */
export type CoachAvgOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coach". All fields are combined with a logical 'AND'. */
export type CoachBoolExp = {
  _and?: InputMaybe<Array<CoachBoolExp>>;
  _not?: InputMaybe<CoachBoolExp>;
  _or?: InputMaybe<Array<CoachBoolExp>>;
  about?: InputMaybe<StringComparisonExp>;
  addRacingExp?: InputMaybe<BooleanComparisonExp>;
  allowRequests?: InputMaybe<BooleanComparisonExp>;
  calendarId?: InputMaybe<StringComparisonExp>;
  clinicCoaches?: InputMaybe<ClinicCoachBoolExp>;
  clinicCoaches_aggregate?: InputMaybe<ClinicCoachAggregateBoolExp>;
  coachAvailabilities?: InputMaybe<CoachAvailabilityBoolExp>;
  coachAvailabilities_aggregate?: InputMaybe<CoachAvailabilityAggregateBoolExp>;
  coachCertifications?: InputMaybe<CoachCertificationBoolExp>;
  coachCertifications_aggregate?: InputMaybe<CoachCertificationAggregateBoolExp>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonBoolExp>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateBoolExp>;
  coachCustomers?: InputMaybe<CoachCustomerBoolExp>;
  coachCustomers_aggregate?: InputMaybe<CoachCustomerAggregateBoolExp>;
  coachDisciplines?: InputMaybe<CoachDisciplineBoolExp>;
  coachDisciplines_aggregate?: InputMaybe<CoachDisciplineAggregateBoolExp>;
  coachImages?: InputMaybe<CoachImageBoolExp>;
  coachImages_aggregate?: InputMaybe<CoachImageAggregateBoolExp>;
  coachLessons?: InputMaybe<CoachLessonBoolExp>;
  coachLessons_aggregate?: InputMaybe<CoachLessonAggregateBoolExp>;
  coachLocations?: InputMaybe<CoachLocationBoolExp>;
  coachLocations_aggregate?: InputMaybe<CoachLocationAggregateBoolExp>;
  coachModerationFlags?: InputMaybe<CoachModerationFlagBoolExp>;
  coachModerationFlags_aggregate?: InputMaybe<CoachModerationFlagAggregateBoolExp>;
  coachStudents?: InputMaybe<CoachStudentBoolExp>;
  coachStudents_aggregate?: InputMaybe<CoachStudentAggregateBoolExp>;
  coachUnavailableDates?: InputMaybe<CoachUnavailableDateBoolExp>;
  coachUnavailableDates_aggregate?: InputMaybe<CoachUnavailableDateAggregateBoolExp>;
  coachingRadius?: InputMaybe<IntComparisonExp>;
  coachingSince?: InputMaybe<IntComparisonExp>;
  coachingVibe?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  currencyCode?: InputMaybe<StringComparisonExp>;
  experience?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  introVideoThumbnailUrl?: InputMaybe<StringComparisonExp>;
  introVideoUrl?: InputMaybe<StringComparisonExp>;
  isCPRCertified?: InputMaybe<BooleanComparisonExp>;
  isFirstAidCertified?: InputMaybe<BooleanComparisonExp>;
  isSuperCoach?: InputMaybe<BooleanComparisonExp>;
  lessonLocations?: InputMaybe<LessonLocationBoolExp>;
  lessonLocations_aggregate?: InputMaybe<LessonLocationAggregateBoolExp>;
  offerCustomLesson?: InputMaybe<BooleanComparisonExp>;
  onlineCourses?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateBoolExp>;
  openToTravel?: InputMaybe<BooleanComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuidToDisplay?: InputMaybe<StringComparisonExp>;
  racingExp?: InputMaybe<StringComparisonExp>;
  reviews?: InputMaybe<ReviewBoolExp>;
  reviews_aggregate?: InputMaybe<ReviewAggregateBoolExp>;
  showProfileHelpBanner?: InputMaybe<BooleanComparisonExp>;
  syncLocationToProfile?: InputMaybe<BooleanComparisonExp>;
  tagline?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  userSavedCoaches?: InputMaybe<UserSavedCoachBoolExp>;
  userSavedCoaches_aggregate?: InputMaybe<UserSavedCoachAggregateBoolExp>;
  users?: InputMaybe<UserBoolExp>;
  users_aggregate?: InputMaybe<UserAggregateBoolExp>;
  yearStarted?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "coach" */
export enum CoachConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CoachCuidKey = 'coach_cuid_key',
  /** unique or primary key constraint on columns "userId" */
  CoachUserIdKey = 'coach_userId_key',
  /** unique or primary key constraint on columns "id" */
  UserCoachPkey = 'userCoach_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CoachDeleteAtPathInput = {
  coachingVibe?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CoachDeleteElemInput = {
  coachingVibe?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CoachDeleteKeyInput = {
  coachingVibe?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "coach" */
export type CoachIncInput = {
  coachingRadius?: InputMaybe<Scalars['Int']>;
  coachingSince?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  yearStarted?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coach" */
export type CoachInsertInput = {
  about?: InputMaybe<Scalars['String']>;
  addRacingExp?: InputMaybe<Scalars['Boolean']>;
  allowRequests?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  clinicCoaches?: InputMaybe<ClinicCoachArrRelInsertInput>;
  coachAvailabilities?: InputMaybe<CoachAvailabilityArrRelInsertInput>;
  coachCertifications?: InputMaybe<CoachCertificationArrRelInsertInput>;
  coachCustomerLessons?: InputMaybe<CoachCustomerLessonArrRelInsertInput>;
  coachCustomers?: InputMaybe<CoachCustomerArrRelInsertInput>;
  coachDisciplines?: InputMaybe<CoachDisciplineArrRelInsertInput>;
  coachImages?: InputMaybe<CoachImageArrRelInsertInput>;
  coachLessons?: InputMaybe<CoachLessonArrRelInsertInput>;
  coachLocations?: InputMaybe<CoachLocationArrRelInsertInput>;
  coachModerationFlags?: InputMaybe<CoachModerationFlagArrRelInsertInput>;
  coachStudents?: InputMaybe<CoachStudentArrRelInsertInput>;
  coachUnavailableDates?: InputMaybe<CoachUnavailableDateArrRelInsertInput>;
  coachingRadius?: InputMaybe<Scalars['Int']>;
  coachingSince?: InputMaybe<Scalars['Int']>;
  coachingVibe?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  introVideoThumbnailUrl?: InputMaybe<Scalars['String']>;
  introVideoUrl?: InputMaybe<Scalars['String']>;
  isCPRCertified?: InputMaybe<Scalars['Boolean']>;
  isFirstAidCertified?: InputMaybe<Scalars['Boolean']>;
  isSuperCoach?: InputMaybe<Scalars['Boolean']>;
  lessonLocations?: InputMaybe<LessonLocationArrRelInsertInput>;
  offerCustomLesson?: InputMaybe<Scalars['Boolean']>;
  onlineCourses?: InputMaybe<OnlineCourseArrRelInsertInput>;
  openToTravel?: InputMaybe<Scalars['Boolean']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuidToDisplay?: InputMaybe<Scalars['String']>;
  racingExp?: InputMaybe<Scalars['String']>;
  reviews?: InputMaybe<ReviewArrRelInsertInput>;
  showProfileHelpBanner?: InputMaybe<Scalars['Boolean']>;
  syncLocationToProfile?: InputMaybe<Scalars['Boolean']>;
  tagline?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  userSavedCoaches?: InputMaybe<UserSavedCoachArrRelInsertInput>;
  users?: InputMaybe<UserArrRelInsertInput>;
  yearStarted?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CoachMaxFields = {
  __typename?: 'coach_max_fields';
  about?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  coachingRadius?: Maybe<Scalars['Int']>;
  coachingSince?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  introVideoThumbnailUrl?: Maybe<Scalars['String']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  organizerCuidToDisplay?: Maybe<Scalars['String']>;
  racingExp?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  yearStarted?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "coach" */
export type CoachMaxOrderBy = {
  about?: InputMaybe<OrderBy>;
  calendarId?: InputMaybe<OrderBy>;
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  experience?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoThumbnailUrl?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  organizerCuidToDisplay?: InputMaybe<OrderBy>;
  racingExp?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachMinFields = {
  __typename?: 'coach_min_fields';
  about?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  coachingRadius?: Maybe<Scalars['Int']>;
  coachingSince?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  introVideoThumbnailUrl?: Maybe<Scalars['String']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  organizerCuidToDisplay?: Maybe<Scalars['String']>;
  racingExp?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  yearStarted?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "coach" */
export type CoachMinOrderBy = {
  about?: InputMaybe<OrderBy>;
  calendarId?: InputMaybe<OrderBy>;
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  experience?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoThumbnailUrl?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  organizerCuidToDisplay?: InputMaybe<OrderBy>;
  racingExp?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coach" */
export type CoachMutationResponse = {
  __typename?: 'coach_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coach>;
};

/** input type for inserting object relation for remote table "coach" */
export type CoachObjRelInsertInput = {
  data: CoachInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachOnConflict>;
};

/** on_conflict condition type for table "coach" */
export type CoachOnConflict = {
  constraint: CoachConstraint;
  update_columns?: Array<CoachUpdateColumn>;
  where?: InputMaybe<CoachBoolExp>;
};

/** Ordering options when selecting data from "coach". */
export type CoachOrderBy = {
  about?: InputMaybe<OrderBy>;
  addRacingExp?: InputMaybe<OrderBy>;
  allowRequests?: InputMaybe<OrderBy>;
  calendarId?: InputMaybe<OrderBy>;
  clinicCoaches_aggregate?: InputMaybe<ClinicCoachAggregateOrderBy>;
  coachAvailabilities_aggregate?: InputMaybe<CoachAvailabilityAggregateOrderBy>;
  coachCertifications_aggregate?: InputMaybe<CoachCertificationAggregateOrderBy>;
  coachCustomerLessons_aggregate?: InputMaybe<CoachCustomerLessonAggregateOrderBy>;
  coachCustomers_aggregate?: InputMaybe<CoachCustomerAggregateOrderBy>;
  coachDisciplines_aggregate?: InputMaybe<CoachDisciplineAggregateOrderBy>;
  coachImages_aggregate?: InputMaybe<CoachImageAggregateOrderBy>;
  coachLessons_aggregate?: InputMaybe<CoachLessonAggregateOrderBy>;
  coachLocations_aggregate?: InputMaybe<CoachLocationAggregateOrderBy>;
  coachModerationFlags_aggregate?: InputMaybe<CoachModerationFlagAggregateOrderBy>;
  coachStudents_aggregate?: InputMaybe<CoachStudentAggregateOrderBy>;
  coachUnavailableDates_aggregate?: InputMaybe<CoachUnavailableDateAggregateOrderBy>;
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  coachingVibe?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  experience?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoThumbnailUrl?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  isCPRCertified?: InputMaybe<OrderBy>;
  isFirstAidCertified?: InputMaybe<OrderBy>;
  isSuperCoach?: InputMaybe<OrderBy>;
  lessonLocations_aggregate?: InputMaybe<LessonLocationAggregateOrderBy>;
  offerCustomLesson?: InputMaybe<OrderBy>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateOrderBy>;
  openToTravel?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuidToDisplay?: InputMaybe<OrderBy>;
  racingExp?: InputMaybe<OrderBy>;
  reviews_aggregate?: InputMaybe<ReviewAggregateOrderBy>;
  showProfileHelpBanner?: InputMaybe<OrderBy>;
  syncLocationToProfile?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  userSavedCoaches_aggregate?: InputMaybe<UserSavedCoachAggregateOrderBy>;
  users_aggregate?: InputMaybe<UserAggregateOrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coach */
export type CoachPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoachPrependInput = {
  coachingVibe?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coach" */
export enum CoachSelectColumn {
  /** column name */
  About = 'about',
  /** column name */
  AddRacingExp = 'addRacingExp',
  /** column name */
  AllowRequests = 'allowRequests',
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CoachingRadius = 'coachingRadius',
  /** column name */
  CoachingSince = 'coachingSince',
  /** column name */
  CoachingVibe = 'coachingVibe',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  Experience = 'experience',
  /** column name */
  Id = 'id',
  /** column name */
  IntroVideoThumbnailUrl = 'introVideoThumbnailUrl',
  /** column name */
  IntroVideoUrl = 'introVideoUrl',
  /** column name */
  IsCprCertified = 'isCPRCertified',
  /** column name */
  IsFirstAidCertified = 'isFirstAidCertified',
  /** column name */
  IsSuperCoach = 'isSuperCoach',
  /** column name */
  OfferCustomLesson = 'offerCustomLesson',
  /** column name */
  OpenToTravel = 'openToTravel',
  /** column name */
  OrganizerCuidToDisplay = 'organizerCuidToDisplay',
  /** column name */
  RacingExp = 'racingExp',
  /** column name */
  ShowProfileHelpBanner = 'showProfileHelpBanner',
  /** column name */
  SyncLocationToProfile = 'syncLocationToProfile',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  YearStarted = 'yearStarted'
}

/** select "coach_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coach" */
export enum CoachSelectColumnCoachAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AddRacingExp = 'addRacingExp',
  /** column name */
  AllowRequests = 'allowRequests',
  /** column name */
  IsCprCertified = 'isCPRCertified',
  /** column name */
  IsFirstAidCertified = 'isFirstAidCertified',
  /** column name */
  IsSuperCoach = 'isSuperCoach',
  /** column name */
  OfferCustomLesson = 'offerCustomLesson',
  /** column name */
  OpenToTravel = 'openToTravel',
  /** column name */
  ShowProfileHelpBanner = 'showProfileHelpBanner',
  /** column name */
  SyncLocationToProfile = 'syncLocationToProfile'
}

/** select "coach_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coach" */
export enum CoachSelectColumnCoachAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AddRacingExp = 'addRacingExp',
  /** column name */
  AllowRequests = 'allowRequests',
  /** column name */
  IsCprCertified = 'isCPRCertified',
  /** column name */
  IsFirstAidCertified = 'isFirstAidCertified',
  /** column name */
  IsSuperCoach = 'isSuperCoach',
  /** column name */
  OfferCustomLesson = 'offerCustomLesson',
  /** column name */
  OpenToTravel = 'openToTravel',
  /** column name */
  ShowProfileHelpBanner = 'showProfileHelpBanner',
  /** column name */
  SyncLocationToProfile = 'syncLocationToProfile'
}

/** input type for updating data in table "coach" */
export type CoachSetInput = {
  about?: InputMaybe<Scalars['String']>;
  addRacingExp?: InputMaybe<Scalars['Boolean']>;
  allowRequests?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  coachingRadius?: InputMaybe<Scalars['Int']>;
  coachingSince?: InputMaybe<Scalars['Int']>;
  coachingVibe?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  introVideoThumbnailUrl?: InputMaybe<Scalars['String']>;
  introVideoUrl?: InputMaybe<Scalars['String']>;
  isCPRCertified?: InputMaybe<Scalars['Boolean']>;
  isFirstAidCertified?: InputMaybe<Scalars['Boolean']>;
  isSuperCoach?: InputMaybe<Scalars['Boolean']>;
  offerCustomLesson?: InputMaybe<Scalars['Boolean']>;
  openToTravel?: InputMaybe<Scalars['Boolean']>;
  organizerCuidToDisplay?: InputMaybe<Scalars['String']>;
  racingExp?: InputMaybe<Scalars['String']>;
  showProfileHelpBanner?: InputMaybe<Scalars['Boolean']>;
  syncLocationToProfile?: InputMaybe<Scalars['Boolean']>;
  tagline?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
  yearStarted?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoachStddevFields = {
  __typename?: 'coach_stddev_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coach" */
export type CoachStddevOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CoachStddevPopFields = {
  __typename?: 'coach_stddev_pop_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coach" */
export type CoachStddevPopOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CoachStddevSampFields = {
  __typename?: 'coach_stddev_samp_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coach" */
export type CoachStddevSampOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CoachSumFields = {
  __typename?: 'coach_sum_fields';
  coachingRadius?: Maybe<Scalars['Int']>;
  coachingSince?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  yearStarted?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coach" */
export type CoachSumOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** update columns of table "coach" */
export enum CoachUpdateColumn {
  /** column name */
  About = 'about',
  /** column name */
  AddRacingExp = 'addRacingExp',
  /** column name */
  AllowRequests = 'allowRequests',
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CoachingRadius = 'coachingRadius',
  /** column name */
  CoachingSince = 'coachingSince',
  /** column name */
  CoachingVibe = 'coachingVibe',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  Experience = 'experience',
  /** column name */
  Id = 'id',
  /** column name */
  IntroVideoThumbnailUrl = 'introVideoThumbnailUrl',
  /** column name */
  IntroVideoUrl = 'introVideoUrl',
  /** column name */
  IsCprCertified = 'isCPRCertified',
  /** column name */
  IsFirstAidCertified = 'isFirstAidCertified',
  /** column name */
  IsSuperCoach = 'isSuperCoach',
  /** column name */
  OfferCustomLesson = 'offerCustomLesson',
  /** column name */
  OpenToTravel = 'openToTravel',
  /** column name */
  OrganizerCuidToDisplay = 'organizerCuidToDisplay',
  /** column name */
  RacingExp = 'racingExp',
  /** column name */
  ShowProfileHelpBanner = 'showProfileHelpBanner',
  /** column name */
  SyncLocationToProfile = 'syncLocationToProfile',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  YearStarted = 'yearStarted'
}

export type CoachUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CoachAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CoachDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CoachDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CoachDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoachPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachSetInput>;
  where: CoachBoolExp;
};

/** aggregate var_pop on columns */
export type CoachVarPopFields = {
  __typename?: 'coach_var_pop_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coach" */
export type CoachVarPopOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CoachVarSampFields = {
  __typename?: 'coach_var_samp_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coach" */
export type CoachVarSampOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoachVarianceFields = {
  __typename?: 'coach_variance_fields';
  coachingRadius?: Maybe<Scalars['Float']>;
  coachingSince?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  yearStarted?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coach" */
export type CoachVarianceOrderBy = {
  coachingRadius?: InputMaybe<OrderBy>;
  coachingSince?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  yearStarted?: InputMaybe<OrderBy>;
};

/** Course for an event */
export type Course = Node & {
  __typename?: 'course';
  /** An array relationship */
  categoryCourses: Array<CategoryCourse>;
  /** An aggregate relationship */
  categoryCourses_aggregate: CategoryCourseAggregate;
  /** An array relationship connection */
  categoryCourses_connection: CategoryCourseConnection;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  courseLength?: Maybe<Scalars['numeric']>;
  courseLengthSecondary?: Maybe<Scalars['numeric']>;
  courseLengthType: Scalars['String'];
  courseLengthTypeSecondary?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  difficultyName: Scalars['String'];
  distance?: Maybe<Scalars['numeric']>;
  elevationHigh?: Maybe<Scalars['numeric']>;
  elevationLow?: Maybe<Scalars['numeric']>;
  elevationUnit?: Maybe<Scalars['String']>;
  endLat?: Maybe<Scalars['numeric']>;
  endLng?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An object relationship */
  eventDiscipline: EventDiscipline;
  eventDisciplineCuid: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  geoJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['ID'];
  maxGrade?: Maybe<Scalars['numeric']>;
  minGrade?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Int']>;
  platformUrl?: Maybe<Scalars['String']>;
  startLat?: Maybe<Scalars['numeric']>;
  startLng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  totalElevationGain?: Maybe<Scalars['numeric']>;
  totalElevationLoss?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};


/** Course for an event */
export type CourseCategoryCoursesArgs = {
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** Course for an event */
export type CourseCategoryCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** Course for an event */
export type CourseCategoryCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


/** Course for an event */
export type CourseGeoJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "course" */
export type CourseConnection = {
  __typename?: 'courseConnection';
  edges: Array<CourseEdge>;
  pageInfo: PageInfo;
};

export type CourseEdge = {
  __typename?: 'courseEdge';
  cursor: Scalars['String'];
  node: Course;
};

/** aggregated selection of "course" */
export type CourseAggregate = {
  __typename?: 'course_aggregate';
  aggregate?: Maybe<CourseAggregateFields>;
  nodes: Array<Course>;
};

export type CourseAggregateBoolExp = {
  count?: InputMaybe<CourseAggregateBoolExpCount>;
};

export type CourseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CourseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "course" */
export type CourseAggregateFields = {
  __typename?: 'course_aggregate_fields';
  avg?: Maybe<CourseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourseMaxFields>;
  min?: Maybe<CourseMinFields>;
  stddev?: Maybe<CourseStddevFields>;
  stddev_pop?: Maybe<CourseStddevPopFields>;
  stddev_samp?: Maybe<CourseStddevSampFields>;
  sum?: Maybe<CourseSumFields>;
  var_pop?: Maybe<CourseVarPopFields>;
  var_samp?: Maybe<CourseVarSampFields>;
  variance?: Maybe<CourseVarianceFields>;
};


/** aggregate fields of "course" */
export type CourseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course" */
export type CourseAggregateOrderBy = {
  avg?: InputMaybe<CourseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CourseMaxOrderBy>;
  min?: InputMaybe<CourseMinOrderBy>;
  stddev?: InputMaybe<CourseStddevOrderBy>;
  stddev_pop?: InputMaybe<CourseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CourseStddevSampOrderBy>;
  sum?: InputMaybe<CourseSumOrderBy>;
  var_pop?: InputMaybe<CourseVarPopOrderBy>;
  var_samp?: InputMaybe<CourseVarSampOrderBy>;
  variance?: InputMaybe<CourseVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CourseAppendInput = {
  geoJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "course" */
export type CourseArrRelInsertInput = {
  data: Array<CourseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CourseOnConflict>;
};

/** aggregate avg on columns */
export type CourseAvgFields = {
  __typename?: 'course_avg_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course" */
export type CourseAvgOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "course". All fields are combined with a logical 'AND'. */
export type CourseBoolExp = {
  _and?: InputMaybe<Array<CourseBoolExp>>;
  _not?: InputMaybe<CourseBoolExp>;
  _or?: InputMaybe<Array<CourseBoolExp>>;
  categoryCourses?: InputMaybe<CategoryCourseBoolExp>;
  categoryCourses_aggregate?: InputMaybe<CategoryCourseAggregateBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  courseLength?: InputMaybe<NumericComparisonExp>;
  courseLengthSecondary?: InputMaybe<NumericComparisonExp>;
  courseLengthType?: InputMaybe<StringComparisonExp>;
  courseLengthTypeSecondary?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  difficultyName?: InputMaybe<StringComparisonExp>;
  distance?: InputMaybe<NumericComparisonExp>;
  elevationHigh?: InputMaybe<NumericComparisonExp>;
  elevationLow?: InputMaybe<NumericComparisonExp>;
  elevationUnit?: InputMaybe<StringComparisonExp>;
  endLat?: InputMaybe<NumericComparisonExp>;
  endLng?: InputMaybe<NumericComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventDiscipline?: InputMaybe<EventDisciplineBoolExp>;
  eventDisciplineCuid?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  geoJson?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  maxGrade?: InputMaybe<NumericComparisonExp>;
  minGrade?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  platform?: InputMaybe<StringComparisonExp>;
  platformId?: InputMaybe<IntComparisonExp>;
  platformUrl?: InputMaybe<StringComparisonExp>;
  startLat?: InputMaybe<NumericComparisonExp>;
  startLng?: InputMaybe<NumericComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  totalElevationGain?: InputMaybe<NumericComparisonExp>;
  totalElevationLoss?: InputMaybe<NumericComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "course" */
export enum CourseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CourseCuidKey = 'course_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CourseIdKey = 'course_id_key',
  /** unique or primary key constraint on columns "id" */
  CoursePkey = 'course_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CourseDeleteAtPathInput = {
  geoJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CourseDeleteElemInput = {
  geoJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CourseDeleteKeyInput = {
  geoJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "course" */
export type CourseIncInput = {
  courseLength?: InputMaybe<Scalars['numeric']>;
  courseLengthSecondary?: InputMaybe<Scalars['numeric']>;
  distance?: InputMaybe<Scalars['numeric']>;
  elevationHigh?: InputMaybe<Scalars['numeric']>;
  elevationLow?: InputMaybe<Scalars['numeric']>;
  endLat?: InputMaybe<Scalars['numeric']>;
  endLng?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxGrade?: InputMaybe<Scalars['numeric']>;
  minGrade?: InputMaybe<Scalars['Int']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<Scalars['Int']>;
  startLat?: InputMaybe<Scalars['numeric']>;
  startLng?: InputMaybe<Scalars['numeric']>;
  totalElevationGain?: InputMaybe<Scalars['numeric']>;
  totalElevationLoss?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "course" */
export type CourseInsertInput = {
  categoryCourses?: InputMaybe<CategoryCourseArrRelInsertInput>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  courseLength?: InputMaybe<Scalars['numeric']>;
  courseLengthSecondary?: InputMaybe<Scalars['numeric']>;
  courseLengthType?: InputMaybe<Scalars['String']>;
  courseLengthTypeSecondary?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  difficultyName?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['numeric']>;
  elevationHigh?: InputMaybe<Scalars['numeric']>;
  elevationLow?: InputMaybe<Scalars['numeric']>;
  elevationUnit?: InputMaybe<Scalars['String']>;
  endLat?: InputMaybe<Scalars['numeric']>;
  endLng?: InputMaybe<Scalars['numeric']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventDiscipline?: InputMaybe<EventDisciplineObjRelInsertInput>;
  eventDisciplineCuid?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  geoJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxGrade?: InputMaybe<Scalars['numeric']>;
  minGrade?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<Scalars['Int']>;
  platformUrl?: InputMaybe<Scalars['String']>;
  startLat?: InputMaybe<Scalars['numeric']>;
  startLng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  totalElevationGain?: InputMaybe<Scalars['numeric']>;
  totalElevationLoss?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourseMaxFields = {
  __typename?: 'course_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  courseLength?: Maybe<Scalars['numeric']>;
  courseLengthSecondary?: Maybe<Scalars['numeric']>;
  courseLengthType?: Maybe<Scalars['String']>;
  courseLengthTypeSecondary?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  difficultyName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['numeric']>;
  elevationHigh?: Maybe<Scalars['numeric']>;
  elevationLow?: Maybe<Scalars['numeric']>;
  elevationUnit?: Maybe<Scalars['String']>;
  endLat?: Maybe<Scalars['numeric']>;
  endLng?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventDisciplineCuid?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  maxGrade?: Maybe<Scalars['numeric']>;
  minGrade?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Int']>;
  platformUrl?: Maybe<Scalars['String']>;
  startLat?: Maybe<Scalars['numeric']>;
  startLng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  totalElevationGain?: Maybe<Scalars['numeric']>;
  totalElevationLoss?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course" */
export type CourseMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  courseLengthType?: InputMaybe<OrderBy>;
  courseLengthTypeSecondary?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  difficultyName?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  elevationUnit?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  platform?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  platformUrl?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CourseMinFields = {
  __typename?: 'course_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  courseLength?: Maybe<Scalars['numeric']>;
  courseLengthSecondary?: Maybe<Scalars['numeric']>;
  courseLengthType?: Maybe<Scalars['String']>;
  courseLengthTypeSecondary?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  difficultyName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['numeric']>;
  elevationHigh?: Maybe<Scalars['numeric']>;
  elevationLow?: Maybe<Scalars['numeric']>;
  elevationUnit?: Maybe<Scalars['String']>;
  endLat?: Maybe<Scalars['numeric']>;
  endLng?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventDisciplineCuid?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  maxGrade?: Maybe<Scalars['numeric']>;
  minGrade?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Int']>;
  platformUrl?: Maybe<Scalars['String']>;
  startLat?: Maybe<Scalars['numeric']>;
  startLng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  totalElevationGain?: Maybe<Scalars['numeric']>;
  totalElevationLoss?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course" */
export type CourseMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  courseLengthType?: InputMaybe<OrderBy>;
  courseLengthTypeSecondary?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  difficultyName?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  elevationUnit?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  platform?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  platformUrl?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "course" */
export type CourseMutationResponse = {
  __typename?: 'course_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Course>;
};

/** input type for inserting object relation for remote table "course" */
export type CourseObjRelInsertInput = {
  data: CourseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CourseOnConflict>;
};

/** on_conflict condition type for table "course" */
export type CourseOnConflict = {
  constraint: CourseConstraint;
  update_columns?: Array<CourseUpdateColumn>;
  where?: InputMaybe<CourseBoolExp>;
};

/** Ordering options when selecting data from "course". */
export type CourseOrderBy = {
  categoryCourses_aggregate?: InputMaybe<CategoryCourseAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  courseLengthType?: InputMaybe<OrderBy>;
  courseLengthTypeSecondary?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  difficultyName?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  elevationUnit?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventDiscipline?: InputMaybe<EventDisciplineOrderBy>;
  eventDisciplineCuid?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  geoJson?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  platform?: InputMaybe<OrderBy>;
  platformId?: InputMaybe<OrderBy>;
  platformUrl?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course */
export type CoursePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CoursePrependInput = {
  geoJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "course" */
export enum CourseSelectColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CourseLength = 'courseLength',
  /** column name */
  CourseLengthSecondary = 'courseLengthSecondary',
  /** column name */
  CourseLengthType = 'courseLengthType',
  /** column name */
  CourseLengthTypeSecondary = 'courseLengthTypeSecondary',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  DifficultyName = 'difficultyName',
  /** column name */
  Distance = 'distance',
  /** column name */
  ElevationHigh = 'elevationHigh',
  /** column name */
  ElevationLow = 'elevationLow',
  /** column name */
  ElevationUnit = 'elevationUnit',
  /** column name */
  EndLat = 'endLat',
  /** column name */
  EndLng = 'endLng',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventDisciplineCuid = 'eventDisciplineCuid',
  /** column name */
  FullName = 'fullName',
  /** column name */
  GeoJson = 'geoJson',
  /** column name */
  Id = 'id',
  /** column name */
  MaxGrade = 'maxGrade',
  /** column name */
  MinGrade = 'minGrade',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformId = 'platformId',
  /** column name */
  PlatformUrl = 'platformUrl',
  /** column name */
  StartLat = 'startLat',
  /** column name */
  StartLng = 'startLng',
  /** column name */
  State = 'state',
  /** column name */
  TotalElevationGain = 'totalElevationGain',
  /** column name */
  TotalElevationLoss = 'totalElevationLoss',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course" */
export type CourseSetInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  courseLength?: InputMaybe<Scalars['numeric']>;
  courseLengthSecondary?: InputMaybe<Scalars['numeric']>;
  courseLengthType?: InputMaybe<Scalars['String']>;
  courseLengthTypeSecondary?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  difficultyName?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['numeric']>;
  elevationHigh?: InputMaybe<Scalars['numeric']>;
  elevationLow?: InputMaybe<Scalars['numeric']>;
  elevationUnit?: InputMaybe<Scalars['String']>;
  endLat?: InputMaybe<Scalars['numeric']>;
  endLng?: InputMaybe<Scalars['numeric']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventDisciplineCuid?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  geoJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  maxGrade?: InputMaybe<Scalars['numeric']>;
  minGrade?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<Scalars['Int']>;
  platformUrl?: InputMaybe<Scalars['String']>;
  startLat?: InputMaybe<Scalars['numeric']>;
  startLng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  totalElevationGain?: InputMaybe<Scalars['numeric']>;
  totalElevationLoss?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CourseStddevFields = {
  __typename?: 'course_stddev_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course" */
export type CourseStddevOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CourseStddevPopFields = {
  __typename?: 'course_stddev_pop_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course" */
export type CourseStddevPopOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CourseStddevSampFields = {
  __typename?: 'course_stddev_samp_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course" */
export type CourseStddevSampOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CourseSumFields = {
  __typename?: 'course_sum_fields';
  courseLength?: Maybe<Scalars['numeric']>;
  courseLengthSecondary?: Maybe<Scalars['numeric']>;
  distance?: Maybe<Scalars['numeric']>;
  elevationHigh?: Maybe<Scalars['numeric']>;
  elevationLow?: Maybe<Scalars['numeric']>;
  endLat?: Maybe<Scalars['numeric']>;
  endLng?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  maxGrade?: Maybe<Scalars['numeric']>;
  minGrade?: Maybe<Scalars['Int']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Int']>;
  startLat?: Maybe<Scalars['numeric']>;
  startLng?: Maybe<Scalars['numeric']>;
  totalElevationGain?: Maybe<Scalars['numeric']>;
  totalElevationLoss?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "course" */
export type CourseSumOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** update columns of table "course" */
export enum CourseUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CourseLength = 'courseLength',
  /** column name */
  CourseLengthSecondary = 'courseLengthSecondary',
  /** column name */
  CourseLengthType = 'courseLengthType',
  /** column name */
  CourseLengthTypeSecondary = 'courseLengthTypeSecondary',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  DifficultyName = 'difficultyName',
  /** column name */
  Distance = 'distance',
  /** column name */
  ElevationHigh = 'elevationHigh',
  /** column name */
  ElevationLow = 'elevationLow',
  /** column name */
  ElevationUnit = 'elevationUnit',
  /** column name */
  EndLat = 'endLat',
  /** column name */
  EndLng = 'endLng',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventDisciplineCuid = 'eventDisciplineCuid',
  /** column name */
  FullName = 'fullName',
  /** column name */
  GeoJson = 'geoJson',
  /** column name */
  Id = 'id',
  /** column name */
  MaxGrade = 'maxGrade',
  /** column name */
  MinGrade = 'minGrade',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformId = 'platformId',
  /** column name */
  PlatformUrl = 'platformUrl',
  /** column name */
  StartLat = 'startLat',
  /** column name */
  StartLng = 'startLng',
  /** column name */
  State = 'state',
  /** column name */
  TotalElevationGain = 'totalElevationGain',
  /** column name */
  TotalElevationLoss = 'totalElevationLoss',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CourseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CourseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CourseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CourseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CourseDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CourseIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CoursePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseSetInput>;
  where: CourseBoolExp;
};

/** aggregate var_pop on columns */
export type CourseVarPopFields = {
  __typename?: 'course_var_pop_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course" */
export type CourseVarPopOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CourseVarSampFields = {
  __typename?: 'course_var_samp_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course" */
export type CourseVarSampOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CourseVarianceFields = {
  __typename?: 'course_variance_fields';
  courseLength?: Maybe<Scalars['Float']>;
  courseLengthSecondary?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  elevationHigh?: Maybe<Scalars['Float']>;
  elevationLow?: Maybe<Scalars['Float']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxGrade?: Maybe<Scalars['Float']>;
  minGrade?: Maybe<Scalars['Float']>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: Maybe<Scalars['Float']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  totalElevationGain?: Maybe<Scalars['Float']>;
  totalElevationLoss?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course" */
export type CourseVarianceOrderBy = {
  courseLength?: InputMaybe<OrderBy>;
  courseLengthSecondary?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  elevationHigh?: InputMaybe<OrderBy>;
  elevationLow?: InputMaybe<OrderBy>;
  endLat?: InputMaybe<OrderBy>;
  endLng?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxGrade?: InputMaybe<OrderBy>;
  minGrade?: InputMaybe<OrderBy>;
  /** Id used to query service i.e. Fatmap or RideWithGPS */
  platformId?: InputMaybe<OrderBy>;
  startLat?: InputMaybe<OrderBy>;
  startLng?: InputMaybe<OrderBy>;
  totalElevationGain?: InputMaybe<OrderBy>;
  totalElevationLoss?: InputMaybe<OrderBy>;
};

/** Coach and Organizer Invites */
export type CreatorInvite = Node & {
  __typename?: 'creatorInvite';
  acceptedAt?: Maybe<Scalars['timestamptz']>;
  acceptedUserId?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  inviteEmail?: Maybe<Scalars['String']>;
  inviterUserId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userByAcceptor?: Maybe<User>;
  /** An object relationship */
  userByInviter: User;
};

/** A Relay connection object on "creatorInvite" */
export type CreatorInviteConnection = {
  __typename?: 'creatorInviteConnection';
  edges: Array<CreatorInviteEdge>;
  pageInfo: PageInfo;
};

export type CreatorInviteEdge = {
  __typename?: 'creatorInviteEdge';
  cursor: Scalars['String'];
  node: CreatorInvite;
};

/** aggregated selection of "creatorInvite" */
export type CreatorInviteAggregate = {
  __typename?: 'creatorInvite_aggregate';
  aggregate?: Maybe<CreatorInviteAggregateFields>;
  nodes: Array<CreatorInvite>;
};

export type CreatorInviteAggregateBoolExp = {
  count?: InputMaybe<CreatorInviteAggregateBoolExpCount>;
};

export type CreatorInviteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreatorInviteBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "creatorInvite" */
export type CreatorInviteAggregateFields = {
  __typename?: 'creatorInvite_aggregate_fields';
  avg?: Maybe<CreatorInviteAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CreatorInviteMaxFields>;
  min?: Maybe<CreatorInviteMinFields>;
  stddev?: Maybe<CreatorInviteStddevFields>;
  stddev_pop?: Maybe<CreatorInviteStddevPopFields>;
  stddev_samp?: Maybe<CreatorInviteStddevSampFields>;
  sum?: Maybe<CreatorInviteSumFields>;
  var_pop?: Maybe<CreatorInviteVarPopFields>;
  var_samp?: Maybe<CreatorInviteVarSampFields>;
  variance?: Maybe<CreatorInviteVarianceFields>;
};


/** aggregate fields of "creatorInvite" */
export type CreatorInviteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "creatorInvite" */
export type CreatorInviteAggregateOrderBy = {
  avg?: InputMaybe<CreatorInviteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CreatorInviteMaxOrderBy>;
  min?: InputMaybe<CreatorInviteMinOrderBy>;
  stddev?: InputMaybe<CreatorInviteStddevOrderBy>;
  stddev_pop?: InputMaybe<CreatorInviteStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CreatorInviteStddevSampOrderBy>;
  sum?: InputMaybe<CreatorInviteSumOrderBy>;
  var_pop?: InputMaybe<CreatorInviteVarPopOrderBy>;
  var_samp?: InputMaybe<CreatorInviteVarSampOrderBy>;
  variance?: InputMaybe<CreatorInviteVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "creatorInvite" */
export type CreatorInviteArrRelInsertInput = {
  data: Array<CreatorInviteInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreatorInviteOnConflict>;
};

/** aggregate avg on columns */
export type CreatorInviteAvgFields = {
  __typename?: 'creatorInvite_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "creatorInvite" */
export type CreatorInviteAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "creatorInvite". All fields are combined with a logical 'AND'. */
export type CreatorInviteBoolExp = {
  _and?: InputMaybe<Array<CreatorInviteBoolExp>>;
  _not?: InputMaybe<CreatorInviteBoolExp>;
  _or?: InputMaybe<Array<CreatorInviteBoolExp>>;
  acceptedAt?: InputMaybe<TimestamptzComparisonExp>;
  acceptedUserId?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inviteEmail?: InputMaybe<StringComparisonExp>;
  inviterUserId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userByAcceptor?: InputMaybe<UserBoolExp>;
  userByInviter?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "creatorInvite" */
export enum CreatorInviteConstraint {
  /** unique or primary key constraint on columns "cuid" */
  CreatorInviteCuidKey = 'creatorInvite_cuid_key',
  /** unique or primary key constraint on columns "id" */
  CreatorInvitePkey = 'creatorInvite_pkey'
}

/** input type for incrementing numeric columns in table "creatorInvite" */
export type CreatorInviteIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "creatorInvite" */
export type CreatorInviteInsertInput = {
  acceptedAt?: InputMaybe<Scalars['timestamptz']>;
  acceptedUserId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteEmail?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userByAcceptor?: InputMaybe<UserObjRelInsertInput>;
  userByInviter?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type CreatorInviteMaxFields = {
  __typename?: 'creatorInvite_max_fields';
  acceptedAt?: Maybe<Scalars['timestamptz']>;
  acceptedUserId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteEmail?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "creatorInvite" */
export type CreatorInviteMaxOrderBy = {
  acceptedAt?: InputMaybe<OrderBy>;
  acceptedUserId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteEmail?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CreatorInviteMinFields = {
  __typename?: 'creatorInvite_min_fields';
  acceptedAt?: Maybe<Scalars['timestamptz']>;
  acceptedUserId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteEmail?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "creatorInvite" */
export type CreatorInviteMinOrderBy = {
  acceptedAt?: InputMaybe<OrderBy>;
  acceptedUserId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteEmail?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "creatorInvite" */
export type CreatorInviteMutationResponse = {
  __typename?: 'creatorInvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreatorInvite>;
};

/** on_conflict condition type for table "creatorInvite" */
export type CreatorInviteOnConflict = {
  constraint: CreatorInviteConstraint;
  update_columns?: Array<CreatorInviteUpdateColumn>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};

/** Ordering options when selecting data from "creatorInvite". */
export type CreatorInviteOrderBy = {
  acceptedAt?: InputMaybe<OrderBy>;
  acceptedUserId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteEmail?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userByAcceptor?: InputMaybe<UserOrderBy>;
  userByInviter?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: creatorInvite */
export type CreatorInvitePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "creatorInvite" */
export enum CreatorInviteSelectColumn {
  /** column name */
  AcceptedAt = 'acceptedAt',
  /** column name */
  AcceptedUserId = 'acceptedUserId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  InviteEmail = 'inviteEmail',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "creatorInvite" */
export type CreatorInviteSetInput = {
  acceptedAt?: InputMaybe<Scalars['timestamptz']>;
  acceptedUserId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteEmail?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CreatorInviteStddevFields = {
  __typename?: 'creatorInvite_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "creatorInvite" */
export type CreatorInviteStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CreatorInviteStddevPopFields = {
  __typename?: 'creatorInvite_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "creatorInvite" */
export type CreatorInviteStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CreatorInviteStddevSampFields = {
  __typename?: 'creatorInvite_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "creatorInvite" */
export type CreatorInviteStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CreatorInviteSumFields = {
  __typename?: 'creatorInvite_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "creatorInvite" */
export type CreatorInviteSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "creatorInvite" */
export enum CreatorInviteUpdateColumn {
  /** column name */
  AcceptedAt = 'acceptedAt',
  /** column name */
  AcceptedUserId = 'acceptedUserId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  InviteEmail = 'inviteEmail',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CreatorInviteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreatorInviteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreatorInviteSetInput>;
  where: CreatorInviteBoolExp;
};

/** aggregate var_pop on columns */
export type CreatorInviteVarPopFields = {
  __typename?: 'creatorInvite_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "creatorInvite" */
export type CreatorInviteVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CreatorInviteVarSampFields = {
  __typename?: 'creatorInvite_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "creatorInvite" */
export type CreatorInviteVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CreatorInviteVarianceFields = {
  __typename?: 'creatorInvite_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "creatorInvite" */
export type CreatorInviteVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** All Reggy Events. clinicRegDeadline is in seconds */
export type Event = Node & {
  __typename?: 'event';
  /** An object relationship */
  AccessName: AccessName;
  activity: ActivityTypeEnum;
  /** An object relationship */
  activityType: ActivityType;
  additionalContactInfo?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  /** An array relationship */
  categories: Array<Category>;
  /** An aggregate relationship */
  categories_aggregate: CategoryAggregate;
  /** An array relationship connection */
  categories_connection: CategoryConnection;
  /** An array relationship */
  clinics: Array<Clinic>;
  /** An aggregate relationship */
  clinics_aggregate: ClinicAggregate;
  /** An array relationship connection */
  clinics_connection: ClinicConnection;
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections: Scalars['jsonb'];
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregate relationship */
  courses_aggregate: CourseAggregate;
  /** An array relationship connection */
  courses_connection: CourseConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  currencyCode: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  eventAccess: AccessNameEnum;
  /** An array relationship */
  eventAdditionalInfos: Array<EventAdditionalInfo>;
  /** An aggregate relationship */
  eventAdditionalInfos_aggregate: EventAdditionalInfoAggregate;
  /** An array relationship connection */
  eventAdditionalInfos_connection: EventAdditionalInfoConnection;
  /** An array relationship */
  eventAmenities: Array<EventAmenity>;
  /** An aggregate relationship */
  eventAmenities_aggregate: EventAmenityAggregate;
  /** An array relationship connection */
  eventAmenities_connection: EventAmenityConnection;
  /** An array relationship */
  eventBasicWaivers: Array<EventBasicWaiver>;
  /** An aggregate relationship */
  eventBasicWaivers_aggregate: EventBasicWaiverAggregate;
  /** An array relationship connection */
  eventBasicWaivers_connection: EventBasicWaiverConnection;
  /** An array relationship */
  eventChecklistItems: Array<EventChecklistItem>;
  /** An aggregate relationship */
  eventChecklistItems_aggregate: EventChecklistItemAggregate;
  /** An array relationship connection */
  eventChecklistItems_connection: EventChecklistItemConnection;
  /** An array relationship */
  eventClinicDayDurations: Array<EventClinicDayDuration>;
  /** An aggregate relationship */
  eventClinicDayDurations_aggregate: EventClinicDayDurationAggregate;
  /** An array relationship connection */
  eventClinicDayDurations_connection: EventClinicDayDurationConnection;
  /** An array relationship */
  eventCustomQuestions: Array<EventCustomQuestion>;
  /** An aggregate relationship */
  eventCustomQuestions_aggregate: EventCustomQuestionAggregate;
  /** An array relationship connection */
  eventCustomQuestions_connection: EventCustomQuestionConnection;
  /** An array relationship */
  eventDisciplines: Array<EventDiscipline>;
  /** An aggregate relationship */
  eventDisciplines_aggregate: EventDisciplineAggregate;
  /** An array relationship connection */
  eventDisciplines_connection: EventDisciplineConnection;
  /** An array relationship */
  eventImages: Array<EventImage>;
  /** An aggregate relationship */
  eventImages_aggregate: EventImageAggregate;
  /** An array relationship connection */
  eventImages_connection: EventImageConnection;
  /** An array relationship */
  eventMerchandises: Array<EventMerchandise>;
  /** An aggregate relationship */
  eventMerchandises_aggregate: EventMerchandiseAggregate;
  /** An array relationship connection */
  eventMerchandises_connection: EventMerchandiseConnection;
  /** An object relationship */
  eventMetadata: EventMetadata;
  eventMetadataCuid: Scalars['String'];
  /** An array relationship */
  eventModerationFlags: Array<EventModerationFlag>;
  /** An aggregate relationship */
  eventModerationFlags_aggregate: EventModerationFlagAggregate;
  /** An array relationship connection */
  eventModerationFlags_connection: EventModerationFlagConnection;
  /** An array relationship */
  eventPitSpaces: Array<EventPitSpace>;
  /** An aggregate relationship */
  eventPitSpaces_aggregate: EventPitSpaceAggregate;
  /** An array relationship connection */
  eventPitSpaces_connection: EventPitSpaceConnection;
  /** An array relationship */
  eventPricingChanges: Array<EventPricingChange>;
  /** An aggregate relationship */
  eventPricingChanges_aggregate: EventPricingChangeAggregate;
  /** An array relationship connection */
  eventPricingChanges_connection: EventPricingChangeConnection;
  /** An array relationship */
  eventPricingDates: Array<EventPricingDate>;
  /** An aggregate relationship */
  eventPricingDates_aggregate: EventPricingDateAggregate;
  /** An array relationship connection */
  eventPricingDates_connection: EventPricingDateConnection;
  /** An array relationship */
  eventPricingGroups: Array<EventPricingGroup>;
  /** An aggregate relationship */
  eventPricingGroups_aggregate: EventPricingGroupAggregate;
  /** An array relationship connection */
  eventPricingGroups_connection: EventPricingGroupConnection;
  /** An array relationship */
  eventPrivateLinks: Array<EventPrivateLink>;
  /** An array relationship */
  eventPrivateLinksByEventcuid: Array<EventPrivateLink>;
  /** An aggregate relationship */
  eventPrivateLinksByEventcuid_aggregate: EventPrivateLinkAggregate;
  /** An array relationship connection */
  eventPrivateLinksByEventcuid_connection: EventPrivateLinkConnection;
  /** An aggregate relationship */
  eventPrivateLinks_aggregate: EventPrivateLinkAggregate;
  /** An array relationship connection */
  eventPrivateLinks_connection: EventPrivateLinkConnection;
  /** An array relationship */
  eventPrograms: Array<EventProgram>;
  /** An aggregate relationship */
  eventPrograms_aggregate: EventProgramAggregate;
  /** An array relationship connection */
  eventPrograms_connection: EventProgramConnection;
  /** An array relationship */
  eventPromoCodes: Array<EventPromoCode>;
  /** An aggregate relationship */
  eventPromoCodes_aggregate: EventPromoCodeAggregate;
  /** An array relationship connection */
  eventPromoCodes_connection: EventPromoCodeConnection;
  /** An array relationship */
  eventQuestions: Array<EventQuestion>;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestionAggregate;
  /** An array relationship connection */
  eventQuestions_connection: EventQuestionConnection;
  /** An array relationship */
  eventSanctions: Array<EventSanction>;
  /** An aggregate relationship */
  eventSanctions_aggregate: EventSanctionAggregate;
  /** An array relationship connection */
  eventSanctions_connection: EventSanctionConnection;
  /** An array relationship */
  eventScheduleDates: Array<EventScheduleDate>;
  /** An aggregate relationship */
  eventScheduleDates_aggregate: EventScheduleDateAggregate;
  /** An array relationship connection */
  eventScheduleDates_connection: EventScheduleDateConnection;
  /** An array relationship */
  eventScheduleTypeCustoms: Array<EventScheduleTypeCustom>;
  /** An aggregate relationship */
  eventScheduleTypeCustoms_aggregate: EventScheduleTypeCustomAggregate;
  /** An array relationship connection */
  eventScheduleTypeCustoms_connection: EventScheduleTypeCustomConnection;
  /** An array relationship */
  eventScheduleTypePresets: Array<EventScheduleTypePreset>;
  /** An aggregate relationship */
  eventScheduleTypePresets_aggregate: EventScheduleTypePresetAggregate;
  /** An array relationship connection */
  eventScheduleTypePresets_connection: EventScheduleTypePresetConnection;
  /** An array relationship */
  eventSchedules: Array<EventSchedule>;
  /** An aggregate relationship */
  eventSchedules_aggregate: EventScheduleAggregate;
  /** An array relationship connection */
  eventSchedules_connection: EventScheduleConnection;
  /** An array relationship */
  eventSeries: Array<EventSeries>;
  /** An aggregate relationship */
  eventSeries_aggregate: EventSeriesAggregate;
  /** An array relationship connection */
  eventSeries_connection: EventSeriesConnection;
  /** An array relationship */
  eventSponsors: Array<EventSponsor>;
  /** An aggregate relationship */
  eventSponsors_aggregate: EventSponsorAggregate;
  /** An array relationship connection */
  eventSponsors_connection: EventSponsorConnection;
  /** An array relationship */
  eventTickets: Array<EventTicket>;
  /** An aggregate relationship */
  eventTickets_aggregate: EventTicketAggregate;
  /** An array relationship connection */
  eventTickets_connection: EventTicketConnection;
  /** An array relationship */
  eventUpdates: Array<EventUpdate>;
  /** An aggregate relationship */
  eventUpdates_aggregate: EventUpdateAggregate;
  /** An array relationship connection */
  eventUpdates_connection: EventUpdateConnection;
  /** An array relationship */
  eventVenues: Array<EventVenue>;
  /** An aggregate relationship */
  eventVenues_aggregate: EventVenueAggregate;
  /** An array relationship connection */
  eventVenues_connection: EventVenueConnection;
  /** An array relationship */
  eventVibes: Array<EventVibe>;
  /** An aggregate relationship */
  eventVibes_aggregate: EventVibeAggregate;
  /** An array relationship connection */
  eventVibes_connection: EventVibeConnection;
  /** An array relationship */
  eventVirtualVenues: Array<EventVirtualVenue>;
  /** An aggregate relationship */
  eventVirtualVenues_aggregate: EventVirtualVenueAggregate;
  /** An array relationship connection */
  eventVirtualVenues_connection: EventVirtualVenueConnection;
  /** An array relationship */
  eventVolunteerJobs: Array<EventVolunteerJob>;
  /** An aggregate relationship */
  eventVolunteerJobs_aggregate: EventVolunteerJobAggregate;
  /** An array relationship connection */
  eventVolunteerJobs_connection: EventVolunteerJobConnection;
  /** An array relationship */
  eventWaivers: Array<EventWaiver>;
  /** An aggregate relationship */
  eventWaivers_aggregate: EventWaiverAggregate;
  /** An array relationship connection */
  eventWaivers_connection: EventWaiverConnection;
  eventWebsite?: Maybe<Scalars['String']>;
  hasDynamicPricing?: Maybe<Scalars['Boolean']>;
  hasGeneralWaivers?: Maybe<Scalars['Boolean']>;
  hasMerchandise?: Maybe<Scalars['Boolean']>;
  hasVolunteers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCourseEvent?: Maybe<Scalars['Boolean']>;
  isEventCreationComplete: Scalars['Boolean'];
  isRegistrationPaused: Scalars['Boolean'];
  isSanctionedEvent?: Maybe<Scalars['Boolean']>;
  isSeriesEvent?: Maybe<Scalars['Boolean']>;
  isSponsoredEvent?: Maybe<Scalars['Boolean']>;
  isTicketedEvent?: Maybe<Scalars['Boolean']>;
  isVirtualEvent: Scalars['Boolean'];
  lastSavedAt: Scalars['timestamptz'];
  name?: Maybe<Scalars['String']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Int']>;
  occurrenceLabel?: Maybe<Scalars['String']>;
  /** An object relationship */
  organizer: Organizer;
  /** An array relationship */
  organizerChannels: Array<OrganizerChannel>;
  /** An aggregate relationship */
  organizerChannels_aggregate: OrganizerChannelAggregate;
  /** An array relationship connection */
  organizerChannels_connection: OrganizerChannelConnection;
  organizerCuid: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  refundInfo?: Maybe<Scalars['String']>;
  regCloseDate?: Maybe<Scalars['timestamp']>;
  regOpenDate?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  /** An array relationship */
  rides: Array<Ride>;
  /** An aggregate relationship */
  rides_aggregate: RideAggregate;
  /** An array relationship connection */
  rides_connection: RideConnection;
  selfEditEndDate?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userSavedEvents: Array<UserSavedEvent>;
  /** An aggregate relationship */
  userSavedEvents_aggregate: UserSavedEventAggregate;
  /** An array relationship connection */
  userSavedEvents_connection: UserSavedEventConnection;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCategoriesArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventClinicsArgs = {
  distinct_on?: InputMaybe<Array<ClinicSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicOrderBy>>;
  where?: InputMaybe<ClinicBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventClinicsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicOrderBy>>;
  where?: InputMaybe<ClinicBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventClinicsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicOrderBy>>;
  where?: InputMaybe<ClinicBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCompletedSectionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCoursesArgs = {
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAdditionalInfosArgs = {
  distinct_on?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAdditionalInfoOrderBy>>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAdditionalInfosAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAdditionalInfoOrderBy>>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAdditionalInfosConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAdditionalInfoOrderBy>>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAmenitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventAmenitiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventBasicWaiversArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverOrderBy>>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventBasicWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverOrderBy>>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventBasicWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverOrderBy>>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventChecklistItemsArgs = {
  distinct_on?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventChecklistItemOrderBy>>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventChecklistItemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventChecklistItemOrderBy>>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventChecklistItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventChecklistItemOrderBy>>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventClinicDayDurationsArgs = {
  distinct_on?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventClinicDayDurationOrderBy>>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventClinicDayDurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventClinicDayDurationOrderBy>>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventClinicDayDurationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventClinicDayDurationOrderBy>>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventCustomQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventCustomQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventCustomQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventDisciplinesArgs = {
  distinct_on?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDisciplineOrderBy>>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventDisciplinesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDisciplineOrderBy>>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventDisciplinesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDisciplineOrderBy>>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventImagesArgs = {
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventMerchandisesArgs = {
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventMerchandisesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventMerchandisesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventModerationFlagsArgs = {
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventModerationFlagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventModerationFlagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPitSpacesArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPitSpacesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPitSpacesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingChangesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingChangesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingChangesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingDatesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingDateOrderBy>>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingDatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingDateOrderBy>>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingDatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingDateOrderBy>>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingGroupsArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupOrderBy>>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupOrderBy>>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPricingGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupOrderBy>>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksArgs = {
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksByEventcuidArgs = {
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksByEventcuidAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksByEventcuidConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPrivateLinksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventProgramsArgs = {
  distinct_on?: InputMaybe<Array<EventProgramSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventProgramOrderBy>>;
  where?: InputMaybe<EventProgramBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventProgramsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventProgramSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventProgramOrderBy>>;
  where?: InputMaybe<EventProgramBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventProgramsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventProgramSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventProgramOrderBy>>;
  where?: InputMaybe<EventProgramBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPromoCodesArgs = {
  distinct_on?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPromoCodeOrderBy>>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPromoCodesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPromoCodeOrderBy>>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventPromoCodesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPromoCodeOrderBy>>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSanctionsArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSanctionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSanctionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleDatesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleDateOrderBy>>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleDatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleDateOrderBy>>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleDatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleDateOrderBy>>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypeCustomsArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypeCustomOrderBy>>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypeCustomsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypeCustomOrderBy>>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypeCustomsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypeCustomOrderBy>>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypePresetsArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypePresetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventScheduleTypePresetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSchedulesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSchedulesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSeriesArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSeriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSponsorsArgs = {
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSponsorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventSponsorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventTicketsArgs = {
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventTicketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventTicketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventUpdatesArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventUpdatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventUpdatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVenuesArgs = {
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVenuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVenuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVibesArgs = {
  distinct_on?: InputMaybe<Array<EventVibeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVibeOrderBy>>;
  where?: InputMaybe<EventVibeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVibesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVibeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVibeOrderBy>>;
  where?: InputMaybe<EventVibeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVibesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVibeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVibeOrderBy>>;
  where?: InputMaybe<EventVibeBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVirtualVenuesArgs = {
  distinct_on?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVirtualVenueOrderBy>>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVirtualVenuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVirtualVenueOrderBy>>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVirtualVenuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVirtualVenueOrderBy>>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVolunteerJobsArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobOrderBy>>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVolunteerJobsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobOrderBy>>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventVolunteerJobsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobOrderBy>>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventWaiversArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventEventWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventOrganizerChannelsArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventOrganizerChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventOrganizerChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRidesArgs = {
  distinct_on?: InputMaybe<Array<RideSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideOrderBy>>;
  where?: InputMaybe<RideBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRidesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RideSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideOrderBy>>;
  where?: InputMaybe<RideBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventRidesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideOrderBy>>;
  where?: InputMaybe<RideBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventUserSavedEventsArgs = {
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventUserSavedEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


/** All Reggy Events. clinicRegDeadline is in seconds */
export type EventUserSavedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};

/** Free form extra information about the event */
export type EventAdditionalInfo = Node & {
  __typename?: 'eventAdditionalInfo';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventAdditionalInfo" */
export type EventAdditionalInfoConnection = {
  __typename?: 'eventAdditionalInfoConnection';
  edges: Array<EventAdditionalInfoEdge>;
  pageInfo: PageInfo;
};

export type EventAdditionalInfoEdge = {
  __typename?: 'eventAdditionalInfoEdge';
  cursor: Scalars['String'];
  node: EventAdditionalInfo;
};

/** aggregated selection of "eventAdditionalInfo" */
export type EventAdditionalInfoAggregate = {
  __typename?: 'eventAdditionalInfo_aggregate';
  aggregate?: Maybe<EventAdditionalInfoAggregateFields>;
  nodes: Array<EventAdditionalInfo>;
};

export type EventAdditionalInfoAggregateBoolExp = {
  count?: InputMaybe<EventAdditionalInfoAggregateBoolExpCount>;
};

export type EventAdditionalInfoAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventAdditionalInfoBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventAdditionalInfo" */
export type EventAdditionalInfoAggregateFields = {
  __typename?: 'eventAdditionalInfo_aggregate_fields';
  avg?: Maybe<EventAdditionalInfoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventAdditionalInfoMaxFields>;
  min?: Maybe<EventAdditionalInfoMinFields>;
  stddev?: Maybe<EventAdditionalInfoStddevFields>;
  stddev_pop?: Maybe<EventAdditionalInfoStddevPopFields>;
  stddev_samp?: Maybe<EventAdditionalInfoStddevSampFields>;
  sum?: Maybe<EventAdditionalInfoSumFields>;
  var_pop?: Maybe<EventAdditionalInfoVarPopFields>;
  var_samp?: Maybe<EventAdditionalInfoVarSampFields>;
  variance?: Maybe<EventAdditionalInfoVarianceFields>;
};


/** aggregate fields of "eventAdditionalInfo" */
export type EventAdditionalInfoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventAdditionalInfo" */
export type EventAdditionalInfoAggregateOrderBy = {
  avg?: InputMaybe<EventAdditionalInfoAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventAdditionalInfoMaxOrderBy>;
  min?: InputMaybe<EventAdditionalInfoMinOrderBy>;
  stddev?: InputMaybe<EventAdditionalInfoStddevOrderBy>;
  stddev_pop?: InputMaybe<EventAdditionalInfoStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventAdditionalInfoStddevSampOrderBy>;
  sum?: InputMaybe<EventAdditionalInfoSumOrderBy>;
  var_pop?: InputMaybe<EventAdditionalInfoVarPopOrderBy>;
  var_samp?: InputMaybe<EventAdditionalInfoVarSampOrderBy>;
  variance?: InputMaybe<EventAdditionalInfoVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventAdditionalInfo" */
export type EventAdditionalInfoArrRelInsertInput = {
  data: Array<EventAdditionalInfoInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventAdditionalInfoOnConflict>;
};

/** aggregate avg on columns */
export type EventAdditionalInfoAvgFields = {
  __typename?: 'eventAdditionalInfo_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventAdditionalInfo". All fields are combined with a logical 'AND'. */
export type EventAdditionalInfoBoolExp = {
  _and?: InputMaybe<Array<EventAdditionalInfoBoolExp>>;
  _not?: InputMaybe<EventAdditionalInfoBoolExp>;
  _or?: InputMaybe<Array<EventAdditionalInfoBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventAdditionalInfo" */
export enum EventAdditionalInfoConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventAdditionalInfoPkey = 'eventAdditionalInfo_pkey'
}

/** input type for incrementing numeric columns in table "eventAdditionalInfo" */
export type EventAdditionalInfoIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventAdditionalInfo" */
export type EventAdditionalInfoInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventAdditionalInfoMaxFields = {
  __typename?: 'eventAdditionalInfo_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventAdditionalInfoMinFields = {
  __typename?: 'eventAdditionalInfo_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventAdditionalInfo" */
export type EventAdditionalInfoMutationResponse = {
  __typename?: 'eventAdditionalInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventAdditionalInfo>;
};

/** on_conflict condition type for table "eventAdditionalInfo" */
export type EventAdditionalInfoOnConflict = {
  constraint: EventAdditionalInfoConstraint;
  update_columns?: Array<EventAdditionalInfoUpdateColumn>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};

/** Ordering options when selecting data from "eventAdditionalInfo". */
export type EventAdditionalInfoOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventAdditionalInfo */
export type EventAdditionalInfoPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventAdditionalInfo" */
export enum EventAdditionalInfoSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventAdditionalInfo" */
export type EventAdditionalInfoSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventAdditionalInfoStddevFields = {
  __typename?: 'eventAdditionalInfo_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventAdditionalInfoStddevPopFields = {
  __typename?: 'eventAdditionalInfo_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventAdditionalInfoStddevSampFields = {
  __typename?: 'eventAdditionalInfo_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventAdditionalInfoSumFields = {
  __typename?: 'eventAdditionalInfo_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventAdditionalInfo" */
export enum EventAdditionalInfoUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventAdditionalInfoUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventAdditionalInfoIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventAdditionalInfoSetInput>;
  where: EventAdditionalInfoBoolExp;
};

/** aggregate var_pop on columns */
export type EventAdditionalInfoVarPopFields = {
  __typename?: 'eventAdditionalInfo_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventAdditionalInfoVarSampFields = {
  __typename?: 'eventAdditionalInfo_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventAdditionalInfoVarianceFields = {
  __typename?: 'eventAdditionalInfo_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventAdditionalInfo" */
export type EventAdditionalInfoVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventAmenity" */
export type EventAmenity = Node & {
  __typename?: 'eventAmenity';
  /** An object relationship */
  amenity: Amenity;
  amenityCuid: Scalars['String'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
};

/** A Relay connection object on "eventAmenity" */
export type EventAmenityConnection = {
  __typename?: 'eventAmenityConnection';
  edges: Array<EventAmenityEdge>;
  pageInfo: PageInfo;
};

export type EventAmenityEdge = {
  __typename?: 'eventAmenityEdge';
  cursor: Scalars['String'];
  node: EventAmenity;
};

/** aggregated selection of "eventAmenity" */
export type EventAmenityAggregate = {
  __typename?: 'eventAmenity_aggregate';
  aggregate?: Maybe<EventAmenityAggregateFields>;
  nodes: Array<EventAmenity>;
};

export type EventAmenityAggregateBoolExp = {
  count?: InputMaybe<EventAmenityAggregateBoolExpCount>;
};

export type EventAmenityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventAmenitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventAmenityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventAmenity" */
export type EventAmenityAggregateFields = {
  __typename?: 'eventAmenity_aggregate_fields';
  avg?: Maybe<EventAmenityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventAmenityMaxFields>;
  min?: Maybe<EventAmenityMinFields>;
  stddev?: Maybe<EventAmenityStddevFields>;
  stddev_pop?: Maybe<EventAmenityStddevPopFields>;
  stddev_samp?: Maybe<EventAmenityStddevSampFields>;
  sum?: Maybe<EventAmenitySumFields>;
  var_pop?: Maybe<EventAmenityVarPopFields>;
  var_samp?: Maybe<EventAmenityVarSampFields>;
  variance?: Maybe<EventAmenityVarianceFields>;
};


/** aggregate fields of "eventAmenity" */
export type EventAmenityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventAmenitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventAmenity" */
export type EventAmenityAggregateOrderBy = {
  avg?: InputMaybe<EventAmenityAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventAmenityMaxOrderBy>;
  min?: InputMaybe<EventAmenityMinOrderBy>;
  stddev?: InputMaybe<EventAmenityStddevOrderBy>;
  stddev_pop?: InputMaybe<EventAmenityStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventAmenityStddevSampOrderBy>;
  sum?: InputMaybe<EventAmenitySumOrderBy>;
  var_pop?: InputMaybe<EventAmenityVarPopOrderBy>;
  var_samp?: InputMaybe<EventAmenityVarSampOrderBy>;
  variance?: InputMaybe<EventAmenityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventAmenity" */
export type EventAmenityArrRelInsertInput = {
  data: Array<EventAmenityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventAmenityOnConflict>;
};

/** aggregate avg on columns */
export type EventAmenityAvgFields = {
  __typename?: 'eventAmenity_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventAmenity" */
export type EventAmenityAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventAmenity". All fields are combined with a logical 'AND'. */
export type EventAmenityBoolExp = {
  _and?: InputMaybe<Array<EventAmenityBoolExp>>;
  _not?: InputMaybe<EventAmenityBoolExp>;
  _or?: InputMaybe<Array<EventAmenityBoolExp>>;
  amenity?: InputMaybe<AmenityBoolExp>;
  amenityCuid?: InputMaybe<StringComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "eventAmenity" */
export enum EventAmenityConstraint {
  /** unique or primary key constraint on columns "id" */
  PkRaceAmenity = 'PK_raceAmenity',
  /** unique or primary key constraint on columns "cuid" */
  EventAmenityCuidKey = 'eventAmenity_cuid_key',
  /** unique or primary key constraint on columns "amenityCuid", "eventCuid" */
  EventAmenityEventCuidAmenityCuidKey = 'eventAmenity_eventCuid_amenityCuid_key',
  /** unique or primary key constraint on columns "id" */
  EventAmenityPkey = 'eventAmenity_pkey'
}

/** input type for incrementing numeric columns in table "eventAmenity" */
export type EventAmenityIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventAmenity" */
export type EventAmenityInsertInput = {
  amenity?: InputMaybe<AmenityObjRelInsertInput>;
  amenityCuid?: InputMaybe<Scalars['String']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type EventAmenityMaxFields = {
  __typename?: 'eventAmenity_max_fields';
  amenityCuid?: Maybe<Scalars['String']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "eventAmenity" */
export type EventAmenityMaxOrderBy = {
  amenityCuid?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventAmenityMinFields = {
  __typename?: 'eventAmenity_min_fields';
  amenityCuid?: Maybe<Scalars['String']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "eventAmenity" */
export type EventAmenityMinOrderBy = {
  amenityCuid?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventAmenity" */
export type EventAmenityMutationResponse = {
  __typename?: 'eventAmenity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventAmenity>;
};

/** on_conflict condition type for table "eventAmenity" */
export type EventAmenityOnConflict = {
  constraint: EventAmenityConstraint;
  update_columns?: Array<EventAmenityUpdateColumn>;
  where?: InputMaybe<EventAmenityBoolExp>;
};

/** Ordering options when selecting data from "eventAmenity". */
export type EventAmenityOrderBy = {
  amenity?: InputMaybe<AmenityOrderBy>;
  amenityCuid?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventAmenity */
export type EventAmenityPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "eventAmenity" */
export enum EventAmenitySelectColumn {
  /** column name */
  AmenityCuid = 'amenityCuid',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "eventAmenity" */
export type EventAmenitySetInput = {
  amenityCuid?: InputMaybe<Scalars['String']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type EventAmenityStddevFields = {
  __typename?: 'eventAmenity_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventAmenity" */
export type EventAmenityStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventAmenityStddevPopFields = {
  __typename?: 'eventAmenity_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventAmenity" */
export type EventAmenityStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventAmenityStddevSampFields = {
  __typename?: 'eventAmenity_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventAmenity" */
export type EventAmenityStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventAmenitySumFields = {
  __typename?: 'eventAmenity_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventAmenity" */
export type EventAmenitySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventAmenity" */
export enum EventAmenityUpdateColumn {
  /** column name */
  AmenityCuid = 'amenityCuid',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id'
}

export type EventAmenityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventAmenityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventAmenitySetInput>;
  where: EventAmenityBoolExp;
};

/** aggregate var_pop on columns */
export type EventAmenityVarPopFields = {
  __typename?: 'eventAmenity_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventAmenity" */
export type EventAmenityVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventAmenityVarSampFields = {
  __typename?: 'eventAmenity_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventAmenity" */
export type EventAmenityVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventAmenityVarianceFields = {
  __typename?: 'eventAmenity_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventAmenity" */
export type EventAmenityVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventBasicWaiver" */
export type EventBasicWaiver = Node & {
  __typename?: 'eventBasicWaiver';
  appliesToAllCategories: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  /** An array relationship */
  eventBasicWaiverCategories: Array<EventBasicWaiverCategory>;
  /** An aggregate relationship */
  eventBasicWaiverCategories_aggregate: EventBasicWaiverCategoryAggregate;
  /** An array relationship connection */
  eventBasicWaiverCategories_connection: EventBasicWaiverCategoryConnection;
  eventCuid: Scalars['String'];
  fileRef: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "eventBasicWaiver" */
export type EventBasicWaiverEventBasicWaiverCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


/** columns and relationships of "eventBasicWaiver" */
export type EventBasicWaiverEventBasicWaiverCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


/** columns and relationships of "eventBasicWaiver" */
export type EventBasicWaiverEventBasicWaiverCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};

/** columns and relationships of "eventBasicWaiverCategory" */
export type EventBasicWaiverCategory = Node & {
  __typename?: 'eventBasicWaiverCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventBasicWaiver: EventBasicWaiver;
  eventBasicWaiverCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryConnection = {
  __typename?: 'eventBasicWaiverCategoryConnection';
  edges: Array<EventBasicWaiverCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventBasicWaiverCategoryEdge = {
  __typename?: 'eventBasicWaiverCategoryEdge';
  cursor: Scalars['String'];
  node: EventBasicWaiverCategory;
};

/** aggregated selection of "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryAggregate = {
  __typename?: 'eventBasicWaiverCategory_aggregate';
  aggregate?: Maybe<EventBasicWaiverCategoryAggregateFields>;
  nodes: Array<EventBasicWaiverCategory>;
};

export type EventBasicWaiverCategoryAggregateBoolExp = {
  count?: InputMaybe<EventBasicWaiverCategoryAggregateBoolExpCount>;
};

export type EventBasicWaiverCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryAggregateFields = {
  __typename?: 'eventBasicWaiverCategory_aggregate_fields';
  avg?: Maybe<EventBasicWaiverCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventBasicWaiverCategoryMaxFields>;
  min?: Maybe<EventBasicWaiverCategoryMinFields>;
  stddev?: Maybe<EventBasicWaiverCategoryStddevFields>;
  stddev_pop?: Maybe<EventBasicWaiverCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventBasicWaiverCategoryStddevSampFields>;
  sum?: Maybe<EventBasicWaiverCategorySumFields>;
  var_pop?: Maybe<EventBasicWaiverCategoryVarPopFields>;
  var_samp?: Maybe<EventBasicWaiverCategoryVarSampFields>;
  variance?: Maybe<EventBasicWaiverCategoryVarianceFields>;
};


/** aggregate fields of "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventBasicWaiverCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventBasicWaiverCategoryMaxOrderBy>;
  min?: InputMaybe<EventBasicWaiverCategoryMinOrderBy>;
  stddev?: InputMaybe<EventBasicWaiverCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventBasicWaiverCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventBasicWaiverCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventBasicWaiverCategorySumOrderBy>;
  var_pop?: InputMaybe<EventBasicWaiverCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventBasicWaiverCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventBasicWaiverCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryArrRelInsertInput = {
  data: Array<EventBasicWaiverCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventBasicWaiverCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventBasicWaiverCategoryAvgFields = {
  __typename?: 'eventBasicWaiverCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventBasicWaiverCategory". All fields are combined with a logical 'AND'. */
export type EventBasicWaiverCategoryBoolExp = {
  _and?: InputMaybe<Array<EventBasicWaiverCategoryBoolExp>>;
  _not?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
  _or?: InputMaybe<Array<EventBasicWaiverCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventBasicWaiver?: InputMaybe<EventBasicWaiverBoolExp>;
  eventBasicWaiverCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventBasicWaiverCategory" */
export enum EventBasicWaiverCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventBasicWaiverCategoryCuidKey = 'eventBasicWaiverCategory_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventBasicWaiverCategoryPkey = 'eventBasicWaiverCategory_pkey'
}

/** input type for incrementing numeric columns in table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventBasicWaiver?: InputMaybe<EventBasicWaiverObjRelInsertInput>;
  eventBasicWaiverCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventBasicWaiverCategoryMaxFields = {
  __typename?: 'eventBasicWaiverCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventBasicWaiverCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventBasicWaiverCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventBasicWaiverCategoryMinFields = {
  __typename?: 'eventBasicWaiverCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventBasicWaiverCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventBasicWaiverCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryMutationResponse = {
  __typename?: 'eventBasicWaiverCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventBasicWaiverCategory>;
};

/** on_conflict condition type for table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryOnConflict = {
  constraint: EventBasicWaiverCategoryConstraint;
  update_columns?: Array<EventBasicWaiverCategoryUpdateColumn>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventBasicWaiverCategory". */
export type EventBasicWaiverCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventBasicWaiver?: InputMaybe<EventBasicWaiverOrderBy>;
  eventBasicWaiverCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventBasicWaiverCategory */
export type EventBasicWaiverCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventBasicWaiverCategory" */
export enum EventBasicWaiverCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventBasicWaiverCuid = 'eventBasicWaiverCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventBasicWaiverCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventBasicWaiverCategoryStddevFields = {
  __typename?: 'eventBasicWaiverCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventBasicWaiverCategoryStddevPopFields = {
  __typename?: 'eventBasicWaiverCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventBasicWaiverCategoryStddevSampFields = {
  __typename?: 'eventBasicWaiverCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventBasicWaiverCategorySumFields = {
  __typename?: 'eventBasicWaiverCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventBasicWaiverCategory" */
export enum EventBasicWaiverCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventBasicWaiverCuid = 'eventBasicWaiverCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventBasicWaiverCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventBasicWaiverCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventBasicWaiverCategorySetInput>;
  where: EventBasicWaiverCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventBasicWaiverCategoryVarPopFields = {
  __typename?: 'eventBasicWaiverCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventBasicWaiverCategoryVarSampFields = {
  __typename?: 'eventBasicWaiverCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventBasicWaiverCategoryVarianceFields = {
  __typename?: 'eventBasicWaiverCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventBasicWaiverCategory" */
export type EventBasicWaiverCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventBasicWaiver" */
export type EventBasicWaiverConnection = {
  __typename?: 'eventBasicWaiverConnection';
  edges: Array<EventBasicWaiverEdge>;
  pageInfo: PageInfo;
};

export type EventBasicWaiverEdge = {
  __typename?: 'eventBasicWaiverEdge';
  cursor: Scalars['String'];
  node: EventBasicWaiver;
};

/** aggregated selection of "eventBasicWaiver" */
export type EventBasicWaiverAggregate = {
  __typename?: 'eventBasicWaiver_aggregate';
  aggregate?: Maybe<EventBasicWaiverAggregateFields>;
  nodes: Array<EventBasicWaiver>;
};

export type EventBasicWaiverAggregateBoolExp = {
  bool_and?: InputMaybe<EventBasicWaiverAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventBasicWaiverAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventBasicWaiverAggregateBoolExpCount>;
};

export type EventBasicWaiverAggregateBoolExpBoolAnd = {
  arguments: EventBasicWaiverSelectColumnEventBasicWaiverAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBasicWaiverBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventBasicWaiverAggregateBoolExpBoolOr = {
  arguments: EventBasicWaiverSelectColumnEventBasicWaiverAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBasicWaiverBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventBasicWaiverAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBasicWaiverBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventBasicWaiver" */
export type EventBasicWaiverAggregateFields = {
  __typename?: 'eventBasicWaiver_aggregate_fields';
  avg?: Maybe<EventBasicWaiverAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventBasicWaiverMaxFields>;
  min?: Maybe<EventBasicWaiverMinFields>;
  stddev?: Maybe<EventBasicWaiverStddevFields>;
  stddev_pop?: Maybe<EventBasicWaiverStddevPopFields>;
  stddev_samp?: Maybe<EventBasicWaiverStddevSampFields>;
  sum?: Maybe<EventBasicWaiverSumFields>;
  var_pop?: Maybe<EventBasicWaiverVarPopFields>;
  var_samp?: Maybe<EventBasicWaiverVarSampFields>;
  variance?: Maybe<EventBasicWaiverVarianceFields>;
};


/** aggregate fields of "eventBasicWaiver" */
export type EventBasicWaiverAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventBasicWaiver" */
export type EventBasicWaiverAggregateOrderBy = {
  avg?: InputMaybe<EventBasicWaiverAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventBasicWaiverMaxOrderBy>;
  min?: InputMaybe<EventBasicWaiverMinOrderBy>;
  stddev?: InputMaybe<EventBasicWaiverStddevOrderBy>;
  stddev_pop?: InputMaybe<EventBasicWaiverStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventBasicWaiverStddevSampOrderBy>;
  sum?: InputMaybe<EventBasicWaiverSumOrderBy>;
  var_pop?: InputMaybe<EventBasicWaiverVarPopOrderBy>;
  var_samp?: InputMaybe<EventBasicWaiverVarSampOrderBy>;
  variance?: InputMaybe<EventBasicWaiverVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventBasicWaiver" */
export type EventBasicWaiverArrRelInsertInput = {
  data: Array<EventBasicWaiverInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventBasicWaiverOnConflict>;
};

/** aggregate avg on columns */
export type EventBasicWaiverAvgFields = {
  __typename?: 'eventBasicWaiver_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventBasicWaiver". All fields are combined with a logical 'AND'. */
export type EventBasicWaiverBoolExp = {
  _and?: InputMaybe<Array<EventBasicWaiverBoolExp>>;
  _not?: InputMaybe<EventBasicWaiverBoolExp>;
  _or?: InputMaybe<Array<EventBasicWaiverBoolExp>>;
  appliesToAllCategories?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventBasicWaiverCategories?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
  eventBasicWaiverCategories_aggregate?: InputMaybe<EventBasicWaiverCategoryAggregateBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  fileRef?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventBasicWaiver" */
export enum EventBasicWaiverConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventBasicWaiverCuidKey = 'eventBasicWaiver_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventBasicWaiverPkey = 'eventBasicWaiver_pkey'
}

/** input type for incrementing numeric columns in table "eventBasicWaiver" */
export type EventBasicWaiverIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventBasicWaiver" */
export type EventBasicWaiverInsertInput = {
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventBasicWaiverCategories?: InputMaybe<EventBasicWaiverCategoryArrRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  fileRef?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventBasicWaiverMaxFields = {
  __typename?: 'eventBasicWaiver_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  fileRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventBasicWaiverMinFields = {
  __typename?: 'eventBasicWaiver_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  fileRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventBasicWaiver" */
export type EventBasicWaiverMutationResponse = {
  __typename?: 'eventBasicWaiver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventBasicWaiver>;
};

/** input type for inserting object relation for remote table "eventBasicWaiver" */
export type EventBasicWaiverObjRelInsertInput = {
  data: EventBasicWaiverInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventBasicWaiverOnConflict>;
};

/** on_conflict condition type for table "eventBasicWaiver" */
export type EventBasicWaiverOnConflict = {
  constraint: EventBasicWaiverConstraint;
  update_columns?: Array<EventBasicWaiverUpdateColumn>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};

/** Ordering options when selecting data from "eventBasicWaiver". */
export type EventBasicWaiverOrderBy = {
  appliesToAllCategories?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventBasicWaiverCategories_aggregate?: InputMaybe<EventBasicWaiverCategoryAggregateOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventBasicWaiver */
export type EventBasicWaiverPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventBasicWaiver" */
export enum EventBasicWaiverSelectColumn {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FileRef = 'fileRef',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventBasicWaiver_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventBasicWaiver" */
export enum EventBasicWaiverSelectColumnEventBasicWaiverAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** select "eventBasicWaiver_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventBasicWaiver" */
export enum EventBasicWaiverSelectColumnEventBasicWaiverAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** input type for updating data in table "eventBasicWaiver" */
export type EventBasicWaiverSetInput = {
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  fileRef?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventBasicWaiverStddevFields = {
  __typename?: 'eventBasicWaiver_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventBasicWaiverStddevPopFields = {
  __typename?: 'eventBasicWaiver_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventBasicWaiverStddevSampFields = {
  __typename?: 'eventBasicWaiver_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventBasicWaiverSumFields = {
  __typename?: 'eventBasicWaiver_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventBasicWaiver" */
export enum EventBasicWaiverUpdateColumn {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FileRef = 'fileRef',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventBasicWaiverUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventBasicWaiverIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventBasicWaiverSetInput>;
  where: EventBasicWaiverBoolExp;
};

/** aggregate var_pop on columns */
export type EventBasicWaiverVarPopFields = {
  __typename?: 'eventBasicWaiver_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventBasicWaiverVarSampFields = {
  __typename?: 'eventBasicWaiver_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventBasicWaiverVarianceFields = {
  __typename?: 'eventBasicWaiver_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventBasicWaiver" */
export type EventBasicWaiverVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Checklist item for a clinic */
export type EventChecklistItem = Node & {
  __typename?: 'eventChecklistItem';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventChecklistItem" */
export type EventChecklistItemConnection = {
  __typename?: 'eventChecklistItemConnection';
  edges: Array<EventChecklistItemEdge>;
  pageInfo: PageInfo;
};

export type EventChecklistItemEdge = {
  __typename?: 'eventChecklistItemEdge';
  cursor: Scalars['String'];
  node: EventChecklistItem;
};

/** aggregated selection of "eventChecklistItem" */
export type EventChecklistItemAggregate = {
  __typename?: 'eventChecklistItem_aggregate';
  aggregate?: Maybe<EventChecklistItemAggregateFields>;
  nodes: Array<EventChecklistItem>;
};

export type EventChecklistItemAggregateBoolExp = {
  count?: InputMaybe<EventChecklistItemAggregateBoolExpCount>;
};

export type EventChecklistItemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventChecklistItemBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventChecklistItem" */
export type EventChecklistItemAggregateFields = {
  __typename?: 'eventChecklistItem_aggregate_fields';
  avg?: Maybe<EventChecklistItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventChecklistItemMaxFields>;
  min?: Maybe<EventChecklistItemMinFields>;
  stddev?: Maybe<EventChecklistItemStddevFields>;
  stddev_pop?: Maybe<EventChecklistItemStddevPopFields>;
  stddev_samp?: Maybe<EventChecklistItemStddevSampFields>;
  sum?: Maybe<EventChecklistItemSumFields>;
  var_pop?: Maybe<EventChecklistItemVarPopFields>;
  var_samp?: Maybe<EventChecklistItemVarSampFields>;
  variance?: Maybe<EventChecklistItemVarianceFields>;
};


/** aggregate fields of "eventChecklistItem" */
export type EventChecklistItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventChecklistItem" */
export type EventChecklistItemAggregateOrderBy = {
  avg?: InputMaybe<EventChecklistItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventChecklistItemMaxOrderBy>;
  min?: InputMaybe<EventChecklistItemMinOrderBy>;
  stddev?: InputMaybe<EventChecklistItemStddevOrderBy>;
  stddev_pop?: InputMaybe<EventChecklistItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventChecklistItemStddevSampOrderBy>;
  sum?: InputMaybe<EventChecklistItemSumOrderBy>;
  var_pop?: InputMaybe<EventChecklistItemVarPopOrderBy>;
  var_samp?: InputMaybe<EventChecklistItemVarSampOrderBy>;
  variance?: InputMaybe<EventChecklistItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventChecklistItem" */
export type EventChecklistItemArrRelInsertInput = {
  data: Array<EventChecklistItemInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventChecklistItemOnConflict>;
};

/** aggregate avg on columns */
export type EventChecklistItemAvgFields = {
  __typename?: 'eventChecklistItem_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventChecklistItem" */
export type EventChecklistItemAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventChecklistItem". All fields are combined with a logical 'AND'. */
export type EventChecklistItemBoolExp = {
  _and?: InputMaybe<Array<EventChecklistItemBoolExp>>;
  _not?: InputMaybe<EventChecklistItemBoolExp>;
  _or?: InputMaybe<Array<EventChecklistItemBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  info?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventChecklistItem" */
export enum EventChecklistItemConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventChecklistItemCuidKey = 'eventChecklistItem_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventChecklistItemPkey = 'eventChecklistItem_pkey'
}

/** input type for incrementing numeric columns in table "eventChecklistItem" */
export type EventChecklistItemIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventChecklistItem" */
export type EventChecklistItemInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  info?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventChecklistItemMaxFields = {
  __typename?: 'eventChecklistItem_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  info?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventChecklistItem" */
export type EventChecklistItemMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventChecklistItemMinFields = {
  __typename?: 'eventChecklistItem_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  info?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventChecklistItem" */
export type EventChecklistItemMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventChecklistItem" */
export type EventChecklistItemMutationResponse = {
  __typename?: 'eventChecklistItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventChecklistItem>;
};

/** on_conflict condition type for table "eventChecklistItem" */
export type EventChecklistItemOnConflict = {
  constraint: EventChecklistItemConstraint;
  update_columns?: Array<EventChecklistItemUpdateColumn>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};

/** Ordering options when selecting data from "eventChecklistItem". */
export type EventChecklistItemOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventChecklistItem */
export type EventChecklistItemPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventChecklistItem" */
export enum EventChecklistItemSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventChecklistItem" */
export type EventChecklistItemSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  info?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventChecklistItemStddevFields = {
  __typename?: 'eventChecklistItem_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventChecklistItem" */
export type EventChecklistItemStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventChecklistItemStddevPopFields = {
  __typename?: 'eventChecklistItem_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventChecklistItem" */
export type EventChecklistItemStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventChecklistItemStddevSampFields = {
  __typename?: 'eventChecklistItem_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventChecklistItem" */
export type EventChecklistItemStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventChecklistItemSumFields = {
  __typename?: 'eventChecklistItem_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventChecklistItem" */
export type EventChecklistItemSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventChecklistItem" */
export enum EventChecklistItemUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventChecklistItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventChecklistItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventChecklistItemSetInput>;
  where: EventChecklistItemBoolExp;
};

/** aggregate var_pop on columns */
export type EventChecklistItemVarPopFields = {
  __typename?: 'eventChecklistItem_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventChecklistItem" */
export type EventChecklistItemVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventChecklistItemVarSampFields = {
  __typename?: 'eventChecklistItem_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventChecklistItem" */
export type EventChecklistItemVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventChecklistItemVarianceFields = {
  __typename?: 'eventChecklistItem_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventChecklistItem" */
export type EventChecklistItemVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Duration of a day for a Clinic Template. Duration = number of minutes */
export type EventClinicDayDuration = Node & {
  __typename?: 'eventClinicDayDuration';
  /** An array relationship */
  clinicDays: Array<ClinicDay>;
  /** An aggregate relationship */
  clinicDays_aggregate: ClinicDayAggregate;
  /** An array relationship connection */
  clinicDays_connection: ClinicDayConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  dayNumber: Scalars['Int'];
  duration: Scalars['smallint'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};


/** Duration of a day for a Clinic Template. Duration = number of minutes */
export type EventClinicDayDurationClinicDaysArgs = {
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


/** Duration of a day for a Clinic Template. Duration = number of minutes */
export type EventClinicDayDurationClinicDaysAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


/** Duration of a day for a Clinic Template. Duration = number of minutes */
export type EventClinicDayDurationClinicDaysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};

/** A Relay connection object on "eventClinicDayDuration" */
export type EventClinicDayDurationConnection = {
  __typename?: 'eventClinicDayDurationConnection';
  edges: Array<EventClinicDayDurationEdge>;
  pageInfo: PageInfo;
};

export type EventClinicDayDurationEdge = {
  __typename?: 'eventClinicDayDurationEdge';
  cursor: Scalars['String'];
  node: EventClinicDayDuration;
};

/** aggregated selection of "eventClinicDayDuration" */
export type EventClinicDayDurationAggregate = {
  __typename?: 'eventClinicDayDuration_aggregate';
  aggregate?: Maybe<EventClinicDayDurationAggregateFields>;
  nodes: Array<EventClinicDayDuration>;
};

export type EventClinicDayDurationAggregateBoolExp = {
  count?: InputMaybe<EventClinicDayDurationAggregateBoolExpCount>;
};

export type EventClinicDayDurationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventClinicDayDurationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventClinicDayDuration" */
export type EventClinicDayDurationAggregateFields = {
  __typename?: 'eventClinicDayDuration_aggregate_fields';
  avg?: Maybe<EventClinicDayDurationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventClinicDayDurationMaxFields>;
  min?: Maybe<EventClinicDayDurationMinFields>;
  stddev?: Maybe<EventClinicDayDurationStddevFields>;
  stddev_pop?: Maybe<EventClinicDayDurationStddevPopFields>;
  stddev_samp?: Maybe<EventClinicDayDurationStddevSampFields>;
  sum?: Maybe<EventClinicDayDurationSumFields>;
  var_pop?: Maybe<EventClinicDayDurationVarPopFields>;
  var_samp?: Maybe<EventClinicDayDurationVarSampFields>;
  variance?: Maybe<EventClinicDayDurationVarianceFields>;
};


/** aggregate fields of "eventClinicDayDuration" */
export type EventClinicDayDurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventClinicDayDuration" */
export type EventClinicDayDurationAggregateOrderBy = {
  avg?: InputMaybe<EventClinicDayDurationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventClinicDayDurationMaxOrderBy>;
  min?: InputMaybe<EventClinicDayDurationMinOrderBy>;
  stddev?: InputMaybe<EventClinicDayDurationStddevOrderBy>;
  stddev_pop?: InputMaybe<EventClinicDayDurationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventClinicDayDurationStddevSampOrderBy>;
  sum?: InputMaybe<EventClinicDayDurationSumOrderBy>;
  var_pop?: InputMaybe<EventClinicDayDurationVarPopOrderBy>;
  var_samp?: InputMaybe<EventClinicDayDurationVarSampOrderBy>;
  variance?: InputMaybe<EventClinicDayDurationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventClinicDayDuration" */
export type EventClinicDayDurationArrRelInsertInput = {
  data: Array<EventClinicDayDurationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventClinicDayDurationOnConflict>;
};

/** aggregate avg on columns */
export type EventClinicDayDurationAvgFields = {
  __typename?: 'eventClinicDayDuration_avg_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationAvgOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventClinicDayDuration". All fields are combined with a logical 'AND'. */
export type EventClinicDayDurationBoolExp = {
  _and?: InputMaybe<Array<EventClinicDayDurationBoolExp>>;
  _not?: InputMaybe<EventClinicDayDurationBoolExp>;
  _or?: InputMaybe<Array<EventClinicDayDurationBoolExp>>;
  clinicDays?: InputMaybe<ClinicDayBoolExp>;
  clinicDays_aggregate?: InputMaybe<ClinicDayAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  dayNumber?: InputMaybe<IntComparisonExp>;
  duration?: InputMaybe<SmallintComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventClinicDayDuration" */
export enum EventClinicDayDurationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventClinicDurationCuidKey = 'eventClinicDuration_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventClinicDurationPkey = 'eventClinicDuration_pkey'
}

/** input type for incrementing numeric columns in table "eventClinicDayDuration" */
export type EventClinicDayDurationIncInput = {
  dayNumber?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventClinicDayDuration" */
export type EventClinicDayDurationInsertInput = {
  clinicDays?: InputMaybe<ClinicDayArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['smallint']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventClinicDayDurationMaxFields = {
  __typename?: 'eventClinicDayDuration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayNumber?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['smallint']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventClinicDayDurationMinFields = {
  __typename?: 'eventClinicDayDuration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayNumber?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['smallint']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventClinicDayDuration" */
export type EventClinicDayDurationMutationResponse = {
  __typename?: 'eventClinicDayDuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventClinicDayDuration>;
};

/** input type for inserting object relation for remote table "eventClinicDayDuration" */
export type EventClinicDayDurationObjRelInsertInput = {
  data: EventClinicDayDurationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventClinicDayDurationOnConflict>;
};

/** on_conflict condition type for table "eventClinicDayDuration" */
export type EventClinicDayDurationOnConflict = {
  constraint: EventClinicDayDurationConstraint;
  update_columns?: Array<EventClinicDayDurationUpdateColumn>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};

/** Ordering options when selecting data from "eventClinicDayDuration". */
export type EventClinicDayDurationOrderBy = {
  clinicDays_aggregate?: InputMaybe<ClinicDayAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventClinicDayDuration */
export type EventClinicDayDurationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventClinicDayDuration" */
export enum EventClinicDayDurationSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  Duration = 'duration',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventClinicDayDuration" */
export type EventClinicDayDurationSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['smallint']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventClinicDayDurationStddevFields = {
  __typename?: 'eventClinicDayDuration_stddev_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationStddevOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventClinicDayDurationStddevPopFields = {
  __typename?: 'eventClinicDayDuration_stddev_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationStddevPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventClinicDayDurationStddevSampFields = {
  __typename?: 'eventClinicDayDuration_stddev_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationStddevSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventClinicDayDurationSumFields = {
  __typename?: 'eventClinicDayDuration_sum_fields';
  dayNumber?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationSumOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventClinicDayDuration" */
export enum EventClinicDayDurationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  Duration = 'duration',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventClinicDayDurationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventClinicDayDurationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventClinicDayDurationSetInput>;
  where: EventClinicDayDurationBoolExp;
};

/** aggregate var_pop on columns */
export type EventClinicDayDurationVarPopFields = {
  __typename?: 'eventClinicDayDuration_var_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationVarPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventClinicDayDurationVarSampFields = {
  __typename?: 'eventClinicDayDuration_var_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationVarSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventClinicDayDurationVarianceFields = {
  __typename?: 'eventClinicDayDuration_variance_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventClinicDayDuration" */
export type EventClinicDayDurationVarianceOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "event" */
export type EventConnection = {
  __typename?: 'eventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
};

/** Custom questions forr an event */
export type EventCustomQuestion = Node & {
  __typename?: 'eventCustomQuestion';
  additionalInfo?: Maybe<Scalars['String']>;
  appliesToAllCategories: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventCustomQuestionCategories: Array<EventCustomQuestionCategory>;
  /** An aggregate relationship */
  eventCustomQuestionCategories_aggregate: EventCustomQuestionCategoryAggregate;
  /** An array relationship connection */
  eventCustomQuestionCategories_connection: EventCustomQuestionCategoryConnection;
  /** An array relationship */
  eventCustomQuestionOptions: Array<EventCustomQuestionOption>;
  /** An aggregate relationship */
  eventCustomQuestionOptions_aggregate: EventCustomQuestionOptionAggregate;
  /** An array relationship connection */
  eventCustomQuestionOptions_connection: EventCustomQuestionOptionConnection;
  id: Scalars['ID'];
  internalPurpose: Scalars['String'];
  isRequired: Scalars['Boolean'];
  position: Scalars['Int'];
  questionType: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionOptionsArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOptionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOptionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};


/** Custom questions forr an event */
export type EventCustomQuestionEventCustomQuestionOptionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOptionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};

/** What categories are related to a custom question */
export type EventCustomQuestionCategory = Node & {
  __typename?: 'eventCustomQuestionCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventCustomQuestion: EventCustomQuestion;
  eventCustomQuestionCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryConnection = {
  __typename?: 'eventCustomQuestionCategoryConnection';
  edges: Array<EventCustomQuestionCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventCustomQuestionCategoryEdge = {
  __typename?: 'eventCustomQuestionCategoryEdge';
  cursor: Scalars['String'];
  node: EventCustomQuestionCategory;
};

/** aggregated selection of "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryAggregate = {
  __typename?: 'eventCustomQuestionCategory_aggregate';
  aggregate?: Maybe<EventCustomQuestionCategoryAggregateFields>;
  nodes: Array<EventCustomQuestionCategory>;
};

export type EventCustomQuestionCategoryAggregateBoolExp = {
  count?: InputMaybe<EventCustomQuestionCategoryAggregateBoolExpCount>;
};

export type EventCustomQuestionCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryAggregateFields = {
  __typename?: 'eventCustomQuestionCategory_aggregate_fields';
  avg?: Maybe<EventCustomQuestionCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventCustomQuestionCategoryMaxFields>;
  min?: Maybe<EventCustomQuestionCategoryMinFields>;
  stddev?: Maybe<EventCustomQuestionCategoryStddevFields>;
  stddev_pop?: Maybe<EventCustomQuestionCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventCustomQuestionCategoryStddevSampFields>;
  sum?: Maybe<EventCustomQuestionCategorySumFields>;
  var_pop?: Maybe<EventCustomQuestionCategoryVarPopFields>;
  var_samp?: Maybe<EventCustomQuestionCategoryVarSampFields>;
  variance?: Maybe<EventCustomQuestionCategoryVarianceFields>;
};


/** aggregate fields of "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventCustomQuestionCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventCustomQuestionCategoryMaxOrderBy>;
  min?: InputMaybe<EventCustomQuestionCategoryMinOrderBy>;
  stddev?: InputMaybe<EventCustomQuestionCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventCustomQuestionCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventCustomQuestionCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventCustomQuestionCategorySumOrderBy>;
  var_pop?: InputMaybe<EventCustomQuestionCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventCustomQuestionCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventCustomQuestionCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryArrRelInsertInput = {
  data: Array<EventCustomQuestionCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventCustomQuestionCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventCustomQuestionCategoryAvgFields = {
  __typename?: 'eventCustomQuestionCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventCustomQuestionCategory". All fields are combined with a logical 'AND'. */
export type EventCustomQuestionCategoryBoolExp = {
  _and?: InputMaybe<Array<EventCustomQuestionCategoryBoolExp>>;
  _not?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
  _or?: InputMaybe<Array<EventCustomQuestionCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionBoolExp>;
  eventCustomQuestionCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventCustomQuestionCategory" */
export enum EventCustomQuestionCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventCustomQuestionCategoryCuidKey = 'eventCustomQuestionCategory_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventCustomQuestionCategoryPkey = 'eventCustomQuestionCategory_pkey'
}

/** input type for incrementing numeric columns in table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionObjRelInsertInput>;
  eventCustomQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventCustomQuestionCategoryMaxFields = {
  __typename?: 'eventCustomQuestionCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCustomQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventCustomQuestionCategoryMinFields = {
  __typename?: 'eventCustomQuestionCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCustomQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryMutationResponse = {
  __typename?: 'eventCustomQuestionCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventCustomQuestionCategory>;
};

/** on_conflict condition type for table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryOnConflict = {
  constraint: EventCustomQuestionCategoryConstraint;
  update_columns?: Array<EventCustomQuestionCategoryUpdateColumn>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventCustomQuestionCategory". */
export type EventCustomQuestionCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionOrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventCustomQuestionCategory */
export type EventCustomQuestionCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventCustomQuestionCategory" */
export enum EventCustomQuestionCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCustomQuestionCuid = 'eventCustomQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventCustomQuestionCategoryStddevFields = {
  __typename?: 'eventCustomQuestionCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventCustomQuestionCategoryStddevPopFields = {
  __typename?: 'eventCustomQuestionCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventCustomQuestionCategoryStddevSampFields = {
  __typename?: 'eventCustomQuestionCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventCustomQuestionCategorySumFields = {
  __typename?: 'eventCustomQuestionCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventCustomQuestionCategory" */
export enum EventCustomQuestionCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCustomQuestionCuid = 'eventCustomQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventCustomQuestionCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventCustomQuestionCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventCustomQuestionCategorySetInput>;
  where: EventCustomQuestionCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventCustomQuestionCategoryVarPopFields = {
  __typename?: 'eventCustomQuestionCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventCustomQuestionCategoryVarSampFields = {
  __typename?: 'eventCustomQuestionCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventCustomQuestionCategoryVarianceFields = {
  __typename?: 'eventCustomQuestionCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventCustomQuestionCategory" */
export type EventCustomQuestionCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventCustomQuestion" */
export type EventCustomQuestionConnection = {
  __typename?: 'eventCustomQuestionConnection';
  edges: Array<EventCustomQuestionEdge>;
  pageInfo: PageInfo;
};

export type EventCustomQuestionEdge = {
  __typename?: 'eventCustomQuestionEdge';
  cursor: Scalars['String'];
  node: EventCustomQuestion;
};

/** columns and relationships of "eventCustomQuestionOption" */
export type EventCustomQuestionOption = Node & {
  __typename?: 'eventCustomQuestionOption';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventCustomQuestion: EventCustomQuestion;
  eventCustomQuestionCuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventCustomQuestionOption" */
export type EventCustomQuestionOptionConnection = {
  __typename?: 'eventCustomQuestionOptionConnection';
  edges: Array<EventCustomQuestionOptionEdge>;
  pageInfo: PageInfo;
};

export type EventCustomQuestionOptionEdge = {
  __typename?: 'eventCustomQuestionOptionEdge';
  cursor: Scalars['String'];
  node: EventCustomQuestionOption;
};

/** aggregated selection of "eventCustomQuestionOption" */
export type EventCustomQuestionOptionAggregate = {
  __typename?: 'eventCustomQuestionOption_aggregate';
  aggregate?: Maybe<EventCustomQuestionOptionAggregateFields>;
  nodes: Array<EventCustomQuestionOption>;
};

export type EventCustomQuestionOptionAggregateBoolExp = {
  count?: InputMaybe<EventCustomQuestionOptionAggregateBoolExpCount>;
};

export type EventCustomQuestionOptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventCustomQuestionOptionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventCustomQuestionOption" */
export type EventCustomQuestionOptionAggregateFields = {
  __typename?: 'eventCustomQuestionOption_aggregate_fields';
  avg?: Maybe<EventCustomQuestionOptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventCustomQuestionOptionMaxFields>;
  min?: Maybe<EventCustomQuestionOptionMinFields>;
  stddev?: Maybe<EventCustomQuestionOptionStddevFields>;
  stddev_pop?: Maybe<EventCustomQuestionOptionStddevPopFields>;
  stddev_samp?: Maybe<EventCustomQuestionOptionStddevSampFields>;
  sum?: Maybe<EventCustomQuestionOptionSumFields>;
  var_pop?: Maybe<EventCustomQuestionOptionVarPopFields>;
  var_samp?: Maybe<EventCustomQuestionOptionVarSampFields>;
  variance?: Maybe<EventCustomQuestionOptionVarianceFields>;
};


/** aggregate fields of "eventCustomQuestionOption" */
export type EventCustomQuestionOptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionAggregateOrderBy = {
  avg?: InputMaybe<EventCustomQuestionOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventCustomQuestionOptionMaxOrderBy>;
  min?: InputMaybe<EventCustomQuestionOptionMinOrderBy>;
  stddev?: InputMaybe<EventCustomQuestionOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<EventCustomQuestionOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventCustomQuestionOptionStddevSampOrderBy>;
  sum?: InputMaybe<EventCustomQuestionOptionSumOrderBy>;
  var_pop?: InputMaybe<EventCustomQuestionOptionVarPopOrderBy>;
  var_samp?: InputMaybe<EventCustomQuestionOptionVarSampOrderBy>;
  variance?: InputMaybe<EventCustomQuestionOptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionArrRelInsertInput = {
  data: Array<EventCustomQuestionOptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventCustomQuestionOptionOnConflict>;
};

/** aggregate avg on columns */
export type EventCustomQuestionOptionAvgFields = {
  __typename?: 'eventCustomQuestionOption_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventCustomQuestionOption". All fields are combined with a logical 'AND'. */
export type EventCustomQuestionOptionBoolExp = {
  _and?: InputMaybe<Array<EventCustomQuestionOptionBoolExp>>;
  _not?: InputMaybe<EventCustomQuestionOptionBoolExp>;
  _or?: InputMaybe<Array<EventCustomQuestionOptionBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionBoolExp>;
  eventCustomQuestionCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventCustomQuestionOption" */
export enum EventCustomQuestionOptionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventCustomQuestionOptionCuidKey = 'eventCustomQuestionOption_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventCustomQuestionOptionPkey = 'eventCustomQuestionOption_pkey'
}

/** input type for incrementing numeric columns in table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionObjRelInsertInput>;
  eventCustomQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventCustomQuestionOptionMaxFields = {
  __typename?: 'eventCustomQuestionOption_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCustomQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventCustomQuestionOptionMinFields = {
  __typename?: 'eventCustomQuestionOption_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCustomQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionMutationResponse = {
  __typename?: 'eventCustomQuestionOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventCustomQuestionOption>;
};

/** on_conflict condition type for table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionOnConflict = {
  constraint: EventCustomQuestionOptionConstraint;
  update_columns?: Array<EventCustomQuestionOptionUpdateColumn>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};

/** Ordering options when selecting data from "eventCustomQuestionOption". */
export type EventCustomQuestionOptionOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCustomQuestion?: InputMaybe<EventCustomQuestionOrderBy>;
  eventCustomQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventCustomQuestionOption */
export type EventCustomQuestionOptionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventCustomQuestionOption" */
export enum EventCustomQuestionOptionSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCustomQuestionCuid = 'eventCustomQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventCustomQuestionOptionStddevFields = {
  __typename?: 'eventCustomQuestionOption_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventCustomQuestionOptionStddevPopFields = {
  __typename?: 'eventCustomQuestionOption_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventCustomQuestionOptionStddevSampFields = {
  __typename?: 'eventCustomQuestionOption_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventCustomQuestionOptionSumFields = {
  __typename?: 'eventCustomQuestionOption_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "eventCustomQuestionOption" */
export enum EventCustomQuestionOptionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCustomQuestionCuid = 'eventCustomQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventCustomQuestionOptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventCustomQuestionOptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventCustomQuestionOptionSetInput>;
  where: EventCustomQuestionOptionBoolExp;
};

/** aggregate var_pop on columns */
export type EventCustomQuestionOptionVarPopFields = {
  __typename?: 'eventCustomQuestionOption_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventCustomQuestionOptionVarSampFields = {
  __typename?: 'eventCustomQuestionOption_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventCustomQuestionOptionVarianceFields = {
  __typename?: 'eventCustomQuestionOption_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventCustomQuestionOption" */
export type EventCustomQuestionOptionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregated selection of "eventCustomQuestion" */
export type EventCustomQuestionAggregate = {
  __typename?: 'eventCustomQuestion_aggregate';
  aggregate?: Maybe<EventCustomQuestionAggregateFields>;
  nodes: Array<EventCustomQuestion>;
};

export type EventCustomQuestionAggregateBoolExp = {
  bool_and?: InputMaybe<EventCustomQuestionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventCustomQuestionAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventCustomQuestionAggregateBoolExpCount>;
};

export type EventCustomQuestionAggregateBoolExpBoolAnd = {
  arguments: EventCustomQuestionSelectColumnEventCustomQuestionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventCustomQuestionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventCustomQuestionAggregateBoolExpBoolOr = {
  arguments: EventCustomQuestionSelectColumnEventCustomQuestionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventCustomQuestionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventCustomQuestionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventCustomQuestionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventCustomQuestion" */
export type EventCustomQuestionAggregateFields = {
  __typename?: 'eventCustomQuestion_aggregate_fields';
  avg?: Maybe<EventCustomQuestionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventCustomQuestionMaxFields>;
  min?: Maybe<EventCustomQuestionMinFields>;
  stddev?: Maybe<EventCustomQuestionStddevFields>;
  stddev_pop?: Maybe<EventCustomQuestionStddevPopFields>;
  stddev_samp?: Maybe<EventCustomQuestionStddevSampFields>;
  sum?: Maybe<EventCustomQuestionSumFields>;
  var_pop?: Maybe<EventCustomQuestionVarPopFields>;
  var_samp?: Maybe<EventCustomQuestionVarSampFields>;
  variance?: Maybe<EventCustomQuestionVarianceFields>;
};


/** aggregate fields of "eventCustomQuestion" */
export type EventCustomQuestionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventCustomQuestion" */
export type EventCustomQuestionAggregateOrderBy = {
  avg?: InputMaybe<EventCustomQuestionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventCustomQuestionMaxOrderBy>;
  min?: InputMaybe<EventCustomQuestionMinOrderBy>;
  stddev?: InputMaybe<EventCustomQuestionStddevOrderBy>;
  stddev_pop?: InputMaybe<EventCustomQuestionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventCustomQuestionStddevSampOrderBy>;
  sum?: InputMaybe<EventCustomQuestionSumOrderBy>;
  var_pop?: InputMaybe<EventCustomQuestionVarPopOrderBy>;
  var_samp?: InputMaybe<EventCustomQuestionVarSampOrderBy>;
  variance?: InputMaybe<EventCustomQuestionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventCustomQuestion" */
export type EventCustomQuestionArrRelInsertInput = {
  data: Array<EventCustomQuestionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventCustomQuestionOnConflict>;
};

/** aggregate avg on columns */
export type EventCustomQuestionAvgFields = {
  __typename?: 'eventCustomQuestion_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventCustomQuestion". All fields are combined with a logical 'AND'. */
export type EventCustomQuestionBoolExp = {
  _and?: InputMaybe<Array<EventCustomQuestionBoolExp>>;
  _not?: InputMaybe<EventCustomQuestionBoolExp>;
  _or?: InputMaybe<Array<EventCustomQuestionBoolExp>>;
  additionalInfo?: InputMaybe<StringComparisonExp>;
  appliesToAllCategories?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventCustomQuestionCategories?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
  eventCustomQuestionCategories_aggregate?: InputMaybe<EventCustomQuestionCategoryAggregateBoolExp>;
  eventCustomQuestionOptions?: InputMaybe<EventCustomQuestionOptionBoolExp>;
  eventCustomQuestionOptions_aggregate?: InputMaybe<EventCustomQuestionOptionAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  internalPurpose?: InputMaybe<StringComparisonExp>;
  isRequired?: InputMaybe<BooleanComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  questionType?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventCustomQuestion" */
export enum EventCustomQuestionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventCustomQuestionCuidKey = 'eventCustomQuestion_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventCustomQuestionPkey = 'eventCustomQuestion_pkey'
}

/** input type for incrementing numeric columns in table "eventCustomQuestion" */
export type EventCustomQuestionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventCustomQuestion" */
export type EventCustomQuestionInsertInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventCustomQuestionCategories?: InputMaybe<EventCustomQuestionCategoryArrRelInsertInput>;
  eventCustomQuestionOptions?: InputMaybe<EventCustomQuestionOptionArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  internalPurpose?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  questionType?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventCustomQuestionMaxFields = {
  __typename?: 'eventCustomQuestion_max_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internalPurpose?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  questionType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionMaxOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalPurpose?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventCustomQuestionMinFields = {
  __typename?: 'eventCustomQuestion_min_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internalPurpose?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  questionType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionMinOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalPurpose?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventCustomQuestion" */
export type EventCustomQuestionMutationResponse = {
  __typename?: 'eventCustomQuestion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventCustomQuestion>;
};

/** input type for inserting object relation for remote table "eventCustomQuestion" */
export type EventCustomQuestionObjRelInsertInput = {
  data: EventCustomQuestionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventCustomQuestionOnConflict>;
};

/** on_conflict condition type for table "eventCustomQuestion" */
export type EventCustomQuestionOnConflict = {
  constraint: EventCustomQuestionConstraint;
  update_columns?: Array<EventCustomQuestionUpdateColumn>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};

/** Ordering options when selecting data from "eventCustomQuestion". */
export type EventCustomQuestionOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  appliesToAllCategories?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventCustomQuestionCategories_aggregate?: InputMaybe<EventCustomQuestionCategoryAggregateOrderBy>;
  eventCustomQuestionOptions_aggregate?: InputMaybe<EventCustomQuestionOptionAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  internalPurpose?: InputMaybe<OrderBy>;
  isRequired?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventCustomQuestion */
export type EventCustomQuestionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventCustomQuestion" */
export enum EventCustomQuestionSelectColumn {
  /** column name */
  AdditionalInfo = 'additionalInfo',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  InternalPurpose = 'internalPurpose',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionType = 'questionType',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventCustomQuestion_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventCustomQuestion" */
export enum EventCustomQuestionSelectColumnEventCustomQuestionAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  IsRequired = 'isRequired'
}

/** select "eventCustomQuestion_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventCustomQuestion" */
export enum EventCustomQuestionSelectColumnEventCustomQuestionAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  IsRequired = 'isRequired'
}

/** input type for updating data in table "eventCustomQuestion" */
export type EventCustomQuestionSetInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  internalPurpose?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  questionType?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventCustomQuestionStddevFields = {
  __typename?: 'eventCustomQuestion_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventCustomQuestionStddevPopFields = {
  __typename?: 'eventCustomQuestion_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventCustomQuestionStddevSampFields = {
  __typename?: 'eventCustomQuestion_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventCustomQuestionSumFields = {
  __typename?: 'eventCustomQuestion_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "eventCustomQuestion" */
export enum EventCustomQuestionUpdateColumn {
  /** column name */
  AdditionalInfo = 'additionalInfo',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  InternalPurpose = 'internalPurpose',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionType = 'questionType',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventCustomQuestionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventCustomQuestionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventCustomQuestionSetInput>;
  where: EventCustomQuestionBoolExp;
};

/** aggregate var_pop on columns */
export type EventCustomQuestionVarPopFields = {
  __typename?: 'eventCustomQuestion_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventCustomQuestionVarSampFields = {
  __typename?: 'eventCustomQuestion_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventCustomQuestionVarianceFields = {
  __typename?: 'eventCustomQuestion_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventCustomQuestion" */
export type EventCustomQuestionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventDiscipline" */
export type EventDiscipline = Node & {
  __typename?: 'eventDiscipline';
  allowWaitlist: Scalars['Boolean'];
  /** An array relationship */
  categories: Array<Category>;
  /** An aggregate relationship */
  categories_aggregate: CategoryAggregate;
  /** An array relationship connection */
  categories_connection: CategoryConnection;
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregate relationship */
  courses_aggregate: CourseAggregate;
  /** An array relationship connection */
  courses_connection: CourseConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  disciplineName: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  fieldLimit?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCategoriesArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCoursesArgs = {
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


/** columns and relationships of "eventDiscipline" */
export type EventDisciplineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};

/** A Relay connection object on "eventDiscipline" */
export type EventDisciplineConnection = {
  __typename?: 'eventDisciplineConnection';
  edges: Array<EventDisciplineEdge>;
  pageInfo: PageInfo;
};

export type EventDisciplineEdge = {
  __typename?: 'eventDisciplineEdge';
  cursor: Scalars['String'];
  node: EventDiscipline;
};

/** aggregated selection of "eventDiscipline" */
export type EventDisciplineAggregate = {
  __typename?: 'eventDiscipline_aggregate';
  aggregate?: Maybe<EventDisciplineAggregateFields>;
  nodes: Array<EventDiscipline>;
};

export type EventDisciplineAggregateBoolExp = {
  bool_and?: InputMaybe<EventDisciplineAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventDisciplineAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventDisciplineAggregateBoolExpCount>;
};

export type EventDisciplineAggregateBoolExpBoolAnd = {
  arguments: EventDisciplineSelectColumnEventDisciplineAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventDisciplineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventDisciplineAggregateBoolExpBoolOr = {
  arguments: EventDisciplineSelectColumnEventDisciplineAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventDisciplineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventDisciplineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventDisciplineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventDiscipline" */
export type EventDisciplineAggregateFields = {
  __typename?: 'eventDiscipline_aggregate_fields';
  avg?: Maybe<EventDisciplineAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventDisciplineMaxFields>;
  min?: Maybe<EventDisciplineMinFields>;
  stddev?: Maybe<EventDisciplineStddevFields>;
  stddev_pop?: Maybe<EventDisciplineStddevPopFields>;
  stddev_samp?: Maybe<EventDisciplineStddevSampFields>;
  sum?: Maybe<EventDisciplineSumFields>;
  var_pop?: Maybe<EventDisciplineVarPopFields>;
  var_samp?: Maybe<EventDisciplineVarSampFields>;
  variance?: Maybe<EventDisciplineVarianceFields>;
};


/** aggregate fields of "eventDiscipline" */
export type EventDisciplineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventDiscipline" */
export type EventDisciplineAggregateOrderBy = {
  avg?: InputMaybe<EventDisciplineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventDisciplineMaxOrderBy>;
  min?: InputMaybe<EventDisciplineMinOrderBy>;
  stddev?: InputMaybe<EventDisciplineStddevOrderBy>;
  stddev_pop?: InputMaybe<EventDisciplineStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventDisciplineStddevSampOrderBy>;
  sum?: InputMaybe<EventDisciplineSumOrderBy>;
  var_pop?: InputMaybe<EventDisciplineVarPopOrderBy>;
  var_samp?: InputMaybe<EventDisciplineVarSampOrderBy>;
  variance?: InputMaybe<EventDisciplineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventDiscipline" */
export type EventDisciplineArrRelInsertInput = {
  data: Array<EventDisciplineInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventDisciplineOnConflict>;
};

/** aggregate avg on columns */
export type EventDisciplineAvgFields = {
  __typename?: 'eventDiscipline_avg_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventDiscipline" */
export type EventDisciplineAvgOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventDiscipline". All fields are combined with a logical 'AND'. */
export type EventDisciplineBoolExp = {
  _and?: InputMaybe<Array<EventDisciplineBoolExp>>;
  _not?: InputMaybe<EventDisciplineBoolExp>;
  _or?: InputMaybe<Array<EventDisciplineBoolExp>>;
  allowWaitlist?: InputMaybe<BooleanComparisonExp>;
  categories?: InputMaybe<CategoryBoolExp>;
  categories_aggregate?: InputMaybe<CategoryAggregateBoolExp>;
  courses?: InputMaybe<CourseBoolExp>;
  courses_aggregate?: InputMaybe<CourseAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  disciplineName?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  fieldLimit?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventDiscipline" */
export enum EventDisciplineConstraint {
  /** unique or primary key constraint on columns "id" */
  PkRaceDiscipline = 'PK_raceDiscipline',
  /** unique or primary key constraint on columns "cuid" */
  EventDisciplineCuidKey = 'eventDiscipline_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventDisciplineIdKey = 'eventDiscipline_id_key',
  /** unique or primary key constraint on columns "id" */
  EventDisciplinePkey = 'eventDiscipline_pkey'
}

/** input type for incrementing numeric columns in table "eventDiscipline" */
export type EventDisciplineIncInput = {
  fieldLimit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventDiscipline" */
export type EventDisciplineInsertInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<CategoryArrRelInsertInput>;
  courses?: InputMaybe<CourseArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  disciplineName?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  fieldLimit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventDisciplineMaxFields = {
  __typename?: 'eventDiscipline_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  disciplineName?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  fieldLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventDiscipline" */
export type EventDisciplineMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventDisciplineMinFields = {
  __typename?: 'eventDiscipline_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  disciplineName?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  fieldLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventDiscipline" */
export type EventDisciplineMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventDiscipline" */
export type EventDisciplineMutationResponse = {
  __typename?: 'eventDiscipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventDiscipline>;
};

/** input type for inserting object relation for remote table "eventDiscipline" */
export type EventDisciplineObjRelInsertInput = {
  data: EventDisciplineInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventDisciplineOnConflict>;
};

/** on_conflict condition type for table "eventDiscipline" */
export type EventDisciplineOnConflict = {
  constraint: EventDisciplineConstraint;
  update_columns?: Array<EventDisciplineUpdateColumn>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};

/** Ordering options when selecting data from "eventDiscipline". */
export type EventDisciplineOrderBy = {
  allowWaitlist?: InputMaybe<OrderBy>;
  categories_aggregate?: InputMaybe<CategoryAggregateOrderBy>;
  courses_aggregate?: InputMaybe<CourseAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventDiscipline */
export type EventDisciplinePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventDiscipline" */
export enum EventDisciplineSelectColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DisciplineName = 'disciplineName',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FieldLimit = 'fieldLimit',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventDiscipline_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventDiscipline" */
export enum EventDisciplineSelectColumnEventDisciplineAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist'
}

/** select "eventDiscipline_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventDiscipline" */
export enum EventDisciplineSelectColumnEventDisciplineAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist'
}

/** input type for updating data in table "eventDiscipline" */
export type EventDisciplineSetInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  disciplineName?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  fieldLimit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventDisciplineStddevFields = {
  __typename?: 'eventDiscipline_stddev_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventDiscipline" */
export type EventDisciplineStddevOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventDisciplineStddevPopFields = {
  __typename?: 'eventDiscipline_stddev_pop_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventDiscipline" */
export type EventDisciplineStddevPopOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventDisciplineStddevSampFields = {
  __typename?: 'eventDiscipline_stddev_samp_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventDiscipline" */
export type EventDisciplineStddevSampOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventDisciplineSumFields = {
  __typename?: 'eventDiscipline_sum_fields';
  fieldLimit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventDiscipline" */
export type EventDisciplineSumOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventDiscipline" */
export enum EventDisciplineUpdateColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DisciplineName = 'disciplineName',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FieldLimit = 'fieldLimit',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventDisciplineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventDisciplineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventDisciplineSetInput>;
  where: EventDisciplineBoolExp;
};

/** aggregate var_pop on columns */
export type EventDisciplineVarPopFields = {
  __typename?: 'eventDiscipline_var_pop_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventDiscipline" */
export type EventDisciplineVarPopOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventDisciplineVarSampFields = {
  __typename?: 'eventDiscipline_var_samp_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventDiscipline" */
export type EventDisciplineVarSampOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventDisciplineVarianceFields = {
  __typename?: 'eventDiscipline_variance_fields';
  fieldLimit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventDiscipline" */
export type EventDisciplineVarianceOrderBy = {
  fieldLimit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export type EventEdge = {
  __typename?: 'eventEdge';
  cursor: Scalars['String'];
  node: Event;
};

/** columns and relationships of "eventImage" */
export type EventImage = Node & {
  __typename?: 'eventImage';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image: Image;
  imagePublicId: Scalars['String'];
  position: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventImage" */
export type EventImageConnection = {
  __typename?: 'eventImageConnection';
  edges: Array<EventImageEdge>;
  pageInfo: PageInfo;
};

export type EventImageEdge = {
  __typename?: 'eventImageEdge';
  cursor: Scalars['String'];
  node: EventImage;
};

/** aggregated selection of "eventImage" */
export type EventImageAggregate = {
  __typename?: 'eventImage_aggregate';
  aggregate?: Maybe<EventImageAggregateFields>;
  nodes: Array<EventImage>;
};

export type EventImageAggregateBoolExp = {
  count?: InputMaybe<EventImageAggregateBoolExpCount>;
};

export type EventImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventImage" */
export type EventImageAggregateFields = {
  __typename?: 'eventImage_aggregate_fields';
  avg?: Maybe<EventImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventImageMaxFields>;
  min?: Maybe<EventImageMinFields>;
  stddev?: Maybe<EventImageStddevFields>;
  stddev_pop?: Maybe<EventImageStddevPopFields>;
  stddev_samp?: Maybe<EventImageStddevSampFields>;
  sum?: Maybe<EventImageSumFields>;
  var_pop?: Maybe<EventImageVarPopFields>;
  var_samp?: Maybe<EventImageVarSampFields>;
  variance?: Maybe<EventImageVarianceFields>;
};


/** aggregate fields of "eventImage" */
export type EventImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventImage" */
export type EventImageAggregateOrderBy = {
  avg?: InputMaybe<EventImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventImageMaxOrderBy>;
  min?: InputMaybe<EventImageMinOrderBy>;
  stddev?: InputMaybe<EventImageStddevOrderBy>;
  stddev_pop?: InputMaybe<EventImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventImageStddevSampOrderBy>;
  sum?: InputMaybe<EventImageSumOrderBy>;
  var_pop?: InputMaybe<EventImageVarPopOrderBy>;
  var_samp?: InputMaybe<EventImageVarSampOrderBy>;
  variance?: InputMaybe<EventImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventImage" */
export type EventImageArrRelInsertInput = {
  data: Array<EventImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventImageOnConflict>;
};

/** aggregate avg on columns */
export type EventImageAvgFields = {
  __typename?: 'eventImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventImage" */
export type EventImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventImage". All fields are combined with a logical 'AND'. */
export type EventImageBoolExp = {
  _and?: InputMaybe<Array<EventImageBoolExp>>;
  _not?: InputMaybe<EventImageBoolExp>;
  _or?: InputMaybe<Array<EventImageBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventImage" */
export enum EventImageConstraint {
  /** unique or primary key constraint on columns "imagePublicId" */
  EventImageImagePublicIdKey = 'eventImage_imagePublicId_key',
  /** unique or primary key constraint on columns "cuid" */
  EventImagePkey = 'eventImage_pkey'
}

/** input type for incrementing numeric columns in table "eventImage" */
export type EventImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventImage" */
export type EventImageInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventImageMaxFields = {
  __typename?: 'eventImage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventImage" */
export type EventImageMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventImageMinFields = {
  __typename?: 'eventImage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventImage" */
export type EventImageMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventImage" */
export type EventImageMutationResponse = {
  __typename?: 'eventImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventImage>;
};

/** input type for inserting object relation for remote table "eventImage" */
export type EventImageObjRelInsertInput = {
  data: EventImageInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventImageOnConflict>;
};

/** on_conflict condition type for table "eventImage" */
export type EventImageOnConflict = {
  constraint: EventImageConstraint;
  update_columns?: Array<EventImageUpdateColumn>;
  where?: InputMaybe<EventImageBoolExp>;
};

/** Ordering options when selecting data from "eventImage". */
export type EventImageOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventImage */
export type EventImagePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventImage" */
export enum EventImageSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventImage" */
export type EventImageSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventImageStddevFields = {
  __typename?: 'eventImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventImage" */
export type EventImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventImageStddevPopFields = {
  __typename?: 'eventImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventImage" */
export type EventImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventImageStddevSampFields = {
  __typename?: 'eventImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventImage" */
export type EventImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventImageSumFields = {
  __typename?: 'eventImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventImage" */
export type EventImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "eventImage" */
export enum EventImageUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventImageSetInput>;
  where: EventImageBoolExp;
};

/** aggregate var_pop on columns */
export type EventImageVarPopFields = {
  __typename?: 'eventImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventImage" */
export type EventImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventImageVarSampFields = {
  __typename?: 'eventImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventImage" */
export type EventImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventImageVarianceFields = {
  __typename?: 'eventImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventImage" */
export type EventImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Merchandise for Event */
export type EventMerchandise = Node & {
  __typename?: 'eventMerchandise';
  availableTo?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  merchandise: Merchandise;
  merchandiseCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  visible: Scalars['Boolean'];
};


/** Merchandise for Event */
export type EventMerchandiseAvailableToArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "eventMerchandise" */
export type EventMerchandiseConnection = {
  __typename?: 'eventMerchandiseConnection';
  edges: Array<EventMerchandiseEdge>;
  pageInfo: PageInfo;
};

export type EventMerchandiseEdge = {
  __typename?: 'eventMerchandiseEdge';
  cursor: Scalars['String'];
  node: EventMerchandise;
};

/** aggregated selection of "eventMerchandise" */
export type EventMerchandiseAggregate = {
  __typename?: 'eventMerchandise_aggregate';
  aggregate?: Maybe<EventMerchandiseAggregateFields>;
  nodes: Array<EventMerchandise>;
};

export type EventMerchandiseAggregateBoolExp = {
  bool_and?: InputMaybe<EventMerchandiseAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventMerchandiseAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventMerchandiseAggregateBoolExpCount>;
};

export type EventMerchandiseAggregateBoolExpBoolAnd = {
  arguments: EventMerchandiseSelectColumnEventMerchandiseAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMerchandiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventMerchandiseAggregateBoolExpBoolOr = {
  arguments: EventMerchandiseSelectColumnEventMerchandiseAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMerchandiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventMerchandiseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMerchandiseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventMerchandise" */
export type EventMerchandiseAggregateFields = {
  __typename?: 'eventMerchandise_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EventMerchandiseMaxFields>;
  min?: Maybe<EventMerchandiseMinFields>;
};


/** aggregate fields of "eventMerchandise" */
export type EventMerchandiseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventMerchandise" */
export type EventMerchandiseAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventMerchandiseMaxOrderBy>;
  min?: InputMaybe<EventMerchandiseMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EventMerchandiseAppendInput = {
  availableTo?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "eventMerchandise" */
export type EventMerchandiseArrRelInsertInput = {
  data: Array<EventMerchandiseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventMerchandiseOnConflict>;
};

/** Boolean expression to filter rows from the table "eventMerchandise". All fields are combined with a logical 'AND'. */
export type EventMerchandiseBoolExp = {
  _and?: InputMaybe<Array<EventMerchandiseBoolExp>>;
  _not?: InputMaybe<EventMerchandiseBoolExp>;
  _or?: InputMaybe<Array<EventMerchandiseBoolExp>>;
  availableTo?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  merchandise?: InputMaybe<MerchandiseBoolExp>;
  merchandiseCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  visible?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "eventMerchandise" */
export enum EventMerchandiseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventMerchandisePkey = 'eventMerchandise_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EventMerchandiseDeleteAtPathInput = {
  availableTo?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EventMerchandiseDeleteElemInput = {
  availableTo?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EventMerchandiseDeleteKeyInput = {
  availableTo?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "eventMerchandise" */
export type EventMerchandiseInsertInput = {
  availableTo?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  merchandise?: InputMaybe<MerchandiseObjRelInsertInput>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type EventMerchandiseMaxFields = {
  __typename?: 'eventMerchandise_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventMerchandise" */
export type EventMerchandiseMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventMerchandiseMinFields = {
  __typename?: 'eventMerchandise_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventMerchandise" */
export type EventMerchandiseMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventMerchandise" */
export type EventMerchandiseMutationResponse = {
  __typename?: 'eventMerchandise_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventMerchandise>;
};

/** on_conflict condition type for table "eventMerchandise" */
export type EventMerchandiseOnConflict = {
  constraint: EventMerchandiseConstraint;
  update_columns?: Array<EventMerchandiseUpdateColumn>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};

/** Ordering options when selecting data from "eventMerchandise". */
export type EventMerchandiseOrderBy = {
  availableTo?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  merchandise?: InputMaybe<MerchandiseOrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  visible?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventMerchandise */
export type EventMerchandisePkColumnsInput = {
  cuid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EventMerchandisePrependInput = {
  availableTo?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "eventMerchandise" */
export enum EventMerchandiseSelectColumn {
  /** column name */
  AvailableTo = 'availableTo',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

/** select "eventMerchandise_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventMerchandise" */
export enum EventMerchandiseSelectColumnEventMerchandiseAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Visible = 'visible'
}

/** select "eventMerchandise_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventMerchandise" */
export enum EventMerchandiseSelectColumnEventMerchandiseAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "eventMerchandise" */
export type EventMerchandiseSetInput = {
  availableTo?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "eventMerchandise" */
export enum EventMerchandiseUpdateColumn {
  /** column name */
  AvailableTo = 'availableTo',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

export type EventMerchandiseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventMerchandiseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventMerchandiseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventMerchandiseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventMerchandiseDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventMerchandisePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventMerchandiseSetInput>;
  where: EventMerchandiseBoolExp;
};

/** The "template" for a race or big ride. This stores a thin layer of data that is used across event "instances" */
export type EventMetadata = Node & {
  __typename?: 'eventMetadata';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: EventAggregate;
  /** An array relationship connection */
  events_connection: EventConnection;
  id: Scalars['ID'];
  /** Does this event have categories or not? This is the flag for a "big" event or not */
  isCategoriedEvent: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfDays?: Maybe<Scalars['Int']>;
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** The "template" for a race or big ride. This stores a thin layer of data that is used across event "instances" */
export type EventMetadataEventsArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** The "template" for a race or big ride. This stores a thin layer of data that is used across event "instances" */
export type EventMetadataEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** The "template" for a race or big ride. This stores a thin layer of data that is used across event "instances" */
export type EventMetadataEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};

/** A Relay connection object on "eventMetadata" */
export type EventMetadataConnection = {
  __typename?: 'eventMetadataConnection';
  edges: Array<EventMetadataEdge>;
  pageInfo: PageInfo;
};

export type EventMetadataEdge = {
  __typename?: 'eventMetadataEdge';
  cursor: Scalars['String'];
  node: EventMetadata;
};

/** aggregated selection of "eventMetadata" */
export type EventMetadataAggregate = {
  __typename?: 'eventMetadata_aggregate';
  aggregate?: Maybe<EventMetadataAggregateFields>;
  nodes: Array<EventMetadata>;
};

export type EventMetadataAggregateBoolExp = {
  bool_and?: InputMaybe<EventMetadataAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventMetadataAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventMetadataAggregateBoolExpCount>;
};

export type EventMetadataAggregateBoolExpBoolAnd = {
  arguments: EventMetadataSelectColumnEventMetadataAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMetadataBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventMetadataAggregateBoolExpBoolOr = {
  arguments: EventMetadataSelectColumnEventMetadataAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMetadataBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventMetadataAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventMetadataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventMetadataBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventMetadata" */
export type EventMetadataAggregateFields = {
  __typename?: 'eventMetadata_aggregate_fields';
  avg?: Maybe<EventMetadataAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventMetadataMaxFields>;
  min?: Maybe<EventMetadataMinFields>;
  stddev?: Maybe<EventMetadataStddevFields>;
  stddev_pop?: Maybe<EventMetadataStddevPopFields>;
  stddev_samp?: Maybe<EventMetadataStddevSampFields>;
  sum?: Maybe<EventMetadataSumFields>;
  var_pop?: Maybe<EventMetadataVarPopFields>;
  var_samp?: Maybe<EventMetadataVarSampFields>;
  variance?: Maybe<EventMetadataVarianceFields>;
};


/** aggregate fields of "eventMetadata" */
export type EventMetadataAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventMetadataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventMetadata" */
export type EventMetadataAggregateOrderBy = {
  avg?: InputMaybe<EventMetadataAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventMetadataMaxOrderBy>;
  min?: InputMaybe<EventMetadataMinOrderBy>;
  stddev?: InputMaybe<EventMetadataStddevOrderBy>;
  stddev_pop?: InputMaybe<EventMetadataStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventMetadataStddevSampOrderBy>;
  sum?: InputMaybe<EventMetadataSumOrderBy>;
  var_pop?: InputMaybe<EventMetadataVarPopOrderBy>;
  var_samp?: InputMaybe<EventMetadataVarSampOrderBy>;
  variance?: InputMaybe<EventMetadataVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventMetadata" */
export type EventMetadataArrRelInsertInput = {
  data: Array<EventMetadataInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventMetadataOnConflict>;
};

/** aggregate avg on columns */
export type EventMetadataAvgFields = {
  __typename?: 'eventMetadata_avg_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventMetadata" */
export type EventMetadataAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventMetadata". All fields are combined with a logical 'AND'. */
export type EventMetadataBoolExp = {
  _and?: InputMaybe<Array<EventMetadataBoolExp>>;
  _not?: InputMaybe<EventMetadataBoolExp>;
  _or?: InputMaybe<Array<EventMetadataBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  events?: InputMaybe<EventBoolExp>;
  events_aggregate?: InputMaybe<EventAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isCategoriedEvent?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  numberOfDays?: InputMaybe<IntComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventMetadata" */
export enum EventMetadataConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventMetadataCuidKey = 'eventMetadata_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventMetadataPkey = 'eventMetadata_pkey'
}

/** input type for incrementing numeric columns in table "eventMetadata" */
export type EventMetadataIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  numberOfDays?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventMetadata" */
export type EventMetadataInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  events?: InputMaybe<EventArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  /** Does this event have categories or not? This is the flag for a "big" event or not */
  isCategoriedEvent?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfDays?: InputMaybe<Scalars['Int']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventMetadataMaxFields = {
  __typename?: 'eventMetadata_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  numberOfDays?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventMetadata" */
export type EventMetadataMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventMetadataMinFields = {
  __typename?: 'eventMetadata_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  numberOfDays?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventMetadata" */
export type EventMetadataMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventMetadata" */
export type EventMetadataMutationResponse = {
  __typename?: 'eventMetadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventMetadata>;
};

/** input type for inserting object relation for remote table "eventMetadata" */
export type EventMetadataObjRelInsertInput = {
  data: EventMetadataInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventMetadataOnConflict>;
};

/** on_conflict condition type for table "eventMetadata" */
export type EventMetadataOnConflict = {
  constraint: EventMetadataConstraint;
  update_columns?: Array<EventMetadataUpdateColumn>;
  where?: InputMaybe<EventMetadataBoolExp>;
};

/** Ordering options when selecting data from "eventMetadata". */
export type EventMetadataOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  events_aggregate?: InputMaybe<EventAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isCategoriedEvent?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventMetadata */
export type EventMetadataPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventMetadata" */
export enum EventMetadataSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCategoriedEvent = 'isCategoriedEvent',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfDays = 'numberOfDays',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventMetadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventMetadata" */
export enum EventMetadataSelectColumnEventMetadataAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCategoriedEvent = 'isCategoriedEvent'
}

/** select "eventMetadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventMetadata" */
export enum EventMetadataSelectColumnEventMetadataAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCategoriedEvent = 'isCategoriedEvent'
}

/** input type for updating data in table "eventMetadata" */
export type EventMetadataSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** Does this event have categories or not? This is the flag for a "big" event or not */
  isCategoriedEvent?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfDays?: InputMaybe<Scalars['Int']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventMetadataStddevFields = {
  __typename?: 'eventMetadata_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventMetadata" */
export type EventMetadataStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventMetadataStddevPopFields = {
  __typename?: 'eventMetadata_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventMetadata" */
export type EventMetadataStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventMetadataStddevSampFields = {
  __typename?: 'eventMetadata_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventMetadata" */
export type EventMetadataStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventMetadataSumFields = {
  __typename?: 'eventMetadata_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  numberOfDays?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventMetadata" */
export type EventMetadataSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** update columns of table "eventMetadata" */
export enum EventMetadataUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCategoriedEvent = 'isCategoriedEvent',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfDays = 'numberOfDays',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventMetadataUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventMetadataIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventMetadataSetInput>;
  where: EventMetadataBoolExp;
};

/** aggregate var_pop on columns */
export type EventMetadataVarPopFields = {
  __typename?: 'eventMetadata_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventMetadata" */
export type EventMetadataVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventMetadataVarSampFields = {
  __typename?: 'eventMetadata_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventMetadata" */
export type EventMetadataVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventMetadataVarianceFields = {
  __typename?: 'eventMetadata_variance_fields';
  id?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventMetadata" */
export type EventMetadataVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  numberOfDays?: InputMaybe<OrderBy>;
};

/** All flags for events that need moderation */
export type EventModerationFlag = Node & {
  __typename?: 'eventModerationFlag';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  isReportOpen: Scalars['Boolean'];
  reason: Scalars['String'];
  reasonDetails: Scalars['String'];
  reportPriority: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "eventModerationFlag" */
export type EventModerationFlagConnection = {
  __typename?: 'eventModerationFlagConnection';
  edges: Array<EventModerationFlagEdge>;
  pageInfo: PageInfo;
};

export type EventModerationFlagEdge = {
  __typename?: 'eventModerationFlagEdge';
  cursor: Scalars['String'];
  node: EventModerationFlag;
};

/** aggregated selection of "eventModerationFlag" */
export type EventModerationFlagAggregate = {
  __typename?: 'eventModerationFlag_aggregate';
  aggregate?: Maybe<EventModerationFlagAggregateFields>;
  nodes: Array<EventModerationFlag>;
};

export type EventModerationFlagAggregateBoolExp = {
  bool_and?: InputMaybe<EventModerationFlagAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventModerationFlagAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventModerationFlagAggregateBoolExpCount>;
};

export type EventModerationFlagAggregateBoolExpBoolAnd = {
  arguments: EventModerationFlagSelectColumnEventModerationFlagAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventModerationFlagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventModerationFlagAggregateBoolExpBoolOr = {
  arguments: EventModerationFlagSelectColumnEventModerationFlagAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventModerationFlagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventModerationFlagAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventModerationFlagBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventModerationFlag" */
export type EventModerationFlagAggregateFields = {
  __typename?: 'eventModerationFlag_aggregate_fields';
  avg?: Maybe<EventModerationFlagAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventModerationFlagMaxFields>;
  min?: Maybe<EventModerationFlagMinFields>;
  stddev?: Maybe<EventModerationFlagStddevFields>;
  stddev_pop?: Maybe<EventModerationFlagStddevPopFields>;
  stddev_samp?: Maybe<EventModerationFlagStddevSampFields>;
  sum?: Maybe<EventModerationFlagSumFields>;
  var_pop?: Maybe<EventModerationFlagVarPopFields>;
  var_samp?: Maybe<EventModerationFlagVarSampFields>;
  variance?: Maybe<EventModerationFlagVarianceFields>;
};


/** aggregate fields of "eventModerationFlag" */
export type EventModerationFlagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventModerationFlag" */
export type EventModerationFlagAggregateOrderBy = {
  avg?: InputMaybe<EventModerationFlagAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventModerationFlagMaxOrderBy>;
  min?: InputMaybe<EventModerationFlagMinOrderBy>;
  stddev?: InputMaybe<EventModerationFlagStddevOrderBy>;
  stddev_pop?: InputMaybe<EventModerationFlagStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventModerationFlagStddevSampOrderBy>;
  sum?: InputMaybe<EventModerationFlagSumOrderBy>;
  var_pop?: InputMaybe<EventModerationFlagVarPopOrderBy>;
  var_samp?: InputMaybe<EventModerationFlagVarSampOrderBy>;
  variance?: InputMaybe<EventModerationFlagVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventModerationFlag" */
export type EventModerationFlagArrRelInsertInput = {
  data: Array<EventModerationFlagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventModerationFlagOnConflict>;
};

/** aggregate avg on columns */
export type EventModerationFlagAvgFields = {
  __typename?: 'eventModerationFlag_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventModerationFlag" */
export type EventModerationFlagAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventModerationFlag". All fields are combined with a logical 'AND'. */
export type EventModerationFlagBoolExp = {
  _and?: InputMaybe<Array<EventModerationFlagBoolExp>>;
  _not?: InputMaybe<EventModerationFlagBoolExp>;
  _or?: InputMaybe<Array<EventModerationFlagBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isReportOpen?: InputMaybe<BooleanComparisonExp>;
  reason?: InputMaybe<StringComparisonExp>;
  reasonDetails?: InputMaybe<StringComparisonExp>;
  reportPriority?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventModerationFlag" */
export enum EventModerationFlagConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventModerationFlagCuidKey = 'eventModerationFlag_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventModerationFlagPkey = 'eventModerationFlag_pkey'
}

/** input type for incrementing numeric columns in table "eventModerationFlag" */
export type EventModerationFlagIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventModerationFlag" */
export type EventModerationFlagInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isReportOpen?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  reportPriority?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventModerationFlagMaxFields = {
  __typename?: 'eventModerationFlag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  reasonDetails?: Maybe<Scalars['String']>;
  reportPriority?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventModerationFlag" */
export type EventModerationFlagMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventModerationFlagMinFields = {
  __typename?: 'eventModerationFlag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  reasonDetails?: Maybe<Scalars['String']>;
  reportPriority?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventModerationFlag" */
export type EventModerationFlagMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventModerationFlag" */
export type EventModerationFlagMutationResponse = {
  __typename?: 'eventModerationFlag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventModerationFlag>;
};

/** on_conflict condition type for table "eventModerationFlag" */
export type EventModerationFlagOnConflict = {
  constraint: EventModerationFlagConstraint;
  update_columns?: Array<EventModerationFlagUpdateColumn>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};

/** Ordering options when selecting data from "eventModerationFlag". */
export type EventModerationFlagOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isReportOpen?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reasonDetails?: InputMaybe<OrderBy>;
  reportPriority?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventModerationFlag */
export type EventModerationFlagPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventModerationFlag" */
export enum EventModerationFlagSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsReportOpen = 'isReportOpen',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReasonDetails = 'reasonDetails',
  /** column name */
  ReportPriority = 'reportPriority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "eventModerationFlag_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventModerationFlag" */
export enum EventModerationFlagSelectColumnEventModerationFlagAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsReportOpen = 'isReportOpen'
}

/** select "eventModerationFlag_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventModerationFlag" */
export enum EventModerationFlagSelectColumnEventModerationFlagAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsReportOpen = 'isReportOpen'
}

/** input type for updating data in table "eventModerationFlag" */
export type EventModerationFlagSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isReportOpen?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  reportPriority?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventModerationFlagStddevFields = {
  __typename?: 'eventModerationFlag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventModerationFlag" */
export type EventModerationFlagStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventModerationFlagStddevPopFields = {
  __typename?: 'eventModerationFlag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventModerationFlag" */
export type EventModerationFlagStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventModerationFlagStddevSampFields = {
  __typename?: 'eventModerationFlag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventModerationFlag" */
export type EventModerationFlagStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventModerationFlagSumFields = {
  __typename?: 'eventModerationFlag_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventModerationFlag" */
export type EventModerationFlagSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventModerationFlag" */
export enum EventModerationFlagUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsReportOpen = 'isReportOpen',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReasonDetails = 'reasonDetails',
  /** column name */
  ReportPriority = 'reportPriority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type EventModerationFlagUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventModerationFlagIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventModerationFlagSetInput>;
  where: EventModerationFlagBoolExp;
};

/** aggregate var_pop on columns */
export type EventModerationFlagVarPopFields = {
  __typename?: 'eventModerationFlag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventModerationFlag" */
export type EventModerationFlagVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventModerationFlagVarSampFields = {
  __typename?: 'eventModerationFlag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventModerationFlag" */
export type EventModerationFlagVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventModerationFlagVarianceFields = {
  __typename?: 'eventModerationFlag_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventModerationFlag" */
export type EventModerationFlagVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Pit Space for Events */
export type EventPitSpace = Node & {
  __typename?: 'eventPitSpace';
  /** An object relationship */
  AccessName: AccessName;
  allowWaitlist: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventPitSpaceAddOns: Array<EventPitSpaceAddOn>;
  /** An aggregate relationship */
  eventPitSpaceAddOns_aggregate: EventPitSpaceAddOnAggregate;
  /** An array relationship connection */
  eventPitSpaceAddOns_connection: EventPitSpaceAddOnConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  pitSpaceAccess: AccessNameEnum;
  plotLength?: Maybe<Scalars['numeric']>;
  plotLengthType: Scalars['String'];
  plotWidth?: Maybe<Scalars['numeric']>;
  plotWidthType: Scalars['String'];
  position: Scalars['Int'];
  price?: Maybe<Scalars['numeric']>;
  requireDimensions: Scalars['Boolean'];
  shouldContactForPrice: Scalars['Boolean'];
  shouldUseContactSize: Scalars['Boolean'];
  shouldUsePlotSize: Scalars['Boolean'];
  shouldUseUnitSize: Scalars['Boolean'];
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Scalars['numeric']>;
  unitSizeType: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Pit Space for Events */
export type EventPitSpaceEventPitSpaceAddOnsArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceAddOnOrderBy>>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};


/** Pit Space for Events */
export type EventPitSpaceEventPitSpaceAddOnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceAddOnOrderBy>>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};


/** Pit Space for Events */
export type EventPitSpaceEventPitSpaceAddOnsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceAddOnOrderBy>>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};

/** Add-on for pit space. i.e. electricity/eater/tents/etc */
export type EventPitSpaceAddOn = Node & {
  __typename?: 'eventPitSpaceAddOn';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventPitSpace: EventPitSpace;
  eventPitSpaceCuid: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnConnection = {
  __typename?: 'eventPitSpaceAddOnConnection';
  edges: Array<EventPitSpaceAddOnEdge>;
  pageInfo: PageInfo;
};

export type EventPitSpaceAddOnEdge = {
  __typename?: 'eventPitSpaceAddOnEdge';
  cursor: Scalars['String'];
  node: EventPitSpaceAddOn;
};

/** aggregated selection of "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnAggregate = {
  __typename?: 'eventPitSpaceAddOn_aggregate';
  aggregate?: Maybe<EventPitSpaceAddOnAggregateFields>;
  nodes: Array<EventPitSpaceAddOn>;
};

export type EventPitSpaceAddOnAggregateBoolExp = {
  count?: InputMaybe<EventPitSpaceAddOnAggregateBoolExpCount>;
};

export type EventPitSpaceAddOnAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPitSpaceAddOnBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnAggregateFields = {
  __typename?: 'eventPitSpaceAddOn_aggregate_fields';
  avg?: Maybe<EventPitSpaceAddOnAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPitSpaceAddOnMaxFields>;
  min?: Maybe<EventPitSpaceAddOnMinFields>;
  stddev?: Maybe<EventPitSpaceAddOnStddevFields>;
  stddev_pop?: Maybe<EventPitSpaceAddOnStddevPopFields>;
  stddev_samp?: Maybe<EventPitSpaceAddOnStddevSampFields>;
  sum?: Maybe<EventPitSpaceAddOnSumFields>;
  var_pop?: Maybe<EventPitSpaceAddOnVarPopFields>;
  var_samp?: Maybe<EventPitSpaceAddOnVarSampFields>;
  variance?: Maybe<EventPitSpaceAddOnVarianceFields>;
};


/** aggregate fields of "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnAggregateOrderBy = {
  avg?: InputMaybe<EventPitSpaceAddOnAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPitSpaceAddOnMaxOrderBy>;
  min?: InputMaybe<EventPitSpaceAddOnMinOrderBy>;
  stddev?: InputMaybe<EventPitSpaceAddOnStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPitSpaceAddOnStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPitSpaceAddOnStddevSampOrderBy>;
  sum?: InputMaybe<EventPitSpaceAddOnSumOrderBy>;
  var_pop?: InputMaybe<EventPitSpaceAddOnVarPopOrderBy>;
  var_samp?: InputMaybe<EventPitSpaceAddOnVarSampOrderBy>;
  variance?: InputMaybe<EventPitSpaceAddOnVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnArrRelInsertInput = {
  data: Array<EventPitSpaceAddOnInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPitSpaceAddOnOnConflict>;
};

/** aggregate avg on columns */
export type EventPitSpaceAddOnAvgFields = {
  __typename?: 'eventPitSpaceAddOn_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnAvgOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPitSpaceAddOn". All fields are combined with a logical 'AND'. */
export type EventPitSpaceAddOnBoolExp = {
  _and?: InputMaybe<Array<EventPitSpaceAddOnBoolExp>>;
  _not?: InputMaybe<EventPitSpaceAddOnBoolExp>;
  _or?: InputMaybe<Array<EventPitSpaceAddOnBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventPitSpace?: InputMaybe<EventPitSpaceBoolExp>;
  eventPitSpaceCuid?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPitSpaceAddOn" */
export enum EventPitSpaceAddOnConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPitSpaceAddOnPkey = 'eventPitSpaceAddOn_pkey'
}

/** input type for incrementing numeric columns in table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnIncInput = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventPitSpace?: InputMaybe<EventPitSpaceObjRelInsertInput>;
  eventPitSpaceCuid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPitSpaceAddOnMaxFields = {
  __typename?: 'eventPitSpaceAddOn_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventPitSpaceCuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPitSpaceCuid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPitSpaceAddOnMinFields = {
  __typename?: 'eventPitSpaceAddOn_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventPitSpaceCuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPitSpaceCuid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnMutationResponse = {
  __typename?: 'eventPitSpaceAddOn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPitSpaceAddOn>;
};

/** on_conflict condition type for table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnOnConflict = {
  constraint: EventPitSpaceAddOnConstraint;
  update_columns?: Array<EventPitSpaceAddOnUpdateColumn>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};

/** Ordering options when selecting data from "eventPitSpaceAddOn". */
export type EventPitSpaceAddOnOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPitSpace?: InputMaybe<EventPitSpaceOrderBy>;
  eventPitSpaceCuid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPitSpaceAddOn */
export type EventPitSpaceAddOnPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventPitSpaceAddOn" */
export enum EventPitSpaceAddOnSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventPitSpaceCuid = 'eventPitSpaceCuid',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventPitSpaceCuid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPitSpaceAddOnStddevFields = {
  __typename?: 'eventPitSpaceAddOn_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnStddevOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPitSpaceAddOnStddevPopFields = {
  __typename?: 'eventPitSpaceAddOn_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnStddevPopOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPitSpaceAddOnStddevSampFields = {
  __typename?: 'eventPitSpaceAddOn_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnStddevSampOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPitSpaceAddOnSumFields = {
  __typename?: 'eventPitSpaceAddOn_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnSumOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPitSpaceAddOn" */
export enum EventPitSpaceAddOnUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventPitSpaceCuid = 'eventPitSpaceCuid',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPitSpaceAddOnUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPitSpaceAddOnIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPitSpaceAddOnSetInput>;
  where: EventPitSpaceAddOnBoolExp;
};

/** aggregate var_pop on columns */
export type EventPitSpaceAddOnVarPopFields = {
  __typename?: 'eventPitSpaceAddOn_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnVarPopOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPitSpaceAddOnVarSampFields = {
  __typename?: 'eventPitSpaceAddOn_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnVarSampOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPitSpaceAddOnVarianceFields = {
  __typename?: 'eventPitSpaceAddOn_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPitSpaceAddOn" */
export type EventPitSpaceAddOnVarianceOrderBy = {
  price?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventPitSpace" */
export type EventPitSpaceConnection = {
  __typename?: 'eventPitSpaceConnection';
  edges: Array<EventPitSpaceEdge>;
  pageInfo: PageInfo;
};

export type EventPitSpaceEdge = {
  __typename?: 'eventPitSpaceEdge';
  cursor: Scalars['String'];
  node: EventPitSpace;
};

/** aggregated selection of "eventPitSpace" */
export type EventPitSpaceAggregate = {
  __typename?: 'eventPitSpace_aggregate';
  aggregate?: Maybe<EventPitSpaceAggregateFields>;
  nodes: Array<EventPitSpace>;
};

export type EventPitSpaceAggregateBoolExp = {
  bool_and?: InputMaybe<EventPitSpaceAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventPitSpaceAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventPitSpaceAggregateBoolExpCount>;
};

export type EventPitSpaceAggregateBoolExpBoolAnd = {
  arguments: EventPitSpaceSelectColumnEventPitSpaceAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPitSpaceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPitSpaceAggregateBoolExpBoolOr = {
  arguments: EventPitSpaceSelectColumnEventPitSpaceAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPitSpaceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPitSpaceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPitSpaceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPitSpace" */
export type EventPitSpaceAggregateFields = {
  __typename?: 'eventPitSpace_aggregate_fields';
  avg?: Maybe<EventPitSpaceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPitSpaceMaxFields>;
  min?: Maybe<EventPitSpaceMinFields>;
  stddev?: Maybe<EventPitSpaceStddevFields>;
  stddev_pop?: Maybe<EventPitSpaceStddevPopFields>;
  stddev_samp?: Maybe<EventPitSpaceStddevSampFields>;
  sum?: Maybe<EventPitSpaceSumFields>;
  var_pop?: Maybe<EventPitSpaceVarPopFields>;
  var_samp?: Maybe<EventPitSpaceVarSampFields>;
  variance?: Maybe<EventPitSpaceVarianceFields>;
};


/** aggregate fields of "eventPitSpace" */
export type EventPitSpaceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPitSpace" */
export type EventPitSpaceAggregateOrderBy = {
  avg?: InputMaybe<EventPitSpaceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPitSpaceMaxOrderBy>;
  min?: InputMaybe<EventPitSpaceMinOrderBy>;
  stddev?: InputMaybe<EventPitSpaceStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPitSpaceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPitSpaceStddevSampOrderBy>;
  sum?: InputMaybe<EventPitSpaceSumOrderBy>;
  var_pop?: InputMaybe<EventPitSpaceVarPopOrderBy>;
  var_samp?: InputMaybe<EventPitSpaceVarSampOrderBy>;
  variance?: InputMaybe<EventPitSpaceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPitSpace" */
export type EventPitSpaceArrRelInsertInput = {
  data: Array<EventPitSpaceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPitSpaceOnConflict>;
};

/** aggregate avg on columns */
export type EventPitSpaceAvgFields = {
  __typename?: 'eventPitSpace_avg_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPitSpace" */
export type EventPitSpaceAvgOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPitSpace". All fields are combined with a logical 'AND'. */
export type EventPitSpaceBoolExp = {
  AccessName?: InputMaybe<AccessNameBoolExp>;
  _and?: InputMaybe<Array<EventPitSpaceBoolExp>>;
  _not?: InputMaybe<EventPitSpaceBoolExp>;
  _or?: InputMaybe<Array<EventPitSpaceBoolExp>>;
  allowWaitlist?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventPitSpaceAddOns?: InputMaybe<EventPitSpaceAddOnBoolExp>;
  eventPitSpaceAddOns_aggregate?: InputMaybe<EventPitSpaceAddOnAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  pitSpaceAccess?: InputMaybe<AccessNameEnumComparisonExp>;
  plotLength?: InputMaybe<NumericComparisonExp>;
  plotLengthType?: InputMaybe<StringComparisonExp>;
  plotWidth?: InputMaybe<NumericComparisonExp>;
  plotWidthType?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  requireDimensions?: InputMaybe<BooleanComparisonExp>;
  shouldContactForPrice?: InputMaybe<BooleanComparisonExp>;
  shouldUseContactSize?: InputMaybe<BooleanComparisonExp>;
  shouldUsePlotSize?: InputMaybe<BooleanComparisonExp>;
  shouldUseUnitSize?: InputMaybe<BooleanComparisonExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  spotsReserved?: InputMaybe<IntComparisonExp>;
  unitSize?: InputMaybe<NumericComparisonExp>;
  unitSizeType?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPitSpace" */
export enum EventPitSpaceConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPitSpacePkey = 'eventPitSpace_pkey'
}

/** input type for incrementing numeric columns in table "eventPitSpace" */
export type EventPitSpaceIncInput = {
  plotLength?: InputMaybe<Scalars['numeric']>;
  plotWidth?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
  unitSize?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "eventPitSpace" */
export type EventPitSpaceInsertInput = {
  AccessName?: InputMaybe<AccessNameObjRelInsertInput>;
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventPitSpaceAddOns?: InputMaybe<EventPitSpaceAddOnArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  pitSpaceAccess?: InputMaybe<AccessNameEnum>;
  plotLength?: InputMaybe<Scalars['numeric']>;
  plotLengthType?: InputMaybe<Scalars['String']>;
  plotWidth?: InputMaybe<Scalars['numeric']>;
  plotWidthType?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  requireDimensions?: InputMaybe<Scalars['Boolean']>;
  shouldContactForPrice?: InputMaybe<Scalars['Boolean']>;
  shouldUseContactSize?: InputMaybe<Scalars['Boolean']>;
  shouldUsePlotSize?: InputMaybe<Scalars['Boolean']>;
  shouldUseUnitSize?: InputMaybe<Scalars['Boolean']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
  unitSize?: InputMaybe<Scalars['numeric']>;
  unitSizeType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPitSpaceMaxFields = {
  __typename?: 'eventPitSpace_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plotLength?: Maybe<Scalars['numeric']>;
  plotLengthType?: Maybe<Scalars['String']>;
  plotWidth?: Maybe<Scalars['numeric']>;
  plotWidthType?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Scalars['numeric']>;
  unitSizeType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPitSpace" */
export type EventPitSpaceMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  plotLength?: InputMaybe<OrderBy>;
  plotLengthType?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  plotWidthType?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
  unitSizeType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPitSpaceMinFields = {
  __typename?: 'eventPitSpace_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plotLength?: Maybe<Scalars['numeric']>;
  plotLengthType?: Maybe<Scalars['String']>;
  plotWidth?: Maybe<Scalars['numeric']>;
  plotWidthType?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Scalars['numeric']>;
  unitSizeType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPitSpace" */
export type EventPitSpaceMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  plotLength?: InputMaybe<OrderBy>;
  plotLengthType?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  plotWidthType?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
  unitSizeType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPitSpace" */
export type EventPitSpaceMutationResponse = {
  __typename?: 'eventPitSpace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPitSpace>;
};

/** input type for inserting object relation for remote table "eventPitSpace" */
export type EventPitSpaceObjRelInsertInput = {
  data: EventPitSpaceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPitSpaceOnConflict>;
};

/** on_conflict condition type for table "eventPitSpace" */
export type EventPitSpaceOnConflict = {
  constraint: EventPitSpaceConstraint;
  update_columns?: Array<EventPitSpaceUpdateColumn>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};

/** Ordering options when selecting data from "eventPitSpace". */
export type EventPitSpaceOrderBy = {
  AccessName?: InputMaybe<AccessNameOrderBy>;
  allowWaitlist?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPitSpaceAddOns_aggregate?: InputMaybe<EventPitSpaceAddOnAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  pitSpaceAccess?: InputMaybe<OrderBy>;
  plotLength?: InputMaybe<OrderBy>;
  plotLengthType?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  plotWidthType?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  requireDimensions?: InputMaybe<OrderBy>;
  shouldContactForPrice?: InputMaybe<OrderBy>;
  shouldUseContactSize?: InputMaybe<OrderBy>;
  shouldUsePlotSize?: InputMaybe<OrderBy>;
  shouldUseUnitSize?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
  unitSizeType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPitSpace */
export type EventPitSpacePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventPitSpace" */
export enum EventPitSpaceSelectColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Name = 'name',
  /** column name */
  PitSpaceAccess = 'pitSpaceAccess',
  /** column name */
  PlotLength = 'plotLength',
  /** column name */
  PlotLengthType = 'plotLengthType',
  /** column name */
  PlotWidth = 'plotWidth',
  /** column name */
  PlotWidthType = 'plotWidthType',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  RequireDimensions = 'requireDimensions',
  /** column name */
  ShouldContactForPrice = 'shouldContactForPrice',
  /** column name */
  ShouldUseContactSize = 'shouldUseContactSize',
  /** column name */
  ShouldUsePlotSize = 'shouldUsePlotSize',
  /** column name */
  ShouldUseUnitSize = 'shouldUseUnitSize',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  SpotsReserved = 'spotsReserved',
  /** column name */
  UnitSize = 'unitSize',
  /** column name */
  UnitSizeType = 'unitSizeType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventPitSpace_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventPitSpace" */
export enum EventPitSpaceSelectColumnEventPitSpaceAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  RequireDimensions = 'requireDimensions',
  /** column name */
  ShouldContactForPrice = 'shouldContactForPrice',
  /** column name */
  ShouldUseContactSize = 'shouldUseContactSize',
  /** column name */
  ShouldUsePlotSize = 'shouldUsePlotSize',
  /** column name */
  ShouldUseUnitSize = 'shouldUseUnitSize'
}

/** select "eventPitSpace_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventPitSpace" */
export enum EventPitSpaceSelectColumnEventPitSpaceAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  RequireDimensions = 'requireDimensions',
  /** column name */
  ShouldContactForPrice = 'shouldContactForPrice',
  /** column name */
  ShouldUseContactSize = 'shouldUseContactSize',
  /** column name */
  ShouldUsePlotSize = 'shouldUsePlotSize',
  /** column name */
  ShouldUseUnitSize = 'shouldUseUnitSize'
}

/** input type for updating data in table "eventPitSpace" */
export type EventPitSpaceSetInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pitSpaceAccess?: InputMaybe<AccessNameEnum>;
  plotLength?: InputMaybe<Scalars['numeric']>;
  plotLengthType?: InputMaybe<Scalars['String']>;
  plotWidth?: InputMaybe<Scalars['numeric']>;
  plotWidthType?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  requireDimensions?: InputMaybe<Scalars['Boolean']>;
  shouldContactForPrice?: InputMaybe<Scalars['Boolean']>;
  shouldUseContactSize?: InputMaybe<Scalars['Boolean']>;
  shouldUsePlotSize?: InputMaybe<Scalars['Boolean']>;
  shouldUseUnitSize?: InputMaybe<Scalars['Boolean']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
  unitSize?: InputMaybe<Scalars['numeric']>;
  unitSizeType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPitSpaceStddevFields = {
  __typename?: 'eventPitSpace_stddev_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPitSpace" */
export type EventPitSpaceStddevOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPitSpaceStddevPopFields = {
  __typename?: 'eventPitSpace_stddev_pop_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPitSpace" */
export type EventPitSpaceStddevPopOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPitSpaceStddevSampFields = {
  __typename?: 'eventPitSpace_stddev_samp_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPitSpace" */
export type EventPitSpaceStddevSampOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPitSpaceSumFields = {
  __typename?: 'eventPitSpace_sum_fields';
  plotLength?: Maybe<Scalars['numeric']>;
  plotWidth?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "eventPitSpace" */
export type EventPitSpaceSumOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPitSpace" */
export enum EventPitSpaceUpdateColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Name = 'name',
  /** column name */
  PitSpaceAccess = 'pitSpaceAccess',
  /** column name */
  PlotLength = 'plotLength',
  /** column name */
  PlotLengthType = 'plotLengthType',
  /** column name */
  PlotWidth = 'plotWidth',
  /** column name */
  PlotWidthType = 'plotWidthType',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  RequireDimensions = 'requireDimensions',
  /** column name */
  ShouldContactForPrice = 'shouldContactForPrice',
  /** column name */
  ShouldUseContactSize = 'shouldUseContactSize',
  /** column name */
  ShouldUsePlotSize = 'shouldUsePlotSize',
  /** column name */
  ShouldUseUnitSize = 'shouldUseUnitSize',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  SpotsReserved = 'spotsReserved',
  /** column name */
  UnitSize = 'unitSize',
  /** column name */
  UnitSizeType = 'unitSizeType',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPitSpaceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPitSpaceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPitSpaceSetInput>;
  where: EventPitSpaceBoolExp;
};

/** aggregate var_pop on columns */
export type EventPitSpaceVarPopFields = {
  __typename?: 'eventPitSpace_var_pop_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPitSpace" */
export type EventPitSpaceVarPopOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPitSpaceVarSampFields = {
  __typename?: 'eventPitSpace_var_samp_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPitSpace" */
export type EventPitSpaceVarSampOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPitSpaceVarianceFields = {
  __typename?: 'eventPitSpace_variance_fields';
  plotLength?: Maybe<Scalars['Float']>;
  plotWidth?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
  unitSize?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPitSpace" */
export type EventPitSpaceVarianceOrderBy = {
  plotLength?: InputMaybe<OrderBy>;
  plotWidth?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  unitSize?: InputMaybe<OrderBy>;
};

/** A price change on a specific date for a group */
export type EventPricingChange = Node & {
  __typename?: 'eventPricingChange';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An object relationship */
  eventPricingDate: EventPricingDate;
  eventPricingDateCuid: Scalars['String'];
  /** An object relationship */
  eventPricingGroup: EventPricingGroup;
  eventPricingGroupCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventPricingChange" */
export type EventPricingChangeConnection = {
  __typename?: 'eventPricingChangeConnection';
  edges: Array<EventPricingChangeEdge>;
  pageInfo: PageInfo;
};

export type EventPricingChangeEdge = {
  __typename?: 'eventPricingChangeEdge';
  cursor: Scalars['String'];
  node: EventPricingChange;
};

/** aggregated selection of "eventPricingChange" */
export type EventPricingChangeAggregate = {
  __typename?: 'eventPricingChange_aggregate';
  aggregate?: Maybe<EventPricingChangeAggregateFields>;
  nodes: Array<EventPricingChange>;
};

export type EventPricingChangeAggregateBoolExp = {
  count?: InputMaybe<EventPricingChangeAggregateBoolExpCount>;
};

export type EventPricingChangeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPricingChangeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPricingChange" */
export type EventPricingChangeAggregateFields = {
  __typename?: 'eventPricingChange_aggregate_fields';
  avg?: Maybe<EventPricingChangeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPricingChangeMaxFields>;
  min?: Maybe<EventPricingChangeMinFields>;
  stddev?: Maybe<EventPricingChangeStddevFields>;
  stddev_pop?: Maybe<EventPricingChangeStddevPopFields>;
  stddev_samp?: Maybe<EventPricingChangeStddevSampFields>;
  sum?: Maybe<EventPricingChangeSumFields>;
  var_pop?: Maybe<EventPricingChangeVarPopFields>;
  var_samp?: Maybe<EventPricingChangeVarSampFields>;
  variance?: Maybe<EventPricingChangeVarianceFields>;
};


/** aggregate fields of "eventPricingChange" */
export type EventPricingChangeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPricingChange" */
export type EventPricingChangeAggregateOrderBy = {
  avg?: InputMaybe<EventPricingChangeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPricingChangeMaxOrderBy>;
  min?: InputMaybe<EventPricingChangeMinOrderBy>;
  stddev?: InputMaybe<EventPricingChangeStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPricingChangeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPricingChangeStddevSampOrderBy>;
  sum?: InputMaybe<EventPricingChangeSumOrderBy>;
  var_pop?: InputMaybe<EventPricingChangeVarPopOrderBy>;
  var_samp?: InputMaybe<EventPricingChangeVarSampOrderBy>;
  variance?: InputMaybe<EventPricingChangeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPricingChange" */
export type EventPricingChangeArrRelInsertInput = {
  data: Array<EventPricingChangeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingChangeOnConflict>;
};

/** aggregate avg on columns */
export type EventPricingChangeAvgFields = {
  __typename?: 'eventPricingChange_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPricingChange" */
export type EventPricingChangeAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPricingChange". All fields are combined with a logical 'AND'. */
export type EventPricingChangeBoolExp = {
  _and?: InputMaybe<Array<EventPricingChangeBoolExp>>;
  _not?: InputMaybe<EventPricingChangeBoolExp>;
  _or?: InputMaybe<Array<EventPricingChangeBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventPricingDate?: InputMaybe<EventPricingDateBoolExp>;
  eventPricingDateCuid?: InputMaybe<StringComparisonExp>;
  eventPricingGroup?: InputMaybe<EventPricingGroupBoolExp>;
  eventPricingGroupCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPricingChange" */
export enum EventPricingChangeConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPricingChangeCuidKey = 'eventPricingChange_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPricingChangePkey = 'eventPricingChange_pkey'
}

/** input type for incrementing numeric columns in table "eventPricingChange" */
export type EventPricingChangeIncInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventPricingChange" */
export type EventPricingChangeInsertInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventPricingDate?: InputMaybe<EventPricingDateObjRelInsertInput>;
  eventPricingDateCuid?: InputMaybe<Scalars['String']>;
  eventPricingGroup?: InputMaybe<EventPricingGroupObjRelInsertInput>;
  eventPricingGroupCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPricingChangeMaxFields = {
  __typename?: 'eventPricingChange_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventPricingDateCuid?: Maybe<Scalars['String']>;
  eventPricingGroupCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPricingChange" */
export type EventPricingChangeMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPricingDateCuid?: InputMaybe<OrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPricingChangeMinFields = {
  __typename?: 'eventPricingChange_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventPricingDateCuid?: Maybe<Scalars['String']>;
  eventPricingGroupCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPricingChange" */
export type EventPricingChangeMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPricingDateCuid?: InputMaybe<OrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPricingChange" */
export type EventPricingChangeMutationResponse = {
  __typename?: 'eventPricingChange_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPricingChange>;
};

/** on_conflict condition type for table "eventPricingChange" */
export type EventPricingChangeOnConflict = {
  constraint: EventPricingChangeConstraint;
  update_columns?: Array<EventPricingChangeUpdateColumn>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};

/** Ordering options when selecting data from "eventPricingChange". */
export type EventPricingChangeOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPricingDate?: InputMaybe<EventPricingDateOrderBy>;
  eventPricingDateCuid?: InputMaybe<OrderBy>;
  eventPricingGroup?: InputMaybe<EventPricingGroupOrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPricingChange */
export type EventPricingChangePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventPricingChange" */
export enum EventPricingChangeSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventPricingDateCuid = 'eventPricingDateCuid',
  /** column name */
  EventPricingGroupCuid = 'eventPricingGroupCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventPricingChange" */
export type EventPricingChangeSetInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventPricingDateCuid?: InputMaybe<Scalars['String']>;
  eventPricingGroupCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPricingChangeStddevFields = {
  __typename?: 'eventPricingChange_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPricingChange" */
export type EventPricingChangeStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPricingChangeStddevPopFields = {
  __typename?: 'eventPricingChange_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPricingChange" */
export type EventPricingChangeStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPricingChangeStddevSampFields = {
  __typename?: 'eventPricingChange_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPricingChange" */
export type EventPricingChangeStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPricingChangeSumFields = {
  __typename?: 'eventPricingChange_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventPricingChange" */
export type EventPricingChangeSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPricingChange" */
export enum EventPricingChangeUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventPricingDateCuid = 'eventPricingDateCuid',
  /** column name */
  EventPricingGroupCuid = 'eventPricingGroupCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPricingChangeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPricingChangeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPricingChangeSetInput>;
  where: EventPricingChangeBoolExp;
};

/** aggregate var_pop on columns */
export type EventPricingChangeVarPopFields = {
  __typename?: 'eventPricingChange_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPricingChange" */
export type EventPricingChangeVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPricingChangeVarSampFields = {
  __typename?: 'eventPricingChange_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPricingChange" */
export type EventPricingChangeVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPricingChangeVarianceFields = {
  __typename?: 'eventPricingChange_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPricingChange" */
export type EventPricingChangeVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Date of a price change */
export type EventPricingDate = Node & {
  __typename?: 'eventPricingDate';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  date: Scalars['timestamptz'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventPricingChanges: Array<EventPricingChange>;
  /** An aggregate relationship */
  eventPricingChanges_aggregate: EventPricingChangeAggregate;
  /** An array relationship connection */
  eventPricingChanges_connection: EventPricingChangeConnection;
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};


/** Date of a price change */
export type EventPricingDateEventPricingChangesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** Date of a price change */
export type EventPricingDateEventPricingChangesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** Date of a price change */
export type EventPricingDateEventPricingChangesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};

/** A Relay connection object on "eventPricingDate" */
export type EventPricingDateConnection = {
  __typename?: 'eventPricingDateConnection';
  edges: Array<EventPricingDateEdge>;
  pageInfo: PageInfo;
};

export type EventPricingDateEdge = {
  __typename?: 'eventPricingDateEdge';
  cursor: Scalars['String'];
  node: EventPricingDate;
};

/** aggregated selection of "eventPricingDate" */
export type EventPricingDateAggregate = {
  __typename?: 'eventPricingDate_aggregate';
  aggregate?: Maybe<EventPricingDateAggregateFields>;
  nodes: Array<EventPricingDate>;
};

export type EventPricingDateAggregateBoolExp = {
  count?: InputMaybe<EventPricingDateAggregateBoolExpCount>;
};

export type EventPricingDateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPricingDateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPricingDate" */
export type EventPricingDateAggregateFields = {
  __typename?: 'eventPricingDate_aggregate_fields';
  avg?: Maybe<EventPricingDateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPricingDateMaxFields>;
  min?: Maybe<EventPricingDateMinFields>;
  stddev?: Maybe<EventPricingDateStddevFields>;
  stddev_pop?: Maybe<EventPricingDateStddevPopFields>;
  stddev_samp?: Maybe<EventPricingDateStddevSampFields>;
  sum?: Maybe<EventPricingDateSumFields>;
  var_pop?: Maybe<EventPricingDateVarPopFields>;
  var_samp?: Maybe<EventPricingDateVarSampFields>;
  variance?: Maybe<EventPricingDateVarianceFields>;
};


/** aggregate fields of "eventPricingDate" */
export type EventPricingDateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPricingDate" */
export type EventPricingDateAggregateOrderBy = {
  avg?: InputMaybe<EventPricingDateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPricingDateMaxOrderBy>;
  min?: InputMaybe<EventPricingDateMinOrderBy>;
  stddev?: InputMaybe<EventPricingDateStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPricingDateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPricingDateStddevSampOrderBy>;
  sum?: InputMaybe<EventPricingDateSumOrderBy>;
  var_pop?: InputMaybe<EventPricingDateVarPopOrderBy>;
  var_samp?: InputMaybe<EventPricingDateVarSampOrderBy>;
  variance?: InputMaybe<EventPricingDateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPricingDate" */
export type EventPricingDateArrRelInsertInput = {
  data: Array<EventPricingDateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingDateOnConflict>;
};

/** aggregate avg on columns */
export type EventPricingDateAvgFields = {
  __typename?: 'eventPricingDate_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPricingDate" */
export type EventPricingDateAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPricingDate". All fields are combined with a logical 'AND'. */
export type EventPricingDateBoolExp = {
  _and?: InputMaybe<Array<EventPricingDateBoolExp>>;
  _not?: InputMaybe<EventPricingDateBoolExp>;
  _or?: InputMaybe<Array<EventPricingDateBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventPricingChanges?: InputMaybe<EventPricingChangeBoolExp>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPricingDate" */
export enum EventPricingDateConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPricingDateCuidKey = 'eventPricingDate_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPricingDatePkey = 'eventPricingDate_pkey'
}

/** input type for incrementing numeric columns in table "eventPricingDate" */
export type EventPricingDateIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventPricingDate" */
export type EventPricingDateInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventPricingChanges?: InputMaybe<EventPricingChangeArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPricingDateMaxFields = {
  __typename?: 'eventPricingDate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPricingDate" */
export type EventPricingDateMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPricingDateMinFields = {
  __typename?: 'eventPricingDate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPricingDate" */
export type EventPricingDateMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPricingDate" */
export type EventPricingDateMutationResponse = {
  __typename?: 'eventPricingDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPricingDate>;
};

/** input type for inserting object relation for remote table "eventPricingDate" */
export type EventPricingDateObjRelInsertInput = {
  data: EventPricingDateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingDateOnConflict>;
};

/** on_conflict condition type for table "eventPricingDate" */
export type EventPricingDateOnConflict = {
  constraint: EventPricingDateConstraint;
  update_columns?: Array<EventPricingDateUpdateColumn>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};

/** Ordering options when selecting data from "eventPricingDate". */
export type EventPricingDateOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPricingDate */
export type EventPricingDatePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventPricingDate" */
export enum EventPricingDateSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventPricingDate" */
export type EventPricingDateSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPricingDateStddevFields = {
  __typename?: 'eventPricingDate_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPricingDate" */
export type EventPricingDateStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPricingDateStddevPopFields = {
  __typename?: 'eventPricingDate_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPricingDate" */
export type EventPricingDateStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPricingDateStddevSampFields = {
  __typename?: 'eventPricingDate_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPricingDate" */
export type EventPricingDateStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPricingDateSumFields = {
  __typename?: 'eventPricingDate_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventPricingDate" */
export type EventPricingDateSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPricingDate" */
export enum EventPricingDateUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPricingDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPricingDateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPricingDateSetInput>;
  where: EventPricingDateBoolExp;
};

/** aggregate var_pop on columns */
export type EventPricingDateVarPopFields = {
  __typename?: 'eventPricingDate_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPricingDate" */
export type EventPricingDateVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPricingDateVarSampFields = {
  __typename?: 'eventPricingDate_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPricingDate" */
export type EventPricingDateVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPricingDateVarianceFields = {
  __typename?: 'eventPricingDate_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPricingDate" */
export type EventPricingDateVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Group of categories that change price */
export type EventPricingGroup = Node & {
  __typename?: 'eventPricingGroup';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventPricingChanges: Array<EventPricingChange>;
  /** An aggregate relationship */
  eventPricingChanges_aggregate: EventPricingChangeAggregate;
  /** An array relationship connection */
  eventPricingChanges_connection: EventPricingChangeConnection;
  /** An array relationship */
  eventPricingGroupCategories: Array<EventPricingGroupCategory>;
  /** An aggregate relationship */
  eventPricingGroupCategories_aggregate: EventPricingGroupCategoryAggregate;
  /** An array relationship connection */
  eventPricingGroupCategories_connection: EventPricingGroupCategoryConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingChangesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingChangesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingChangesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingGroupCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingGroupCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


/** Group of categories that change price */
export type EventPricingGroupEventPricingGroupCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};

/** What categories are part of a pricing group */
export type EventPricingGroupCategory = Node & {
  __typename?: 'eventPricingGroupCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventPricingGroup: EventPricingGroup;
  eventPricingGroupCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventPricingGroupCategory" */
export type EventPricingGroupCategoryConnection = {
  __typename?: 'eventPricingGroupCategoryConnection';
  edges: Array<EventPricingGroupCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventPricingGroupCategoryEdge = {
  __typename?: 'eventPricingGroupCategoryEdge';
  cursor: Scalars['String'];
  node: EventPricingGroupCategory;
};

/** aggregated selection of "eventPricingGroupCategory" */
export type EventPricingGroupCategoryAggregate = {
  __typename?: 'eventPricingGroupCategory_aggregate';
  aggregate?: Maybe<EventPricingGroupCategoryAggregateFields>;
  nodes: Array<EventPricingGroupCategory>;
};

export type EventPricingGroupCategoryAggregateBoolExp = {
  count?: InputMaybe<EventPricingGroupCategoryAggregateBoolExpCount>;
};

export type EventPricingGroupCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPricingGroupCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPricingGroupCategory" */
export type EventPricingGroupCategoryAggregateFields = {
  __typename?: 'eventPricingGroupCategory_aggregate_fields';
  avg?: Maybe<EventPricingGroupCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPricingGroupCategoryMaxFields>;
  min?: Maybe<EventPricingGroupCategoryMinFields>;
  stddev?: Maybe<EventPricingGroupCategoryStddevFields>;
  stddev_pop?: Maybe<EventPricingGroupCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventPricingGroupCategoryStddevSampFields>;
  sum?: Maybe<EventPricingGroupCategorySumFields>;
  var_pop?: Maybe<EventPricingGroupCategoryVarPopFields>;
  var_samp?: Maybe<EventPricingGroupCategoryVarSampFields>;
  variance?: Maybe<EventPricingGroupCategoryVarianceFields>;
};


/** aggregate fields of "eventPricingGroupCategory" */
export type EventPricingGroupCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventPricingGroupCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPricingGroupCategoryMaxOrderBy>;
  min?: InputMaybe<EventPricingGroupCategoryMinOrderBy>;
  stddev?: InputMaybe<EventPricingGroupCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPricingGroupCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPricingGroupCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventPricingGroupCategorySumOrderBy>;
  var_pop?: InputMaybe<EventPricingGroupCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventPricingGroupCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventPricingGroupCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryArrRelInsertInput = {
  data: Array<EventPricingGroupCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingGroupCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventPricingGroupCategoryAvgFields = {
  __typename?: 'eventPricingGroupCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPricingGroupCategory". All fields are combined with a logical 'AND'. */
export type EventPricingGroupCategoryBoolExp = {
  _and?: InputMaybe<Array<EventPricingGroupCategoryBoolExp>>;
  _not?: InputMaybe<EventPricingGroupCategoryBoolExp>;
  _or?: InputMaybe<Array<EventPricingGroupCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventPricingGroup?: InputMaybe<EventPricingGroupBoolExp>;
  eventPricingGroupCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPricingGroupCategory" */
export enum EventPricingGroupCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPricingGroupCategoryCuidKey = 'eventPricingGroupCategory_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPricingGroupCategoryPkey = 'eventPricingGroupCategory_pkey'
}

/** input type for incrementing numeric columns in table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventPricingGroup?: InputMaybe<EventPricingGroupObjRelInsertInput>;
  eventPricingGroupCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPricingGroupCategoryMaxFields = {
  __typename?: 'eventPricingGroupCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventPricingGroupCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPricingGroupCategoryMinFields = {
  __typename?: 'eventPricingGroupCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventPricingGroupCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryMutationResponse = {
  __typename?: 'eventPricingGroupCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPricingGroupCategory>;
};

/** on_conflict condition type for table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryOnConflict = {
  constraint: EventPricingGroupCategoryConstraint;
  update_columns?: Array<EventPricingGroupCategoryUpdateColumn>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventPricingGroupCategory". */
export type EventPricingGroupCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventPricingGroup?: InputMaybe<EventPricingGroupOrderBy>;
  eventPricingGroupCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPricingGroupCategory */
export type EventPricingGroupCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventPricingGroupCategory" */
export enum EventPricingGroupCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventPricingGroupCuid = 'eventPricingGroupCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventPricingGroupCategory" */
export type EventPricingGroupCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventPricingGroupCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPricingGroupCategoryStddevFields = {
  __typename?: 'eventPricingGroupCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPricingGroupCategoryStddevPopFields = {
  __typename?: 'eventPricingGroupCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPricingGroupCategoryStddevSampFields = {
  __typename?: 'eventPricingGroupCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPricingGroupCategorySumFields = {
  __typename?: 'eventPricingGroupCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPricingGroupCategory" */
export enum EventPricingGroupCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventPricingGroupCuid = 'eventPricingGroupCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPricingGroupCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPricingGroupCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPricingGroupCategorySetInput>;
  where: EventPricingGroupCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventPricingGroupCategoryVarPopFields = {
  __typename?: 'eventPricingGroupCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPricingGroupCategoryVarSampFields = {
  __typename?: 'eventPricingGroupCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPricingGroupCategoryVarianceFields = {
  __typename?: 'eventPricingGroupCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPricingGroupCategory" */
export type EventPricingGroupCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventPricingGroup" */
export type EventPricingGroupConnection = {
  __typename?: 'eventPricingGroupConnection';
  edges: Array<EventPricingGroupEdge>;
  pageInfo: PageInfo;
};

export type EventPricingGroupEdge = {
  __typename?: 'eventPricingGroupEdge';
  cursor: Scalars['String'];
  node: EventPricingGroup;
};

/** aggregated selection of "eventPricingGroup" */
export type EventPricingGroupAggregate = {
  __typename?: 'eventPricingGroup_aggregate';
  aggregate?: Maybe<EventPricingGroupAggregateFields>;
  nodes: Array<EventPricingGroup>;
};

export type EventPricingGroupAggregateBoolExp = {
  count?: InputMaybe<EventPricingGroupAggregateBoolExpCount>;
};

export type EventPricingGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPricingGroupBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPricingGroup" */
export type EventPricingGroupAggregateFields = {
  __typename?: 'eventPricingGroup_aggregate_fields';
  avg?: Maybe<EventPricingGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPricingGroupMaxFields>;
  min?: Maybe<EventPricingGroupMinFields>;
  stddev?: Maybe<EventPricingGroupStddevFields>;
  stddev_pop?: Maybe<EventPricingGroupStddevPopFields>;
  stddev_samp?: Maybe<EventPricingGroupStddevSampFields>;
  sum?: Maybe<EventPricingGroupSumFields>;
  var_pop?: Maybe<EventPricingGroupVarPopFields>;
  var_samp?: Maybe<EventPricingGroupVarSampFields>;
  variance?: Maybe<EventPricingGroupVarianceFields>;
};


/** aggregate fields of "eventPricingGroup" */
export type EventPricingGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPricingGroup" */
export type EventPricingGroupAggregateOrderBy = {
  avg?: InputMaybe<EventPricingGroupAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPricingGroupMaxOrderBy>;
  min?: InputMaybe<EventPricingGroupMinOrderBy>;
  stddev?: InputMaybe<EventPricingGroupStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPricingGroupStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPricingGroupStddevSampOrderBy>;
  sum?: InputMaybe<EventPricingGroupSumOrderBy>;
  var_pop?: InputMaybe<EventPricingGroupVarPopOrderBy>;
  var_samp?: InputMaybe<EventPricingGroupVarSampOrderBy>;
  variance?: InputMaybe<EventPricingGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPricingGroup" */
export type EventPricingGroupArrRelInsertInput = {
  data: Array<EventPricingGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingGroupOnConflict>;
};

/** aggregate avg on columns */
export type EventPricingGroupAvgFields = {
  __typename?: 'eventPricingGroup_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPricingGroup" */
export type EventPricingGroupAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPricingGroup". All fields are combined with a logical 'AND'. */
export type EventPricingGroupBoolExp = {
  _and?: InputMaybe<Array<EventPricingGroupBoolExp>>;
  _not?: InputMaybe<EventPricingGroupBoolExp>;
  _or?: InputMaybe<Array<EventPricingGroupBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventPricingChanges?: InputMaybe<EventPricingChangeBoolExp>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateBoolExp>;
  eventPricingGroupCategories?: InputMaybe<EventPricingGroupCategoryBoolExp>;
  eventPricingGroupCategories_aggregate?: InputMaybe<EventPricingGroupCategoryAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPricingGroup" */
export enum EventPricingGroupConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPricingGroupCuidKey = 'eventPricingGroup_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPricingGroupPkey = 'eventPricingGroup_pkey'
}

/** input type for incrementing numeric columns in table "eventPricingGroup" */
export type EventPricingGroupIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventPricingGroup" */
export type EventPricingGroupInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventPricingChanges?: InputMaybe<EventPricingChangeArrRelInsertInput>;
  eventPricingGroupCategories?: InputMaybe<EventPricingGroupCategoryArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPricingGroupMaxFields = {
  __typename?: 'eventPricingGroup_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPricingGroup" */
export type EventPricingGroupMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPricingGroupMinFields = {
  __typename?: 'eventPricingGroup_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPricingGroup" */
export type EventPricingGroupMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPricingGroup" */
export type EventPricingGroupMutationResponse = {
  __typename?: 'eventPricingGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPricingGroup>;
};

/** input type for inserting object relation for remote table "eventPricingGroup" */
export type EventPricingGroupObjRelInsertInput = {
  data: EventPricingGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPricingGroupOnConflict>;
};

/** on_conflict condition type for table "eventPricingGroup" */
export type EventPricingGroupOnConflict = {
  constraint: EventPricingGroupConstraint;
  update_columns?: Array<EventPricingGroupUpdateColumn>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};

/** Ordering options when selecting data from "eventPricingGroup". */
export type EventPricingGroupOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateOrderBy>;
  eventPricingGroupCategories_aggregate?: InputMaybe<EventPricingGroupCategoryAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPricingGroup */
export type EventPricingGroupPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventPricingGroup" */
export enum EventPricingGroupSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventPricingGroup" */
export type EventPricingGroupSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPricingGroupStddevFields = {
  __typename?: 'eventPricingGroup_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPricingGroup" */
export type EventPricingGroupStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPricingGroupStddevPopFields = {
  __typename?: 'eventPricingGroup_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPricingGroup" */
export type EventPricingGroupStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPricingGroupStddevSampFields = {
  __typename?: 'eventPricingGroup_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPricingGroup" */
export type EventPricingGroupStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPricingGroupSumFields = {
  __typename?: 'eventPricingGroup_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventPricingGroup" */
export type EventPricingGroupSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPricingGroup" */
export enum EventPricingGroupUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPricingGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPricingGroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPricingGroupSetInput>;
  where: EventPricingGroupBoolExp;
};

/** aggregate var_pop on columns */
export type EventPricingGroupVarPopFields = {
  __typename?: 'eventPricingGroup_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPricingGroup" */
export type EventPricingGroupVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPricingGroupVarSampFields = {
  __typename?: 'eventPricingGroup_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPricingGroup" */
export type EventPricingGroupVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPricingGroupVarianceFields = {
  __typename?: 'eventPricingGroup_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPricingGroup" */
export type EventPricingGroupVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Generated Event Links for private events (NOT USED YET) */
export type EventPrivateLink = Node & {
  __typename?: 'eventPrivateLink';
  capacity?: Maybe<Scalars['Int']>;
  capacityUsed?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  endDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event: Event;
  /** An object relationship */
  eventByEventcuid?: Maybe<Event>;
  eventCuid?: Maybe<Scalars['String']>;
  eventId: Scalars['Int'];
  id: Scalars['ID'];
  link: Scalars['String'];
  paused: Scalars['Boolean'];
  startDate?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventPrivateLink" */
export type EventPrivateLinkConnection = {
  __typename?: 'eventPrivateLinkConnection';
  edges: Array<EventPrivateLinkEdge>;
  pageInfo: PageInfo;
};

export type EventPrivateLinkEdge = {
  __typename?: 'eventPrivateLinkEdge';
  cursor: Scalars['String'];
  node: EventPrivateLink;
};

/** aggregated selection of "eventPrivateLink" */
export type EventPrivateLinkAggregate = {
  __typename?: 'eventPrivateLink_aggregate';
  aggregate?: Maybe<EventPrivateLinkAggregateFields>;
  nodes: Array<EventPrivateLink>;
};

export type EventPrivateLinkAggregateBoolExp = {
  bool_and?: InputMaybe<EventPrivateLinkAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventPrivateLinkAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventPrivateLinkAggregateBoolExpCount>;
};

export type EventPrivateLinkAggregateBoolExpBoolAnd = {
  arguments: EventPrivateLinkSelectColumnEventPrivateLinkAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPrivateLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPrivateLinkAggregateBoolExpBoolOr = {
  arguments: EventPrivateLinkSelectColumnEventPrivateLinkAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPrivateLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPrivateLinkAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPrivateLinkBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPrivateLink" */
export type EventPrivateLinkAggregateFields = {
  __typename?: 'eventPrivateLink_aggregate_fields';
  avg?: Maybe<EventPrivateLinkAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPrivateLinkMaxFields>;
  min?: Maybe<EventPrivateLinkMinFields>;
  stddev?: Maybe<EventPrivateLinkStddevFields>;
  stddev_pop?: Maybe<EventPrivateLinkStddevPopFields>;
  stddev_samp?: Maybe<EventPrivateLinkStddevSampFields>;
  sum?: Maybe<EventPrivateLinkSumFields>;
  var_pop?: Maybe<EventPrivateLinkVarPopFields>;
  var_samp?: Maybe<EventPrivateLinkVarSampFields>;
  variance?: Maybe<EventPrivateLinkVarianceFields>;
};


/** aggregate fields of "eventPrivateLink" */
export type EventPrivateLinkAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPrivateLink" */
export type EventPrivateLinkAggregateOrderBy = {
  avg?: InputMaybe<EventPrivateLinkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPrivateLinkMaxOrderBy>;
  min?: InputMaybe<EventPrivateLinkMinOrderBy>;
  stddev?: InputMaybe<EventPrivateLinkStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPrivateLinkStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPrivateLinkStddevSampOrderBy>;
  sum?: InputMaybe<EventPrivateLinkSumOrderBy>;
  var_pop?: InputMaybe<EventPrivateLinkVarPopOrderBy>;
  var_samp?: InputMaybe<EventPrivateLinkVarSampOrderBy>;
  variance?: InputMaybe<EventPrivateLinkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPrivateLink" */
export type EventPrivateLinkArrRelInsertInput = {
  data: Array<EventPrivateLinkInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPrivateLinkOnConflict>;
};

/** aggregate avg on columns */
export type EventPrivateLinkAvgFields = {
  __typename?: 'eventPrivateLink_avg_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPrivateLink" */
export type EventPrivateLinkAvgOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPrivateLink". All fields are combined with a logical 'AND'. */
export type EventPrivateLinkBoolExp = {
  _and?: InputMaybe<Array<EventPrivateLinkBoolExp>>;
  _not?: InputMaybe<EventPrivateLinkBoolExp>;
  _or?: InputMaybe<Array<EventPrivateLinkBoolExp>>;
  capacity?: InputMaybe<IntComparisonExp>;
  capacityUsed?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventByEventcuid?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventId?: InputMaybe<IntComparisonExp>;
  link?: InputMaybe<StringComparisonExp>;
  paused?: InputMaybe<BooleanComparisonExp>;
  startDate?: InputMaybe<TimestamptzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventPrivateLink" */
export enum EventPrivateLinkConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventPrivateLinkPkey = 'eventPrivateLink_pkey'
}

/** input type for incrementing numeric columns in table "eventPrivateLink" */
export type EventPrivateLinkIncInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  capacityUsed?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventPrivateLink" */
export type EventPrivateLinkInsertInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  capacityUsed?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventByEventcuid?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  paused?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventPrivateLinkMaxFields = {
  __typename?: 'eventPrivateLink_max_fields';
  capacity?: Maybe<Scalars['Int']>;
  capacityUsed?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventPrivateLink" */
export type EventPrivateLinkMaxOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPrivateLinkMinFields = {
  __typename?: 'eventPrivateLink_min_fields';
  capacity?: Maybe<Scalars['Int']>;
  capacityUsed?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventPrivateLink" */
export type EventPrivateLinkMinOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPrivateLink" */
export type EventPrivateLinkMutationResponse = {
  __typename?: 'eventPrivateLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPrivateLink>;
};

/** on_conflict condition type for table "eventPrivateLink" */
export type EventPrivateLinkOnConflict = {
  constraint: EventPrivateLinkConstraint;
  update_columns?: Array<EventPrivateLinkUpdateColumn>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};

/** Ordering options when selecting data from "eventPrivateLink". */
export type EventPrivateLinkOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventByEventcuid?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  paused?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPrivateLink */
export type EventPrivateLinkPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventPrivateLink" */
export enum EventPrivateLinkSelectColumn {
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CapacityUsed = 'capacityUsed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Link = 'link',
  /** column name */
  Paused = 'paused',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventPrivateLink_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventPrivateLink" */
export enum EventPrivateLinkSelectColumnEventPrivateLinkAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Paused = 'paused'
}

/** select "eventPrivateLink_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventPrivateLink" */
export enum EventPrivateLinkSelectColumnEventPrivateLinkAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Paused = 'paused'
}

/** input type for updating data in table "eventPrivateLink" */
export type EventPrivateLinkSetInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  capacityUsed?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  paused?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventPrivateLinkStddevFields = {
  __typename?: 'eventPrivateLink_stddev_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPrivateLink" */
export type EventPrivateLinkStddevOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPrivateLinkStddevPopFields = {
  __typename?: 'eventPrivateLink_stddev_pop_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPrivateLink" */
export type EventPrivateLinkStddevPopOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPrivateLinkStddevSampFields = {
  __typename?: 'eventPrivateLink_stddev_samp_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPrivateLink" */
export type EventPrivateLinkStddevSampOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPrivateLinkSumFields = {
  __typename?: 'eventPrivateLink_sum_fields';
  capacity?: Maybe<Scalars['Int']>;
  capacityUsed?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventPrivateLink" */
export type EventPrivateLinkSumOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPrivateLink" */
export enum EventPrivateLinkUpdateColumn {
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CapacityUsed = 'capacityUsed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Link = 'link',
  /** column name */
  Paused = 'paused',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventPrivateLinkUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPrivateLinkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPrivateLinkSetInput>;
  where: EventPrivateLinkBoolExp;
};

/** aggregate var_pop on columns */
export type EventPrivateLinkVarPopFields = {
  __typename?: 'eventPrivateLink_var_pop_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPrivateLink" */
export type EventPrivateLinkVarPopOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPrivateLinkVarSampFields = {
  __typename?: 'eventPrivateLink_var_samp_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPrivateLink" */
export type EventPrivateLinkVarSampOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPrivateLinkVarianceFields = {
  __typename?: 'eventPrivateLink_variance_fields';
  capacity?: Maybe<Scalars['Float']>;
  capacityUsed?: Maybe<Scalars['Float']>;
  eventId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPrivateLink" */
export type EventPrivateLinkVarianceOrderBy = {
  capacity?: InputMaybe<OrderBy>;
  capacityUsed?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
};

/** Program for Clinic */
export type EventProgram = Node & {
  __typename?: 'eventProgram';
  abilityLevelRange: Scalars['jsonb'];
  ageRange: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  defaultPrice?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  focus: Scalars['jsonb'];
  genders: Scalars['jsonb'];
  id: Scalars['ID'];
  overview?: Maybe<Scalars['String']>;
  skills: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** Program for Clinic */
export type EventProgramAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Program for Clinic */
export type EventProgramAgeRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Program for Clinic */
export type EventProgramFocusArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Program for Clinic */
export type EventProgramGendersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Program for Clinic */
export type EventProgramSkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "eventProgram" */
export type EventProgramConnection = {
  __typename?: 'eventProgramConnection';
  edges: Array<EventProgramEdge>;
  pageInfo: PageInfo;
};

export type EventProgramEdge = {
  __typename?: 'eventProgramEdge';
  cursor: Scalars['String'];
  node: EventProgram;
};

/** aggregated selection of "eventProgram" */
export type EventProgramAggregate = {
  __typename?: 'eventProgram_aggregate';
  aggregate?: Maybe<EventProgramAggregateFields>;
  nodes: Array<EventProgram>;
};

export type EventProgramAggregateBoolExp = {
  count?: InputMaybe<EventProgramAggregateBoolExpCount>;
};

export type EventProgramAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventProgramSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventProgramBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventProgram" */
export type EventProgramAggregateFields = {
  __typename?: 'eventProgram_aggregate_fields';
  avg?: Maybe<EventProgramAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventProgramMaxFields>;
  min?: Maybe<EventProgramMinFields>;
  stddev?: Maybe<EventProgramStddevFields>;
  stddev_pop?: Maybe<EventProgramStddevPopFields>;
  stddev_samp?: Maybe<EventProgramStddevSampFields>;
  sum?: Maybe<EventProgramSumFields>;
  var_pop?: Maybe<EventProgramVarPopFields>;
  var_samp?: Maybe<EventProgramVarSampFields>;
  variance?: Maybe<EventProgramVarianceFields>;
};


/** aggregate fields of "eventProgram" */
export type EventProgramAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventProgramSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventProgram" */
export type EventProgramAggregateOrderBy = {
  avg?: InputMaybe<EventProgramAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventProgramMaxOrderBy>;
  min?: InputMaybe<EventProgramMinOrderBy>;
  stddev?: InputMaybe<EventProgramStddevOrderBy>;
  stddev_pop?: InputMaybe<EventProgramStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventProgramStddevSampOrderBy>;
  sum?: InputMaybe<EventProgramSumOrderBy>;
  var_pop?: InputMaybe<EventProgramVarPopOrderBy>;
  var_samp?: InputMaybe<EventProgramVarSampOrderBy>;
  variance?: InputMaybe<EventProgramVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EventProgramAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  ageRange?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "eventProgram" */
export type EventProgramArrRelInsertInput = {
  data: Array<EventProgramInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventProgramOnConflict>;
};

/** aggregate avg on columns */
export type EventProgramAvgFields = {
  __typename?: 'eventProgram_avg_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventProgram" */
export type EventProgramAvgOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventProgram". All fields are combined with a logical 'AND'. */
export type EventProgramBoolExp = {
  _and?: InputMaybe<Array<EventProgramBoolExp>>;
  _not?: InputMaybe<EventProgramBoolExp>;
  _or?: InputMaybe<Array<EventProgramBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  ageRange?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  defaultPrice?: InputMaybe<NumericComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  focus?: InputMaybe<JsonbComparisonExp>;
  genders?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  overview?: InputMaybe<StringComparisonExp>;
  skills?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventProgram" */
export enum EventProgramConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventProgramCuidKey = 'eventProgram_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventProgramPkey = 'eventProgram_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EventProgramDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  ageRange?: InputMaybe<Array<Scalars['String']>>;
  focus?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EventProgramDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  ageRange?: InputMaybe<Scalars['Int']>;
  focus?: InputMaybe<Scalars['Int']>;
  genders?: InputMaybe<Scalars['Int']>;
  skills?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EventProgramDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  ageRange?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "eventProgram" */
export type EventProgramIncInput = {
  defaultPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventProgram" */
export type EventProgramInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  ageRange?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  defaultPrice?: InputMaybe<Scalars['numeric']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  overview?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventProgramMaxFields = {
  __typename?: 'eventProgram_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  defaultPrice?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  overview?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventProgram" */
export type EventProgramMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultPrice?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  overview?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventProgramMinFields = {
  __typename?: 'eventProgram_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  defaultPrice?: Maybe<Scalars['numeric']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  overview?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventProgram" */
export type EventProgramMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultPrice?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  overview?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventProgram" */
export type EventProgramMutationResponse = {
  __typename?: 'eventProgram_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventProgram>;
};

/** on_conflict condition type for table "eventProgram" */
export type EventProgramOnConflict = {
  constraint: EventProgramConstraint;
  update_columns?: Array<EventProgramUpdateColumn>;
  where?: InputMaybe<EventProgramBoolExp>;
};

/** Ordering options when selecting data from "eventProgram". */
export type EventProgramOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  ageRange?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultPrice?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  focus?: InputMaybe<OrderBy>;
  genders?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  overview?: InputMaybe<OrderBy>;
  skills?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventProgram */
export type EventProgramPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EventProgramPrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  ageRange?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "eventProgram" */
export enum EventProgramSelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DefaultPrice = 'defaultPrice',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Focus = 'focus',
  /** column name */
  Genders = 'genders',
  /** column name */
  Id = 'id',
  /** column name */
  Overview = 'overview',
  /** column name */
  Skills = 'skills',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventProgram" */
export type EventProgramSetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  ageRange?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  defaultPrice?: InputMaybe<Scalars['numeric']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  overview?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventProgramStddevFields = {
  __typename?: 'eventProgram_stddev_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventProgram" */
export type EventProgramStddevOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventProgramStddevPopFields = {
  __typename?: 'eventProgram_stddev_pop_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventProgram" */
export type EventProgramStddevPopOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventProgramStddevSampFields = {
  __typename?: 'eventProgram_stddev_samp_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventProgram" */
export type EventProgramStddevSampOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventProgramSumFields = {
  __typename?: 'eventProgram_sum_fields';
  defaultPrice?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventProgram" */
export type EventProgramSumOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventProgram" */
export enum EventProgramUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DefaultPrice = 'defaultPrice',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Focus = 'focus',
  /** column name */
  Genders = 'genders',
  /** column name */
  Id = 'id',
  /** column name */
  Overview = 'overview',
  /** column name */
  Skills = 'skills',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventProgramUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventProgramAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventProgramDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventProgramDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventProgramDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventProgramIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventProgramPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventProgramSetInput>;
  where: EventProgramBoolExp;
};

/** aggregate var_pop on columns */
export type EventProgramVarPopFields = {
  __typename?: 'eventProgram_var_pop_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventProgram" */
export type EventProgramVarPopOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventProgramVarSampFields = {
  __typename?: 'eventProgram_var_samp_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventProgram" */
export type EventProgramVarSampOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventProgramVarianceFields = {
  __typename?: 'eventProgram_variance_fields';
  defaultPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventProgram" */
export type EventProgramVarianceOrderBy = {
  defaultPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventPromoCode" */
export type EventPromoCode = Node & {
  __typename?: 'eventPromoCode';
  amountOff?: Maybe<Scalars['numeric']>;
  code: Scalars['String'];
  codesAvailable?: Maybe<Scalars['Int']>;
  /** calculates the codes remaining by accounting for codes used (via complete and in progress registrations) */
  codesRemaining?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  isInactive: Scalars['Boolean'];
  percentOff?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  validFrom?: Maybe<Scalars['timestamp']>;
  validTo?: Maybe<Scalars['timestamp']>;
};

/** A Relay connection object on "eventPromoCode" */
export type EventPromoCodeConnection = {
  __typename?: 'eventPromoCodeConnection';
  edges: Array<EventPromoCodeEdge>;
  pageInfo: PageInfo;
};

export type EventPromoCodeEdge = {
  __typename?: 'eventPromoCodeEdge';
  cursor: Scalars['String'];
  node: EventPromoCode;
};

/** aggregated selection of "eventPromoCode" */
export type EventPromoCodeAggregate = {
  __typename?: 'eventPromoCode_aggregate';
  aggregate?: Maybe<EventPromoCodeAggregateFields>;
  nodes: Array<EventPromoCode>;
};

export type EventPromoCodeAggregateBoolExp = {
  bool_and?: InputMaybe<EventPromoCodeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventPromoCodeAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventPromoCodeAggregateBoolExpCount>;
};

export type EventPromoCodeAggregateBoolExpBoolAnd = {
  arguments: EventPromoCodeSelectColumnEventPromoCodeAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPromoCodeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPromoCodeAggregateBoolExpBoolOr = {
  arguments: EventPromoCodeSelectColumnEventPromoCodeAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPromoCodeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventPromoCodeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventPromoCodeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventPromoCode" */
export type EventPromoCodeAggregateFields = {
  __typename?: 'eventPromoCode_aggregate_fields';
  avg?: Maybe<EventPromoCodeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventPromoCodeMaxFields>;
  min?: Maybe<EventPromoCodeMinFields>;
  stddev?: Maybe<EventPromoCodeStddevFields>;
  stddev_pop?: Maybe<EventPromoCodeStddevPopFields>;
  stddev_samp?: Maybe<EventPromoCodeStddevSampFields>;
  sum?: Maybe<EventPromoCodeSumFields>;
  var_pop?: Maybe<EventPromoCodeVarPopFields>;
  var_samp?: Maybe<EventPromoCodeVarSampFields>;
  variance?: Maybe<EventPromoCodeVarianceFields>;
};


/** aggregate fields of "eventPromoCode" */
export type EventPromoCodeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventPromoCode" */
export type EventPromoCodeAggregateOrderBy = {
  avg?: InputMaybe<EventPromoCodeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventPromoCodeMaxOrderBy>;
  min?: InputMaybe<EventPromoCodeMinOrderBy>;
  stddev?: InputMaybe<EventPromoCodeStddevOrderBy>;
  stddev_pop?: InputMaybe<EventPromoCodeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventPromoCodeStddevSampOrderBy>;
  sum?: InputMaybe<EventPromoCodeSumOrderBy>;
  var_pop?: InputMaybe<EventPromoCodeVarPopOrderBy>;
  var_samp?: InputMaybe<EventPromoCodeVarSampOrderBy>;
  variance?: InputMaybe<EventPromoCodeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventPromoCode" */
export type EventPromoCodeArrRelInsertInput = {
  data: Array<EventPromoCodeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventPromoCodeOnConflict>;
};

/** aggregate avg on columns */
export type EventPromoCodeAvgFields = {
  __typename?: 'eventPromoCode_avg_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventPromoCode" */
export type EventPromoCodeAvgOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventPromoCode". All fields are combined with a logical 'AND'. */
export type EventPromoCodeBoolExp = {
  _and?: InputMaybe<Array<EventPromoCodeBoolExp>>;
  _not?: InputMaybe<EventPromoCodeBoolExp>;
  _or?: InputMaybe<Array<EventPromoCodeBoolExp>>;
  amountOff?: InputMaybe<NumericComparisonExp>;
  code?: InputMaybe<StringComparisonExp>;
  codesAvailable?: InputMaybe<IntComparisonExp>;
  codesRemaining?: InputMaybe<BigintComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isInactive?: InputMaybe<BooleanComparisonExp>;
  percentOff?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  validFrom?: InputMaybe<TimestampComparisonExp>;
  validTo?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "eventPromoCode" */
export enum EventPromoCodeConstraint {
  /** unique or primary key constraint on columns "code", "eventCuid" */
  EventPromoCodeCodeEventCuidKey = 'eventPromoCode_code_eventCuid_key',
  /** unique or primary key constraint on columns "cuid" */
  EventPromoCodeCuidKey = 'eventPromoCode_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPromoCodePkey = 'eventPromoCode_pkey'
}

/** input type for incrementing numeric columns in table "eventPromoCode" */
export type EventPromoCodeIncInput = {
  amountOff?: InputMaybe<Scalars['numeric']>;
  codesAvailable?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  percentOff?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventPromoCode" */
export type EventPromoCodeInsertInput = {
  amountOff?: InputMaybe<Scalars['numeric']>;
  code?: InputMaybe<Scalars['String']>;
  codesAvailable?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isInactive?: InputMaybe<Scalars['Boolean']>;
  percentOff?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validTo?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type EventPromoCodeMaxFields = {
  __typename?: 'eventPromoCode_max_fields';
  amountOff?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  codesAvailable?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  percentOff?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validTo?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "eventPromoCode" */
export type EventPromoCodeMaxOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventPromoCodeMinFields = {
  __typename?: 'eventPromoCode_min_fields';
  amountOff?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  codesAvailable?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  percentOff?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validTo?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "eventPromoCode" */
export type EventPromoCodeMinOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventPromoCode" */
export type EventPromoCodeMutationResponse = {
  __typename?: 'eventPromoCode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventPromoCode>;
};

/** on_conflict condition type for table "eventPromoCode" */
export type EventPromoCodeOnConflict = {
  constraint: EventPromoCodeConstraint;
  update_columns?: Array<EventPromoCodeUpdateColumn>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};

/** Ordering options when selecting data from "eventPromoCode". */
export type EventPromoCodeOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  codesRemaining?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isInactive?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventPromoCode */
export type EventPromoCodePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventPromoCode" */
export enum EventPromoCodeSelectColumn {
  /** column name */
  AmountOff = 'amountOff',
  /** column name */
  Code = 'code',
  /** column name */
  CodesAvailable = 'codesAvailable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsInactive = 'isInactive',
  /** column name */
  PercentOff = 'percentOff',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

/** select "eventPromoCode_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventPromoCode" */
export enum EventPromoCodeSelectColumnEventPromoCodeAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsInactive = 'isInactive'
}

/** select "eventPromoCode_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventPromoCode" */
export enum EventPromoCodeSelectColumnEventPromoCodeAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsInactive = 'isInactive'
}

/** input type for updating data in table "eventPromoCode" */
export type EventPromoCodeSetInput = {
  amountOff?: InputMaybe<Scalars['numeric']>;
  code?: InputMaybe<Scalars['String']>;
  codesAvailable?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isInactive?: InputMaybe<Scalars['Boolean']>;
  percentOff?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validTo?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type EventPromoCodeStddevFields = {
  __typename?: 'eventPromoCode_stddev_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventPromoCode" */
export type EventPromoCodeStddevOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventPromoCodeStddevPopFields = {
  __typename?: 'eventPromoCode_stddev_pop_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventPromoCode" */
export type EventPromoCodeStddevPopOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventPromoCodeStddevSampFields = {
  __typename?: 'eventPromoCode_stddev_samp_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventPromoCode" */
export type EventPromoCodeStddevSampOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventPromoCodeSumFields = {
  __typename?: 'eventPromoCode_sum_fields';
  amountOff?: Maybe<Scalars['numeric']>;
  codesAvailable?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  percentOff?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventPromoCode" */
export type EventPromoCodeSumOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** update columns of table "eventPromoCode" */
export enum EventPromoCodeUpdateColumn {
  /** column name */
  AmountOff = 'amountOff',
  /** column name */
  Code = 'code',
  /** column name */
  CodesAvailable = 'codesAvailable',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsInactive = 'isInactive',
  /** column name */
  PercentOff = 'percentOff',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

export type EventPromoCodeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventPromoCodeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventPromoCodeSetInput>;
  where: EventPromoCodeBoolExp;
};

/** aggregate var_pop on columns */
export type EventPromoCodeVarPopFields = {
  __typename?: 'eventPromoCode_var_pop_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventPromoCode" */
export type EventPromoCodeVarPopOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventPromoCodeVarSampFields = {
  __typename?: 'eventPromoCode_var_samp_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventPromoCode" */
export type EventPromoCodeVarSampOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventPromoCodeVarianceFields = {
  __typename?: 'eventPromoCode_variance_fields';
  amountOff?: Maybe<Scalars['Float']>;
  codesAvailable?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  percentOff?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventPromoCode" */
export type EventPromoCodeVarianceOrderBy = {
  amountOff?: InputMaybe<OrderBy>;
  codesAvailable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  percentOff?: InputMaybe<OrderBy>;
};

/** Question for an event */
export type EventQuestion = Node & {
  __typename?: 'eventQuestion';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  deletedByUser?: Maybe<User>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventQuestionAnswers: Array<EventQuestionAnswer>;
  /** An aggregate relationship */
  eventQuestionAnswers_aggregate: EventQuestionAnswerAggregate;
  /** An array relationship connection */
  eventQuestionAnswers_connection: EventQuestionAnswerConnection;
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};


/** Question for an event */
export type EventQuestionEventQuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Question for an event */
export type EventQuestionEventQuestionAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Question for an event */
export type EventQuestionEventQuestionAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};

/** Answer to an event question */
export type EventQuestionAnswer = Node & {
  __typename?: 'eventQuestionAnswer';
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  deletedByUser?: Maybe<User>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  eventQuestion: EventQuestion;
  eventQuestionCuid: Scalars['String'];
  id: Scalars['ID'];
  isOrganizer: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "eventQuestionAnswer" */
export type EventQuestionAnswerConnection = {
  __typename?: 'eventQuestionAnswerConnection';
  edges: Array<EventQuestionAnswerEdge>;
  pageInfo: PageInfo;
};

export type EventQuestionAnswerEdge = {
  __typename?: 'eventQuestionAnswerEdge';
  cursor: Scalars['String'];
  node: EventQuestionAnswer;
};

/** aggregated selection of "eventQuestionAnswer" */
export type EventQuestionAnswerAggregate = {
  __typename?: 'eventQuestionAnswer_aggregate';
  aggregate?: Maybe<EventQuestionAnswerAggregateFields>;
  nodes: Array<EventQuestionAnswer>;
};

export type EventQuestionAnswerAggregateBoolExp = {
  bool_and?: InputMaybe<EventQuestionAnswerAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventQuestionAnswerAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventQuestionAnswerAggregateBoolExpCount>;
};

export type EventQuestionAnswerAggregateBoolExpBoolAnd = {
  arguments: EventQuestionAnswerSelectColumnEventQuestionAnswerAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventQuestionAnswerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventQuestionAnswerAggregateBoolExpBoolOr = {
  arguments: EventQuestionAnswerSelectColumnEventQuestionAnswerAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventQuestionAnswerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventQuestionAnswerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventQuestionAnswerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventQuestionAnswer" */
export type EventQuestionAnswerAggregateFields = {
  __typename?: 'eventQuestionAnswer_aggregate_fields';
  avg?: Maybe<EventQuestionAnswerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventQuestionAnswerMaxFields>;
  min?: Maybe<EventQuestionAnswerMinFields>;
  stddev?: Maybe<EventQuestionAnswerStddevFields>;
  stddev_pop?: Maybe<EventQuestionAnswerStddevPopFields>;
  stddev_samp?: Maybe<EventQuestionAnswerStddevSampFields>;
  sum?: Maybe<EventQuestionAnswerSumFields>;
  var_pop?: Maybe<EventQuestionAnswerVarPopFields>;
  var_samp?: Maybe<EventQuestionAnswerVarSampFields>;
  variance?: Maybe<EventQuestionAnswerVarianceFields>;
};


/** aggregate fields of "eventQuestionAnswer" */
export type EventQuestionAnswerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventQuestionAnswer" */
export type EventQuestionAnswerAggregateOrderBy = {
  avg?: InputMaybe<EventQuestionAnswerAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventQuestionAnswerMaxOrderBy>;
  min?: InputMaybe<EventQuestionAnswerMinOrderBy>;
  stddev?: InputMaybe<EventQuestionAnswerStddevOrderBy>;
  stddev_pop?: InputMaybe<EventQuestionAnswerStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventQuestionAnswerStddevSampOrderBy>;
  sum?: InputMaybe<EventQuestionAnswerSumOrderBy>;
  var_pop?: InputMaybe<EventQuestionAnswerVarPopOrderBy>;
  var_samp?: InputMaybe<EventQuestionAnswerVarSampOrderBy>;
  variance?: InputMaybe<EventQuestionAnswerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventQuestionAnswer" */
export type EventQuestionAnswerArrRelInsertInput = {
  data: Array<EventQuestionAnswerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventQuestionAnswerOnConflict>;
};

/** aggregate avg on columns */
export type EventQuestionAnswerAvgFields = {
  __typename?: 'eventQuestionAnswer_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventQuestionAnswer". All fields are combined with a logical 'AND'. */
export type EventQuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<EventQuestionAnswerBoolExp>>;
  _not?: InputMaybe<EventQuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<EventQuestionAnswerBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deletedByUser?: InputMaybe<UserBoolExp>;
  deletedByUserId?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  eventQuestion?: InputMaybe<EventQuestionBoolExp>;
  eventQuestionCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isOrganizer?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventQuestionAnswer" */
export enum EventQuestionAnswerConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventQuestionAnswerCuidKey = 'eventQuestionAnswer_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventQuestionAnswerPkey = 'eventQuestionAnswer_pkey',
  /** unique or primary key constraint on columns "eventQuestionCuid", "userId" */
  EventQuestionAnswerUserIdEventQuestionCuidKey = 'eventQuestionAnswer_userId_eventQuestionCuid_key'
}

/** input type for incrementing numeric columns in table "eventQuestionAnswer" */
export type EventQuestionAnswerIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventQuestionAnswer" */
export type EventQuestionAnswerInsertInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUser?: InputMaybe<UserObjRelInsertInput>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventQuestion?: InputMaybe<EventQuestionObjRelInsertInput>;
  eventQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isOrganizer?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventQuestionAnswerMaxFields = {
  __typename?: 'eventQuestionAnswer_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerMaxOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventQuestionAnswerMinFields = {
  __typename?: 'eventQuestionAnswer_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventQuestionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerMinOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventQuestionAnswer" */
export type EventQuestionAnswerMutationResponse = {
  __typename?: 'eventQuestionAnswer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventQuestionAnswer>;
};

/** on_conflict condition type for table "eventQuestionAnswer" */
export type EventQuestionAnswerOnConflict = {
  constraint: EventQuestionAnswerConstraint;
  update_columns?: Array<EventQuestionAnswerUpdateColumn>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};

/** Ordering options when selecting data from "eventQuestionAnswer". */
export type EventQuestionAnswerOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUser?: InputMaybe<UserOrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventQuestion?: InputMaybe<EventQuestionOrderBy>;
  eventQuestionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOrganizer?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventQuestionAnswer */
export type EventQuestionAnswerPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventQuestionAnswer" */
export enum EventQuestionAnswerSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventQuestionCuid = 'eventQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsOrganizer = 'isOrganizer',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "eventQuestionAnswer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventQuestionAnswer" */
export enum EventQuestionAnswerSelectColumnEventQuestionAnswerAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsOrganizer = 'isOrganizer'
}

/** select "eventQuestionAnswer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventQuestionAnswer" */
export enum EventQuestionAnswerSelectColumnEventQuestionAnswerAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsOrganizer = 'isOrganizer'
}

/** input type for updating data in table "eventQuestionAnswer" */
export type EventQuestionAnswerSetInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventQuestionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isOrganizer?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventQuestionAnswerStddevFields = {
  __typename?: 'eventQuestionAnswer_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventQuestionAnswerStddevPopFields = {
  __typename?: 'eventQuestionAnswer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventQuestionAnswerStddevSampFields = {
  __typename?: 'eventQuestionAnswer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventQuestionAnswerSumFields = {
  __typename?: 'eventQuestionAnswer_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventQuestionAnswer" */
export enum EventQuestionAnswerUpdateColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventQuestionCuid = 'eventQuestionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsOrganizer = 'isOrganizer',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type EventQuestionAnswerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventQuestionAnswerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventQuestionAnswerSetInput>;
  where: EventQuestionAnswerBoolExp;
};

/** aggregate var_pop on columns */
export type EventQuestionAnswerVarPopFields = {
  __typename?: 'eventQuestionAnswer_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventQuestionAnswerVarSampFields = {
  __typename?: 'eventQuestionAnswer_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventQuestionAnswerVarianceFields = {
  __typename?: 'eventQuestionAnswer_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventQuestionAnswer" */
export type EventQuestionAnswerVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventQuestion" */
export type EventQuestionConnection = {
  __typename?: 'eventQuestionConnection';
  edges: Array<EventQuestionEdge>;
  pageInfo: PageInfo;
};

export type EventQuestionEdge = {
  __typename?: 'eventQuestionEdge';
  cursor: Scalars['String'];
  node: EventQuestion;
};

/** aggregated selection of "eventQuestion" */
export type EventQuestionAggregate = {
  __typename?: 'eventQuestion_aggregate';
  aggregate?: Maybe<EventQuestionAggregateFields>;
  nodes: Array<EventQuestion>;
};

export type EventQuestionAggregateBoolExp = {
  count?: InputMaybe<EventQuestionAggregateBoolExpCount>;
};

export type EventQuestionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventQuestionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventQuestionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventQuestion" */
export type EventQuestionAggregateFields = {
  __typename?: 'eventQuestion_aggregate_fields';
  avg?: Maybe<EventQuestionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventQuestionMaxFields>;
  min?: Maybe<EventQuestionMinFields>;
  stddev?: Maybe<EventQuestionStddevFields>;
  stddev_pop?: Maybe<EventQuestionStddevPopFields>;
  stddev_samp?: Maybe<EventQuestionStddevSampFields>;
  sum?: Maybe<EventQuestionSumFields>;
  var_pop?: Maybe<EventQuestionVarPopFields>;
  var_samp?: Maybe<EventQuestionVarSampFields>;
  variance?: Maybe<EventQuestionVarianceFields>;
};


/** aggregate fields of "eventQuestion" */
export type EventQuestionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventQuestionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventQuestion" */
export type EventQuestionAggregateOrderBy = {
  avg?: InputMaybe<EventQuestionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventQuestionMaxOrderBy>;
  min?: InputMaybe<EventQuestionMinOrderBy>;
  stddev?: InputMaybe<EventQuestionStddevOrderBy>;
  stddev_pop?: InputMaybe<EventQuestionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventQuestionStddevSampOrderBy>;
  sum?: InputMaybe<EventQuestionSumOrderBy>;
  var_pop?: InputMaybe<EventQuestionVarPopOrderBy>;
  var_samp?: InputMaybe<EventQuestionVarSampOrderBy>;
  variance?: InputMaybe<EventQuestionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventQuestion" */
export type EventQuestionArrRelInsertInput = {
  data: Array<EventQuestionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventQuestionOnConflict>;
};

/** aggregate avg on columns */
export type EventQuestionAvgFields = {
  __typename?: 'eventQuestion_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventQuestion" */
export type EventQuestionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventQuestion". All fields are combined with a logical 'AND'. */
export type EventQuestionBoolExp = {
  _and?: InputMaybe<Array<EventQuestionBoolExp>>;
  _not?: InputMaybe<EventQuestionBoolExp>;
  _or?: InputMaybe<Array<EventQuestionBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deletedByUser?: InputMaybe<UserBoolExp>;
  deletedByUserId?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventQuestionAnswers?: InputMaybe<EventQuestionAnswerBoolExp>;
  eventQuestionAnswers_aggregate?: InputMaybe<EventQuestionAnswerAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventQuestion" */
export enum EventQuestionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventQuestionCuidKey = 'eventQuestion_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventQuestionPkey = 'eventQuestion_pkey',
  /** unique or primary key constraint on columns "eventCuid", "userId" */
  EventQuestionUserIdEventCuidKey = 'eventQuestion_userId_eventCuid_key'
}

/** input type for incrementing numeric columns in table "eventQuestion" */
export type EventQuestionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventQuestion" */
export type EventQuestionInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUser?: InputMaybe<UserObjRelInsertInput>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventQuestionAnswers?: InputMaybe<EventQuestionAnswerArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventQuestionMaxFields = {
  __typename?: 'eventQuestion_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventQuestion" */
export type EventQuestionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventQuestionMinFields = {
  __typename?: 'eventQuestion_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventQuestion" */
export type EventQuestionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventQuestion" */
export type EventQuestionMutationResponse = {
  __typename?: 'eventQuestion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventQuestion>;
};

/** input type for inserting object relation for remote table "eventQuestion" */
export type EventQuestionObjRelInsertInput = {
  data: EventQuestionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventQuestionOnConflict>;
};

/** on_conflict condition type for table "eventQuestion" */
export type EventQuestionOnConflict = {
  constraint: EventQuestionConstraint;
  update_columns?: Array<EventQuestionUpdateColumn>;
  where?: InputMaybe<EventQuestionBoolExp>;
};

/** Ordering options when selecting data from "eventQuestion". */
export type EventQuestionOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUser?: InputMaybe<UserOrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventQuestionAnswers_aggregate?: InputMaybe<EventQuestionAnswerAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventQuestion */
export type EventQuestionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventQuestion" */
export enum EventQuestionSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "eventQuestion" */
export type EventQuestionSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventQuestionStddevFields = {
  __typename?: 'eventQuestion_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventQuestion" */
export type EventQuestionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventQuestionStddevPopFields = {
  __typename?: 'eventQuestion_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventQuestion" */
export type EventQuestionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventQuestionStddevSampFields = {
  __typename?: 'eventQuestion_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventQuestion" */
export type EventQuestionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventQuestionSumFields = {
  __typename?: 'eventQuestion_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventQuestion" */
export type EventQuestionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventQuestion" */
export enum EventQuestionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type EventQuestionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventQuestionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventQuestionSetInput>;
  where: EventQuestionBoolExp;
};

/** aggregate var_pop on columns */
export type EventQuestionVarPopFields = {
  __typename?: 'eventQuestion_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventQuestion" */
export type EventQuestionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventQuestionVarSampFields = {
  __typename?: 'eventQuestion_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventQuestion" */
export type EventQuestionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventQuestionVarianceFields = {
  __typename?: 'eventQuestion_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventQuestion" */
export type EventQuestionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventSanction" */
export type EventSanction = Node & {
  __typename?: 'eventSanction';
  allowOneDayLicense?: Maybe<Scalars['Boolean']>;
  appliesToAllCategories: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventSanctionCategories: Array<EventSanctionCategory>;
  /** An aggregate relationship */
  eventSanctionCategories_aggregate: EventSanctionCategoryAggregate;
  /** An array relationship connection */
  eventSanctionCategories_connection: EventSanctionCategoryConnection;
  /** An array relationship */
  eventWaivers: Array<EventWaiver>;
  /** An aggregate relationship */
  eventWaivers_aggregate: EventWaiverAggregate;
  /** An array relationship connection */
  eventWaivers_connection: EventWaiverConnection;
  excludedWaiverCuids: Scalars['jsonb'];
  id: Scalars['ID'];
  isLicenseRequired: Scalars['Boolean'];
  oneDayFee?: Maybe<Scalars['numeric']>;
  otherSanctionName?: Maybe<Scalars['String']>;
  permit?: Maybe<Scalars['String']>;
  /** An object relationship */
  sanction: Sanction;
  sanctionCuid: Scalars['String'];
  /** An array relationship */
  sanctionWaivers: Array<SanctionWaivers>;
  /** An aggregate relationship */
  sanctionWaivers_aggregate: SanctionWaiversAggregate;
  /** An array relationship connection */
  sanctionWaivers_connection: SanctionWaiversConnection;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventSanctionCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventSanctionCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventSanctionCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventWaiversArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionEventWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionExcludedWaiverCuidsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionSanctionWaiversArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionSanctionWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "eventSanction" */
export type EventSanctionSanctionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};

/** What categories are related to a event sanction */
export type EventSanctionCategory = Node & {
  __typename?: 'eventSanctionCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventSanction: EventSanction;
  eventSanctionCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventSanctionCategory" */
export type EventSanctionCategoryConnection = {
  __typename?: 'eventSanctionCategoryConnection';
  edges: Array<EventSanctionCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventSanctionCategoryEdge = {
  __typename?: 'eventSanctionCategoryEdge';
  cursor: Scalars['String'];
  node: EventSanctionCategory;
};

/** aggregated selection of "eventSanctionCategory" */
export type EventSanctionCategoryAggregate = {
  __typename?: 'eventSanctionCategory_aggregate';
  aggregate?: Maybe<EventSanctionCategoryAggregateFields>;
  nodes: Array<EventSanctionCategory>;
};

export type EventSanctionCategoryAggregateBoolExp = {
  count?: InputMaybe<EventSanctionCategoryAggregateBoolExpCount>;
};

export type EventSanctionCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSanctionCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSanctionCategory" */
export type EventSanctionCategoryAggregateFields = {
  __typename?: 'eventSanctionCategory_aggregate_fields';
  avg?: Maybe<EventSanctionCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventSanctionCategoryMaxFields>;
  min?: Maybe<EventSanctionCategoryMinFields>;
  stddev?: Maybe<EventSanctionCategoryStddevFields>;
  stddev_pop?: Maybe<EventSanctionCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventSanctionCategoryStddevSampFields>;
  sum?: Maybe<EventSanctionCategorySumFields>;
  var_pop?: Maybe<EventSanctionCategoryVarPopFields>;
  var_samp?: Maybe<EventSanctionCategoryVarSampFields>;
  variance?: Maybe<EventSanctionCategoryVarianceFields>;
};


/** aggregate fields of "eventSanctionCategory" */
export type EventSanctionCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSanctionCategory" */
export type EventSanctionCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventSanctionCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventSanctionCategoryMaxOrderBy>;
  min?: InputMaybe<EventSanctionCategoryMinOrderBy>;
  stddev?: InputMaybe<EventSanctionCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventSanctionCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventSanctionCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventSanctionCategorySumOrderBy>;
  var_pop?: InputMaybe<EventSanctionCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventSanctionCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventSanctionCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventSanctionCategory" */
export type EventSanctionCategoryArrRelInsertInput = {
  data: Array<EventSanctionCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSanctionCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventSanctionCategoryAvgFields = {
  __typename?: 'eventSanctionCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSanctionCategory". All fields are combined with a logical 'AND'. */
export type EventSanctionCategoryBoolExp = {
  _and?: InputMaybe<Array<EventSanctionCategoryBoolExp>>;
  _not?: InputMaybe<EventSanctionCategoryBoolExp>;
  _or?: InputMaybe<Array<EventSanctionCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventSanction?: InputMaybe<EventSanctionBoolExp>;
  eventSanctionCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSanctionCategory" */
export enum EventSanctionCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventSanctionCategoryCuidKey = 'eventSanctionCategory_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventSanctionCategoryPkey = 'eventSanctionCategory_pkey'
}

/** input type for incrementing numeric columns in table "eventSanctionCategory" */
export type EventSanctionCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventSanctionCategory" */
export type EventSanctionCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSanction?: InputMaybe<EventSanctionObjRelInsertInput>;
  eventSanctionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventSanctionCategoryMaxFields = {
  __typename?: 'eventSanctionCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventSanctionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventSanctionCategoryMinFields = {
  __typename?: 'eventSanctionCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventSanctionCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSanctionCategory" */
export type EventSanctionCategoryMutationResponse = {
  __typename?: 'eventSanctionCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSanctionCategory>;
};

/** on_conflict condition type for table "eventSanctionCategory" */
export type EventSanctionCategoryOnConflict = {
  constraint: EventSanctionCategoryConstraint;
  update_columns?: Array<EventSanctionCategoryUpdateColumn>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventSanctionCategory". */
export type EventSanctionCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSanction?: InputMaybe<EventSanctionOrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSanctionCategory */
export type EventSanctionCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventSanctionCategory" */
export enum EventSanctionCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventSanctionCuid = 'eventSanctionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventSanctionCategory" */
export type EventSanctionCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSanctionCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventSanctionCategoryStddevFields = {
  __typename?: 'eventSanctionCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventSanctionCategoryStddevPopFields = {
  __typename?: 'eventSanctionCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventSanctionCategoryStddevSampFields = {
  __typename?: 'eventSanctionCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSanctionCategorySumFields = {
  __typename?: 'eventSanctionCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventSanctionCategory" */
export type EventSanctionCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSanctionCategory" */
export enum EventSanctionCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventSanctionCuid = 'eventSanctionCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventSanctionCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventSanctionCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSanctionCategorySetInput>;
  where: EventSanctionCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventSanctionCategoryVarPopFields = {
  __typename?: 'eventSanctionCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventSanctionCategoryVarSampFields = {
  __typename?: 'eventSanctionCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventSanctionCategoryVarianceFields = {
  __typename?: 'eventSanctionCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSanctionCategory" */
export type EventSanctionCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventSanction" */
export type EventSanctionConnection = {
  __typename?: 'eventSanctionConnection';
  edges: Array<EventSanctionEdge>;
  pageInfo: PageInfo;
};

export type EventSanctionEdge = {
  __typename?: 'eventSanctionEdge';
  cursor: Scalars['String'];
  node: EventSanction;
};

/** aggregated selection of "eventSanction" */
export type EventSanctionAggregate = {
  __typename?: 'eventSanction_aggregate';
  aggregate?: Maybe<EventSanctionAggregateFields>;
  nodes: Array<EventSanction>;
};

export type EventSanctionAggregateBoolExp = {
  bool_and?: InputMaybe<EventSanctionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventSanctionAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventSanctionAggregateBoolExpCount>;
};

export type EventSanctionAggregateBoolExpBoolAnd = {
  arguments: EventSanctionSelectColumnEventSanctionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSanctionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventSanctionAggregateBoolExpBoolOr = {
  arguments: EventSanctionSelectColumnEventSanctionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSanctionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventSanctionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSanctionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSanctionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSanction" */
export type EventSanctionAggregateFields = {
  __typename?: 'eventSanction_aggregate_fields';
  avg?: Maybe<EventSanctionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventSanctionMaxFields>;
  min?: Maybe<EventSanctionMinFields>;
  stddev?: Maybe<EventSanctionStddevFields>;
  stddev_pop?: Maybe<EventSanctionStddevPopFields>;
  stddev_samp?: Maybe<EventSanctionStddevSampFields>;
  sum?: Maybe<EventSanctionSumFields>;
  var_pop?: Maybe<EventSanctionVarPopFields>;
  var_samp?: Maybe<EventSanctionVarSampFields>;
  variance?: Maybe<EventSanctionVarianceFields>;
};


/** aggregate fields of "eventSanction" */
export type EventSanctionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSanctionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSanction" */
export type EventSanctionAggregateOrderBy = {
  avg?: InputMaybe<EventSanctionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventSanctionMaxOrderBy>;
  min?: InputMaybe<EventSanctionMinOrderBy>;
  stddev?: InputMaybe<EventSanctionStddevOrderBy>;
  stddev_pop?: InputMaybe<EventSanctionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventSanctionStddevSampOrderBy>;
  sum?: InputMaybe<EventSanctionSumOrderBy>;
  var_pop?: InputMaybe<EventSanctionVarPopOrderBy>;
  var_samp?: InputMaybe<EventSanctionVarSampOrderBy>;
  variance?: InputMaybe<EventSanctionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EventSanctionAppendInput = {
  excludedWaiverCuids?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "eventSanction" */
export type EventSanctionArrRelInsertInput = {
  data: Array<EventSanctionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSanctionOnConflict>;
};

/** aggregate avg on columns */
export type EventSanctionAvgFields = {
  __typename?: 'eventSanction_avg_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSanction" */
export type EventSanctionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSanction". All fields are combined with a logical 'AND'. */
export type EventSanctionBoolExp = {
  _and?: InputMaybe<Array<EventSanctionBoolExp>>;
  _not?: InputMaybe<EventSanctionBoolExp>;
  _or?: InputMaybe<Array<EventSanctionBoolExp>>;
  allowOneDayLicense?: InputMaybe<BooleanComparisonExp>;
  appliesToAllCategories?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSanctionCategories?: InputMaybe<EventSanctionCategoryBoolExp>;
  eventSanctionCategories_aggregate?: InputMaybe<EventSanctionCategoryAggregateBoolExp>;
  eventWaivers?: InputMaybe<EventWaiverBoolExp>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateBoolExp>;
  excludedWaiverCuids?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isLicenseRequired?: InputMaybe<BooleanComparisonExp>;
  oneDayFee?: InputMaybe<NumericComparisonExp>;
  otherSanctionName?: InputMaybe<StringComparisonExp>;
  permit?: InputMaybe<StringComparisonExp>;
  sanction?: InputMaybe<SanctionBoolExp>;
  sanctionCuid?: InputMaybe<StringComparisonExp>;
  sanctionWaivers?: InputMaybe<SanctionWaiversBoolExp>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSanction" */
export enum EventSanctionConstraint {
  /** unique or primary key constraint on columns "id" */
  EventSanctionIdKey = 'eventSanction_id_key',
  /** unique or primary key constraint on columns "cuid" */
  EventSanctionPkey = 'eventSanction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EventSanctionDeleteAtPathInput = {
  excludedWaiverCuids?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EventSanctionDeleteElemInput = {
  excludedWaiverCuids?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EventSanctionDeleteKeyInput = {
  excludedWaiverCuids?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "eventSanction" */
export type EventSanctionIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "eventSanction" */
export type EventSanctionInsertInput = {
  allowOneDayLicense?: InputMaybe<Scalars['Boolean']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSanctionCategories?: InputMaybe<EventSanctionCategoryArrRelInsertInput>;
  eventWaivers?: InputMaybe<EventWaiverArrRelInsertInput>;
  excludedWaiverCuids?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  isLicenseRequired?: InputMaybe<Scalars['Boolean']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
  otherSanctionName?: InputMaybe<Scalars['String']>;
  permit?: InputMaybe<Scalars['String']>;
  sanction?: InputMaybe<SanctionObjRelInsertInput>;
  sanctionCuid?: InputMaybe<Scalars['String']>;
  sanctionWaivers?: InputMaybe<SanctionWaiversArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventSanctionMaxFields = {
  __typename?: 'eventSanction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  oneDayFee?: Maybe<Scalars['numeric']>;
  otherSanctionName?: Maybe<Scalars['String']>;
  permit?: Maybe<Scalars['String']>;
  sanctionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSanction" */
export type EventSanctionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
  otherSanctionName?: InputMaybe<OrderBy>;
  permit?: InputMaybe<OrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventSanctionMinFields = {
  __typename?: 'eventSanction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  oneDayFee?: Maybe<Scalars['numeric']>;
  otherSanctionName?: Maybe<Scalars['String']>;
  permit?: Maybe<Scalars['String']>;
  sanctionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSanction" */
export type EventSanctionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
  otherSanctionName?: InputMaybe<OrderBy>;
  permit?: InputMaybe<OrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSanction" */
export type EventSanctionMutationResponse = {
  __typename?: 'eventSanction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSanction>;
};

/** input type for inserting object relation for remote table "eventSanction" */
export type EventSanctionObjRelInsertInput = {
  data: EventSanctionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSanctionOnConflict>;
};

/** on_conflict condition type for table "eventSanction" */
export type EventSanctionOnConflict = {
  constraint: EventSanctionConstraint;
  update_columns?: Array<EventSanctionUpdateColumn>;
  where?: InputMaybe<EventSanctionBoolExp>;
};

/** Ordering options when selecting data from "eventSanction". */
export type EventSanctionOrderBy = {
  allowOneDayLicense?: InputMaybe<OrderBy>;
  appliesToAllCategories?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSanctionCategories_aggregate?: InputMaybe<EventSanctionCategoryAggregateOrderBy>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateOrderBy>;
  excludedWaiverCuids?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLicenseRequired?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
  otherSanctionName?: InputMaybe<OrderBy>;
  permit?: InputMaybe<OrderBy>;
  sanction?: InputMaybe<SanctionOrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSanction */
export type EventSanctionPkColumnsInput = {
  cuid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EventSanctionPrependInput = {
  excludedWaiverCuids?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "eventSanction" */
export enum EventSanctionSelectColumn {
  /** column name */
  AllowOneDayLicense = 'allowOneDayLicense',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  ExcludedWaiverCuids = 'excludedWaiverCuids',
  /** column name */
  Id = 'id',
  /** column name */
  IsLicenseRequired = 'isLicenseRequired',
  /** column name */
  OneDayFee = 'oneDayFee',
  /** column name */
  OtherSanctionName = 'otherSanctionName',
  /** column name */
  Permit = 'permit',
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventSanction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventSanction" */
export enum EventSanctionSelectColumnEventSanctionAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AllowOneDayLicense = 'allowOneDayLicense',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  IsLicenseRequired = 'isLicenseRequired'
}

/** select "eventSanction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventSanction" */
export enum EventSanctionSelectColumnEventSanctionAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AllowOneDayLicense = 'allowOneDayLicense',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  IsLicenseRequired = 'isLicenseRequired'
}

/** input type for updating data in table "eventSanction" */
export type EventSanctionSetInput = {
  allowOneDayLicense?: InputMaybe<Scalars['Boolean']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  excludedWaiverCuids?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  isLicenseRequired?: InputMaybe<Scalars['Boolean']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
  otherSanctionName?: InputMaybe<Scalars['String']>;
  permit?: InputMaybe<Scalars['String']>;
  sanctionCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventSanctionStddevFields = {
  __typename?: 'eventSanction_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSanction" */
export type EventSanctionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventSanctionStddevPopFields = {
  __typename?: 'eventSanction_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSanction" */
export type EventSanctionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventSanctionStddevSampFields = {
  __typename?: 'eventSanction_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSanction" */
export type EventSanctionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSanctionSumFields = {
  __typename?: 'eventSanction_sum_fields';
  id?: Maybe<Scalars['Int']>;
  oneDayFee?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "eventSanction" */
export type EventSanctionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSanction" */
export enum EventSanctionUpdateColumn {
  /** column name */
  AllowOneDayLicense = 'allowOneDayLicense',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  ExcludedWaiverCuids = 'excludedWaiverCuids',
  /** column name */
  Id = 'id',
  /** column name */
  IsLicenseRequired = 'isLicenseRequired',
  /** column name */
  OneDayFee = 'oneDayFee',
  /** column name */
  OtherSanctionName = 'otherSanctionName',
  /** column name */
  Permit = 'permit',
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventSanctionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventSanctionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventSanctionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventSanctionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventSanctionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventSanctionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventSanctionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSanctionSetInput>;
  where: EventSanctionBoolExp;
};

/** aggregate var_pop on columns */
export type EventSanctionVarPopFields = {
  __typename?: 'eventSanction_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSanction" */
export type EventSanctionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventSanctionVarSampFields = {
  __typename?: 'eventSanction_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSanction" */
export type EventSanctionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventSanctionVarianceFields = {
  __typename?: 'eventSanction_variance_fields';
  id?: Maybe<Scalars['Float']>;
  oneDayFee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSanction" */
export type EventSanctionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
};

/** Schedule item for an event */
export type EventSchedule = Node & {
  __typename?: 'eventSchedule';
  addedInternally: Scalars['Boolean'];
  appliesToAllCategories: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['time'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventScheduleCategories: Array<EventScheduleCategory>;
  /** An aggregate relationship */
  eventScheduleCategories_aggregate: EventScheduleCategoryAggregate;
  /** An array relationship connection */
  eventScheduleCategories_connection: EventScheduleCategoryConnection;
  /** An object relationship */
  eventScheduleDate: EventScheduleDate;
  eventScheduleDateCuid: Scalars['String'];
  /** An object relationship */
  eventScheduleTypeCustom?: Maybe<EventScheduleTypeCustom>;
  eventScheduleTypeCustomCuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  eventScheduleTypePreset?: Maybe<EventScheduleTypePreset>;
  eventScheduleTypePresetCuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startTime: Scalars['time'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Schedule item for an event */
export type EventScheduleEventScheduleCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


/** Schedule item for an event */
export type EventScheduleEventScheduleCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


/** Schedule item for an event */
export type EventScheduleEventScheduleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};

/** What categories are related to a schedule item */
export type EventScheduleCategory = Node & {
  __typename?: 'eventScheduleCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventSchedule: EventSchedule;
  eventScheduleCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventScheduleCategory" */
export type EventScheduleCategoryConnection = {
  __typename?: 'eventScheduleCategoryConnection';
  edges: Array<EventScheduleCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventScheduleCategoryEdge = {
  __typename?: 'eventScheduleCategoryEdge';
  cursor: Scalars['String'];
  node: EventScheduleCategory;
};

/** aggregated selection of "eventScheduleCategory" */
export type EventScheduleCategoryAggregate = {
  __typename?: 'eventScheduleCategory_aggregate';
  aggregate?: Maybe<EventScheduleCategoryAggregateFields>;
  nodes: Array<EventScheduleCategory>;
};

export type EventScheduleCategoryAggregateBoolExp = {
  count?: InputMaybe<EventScheduleCategoryAggregateBoolExpCount>;
};

export type EventScheduleCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventScheduleCategory" */
export type EventScheduleCategoryAggregateFields = {
  __typename?: 'eventScheduleCategory_aggregate_fields';
  avg?: Maybe<EventScheduleCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventScheduleCategoryMaxFields>;
  min?: Maybe<EventScheduleCategoryMinFields>;
  stddev?: Maybe<EventScheduleCategoryStddevFields>;
  stddev_pop?: Maybe<EventScheduleCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventScheduleCategoryStddevSampFields>;
  sum?: Maybe<EventScheduleCategorySumFields>;
  var_pop?: Maybe<EventScheduleCategoryVarPopFields>;
  var_samp?: Maybe<EventScheduleCategoryVarSampFields>;
  variance?: Maybe<EventScheduleCategoryVarianceFields>;
};


/** aggregate fields of "eventScheduleCategory" */
export type EventScheduleCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventScheduleCategory" */
export type EventScheduleCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventScheduleCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventScheduleCategoryMaxOrderBy>;
  min?: InputMaybe<EventScheduleCategoryMinOrderBy>;
  stddev?: InputMaybe<EventScheduleCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventScheduleCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventScheduleCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventScheduleCategorySumOrderBy>;
  var_pop?: InputMaybe<EventScheduleCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventScheduleCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventScheduleCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventScheduleCategory" */
export type EventScheduleCategoryArrRelInsertInput = {
  data: Array<EventScheduleCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventScheduleCategoryAvgFields = {
  __typename?: 'eventScheduleCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventScheduleCategory". All fields are combined with a logical 'AND'. */
export type EventScheduleCategoryBoolExp = {
  _and?: InputMaybe<Array<EventScheduleCategoryBoolExp>>;
  _not?: InputMaybe<EventScheduleCategoryBoolExp>;
  _or?: InputMaybe<Array<EventScheduleCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventSchedule?: InputMaybe<EventScheduleBoolExp>;
  eventScheduleCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventScheduleCategory" */
export enum EventScheduleCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventScheduleCategoriesCuidKey = 'eventScheduleCategories_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventScheduleCategoriesPkey = 'eventScheduleCategories_pkey'
}

/** input type for incrementing numeric columns in table "eventScheduleCategory" */
export type EventScheduleCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventScheduleCategory" */
export type EventScheduleCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSchedule?: InputMaybe<EventScheduleObjRelInsertInput>;
  eventScheduleCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventScheduleCategoryMaxFields = {
  __typename?: 'eventScheduleCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventScheduleCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventScheduleCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventScheduleCategoryMinFields = {
  __typename?: 'eventScheduleCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventScheduleCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventScheduleCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventScheduleCategory" */
export type EventScheduleCategoryMutationResponse = {
  __typename?: 'eventScheduleCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventScheduleCategory>;
};

/** on_conflict condition type for table "eventScheduleCategory" */
export type EventScheduleCategoryOnConflict = {
  constraint: EventScheduleCategoryConstraint;
  update_columns?: Array<EventScheduleCategoryUpdateColumn>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventScheduleCategory". */
export type EventScheduleCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSchedule?: InputMaybe<EventScheduleOrderBy>;
  eventScheduleCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventScheduleCategory */
export type EventScheduleCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventScheduleCategory" */
export enum EventScheduleCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventScheduleCuid = 'eventScheduleCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventScheduleCategory" */
export type EventScheduleCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventScheduleCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventScheduleCategoryStddevFields = {
  __typename?: 'eventScheduleCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventScheduleCategoryStddevPopFields = {
  __typename?: 'eventScheduleCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventScheduleCategoryStddevSampFields = {
  __typename?: 'eventScheduleCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventScheduleCategorySumFields = {
  __typename?: 'eventScheduleCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventScheduleCategory" */
export type EventScheduleCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventScheduleCategory" */
export enum EventScheduleCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventScheduleCuid = 'eventScheduleCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventScheduleCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventScheduleCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventScheduleCategorySetInput>;
  where: EventScheduleCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventScheduleCategoryVarPopFields = {
  __typename?: 'eventScheduleCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventScheduleCategoryVarSampFields = {
  __typename?: 'eventScheduleCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventScheduleCategoryVarianceFields = {
  __typename?: 'eventScheduleCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventScheduleCategory" */
export type EventScheduleCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventSchedule" */
export type EventScheduleConnection = {
  __typename?: 'eventScheduleConnection';
  edges: Array<EventScheduleEdge>;
  pageInfo: PageInfo;
};

/** A date that is related to an event */
export type EventScheduleDate = Node & {
  __typename?: 'eventScheduleDate';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  date?: Maybe<Scalars['date']>;
  dayNumber?: Maybe<Scalars['Int']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventSchedules: Array<EventSchedule>;
  /** An aggregate relationship */
  eventSchedules_aggregate: EventScheduleAggregate;
  /** An array relationship connection */
  eventSchedules_connection: EventScheduleConnection;
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};


/** A date that is related to an event */
export type EventScheduleDateEventSchedulesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** A date that is related to an event */
export type EventScheduleDateEventSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** A date that is related to an event */
export type EventScheduleDateEventSchedulesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};

/** A Relay connection object on "eventScheduleDate" */
export type EventScheduleDateConnection = {
  __typename?: 'eventScheduleDateConnection';
  edges: Array<EventScheduleDateEdge>;
  pageInfo: PageInfo;
};

export type EventScheduleDateEdge = {
  __typename?: 'eventScheduleDateEdge';
  cursor: Scalars['String'];
  node: EventScheduleDate;
};

/** aggregated selection of "eventScheduleDate" */
export type EventScheduleDateAggregate = {
  __typename?: 'eventScheduleDate_aggregate';
  aggregate?: Maybe<EventScheduleDateAggregateFields>;
  nodes: Array<EventScheduleDate>;
};

export type EventScheduleDateAggregateBoolExp = {
  count?: InputMaybe<EventScheduleDateAggregateBoolExpCount>;
};

export type EventScheduleDateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleDateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventScheduleDate" */
export type EventScheduleDateAggregateFields = {
  __typename?: 'eventScheduleDate_aggregate_fields';
  avg?: Maybe<EventScheduleDateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventScheduleDateMaxFields>;
  min?: Maybe<EventScheduleDateMinFields>;
  stddev?: Maybe<EventScheduleDateStddevFields>;
  stddev_pop?: Maybe<EventScheduleDateStddevPopFields>;
  stddev_samp?: Maybe<EventScheduleDateStddevSampFields>;
  sum?: Maybe<EventScheduleDateSumFields>;
  var_pop?: Maybe<EventScheduleDateVarPopFields>;
  var_samp?: Maybe<EventScheduleDateVarSampFields>;
  variance?: Maybe<EventScheduleDateVarianceFields>;
};


/** aggregate fields of "eventScheduleDate" */
export type EventScheduleDateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventScheduleDate" */
export type EventScheduleDateAggregateOrderBy = {
  avg?: InputMaybe<EventScheduleDateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventScheduleDateMaxOrderBy>;
  min?: InputMaybe<EventScheduleDateMinOrderBy>;
  stddev?: InputMaybe<EventScheduleDateStddevOrderBy>;
  stddev_pop?: InputMaybe<EventScheduleDateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventScheduleDateStddevSampOrderBy>;
  sum?: InputMaybe<EventScheduleDateSumOrderBy>;
  var_pop?: InputMaybe<EventScheduleDateVarPopOrderBy>;
  var_samp?: InputMaybe<EventScheduleDateVarSampOrderBy>;
  variance?: InputMaybe<EventScheduleDateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventScheduleDate" */
export type EventScheduleDateArrRelInsertInput = {
  data: Array<EventScheduleDateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleDateOnConflict>;
};

/** aggregate avg on columns */
export type EventScheduleDateAvgFields = {
  __typename?: 'eventScheduleDate_avg_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventScheduleDate" */
export type EventScheduleDateAvgOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventScheduleDate". All fields are combined with a logical 'AND'. */
export type EventScheduleDateBoolExp = {
  _and?: InputMaybe<Array<EventScheduleDateBoolExp>>;
  _not?: InputMaybe<EventScheduleDateBoolExp>;
  _or?: InputMaybe<Array<EventScheduleDateBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<DateComparisonExp>;
  dayNumber?: InputMaybe<IntComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSchedules?: InputMaybe<EventScheduleBoolExp>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventScheduleDate" */
export enum EventScheduleDateConstraint {
  /** unique or primary key constraint on columns "date", "eventCuid" */
  EventScheduleDateDateEventCuidKey = 'eventScheduleDate_date_eventCuid_key',
  /** unique or primary key constraint on columns "cuid" */
  EventScheduleDatePkey = 'eventScheduleDate_pkey'
}

/** input type for incrementing numeric columns in table "eventScheduleDate" */
export type EventScheduleDateIncInput = {
  dayNumber?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventScheduleDate" */
export type EventScheduleDateInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSchedules?: InputMaybe<EventScheduleArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventScheduleDateMaxFields = {
  __typename?: 'eventScheduleDate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  dayNumber?: Maybe<Scalars['Int']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventScheduleDate" */
export type EventScheduleDateMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventScheduleDateMinFields = {
  __typename?: 'eventScheduleDate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  dayNumber?: Maybe<Scalars['Int']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventScheduleDate" */
export type EventScheduleDateMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventScheduleDate" */
export type EventScheduleDateMutationResponse = {
  __typename?: 'eventScheduleDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventScheduleDate>;
};

/** input type for inserting object relation for remote table "eventScheduleDate" */
export type EventScheduleDateObjRelInsertInput = {
  data: EventScheduleDateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleDateOnConflict>;
};

/** on_conflict condition type for table "eventScheduleDate" */
export type EventScheduleDateOnConflict = {
  constraint: EventScheduleDateConstraint;
  update_columns?: Array<EventScheduleDateUpdateColumn>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};

/** Ordering options when selecting data from "eventScheduleDate". */
export type EventScheduleDateOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventScheduleDate */
export type EventScheduleDatePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventScheduleDate" */
export enum EventScheduleDateSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventScheduleDate" */
export type EventScheduleDateSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventScheduleDateStddevFields = {
  __typename?: 'eventScheduleDate_stddev_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventScheduleDate" */
export type EventScheduleDateStddevOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventScheduleDateStddevPopFields = {
  __typename?: 'eventScheduleDate_stddev_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventScheduleDate" */
export type EventScheduleDateStddevPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventScheduleDateStddevSampFields = {
  __typename?: 'eventScheduleDate_stddev_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventScheduleDate" */
export type EventScheduleDateStddevSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventScheduleDateSumFields = {
  __typename?: 'eventScheduleDate_sum_fields';
  dayNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventScheduleDate" */
export type EventScheduleDateSumOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventScheduleDate" */
export enum EventScheduleDateUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Date = 'date',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventScheduleDateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventScheduleDateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventScheduleDateSetInput>;
  where: EventScheduleDateBoolExp;
};

/** aggregate var_pop on columns */
export type EventScheduleDateVarPopFields = {
  __typename?: 'eventScheduleDate_var_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventScheduleDate" */
export type EventScheduleDateVarPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventScheduleDateVarSampFields = {
  __typename?: 'eventScheduleDate_var_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventScheduleDate" */
export type EventScheduleDateVarSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventScheduleDateVarianceFields = {
  __typename?: 'eventScheduleDate_variance_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventScheduleDate" */
export type EventScheduleDateVarianceOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export type EventScheduleEdge = {
  __typename?: 'eventScheduleEdge';
  cursor: Scalars['String'];
  node: EventSchedule;
};

/** What type of schedule item is it? */
export type EventScheduleTypeCustom = Node & {
  __typename?: 'eventScheduleTypeCustom';
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventSchedules: Array<EventSchedule>;
  /** An aggregate relationship */
  eventSchedules_aggregate: EventScheduleAggregate;
  /** An array relationship connection */
  eventSchedules_connection: EventScheduleConnection;
  id: Scalars['ID'];
  isPreset: Scalars['Boolean'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** What type of schedule item is it? */
export type EventScheduleTypeCustomEventSchedulesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** What type of schedule item is it? */
export type EventScheduleTypeCustomEventSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** What type of schedule item is it? */
export type EventScheduleTypeCustomEventSchedulesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};

/** A Relay connection object on "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomConnection = {
  __typename?: 'eventScheduleTypeCustomConnection';
  edges: Array<EventScheduleTypeCustomEdge>;
  pageInfo: PageInfo;
};

export type EventScheduleTypeCustomEdge = {
  __typename?: 'eventScheduleTypeCustomEdge';
  cursor: Scalars['String'];
  node: EventScheduleTypeCustom;
};

/** aggregated selection of "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomAggregate = {
  __typename?: 'eventScheduleTypeCustom_aggregate';
  aggregate?: Maybe<EventScheduleTypeCustomAggregateFields>;
  nodes: Array<EventScheduleTypeCustom>;
};

export type EventScheduleTypeCustomAggregateBoolExp = {
  bool_and?: InputMaybe<EventScheduleTypeCustomAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventScheduleTypeCustomAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventScheduleTypeCustomAggregateBoolExpCount>;
};

export type EventScheduleTypeCustomAggregateBoolExpBoolAnd = {
  arguments: EventScheduleTypeCustomSelectColumnEventScheduleTypeCustomAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleTypeCustomAggregateBoolExpBoolOr = {
  arguments: EventScheduleTypeCustomSelectColumnEventScheduleTypeCustomAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleTypeCustomAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomAggregateFields = {
  __typename?: 'eventScheduleTypeCustom_aggregate_fields';
  avg?: Maybe<EventScheduleTypeCustomAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventScheduleTypeCustomMaxFields>;
  min?: Maybe<EventScheduleTypeCustomMinFields>;
  stddev?: Maybe<EventScheduleTypeCustomStddevFields>;
  stddev_pop?: Maybe<EventScheduleTypeCustomStddevPopFields>;
  stddev_samp?: Maybe<EventScheduleTypeCustomStddevSampFields>;
  sum?: Maybe<EventScheduleTypeCustomSumFields>;
  var_pop?: Maybe<EventScheduleTypeCustomVarPopFields>;
  var_samp?: Maybe<EventScheduleTypeCustomVarSampFields>;
  variance?: Maybe<EventScheduleTypeCustomVarianceFields>;
};


/** aggregate fields of "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomAggregateOrderBy = {
  avg?: InputMaybe<EventScheduleTypeCustomAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventScheduleTypeCustomMaxOrderBy>;
  min?: InputMaybe<EventScheduleTypeCustomMinOrderBy>;
  stddev?: InputMaybe<EventScheduleTypeCustomStddevOrderBy>;
  stddev_pop?: InputMaybe<EventScheduleTypeCustomStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventScheduleTypeCustomStddevSampOrderBy>;
  sum?: InputMaybe<EventScheduleTypeCustomSumOrderBy>;
  var_pop?: InputMaybe<EventScheduleTypeCustomVarPopOrderBy>;
  var_samp?: InputMaybe<EventScheduleTypeCustomVarSampOrderBy>;
  variance?: InputMaybe<EventScheduleTypeCustomVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomArrRelInsertInput = {
  data: Array<EventScheduleTypeCustomInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleTypeCustomOnConflict>;
};

/** aggregate avg on columns */
export type EventScheduleTypeCustomAvgFields = {
  __typename?: 'eventScheduleTypeCustom_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventScheduleTypeCustom". All fields are combined with a logical 'AND'. */
export type EventScheduleTypeCustomBoolExp = {
  _and?: InputMaybe<Array<EventScheduleTypeCustomBoolExp>>;
  _not?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  _or?: InputMaybe<Array<EventScheduleTypeCustomBoolExp>>;
  color?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSchedules?: InputMaybe<EventScheduleBoolExp>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isPreset?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventScheduleTypeCustom" */
export enum EventScheduleTypeCustomConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventScheduleTypePkey = 'eventScheduleType_pkey'
}

/** input type for incrementing numeric columns in table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomInsertInput = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSchedules?: InputMaybe<EventScheduleArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventScheduleTypeCustomMaxFields = {
  __typename?: 'eventScheduleTypeCustom_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomMaxOrderBy = {
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventScheduleTypeCustomMinFields = {
  __typename?: 'eventScheduleTypeCustom_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomMinOrderBy = {
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomMutationResponse = {
  __typename?: 'eventScheduleTypeCustom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventScheduleTypeCustom>;
};

/** input type for inserting object relation for remote table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomObjRelInsertInput = {
  data: EventScheduleTypeCustomInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleTypeCustomOnConflict>;
};

/** on_conflict condition type for table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomOnConflict = {
  constraint: EventScheduleTypeCustomConstraint;
  update_columns?: Array<EventScheduleTypeCustomUpdateColumn>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};

/** Ordering options when selecting data from "eventScheduleTypeCustom". */
export type EventScheduleTypeCustomOrderBy = {
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isPreset?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventScheduleTypeCustom */
export type EventScheduleTypeCustomPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventScheduleTypeCustom" */
export enum EventScheduleTypeCustomSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventScheduleTypeCustom_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventScheduleTypeCustom" */
export enum EventScheduleTypeCustomSelectColumnEventScheduleTypeCustomAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** select "eventScheduleTypeCustom_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventScheduleTypeCustom" */
export enum EventScheduleTypeCustomSelectColumnEventScheduleTypeCustomAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** input type for updating data in table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomSetInput = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventScheduleTypeCustomStddevFields = {
  __typename?: 'eventScheduleTypeCustom_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventScheduleTypeCustomStddevPopFields = {
  __typename?: 'eventScheduleTypeCustom_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventScheduleTypeCustomStddevSampFields = {
  __typename?: 'eventScheduleTypeCustom_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventScheduleTypeCustomSumFields = {
  __typename?: 'eventScheduleTypeCustom_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventScheduleTypeCustom" */
export enum EventScheduleTypeCustomUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventScheduleTypeCustomUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventScheduleTypeCustomIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventScheduleTypeCustomSetInput>;
  where: EventScheduleTypeCustomBoolExp;
};

/** aggregate var_pop on columns */
export type EventScheduleTypeCustomVarPopFields = {
  __typename?: 'eventScheduleTypeCustom_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventScheduleTypeCustomVarSampFields = {
  __typename?: 'eventScheduleTypeCustom_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventScheduleTypeCustomVarianceFields = {
  __typename?: 'eventScheduleTypeCustom_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventScheduleTypeCustom" */
export type EventScheduleTypeCustomVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A preset that is related to a schedulePresetType */
export type EventScheduleTypePreset = Node & {
  __typename?: 'eventScheduleTypePreset';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventSchedules: Array<EventSchedule>;
  /** An aggregate relationship */
  eventSchedules_aggregate: EventScheduleAggregate;
  /** An array relationship connection */
  eventSchedules_connection: EventScheduleConnection;
  id: Scalars['ID'];
  isPreset: Scalars['Boolean'];
  /** An object relationship */
  scheduleTypePreset: ScheduleTypePreset;
  scheduleTypePresetCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** A preset that is related to a schedulePresetType */
export type EventScheduleTypePresetEventSchedulesArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** A preset that is related to a schedulePresetType */
export type EventScheduleTypePresetEventSchedulesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


/** A preset that is related to a schedulePresetType */
export type EventScheduleTypePresetEventSchedulesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};

/** A Relay connection object on "eventScheduleTypePreset" */
export type EventScheduleTypePresetConnection = {
  __typename?: 'eventScheduleTypePresetConnection';
  edges: Array<EventScheduleTypePresetEdge>;
  pageInfo: PageInfo;
};

export type EventScheduleTypePresetEdge = {
  __typename?: 'eventScheduleTypePresetEdge';
  cursor: Scalars['String'];
  node: EventScheduleTypePreset;
};

/** aggregated selection of "eventScheduleTypePreset" */
export type EventScheduleTypePresetAggregate = {
  __typename?: 'eventScheduleTypePreset_aggregate';
  aggregate?: Maybe<EventScheduleTypePresetAggregateFields>;
  nodes: Array<EventScheduleTypePreset>;
};

export type EventScheduleTypePresetAggregateBoolExp = {
  bool_and?: InputMaybe<EventScheduleTypePresetAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventScheduleTypePresetAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventScheduleTypePresetAggregateBoolExpCount>;
};

export type EventScheduleTypePresetAggregateBoolExpBoolAnd = {
  arguments: EventScheduleTypePresetSelectColumnEventScheduleTypePresetAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypePresetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleTypePresetAggregateBoolExpBoolOr = {
  arguments: EventScheduleTypePresetSelectColumnEventScheduleTypePresetAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypePresetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleTypePresetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleTypePresetBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventScheduleTypePreset" */
export type EventScheduleTypePresetAggregateFields = {
  __typename?: 'eventScheduleTypePreset_aggregate_fields';
  avg?: Maybe<EventScheduleTypePresetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventScheduleTypePresetMaxFields>;
  min?: Maybe<EventScheduleTypePresetMinFields>;
  stddev?: Maybe<EventScheduleTypePresetStddevFields>;
  stddev_pop?: Maybe<EventScheduleTypePresetStddevPopFields>;
  stddev_samp?: Maybe<EventScheduleTypePresetStddevSampFields>;
  sum?: Maybe<EventScheduleTypePresetSumFields>;
  var_pop?: Maybe<EventScheduleTypePresetVarPopFields>;
  var_samp?: Maybe<EventScheduleTypePresetVarSampFields>;
  variance?: Maybe<EventScheduleTypePresetVarianceFields>;
};


/** aggregate fields of "eventScheduleTypePreset" */
export type EventScheduleTypePresetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetAggregateOrderBy = {
  avg?: InputMaybe<EventScheduleTypePresetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventScheduleTypePresetMaxOrderBy>;
  min?: InputMaybe<EventScheduleTypePresetMinOrderBy>;
  stddev?: InputMaybe<EventScheduleTypePresetStddevOrderBy>;
  stddev_pop?: InputMaybe<EventScheduleTypePresetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventScheduleTypePresetStddevSampOrderBy>;
  sum?: InputMaybe<EventScheduleTypePresetSumOrderBy>;
  var_pop?: InputMaybe<EventScheduleTypePresetVarPopOrderBy>;
  var_samp?: InputMaybe<EventScheduleTypePresetVarSampOrderBy>;
  variance?: InputMaybe<EventScheduleTypePresetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventScheduleTypePreset" */
export type EventScheduleTypePresetArrRelInsertInput = {
  data: Array<EventScheduleTypePresetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleTypePresetOnConflict>;
};

/** aggregate avg on columns */
export type EventScheduleTypePresetAvgFields = {
  __typename?: 'eventScheduleTypePreset_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventScheduleTypePreset". All fields are combined with a logical 'AND'. */
export type EventScheduleTypePresetBoolExp = {
  _and?: InputMaybe<Array<EventScheduleTypePresetBoolExp>>;
  _not?: InputMaybe<EventScheduleTypePresetBoolExp>;
  _or?: InputMaybe<Array<EventScheduleTypePresetBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSchedules?: InputMaybe<EventScheduleBoolExp>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isPreset?: InputMaybe<BooleanComparisonExp>;
  scheduleTypePreset?: InputMaybe<ScheduleTypePresetBoolExp>;
  scheduleTypePresetCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventScheduleTypePreset" */
export enum EventScheduleTypePresetConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventScheduleTypePresetPkey = 'eventScheduleTypePreset_pkey'
}

/** input type for incrementing numeric columns in table "eventScheduleTypePreset" */
export type EventScheduleTypePresetIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventScheduleTypePreset" */
export type EventScheduleTypePresetInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSchedules?: InputMaybe<EventScheduleArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  scheduleTypePreset?: InputMaybe<ScheduleTypePresetObjRelInsertInput>;
  scheduleTypePresetCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventScheduleTypePresetMaxFields = {
  __typename?: 'eventScheduleTypePreset_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  scheduleTypePresetCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  scheduleTypePresetCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventScheduleTypePresetMinFields = {
  __typename?: 'eventScheduleTypePreset_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  scheduleTypePresetCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  scheduleTypePresetCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventScheduleTypePreset" */
export type EventScheduleTypePresetMutationResponse = {
  __typename?: 'eventScheduleTypePreset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventScheduleTypePreset>;
};

/** input type for inserting object relation for remote table "eventScheduleTypePreset" */
export type EventScheduleTypePresetObjRelInsertInput = {
  data: EventScheduleTypePresetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleTypePresetOnConflict>;
};

/** on_conflict condition type for table "eventScheduleTypePreset" */
export type EventScheduleTypePresetOnConflict = {
  constraint: EventScheduleTypePresetConstraint;
  update_columns?: Array<EventScheduleTypePresetUpdateColumn>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};

/** Ordering options when selecting data from "eventScheduleTypePreset". */
export type EventScheduleTypePresetOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isPreset?: InputMaybe<OrderBy>;
  scheduleTypePreset?: InputMaybe<ScheduleTypePresetOrderBy>;
  scheduleTypePresetCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventScheduleTypePreset */
export type EventScheduleTypePresetPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventScheduleTypePreset" */
export enum EventScheduleTypePresetSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  ScheduleTypePresetCuid = 'scheduleTypePresetCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventScheduleTypePreset_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventScheduleTypePreset" */
export enum EventScheduleTypePresetSelectColumnEventScheduleTypePresetAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** select "eventScheduleTypePreset_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventScheduleTypePreset" */
export enum EventScheduleTypePresetSelectColumnEventScheduleTypePresetAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** input type for updating data in table "eventScheduleTypePreset" */
export type EventScheduleTypePresetSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  scheduleTypePresetCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventScheduleTypePresetStddevFields = {
  __typename?: 'eventScheduleTypePreset_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventScheduleTypePresetStddevPopFields = {
  __typename?: 'eventScheduleTypePreset_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventScheduleTypePresetStddevSampFields = {
  __typename?: 'eventScheduleTypePreset_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventScheduleTypePresetSumFields = {
  __typename?: 'eventScheduleTypePreset_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventScheduleTypePreset" */
export enum EventScheduleTypePresetUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  ScheduleTypePresetCuid = 'scheduleTypePresetCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventScheduleTypePresetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventScheduleTypePresetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventScheduleTypePresetSetInput>;
  where: EventScheduleTypePresetBoolExp;
};

/** aggregate var_pop on columns */
export type EventScheduleTypePresetVarPopFields = {
  __typename?: 'eventScheduleTypePreset_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventScheduleTypePresetVarSampFields = {
  __typename?: 'eventScheduleTypePreset_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventScheduleTypePresetVarianceFields = {
  __typename?: 'eventScheduleTypePreset_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventScheduleTypePreset" */
export type EventScheduleTypePresetVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "eventSchedule" */
export type EventScheduleAggregate = {
  __typename?: 'eventSchedule_aggregate';
  aggregate?: Maybe<EventScheduleAggregateFields>;
  nodes: Array<EventSchedule>;
};

export type EventScheduleAggregateBoolExp = {
  bool_and?: InputMaybe<EventScheduleAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventScheduleAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventScheduleAggregateBoolExpCount>;
};

export type EventScheduleAggregateBoolExpBoolAnd = {
  arguments: EventScheduleSelectColumnEventScheduleAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleAggregateBoolExpBoolOr = {
  arguments: EventScheduleSelectColumnEventScheduleAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventScheduleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventScheduleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventScheduleBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSchedule" */
export type EventScheduleAggregateFields = {
  __typename?: 'eventSchedule_aggregate_fields';
  avg?: Maybe<EventScheduleAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventScheduleMaxFields>;
  min?: Maybe<EventScheduleMinFields>;
  stddev?: Maybe<EventScheduleStddevFields>;
  stddev_pop?: Maybe<EventScheduleStddevPopFields>;
  stddev_samp?: Maybe<EventScheduleStddevSampFields>;
  sum?: Maybe<EventScheduleSumFields>;
  var_pop?: Maybe<EventScheduleVarPopFields>;
  var_samp?: Maybe<EventScheduleVarSampFields>;
  variance?: Maybe<EventScheduleVarianceFields>;
};


/** aggregate fields of "eventSchedule" */
export type EventScheduleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventScheduleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSchedule" */
export type EventScheduleAggregateOrderBy = {
  avg?: InputMaybe<EventScheduleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventScheduleMaxOrderBy>;
  min?: InputMaybe<EventScheduleMinOrderBy>;
  stddev?: InputMaybe<EventScheduleStddevOrderBy>;
  stddev_pop?: InputMaybe<EventScheduleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventScheduleStddevSampOrderBy>;
  sum?: InputMaybe<EventScheduleSumOrderBy>;
  var_pop?: InputMaybe<EventScheduleVarPopOrderBy>;
  var_samp?: InputMaybe<EventScheduleVarSampOrderBy>;
  variance?: InputMaybe<EventScheduleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventSchedule" */
export type EventScheduleArrRelInsertInput = {
  data: Array<EventScheduleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleOnConflict>;
};

/** aggregate avg on columns */
export type EventScheduleAvgFields = {
  __typename?: 'eventSchedule_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSchedule" */
export type EventScheduleAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSchedule". All fields are combined with a logical 'AND'. */
export type EventScheduleBoolExp = {
  _and?: InputMaybe<Array<EventScheduleBoolExp>>;
  _not?: InputMaybe<EventScheduleBoolExp>;
  _or?: InputMaybe<Array<EventScheduleBoolExp>>;
  addedInternally?: InputMaybe<BooleanComparisonExp>;
  appliesToAllCategories?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventScheduleCategories?: InputMaybe<EventScheduleCategoryBoolExp>;
  eventScheduleCategories_aggregate?: InputMaybe<EventScheduleCategoryAggregateBoolExp>;
  eventScheduleDate?: InputMaybe<EventScheduleDateBoolExp>;
  eventScheduleDateCuid?: InputMaybe<StringComparisonExp>;
  eventScheduleTypeCustom?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  eventScheduleTypeCustomCuid?: InputMaybe<StringComparisonExp>;
  eventScheduleTypePreset?: InputMaybe<EventScheduleTypePresetBoolExp>;
  eventScheduleTypePresetCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSchedule" */
export enum EventScheduleConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventSchedulePkey = 'eventSchedule_pkey'
}

/** input type for incrementing numeric columns in table "eventSchedule" */
export type EventScheduleIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventSchedule" */
export type EventScheduleInsertInput = {
  addedInternally?: InputMaybe<Scalars['Boolean']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['time']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventScheduleCategories?: InputMaybe<EventScheduleCategoryArrRelInsertInput>;
  eventScheduleDate?: InputMaybe<EventScheduleDateObjRelInsertInput>;
  eventScheduleDateCuid?: InputMaybe<Scalars['String']>;
  eventScheduleTypeCustom?: InputMaybe<EventScheduleTypeCustomObjRelInsertInput>;
  eventScheduleTypeCustomCuid?: InputMaybe<Scalars['String']>;
  eventScheduleTypePreset?: InputMaybe<EventScheduleTypePresetObjRelInsertInput>;
  eventScheduleTypePresetCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['time']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventScheduleMaxFields = {
  __typename?: 'eventSchedule_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventScheduleDateCuid?: Maybe<Scalars['String']>;
  eventScheduleTypeCustomCuid?: Maybe<Scalars['String']>;
  eventScheduleTypePresetCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSchedule" */
export type EventScheduleMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventScheduleDateCuid?: InputMaybe<OrderBy>;
  eventScheduleTypeCustomCuid?: InputMaybe<OrderBy>;
  eventScheduleTypePresetCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventScheduleMinFields = {
  __typename?: 'eventSchedule_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  eventScheduleDateCuid?: Maybe<Scalars['String']>;
  eventScheduleTypeCustomCuid?: Maybe<Scalars['String']>;
  eventScheduleTypePresetCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSchedule" */
export type EventScheduleMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventScheduleDateCuid?: InputMaybe<OrderBy>;
  eventScheduleTypeCustomCuid?: InputMaybe<OrderBy>;
  eventScheduleTypePresetCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSchedule" */
export type EventScheduleMutationResponse = {
  __typename?: 'eventSchedule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSchedule>;
};

/** input type for inserting object relation for remote table "eventSchedule" */
export type EventScheduleObjRelInsertInput = {
  data: EventScheduleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventScheduleOnConflict>;
};

/** on_conflict condition type for table "eventSchedule" */
export type EventScheduleOnConflict = {
  constraint: EventScheduleConstraint;
  update_columns?: Array<EventScheduleUpdateColumn>;
  where?: InputMaybe<EventScheduleBoolExp>;
};

/** Ordering options when selecting data from "eventSchedule". */
export type EventScheduleOrderBy = {
  addedInternally?: InputMaybe<OrderBy>;
  appliesToAllCategories?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventScheduleCategories_aggregate?: InputMaybe<EventScheduleCategoryAggregateOrderBy>;
  eventScheduleDate?: InputMaybe<EventScheduleDateOrderBy>;
  eventScheduleDateCuid?: InputMaybe<OrderBy>;
  eventScheduleTypeCustom?: InputMaybe<EventScheduleTypeCustomOrderBy>;
  eventScheduleTypeCustomCuid?: InputMaybe<OrderBy>;
  eventScheduleTypePreset?: InputMaybe<EventScheduleTypePresetOrderBy>;
  eventScheduleTypePresetCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSchedule */
export type EventSchedulePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventSchedule" */
export enum EventScheduleSelectColumn {
  /** column name */
  AddedInternally = 'addedInternally',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventScheduleDateCuid = 'eventScheduleDateCuid',
  /** column name */
  EventScheduleTypeCustomCuid = 'eventScheduleTypeCustomCuid',
  /** column name */
  EventScheduleTypePresetCuid = 'eventScheduleTypePresetCuid',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventSchedule_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventSchedule" */
export enum EventScheduleSelectColumnEventScheduleAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AddedInternally = 'addedInternally',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** select "eventSchedule_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventSchedule" */
export enum EventScheduleSelectColumnEventScheduleAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AddedInternally = 'addedInternally',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** input type for updating data in table "eventSchedule" */
export type EventScheduleSetInput = {
  addedInternally?: InputMaybe<Scalars['Boolean']>;
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['time']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventScheduleDateCuid?: InputMaybe<Scalars['String']>;
  eventScheduleTypeCustomCuid?: InputMaybe<Scalars['String']>;
  eventScheduleTypePresetCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['time']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventScheduleStddevFields = {
  __typename?: 'eventSchedule_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSchedule" */
export type EventScheduleStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventScheduleStddevPopFields = {
  __typename?: 'eventSchedule_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSchedule" */
export type EventScheduleStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventScheduleStddevSampFields = {
  __typename?: 'eventSchedule_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSchedule" */
export type EventScheduleStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventScheduleSumFields = {
  __typename?: 'eventSchedule_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventSchedule" */
export type EventScheduleSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSchedule" */
export enum EventScheduleUpdateColumn {
  /** column name */
  AddedInternally = 'addedInternally',
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventScheduleDateCuid = 'eventScheduleDateCuid',
  /** column name */
  EventScheduleTypeCustomCuid = 'eventScheduleTypeCustomCuid',
  /** column name */
  EventScheduleTypePresetCuid = 'eventScheduleTypePresetCuid',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventScheduleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventScheduleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventScheduleSetInput>;
  where: EventScheduleBoolExp;
};

/** aggregate var_pop on columns */
export type EventScheduleVarPopFields = {
  __typename?: 'eventSchedule_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSchedule" */
export type EventScheduleVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventScheduleVarSampFields = {
  __typename?: 'eventSchedule_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSchedule" */
export type EventScheduleVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventScheduleVarianceFields = {
  __typename?: 'eventSchedule_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSchedule" */
export type EventScheduleVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventSeries" */
export type EventSeries = Node & {
  __typename?: 'eventSeries';
  appliesToAllCategories: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventSeriesCategories: Array<EventSeriesCategory>;
  /** An aggregate relationship */
  eventSeriesCategories_aggregate: EventSeriesCategoryAggregate;
  /** An array relationship connection */
  eventSeriesCategories_connection: EventSeriesCategoryConnection;
  id: Scalars['ID'];
  /** An array relationship */
  registrationSeries: Array<RegistrationSeries>;
  /** An aggregate relationship */
  registrationSeries_aggregate: RegistrationSeriesAggregate;
  /** An array relationship connection */
  registrationSeries_connection: RegistrationSeriesConnection;
  roundNumber: Scalars['Int'];
  /** An object relationship */
  series: Series;
  seriesCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "eventSeries" */
export type EventSeriesEventSeriesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "eventSeries" */
export type EventSeriesEventSeriesCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "eventSeries" */
export type EventSeriesEventSeriesCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


/** columns and relationships of "eventSeries" */
export type EventSeriesRegistrationSeriesArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


/** columns and relationships of "eventSeries" */
export type EventSeriesRegistrationSeriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


/** columns and relationships of "eventSeries" */
export type EventSeriesRegistrationSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};

/** What categories are related to a event series? */
export type EventSeriesCategory = Node & {
  __typename?: 'eventSeriesCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventSeries: EventSeries;
  eventSeriesCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventSeriesCategory" */
export type EventSeriesCategoryConnection = {
  __typename?: 'eventSeriesCategoryConnection';
  edges: Array<EventSeriesCategoryEdge>;
  pageInfo: PageInfo;
};

export type EventSeriesCategoryEdge = {
  __typename?: 'eventSeriesCategoryEdge';
  cursor: Scalars['String'];
  node: EventSeriesCategory;
};

/** aggregated selection of "eventSeriesCategory" */
export type EventSeriesCategoryAggregate = {
  __typename?: 'eventSeriesCategory_aggregate';
  aggregate?: Maybe<EventSeriesCategoryAggregateFields>;
  nodes: Array<EventSeriesCategory>;
};

export type EventSeriesCategoryAggregateBoolExp = {
  count?: InputMaybe<EventSeriesCategoryAggregateBoolExpCount>;
};

export type EventSeriesCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSeriesCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSeriesCategory" */
export type EventSeriesCategoryAggregateFields = {
  __typename?: 'eventSeriesCategory_aggregate_fields';
  avg?: Maybe<EventSeriesCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventSeriesCategoryMaxFields>;
  min?: Maybe<EventSeriesCategoryMinFields>;
  stddev?: Maybe<EventSeriesCategoryStddevFields>;
  stddev_pop?: Maybe<EventSeriesCategoryStddevPopFields>;
  stddev_samp?: Maybe<EventSeriesCategoryStddevSampFields>;
  sum?: Maybe<EventSeriesCategorySumFields>;
  var_pop?: Maybe<EventSeriesCategoryVarPopFields>;
  var_samp?: Maybe<EventSeriesCategoryVarSampFields>;
  variance?: Maybe<EventSeriesCategoryVarianceFields>;
};


/** aggregate fields of "eventSeriesCategory" */
export type EventSeriesCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSeriesCategory" */
export type EventSeriesCategoryAggregateOrderBy = {
  avg?: InputMaybe<EventSeriesCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventSeriesCategoryMaxOrderBy>;
  min?: InputMaybe<EventSeriesCategoryMinOrderBy>;
  stddev?: InputMaybe<EventSeriesCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<EventSeriesCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventSeriesCategoryStddevSampOrderBy>;
  sum?: InputMaybe<EventSeriesCategorySumOrderBy>;
  var_pop?: InputMaybe<EventSeriesCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<EventSeriesCategoryVarSampOrderBy>;
  variance?: InputMaybe<EventSeriesCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventSeriesCategory" */
export type EventSeriesCategoryArrRelInsertInput = {
  data: Array<EventSeriesCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSeriesCategoryOnConflict>;
};

/** aggregate avg on columns */
export type EventSeriesCategoryAvgFields = {
  __typename?: 'eventSeriesCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSeriesCategory". All fields are combined with a logical 'AND'. */
export type EventSeriesCategoryBoolExp = {
  _and?: InputMaybe<Array<EventSeriesCategoryBoolExp>>;
  _not?: InputMaybe<EventSeriesCategoryBoolExp>;
  _or?: InputMaybe<Array<EventSeriesCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventSeries?: InputMaybe<EventSeriesBoolExp>;
  eventSeriesCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSeriesCategory" */
export enum EventSeriesCategoryConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventSeriesCategoryCuidKey = 'eventSeriesCategory_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventSeriesCategoryPkey = 'eventSeriesCategory_pkey'
}

/** input type for incrementing numeric columns in table "eventSeriesCategory" */
export type EventSeriesCategoryIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventSeriesCategory" */
export type EventSeriesCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSeries?: InputMaybe<EventSeriesObjRelInsertInput>;
  eventSeriesCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventSeriesCategoryMaxFields = {
  __typename?: 'eventSeriesCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventSeriesCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventSeriesCategoryMinFields = {
  __typename?: 'eventSeriesCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventSeriesCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSeriesCategory" */
export type EventSeriesCategoryMutationResponse = {
  __typename?: 'eventSeriesCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSeriesCategory>;
};

/** on_conflict condition type for table "eventSeriesCategory" */
export type EventSeriesCategoryOnConflict = {
  constraint: EventSeriesCategoryConstraint;
  update_columns?: Array<EventSeriesCategoryUpdateColumn>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};

/** Ordering options when selecting data from "eventSeriesCategory". */
export type EventSeriesCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSeries?: InputMaybe<EventSeriesOrderBy>;
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSeriesCategory */
export type EventSeriesCategoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventSeriesCategory" */
export enum EventSeriesCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventSeriesCuid = 'eventSeriesCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventSeriesCategory" */
export type EventSeriesCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSeriesCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventSeriesCategoryStddevFields = {
  __typename?: 'eventSeriesCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventSeriesCategoryStddevPopFields = {
  __typename?: 'eventSeriesCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventSeriesCategoryStddevSampFields = {
  __typename?: 'eventSeriesCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSeriesCategorySumFields = {
  __typename?: 'eventSeriesCategory_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventSeriesCategory" */
export type EventSeriesCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSeriesCategory" */
export enum EventSeriesCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventSeriesCuid = 'eventSeriesCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventSeriesCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventSeriesCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSeriesCategorySetInput>;
  where: EventSeriesCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type EventSeriesCategoryVarPopFields = {
  __typename?: 'eventSeriesCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventSeriesCategoryVarSampFields = {
  __typename?: 'eventSeriesCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventSeriesCategoryVarianceFields = {
  __typename?: 'eventSeriesCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSeriesCategory" */
export type EventSeriesCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventSeries" */
export type EventSeriesConnection = {
  __typename?: 'eventSeriesConnection';
  edges: Array<EventSeriesEdge>;
  pageInfo: PageInfo;
};

export type EventSeriesEdge = {
  __typename?: 'eventSeriesEdge';
  cursor: Scalars['String'];
  node: EventSeries;
};

/** aggregated selection of "eventSeries" */
export type EventSeriesAggregate = {
  __typename?: 'eventSeries_aggregate';
  aggregate?: Maybe<EventSeriesAggregateFields>;
  nodes: Array<EventSeries>;
};

export type EventSeriesAggregateBoolExp = {
  bool_and?: InputMaybe<EventSeriesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventSeriesAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventSeriesAggregateBoolExpCount>;
};

export type EventSeriesAggregateBoolExpBoolAnd = {
  arguments: EventSeriesSelectColumnEventSeriesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSeriesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventSeriesAggregateBoolExpBoolOr = {
  arguments: EventSeriesSelectColumnEventSeriesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSeriesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventSeriesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSeriesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSeries" */
export type EventSeriesAggregateFields = {
  __typename?: 'eventSeries_aggregate_fields';
  avg?: Maybe<EventSeriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventSeriesMaxFields>;
  min?: Maybe<EventSeriesMinFields>;
  stddev?: Maybe<EventSeriesStddevFields>;
  stddev_pop?: Maybe<EventSeriesStddevPopFields>;
  stddev_samp?: Maybe<EventSeriesStddevSampFields>;
  sum?: Maybe<EventSeriesSumFields>;
  var_pop?: Maybe<EventSeriesVarPopFields>;
  var_samp?: Maybe<EventSeriesVarSampFields>;
  variance?: Maybe<EventSeriesVarianceFields>;
};


/** aggregate fields of "eventSeries" */
export type EventSeriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSeries" */
export type EventSeriesAggregateOrderBy = {
  avg?: InputMaybe<EventSeriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventSeriesMaxOrderBy>;
  min?: InputMaybe<EventSeriesMinOrderBy>;
  stddev?: InputMaybe<EventSeriesStddevOrderBy>;
  stddev_pop?: InputMaybe<EventSeriesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventSeriesStddevSampOrderBy>;
  sum?: InputMaybe<EventSeriesSumOrderBy>;
  var_pop?: InputMaybe<EventSeriesVarPopOrderBy>;
  var_samp?: InputMaybe<EventSeriesVarSampOrderBy>;
  variance?: InputMaybe<EventSeriesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventSeries" */
export type EventSeriesArrRelInsertInput = {
  data: Array<EventSeriesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSeriesOnConflict>;
};

/** aggregate avg on columns */
export type EventSeriesAvgFields = {
  __typename?: 'eventSeries_avg_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSeries" */
export type EventSeriesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSeries". All fields are combined with a logical 'AND'. */
export type EventSeriesBoolExp = {
  _and?: InputMaybe<Array<EventSeriesBoolExp>>;
  _not?: InputMaybe<EventSeriesBoolExp>;
  _or?: InputMaybe<Array<EventSeriesBoolExp>>;
  appliesToAllCategories?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSeriesCategories?: InputMaybe<EventSeriesCategoryBoolExp>;
  eventSeriesCategories_aggregate?: InputMaybe<EventSeriesCategoryAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  registrationSeries?: InputMaybe<RegistrationSeriesBoolExp>;
  registrationSeries_aggregate?: InputMaybe<RegistrationSeriesAggregateBoolExp>;
  roundNumber?: InputMaybe<IntComparisonExp>;
  series?: InputMaybe<SeriesBoolExp>;
  seriesCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSeries" */
export enum EventSeriesConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventSeriesCuidKey = 'eventSeries_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventSeriesIdKey = 'eventSeries_id_key',
  /** unique or primary key constraint on columns "cuid" */
  EventSeriesPkey = 'eventSeries_pkey'
}

/** input type for incrementing numeric columns in table "eventSeries" */
export type EventSeriesIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventSeries" */
export type EventSeriesInsertInput = {
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSeriesCategories?: InputMaybe<EventSeriesCategoryArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  registrationSeries?: InputMaybe<RegistrationSeriesArrRelInsertInput>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  series?: InputMaybe<SeriesObjRelInsertInput>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventSeriesMaxFields = {
  __typename?: 'eventSeries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roundNumber?: Maybe<Scalars['Int']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSeries" */
export type EventSeriesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventSeriesMinFields = {
  __typename?: 'eventSeries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roundNumber?: Maybe<Scalars['Int']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSeries" */
export type EventSeriesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSeries" */
export type EventSeriesMutationResponse = {
  __typename?: 'eventSeries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSeries>;
};

/** input type for inserting object relation for remote table "eventSeries" */
export type EventSeriesObjRelInsertInput = {
  data: EventSeriesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSeriesOnConflict>;
};

/** on_conflict condition type for table "eventSeries" */
export type EventSeriesOnConflict = {
  constraint: EventSeriesConstraint;
  update_columns?: Array<EventSeriesUpdateColumn>;
  where?: InputMaybe<EventSeriesBoolExp>;
};

/** Ordering options when selecting data from "eventSeries". */
export type EventSeriesOrderBy = {
  appliesToAllCategories?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSeriesCategories_aggregate?: InputMaybe<EventSeriesCategoryAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationSeries_aggregate?: InputMaybe<RegistrationSeriesAggregateOrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
  series?: InputMaybe<SeriesOrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSeries */
export type EventSeriesPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventSeries" */
export enum EventSeriesSelectColumn {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RoundNumber = 'roundNumber',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventSeries_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventSeries" */
export enum EventSeriesSelectColumnEventSeriesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** select "eventSeries_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventSeries" */
export enum EventSeriesSelectColumnEventSeriesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories'
}

/** input type for updating data in table "eventSeries" */
export type EventSeriesSetInput = {
  appliesToAllCategories?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventSeriesStddevFields = {
  __typename?: 'eventSeries_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSeries" */
export type EventSeriesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventSeriesStddevPopFields = {
  __typename?: 'eventSeries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSeries" */
export type EventSeriesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventSeriesStddevSampFields = {
  __typename?: 'eventSeries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSeries" */
export type EventSeriesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSeriesSumFields = {
  __typename?: 'eventSeries_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  roundNumber?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventSeries" */
export type EventSeriesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSeries" */
export enum EventSeriesUpdateColumn {
  /** column name */
  AppliesToAllCategories = 'appliesToAllCategories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RoundNumber = 'roundNumber',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventSeriesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventSeriesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSeriesSetInput>;
  where: EventSeriesBoolExp;
};

/** aggregate var_pop on columns */
export type EventSeriesVarPopFields = {
  __typename?: 'eventSeries_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSeries" */
export type EventSeriesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventSeriesVarSampFields = {
  __typename?: 'eventSeries_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSeries" */
export type EventSeriesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventSeriesVarianceFields = {
  __typename?: 'eventSeries_variance_fields';
  id?: Maybe<Scalars['Float']>;
  roundNumber?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSeries" */
export type EventSeriesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  roundNumber?: InputMaybe<OrderBy>;
};

/** Sponsor for an event */
export type EventSponsor = Node & {
  __typename?: 'eventSponsor';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  /** An object relationship */
  sponsor: Sponsor;
  sponsorCuid: Scalars['String'];
  tierName: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventSponsor" */
export type EventSponsorConnection = {
  __typename?: 'eventSponsorConnection';
  edges: Array<EventSponsorEdge>;
  pageInfo: PageInfo;
};

export type EventSponsorEdge = {
  __typename?: 'eventSponsorEdge';
  cursor: Scalars['String'];
  node: EventSponsor;
};

/** aggregated selection of "eventSponsor" */
export type EventSponsorAggregate = {
  __typename?: 'eventSponsor_aggregate';
  aggregate?: Maybe<EventSponsorAggregateFields>;
  nodes: Array<EventSponsor>;
};

export type EventSponsorAggregateBoolExp = {
  count?: InputMaybe<EventSponsorAggregateBoolExpCount>;
};

export type EventSponsorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSponsorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventSponsorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventSponsor" */
export type EventSponsorAggregateFields = {
  __typename?: 'eventSponsor_aggregate_fields';
  avg?: Maybe<EventSponsorAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventSponsorMaxFields>;
  min?: Maybe<EventSponsorMinFields>;
  stddev?: Maybe<EventSponsorStddevFields>;
  stddev_pop?: Maybe<EventSponsorStddevPopFields>;
  stddev_samp?: Maybe<EventSponsorStddevSampFields>;
  sum?: Maybe<EventSponsorSumFields>;
  var_pop?: Maybe<EventSponsorVarPopFields>;
  var_samp?: Maybe<EventSponsorVarSampFields>;
  variance?: Maybe<EventSponsorVarianceFields>;
};


/** aggregate fields of "eventSponsor" */
export type EventSponsorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSponsorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventSponsor" */
export type EventSponsorAggregateOrderBy = {
  avg?: InputMaybe<EventSponsorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventSponsorMaxOrderBy>;
  min?: InputMaybe<EventSponsorMinOrderBy>;
  stddev?: InputMaybe<EventSponsorStddevOrderBy>;
  stddev_pop?: InputMaybe<EventSponsorStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventSponsorStddevSampOrderBy>;
  sum?: InputMaybe<EventSponsorSumOrderBy>;
  var_pop?: InputMaybe<EventSponsorVarPopOrderBy>;
  var_samp?: InputMaybe<EventSponsorVarSampOrderBy>;
  variance?: InputMaybe<EventSponsorVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventSponsor" */
export type EventSponsorArrRelInsertInput = {
  data: Array<EventSponsorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventSponsorOnConflict>;
};

/** aggregate avg on columns */
export type EventSponsorAvgFields = {
  __typename?: 'eventSponsor_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventSponsor" */
export type EventSponsorAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventSponsor". All fields are combined with a logical 'AND'. */
export type EventSponsorBoolExp = {
  _and?: InputMaybe<Array<EventSponsorBoolExp>>;
  _not?: InputMaybe<EventSponsorBoolExp>;
  _or?: InputMaybe<Array<EventSponsorBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  sponsor?: InputMaybe<SponsorBoolExp>;
  sponsorCuid?: InputMaybe<StringComparisonExp>;
  tierName?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventSponsor" */
export enum EventSponsorConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventSponsorCuidKey = 'eventSponsor_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventSponsorPkey = 'eventSponsor_pkey',
  /** unique or primary key constraint on columns "cuid", "eventCuid", "tierName", "position" */
  EventSponsorPositionTierNameEventCuidCuidKey = 'eventSponsor_position_tierName_eventCuid_cuid_key'
}

/** input type for incrementing numeric columns in table "eventSponsor" */
export type EventSponsorIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventSponsor" */
export type EventSponsorInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
  sponsor?: InputMaybe<SponsorObjRelInsertInput>;
  sponsorCuid?: InputMaybe<Scalars['String']>;
  tierName?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventSponsorMaxFields = {
  __typename?: 'eventSponsor_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
  sponsorCuid?: Maybe<Scalars['String']>;
  tierName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventSponsor" */
export type EventSponsorMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  tierName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventSponsorMinFields = {
  __typename?: 'eventSponsor_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
  sponsorCuid?: Maybe<Scalars['String']>;
  tierName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventSponsor" */
export type EventSponsorMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  tierName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventSponsor" */
export type EventSponsorMutationResponse = {
  __typename?: 'eventSponsor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventSponsor>;
};

/** on_conflict condition type for table "eventSponsor" */
export type EventSponsorOnConflict = {
  constraint: EventSponsorConstraint;
  update_columns?: Array<EventSponsorUpdateColumn>;
  where?: InputMaybe<EventSponsorBoolExp>;
};

/** Ordering options when selecting data from "eventSponsor". */
export type EventSponsorOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  sponsor?: InputMaybe<SponsorOrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  tierName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventSponsor */
export type EventSponsorPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventSponsor" */
export enum EventSponsorSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  SponsorCuid = 'sponsorCuid',
  /** column name */
  TierName = 'tierName',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventSponsor" */
export type EventSponsorSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['Int']>;
  sponsorCuid?: InputMaybe<Scalars['String']>;
  tierName?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventSponsorStddevFields = {
  __typename?: 'eventSponsor_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventSponsor" */
export type EventSponsorStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventSponsorStddevPopFields = {
  __typename?: 'eventSponsor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventSponsor" */
export type EventSponsorStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventSponsorStddevSampFields = {
  __typename?: 'eventSponsor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventSponsor" */
export type EventSponsorStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSponsorSumFields = {
  __typename?: 'eventSponsor_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventSponsor" */
export type EventSponsorSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "eventSponsor" */
export enum EventSponsorUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  SponsorCuid = 'sponsorCuid',
  /** column name */
  TierName = 'tierName',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventSponsorUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventSponsorIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSponsorSetInput>;
  where: EventSponsorBoolExp;
};

/** aggregate var_pop on columns */
export type EventSponsorVarPopFields = {
  __typename?: 'eventSponsor_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventSponsor" */
export type EventSponsorVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventSponsorVarSampFields = {
  __typename?: 'eventSponsor_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventSponsor" */
export type EventSponsorVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventSponsorVarianceFields = {
  __typename?: 'eventSponsor_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventSponsor" */
export type EventSponsorVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventTicket" */
export type EventTicket = Node & {
  __typename?: 'eventTicket';
  allowWaitlist: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  event?: Maybe<Event>;
  eventCuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  registrationTickets: Array<RegistrationTicket>;
  /** An aggregate relationship */
  registrationTickets_aggregate: RegistrationTicketAggregate;
  /** An array relationship connection */
  registrationTickets_connection: RegistrationTicketConnection;
  /** An object relationship */
  ticket?: Maybe<Ticket>;
  ticketCuid?: Maybe<Scalars['String']>;
  ticketsAvailable?: Maybe<Scalars['Int']>;
  /** calculates the tickets remaining by accounting for tickets sold (via complete and in progress registrations) */
  ticketsRemaining?: Maybe<Scalars['bigint']>;
  ticketsReserved?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  visible: Scalars['Boolean'];
};


/** columns and relationships of "eventTicket" */
export type EventTicketRegistrationTicketsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


/** columns and relationships of "eventTicket" */
export type EventTicketRegistrationTicketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


/** columns and relationships of "eventTicket" */
export type EventTicketRegistrationTicketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};

/** A Relay connection object on "eventTicket" */
export type EventTicketConnection = {
  __typename?: 'eventTicketConnection';
  edges: Array<EventTicketEdge>;
  pageInfo: PageInfo;
};

export type EventTicketEdge = {
  __typename?: 'eventTicketEdge';
  cursor: Scalars['String'];
  node: EventTicket;
};

/** aggregated selection of "eventTicket" */
export type EventTicketAggregate = {
  __typename?: 'eventTicket_aggregate';
  aggregate?: Maybe<EventTicketAggregateFields>;
  nodes: Array<EventTicket>;
};

export type EventTicketAggregateBoolExp = {
  bool_and?: InputMaybe<EventTicketAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventTicketAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventTicketAggregateBoolExpCount>;
};

export type EventTicketAggregateBoolExpBoolAnd = {
  arguments: EventTicketSelectColumnEventTicketAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventTicketBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventTicketAggregateBoolExpBoolOr = {
  arguments: EventTicketSelectColumnEventTicketAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventTicketBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventTicketAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventTicketBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventTicket" */
export type EventTicketAggregateFields = {
  __typename?: 'eventTicket_aggregate_fields';
  avg?: Maybe<EventTicketAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventTicketMaxFields>;
  min?: Maybe<EventTicketMinFields>;
  stddev?: Maybe<EventTicketStddevFields>;
  stddev_pop?: Maybe<EventTicketStddevPopFields>;
  stddev_samp?: Maybe<EventTicketStddevSampFields>;
  sum?: Maybe<EventTicketSumFields>;
  var_pop?: Maybe<EventTicketVarPopFields>;
  var_samp?: Maybe<EventTicketVarSampFields>;
  variance?: Maybe<EventTicketVarianceFields>;
};


/** aggregate fields of "eventTicket" */
export type EventTicketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventTicket" */
export type EventTicketAggregateOrderBy = {
  avg?: InputMaybe<EventTicketAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventTicketMaxOrderBy>;
  min?: InputMaybe<EventTicketMinOrderBy>;
  stddev?: InputMaybe<EventTicketStddevOrderBy>;
  stddev_pop?: InputMaybe<EventTicketStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventTicketStddevSampOrderBy>;
  sum?: InputMaybe<EventTicketSumOrderBy>;
  var_pop?: InputMaybe<EventTicketVarPopOrderBy>;
  var_samp?: InputMaybe<EventTicketVarSampOrderBy>;
  variance?: InputMaybe<EventTicketVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventTicket" */
export type EventTicketArrRelInsertInput = {
  data: Array<EventTicketInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventTicketOnConflict>;
};

/** aggregate avg on columns */
export type EventTicketAvgFields = {
  __typename?: 'eventTicket_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventTicket" */
export type EventTicketAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventTicket". All fields are combined with a logical 'AND'. */
export type EventTicketBoolExp = {
  _and?: InputMaybe<Array<EventTicketBoolExp>>;
  _not?: InputMaybe<EventTicketBoolExp>;
  _or?: InputMaybe<Array<EventTicketBoolExp>>;
  allowWaitlist?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  registrationTickets?: InputMaybe<RegistrationTicketBoolExp>;
  registrationTickets_aggregate?: InputMaybe<RegistrationTicketAggregateBoolExp>;
  ticket?: InputMaybe<TicketBoolExp>;
  ticketCuid?: InputMaybe<StringComparisonExp>;
  ticketsAvailable?: InputMaybe<IntComparisonExp>;
  ticketsRemaining?: InputMaybe<BigintComparisonExp>;
  ticketsReserved?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  visible?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "eventTicket" */
export enum EventTicketConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventTicketPkey = 'eventTicket_pkey'
}

/** input type for incrementing numeric columns in table "eventTicket" */
export type EventTicketIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  ticketsAvailable?: InputMaybe<Scalars['Int']>;
  ticketsReserved?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventTicket" */
export type EventTicketInsertInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  registrationTickets?: InputMaybe<RegistrationTicketArrRelInsertInput>;
  ticket?: InputMaybe<TicketObjRelInsertInput>;
  ticketCuid?: InputMaybe<Scalars['String']>;
  ticketsAvailable?: InputMaybe<Scalars['Int']>;
  ticketsReserved?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type EventTicketMaxFields = {
  __typename?: 'eventTicket_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  ticketCuid?: Maybe<Scalars['String']>;
  ticketsAvailable?: Maybe<Scalars['Int']>;
  ticketsReserved?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventTicket" */
export type EventTicketMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketCuid?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventTicketMinFields = {
  __typename?: 'eventTicket_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  ticketCuid?: Maybe<Scalars['String']>;
  ticketsAvailable?: Maybe<Scalars['Int']>;
  ticketsReserved?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventTicket" */
export type EventTicketMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketCuid?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventTicket" */
export type EventTicketMutationResponse = {
  __typename?: 'eventTicket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventTicket>;
};

/** input type for inserting object relation for remote table "eventTicket" */
export type EventTicketObjRelInsertInput = {
  data: EventTicketInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventTicketOnConflict>;
};

/** on_conflict condition type for table "eventTicket" */
export type EventTicketOnConflict = {
  constraint: EventTicketConstraint;
  update_columns?: Array<EventTicketUpdateColumn>;
  where?: InputMaybe<EventTicketBoolExp>;
};

/** Ordering options when selecting data from "eventTicket". */
export type EventTicketOrderBy = {
  allowWaitlist?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  registrationTickets_aggregate?: InputMaybe<RegistrationTicketAggregateOrderBy>;
  ticket?: InputMaybe<TicketOrderBy>;
  ticketCuid?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsRemaining?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  visible?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventTicket */
export type EventTicketPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventTicket" */
export enum EventTicketSelectColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  TicketCuid = 'ticketCuid',
  /** column name */
  TicketsAvailable = 'ticketsAvailable',
  /** column name */
  TicketsReserved = 'ticketsReserved',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

/** select "eventTicket_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventTicket" */
export enum EventTicketSelectColumnEventTicketAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  Visible = 'visible'
}

/** select "eventTicket_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventTicket" */
export enum EventTicketSelectColumnEventTicketAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "eventTicket" */
export type EventTicketSetInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  ticketCuid?: InputMaybe<Scalars['String']>;
  ticketsAvailable?: InputMaybe<Scalars['Int']>;
  ticketsReserved?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type EventTicketStddevFields = {
  __typename?: 'eventTicket_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventTicket" */
export type EventTicketStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventTicketStddevPopFields = {
  __typename?: 'eventTicket_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventTicket" */
export type EventTicketStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventTicketStddevSampFields = {
  __typename?: 'eventTicket_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventTicket" */
export type EventTicketStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventTicketSumFields = {
  __typename?: 'eventTicket_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  ticketsAvailable?: Maybe<Scalars['Int']>;
  ticketsReserved?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventTicket" */
export type EventTicketSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** update columns of table "eventTicket" */
export enum EventTicketUpdateColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  TicketCuid = 'ticketCuid',
  /** column name */
  TicketsAvailable = 'ticketsAvailable',
  /** column name */
  TicketsReserved = 'ticketsReserved',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

export type EventTicketUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventTicketIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventTicketSetInput>;
  where: EventTicketBoolExp;
};

/** aggregate var_pop on columns */
export type EventTicketVarPopFields = {
  __typename?: 'eventTicket_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventTicket" */
export type EventTicketVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventTicketVarSampFields = {
  __typename?: 'eventTicket_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventTicket" */
export type EventTicketVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventTicketVarianceFields = {
  __typename?: 'eventTicket_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  ticketsAvailable?: Maybe<Scalars['Float']>;
  ticketsReserved?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventTicket" */
export type EventTicketVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  ticketsAvailable?: InputMaybe<OrderBy>;
  ticketsReserved?: InputMaybe<OrderBy>;
};

/** Update for an event */
export type EventUpdate = Node & {
  __typename?: 'eventUpdate';
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  deletedByUser?: Maybe<User>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventUpdateComments: Array<EventUpdateComment>;
  /** An aggregate relationship */
  eventUpdateComments_aggregate: EventUpdateCommentAggregate;
  /** An array relationship connection */
  eventUpdateComments_connection: EventUpdateCommentConnection;
  id: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};


/** Update for an event */
export type EventUpdateEventUpdateCommentsArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Update for an event */
export type EventUpdateEventUpdateCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Update for an event */
export type EventUpdateEventUpdateCommentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};

/** Comment for an Event Update */
export type EventUpdateComment = Node & {
  __typename?: 'eventUpdateComment';
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  deletedByUser?: Maybe<User>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  eventUpdate: EventUpdate;
  eventUpdateCuid: Scalars['String'];
  id: Scalars['ID'];
  isOrganizer: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "eventUpdateComment" */
export type EventUpdateCommentConnection = {
  __typename?: 'eventUpdateCommentConnection';
  edges: Array<EventUpdateCommentEdge>;
  pageInfo: PageInfo;
};

export type EventUpdateCommentEdge = {
  __typename?: 'eventUpdateCommentEdge';
  cursor: Scalars['String'];
  node: EventUpdateComment;
};

/** aggregated selection of "eventUpdateComment" */
export type EventUpdateCommentAggregate = {
  __typename?: 'eventUpdateComment_aggregate';
  aggregate?: Maybe<EventUpdateCommentAggregateFields>;
  nodes: Array<EventUpdateComment>;
};

export type EventUpdateCommentAggregateBoolExp = {
  bool_and?: InputMaybe<EventUpdateCommentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventUpdateCommentAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventUpdateCommentAggregateBoolExpCount>;
};

export type EventUpdateCommentAggregateBoolExpBoolAnd = {
  arguments: EventUpdateCommentSelectColumnEventUpdateCommentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventUpdateCommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventUpdateCommentAggregateBoolExpBoolOr = {
  arguments: EventUpdateCommentSelectColumnEventUpdateCommentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventUpdateCommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventUpdateCommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventUpdateCommentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventUpdateComment" */
export type EventUpdateCommentAggregateFields = {
  __typename?: 'eventUpdateComment_aggregate_fields';
  avg?: Maybe<EventUpdateCommentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventUpdateCommentMaxFields>;
  min?: Maybe<EventUpdateCommentMinFields>;
  stddev?: Maybe<EventUpdateCommentStddevFields>;
  stddev_pop?: Maybe<EventUpdateCommentStddevPopFields>;
  stddev_samp?: Maybe<EventUpdateCommentStddevSampFields>;
  sum?: Maybe<EventUpdateCommentSumFields>;
  var_pop?: Maybe<EventUpdateCommentVarPopFields>;
  var_samp?: Maybe<EventUpdateCommentVarSampFields>;
  variance?: Maybe<EventUpdateCommentVarianceFields>;
};


/** aggregate fields of "eventUpdateComment" */
export type EventUpdateCommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventUpdateComment" */
export type EventUpdateCommentAggregateOrderBy = {
  avg?: InputMaybe<EventUpdateCommentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventUpdateCommentMaxOrderBy>;
  min?: InputMaybe<EventUpdateCommentMinOrderBy>;
  stddev?: InputMaybe<EventUpdateCommentStddevOrderBy>;
  stddev_pop?: InputMaybe<EventUpdateCommentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventUpdateCommentStddevSampOrderBy>;
  sum?: InputMaybe<EventUpdateCommentSumOrderBy>;
  var_pop?: InputMaybe<EventUpdateCommentVarPopOrderBy>;
  var_samp?: InputMaybe<EventUpdateCommentVarSampOrderBy>;
  variance?: InputMaybe<EventUpdateCommentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventUpdateComment" */
export type EventUpdateCommentArrRelInsertInput = {
  data: Array<EventUpdateCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventUpdateCommentOnConflict>;
};

/** aggregate avg on columns */
export type EventUpdateCommentAvgFields = {
  __typename?: 'eventUpdateComment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventUpdateComment" */
export type EventUpdateCommentAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventUpdateComment". All fields are combined with a logical 'AND'. */
export type EventUpdateCommentBoolExp = {
  _and?: InputMaybe<Array<EventUpdateCommentBoolExp>>;
  _not?: InputMaybe<EventUpdateCommentBoolExp>;
  _or?: InputMaybe<Array<EventUpdateCommentBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deletedByUser?: InputMaybe<UserBoolExp>;
  deletedByUserId?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  eventUpdate?: InputMaybe<EventUpdateBoolExp>;
  eventUpdateCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isOrganizer?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventUpdateComment" */
export enum EventUpdateCommentConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventUpdateCommentCuidKey = 'eventUpdateComment_cuid_key',
  /** unique or primary key constraint on columns "eventUpdateCuid", "userId" */
  EventUpdateCommentEventUpdateCuidUserIdKey = 'eventUpdateComment_eventUpdateCuid_userId_key',
  /** unique or primary key constraint on columns "id" */
  EventUpdateCommentPkey = 'eventUpdateComment_pkey'
}

/** input type for incrementing numeric columns in table "eventUpdateComment" */
export type EventUpdateCommentIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventUpdateComment" */
export type EventUpdateCommentInsertInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUser?: InputMaybe<UserObjRelInsertInput>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventUpdate?: InputMaybe<EventUpdateObjRelInsertInput>;
  eventUpdateCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isOrganizer?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventUpdateCommentMaxFields = {
  __typename?: 'eventUpdateComment_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventUpdateCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventUpdateComment" */
export type EventUpdateCommentMaxOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventUpdateCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventUpdateCommentMinFields = {
  __typename?: 'eventUpdateComment_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventUpdateCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventUpdateComment" */
export type EventUpdateCommentMinOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventUpdateCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventUpdateComment" */
export type EventUpdateCommentMutationResponse = {
  __typename?: 'eventUpdateComment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventUpdateComment>;
};

/** on_conflict condition type for table "eventUpdateComment" */
export type EventUpdateCommentOnConflict = {
  constraint: EventUpdateCommentConstraint;
  update_columns?: Array<EventUpdateCommentUpdateColumn>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};

/** Ordering options when selecting data from "eventUpdateComment". */
export type EventUpdateCommentOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUser?: InputMaybe<UserOrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventUpdate?: InputMaybe<EventUpdateOrderBy>;
  eventUpdateCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOrganizer?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventUpdateComment */
export type EventUpdateCommentPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventUpdateComment" */
export enum EventUpdateCommentSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventUpdateCuid = 'eventUpdateCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsOrganizer = 'isOrganizer',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "eventUpdateComment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventUpdateComment" */
export enum EventUpdateCommentSelectColumnEventUpdateCommentAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsOrganizer = 'isOrganizer'
}

/** select "eventUpdateComment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventUpdateComment" */
export enum EventUpdateCommentSelectColumnEventUpdateCommentAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsOrganizer = 'isOrganizer'
}

/** input type for updating data in table "eventUpdateComment" */
export type EventUpdateCommentSetInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventUpdateCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isOrganizer?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventUpdateCommentStddevFields = {
  __typename?: 'eventUpdateComment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventUpdateComment" */
export type EventUpdateCommentStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventUpdateCommentStddevPopFields = {
  __typename?: 'eventUpdateComment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventUpdateComment" */
export type EventUpdateCommentStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventUpdateCommentStddevSampFields = {
  __typename?: 'eventUpdateComment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventUpdateComment" */
export type EventUpdateCommentStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventUpdateCommentSumFields = {
  __typename?: 'eventUpdateComment_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventUpdateComment" */
export type EventUpdateCommentSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventUpdateComment" */
export enum EventUpdateCommentUpdateColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventUpdateCuid = 'eventUpdateCuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsOrganizer = 'isOrganizer',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type EventUpdateCommentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventUpdateCommentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventUpdateCommentSetInput>;
  where: EventUpdateCommentBoolExp;
};

/** aggregate var_pop on columns */
export type EventUpdateCommentVarPopFields = {
  __typename?: 'eventUpdateComment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventUpdateComment" */
export type EventUpdateCommentVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventUpdateCommentVarSampFields = {
  __typename?: 'eventUpdateComment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventUpdateComment" */
export type EventUpdateCommentVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventUpdateCommentVarianceFields = {
  __typename?: 'eventUpdateComment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventUpdateComment" */
export type EventUpdateCommentVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "eventUpdate" */
export type EventUpdateConnection = {
  __typename?: 'eventUpdateConnection';
  edges: Array<EventUpdateEdge>;
  pageInfo: PageInfo;
};

export type EventUpdateEdge = {
  __typename?: 'eventUpdateEdge';
  cursor: Scalars['String'];
  node: EventUpdate;
};

/** aggregated selection of "eventUpdate" */
export type EventUpdateAggregate = {
  __typename?: 'eventUpdate_aggregate';
  aggregate?: Maybe<EventUpdateAggregateFields>;
  nodes: Array<EventUpdate>;
};

export type EventUpdateAggregateBoolExp = {
  count?: InputMaybe<EventUpdateAggregateBoolExpCount>;
};

export type EventUpdateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventUpdateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventUpdate" */
export type EventUpdateAggregateFields = {
  __typename?: 'eventUpdate_aggregate_fields';
  avg?: Maybe<EventUpdateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventUpdateMaxFields>;
  min?: Maybe<EventUpdateMinFields>;
  stddev?: Maybe<EventUpdateStddevFields>;
  stddev_pop?: Maybe<EventUpdateStddevPopFields>;
  stddev_samp?: Maybe<EventUpdateStddevSampFields>;
  sum?: Maybe<EventUpdateSumFields>;
  var_pop?: Maybe<EventUpdateVarPopFields>;
  var_samp?: Maybe<EventUpdateVarSampFields>;
  variance?: Maybe<EventUpdateVarianceFields>;
};


/** aggregate fields of "eventUpdate" */
export type EventUpdateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventUpdate" */
export type EventUpdateAggregateOrderBy = {
  avg?: InputMaybe<EventUpdateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventUpdateMaxOrderBy>;
  min?: InputMaybe<EventUpdateMinOrderBy>;
  stddev?: InputMaybe<EventUpdateStddevOrderBy>;
  stddev_pop?: InputMaybe<EventUpdateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventUpdateStddevSampOrderBy>;
  sum?: InputMaybe<EventUpdateSumOrderBy>;
  var_pop?: InputMaybe<EventUpdateVarPopOrderBy>;
  var_samp?: InputMaybe<EventUpdateVarSampOrderBy>;
  variance?: InputMaybe<EventUpdateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventUpdate" */
export type EventUpdateArrRelInsertInput = {
  data: Array<EventUpdateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventUpdateOnConflict>;
};

/** aggregate avg on columns */
export type EventUpdateAvgFields = {
  __typename?: 'eventUpdate_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventUpdate" */
export type EventUpdateAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventUpdate". All fields are combined with a logical 'AND'. */
export type EventUpdateBoolExp = {
  _and?: InputMaybe<Array<EventUpdateBoolExp>>;
  _not?: InputMaybe<EventUpdateBoolExp>;
  _or?: InputMaybe<Array<EventUpdateBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deletedByUser?: InputMaybe<UserBoolExp>;
  deletedByUserId?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventUpdateComments?: InputMaybe<EventUpdateCommentBoolExp>;
  eventUpdateComments_aggregate?: InputMaybe<EventUpdateCommentAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventUpdate" */
export enum EventUpdateConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventUpdateCuidKey = 'eventUpdate_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventUpdatePkey = 'eventUpdate_pkey'
}

/** input type for incrementing numeric columns in table "eventUpdate" */
export type EventUpdateIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventUpdate" */
export type EventUpdateInsertInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUser?: InputMaybe<UserObjRelInsertInput>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventUpdateComments?: InputMaybe<EventUpdateCommentArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventUpdateMaxFields = {
  __typename?: 'eventUpdate_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventUpdate" */
export type EventUpdateMaxOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventUpdateMinFields = {
  __typename?: 'eventUpdate_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deletedByUserId?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventUpdate" */
export type EventUpdateMinOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventUpdate" */
export type EventUpdateMutationResponse = {
  __typename?: 'eventUpdate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventUpdate>;
};

/** input type for inserting object relation for remote table "eventUpdate" */
export type EventUpdateObjRelInsertInput = {
  data: EventUpdateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventUpdateOnConflict>;
};

/** on_conflict condition type for table "eventUpdate" */
export type EventUpdateOnConflict = {
  constraint: EventUpdateConstraint;
  update_columns?: Array<EventUpdateUpdateColumn>;
  where?: InputMaybe<EventUpdateBoolExp>;
};

/** Ordering options when selecting data from "eventUpdate". */
export type EventUpdateOrderBy = {
  body?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deletedByUser?: InputMaybe<UserOrderBy>;
  deletedByUserId?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventUpdateComments_aggregate?: InputMaybe<EventUpdateCommentAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventUpdate */
export type EventUpdatePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventUpdate" */
export enum EventUpdateSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "eventUpdate" */
export type EventUpdateSetInput = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deletedByUserId?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventUpdateStddevFields = {
  __typename?: 'eventUpdate_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventUpdate" */
export type EventUpdateStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventUpdateStddevPopFields = {
  __typename?: 'eventUpdate_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventUpdate" */
export type EventUpdateStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventUpdateStddevSampFields = {
  __typename?: 'eventUpdate_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventUpdate" */
export type EventUpdateStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventUpdateSumFields = {
  __typename?: 'eventUpdate_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventUpdate" */
export type EventUpdateSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventUpdate" */
export enum EventUpdateUpdateColumn {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedByUserId = 'deletedByUserId',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type EventUpdateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventUpdateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventUpdateSetInput>;
  where: EventUpdateBoolExp;
};

/** aggregate var_pop on columns */
export type EventUpdateVarPopFields = {
  __typename?: 'eventUpdate_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventUpdate" */
export type EventUpdateVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventUpdateVarSampFields = {
  __typename?: 'eventUpdate_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventUpdate" */
export type EventUpdateVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventUpdateVarianceFields = {
  __typename?: 'eventUpdate_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventUpdate" */
export type EventUpdateVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Many to Many intermediary table */
export type EventVenue = Node & {
  __typename?: 'eventVenue';
  additionalInfo?: Maybe<Scalars['String']>;
  campingDescription?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event?: Maybe<Event>;
  eventCuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meetupInstructions?: Maybe<Scalars['String']>;
  parkingInfo?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  venue: Venue;
  venueCuid: Scalars['String'];
};

/** A Relay connection object on "eventVenue" */
export type EventVenueConnection = {
  __typename?: 'eventVenueConnection';
  edges: Array<EventVenueEdge>;
  pageInfo: PageInfo;
};

export type EventVenueEdge = {
  __typename?: 'eventVenueEdge';
  cursor: Scalars['String'];
  node: EventVenue;
};

/** aggregated selection of "eventVenue" */
export type EventVenueAggregate = {
  __typename?: 'eventVenue_aggregate';
  aggregate?: Maybe<EventVenueAggregateFields>;
  nodes: Array<EventVenue>;
};

export type EventVenueAggregateBoolExp = {
  count?: InputMaybe<EventVenueAggregateBoolExpCount>;
};

export type EventVenueAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVenueBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVenue" */
export type EventVenueAggregateFields = {
  __typename?: 'eventVenue_aggregate_fields';
  avg?: Maybe<EventVenueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVenueMaxFields>;
  min?: Maybe<EventVenueMinFields>;
  stddev?: Maybe<EventVenueStddevFields>;
  stddev_pop?: Maybe<EventVenueStddevPopFields>;
  stddev_samp?: Maybe<EventVenueStddevSampFields>;
  sum?: Maybe<EventVenueSumFields>;
  var_pop?: Maybe<EventVenueVarPopFields>;
  var_samp?: Maybe<EventVenueVarSampFields>;
  variance?: Maybe<EventVenueVarianceFields>;
};


/** aggregate fields of "eventVenue" */
export type EventVenueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVenue" */
export type EventVenueAggregateOrderBy = {
  avg?: InputMaybe<EventVenueAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVenueMaxOrderBy>;
  min?: InputMaybe<EventVenueMinOrderBy>;
  stddev?: InputMaybe<EventVenueStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVenueStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVenueStddevSampOrderBy>;
  sum?: InputMaybe<EventVenueSumOrderBy>;
  var_pop?: InputMaybe<EventVenueVarPopOrderBy>;
  var_samp?: InputMaybe<EventVenueVarSampOrderBy>;
  variance?: InputMaybe<EventVenueVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVenue" */
export type EventVenueArrRelInsertInput = {
  data: Array<EventVenueInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVenueOnConflict>;
};

/** aggregate avg on columns */
export type EventVenueAvgFields = {
  __typename?: 'eventVenue_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVenue" */
export type EventVenueAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVenue". All fields are combined with a logical 'AND'. */
export type EventVenueBoolExp = {
  _and?: InputMaybe<Array<EventVenueBoolExp>>;
  _not?: InputMaybe<EventVenueBoolExp>;
  _or?: InputMaybe<Array<EventVenueBoolExp>>;
  additionalInfo?: InputMaybe<StringComparisonExp>;
  campingDescription?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  meetupInstructions?: InputMaybe<StringComparisonExp>;
  parkingInfo?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  venue?: InputMaybe<VenueBoolExp>;
  venueCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventVenue" */
export enum EventVenueConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVenueCuidKey = 'eventVenue_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventVenueIdKey = 'eventVenue_id_key',
  /** unique or primary key constraint on columns "id" */
  EventVenuePkey = 'eventVenue_pkey',
  /** unique or primary key constraint on columns "venueCuid" */
  EventVenueVenueCuidKey = 'eventVenue_venueCuid_key'
}

/** input type for incrementing numeric columns in table "eventVenue" */
export type EventVenueIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventVenue" */
export type EventVenueInsertInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  campingDescription?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  meetupInstructions?: InputMaybe<Scalars['String']>;
  parkingInfo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  venue?: InputMaybe<VenueObjRelInsertInput>;
  venueCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventVenueMaxFields = {
  __typename?: 'eventVenue_max_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  campingDescription?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  meetupInstructions?: Maybe<Scalars['String']>;
  parkingInfo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  venueCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventVenue" */
export type EventVenueMaxOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  campingDescription?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetupInstructions?: InputMaybe<OrderBy>;
  parkingInfo?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  venueCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVenueMinFields = {
  __typename?: 'eventVenue_min_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  campingDescription?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  meetupInstructions?: Maybe<Scalars['String']>;
  parkingInfo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  venueCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventVenue" */
export type EventVenueMinOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  campingDescription?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetupInstructions?: InputMaybe<OrderBy>;
  parkingInfo?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  venueCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVenue" */
export type EventVenueMutationResponse = {
  __typename?: 'eventVenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVenue>;
};

/** input type for inserting object relation for remote table "eventVenue" */
export type EventVenueObjRelInsertInput = {
  data: EventVenueInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVenueOnConflict>;
};

/** on_conflict condition type for table "eventVenue" */
export type EventVenueOnConflict = {
  constraint: EventVenueConstraint;
  update_columns?: Array<EventVenueUpdateColumn>;
  where?: InputMaybe<EventVenueBoolExp>;
};

/** Ordering options when selecting data from "eventVenue". */
export type EventVenueOrderBy = {
  additionalInfo?: InputMaybe<OrderBy>;
  campingDescription?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meetupInstructions?: InputMaybe<OrderBy>;
  parkingInfo?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  venue?: InputMaybe<VenueOrderBy>;
  venueCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVenue */
export type EventVenuePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "eventVenue" */
export enum EventVenueSelectColumn {
  /** column name */
  AdditionalInfo = 'additionalInfo',
  /** column name */
  CampingDescription = 'campingDescription',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetupInstructions = 'meetupInstructions',
  /** column name */
  ParkingInfo = 'parkingInfo',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VenueCuid = 'venueCuid'
}

/** input type for updating data in table "eventVenue" */
export type EventVenueSetInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  campingDescription?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  meetupInstructions?: InputMaybe<Scalars['String']>;
  parkingInfo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  venueCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventVenueStddevFields = {
  __typename?: 'eventVenue_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVenue" */
export type EventVenueStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVenueStddevPopFields = {
  __typename?: 'eventVenue_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVenue" */
export type EventVenueStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVenueStddevSampFields = {
  __typename?: 'eventVenue_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVenue" */
export type EventVenueStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVenueSumFields = {
  __typename?: 'eventVenue_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventVenue" */
export type EventVenueSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVenue" */
export enum EventVenueUpdateColumn {
  /** column name */
  AdditionalInfo = 'additionalInfo',
  /** column name */
  CampingDescription = 'campingDescription',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  MeetupInstructions = 'meetupInstructions',
  /** column name */
  ParkingInfo = 'parkingInfo',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VenueCuid = 'venueCuid'
}

export type EventVenueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVenueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVenueSetInput>;
  where: EventVenueBoolExp;
};

/** aggregate var_pop on columns */
export type EventVenueVarPopFields = {
  __typename?: 'eventVenue_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVenue" */
export type EventVenueVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVenueVarSampFields = {
  __typename?: 'eventVenue_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVenue" */
export type EventVenueVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVenueVarianceFields = {
  __typename?: 'eventVenue_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVenue" */
export type EventVenueVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** a vibe for a ride/event */
export type EventVibe = Node & {
  __typename?: 'eventVibe';
  abilityLevelRange: Scalars['jsonb'];
  averageSpeedRange: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  genders: Scalars['jsonb'];
  hasAverageSpeed: Scalars['Boolean'];
  id: Scalars['ID'];
  intensityRange: Scalars['jsonb'];
  moreInfo?: Maybe<Scalars['String']>;
  rideType?: Maybe<Scalars['String']>;
  ridingTimePerDayRange: Scalars['jsonb'];
  speedType: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** a vibe for a ride/event */
export type EventVibeAbilityLevelRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** a vibe for a ride/event */
export type EventVibeAverageSpeedRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** a vibe for a ride/event */
export type EventVibeGendersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** a vibe for a ride/event */
export type EventVibeIntensityRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** a vibe for a ride/event */
export type EventVibeRidingTimePerDayRangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "eventVibe" */
export type EventVibeConnection = {
  __typename?: 'eventVibeConnection';
  edges: Array<EventVibeEdge>;
  pageInfo: PageInfo;
};

export type EventVibeEdge = {
  __typename?: 'eventVibeEdge';
  cursor: Scalars['String'];
  node: EventVibe;
};

/** aggregated selection of "eventVibe" */
export type EventVibeAggregate = {
  __typename?: 'eventVibe_aggregate';
  aggregate?: Maybe<EventVibeAggregateFields>;
  nodes: Array<EventVibe>;
};

export type EventVibeAggregateBoolExp = {
  bool_and?: InputMaybe<EventVibeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventVibeAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventVibeAggregateBoolExpCount>;
};

export type EventVibeAggregateBoolExpBoolAnd = {
  arguments: EventVibeSelectColumnEventVibeAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVibeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventVibeAggregateBoolExpBoolOr = {
  arguments: EventVibeSelectColumnEventVibeAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVibeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventVibeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVibeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVibeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVibe" */
export type EventVibeAggregateFields = {
  __typename?: 'eventVibe_aggregate_fields';
  avg?: Maybe<EventVibeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVibeMaxFields>;
  min?: Maybe<EventVibeMinFields>;
  stddev?: Maybe<EventVibeStddevFields>;
  stddev_pop?: Maybe<EventVibeStddevPopFields>;
  stddev_samp?: Maybe<EventVibeStddevSampFields>;
  sum?: Maybe<EventVibeSumFields>;
  var_pop?: Maybe<EventVibeVarPopFields>;
  var_samp?: Maybe<EventVibeVarSampFields>;
  variance?: Maybe<EventVibeVarianceFields>;
};


/** aggregate fields of "eventVibe" */
export type EventVibeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVibeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVibe" */
export type EventVibeAggregateOrderBy = {
  avg?: InputMaybe<EventVibeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVibeMaxOrderBy>;
  min?: InputMaybe<EventVibeMinOrderBy>;
  stddev?: InputMaybe<EventVibeStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVibeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVibeStddevSampOrderBy>;
  sum?: InputMaybe<EventVibeSumOrderBy>;
  var_pop?: InputMaybe<EventVibeVarPopOrderBy>;
  var_samp?: InputMaybe<EventVibeVarSampOrderBy>;
  variance?: InputMaybe<EventVibeVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EventVibeAppendInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  averageSpeedRange?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  intensityRange?: InputMaybe<Scalars['jsonb']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "eventVibe" */
export type EventVibeArrRelInsertInput = {
  data: Array<EventVibeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVibeOnConflict>;
};

/** aggregate avg on columns */
export type EventVibeAvgFields = {
  __typename?: 'eventVibe_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVibe" */
export type EventVibeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVibe". All fields are combined with a logical 'AND'. */
export type EventVibeBoolExp = {
  _and?: InputMaybe<Array<EventVibeBoolExp>>;
  _not?: InputMaybe<EventVibeBoolExp>;
  _or?: InputMaybe<Array<EventVibeBoolExp>>;
  abilityLevelRange?: InputMaybe<JsonbComparisonExp>;
  averageSpeedRange?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  genders?: InputMaybe<JsonbComparisonExp>;
  hasAverageSpeed?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  intensityRange?: InputMaybe<JsonbComparisonExp>;
  moreInfo?: InputMaybe<StringComparisonExp>;
  rideType?: InputMaybe<StringComparisonExp>;
  ridingTimePerDayRange?: InputMaybe<JsonbComparisonExp>;
  speedType?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventVibe" */
export enum EventVibeConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVibeCuidKey = 'eventVibe_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventVibePkey = 'eventVibe_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EventVibeDeleteAtPathInput = {
  abilityLevelRange?: InputMaybe<Array<Scalars['String']>>;
  averageSpeedRange?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  intensityRange?: InputMaybe<Array<Scalars['String']>>;
  ridingTimePerDayRange?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EventVibeDeleteElemInput = {
  abilityLevelRange?: InputMaybe<Scalars['Int']>;
  averageSpeedRange?: InputMaybe<Scalars['Int']>;
  genders?: InputMaybe<Scalars['Int']>;
  intensityRange?: InputMaybe<Scalars['Int']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EventVibeDeleteKeyInput = {
  abilityLevelRange?: InputMaybe<Scalars['String']>;
  averageSpeedRange?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<Scalars['String']>;
  intensityRange?: InputMaybe<Scalars['String']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "eventVibe" */
export type EventVibeIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventVibe" */
export type EventVibeInsertInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  averageSpeedRange?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  hasAverageSpeed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  intensityRange?: InputMaybe<Scalars['jsonb']>;
  moreInfo?: InputMaybe<Scalars['String']>;
  rideType?: InputMaybe<Scalars['String']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['jsonb']>;
  speedType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventVibeMaxFields = {
  __typename?: 'eventVibe_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  moreInfo?: Maybe<Scalars['String']>;
  rideType?: Maybe<Scalars['String']>;
  speedType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventVibe" */
export type EventVibeMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  moreInfo?: InputMaybe<OrderBy>;
  rideType?: InputMaybe<OrderBy>;
  speedType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVibeMinFields = {
  __typename?: 'eventVibe_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  moreInfo?: Maybe<Scalars['String']>;
  rideType?: Maybe<Scalars['String']>;
  speedType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventVibe" */
export type EventVibeMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  moreInfo?: InputMaybe<OrderBy>;
  rideType?: InputMaybe<OrderBy>;
  speedType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVibe" */
export type EventVibeMutationResponse = {
  __typename?: 'eventVibe_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVibe>;
};

/** on_conflict condition type for table "eventVibe" */
export type EventVibeOnConflict = {
  constraint: EventVibeConstraint;
  update_columns?: Array<EventVibeUpdateColumn>;
  where?: InputMaybe<EventVibeBoolExp>;
};

/** Ordering options when selecting data from "eventVibe". */
export type EventVibeOrderBy = {
  abilityLevelRange?: InputMaybe<OrderBy>;
  averageSpeedRange?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  genders?: InputMaybe<OrderBy>;
  hasAverageSpeed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intensityRange?: InputMaybe<OrderBy>;
  moreInfo?: InputMaybe<OrderBy>;
  rideType?: InputMaybe<OrderBy>;
  ridingTimePerDayRange?: InputMaybe<OrderBy>;
  speedType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVibe */
export type EventVibePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EventVibePrependInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  averageSpeedRange?: InputMaybe<Scalars['jsonb']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  intensityRange?: InputMaybe<Scalars['jsonb']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "eventVibe" */
export enum EventVibeSelectColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AverageSpeedRange = 'averageSpeedRange',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Genders = 'genders',
  /** column name */
  HasAverageSpeed = 'hasAverageSpeed',
  /** column name */
  Id = 'id',
  /** column name */
  IntensityRange = 'intensityRange',
  /** column name */
  MoreInfo = 'moreInfo',
  /** column name */
  RideType = 'rideType',
  /** column name */
  RidingTimePerDayRange = 'ridingTimePerDayRange',
  /** column name */
  SpeedType = 'speedType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventVibe_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventVibe" */
export enum EventVibeSelectColumnEventVibeAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasAverageSpeed = 'hasAverageSpeed'
}

/** select "eventVibe_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventVibe" */
export enum EventVibeSelectColumnEventVibeAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasAverageSpeed = 'hasAverageSpeed'
}

/** input type for updating data in table "eventVibe" */
export type EventVibeSetInput = {
  abilityLevelRange?: InputMaybe<Scalars['jsonb']>;
  averageSpeedRange?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  genders?: InputMaybe<Scalars['jsonb']>;
  hasAverageSpeed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  intensityRange?: InputMaybe<Scalars['jsonb']>;
  moreInfo?: InputMaybe<Scalars['String']>;
  rideType?: InputMaybe<Scalars['String']>;
  ridingTimePerDayRange?: InputMaybe<Scalars['jsonb']>;
  speedType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventVibeStddevFields = {
  __typename?: 'eventVibe_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVibe" */
export type EventVibeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVibeStddevPopFields = {
  __typename?: 'eventVibe_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVibe" */
export type EventVibeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVibeStddevSampFields = {
  __typename?: 'eventVibe_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVibe" */
export type EventVibeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVibeSumFields = {
  __typename?: 'eventVibe_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventVibe" */
export type EventVibeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVibe" */
export enum EventVibeUpdateColumn {
  /** column name */
  AbilityLevelRange = 'abilityLevelRange',
  /** column name */
  AverageSpeedRange = 'averageSpeedRange',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Genders = 'genders',
  /** column name */
  HasAverageSpeed = 'hasAverageSpeed',
  /** column name */
  Id = 'id',
  /** column name */
  IntensityRange = 'intensityRange',
  /** column name */
  MoreInfo = 'moreInfo',
  /** column name */
  RideType = 'rideType',
  /** column name */
  RidingTimePerDayRange = 'ridingTimePerDayRange',
  /** column name */
  SpeedType = 'speedType',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventVibeUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventVibeAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventVibeDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventVibeDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventVibeDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVibeIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventVibePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVibeSetInput>;
  where: EventVibeBoolExp;
};

/** aggregate var_pop on columns */
export type EventVibeVarPopFields = {
  __typename?: 'eventVibe_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVibe" */
export type EventVibeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVibeVarSampFields = {
  __typename?: 'eventVibe_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVibe" */
export type EventVibeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVibeVarianceFields = {
  __typename?: 'eventVibe_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVibe" */
export type EventVibeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Virtual Event Venue */
export type EventVirtualVenue = Node & {
  __typename?: 'eventVirtualVenue';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventVirtualVenue" */
export type EventVirtualVenueConnection = {
  __typename?: 'eventVirtualVenueConnection';
  edges: Array<EventVirtualVenueEdge>;
  pageInfo: PageInfo;
};

export type EventVirtualVenueEdge = {
  __typename?: 'eventVirtualVenueEdge';
  cursor: Scalars['String'];
  node: EventVirtualVenue;
};

/** aggregated selection of "eventVirtualVenue" */
export type EventVirtualVenueAggregate = {
  __typename?: 'eventVirtualVenue_aggregate';
  aggregate?: Maybe<EventVirtualVenueAggregateFields>;
  nodes: Array<EventVirtualVenue>;
};

export type EventVirtualVenueAggregateBoolExp = {
  count?: InputMaybe<EventVirtualVenueAggregateBoolExpCount>;
};

export type EventVirtualVenueAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVirtualVenueBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVirtualVenue" */
export type EventVirtualVenueAggregateFields = {
  __typename?: 'eventVirtualVenue_aggregate_fields';
  avg?: Maybe<EventVirtualVenueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVirtualVenueMaxFields>;
  min?: Maybe<EventVirtualVenueMinFields>;
  stddev?: Maybe<EventVirtualVenueStddevFields>;
  stddev_pop?: Maybe<EventVirtualVenueStddevPopFields>;
  stddev_samp?: Maybe<EventVirtualVenueStddevSampFields>;
  sum?: Maybe<EventVirtualVenueSumFields>;
  var_pop?: Maybe<EventVirtualVenueVarPopFields>;
  var_samp?: Maybe<EventVirtualVenueVarSampFields>;
  variance?: Maybe<EventVirtualVenueVarianceFields>;
};


/** aggregate fields of "eventVirtualVenue" */
export type EventVirtualVenueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVirtualVenue" */
export type EventVirtualVenueAggregateOrderBy = {
  avg?: InputMaybe<EventVirtualVenueAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVirtualVenueMaxOrderBy>;
  min?: InputMaybe<EventVirtualVenueMinOrderBy>;
  stddev?: InputMaybe<EventVirtualVenueStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVirtualVenueStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVirtualVenueStddevSampOrderBy>;
  sum?: InputMaybe<EventVirtualVenueSumOrderBy>;
  var_pop?: InputMaybe<EventVirtualVenueVarPopOrderBy>;
  var_samp?: InputMaybe<EventVirtualVenueVarSampOrderBy>;
  variance?: InputMaybe<EventVirtualVenueVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVirtualVenue" */
export type EventVirtualVenueArrRelInsertInput = {
  data: Array<EventVirtualVenueInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVirtualVenueOnConflict>;
};

/** aggregate avg on columns */
export type EventVirtualVenueAvgFields = {
  __typename?: 'eventVirtualVenue_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVirtualVenue". All fields are combined with a logical 'AND'. */
export type EventVirtualVenueBoolExp = {
  _and?: InputMaybe<Array<EventVirtualVenueBoolExp>>;
  _not?: InputMaybe<EventVirtualVenueBoolExp>;
  _or?: InputMaybe<Array<EventVirtualVenueBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventVirtualVenue" */
export enum EventVirtualVenueConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVirtualVenueCuidKey = 'eventVirtualVenue_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventVirtualVenuePkey = 'eventVirtualVenue_pkey'
}

/** input type for incrementing numeric columns in table "eventVirtualVenue" */
export type EventVirtualVenueIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventVirtualVenue" */
export type EventVirtualVenueInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventVirtualVenueMaxFields = {
  __typename?: 'eventVirtualVenue_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVirtualVenueMinFields = {
  __typename?: 'eventVirtualVenue_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVirtualVenue" */
export type EventVirtualVenueMutationResponse = {
  __typename?: 'eventVirtualVenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVirtualVenue>;
};

/** on_conflict condition type for table "eventVirtualVenue" */
export type EventVirtualVenueOnConflict = {
  constraint: EventVirtualVenueConstraint;
  update_columns?: Array<EventVirtualVenueUpdateColumn>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};

/** Ordering options when selecting data from "eventVirtualVenue". */
export type EventVirtualVenueOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVirtualVenue */
export type EventVirtualVenuePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "eventVirtualVenue" */
export enum EventVirtualVenueSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventVirtualVenue" */
export type EventVirtualVenueSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventVirtualVenueStddevFields = {
  __typename?: 'eventVirtualVenue_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVirtualVenueStddevPopFields = {
  __typename?: 'eventVirtualVenue_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVirtualVenueStddevSampFields = {
  __typename?: 'eventVirtualVenue_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVirtualVenueSumFields = {
  __typename?: 'eventVirtualVenue_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVirtualVenue" */
export enum EventVirtualVenueUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventVirtualVenueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVirtualVenueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVirtualVenueSetInput>;
  where: EventVirtualVenueBoolExp;
};

/** aggregate var_pop on columns */
export type EventVirtualVenueVarPopFields = {
  __typename?: 'eventVirtualVenue_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVirtualVenueVarSampFields = {
  __typename?: 'eventVirtualVenue_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVirtualVenueVarianceFields = {
  __typename?: 'eventVirtualVenue_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVirtualVenue" */
export type EventVirtualVenueVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Volunteer job for an event */
export type EventVolunteerJob = Node & {
  __typename?: 'eventVolunteerJob';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An array relationship */
  eventVolunteerJobShifts: Array<EventVolunteerJobShift>;
  /** An aggregate relationship */
  eventVolunteerJobShifts_aggregate: EventVolunteerJobShiftAggregate;
  /** An array relationship connection */
  eventVolunteerJobShifts_connection: EventVolunteerJobShiftConnection;
  /** An array relationship */
  eventVolunteerJobTasks: Array<EventVolunteerJobTask>;
  /** An aggregate relationship */
  eventVolunteerJobTasks_aggregate: EventVolunteerJobTaskAggregate;
  /** An array relationship connection */
  eventVolunteerJobTasks_connection: EventVolunteerJobTaskConnection;
  id: Scalars['ID'];
  locationInfo?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobShiftsArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobShiftsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobShiftsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobTasksArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** Volunteer job for an event */
export type EventVolunteerJobEventVolunteerJobTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};

/** A Relay connection object on "eventVolunteerJob" */
export type EventVolunteerJobConnection = {
  __typename?: 'eventVolunteerJobConnection';
  edges: Array<EventVolunteerJobEdge>;
  pageInfo: PageInfo;
};

export type EventVolunteerJobEdge = {
  __typename?: 'eventVolunteerJobEdge';
  cursor: Scalars['String'];
  node: EventVolunteerJob;
};

/** Shift for a Volunteer Job */
export type EventVolunteerJobShift = Node & {
  __typename?: 'eventVolunteerJobShift';
  /** An object relationship */
  AccessName: AccessName;
  allowWaitlist: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  endDate: Scalars['timestamptz'];
  /** An object relationship */
  eventVolunteerJob: EventVolunteerJob;
  eventVolunteerJobCuid: Scalars['String'];
  /** An array relationship */
  eventVolunteerJobShiftTasks: Array<EventVolunteerJobShiftTask>;
  /** An aggregate relationship */
  eventVolunteerJobShiftTasks_aggregate: EventVolunteerJobShiftTaskAggregate;
  /** An array relationship connection */
  eventVolunteerJobShiftTasks_connection: EventVolunteerJobShiftTaskConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  shiftAccess: AccessNameEnum;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  startDate: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};


/** Shift for a Volunteer Job */
export type EventVolunteerJobShiftEventVolunteerJobShiftTasksArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


/** Shift for a Volunteer Job */
export type EventVolunteerJobShiftEventVolunteerJobShiftTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


/** Shift for a Volunteer Job */
export type EventVolunteerJobShiftEventVolunteerJobShiftTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};

/** A Relay connection object on "eventVolunteerJobShift" */
export type EventVolunteerJobShiftConnection = {
  __typename?: 'eventVolunteerJobShiftConnection';
  edges: Array<EventVolunteerJobShiftEdge>;
  pageInfo: PageInfo;
};

export type EventVolunteerJobShiftEdge = {
  __typename?: 'eventVolunteerJobShiftEdge';
  cursor: Scalars['String'];
  node: EventVolunteerJobShift;
};

/** Task for a Volunteers shift */
export type EventVolunteerJobShiftTask = Node & {
  __typename?: 'eventVolunteerJobShiftTask';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventVolunteerJobShift: EventVolunteerJobShift;
  eventVolunteerJobShiftCuid: Scalars['String'];
  /** An object relationship */
  eventVolunteerJobTask: EventVolunteerJobTask;
  eventVolunteerJobTaskCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskConnection = {
  __typename?: 'eventVolunteerJobShiftTaskConnection';
  edges: Array<EventVolunteerJobShiftTaskEdge>;
  pageInfo: PageInfo;
};

export type EventVolunteerJobShiftTaskEdge = {
  __typename?: 'eventVolunteerJobShiftTaskEdge';
  cursor: Scalars['String'];
  node: EventVolunteerJobShiftTask;
};

/** aggregated selection of "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskAggregate = {
  __typename?: 'eventVolunteerJobShiftTask_aggregate';
  aggregate?: Maybe<EventVolunteerJobShiftTaskAggregateFields>;
  nodes: Array<EventVolunteerJobShiftTask>;
};

export type EventVolunteerJobShiftTaskAggregateBoolExp = {
  count?: InputMaybe<EventVolunteerJobShiftTaskAggregateBoolExpCount>;
};

export type EventVolunteerJobShiftTaskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskAggregateFields = {
  __typename?: 'eventVolunteerJobShiftTask_aggregate_fields';
  avg?: Maybe<EventVolunteerJobShiftTaskAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVolunteerJobShiftTaskMaxFields>;
  min?: Maybe<EventVolunteerJobShiftTaskMinFields>;
  stddev?: Maybe<EventVolunteerJobShiftTaskStddevFields>;
  stddev_pop?: Maybe<EventVolunteerJobShiftTaskStddevPopFields>;
  stddev_samp?: Maybe<EventVolunteerJobShiftTaskStddevSampFields>;
  sum?: Maybe<EventVolunteerJobShiftTaskSumFields>;
  var_pop?: Maybe<EventVolunteerJobShiftTaskVarPopFields>;
  var_samp?: Maybe<EventVolunteerJobShiftTaskVarSampFields>;
  variance?: Maybe<EventVolunteerJobShiftTaskVarianceFields>;
};


/** aggregate fields of "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskAggregateOrderBy = {
  avg?: InputMaybe<EventVolunteerJobShiftTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVolunteerJobShiftTaskMaxOrderBy>;
  min?: InputMaybe<EventVolunteerJobShiftTaskMinOrderBy>;
  stddev?: InputMaybe<EventVolunteerJobShiftTaskStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVolunteerJobShiftTaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVolunteerJobShiftTaskStddevSampOrderBy>;
  sum?: InputMaybe<EventVolunteerJobShiftTaskSumOrderBy>;
  var_pop?: InputMaybe<EventVolunteerJobShiftTaskVarPopOrderBy>;
  var_samp?: InputMaybe<EventVolunteerJobShiftTaskVarSampOrderBy>;
  variance?: InputMaybe<EventVolunteerJobShiftTaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskArrRelInsertInput = {
  data: Array<EventVolunteerJobShiftTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobShiftTaskOnConflict>;
};

/** aggregate avg on columns */
export type EventVolunteerJobShiftTaskAvgFields = {
  __typename?: 'eventVolunteerJobShiftTask_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVolunteerJobShiftTask". All fields are combined with a logical 'AND'. */
export type EventVolunteerJobShiftTaskBoolExp = {
  _and?: InputMaybe<Array<EventVolunteerJobShiftTaskBoolExp>>;
  _not?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
  _or?: InputMaybe<Array<EventVolunteerJobShiftTaskBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobShift?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  eventVolunteerJobShiftCuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobTask?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  eventVolunteerJobTaskCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventVolunteerJobShiftTask" */
export enum EventVolunteerJobShiftTaskConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVolunteerJobShiftTaskPkey = 'eventVolunteerJobShiftTask_pkey'
}

/** input type for incrementing numeric columns in table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobShift?: InputMaybe<EventVolunteerJobShiftObjRelInsertInput>;
  eventVolunteerJobShiftCuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobTask?: InputMaybe<EventVolunteerJobTaskObjRelInsertInput>;
  eventVolunteerJobTaskCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventVolunteerJobShiftTaskMaxFields = {
  __typename?: 'eventVolunteerJobShiftTask_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventVolunteerJobShiftCuid?: Maybe<Scalars['String']>;
  eventVolunteerJobTaskCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShiftCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobTaskCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVolunteerJobShiftTaskMinFields = {
  __typename?: 'eventVolunteerJobShiftTask_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventVolunteerJobShiftCuid?: Maybe<Scalars['String']>;
  eventVolunteerJobTaskCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShiftCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobTaskCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskMutationResponse = {
  __typename?: 'eventVolunteerJobShiftTask_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVolunteerJobShiftTask>;
};

/** on_conflict condition type for table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskOnConflict = {
  constraint: EventVolunteerJobShiftTaskConstraint;
  update_columns?: Array<EventVolunteerJobShiftTaskUpdateColumn>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};

/** Ordering options when selecting data from "eventVolunteerJobShiftTask". */
export type EventVolunteerJobShiftTaskOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShift?: InputMaybe<EventVolunteerJobShiftOrderBy>;
  eventVolunteerJobShiftCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobTask?: InputMaybe<EventVolunteerJobTaskOrderBy>;
  eventVolunteerJobTaskCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVolunteerJobShiftTask */
export type EventVolunteerJobShiftTaskPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventVolunteerJobShiftTask" */
export enum EventVolunteerJobShiftTaskSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventVolunteerJobShiftCuid = 'eventVolunteerJobShiftCuid',
  /** column name */
  EventVolunteerJobTaskCuid = 'eventVolunteerJobTaskCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobShiftCuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobTaskCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventVolunteerJobShiftTaskStddevFields = {
  __typename?: 'eventVolunteerJobShiftTask_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVolunteerJobShiftTaskStddevPopFields = {
  __typename?: 'eventVolunteerJobShiftTask_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVolunteerJobShiftTaskStddevSampFields = {
  __typename?: 'eventVolunteerJobShiftTask_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVolunteerJobShiftTaskSumFields = {
  __typename?: 'eventVolunteerJobShiftTask_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVolunteerJobShiftTask" */
export enum EventVolunteerJobShiftTaskUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventVolunteerJobShiftCuid = 'eventVolunteerJobShiftCuid',
  /** column name */
  EventVolunteerJobTaskCuid = 'eventVolunteerJobTaskCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventVolunteerJobShiftTaskUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVolunteerJobShiftTaskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVolunteerJobShiftTaskSetInput>;
  where: EventVolunteerJobShiftTaskBoolExp;
};

/** aggregate var_pop on columns */
export type EventVolunteerJobShiftTaskVarPopFields = {
  __typename?: 'eventVolunteerJobShiftTask_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVolunteerJobShiftTaskVarSampFields = {
  __typename?: 'eventVolunteerJobShiftTask_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVolunteerJobShiftTaskVarianceFields = {
  __typename?: 'eventVolunteerJobShiftTask_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVolunteerJobShiftTask" */
export type EventVolunteerJobShiftTaskVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "eventVolunteerJobShift" */
export type EventVolunteerJobShiftAggregate = {
  __typename?: 'eventVolunteerJobShift_aggregate';
  aggregate?: Maybe<EventVolunteerJobShiftAggregateFields>;
  nodes: Array<EventVolunteerJobShift>;
};

export type EventVolunteerJobShiftAggregateBoolExp = {
  bool_and?: InputMaybe<EventVolunteerJobShiftAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventVolunteerJobShiftAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventVolunteerJobShiftAggregateBoolExpCount>;
};

export type EventVolunteerJobShiftAggregateBoolExpBoolAnd = {
  arguments: EventVolunteerJobShiftSelectColumnEventVolunteerJobShiftAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventVolunteerJobShiftAggregateBoolExpBoolOr = {
  arguments: EventVolunteerJobShiftSelectColumnEventVolunteerJobShiftAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventVolunteerJobShiftAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVolunteerJobShift" */
export type EventVolunteerJobShiftAggregateFields = {
  __typename?: 'eventVolunteerJobShift_aggregate_fields';
  avg?: Maybe<EventVolunteerJobShiftAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVolunteerJobShiftMaxFields>;
  min?: Maybe<EventVolunteerJobShiftMinFields>;
  stddev?: Maybe<EventVolunteerJobShiftStddevFields>;
  stddev_pop?: Maybe<EventVolunteerJobShiftStddevPopFields>;
  stddev_samp?: Maybe<EventVolunteerJobShiftStddevSampFields>;
  sum?: Maybe<EventVolunteerJobShiftSumFields>;
  var_pop?: Maybe<EventVolunteerJobShiftVarPopFields>;
  var_samp?: Maybe<EventVolunteerJobShiftVarSampFields>;
  variance?: Maybe<EventVolunteerJobShiftVarianceFields>;
};


/** aggregate fields of "eventVolunteerJobShift" */
export type EventVolunteerJobShiftAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftAggregateOrderBy = {
  avg?: InputMaybe<EventVolunteerJobShiftAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVolunteerJobShiftMaxOrderBy>;
  min?: InputMaybe<EventVolunteerJobShiftMinOrderBy>;
  stddev?: InputMaybe<EventVolunteerJobShiftStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVolunteerJobShiftStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVolunteerJobShiftStddevSampOrderBy>;
  sum?: InputMaybe<EventVolunteerJobShiftSumOrderBy>;
  var_pop?: InputMaybe<EventVolunteerJobShiftVarPopOrderBy>;
  var_samp?: InputMaybe<EventVolunteerJobShiftVarSampOrderBy>;
  variance?: InputMaybe<EventVolunteerJobShiftVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftArrRelInsertInput = {
  data: Array<EventVolunteerJobShiftInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobShiftOnConflict>;
};

/** aggregate avg on columns */
export type EventVolunteerJobShiftAvgFields = {
  __typename?: 'eventVolunteerJobShift_avg_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVolunteerJobShift". All fields are combined with a logical 'AND'. */
export type EventVolunteerJobShiftBoolExp = {
  AccessName?: InputMaybe<AccessNameBoolExp>;
  _and?: InputMaybe<Array<EventVolunteerJobShiftBoolExp>>;
  _not?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  _or?: InputMaybe<Array<EventVolunteerJobShiftBoolExp>>;
  allowWaitlist?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestamptzComparisonExp>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobBoolExp>;
  eventVolunteerJobCuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobShiftTasks?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
  eventVolunteerJobShiftTasks_aggregate?: InputMaybe<EventVolunteerJobShiftTaskAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shiftAccess?: InputMaybe<AccessNameEnumComparisonExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  spotsReserved?: InputMaybe<IntComparisonExp>;
  startDate?: InputMaybe<TimestamptzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventVolunteerJobShift" */
export enum EventVolunteerJobShiftConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVolunteerJobShiftPkey = 'eventVolunteerJobShift_pkey'
}

/** input type for incrementing numeric columns in table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftInsertInput = {
  AccessName?: InputMaybe<AccessNameObjRelInsertInput>;
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobObjRelInsertInput>;
  eventVolunteerJobCuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobShiftTasks?: InputMaybe<EventVolunteerJobShiftTaskArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  shiftAccess?: InputMaybe<AccessNameEnum>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventVolunteerJobShiftMaxFields = {
  __typename?: 'eventVolunteerJobShift_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  eventVolunteerJobCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVolunteerJobShiftMinFields = {
  __typename?: 'eventVolunteerJobShift_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  eventVolunteerJobCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftMutationResponse = {
  __typename?: 'eventVolunteerJobShift_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVolunteerJobShift>;
};

/** input type for inserting object relation for remote table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftObjRelInsertInput = {
  data: EventVolunteerJobShiftInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobShiftOnConflict>;
};

/** on_conflict condition type for table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftOnConflict = {
  constraint: EventVolunteerJobShiftConstraint;
  update_columns?: Array<EventVolunteerJobShiftUpdateColumn>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};

/** Ordering options when selecting data from "eventVolunteerJobShift". */
export type EventVolunteerJobShiftOrderBy = {
  AccessName?: InputMaybe<AccessNameOrderBy>;
  allowWaitlist?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobOrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShiftTasks_aggregate?: InputMaybe<EventVolunteerJobShiftTaskAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shiftAccess?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVolunteerJobShift */
export type EventVolunteerJobShiftPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventVolunteerJobShift" */
export enum EventVolunteerJobShiftSelectColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventVolunteerJobCuid = 'eventVolunteerJobCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShiftAccess = 'shiftAccess',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  SpotsReserved = 'spotsReserved',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "eventVolunteerJobShift_aggregate_bool_exp_bool_and_arguments_columns" columns of table "eventVolunteerJobShift" */
export enum EventVolunteerJobShiftSelectColumnEventVolunteerJobShiftAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist'
}

/** select "eventVolunteerJobShift_aggregate_bool_exp_bool_or_arguments_columns" columns of table "eventVolunteerJobShift" */
export enum EventVolunteerJobShiftSelectColumnEventVolunteerJobShiftAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  AllowWaitlist = 'allowWaitlist'
}

/** input type for updating data in table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftSetInput = {
  allowWaitlist?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  eventVolunteerJobCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  shiftAccess?: InputMaybe<AccessNameEnum>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  spotsReserved?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventVolunteerJobShiftStddevFields = {
  __typename?: 'eventVolunteerJobShift_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVolunteerJobShiftStddevPopFields = {
  __typename?: 'eventVolunteerJobShift_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVolunteerJobShiftStddevSampFields = {
  __typename?: 'eventVolunteerJobShift_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVolunteerJobShiftSumFields = {
  __typename?: 'eventVolunteerJobShift_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  spotsReserved?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVolunteerJobShift" */
export enum EventVolunteerJobShiftUpdateColumn {
  /** column name */
  AllowWaitlist = 'allowWaitlist',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventVolunteerJobCuid = 'eventVolunteerJobCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShiftAccess = 'shiftAccess',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  SpotsReserved = 'spotsReserved',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventVolunteerJobShiftUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVolunteerJobShiftIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVolunteerJobShiftSetInput>;
  where: EventVolunteerJobShiftBoolExp;
};

/** aggregate var_pop on columns */
export type EventVolunteerJobShiftVarPopFields = {
  __typename?: 'eventVolunteerJobShift_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVolunteerJobShiftVarSampFields = {
  __typename?: 'eventVolunteerJobShift_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVolunteerJobShiftVarianceFields = {
  __typename?: 'eventVolunteerJobShift_variance_fields';
  id?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
  spotsReserved?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVolunteerJobShift" */
export type EventVolunteerJobShiftVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsReserved?: InputMaybe<OrderBy>;
};

/** Task for a Volunteer Job */
export type EventVolunteerJobTask = Node & {
  __typename?: 'eventVolunteerJobTask';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description: Scalars['String'];
  /** An object relationship */
  eventVolunteerJob: EventVolunteerJob;
  eventVolunteerJobCuid: Scalars['String'];
  /** An array relationship */
  eventVolunteerJobShiftTasks: Array<EventVolunteerJobShiftTask>;
  /** An aggregate relationship */
  eventVolunteerJobShiftTasks_aggregate: EventVolunteerJobShiftTaskAggregate;
  /** An array relationship connection */
  eventVolunteerJobShiftTasks_connection: EventVolunteerJobShiftTaskConnection;
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  volunteerTaskDifficulty: VolunteerTaskDifficulties;
  volunteerTaskDifficultyCuid: Scalars['String'];
  /** An object relationship */
  volunteerTaskPriority: VolunteerTaskPriorities;
  volunteerTaskPriorityCuid: Scalars['String'];
};


/** Task for a Volunteer Job */
export type EventVolunteerJobTaskEventVolunteerJobShiftTasksArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


/** Task for a Volunteer Job */
export type EventVolunteerJobTaskEventVolunteerJobShiftTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


/** Task for a Volunteer Job */
export type EventVolunteerJobTaskEventVolunteerJobShiftTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};

/** A Relay connection object on "eventVolunteerJobTask" */
export type EventVolunteerJobTaskConnection = {
  __typename?: 'eventVolunteerJobTaskConnection';
  edges: Array<EventVolunteerJobTaskEdge>;
  pageInfo: PageInfo;
};

export type EventVolunteerJobTaskEdge = {
  __typename?: 'eventVolunteerJobTaskEdge';
  cursor: Scalars['String'];
  node: EventVolunteerJobTask;
};

/** aggregated selection of "eventVolunteerJobTask" */
export type EventVolunteerJobTaskAggregate = {
  __typename?: 'eventVolunteerJobTask_aggregate';
  aggregate?: Maybe<EventVolunteerJobTaskAggregateFields>;
  nodes: Array<EventVolunteerJobTask>;
};

export type EventVolunteerJobTaskAggregateBoolExp = {
  count?: InputMaybe<EventVolunteerJobTaskAggregateBoolExpCount>;
};

export type EventVolunteerJobTaskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVolunteerJobTask" */
export type EventVolunteerJobTaskAggregateFields = {
  __typename?: 'eventVolunteerJobTask_aggregate_fields';
  avg?: Maybe<EventVolunteerJobTaskAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVolunteerJobTaskMaxFields>;
  min?: Maybe<EventVolunteerJobTaskMinFields>;
  stddev?: Maybe<EventVolunteerJobTaskStddevFields>;
  stddev_pop?: Maybe<EventVolunteerJobTaskStddevPopFields>;
  stddev_samp?: Maybe<EventVolunteerJobTaskStddevSampFields>;
  sum?: Maybe<EventVolunteerJobTaskSumFields>;
  var_pop?: Maybe<EventVolunteerJobTaskVarPopFields>;
  var_samp?: Maybe<EventVolunteerJobTaskVarSampFields>;
  variance?: Maybe<EventVolunteerJobTaskVarianceFields>;
};


/** aggregate fields of "eventVolunteerJobTask" */
export type EventVolunteerJobTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskAggregateOrderBy = {
  avg?: InputMaybe<EventVolunteerJobTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVolunteerJobTaskMaxOrderBy>;
  min?: InputMaybe<EventVolunteerJobTaskMinOrderBy>;
  stddev?: InputMaybe<EventVolunteerJobTaskStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVolunteerJobTaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVolunteerJobTaskStddevSampOrderBy>;
  sum?: InputMaybe<EventVolunteerJobTaskSumOrderBy>;
  var_pop?: InputMaybe<EventVolunteerJobTaskVarPopOrderBy>;
  var_samp?: InputMaybe<EventVolunteerJobTaskVarSampOrderBy>;
  variance?: InputMaybe<EventVolunteerJobTaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskArrRelInsertInput = {
  data: Array<EventVolunteerJobTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobTaskOnConflict>;
};

/** aggregate avg on columns */
export type EventVolunteerJobTaskAvgFields = {
  __typename?: 'eventVolunteerJobTask_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVolunteerJobTask". All fields are combined with a logical 'AND'. */
export type EventVolunteerJobTaskBoolExp = {
  _and?: InputMaybe<Array<EventVolunteerJobTaskBoolExp>>;
  _not?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  _or?: InputMaybe<Array<EventVolunteerJobTaskBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobBoolExp>;
  eventVolunteerJobCuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobShiftTasks?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
  eventVolunteerJobShiftTasks_aggregate?: InputMaybe<EventVolunteerJobShiftTaskAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  volunteerTaskDifficulty?: InputMaybe<VolunteerTaskDifficultiesBoolExp>;
  volunteerTaskDifficultyCuid?: InputMaybe<StringComparisonExp>;
  volunteerTaskPriority?: InputMaybe<VolunteerTaskPrioritiesBoolExp>;
  volunteerTaskPriorityCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventVolunteerJobTask" */
export enum EventVolunteerJobTaskConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVolunteerJobTaskPkey = 'eventVolunteerJobTask_pkey'
}

/** input type for incrementing numeric columns in table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobObjRelInsertInput>;
  eventVolunteerJobCuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobShiftTasks?: InputMaybe<EventVolunteerJobShiftTaskArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  volunteerTaskDifficulty?: InputMaybe<VolunteerTaskDifficultiesObjRelInsertInput>;
  volunteerTaskDifficultyCuid?: InputMaybe<Scalars['String']>;
  volunteerTaskPriority?: InputMaybe<VolunteerTaskPrioritiesObjRelInsertInput>;
  volunteerTaskPriorityCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventVolunteerJobTaskMaxFields = {
  __typename?: 'eventVolunteerJobTask_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventVolunteerJobCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  volunteerTaskDifficultyCuid?: Maybe<Scalars['String']>;
  volunteerTaskPriorityCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  volunteerTaskDifficultyCuid?: InputMaybe<OrderBy>;
  volunteerTaskPriorityCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVolunteerJobTaskMinFields = {
  __typename?: 'eventVolunteerJobTask_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventVolunteerJobCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  volunteerTaskDifficultyCuid?: Maybe<Scalars['String']>;
  volunteerTaskPriorityCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  volunteerTaskDifficultyCuid?: InputMaybe<OrderBy>;
  volunteerTaskPriorityCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskMutationResponse = {
  __typename?: 'eventVolunteerJobTask_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVolunteerJobTask>;
};

/** input type for inserting object relation for remote table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskObjRelInsertInput = {
  data: EventVolunteerJobTaskInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobTaskOnConflict>;
};

/** on_conflict condition type for table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskOnConflict = {
  constraint: EventVolunteerJobTaskConstraint;
  update_columns?: Array<EventVolunteerJobTaskUpdateColumn>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};

/** Ordering options when selecting data from "eventVolunteerJobTask". */
export type EventVolunteerJobTaskOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventVolunteerJob?: InputMaybe<EventVolunteerJobOrderBy>;
  eventVolunteerJobCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShiftTasks_aggregate?: InputMaybe<EventVolunteerJobShiftTaskAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  volunteerTaskDifficulty?: InputMaybe<VolunteerTaskDifficultiesOrderBy>;
  volunteerTaskDifficultyCuid?: InputMaybe<OrderBy>;
  volunteerTaskPriority?: InputMaybe<VolunteerTaskPrioritiesOrderBy>;
  volunteerTaskPriorityCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVolunteerJobTask */
export type EventVolunteerJobTaskPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventVolunteerJobTask" */
export enum EventVolunteerJobTaskSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventVolunteerJobCuid = 'eventVolunteerJobCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VolunteerTaskDifficultyCuid = 'volunteerTaskDifficultyCuid',
  /** column name */
  VolunteerTaskPriorityCuid = 'volunteerTaskPriorityCuid'
}

/** input type for updating data in table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventVolunteerJobCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  volunteerTaskDifficultyCuid?: InputMaybe<Scalars['String']>;
  volunteerTaskPriorityCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EventVolunteerJobTaskStddevFields = {
  __typename?: 'eventVolunteerJobTask_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVolunteerJobTaskStddevPopFields = {
  __typename?: 'eventVolunteerJobTask_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVolunteerJobTaskStddevSampFields = {
  __typename?: 'eventVolunteerJobTask_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVolunteerJobTaskSumFields = {
  __typename?: 'eventVolunteerJobTask_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVolunteerJobTask" */
export enum EventVolunteerJobTaskUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  EventVolunteerJobCuid = 'eventVolunteerJobCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VolunteerTaskDifficultyCuid = 'volunteerTaskDifficultyCuid',
  /** column name */
  VolunteerTaskPriorityCuid = 'volunteerTaskPriorityCuid'
}

export type EventVolunteerJobTaskUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVolunteerJobTaskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVolunteerJobTaskSetInput>;
  where: EventVolunteerJobTaskBoolExp;
};

/** aggregate var_pop on columns */
export type EventVolunteerJobTaskVarPopFields = {
  __typename?: 'eventVolunteerJobTask_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVolunteerJobTaskVarSampFields = {
  __typename?: 'eventVolunteerJobTask_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVolunteerJobTaskVarianceFields = {
  __typename?: 'eventVolunteerJobTask_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVolunteerJobTask" */
export type EventVolunteerJobTaskVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "eventVolunteerJob" */
export type EventVolunteerJobAggregate = {
  __typename?: 'eventVolunteerJob_aggregate';
  aggregate?: Maybe<EventVolunteerJobAggregateFields>;
  nodes: Array<EventVolunteerJob>;
};

export type EventVolunteerJobAggregateBoolExp = {
  count?: InputMaybe<EventVolunteerJobAggregateBoolExpCount>;
};

export type EventVolunteerJobAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventVolunteerJobBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventVolunteerJob" */
export type EventVolunteerJobAggregateFields = {
  __typename?: 'eventVolunteerJob_aggregate_fields';
  avg?: Maybe<EventVolunteerJobAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventVolunteerJobMaxFields>;
  min?: Maybe<EventVolunteerJobMinFields>;
  stddev?: Maybe<EventVolunteerJobStddevFields>;
  stddev_pop?: Maybe<EventVolunteerJobStddevPopFields>;
  stddev_samp?: Maybe<EventVolunteerJobStddevSampFields>;
  sum?: Maybe<EventVolunteerJobSumFields>;
  var_pop?: Maybe<EventVolunteerJobVarPopFields>;
  var_samp?: Maybe<EventVolunteerJobVarSampFields>;
  variance?: Maybe<EventVolunteerJobVarianceFields>;
};


/** aggregate fields of "eventVolunteerJob" */
export type EventVolunteerJobAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventVolunteerJob" */
export type EventVolunteerJobAggregateOrderBy = {
  avg?: InputMaybe<EventVolunteerJobAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventVolunteerJobMaxOrderBy>;
  min?: InputMaybe<EventVolunteerJobMinOrderBy>;
  stddev?: InputMaybe<EventVolunteerJobStddevOrderBy>;
  stddev_pop?: InputMaybe<EventVolunteerJobStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventVolunteerJobStddevSampOrderBy>;
  sum?: InputMaybe<EventVolunteerJobSumOrderBy>;
  var_pop?: InputMaybe<EventVolunteerJobVarPopOrderBy>;
  var_samp?: InputMaybe<EventVolunteerJobVarSampOrderBy>;
  variance?: InputMaybe<EventVolunteerJobVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "eventVolunteerJob" */
export type EventVolunteerJobArrRelInsertInput = {
  data: Array<EventVolunteerJobInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobOnConflict>;
};

/** aggregate avg on columns */
export type EventVolunteerJobAvgFields = {
  __typename?: 'eventVolunteerJob_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "eventVolunteerJob". All fields are combined with a logical 'AND'. */
export type EventVolunteerJobBoolExp = {
  _and?: InputMaybe<Array<EventVolunteerJobBoolExp>>;
  _not?: InputMaybe<EventVolunteerJobBoolExp>;
  _or?: InputMaybe<Array<EventVolunteerJobBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobShifts?: InputMaybe<EventVolunteerJobShiftBoolExp>;
  eventVolunteerJobShifts_aggregate?: InputMaybe<EventVolunteerJobShiftAggregateBoolExp>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  locationInfo?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "eventVolunteerJob" */
export enum EventVolunteerJobConstraint {
  /** unique or primary key constraint on columns "cuid" */
  EventVolunteerJobPkey = 'eventVolunteerJob_pkey'
}

/** input type for incrementing numeric columns in table "eventVolunteerJob" */
export type EventVolunteerJobIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "eventVolunteerJob" */
export type EventVolunteerJobInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobShifts?: InputMaybe<EventVolunteerJobShiftArrRelInsertInput>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  locationInfo?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventVolunteerJobMaxFields = {
  __typename?: 'eventVolunteerJob_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  locationInfo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locationInfo?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventVolunteerJobMinFields = {
  __typename?: 'eventVolunteerJob_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  locationInfo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locationInfo?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventVolunteerJob" */
export type EventVolunteerJobMutationResponse = {
  __typename?: 'eventVolunteerJob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventVolunteerJob>;
};

/** input type for inserting object relation for remote table "eventVolunteerJob" */
export type EventVolunteerJobObjRelInsertInput = {
  data: EventVolunteerJobInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventVolunteerJobOnConflict>;
};

/** on_conflict condition type for table "eventVolunteerJob" */
export type EventVolunteerJobOnConflict = {
  constraint: EventVolunteerJobConstraint;
  update_columns?: Array<EventVolunteerJobUpdateColumn>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};

/** Ordering options when selecting data from "eventVolunteerJob". */
export type EventVolunteerJobOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventVolunteerJobShifts_aggregate?: InputMaybe<EventVolunteerJobShiftAggregateOrderBy>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  locationInfo?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventVolunteerJob */
export type EventVolunteerJobPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "eventVolunteerJob" */
export enum EventVolunteerJobSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  LocationInfo = 'locationInfo',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "eventVolunteerJob" */
export type EventVolunteerJobSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  locationInfo?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventVolunteerJobStddevFields = {
  __typename?: 'eventVolunteerJob_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventVolunteerJobStddevPopFields = {
  __typename?: 'eventVolunteerJob_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventVolunteerJobStddevSampFields = {
  __typename?: 'eventVolunteerJob_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventVolunteerJobSumFields = {
  __typename?: 'eventVolunteerJob_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "eventVolunteerJob" */
export enum EventVolunteerJobUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  LocationInfo = 'locationInfo',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventVolunteerJobUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventVolunteerJobIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventVolunteerJobSetInput>;
  where: EventVolunteerJobBoolExp;
};

/** aggregate var_pop on columns */
export type EventVolunteerJobVarPopFields = {
  __typename?: 'eventVolunteerJob_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVolunteerJobVarSampFields = {
  __typename?: 'eventVolunteerJob_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVolunteerJobVarianceFields = {
  __typename?: 'eventVolunteerJob_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "eventVolunteerJob" */
export type EventVolunteerJobVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "eventWaiver" */
export type EventWaiver = Node & {
  __typename?: 'eventWaiver';
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  /** An object relationship */
  eventSanction?: Maybe<EventSanction>;
  eventSanctionCuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  eventWaiverType: EventWaiverType;
  id: Scalars['ID'];
  type: EventWaiverTypeEnum;
  /** An object relationship */
  waiver?: Maybe<Waiver>;
  waiverCuid: Scalars['String'];
};

/** A Relay connection object on "eventWaiver" */
export type EventWaiverConnection = {
  __typename?: 'eventWaiverConnection';
  edges: Array<EventWaiverEdge>;
  pageInfo: PageInfo;
};

export type EventWaiverEdge = {
  __typename?: 'eventWaiverEdge';
  cursor: Scalars['String'];
  node: EventWaiver;
};

/** columns and relationships of "eventWaiverType" */
export type EventWaiverType = Node & {
  __typename?: 'eventWaiverType';
  description: Scalars['String'];
  /** An array relationship */
  eventWaivers: Array<EventWaiver>;
  /** An aggregate relationship */
  eventWaivers_aggregate: EventWaiverAggregate;
  /** An array relationship connection */
  eventWaivers_connection: EventWaiverConnection;
  id: Scalars['ID'];
  type: Scalars['String'];
};


/** columns and relationships of "eventWaiverType" */
export type EventWaiverTypeEventWaiversArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** columns and relationships of "eventWaiverType" */
export type EventWaiverTypeEventWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


/** columns and relationships of "eventWaiverType" */
export type EventWaiverTypeEventWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};

/** A Relay connection object on "eventWaiverType" */
export type EventWaiverTypeConnection = {
  __typename?: 'eventWaiverTypeConnection';
  edges: Array<EventWaiverTypeEdge>;
  pageInfo: PageInfo;
};

export type EventWaiverTypeEdge = {
  __typename?: 'eventWaiverTypeEdge';
  cursor: Scalars['String'];
  node: EventWaiverType;
};

/** Boolean expression to filter rows from the table "eventWaiverType". All fields are combined with a logical 'AND'. */
export type EventWaiverTypeBoolExp = {
  _and?: InputMaybe<Array<EventWaiverTypeBoolExp>>;
  _not?: InputMaybe<EventWaiverTypeBoolExp>;
  _or?: InputMaybe<Array<EventWaiverTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  eventWaivers?: InputMaybe<EventWaiverBoolExp>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventWaiverType" */
export enum EventWaiverTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  EventWaiverTypePkey = 'eventWaiverType_pkey'
}

export enum EventWaiverTypeEnum {
  /** Waivers associated with a sanction that only registrants participating in the appropriate sanctioned categories must complete. */
  EventSanction = 'EVENT_SANCTION',
  /** Waivers associated with the event that all registrants must complete. */
  General = 'GENERAL'
}

/** Boolean expression to compare columns of type "eventWaiverType_enum". All fields are combined with logical 'AND'. */
export type EventWaiverTypeEnumComparisonExp = {
  _eq?: InputMaybe<EventWaiverTypeEnum>;
  _in?: InputMaybe<Array<EventWaiverTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EventWaiverTypeEnum>;
  _nin?: InputMaybe<Array<EventWaiverTypeEnum>>;
};

/** input type for inserting data into table "eventWaiverType" */
export type EventWaiverTypeInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  eventWaivers?: InputMaybe<EventWaiverArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "eventWaiverType" */
export type EventWaiverTypeMutationResponse = {
  __typename?: 'eventWaiverType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventWaiverType>;
};

/** input type for inserting object relation for remote table "eventWaiverType" */
export type EventWaiverTypeObjRelInsertInput = {
  data: EventWaiverTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventWaiverTypeOnConflict>;
};

/** on_conflict condition type for table "eventWaiverType" */
export type EventWaiverTypeOnConflict = {
  constraint: EventWaiverTypeConstraint;
  update_columns?: Array<EventWaiverTypeUpdateColumn>;
  where?: InputMaybe<EventWaiverTypeBoolExp>;
};

/** Ordering options when selecting data from "eventWaiverType". */
export type EventWaiverTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventWaiverType */
export type EventWaiverTypePkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "eventWaiverType" */
export enum EventWaiverTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "eventWaiverType" */
export type EventWaiverTypeSetInput = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "eventWaiverType" */
export enum EventWaiverTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

export type EventWaiverTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventWaiverTypeSetInput>;
  where: EventWaiverTypeBoolExp;
};

/** aggregated selection of "eventWaiver" */
export type EventWaiverAggregate = {
  __typename?: 'eventWaiver_aggregate';
  aggregate?: Maybe<EventWaiverAggregateFields>;
  nodes: Array<EventWaiver>;
};

export type EventWaiverAggregateBoolExp = {
  count?: InputMaybe<EventWaiverAggregateBoolExpCount>;
};

export type EventWaiverAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventWaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventWaiverBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "eventWaiver" */
export type EventWaiverAggregateFields = {
  __typename?: 'eventWaiver_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EventWaiverMaxFields>;
  min?: Maybe<EventWaiverMinFields>;
};


/** aggregate fields of "eventWaiver" */
export type EventWaiverAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventWaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eventWaiver" */
export type EventWaiverAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventWaiverMaxOrderBy>;
  min?: InputMaybe<EventWaiverMinOrderBy>;
};

/** input type for inserting array relation for remote table "eventWaiver" */
export type EventWaiverArrRelInsertInput = {
  data: Array<EventWaiverInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventWaiverOnConflict>;
};

/** Boolean expression to filter rows from the table "eventWaiver". All fields are combined with a logical 'AND'. */
export type EventWaiverBoolExp = {
  _and?: InputMaybe<Array<EventWaiverBoolExp>>;
  _not?: InputMaybe<EventWaiverBoolExp>;
  _or?: InputMaybe<Array<EventWaiverBoolExp>>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  eventSanction?: InputMaybe<EventSanctionBoolExp>;
  eventSanctionCuid?: InputMaybe<StringComparisonExp>;
  eventWaiverType?: InputMaybe<EventWaiverTypeBoolExp>;
  type?: InputMaybe<EventWaiverTypeEnumComparisonExp>;
  waiver?: InputMaybe<WaiverBoolExp>;
  waiverCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "eventWaiver" */
export enum EventWaiverConstraint {
  /** unique or primary key constraint on columns "eventCuid", "waiverCuid" */
  EventWaiverPkey = 'eventWaiver_pkey'
}

/** input type for inserting data into table "eventWaiver" */
export type EventWaiverInsertInput = {
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSanction?: InputMaybe<EventSanctionObjRelInsertInput>;
  eventSanctionCuid?: InputMaybe<Scalars['String']>;
  eventWaiverType?: InputMaybe<EventWaiverTypeObjRelInsertInput>;
  type?: InputMaybe<EventWaiverTypeEnum>;
  waiver?: InputMaybe<WaiverObjRelInsertInput>;
  waiverCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EventWaiverMaxFields = {
  __typename?: 'eventWaiver_max_fields';
  eventCuid?: Maybe<Scalars['String']>;
  eventSanctionCuid?: Maybe<Scalars['String']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "eventWaiver" */
export type EventWaiverMaxOrderBy = {
  eventCuid?: InputMaybe<OrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventWaiverMinFields = {
  __typename?: 'eventWaiver_min_fields';
  eventCuid?: Maybe<Scalars['String']>;
  eventSanctionCuid?: Maybe<Scalars['String']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "eventWaiver" */
export type EventWaiverMinOrderBy = {
  eventCuid?: InputMaybe<OrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "eventWaiver" */
export type EventWaiverMutationResponse = {
  __typename?: 'eventWaiver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventWaiver>;
};

/** on_conflict condition type for table "eventWaiver" */
export type EventWaiverOnConflict = {
  constraint: EventWaiverConstraint;
  update_columns?: Array<EventWaiverUpdateColumn>;
  where?: InputMaybe<EventWaiverBoolExp>;
};

/** Ordering options when selecting data from "eventWaiver". */
export type EventWaiverOrderBy = {
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  eventSanction?: InputMaybe<EventSanctionOrderBy>;
  eventSanctionCuid?: InputMaybe<OrderBy>;
  eventWaiverType?: InputMaybe<EventWaiverTypeOrderBy>;
  type?: InputMaybe<OrderBy>;
  waiver?: InputMaybe<WaiverOrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: eventWaiver */
export type EventWaiverPkColumnsInput = {
  eventCuid: Scalars['String'];
  waiverCuid: Scalars['String'];
};

/** select columns of table "eventWaiver" */
export enum EventWaiverSelectColumn {
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventSanctionCuid = 'eventSanctionCuid',
  /** column name */
  Type = 'type',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

/** input type for updating data in table "eventWaiver" */
export type EventWaiverSetInput = {
  eventCuid?: InputMaybe<Scalars['String']>;
  eventSanctionCuid?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EventWaiverTypeEnum>;
  waiverCuid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "eventWaiver" */
export enum EventWaiverUpdateColumn {
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  EventSanctionCuid = 'eventSanctionCuid',
  /** column name */
  Type = 'type',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

export type EventWaiverUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventWaiverSetInput>;
  where: EventWaiverBoolExp;
};

/** aggregated selection of "event" */
export type EventAggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<EventAggregateFields>;
  nodes: Array<Event>;
};

export type EventAggregateBoolExp = {
  bool_and?: InputMaybe<EventAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EventAggregateBoolExpBoolOr>;
  count?: InputMaybe<EventAggregateBoolExpCount>;
};

export type EventAggregateBoolExpBoolAnd = {
  arguments: EventSelectColumnEventAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventAggregateBoolExpBoolOr = {
  arguments: EventSelectColumnEventAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "event" */
export type EventAggregateFields = {
  __typename?: 'event_aggregate_fields';
  avg?: Maybe<EventAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<EventMaxFields>;
  min?: Maybe<EventMinFields>;
  stddev?: Maybe<EventStddevFields>;
  stddev_pop?: Maybe<EventStddevPopFields>;
  stddev_samp?: Maybe<EventStddevSampFields>;
  sum?: Maybe<EventSumFields>;
  var_pop?: Maybe<EventVarPopFields>;
  var_samp?: Maybe<EventVarSampFields>;
  variance?: Maybe<EventVarianceFields>;
};


/** aggregate fields of "event" */
export type EventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type EventAggregateOrderBy = {
  avg?: InputMaybe<EventAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventMaxOrderBy>;
  min?: InputMaybe<EventMinOrderBy>;
  stddev?: InputMaybe<EventStddevOrderBy>;
  stddev_pop?: InputMaybe<EventStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EventStddevSampOrderBy>;
  sum?: InputMaybe<EventSumOrderBy>;
  var_pop?: InputMaybe<EventVarPopOrderBy>;
  var_samp?: InputMaybe<EventVarSampOrderBy>;
  variance?: InputMaybe<EventVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EventAppendInput = {
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event" */
export type EventArrRelInsertInput = {
  data: Array<EventInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventOnConflict>;
};

/** aggregate avg on columns */
export type EventAvgFields = {
  __typename?: 'event_avg_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event" */
export type EventAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type EventBoolExp = {
  AccessName?: InputMaybe<AccessNameBoolExp>;
  _and?: InputMaybe<Array<EventBoolExp>>;
  _not?: InputMaybe<EventBoolExp>;
  _or?: InputMaybe<Array<EventBoolExp>>;
  activity?: InputMaybe<ActivityTypeEnumComparisonExp>;
  activityType?: InputMaybe<ActivityTypeBoolExp>;
  additionalContactInfo?: InputMaybe<StringComparisonExp>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  cancellationReason?: InputMaybe<StringComparisonExp>;
  categories?: InputMaybe<CategoryBoolExp>;
  categories_aggregate?: InputMaybe<CategoryAggregateBoolExp>;
  clinics?: InputMaybe<ClinicBoolExp>;
  clinics_aggregate?: InputMaybe<ClinicAggregateBoolExp>;
  completedSections?: InputMaybe<JsonbComparisonExp>;
  courses?: InputMaybe<CourseBoolExp>;
  courses_aggregate?: InputMaybe<CourseAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  currencyCode?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  eventAccess?: InputMaybe<AccessNameEnumComparisonExp>;
  eventAdditionalInfos?: InputMaybe<EventAdditionalInfoBoolExp>;
  eventAdditionalInfos_aggregate?: InputMaybe<EventAdditionalInfoAggregateBoolExp>;
  eventAmenities?: InputMaybe<EventAmenityBoolExp>;
  eventAmenities_aggregate?: InputMaybe<EventAmenityAggregateBoolExp>;
  eventBasicWaivers?: InputMaybe<EventBasicWaiverBoolExp>;
  eventBasicWaivers_aggregate?: InputMaybe<EventBasicWaiverAggregateBoolExp>;
  eventChecklistItems?: InputMaybe<EventChecklistItemBoolExp>;
  eventChecklistItems_aggregate?: InputMaybe<EventChecklistItemAggregateBoolExp>;
  eventClinicDayDurations?: InputMaybe<EventClinicDayDurationBoolExp>;
  eventClinicDayDurations_aggregate?: InputMaybe<EventClinicDayDurationAggregateBoolExp>;
  eventCustomQuestions?: InputMaybe<EventCustomQuestionBoolExp>;
  eventCustomQuestions_aggregate?: InputMaybe<EventCustomQuestionAggregateBoolExp>;
  eventDisciplines?: InputMaybe<EventDisciplineBoolExp>;
  eventDisciplines_aggregate?: InputMaybe<EventDisciplineAggregateBoolExp>;
  eventImages?: InputMaybe<EventImageBoolExp>;
  eventImages_aggregate?: InputMaybe<EventImageAggregateBoolExp>;
  eventMerchandises?: InputMaybe<EventMerchandiseBoolExp>;
  eventMerchandises_aggregate?: InputMaybe<EventMerchandiseAggregateBoolExp>;
  eventMetadata?: InputMaybe<EventMetadataBoolExp>;
  eventMetadataCuid?: InputMaybe<StringComparisonExp>;
  eventModerationFlags?: InputMaybe<EventModerationFlagBoolExp>;
  eventModerationFlags_aggregate?: InputMaybe<EventModerationFlagAggregateBoolExp>;
  eventPitSpaces?: InputMaybe<EventPitSpaceBoolExp>;
  eventPitSpaces_aggregate?: InputMaybe<EventPitSpaceAggregateBoolExp>;
  eventPricingChanges?: InputMaybe<EventPricingChangeBoolExp>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateBoolExp>;
  eventPricingDates?: InputMaybe<EventPricingDateBoolExp>;
  eventPricingDates_aggregate?: InputMaybe<EventPricingDateAggregateBoolExp>;
  eventPricingGroups?: InputMaybe<EventPricingGroupBoolExp>;
  eventPricingGroups_aggregate?: InputMaybe<EventPricingGroupAggregateBoolExp>;
  eventPrivateLinks?: InputMaybe<EventPrivateLinkBoolExp>;
  eventPrivateLinksByEventcuid?: InputMaybe<EventPrivateLinkBoolExp>;
  eventPrivateLinksByEventcuid_aggregate?: InputMaybe<EventPrivateLinkAggregateBoolExp>;
  eventPrivateLinks_aggregate?: InputMaybe<EventPrivateLinkAggregateBoolExp>;
  eventPrograms?: InputMaybe<EventProgramBoolExp>;
  eventPrograms_aggregate?: InputMaybe<EventProgramAggregateBoolExp>;
  eventPromoCodes?: InputMaybe<EventPromoCodeBoolExp>;
  eventPromoCodes_aggregate?: InputMaybe<EventPromoCodeAggregateBoolExp>;
  eventQuestions?: InputMaybe<EventQuestionBoolExp>;
  eventQuestions_aggregate?: InputMaybe<EventQuestionAggregateBoolExp>;
  eventSanctions?: InputMaybe<EventSanctionBoolExp>;
  eventSanctions_aggregate?: InputMaybe<EventSanctionAggregateBoolExp>;
  eventScheduleDates?: InputMaybe<EventScheduleDateBoolExp>;
  eventScheduleDates_aggregate?: InputMaybe<EventScheduleDateAggregateBoolExp>;
  eventScheduleTypeCustoms?: InputMaybe<EventScheduleTypeCustomBoolExp>;
  eventScheduleTypeCustoms_aggregate?: InputMaybe<EventScheduleTypeCustomAggregateBoolExp>;
  eventScheduleTypePresets?: InputMaybe<EventScheduleTypePresetBoolExp>;
  eventScheduleTypePresets_aggregate?: InputMaybe<EventScheduleTypePresetAggregateBoolExp>;
  eventSchedules?: InputMaybe<EventScheduleBoolExp>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateBoolExp>;
  eventSeries?: InputMaybe<EventSeriesBoolExp>;
  eventSeries_aggregate?: InputMaybe<EventSeriesAggregateBoolExp>;
  eventSponsors?: InputMaybe<EventSponsorBoolExp>;
  eventSponsors_aggregate?: InputMaybe<EventSponsorAggregateBoolExp>;
  eventTickets?: InputMaybe<EventTicketBoolExp>;
  eventTickets_aggregate?: InputMaybe<EventTicketAggregateBoolExp>;
  eventUpdates?: InputMaybe<EventUpdateBoolExp>;
  eventUpdates_aggregate?: InputMaybe<EventUpdateAggregateBoolExp>;
  eventVenues?: InputMaybe<EventVenueBoolExp>;
  eventVenues_aggregate?: InputMaybe<EventVenueAggregateBoolExp>;
  eventVibes?: InputMaybe<EventVibeBoolExp>;
  eventVibes_aggregate?: InputMaybe<EventVibeAggregateBoolExp>;
  eventVirtualVenues?: InputMaybe<EventVirtualVenueBoolExp>;
  eventVirtualVenues_aggregate?: InputMaybe<EventVirtualVenueAggregateBoolExp>;
  eventVolunteerJobs?: InputMaybe<EventVolunteerJobBoolExp>;
  eventVolunteerJobs_aggregate?: InputMaybe<EventVolunteerJobAggregateBoolExp>;
  eventWaivers?: InputMaybe<EventWaiverBoolExp>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateBoolExp>;
  eventWebsite?: InputMaybe<StringComparisonExp>;
  hasDynamicPricing?: InputMaybe<BooleanComparisonExp>;
  hasGeneralWaivers?: InputMaybe<BooleanComparisonExp>;
  hasMerchandise?: InputMaybe<BooleanComparisonExp>;
  hasVolunteers?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isCourseEvent?: InputMaybe<BooleanComparisonExp>;
  isEventCreationComplete?: InputMaybe<BooleanComparisonExp>;
  isRegistrationPaused?: InputMaybe<BooleanComparisonExp>;
  isSanctionedEvent?: InputMaybe<BooleanComparisonExp>;
  isSeriesEvent?: InputMaybe<BooleanComparisonExp>;
  isSponsoredEvent?: InputMaybe<BooleanComparisonExp>;
  isTicketedEvent?: InputMaybe<BooleanComparisonExp>;
  isVirtualEvent?: InputMaybe<BooleanComparisonExp>;
  lastSavedAt?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nonCategoriedRegDeadline?: InputMaybe<IntComparisonExp>;
  occurrenceLabel?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerChannels?: InputMaybe<OrganizerChannelBoolExp>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestamptzComparisonExp>;
  refundInfo?: InputMaybe<StringComparisonExp>;
  regCloseDate?: InputMaybe<TimestampComparisonExp>;
  regOpenDate?: InputMaybe<TimestampComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  rides?: InputMaybe<RideBoolExp>;
  rides_aggregate?: InputMaybe<RideAggregateBoolExp>;
  selfEditEndDate?: InputMaybe<TimestamptzComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userSavedEvents?: InputMaybe<UserSavedEventBoolExp>;
  userSavedEvents_aggregate?: InputMaybe<UserSavedEventAggregateBoolExp>;
};

/** unique or primary key constraints on table "event" */
export enum EventConstraint {
  /** unique or primary key constraint on columns "id" */
  PkRace = 'PK_race',
  /** unique or primary key constraint on columns "cuid" */
  EventCuidKey = 'event_cuid_key',
  /** unique or primary key constraint on columns "id" */
  EventPkey = 'event_pkey',
  /** unique or primary key constraint on columns "slug", "organizerCuid" */
  EventSlugOrganizerCuidKey = 'event_slug_organizerCuid_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EventDeleteAtPathInput = {
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EventDeleteElemInput = {
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EventDeleteKeyInput = {
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event" */
export type EventIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<Scalars['Int']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "event" */
export type EventInsertInput = {
  AccessName?: InputMaybe<AccessNameObjRelInsertInput>;
  activity?: InputMaybe<ActivityTypeEnum>;
  activityType?: InputMaybe<ActivityTypeObjRelInsertInput>;
  additionalContactInfo?: InputMaybe<Scalars['String']>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<CategoryArrRelInsertInput>;
  clinics?: InputMaybe<ClinicArrRelInsertInput>;
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['jsonb']>;
  courses?: InputMaybe<CourseArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  eventAccess?: InputMaybe<AccessNameEnum>;
  eventAdditionalInfos?: InputMaybe<EventAdditionalInfoArrRelInsertInput>;
  eventAmenities?: InputMaybe<EventAmenityArrRelInsertInput>;
  eventBasicWaivers?: InputMaybe<EventBasicWaiverArrRelInsertInput>;
  eventChecklistItems?: InputMaybe<EventChecklistItemArrRelInsertInput>;
  eventClinicDayDurations?: InputMaybe<EventClinicDayDurationArrRelInsertInput>;
  eventCustomQuestions?: InputMaybe<EventCustomQuestionArrRelInsertInput>;
  eventDisciplines?: InputMaybe<EventDisciplineArrRelInsertInput>;
  eventImages?: InputMaybe<EventImageArrRelInsertInput>;
  eventMerchandises?: InputMaybe<EventMerchandiseArrRelInsertInput>;
  eventMetadata?: InputMaybe<EventMetadataObjRelInsertInput>;
  eventMetadataCuid?: InputMaybe<Scalars['String']>;
  eventModerationFlags?: InputMaybe<EventModerationFlagArrRelInsertInput>;
  eventPitSpaces?: InputMaybe<EventPitSpaceArrRelInsertInput>;
  eventPricingChanges?: InputMaybe<EventPricingChangeArrRelInsertInput>;
  eventPricingDates?: InputMaybe<EventPricingDateArrRelInsertInput>;
  eventPricingGroups?: InputMaybe<EventPricingGroupArrRelInsertInput>;
  eventPrivateLinks?: InputMaybe<EventPrivateLinkArrRelInsertInput>;
  eventPrivateLinksByEventcuid?: InputMaybe<EventPrivateLinkArrRelInsertInput>;
  eventPrograms?: InputMaybe<EventProgramArrRelInsertInput>;
  eventPromoCodes?: InputMaybe<EventPromoCodeArrRelInsertInput>;
  eventQuestions?: InputMaybe<EventQuestionArrRelInsertInput>;
  eventSanctions?: InputMaybe<EventSanctionArrRelInsertInput>;
  eventScheduleDates?: InputMaybe<EventScheduleDateArrRelInsertInput>;
  eventScheduleTypeCustoms?: InputMaybe<EventScheduleTypeCustomArrRelInsertInput>;
  eventScheduleTypePresets?: InputMaybe<EventScheduleTypePresetArrRelInsertInput>;
  eventSchedules?: InputMaybe<EventScheduleArrRelInsertInput>;
  eventSeries?: InputMaybe<EventSeriesArrRelInsertInput>;
  eventSponsors?: InputMaybe<EventSponsorArrRelInsertInput>;
  eventTickets?: InputMaybe<EventTicketArrRelInsertInput>;
  eventUpdates?: InputMaybe<EventUpdateArrRelInsertInput>;
  eventVenues?: InputMaybe<EventVenueArrRelInsertInput>;
  eventVibes?: InputMaybe<EventVibeArrRelInsertInput>;
  eventVirtualVenues?: InputMaybe<EventVirtualVenueArrRelInsertInput>;
  eventVolunteerJobs?: InputMaybe<EventVolunteerJobArrRelInsertInput>;
  eventWaivers?: InputMaybe<EventWaiverArrRelInsertInput>;
  eventWebsite?: InputMaybe<Scalars['String']>;
  hasDynamicPricing?: InputMaybe<Scalars['Boolean']>;
  hasGeneralWaivers?: InputMaybe<Scalars['Boolean']>;
  hasMerchandise?: InputMaybe<Scalars['Boolean']>;
  hasVolunteers?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isCourseEvent?: InputMaybe<Scalars['Boolean']>;
  isEventCreationComplete?: InputMaybe<Scalars['Boolean']>;
  isRegistrationPaused?: InputMaybe<Scalars['Boolean']>;
  isSanctionedEvent?: InputMaybe<Scalars['Boolean']>;
  isSeriesEvent?: InputMaybe<Scalars['Boolean']>;
  isSponsoredEvent?: InputMaybe<Scalars['Boolean']>;
  isTicketedEvent?: InputMaybe<Scalars['Boolean']>;
  isVirtualEvent?: InputMaybe<Scalars['Boolean']>;
  lastSavedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<Scalars['Int']>;
  occurrenceLabel?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerChannels?: InputMaybe<OrganizerChannelArrRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  refundInfo?: InputMaybe<Scalars['String']>;
  regCloseDate?: InputMaybe<Scalars['timestamp']>;
  regOpenDate?: InputMaybe<Scalars['timestamp']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  rides?: InputMaybe<RideArrRelInsertInput>;
  selfEditEndDate?: InputMaybe<Scalars['timestamptz']>;
  slug?: InputMaybe<Scalars['String']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userSavedEvents?: InputMaybe<UserSavedEventArrRelInsertInput>;
};

/** aggregate max on columns */
export type EventMaxFields = {
  __typename?: 'event_max_fields';
  additionalContactInfo?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  eventMetadataCuid?: Maybe<Scalars['String']>;
  eventWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastSavedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Int']>;
  occurrenceLabel?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  refundInfo?: Maybe<Scalars['String']>;
  regCloseDate?: Maybe<Scalars['timestamp']>;
  regOpenDate?: Maybe<Scalars['timestamp']>;
  selfEditEndDate?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event" */
export type EventMaxOrderBy = {
  additionalContactInfo?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventMetadataCuid?: InputMaybe<OrderBy>;
  eventWebsite?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  occurrenceLabel?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  refundInfo?: InputMaybe<OrderBy>;
  regCloseDate?: InputMaybe<OrderBy>;
  regOpenDate?: InputMaybe<OrderBy>;
  selfEditEndDate?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EventMinFields = {
  __typename?: 'event_min_fields';
  additionalContactInfo?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  eventMetadataCuid?: Maybe<Scalars['String']>;
  eventWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastSavedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Int']>;
  occurrenceLabel?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  refundInfo?: Maybe<Scalars['String']>;
  regCloseDate?: Maybe<Scalars['timestamp']>;
  regOpenDate?: Maybe<Scalars['timestamp']>;
  selfEditEndDate?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event" */
export type EventMinOrderBy = {
  additionalContactInfo?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventMetadataCuid?: InputMaybe<OrderBy>;
  eventWebsite?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  occurrenceLabel?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  refundInfo?: InputMaybe<OrderBy>;
  regCloseDate?: InputMaybe<OrderBy>;
  regOpenDate?: InputMaybe<OrderBy>;
  selfEditEndDate?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "event" */
export type EventMutationResponse = {
  __typename?: 'event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event>;
};

/** input type for inserting object relation for remote table "event" */
export type EventObjRelInsertInput = {
  data: EventInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventOnConflict>;
};

/** on_conflict condition type for table "event" */
export type EventOnConflict = {
  constraint: EventConstraint;
  update_columns?: Array<EventUpdateColumn>;
  where?: InputMaybe<EventBoolExp>;
};

/** Ordering options when selecting data from "event". */
export type EventOrderBy = {
  AccessName?: InputMaybe<AccessNameOrderBy>;
  activity?: InputMaybe<OrderBy>;
  activityType?: InputMaybe<ActivityTypeOrderBy>;
  additionalContactInfo?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  categories_aggregate?: InputMaybe<CategoryAggregateOrderBy>;
  clinics_aggregate?: InputMaybe<ClinicAggregateOrderBy>;
  completedSections?: InputMaybe<OrderBy>;
  courses_aggregate?: InputMaybe<CourseAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  eventAccess?: InputMaybe<OrderBy>;
  eventAdditionalInfos_aggregate?: InputMaybe<EventAdditionalInfoAggregateOrderBy>;
  eventAmenities_aggregate?: InputMaybe<EventAmenityAggregateOrderBy>;
  eventBasicWaivers_aggregate?: InputMaybe<EventBasicWaiverAggregateOrderBy>;
  eventChecklistItems_aggregate?: InputMaybe<EventChecklistItemAggregateOrderBy>;
  eventClinicDayDurations_aggregate?: InputMaybe<EventClinicDayDurationAggregateOrderBy>;
  eventCustomQuestions_aggregate?: InputMaybe<EventCustomQuestionAggregateOrderBy>;
  eventDisciplines_aggregate?: InputMaybe<EventDisciplineAggregateOrderBy>;
  eventImages_aggregate?: InputMaybe<EventImageAggregateOrderBy>;
  eventMerchandises_aggregate?: InputMaybe<EventMerchandiseAggregateOrderBy>;
  eventMetadata?: InputMaybe<EventMetadataOrderBy>;
  eventMetadataCuid?: InputMaybe<OrderBy>;
  eventModerationFlags_aggregate?: InputMaybe<EventModerationFlagAggregateOrderBy>;
  eventPitSpaces_aggregate?: InputMaybe<EventPitSpaceAggregateOrderBy>;
  eventPricingChanges_aggregate?: InputMaybe<EventPricingChangeAggregateOrderBy>;
  eventPricingDates_aggregate?: InputMaybe<EventPricingDateAggregateOrderBy>;
  eventPricingGroups_aggregate?: InputMaybe<EventPricingGroupAggregateOrderBy>;
  eventPrivateLinksByEventcuid_aggregate?: InputMaybe<EventPrivateLinkAggregateOrderBy>;
  eventPrivateLinks_aggregate?: InputMaybe<EventPrivateLinkAggregateOrderBy>;
  eventPrograms_aggregate?: InputMaybe<EventProgramAggregateOrderBy>;
  eventPromoCodes_aggregate?: InputMaybe<EventPromoCodeAggregateOrderBy>;
  eventQuestions_aggregate?: InputMaybe<EventQuestionAggregateOrderBy>;
  eventSanctions_aggregate?: InputMaybe<EventSanctionAggregateOrderBy>;
  eventScheduleDates_aggregate?: InputMaybe<EventScheduleDateAggregateOrderBy>;
  eventScheduleTypeCustoms_aggregate?: InputMaybe<EventScheduleTypeCustomAggregateOrderBy>;
  eventScheduleTypePresets_aggregate?: InputMaybe<EventScheduleTypePresetAggregateOrderBy>;
  eventSchedules_aggregate?: InputMaybe<EventScheduleAggregateOrderBy>;
  eventSeries_aggregate?: InputMaybe<EventSeriesAggregateOrderBy>;
  eventSponsors_aggregate?: InputMaybe<EventSponsorAggregateOrderBy>;
  eventTickets_aggregate?: InputMaybe<EventTicketAggregateOrderBy>;
  eventUpdates_aggregate?: InputMaybe<EventUpdateAggregateOrderBy>;
  eventVenues_aggregate?: InputMaybe<EventVenueAggregateOrderBy>;
  eventVibes_aggregate?: InputMaybe<EventVibeAggregateOrderBy>;
  eventVirtualVenues_aggregate?: InputMaybe<EventVirtualVenueAggregateOrderBy>;
  eventVolunteerJobs_aggregate?: InputMaybe<EventVolunteerJobAggregateOrderBy>;
  eventWaivers_aggregate?: InputMaybe<EventWaiverAggregateOrderBy>;
  eventWebsite?: InputMaybe<OrderBy>;
  hasDynamicPricing?: InputMaybe<OrderBy>;
  hasGeneralWaivers?: InputMaybe<OrderBy>;
  hasMerchandise?: InputMaybe<OrderBy>;
  hasVolunteers?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCourseEvent?: InputMaybe<OrderBy>;
  isEventCreationComplete?: InputMaybe<OrderBy>;
  isRegistrationPaused?: InputMaybe<OrderBy>;
  isSanctionedEvent?: InputMaybe<OrderBy>;
  isSeriesEvent?: InputMaybe<OrderBy>;
  isSponsoredEvent?: InputMaybe<OrderBy>;
  isTicketedEvent?: InputMaybe<OrderBy>;
  isVirtualEvent?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  occurrenceLabel?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  refundInfo?: InputMaybe<OrderBy>;
  regCloseDate?: InputMaybe<OrderBy>;
  regOpenDate?: InputMaybe<OrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  rides_aggregate?: InputMaybe<RideAggregateOrderBy>;
  selfEditEndDate?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userSavedEvents_aggregate?: InputMaybe<UserSavedEventAggregateOrderBy>;
};

/** primary key columns input for table: event */
export type EventPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EventPrependInput = {
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "event" */
export enum EventSelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  AdditionalContactInfo = 'additionalContactInfo',
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CompletedSections = 'completedSections',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventAccess = 'eventAccess',
  /** column name */
  EventMetadataCuid = 'eventMetadataCuid',
  /** column name */
  EventWebsite = 'eventWebsite',
  /** column name */
  HasDynamicPricing = 'hasDynamicPricing',
  /** column name */
  HasGeneralWaivers = 'hasGeneralWaivers',
  /** column name */
  HasMerchandise = 'hasMerchandise',
  /** column name */
  HasVolunteers = 'hasVolunteers',
  /** column name */
  Id = 'id',
  /** column name */
  IsCourseEvent = 'isCourseEvent',
  /** column name */
  IsEventCreationComplete = 'isEventCreationComplete',
  /** column name */
  IsRegistrationPaused = 'isRegistrationPaused',
  /** column name */
  IsSanctionedEvent = 'isSanctionedEvent',
  /** column name */
  IsSeriesEvent = 'isSeriesEvent',
  /** column name */
  IsSponsoredEvent = 'isSponsoredEvent',
  /** column name */
  IsTicketedEvent = 'isTicketedEvent',
  /** column name */
  IsVirtualEvent = 'isVirtualEvent',
  /** column name */
  LastSavedAt = 'lastSavedAt',
  /** column name */
  Name = 'name',
  /** column name */
  NonCategoriedRegDeadline = 'nonCategoriedRegDeadline',
  /** column name */
  OccurrenceLabel = 'occurrenceLabel',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Phone = 'phone',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  RefundInfo = 'refundInfo',
  /** column name */
  RegCloseDate = 'regCloseDate',
  /** column name */
  RegOpenDate = 'regOpenDate',
  /** column name */
  SelfEditEndDate = 'selfEditEndDate',
  /** column name */
  Slug = 'slug',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event" */
export enum EventSelectColumnEventAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasDynamicPricing = 'hasDynamicPricing',
  /** column name */
  HasGeneralWaivers = 'hasGeneralWaivers',
  /** column name */
  HasMerchandise = 'hasMerchandise',
  /** column name */
  HasVolunteers = 'hasVolunteers',
  /** column name */
  IsCourseEvent = 'isCourseEvent',
  /** column name */
  IsEventCreationComplete = 'isEventCreationComplete',
  /** column name */
  IsRegistrationPaused = 'isRegistrationPaused',
  /** column name */
  IsSanctionedEvent = 'isSanctionedEvent',
  /** column name */
  IsSeriesEvent = 'isSeriesEvent',
  /** column name */
  IsSponsoredEvent = 'isSponsoredEvent',
  /** column name */
  IsTicketedEvent = 'isTicketedEvent',
  /** column name */
  IsVirtualEvent = 'isVirtualEvent'
}

/** select "event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event" */
export enum EventSelectColumnEventAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasDynamicPricing = 'hasDynamicPricing',
  /** column name */
  HasGeneralWaivers = 'hasGeneralWaivers',
  /** column name */
  HasMerchandise = 'hasMerchandise',
  /** column name */
  HasVolunteers = 'hasVolunteers',
  /** column name */
  IsCourseEvent = 'isCourseEvent',
  /** column name */
  IsEventCreationComplete = 'isEventCreationComplete',
  /** column name */
  IsRegistrationPaused = 'isRegistrationPaused',
  /** column name */
  IsSanctionedEvent = 'isSanctionedEvent',
  /** column name */
  IsSeriesEvent = 'isSeriesEvent',
  /** column name */
  IsSponsoredEvent = 'isSponsoredEvent',
  /** column name */
  IsTicketedEvent = 'isTicketedEvent',
  /** column name */
  IsVirtualEvent = 'isVirtualEvent'
}

/** input type for updating data in table "event" */
export type EventSetInput = {
  activity?: InputMaybe<ActivityTypeEnum>;
  additionalContactInfo?: InputMaybe<Scalars['String']>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  /** Completed host event sections. Once a section gets completed the first time, it never gets removed */
  completedSections?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  eventAccess?: InputMaybe<AccessNameEnum>;
  eventMetadataCuid?: InputMaybe<Scalars['String']>;
  eventWebsite?: InputMaybe<Scalars['String']>;
  hasDynamicPricing?: InputMaybe<Scalars['Boolean']>;
  hasGeneralWaivers?: InputMaybe<Scalars['Boolean']>;
  hasMerchandise?: InputMaybe<Scalars['Boolean']>;
  hasVolunteers?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isCourseEvent?: InputMaybe<Scalars['Boolean']>;
  isEventCreationComplete?: InputMaybe<Scalars['Boolean']>;
  isRegistrationPaused?: InputMaybe<Scalars['Boolean']>;
  isSanctionedEvent?: InputMaybe<Scalars['Boolean']>;
  isSeriesEvent?: InputMaybe<Scalars['Boolean']>;
  isSponsoredEvent?: InputMaybe<Scalars['Boolean']>;
  isTicketedEvent?: InputMaybe<Scalars['Boolean']>;
  isVirtualEvent?: InputMaybe<Scalars['Boolean']>;
  lastSavedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<Scalars['Int']>;
  occurrenceLabel?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  refundInfo?: InputMaybe<Scalars['String']>;
  regCloseDate?: InputMaybe<Scalars['timestamp']>;
  regOpenDate?: InputMaybe<Scalars['timestamp']>;
  selfEditEndDate?: InputMaybe<Scalars['timestamptz']>;
  slug?: InputMaybe<Scalars['String']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EventStddevFields = {
  __typename?: 'event_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event" */
export type EventStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EventStddevPopFields = {
  __typename?: 'event_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event" */
export type EventStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EventStddevSampFields = {
  __typename?: 'event_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event" */
export type EventStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type EventSumFields = {
  __typename?: 'event_sum_fields';
  id?: Maybe<Scalars['Int']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Int']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "event" */
export type EventSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** update columns of table "event" */
export enum EventUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  AdditionalContactInfo = 'additionalContactInfo',
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CompletedSections = 'completedSections',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EventAccess = 'eventAccess',
  /** column name */
  EventMetadataCuid = 'eventMetadataCuid',
  /** column name */
  EventWebsite = 'eventWebsite',
  /** column name */
  HasDynamicPricing = 'hasDynamicPricing',
  /** column name */
  HasGeneralWaivers = 'hasGeneralWaivers',
  /** column name */
  HasMerchandise = 'hasMerchandise',
  /** column name */
  HasVolunteers = 'hasVolunteers',
  /** column name */
  Id = 'id',
  /** column name */
  IsCourseEvent = 'isCourseEvent',
  /** column name */
  IsEventCreationComplete = 'isEventCreationComplete',
  /** column name */
  IsRegistrationPaused = 'isRegistrationPaused',
  /** column name */
  IsSanctionedEvent = 'isSanctionedEvent',
  /** column name */
  IsSeriesEvent = 'isSeriesEvent',
  /** column name */
  IsSponsoredEvent = 'isSponsoredEvent',
  /** column name */
  IsTicketedEvent = 'isTicketedEvent',
  /** column name */
  IsVirtualEvent = 'isVirtualEvent',
  /** column name */
  LastSavedAt = 'lastSavedAt',
  /** column name */
  Name = 'name',
  /** column name */
  NonCategoriedRegDeadline = 'nonCategoriedRegDeadline',
  /** column name */
  OccurrenceLabel = 'occurrenceLabel',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Phone = 'phone',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  RefundInfo = 'refundInfo',
  /** column name */
  RegCloseDate = 'regCloseDate',
  /** column name */
  RegOpenDate = 'regOpenDate',
  /** column name */
  SelfEditEndDate = 'selfEditEndDate',
  /** column name */
  Slug = 'slug',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventSetInput>;
  where: EventBoolExp;
};

/** aggregate var_pop on columns */
export type EventVarPopFields = {
  __typename?: 'event_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event" */
export type EventVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EventVarSampFields = {
  __typename?: 'event_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event" */
export type EventVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EventVarianceFields = {
  __typename?: 'event_variance_fields';
  id?: Maybe<Scalars['Float']>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: Maybe<Scalars['Float']>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event" */
export type EventVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** How many seconds before the clinic/ride start does the registration close? */
  nonCategoriedRegDeadline?: InputMaybe<OrderBy>;
  /** This is a temp implementation of a more robust solution. We check the aggregate of this column on the client to see if our event is full. */
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type Image = Node & {
  __typename?: 'image';
  bytes?: Maybe<Scalars['Int']>;
  colors?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  eventImage?: Maybe<EventImage>;
  /** An array relationship */
  eventImages: Array<EventImage>;
  /** An aggregate relationship */
  eventImages_aggregate: EventImageAggregate;
  /** An array relationship connection */
  eventImages_connection: EventImageConnection;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** An array relationship */
  merchandiseImages: Array<MerchandiseImage>;
  /** An aggregate relationship */
  merchandiseImages_aggregate: MerchandiseImageAggregate;
  /** An array relationship connection */
  merchandiseImages_connection: MerchandiseImageConnection;
  /** An array relationship */
  merchandiseVariants: Array<MerchandiseVariant>;
  /** An aggregate relationship */
  merchandiseVariants_aggregate: MerchandiseVariantAggregate;
  /** An array relationship connection */
  merchandiseVariants_connection: MerchandiseVariantConnection;
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  /** An array relationship */
  organizers: Array<Organizer>;
  /** An aggregate relationship */
  organizers_aggregate: OrganizerAggregate;
  /** An array relationship connection */
  organizers_connection: OrganizerConnection;
  originalFilename?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  relativeUrl: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  /** An array relationship */
  seriesImages: Array<SeriesImage>;
  /** An aggregate relationship */
  seriesImages_aggregate: SeriesImageAggregate;
  /** An array relationship connection */
  seriesImages_connection: SeriesImageConnection;
  /** An array relationship */
  sponsorImages: Array<SponsorImage>;
  /** An aggregate relationship */
  sponsorImages_aggregate: SponsorImageAggregate;
  /** An array relationship connection */
  sponsorImages_connection: SponsorImageConnection;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url: Scalars['String'];
  /** An array relationship */
  userImages: Array<UserImage>;
  /** An aggregate relationship */
  userImages_aggregate: UserImageAggregate;
  /** An array relationship connection */
  userImages_connection: UserImageConnection;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: UserAggregate;
  /** An array relationship connection */
  users_connection: UserConnection;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageColorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageEventImagesArgs = {
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageEventImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageEventImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseImagesArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseVariantsArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseVariantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageMerchandiseVariantsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageOrganizersArgs = {
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageOrganizersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageOrganizersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSeriesImagesArgs = {
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSeriesImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSeriesImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSponsorImagesArgs = {
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSponsorImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageSponsorImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUserImagesArgs = {
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUserImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUserImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUsersArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** This is an Organizer image. We aren't renaming it as such because there is a lot of code to change. Images that belong to the user live in imageUser */
export type ImageUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** A Relay connection object on "image" */
export type ImageConnection = {
  __typename?: 'imageConnection';
  edges: Array<ImageEdge>;
  pageInfo: PageInfo;
};

export type ImageEdge = {
  __typename?: 'imageEdge';
  cursor: Scalars['String'];
  node: Image;
};

/** image that is owned by an online course */
export type ImageOnlineCourse = Node & {
  __typename?: 'imageOnlineCourse';
  bytes: Scalars['Int'];
  colors?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  format: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  /** An object relationship */
  onlineCourse: OnlineCourse;
  onlineCourseCuid: Scalars['String'];
  originalFilename: Scalars['String'];
  publicId: Scalars['String'];
  relativeUrl: Scalars['String'];
  resourceType: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  version: Scalars['Int'];
  width: Scalars['Int'];
};


/** image that is owned by an online course */
export type ImageOnlineCourseColorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "imageOnlineCourse" */
export type ImageOnlineCourseConnection = {
  __typename?: 'imageOnlineCourseConnection';
  edges: Array<ImageOnlineCourseEdge>;
  pageInfo: PageInfo;
};

export type ImageOnlineCourseEdge = {
  __typename?: 'imageOnlineCourseEdge';
  cursor: Scalars['String'];
  node: ImageOnlineCourse;
};

/** aggregated selection of "imageOnlineCourse" */
export type ImageOnlineCourseAggregate = {
  __typename?: 'imageOnlineCourse_aggregate';
  aggregate?: Maybe<ImageOnlineCourseAggregateFields>;
  nodes: Array<ImageOnlineCourse>;
};

export type ImageOnlineCourseAggregateBoolExp = {
  count?: InputMaybe<ImageOnlineCourseAggregateBoolExpCount>;
};

export type ImageOnlineCourseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImageOnlineCourseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "imageOnlineCourse" */
export type ImageOnlineCourseAggregateFields = {
  __typename?: 'imageOnlineCourse_aggregate_fields';
  avg?: Maybe<ImageOnlineCourseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImageOnlineCourseMaxFields>;
  min?: Maybe<ImageOnlineCourseMinFields>;
  stddev?: Maybe<ImageOnlineCourseStddevFields>;
  stddev_pop?: Maybe<ImageOnlineCourseStddevPopFields>;
  stddev_samp?: Maybe<ImageOnlineCourseStddevSampFields>;
  sum?: Maybe<ImageOnlineCourseSumFields>;
  var_pop?: Maybe<ImageOnlineCourseVarPopFields>;
  var_samp?: Maybe<ImageOnlineCourseVarSampFields>;
  variance?: Maybe<ImageOnlineCourseVarianceFields>;
};


/** aggregate fields of "imageOnlineCourse" */
export type ImageOnlineCourseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "imageOnlineCourse" */
export type ImageOnlineCourseAggregateOrderBy = {
  avg?: InputMaybe<ImageOnlineCourseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImageOnlineCourseMaxOrderBy>;
  min?: InputMaybe<ImageOnlineCourseMinOrderBy>;
  stddev?: InputMaybe<ImageOnlineCourseStddevOrderBy>;
  stddev_pop?: InputMaybe<ImageOnlineCourseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImageOnlineCourseStddevSampOrderBy>;
  sum?: InputMaybe<ImageOnlineCourseSumOrderBy>;
  var_pop?: InputMaybe<ImageOnlineCourseVarPopOrderBy>;
  var_samp?: InputMaybe<ImageOnlineCourseVarSampOrderBy>;
  variance?: InputMaybe<ImageOnlineCourseVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImageOnlineCourseAppendInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "imageOnlineCourse" */
export type ImageOnlineCourseArrRelInsertInput = {
  data: Array<ImageOnlineCourseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageOnlineCourseOnConflict>;
};

/** aggregate avg on columns */
export type ImageOnlineCourseAvgFields = {
  __typename?: 'imageOnlineCourse_avg_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseAvgOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "imageOnlineCourse". All fields are combined with a logical 'AND'. */
export type ImageOnlineCourseBoolExp = {
  _and?: InputMaybe<Array<ImageOnlineCourseBoolExp>>;
  _not?: InputMaybe<ImageOnlineCourseBoolExp>;
  _or?: InputMaybe<Array<ImageOnlineCourseBoolExp>>;
  bytes?: InputMaybe<IntComparisonExp>;
  colors?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  format?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  onlineCourse?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourseCuid?: InputMaybe<StringComparisonExp>;
  originalFilename?: InputMaybe<StringComparisonExp>;
  publicId?: InputMaybe<StringComparisonExp>;
  relativeUrl?: InputMaybe<StringComparisonExp>;
  resourceType?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "imageOnlineCourse" */
export enum ImageOnlineCourseConstraint {
  /** unique or primary key constraint on columns "id" */
  ImageOnlineCoursePkey = 'imageOnlineCourse_pkey',
  /** unique or primary key constraint on columns "publicId" */
  ImageOnlineCoursePublicIdKey = 'imageOnlineCourse_publicId_key',
  /** unique or primary key constraint on columns "relativeUrl" */
  ImageOnlineCourseRelativeUrlKey = 'imageOnlineCourse_relativeUrl_key',
  /** unique or primary key constraint on columns "version" */
  ImageOnlineCourseVersionKey = 'imageOnlineCourse_version_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImageOnlineCourseDeleteAtPathInput = {
  colors?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImageOnlineCourseDeleteElemInput = {
  colors?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImageOnlineCourseDeleteKeyInput = {
  colors?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "imageOnlineCourse" */
export type ImageOnlineCourseIncInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "imageOnlineCourse" */
export type ImageOnlineCourseInsertInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  onlineCourse?: InputMaybe<OnlineCourseObjRelInsertInput>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImageOnlineCourseMaxFields = {
  __typename?: 'imageOnlineCourse_max_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseMaxOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImageOnlineCourseMinFields = {
  __typename?: 'imageOnlineCourse_min_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseMinOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "imageOnlineCourse" */
export type ImageOnlineCourseMutationResponse = {
  __typename?: 'imageOnlineCourse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImageOnlineCourse>;
};

/** on_conflict condition type for table "imageOnlineCourse" */
export type ImageOnlineCourseOnConflict = {
  constraint: ImageOnlineCourseConstraint;
  update_columns?: Array<ImageOnlineCourseUpdateColumn>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};

/** Ordering options when selecting data from "imageOnlineCourse". */
export type ImageOnlineCourseOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  colors?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourse?: InputMaybe<OnlineCourseOrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: imageOnlineCourse */
export type ImageOnlineCoursePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImageOnlineCoursePrependInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "imageOnlineCourse" */
export enum ImageOnlineCourseSelectColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "imageOnlineCourse" */
export type ImageOnlineCourseSetInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImageOnlineCourseStddevFields = {
  __typename?: 'imageOnlineCourse_stddev_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseStddevOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImageOnlineCourseStddevPopFields = {
  __typename?: 'imageOnlineCourse_stddev_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseStddevPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImageOnlineCourseStddevSampFields = {
  __typename?: 'imageOnlineCourse_stddev_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseStddevSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ImageOnlineCourseSumFields = {
  __typename?: 'imageOnlineCourse_sum_fields';
  bytes?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseSumOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "imageOnlineCourse" */
export enum ImageOnlineCourseUpdateColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

export type ImageOnlineCourseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImageOnlineCourseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImageOnlineCourseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImageOnlineCourseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImageOnlineCourseDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImageOnlineCourseIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImageOnlineCoursePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImageOnlineCourseSetInput>;
  where: ImageOnlineCourseBoolExp;
};

/** aggregate var_pop on columns */
export type ImageOnlineCourseVarPopFields = {
  __typename?: 'imageOnlineCourse_var_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseVarPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImageOnlineCourseVarSampFields = {
  __typename?: 'imageOnlineCourse_var_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseVarSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImageOnlineCourseVarianceFields = {
  __typename?: 'imageOnlineCourse_variance_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "imageOnlineCourse" */
export type ImageOnlineCourseVarianceOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** A Cloudinary image that a user owns */
export type ImageUser = Node & {
  __typename?: 'imageUser';
  bytes: Scalars['Int'];
  /** An array relationship */
  coachImages: Array<CoachImage>;
  /** An aggregate relationship */
  coachImages_aggregate: CoachImageAggregate;
  /** An array relationship connection */
  coachImages_connection: CoachImageConnection;
  colors?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  format: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  originalFilename: Scalars['String'];
  publicId: Scalars['String'];
  relativeUrl: Scalars['String'];
  resourceType: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  version: Scalars['Int'];
  width: Scalars['Int'];
};


/** A Cloudinary image that a user owns */
export type ImageUserCoachImagesArgs = {
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** A Cloudinary image that a user owns */
export type ImageUserCoachImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** A Cloudinary image that a user owns */
export type ImageUserCoachImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


/** A Cloudinary image that a user owns */
export type ImageUserColorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "imageUser" */
export type ImageUserConnection = {
  __typename?: 'imageUserConnection';
  edges: Array<ImageUserEdge>;
  pageInfo: PageInfo;
};

export type ImageUserEdge = {
  __typename?: 'imageUserEdge';
  cursor: Scalars['String'];
  node: ImageUser;
};

/** aggregated selection of "imageUser" */
export type ImageUserAggregate = {
  __typename?: 'imageUser_aggregate';
  aggregate?: Maybe<ImageUserAggregateFields>;
  nodes: Array<ImageUser>;
};

export type ImageUserAggregateBoolExp = {
  count?: InputMaybe<ImageUserAggregateBoolExpCount>;
};

export type ImageUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImageUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImageUserBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "imageUser" */
export type ImageUserAggregateFields = {
  __typename?: 'imageUser_aggregate_fields';
  avg?: Maybe<ImageUserAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImageUserMaxFields>;
  min?: Maybe<ImageUserMinFields>;
  stddev?: Maybe<ImageUserStddevFields>;
  stddev_pop?: Maybe<ImageUserStddevPopFields>;
  stddev_samp?: Maybe<ImageUserStddevSampFields>;
  sum?: Maybe<ImageUserSumFields>;
  var_pop?: Maybe<ImageUserVarPopFields>;
  var_samp?: Maybe<ImageUserVarSampFields>;
  variance?: Maybe<ImageUserVarianceFields>;
};


/** aggregate fields of "imageUser" */
export type ImageUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "imageUser" */
export type ImageUserAggregateOrderBy = {
  avg?: InputMaybe<ImageUserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImageUserMaxOrderBy>;
  min?: InputMaybe<ImageUserMinOrderBy>;
  stddev?: InputMaybe<ImageUserStddevOrderBy>;
  stddev_pop?: InputMaybe<ImageUserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImageUserStddevSampOrderBy>;
  sum?: InputMaybe<ImageUserSumOrderBy>;
  var_pop?: InputMaybe<ImageUserVarPopOrderBy>;
  var_samp?: InputMaybe<ImageUserVarSampOrderBy>;
  variance?: InputMaybe<ImageUserVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImageUserAppendInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "imageUser" */
export type ImageUserArrRelInsertInput = {
  data: Array<ImageUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageUserOnConflict>;
};

/** aggregate avg on columns */
export type ImageUserAvgFields = {
  __typename?: 'imageUser_avg_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "imageUser" */
export type ImageUserAvgOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "imageUser". All fields are combined with a logical 'AND'. */
export type ImageUserBoolExp = {
  _and?: InputMaybe<Array<ImageUserBoolExp>>;
  _not?: InputMaybe<ImageUserBoolExp>;
  _or?: InputMaybe<Array<ImageUserBoolExp>>;
  bytes?: InputMaybe<IntComparisonExp>;
  coachImages?: InputMaybe<CoachImageBoolExp>;
  coachImages_aggregate?: InputMaybe<CoachImageAggregateBoolExp>;
  colors?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  format?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  originalFilename?: InputMaybe<StringComparisonExp>;
  publicId?: InputMaybe<StringComparisonExp>;
  relativeUrl?: InputMaybe<StringComparisonExp>;
  resourceType?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "imageUser" */
export enum ImageUserConstraint {
  /** unique or primary key constraint on columns "id" */
  ImageUserPkey = 'imageUser_pkey',
  /** unique or primary key constraint on columns "publicId" */
  ImageUserPublicIdKey = 'imageUser_publicId_key',
  /** unique or primary key constraint on columns "relativeUrl" */
  ImageUserRelativeUrlKey = 'imageUser_relativeUrl_key',
  /** unique or primary key constraint on columns "url" */
  ImageUserUrlKey = 'imageUser_url_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImageUserDeleteAtPathInput = {
  colors?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImageUserDeleteElemInput = {
  colors?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImageUserDeleteKeyInput = {
  colors?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "imageUser" */
export type ImageUserIncInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "imageUser" */
export type ImageUserInsertInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  coachImages?: InputMaybe<CoachImageArrRelInsertInput>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImageUserMaxFields = {
  __typename?: 'imageUser_max_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "imageUser" */
export type ImageUserMaxOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImageUserMinFields = {
  __typename?: 'imageUser_min_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "imageUser" */
export type ImageUserMinOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "imageUser" */
export type ImageUserMutationResponse = {
  __typename?: 'imageUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImageUser>;
};

/** input type for inserting object relation for remote table "imageUser" */
export type ImageUserObjRelInsertInput = {
  data: ImageUserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageUserOnConflict>;
};

/** on_conflict condition type for table "imageUser" */
export type ImageUserOnConflict = {
  constraint: ImageUserConstraint;
  update_columns?: Array<ImageUserUpdateColumn>;
  where?: InputMaybe<ImageUserBoolExp>;
};

/** Ordering options when selecting data from "imageUser". */
export type ImageUserOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  coachImages_aggregate?: InputMaybe<CoachImageAggregateOrderBy>;
  colors?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: imageUser */
export type ImageUserPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImageUserPrependInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "imageUser" */
export enum ImageUserSelectColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "imageUser" */
export type ImageUserSetInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImageUserStddevFields = {
  __typename?: 'imageUser_stddev_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "imageUser" */
export type ImageUserStddevOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImageUserStddevPopFields = {
  __typename?: 'imageUser_stddev_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "imageUser" */
export type ImageUserStddevPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImageUserStddevSampFields = {
  __typename?: 'imageUser_stddev_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "imageUser" */
export type ImageUserStddevSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ImageUserSumFields = {
  __typename?: 'imageUser_sum_fields';
  bytes?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "imageUser" */
export type ImageUserSumOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "imageUser" */
export enum ImageUserUpdateColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

export type ImageUserUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImageUserAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImageUserDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImageUserDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImageUserDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImageUserIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImageUserPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImageUserSetInput>;
  where: ImageUserBoolExp;
};

/** aggregate var_pop on columns */
export type ImageUserVarPopFields = {
  __typename?: 'imageUser_var_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "imageUser" */
export type ImageUserVarPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImageUserVarSampFields = {
  __typename?: 'imageUser_var_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "imageUser" */
export type ImageUserVarSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImageUserVarianceFields = {
  __typename?: 'imageUser_variance_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "imageUser" */
export type ImageUserVarianceOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregated selection of "image" */
export type ImageAggregate = {
  __typename?: 'image_aggregate';
  aggregate?: Maybe<ImageAggregateFields>;
  nodes: Array<Image>;
};

export type ImageAggregateBoolExp = {
  count?: InputMaybe<ImageAggregateBoolExpCount>;
};

export type ImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "image" */
export type ImageAggregateFields = {
  __typename?: 'image_aggregate_fields';
  avg?: Maybe<ImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImageMaxFields>;
  min?: Maybe<ImageMinFields>;
  stddev?: Maybe<ImageStddevFields>;
  stddev_pop?: Maybe<ImageStddevPopFields>;
  stddev_samp?: Maybe<ImageStddevSampFields>;
  sum?: Maybe<ImageSumFields>;
  var_pop?: Maybe<ImageVarPopFields>;
  var_samp?: Maybe<ImageVarSampFields>;
  variance?: Maybe<ImageVarianceFields>;
};


/** aggregate fields of "image" */
export type ImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "image" */
export type ImageAggregateOrderBy = {
  avg?: InputMaybe<ImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImageMaxOrderBy>;
  min?: InputMaybe<ImageMinOrderBy>;
  stddev?: InputMaybe<ImageStddevOrderBy>;
  stddev_pop?: InputMaybe<ImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImageStddevSampOrderBy>;
  sum?: InputMaybe<ImageSumOrderBy>;
  var_pop?: InputMaybe<ImageVarPopOrderBy>;
  var_samp?: InputMaybe<ImageVarSampOrderBy>;
  variance?: InputMaybe<ImageVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImageAppendInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "image" */
export type ImageArrRelInsertInput = {
  data: Array<ImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageOnConflict>;
};

/** aggregate avg on columns */
export type ImageAvgFields = {
  __typename?: 'image_avg_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "image" */
export type ImageAvgOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "image". All fields are combined with a logical 'AND'. */
export type ImageBoolExp = {
  _and?: InputMaybe<Array<ImageBoolExp>>;
  _not?: InputMaybe<ImageBoolExp>;
  _or?: InputMaybe<Array<ImageBoolExp>>;
  bytes?: InputMaybe<IntComparisonExp>;
  colors?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  eventImage?: InputMaybe<EventImageBoolExp>;
  eventImages?: InputMaybe<EventImageBoolExp>;
  eventImages_aggregate?: InputMaybe<EventImageAggregateBoolExp>;
  format?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  merchandiseImages?: InputMaybe<MerchandiseImageBoolExp>;
  merchandiseImages_aggregate?: InputMaybe<MerchandiseImageAggregateBoolExp>;
  merchandiseVariants?: InputMaybe<MerchandiseVariantBoolExp>;
  merchandiseVariants_aggregate?: InputMaybe<MerchandiseVariantAggregateBoolExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  organizers?: InputMaybe<OrganizerBoolExp>;
  organizers_aggregate?: InputMaybe<OrganizerAggregateBoolExp>;
  originalFilename?: InputMaybe<StringComparisonExp>;
  publicId?: InputMaybe<StringComparisonExp>;
  relativeUrl?: InputMaybe<StringComparisonExp>;
  resourceType?: InputMaybe<StringComparisonExp>;
  seriesImages?: InputMaybe<SeriesImageBoolExp>;
  seriesImages_aggregate?: InputMaybe<SeriesImageAggregateBoolExp>;
  sponsorImages?: InputMaybe<SponsorImageBoolExp>;
  sponsorImages_aggregate?: InputMaybe<SponsorImageAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  userImages?: InputMaybe<UserImageBoolExp>;
  userImages_aggregate?: InputMaybe<UserImageAggregateBoolExp>;
  users?: InputMaybe<UserBoolExp>;
  users_aggregate?: InputMaybe<UserAggregateBoolExp>;
  version?: InputMaybe<IntComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "image" */
export enum ImageConstraint {
  /** unique or primary key constraint on columns "url" */
  ImageImageUrlKey = 'image_imageUrl_key',
  /** unique or primary key constraint on columns "publicId" */
  ImagePkey = 'image_pkey',
  /** unique or primary key constraint on columns "publicId" */
  ImagePublicIdKey = 'image_publicId_key',
  /** unique or primary key constraint on columns "relativeUrl" */
  ImageRelativeFileKey = 'image_relativeFile_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImageDeleteAtPathInput = {
  colors?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImageDeleteElemInput = {
  colors?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImageDeleteKeyInput = {
  colors?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "image" */
export type ImageIncInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "image" */
export type ImageInsertInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eventImage?: InputMaybe<EventImageObjRelInsertInput>;
  eventImages?: InputMaybe<EventImageArrRelInsertInput>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  merchandiseImages?: InputMaybe<MerchandiseImageArrRelInsertInput>;
  merchandiseVariants?: InputMaybe<MerchandiseVariantArrRelInsertInput>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  organizers?: InputMaybe<OrganizerArrRelInsertInput>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  seriesImages?: InputMaybe<SeriesImageArrRelInsertInput>;
  sponsorImages?: InputMaybe<SponsorImageArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userImages?: InputMaybe<UserImageArrRelInsertInput>;
  users?: InputMaybe<UserArrRelInsertInput>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImageMaxFields = {
  __typename?: 'image_max_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "image" */
export type ImageMaxOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImageMinFields = {
  __typename?: 'image_min_fields';
  bytes?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  relativeUrl?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "image" */
export type ImageMinOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "image" */
export type ImageMutationResponse = {
  __typename?: 'image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Image>;
};

/** input type for inserting object relation for remote table "image" */
export type ImageObjRelInsertInput = {
  data: ImageInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageOnConflict>;
};

/** on_conflict condition type for table "image" */
export type ImageOnConflict = {
  constraint: ImageConstraint;
  update_columns?: Array<ImageUpdateColumn>;
  where?: InputMaybe<ImageBoolExp>;
};

/** Ordering options when selecting data from "image". */
export type ImageOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  colors?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  eventImage?: InputMaybe<EventImageOrderBy>;
  eventImages_aggregate?: InputMaybe<EventImageAggregateOrderBy>;
  format?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  merchandiseImages_aggregate?: InputMaybe<MerchandiseImageAggregateOrderBy>;
  merchandiseVariants_aggregate?: InputMaybe<MerchandiseVariantAggregateOrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  organizers_aggregate?: InputMaybe<OrganizerAggregateOrderBy>;
  originalFilename?: InputMaybe<OrderBy>;
  publicId?: InputMaybe<OrderBy>;
  relativeUrl?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  seriesImages_aggregate?: InputMaybe<SeriesImageAggregateOrderBy>;
  sponsorImages_aggregate?: InputMaybe<SponsorImageAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userImages_aggregate?: InputMaybe<UserImageAggregateOrderBy>;
  users_aggregate?: InputMaybe<UserAggregateOrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: image */
export type ImagePkColumnsInput = {
  publicId: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImagePrependInput = {
  colors?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "image" */
export enum ImageSelectColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "image" */
export type ImageSetInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  publicId?: InputMaybe<Scalars['String']>;
  relativeUrl?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImageStddevFields = {
  __typename?: 'image_stddev_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "image" */
export type ImageStddevOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImageStddevPopFields = {
  __typename?: 'image_stddev_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "image" */
export type ImageStddevPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImageStddevSampFields = {
  __typename?: 'image_stddev_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "image" */
export type ImageStddevSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ImageSumFields = {
  __typename?: 'image_sum_fields';
  bytes?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "image" */
export type ImageSumOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "image" */
export enum ImageUpdateColumn {
  /** column name */
  Bytes = 'bytes',
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Format = 'format',
  /** column name */
  Height = 'height',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  OriginalFilename = 'originalFilename',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  RelativeUrl = 'relativeUrl',
  /** column name */
  ResourceType = 'resourceType',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version',
  /** column name */
  Width = 'width'
}

export type ImageUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImageAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImageDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImageDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImageDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImageIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImagePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImageSetInput>;
  where: ImageBoolExp;
};

/** aggregate var_pop on columns */
export type ImageVarPopFields = {
  __typename?: 'image_var_pop_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "image" */
export type ImageVarPopOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImageVarSampFields = {
  __typename?: 'image_var_samp_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "image" */
export type ImageVarSampOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImageVarianceFields = {
  __typename?: 'image_variance_fields';
  bytes?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "image" */
export type ImageVarianceOrderBy = {
  bytes?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Preset location for a coach lesson */
export type LessonLocation = Node & {
  __typename?: 'lessonLocation';
  address: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  /** An array relationship */
  coachLessonLocations: Array<CoachLessonLocation>;
  /** An aggregate relationship */
  coachLessonLocations_aggregate: CoachLessonLocationAggregate;
  /** An array relationship connection */
  coachLessonLocations_connection: CoachLessonLocationConnection;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  googlePlaceId: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  label: Scalars['String'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  state?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zipCode?: Maybe<Scalars['String']>;
};


/** Preset location for a coach lesson */
export type LessonLocationCoachLessonLocationsArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


/** Preset location for a coach lesson */
export type LessonLocationCoachLessonLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


/** Preset location for a coach lesson */
export type LessonLocationCoachLessonLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};

/** A Relay connection object on "lessonLocation" */
export type LessonLocationConnection = {
  __typename?: 'lessonLocationConnection';
  edges: Array<LessonLocationEdge>;
  pageInfo: PageInfo;
};

export type LessonLocationEdge = {
  __typename?: 'lessonLocationEdge';
  cursor: Scalars['String'];
  node: LessonLocation;
};

/** aggregated selection of "lessonLocation" */
export type LessonLocationAggregate = {
  __typename?: 'lessonLocation_aggregate';
  aggregate?: Maybe<LessonLocationAggregateFields>;
  nodes: Array<LessonLocation>;
};

export type LessonLocationAggregateBoolExp = {
  bool_and?: InputMaybe<LessonLocationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<LessonLocationAggregateBoolExpBoolOr>;
  count?: InputMaybe<LessonLocationAggregateBoolExpCount>;
};

export type LessonLocationAggregateBoolExpBoolAnd = {
  arguments: LessonLocationSelectColumnLessonLocationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LessonLocationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LessonLocationAggregateBoolExpBoolOr = {
  arguments: LessonLocationSelectColumnLessonLocationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LessonLocationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LessonLocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LessonLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LessonLocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "lessonLocation" */
export type LessonLocationAggregateFields = {
  __typename?: 'lessonLocation_aggregate_fields';
  avg?: Maybe<LessonLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LessonLocationMaxFields>;
  min?: Maybe<LessonLocationMinFields>;
  stddev?: Maybe<LessonLocationStddevFields>;
  stddev_pop?: Maybe<LessonLocationStddevPopFields>;
  stddev_samp?: Maybe<LessonLocationStddevSampFields>;
  sum?: Maybe<LessonLocationSumFields>;
  var_pop?: Maybe<LessonLocationVarPopFields>;
  var_samp?: Maybe<LessonLocationVarSampFields>;
  variance?: Maybe<LessonLocationVarianceFields>;
};


/** aggregate fields of "lessonLocation" */
export type LessonLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LessonLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lessonLocation" */
export type LessonLocationAggregateOrderBy = {
  avg?: InputMaybe<LessonLocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LessonLocationMaxOrderBy>;
  min?: InputMaybe<LessonLocationMinOrderBy>;
  stddev?: InputMaybe<LessonLocationStddevOrderBy>;
  stddev_pop?: InputMaybe<LessonLocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LessonLocationStddevSampOrderBy>;
  sum?: InputMaybe<LessonLocationSumOrderBy>;
  var_pop?: InputMaybe<LessonLocationVarPopOrderBy>;
  var_samp?: InputMaybe<LessonLocationVarSampOrderBy>;
  variance?: InputMaybe<LessonLocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "lessonLocation" */
export type LessonLocationArrRelInsertInput = {
  data: Array<LessonLocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LessonLocationOnConflict>;
};

/** aggregate avg on columns */
export type LessonLocationAvgFields = {
  __typename?: 'lessonLocation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lessonLocation" */
export type LessonLocationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "lessonLocation". All fields are combined with a logical 'AND'. */
export type LessonLocationBoolExp = {
  _and?: InputMaybe<Array<LessonLocationBoolExp>>;
  _not?: InputMaybe<LessonLocationBoolExp>;
  _or?: InputMaybe<Array<LessonLocationBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  addressLine2?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  coachLessonLocations?: InputMaybe<CoachLessonLocationBoolExp>;
  coachLessonLocations_aggregate?: InputMaybe<CoachLessonLocationAggregateBoolExp>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "lessonLocation" */
export enum LessonLocationConstraint {
  /** unique or primary key constraint on columns "cuid" */
  LessonLocationCuidKey = 'lessonLocation_cuid_key',
  /** unique or primary key constraint on columns "googlePlaceId", "coachCuid" */
  LessonLocationGooglePlaceIdCoachCuidKey = 'lessonLocation_googlePlaceId_coachCuid_key',
  /** unique or primary key constraint on columns "id" */
  LessonLocationPkey = 'lessonLocation_pkey'
}

/** input type for incrementing numeric columns in table "lessonLocation" */
export type LessonLocationIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "lessonLocation" */
export type LessonLocationInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachLessonLocations?: InputMaybe<CoachLessonLocationArrRelInsertInput>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LessonLocationMaxFields = {
  __typename?: 'lessonLocation_max_fields';
  address?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lessonLocation" */
export type LessonLocationMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LessonLocationMinFields = {
  __typename?: 'lessonLocation_min_fields';
  address?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coachCuid?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lessonLocation" */
export type LessonLocationMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lessonLocation" */
export type LessonLocationMutationResponse = {
  __typename?: 'lessonLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LessonLocation>;
};

/** input type for inserting object relation for remote table "lessonLocation" */
export type LessonLocationObjRelInsertInput = {
  data: LessonLocationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LessonLocationOnConflict>;
};

/** on_conflict condition type for table "lessonLocation" */
export type LessonLocationOnConflict = {
  constraint: LessonLocationConstraint;
  update_columns?: Array<LessonLocationUpdateColumn>;
  where?: InputMaybe<LessonLocationBoolExp>;
};

/** Ordering options when selecting data from "lessonLocation". */
export type LessonLocationOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachLessonLocations_aggregate?: InputMaybe<CoachLessonLocationAggregateOrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lessonLocation */
export type LessonLocationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "lessonLocation" */
export enum LessonLocationSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Label = 'label',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

/** select "lessonLocation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lessonLocation" */
export enum LessonLocationSelectColumnLessonLocationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "lessonLocation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lessonLocation" */
export enum LessonLocationSelectColumnLessonLocationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "lessonLocation" */
export type LessonLocationSetInput = {
  address?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  coachCuid?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LessonLocationStddevFields = {
  __typename?: 'lessonLocation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lessonLocation" */
export type LessonLocationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LessonLocationStddevPopFields = {
  __typename?: 'lessonLocation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lessonLocation" */
export type LessonLocationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LessonLocationStddevSampFields = {
  __typename?: 'lessonLocation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lessonLocation" */
export type LessonLocationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type LessonLocationSumFields = {
  __typename?: 'lessonLocation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "lessonLocation" */
export type LessonLocationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "lessonLocation" */
export enum LessonLocationUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Label = 'label',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

export type LessonLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LessonLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LessonLocationSetInput>;
  where: LessonLocationBoolExp;
};

/** aggregate var_pop on columns */
export type LessonLocationVarPopFields = {
  __typename?: 'lessonLocation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lessonLocation" */
export type LessonLocationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LessonLocationVarSampFields = {
  __typename?: 'lessonLocation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lessonLocation" */
export type LessonLocationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LessonLocationVarianceFields = {
  __typename?: 'lessonLocation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lessonLocation" */
export type LessonLocationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** columns and relationships of "merchandise" */
export type Merchandise = Node & {
  __typename?: 'merchandise';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  deliveryType: MerchandiseDeliveryTypeEnum;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  eventMerchandises: Array<EventMerchandise>;
  /** An aggregate relationship */
  eventMerchandises_aggregate: EventMerchandiseAggregate;
  /** An array relationship connection */
  eventMerchandises_connection: EventMerchandiseConnection;
  id: Scalars['ID'];
  /** An object relationship */
  merchandiseDeliveryType: MerchandiseDeliveryType;
  /** An array relationship */
  merchandiseImages: Array<MerchandiseImage>;
  /** An aggregate relationship */
  merchandiseImages_aggregate: MerchandiseImageAggregate;
  /** An array relationship connection */
  merchandiseImages_connection: MerchandiseImageConnection;
  /** An array relationship */
  merchandiseVariantOptions: Array<MerchandiseVariantOption>;
  /** An aggregate relationship */
  merchandiseVariantOptions_aggregate: MerchandiseVariantOptionAggregate;
  /** An array relationship connection */
  merchandiseVariantOptions_connection: MerchandiseVariantOptionConnection;
  /** An array relationship */
  merchandiseVariants: Array<MerchandiseVariant>;
  /** An aggregate relationship */
  merchandiseVariants_aggregate: MerchandiseVariantAggregate;
  /** An array relationship connection */
  merchandiseVariants_connection: MerchandiseVariantConnection;
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vendorId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseEventMerchandisesArgs = {
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseEventMerchandisesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseEventMerchandisesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseImagesArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantOptionsArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantOptionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantsArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


/** columns and relationships of "merchandise" */
export type MerchandiseMerchandiseVariantsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};

/** A Relay connection object on "merchandise" */
export type MerchandiseConnection = {
  __typename?: 'merchandiseConnection';
  edges: Array<MerchandiseEdge>;
  pageInfo: PageInfo;
};

/** Delivery Type Enum Table for Merchandise */
export type MerchandiseDeliveryType = Node & {
  __typename?: 'merchandiseDeliveryType';
  id: Scalars['ID'];
  /** An array relationship */
  merchandises: Array<Merchandise>;
  /** An aggregate relationship */
  merchandises_aggregate: MerchandiseAggregate;
  /** An array relationship connection */
  merchandises_connection: MerchandiseConnection;
  name: Scalars['String'];
};


/** Delivery Type Enum Table for Merchandise */
export type MerchandiseDeliveryTypeMerchandisesArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


/** Delivery Type Enum Table for Merchandise */
export type MerchandiseDeliveryTypeMerchandisesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


/** Delivery Type Enum Table for Merchandise */
export type MerchandiseDeliveryTypeMerchandisesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};

/** A Relay connection object on "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeConnection = {
  __typename?: 'merchandiseDeliveryTypeConnection';
  edges: Array<MerchandiseDeliveryTypeEdge>;
  pageInfo: PageInfo;
};

export type MerchandiseDeliveryTypeEdge = {
  __typename?: 'merchandiseDeliveryTypeEdge';
  cursor: Scalars['String'];
  node: MerchandiseDeliveryType;
};

/** Boolean expression to filter rows from the table "merchandiseDeliveryType". All fields are combined with a logical 'AND'. */
export type MerchandiseDeliveryTypeBoolExp = {
  _and?: InputMaybe<Array<MerchandiseDeliveryTypeBoolExp>>;
  _not?: InputMaybe<MerchandiseDeliveryTypeBoolExp>;
  _or?: InputMaybe<Array<MerchandiseDeliveryTypeBoolExp>>;
  merchandises?: InputMaybe<MerchandiseBoolExp>;
  merchandises_aggregate?: InputMaybe<MerchandiseAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchandiseDeliveryType" */
export enum MerchandiseDeliveryTypeConstraint {
  /** unique or primary key constraint on columns "name" */
  MerchandiseDeliveryTypePkey = 'merchandiseDeliveryType_pkey'
}

export enum MerchandiseDeliveryTypeEnum {
  OnSite = 'OnSite',
  Shipped = 'Shipped',
  Virtual = 'Virtual'
}

/** Boolean expression to compare columns of type "merchandiseDeliveryType_enum". All fields are combined with logical 'AND'. */
export type MerchandiseDeliveryTypeEnumComparisonExp = {
  _eq?: InputMaybe<MerchandiseDeliveryTypeEnum>;
  _in?: InputMaybe<Array<MerchandiseDeliveryTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MerchandiseDeliveryTypeEnum>;
  _nin?: InputMaybe<Array<MerchandiseDeliveryTypeEnum>>;
};

/** input type for inserting data into table "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeInsertInput = {
  merchandises?: InputMaybe<MerchandiseArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeMutationResponse = {
  __typename?: 'merchandiseDeliveryType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchandiseDeliveryType>;
};

/** input type for inserting object relation for remote table "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeObjRelInsertInput = {
  data: MerchandiseDeliveryTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseDeliveryTypeOnConflict>;
};

/** on_conflict condition type for table "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeOnConflict = {
  constraint: MerchandiseDeliveryTypeConstraint;
  update_columns?: Array<MerchandiseDeliveryTypeUpdateColumn>;
  where?: InputMaybe<MerchandiseDeliveryTypeBoolExp>;
};

/** Ordering options when selecting data from "merchandiseDeliveryType". */
export type MerchandiseDeliveryTypeOrderBy = {
  merchandises_aggregate?: InputMaybe<MerchandiseAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandiseDeliveryType */
export type MerchandiseDeliveryTypePkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "merchandiseDeliveryType" */
export enum MerchandiseDeliveryTypeSelectColumn {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "merchandiseDeliveryType" */
export type MerchandiseDeliveryTypeSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merchandiseDeliveryType" */
export enum MerchandiseDeliveryTypeUpdateColumn {
  /** column name */
  Name = 'name'
}

export type MerchandiseDeliveryTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseDeliveryTypeSetInput>;
  where: MerchandiseDeliveryTypeBoolExp;
};

export type MerchandiseEdge = {
  __typename?: 'merchandiseEdge';
  cursor: Scalars['String'];
  node: Merchandise;
};

/** Image for a piece of Merchandise */
export type MerchandiseImage = Node & {
  __typename?: 'merchandiseImage';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image: Image;
  imagePublicId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  /** An object relationship */
  merchandise: Merchandise;
  merchandiseCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "merchandiseImage" */
export type MerchandiseImageConnection = {
  __typename?: 'merchandiseImageConnection';
  edges: Array<MerchandiseImageEdge>;
  pageInfo: PageInfo;
};

export type MerchandiseImageEdge = {
  __typename?: 'merchandiseImageEdge';
  cursor: Scalars['String'];
  node: MerchandiseImage;
};

/** aggregated selection of "merchandiseImage" */
export type MerchandiseImageAggregate = {
  __typename?: 'merchandiseImage_aggregate';
  aggregate?: Maybe<MerchandiseImageAggregateFields>;
  nodes: Array<MerchandiseImage>;
};

export type MerchandiseImageAggregateBoolExp = {
  bool_and?: InputMaybe<MerchandiseImageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MerchandiseImageAggregateBoolExpBoolOr>;
  count?: InputMaybe<MerchandiseImageAggregateBoolExpCount>;
};

export type MerchandiseImageAggregateBoolExpBoolAnd = {
  arguments: MerchandiseImageSelectColumnMerchandiseImageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchandiseImageAggregateBoolExpBoolOr = {
  arguments: MerchandiseImageSelectColumnMerchandiseImageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchandiseImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchandiseImage" */
export type MerchandiseImageAggregateFields = {
  __typename?: 'merchandiseImage_aggregate_fields';
  avg?: Maybe<MerchandiseImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchandiseImageMaxFields>;
  min?: Maybe<MerchandiseImageMinFields>;
  stddev?: Maybe<MerchandiseImageStddevFields>;
  stddev_pop?: Maybe<MerchandiseImageStddevPopFields>;
  stddev_samp?: Maybe<MerchandiseImageStddevSampFields>;
  sum?: Maybe<MerchandiseImageSumFields>;
  var_pop?: Maybe<MerchandiseImageVarPopFields>;
  var_samp?: Maybe<MerchandiseImageVarSampFields>;
  variance?: Maybe<MerchandiseImageVarianceFields>;
};


/** aggregate fields of "merchandiseImage" */
export type MerchandiseImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchandiseImage" */
export type MerchandiseImageAggregateOrderBy = {
  avg?: InputMaybe<MerchandiseImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchandiseImageMaxOrderBy>;
  min?: InputMaybe<MerchandiseImageMinOrderBy>;
  stddev?: InputMaybe<MerchandiseImageStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchandiseImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchandiseImageStddevSampOrderBy>;
  sum?: InputMaybe<MerchandiseImageSumOrderBy>;
  var_pop?: InputMaybe<MerchandiseImageVarPopOrderBy>;
  var_samp?: InputMaybe<MerchandiseImageVarSampOrderBy>;
  variance?: InputMaybe<MerchandiseImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchandiseImage" */
export type MerchandiseImageArrRelInsertInput = {
  data: Array<MerchandiseImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseImageOnConflict>;
};

/** aggregate avg on columns */
export type MerchandiseImageAvgFields = {
  __typename?: 'merchandiseImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchandiseImage" */
export type MerchandiseImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchandiseImage". All fields are combined with a logical 'AND'. */
export type MerchandiseImageBoolExp = {
  _and?: InputMaybe<Array<MerchandiseImageBoolExp>>;
  _not?: InputMaybe<MerchandiseImageBoolExp>;
  _or?: InputMaybe<Array<MerchandiseImageBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  merchandise?: InputMaybe<MerchandiseBoolExp>;
  merchandiseCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "merchandiseImage" */
export enum MerchandiseImageConstraint {
  /** unique or primary key constraint on columns "cuid" */
  MerchandiseImagePkey = 'merchandiseImage_pkey'
}

/** input type for incrementing numeric columns in table "merchandiseImage" */
export type MerchandiseImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "merchandiseImage" */
export type MerchandiseImageInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  merchandise?: InputMaybe<MerchandiseObjRelInsertInput>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MerchandiseImageMaxFields = {
  __typename?: 'merchandiseImage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "merchandiseImage" */
export type MerchandiseImageMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchandiseImageMinFields = {
  __typename?: 'merchandiseImage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "merchandiseImage" */
export type MerchandiseImageMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchandiseImage" */
export type MerchandiseImageMutationResponse = {
  __typename?: 'merchandiseImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchandiseImage>;
};

/** on_conflict condition type for table "merchandiseImage" */
export type MerchandiseImageOnConflict = {
  constraint: MerchandiseImageConstraint;
  update_columns?: Array<MerchandiseImageUpdateColumn>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};

/** Ordering options when selecting data from "merchandiseImage". */
export type MerchandiseImageOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  merchandise?: InputMaybe<MerchandiseOrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandiseImage */
export type MerchandiseImagePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "merchandiseImage" */
export enum MerchandiseImageSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "merchandiseImage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "merchandiseImage" */
export enum MerchandiseImageSelectColumnMerchandiseImageAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "merchandiseImage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "merchandiseImage" */
export enum MerchandiseImageSelectColumnMerchandiseImageAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "merchandiseImage" */
export type MerchandiseImageSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MerchandiseImageStddevFields = {
  __typename?: 'merchandiseImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchandiseImage" */
export type MerchandiseImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchandiseImageStddevPopFields = {
  __typename?: 'merchandiseImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchandiseImage" */
export type MerchandiseImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchandiseImageStddevSampFields = {
  __typename?: 'merchandiseImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchandiseImage" */
export type MerchandiseImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MerchandiseImageSumFields = {
  __typename?: 'merchandiseImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "merchandiseImage" */
export type MerchandiseImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "merchandiseImage" */
export enum MerchandiseImageUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type MerchandiseImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchandiseImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseImageSetInput>;
  where: MerchandiseImageBoolExp;
};

/** aggregate var_pop on columns */
export type MerchandiseImageVarPopFields = {
  __typename?: 'merchandiseImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchandiseImage" */
export type MerchandiseImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchandiseImageVarSampFields = {
  __typename?: 'merchandiseImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchandiseImage" */
export type MerchandiseImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchandiseImageVarianceFields = {
  __typename?: 'merchandiseImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchandiseImage" */
export type MerchandiseImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Variant for a piece of Merchandise metadata: [[merchandiseVariantOptionCuid, merchandiseVariantOptionValueCuid]] */
export type MerchandiseVariant = Node & {
  __typename?: 'merchandiseVariant';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image?: Maybe<Image>;
  imagePublicId?: Maybe<Scalars['String']>;
  /** An object relationship */
  merchandise: Merchandise;
  merchandiseCuid: Scalars['String'];
  optionCuidValueCuid: Scalars['jsonb'];
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** Variant for a piece of Merchandise metadata: [[merchandiseVariantOptionCuid, merchandiseVariantOptionValueCuid]] */
export type MerchandiseVariantOptionCuidValueCuidArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "merchandiseVariant" */
export type MerchandiseVariantConnection = {
  __typename?: 'merchandiseVariantConnection';
  edges: Array<MerchandiseVariantEdge>;
  pageInfo: PageInfo;
};

export type MerchandiseVariantEdge = {
  __typename?: 'merchandiseVariantEdge';
  cursor: Scalars['String'];
  node: MerchandiseVariant;
};

/** options for merchandise variants */
export type MerchandiseVariantOption = Node & {
  __typename?: 'merchandiseVariantOption';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  merchandise: Merchandise;
  merchandiseCuid: Scalars['String'];
  /** An array relationship */
  merchandiseVariantOptionValues: Array<MerchandiseVariantOptionValue>;
  /** An aggregate relationship */
  merchandiseVariantOptionValues_aggregate: MerchandiseVariantOptionValueAggregate;
  /** An array relationship connection */
  merchandiseVariantOptionValues_connection: MerchandiseVariantOptionValueConnection;
  optionName: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** options for merchandise variants */
export type MerchandiseVariantOptionMerchandiseVariantOptionValuesArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionValueOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};


/** options for merchandise variants */
export type MerchandiseVariantOptionMerchandiseVariantOptionValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionValueOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};


/** options for merchandise variants */
export type MerchandiseVariantOptionMerchandiseVariantOptionValuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionValueOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};

/** A Relay connection object on "merchandiseVariantOption" */
export type MerchandiseVariantOptionConnection = {
  __typename?: 'merchandiseVariantOptionConnection';
  edges: Array<MerchandiseVariantOptionEdge>;
  pageInfo: PageInfo;
};

export type MerchandiseVariantOptionEdge = {
  __typename?: 'merchandiseVariantOptionEdge';
  cursor: Scalars['String'];
  node: MerchandiseVariantOption;
};

/** Value for variant option */
export type MerchandiseVariantOptionValue = Node & {
  __typename?: 'merchandiseVariantOptionValue';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  merchandiseVariantOption: MerchandiseVariantOption;
  merchandiseVariantOptionCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** A Relay connection object on "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueConnection = {
  __typename?: 'merchandiseVariantOptionValueConnection';
  edges: Array<MerchandiseVariantOptionValueEdge>;
  pageInfo: PageInfo;
};

export type MerchandiseVariantOptionValueEdge = {
  __typename?: 'merchandiseVariantOptionValueEdge';
  cursor: Scalars['String'];
  node: MerchandiseVariantOptionValue;
};

/** aggregated selection of "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueAggregate = {
  __typename?: 'merchandiseVariantOptionValue_aggregate';
  aggregate?: Maybe<MerchandiseVariantOptionValueAggregateFields>;
  nodes: Array<MerchandiseVariantOptionValue>;
};

export type MerchandiseVariantOptionValueAggregateBoolExp = {
  count?: InputMaybe<MerchandiseVariantOptionValueAggregateBoolExpCount>;
};

export type MerchandiseVariantOptionValueAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueAggregateFields = {
  __typename?: 'merchandiseVariantOptionValue_aggregate_fields';
  avg?: Maybe<MerchandiseVariantOptionValueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchandiseVariantOptionValueMaxFields>;
  min?: Maybe<MerchandiseVariantOptionValueMinFields>;
  stddev?: Maybe<MerchandiseVariantOptionValueStddevFields>;
  stddev_pop?: Maybe<MerchandiseVariantOptionValueStddevPopFields>;
  stddev_samp?: Maybe<MerchandiseVariantOptionValueStddevSampFields>;
  sum?: Maybe<MerchandiseVariantOptionValueSumFields>;
  var_pop?: Maybe<MerchandiseVariantOptionValueVarPopFields>;
  var_samp?: Maybe<MerchandiseVariantOptionValueVarSampFields>;
  variance?: Maybe<MerchandiseVariantOptionValueVarianceFields>;
};


/** aggregate fields of "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueAggregateOrderBy = {
  avg?: InputMaybe<MerchandiseVariantOptionValueAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchandiseVariantOptionValueMaxOrderBy>;
  min?: InputMaybe<MerchandiseVariantOptionValueMinOrderBy>;
  stddev?: InputMaybe<MerchandiseVariantOptionValueStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchandiseVariantOptionValueStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchandiseVariantOptionValueStddevSampOrderBy>;
  sum?: InputMaybe<MerchandiseVariantOptionValueSumOrderBy>;
  var_pop?: InputMaybe<MerchandiseVariantOptionValueVarPopOrderBy>;
  var_samp?: InputMaybe<MerchandiseVariantOptionValueVarSampOrderBy>;
  variance?: InputMaybe<MerchandiseVariantOptionValueVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueArrRelInsertInput = {
  data: Array<MerchandiseVariantOptionValueInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseVariantOptionValueOnConflict>;
};

/** aggregate avg on columns */
export type MerchandiseVariantOptionValueAvgFields = {
  __typename?: 'merchandiseVariantOptionValue_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchandiseVariantOptionValue". All fields are combined with a logical 'AND'. */
export type MerchandiseVariantOptionValueBoolExp = {
  _and?: InputMaybe<Array<MerchandiseVariantOptionValueBoolExp>>;
  _not?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
  _or?: InputMaybe<Array<MerchandiseVariantOptionValueBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  merchandiseVariantOption?: InputMaybe<MerchandiseVariantOptionBoolExp>;
  merchandiseVariantOptionCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchandiseVariantOptionValue" */
export enum MerchandiseVariantOptionValueConstraint {
  /** unique or primary key constraint on columns "cuid" */
  MerchandiseVariantOptionValuePkey = 'merchandiseVariantOptionValue_pkey'
}

/** input type for incrementing numeric columns in table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  merchandiseVariantOption?: InputMaybe<MerchandiseVariantOptionObjRelInsertInput>;
  merchandiseVariantOptionCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchandiseVariantOptionValueMaxFields = {
  __typename?: 'merchandiseVariantOptionValue_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  merchandiseVariantOptionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandiseVariantOptionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchandiseVariantOptionValueMinFields = {
  __typename?: 'merchandiseVariantOptionValue_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  merchandiseVariantOptionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandiseVariantOptionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueMutationResponse = {
  __typename?: 'merchandiseVariantOptionValue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchandiseVariantOptionValue>;
};

/** on_conflict condition type for table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueOnConflict = {
  constraint: MerchandiseVariantOptionValueConstraint;
  update_columns?: Array<MerchandiseVariantOptionValueUpdateColumn>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};

/** Ordering options when selecting data from "merchandiseVariantOptionValue". */
export type MerchandiseVariantOptionValueOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandiseVariantOption?: InputMaybe<MerchandiseVariantOptionOrderBy>;
  merchandiseVariantOptionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandiseVariantOptionValue */
export type MerchandiseVariantOptionValuePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "merchandiseVariantOptionValue" */
export enum MerchandiseVariantOptionValueSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MerchandiseVariantOptionCuid = 'merchandiseVariantOptionCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  merchandiseVariantOptionCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MerchandiseVariantOptionValueStddevFields = {
  __typename?: 'merchandiseVariantOptionValue_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchandiseVariantOptionValueStddevPopFields = {
  __typename?: 'merchandiseVariantOptionValue_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchandiseVariantOptionValueStddevSampFields = {
  __typename?: 'merchandiseVariantOptionValue_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MerchandiseVariantOptionValueSumFields = {
  __typename?: 'merchandiseVariantOptionValue_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "merchandiseVariantOptionValue" */
export enum MerchandiseVariantOptionValueUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MerchandiseVariantOptionCuid = 'merchandiseVariantOptionCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type MerchandiseVariantOptionValueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchandiseVariantOptionValueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseVariantOptionValueSetInput>;
  where: MerchandiseVariantOptionValueBoolExp;
};

/** aggregate var_pop on columns */
export type MerchandiseVariantOptionValueVarPopFields = {
  __typename?: 'merchandiseVariantOptionValue_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchandiseVariantOptionValueVarSampFields = {
  __typename?: 'merchandiseVariantOptionValue_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchandiseVariantOptionValueVarianceFields = {
  __typename?: 'merchandiseVariantOptionValue_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchandiseVariantOptionValue" */
export type MerchandiseVariantOptionValueVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "merchandiseVariantOption" */
export type MerchandiseVariantOptionAggregate = {
  __typename?: 'merchandiseVariantOption_aggregate';
  aggregate?: Maybe<MerchandiseVariantOptionAggregateFields>;
  nodes: Array<MerchandiseVariantOption>;
};

export type MerchandiseVariantOptionAggregateBoolExp = {
  count?: InputMaybe<MerchandiseVariantOptionAggregateBoolExpCount>;
};

export type MerchandiseVariantOptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseVariantOptionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchandiseVariantOption" */
export type MerchandiseVariantOptionAggregateFields = {
  __typename?: 'merchandiseVariantOption_aggregate_fields';
  avg?: Maybe<MerchandiseVariantOptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchandiseVariantOptionMaxFields>;
  min?: Maybe<MerchandiseVariantOptionMinFields>;
  stddev?: Maybe<MerchandiseVariantOptionStddevFields>;
  stddev_pop?: Maybe<MerchandiseVariantOptionStddevPopFields>;
  stddev_samp?: Maybe<MerchandiseVariantOptionStddevSampFields>;
  sum?: Maybe<MerchandiseVariantOptionSumFields>;
  var_pop?: Maybe<MerchandiseVariantOptionVarPopFields>;
  var_samp?: Maybe<MerchandiseVariantOptionVarSampFields>;
  variance?: Maybe<MerchandiseVariantOptionVarianceFields>;
};


/** aggregate fields of "merchandiseVariantOption" */
export type MerchandiseVariantOptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionAggregateOrderBy = {
  avg?: InputMaybe<MerchandiseVariantOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchandiseVariantOptionMaxOrderBy>;
  min?: InputMaybe<MerchandiseVariantOptionMinOrderBy>;
  stddev?: InputMaybe<MerchandiseVariantOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchandiseVariantOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchandiseVariantOptionStddevSampOrderBy>;
  sum?: InputMaybe<MerchandiseVariantOptionSumOrderBy>;
  var_pop?: InputMaybe<MerchandiseVariantOptionVarPopOrderBy>;
  var_samp?: InputMaybe<MerchandiseVariantOptionVarSampOrderBy>;
  variance?: InputMaybe<MerchandiseVariantOptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchandiseVariantOption" */
export type MerchandiseVariantOptionArrRelInsertInput = {
  data: Array<MerchandiseVariantOptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseVariantOptionOnConflict>;
};

/** aggregate avg on columns */
export type MerchandiseVariantOptionAvgFields = {
  __typename?: 'merchandiseVariantOption_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchandiseVariantOption". All fields are combined with a logical 'AND'. */
export type MerchandiseVariantOptionBoolExp = {
  _and?: InputMaybe<Array<MerchandiseVariantOptionBoolExp>>;
  _not?: InputMaybe<MerchandiseVariantOptionBoolExp>;
  _or?: InputMaybe<Array<MerchandiseVariantOptionBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  merchandise?: InputMaybe<MerchandiseBoolExp>;
  merchandiseCuid?: InputMaybe<StringComparisonExp>;
  merchandiseVariantOptionValues?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
  merchandiseVariantOptionValues_aggregate?: InputMaybe<MerchandiseVariantOptionValueAggregateBoolExp>;
  optionName?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "merchandiseVariantOption" */
export enum MerchandiseVariantOptionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  MerchandiseVariantOptionsPkey = 'merchandiseVariantOptions_pkey'
}

/** input type for incrementing numeric columns in table "merchandiseVariantOption" */
export type MerchandiseVariantOptionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "merchandiseVariantOption" */
export type MerchandiseVariantOptionInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  merchandise?: InputMaybe<MerchandiseObjRelInsertInput>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  merchandiseVariantOptionValues?: InputMaybe<MerchandiseVariantOptionValueArrRelInsertInput>;
  optionName?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MerchandiseVariantOptionMaxFields = {
  __typename?: 'merchandiseVariantOption_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  optionName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchandiseVariantOptionMinFields = {
  __typename?: 'merchandiseVariantOption_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  optionName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  optionName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchandiseVariantOption" */
export type MerchandiseVariantOptionMutationResponse = {
  __typename?: 'merchandiseVariantOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchandiseVariantOption>;
};

/** input type for inserting object relation for remote table "merchandiseVariantOption" */
export type MerchandiseVariantOptionObjRelInsertInput = {
  data: MerchandiseVariantOptionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseVariantOptionOnConflict>;
};

/** on_conflict condition type for table "merchandiseVariantOption" */
export type MerchandiseVariantOptionOnConflict = {
  constraint: MerchandiseVariantOptionConstraint;
  update_columns?: Array<MerchandiseVariantOptionUpdateColumn>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};

/** Ordering options when selecting data from "merchandiseVariantOption". */
export type MerchandiseVariantOptionOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchandise?: InputMaybe<MerchandiseOrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  merchandiseVariantOptionValues_aggregate?: InputMaybe<MerchandiseVariantOptionValueAggregateOrderBy>;
  optionName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandiseVariantOption */
export type MerchandiseVariantOptionPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "merchandiseVariantOption" */
export enum MerchandiseVariantOptionSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  OptionName = 'optionName',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "merchandiseVariantOption" */
export type MerchandiseVariantOptionSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  optionName?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MerchandiseVariantOptionStddevFields = {
  __typename?: 'merchandiseVariantOption_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchandiseVariantOptionStddevPopFields = {
  __typename?: 'merchandiseVariantOption_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchandiseVariantOptionStddevSampFields = {
  __typename?: 'merchandiseVariantOption_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MerchandiseVariantOptionSumFields = {
  __typename?: 'merchandiseVariantOption_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "merchandiseVariantOption" */
export enum MerchandiseVariantOptionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  OptionName = 'optionName',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type MerchandiseVariantOptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchandiseVariantOptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseVariantOptionSetInput>;
  where: MerchandiseVariantOptionBoolExp;
};

/** aggregate var_pop on columns */
export type MerchandiseVariantOptionVarPopFields = {
  __typename?: 'merchandiseVariantOption_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchandiseVariantOptionVarSampFields = {
  __typename?: 'merchandiseVariantOption_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchandiseVariantOptionVarianceFields = {
  __typename?: 'merchandiseVariantOption_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchandiseVariantOption" */
export type MerchandiseVariantOptionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "merchandiseVariant" */
export type MerchandiseVariantAggregate = {
  __typename?: 'merchandiseVariant_aggregate';
  aggregate?: Maybe<MerchandiseVariantAggregateFields>;
  nodes: Array<MerchandiseVariant>;
};

export type MerchandiseVariantAggregateBoolExp = {
  count?: InputMaybe<MerchandiseVariantAggregateBoolExpCount>;
};

export type MerchandiseVariantAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseVariantBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchandiseVariant" */
export type MerchandiseVariantAggregateFields = {
  __typename?: 'merchandiseVariant_aggregate_fields';
  avg?: Maybe<MerchandiseVariantAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchandiseVariantMaxFields>;
  min?: Maybe<MerchandiseVariantMinFields>;
  stddev?: Maybe<MerchandiseVariantStddevFields>;
  stddev_pop?: Maybe<MerchandiseVariantStddevPopFields>;
  stddev_samp?: Maybe<MerchandiseVariantStddevSampFields>;
  sum?: Maybe<MerchandiseVariantSumFields>;
  var_pop?: Maybe<MerchandiseVariantVarPopFields>;
  var_samp?: Maybe<MerchandiseVariantVarSampFields>;
  variance?: Maybe<MerchandiseVariantVarianceFields>;
};


/** aggregate fields of "merchandiseVariant" */
export type MerchandiseVariantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchandiseVariant" */
export type MerchandiseVariantAggregateOrderBy = {
  avg?: InputMaybe<MerchandiseVariantAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchandiseVariantMaxOrderBy>;
  min?: InputMaybe<MerchandiseVariantMinOrderBy>;
  stddev?: InputMaybe<MerchandiseVariantStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchandiseVariantStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchandiseVariantStddevSampOrderBy>;
  sum?: InputMaybe<MerchandiseVariantSumOrderBy>;
  var_pop?: InputMaybe<MerchandiseVariantVarPopOrderBy>;
  var_samp?: InputMaybe<MerchandiseVariantVarSampOrderBy>;
  variance?: InputMaybe<MerchandiseVariantVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MerchandiseVariantAppendInput = {
  optionCuidValueCuid?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "merchandiseVariant" */
export type MerchandiseVariantArrRelInsertInput = {
  data: Array<MerchandiseVariantInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseVariantOnConflict>;
};

/** aggregate avg on columns */
export type MerchandiseVariantAvgFields = {
  __typename?: 'merchandiseVariant_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchandiseVariant" */
export type MerchandiseVariantAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchandiseVariant". All fields are combined with a logical 'AND'. */
export type MerchandiseVariantBoolExp = {
  _and?: InputMaybe<Array<MerchandiseVariantBoolExp>>;
  _not?: InputMaybe<MerchandiseVariantBoolExp>;
  _or?: InputMaybe<Array<MerchandiseVariantBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  merchandise?: InputMaybe<MerchandiseBoolExp>;
  merchandiseCuid?: InputMaybe<StringComparisonExp>;
  optionCuidValueCuid?: InputMaybe<JsonbComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  stock?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "merchandiseVariant" */
export enum MerchandiseVariantConstraint {
  /** unique or primary key constraint on columns "cuid" */
  MerchandiseVariantPkey = 'merchandiseVariant_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MerchandiseVariantDeleteAtPathInput = {
  optionCuidValueCuid?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MerchandiseVariantDeleteElemInput = {
  optionCuidValueCuid?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MerchandiseVariantDeleteKeyInput = {
  optionCuidValueCuid?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "merchandiseVariant" */
export type MerchandiseVariantIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merchandiseVariant" */
export type MerchandiseVariantInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  merchandise?: InputMaybe<MerchandiseObjRelInsertInput>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  optionCuidValueCuid?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MerchandiseVariantMaxFields = {
  __typename?: 'merchandiseVariant_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "merchandiseVariant" */
export type MerchandiseVariantMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchandiseVariantMinFields = {
  __typename?: 'merchandiseVariant_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  merchandiseCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "merchandiseVariant" */
export type MerchandiseVariantMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchandiseVariant" */
export type MerchandiseVariantMutationResponse = {
  __typename?: 'merchandiseVariant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchandiseVariant>;
};

/** on_conflict condition type for table "merchandiseVariant" */
export type MerchandiseVariantOnConflict = {
  constraint: MerchandiseVariantConstraint;
  update_columns?: Array<MerchandiseVariantUpdateColumn>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};

/** Ordering options when selecting data from "merchandiseVariant". */
export type MerchandiseVariantOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  merchandise?: InputMaybe<MerchandiseOrderBy>;
  merchandiseCuid?: InputMaybe<OrderBy>;
  optionCuidValueCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandiseVariant */
export type MerchandiseVariantPkColumnsInput = {
  cuid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MerchandiseVariantPrependInput = {
  optionCuidValueCuid?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "merchandiseVariant" */
export enum MerchandiseVariantSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  OptionCuidValueCuid = 'optionCuidValueCuid',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "merchandiseVariant" */
export type MerchandiseVariantSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  merchandiseCuid?: InputMaybe<Scalars['String']>;
  optionCuidValueCuid?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MerchandiseVariantStddevFields = {
  __typename?: 'merchandiseVariant_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchandiseVariant" */
export type MerchandiseVariantStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchandiseVariantStddevPopFields = {
  __typename?: 'merchandiseVariant_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchandiseVariant" */
export type MerchandiseVariantStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchandiseVariantStddevSampFields = {
  __typename?: 'merchandiseVariant_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchandiseVariant" */
export type MerchandiseVariantStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MerchandiseVariantSumFields = {
  __typename?: 'merchandiseVariant_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "merchandiseVariant" */
export type MerchandiseVariantSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** update columns of table "merchandiseVariant" */
export enum MerchandiseVariantUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  MerchandiseCuid = 'merchandiseCuid',
  /** column name */
  OptionCuidValueCuid = 'optionCuidValueCuid',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type MerchandiseVariantUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MerchandiseVariantAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MerchandiseVariantDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MerchandiseVariantDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MerchandiseVariantDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchandiseVariantIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MerchandiseVariantPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseVariantSetInput>;
  where: MerchandiseVariantBoolExp;
};

/** aggregate var_pop on columns */
export type MerchandiseVariantVarPopFields = {
  __typename?: 'merchandiseVariant_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchandiseVariant" */
export type MerchandiseVariantVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchandiseVariantVarSampFields = {
  __typename?: 'merchandiseVariant_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchandiseVariant" */
export type MerchandiseVariantVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchandiseVariantVarianceFields = {
  __typename?: 'merchandiseVariant_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchandiseVariant" */
export type MerchandiseVariantVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
};

/** aggregated selection of "merchandise" */
export type MerchandiseAggregate = {
  __typename?: 'merchandise_aggregate';
  aggregate?: Maybe<MerchandiseAggregateFields>;
  nodes: Array<Merchandise>;
};

export type MerchandiseAggregateBoolExp = {
  count?: InputMaybe<MerchandiseAggregateBoolExpCount>;
};

export type MerchandiseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchandiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchandiseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchandise" */
export type MerchandiseAggregateFields = {
  __typename?: 'merchandise_aggregate_fields';
  avg?: Maybe<MerchandiseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchandiseMaxFields>;
  min?: Maybe<MerchandiseMinFields>;
  stddev?: Maybe<MerchandiseStddevFields>;
  stddev_pop?: Maybe<MerchandiseStddevPopFields>;
  stddev_samp?: Maybe<MerchandiseStddevSampFields>;
  sum?: Maybe<MerchandiseSumFields>;
  var_pop?: Maybe<MerchandiseVarPopFields>;
  var_samp?: Maybe<MerchandiseVarSampFields>;
  variance?: Maybe<MerchandiseVarianceFields>;
};


/** aggregate fields of "merchandise" */
export type MerchandiseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchandiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchandise" */
export type MerchandiseAggregateOrderBy = {
  avg?: InputMaybe<MerchandiseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchandiseMaxOrderBy>;
  min?: InputMaybe<MerchandiseMinOrderBy>;
  stddev?: InputMaybe<MerchandiseStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchandiseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchandiseStddevSampOrderBy>;
  sum?: InputMaybe<MerchandiseSumOrderBy>;
  var_pop?: InputMaybe<MerchandiseVarPopOrderBy>;
  var_samp?: InputMaybe<MerchandiseVarSampOrderBy>;
  variance?: InputMaybe<MerchandiseVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchandise" */
export type MerchandiseArrRelInsertInput = {
  data: Array<MerchandiseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseOnConflict>;
};

/** aggregate avg on columns */
export type MerchandiseAvgFields = {
  __typename?: 'merchandise_avg_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchandise" */
export type MerchandiseAvgOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchandise". All fields are combined with a logical 'AND'. */
export type MerchandiseBoolExp = {
  _and?: InputMaybe<Array<MerchandiseBoolExp>>;
  _not?: InputMaybe<MerchandiseBoolExp>;
  _or?: InputMaybe<Array<MerchandiseBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  deliveryType?: InputMaybe<MerchandiseDeliveryTypeEnumComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  eventMerchandises?: InputMaybe<EventMerchandiseBoolExp>;
  eventMerchandises_aggregate?: InputMaybe<EventMerchandiseAggregateBoolExp>;
  merchandiseDeliveryType?: InputMaybe<MerchandiseDeliveryTypeBoolExp>;
  merchandiseImages?: InputMaybe<MerchandiseImageBoolExp>;
  merchandiseImages_aggregate?: InputMaybe<MerchandiseImageAggregateBoolExp>;
  merchandiseVariantOptions?: InputMaybe<MerchandiseVariantOptionBoolExp>;
  merchandiseVariantOptions_aggregate?: InputMaybe<MerchandiseVariantOptionAggregateBoolExp>;
  merchandiseVariants?: InputMaybe<MerchandiseVariantBoolExp>;
  merchandiseVariants_aggregate?: InputMaybe<MerchandiseVariantAggregateBoolExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  stock?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  vendorId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "merchandise" */
export enum MerchandiseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  MerchandisePkey = 'merchandise_pkey'
}

/** input type for incrementing numeric columns in table "merchandise" */
export type MerchandiseIncInput = {
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merchandise" */
export type MerchandiseInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deliveryType?: InputMaybe<MerchandiseDeliveryTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  eventMerchandises?: InputMaybe<EventMerchandiseArrRelInsertInput>;
  merchandiseDeliveryType?: InputMaybe<MerchandiseDeliveryTypeObjRelInsertInput>;
  merchandiseImages?: InputMaybe<MerchandiseImageArrRelInsertInput>;
  merchandiseVariantOptions?: InputMaybe<MerchandiseVariantOptionArrRelInsertInput>;
  merchandiseVariants?: InputMaybe<MerchandiseVariantArrRelInsertInput>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type MerchandiseMaxFields = {
  __typename?: 'merchandise_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "merchandise" */
export type MerchandiseMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchandiseMinFields = {
  __typename?: 'merchandise_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "merchandise" */
export type MerchandiseMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchandise" */
export type MerchandiseMutationResponse = {
  __typename?: 'merchandise_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merchandise>;
};

/** input type for inserting object relation for remote table "merchandise" */
export type MerchandiseObjRelInsertInput = {
  data: MerchandiseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchandiseOnConflict>;
};

/** on_conflict condition type for table "merchandise" */
export type MerchandiseOnConflict = {
  constraint: MerchandiseConstraint;
  update_columns?: Array<MerchandiseUpdateColumn>;
  where?: InputMaybe<MerchandiseBoolExp>;
};

/** Ordering options when selecting data from "merchandise". */
export type MerchandiseOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  deliveryType?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventMerchandises_aggregate?: InputMaybe<EventMerchandiseAggregateOrderBy>;
  merchandiseDeliveryType?: InputMaybe<MerchandiseDeliveryTypeOrderBy>;
  merchandiseImages_aggregate?: InputMaybe<MerchandiseImageAggregateOrderBy>;
  merchandiseVariantOptions_aggregate?: InputMaybe<MerchandiseVariantOptionAggregateOrderBy>;
  merchandiseVariants_aggregate?: InputMaybe<MerchandiseVariantAggregateOrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchandise */
export type MerchandisePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "merchandise" */
export enum MerchandiseSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  Description = 'description',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "merchandise" */
export type MerchandiseSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  deliveryType?: InputMaybe<MerchandiseDeliveryTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  stock?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MerchandiseStddevFields = {
  __typename?: 'merchandise_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchandise" */
export type MerchandiseStddevOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchandiseStddevPopFields = {
  __typename?: 'merchandise_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchandise" */
export type MerchandiseStddevPopOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchandiseStddevSampFields = {
  __typename?: 'merchandise_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchandise" */
export type MerchandiseStddevSampOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MerchandiseSumFields = {
  __typename?: 'merchandise_sum_fields';
  price?: Maybe<Scalars['numeric']>;
  stock?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "merchandise" */
export type MerchandiseSumOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** update columns of table "merchandise" */
export enum MerchandiseUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  Description = 'description',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Price = 'price',
  /** column name */
  Stock = 'stock',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendorId'
}

export type MerchandiseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchandiseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchandiseSetInput>;
  where: MerchandiseBoolExp;
};

/** aggregate var_pop on columns */
export type MerchandiseVarPopFields = {
  __typename?: 'merchandise_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchandise" */
export type MerchandiseVarPopOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchandiseVarSampFields = {
  __typename?: 'merchandise_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchandise" */
export type MerchandiseVarSampOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchandiseVarianceFields = {
  __typename?: 'merchandise_variance_fields';
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchandise" */
export type MerchandiseVarianceOrderBy = {
  price?: InputMaybe<OrderBy>;
  stock?: InputMaybe<OrderBy>;
  vendorId?: InputMaybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "AccessName" */
  delete_AccessName?: Maybe<AccessNameMutationResponse>;
  /** delete single row from the table: "AccessName" */
  delete_AccessName_by_pk?: Maybe<AccessName>;
  /** delete data from the table: "activityType" */
  delete_activityType?: Maybe<ActivityTypeMutationResponse>;
  /** delete single row from the table: "activityType" */
  delete_activityType_by_pk?: Maybe<ActivityType>;
  /** delete data from the table: "amenity" */
  delete_amenity?: Maybe<AmenityMutationResponse>;
  /** delete single row from the table: "amenity" */
  delete_amenity_by_pk?: Maybe<Amenity>;
  /** delete data from the table: "basicSignatureRequest" */
  delete_basicSignatureRequest?: Maybe<BasicSignatureRequestMutationResponse>;
  /** delete single row from the table: "basicSignatureRequest" */
  delete_basicSignatureRequest_by_pk?: Maybe<BasicSignatureRequest>;
  /** delete data from the table: "category" */
  delete_category?: Maybe<CategoryMutationResponse>;
  /** delete data from the table: "categoryCourse" */
  delete_categoryCourse?: Maybe<CategoryCourseMutationResponse>;
  /** delete single row from the table: "categoryCourse" */
  delete_categoryCourse_by_pk?: Maybe<CategoryCourse>;
  /** delete single row from the table: "category" */
  delete_category_by_pk?: Maybe<Category>;
  /** delete data from the table: "changeLogDate" */
  delete_changeLogDate?: Maybe<ChangeLogDateMutationResponse>;
  /** delete single row from the table: "changeLogDate" */
  delete_changeLogDate_by_pk?: Maybe<ChangeLogDate>;
  /** delete data from the table: "changeLogItem" */
  delete_changeLogItem?: Maybe<ChangeLogItemMutationResponse>;
  /** delete single row from the table: "changeLogItem" */
  delete_changeLogItem_by_pk?: Maybe<ChangeLogItem>;
  /** delete data from the table: "clinic" */
  delete_clinic?: Maybe<ClinicMutationResponse>;
  /** delete data from the table: "clinicCoach" */
  delete_clinicCoach?: Maybe<ClinicCoachMutationResponse>;
  /** delete single row from the table: "clinicCoach" */
  delete_clinicCoach_by_pk?: Maybe<ClinicCoach>;
  /** delete data from the table: "clinicDay" */
  delete_clinicDay?: Maybe<ClinicDayMutationResponse>;
  /** delete single row from the table: "clinicDay" */
  delete_clinicDay_by_pk?: Maybe<ClinicDay>;
  /** delete single row from the table: "clinic" */
  delete_clinic_by_pk?: Maybe<Clinic>;
  /** delete data from the table: "coach" */
  delete_coach?: Maybe<CoachMutationResponse>;
  /** delete data from the table: "coachAvailability" */
  delete_coachAvailability?: Maybe<CoachAvailabilityMutationResponse>;
  /** delete single row from the table: "coachAvailability" */
  delete_coachAvailability_by_pk?: Maybe<CoachAvailability>;
  /** delete data from the table: "coachCertification" */
  delete_coachCertification?: Maybe<CoachCertificationMutationResponse>;
  /** delete single row from the table: "coachCertification" */
  delete_coachCertification_by_pk?: Maybe<CoachCertification>;
  /** delete data from the table: "coachCustomer" */
  delete_coachCustomer?: Maybe<CoachCustomerMutationResponse>;
  /** delete data from the table: "coachCustomerLesson" */
  delete_coachCustomerLesson?: Maybe<CoachCustomerLessonMutationResponse>;
  /** delete data from the table: "coachCustomerLessonDate" */
  delete_coachCustomerLessonDate?: Maybe<CoachCustomerLessonDateMutationResponse>;
  /** delete data from the table: "coachCustomerLessonDateLocation" */
  delete_coachCustomerLessonDateLocation?: Maybe<CoachCustomerLessonDateLocationMutationResponse>;
  /** delete single row from the table: "coachCustomerLessonDateLocation" */
  delete_coachCustomerLessonDateLocation_by_pk?: Maybe<CoachCustomerLessonDateLocation>;
  /** delete data from the table: "coachCustomerLessonDateVirtualLocation" */
  delete_coachCustomerLessonDateVirtualLocation?: Maybe<CoachCustomerLessonDateVirtualLocationMutationResponse>;
  /** delete single row from the table: "coachCustomerLessonDateVirtualLocation" */
  delete_coachCustomerLessonDateVirtualLocation_by_pk?: Maybe<CoachCustomerLessonDateVirtualLocation>;
  /** delete single row from the table: "coachCustomerLessonDate" */
  delete_coachCustomerLessonDate_by_pk?: Maybe<CoachCustomerLessonDate>;
  /** delete data from the table: "coachCustomerLessonOneTimeCharge" */
  delete_coachCustomerLessonOneTimeCharge?: Maybe<CoachCustomerLessonOneTimeChargeMutationResponse>;
  /** delete single row from the table: "coachCustomerLessonOneTimeCharge" */
  delete_coachCustomerLessonOneTimeCharge_by_pk?: Maybe<CoachCustomerLessonOneTimeCharge>;
  /** delete data from the table: "coachCustomerLessonPauseResume" */
  delete_coachCustomerLessonPauseResume?: Maybe<CoachCustomerLessonPauseResumeMutationResponse>;
  /** delete single row from the table: "coachCustomerLessonPauseResume" */
  delete_coachCustomerLessonPauseResume_by_pk?: Maybe<CoachCustomerLessonPauseResume>;
  /** delete data from the table: "coachCustomerLessonTransaction" */
  delete_coachCustomerLessonTransaction?: Maybe<CoachCustomerLessonTransactionMutationResponse>;
  /** delete single row from the table: "coachCustomerLessonTransaction" */
  delete_coachCustomerLessonTransaction_by_pk?: Maybe<CoachCustomerLessonTransaction>;
  /** delete single row from the table: "coachCustomerLesson" */
  delete_coachCustomerLesson_by_pk?: Maybe<CoachCustomerLesson>;
  /** delete data from the table: "coachCustomerMessageRequest" */
  delete_coachCustomerMessageRequest?: Maybe<CoachCustomerMessageRequestMutationResponse>;
  /** delete single row from the table: "coachCustomerMessageRequest" */
  delete_coachCustomerMessageRequest_by_pk?: Maybe<CoachCustomerMessageRequest>;
  /** delete data from the table: "coachCustomerUser" */
  delete_coachCustomerUser?: Maybe<CoachCustomerUserMutationResponse>;
  /** delete single row from the table: "coachCustomerUser" */
  delete_coachCustomerUser_by_pk?: Maybe<CoachCustomerUser>;
  /** delete single row from the table: "coachCustomer" */
  delete_coachCustomer_by_pk?: Maybe<CoachCustomer>;
  /** delete data from the table: "coachDiscipline" */
  delete_coachDiscipline?: Maybe<CoachDisciplineMutationResponse>;
  /** delete single row from the table: "coachDiscipline" */
  delete_coachDiscipline_by_pk?: Maybe<CoachDiscipline>;
  /** delete data from the table: "coachImage" */
  delete_coachImage?: Maybe<CoachImageMutationResponse>;
  /** delete single row from the table: "coachImage" */
  delete_coachImage_by_pk?: Maybe<CoachImage>;
  /** delete data from the table: "coachLesson" */
  delete_coachLesson?: Maybe<CoachLessonMutationResponse>;
  /** delete data from the table: "coachLessonLocation" */
  delete_coachLessonLocation?: Maybe<CoachLessonLocationMutationResponse>;
  /** delete single row from the table: "coachLessonLocation" */
  delete_coachLessonLocation_by_pk?: Maybe<CoachLessonLocation>;
  /** delete single row from the table: "coachLesson" */
  delete_coachLesson_by_pk?: Maybe<CoachLesson>;
  /** delete data from the table: "coachLocation" */
  delete_coachLocation?: Maybe<CoachLocationMutationResponse>;
  /** delete single row from the table: "coachLocation" */
  delete_coachLocation_by_pk?: Maybe<CoachLocation>;
  /** delete data from the table: "coachModerationFlag" */
  delete_coachModerationFlag?: Maybe<CoachModerationFlagMutationResponse>;
  /** delete single row from the table: "coachModerationFlag" */
  delete_coachModerationFlag_by_pk?: Maybe<CoachModerationFlag>;
  /** delete data from the table: "coachStudent" */
  delete_coachStudent?: Maybe<CoachStudentMutationResponse>;
  /** delete single row from the table: "coachStudent" */
  delete_coachStudent_by_pk?: Maybe<CoachStudent>;
  /** delete data from the table: "coachUnavailableDate" */
  delete_coachUnavailableDate?: Maybe<CoachUnavailableDateMutationResponse>;
  /** delete single row from the table: "coachUnavailableDate" */
  delete_coachUnavailableDate_by_pk?: Maybe<CoachUnavailableDate>;
  /** delete single row from the table: "coach" */
  delete_coach_by_pk?: Maybe<Coach>;
  /** delete data from the table: "course" */
  delete_course?: Maybe<CourseMutationResponse>;
  /** delete single row from the table: "course" */
  delete_course_by_pk?: Maybe<Course>;
  /** delete data from the table: "creatorInvite" */
  delete_creatorInvite?: Maybe<CreatorInviteMutationResponse>;
  /** delete single row from the table: "creatorInvite" */
  delete_creatorInvite_by_pk?: Maybe<CreatorInvite>;
  /** delete data from the table: "event" */
  delete_event?: Maybe<EventMutationResponse>;
  /** delete data from the table: "eventAdditionalInfo" */
  delete_eventAdditionalInfo?: Maybe<EventAdditionalInfoMutationResponse>;
  /** delete single row from the table: "eventAdditionalInfo" */
  delete_eventAdditionalInfo_by_pk?: Maybe<EventAdditionalInfo>;
  /** delete data from the table: "eventAmenity" */
  delete_eventAmenity?: Maybe<EventAmenityMutationResponse>;
  /** delete single row from the table: "eventAmenity" */
  delete_eventAmenity_by_pk?: Maybe<EventAmenity>;
  /** delete data from the table: "eventBasicWaiver" */
  delete_eventBasicWaiver?: Maybe<EventBasicWaiverMutationResponse>;
  /** delete data from the table: "eventBasicWaiverCategory" */
  delete_eventBasicWaiverCategory?: Maybe<EventBasicWaiverCategoryMutationResponse>;
  /** delete single row from the table: "eventBasicWaiverCategory" */
  delete_eventBasicWaiverCategory_by_pk?: Maybe<EventBasicWaiverCategory>;
  /** delete single row from the table: "eventBasicWaiver" */
  delete_eventBasicWaiver_by_pk?: Maybe<EventBasicWaiver>;
  /** delete data from the table: "eventChecklistItem" */
  delete_eventChecklistItem?: Maybe<EventChecklistItemMutationResponse>;
  /** delete single row from the table: "eventChecklistItem" */
  delete_eventChecklistItem_by_pk?: Maybe<EventChecklistItem>;
  /** delete data from the table: "eventClinicDayDuration" */
  delete_eventClinicDayDuration?: Maybe<EventClinicDayDurationMutationResponse>;
  /** delete single row from the table: "eventClinicDayDuration" */
  delete_eventClinicDayDuration_by_pk?: Maybe<EventClinicDayDuration>;
  /** delete data from the table: "eventCustomQuestion" */
  delete_eventCustomQuestion?: Maybe<EventCustomQuestionMutationResponse>;
  /** delete data from the table: "eventCustomQuestionCategory" */
  delete_eventCustomQuestionCategory?: Maybe<EventCustomQuestionCategoryMutationResponse>;
  /** delete single row from the table: "eventCustomQuestionCategory" */
  delete_eventCustomQuestionCategory_by_pk?: Maybe<EventCustomQuestionCategory>;
  /** delete data from the table: "eventCustomQuestionOption" */
  delete_eventCustomQuestionOption?: Maybe<EventCustomQuestionOptionMutationResponse>;
  /** delete single row from the table: "eventCustomQuestionOption" */
  delete_eventCustomQuestionOption_by_pk?: Maybe<EventCustomQuestionOption>;
  /** delete single row from the table: "eventCustomQuestion" */
  delete_eventCustomQuestion_by_pk?: Maybe<EventCustomQuestion>;
  /** delete data from the table: "eventDiscipline" */
  delete_eventDiscipline?: Maybe<EventDisciplineMutationResponse>;
  /** delete single row from the table: "eventDiscipline" */
  delete_eventDiscipline_by_pk?: Maybe<EventDiscipline>;
  /** delete data from the table: "eventImage" */
  delete_eventImage?: Maybe<EventImageMutationResponse>;
  /** delete single row from the table: "eventImage" */
  delete_eventImage_by_pk?: Maybe<EventImage>;
  /** delete data from the table: "eventMerchandise" */
  delete_eventMerchandise?: Maybe<EventMerchandiseMutationResponse>;
  /** delete single row from the table: "eventMerchandise" */
  delete_eventMerchandise_by_pk?: Maybe<EventMerchandise>;
  /** delete data from the table: "eventMetadata" */
  delete_eventMetadata?: Maybe<EventMetadataMutationResponse>;
  /** delete single row from the table: "eventMetadata" */
  delete_eventMetadata_by_pk?: Maybe<EventMetadata>;
  /** delete data from the table: "eventModerationFlag" */
  delete_eventModerationFlag?: Maybe<EventModerationFlagMutationResponse>;
  /** delete single row from the table: "eventModerationFlag" */
  delete_eventModerationFlag_by_pk?: Maybe<EventModerationFlag>;
  /** delete data from the table: "eventPitSpace" */
  delete_eventPitSpace?: Maybe<EventPitSpaceMutationResponse>;
  /** delete data from the table: "eventPitSpaceAddOn" */
  delete_eventPitSpaceAddOn?: Maybe<EventPitSpaceAddOnMutationResponse>;
  /** delete single row from the table: "eventPitSpaceAddOn" */
  delete_eventPitSpaceAddOn_by_pk?: Maybe<EventPitSpaceAddOn>;
  /** delete single row from the table: "eventPitSpace" */
  delete_eventPitSpace_by_pk?: Maybe<EventPitSpace>;
  /** delete data from the table: "eventPricingChange" */
  delete_eventPricingChange?: Maybe<EventPricingChangeMutationResponse>;
  /** delete single row from the table: "eventPricingChange" */
  delete_eventPricingChange_by_pk?: Maybe<EventPricingChange>;
  /** delete data from the table: "eventPricingDate" */
  delete_eventPricingDate?: Maybe<EventPricingDateMutationResponse>;
  /** delete single row from the table: "eventPricingDate" */
  delete_eventPricingDate_by_pk?: Maybe<EventPricingDate>;
  /** delete data from the table: "eventPricingGroup" */
  delete_eventPricingGroup?: Maybe<EventPricingGroupMutationResponse>;
  /** delete data from the table: "eventPricingGroupCategory" */
  delete_eventPricingGroupCategory?: Maybe<EventPricingGroupCategoryMutationResponse>;
  /** delete single row from the table: "eventPricingGroupCategory" */
  delete_eventPricingGroupCategory_by_pk?: Maybe<EventPricingGroupCategory>;
  /** delete single row from the table: "eventPricingGroup" */
  delete_eventPricingGroup_by_pk?: Maybe<EventPricingGroup>;
  /** delete data from the table: "eventPrivateLink" */
  delete_eventPrivateLink?: Maybe<EventPrivateLinkMutationResponse>;
  /** delete single row from the table: "eventPrivateLink" */
  delete_eventPrivateLink_by_pk?: Maybe<EventPrivateLink>;
  /** delete data from the table: "eventProgram" */
  delete_eventProgram?: Maybe<EventProgramMutationResponse>;
  /** delete single row from the table: "eventProgram" */
  delete_eventProgram_by_pk?: Maybe<EventProgram>;
  /** delete data from the table: "eventPromoCode" */
  delete_eventPromoCode?: Maybe<EventPromoCodeMutationResponse>;
  /** delete single row from the table: "eventPromoCode" */
  delete_eventPromoCode_by_pk?: Maybe<EventPromoCode>;
  /** delete data from the table: "eventQuestion" */
  delete_eventQuestion?: Maybe<EventQuestionMutationResponse>;
  /** delete data from the table: "eventQuestionAnswer" */
  delete_eventQuestionAnswer?: Maybe<EventQuestionAnswerMutationResponse>;
  /** delete single row from the table: "eventQuestionAnswer" */
  delete_eventQuestionAnswer_by_pk?: Maybe<EventQuestionAnswer>;
  /** delete single row from the table: "eventQuestion" */
  delete_eventQuestion_by_pk?: Maybe<EventQuestion>;
  /** delete data from the table: "eventSanction" */
  delete_eventSanction?: Maybe<EventSanctionMutationResponse>;
  /** delete data from the table: "eventSanctionCategory" */
  delete_eventSanctionCategory?: Maybe<EventSanctionCategoryMutationResponse>;
  /** delete single row from the table: "eventSanctionCategory" */
  delete_eventSanctionCategory_by_pk?: Maybe<EventSanctionCategory>;
  /** delete single row from the table: "eventSanction" */
  delete_eventSanction_by_pk?: Maybe<EventSanction>;
  /** delete data from the table: "eventSchedule" */
  delete_eventSchedule?: Maybe<EventScheduleMutationResponse>;
  /** delete data from the table: "eventScheduleCategory" */
  delete_eventScheduleCategory?: Maybe<EventScheduleCategoryMutationResponse>;
  /** delete single row from the table: "eventScheduleCategory" */
  delete_eventScheduleCategory_by_pk?: Maybe<EventScheduleCategory>;
  /** delete data from the table: "eventScheduleDate" */
  delete_eventScheduleDate?: Maybe<EventScheduleDateMutationResponse>;
  /** delete single row from the table: "eventScheduleDate" */
  delete_eventScheduleDate_by_pk?: Maybe<EventScheduleDate>;
  /** delete data from the table: "eventScheduleTypeCustom" */
  delete_eventScheduleTypeCustom?: Maybe<EventScheduleTypeCustomMutationResponse>;
  /** delete single row from the table: "eventScheduleTypeCustom" */
  delete_eventScheduleTypeCustom_by_pk?: Maybe<EventScheduleTypeCustom>;
  /** delete data from the table: "eventScheduleTypePreset" */
  delete_eventScheduleTypePreset?: Maybe<EventScheduleTypePresetMutationResponse>;
  /** delete single row from the table: "eventScheduleTypePreset" */
  delete_eventScheduleTypePreset_by_pk?: Maybe<EventScheduleTypePreset>;
  /** delete single row from the table: "eventSchedule" */
  delete_eventSchedule_by_pk?: Maybe<EventSchedule>;
  /** delete data from the table: "eventSeries" */
  delete_eventSeries?: Maybe<EventSeriesMutationResponse>;
  /** delete data from the table: "eventSeriesCategory" */
  delete_eventSeriesCategory?: Maybe<EventSeriesCategoryMutationResponse>;
  /** delete single row from the table: "eventSeriesCategory" */
  delete_eventSeriesCategory_by_pk?: Maybe<EventSeriesCategory>;
  /** delete single row from the table: "eventSeries" */
  delete_eventSeries_by_pk?: Maybe<EventSeries>;
  /** delete data from the table: "eventSponsor" */
  delete_eventSponsor?: Maybe<EventSponsorMutationResponse>;
  /** delete single row from the table: "eventSponsor" */
  delete_eventSponsor_by_pk?: Maybe<EventSponsor>;
  /** delete data from the table: "eventTicket" */
  delete_eventTicket?: Maybe<EventTicketMutationResponse>;
  /** delete single row from the table: "eventTicket" */
  delete_eventTicket_by_pk?: Maybe<EventTicket>;
  /** delete data from the table: "eventUpdate" */
  delete_eventUpdate?: Maybe<EventUpdateMutationResponse>;
  /** delete data from the table: "eventUpdateComment" */
  delete_eventUpdateComment?: Maybe<EventUpdateCommentMutationResponse>;
  /** delete single row from the table: "eventUpdateComment" */
  delete_eventUpdateComment_by_pk?: Maybe<EventUpdateComment>;
  /** delete single row from the table: "eventUpdate" */
  delete_eventUpdate_by_pk?: Maybe<EventUpdate>;
  /** delete data from the table: "eventVenue" */
  delete_eventVenue?: Maybe<EventVenueMutationResponse>;
  /** delete single row from the table: "eventVenue" */
  delete_eventVenue_by_pk?: Maybe<EventVenue>;
  /** delete data from the table: "eventVibe" */
  delete_eventVibe?: Maybe<EventVibeMutationResponse>;
  /** delete single row from the table: "eventVibe" */
  delete_eventVibe_by_pk?: Maybe<EventVibe>;
  /** delete data from the table: "eventVirtualVenue" */
  delete_eventVirtualVenue?: Maybe<EventVirtualVenueMutationResponse>;
  /** delete single row from the table: "eventVirtualVenue" */
  delete_eventVirtualVenue_by_pk?: Maybe<EventVirtualVenue>;
  /** delete data from the table: "eventVolunteerJob" */
  delete_eventVolunteerJob?: Maybe<EventVolunteerJobMutationResponse>;
  /** delete data from the table: "eventVolunteerJobShift" */
  delete_eventVolunteerJobShift?: Maybe<EventVolunteerJobShiftMutationResponse>;
  /** delete data from the table: "eventVolunteerJobShiftTask" */
  delete_eventVolunteerJobShiftTask?: Maybe<EventVolunteerJobShiftTaskMutationResponse>;
  /** delete single row from the table: "eventVolunteerJobShiftTask" */
  delete_eventVolunteerJobShiftTask_by_pk?: Maybe<EventVolunteerJobShiftTask>;
  /** delete single row from the table: "eventVolunteerJobShift" */
  delete_eventVolunteerJobShift_by_pk?: Maybe<EventVolunteerJobShift>;
  /** delete data from the table: "eventVolunteerJobTask" */
  delete_eventVolunteerJobTask?: Maybe<EventVolunteerJobTaskMutationResponse>;
  /** delete single row from the table: "eventVolunteerJobTask" */
  delete_eventVolunteerJobTask_by_pk?: Maybe<EventVolunteerJobTask>;
  /** delete single row from the table: "eventVolunteerJob" */
  delete_eventVolunteerJob_by_pk?: Maybe<EventVolunteerJob>;
  /** delete data from the table: "eventWaiver" */
  delete_eventWaiver?: Maybe<EventWaiverMutationResponse>;
  /** delete data from the table: "eventWaiverType" */
  delete_eventWaiverType?: Maybe<EventWaiverTypeMutationResponse>;
  /** delete single row from the table: "eventWaiverType" */
  delete_eventWaiverType_by_pk?: Maybe<EventWaiverType>;
  /** delete single row from the table: "eventWaiver" */
  delete_eventWaiver_by_pk?: Maybe<EventWaiver>;
  /** delete single row from the table: "event" */
  delete_event_by_pk?: Maybe<Event>;
  /** delete data from the table: "image" */
  delete_image?: Maybe<ImageMutationResponse>;
  /** delete data from the table: "imageOnlineCourse" */
  delete_imageOnlineCourse?: Maybe<ImageOnlineCourseMutationResponse>;
  /** delete single row from the table: "imageOnlineCourse" */
  delete_imageOnlineCourse_by_pk?: Maybe<ImageOnlineCourse>;
  /** delete data from the table: "imageUser" */
  delete_imageUser?: Maybe<ImageUserMutationResponse>;
  /** delete single row from the table: "imageUser" */
  delete_imageUser_by_pk?: Maybe<ImageUser>;
  /** delete single row from the table: "image" */
  delete_image_by_pk?: Maybe<Image>;
  /** delete data from the table: "lessonLocation" */
  delete_lessonLocation?: Maybe<LessonLocationMutationResponse>;
  /** delete single row from the table: "lessonLocation" */
  delete_lessonLocation_by_pk?: Maybe<LessonLocation>;
  /** delete data from the table: "merchandise" */
  delete_merchandise?: Maybe<MerchandiseMutationResponse>;
  /** delete data from the table: "merchandiseDeliveryType" */
  delete_merchandiseDeliveryType?: Maybe<MerchandiseDeliveryTypeMutationResponse>;
  /** delete single row from the table: "merchandiseDeliveryType" */
  delete_merchandiseDeliveryType_by_pk?: Maybe<MerchandiseDeliveryType>;
  /** delete data from the table: "merchandiseImage" */
  delete_merchandiseImage?: Maybe<MerchandiseImageMutationResponse>;
  /** delete single row from the table: "merchandiseImage" */
  delete_merchandiseImage_by_pk?: Maybe<MerchandiseImage>;
  /** delete data from the table: "merchandiseVariant" */
  delete_merchandiseVariant?: Maybe<MerchandiseVariantMutationResponse>;
  /** delete data from the table: "merchandiseVariantOption" */
  delete_merchandiseVariantOption?: Maybe<MerchandiseVariantOptionMutationResponse>;
  /** delete data from the table: "merchandiseVariantOptionValue" */
  delete_merchandiseVariantOptionValue?: Maybe<MerchandiseVariantOptionValueMutationResponse>;
  /** delete single row from the table: "merchandiseVariantOptionValue" */
  delete_merchandiseVariantOptionValue_by_pk?: Maybe<MerchandiseVariantOptionValue>;
  /** delete single row from the table: "merchandiseVariantOption" */
  delete_merchandiseVariantOption_by_pk?: Maybe<MerchandiseVariantOption>;
  /** delete single row from the table: "merchandiseVariant" */
  delete_merchandiseVariant_by_pk?: Maybe<MerchandiseVariant>;
  /** delete single row from the table: "merchandise" */
  delete_merchandise_by_pk?: Maybe<Merchandise>;
  /** delete data from the table: "onlineCourse" */
  delete_onlineCourse?: Maybe<OnlineCourseMutationResponse>;
  /** delete data from the table: "onlineCourseOwner" */
  delete_onlineCourseOwner?: Maybe<OnlineCourseOwnerMutationResponse>;
  /** delete single row from the table: "onlineCourseOwner" */
  delete_onlineCourseOwner_by_pk?: Maybe<OnlineCourseOwner>;
  /** delete data from the table: "onlineCourseRevision" */
  delete_onlineCourseRevision?: Maybe<OnlineCourseRevisionMutationResponse>;
  /** delete single row from the table: "onlineCourseRevision" */
  delete_onlineCourseRevision_by_pk?: Maybe<OnlineCourseRevision>;
  /** delete single row from the table: "onlineCourse" */
  delete_onlineCourse_by_pk?: Maybe<OnlineCourse>;
  /** delete data from the table: "organizer" */
  delete_organizer?: Maybe<OrganizerMutationResponse>;
  /** delete data from the table: "organizerChannel" */
  delete_organizerChannel?: Maybe<OrganizerChannelMutationResponse>;
  /** delete single row from the table: "organizerChannel" */
  delete_organizerChannel_by_pk?: Maybe<OrganizerChannel>;
  /** delete data from the table: "organizerInvite" */
  delete_organizerInvite?: Maybe<OrganizerInviteMutationResponse>;
  /** delete single row from the table: "organizerInvite" */
  delete_organizerInvite_by_pk?: Maybe<OrganizerInvite>;
  /** delete single row from the table: "organizer" */
  delete_organizer_by_pk?: Maybe<Organizer>;
  /** delete data from the table: "registration" */
  delete_registration?: Maybe<RegistrationMutationResponse>;
  /** delete data from the table: "registrationActivity" */
  delete_registrationActivity?: Maybe<RegistrationActivityMutationResponse>;
  /** delete single row from the table: "registrationActivity" */
  delete_registrationActivity_by_pk?: Maybe<RegistrationActivity>;
  /** delete data from the table: "registrationAdditionalPayment" */
  delete_registrationAdditionalPayment?: Maybe<RegistrationAdditionalPaymentMutationResponse>;
  /** delete single row from the table: "registrationAdditionalPayment" */
  delete_registrationAdditionalPayment_by_pk?: Maybe<RegistrationAdditionalPayment>;
  /** delete data from the table: "registrationCategory" */
  delete_registrationCategory?: Maybe<RegistrationCategoryMutationResponse>;
  /** delete single row from the table: "registrationCategory" */
  delete_registrationCategory_by_pk?: Maybe<RegistrationCategory>;
  /** delete data from the table: "registrationFor" */
  delete_registrationFor?: Maybe<RegistrationForMutationResponse>;
  /** delete single row from the table: "registrationFor" */
  delete_registrationFor_by_pk?: Maybe<RegistrationFor>;
  /** delete data from the table: "registrationSeries" */
  delete_registrationSeries?: Maybe<RegistrationSeriesMutationResponse>;
  /** delete single row from the table: "registrationSeries" */
  delete_registrationSeries_by_pk?: Maybe<RegistrationSeries>;
  /** delete data from the table: "registrationStatus" */
  delete_registrationStatus?: Maybe<RegistrationStatusMutationResponse>;
  /** delete single row from the table: "registrationStatus" */
  delete_registrationStatus_by_pk?: Maybe<RegistrationStatus>;
  /** delete data from the table: "registrationTicket" */
  delete_registrationTicket?: Maybe<RegistrationTicketMutationResponse>;
  /** delete single row from the table: "registrationTicket" */
  delete_registrationTicket_by_pk?: Maybe<RegistrationTicket>;
  /** delete single row from the table: "registration" */
  delete_registration_by_pk?: Maybe<Registration>;
  /** delete data from the table: "reportClinicNoShow" */
  delete_reportClinicNoShow?: Maybe<ReportClinicNoShowMutationResponse>;
  /** delete single row from the table: "reportClinicNoShow" */
  delete_reportClinicNoShow_by_pk?: Maybe<ReportClinicNoShow>;
  /** delete data from the table: "reportLessonNoShow" */
  delete_reportLessonNoShow?: Maybe<ReportLessonNoShowMutationResponse>;
  /** delete single row from the table: "reportLessonNoShow" */
  delete_reportLessonNoShow_by_pk?: Maybe<ReportLessonNoShow>;
  /** delete data from the table: "review" */
  delete_review?: Maybe<ReviewMutationResponse>;
  /** delete data from the table: "reviewSubjectType" */
  delete_reviewSubjectType?: Maybe<ReviewSubjectTypeMutationResponse>;
  /** delete single row from the table: "reviewSubjectType" */
  delete_reviewSubjectType_by_pk?: Maybe<ReviewSubjectType>;
  /** delete data from the table: "reviewTotals" */
  delete_reviewTotals?: Maybe<ReviewTotalsMutationResponse>;
  /** delete single row from the table: "reviewTotals" */
  delete_reviewTotals_by_pk?: Maybe<ReviewTotals>;
  /** delete single row from the table: "review" */
  delete_review_by_pk?: Maybe<Review>;
  /** delete data from the table: "ride" */
  delete_ride?: Maybe<RideMutationResponse>;
  /** delete data from the table: "rideDay" */
  delete_rideDay?: Maybe<RideDayMutationResponse>;
  /** delete single row from the table: "rideDay" */
  delete_rideDay_by_pk?: Maybe<RideDay>;
  /** delete single row from the table: "ride" */
  delete_ride_by_pk?: Maybe<Ride>;
  /** delete data from the table: "roleOrganizer" */
  delete_roleOrganizer?: Maybe<RoleOrganizerMutationResponse>;
  /** delete single row from the table: "roleOrganizer" */
  delete_roleOrganizer_by_pk?: Maybe<RoleOrganizer>;
  /** delete data from the table: "sanction" */
  delete_sanction?: Maybe<SanctionMutationResponse>;
  /** delete data from the table: "sanctionWaivers" */
  delete_sanctionWaivers?: Maybe<SanctionWaiversMutationResponse>;
  /** delete single row from the table: "sanctionWaivers" */
  delete_sanctionWaivers_by_pk?: Maybe<SanctionWaivers>;
  /** delete single row from the table: "sanction" */
  delete_sanction_by_pk?: Maybe<Sanction>;
  /** delete data from the table: "scheduleTypePreset" */
  delete_scheduleTypePreset?: Maybe<ScheduleTypePresetMutationResponse>;
  /** delete single row from the table: "scheduleTypePreset" */
  delete_scheduleTypePreset_by_pk?: Maybe<ScheduleTypePreset>;
  /** delete data from the table: "series" */
  delete_series?: Maybe<SeriesMutationResponse>;
  /** delete data from the table: "seriesImage" */
  delete_seriesImage?: Maybe<SeriesImageMutationResponse>;
  /** delete single row from the table: "seriesImage" */
  delete_seriesImage_by_pk?: Maybe<SeriesImage>;
  /** delete data from the table: "seriesPointsStructureCustom" */
  delete_seriesPointsStructureCustom?: Maybe<SeriesPointsStructureCustomMutationResponse>;
  /** delete single row from the table: "seriesPointsStructureCustom" */
  delete_seriesPointsStructureCustom_by_pk?: Maybe<SeriesPointsStructureCustom>;
  /** delete data from the table: "seriesPointsStructurePreset" */
  delete_seriesPointsStructurePreset?: Maybe<SeriesPointsStructurePresetMutationResponse>;
  /** delete single row from the table: "seriesPointsStructurePreset" */
  delete_seriesPointsStructurePreset_by_pk?: Maybe<SeriesPointsStructurePreset>;
  /** delete single row from the table: "series" */
  delete_series_by_pk?: Maybe<Series>;
  /** delete data from the table: "signatureRequest" */
  delete_signatureRequest?: Maybe<SignatureRequestMutationResponse>;
  /** delete data from the table: "signatureRequestSignerType" */
  delete_signatureRequestSignerType?: Maybe<SignatureRequestSignerTypeMutationResponse>;
  /** delete single row from the table: "signatureRequestSignerType" */
  delete_signatureRequestSignerType_by_pk?: Maybe<SignatureRequestSignerType>;
  /** delete data from the table: "signatureRequestStatus" */
  delete_signatureRequestStatus?: Maybe<SignatureRequestStatusMutationResponse>;
  /** delete single row from the table: "signatureRequestStatus" */
  delete_signatureRequestStatus_by_pk?: Maybe<SignatureRequestStatus>;
  /** delete single row from the table: "signatureRequest" */
  delete_signatureRequest_by_pk?: Maybe<SignatureRequest>;
  /** delete data from the table: "sponsor" */
  delete_sponsor?: Maybe<SponsorMutationResponse>;
  /** delete data from the table: "sponsorImage" */
  delete_sponsorImage?: Maybe<SponsorImageMutationResponse>;
  /** delete single row from the table: "sponsorImage" */
  delete_sponsorImage_by_pk?: Maybe<SponsorImage>;
  /** delete single row from the table: "sponsor" */
  delete_sponsor_by_pk?: Maybe<Sponsor>;
  /** delete data from the table: "stripeConnectAccount" */
  delete_stripeConnectAccount?: Maybe<StripeConnectAccountMutationResponse>;
  /** delete single row from the table: "stripeConnectAccount" */
  delete_stripeConnectAccount_by_pk?: Maybe<StripeConnectAccount>;
  /** delete data from the table: "ticket" */
  delete_ticket?: Maybe<TicketMutationResponse>;
  /** delete single row from the table: "ticket" */
  delete_ticket_by_pk?: Maybe<Ticket>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<UserMutationResponse>;
  /** delete data from the table: "userAddress" */
  delete_userAddress?: Maybe<UserAddressMutationResponse>;
  /** delete single row from the table: "userAddress" */
  delete_userAddress_by_pk?: Maybe<UserAddress>;
  /** delete data from the table: "userBike" */
  delete_userBike?: Maybe<UserBikeMutationResponse>;
  /** delete data from the table: "userBikeRevision" */
  delete_userBikeRevision?: Maybe<UserBikeRevisionMutationResponse>;
  /** delete single row from the table: "userBikeRevision" */
  delete_userBikeRevision_by_pk?: Maybe<UserBikeRevision>;
  /** delete single row from the table: "userBike" */
  delete_userBike_by_pk?: Maybe<UserBike>;
  /** delete data from the table: "userEmergencyContact" */
  delete_userEmergencyContact?: Maybe<UserEmergencyContactMutationResponse>;
  /** delete single row from the table: "userEmergencyContact" */
  delete_userEmergencyContact_by_pk?: Maybe<UserEmergencyContact>;
  /** delete data from the table: "userImage" */
  delete_userImage?: Maybe<UserImageMutationResponse>;
  /** delete single row from the table: "userImage" */
  delete_userImage_by_pk?: Maybe<UserImage>;
  /** delete data from the table: "userOnlineCourse" */
  delete_userOnlineCourse?: Maybe<UserOnlineCourseMutationResponse>;
  /** delete data from the table: "userOnlineCourseStatus" */
  delete_userOnlineCourseStatus?: Maybe<UserOnlineCourseStatusMutationResponse>;
  /** delete single row from the table: "userOnlineCourseStatus" */
  delete_userOnlineCourseStatus_by_pk?: Maybe<UserOnlineCourseStatus>;
  /** delete single row from the table: "userOnlineCourse" */
  delete_userOnlineCourse_by_pk?: Maybe<UserOnlineCourse>;
  /** delete data from the table: "userOrganizerRole" */
  delete_userOrganizerRole?: Maybe<UserOrganizerRoleMutationResponse>;
  /** delete single row from the table: "userOrganizerRole" */
  delete_userOrganizerRole_by_pk?: Maybe<UserOrganizerRole>;
  /** delete data from the table: "userPersonalization" */
  delete_userPersonalization?: Maybe<UserPersonalizationMutationResponse>;
  /** delete single row from the table: "userPersonalization" */
  delete_userPersonalization_by_pk?: Maybe<UserPersonalization>;
  /** delete data from the table: "userRidingInterest" */
  delete_userRidingInterest?: Maybe<UserRidingInterestMutationResponse>;
  /** delete single row from the table: "userRidingInterest" */
  delete_userRidingInterest_by_pk?: Maybe<UserRidingInterest>;
  /** delete data from the table: "userSanctionLicense" */
  delete_userSanctionLicense?: Maybe<UserSanctionLicenseMutationResponse>;
  /** delete single row from the table: "userSanctionLicense" */
  delete_userSanctionLicense_by_pk?: Maybe<UserSanctionLicense>;
  /** delete data from the table: "userSavedCoach" */
  delete_userSavedCoach?: Maybe<UserSavedCoachMutationResponse>;
  /** delete single row from the table: "userSavedCoach" */
  delete_userSavedCoach_by_pk?: Maybe<UserSavedCoach>;
  /** delete data from the table: "userSavedEvent" */
  delete_userSavedEvent?: Maybe<UserSavedEventMutationResponse>;
  /** delete single row from the table: "userSavedEvent" */
  delete_userSavedEvent_by_pk?: Maybe<UserSavedEvent>;
  /** delete data from the table: "userSavedLesson" */
  delete_userSavedLesson?: Maybe<UserSavedLessonMutationResponse>;
  /** delete single row from the table: "userSavedLesson" */
  delete_userSavedLesson_by_pk?: Maybe<UserSavedLesson>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "venue" */
  delete_venue?: Maybe<VenueMutationResponse>;
  /** delete single row from the table: "venue" */
  delete_venue_by_pk?: Maybe<Venue>;
  /** delete data from the table: "volunteerTaskDifficulties" */
  delete_volunteerTaskDifficulties?: Maybe<VolunteerTaskDifficultiesMutationResponse>;
  /** delete single row from the table: "volunteerTaskDifficulties" */
  delete_volunteerTaskDifficulties_by_pk?: Maybe<VolunteerTaskDifficulties>;
  /** delete data from the table: "volunteerTaskPriorities" */
  delete_volunteerTaskPriorities?: Maybe<VolunteerTaskPrioritiesMutationResponse>;
  /** delete single row from the table: "volunteerTaskPriorities" */
  delete_volunteerTaskPriorities_by_pk?: Maybe<VolunteerTaskPriorities>;
  /** delete data from the table: "waiver" */
  delete_waiver?: Maybe<WaiverMutationResponse>;
  /** delete data from the table: "waiverOwner" */
  delete_waiverOwner?: Maybe<WaiverOwnerMutationResponse>;
  /** delete single row from the table: "waiverOwner" */
  delete_waiverOwner_by_pk?: Maybe<WaiverOwner>;
  /** delete data from the table: "waiverRevision" */
  delete_waiverRevision?: Maybe<WaiverRevisionMutationResponse>;
  /** delete single row from the table: "waiverRevision" */
  delete_waiverRevision_by_pk?: Maybe<WaiverRevision>;
  /** delete single row from the table: "waiver" */
  delete_waiver_by_pk?: Maybe<Waiver>;
  /** insert data into the table: "AccessName" */
  insert_AccessName?: Maybe<AccessNameMutationResponse>;
  /** insert a single row into the table: "AccessName" */
  insert_AccessName_one?: Maybe<AccessName>;
  /** insert data into the table: "activityType" */
  insert_activityType?: Maybe<ActivityTypeMutationResponse>;
  /** insert a single row into the table: "activityType" */
  insert_activityType_one?: Maybe<ActivityType>;
  /** insert data into the table: "amenity" */
  insert_amenity?: Maybe<AmenityMutationResponse>;
  /** insert a single row into the table: "amenity" */
  insert_amenity_one?: Maybe<Amenity>;
  /** insert data into the table: "basicSignatureRequest" */
  insert_basicSignatureRequest?: Maybe<BasicSignatureRequestMutationResponse>;
  /** insert a single row into the table: "basicSignatureRequest" */
  insert_basicSignatureRequest_one?: Maybe<BasicSignatureRequest>;
  /** insert data into the table: "category" */
  insert_category?: Maybe<CategoryMutationResponse>;
  /** insert data into the table: "categoryCourse" */
  insert_categoryCourse?: Maybe<CategoryCourseMutationResponse>;
  /** insert a single row into the table: "categoryCourse" */
  insert_categoryCourse_one?: Maybe<CategoryCourse>;
  /** insert a single row into the table: "category" */
  insert_category_one?: Maybe<Category>;
  /** insert data into the table: "changeLogDate" */
  insert_changeLogDate?: Maybe<ChangeLogDateMutationResponse>;
  /** insert a single row into the table: "changeLogDate" */
  insert_changeLogDate_one?: Maybe<ChangeLogDate>;
  /** insert data into the table: "changeLogItem" */
  insert_changeLogItem?: Maybe<ChangeLogItemMutationResponse>;
  /** insert a single row into the table: "changeLogItem" */
  insert_changeLogItem_one?: Maybe<ChangeLogItem>;
  /** insert data into the table: "clinic" */
  insert_clinic?: Maybe<ClinicMutationResponse>;
  /** insert data into the table: "clinicCoach" */
  insert_clinicCoach?: Maybe<ClinicCoachMutationResponse>;
  /** insert a single row into the table: "clinicCoach" */
  insert_clinicCoach_one?: Maybe<ClinicCoach>;
  /** insert data into the table: "clinicDay" */
  insert_clinicDay?: Maybe<ClinicDayMutationResponse>;
  /** insert a single row into the table: "clinicDay" */
  insert_clinicDay_one?: Maybe<ClinicDay>;
  /** insert a single row into the table: "clinic" */
  insert_clinic_one?: Maybe<Clinic>;
  /** insert data into the table: "coach" */
  insert_coach?: Maybe<CoachMutationResponse>;
  /** insert data into the table: "coachAvailability" */
  insert_coachAvailability?: Maybe<CoachAvailabilityMutationResponse>;
  /** insert a single row into the table: "coachAvailability" */
  insert_coachAvailability_one?: Maybe<CoachAvailability>;
  /** insert data into the table: "coachCertification" */
  insert_coachCertification?: Maybe<CoachCertificationMutationResponse>;
  /** insert a single row into the table: "coachCertification" */
  insert_coachCertification_one?: Maybe<CoachCertification>;
  /** insert data into the table: "coachCustomer" */
  insert_coachCustomer?: Maybe<CoachCustomerMutationResponse>;
  /** insert data into the table: "coachCustomerLesson" */
  insert_coachCustomerLesson?: Maybe<CoachCustomerLessonMutationResponse>;
  /** insert data into the table: "coachCustomerLessonDate" */
  insert_coachCustomerLessonDate?: Maybe<CoachCustomerLessonDateMutationResponse>;
  /** insert data into the table: "coachCustomerLessonDateLocation" */
  insert_coachCustomerLessonDateLocation?: Maybe<CoachCustomerLessonDateLocationMutationResponse>;
  /** insert a single row into the table: "coachCustomerLessonDateLocation" */
  insert_coachCustomerLessonDateLocation_one?: Maybe<CoachCustomerLessonDateLocation>;
  /** insert data into the table: "coachCustomerLessonDateVirtualLocation" */
  insert_coachCustomerLessonDateVirtualLocation?: Maybe<CoachCustomerLessonDateVirtualLocationMutationResponse>;
  /** insert a single row into the table: "coachCustomerLessonDateVirtualLocation" */
  insert_coachCustomerLessonDateVirtualLocation_one?: Maybe<CoachCustomerLessonDateVirtualLocation>;
  /** insert a single row into the table: "coachCustomerLessonDate" */
  insert_coachCustomerLessonDate_one?: Maybe<CoachCustomerLessonDate>;
  /** insert data into the table: "coachCustomerLessonOneTimeCharge" */
  insert_coachCustomerLessonOneTimeCharge?: Maybe<CoachCustomerLessonOneTimeChargeMutationResponse>;
  /** insert a single row into the table: "coachCustomerLessonOneTimeCharge" */
  insert_coachCustomerLessonOneTimeCharge_one?: Maybe<CoachCustomerLessonOneTimeCharge>;
  /** insert data into the table: "coachCustomerLessonPauseResume" */
  insert_coachCustomerLessonPauseResume?: Maybe<CoachCustomerLessonPauseResumeMutationResponse>;
  /** insert a single row into the table: "coachCustomerLessonPauseResume" */
  insert_coachCustomerLessonPauseResume_one?: Maybe<CoachCustomerLessonPauseResume>;
  /** insert data into the table: "coachCustomerLessonTransaction" */
  insert_coachCustomerLessonTransaction?: Maybe<CoachCustomerLessonTransactionMutationResponse>;
  /** insert a single row into the table: "coachCustomerLessonTransaction" */
  insert_coachCustomerLessonTransaction_one?: Maybe<CoachCustomerLessonTransaction>;
  /** insert a single row into the table: "coachCustomerLesson" */
  insert_coachCustomerLesson_one?: Maybe<CoachCustomerLesson>;
  /** insert data into the table: "coachCustomerMessageRequest" */
  insert_coachCustomerMessageRequest?: Maybe<CoachCustomerMessageRequestMutationResponse>;
  /** insert a single row into the table: "coachCustomerMessageRequest" */
  insert_coachCustomerMessageRequest_one?: Maybe<CoachCustomerMessageRequest>;
  /** insert data into the table: "coachCustomerUser" */
  insert_coachCustomerUser?: Maybe<CoachCustomerUserMutationResponse>;
  /** insert a single row into the table: "coachCustomerUser" */
  insert_coachCustomerUser_one?: Maybe<CoachCustomerUser>;
  /** insert a single row into the table: "coachCustomer" */
  insert_coachCustomer_one?: Maybe<CoachCustomer>;
  /** insert data into the table: "coachDiscipline" */
  insert_coachDiscipline?: Maybe<CoachDisciplineMutationResponse>;
  /** insert a single row into the table: "coachDiscipline" */
  insert_coachDiscipline_one?: Maybe<CoachDiscipline>;
  /** insert data into the table: "coachImage" */
  insert_coachImage?: Maybe<CoachImageMutationResponse>;
  /** insert a single row into the table: "coachImage" */
  insert_coachImage_one?: Maybe<CoachImage>;
  /** insert data into the table: "coachLesson" */
  insert_coachLesson?: Maybe<CoachLessonMutationResponse>;
  /** insert data into the table: "coachLessonLocation" */
  insert_coachLessonLocation?: Maybe<CoachLessonLocationMutationResponse>;
  /** insert a single row into the table: "coachLessonLocation" */
  insert_coachLessonLocation_one?: Maybe<CoachLessonLocation>;
  /** insert a single row into the table: "coachLesson" */
  insert_coachLesson_one?: Maybe<CoachLesson>;
  /** insert data into the table: "coachLocation" */
  insert_coachLocation?: Maybe<CoachLocationMutationResponse>;
  /** insert a single row into the table: "coachLocation" */
  insert_coachLocation_one?: Maybe<CoachLocation>;
  /** insert data into the table: "coachModerationFlag" */
  insert_coachModerationFlag?: Maybe<CoachModerationFlagMutationResponse>;
  /** insert a single row into the table: "coachModerationFlag" */
  insert_coachModerationFlag_one?: Maybe<CoachModerationFlag>;
  /** insert data into the table: "coachStudent" */
  insert_coachStudent?: Maybe<CoachStudentMutationResponse>;
  /** insert a single row into the table: "coachStudent" */
  insert_coachStudent_one?: Maybe<CoachStudent>;
  /** insert data into the table: "coachUnavailableDate" */
  insert_coachUnavailableDate?: Maybe<CoachUnavailableDateMutationResponse>;
  /** insert a single row into the table: "coachUnavailableDate" */
  insert_coachUnavailableDate_one?: Maybe<CoachUnavailableDate>;
  /** insert a single row into the table: "coach" */
  insert_coach_one?: Maybe<Coach>;
  /** insert data into the table: "course" */
  insert_course?: Maybe<CourseMutationResponse>;
  /** insert a single row into the table: "course" */
  insert_course_one?: Maybe<Course>;
  /** insert data into the table: "creatorInvite" */
  insert_creatorInvite?: Maybe<CreatorInviteMutationResponse>;
  /** insert a single row into the table: "creatorInvite" */
  insert_creatorInvite_one?: Maybe<CreatorInvite>;
  /** insert data into the table: "event" */
  insert_event?: Maybe<EventMutationResponse>;
  /** insert data into the table: "eventAdditionalInfo" */
  insert_eventAdditionalInfo?: Maybe<EventAdditionalInfoMutationResponse>;
  /** insert a single row into the table: "eventAdditionalInfo" */
  insert_eventAdditionalInfo_one?: Maybe<EventAdditionalInfo>;
  /** insert data into the table: "eventAmenity" */
  insert_eventAmenity?: Maybe<EventAmenityMutationResponse>;
  /** insert a single row into the table: "eventAmenity" */
  insert_eventAmenity_one?: Maybe<EventAmenity>;
  /** insert data into the table: "eventBasicWaiver" */
  insert_eventBasicWaiver?: Maybe<EventBasicWaiverMutationResponse>;
  /** insert data into the table: "eventBasicWaiverCategory" */
  insert_eventBasicWaiverCategory?: Maybe<EventBasicWaiverCategoryMutationResponse>;
  /** insert a single row into the table: "eventBasicWaiverCategory" */
  insert_eventBasicWaiverCategory_one?: Maybe<EventBasicWaiverCategory>;
  /** insert a single row into the table: "eventBasicWaiver" */
  insert_eventBasicWaiver_one?: Maybe<EventBasicWaiver>;
  /** insert data into the table: "eventChecklistItem" */
  insert_eventChecklistItem?: Maybe<EventChecklistItemMutationResponse>;
  /** insert a single row into the table: "eventChecklistItem" */
  insert_eventChecklistItem_one?: Maybe<EventChecklistItem>;
  /** insert data into the table: "eventClinicDayDuration" */
  insert_eventClinicDayDuration?: Maybe<EventClinicDayDurationMutationResponse>;
  /** insert a single row into the table: "eventClinicDayDuration" */
  insert_eventClinicDayDuration_one?: Maybe<EventClinicDayDuration>;
  /** insert data into the table: "eventCustomQuestion" */
  insert_eventCustomQuestion?: Maybe<EventCustomQuestionMutationResponse>;
  /** insert data into the table: "eventCustomQuestionCategory" */
  insert_eventCustomQuestionCategory?: Maybe<EventCustomQuestionCategoryMutationResponse>;
  /** insert a single row into the table: "eventCustomQuestionCategory" */
  insert_eventCustomQuestionCategory_one?: Maybe<EventCustomQuestionCategory>;
  /** insert data into the table: "eventCustomQuestionOption" */
  insert_eventCustomQuestionOption?: Maybe<EventCustomQuestionOptionMutationResponse>;
  /** insert a single row into the table: "eventCustomQuestionOption" */
  insert_eventCustomQuestionOption_one?: Maybe<EventCustomQuestionOption>;
  /** insert a single row into the table: "eventCustomQuestion" */
  insert_eventCustomQuestion_one?: Maybe<EventCustomQuestion>;
  /** insert data into the table: "eventDiscipline" */
  insert_eventDiscipline?: Maybe<EventDisciplineMutationResponse>;
  /** insert a single row into the table: "eventDiscipline" */
  insert_eventDiscipline_one?: Maybe<EventDiscipline>;
  /** insert data into the table: "eventImage" */
  insert_eventImage?: Maybe<EventImageMutationResponse>;
  /** insert a single row into the table: "eventImage" */
  insert_eventImage_one?: Maybe<EventImage>;
  /** insert data into the table: "eventMerchandise" */
  insert_eventMerchandise?: Maybe<EventMerchandiseMutationResponse>;
  /** insert a single row into the table: "eventMerchandise" */
  insert_eventMerchandise_one?: Maybe<EventMerchandise>;
  /** insert data into the table: "eventMetadata" */
  insert_eventMetadata?: Maybe<EventMetadataMutationResponse>;
  /** insert a single row into the table: "eventMetadata" */
  insert_eventMetadata_one?: Maybe<EventMetadata>;
  /** insert data into the table: "eventModerationFlag" */
  insert_eventModerationFlag?: Maybe<EventModerationFlagMutationResponse>;
  /** insert a single row into the table: "eventModerationFlag" */
  insert_eventModerationFlag_one?: Maybe<EventModerationFlag>;
  /** insert data into the table: "eventPitSpace" */
  insert_eventPitSpace?: Maybe<EventPitSpaceMutationResponse>;
  /** insert data into the table: "eventPitSpaceAddOn" */
  insert_eventPitSpaceAddOn?: Maybe<EventPitSpaceAddOnMutationResponse>;
  /** insert a single row into the table: "eventPitSpaceAddOn" */
  insert_eventPitSpaceAddOn_one?: Maybe<EventPitSpaceAddOn>;
  /** insert a single row into the table: "eventPitSpace" */
  insert_eventPitSpace_one?: Maybe<EventPitSpace>;
  /** insert data into the table: "eventPricingChange" */
  insert_eventPricingChange?: Maybe<EventPricingChangeMutationResponse>;
  /** insert a single row into the table: "eventPricingChange" */
  insert_eventPricingChange_one?: Maybe<EventPricingChange>;
  /** insert data into the table: "eventPricingDate" */
  insert_eventPricingDate?: Maybe<EventPricingDateMutationResponse>;
  /** insert a single row into the table: "eventPricingDate" */
  insert_eventPricingDate_one?: Maybe<EventPricingDate>;
  /** insert data into the table: "eventPricingGroup" */
  insert_eventPricingGroup?: Maybe<EventPricingGroupMutationResponse>;
  /** insert data into the table: "eventPricingGroupCategory" */
  insert_eventPricingGroupCategory?: Maybe<EventPricingGroupCategoryMutationResponse>;
  /** insert a single row into the table: "eventPricingGroupCategory" */
  insert_eventPricingGroupCategory_one?: Maybe<EventPricingGroupCategory>;
  /** insert a single row into the table: "eventPricingGroup" */
  insert_eventPricingGroup_one?: Maybe<EventPricingGroup>;
  /** insert data into the table: "eventPrivateLink" */
  insert_eventPrivateLink?: Maybe<EventPrivateLinkMutationResponse>;
  /** insert a single row into the table: "eventPrivateLink" */
  insert_eventPrivateLink_one?: Maybe<EventPrivateLink>;
  /** insert data into the table: "eventProgram" */
  insert_eventProgram?: Maybe<EventProgramMutationResponse>;
  /** insert a single row into the table: "eventProgram" */
  insert_eventProgram_one?: Maybe<EventProgram>;
  /** insert data into the table: "eventPromoCode" */
  insert_eventPromoCode?: Maybe<EventPromoCodeMutationResponse>;
  /** insert a single row into the table: "eventPromoCode" */
  insert_eventPromoCode_one?: Maybe<EventPromoCode>;
  /** insert data into the table: "eventQuestion" */
  insert_eventQuestion?: Maybe<EventQuestionMutationResponse>;
  /** insert data into the table: "eventQuestionAnswer" */
  insert_eventQuestionAnswer?: Maybe<EventQuestionAnswerMutationResponse>;
  /** insert a single row into the table: "eventQuestionAnswer" */
  insert_eventQuestionAnswer_one?: Maybe<EventQuestionAnswer>;
  /** insert a single row into the table: "eventQuestion" */
  insert_eventQuestion_one?: Maybe<EventQuestion>;
  /** insert data into the table: "eventSanction" */
  insert_eventSanction?: Maybe<EventSanctionMutationResponse>;
  /** insert data into the table: "eventSanctionCategory" */
  insert_eventSanctionCategory?: Maybe<EventSanctionCategoryMutationResponse>;
  /** insert a single row into the table: "eventSanctionCategory" */
  insert_eventSanctionCategory_one?: Maybe<EventSanctionCategory>;
  /** insert a single row into the table: "eventSanction" */
  insert_eventSanction_one?: Maybe<EventSanction>;
  /** insert data into the table: "eventSchedule" */
  insert_eventSchedule?: Maybe<EventScheduleMutationResponse>;
  /** insert data into the table: "eventScheduleCategory" */
  insert_eventScheduleCategory?: Maybe<EventScheduleCategoryMutationResponse>;
  /** insert a single row into the table: "eventScheduleCategory" */
  insert_eventScheduleCategory_one?: Maybe<EventScheduleCategory>;
  /** insert data into the table: "eventScheduleDate" */
  insert_eventScheduleDate?: Maybe<EventScheduleDateMutationResponse>;
  /** insert a single row into the table: "eventScheduleDate" */
  insert_eventScheduleDate_one?: Maybe<EventScheduleDate>;
  /** insert data into the table: "eventScheduleTypeCustom" */
  insert_eventScheduleTypeCustom?: Maybe<EventScheduleTypeCustomMutationResponse>;
  /** insert a single row into the table: "eventScheduleTypeCustom" */
  insert_eventScheduleTypeCustom_one?: Maybe<EventScheduleTypeCustom>;
  /** insert data into the table: "eventScheduleTypePreset" */
  insert_eventScheduleTypePreset?: Maybe<EventScheduleTypePresetMutationResponse>;
  /** insert a single row into the table: "eventScheduleTypePreset" */
  insert_eventScheduleTypePreset_one?: Maybe<EventScheduleTypePreset>;
  /** insert a single row into the table: "eventSchedule" */
  insert_eventSchedule_one?: Maybe<EventSchedule>;
  /** insert data into the table: "eventSeries" */
  insert_eventSeries?: Maybe<EventSeriesMutationResponse>;
  /** insert data into the table: "eventSeriesCategory" */
  insert_eventSeriesCategory?: Maybe<EventSeriesCategoryMutationResponse>;
  /** insert a single row into the table: "eventSeriesCategory" */
  insert_eventSeriesCategory_one?: Maybe<EventSeriesCategory>;
  /** insert a single row into the table: "eventSeries" */
  insert_eventSeries_one?: Maybe<EventSeries>;
  /** insert data into the table: "eventSponsor" */
  insert_eventSponsor?: Maybe<EventSponsorMutationResponse>;
  /** insert a single row into the table: "eventSponsor" */
  insert_eventSponsor_one?: Maybe<EventSponsor>;
  /** insert data into the table: "eventTicket" */
  insert_eventTicket?: Maybe<EventTicketMutationResponse>;
  /** insert a single row into the table: "eventTicket" */
  insert_eventTicket_one?: Maybe<EventTicket>;
  /** insert data into the table: "eventUpdate" */
  insert_eventUpdate?: Maybe<EventUpdateMutationResponse>;
  /** insert data into the table: "eventUpdateComment" */
  insert_eventUpdateComment?: Maybe<EventUpdateCommentMutationResponse>;
  /** insert a single row into the table: "eventUpdateComment" */
  insert_eventUpdateComment_one?: Maybe<EventUpdateComment>;
  /** insert a single row into the table: "eventUpdate" */
  insert_eventUpdate_one?: Maybe<EventUpdate>;
  /** insert data into the table: "eventVenue" */
  insert_eventVenue?: Maybe<EventVenueMutationResponse>;
  /** insert a single row into the table: "eventVenue" */
  insert_eventVenue_one?: Maybe<EventVenue>;
  /** insert data into the table: "eventVibe" */
  insert_eventVibe?: Maybe<EventVibeMutationResponse>;
  /** insert a single row into the table: "eventVibe" */
  insert_eventVibe_one?: Maybe<EventVibe>;
  /** insert data into the table: "eventVirtualVenue" */
  insert_eventVirtualVenue?: Maybe<EventVirtualVenueMutationResponse>;
  /** insert a single row into the table: "eventVirtualVenue" */
  insert_eventVirtualVenue_one?: Maybe<EventVirtualVenue>;
  /** insert data into the table: "eventVolunteerJob" */
  insert_eventVolunteerJob?: Maybe<EventVolunteerJobMutationResponse>;
  /** insert data into the table: "eventVolunteerJobShift" */
  insert_eventVolunteerJobShift?: Maybe<EventVolunteerJobShiftMutationResponse>;
  /** insert data into the table: "eventVolunteerJobShiftTask" */
  insert_eventVolunteerJobShiftTask?: Maybe<EventVolunteerJobShiftTaskMutationResponse>;
  /** insert a single row into the table: "eventVolunteerJobShiftTask" */
  insert_eventVolunteerJobShiftTask_one?: Maybe<EventVolunteerJobShiftTask>;
  /** insert a single row into the table: "eventVolunteerJobShift" */
  insert_eventVolunteerJobShift_one?: Maybe<EventVolunteerJobShift>;
  /** insert data into the table: "eventVolunteerJobTask" */
  insert_eventVolunteerJobTask?: Maybe<EventVolunteerJobTaskMutationResponse>;
  /** insert a single row into the table: "eventVolunteerJobTask" */
  insert_eventVolunteerJobTask_one?: Maybe<EventVolunteerJobTask>;
  /** insert a single row into the table: "eventVolunteerJob" */
  insert_eventVolunteerJob_one?: Maybe<EventVolunteerJob>;
  /** insert data into the table: "eventWaiver" */
  insert_eventWaiver?: Maybe<EventWaiverMutationResponse>;
  /** insert data into the table: "eventWaiverType" */
  insert_eventWaiverType?: Maybe<EventWaiverTypeMutationResponse>;
  /** insert a single row into the table: "eventWaiverType" */
  insert_eventWaiverType_one?: Maybe<EventWaiverType>;
  /** insert a single row into the table: "eventWaiver" */
  insert_eventWaiver_one?: Maybe<EventWaiver>;
  /** insert a single row into the table: "event" */
  insert_event_one?: Maybe<Event>;
  /** insert data into the table: "image" */
  insert_image?: Maybe<ImageMutationResponse>;
  /** insert data into the table: "imageOnlineCourse" */
  insert_imageOnlineCourse?: Maybe<ImageOnlineCourseMutationResponse>;
  /** insert a single row into the table: "imageOnlineCourse" */
  insert_imageOnlineCourse_one?: Maybe<ImageOnlineCourse>;
  /** insert data into the table: "imageUser" */
  insert_imageUser?: Maybe<ImageUserMutationResponse>;
  /** insert a single row into the table: "imageUser" */
  insert_imageUser_one?: Maybe<ImageUser>;
  /** insert a single row into the table: "image" */
  insert_image_one?: Maybe<Image>;
  /** insert data into the table: "lessonLocation" */
  insert_lessonLocation?: Maybe<LessonLocationMutationResponse>;
  /** insert a single row into the table: "lessonLocation" */
  insert_lessonLocation_one?: Maybe<LessonLocation>;
  /** insert data into the table: "merchandise" */
  insert_merchandise?: Maybe<MerchandiseMutationResponse>;
  /** insert data into the table: "merchandiseDeliveryType" */
  insert_merchandiseDeliveryType?: Maybe<MerchandiseDeliveryTypeMutationResponse>;
  /** insert a single row into the table: "merchandiseDeliveryType" */
  insert_merchandiseDeliveryType_one?: Maybe<MerchandiseDeliveryType>;
  /** insert data into the table: "merchandiseImage" */
  insert_merchandiseImage?: Maybe<MerchandiseImageMutationResponse>;
  /** insert a single row into the table: "merchandiseImage" */
  insert_merchandiseImage_one?: Maybe<MerchandiseImage>;
  /** insert data into the table: "merchandiseVariant" */
  insert_merchandiseVariant?: Maybe<MerchandiseVariantMutationResponse>;
  /** insert data into the table: "merchandiseVariantOption" */
  insert_merchandiseVariantOption?: Maybe<MerchandiseVariantOptionMutationResponse>;
  /** insert data into the table: "merchandiseVariantOptionValue" */
  insert_merchandiseVariantOptionValue?: Maybe<MerchandiseVariantOptionValueMutationResponse>;
  /** insert a single row into the table: "merchandiseVariantOptionValue" */
  insert_merchandiseVariantOptionValue_one?: Maybe<MerchandiseVariantOptionValue>;
  /** insert a single row into the table: "merchandiseVariantOption" */
  insert_merchandiseVariantOption_one?: Maybe<MerchandiseVariantOption>;
  /** insert a single row into the table: "merchandiseVariant" */
  insert_merchandiseVariant_one?: Maybe<MerchandiseVariant>;
  /** insert a single row into the table: "merchandise" */
  insert_merchandise_one?: Maybe<Merchandise>;
  /** insert data into the table: "onlineCourse" */
  insert_onlineCourse?: Maybe<OnlineCourseMutationResponse>;
  /** insert data into the table: "onlineCourseOwner" */
  insert_onlineCourseOwner?: Maybe<OnlineCourseOwnerMutationResponse>;
  /** insert a single row into the table: "onlineCourseOwner" */
  insert_onlineCourseOwner_one?: Maybe<OnlineCourseOwner>;
  /** insert data into the table: "onlineCourseRevision" */
  insert_onlineCourseRevision?: Maybe<OnlineCourseRevisionMutationResponse>;
  /** insert a single row into the table: "onlineCourseRevision" */
  insert_onlineCourseRevision_one?: Maybe<OnlineCourseRevision>;
  /** insert a single row into the table: "onlineCourse" */
  insert_onlineCourse_one?: Maybe<OnlineCourse>;
  /** insert data into the table: "organizer" */
  insert_organizer?: Maybe<OrganizerMutationResponse>;
  /** insert data into the table: "organizerChannel" */
  insert_organizerChannel?: Maybe<OrganizerChannelMutationResponse>;
  /** insert a single row into the table: "organizerChannel" */
  insert_organizerChannel_one?: Maybe<OrganizerChannel>;
  /** insert data into the table: "organizerInvite" */
  insert_organizerInvite?: Maybe<OrganizerInviteMutationResponse>;
  /** insert a single row into the table: "organizerInvite" */
  insert_organizerInvite_one?: Maybe<OrganizerInvite>;
  /** insert a single row into the table: "organizer" */
  insert_organizer_one?: Maybe<Organizer>;
  /** insert data into the table: "registration" */
  insert_registration?: Maybe<RegistrationMutationResponse>;
  /** insert data into the table: "registrationActivity" */
  insert_registrationActivity?: Maybe<RegistrationActivityMutationResponse>;
  /** insert a single row into the table: "registrationActivity" */
  insert_registrationActivity_one?: Maybe<RegistrationActivity>;
  /** insert data into the table: "registrationAdditionalPayment" */
  insert_registrationAdditionalPayment?: Maybe<RegistrationAdditionalPaymentMutationResponse>;
  /** insert a single row into the table: "registrationAdditionalPayment" */
  insert_registrationAdditionalPayment_one?: Maybe<RegistrationAdditionalPayment>;
  /** insert data into the table: "registrationCategory" */
  insert_registrationCategory?: Maybe<RegistrationCategoryMutationResponse>;
  /** insert a single row into the table: "registrationCategory" */
  insert_registrationCategory_one?: Maybe<RegistrationCategory>;
  /** insert data into the table: "registrationFor" */
  insert_registrationFor?: Maybe<RegistrationForMutationResponse>;
  /** insert a single row into the table: "registrationFor" */
  insert_registrationFor_one?: Maybe<RegistrationFor>;
  /** insert data into the table: "registrationSeries" */
  insert_registrationSeries?: Maybe<RegistrationSeriesMutationResponse>;
  /** insert a single row into the table: "registrationSeries" */
  insert_registrationSeries_one?: Maybe<RegistrationSeries>;
  /** insert data into the table: "registrationStatus" */
  insert_registrationStatus?: Maybe<RegistrationStatusMutationResponse>;
  /** insert a single row into the table: "registrationStatus" */
  insert_registrationStatus_one?: Maybe<RegistrationStatus>;
  /** insert data into the table: "registrationTicket" */
  insert_registrationTicket?: Maybe<RegistrationTicketMutationResponse>;
  /** insert a single row into the table: "registrationTicket" */
  insert_registrationTicket_one?: Maybe<RegistrationTicket>;
  /** insert a single row into the table: "registration" */
  insert_registration_one?: Maybe<Registration>;
  /** insert data into the table: "reportClinicNoShow" */
  insert_reportClinicNoShow?: Maybe<ReportClinicNoShowMutationResponse>;
  /** insert a single row into the table: "reportClinicNoShow" */
  insert_reportClinicNoShow_one?: Maybe<ReportClinicNoShow>;
  /** insert data into the table: "reportLessonNoShow" */
  insert_reportLessonNoShow?: Maybe<ReportLessonNoShowMutationResponse>;
  /** insert a single row into the table: "reportLessonNoShow" */
  insert_reportLessonNoShow_one?: Maybe<ReportLessonNoShow>;
  /** insert data into the table: "review" */
  insert_review?: Maybe<ReviewMutationResponse>;
  /** insert data into the table: "reviewSubjectType" */
  insert_reviewSubjectType?: Maybe<ReviewSubjectTypeMutationResponse>;
  /** insert a single row into the table: "reviewSubjectType" */
  insert_reviewSubjectType_one?: Maybe<ReviewSubjectType>;
  /** insert data into the table: "reviewTotals" */
  insert_reviewTotals?: Maybe<ReviewTotalsMutationResponse>;
  /** insert a single row into the table: "reviewTotals" */
  insert_reviewTotals_one?: Maybe<ReviewTotals>;
  /** insert a single row into the table: "review" */
  insert_review_one?: Maybe<Review>;
  /** insert data into the table: "ride" */
  insert_ride?: Maybe<RideMutationResponse>;
  /** insert data into the table: "rideDay" */
  insert_rideDay?: Maybe<RideDayMutationResponse>;
  /** insert a single row into the table: "rideDay" */
  insert_rideDay_one?: Maybe<RideDay>;
  /** insert a single row into the table: "ride" */
  insert_ride_one?: Maybe<Ride>;
  /** insert data into the table: "roleOrganizer" */
  insert_roleOrganizer?: Maybe<RoleOrganizerMutationResponse>;
  /** insert a single row into the table: "roleOrganizer" */
  insert_roleOrganizer_one?: Maybe<RoleOrganizer>;
  /** insert data into the table: "sanction" */
  insert_sanction?: Maybe<SanctionMutationResponse>;
  /** insert data into the table: "sanctionWaivers" */
  insert_sanctionWaivers?: Maybe<SanctionWaiversMutationResponse>;
  /** insert a single row into the table: "sanctionWaivers" */
  insert_sanctionWaivers_one?: Maybe<SanctionWaivers>;
  /** insert a single row into the table: "sanction" */
  insert_sanction_one?: Maybe<Sanction>;
  /** insert data into the table: "scheduleTypePreset" */
  insert_scheduleTypePreset?: Maybe<ScheduleTypePresetMutationResponse>;
  /** insert a single row into the table: "scheduleTypePreset" */
  insert_scheduleTypePreset_one?: Maybe<ScheduleTypePreset>;
  /** insert data into the table: "series" */
  insert_series?: Maybe<SeriesMutationResponse>;
  /** insert data into the table: "seriesImage" */
  insert_seriesImage?: Maybe<SeriesImageMutationResponse>;
  /** insert a single row into the table: "seriesImage" */
  insert_seriesImage_one?: Maybe<SeriesImage>;
  /** insert data into the table: "seriesPointsStructureCustom" */
  insert_seriesPointsStructureCustom?: Maybe<SeriesPointsStructureCustomMutationResponse>;
  /** insert a single row into the table: "seriesPointsStructureCustom" */
  insert_seriesPointsStructureCustom_one?: Maybe<SeriesPointsStructureCustom>;
  /** insert data into the table: "seriesPointsStructurePreset" */
  insert_seriesPointsStructurePreset?: Maybe<SeriesPointsStructurePresetMutationResponse>;
  /** insert a single row into the table: "seriesPointsStructurePreset" */
  insert_seriesPointsStructurePreset_one?: Maybe<SeriesPointsStructurePreset>;
  /** insert a single row into the table: "series" */
  insert_series_one?: Maybe<Series>;
  /** insert data into the table: "signatureRequest" */
  insert_signatureRequest?: Maybe<SignatureRequestMutationResponse>;
  /** insert data into the table: "signatureRequestSignerType" */
  insert_signatureRequestSignerType?: Maybe<SignatureRequestSignerTypeMutationResponse>;
  /** insert a single row into the table: "signatureRequestSignerType" */
  insert_signatureRequestSignerType_one?: Maybe<SignatureRequestSignerType>;
  /** insert data into the table: "signatureRequestStatus" */
  insert_signatureRequestStatus?: Maybe<SignatureRequestStatusMutationResponse>;
  /** insert a single row into the table: "signatureRequestStatus" */
  insert_signatureRequestStatus_one?: Maybe<SignatureRequestStatus>;
  /** insert a single row into the table: "signatureRequest" */
  insert_signatureRequest_one?: Maybe<SignatureRequest>;
  /** insert data into the table: "sponsor" */
  insert_sponsor?: Maybe<SponsorMutationResponse>;
  /** insert data into the table: "sponsorImage" */
  insert_sponsorImage?: Maybe<SponsorImageMutationResponse>;
  /** insert a single row into the table: "sponsorImage" */
  insert_sponsorImage_one?: Maybe<SponsorImage>;
  /** insert a single row into the table: "sponsor" */
  insert_sponsor_one?: Maybe<Sponsor>;
  /** insert data into the table: "stripeConnectAccount" */
  insert_stripeConnectAccount?: Maybe<StripeConnectAccountMutationResponse>;
  /** insert a single row into the table: "stripeConnectAccount" */
  insert_stripeConnectAccount_one?: Maybe<StripeConnectAccount>;
  /** insert data into the table: "ticket" */
  insert_ticket?: Maybe<TicketMutationResponse>;
  /** insert a single row into the table: "ticket" */
  insert_ticket_one?: Maybe<Ticket>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<UserMutationResponse>;
  /** insert data into the table: "userAddress" */
  insert_userAddress?: Maybe<UserAddressMutationResponse>;
  /** insert a single row into the table: "userAddress" */
  insert_userAddress_one?: Maybe<UserAddress>;
  /** insert data into the table: "userBike" */
  insert_userBike?: Maybe<UserBikeMutationResponse>;
  /** insert data into the table: "userBikeRevision" */
  insert_userBikeRevision?: Maybe<UserBikeRevisionMutationResponse>;
  /** insert a single row into the table: "userBikeRevision" */
  insert_userBikeRevision_one?: Maybe<UserBikeRevision>;
  /** insert a single row into the table: "userBike" */
  insert_userBike_one?: Maybe<UserBike>;
  /** insert data into the table: "userEmergencyContact" */
  insert_userEmergencyContact?: Maybe<UserEmergencyContactMutationResponse>;
  /** insert a single row into the table: "userEmergencyContact" */
  insert_userEmergencyContact_one?: Maybe<UserEmergencyContact>;
  /** insert data into the table: "userImage" */
  insert_userImage?: Maybe<UserImageMutationResponse>;
  /** insert a single row into the table: "userImage" */
  insert_userImage_one?: Maybe<UserImage>;
  /** insert data into the table: "userOnlineCourse" */
  insert_userOnlineCourse?: Maybe<UserOnlineCourseMutationResponse>;
  /** insert data into the table: "userOnlineCourseStatus" */
  insert_userOnlineCourseStatus?: Maybe<UserOnlineCourseStatusMutationResponse>;
  /** insert a single row into the table: "userOnlineCourseStatus" */
  insert_userOnlineCourseStatus_one?: Maybe<UserOnlineCourseStatus>;
  /** insert a single row into the table: "userOnlineCourse" */
  insert_userOnlineCourse_one?: Maybe<UserOnlineCourse>;
  /** insert data into the table: "userOrganizerRole" */
  insert_userOrganizerRole?: Maybe<UserOrganizerRoleMutationResponse>;
  /** insert a single row into the table: "userOrganizerRole" */
  insert_userOrganizerRole_one?: Maybe<UserOrganizerRole>;
  /** insert data into the table: "userPersonalization" */
  insert_userPersonalization?: Maybe<UserPersonalizationMutationResponse>;
  /** insert a single row into the table: "userPersonalization" */
  insert_userPersonalization_one?: Maybe<UserPersonalization>;
  /** insert data into the table: "userRidingInterest" */
  insert_userRidingInterest?: Maybe<UserRidingInterestMutationResponse>;
  /** insert a single row into the table: "userRidingInterest" */
  insert_userRidingInterest_one?: Maybe<UserRidingInterest>;
  /** insert data into the table: "userSanctionLicense" */
  insert_userSanctionLicense?: Maybe<UserSanctionLicenseMutationResponse>;
  /** insert a single row into the table: "userSanctionLicense" */
  insert_userSanctionLicense_one?: Maybe<UserSanctionLicense>;
  /** insert data into the table: "userSavedCoach" */
  insert_userSavedCoach?: Maybe<UserSavedCoachMutationResponse>;
  /** insert a single row into the table: "userSavedCoach" */
  insert_userSavedCoach_one?: Maybe<UserSavedCoach>;
  /** insert data into the table: "userSavedEvent" */
  insert_userSavedEvent?: Maybe<UserSavedEventMutationResponse>;
  /** insert a single row into the table: "userSavedEvent" */
  insert_userSavedEvent_one?: Maybe<UserSavedEvent>;
  /** insert data into the table: "userSavedLesson" */
  insert_userSavedLesson?: Maybe<UserSavedLessonMutationResponse>;
  /** insert a single row into the table: "userSavedLesson" */
  insert_userSavedLesson_one?: Maybe<UserSavedLesson>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "venue" */
  insert_venue?: Maybe<VenueMutationResponse>;
  /** insert a single row into the table: "venue" */
  insert_venue_one?: Maybe<Venue>;
  /** insert data into the table: "volunteerTaskDifficulties" */
  insert_volunteerTaskDifficulties?: Maybe<VolunteerTaskDifficultiesMutationResponse>;
  /** insert a single row into the table: "volunteerTaskDifficulties" */
  insert_volunteerTaskDifficulties_one?: Maybe<VolunteerTaskDifficulties>;
  /** insert data into the table: "volunteerTaskPriorities" */
  insert_volunteerTaskPriorities?: Maybe<VolunteerTaskPrioritiesMutationResponse>;
  /** insert a single row into the table: "volunteerTaskPriorities" */
  insert_volunteerTaskPriorities_one?: Maybe<VolunteerTaskPriorities>;
  /** insert data into the table: "waiver" */
  insert_waiver?: Maybe<WaiverMutationResponse>;
  /** insert data into the table: "waiverOwner" */
  insert_waiverOwner?: Maybe<WaiverOwnerMutationResponse>;
  /** insert a single row into the table: "waiverOwner" */
  insert_waiverOwner_one?: Maybe<WaiverOwner>;
  /** insert data into the table: "waiverRevision" */
  insert_waiverRevision?: Maybe<WaiverRevisionMutationResponse>;
  /** insert a single row into the table: "waiverRevision" */
  insert_waiverRevision_one?: Maybe<WaiverRevision>;
  /** insert a single row into the table: "waiver" */
  insert_waiver_one?: Maybe<Waiver>;
  /** update data of the table: "AccessName" */
  update_AccessName?: Maybe<AccessNameMutationResponse>;
  /** update single row of the table: "AccessName" */
  update_AccessName_by_pk?: Maybe<AccessName>;
  /** update multiples rows of table: "AccessName" */
  update_AccessName_many?: Maybe<Array<Maybe<AccessNameMutationResponse>>>;
  /** update data of the table: "activityType" */
  update_activityType?: Maybe<ActivityTypeMutationResponse>;
  /** update single row of the table: "activityType" */
  update_activityType_by_pk?: Maybe<ActivityType>;
  /** update multiples rows of table: "activityType" */
  update_activityType_many?: Maybe<Array<Maybe<ActivityTypeMutationResponse>>>;
  /** update data of the table: "amenity" */
  update_amenity?: Maybe<AmenityMutationResponse>;
  /** update single row of the table: "amenity" */
  update_amenity_by_pk?: Maybe<Amenity>;
  /** update multiples rows of table: "amenity" */
  update_amenity_many?: Maybe<Array<Maybe<AmenityMutationResponse>>>;
  /** update data of the table: "basicSignatureRequest" */
  update_basicSignatureRequest?: Maybe<BasicSignatureRequestMutationResponse>;
  /** update single row of the table: "basicSignatureRequest" */
  update_basicSignatureRequest_by_pk?: Maybe<BasicSignatureRequest>;
  /** update multiples rows of table: "basicSignatureRequest" */
  update_basicSignatureRequest_many?: Maybe<Array<Maybe<BasicSignatureRequestMutationResponse>>>;
  /** update data of the table: "category" */
  update_category?: Maybe<CategoryMutationResponse>;
  /** update data of the table: "categoryCourse" */
  update_categoryCourse?: Maybe<CategoryCourseMutationResponse>;
  /** update single row of the table: "categoryCourse" */
  update_categoryCourse_by_pk?: Maybe<CategoryCourse>;
  /** update multiples rows of table: "categoryCourse" */
  update_categoryCourse_many?: Maybe<Array<Maybe<CategoryCourseMutationResponse>>>;
  /** update single row of the table: "category" */
  update_category_by_pk?: Maybe<Category>;
  /** update multiples rows of table: "category" */
  update_category_many?: Maybe<Array<Maybe<CategoryMutationResponse>>>;
  /** update data of the table: "changeLogDate" */
  update_changeLogDate?: Maybe<ChangeLogDateMutationResponse>;
  /** update single row of the table: "changeLogDate" */
  update_changeLogDate_by_pk?: Maybe<ChangeLogDate>;
  /** update multiples rows of table: "changeLogDate" */
  update_changeLogDate_many?: Maybe<Array<Maybe<ChangeLogDateMutationResponse>>>;
  /** update data of the table: "changeLogItem" */
  update_changeLogItem?: Maybe<ChangeLogItemMutationResponse>;
  /** update single row of the table: "changeLogItem" */
  update_changeLogItem_by_pk?: Maybe<ChangeLogItem>;
  /** update multiples rows of table: "changeLogItem" */
  update_changeLogItem_many?: Maybe<Array<Maybe<ChangeLogItemMutationResponse>>>;
  /** update data of the table: "clinic" */
  update_clinic?: Maybe<ClinicMutationResponse>;
  /** update data of the table: "clinicCoach" */
  update_clinicCoach?: Maybe<ClinicCoachMutationResponse>;
  /** update single row of the table: "clinicCoach" */
  update_clinicCoach_by_pk?: Maybe<ClinicCoach>;
  /** update multiples rows of table: "clinicCoach" */
  update_clinicCoach_many?: Maybe<Array<Maybe<ClinicCoachMutationResponse>>>;
  /** update data of the table: "clinicDay" */
  update_clinicDay?: Maybe<ClinicDayMutationResponse>;
  /** update single row of the table: "clinicDay" */
  update_clinicDay_by_pk?: Maybe<ClinicDay>;
  /** update multiples rows of table: "clinicDay" */
  update_clinicDay_many?: Maybe<Array<Maybe<ClinicDayMutationResponse>>>;
  /** update single row of the table: "clinic" */
  update_clinic_by_pk?: Maybe<Clinic>;
  /** update multiples rows of table: "clinic" */
  update_clinic_many?: Maybe<Array<Maybe<ClinicMutationResponse>>>;
  /** update data of the table: "coach" */
  update_coach?: Maybe<CoachMutationResponse>;
  /** update data of the table: "coachAvailability" */
  update_coachAvailability?: Maybe<CoachAvailabilityMutationResponse>;
  /** update single row of the table: "coachAvailability" */
  update_coachAvailability_by_pk?: Maybe<CoachAvailability>;
  /** update multiples rows of table: "coachAvailability" */
  update_coachAvailability_many?: Maybe<Array<Maybe<CoachAvailabilityMutationResponse>>>;
  /** update data of the table: "coachCertification" */
  update_coachCertification?: Maybe<CoachCertificationMutationResponse>;
  /** update single row of the table: "coachCertification" */
  update_coachCertification_by_pk?: Maybe<CoachCertification>;
  /** update multiples rows of table: "coachCertification" */
  update_coachCertification_many?: Maybe<Array<Maybe<CoachCertificationMutationResponse>>>;
  /** update data of the table: "coachCustomer" */
  update_coachCustomer?: Maybe<CoachCustomerMutationResponse>;
  /** update data of the table: "coachCustomerLesson" */
  update_coachCustomerLesson?: Maybe<CoachCustomerLessonMutationResponse>;
  /** update data of the table: "coachCustomerLessonDate" */
  update_coachCustomerLessonDate?: Maybe<CoachCustomerLessonDateMutationResponse>;
  /** update data of the table: "coachCustomerLessonDateLocation" */
  update_coachCustomerLessonDateLocation?: Maybe<CoachCustomerLessonDateLocationMutationResponse>;
  /** update single row of the table: "coachCustomerLessonDateLocation" */
  update_coachCustomerLessonDateLocation_by_pk?: Maybe<CoachCustomerLessonDateLocation>;
  /** update multiples rows of table: "coachCustomerLessonDateLocation" */
  update_coachCustomerLessonDateLocation_many?: Maybe<Array<Maybe<CoachCustomerLessonDateLocationMutationResponse>>>;
  /** update data of the table: "coachCustomerLessonDateVirtualLocation" */
  update_coachCustomerLessonDateVirtualLocation?: Maybe<CoachCustomerLessonDateVirtualLocationMutationResponse>;
  /** update single row of the table: "coachCustomerLessonDateVirtualLocation" */
  update_coachCustomerLessonDateVirtualLocation_by_pk?: Maybe<CoachCustomerLessonDateVirtualLocation>;
  /** update multiples rows of table: "coachCustomerLessonDateVirtualLocation" */
  update_coachCustomerLessonDateVirtualLocation_many?: Maybe<Array<Maybe<CoachCustomerLessonDateVirtualLocationMutationResponse>>>;
  /** update single row of the table: "coachCustomerLessonDate" */
  update_coachCustomerLessonDate_by_pk?: Maybe<CoachCustomerLessonDate>;
  /** update multiples rows of table: "coachCustomerLessonDate" */
  update_coachCustomerLessonDate_many?: Maybe<Array<Maybe<CoachCustomerLessonDateMutationResponse>>>;
  /** update data of the table: "coachCustomerLessonOneTimeCharge" */
  update_coachCustomerLessonOneTimeCharge?: Maybe<CoachCustomerLessonOneTimeChargeMutationResponse>;
  /** update single row of the table: "coachCustomerLessonOneTimeCharge" */
  update_coachCustomerLessonOneTimeCharge_by_pk?: Maybe<CoachCustomerLessonOneTimeCharge>;
  /** update multiples rows of table: "coachCustomerLessonOneTimeCharge" */
  update_coachCustomerLessonOneTimeCharge_many?: Maybe<Array<Maybe<CoachCustomerLessonOneTimeChargeMutationResponse>>>;
  /** update data of the table: "coachCustomerLessonPauseResume" */
  update_coachCustomerLessonPauseResume?: Maybe<CoachCustomerLessonPauseResumeMutationResponse>;
  /** update single row of the table: "coachCustomerLessonPauseResume" */
  update_coachCustomerLessonPauseResume_by_pk?: Maybe<CoachCustomerLessonPauseResume>;
  /** update multiples rows of table: "coachCustomerLessonPauseResume" */
  update_coachCustomerLessonPauseResume_many?: Maybe<Array<Maybe<CoachCustomerLessonPauseResumeMutationResponse>>>;
  /** update data of the table: "coachCustomerLessonTransaction" */
  update_coachCustomerLessonTransaction?: Maybe<CoachCustomerLessonTransactionMutationResponse>;
  /** update single row of the table: "coachCustomerLessonTransaction" */
  update_coachCustomerLessonTransaction_by_pk?: Maybe<CoachCustomerLessonTransaction>;
  /** update multiples rows of table: "coachCustomerLessonTransaction" */
  update_coachCustomerLessonTransaction_many?: Maybe<Array<Maybe<CoachCustomerLessonTransactionMutationResponse>>>;
  /** update single row of the table: "coachCustomerLesson" */
  update_coachCustomerLesson_by_pk?: Maybe<CoachCustomerLesson>;
  /** update multiples rows of table: "coachCustomerLesson" */
  update_coachCustomerLesson_many?: Maybe<Array<Maybe<CoachCustomerLessonMutationResponse>>>;
  /** update data of the table: "coachCustomerMessageRequest" */
  update_coachCustomerMessageRequest?: Maybe<CoachCustomerMessageRequestMutationResponse>;
  /** update single row of the table: "coachCustomerMessageRequest" */
  update_coachCustomerMessageRequest_by_pk?: Maybe<CoachCustomerMessageRequest>;
  /** update multiples rows of table: "coachCustomerMessageRequest" */
  update_coachCustomerMessageRequest_many?: Maybe<Array<Maybe<CoachCustomerMessageRequestMutationResponse>>>;
  /** update data of the table: "coachCustomerUser" */
  update_coachCustomerUser?: Maybe<CoachCustomerUserMutationResponse>;
  /** update single row of the table: "coachCustomerUser" */
  update_coachCustomerUser_by_pk?: Maybe<CoachCustomerUser>;
  /** update multiples rows of table: "coachCustomerUser" */
  update_coachCustomerUser_many?: Maybe<Array<Maybe<CoachCustomerUserMutationResponse>>>;
  /** update single row of the table: "coachCustomer" */
  update_coachCustomer_by_pk?: Maybe<CoachCustomer>;
  /** update multiples rows of table: "coachCustomer" */
  update_coachCustomer_many?: Maybe<Array<Maybe<CoachCustomerMutationResponse>>>;
  /** update data of the table: "coachDiscipline" */
  update_coachDiscipline?: Maybe<CoachDisciplineMutationResponse>;
  /** update single row of the table: "coachDiscipline" */
  update_coachDiscipline_by_pk?: Maybe<CoachDiscipline>;
  /** update multiples rows of table: "coachDiscipline" */
  update_coachDiscipline_many?: Maybe<Array<Maybe<CoachDisciplineMutationResponse>>>;
  /** update data of the table: "coachImage" */
  update_coachImage?: Maybe<CoachImageMutationResponse>;
  /** update single row of the table: "coachImage" */
  update_coachImage_by_pk?: Maybe<CoachImage>;
  /** update multiples rows of table: "coachImage" */
  update_coachImage_many?: Maybe<Array<Maybe<CoachImageMutationResponse>>>;
  /** update data of the table: "coachLesson" */
  update_coachLesson?: Maybe<CoachLessonMutationResponse>;
  /** update data of the table: "coachLessonLocation" */
  update_coachLessonLocation?: Maybe<CoachLessonLocationMutationResponse>;
  /** update single row of the table: "coachLessonLocation" */
  update_coachLessonLocation_by_pk?: Maybe<CoachLessonLocation>;
  /** update multiples rows of table: "coachLessonLocation" */
  update_coachLessonLocation_many?: Maybe<Array<Maybe<CoachLessonLocationMutationResponse>>>;
  /** update single row of the table: "coachLesson" */
  update_coachLesson_by_pk?: Maybe<CoachLesson>;
  /** update multiples rows of table: "coachLesson" */
  update_coachLesson_many?: Maybe<Array<Maybe<CoachLessonMutationResponse>>>;
  /** update data of the table: "coachLocation" */
  update_coachLocation?: Maybe<CoachLocationMutationResponse>;
  /** update single row of the table: "coachLocation" */
  update_coachLocation_by_pk?: Maybe<CoachLocation>;
  /** update multiples rows of table: "coachLocation" */
  update_coachLocation_many?: Maybe<Array<Maybe<CoachLocationMutationResponse>>>;
  /** update data of the table: "coachModerationFlag" */
  update_coachModerationFlag?: Maybe<CoachModerationFlagMutationResponse>;
  /** update single row of the table: "coachModerationFlag" */
  update_coachModerationFlag_by_pk?: Maybe<CoachModerationFlag>;
  /** update multiples rows of table: "coachModerationFlag" */
  update_coachModerationFlag_many?: Maybe<Array<Maybe<CoachModerationFlagMutationResponse>>>;
  /** update data of the table: "coachStudent" */
  update_coachStudent?: Maybe<CoachStudentMutationResponse>;
  /** update single row of the table: "coachStudent" */
  update_coachStudent_by_pk?: Maybe<CoachStudent>;
  /** update multiples rows of table: "coachStudent" */
  update_coachStudent_many?: Maybe<Array<Maybe<CoachStudentMutationResponse>>>;
  /** update data of the table: "coachUnavailableDate" */
  update_coachUnavailableDate?: Maybe<CoachUnavailableDateMutationResponse>;
  /** update single row of the table: "coachUnavailableDate" */
  update_coachUnavailableDate_by_pk?: Maybe<CoachUnavailableDate>;
  /** update multiples rows of table: "coachUnavailableDate" */
  update_coachUnavailableDate_many?: Maybe<Array<Maybe<CoachUnavailableDateMutationResponse>>>;
  /** update single row of the table: "coach" */
  update_coach_by_pk?: Maybe<Coach>;
  /** update multiples rows of table: "coach" */
  update_coach_many?: Maybe<Array<Maybe<CoachMutationResponse>>>;
  /** update data of the table: "course" */
  update_course?: Maybe<CourseMutationResponse>;
  /** update single row of the table: "course" */
  update_course_by_pk?: Maybe<Course>;
  /** update multiples rows of table: "course" */
  update_course_many?: Maybe<Array<Maybe<CourseMutationResponse>>>;
  /** update data of the table: "creatorInvite" */
  update_creatorInvite?: Maybe<CreatorInviteMutationResponse>;
  /** update single row of the table: "creatorInvite" */
  update_creatorInvite_by_pk?: Maybe<CreatorInvite>;
  /** update multiples rows of table: "creatorInvite" */
  update_creatorInvite_many?: Maybe<Array<Maybe<CreatorInviteMutationResponse>>>;
  /** update data of the table: "event" */
  update_event?: Maybe<EventMutationResponse>;
  /** update data of the table: "eventAdditionalInfo" */
  update_eventAdditionalInfo?: Maybe<EventAdditionalInfoMutationResponse>;
  /** update single row of the table: "eventAdditionalInfo" */
  update_eventAdditionalInfo_by_pk?: Maybe<EventAdditionalInfo>;
  /** update multiples rows of table: "eventAdditionalInfo" */
  update_eventAdditionalInfo_many?: Maybe<Array<Maybe<EventAdditionalInfoMutationResponse>>>;
  /** update data of the table: "eventAmenity" */
  update_eventAmenity?: Maybe<EventAmenityMutationResponse>;
  /** update single row of the table: "eventAmenity" */
  update_eventAmenity_by_pk?: Maybe<EventAmenity>;
  /** update multiples rows of table: "eventAmenity" */
  update_eventAmenity_many?: Maybe<Array<Maybe<EventAmenityMutationResponse>>>;
  /** update data of the table: "eventBasicWaiver" */
  update_eventBasicWaiver?: Maybe<EventBasicWaiverMutationResponse>;
  /** update data of the table: "eventBasicWaiverCategory" */
  update_eventBasicWaiverCategory?: Maybe<EventBasicWaiverCategoryMutationResponse>;
  /** update single row of the table: "eventBasicWaiverCategory" */
  update_eventBasicWaiverCategory_by_pk?: Maybe<EventBasicWaiverCategory>;
  /** update multiples rows of table: "eventBasicWaiverCategory" */
  update_eventBasicWaiverCategory_many?: Maybe<Array<Maybe<EventBasicWaiverCategoryMutationResponse>>>;
  /** update single row of the table: "eventBasicWaiver" */
  update_eventBasicWaiver_by_pk?: Maybe<EventBasicWaiver>;
  /** update multiples rows of table: "eventBasicWaiver" */
  update_eventBasicWaiver_many?: Maybe<Array<Maybe<EventBasicWaiverMutationResponse>>>;
  /** update data of the table: "eventChecklistItem" */
  update_eventChecklistItem?: Maybe<EventChecklistItemMutationResponse>;
  /** update single row of the table: "eventChecklistItem" */
  update_eventChecklistItem_by_pk?: Maybe<EventChecklistItem>;
  /** update multiples rows of table: "eventChecklistItem" */
  update_eventChecklistItem_many?: Maybe<Array<Maybe<EventChecklistItemMutationResponse>>>;
  /** update data of the table: "eventClinicDayDuration" */
  update_eventClinicDayDuration?: Maybe<EventClinicDayDurationMutationResponse>;
  /** update single row of the table: "eventClinicDayDuration" */
  update_eventClinicDayDuration_by_pk?: Maybe<EventClinicDayDuration>;
  /** update multiples rows of table: "eventClinicDayDuration" */
  update_eventClinicDayDuration_many?: Maybe<Array<Maybe<EventClinicDayDurationMutationResponse>>>;
  /** update data of the table: "eventCustomQuestion" */
  update_eventCustomQuestion?: Maybe<EventCustomQuestionMutationResponse>;
  /** update data of the table: "eventCustomQuestionCategory" */
  update_eventCustomQuestionCategory?: Maybe<EventCustomQuestionCategoryMutationResponse>;
  /** update single row of the table: "eventCustomQuestionCategory" */
  update_eventCustomQuestionCategory_by_pk?: Maybe<EventCustomQuestionCategory>;
  /** update multiples rows of table: "eventCustomQuestionCategory" */
  update_eventCustomQuestionCategory_many?: Maybe<Array<Maybe<EventCustomQuestionCategoryMutationResponse>>>;
  /** update data of the table: "eventCustomQuestionOption" */
  update_eventCustomQuestionOption?: Maybe<EventCustomQuestionOptionMutationResponse>;
  /** update single row of the table: "eventCustomQuestionOption" */
  update_eventCustomQuestionOption_by_pk?: Maybe<EventCustomQuestionOption>;
  /** update multiples rows of table: "eventCustomQuestionOption" */
  update_eventCustomQuestionOption_many?: Maybe<Array<Maybe<EventCustomQuestionOptionMutationResponse>>>;
  /** update single row of the table: "eventCustomQuestion" */
  update_eventCustomQuestion_by_pk?: Maybe<EventCustomQuestion>;
  /** update multiples rows of table: "eventCustomQuestion" */
  update_eventCustomQuestion_many?: Maybe<Array<Maybe<EventCustomQuestionMutationResponse>>>;
  /** update data of the table: "eventDiscipline" */
  update_eventDiscipline?: Maybe<EventDisciplineMutationResponse>;
  /** update single row of the table: "eventDiscipline" */
  update_eventDiscipline_by_pk?: Maybe<EventDiscipline>;
  /** update multiples rows of table: "eventDiscipline" */
  update_eventDiscipline_many?: Maybe<Array<Maybe<EventDisciplineMutationResponse>>>;
  /** update data of the table: "eventImage" */
  update_eventImage?: Maybe<EventImageMutationResponse>;
  /** update single row of the table: "eventImage" */
  update_eventImage_by_pk?: Maybe<EventImage>;
  /** update multiples rows of table: "eventImage" */
  update_eventImage_many?: Maybe<Array<Maybe<EventImageMutationResponse>>>;
  /** update data of the table: "eventMerchandise" */
  update_eventMerchandise?: Maybe<EventMerchandiseMutationResponse>;
  /** update single row of the table: "eventMerchandise" */
  update_eventMerchandise_by_pk?: Maybe<EventMerchandise>;
  /** update multiples rows of table: "eventMerchandise" */
  update_eventMerchandise_many?: Maybe<Array<Maybe<EventMerchandiseMutationResponse>>>;
  /** update data of the table: "eventMetadata" */
  update_eventMetadata?: Maybe<EventMetadataMutationResponse>;
  /** update single row of the table: "eventMetadata" */
  update_eventMetadata_by_pk?: Maybe<EventMetadata>;
  /** update multiples rows of table: "eventMetadata" */
  update_eventMetadata_many?: Maybe<Array<Maybe<EventMetadataMutationResponse>>>;
  /** update data of the table: "eventModerationFlag" */
  update_eventModerationFlag?: Maybe<EventModerationFlagMutationResponse>;
  /** update single row of the table: "eventModerationFlag" */
  update_eventModerationFlag_by_pk?: Maybe<EventModerationFlag>;
  /** update multiples rows of table: "eventModerationFlag" */
  update_eventModerationFlag_many?: Maybe<Array<Maybe<EventModerationFlagMutationResponse>>>;
  /** update data of the table: "eventPitSpace" */
  update_eventPitSpace?: Maybe<EventPitSpaceMutationResponse>;
  /** update data of the table: "eventPitSpaceAddOn" */
  update_eventPitSpaceAddOn?: Maybe<EventPitSpaceAddOnMutationResponse>;
  /** update single row of the table: "eventPitSpaceAddOn" */
  update_eventPitSpaceAddOn_by_pk?: Maybe<EventPitSpaceAddOn>;
  /** update multiples rows of table: "eventPitSpaceAddOn" */
  update_eventPitSpaceAddOn_many?: Maybe<Array<Maybe<EventPitSpaceAddOnMutationResponse>>>;
  /** update single row of the table: "eventPitSpace" */
  update_eventPitSpace_by_pk?: Maybe<EventPitSpace>;
  /** update multiples rows of table: "eventPitSpace" */
  update_eventPitSpace_many?: Maybe<Array<Maybe<EventPitSpaceMutationResponse>>>;
  /** update data of the table: "eventPricingChange" */
  update_eventPricingChange?: Maybe<EventPricingChangeMutationResponse>;
  /** update single row of the table: "eventPricingChange" */
  update_eventPricingChange_by_pk?: Maybe<EventPricingChange>;
  /** update multiples rows of table: "eventPricingChange" */
  update_eventPricingChange_many?: Maybe<Array<Maybe<EventPricingChangeMutationResponse>>>;
  /** update data of the table: "eventPricingDate" */
  update_eventPricingDate?: Maybe<EventPricingDateMutationResponse>;
  /** update single row of the table: "eventPricingDate" */
  update_eventPricingDate_by_pk?: Maybe<EventPricingDate>;
  /** update multiples rows of table: "eventPricingDate" */
  update_eventPricingDate_many?: Maybe<Array<Maybe<EventPricingDateMutationResponse>>>;
  /** update data of the table: "eventPricingGroup" */
  update_eventPricingGroup?: Maybe<EventPricingGroupMutationResponse>;
  /** update data of the table: "eventPricingGroupCategory" */
  update_eventPricingGroupCategory?: Maybe<EventPricingGroupCategoryMutationResponse>;
  /** update single row of the table: "eventPricingGroupCategory" */
  update_eventPricingGroupCategory_by_pk?: Maybe<EventPricingGroupCategory>;
  /** update multiples rows of table: "eventPricingGroupCategory" */
  update_eventPricingGroupCategory_many?: Maybe<Array<Maybe<EventPricingGroupCategoryMutationResponse>>>;
  /** update single row of the table: "eventPricingGroup" */
  update_eventPricingGroup_by_pk?: Maybe<EventPricingGroup>;
  /** update multiples rows of table: "eventPricingGroup" */
  update_eventPricingGroup_many?: Maybe<Array<Maybe<EventPricingGroupMutationResponse>>>;
  /** update data of the table: "eventPrivateLink" */
  update_eventPrivateLink?: Maybe<EventPrivateLinkMutationResponse>;
  /** update single row of the table: "eventPrivateLink" */
  update_eventPrivateLink_by_pk?: Maybe<EventPrivateLink>;
  /** update multiples rows of table: "eventPrivateLink" */
  update_eventPrivateLink_many?: Maybe<Array<Maybe<EventPrivateLinkMutationResponse>>>;
  /** update data of the table: "eventProgram" */
  update_eventProgram?: Maybe<EventProgramMutationResponse>;
  /** update single row of the table: "eventProgram" */
  update_eventProgram_by_pk?: Maybe<EventProgram>;
  /** update multiples rows of table: "eventProgram" */
  update_eventProgram_many?: Maybe<Array<Maybe<EventProgramMutationResponse>>>;
  /** update data of the table: "eventPromoCode" */
  update_eventPromoCode?: Maybe<EventPromoCodeMutationResponse>;
  /** update single row of the table: "eventPromoCode" */
  update_eventPromoCode_by_pk?: Maybe<EventPromoCode>;
  /** update multiples rows of table: "eventPromoCode" */
  update_eventPromoCode_many?: Maybe<Array<Maybe<EventPromoCodeMutationResponse>>>;
  /** update data of the table: "eventQuestion" */
  update_eventQuestion?: Maybe<EventQuestionMutationResponse>;
  /** update data of the table: "eventQuestionAnswer" */
  update_eventQuestionAnswer?: Maybe<EventQuestionAnswerMutationResponse>;
  /** update single row of the table: "eventQuestionAnswer" */
  update_eventQuestionAnswer_by_pk?: Maybe<EventQuestionAnswer>;
  /** update multiples rows of table: "eventQuestionAnswer" */
  update_eventQuestionAnswer_many?: Maybe<Array<Maybe<EventQuestionAnswerMutationResponse>>>;
  /** update single row of the table: "eventQuestion" */
  update_eventQuestion_by_pk?: Maybe<EventQuestion>;
  /** update multiples rows of table: "eventQuestion" */
  update_eventQuestion_many?: Maybe<Array<Maybe<EventQuestionMutationResponse>>>;
  /** update data of the table: "eventSanction" */
  update_eventSanction?: Maybe<EventSanctionMutationResponse>;
  /** update data of the table: "eventSanctionCategory" */
  update_eventSanctionCategory?: Maybe<EventSanctionCategoryMutationResponse>;
  /** update single row of the table: "eventSanctionCategory" */
  update_eventSanctionCategory_by_pk?: Maybe<EventSanctionCategory>;
  /** update multiples rows of table: "eventSanctionCategory" */
  update_eventSanctionCategory_many?: Maybe<Array<Maybe<EventSanctionCategoryMutationResponse>>>;
  /** update single row of the table: "eventSanction" */
  update_eventSanction_by_pk?: Maybe<EventSanction>;
  /** update multiples rows of table: "eventSanction" */
  update_eventSanction_many?: Maybe<Array<Maybe<EventSanctionMutationResponse>>>;
  /** update data of the table: "eventSchedule" */
  update_eventSchedule?: Maybe<EventScheduleMutationResponse>;
  /** update data of the table: "eventScheduleCategory" */
  update_eventScheduleCategory?: Maybe<EventScheduleCategoryMutationResponse>;
  /** update single row of the table: "eventScheduleCategory" */
  update_eventScheduleCategory_by_pk?: Maybe<EventScheduleCategory>;
  /** update multiples rows of table: "eventScheduleCategory" */
  update_eventScheduleCategory_many?: Maybe<Array<Maybe<EventScheduleCategoryMutationResponse>>>;
  /** update data of the table: "eventScheduleDate" */
  update_eventScheduleDate?: Maybe<EventScheduleDateMutationResponse>;
  /** update single row of the table: "eventScheduleDate" */
  update_eventScheduleDate_by_pk?: Maybe<EventScheduleDate>;
  /** update multiples rows of table: "eventScheduleDate" */
  update_eventScheduleDate_many?: Maybe<Array<Maybe<EventScheduleDateMutationResponse>>>;
  /** update data of the table: "eventScheduleTypeCustom" */
  update_eventScheduleTypeCustom?: Maybe<EventScheduleTypeCustomMutationResponse>;
  /** update single row of the table: "eventScheduleTypeCustom" */
  update_eventScheduleTypeCustom_by_pk?: Maybe<EventScheduleTypeCustom>;
  /** update multiples rows of table: "eventScheduleTypeCustom" */
  update_eventScheduleTypeCustom_many?: Maybe<Array<Maybe<EventScheduleTypeCustomMutationResponse>>>;
  /** update data of the table: "eventScheduleTypePreset" */
  update_eventScheduleTypePreset?: Maybe<EventScheduleTypePresetMutationResponse>;
  /** update single row of the table: "eventScheduleTypePreset" */
  update_eventScheduleTypePreset_by_pk?: Maybe<EventScheduleTypePreset>;
  /** update multiples rows of table: "eventScheduleTypePreset" */
  update_eventScheduleTypePreset_many?: Maybe<Array<Maybe<EventScheduleTypePresetMutationResponse>>>;
  /** update single row of the table: "eventSchedule" */
  update_eventSchedule_by_pk?: Maybe<EventSchedule>;
  /** update multiples rows of table: "eventSchedule" */
  update_eventSchedule_many?: Maybe<Array<Maybe<EventScheduleMutationResponse>>>;
  /** update data of the table: "eventSeries" */
  update_eventSeries?: Maybe<EventSeriesMutationResponse>;
  /** update data of the table: "eventSeriesCategory" */
  update_eventSeriesCategory?: Maybe<EventSeriesCategoryMutationResponse>;
  /** update single row of the table: "eventSeriesCategory" */
  update_eventSeriesCategory_by_pk?: Maybe<EventSeriesCategory>;
  /** update multiples rows of table: "eventSeriesCategory" */
  update_eventSeriesCategory_many?: Maybe<Array<Maybe<EventSeriesCategoryMutationResponse>>>;
  /** update single row of the table: "eventSeries" */
  update_eventSeries_by_pk?: Maybe<EventSeries>;
  /** update multiples rows of table: "eventSeries" */
  update_eventSeries_many?: Maybe<Array<Maybe<EventSeriesMutationResponse>>>;
  /** update data of the table: "eventSponsor" */
  update_eventSponsor?: Maybe<EventSponsorMutationResponse>;
  /** update single row of the table: "eventSponsor" */
  update_eventSponsor_by_pk?: Maybe<EventSponsor>;
  /** update multiples rows of table: "eventSponsor" */
  update_eventSponsor_many?: Maybe<Array<Maybe<EventSponsorMutationResponse>>>;
  /** update data of the table: "eventTicket" */
  update_eventTicket?: Maybe<EventTicketMutationResponse>;
  /** update single row of the table: "eventTicket" */
  update_eventTicket_by_pk?: Maybe<EventTicket>;
  /** update multiples rows of table: "eventTicket" */
  update_eventTicket_many?: Maybe<Array<Maybe<EventTicketMutationResponse>>>;
  /** update data of the table: "eventUpdate" */
  update_eventUpdate?: Maybe<EventUpdateMutationResponse>;
  /** update data of the table: "eventUpdateComment" */
  update_eventUpdateComment?: Maybe<EventUpdateCommentMutationResponse>;
  /** update single row of the table: "eventUpdateComment" */
  update_eventUpdateComment_by_pk?: Maybe<EventUpdateComment>;
  /** update multiples rows of table: "eventUpdateComment" */
  update_eventUpdateComment_many?: Maybe<Array<Maybe<EventUpdateCommentMutationResponse>>>;
  /** update single row of the table: "eventUpdate" */
  update_eventUpdate_by_pk?: Maybe<EventUpdate>;
  /** update multiples rows of table: "eventUpdate" */
  update_eventUpdate_many?: Maybe<Array<Maybe<EventUpdateMutationResponse>>>;
  /** update data of the table: "eventVenue" */
  update_eventVenue?: Maybe<EventVenueMutationResponse>;
  /** update single row of the table: "eventVenue" */
  update_eventVenue_by_pk?: Maybe<EventVenue>;
  /** update multiples rows of table: "eventVenue" */
  update_eventVenue_many?: Maybe<Array<Maybe<EventVenueMutationResponse>>>;
  /** update data of the table: "eventVibe" */
  update_eventVibe?: Maybe<EventVibeMutationResponse>;
  /** update single row of the table: "eventVibe" */
  update_eventVibe_by_pk?: Maybe<EventVibe>;
  /** update multiples rows of table: "eventVibe" */
  update_eventVibe_many?: Maybe<Array<Maybe<EventVibeMutationResponse>>>;
  /** update data of the table: "eventVirtualVenue" */
  update_eventVirtualVenue?: Maybe<EventVirtualVenueMutationResponse>;
  /** update single row of the table: "eventVirtualVenue" */
  update_eventVirtualVenue_by_pk?: Maybe<EventVirtualVenue>;
  /** update multiples rows of table: "eventVirtualVenue" */
  update_eventVirtualVenue_many?: Maybe<Array<Maybe<EventVirtualVenueMutationResponse>>>;
  /** update data of the table: "eventVolunteerJob" */
  update_eventVolunteerJob?: Maybe<EventVolunteerJobMutationResponse>;
  /** update data of the table: "eventVolunteerJobShift" */
  update_eventVolunteerJobShift?: Maybe<EventVolunteerJobShiftMutationResponse>;
  /** update data of the table: "eventVolunteerJobShiftTask" */
  update_eventVolunteerJobShiftTask?: Maybe<EventVolunteerJobShiftTaskMutationResponse>;
  /** update single row of the table: "eventVolunteerJobShiftTask" */
  update_eventVolunteerJobShiftTask_by_pk?: Maybe<EventVolunteerJobShiftTask>;
  /** update multiples rows of table: "eventVolunteerJobShiftTask" */
  update_eventVolunteerJobShiftTask_many?: Maybe<Array<Maybe<EventVolunteerJobShiftTaskMutationResponse>>>;
  /** update single row of the table: "eventVolunteerJobShift" */
  update_eventVolunteerJobShift_by_pk?: Maybe<EventVolunteerJobShift>;
  /** update multiples rows of table: "eventVolunteerJobShift" */
  update_eventVolunteerJobShift_many?: Maybe<Array<Maybe<EventVolunteerJobShiftMutationResponse>>>;
  /** update data of the table: "eventVolunteerJobTask" */
  update_eventVolunteerJobTask?: Maybe<EventVolunteerJobTaskMutationResponse>;
  /** update single row of the table: "eventVolunteerJobTask" */
  update_eventVolunteerJobTask_by_pk?: Maybe<EventVolunteerJobTask>;
  /** update multiples rows of table: "eventVolunteerJobTask" */
  update_eventVolunteerJobTask_many?: Maybe<Array<Maybe<EventVolunteerJobTaskMutationResponse>>>;
  /** update single row of the table: "eventVolunteerJob" */
  update_eventVolunteerJob_by_pk?: Maybe<EventVolunteerJob>;
  /** update multiples rows of table: "eventVolunteerJob" */
  update_eventVolunteerJob_many?: Maybe<Array<Maybe<EventVolunteerJobMutationResponse>>>;
  /** update data of the table: "eventWaiver" */
  update_eventWaiver?: Maybe<EventWaiverMutationResponse>;
  /** update data of the table: "eventWaiverType" */
  update_eventWaiverType?: Maybe<EventWaiverTypeMutationResponse>;
  /** update single row of the table: "eventWaiverType" */
  update_eventWaiverType_by_pk?: Maybe<EventWaiverType>;
  /** update multiples rows of table: "eventWaiverType" */
  update_eventWaiverType_many?: Maybe<Array<Maybe<EventWaiverTypeMutationResponse>>>;
  /** update single row of the table: "eventWaiver" */
  update_eventWaiver_by_pk?: Maybe<EventWaiver>;
  /** update multiples rows of table: "eventWaiver" */
  update_eventWaiver_many?: Maybe<Array<Maybe<EventWaiverMutationResponse>>>;
  /** update single row of the table: "event" */
  update_event_by_pk?: Maybe<Event>;
  /** update multiples rows of table: "event" */
  update_event_many?: Maybe<Array<Maybe<EventMutationResponse>>>;
  /** update data of the table: "image" */
  update_image?: Maybe<ImageMutationResponse>;
  /** update data of the table: "imageOnlineCourse" */
  update_imageOnlineCourse?: Maybe<ImageOnlineCourseMutationResponse>;
  /** update single row of the table: "imageOnlineCourse" */
  update_imageOnlineCourse_by_pk?: Maybe<ImageOnlineCourse>;
  /** update multiples rows of table: "imageOnlineCourse" */
  update_imageOnlineCourse_many?: Maybe<Array<Maybe<ImageOnlineCourseMutationResponse>>>;
  /** update data of the table: "imageUser" */
  update_imageUser?: Maybe<ImageUserMutationResponse>;
  /** update single row of the table: "imageUser" */
  update_imageUser_by_pk?: Maybe<ImageUser>;
  /** update multiples rows of table: "imageUser" */
  update_imageUser_many?: Maybe<Array<Maybe<ImageUserMutationResponse>>>;
  /** update single row of the table: "image" */
  update_image_by_pk?: Maybe<Image>;
  /** update multiples rows of table: "image" */
  update_image_many?: Maybe<Array<Maybe<ImageMutationResponse>>>;
  /** update data of the table: "lessonLocation" */
  update_lessonLocation?: Maybe<LessonLocationMutationResponse>;
  /** update single row of the table: "lessonLocation" */
  update_lessonLocation_by_pk?: Maybe<LessonLocation>;
  /** update multiples rows of table: "lessonLocation" */
  update_lessonLocation_many?: Maybe<Array<Maybe<LessonLocationMutationResponse>>>;
  /** update data of the table: "merchandise" */
  update_merchandise?: Maybe<MerchandiseMutationResponse>;
  /** update data of the table: "merchandiseDeliveryType" */
  update_merchandiseDeliveryType?: Maybe<MerchandiseDeliveryTypeMutationResponse>;
  /** update single row of the table: "merchandiseDeliveryType" */
  update_merchandiseDeliveryType_by_pk?: Maybe<MerchandiseDeliveryType>;
  /** update multiples rows of table: "merchandiseDeliveryType" */
  update_merchandiseDeliveryType_many?: Maybe<Array<Maybe<MerchandiseDeliveryTypeMutationResponse>>>;
  /** update data of the table: "merchandiseImage" */
  update_merchandiseImage?: Maybe<MerchandiseImageMutationResponse>;
  /** update single row of the table: "merchandiseImage" */
  update_merchandiseImage_by_pk?: Maybe<MerchandiseImage>;
  /** update multiples rows of table: "merchandiseImage" */
  update_merchandiseImage_many?: Maybe<Array<Maybe<MerchandiseImageMutationResponse>>>;
  /** update data of the table: "merchandiseVariant" */
  update_merchandiseVariant?: Maybe<MerchandiseVariantMutationResponse>;
  /** update data of the table: "merchandiseVariantOption" */
  update_merchandiseVariantOption?: Maybe<MerchandiseVariantOptionMutationResponse>;
  /** update data of the table: "merchandiseVariantOptionValue" */
  update_merchandiseVariantOptionValue?: Maybe<MerchandiseVariantOptionValueMutationResponse>;
  /** update single row of the table: "merchandiseVariantOptionValue" */
  update_merchandiseVariantOptionValue_by_pk?: Maybe<MerchandiseVariantOptionValue>;
  /** update multiples rows of table: "merchandiseVariantOptionValue" */
  update_merchandiseVariantOptionValue_many?: Maybe<Array<Maybe<MerchandiseVariantOptionValueMutationResponse>>>;
  /** update single row of the table: "merchandiseVariantOption" */
  update_merchandiseVariantOption_by_pk?: Maybe<MerchandiseVariantOption>;
  /** update multiples rows of table: "merchandiseVariantOption" */
  update_merchandiseVariantOption_many?: Maybe<Array<Maybe<MerchandiseVariantOptionMutationResponse>>>;
  /** update single row of the table: "merchandiseVariant" */
  update_merchandiseVariant_by_pk?: Maybe<MerchandiseVariant>;
  /** update multiples rows of table: "merchandiseVariant" */
  update_merchandiseVariant_many?: Maybe<Array<Maybe<MerchandiseVariantMutationResponse>>>;
  /** update single row of the table: "merchandise" */
  update_merchandise_by_pk?: Maybe<Merchandise>;
  /** update multiples rows of table: "merchandise" */
  update_merchandise_many?: Maybe<Array<Maybe<MerchandiseMutationResponse>>>;
  /** update data of the table: "onlineCourse" */
  update_onlineCourse?: Maybe<OnlineCourseMutationResponse>;
  /** update data of the table: "onlineCourseOwner" */
  update_onlineCourseOwner?: Maybe<OnlineCourseOwnerMutationResponse>;
  /** update single row of the table: "onlineCourseOwner" */
  update_onlineCourseOwner_by_pk?: Maybe<OnlineCourseOwner>;
  /** update multiples rows of table: "onlineCourseOwner" */
  update_onlineCourseOwner_many?: Maybe<Array<Maybe<OnlineCourseOwnerMutationResponse>>>;
  /** update data of the table: "onlineCourseRevision" */
  update_onlineCourseRevision?: Maybe<OnlineCourseRevisionMutationResponse>;
  /** update single row of the table: "onlineCourseRevision" */
  update_onlineCourseRevision_by_pk?: Maybe<OnlineCourseRevision>;
  /** update multiples rows of table: "onlineCourseRevision" */
  update_onlineCourseRevision_many?: Maybe<Array<Maybe<OnlineCourseRevisionMutationResponse>>>;
  /** update single row of the table: "onlineCourse" */
  update_onlineCourse_by_pk?: Maybe<OnlineCourse>;
  /** update multiples rows of table: "onlineCourse" */
  update_onlineCourse_many?: Maybe<Array<Maybe<OnlineCourseMutationResponse>>>;
  /** update data of the table: "organizer" */
  update_organizer?: Maybe<OrganizerMutationResponse>;
  /** update data of the table: "organizerChannel" */
  update_organizerChannel?: Maybe<OrganizerChannelMutationResponse>;
  /** update single row of the table: "organizerChannel" */
  update_organizerChannel_by_pk?: Maybe<OrganizerChannel>;
  /** update multiples rows of table: "organizerChannel" */
  update_organizerChannel_many?: Maybe<Array<Maybe<OrganizerChannelMutationResponse>>>;
  /** update data of the table: "organizerInvite" */
  update_organizerInvite?: Maybe<OrganizerInviteMutationResponse>;
  /** update single row of the table: "organizerInvite" */
  update_organizerInvite_by_pk?: Maybe<OrganizerInvite>;
  /** update multiples rows of table: "organizerInvite" */
  update_organizerInvite_many?: Maybe<Array<Maybe<OrganizerInviteMutationResponse>>>;
  /** update single row of the table: "organizer" */
  update_organizer_by_pk?: Maybe<Organizer>;
  /** update multiples rows of table: "organizer" */
  update_organizer_many?: Maybe<Array<Maybe<OrganizerMutationResponse>>>;
  /** update data of the table: "registration" */
  update_registration?: Maybe<RegistrationMutationResponse>;
  /** update data of the table: "registrationActivity" */
  update_registrationActivity?: Maybe<RegistrationActivityMutationResponse>;
  /** update single row of the table: "registrationActivity" */
  update_registrationActivity_by_pk?: Maybe<RegistrationActivity>;
  /** update multiples rows of table: "registrationActivity" */
  update_registrationActivity_many?: Maybe<Array<Maybe<RegistrationActivityMutationResponse>>>;
  /** update data of the table: "registrationAdditionalPayment" */
  update_registrationAdditionalPayment?: Maybe<RegistrationAdditionalPaymentMutationResponse>;
  /** update single row of the table: "registrationAdditionalPayment" */
  update_registrationAdditionalPayment_by_pk?: Maybe<RegistrationAdditionalPayment>;
  /** update multiples rows of table: "registrationAdditionalPayment" */
  update_registrationAdditionalPayment_many?: Maybe<Array<Maybe<RegistrationAdditionalPaymentMutationResponse>>>;
  /** update data of the table: "registrationCategory" */
  update_registrationCategory?: Maybe<RegistrationCategoryMutationResponse>;
  /** update single row of the table: "registrationCategory" */
  update_registrationCategory_by_pk?: Maybe<RegistrationCategory>;
  /** update multiples rows of table: "registrationCategory" */
  update_registrationCategory_many?: Maybe<Array<Maybe<RegistrationCategoryMutationResponse>>>;
  /** update data of the table: "registrationFor" */
  update_registrationFor?: Maybe<RegistrationForMutationResponse>;
  /** update single row of the table: "registrationFor" */
  update_registrationFor_by_pk?: Maybe<RegistrationFor>;
  /** update multiples rows of table: "registrationFor" */
  update_registrationFor_many?: Maybe<Array<Maybe<RegistrationForMutationResponse>>>;
  /** update data of the table: "registrationSeries" */
  update_registrationSeries?: Maybe<RegistrationSeriesMutationResponse>;
  /** update single row of the table: "registrationSeries" */
  update_registrationSeries_by_pk?: Maybe<RegistrationSeries>;
  /** update multiples rows of table: "registrationSeries" */
  update_registrationSeries_many?: Maybe<Array<Maybe<RegistrationSeriesMutationResponse>>>;
  /** update data of the table: "registrationStatus" */
  update_registrationStatus?: Maybe<RegistrationStatusMutationResponse>;
  /** update single row of the table: "registrationStatus" */
  update_registrationStatus_by_pk?: Maybe<RegistrationStatus>;
  /** update multiples rows of table: "registrationStatus" */
  update_registrationStatus_many?: Maybe<Array<Maybe<RegistrationStatusMutationResponse>>>;
  /** update data of the table: "registrationTicket" */
  update_registrationTicket?: Maybe<RegistrationTicketMutationResponse>;
  /** update single row of the table: "registrationTicket" */
  update_registrationTicket_by_pk?: Maybe<RegistrationTicket>;
  /** update multiples rows of table: "registrationTicket" */
  update_registrationTicket_many?: Maybe<Array<Maybe<RegistrationTicketMutationResponse>>>;
  /** update single row of the table: "registration" */
  update_registration_by_pk?: Maybe<Registration>;
  /** update multiples rows of table: "registration" */
  update_registration_many?: Maybe<Array<Maybe<RegistrationMutationResponse>>>;
  /** update data of the table: "reportClinicNoShow" */
  update_reportClinicNoShow?: Maybe<ReportClinicNoShowMutationResponse>;
  /** update single row of the table: "reportClinicNoShow" */
  update_reportClinicNoShow_by_pk?: Maybe<ReportClinicNoShow>;
  /** update multiples rows of table: "reportClinicNoShow" */
  update_reportClinicNoShow_many?: Maybe<Array<Maybe<ReportClinicNoShowMutationResponse>>>;
  /** update data of the table: "reportLessonNoShow" */
  update_reportLessonNoShow?: Maybe<ReportLessonNoShowMutationResponse>;
  /** update single row of the table: "reportLessonNoShow" */
  update_reportLessonNoShow_by_pk?: Maybe<ReportLessonNoShow>;
  /** update multiples rows of table: "reportLessonNoShow" */
  update_reportLessonNoShow_many?: Maybe<Array<Maybe<ReportLessonNoShowMutationResponse>>>;
  /** update data of the table: "review" */
  update_review?: Maybe<ReviewMutationResponse>;
  /** update data of the table: "reviewSubjectType" */
  update_reviewSubjectType?: Maybe<ReviewSubjectTypeMutationResponse>;
  /** update single row of the table: "reviewSubjectType" */
  update_reviewSubjectType_by_pk?: Maybe<ReviewSubjectType>;
  /** update multiples rows of table: "reviewSubjectType" */
  update_reviewSubjectType_many?: Maybe<Array<Maybe<ReviewSubjectTypeMutationResponse>>>;
  /** update data of the table: "reviewTotals" */
  update_reviewTotals?: Maybe<ReviewTotalsMutationResponse>;
  /** update single row of the table: "reviewTotals" */
  update_reviewTotals_by_pk?: Maybe<ReviewTotals>;
  /** update multiples rows of table: "reviewTotals" */
  update_reviewTotals_many?: Maybe<Array<Maybe<ReviewTotalsMutationResponse>>>;
  /** update single row of the table: "review" */
  update_review_by_pk?: Maybe<Review>;
  /** update multiples rows of table: "review" */
  update_review_many?: Maybe<Array<Maybe<ReviewMutationResponse>>>;
  /** update data of the table: "ride" */
  update_ride?: Maybe<RideMutationResponse>;
  /** update data of the table: "rideDay" */
  update_rideDay?: Maybe<RideDayMutationResponse>;
  /** update single row of the table: "rideDay" */
  update_rideDay_by_pk?: Maybe<RideDay>;
  /** update multiples rows of table: "rideDay" */
  update_rideDay_many?: Maybe<Array<Maybe<RideDayMutationResponse>>>;
  /** update single row of the table: "ride" */
  update_ride_by_pk?: Maybe<Ride>;
  /** update multiples rows of table: "ride" */
  update_ride_many?: Maybe<Array<Maybe<RideMutationResponse>>>;
  /** update data of the table: "roleOrganizer" */
  update_roleOrganizer?: Maybe<RoleOrganizerMutationResponse>;
  /** update single row of the table: "roleOrganizer" */
  update_roleOrganizer_by_pk?: Maybe<RoleOrganizer>;
  /** update multiples rows of table: "roleOrganizer" */
  update_roleOrganizer_many?: Maybe<Array<Maybe<RoleOrganizerMutationResponse>>>;
  /** update data of the table: "sanction" */
  update_sanction?: Maybe<SanctionMutationResponse>;
  /** update data of the table: "sanctionWaivers" */
  update_sanctionWaivers?: Maybe<SanctionWaiversMutationResponse>;
  /** update single row of the table: "sanctionWaivers" */
  update_sanctionWaivers_by_pk?: Maybe<SanctionWaivers>;
  /** update multiples rows of table: "sanctionWaivers" */
  update_sanctionWaivers_many?: Maybe<Array<Maybe<SanctionWaiversMutationResponse>>>;
  /** update single row of the table: "sanction" */
  update_sanction_by_pk?: Maybe<Sanction>;
  /** update multiples rows of table: "sanction" */
  update_sanction_many?: Maybe<Array<Maybe<SanctionMutationResponse>>>;
  /** update data of the table: "scheduleTypePreset" */
  update_scheduleTypePreset?: Maybe<ScheduleTypePresetMutationResponse>;
  /** update single row of the table: "scheduleTypePreset" */
  update_scheduleTypePreset_by_pk?: Maybe<ScheduleTypePreset>;
  /** update multiples rows of table: "scheduleTypePreset" */
  update_scheduleTypePreset_many?: Maybe<Array<Maybe<ScheduleTypePresetMutationResponse>>>;
  /** update data of the table: "series" */
  update_series?: Maybe<SeriesMutationResponse>;
  /** update data of the table: "seriesImage" */
  update_seriesImage?: Maybe<SeriesImageMutationResponse>;
  /** update single row of the table: "seriesImage" */
  update_seriesImage_by_pk?: Maybe<SeriesImage>;
  /** update multiples rows of table: "seriesImage" */
  update_seriesImage_many?: Maybe<Array<Maybe<SeriesImageMutationResponse>>>;
  /** update data of the table: "seriesPointsStructureCustom" */
  update_seriesPointsStructureCustom?: Maybe<SeriesPointsStructureCustomMutationResponse>;
  /** update single row of the table: "seriesPointsStructureCustom" */
  update_seriesPointsStructureCustom_by_pk?: Maybe<SeriesPointsStructureCustom>;
  /** update multiples rows of table: "seriesPointsStructureCustom" */
  update_seriesPointsStructureCustom_many?: Maybe<Array<Maybe<SeriesPointsStructureCustomMutationResponse>>>;
  /** update data of the table: "seriesPointsStructurePreset" */
  update_seriesPointsStructurePreset?: Maybe<SeriesPointsStructurePresetMutationResponse>;
  /** update single row of the table: "seriesPointsStructurePreset" */
  update_seriesPointsStructurePreset_by_pk?: Maybe<SeriesPointsStructurePreset>;
  /** update multiples rows of table: "seriesPointsStructurePreset" */
  update_seriesPointsStructurePreset_many?: Maybe<Array<Maybe<SeriesPointsStructurePresetMutationResponse>>>;
  /** update single row of the table: "series" */
  update_series_by_pk?: Maybe<Series>;
  /** update multiples rows of table: "series" */
  update_series_many?: Maybe<Array<Maybe<SeriesMutationResponse>>>;
  /** update data of the table: "signatureRequest" */
  update_signatureRequest?: Maybe<SignatureRequestMutationResponse>;
  /** update data of the table: "signatureRequestSignerType" */
  update_signatureRequestSignerType?: Maybe<SignatureRequestSignerTypeMutationResponse>;
  /** update single row of the table: "signatureRequestSignerType" */
  update_signatureRequestSignerType_by_pk?: Maybe<SignatureRequestSignerType>;
  /** update multiples rows of table: "signatureRequestSignerType" */
  update_signatureRequestSignerType_many?: Maybe<Array<Maybe<SignatureRequestSignerTypeMutationResponse>>>;
  /** update data of the table: "signatureRequestStatus" */
  update_signatureRequestStatus?: Maybe<SignatureRequestStatusMutationResponse>;
  /** update single row of the table: "signatureRequestStatus" */
  update_signatureRequestStatus_by_pk?: Maybe<SignatureRequestStatus>;
  /** update multiples rows of table: "signatureRequestStatus" */
  update_signatureRequestStatus_many?: Maybe<Array<Maybe<SignatureRequestStatusMutationResponse>>>;
  /** update single row of the table: "signatureRequest" */
  update_signatureRequest_by_pk?: Maybe<SignatureRequest>;
  /** update multiples rows of table: "signatureRequest" */
  update_signatureRequest_many?: Maybe<Array<Maybe<SignatureRequestMutationResponse>>>;
  /** update data of the table: "sponsor" */
  update_sponsor?: Maybe<SponsorMutationResponse>;
  /** update data of the table: "sponsorImage" */
  update_sponsorImage?: Maybe<SponsorImageMutationResponse>;
  /** update single row of the table: "sponsorImage" */
  update_sponsorImage_by_pk?: Maybe<SponsorImage>;
  /** update multiples rows of table: "sponsorImage" */
  update_sponsorImage_many?: Maybe<Array<Maybe<SponsorImageMutationResponse>>>;
  /** update single row of the table: "sponsor" */
  update_sponsor_by_pk?: Maybe<Sponsor>;
  /** update multiples rows of table: "sponsor" */
  update_sponsor_many?: Maybe<Array<Maybe<SponsorMutationResponse>>>;
  /** update data of the table: "stripeConnectAccount" */
  update_stripeConnectAccount?: Maybe<StripeConnectAccountMutationResponse>;
  /** update single row of the table: "stripeConnectAccount" */
  update_stripeConnectAccount_by_pk?: Maybe<StripeConnectAccount>;
  /** update multiples rows of table: "stripeConnectAccount" */
  update_stripeConnectAccount_many?: Maybe<Array<Maybe<StripeConnectAccountMutationResponse>>>;
  /** update data of the table: "ticket" */
  update_ticket?: Maybe<TicketMutationResponse>;
  /** update single row of the table: "ticket" */
  update_ticket_by_pk?: Maybe<Ticket>;
  /** update multiples rows of table: "ticket" */
  update_ticket_many?: Maybe<Array<Maybe<TicketMutationResponse>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<UserMutationResponse>;
  /** update data of the table: "userAddress" */
  update_userAddress?: Maybe<UserAddressMutationResponse>;
  /** update single row of the table: "userAddress" */
  update_userAddress_by_pk?: Maybe<UserAddress>;
  /** update multiples rows of table: "userAddress" */
  update_userAddress_many?: Maybe<Array<Maybe<UserAddressMutationResponse>>>;
  /** update data of the table: "userBike" */
  update_userBike?: Maybe<UserBikeMutationResponse>;
  /** update data of the table: "userBikeRevision" */
  update_userBikeRevision?: Maybe<UserBikeRevisionMutationResponse>;
  /** update single row of the table: "userBikeRevision" */
  update_userBikeRevision_by_pk?: Maybe<UserBikeRevision>;
  /** update multiples rows of table: "userBikeRevision" */
  update_userBikeRevision_many?: Maybe<Array<Maybe<UserBikeRevisionMutationResponse>>>;
  /** update single row of the table: "userBike" */
  update_userBike_by_pk?: Maybe<UserBike>;
  /** update multiples rows of table: "userBike" */
  update_userBike_many?: Maybe<Array<Maybe<UserBikeMutationResponse>>>;
  /** update data of the table: "userEmergencyContact" */
  update_userEmergencyContact?: Maybe<UserEmergencyContactMutationResponse>;
  /** update single row of the table: "userEmergencyContact" */
  update_userEmergencyContact_by_pk?: Maybe<UserEmergencyContact>;
  /** update multiples rows of table: "userEmergencyContact" */
  update_userEmergencyContact_many?: Maybe<Array<Maybe<UserEmergencyContactMutationResponse>>>;
  /** update data of the table: "userImage" */
  update_userImage?: Maybe<UserImageMutationResponse>;
  /** update single row of the table: "userImage" */
  update_userImage_by_pk?: Maybe<UserImage>;
  /** update multiples rows of table: "userImage" */
  update_userImage_many?: Maybe<Array<Maybe<UserImageMutationResponse>>>;
  /** update data of the table: "userOnlineCourse" */
  update_userOnlineCourse?: Maybe<UserOnlineCourseMutationResponse>;
  /** update data of the table: "userOnlineCourseStatus" */
  update_userOnlineCourseStatus?: Maybe<UserOnlineCourseStatusMutationResponse>;
  /** update single row of the table: "userOnlineCourseStatus" */
  update_userOnlineCourseStatus_by_pk?: Maybe<UserOnlineCourseStatus>;
  /** update multiples rows of table: "userOnlineCourseStatus" */
  update_userOnlineCourseStatus_many?: Maybe<Array<Maybe<UserOnlineCourseStatusMutationResponse>>>;
  /** update single row of the table: "userOnlineCourse" */
  update_userOnlineCourse_by_pk?: Maybe<UserOnlineCourse>;
  /** update multiples rows of table: "userOnlineCourse" */
  update_userOnlineCourse_many?: Maybe<Array<Maybe<UserOnlineCourseMutationResponse>>>;
  /** update data of the table: "userOrganizerRole" */
  update_userOrganizerRole?: Maybe<UserOrganizerRoleMutationResponse>;
  /** update single row of the table: "userOrganizerRole" */
  update_userOrganizerRole_by_pk?: Maybe<UserOrganizerRole>;
  /** update multiples rows of table: "userOrganizerRole" */
  update_userOrganizerRole_many?: Maybe<Array<Maybe<UserOrganizerRoleMutationResponse>>>;
  /** update data of the table: "userPersonalization" */
  update_userPersonalization?: Maybe<UserPersonalizationMutationResponse>;
  /** update single row of the table: "userPersonalization" */
  update_userPersonalization_by_pk?: Maybe<UserPersonalization>;
  /** update multiples rows of table: "userPersonalization" */
  update_userPersonalization_many?: Maybe<Array<Maybe<UserPersonalizationMutationResponse>>>;
  /** update data of the table: "userRidingInterest" */
  update_userRidingInterest?: Maybe<UserRidingInterestMutationResponse>;
  /** update single row of the table: "userRidingInterest" */
  update_userRidingInterest_by_pk?: Maybe<UserRidingInterest>;
  /** update multiples rows of table: "userRidingInterest" */
  update_userRidingInterest_many?: Maybe<Array<Maybe<UserRidingInterestMutationResponse>>>;
  /** update data of the table: "userSanctionLicense" */
  update_userSanctionLicense?: Maybe<UserSanctionLicenseMutationResponse>;
  /** update single row of the table: "userSanctionLicense" */
  update_userSanctionLicense_by_pk?: Maybe<UserSanctionLicense>;
  /** update multiples rows of table: "userSanctionLicense" */
  update_userSanctionLicense_many?: Maybe<Array<Maybe<UserSanctionLicenseMutationResponse>>>;
  /** update data of the table: "userSavedCoach" */
  update_userSavedCoach?: Maybe<UserSavedCoachMutationResponse>;
  /** update single row of the table: "userSavedCoach" */
  update_userSavedCoach_by_pk?: Maybe<UserSavedCoach>;
  /** update multiples rows of table: "userSavedCoach" */
  update_userSavedCoach_many?: Maybe<Array<Maybe<UserSavedCoachMutationResponse>>>;
  /** update data of the table: "userSavedEvent" */
  update_userSavedEvent?: Maybe<UserSavedEventMutationResponse>;
  /** update single row of the table: "userSavedEvent" */
  update_userSavedEvent_by_pk?: Maybe<UserSavedEvent>;
  /** update multiples rows of table: "userSavedEvent" */
  update_userSavedEvent_many?: Maybe<Array<Maybe<UserSavedEventMutationResponse>>>;
  /** update data of the table: "userSavedLesson" */
  update_userSavedLesson?: Maybe<UserSavedLessonMutationResponse>;
  /** update single row of the table: "userSavedLesson" */
  update_userSavedLesson_by_pk?: Maybe<UserSavedLesson>;
  /** update multiples rows of table: "userSavedLesson" */
  update_userSavedLesson_many?: Maybe<Array<Maybe<UserSavedLessonMutationResponse>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<UserMutationResponse>>>;
  /** update data of the table: "venue" */
  update_venue?: Maybe<VenueMutationResponse>;
  /** update single row of the table: "venue" */
  update_venue_by_pk?: Maybe<Venue>;
  /** update multiples rows of table: "venue" */
  update_venue_many?: Maybe<Array<Maybe<VenueMutationResponse>>>;
  /** update data of the table: "volunteerTaskDifficulties" */
  update_volunteerTaskDifficulties?: Maybe<VolunteerTaskDifficultiesMutationResponse>;
  /** update single row of the table: "volunteerTaskDifficulties" */
  update_volunteerTaskDifficulties_by_pk?: Maybe<VolunteerTaskDifficulties>;
  /** update multiples rows of table: "volunteerTaskDifficulties" */
  update_volunteerTaskDifficulties_many?: Maybe<Array<Maybe<VolunteerTaskDifficultiesMutationResponse>>>;
  /** update data of the table: "volunteerTaskPriorities" */
  update_volunteerTaskPriorities?: Maybe<VolunteerTaskPrioritiesMutationResponse>;
  /** update single row of the table: "volunteerTaskPriorities" */
  update_volunteerTaskPriorities_by_pk?: Maybe<VolunteerTaskPriorities>;
  /** update multiples rows of table: "volunteerTaskPriorities" */
  update_volunteerTaskPriorities_many?: Maybe<Array<Maybe<VolunteerTaskPrioritiesMutationResponse>>>;
  /** update data of the table: "waiver" */
  update_waiver?: Maybe<WaiverMutationResponse>;
  /** update data of the table: "waiverOwner" */
  update_waiverOwner?: Maybe<WaiverOwnerMutationResponse>;
  /** update single row of the table: "waiverOwner" */
  update_waiverOwner_by_pk?: Maybe<WaiverOwner>;
  /** update multiples rows of table: "waiverOwner" */
  update_waiverOwner_many?: Maybe<Array<Maybe<WaiverOwnerMutationResponse>>>;
  /** update data of the table: "waiverRevision" */
  update_waiverRevision?: Maybe<WaiverRevisionMutationResponse>;
  /** update single row of the table: "waiverRevision" */
  update_waiverRevision_by_pk?: Maybe<WaiverRevision>;
  /** update multiples rows of table: "waiverRevision" */
  update_waiverRevision_many?: Maybe<Array<Maybe<WaiverRevisionMutationResponse>>>;
  /** update single row of the table: "waiver" */
  update_waiver_by_pk?: Maybe<Waiver>;
  /** update multiples rows of table: "waiver" */
  update_waiver_many?: Maybe<Array<Maybe<WaiverMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteAccessNameArgs = {
  where: AccessNameBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccessNameByPkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteActivityTypeArgs = {
  where: ActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteActivityTypeByPkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAmenityArgs = {
  where: AmenityBoolExp;
};


/** mutation root */
export type MutationRootDeleteAmenityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteBasicSignatureRequestArgs = {
  where: BasicSignatureRequestBoolExp;
};


/** mutation root */
export type MutationRootDeleteBasicSignatureRequestByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCategoryArgs = {
  where: CategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteCategoryCourseArgs = {
  where: CategoryCourseBoolExp;
};


/** mutation root */
export type MutationRootDeleteCategoryCourseByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteChangeLogDateArgs = {
  where: ChangeLogDateBoolExp;
};


/** mutation root */
export type MutationRootDeleteChangeLogDateByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteChangeLogItemArgs = {
  where: ChangeLogItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteChangeLogItemByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteClinicArgs = {
  where: ClinicBoolExp;
};


/** mutation root */
export type MutationRootDeleteClinicCoachArgs = {
  where: ClinicCoachBoolExp;
};


/** mutation root */
export type MutationRootDeleteClinicCoachByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteClinicDayArgs = {
  where: ClinicDayBoolExp;
};


/** mutation root */
export type MutationRootDeleteClinicDayByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteClinicByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachArgs = {
  where: CoachBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachAvailabilityArgs = {
  where: CoachAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCertificationArgs = {
  where: CoachCertificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCertificationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerArgs = {
  where: CoachCustomerBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonArgs = {
  where: CoachCustomerLessonBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateArgs = {
  where: CoachCustomerLessonDateBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateLocationArgs = {
  where: CoachCustomerLessonDateLocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateLocationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateVirtualLocationArgs = {
  where: CoachCustomerLessonDateVirtualLocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateVirtualLocationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonDateByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonOneTimeChargeArgs = {
  where: CoachCustomerLessonOneTimeChargeBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonOneTimeChargeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonPauseResumeArgs = {
  where: CoachCustomerLessonPauseResumeBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonPauseResumeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonTransactionArgs = {
  where: CoachCustomerLessonTransactionBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonTransactionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerLessonByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerMessageRequestArgs = {
  where: CoachCustomerMessageRequestBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerMessageRequestByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerUserArgs = {
  where: CoachCustomerUserBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachCustomerUserByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachCustomerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachDisciplineArgs = {
  where: CoachDisciplineBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachDisciplineByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachImageArgs = {
  where: CoachImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachImageByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachLessonArgs = {
  where: CoachLessonBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachLessonLocationArgs = {
  where: CoachLessonLocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachLessonLocationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCoachLessonByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachLocationArgs = {
  where: CoachLocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachLocationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachModerationFlagArgs = {
  where: CoachModerationFlagBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachModerationFlagByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachStudentArgs = {
  where: CoachStudentBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachStudentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachUnavailableDateArgs = {
  where: CoachUnavailableDateBoolExp;
};


/** mutation root */
export type MutationRootDeleteCoachUnavailableDateByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCoachByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCourseArgs = {
  where: CourseBoolExp;
};


/** mutation root */
export type MutationRootDeleteCourseByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteCreatorInviteArgs = {
  where: CreatorInviteBoolExp;
};


/** mutation root */
export type MutationRootDeleteCreatorInviteByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventArgs = {
  where: EventBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventAdditionalInfoArgs = {
  where: EventAdditionalInfoBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventAdditionalInfoByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventAmenityArgs = {
  where: EventAmenityBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventAmenityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteEventBasicWaiverArgs = {
  where: EventBasicWaiverBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventBasicWaiverCategoryArgs = {
  where: EventBasicWaiverCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventBasicWaiverCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventBasicWaiverByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventChecklistItemArgs = {
  where: EventChecklistItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventChecklistItemByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventClinicDayDurationArgs = {
  where: EventClinicDayDurationBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventClinicDayDurationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionArgs = {
  where: EventCustomQuestionBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionCategoryArgs = {
  where: EventCustomQuestionCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionOptionArgs = {
  where: EventCustomQuestionOptionBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionOptionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventCustomQuestionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventDisciplineArgs = {
  where: EventDisciplineBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventDisciplineByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventImageArgs = {
  where: EventImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventImageByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventMerchandiseArgs = {
  where: EventMerchandiseBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventMerchandiseByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventMetadataArgs = {
  where: EventMetadataBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventMetadataByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventModerationFlagArgs = {
  where: EventModerationFlagBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventModerationFlagByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPitSpaceArgs = {
  where: EventPitSpaceBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPitSpaceAddOnArgs = {
  where: EventPitSpaceAddOnBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPitSpaceAddOnByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventPitSpaceByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventPricingChangeArgs = {
  where: EventPricingChangeBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPricingChangeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPricingDateArgs = {
  where: EventPricingDateBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPricingDateByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPricingGroupArgs = {
  where: EventPricingGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPricingGroupCategoryArgs = {
  where: EventPricingGroupCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPricingGroupCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPricingGroupByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPrivateLinkArgs = {
  where: EventPrivateLinkBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPrivateLinkByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventProgramArgs = {
  where: EventProgramBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventProgramByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventPromoCodeArgs = {
  where: EventPromoCodeBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventPromoCodeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventQuestionArgs = {
  where: EventQuestionBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventQuestionAnswerArgs = {
  where: EventQuestionAnswerBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventQuestionAnswerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventQuestionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventSanctionArgs = {
  where: EventSanctionBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventSanctionCategoryArgs = {
  where: EventSanctionCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventSanctionCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventSanctionByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventScheduleArgs = {
  where: EventScheduleBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventScheduleCategoryArgs = {
  where: EventScheduleCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventScheduleCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventScheduleDateArgs = {
  where: EventScheduleDateBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventScheduleDateByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventScheduleTypeCustomArgs = {
  where: EventScheduleTypeCustomBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventScheduleTypeCustomByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventScheduleTypePresetArgs = {
  where: EventScheduleTypePresetBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventScheduleTypePresetByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventScheduleByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventSeriesArgs = {
  where: EventSeriesBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventSeriesCategoryArgs = {
  where: EventSeriesCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventSeriesCategoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventSeriesByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventSponsorArgs = {
  where: EventSponsorBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventSponsorByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventTicketArgs = {
  where: EventTicketBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventTicketByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventUpdateArgs = {
  where: EventUpdateBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventUpdateCommentArgs = {
  where: EventUpdateCommentBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventUpdateCommentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventUpdateByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventVenueArgs = {
  where: EventVenueBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVenueByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteEventVibeArgs = {
  where: EventVibeBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVibeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventVirtualVenueArgs = {
  where: EventVirtualVenueBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVirtualVenueByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobArgs = {
  where: EventVolunteerJobBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobShiftArgs = {
  where: EventVolunteerJobShiftBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobShiftTaskArgs = {
  where: EventVolunteerJobShiftTaskBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobShiftTaskByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobShiftByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobTaskArgs = {
  where: EventVolunteerJobTaskBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobTaskByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventVolunteerJobByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventWaiverArgs = {
  where: EventWaiverBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventWaiverTypeArgs = {
  where: EventWaiverTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteEventWaiverTypeByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventWaiverByPkArgs = {
  eventCuid: Scalars['String'];
  waiverCuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteEventByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteImageArgs = {
  where: ImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteImageOnlineCourseArgs = {
  where: ImageOnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootDeleteImageOnlineCourseByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteImageUserArgs = {
  where: ImageUserBoolExp;
};


/** mutation root */
export type MutationRootDeleteImageUserByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteImageByPkArgs = {
  publicId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLessonLocationArgs = {
  where: LessonLocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteLessonLocationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseArgs = {
  where: MerchandiseBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseDeliveryTypeArgs = {
  where: MerchandiseDeliveryTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseDeliveryTypeByPkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseImageArgs = {
  where: MerchandiseImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseImageByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantArgs = {
  where: MerchandiseVariantBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantOptionArgs = {
  where: MerchandiseVariantOptionBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantOptionValueArgs = {
  where: MerchandiseVariantOptionValueBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantOptionValueByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantOptionByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseVariantByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMerchandiseByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOnlineCourseArgs = {
  where: OnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnlineCourseOwnerArgs = {
  where: OnlineCourseOwnerBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnlineCourseOwnerByPkArgs = {
  owner: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOnlineCourseRevisionArgs = {
  where: OnlineCourseRevisionBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnlineCourseRevisionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOnlineCourseByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrganizerArgs = {
  where: OrganizerBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizerChannelArgs = {
  where: OrganizerChannelBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizerChannelByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrganizerInviteArgs = {
  where: OrganizerInviteBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizerInviteByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrganizerByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRegistrationArgs = {
  where: RegistrationBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationActivityArgs = {
  where: RegistrationActivityBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationActivityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRegistrationAdditionalPaymentArgs = {
  where: RegistrationAdditionalPaymentBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationAdditionalPaymentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRegistrationCategoryArgs = {
  where: RegistrationCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationCategoryByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRegistrationForArgs = {
  where: RegistrationForBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationForByPkArgs = {
  for: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteRegistrationSeriesArgs = {
  where: RegistrationSeriesBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationSeriesByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteRegistrationStatusArgs = {
  where: RegistrationStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationStatusByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteRegistrationTicketArgs = {
  where: RegistrationTicketBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegistrationTicketByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRegistrationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteReportClinicNoShowArgs = {
  where: ReportClinicNoShowBoolExp;
};


/** mutation root */
export type MutationRootDeleteReportClinicNoShowByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteReportLessonNoShowArgs = {
  where: ReportLessonNoShowBoolExp;
};


/** mutation root */
export type MutationRootDeleteReportLessonNoShowByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteReviewArgs = {
  where: ReviewBoolExp;
};


/** mutation root */
export type MutationRootDeleteReviewSubjectTypeArgs = {
  where: ReviewSubjectTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteReviewSubjectTypeByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteReviewTotalsArgs = {
  where: ReviewTotalsBoolExp;
};


/** mutation root */
export type MutationRootDeleteReviewTotalsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteReviewByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteRideArgs = {
  where: RideBoolExp;
};


/** mutation root */
export type MutationRootDeleteRideDayArgs = {
  where: RideDayBoolExp;
};


/** mutation root */
export type MutationRootDeleteRideDayByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteRideByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteRoleOrganizerArgs = {
  where: RoleOrganizerBoolExp;
};


/** mutation root */
export type MutationRootDeleteRoleOrganizerByPkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSanctionArgs = {
  where: SanctionBoolExp;
};


/** mutation root */
export type MutationRootDeleteSanctionWaiversArgs = {
  where: SanctionWaiversBoolExp;
};


/** mutation root */
export type MutationRootDeleteSanctionWaiversByPkArgs = {
  sanctionCuid: Scalars['String'];
  waiverCuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSanctionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteScheduleTypePresetArgs = {
  where: ScheduleTypePresetBoolExp;
};


/** mutation root */
export type MutationRootDeleteScheduleTypePresetByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteSeriesArgs = {
  where: SeriesBoolExp;
};


/** mutation root */
export type MutationRootDeleteSeriesImageArgs = {
  where: SeriesImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteSeriesImageByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteSeriesPointsStructureCustomArgs = {
  where: SeriesPointsStructureCustomBoolExp;
};


/** mutation root */
export type MutationRootDeleteSeriesPointsStructureCustomByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSeriesPointsStructurePresetArgs = {
  where: SeriesPointsStructurePresetBoolExp;
};


/** mutation root */
export type MutationRootDeleteSeriesPointsStructurePresetByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteSeriesByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSignatureRequestArgs = {
  where: SignatureRequestBoolExp;
};


/** mutation root */
export type MutationRootDeleteSignatureRequestSignerTypeArgs = {
  where: SignatureRequestSignerTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteSignatureRequestSignerTypeByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSignatureRequestStatusArgs = {
  where: SignatureRequestStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteSignatureRequestStatusByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSignatureRequestByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteSponsorArgs = {
  where: SponsorBoolExp;
};


/** mutation root */
export type MutationRootDeleteSponsorImageArgs = {
  where: SponsorImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteSponsorImageByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteSponsorByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteStripeConnectAccountArgs = {
  where: StripeConnectAccountBoolExp;
};


/** mutation root */
export type MutationRootDeleteStripeConnectAccountByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteTicketArgs = {
  where: TicketBoolExp;
};


/** mutation root */
export type MutationRootDeleteTicketByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteUserArgs = {
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserAddressArgs = {
  where: UserAddressBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserAddressByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteUserBikeArgs = {
  where: UserBikeBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserBikeRevisionArgs = {
  where: UserBikeRevisionBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserBikeRevisionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserBikeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserEmergencyContactArgs = {
  where: UserEmergencyContactBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserEmergencyContactByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteUserImageArgs = {
  where: UserImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserImageByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserOnlineCourseArgs = {
  where: UserOnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserOnlineCourseStatusArgs = {
  where: UserOnlineCourseStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserOnlineCourseStatusByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteUserOnlineCourseByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserOrganizerRoleArgs = {
  where: UserOrganizerRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserOrganizerRoleByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteUserPersonalizationArgs = {
  where: UserPersonalizationBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserPersonalizationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteUserRidingInterestArgs = {
  where: UserRidingInterestBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserRidingInterestByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserSanctionLicenseArgs = {
  where: UserSanctionLicenseBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSanctionLicenseByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserSavedCoachArgs = {
  where: UserSavedCoachBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSavedCoachByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserSavedEventArgs = {
  where: UserSavedEventBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSavedEventByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserSavedLessonArgs = {
  where: UserSavedLessonBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSavedLessonByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteUserByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteVenueArgs = {
  where: VenueBoolExp;
};


/** mutation root */
export type MutationRootDeleteVenueByPkArgs = {
  cuid: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteVolunteerTaskDifficultiesArgs = {
  where: VolunteerTaskDifficultiesBoolExp;
};


/** mutation root */
export type MutationRootDeleteVolunteerTaskDifficultiesByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteVolunteerTaskPrioritiesArgs = {
  where: VolunteerTaskPrioritiesBoolExp;
};


/** mutation root */
export type MutationRootDeleteVolunteerTaskPrioritiesByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteWaiverArgs = {
  where: WaiverBoolExp;
};


/** mutation root */
export type MutationRootDeleteWaiverOwnerArgs = {
  where: WaiverOwnerBoolExp;
};


/** mutation root */
export type MutationRootDeleteWaiverOwnerByPkArgs = {
  owner: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWaiverRevisionArgs = {
  where: WaiverRevisionBoolExp;
};


/** mutation root */
export type MutationRootDeleteWaiverRevisionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteWaiverByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootInsertAccessNameArgs = {
  objects: Array<AccessNameInsertInput>;
  on_conflict?: InputMaybe<AccessNameOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccessNameOneArgs = {
  object: AccessNameInsertInput;
  on_conflict?: InputMaybe<AccessNameOnConflict>;
};


/** mutation root */
export type MutationRootInsertActivityTypeArgs = {
  objects: Array<ActivityTypeInsertInput>;
  on_conflict?: InputMaybe<ActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertActivityTypeOneArgs = {
  object: ActivityTypeInsertInput;
  on_conflict?: InputMaybe<ActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAmenityArgs = {
  objects: Array<AmenityInsertInput>;
  on_conflict?: InputMaybe<AmenityOnConflict>;
};


/** mutation root */
export type MutationRootInsertAmenityOneArgs = {
  object: AmenityInsertInput;
  on_conflict?: InputMaybe<AmenityOnConflict>;
};


/** mutation root */
export type MutationRootInsertBasicSignatureRequestArgs = {
  objects: Array<BasicSignatureRequestInsertInput>;
  on_conflict?: InputMaybe<BasicSignatureRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertBasicSignatureRequestOneArgs = {
  object: BasicSignatureRequestInsertInput;
  on_conflict?: InputMaybe<BasicSignatureRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryArgs = {
  objects: Array<CategoryInsertInput>;
  on_conflict?: InputMaybe<CategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryCourseArgs = {
  objects: Array<CategoryCourseInsertInput>;
  on_conflict?: InputMaybe<CategoryCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryCourseOneArgs = {
  object: CategoryCourseInsertInput;
  on_conflict?: InputMaybe<CategoryCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryOneArgs = {
  object: CategoryInsertInput;
  on_conflict?: InputMaybe<CategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeLogDateArgs = {
  objects: Array<ChangeLogDateInsertInput>;
  on_conflict?: InputMaybe<ChangeLogDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeLogDateOneArgs = {
  object: ChangeLogDateInsertInput;
  on_conflict?: InputMaybe<ChangeLogDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeLogItemArgs = {
  objects: Array<ChangeLogItemInsertInput>;
  on_conflict?: InputMaybe<ChangeLogItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeLogItemOneArgs = {
  object: ChangeLogItemInsertInput;
  on_conflict?: InputMaybe<ChangeLogItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicArgs = {
  objects: Array<ClinicInsertInput>;
  on_conflict?: InputMaybe<ClinicOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicCoachArgs = {
  objects: Array<ClinicCoachInsertInput>;
  on_conflict?: InputMaybe<ClinicCoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicCoachOneArgs = {
  object: ClinicCoachInsertInput;
  on_conflict?: InputMaybe<ClinicCoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicDayArgs = {
  objects: Array<ClinicDayInsertInput>;
  on_conflict?: InputMaybe<ClinicDayOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicDayOneArgs = {
  object: ClinicDayInsertInput;
  on_conflict?: InputMaybe<ClinicDayOnConflict>;
};


/** mutation root */
export type MutationRootInsertClinicOneArgs = {
  object: ClinicInsertInput;
  on_conflict?: InputMaybe<ClinicOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachArgs = {
  objects: Array<CoachInsertInput>;
  on_conflict?: InputMaybe<CoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachAvailabilityArgs = {
  objects: Array<CoachAvailabilityInsertInput>;
  on_conflict?: InputMaybe<CoachAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachAvailabilityOneArgs = {
  object: CoachAvailabilityInsertInput;
  on_conflict?: InputMaybe<CoachAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCertificationArgs = {
  objects: Array<CoachCertificationInsertInput>;
  on_conflict?: InputMaybe<CoachCertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCertificationOneArgs = {
  object: CoachCertificationInsertInput;
  on_conflict?: InputMaybe<CoachCertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerArgs = {
  objects: Array<CoachCustomerInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonArgs = {
  objects: Array<CoachCustomerLessonInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateArgs = {
  objects: Array<CoachCustomerLessonDateInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateLocationArgs = {
  objects: Array<CoachCustomerLessonDateLocationInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonDateLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateLocationOneArgs = {
  object: CoachCustomerLessonDateLocationInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonDateLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateVirtualLocationArgs = {
  objects: Array<CoachCustomerLessonDateVirtualLocationInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonDateVirtualLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateVirtualLocationOneArgs = {
  object: CoachCustomerLessonDateVirtualLocationInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonDateVirtualLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonDateOneArgs = {
  object: CoachCustomerLessonDateInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonOneTimeChargeArgs = {
  objects: Array<CoachCustomerLessonOneTimeChargeInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonOneTimeChargeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonOneTimeChargeOneArgs = {
  object: CoachCustomerLessonOneTimeChargeInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonOneTimeChargeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonPauseResumeArgs = {
  objects: Array<CoachCustomerLessonPauseResumeInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonPauseResumeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonPauseResumeOneArgs = {
  object: CoachCustomerLessonPauseResumeInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonPauseResumeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonTransactionArgs = {
  objects: Array<CoachCustomerLessonTransactionInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerLessonTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonTransactionOneArgs = {
  object: CoachCustomerLessonTransactionInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerLessonOneArgs = {
  object: CoachCustomerLessonInsertInput;
  on_conflict?: InputMaybe<CoachCustomerLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerMessageRequestArgs = {
  objects: Array<CoachCustomerMessageRequestInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerMessageRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerMessageRequestOneArgs = {
  object: CoachCustomerMessageRequestInsertInput;
  on_conflict?: InputMaybe<CoachCustomerMessageRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerUserArgs = {
  objects: Array<CoachCustomerUserInsertInput>;
  on_conflict?: InputMaybe<CoachCustomerUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerUserOneArgs = {
  object: CoachCustomerUserInsertInput;
  on_conflict?: InputMaybe<CoachCustomerUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachCustomerOneArgs = {
  object: CoachCustomerInsertInput;
  on_conflict?: InputMaybe<CoachCustomerOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachDisciplineArgs = {
  objects: Array<CoachDisciplineInsertInput>;
  on_conflict?: InputMaybe<CoachDisciplineOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachDisciplineOneArgs = {
  object: CoachDisciplineInsertInput;
  on_conflict?: InputMaybe<CoachDisciplineOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachImageArgs = {
  objects: Array<CoachImageInsertInput>;
  on_conflict?: InputMaybe<CoachImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachImageOneArgs = {
  object: CoachImageInsertInput;
  on_conflict?: InputMaybe<CoachImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLessonArgs = {
  objects: Array<CoachLessonInsertInput>;
  on_conflict?: InputMaybe<CoachLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLessonLocationArgs = {
  objects: Array<CoachLessonLocationInsertInput>;
  on_conflict?: InputMaybe<CoachLessonLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLessonLocationOneArgs = {
  object: CoachLessonLocationInsertInput;
  on_conflict?: InputMaybe<CoachLessonLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLessonOneArgs = {
  object: CoachLessonInsertInput;
  on_conflict?: InputMaybe<CoachLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLocationArgs = {
  objects: Array<CoachLocationInsertInput>;
  on_conflict?: InputMaybe<CoachLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachLocationOneArgs = {
  object: CoachLocationInsertInput;
  on_conflict?: InputMaybe<CoachLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachModerationFlagArgs = {
  objects: Array<CoachModerationFlagInsertInput>;
  on_conflict?: InputMaybe<CoachModerationFlagOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachModerationFlagOneArgs = {
  object: CoachModerationFlagInsertInput;
  on_conflict?: InputMaybe<CoachModerationFlagOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachStudentArgs = {
  objects: Array<CoachStudentInsertInput>;
  on_conflict?: InputMaybe<CoachStudentOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachStudentOneArgs = {
  object: CoachStudentInsertInput;
  on_conflict?: InputMaybe<CoachStudentOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachUnavailableDateArgs = {
  objects: Array<CoachUnavailableDateInsertInput>;
  on_conflict?: InputMaybe<CoachUnavailableDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachUnavailableDateOneArgs = {
  object: CoachUnavailableDateInsertInput;
  on_conflict?: InputMaybe<CoachUnavailableDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertCoachOneArgs = {
  object: CoachInsertInput;
  on_conflict?: InputMaybe<CoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertCourseArgs = {
  objects: Array<CourseInsertInput>;
  on_conflict?: InputMaybe<CourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertCourseOneArgs = {
  object: CourseInsertInput;
  on_conflict?: InputMaybe<CourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertCreatorInviteArgs = {
  objects: Array<CreatorInviteInsertInput>;
  on_conflict?: InputMaybe<CreatorInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertCreatorInviteOneArgs = {
  object: CreatorInviteInsertInput;
  on_conflict?: InputMaybe<CreatorInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventArgs = {
  objects: Array<EventInsertInput>;
  on_conflict?: InputMaybe<EventOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventAdditionalInfoArgs = {
  objects: Array<EventAdditionalInfoInsertInput>;
  on_conflict?: InputMaybe<EventAdditionalInfoOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventAdditionalInfoOneArgs = {
  object: EventAdditionalInfoInsertInput;
  on_conflict?: InputMaybe<EventAdditionalInfoOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventAmenityArgs = {
  objects: Array<EventAmenityInsertInput>;
  on_conflict?: InputMaybe<EventAmenityOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventAmenityOneArgs = {
  object: EventAmenityInsertInput;
  on_conflict?: InputMaybe<EventAmenityOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventBasicWaiverArgs = {
  objects: Array<EventBasicWaiverInsertInput>;
  on_conflict?: InputMaybe<EventBasicWaiverOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventBasicWaiverCategoryArgs = {
  objects: Array<EventBasicWaiverCategoryInsertInput>;
  on_conflict?: InputMaybe<EventBasicWaiverCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventBasicWaiverCategoryOneArgs = {
  object: EventBasicWaiverCategoryInsertInput;
  on_conflict?: InputMaybe<EventBasicWaiverCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventBasicWaiverOneArgs = {
  object: EventBasicWaiverInsertInput;
  on_conflict?: InputMaybe<EventBasicWaiverOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventChecklistItemArgs = {
  objects: Array<EventChecklistItemInsertInput>;
  on_conflict?: InputMaybe<EventChecklistItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventChecklistItemOneArgs = {
  object: EventChecklistItemInsertInput;
  on_conflict?: InputMaybe<EventChecklistItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventClinicDayDurationArgs = {
  objects: Array<EventClinicDayDurationInsertInput>;
  on_conflict?: InputMaybe<EventClinicDayDurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventClinicDayDurationOneArgs = {
  object: EventClinicDayDurationInsertInput;
  on_conflict?: InputMaybe<EventClinicDayDurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionArgs = {
  objects: Array<EventCustomQuestionInsertInput>;
  on_conflict?: InputMaybe<EventCustomQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionCategoryArgs = {
  objects: Array<EventCustomQuestionCategoryInsertInput>;
  on_conflict?: InputMaybe<EventCustomQuestionCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionCategoryOneArgs = {
  object: EventCustomQuestionCategoryInsertInput;
  on_conflict?: InputMaybe<EventCustomQuestionCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionOptionArgs = {
  objects: Array<EventCustomQuestionOptionInsertInput>;
  on_conflict?: InputMaybe<EventCustomQuestionOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionOptionOneArgs = {
  object: EventCustomQuestionOptionInsertInput;
  on_conflict?: InputMaybe<EventCustomQuestionOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventCustomQuestionOneArgs = {
  object: EventCustomQuestionInsertInput;
  on_conflict?: InputMaybe<EventCustomQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventDisciplineArgs = {
  objects: Array<EventDisciplineInsertInput>;
  on_conflict?: InputMaybe<EventDisciplineOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventDisciplineOneArgs = {
  object: EventDisciplineInsertInput;
  on_conflict?: InputMaybe<EventDisciplineOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventImageArgs = {
  objects: Array<EventImageInsertInput>;
  on_conflict?: InputMaybe<EventImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventImageOneArgs = {
  object: EventImageInsertInput;
  on_conflict?: InputMaybe<EventImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventMerchandiseArgs = {
  objects: Array<EventMerchandiseInsertInput>;
  on_conflict?: InputMaybe<EventMerchandiseOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventMerchandiseOneArgs = {
  object: EventMerchandiseInsertInput;
  on_conflict?: InputMaybe<EventMerchandiseOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventMetadataArgs = {
  objects: Array<EventMetadataInsertInput>;
  on_conflict?: InputMaybe<EventMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventMetadataOneArgs = {
  object: EventMetadataInsertInput;
  on_conflict?: InputMaybe<EventMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventModerationFlagArgs = {
  objects: Array<EventModerationFlagInsertInput>;
  on_conflict?: InputMaybe<EventModerationFlagOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventModerationFlagOneArgs = {
  object: EventModerationFlagInsertInput;
  on_conflict?: InputMaybe<EventModerationFlagOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPitSpaceArgs = {
  objects: Array<EventPitSpaceInsertInput>;
  on_conflict?: InputMaybe<EventPitSpaceOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPitSpaceAddOnArgs = {
  objects: Array<EventPitSpaceAddOnInsertInput>;
  on_conflict?: InputMaybe<EventPitSpaceAddOnOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPitSpaceAddOnOneArgs = {
  object: EventPitSpaceAddOnInsertInput;
  on_conflict?: InputMaybe<EventPitSpaceAddOnOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPitSpaceOneArgs = {
  object: EventPitSpaceInsertInput;
  on_conflict?: InputMaybe<EventPitSpaceOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingChangeArgs = {
  objects: Array<EventPricingChangeInsertInput>;
  on_conflict?: InputMaybe<EventPricingChangeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingChangeOneArgs = {
  object: EventPricingChangeInsertInput;
  on_conflict?: InputMaybe<EventPricingChangeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingDateArgs = {
  objects: Array<EventPricingDateInsertInput>;
  on_conflict?: InputMaybe<EventPricingDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingDateOneArgs = {
  object: EventPricingDateInsertInput;
  on_conflict?: InputMaybe<EventPricingDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingGroupArgs = {
  objects: Array<EventPricingGroupInsertInput>;
  on_conflict?: InputMaybe<EventPricingGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingGroupCategoryArgs = {
  objects: Array<EventPricingGroupCategoryInsertInput>;
  on_conflict?: InputMaybe<EventPricingGroupCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingGroupCategoryOneArgs = {
  object: EventPricingGroupCategoryInsertInput;
  on_conflict?: InputMaybe<EventPricingGroupCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPricingGroupOneArgs = {
  object: EventPricingGroupInsertInput;
  on_conflict?: InputMaybe<EventPricingGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPrivateLinkArgs = {
  objects: Array<EventPrivateLinkInsertInput>;
  on_conflict?: InputMaybe<EventPrivateLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPrivateLinkOneArgs = {
  object: EventPrivateLinkInsertInput;
  on_conflict?: InputMaybe<EventPrivateLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventProgramArgs = {
  objects: Array<EventProgramInsertInput>;
  on_conflict?: InputMaybe<EventProgramOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventProgramOneArgs = {
  object: EventProgramInsertInput;
  on_conflict?: InputMaybe<EventProgramOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPromoCodeArgs = {
  objects: Array<EventPromoCodeInsertInput>;
  on_conflict?: InputMaybe<EventPromoCodeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventPromoCodeOneArgs = {
  object: EventPromoCodeInsertInput;
  on_conflict?: InputMaybe<EventPromoCodeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventQuestionArgs = {
  objects: Array<EventQuestionInsertInput>;
  on_conflict?: InputMaybe<EventQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventQuestionAnswerArgs = {
  objects: Array<EventQuestionAnswerInsertInput>;
  on_conflict?: InputMaybe<EventQuestionAnswerOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventQuestionAnswerOneArgs = {
  object: EventQuestionAnswerInsertInput;
  on_conflict?: InputMaybe<EventQuestionAnswerOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventQuestionOneArgs = {
  object: EventQuestionInsertInput;
  on_conflict?: InputMaybe<EventQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSanctionArgs = {
  objects: Array<EventSanctionInsertInput>;
  on_conflict?: InputMaybe<EventSanctionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSanctionCategoryArgs = {
  objects: Array<EventSanctionCategoryInsertInput>;
  on_conflict?: InputMaybe<EventSanctionCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSanctionCategoryOneArgs = {
  object: EventSanctionCategoryInsertInput;
  on_conflict?: InputMaybe<EventSanctionCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSanctionOneArgs = {
  object: EventSanctionInsertInput;
  on_conflict?: InputMaybe<EventSanctionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleArgs = {
  objects: Array<EventScheduleInsertInput>;
  on_conflict?: InputMaybe<EventScheduleOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleCategoryArgs = {
  objects: Array<EventScheduleCategoryInsertInput>;
  on_conflict?: InputMaybe<EventScheduleCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleCategoryOneArgs = {
  object: EventScheduleCategoryInsertInput;
  on_conflict?: InputMaybe<EventScheduleCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleDateArgs = {
  objects: Array<EventScheduleDateInsertInput>;
  on_conflict?: InputMaybe<EventScheduleDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleDateOneArgs = {
  object: EventScheduleDateInsertInput;
  on_conflict?: InputMaybe<EventScheduleDateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleTypeCustomArgs = {
  objects: Array<EventScheduleTypeCustomInsertInput>;
  on_conflict?: InputMaybe<EventScheduleTypeCustomOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleTypeCustomOneArgs = {
  object: EventScheduleTypeCustomInsertInput;
  on_conflict?: InputMaybe<EventScheduleTypeCustomOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleTypePresetArgs = {
  objects: Array<EventScheduleTypePresetInsertInput>;
  on_conflict?: InputMaybe<EventScheduleTypePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleTypePresetOneArgs = {
  object: EventScheduleTypePresetInsertInput;
  on_conflict?: InputMaybe<EventScheduleTypePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventScheduleOneArgs = {
  object: EventScheduleInsertInput;
  on_conflict?: InputMaybe<EventScheduleOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSeriesArgs = {
  objects: Array<EventSeriesInsertInput>;
  on_conflict?: InputMaybe<EventSeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSeriesCategoryArgs = {
  objects: Array<EventSeriesCategoryInsertInput>;
  on_conflict?: InputMaybe<EventSeriesCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSeriesCategoryOneArgs = {
  object: EventSeriesCategoryInsertInput;
  on_conflict?: InputMaybe<EventSeriesCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSeriesOneArgs = {
  object: EventSeriesInsertInput;
  on_conflict?: InputMaybe<EventSeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSponsorArgs = {
  objects: Array<EventSponsorInsertInput>;
  on_conflict?: InputMaybe<EventSponsorOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventSponsorOneArgs = {
  object: EventSponsorInsertInput;
  on_conflict?: InputMaybe<EventSponsorOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventTicketArgs = {
  objects: Array<EventTicketInsertInput>;
  on_conflict?: InputMaybe<EventTicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventTicketOneArgs = {
  object: EventTicketInsertInput;
  on_conflict?: InputMaybe<EventTicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventUpdateArgs = {
  objects: Array<EventUpdateInsertInput>;
  on_conflict?: InputMaybe<EventUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventUpdateCommentArgs = {
  objects: Array<EventUpdateCommentInsertInput>;
  on_conflict?: InputMaybe<EventUpdateCommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventUpdateCommentOneArgs = {
  object: EventUpdateCommentInsertInput;
  on_conflict?: InputMaybe<EventUpdateCommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventUpdateOneArgs = {
  object: EventUpdateInsertInput;
  on_conflict?: InputMaybe<EventUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVenueArgs = {
  objects: Array<EventVenueInsertInput>;
  on_conflict?: InputMaybe<EventVenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVenueOneArgs = {
  object: EventVenueInsertInput;
  on_conflict?: InputMaybe<EventVenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVibeArgs = {
  objects: Array<EventVibeInsertInput>;
  on_conflict?: InputMaybe<EventVibeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVibeOneArgs = {
  object: EventVibeInsertInput;
  on_conflict?: InputMaybe<EventVibeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVirtualVenueArgs = {
  objects: Array<EventVirtualVenueInsertInput>;
  on_conflict?: InputMaybe<EventVirtualVenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVirtualVenueOneArgs = {
  object: EventVirtualVenueInsertInput;
  on_conflict?: InputMaybe<EventVirtualVenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobArgs = {
  objects: Array<EventVolunteerJobInsertInput>;
  on_conflict?: InputMaybe<EventVolunteerJobOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobShiftArgs = {
  objects: Array<EventVolunteerJobShiftInsertInput>;
  on_conflict?: InputMaybe<EventVolunteerJobShiftOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobShiftTaskArgs = {
  objects: Array<EventVolunteerJobShiftTaskInsertInput>;
  on_conflict?: InputMaybe<EventVolunteerJobShiftTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobShiftTaskOneArgs = {
  object: EventVolunteerJobShiftTaskInsertInput;
  on_conflict?: InputMaybe<EventVolunteerJobShiftTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobShiftOneArgs = {
  object: EventVolunteerJobShiftInsertInput;
  on_conflict?: InputMaybe<EventVolunteerJobShiftOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobTaskArgs = {
  objects: Array<EventVolunteerJobTaskInsertInput>;
  on_conflict?: InputMaybe<EventVolunteerJobTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobTaskOneArgs = {
  object: EventVolunteerJobTaskInsertInput;
  on_conflict?: InputMaybe<EventVolunteerJobTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventVolunteerJobOneArgs = {
  object: EventVolunteerJobInsertInput;
  on_conflict?: InputMaybe<EventVolunteerJobOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventWaiverArgs = {
  objects: Array<EventWaiverInsertInput>;
  on_conflict?: InputMaybe<EventWaiverOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventWaiverTypeArgs = {
  objects: Array<EventWaiverTypeInsertInput>;
  on_conflict?: InputMaybe<EventWaiverTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventWaiverTypeOneArgs = {
  object: EventWaiverTypeInsertInput;
  on_conflict?: InputMaybe<EventWaiverTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventWaiverOneArgs = {
  object: EventWaiverInsertInput;
  on_conflict?: InputMaybe<EventWaiverOnConflict>;
};


/** mutation root */
export type MutationRootInsertEventOneArgs = {
  object: EventInsertInput;
  on_conflict?: InputMaybe<EventOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageArgs = {
  objects: Array<ImageInsertInput>;
  on_conflict?: InputMaybe<ImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageOnlineCourseArgs = {
  objects: Array<ImageOnlineCourseInsertInput>;
  on_conflict?: InputMaybe<ImageOnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageOnlineCourseOneArgs = {
  object: ImageOnlineCourseInsertInput;
  on_conflict?: InputMaybe<ImageOnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageUserArgs = {
  objects: Array<ImageUserInsertInput>;
  on_conflict?: InputMaybe<ImageUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageUserOneArgs = {
  object: ImageUserInsertInput;
  on_conflict?: InputMaybe<ImageUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageOneArgs = {
  object: ImageInsertInput;
  on_conflict?: InputMaybe<ImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertLessonLocationArgs = {
  objects: Array<LessonLocationInsertInput>;
  on_conflict?: InputMaybe<LessonLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLessonLocationOneArgs = {
  object: LessonLocationInsertInput;
  on_conflict?: InputMaybe<LessonLocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseArgs = {
  objects: Array<MerchandiseInsertInput>;
  on_conflict?: InputMaybe<MerchandiseOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseDeliveryTypeArgs = {
  objects: Array<MerchandiseDeliveryTypeInsertInput>;
  on_conflict?: InputMaybe<MerchandiseDeliveryTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseDeliveryTypeOneArgs = {
  object: MerchandiseDeliveryTypeInsertInput;
  on_conflict?: InputMaybe<MerchandiseDeliveryTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseImageArgs = {
  objects: Array<MerchandiseImageInsertInput>;
  on_conflict?: InputMaybe<MerchandiseImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseImageOneArgs = {
  object: MerchandiseImageInsertInput;
  on_conflict?: InputMaybe<MerchandiseImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantArgs = {
  objects: Array<MerchandiseVariantInsertInput>;
  on_conflict?: InputMaybe<MerchandiseVariantOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantOptionArgs = {
  objects: Array<MerchandiseVariantOptionInsertInput>;
  on_conflict?: InputMaybe<MerchandiseVariantOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantOptionValueArgs = {
  objects: Array<MerchandiseVariantOptionValueInsertInput>;
  on_conflict?: InputMaybe<MerchandiseVariantOptionValueOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantOptionValueOneArgs = {
  object: MerchandiseVariantOptionValueInsertInput;
  on_conflict?: InputMaybe<MerchandiseVariantOptionValueOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantOptionOneArgs = {
  object: MerchandiseVariantOptionInsertInput;
  on_conflict?: InputMaybe<MerchandiseVariantOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseVariantOneArgs = {
  object: MerchandiseVariantInsertInput;
  on_conflict?: InputMaybe<MerchandiseVariantOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchandiseOneArgs = {
  object: MerchandiseInsertInput;
  on_conflict?: InputMaybe<MerchandiseOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseArgs = {
  objects: Array<OnlineCourseInsertInput>;
  on_conflict?: InputMaybe<OnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseOwnerArgs = {
  objects: Array<OnlineCourseOwnerInsertInput>;
  on_conflict?: InputMaybe<OnlineCourseOwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseOwnerOneArgs = {
  object: OnlineCourseOwnerInsertInput;
  on_conflict?: InputMaybe<OnlineCourseOwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseRevisionArgs = {
  objects: Array<OnlineCourseRevisionInsertInput>;
  on_conflict?: InputMaybe<OnlineCourseRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseRevisionOneArgs = {
  object: OnlineCourseRevisionInsertInput;
  on_conflict?: InputMaybe<OnlineCourseRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlineCourseOneArgs = {
  object: OnlineCourseInsertInput;
  on_conflict?: InputMaybe<OnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerArgs = {
  objects: Array<OrganizerInsertInput>;
  on_conflict?: InputMaybe<OrganizerOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerChannelArgs = {
  objects: Array<OrganizerChannelInsertInput>;
  on_conflict?: InputMaybe<OrganizerChannelOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerChannelOneArgs = {
  object: OrganizerChannelInsertInput;
  on_conflict?: InputMaybe<OrganizerChannelOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerInviteArgs = {
  objects: Array<OrganizerInviteInsertInput>;
  on_conflict?: InputMaybe<OrganizerInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerInviteOneArgs = {
  object: OrganizerInviteInsertInput;
  on_conflict?: InputMaybe<OrganizerInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizerOneArgs = {
  object: OrganizerInsertInput;
  on_conflict?: InputMaybe<OrganizerOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationArgs = {
  objects: Array<RegistrationInsertInput>;
  on_conflict?: InputMaybe<RegistrationOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationActivityArgs = {
  objects: Array<RegistrationActivityInsertInput>;
  on_conflict?: InputMaybe<RegistrationActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationActivityOneArgs = {
  object: RegistrationActivityInsertInput;
  on_conflict?: InputMaybe<RegistrationActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationAdditionalPaymentArgs = {
  objects: Array<RegistrationAdditionalPaymentInsertInput>;
  on_conflict?: InputMaybe<RegistrationAdditionalPaymentOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationAdditionalPaymentOneArgs = {
  object: RegistrationAdditionalPaymentInsertInput;
  on_conflict?: InputMaybe<RegistrationAdditionalPaymentOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationCategoryArgs = {
  objects: Array<RegistrationCategoryInsertInput>;
  on_conflict?: InputMaybe<RegistrationCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationCategoryOneArgs = {
  object: RegistrationCategoryInsertInput;
  on_conflict?: InputMaybe<RegistrationCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationForArgs = {
  objects: Array<RegistrationForInsertInput>;
  on_conflict?: InputMaybe<RegistrationForOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationForOneArgs = {
  object: RegistrationForInsertInput;
  on_conflict?: InputMaybe<RegistrationForOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationSeriesArgs = {
  objects: Array<RegistrationSeriesInsertInput>;
  on_conflict?: InputMaybe<RegistrationSeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationSeriesOneArgs = {
  object: RegistrationSeriesInsertInput;
  on_conflict?: InputMaybe<RegistrationSeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationStatusArgs = {
  objects: Array<RegistrationStatusInsertInput>;
  on_conflict?: InputMaybe<RegistrationStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationStatusOneArgs = {
  object: RegistrationStatusInsertInput;
  on_conflict?: InputMaybe<RegistrationStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationTicketArgs = {
  objects: Array<RegistrationTicketInsertInput>;
  on_conflict?: InputMaybe<RegistrationTicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationTicketOneArgs = {
  object: RegistrationTicketInsertInput;
  on_conflict?: InputMaybe<RegistrationTicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegistrationOneArgs = {
  object: RegistrationInsertInput;
  on_conflict?: InputMaybe<RegistrationOnConflict>;
};


/** mutation root */
export type MutationRootInsertReportClinicNoShowArgs = {
  objects: Array<ReportClinicNoShowInsertInput>;
  on_conflict?: InputMaybe<ReportClinicNoShowOnConflict>;
};


/** mutation root */
export type MutationRootInsertReportClinicNoShowOneArgs = {
  object: ReportClinicNoShowInsertInput;
  on_conflict?: InputMaybe<ReportClinicNoShowOnConflict>;
};


/** mutation root */
export type MutationRootInsertReportLessonNoShowArgs = {
  objects: Array<ReportLessonNoShowInsertInput>;
  on_conflict?: InputMaybe<ReportLessonNoShowOnConflict>;
};


/** mutation root */
export type MutationRootInsertReportLessonNoShowOneArgs = {
  object: ReportLessonNoShowInsertInput;
  on_conflict?: InputMaybe<ReportLessonNoShowOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewArgs = {
  objects: Array<ReviewInsertInput>;
  on_conflict?: InputMaybe<ReviewOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewSubjectTypeArgs = {
  objects: Array<ReviewSubjectTypeInsertInput>;
  on_conflict?: InputMaybe<ReviewSubjectTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewSubjectTypeOneArgs = {
  object: ReviewSubjectTypeInsertInput;
  on_conflict?: InputMaybe<ReviewSubjectTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewTotalsArgs = {
  objects: Array<ReviewTotalsInsertInput>;
  on_conflict?: InputMaybe<ReviewTotalsOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewTotalsOneArgs = {
  object: ReviewTotalsInsertInput;
  on_conflict?: InputMaybe<ReviewTotalsOnConflict>;
};


/** mutation root */
export type MutationRootInsertReviewOneArgs = {
  object: ReviewInsertInput;
  on_conflict?: InputMaybe<ReviewOnConflict>;
};


/** mutation root */
export type MutationRootInsertRideArgs = {
  objects: Array<RideInsertInput>;
  on_conflict?: InputMaybe<RideOnConflict>;
};


/** mutation root */
export type MutationRootInsertRideDayArgs = {
  objects: Array<RideDayInsertInput>;
  on_conflict?: InputMaybe<RideDayOnConflict>;
};


/** mutation root */
export type MutationRootInsertRideDayOneArgs = {
  object: RideDayInsertInput;
  on_conflict?: InputMaybe<RideDayOnConflict>;
};


/** mutation root */
export type MutationRootInsertRideOneArgs = {
  object: RideInsertInput;
  on_conflict?: InputMaybe<RideOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleOrganizerArgs = {
  objects: Array<RoleOrganizerInsertInput>;
  on_conflict?: InputMaybe<RoleOrganizerOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleOrganizerOneArgs = {
  object: RoleOrganizerInsertInput;
  on_conflict?: InputMaybe<RoleOrganizerOnConflict>;
};


/** mutation root */
export type MutationRootInsertSanctionArgs = {
  objects: Array<SanctionInsertInput>;
  on_conflict?: InputMaybe<SanctionOnConflict>;
};


/** mutation root */
export type MutationRootInsertSanctionWaiversArgs = {
  objects: Array<SanctionWaiversInsertInput>;
  on_conflict?: InputMaybe<SanctionWaiversOnConflict>;
};


/** mutation root */
export type MutationRootInsertSanctionWaiversOneArgs = {
  object: SanctionWaiversInsertInput;
  on_conflict?: InputMaybe<SanctionWaiversOnConflict>;
};


/** mutation root */
export type MutationRootInsertSanctionOneArgs = {
  object: SanctionInsertInput;
  on_conflict?: InputMaybe<SanctionOnConflict>;
};


/** mutation root */
export type MutationRootInsertScheduleTypePresetArgs = {
  objects: Array<ScheduleTypePresetInsertInput>;
  on_conflict?: InputMaybe<ScheduleTypePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertScheduleTypePresetOneArgs = {
  object: ScheduleTypePresetInsertInput;
  on_conflict?: InputMaybe<ScheduleTypePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesArgs = {
  objects: Array<SeriesInsertInput>;
  on_conflict?: InputMaybe<SeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesImageArgs = {
  objects: Array<SeriesImageInsertInput>;
  on_conflict?: InputMaybe<SeriesImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesImageOneArgs = {
  object: SeriesImageInsertInput;
  on_conflict?: InputMaybe<SeriesImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesPointsStructureCustomArgs = {
  objects: Array<SeriesPointsStructureCustomInsertInput>;
  on_conflict?: InputMaybe<SeriesPointsStructureCustomOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesPointsStructureCustomOneArgs = {
  object: SeriesPointsStructureCustomInsertInput;
  on_conflict?: InputMaybe<SeriesPointsStructureCustomOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesPointsStructurePresetArgs = {
  objects: Array<SeriesPointsStructurePresetInsertInput>;
  on_conflict?: InputMaybe<SeriesPointsStructurePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesPointsStructurePresetOneArgs = {
  object: SeriesPointsStructurePresetInsertInput;
  on_conflict?: InputMaybe<SeriesPointsStructurePresetOnConflict>;
};


/** mutation root */
export type MutationRootInsertSeriesOneArgs = {
  object: SeriesInsertInput;
  on_conflict?: InputMaybe<SeriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestArgs = {
  objects: Array<SignatureRequestInsertInput>;
  on_conflict?: InputMaybe<SignatureRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestSignerTypeArgs = {
  objects: Array<SignatureRequestSignerTypeInsertInput>;
  on_conflict?: InputMaybe<SignatureRequestSignerTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestSignerTypeOneArgs = {
  object: SignatureRequestSignerTypeInsertInput;
  on_conflict?: InputMaybe<SignatureRequestSignerTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestStatusArgs = {
  objects: Array<SignatureRequestStatusInsertInput>;
  on_conflict?: InputMaybe<SignatureRequestStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestStatusOneArgs = {
  object: SignatureRequestStatusInsertInput;
  on_conflict?: InputMaybe<SignatureRequestStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertSignatureRequestOneArgs = {
  object: SignatureRequestInsertInput;
  on_conflict?: InputMaybe<SignatureRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertSponsorArgs = {
  objects: Array<SponsorInsertInput>;
  on_conflict?: InputMaybe<SponsorOnConflict>;
};


/** mutation root */
export type MutationRootInsertSponsorImageArgs = {
  objects: Array<SponsorImageInsertInput>;
  on_conflict?: InputMaybe<SponsorImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertSponsorImageOneArgs = {
  object: SponsorImageInsertInput;
  on_conflict?: InputMaybe<SponsorImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertSponsorOneArgs = {
  object: SponsorInsertInput;
  on_conflict?: InputMaybe<SponsorOnConflict>;
};


/** mutation root */
export type MutationRootInsertStripeConnectAccountArgs = {
  objects: Array<StripeConnectAccountInsertInput>;
  on_conflict?: InputMaybe<StripeConnectAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertStripeConnectAccountOneArgs = {
  object: StripeConnectAccountInsertInput;
  on_conflict?: InputMaybe<StripeConnectAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertTicketArgs = {
  objects: Array<TicketInsertInput>;
  on_conflict?: InputMaybe<TicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertTicketOneArgs = {
  object: TicketInsertInput;
  on_conflict?: InputMaybe<TicketOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserAddressArgs = {
  objects: Array<UserAddressInsertInput>;
  on_conflict?: InputMaybe<UserAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserAddressOneArgs = {
  object: UserAddressInsertInput;
  on_conflict?: InputMaybe<UserAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserBikeArgs = {
  objects: Array<UserBikeInsertInput>;
  on_conflict?: InputMaybe<UserBikeOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserBikeRevisionArgs = {
  objects: Array<UserBikeRevisionInsertInput>;
  on_conflict?: InputMaybe<UserBikeRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserBikeRevisionOneArgs = {
  object: UserBikeRevisionInsertInput;
  on_conflict?: InputMaybe<UserBikeRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserBikeOneArgs = {
  object: UserBikeInsertInput;
  on_conflict?: InputMaybe<UserBikeOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserEmergencyContactArgs = {
  objects: Array<UserEmergencyContactInsertInput>;
  on_conflict?: InputMaybe<UserEmergencyContactOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserEmergencyContactOneArgs = {
  object: UserEmergencyContactInsertInput;
  on_conflict?: InputMaybe<UserEmergencyContactOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserImageArgs = {
  objects: Array<UserImageInsertInput>;
  on_conflict?: InputMaybe<UserImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserImageOneArgs = {
  object: UserImageInsertInput;
  on_conflict?: InputMaybe<UserImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOnlineCourseArgs = {
  objects: Array<UserOnlineCourseInsertInput>;
  on_conflict?: InputMaybe<UserOnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOnlineCourseStatusArgs = {
  objects: Array<UserOnlineCourseStatusInsertInput>;
  on_conflict?: InputMaybe<UserOnlineCourseStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOnlineCourseStatusOneArgs = {
  object: UserOnlineCourseStatusInsertInput;
  on_conflict?: InputMaybe<UserOnlineCourseStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOnlineCourseOneArgs = {
  object: UserOnlineCourseInsertInput;
  on_conflict?: InputMaybe<UserOnlineCourseOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizerRoleArgs = {
  objects: Array<UserOrganizerRoleInsertInput>;
  on_conflict?: InputMaybe<UserOrganizerRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizerRoleOneArgs = {
  object: UserOrganizerRoleInsertInput;
  on_conflict?: InputMaybe<UserOrganizerRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserPersonalizationArgs = {
  objects: Array<UserPersonalizationInsertInput>;
  on_conflict?: InputMaybe<UserPersonalizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserPersonalizationOneArgs = {
  object: UserPersonalizationInsertInput;
  on_conflict?: InputMaybe<UserPersonalizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserRidingInterestArgs = {
  objects: Array<UserRidingInterestInsertInput>;
  on_conflict?: InputMaybe<UserRidingInterestOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserRidingInterestOneArgs = {
  object: UserRidingInterestInsertInput;
  on_conflict?: InputMaybe<UserRidingInterestOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSanctionLicenseArgs = {
  objects: Array<UserSanctionLicenseInsertInput>;
  on_conflict?: InputMaybe<UserSanctionLicenseOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSanctionLicenseOneArgs = {
  object: UserSanctionLicenseInsertInput;
  on_conflict?: InputMaybe<UserSanctionLicenseOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedCoachArgs = {
  objects: Array<UserSavedCoachInsertInput>;
  on_conflict?: InputMaybe<UserSavedCoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedCoachOneArgs = {
  object: UserSavedCoachInsertInput;
  on_conflict?: InputMaybe<UserSavedCoachOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedEventArgs = {
  objects: Array<UserSavedEventInsertInput>;
  on_conflict?: InputMaybe<UserSavedEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedEventOneArgs = {
  object: UserSavedEventInsertInput;
  on_conflict?: InputMaybe<UserSavedEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedLessonArgs = {
  objects: Array<UserSavedLessonInsertInput>;
  on_conflict?: InputMaybe<UserSavedLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSavedLessonOneArgs = {
  object: UserSavedLessonInsertInput;
  on_conflict?: InputMaybe<UserSavedLessonOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOneArgs = {
  object: UserInsertInput;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootInsertVenueArgs = {
  objects: Array<VenueInsertInput>;
  on_conflict?: InputMaybe<VenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertVenueOneArgs = {
  object: VenueInsertInput;
  on_conflict?: InputMaybe<VenueOnConflict>;
};


/** mutation root */
export type MutationRootInsertVolunteerTaskDifficultiesArgs = {
  objects: Array<VolunteerTaskDifficultiesInsertInput>;
  on_conflict?: InputMaybe<VolunteerTaskDifficultiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertVolunteerTaskDifficultiesOneArgs = {
  object: VolunteerTaskDifficultiesInsertInput;
  on_conflict?: InputMaybe<VolunteerTaskDifficultiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertVolunteerTaskPrioritiesArgs = {
  objects: Array<VolunteerTaskPrioritiesInsertInput>;
  on_conflict?: InputMaybe<VolunteerTaskPrioritiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertVolunteerTaskPrioritiesOneArgs = {
  object: VolunteerTaskPrioritiesInsertInput;
  on_conflict?: InputMaybe<VolunteerTaskPrioritiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverArgs = {
  objects: Array<WaiverInsertInput>;
  on_conflict?: InputMaybe<WaiverOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverOwnerArgs = {
  objects: Array<WaiverOwnerInsertInput>;
  on_conflict?: InputMaybe<WaiverOwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverOwnerOneArgs = {
  object: WaiverOwnerInsertInput;
  on_conflict?: InputMaybe<WaiverOwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverRevisionArgs = {
  objects: Array<WaiverRevisionInsertInput>;
  on_conflict?: InputMaybe<WaiverRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverRevisionOneArgs = {
  object: WaiverRevisionInsertInput;
  on_conflict?: InputMaybe<WaiverRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertWaiverOneArgs = {
  object: WaiverInsertInput;
  on_conflict?: InputMaybe<WaiverOnConflict>;
};


/** mutation root */
export type MutationRootUpdateAccessNameArgs = {
  _set?: InputMaybe<AccessNameSetInput>;
  where: AccessNameBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccessNameByPkArgs = {
  _set?: InputMaybe<AccessNameSetInput>;
  pk_columns: AccessNamePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccessNameManyArgs = {
  updates: Array<AccessNameUpdates>;
};


/** mutation root */
export type MutationRootUpdateActivityTypeArgs = {
  _set?: InputMaybe<ActivityTypeSetInput>;
  where: ActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateActivityTypeByPkArgs = {
  _set?: InputMaybe<ActivityTypeSetInput>;
  pk_columns: ActivityTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActivityTypeManyArgs = {
  updates: Array<ActivityTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateAmenityArgs = {
  _inc?: InputMaybe<AmenityIncInput>;
  _set?: InputMaybe<AmenitySetInput>;
  where: AmenityBoolExp;
};


/** mutation root */
export type MutationRootUpdateAmenityByPkArgs = {
  _inc?: InputMaybe<AmenityIncInput>;
  _set?: InputMaybe<AmenitySetInput>;
  pk_columns: AmenityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAmenityManyArgs = {
  updates: Array<AmenityUpdates>;
};


/** mutation root */
export type MutationRootUpdateBasicSignatureRequestArgs = {
  _inc?: InputMaybe<BasicSignatureRequestIncInput>;
  _set?: InputMaybe<BasicSignatureRequestSetInput>;
  where: BasicSignatureRequestBoolExp;
};


/** mutation root */
export type MutationRootUpdateBasicSignatureRequestByPkArgs = {
  _inc?: InputMaybe<BasicSignatureRequestIncInput>;
  _set?: InputMaybe<BasicSignatureRequestSetInput>;
  pk_columns: BasicSignatureRequestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBasicSignatureRequestManyArgs = {
  updates: Array<BasicSignatureRequestUpdates>;
};


/** mutation root */
export type MutationRootUpdateCategoryArgs = {
  _append?: InputMaybe<CategoryAppendInput>;
  _delete_at_path?: InputMaybe<CategoryDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CategoryDeleteElemInput>;
  _delete_key?: InputMaybe<CategoryDeleteKeyInput>;
  _inc?: InputMaybe<CategoryIncInput>;
  _prepend?: InputMaybe<CategoryPrependInput>;
  _set?: InputMaybe<CategorySetInput>;
  where: CategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateCategoryCourseArgs = {
  _set?: InputMaybe<CategoryCourseSetInput>;
  where: CategoryCourseBoolExp;
};


/** mutation root */
export type MutationRootUpdateCategoryCourseByPkArgs = {
  _set?: InputMaybe<CategoryCourseSetInput>;
  pk_columns: CategoryCoursePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCategoryCourseManyArgs = {
  updates: Array<CategoryCourseUpdates>;
};


/** mutation root */
export type MutationRootUpdateCategoryByPkArgs = {
  _append?: InputMaybe<CategoryAppendInput>;
  _delete_at_path?: InputMaybe<CategoryDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CategoryDeleteElemInput>;
  _delete_key?: InputMaybe<CategoryDeleteKeyInput>;
  _inc?: InputMaybe<CategoryIncInput>;
  _prepend?: InputMaybe<CategoryPrependInput>;
  _set?: InputMaybe<CategorySetInput>;
  pk_columns: CategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCategoryManyArgs = {
  updates: Array<CategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateChangeLogDateArgs = {
  _inc?: InputMaybe<ChangeLogDateIncInput>;
  _set?: InputMaybe<ChangeLogDateSetInput>;
  where: ChangeLogDateBoolExp;
};


/** mutation root */
export type MutationRootUpdateChangeLogDateByPkArgs = {
  _inc?: InputMaybe<ChangeLogDateIncInput>;
  _set?: InputMaybe<ChangeLogDateSetInput>;
  pk_columns: ChangeLogDatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChangeLogDateManyArgs = {
  updates: Array<ChangeLogDateUpdates>;
};


/** mutation root */
export type MutationRootUpdateChangeLogItemArgs = {
  _inc?: InputMaybe<ChangeLogItemIncInput>;
  _set?: InputMaybe<ChangeLogItemSetInput>;
  where: ChangeLogItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateChangeLogItemByPkArgs = {
  _inc?: InputMaybe<ChangeLogItemIncInput>;
  _set?: InputMaybe<ChangeLogItemSetInput>;
  pk_columns: ChangeLogItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChangeLogItemManyArgs = {
  updates: Array<ChangeLogItemUpdates>;
};


/** mutation root */
export type MutationRootUpdateClinicArgs = {
  _inc?: InputMaybe<ClinicIncInput>;
  _set?: InputMaybe<ClinicSetInput>;
  where: ClinicBoolExp;
};


/** mutation root */
export type MutationRootUpdateClinicCoachArgs = {
  _inc?: InputMaybe<ClinicCoachIncInput>;
  _set?: InputMaybe<ClinicCoachSetInput>;
  where: ClinicCoachBoolExp;
};


/** mutation root */
export type MutationRootUpdateClinicCoachByPkArgs = {
  _inc?: InputMaybe<ClinicCoachIncInput>;
  _set?: InputMaybe<ClinicCoachSetInput>;
  pk_columns: ClinicCoachPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateClinicCoachManyArgs = {
  updates: Array<ClinicCoachUpdates>;
};


/** mutation root */
export type MutationRootUpdateClinicDayArgs = {
  _inc?: InputMaybe<ClinicDayIncInput>;
  _set?: InputMaybe<ClinicDaySetInput>;
  where: ClinicDayBoolExp;
};


/** mutation root */
export type MutationRootUpdateClinicDayByPkArgs = {
  _inc?: InputMaybe<ClinicDayIncInput>;
  _set?: InputMaybe<ClinicDaySetInput>;
  pk_columns: ClinicDayPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateClinicDayManyArgs = {
  updates: Array<ClinicDayUpdates>;
};


/** mutation root */
export type MutationRootUpdateClinicByPkArgs = {
  _inc?: InputMaybe<ClinicIncInput>;
  _set?: InputMaybe<ClinicSetInput>;
  pk_columns: ClinicPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateClinicManyArgs = {
  updates: Array<ClinicUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachArgs = {
  _append?: InputMaybe<CoachAppendInput>;
  _delete_at_path?: InputMaybe<CoachDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachDeleteElemInput>;
  _delete_key?: InputMaybe<CoachDeleteKeyInput>;
  _inc?: InputMaybe<CoachIncInput>;
  _prepend?: InputMaybe<CoachPrependInput>;
  _set?: InputMaybe<CoachSetInput>;
  where: CoachBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachAvailabilityArgs = {
  _inc?: InputMaybe<CoachAvailabilityIncInput>;
  _set?: InputMaybe<CoachAvailabilitySetInput>;
  where: CoachAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachAvailabilityByPkArgs = {
  _inc?: InputMaybe<CoachAvailabilityIncInput>;
  _set?: InputMaybe<CoachAvailabilitySetInput>;
  pk_columns: CoachAvailabilityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachAvailabilityManyArgs = {
  updates: Array<CoachAvailabilityUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCertificationArgs = {
  _inc?: InputMaybe<CoachCertificationIncInput>;
  _set?: InputMaybe<CoachCertificationSetInput>;
  where: CoachCertificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCertificationByPkArgs = {
  _inc?: InputMaybe<CoachCertificationIncInput>;
  _set?: InputMaybe<CoachCertificationSetInput>;
  pk_columns: CoachCertificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCertificationManyArgs = {
  updates: Array<CoachCertificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerArgs = {
  _inc?: InputMaybe<CoachCustomerIncInput>;
  _set?: InputMaybe<CoachCustomerSetInput>;
  where: CoachCustomerBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonArgs = {
  _append?: InputMaybe<CoachCustomerLessonAppendInput>;
  _delete_at_path?: InputMaybe<CoachCustomerLessonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachCustomerLessonDeleteElemInput>;
  _delete_key?: InputMaybe<CoachCustomerLessonDeleteKeyInput>;
  _inc?: InputMaybe<CoachCustomerLessonIncInput>;
  _prepend?: InputMaybe<CoachCustomerLessonPrependInput>;
  _set?: InputMaybe<CoachCustomerLessonSetInput>;
  where: CoachCustomerLessonBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateSetInput>;
  where: CoachCustomerLessonDateBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateLocationArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateLocationIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateLocationSetInput>;
  where: CoachCustomerLessonDateLocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateLocationByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateLocationIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateLocationSetInput>;
  pk_columns: CoachCustomerLessonDateLocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateLocationManyArgs = {
  updates: Array<CoachCustomerLessonDateLocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateVirtualLocationArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateVirtualLocationIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateVirtualLocationSetInput>;
  where: CoachCustomerLessonDateVirtualLocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateVirtualLocationByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateVirtualLocationIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateVirtualLocationSetInput>;
  pk_columns: CoachCustomerLessonDateVirtualLocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateVirtualLocationManyArgs = {
  updates: Array<CoachCustomerLessonDateVirtualLocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonDateIncInput>;
  _set?: InputMaybe<CoachCustomerLessonDateSetInput>;
  pk_columns: CoachCustomerLessonDatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonDateManyArgs = {
  updates: Array<CoachCustomerLessonDateUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonOneTimeChargeArgs = {
  _inc?: InputMaybe<CoachCustomerLessonOneTimeChargeIncInput>;
  _set?: InputMaybe<CoachCustomerLessonOneTimeChargeSetInput>;
  where: CoachCustomerLessonOneTimeChargeBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonOneTimeChargeByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonOneTimeChargeIncInput>;
  _set?: InputMaybe<CoachCustomerLessonOneTimeChargeSetInput>;
  pk_columns: CoachCustomerLessonOneTimeChargePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonOneTimeChargeManyArgs = {
  updates: Array<CoachCustomerLessonOneTimeChargeUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonPauseResumeArgs = {
  _inc?: InputMaybe<CoachCustomerLessonPauseResumeIncInput>;
  _set?: InputMaybe<CoachCustomerLessonPauseResumeSetInput>;
  where: CoachCustomerLessonPauseResumeBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonPauseResumeByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonPauseResumeIncInput>;
  _set?: InputMaybe<CoachCustomerLessonPauseResumeSetInput>;
  pk_columns: CoachCustomerLessonPauseResumePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonPauseResumeManyArgs = {
  updates: Array<CoachCustomerLessonPauseResumeUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonTransactionArgs = {
  _inc?: InputMaybe<CoachCustomerLessonTransactionIncInput>;
  _set?: InputMaybe<CoachCustomerLessonTransactionSetInput>;
  where: CoachCustomerLessonTransactionBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonTransactionByPkArgs = {
  _inc?: InputMaybe<CoachCustomerLessonTransactionIncInput>;
  _set?: InputMaybe<CoachCustomerLessonTransactionSetInput>;
  pk_columns: CoachCustomerLessonTransactionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonTransactionManyArgs = {
  updates: Array<CoachCustomerLessonTransactionUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonByPkArgs = {
  _append?: InputMaybe<CoachCustomerLessonAppendInput>;
  _delete_at_path?: InputMaybe<CoachCustomerLessonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachCustomerLessonDeleteElemInput>;
  _delete_key?: InputMaybe<CoachCustomerLessonDeleteKeyInput>;
  _inc?: InputMaybe<CoachCustomerLessonIncInput>;
  _prepend?: InputMaybe<CoachCustomerLessonPrependInput>;
  _set?: InputMaybe<CoachCustomerLessonSetInput>;
  pk_columns: CoachCustomerLessonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerLessonManyArgs = {
  updates: Array<CoachCustomerLessonUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerMessageRequestArgs = {
  _inc?: InputMaybe<CoachCustomerMessageRequestIncInput>;
  _set?: InputMaybe<CoachCustomerMessageRequestSetInput>;
  where: CoachCustomerMessageRequestBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerMessageRequestByPkArgs = {
  _inc?: InputMaybe<CoachCustomerMessageRequestIncInput>;
  _set?: InputMaybe<CoachCustomerMessageRequestSetInput>;
  pk_columns: CoachCustomerMessageRequestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerMessageRequestManyArgs = {
  updates: Array<CoachCustomerMessageRequestUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerUserArgs = {
  _inc?: InputMaybe<CoachCustomerUserIncInput>;
  _set?: InputMaybe<CoachCustomerUserSetInput>;
  where: CoachCustomerUserBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerUserByPkArgs = {
  _inc?: InputMaybe<CoachCustomerUserIncInput>;
  _set?: InputMaybe<CoachCustomerUserSetInput>;
  pk_columns: CoachCustomerUserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerUserManyArgs = {
  updates: Array<CoachCustomerUserUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerByPkArgs = {
  _inc?: InputMaybe<CoachCustomerIncInput>;
  _set?: InputMaybe<CoachCustomerSetInput>;
  pk_columns: CoachCustomerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachCustomerManyArgs = {
  updates: Array<CoachCustomerUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachDisciplineArgs = {
  _append?: InputMaybe<CoachDisciplineAppendInput>;
  _delete_at_path?: InputMaybe<CoachDisciplineDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachDisciplineDeleteElemInput>;
  _delete_key?: InputMaybe<CoachDisciplineDeleteKeyInput>;
  _inc?: InputMaybe<CoachDisciplineIncInput>;
  _prepend?: InputMaybe<CoachDisciplinePrependInput>;
  _set?: InputMaybe<CoachDisciplineSetInput>;
  where: CoachDisciplineBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachDisciplineByPkArgs = {
  _append?: InputMaybe<CoachDisciplineAppendInput>;
  _delete_at_path?: InputMaybe<CoachDisciplineDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachDisciplineDeleteElemInput>;
  _delete_key?: InputMaybe<CoachDisciplineDeleteKeyInput>;
  _inc?: InputMaybe<CoachDisciplineIncInput>;
  _prepend?: InputMaybe<CoachDisciplinePrependInput>;
  _set?: InputMaybe<CoachDisciplineSetInput>;
  pk_columns: CoachDisciplinePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachDisciplineManyArgs = {
  updates: Array<CoachDisciplineUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachImageArgs = {
  _inc?: InputMaybe<CoachImageIncInput>;
  _set?: InputMaybe<CoachImageSetInput>;
  where: CoachImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachImageByPkArgs = {
  _inc?: InputMaybe<CoachImageIncInput>;
  _set?: InputMaybe<CoachImageSetInput>;
  pk_columns: CoachImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachImageManyArgs = {
  updates: Array<CoachImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachLessonArgs = {
  _append?: InputMaybe<CoachLessonAppendInput>;
  _delete_at_path?: InputMaybe<CoachLessonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachLessonDeleteElemInput>;
  _delete_key?: InputMaybe<CoachLessonDeleteKeyInput>;
  _inc?: InputMaybe<CoachLessonIncInput>;
  _prepend?: InputMaybe<CoachLessonPrependInput>;
  _set?: InputMaybe<CoachLessonSetInput>;
  where: CoachLessonBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachLessonLocationArgs = {
  _inc?: InputMaybe<CoachLessonLocationIncInput>;
  _set?: InputMaybe<CoachLessonLocationSetInput>;
  where: CoachLessonLocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachLessonLocationByPkArgs = {
  _inc?: InputMaybe<CoachLessonLocationIncInput>;
  _set?: InputMaybe<CoachLessonLocationSetInput>;
  pk_columns: CoachLessonLocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachLessonLocationManyArgs = {
  updates: Array<CoachLessonLocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachLessonByPkArgs = {
  _append?: InputMaybe<CoachLessonAppendInput>;
  _delete_at_path?: InputMaybe<CoachLessonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachLessonDeleteElemInput>;
  _delete_key?: InputMaybe<CoachLessonDeleteKeyInput>;
  _inc?: InputMaybe<CoachLessonIncInput>;
  _prepend?: InputMaybe<CoachLessonPrependInput>;
  _set?: InputMaybe<CoachLessonSetInput>;
  pk_columns: CoachLessonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachLessonManyArgs = {
  updates: Array<CoachLessonUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachLocationArgs = {
  _inc?: InputMaybe<CoachLocationIncInput>;
  _set?: InputMaybe<CoachLocationSetInput>;
  where: CoachLocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachLocationByPkArgs = {
  _inc?: InputMaybe<CoachLocationIncInput>;
  _set?: InputMaybe<CoachLocationSetInput>;
  pk_columns: CoachLocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachLocationManyArgs = {
  updates: Array<CoachLocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachModerationFlagArgs = {
  _inc?: InputMaybe<CoachModerationFlagIncInput>;
  _set?: InputMaybe<CoachModerationFlagSetInput>;
  where: CoachModerationFlagBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachModerationFlagByPkArgs = {
  _inc?: InputMaybe<CoachModerationFlagIncInput>;
  _set?: InputMaybe<CoachModerationFlagSetInput>;
  pk_columns: CoachModerationFlagPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachModerationFlagManyArgs = {
  updates: Array<CoachModerationFlagUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachStudentArgs = {
  _append?: InputMaybe<CoachStudentAppendInput>;
  _delete_at_path?: InputMaybe<CoachStudentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachStudentDeleteElemInput>;
  _delete_key?: InputMaybe<CoachStudentDeleteKeyInput>;
  _inc?: InputMaybe<CoachStudentIncInput>;
  _prepend?: InputMaybe<CoachStudentPrependInput>;
  _set?: InputMaybe<CoachStudentSetInput>;
  where: CoachStudentBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachStudentByPkArgs = {
  _append?: InputMaybe<CoachStudentAppendInput>;
  _delete_at_path?: InputMaybe<CoachStudentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachStudentDeleteElemInput>;
  _delete_key?: InputMaybe<CoachStudentDeleteKeyInput>;
  _inc?: InputMaybe<CoachStudentIncInput>;
  _prepend?: InputMaybe<CoachStudentPrependInput>;
  _set?: InputMaybe<CoachStudentSetInput>;
  pk_columns: CoachStudentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachStudentManyArgs = {
  updates: Array<CoachStudentUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachUnavailableDateArgs = {
  _inc?: InputMaybe<CoachUnavailableDateIncInput>;
  _set?: InputMaybe<CoachUnavailableDateSetInput>;
  where: CoachUnavailableDateBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoachUnavailableDateByPkArgs = {
  _inc?: InputMaybe<CoachUnavailableDateIncInput>;
  _set?: InputMaybe<CoachUnavailableDateSetInput>;
  pk_columns: CoachUnavailableDatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachUnavailableDateManyArgs = {
  updates: Array<CoachUnavailableDateUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoachByPkArgs = {
  _append?: InputMaybe<CoachAppendInput>;
  _delete_at_path?: InputMaybe<CoachDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CoachDeleteElemInput>;
  _delete_key?: InputMaybe<CoachDeleteKeyInput>;
  _inc?: InputMaybe<CoachIncInput>;
  _prepend?: InputMaybe<CoachPrependInput>;
  _set?: InputMaybe<CoachSetInput>;
  pk_columns: CoachPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoachManyArgs = {
  updates: Array<CoachUpdates>;
};


/** mutation root */
export type MutationRootUpdateCourseArgs = {
  _append?: InputMaybe<CourseAppendInput>;
  _delete_at_path?: InputMaybe<CourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CourseDeleteElemInput>;
  _delete_key?: InputMaybe<CourseDeleteKeyInput>;
  _inc?: InputMaybe<CourseIncInput>;
  _prepend?: InputMaybe<CoursePrependInput>;
  _set?: InputMaybe<CourseSetInput>;
  where: CourseBoolExp;
};


/** mutation root */
export type MutationRootUpdateCourseByPkArgs = {
  _append?: InputMaybe<CourseAppendInput>;
  _delete_at_path?: InputMaybe<CourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CourseDeleteElemInput>;
  _delete_key?: InputMaybe<CourseDeleteKeyInput>;
  _inc?: InputMaybe<CourseIncInput>;
  _prepend?: InputMaybe<CoursePrependInput>;
  _set?: InputMaybe<CourseSetInput>;
  pk_columns: CoursePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCourseManyArgs = {
  updates: Array<CourseUpdates>;
};


/** mutation root */
export type MutationRootUpdateCreatorInviteArgs = {
  _inc?: InputMaybe<CreatorInviteIncInput>;
  _set?: InputMaybe<CreatorInviteSetInput>;
  where: CreatorInviteBoolExp;
};


/** mutation root */
export type MutationRootUpdateCreatorInviteByPkArgs = {
  _inc?: InputMaybe<CreatorInviteIncInput>;
  _set?: InputMaybe<CreatorInviteSetInput>;
  pk_columns: CreatorInvitePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCreatorInviteManyArgs = {
  updates: Array<CreatorInviteUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventArgs = {
  _append?: InputMaybe<EventAppendInput>;
  _delete_at_path?: InputMaybe<EventDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventDeleteElemInput>;
  _delete_key?: InputMaybe<EventDeleteKeyInput>;
  _inc?: InputMaybe<EventIncInput>;
  _prepend?: InputMaybe<EventPrependInput>;
  _set?: InputMaybe<EventSetInput>;
  where: EventBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventAdditionalInfoArgs = {
  _inc?: InputMaybe<EventAdditionalInfoIncInput>;
  _set?: InputMaybe<EventAdditionalInfoSetInput>;
  where: EventAdditionalInfoBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventAdditionalInfoByPkArgs = {
  _inc?: InputMaybe<EventAdditionalInfoIncInput>;
  _set?: InputMaybe<EventAdditionalInfoSetInput>;
  pk_columns: EventAdditionalInfoPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventAdditionalInfoManyArgs = {
  updates: Array<EventAdditionalInfoUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventAmenityArgs = {
  _inc?: InputMaybe<EventAmenityIncInput>;
  _set?: InputMaybe<EventAmenitySetInput>;
  where: EventAmenityBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventAmenityByPkArgs = {
  _inc?: InputMaybe<EventAmenityIncInput>;
  _set?: InputMaybe<EventAmenitySetInput>;
  pk_columns: EventAmenityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventAmenityManyArgs = {
  updates: Array<EventAmenityUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverArgs = {
  _inc?: InputMaybe<EventBasicWaiverIncInput>;
  _set?: InputMaybe<EventBasicWaiverSetInput>;
  where: EventBasicWaiverBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverCategoryArgs = {
  _inc?: InputMaybe<EventBasicWaiverCategoryIncInput>;
  _set?: InputMaybe<EventBasicWaiverCategorySetInput>;
  where: EventBasicWaiverCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverCategoryByPkArgs = {
  _inc?: InputMaybe<EventBasicWaiverCategoryIncInput>;
  _set?: InputMaybe<EventBasicWaiverCategorySetInput>;
  pk_columns: EventBasicWaiverCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverCategoryManyArgs = {
  updates: Array<EventBasicWaiverCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverByPkArgs = {
  _inc?: InputMaybe<EventBasicWaiverIncInput>;
  _set?: InputMaybe<EventBasicWaiverSetInput>;
  pk_columns: EventBasicWaiverPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventBasicWaiverManyArgs = {
  updates: Array<EventBasicWaiverUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventChecklistItemArgs = {
  _inc?: InputMaybe<EventChecklistItemIncInput>;
  _set?: InputMaybe<EventChecklistItemSetInput>;
  where: EventChecklistItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventChecklistItemByPkArgs = {
  _inc?: InputMaybe<EventChecklistItemIncInput>;
  _set?: InputMaybe<EventChecklistItemSetInput>;
  pk_columns: EventChecklistItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventChecklistItemManyArgs = {
  updates: Array<EventChecklistItemUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventClinicDayDurationArgs = {
  _inc?: InputMaybe<EventClinicDayDurationIncInput>;
  _set?: InputMaybe<EventClinicDayDurationSetInput>;
  where: EventClinicDayDurationBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventClinicDayDurationByPkArgs = {
  _inc?: InputMaybe<EventClinicDayDurationIncInput>;
  _set?: InputMaybe<EventClinicDayDurationSetInput>;
  pk_columns: EventClinicDayDurationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventClinicDayDurationManyArgs = {
  updates: Array<EventClinicDayDurationUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionArgs = {
  _inc?: InputMaybe<EventCustomQuestionIncInput>;
  _set?: InputMaybe<EventCustomQuestionSetInput>;
  where: EventCustomQuestionBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionCategoryArgs = {
  _inc?: InputMaybe<EventCustomQuestionCategoryIncInput>;
  _set?: InputMaybe<EventCustomQuestionCategorySetInput>;
  where: EventCustomQuestionCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionCategoryByPkArgs = {
  _inc?: InputMaybe<EventCustomQuestionCategoryIncInput>;
  _set?: InputMaybe<EventCustomQuestionCategorySetInput>;
  pk_columns: EventCustomQuestionCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionCategoryManyArgs = {
  updates: Array<EventCustomQuestionCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionOptionArgs = {
  _inc?: InputMaybe<EventCustomQuestionOptionIncInput>;
  _set?: InputMaybe<EventCustomQuestionOptionSetInput>;
  where: EventCustomQuestionOptionBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionOptionByPkArgs = {
  _inc?: InputMaybe<EventCustomQuestionOptionIncInput>;
  _set?: InputMaybe<EventCustomQuestionOptionSetInput>;
  pk_columns: EventCustomQuestionOptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionOptionManyArgs = {
  updates: Array<EventCustomQuestionOptionUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionByPkArgs = {
  _inc?: InputMaybe<EventCustomQuestionIncInput>;
  _set?: InputMaybe<EventCustomQuestionSetInput>;
  pk_columns: EventCustomQuestionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventCustomQuestionManyArgs = {
  updates: Array<EventCustomQuestionUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventDisciplineArgs = {
  _inc?: InputMaybe<EventDisciplineIncInput>;
  _set?: InputMaybe<EventDisciplineSetInput>;
  where: EventDisciplineBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventDisciplineByPkArgs = {
  _inc?: InputMaybe<EventDisciplineIncInput>;
  _set?: InputMaybe<EventDisciplineSetInput>;
  pk_columns: EventDisciplinePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventDisciplineManyArgs = {
  updates: Array<EventDisciplineUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventImageArgs = {
  _inc?: InputMaybe<EventImageIncInput>;
  _set?: InputMaybe<EventImageSetInput>;
  where: EventImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventImageByPkArgs = {
  _inc?: InputMaybe<EventImageIncInput>;
  _set?: InputMaybe<EventImageSetInput>;
  pk_columns: EventImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventImageManyArgs = {
  updates: Array<EventImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventMerchandiseArgs = {
  _append?: InputMaybe<EventMerchandiseAppendInput>;
  _delete_at_path?: InputMaybe<EventMerchandiseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventMerchandiseDeleteElemInput>;
  _delete_key?: InputMaybe<EventMerchandiseDeleteKeyInput>;
  _prepend?: InputMaybe<EventMerchandisePrependInput>;
  _set?: InputMaybe<EventMerchandiseSetInput>;
  where: EventMerchandiseBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventMerchandiseByPkArgs = {
  _append?: InputMaybe<EventMerchandiseAppendInput>;
  _delete_at_path?: InputMaybe<EventMerchandiseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventMerchandiseDeleteElemInput>;
  _delete_key?: InputMaybe<EventMerchandiseDeleteKeyInput>;
  _prepend?: InputMaybe<EventMerchandisePrependInput>;
  _set?: InputMaybe<EventMerchandiseSetInput>;
  pk_columns: EventMerchandisePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventMerchandiseManyArgs = {
  updates: Array<EventMerchandiseUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventMetadataArgs = {
  _inc?: InputMaybe<EventMetadataIncInput>;
  _set?: InputMaybe<EventMetadataSetInput>;
  where: EventMetadataBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventMetadataByPkArgs = {
  _inc?: InputMaybe<EventMetadataIncInput>;
  _set?: InputMaybe<EventMetadataSetInput>;
  pk_columns: EventMetadataPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventMetadataManyArgs = {
  updates: Array<EventMetadataUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventModerationFlagArgs = {
  _inc?: InputMaybe<EventModerationFlagIncInput>;
  _set?: InputMaybe<EventModerationFlagSetInput>;
  where: EventModerationFlagBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventModerationFlagByPkArgs = {
  _inc?: InputMaybe<EventModerationFlagIncInput>;
  _set?: InputMaybe<EventModerationFlagSetInput>;
  pk_columns: EventModerationFlagPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventModerationFlagManyArgs = {
  updates: Array<EventModerationFlagUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceArgs = {
  _inc?: InputMaybe<EventPitSpaceIncInput>;
  _set?: InputMaybe<EventPitSpaceSetInput>;
  where: EventPitSpaceBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceAddOnArgs = {
  _inc?: InputMaybe<EventPitSpaceAddOnIncInput>;
  _set?: InputMaybe<EventPitSpaceAddOnSetInput>;
  where: EventPitSpaceAddOnBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceAddOnByPkArgs = {
  _inc?: InputMaybe<EventPitSpaceAddOnIncInput>;
  _set?: InputMaybe<EventPitSpaceAddOnSetInput>;
  pk_columns: EventPitSpaceAddOnPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceAddOnManyArgs = {
  updates: Array<EventPitSpaceAddOnUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceByPkArgs = {
  _inc?: InputMaybe<EventPitSpaceIncInput>;
  _set?: InputMaybe<EventPitSpaceSetInput>;
  pk_columns: EventPitSpacePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPitSpaceManyArgs = {
  updates: Array<EventPitSpaceUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPricingChangeArgs = {
  _inc?: InputMaybe<EventPricingChangeIncInput>;
  _set?: InputMaybe<EventPricingChangeSetInput>;
  where: EventPricingChangeBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPricingChangeByPkArgs = {
  _inc?: InputMaybe<EventPricingChangeIncInput>;
  _set?: InputMaybe<EventPricingChangeSetInput>;
  pk_columns: EventPricingChangePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPricingChangeManyArgs = {
  updates: Array<EventPricingChangeUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPricingDateArgs = {
  _inc?: InputMaybe<EventPricingDateIncInput>;
  _set?: InputMaybe<EventPricingDateSetInput>;
  where: EventPricingDateBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPricingDateByPkArgs = {
  _inc?: InputMaybe<EventPricingDateIncInput>;
  _set?: InputMaybe<EventPricingDateSetInput>;
  pk_columns: EventPricingDatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPricingDateManyArgs = {
  updates: Array<EventPricingDateUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupArgs = {
  _inc?: InputMaybe<EventPricingGroupIncInput>;
  _set?: InputMaybe<EventPricingGroupSetInput>;
  where: EventPricingGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupCategoryArgs = {
  _inc?: InputMaybe<EventPricingGroupCategoryIncInput>;
  _set?: InputMaybe<EventPricingGroupCategorySetInput>;
  where: EventPricingGroupCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupCategoryByPkArgs = {
  _inc?: InputMaybe<EventPricingGroupCategoryIncInput>;
  _set?: InputMaybe<EventPricingGroupCategorySetInput>;
  pk_columns: EventPricingGroupCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupCategoryManyArgs = {
  updates: Array<EventPricingGroupCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupByPkArgs = {
  _inc?: InputMaybe<EventPricingGroupIncInput>;
  _set?: InputMaybe<EventPricingGroupSetInput>;
  pk_columns: EventPricingGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPricingGroupManyArgs = {
  updates: Array<EventPricingGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPrivateLinkArgs = {
  _inc?: InputMaybe<EventPrivateLinkIncInput>;
  _set?: InputMaybe<EventPrivateLinkSetInput>;
  where: EventPrivateLinkBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPrivateLinkByPkArgs = {
  _inc?: InputMaybe<EventPrivateLinkIncInput>;
  _set?: InputMaybe<EventPrivateLinkSetInput>;
  pk_columns: EventPrivateLinkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPrivateLinkManyArgs = {
  updates: Array<EventPrivateLinkUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventProgramArgs = {
  _append?: InputMaybe<EventProgramAppendInput>;
  _delete_at_path?: InputMaybe<EventProgramDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventProgramDeleteElemInput>;
  _delete_key?: InputMaybe<EventProgramDeleteKeyInput>;
  _inc?: InputMaybe<EventProgramIncInput>;
  _prepend?: InputMaybe<EventProgramPrependInput>;
  _set?: InputMaybe<EventProgramSetInput>;
  where: EventProgramBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventProgramByPkArgs = {
  _append?: InputMaybe<EventProgramAppendInput>;
  _delete_at_path?: InputMaybe<EventProgramDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventProgramDeleteElemInput>;
  _delete_key?: InputMaybe<EventProgramDeleteKeyInput>;
  _inc?: InputMaybe<EventProgramIncInput>;
  _prepend?: InputMaybe<EventProgramPrependInput>;
  _set?: InputMaybe<EventProgramSetInput>;
  pk_columns: EventProgramPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventProgramManyArgs = {
  updates: Array<EventProgramUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventPromoCodeArgs = {
  _inc?: InputMaybe<EventPromoCodeIncInput>;
  _set?: InputMaybe<EventPromoCodeSetInput>;
  where: EventPromoCodeBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventPromoCodeByPkArgs = {
  _inc?: InputMaybe<EventPromoCodeIncInput>;
  _set?: InputMaybe<EventPromoCodeSetInput>;
  pk_columns: EventPromoCodePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventPromoCodeManyArgs = {
  updates: Array<EventPromoCodeUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventQuestionArgs = {
  _inc?: InputMaybe<EventQuestionIncInput>;
  _set?: InputMaybe<EventQuestionSetInput>;
  where: EventQuestionBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventQuestionAnswerArgs = {
  _inc?: InputMaybe<EventQuestionAnswerIncInput>;
  _set?: InputMaybe<EventQuestionAnswerSetInput>;
  where: EventQuestionAnswerBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventQuestionAnswerByPkArgs = {
  _inc?: InputMaybe<EventQuestionAnswerIncInput>;
  _set?: InputMaybe<EventQuestionAnswerSetInput>;
  pk_columns: EventQuestionAnswerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventQuestionAnswerManyArgs = {
  updates: Array<EventQuestionAnswerUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventQuestionByPkArgs = {
  _inc?: InputMaybe<EventQuestionIncInput>;
  _set?: InputMaybe<EventQuestionSetInput>;
  pk_columns: EventQuestionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventQuestionManyArgs = {
  updates: Array<EventQuestionUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventSanctionArgs = {
  _append?: InputMaybe<EventSanctionAppendInput>;
  _delete_at_path?: InputMaybe<EventSanctionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventSanctionDeleteElemInput>;
  _delete_key?: InputMaybe<EventSanctionDeleteKeyInput>;
  _inc?: InputMaybe<EventSanctionIncInput>;
  _prepend?: InputMaybe<EventSanctionPrependInput>;
  _set?: InputMaybe<EventSanctionSetInput>;
  where: EventSanctionBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventSanctionCategoryArgs = {
  _inc?: InputMaybe<EventSanctionCategoryIncInput>;
  _set?: InputMaybe<EventSanctionCategorySetInput>;
  where: EventSanctionCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventSanctionCategoryByPkArgs = {
  _inc?: InputMaybe<EventSanctionCategoryIncInput>;
  _set?: InputMaybe<EventSanctionCategorySetInput>;
  pk_columns: EventSanctionCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventSanctionCategoryManyArgs = {
  updates: Array<EventSanctionCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventSanctionByPkArgs = {
  _append?: InputMaybe<EventSanctionAppendInput>;
  _delete_at_path?: InputMaybe<EventSanctionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventSanctionDeleteElemInput>;
  _delete_key?: InputMaybe<EventSanctionDeleteKeyInput>;
  _inc?: InputMaybe<EventSanctionIncInput>;
  _prepend?: InputMaybe<EventSanctionPrependInput>;
  _set?: InputMaybe<EventSanctionSetInput>;
  pk_columns: EventSanctionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventSanctionManyArgs = {
  updates: Array<EventSanctionUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventScheduleArgs = {
  _inc?: InputMaybe<EventScheduleIncInput>;
  _set?: InputMaybe<EventScheduleSetInput>;
  where: EventScheduleBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventScheduleCategoryArgs = {
  _inc?: InputMaybe<EventScheduleCategoryIncInput>;
  _set?: InputMaybe<EventScheduleCategorySetInput>;
  where: EventScheduleCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventScheduleCategoryByPkArgs = {
  _inc?: InputMaybe<EventScheduleCategoryIncInput>;
  _set?: InputMaybe<EventScheduleCategorySetInput>;
  pk_columns: EventScheduleCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventScheduleCategoryManyArgs = {
  updates: Array<EventScheduleCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventScheduleDateArgs = {
  _inc?: InputMaybe<EventScheduleDateIncInput>;
  _set?: InputMaybe<EventScheduleDateSetInput>;
  where: EventScheduleDateBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventScheduleDateByPkArgs = {
  _inc?: InputMaybe<EventScheduleDateIncInput>;
  _set?: InputMaybe<EventScheduleDateSetInput>;
  pk_columns: EventScheduleDatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventScheduleDateManyArgs = {
  updates: Array<EventScheduleDateUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypeCustomArgs = {
  _inc?: InputMaybe<EventScheduleTypeCustomIncInput>;
  _set?: InputMaybe<EventScheduleTypeCustomSetInput>;
  where: EventScheduleTypeCustomBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypeCustomByPkArgs = {
  _inc?: InputMaybe<EventScheduleTypeCustomIncInput>;
  _set?: InputMaybe<EventScheduleTypeCustomSetInput>;
  pk_columns: EventScheduleTypeCustomPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypeCustomManyArgs = {
  updates: Array<EventScheduleTypeCustomUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypePresetArgs = {
  _inc?: InputMaybe<EventScheduleTypePresetIncInput>;
  _set?: InputMaybe<EventScheduleTypePresetSetInput>;
  where: EventScheduleTypePresetBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypePresetByPkArgs = {
  _inc?: InputMaybe<EventScheduleTypePresetIncInput>;
  _set?: InputMaybe<EventScheduleTypePresetSetInput>;
  pk_columns: EventScheduleTypePresetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventScheduleTypePresetManyArgs = {
  updates: Array<EventScheduleTypePresetUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventScheduleByPkArgs = {
  _inc?: InputMaybe<EventScheduleIncInput>;
  _set?: InputMaybe<EventScheduleSetInput>;
  pk_columns: EventSchedulePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventScheduleManyArgs = {
  updates: Array<EventScheduleUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventSeriesArgs = {
  _inc?: InputMaybe<EventSeriesIncInput>;
  _set?: InputMaybe<EventSeriesSetInput>;
  where: EventSeriesBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventSeriesCategoryArgs = {
  _inc?: InputMaybe<EventSeriesCategoryIncInput>;
  _set?: InputMaybe<EventSeriesCategorySetInput>;
  where: EventSeriesCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventSeriesCategoryByPkArgs = {
  _inc?: InputMaybe<EventSeriesCategoryIncInput>;
  _set?: InputMaybe<EventSeriesCategorySetInput>;
  pk_columns: EventSeriesCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventSeriesCategoryManyArgs = {
  updates: Array<EventSeriesCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventSeriesByPkArgs = {
  _inc?: InputMaybe<EventSeriesIncInput>;
  _set?: InputMaybe<EventSeriesSetInput>;
  pk_columns: EventSeriesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventSeriesManyArgs = {
  updates: Array<EventSeriesUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventSponsorArgs = {
  _inc?: InputMaybe<EventSponsorIncInput>;
  _set?: InputMaybe<EventSponsorSetInput>;
  where: EventSponsorBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventSponsorByPkArgs = {
  _inc?: InputMaybe<EventSponsorIncInput>;
  _set?: InputMaybe<EventSponsorSetInput>;
  pk_columns: EventSponsorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventSponsorManyArgs = {
  updates: Array<EventSponsorUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventTicketArgs = {
  _inc?: InputMaybe<EventTicketIncInput>;
  _set?: InputMaybe<EventTicketSetInput>;
  where: EventTicketBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventTicketByPkArgs = {
  _inc?: InputMaybe<EventTicketIncInput>;
  _set?: InputMaybe<EventTicketSetInput>;
  pk_columns: EventTicketPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventTicketManyArgs = {
  updates: Array<EventTicketUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventUpdateArgs = {
  _inc?: InputMaybe<EventUpdateIncInput>;
  _set?: InputMaybe<EventUpdateSetInput>;
  where: EventUpdateBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventUpdateCommentArgs = {
  _inc?: InputMaybe<EventUpdateCommentIncInput>;
  _set?: InputMaybe<EventUpdateCommentSetInput>;
  where: EventUpdateCommentBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventUpdateCommentByPkArgs = {
  _inc?: InputMaybe<EventUpdateCommentIncInput>;
  _set?: InputMaybe<EventUpdateCommentSetInput>;
  pk_columns: EventUpdateCommentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventUpdateCommentManyArgs = {
  updates: Array<EventUpdateCommentUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventUpdateByPkArgs = {
  _inc?: InputMaybe<EventUpdateIncInput>;
  _set?: InputMaybe<EventUpdateSetInput>;
  pk_columns: EventUpdatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventUpdateManyArgs = {
  updates: Array<EventUpdateUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVenueArgs = {
  _inc?: InputMaybe<EventVenueIncInput>;
  _set?: InputMaybe<EventVenueSetInput>;
  where: EventVenueBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVenueByPkArgs = {
  _inc?: InputMaybe<EventVenueIncInput>;
  _set?: InputMaybe<EventVenueSetInput>;
  pk_columns: EventVenuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVenueManyArgs = {
  updates: Array<EventVenueUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVibeArgs = {
  _append?: InputMaybe<EventVibeAppendInput>;
  _delete_at_path?: InputMaybe<EventVibeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventVibeDeleteElemInput>;
  _delete_key?: InputMaybe<EventVibeDeleteKeyInput>;
  _inc?: InputMaybe<EventVibeIncInput>;
  _prepend?: InputMaybe<EventVibePrependInput>;
  _set?: InputMaybe<EventVibeSetInput>;
  where: EventVibeBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVibeByPkArgs = {
  _append?: InputMaybe<EventVibeAppendInput>;
  _delete_at_path?: InputMaybe<EventVibeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventVibeDeleteElemInput>;
  _delete_key?: InputMaybe<EventVibeDeleteKeyInput>;
  _inc?: InputMaybe<EventVibeIncInput>;
  _prepend?: InputMaybe<EventVibePrependInput>;
  _set?: InputMaybe<EventVibeSetInput>;
  pk_columns: EventVibePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVibeManyArgs = {
  updates: Array<EventVibeUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVirtualVenueArgs = {
  _inc?: InputMaybe<EventVirtualVenueIncInput>;
  _set?: InputMaybe<EventVirtualVenueSetInput>;
  where: EventVirtualVenueBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVirtualVenueByPkArgs = {
  _inc?: InputMaybe<EventVirtualVenueIncInput>;
  _set?: InputMaybe<EventVirtualVenueSetInput>;
  pk_columns: EventVirtualVenuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVirtualVenueManyArgs = {
  updates: Array<EventVirtualVenueUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobArgs = {
  _inc?: InputMaybe<EventVolunteerJobIncInput>;
  _set?: InputMaybe<EventVolunteerJobSetInput>;
  where: EventVolunteerJobBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftArgs = {
  _inc?: InputMaybe<EventVolunteerJobShiftIncInput>;
  _set?: InputMaybe<EventVolunteerJobShiftSetInput>;
  where: EventVolunteerJobShiftBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftTaskArgs = {
  _inc?: InputMaybe<EventVolunteerJobShiftTaskIncInput>;
  _set?: InputMaybe<EventVolunteerJobShiftTaskSetInput>;
  where: EventVolunteerJobShiftTaskBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftTaskByPkArgs = {
  _inc?: InputMaybe<EventVolunteerJobShiftTaskIncInput>;
  _set?: InputMaybe<EventVolunteerJobShiftTaskSetInput>;
  pk_columns: EventVolunteerJobShiftTaskPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftTaskManyArgs = {
  updates: Array<EventVolunteerJobShiftTaskUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftByPkArgs = {
  _inc?: InputMaybe<EventVolunteerJobShiftIncInput>;
  _set?: InputMaybe<EventVolunteerJobShiftSetInput>;
  pk_columns: EventVolunteerJobShiftPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobShiftManyArgs = {
  updates: Array<EventVolunteerJobShiftUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobTaskArgs = {
  _inc?: InputMaybe<EventVolunteerJobTaskIncInput>;
  _set?: InputMaybe<EventVolunteerJobTaskSetInput>;
  where: EventVolunteerJobTaskBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobTaskByPkArgs = {
  _inc?: InputMaybe<EventVolunteerJobTaskIncInput>;
  _set?: InputMaybe<EventVolunteerJobTaskSetInput>;
  pk_columns: EventVolunteerJobTaskPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobTaskManyArgs = {
  updates: Array<EventVolunteerJobTaskUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobByPkArgs = {
  _inc?: InputMaybe<EventVolunteerJobIncInput>;
  _set?: InputMaybe<EventVolunteerJobSetInput>;
  pk_columns: EventVolunteerJobPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventVolunteerJobManyArgs = {
  updates: Array<EventVolunteerJobUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventWaiverArgs = {
  _set?: InputMaybe<EventWaiverSetInput>;
  where: EventWaiverBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventWaiverTypeArgs = {
  _set?: InputMaybe<EventWaiverTypeSetInput>;
  where: EventWaiverTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateEventWaiverTypeByPkArgs = {
  _set?: InputMaybe<EventWaiverTypeSetInput>;
  pk_columns: EventWaiverTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventWaiverTypeManyArgs = {
  updates: Array<EventWaiverTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventWaiverByPkArgs = {
  _set?: InputMaybe<EventWaiverSetInput>;
  pk_columns: EventWaiverPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventWaiverManyArgs = {
  updates: Array<EventWaiverUpdates>;
};


/** mutation root */
export type MutationRootUpdateEventByPkArgs = {
  _append?: InputMaybe<EventAppendInput>;
  _delete_at_path?: InputMaybe<EventDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventDeleteElemInput>;
  _delete_key?: InputMaybe<EventDeleteKeyInput>;
  _inc?: InputMaybe<EventIncInput>;
  _prepend?: InputMaybe<EventPrependInput>;
  _set?: InputMaybe<EventSetInput>;
  pk_columns: EventPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEventManyArgs = {
  updates: Array<EventUpdates>;
};


/** mutation root */
export type MutationRootUpdateImageArgs = {
  _append?: InputMaybe<ImageAppendInput>;
  _delete_at_path?: InputMaybe<ImageDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageDeleteElemInput>;
  _delete_key?: InputMaybe<ImageDeleteKeyInput>;
  _inc?: InputMaybe<ImageIncInput>;
  _prepend?: InputMaybe<ImagePrependInput>;
  _set?: InputMaybe<ImageSetInput>;
  where: ImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateImageOnlineCourseArgs = {
  _append?: InputMaybe<ImageOnlineCourseAppendInput>;
  _delete_at_path?: InputMaybe<ImageOnlineCourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageOnlineCourseDeleteElemInput>;
  _delete_key?: InputMaybe<ImageOnlineCourseDeleteKeyInput>;
  _inc?: InputMaybe<ImageOnlineCourseIncInput>;
  _prepend?: InputMaybe<ImageOnlineCoursePrependInput>;
  _set?: InputMaybe<ImageOnlineCourseSetInput>;
  where: ImageOnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootUpdateImageOnlineCourseByPkArgs = {
  _append?: InputMaybe<ImageOnlineCourseAppendInput>;
  _delete_at_path?: InputMaybe<ImageOnlineCourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageOnlineCourseDeleteElemInput>;
  _delete_key?: InputMaybe<ImageOnlineCourseDeleteKeyInput>;
  _inc?: InputMaybe<ImageOnlineCourseIncInput>;
  _prepend?: InputMaybe<ImageOnlineCoursePrependInput>;
  _set?: InputMaybe<ImageOnlineCourseSetInput>;
  pk_columns: ImageOnlineCoursePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImageOnlineCourseManyArgs = {
  updates: Array<ImageOnlineCourseUpdates>;
};


/** mutation root */
export type MutationRootUpdateImageUserArgs = {
  _append?: InputMaybe<ImageUserAppendInput>;
  _delete_at_path?: InputMaybe<ImageUserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageUserDeleteElemInput>;
  _delete_key?: InputMaybe<ImageUserDeleteKeyInput>;
  _inc?: InputMaybe<ImageUserIncInput>;
  _prepend?: InputMaybe<ImageUserPrependInput>;
  _set?: InputMaybe<ImageUserSetInput>;
  where: ImageUserBoolExp;
};


/** mutation root */
export type MutationRootUpdateImageUserByPkArgs = {
  _append?: InputMaybe<ImageUserAppendInput>;
  _delete_at_path?: InputMaybe<ImageUserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageUserDeleteElemInput>;
  _delete_key?: InputMaybe<ImageUserDeleteKeyInput>;
  _inc?: InputMaybe<ImageUserIncInput>;
  _prepend?: InputMaybe<ImageUserPrependInput>;
  _set?: InputMaybe<ImageUserSetInput>;
  pk_columns: ImageUserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImageUserManyArgs = {
  updates: Array<ImageUserUpdates>;
};


/** mutation root */
export type MutationRootUpdateImageByPkArgs = {
  _append?: InputMaybe<ImageAppendInput>;
  _delete_at_path?: InputMaybe<ImageDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImageDeleteElemInput>;
  _delete_key?: InputMaybe<ImageDeleteKeyInput>;
  _inc?: InputMaybe<ImageIncInput>;
  _prepend?: InputMaybe<ImagePrependInput>;
  _set?: InputMaybe<ImageSetInput>;
  pk_columns: ImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImageManyArgs = {
  updates: Array<ImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateLessonLocationArgs = {
  _inc?: InputMaybe<LessonLocationIncInput>;
  _set?: InputMaybe<LessonLocationSetInput>;
  where: LessonLocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateLessonLocationByPkArgs = {
  _inc?: InputMaybe<LessonLocationIncInput>;
  _set?: InputMaybe<LessonLocationSetInput>;
  pk_columns: LessonLocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLessonLocationManyArgs = {
  updates: Array<LessonLocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseArgs = {
  _inc?: InputMaybe<MerchandiseIncInput>;
  _set?: InputMaybe<MerchandiseSetInput>;
  where: MerchandiseBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseDeliveryTypeArgs = {
  _set?: InputMaybe<MerchandiseDeliveryTypeSetInput>;
  where: MerchandiseDeliveryTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseDeliveryTypeByPkArgs = {
  _set?: InputMaybe<MerchandiseDeliveryTypeSetInput>;
  pk_columns: MerchandiseDeliveryTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseDeliveryTypeManyArgs = {
  updates: Array<MerchandiseDeliveryTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseImageArgs = {
  _inc?: InputMaybe<MerchandiseImageIncInput>;
  _set?: InputMaybe<MerchandiseImageSetInput>;
  where: MerchandiseImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseImageByPkArgs = {
  _inc?: InputMaybe<MerchandiseImageIncInput>;
  _set?: InputMaybe<MerchandiseImageSetInput>;
  pk_columns: MerchandiseImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseImageManyArgs = {
  updates: Array<MerchandiseImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantArgs = {
  _append?: InputMaybe<MerchandiseVariantAppendInput>;
  _delete_at_path?: InputMaybe<MerchandiseVariantDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MerchandiseVariantDeleteElemInput>;
  _delete_key?: InputMaybe<MerchandiseVariantDeleteKeyInput>;
  _inc?: InputMaybe<MerchandiseVariantIncInput>;
  _prepend?: InputMaybe<MerchandiseVariantPrependInput>;
  _set?: InputMaybe<MerchandiseVariantSetInput>;
  where: MerchandiseVariantBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionArgs = {
  _inc?: InputMaybe<MerchandiseVariantOptionIncInput>;
  _set?: InputMaybe<MerchandiseVariantOptionSetInput>;
  where: MerchandiseVariantOptionBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionValueArgs = {
  _inc?: InputMaybe<MerchandiseVariantOptionValueIncInput>;
  _set?: InputMaybe<MerchandiseVariantOptionValueSetInput>;
  where: MerchandiseVariantOptionValueBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionValueByPkArgs = {
  _inc?: InputMaybe<MerchandiseVariantOptionValueIncInput>;
  _set?: InputMaybe<MerchandiseVariantOptionValueSetInput>;
  pk_columns: MerchandiseVariantOptionValuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionValueManyArgs = {
  updates: Array<MerchandiseVariantOptionValueUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionByPkArgs = {
  _inc?: InputMaybe<MerchandiseVariantOptionIncInput>;
  _set?: InputMaybe<MerchandiseVariantOptionSetInput>;
  pk_columns: MerchandiseVariantOptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantOptionManyArgs = {
  updates: Array<MerchandiseVariantOptionUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantByPkArgs = {
  _append?: InputMaybe<MerchandiseVariantAppendInput>;
  _delete_at_path?: InputMaybe<MerchandiseVariantDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MerchandiseVariantDeleteElemInput>;
  _delete_key?: InputMaybe<MerchandiseVariantDeleteKeyInput>;
  _inc?: InputMaybe<MerchandiseVariantIncInput>;
  _prepend?: InputMaybe<MerchandiseVariantPrependInput>;
  _set?: InputMaybe<MerchandiseVariantSetInput>;
  pk_columns: MerchandiseVariantPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseVariantManyArgs = {
  updates: Array<MerchandiseVariantUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchandiseByPkArgs = {
  _inc?: InputMaybe<MerchandiseIncInput>;
  _set?: InputMaybe<MerchandiseSetInput>;
  pk_columns: MerchandisePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchandiseManyArgs = {
  updates: Array<MerchandiseUpdates>;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseArgs = {
  _inc?: InputMaybe<OnlineCourseIncInput>;
  _set?: InputMaybe<OnlineCourseSetInput>;
  where: OnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseOwnerArgs = {
  _set?: InputMaybe<OnlineCourseOwnerSetInput>;
  where: OnlineCourseOwnerBoolExp;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseOwnerByPkArgs = {
  _set?: InputMaybe<OnlineCourseOwnerSetInput>;
  pk_columns: OnlineCourseOwnerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseOwnerManyArgs = {
  updates: Array<OnlineCourseOwnerUpdates>;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseRevisionArgs = {
  _append?: InputMaybe<OnlineCourseRevisionAppendInput>;
  _delete_at_path?: InputMaybe<OnlineCourseRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OnlineCourseRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<OnlineCourseRevisionDeleteKeyInput>;
  _inc?: InputMaybe<OnlineCourseRevisionIncInput>;
  _prepend?: InputMaybe<OnlineCourseRevisionPrependInput>;
  _set?: InputMaybe<OnlineCourseRevisionSetInput>;
  where: OnlineCourseRevisionBoolExp;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseRevisionByPkArgs = {
  _append?: InputMaybe<OnlineCourseRevisionAppendInput>;
  _delete_at_path?: InputMaybe<OnlineCourseRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OnlineCourseRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<OnlineCourseRevisionDeleteKeyInput>;
  _inc?: InputMaybe<OnlineCourseRevisionIncInput>;
  _prepend?: InputMaybe<OnlineCourseRevisionPrependInput>;
  _set?: InputMaybe<OnlineCourseRevisionSetInput>;
  pk_columns: OnlineCourseRevisionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseRevisionManyArgs = {
  updates: Array<OnlineCourseRevisionUpdates>;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseByPkArgs = {
  _inc?: InputMaybe<OnlineCourseIncInput>;
  _set?: InputMaybe<OnlineCourseSetInput>;
  pk_columns: OnlineCoursePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOnlineCourseManyArgs = {
  updates: Array<OnlineCourseUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizerArgs = {
  _inc?: InputMaybe<OrganizerIncInput>;
  _set?: InputMaybe<OrganizerSetInput>;
  where: OrganizerBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizerChannelArgs = {
  _inc?: InputMaybe<OrganizerChannelIncInput>;
  _set?: InputMaybe<OrganizerChannelSetInput>;
  where: OrganizerChannelBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizerChannelByPkArgs = {
  _inc?: InputMaybe<OrganizerChannelIncInput>;
  _set?: InputMaybe<OrganizerChannelSetInput>;
  pk_columns: OrganizerChannelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizerChannelManyArgs = {
  updates: Array<OrganizerChannelUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizerInviteArgs = {
  _inc?: InputMaybe<OrganizerInviteIncInput>;
  _set?: InputMaybe<OrganizerInviteSetInput>;
  where: OrganizerInviteBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizerInviteByPkArgs = {
  _inc?: InputMaybe<OrganizerInviteIncInput>;
  _set?: InputMaybe<OrganizerInviteSetInput>;
  pk_columns: OrganizerInvitePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizerInviteManyArgs = {
  updates: Array<OrganizerInviteUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizerByPkArgs = {
  _inc?: InputMaybe<OrganizerIncInput>;
  _set?: InputMaybe<OrganizerSetInput>;
  pk_columns: OrganizerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizerManyArgs = {
  updates: Array<OrganizerUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationArgs = {
  _append?: InputMaybe<RegistrationAppendInput>;
  _delete_at_path?: InputMaybe<RegistrationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegistrationDeleteElemInput>;
  _delete_key?: InputMaybe<RegistrationDeleteKeyInput>;
  _inc?: InputMaybe<RegistrationIncInput>;
  _prepend?: InputMaybe<RegistrationPrependInput>;
  _set?: InputMaybe<RegistrationSetInput>;
  where: RegistrationBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationActivityArgs = {
  _append?: InputMaybe<RegistrationActivityAppendInput>;
  _delete_at_path?: InputMaybe<RegistrationActivityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegistrationActivityDeleteElemInput>;
  _delete_key?: InputMaybe<RegistrationActivityDeleteKeyInput>;
  _inc?: InputMaybe<RegistrationActivityIncInput>;
  _prepend?: InputMaybe<RegistrationActivityPrependInput>;
  _set?: InputMaybe<RegistrationActivitySetInput>;
  where: RegistrationActivityBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationActivityByPkArgs = {
  _append?: InputMaybe<RegistrationActivityAppendInput>;
  _delete_at_path?: InputMaybe<RegistrationActivityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegistrationActivityDeleteElemInput>;
  _delete_key?: InputMaybe<RegistrationActivityDeleteKeyInput>;
  _inc?: InputMaybe<RegistrationActivityIncInput>;
  _prepend?: InputMaybe<RegistrationActivityPrependInput>;
  _set?: InputMaybe<RegistrationActivitySetInput>;
  pk_columns: RegistrationActivityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationActivityManyArgs = {
  updates: Array<RegistrationActivityUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationAdditionalPaymentArgs = {
  _inc?: InputMaybe<RegistrationAdditionalPaymentIncInput>;
  _set?: InputMaybe<RegistrationAdditionalPaymentSetInput>;
  where: RegistrationAdditionalPaymentBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationAdditionalPaymentByPkArgs = {
  _inc?: InputMaybe<RegistrationAdditionalPaymentIncInput>;
  _set?: InputMaybe<RegistrationAdditionalPaymentSetInput>;
  pk_columns: RegistrationAdditionalPaymentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationAdditionalPaymentManyArgs = {
  updates: Array<RegistrationAdditionalPaymentUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationCategoryArgs = {
  _inc?: InputMaybe<RegistrationCategoryIncInput>;
  _set?: InputMaybe<RegistrationCategorySetInput>;
  where: RegistrationCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationCategoryByPkArgs = {
  _inc?: InputMaybe<RegistrationCategoryIncInput>;
  _set?: InputMaybe<RegistrationCategorySetInput>;
  pk_columns: RegistrationCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationCategoryManyArgs = {
  updates: Array<RegistrationCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationForArgs = {
  _set?: InputMaybe<RegistrationForSetInput>;
  where: RegistrationForBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationForByPkArgs = {
  _set?: InputMaybe<RegistrationForSetInput>;
  pk_columns: RegistrationForPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationForManyArgs = {
  updates: Array<RegistrationForUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationSeriesArgs = {
  _inc?: InputMaybe<RegistrationSeriesIncInput>;
  _set?: InputMaybe<RegistrationSeriesSetInput>;
  where: RegistrationSeriesBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationSeriesByPkArgs = {
  _inc?: InputMaybe<RegistrationSeriesIncInput>;
  _set?: InputMaybe<RegistrationSeriesSetInput>;
  pk_columns: RegistrationSeriesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationSeriesManyArgs = {
  updates: Array<RegistrationSeriesUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationStatusArgs = {
  _set?: InputMaybe<RegistrationStatusSetInput>;
  where: RegistrationStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationStatusByPkArgs = {
  _set?: InputMaybe<RegistrationStatusSetInput>;
  pk_columns: RegistrationStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationStatusManyArgs = {
  updates: Array<RegistrationStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationTicketArgs = {
  _inc?: InputMaybe<RegistrationTicketIncInput>;
  _set?: InputMaybe<RegistrationTicketSetInput>;
  where: RegistrationTicketBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegistrationTicketByPkArgs = {
  _inc?: InputMaybe<RegistrationTicketIncInput>;
  _set?: InputMaybe<RegistrationTicketSetInput>;
  pk_columns: RegistrationTicketPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationTicketManyArgs = {
  updates: Array<RegistrationTicketUpdates>;
};


/** mutation root */
export type MutationRootUpdateRegistrationByPkArgs = {
  _append?: InputMaybe<RegistrationAppendInput>;
  _delete_at_path?: InputMaybe<RegistrationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegistrationDeleteElemInput>;
  _delete_key?: InputMaybe<RegistrationDeleteKeyInput>;
  _inc?: InputMaybe<RegistrationIncInput>;
  _prepend?: InputMaybe<RegistrationPrependInput>;
  _set?: InputMaybe<RegistrationSetInput>;
  pk_columns: RegistrationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegistrationManyArgs = {
  updates: Array<RegistrationUpdates>;
};


/** mutation root */
export type MutationRootUpdateReportClinicNoShowArgs = {
  _append?: InputMaybe<ReportClinicNoShowAppendInput>;
  _delete_at_path?: InputMaybe<ReportClinicNoShowDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportClinicNoShowDeleteElemInput>;
  _delete_key?: InputMaybe<ReportClinicNoShowDeleteKeyInput>;
  _inc?: InputMaybe<ReportClinicNoShowIncInput>;
  _prepend?: InputMaybe<ReportClinicNoShowPrependInput>;
  _set?: InputMaybe<ReportClinicNoShowSetInput>;
  where: ReportClinicNoShowBoolExp;
};


/** mutation root */
export type MutationRootUpdateReportClinicNoShowByPkArgs = {
  _append?: InputMaybe<ReportClinicNoShowAppendInput>;
  _delete_at_path?: InputMaybe<ReportClinicNoShowDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportClinicNoShowDeleteElemInput>;
  _delete_key?: InputMaybe<ReportClinicNoShowDeleteKeyInput>;
  _inc?: InputMaybe<ReportClinicNoShowIncInput>;
  _prepend?: InputMaybe<ReportClinicNoShowPrependInput>;
  _set?: InputMaybe<ReportClinicNoShowSetInput>;
  pk_columns: ReportClinicNoShowPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateReportClinicNoShowManyArgs = {
  updates: Array<ReportClinicNoShowUpdates>;
};


/** mutation root */
export type MutationRootUpdateReportLessonNoShowArgs = {
  _append?: InputMaybe<ReportLessonNoShowAppendInput>;
  _delete_at_path?: InputMaybe<ReportLessonNoShowDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportLessonNoShowDeleteElemInput>;
  _delete_key?: InputMaybe<ReportLessonNoShowDeleteKeyInput>;
  _inc?: InputMaybe<ReportLessonNoShowIncInput>;
  _prepend?: InputMaybe<ReportLessonNoShowPrependInput>;
  _set?: InputMaybe<ReportLessonNoShowSetInput>;
  where: ReportLessonNoShowBoolExp;
};


/** mutation root */
export type MutationRootUpdateReportLessonNoShowByPkArgs = {
  _append?: InputMaybe<ReportLessonNoShowAppendInput>;
  _delete_at_path?: InputMaybe<ReportLessonNoShowDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportLessonNoShowDeleteElemInput>;
  _delete_key?: InputMaybe<ReportLessonNoShowDeleteKeyInput>;
  _inc?: InputMaybe<ReportLessonNoShowIncInput>;
  _prepend?: InputMaybe<ReportLessonNoShowPrependInput>;
  _set?: InputMaybe<ReportLessonNoShowSetInput>;
  pk_columns: ReportLessonNoShowPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateReportLessonNoShowManyArgs = {
  updates: Array<ReportLessonNoShowUpdates>;
};


/** mutation root */
export type MutationRootUpdateReviewArgs = {
  _append?: InputMaybe<ReviewAppendInput>;
  _delete_at_path?: InputMaybe<ReviewDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReviewDeleteElemInput>;
  _delete_key?: InputMaybe<ReviewDeleteKeyInput>;
  _inc?: InputMaybe<ReviewIncInput>;
  _prepend?: InputMaybe<ReviewPrependInput>;
  _set?: InputMaybe<ReviewSetInput>;
  where: ReviewBoolExp;
};


/** mutation root */
export type MutationRootUpdateReviewSubjectTypeArgs = {
  _set?: InputMaybe<ReviewSubjectTypeSetInput>;
  where: ReviewSubjectTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateReviewSubjectTypeByPkArgs = {
  _set?: InputMaybe<ReviewSubjectTypeSetInput>;
  pk_columns: ReviewSubjectTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateReviewSubjectTypeManyArgs = {
  updates: Array<ReviewSubjectTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateReviewTotalsArgs = {
  _inc?: InputMaybe<ReviewTotalsIncInput>;
  _set?: InputMaybe<ReviewTotalsSetInput>;
  where: ReviewTotalsBoolExp;
};


/** mutation root */
export type MutationRootUpdateReviewTotalsByPkArgs = {
  _inc?: InputMaybe<ReviewTotalsIncInput>;
  _set?: InputMaybe<ReviewTotalsSetInput>;
  pk_columns: ReviewTotalsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateReviewTotalsManyArgs = {
  updates: Array<ReviewTotalsUpdates>;
};


/** mutation root */
export type MutationRootUpdateReviewByPkArgs = {
  _append?: InputMaybe<ReviewAppendInput>;
  _delete_at_path?: InputMaybe<ReviewDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReviewDeleteElemInput>;
  _delete_key?: InputMaybe<ReviewDeleteKeyInput>;
  _inc?: InputMaybe<ReviewIncInput>;
  _prepend?: InputMaybe<ReviewPrependInput>;
  _set?: InputMaybe<ReviewSetInput>;
  pk_columns: ReviewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateReviewManyArgs = {
  updates: Array<ReviewUpdates>;
};


/** mutation root */
export type MutationRootUpdateRideArgs = {
  _inc?: InputMaybe<RideIncInput>;
  _set?: InputMaybe<RideSetInput>;
  where: RideBoolExp;
};


/** mutation root */
export type MutationRootUpdateRideDayArgs = {
  _inc?: InputMaybe<RideDayIncInput>;
  _set?: InputMaybe<RideDaySetInput>;
  where: RideDayBoolExp;
};


/** mutation root */
export type MutationRootUpdateRideDayByPkArgs = {
  _inc?: InputMaybe<RideDayIncInput>;
  _set?: InputMaybe<RideDaySetInput>;
  pk_columns: RideDayPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRideDayManyArgs = {
  updates: Array<RideDayUpdates>;
};


/** mutation root */
export type MutationRootUpdateRideByPkArgs = {
  _inc?: InputMaybe<RideIncInput>;
  _set?: InputMaybe<RideSetInput>;
  pk_columns: RidePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRideManyArgs = {
  updates: Array<RideUpdates>;
};


/** mutation root */
export type MutationRootUpdateRoleOrganizerArgs = {
  _set?: InputMaybe<RoleOrganizerSetInput>;
  where: RoleOrganizerBoolExp;
};


/** mutation root */
export type MutationRootUpdateRoleOrganizerByPkArgs = {
  _set?: InputMaybe<RoleOrganizerSetInput>;
  pk_columns: RoleOrganizerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRoleOrganizerManyArgs = {
  updates: Array<RoleOrganizerUpdates>;
};


/** mutation root */
export type MutationRootUpdateSanctionArgs = {
  _append?: InputMaybe<SanctionAppendInput>;
  _delete_at_path?: InputMaybe<SanctionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SanctionDeleteElemInput>;
  _delete_key?: InputMaybe<SanctionDeleteKeyInput>;
  _inc?: InputMaybe<SanctionIncInput>;
  _prepend?: InputMaybe<SanctionPrependInput>;
  _set?: InputMaybe<SanctionSetInput>;
  where: SanctionBoolExp;
};


/** mutation root */
export type MutationRootUpdateSanctionWaiversArgs = {
  _set?: InputMaybe<SanctionWaiversSetInput>;
  where: SanctionWaiversBoolExp;
};


/** mutation root */
export type MutationRootUpdateSanctionWaiversByPkArgs = {
  _set?: InputMaybe<SanctionWaiversSetInput>;
  pk_columns: SanctionWaiversPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSanctionWaiversManyArgs = {
  updates: Array<SanctionWaiversUpdates>;
};


/** mutation root */
export type MutationRootUpdateSanctionByPkArgs = {
  _append?: InputMaybe<SanctionAppendInput>;
  _delete_at_path?: InputMaybe<SanctionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SanctionDeleteElemInput>;
  _delete_key?: InputMaybe<SanctionDeleteKeyInput>;
  _inc?: InputMaybe<SanctionIncInput>;
  _prepend?: InputMaybe<SanctionPrependInput>;
  _set?: InputMaybe<SanctionSetInput>;
  pk_columns: SanctionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSanctionManyArgs = {
  updates: Array<SanctionUpdates>;
};


/** mutation root */
export type MutationRootUpdateScheduleTypePresetArgs = {
  _inc?: InputMaybe<ScheduleTypePresetIncInput>;
  _set?: InputMaybe<ScheduleTypePresetSetInput>;
  where: ScheduleTypePresetBoolExp;
};


/** mutation root */
export type MutationRootUpdateScheduleTypePresetByPkArgs = {
  _inc?: InputMaybe<ScheduleTypePresetIncInput>;
  _set?: InputMaybe<ScheduleTypePresetSetInput>;
  pk_columns: ScheduleTypePresetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateScheduleTypePresetManyArgs = {
  updates: Array<ScheduleTypePresetUpdates>;
};


/** mutation root */
export type MutationRootUpdateSeriesArgs = {
  _inc?: InputMaybe<SeriesIncInput>;
  _set?: InputMaybe<SeriesSetInput>;
  where: SeriesBoolExp;
};


/** mutation root */
export type MutationRootUpdateSeriesImageArgs = {
  _inc?: InputMaybe<SeriesImageIncInput>;
  _set?: InputMaybe<SeriesImageSetInput>;
  where: SeriesImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateSeriesImageByPkArgs = {
  _inc?: InputMaybe<SeriesImageIncInput>;
  _set?: InputMaybe<SeriesImageSetInput>;
  pk_columns: SeriesImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSeriesImageManyArgs = {
  updates: Array<SeriesImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructureCustomArgs = {
  _append?: InputMaybe<SeriesPointsStructureCustomAppendInput>;
  _delete_at_path?: InputMaybe<SeriesPointsStructureCustomDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SeriesPointsStructureCustomDeleteElemInput>;
  _delete_key?: InputMaybe<SeriesPointsStructureCustomDeleteKeyInput>;
  _inc?: InputMaybe<SeriesPointsStructureCustomIncInput>;
  _prepend?: InputMaybe<SeriesPointsStructureCustomPrependInput>;
  _set?: InputMaybe<SeriesPointsStructureCustomSetInput>;
  where: SeriesPointsStructureCustomBoolExp;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructureCustomByPkArgs = {
  _append?: InputMaybe<SeriesPointsStructureCustomAppendInput>;
  _delete_at_path?: InputMaybe<SeriesPointsStructureCustomDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SeriesPointsStructureCustomDeleteElemInput>;
  _delete_key?: InputMaybe<SeriesPointsStructureCustomDeleteKeyInput>;
  _inc?: InputMaybe<SeriesPointsStructureCustomIncInput>;
  _prepend?: InputMaybe<SeriesPointsStructureCustomPrependInput>;
  _set?: InputMaybe<SeriesPointsStructureCustomSetInput>;
  pk_columns: SeriesPointsStructureCustomPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructureCustomManyArgs = {
  updates: Array<SeriesPointsStructureCustomUpdates>;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructurePresetArgs = {
  _inc?: InputMaybe<SeriesPointsStructurePresetIncInput>;
  _set?: InputMaybe<SeriesPointsStructurePresetSetInput>;
  where: SeriesPointsStructurePresetBoolExp;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructurePresetByPkArgs = {
  _inc?: InputMaybe<SeriesPointsStructurePresetIncInput>;
  _set?: InputMaybe<SeriesPointsStructurePresetSetInput>;
  pk_columns: SeriesPointsStructurePresetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSeriesPointsStructurePresetManyArgs = {
  updates: Array<SeriesPointsStructurePresetUpdates>;
};


/** mutation root */
export type MutationRootUpdateSeriesByPkArgs = {
  _inc?: InputMaybe<SeriesIncInput>;
  _set?: InputMaybe<SeriesSetInput>;
  pk_columns: SeriesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSeriesManyArgs = {
  updates: Array<SeriesUpdates>;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestArgs = {
  _inc?: InputMaybe<SignatureRequestIncInput>;
  _set?: InputMaybe<SignatureRequestSetInput>;
  where: SignatureRequestBoolExp;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestSignerTypeArgs = {
  _set?: InputMaybe<SignatureRequestSignerTypeSetInput>;
  where: SignatureRequestSignerTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestSignerTypeByPkArgs = {
  _set?: InputMaybe<SignatureRequestSignerTypeSetInput>;
  pk_columns: SignatureRequestSignerTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestSignerTypeManyArgs = {
  updates: Array<SignatureRequestSignerTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestStatusArgs = {
  _set?: InputMaybe<SignatureRequestStatusSetInput>;
  where: SignatureRequestStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestStatusByPkArgs = {
  _set?: InputMaybe<SignatureRequestStatusSetInput>;
  pk_columns: SignatureRequestStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestStatusManyArgs = {
  updates: Array<SignatureRequestStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestByPkArgs = {
  _inc?: InputMaybe<SignatureRequestIncInput>;
  _set?: InputMaybe<SignatureRequestSetInput>;
  pk_columns: SignatureRequestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSignatureRequestManyArgs = {
  updates: Array<SignatureRequestUpdates>;
};


/** mutation root */
export type MutationRootUpdateSponsorArgs = {
  _inc?: InputMaybe<SponsorIncInput>;
  _set?: InputMaybe<SponsorSetInput>;
  where: SponsorBoolExp;
};


/** mutation root */
export type MutationRootUpdateSponsorImageArgs = {
  _inc?: InputMaybe<SponsorImageIncInput>;
  _set?: InputMaybe<SponsorImageSetInput>;
  where: SponsorImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateSponsorImageByPkArgs = {
  _inc?: InputMaybe<SponsorImageIncInput>;
  _set?: InputMaybe<SponsorImageSetInput>;
  pk_columns: SponsorImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSponsorImageManyArgs = {
  updates: Array<SponsorImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateSponsorByPkArgs = {
  _inc?: InputMaybe<SponsorIncInput>;
  _set?: InputMaybe<SponsorSetInput>;
  pk_columns: SponsorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSponsorManyArgs = {
  updates: Array<SponsorUpdates>;
};


/** mutation root */
export type MutationRootUpdateStripeConnectAccountArgs = {
  _inc?: InputMaybe<StripeConnectAccountIncInput>;
  _set?: InputMaybe<StripeConnectAccountSetInput>;
  where: StripeConnectAccountBoolExp;
};


/** mutation root */
export type MutationRootUpdateStripeConnectAccountByPkArgs = {
  _inc?: InputMaybe<StripeConnectAccountIncInput>;
  _set?: InputMaybe<StripeConnectAccountSetInput>;
  pk_columns: StripeConnectAccountPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateStripeConnectAccountManyArgs = {
  updates: Array<StripeConnectAccountUpdates>;
};


/** mutation root */
export type MutationRootUpdateTicketArgs = {
  _inc?: InputMaybe<TicketIncInput>;
  _set?: InputMaybe<TicketSetInput>;
  where: TicketBoolExp;
};


/** mutation root */
export type MutationRootUpdateTicketByPkArgs = {
  _inc?: InputMaybe<TicketIncInput>;
  _set?: InputMaybe<TicketSetInput>;
  pk_columns: TicketPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTicketManyArgs = {
  updates: Array<TicketUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserArgs = {
  _append?: InputMaybe<UserAppendInput>;
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  _inc?: InputMaybe<UserIncInput>;
  _prepend?: InputMaybe<UserPrependInput>;
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserAddressArgs = {
  _inc?: InputMaybe<UserAddressIncInput>;
  _set?: InputMaybe<UserAddressSetInput>;
  where: UserAddressBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserAddressByPkArgs = {
  _inc?: InputMaybe<UserAddressIncInput>;
  _set?: InputMaybe<UserAddressSetInput>;
  pk_columns: UserAddressPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserAddressManyArgs = {
  updates: Array<UserAddressUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserBikeArgs = {
  _inc?: InputMaybe<UserBikeIncInput>;
  _set?: InputMaybe<UserBikeSetInput>;
  where: UserBikeBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserBikeRevisionArgs = {
  _append?: InputMaybe<UserBikeRevisionAppendInput>;
  _delete_at_path?: InputMaybe<UserBikeRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserBikeRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<UserBikeRevisionDeleteKeyInput>;
  _inc?: InputMaybe<UserBikeRevisionIncInput>;
  _prepend?: InputMaybe<UserBikeRevisionPrependInput>;
  _set?: InputMaybe<UserBikeRevisionSetInput>;
  where: UserBikeRevisionBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserBikeRevisionByPkArgs = {
  _append?: InputMaybe<UserBikeRevisionAppendInput>;
  _delete_at_path?: InputMaybe<UserBikeRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserBikeRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<UserBikeRevisionDeleteKeyInput>;
  _inc?: InputMaybe<UserBikeRevisionIncInput>;
  _prepend?: InputMaybe<UserBikeRevisionPrependInput>;
  _set?: InputMaybe<UserBikeRevisionSetInput>;
  pk_columns: UserBikeRevisionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserBikeRevisionManyArgs = {
  updates: Array<UserBikeRevisionUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserBikeByPkArgs = {
  _inc?: InputMaybe<UserBikeIncInput>;
  _set?: InputMaybe<UserBikeSetInput>;
  pk_columns: UserBikePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserBikeManyArgs = {
  updates: Array<UserBikeUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserEmergencyContactArgs = {
  _inc?: InputMaybe<UserEmergencyContactIncInput>;
  _set?: InputMaybe<UserEmergencyContactSetInput>;
  where: UserEmergencyContactBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserEmergencyContactByPkArgs = {
  _inc?: InputMaybe<UserEmergencyContactIncInput>;
  _set?: InputMaybe<UserEmergencyContactSetInput>;
  pk_columns: UserEmergencyContactPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserEmergencyContactManyArgs = {
  updates: Array<UserEmergencyContactUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserImageArgs = {
  _inc?: InputMaybe<UserImageIncInput>;
  _set?: InputMaybe<UserImageSetInput>;
  where: UserImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserImageByPkArgs = {
  _inc?: InputMaybe<UserImageIncInput>;
  _set?: InputMaybe<UserImageSetInput>;
  pk_columns: UserImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserImageManyArgs = {
  updates: Array<UserImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseArgs = {
  _append?: InputMaybe<UserOnlineCourseAppendInput>;
  _delete_at_path?: InputMaybe<UserOnlineCourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserOnlineCourseDeleteElemInput>;
  _delete_key?: InputMaybe<UserOnlineCourseDeleteKeyInput>;
  _inc?: InputMaybe<UserOnlineCourseIncInput>;
  _prepend?: InputMaybe<UserOnlineCoursePrependInput>;
  _set?: InputMaybe<UserOnlineCourseSetInput>;
  where: UserOnlineCourseBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseStatusArgs = {
  _set?: InputMaybe<UserOnlineCourseStatusSetInput>;
  where: UserOnlineCourseStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseStatusByPkArgs = {
  _set?: InputMaybe<UserOnlineCourseStatusSetInput>;
  pk_columns: UserOnlineCourseStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseStatusManyArgs = {
  updates: Array<UserOnlineCourseStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseByPkArgs = {
  _append?: InputMaybe<UserOnlineCourseAppendInput>;
  _delete_at_path?: InputMaybe<UserOnlineCourseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserOnlineCourseDeleteElemInput>;
  _delete_key?: InputMaybe<UserOnlineCourseDeleteKeyInput>;
  _inc?: InputMaybe<UserOnlineCourseIncInput>;
  _prepend?: InputMaybe<UserOnlineCoursePrependInput>;
  _set?: InputMaybe<UserOnlineCourseSetInput>;
  pk_columns: UserOnlineCoursePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserOnlineCourseManyArgs = {
  updates: Array<UserOnlineCourseUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserOrganizerRoleArgs = {
  _inc?: InputMaybe<UserOrganizerRoleIncInput>;
  _set?: InputMaybe<UserOrganizerRoleSetInput>;
  where: UserOrganizerRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserOrganizerRoleByPkArgs = {
  _inc?: InputMaybe<UserOrganizerRoleIncInput>;
  _set?: InputMaybe<UserOrganizerRoleSetInput>;
  pk_columns: UserOrganizerRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserOrganizerRoleManyArgs = {
  updates: Array<UserOrganizerRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserPersonalizationArgs = {
  _append?: InputMaybe<UserPersonalizationAppendInput>;
  _delete_at_path?: InputMaybe<UserPersonalizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserPersonalizationDeleteElemInput>;
  _delete_key?: InputMaybe<UserPersonalizationDeleteKeyInput>;
  _inc?: InputMaybe<UserPersonalizationIncInput>;
  _prepend?: InputMaybe<UserPersonalizationPrependInput>;
  _set?: InputMaybe<UserPersonalizationSetInput>;
  where: UserPersonalizationBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserPersonalizationByPkArgs = {
  _append?: InputMaybe<UserPersonalizationAppendInput>;
  _delete_at_path?: InputMaybe<UserPersonalizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserPersonalizationDeleteElemInput>;
  _delete_key?: InputMaybe<UserPersonalizationDeleteKeyInput>;
  _inc?: InputMaybe<UserPersonalizationIncInput>;
  _prepend?: InputMaybe<UserPersonalizationPrependInput>;
  _set?: InputMaybe<UserPersonalizationSetInput>;
  pk_columns: UserPersonalizationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserPersonalizationManyArgs = {
  updates: Array<UserPersonalizationUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserRidingInterestArgs = {
  _append?: InputMaybe<UserRidingInterestAppendInput>;
  _delete_at_path?: InputMaybe<UserRidingInterestDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserRidingInterestDeleteElemInput>;
  _delete_key?: InputMaybe<UserRidingInterestDeleteKeyInput>;
  _inc?: InputMaybe<UserRidingInterestIncInput>;
  _prepend?: InputMaybe<UserRidingInterestPrependInput>;
  _set?: InputMaybe<UserRidingInterestSetInput>;
  where: UserRidingInterestBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserRidingInterestByPkArgs = {
  _append?: InputMaybe<UserRidingInterestAppendInput>;
  _delete_at_path?: InputMaybe<UserRidingInterestDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserRidingInterestDeleteElemInput>;
  _delete_key?: InputMaybe<UserRidingInterestDeleteKeyInput>;
  _inc?: InputMaybe<UserRidingInterestIncInput>;
  _prepend?: InputMaybe<UserRidingInterestPrependInput>;
  _set?: InputMaybe<UserRidingInterestSetInput>;
  pk_columns: UserRidingInterestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserRidingInterestManyArgs = {
  updates: Array<UserRidingInterestUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserSanctionLicenseArgs = {
  _inc?: InputMaybe<UserSanctionLicenseIncInput>;
  _set?: InputMaybe<UserSanctionLicenseSetInput>;
  where: UserSanctionLicenseBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSanctionLicenseByPkArgs = {
  _inc?: InputMaybe<UserSanctionLicenseIncInput>;
  _set?: InputMaybe<UserSanctionLicenseSetInput>;
  pk_columns: UserSanctionLicensePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSanctionLicenseManyArgs = {
  updates: Array<UserSanctionLicenseUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserSavedCoachArgs = {
  _inc?: InputMaybe<UserSavedCoachIncInput>;
  _set?: InputMaybe<UserSavedCoachSetInput>;
  where: UserSavedCoachBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSavedCoachByPkArgs = {
  _inc?: InputMaybe<UserSavedCoachIncInput>;
  _set?: InputMaybe<UserSavedCoachSetInput>;
  pk_columns: UserSavedCoachPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSavedCoachManyArgs = {
  updates: Array<UserSavedCoachUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserSavedEventArgs = {
  _inc?: InputMaybe<UserSavedEventIncInput>;
  _set?: InputMaybe<UserSavedEventSetInput>;
  where: UserSavedEventBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSavedEventByPkArgs = {
  _inc?: InputMaybe<UserSavedEventIncInput>;
  _set?: InputMaybe<UserSavedEventSetInput>;
  pk_columns: UserSavedEventPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSavedEventManyArgs = {
  updates: Array<UserSavedEventUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserSavedLessonArgs = {
  _inc?: InputMaybe<UserSavedLessonIncInput>;
  _set?: InputMaybe<UserSavedLessonSetInput>;
  where: UserSavedLessonBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSavedLessonByPkArgs = {
  _inc?: InputMaybe<UserSavedLessonIncInput>;
  _set?: InputMaybe<UserSavedLessonSetInput>;
  pk_columns: UserSavedLessonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSavedLessonManyArgs = {
  updates: Array<UserSavedLessonUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserByPkArgs = {
  _append?: InputMaybe<UserAppendInput>;
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  _inc?: InputMaybe<UserIncInput>;
  _prepend?: InputMaybe<UserPrependInput>;
  _set?: InputMaybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};


/** mutation root */
export type MutationRootUpdateVenueArgs = {
  _inc?: InputMaybe<VenueIncInput>;
  _set?: InputMaybe<VenueSetInput>;
  where: VenueBoolExp;
};


/** mutation root */
export type MutationRootUpdateVenueByPkArgs = {
  _inc?: InputMaybe<VenueIncInput>;
  _set?: InputMaybe<VenueSetInput>;
  pk_columns: VenuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVenueManyArgs = {
  updates: Array<VenueUpdates>;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskDifficultiesArgs = {
  _inc?: InputMaybe<VolunteerTaskDifficultiesIncInput>;
  _set?: InputMaybe<VolunteerTaskDifficultiesSetInput>;
  where: VolunteerTaskDifficultiesBoolExp;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskDifficultiesByPkArgs = {
  _inc?: InputMaybe<VolunteerTaskDifficultiesIncInput>;
  _set?: InputMaybe<VolunteerTaskDifficultiesSetInput>;
  pk_columns: VolunteerTaskDifficultiesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskDifficultiesManyArgs = {
  updates: Array<VolunteerTaskDifficultiesUpdates>;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskPrioritiesArgs = {
  _inc?: InputMaybe<VolunteerTaskPrioritiesIncInput>;
  _set?: InputMaybe<VolunteerTaskPrioritiesSetInput>;
  where: VolunteerTaskPrioritiesBoolExp;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskPrioritiesByPkArgs = {
  _inc?: InputMaybe<VolunteerTaskPrioritiesIncInput>;
  _set?: InputMaybe<VolunteerTaskPrioritiesSetInput>;
  pk_columns: VolunteerTaskPrioritiesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVolunteerTaskPrioritiesManyArgs = {
  updates: Array<VolunteerTaskPrioritiesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWaiverArgs = {
  _inc?: InputMaybe<WaiverIncInput>;
  _set?: InputMaybe<WaiverSetInput>;
  where: WaiverBoolExp;
};


/** mutation root */
export type MutationRootUpdateWaiverOwnerArgs = {
  _set?: InputMaybe<WaiverOwnerSetInput>;
  where: WaiverOwnerBoolExp;
};


/** mutation root */
export type MutationRootUpdateWaiverOwnerByPkArgs = {
  _set?: InputMaybe<WaiverOwnerSetInput>;
  pk_columns: WaiverOwnerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWaiverOwnerManyArgs = {
  updates: Array<WaiverOwnerUpdates>;
};


/** mutation root */
export type MutationRootUpdateWaiverRevisionArgs = {
  _append?: InputMaybe<WaiverRevisionAppendInput>;
  _delete_at_path?: InputMaybe<WaiverRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WaiverRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<WaiverRevisionDeleteKeyInput>;
  _inc?: InputMaybe<WaiverRevisionIncInput>;
  _prepend?: InputMaybe<WaiverRevisionPrependInput>;
  _set?: InputMaybe<WaiverRevisionSetInput>;
  where: WaiverRevisionBoolExp;
};


/** mutation root */
export type MutationRootUpdateWaiverRevisionByPkArgs = {
  _append?: InputMaybe<WaiverRevisionAppendInput>;
  _delete_at_path?: InputMaybe<WaiverRevisionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WaiverRevisionDeleteElemInput>;
  _delete_key?: InputMaybe<WaiverRevisionDeleteKeyInput>;
  _inc?: InputMaybe<WaiverRevisionIncInput>;
  _prepend?: InputMaybe<WaiverRevisionPrependInput>;
  _set?: InputMaybe<WaiverRevisionSetInput>;
  pk_columns: WaiverRevisionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWaiverRevisionManyArgs = {
  updates: Array<WaiverRevisionUpdates>;
};


/** mutation root */
export type MutationRootUpdateWaiverByPkArgs = {
  _inc?: InputMaybe<WaiverIncInput>;
  _set?: InputMaybe<WaiverSetInput>;
  pk_columns: WaiverPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWaiverManyArgs = {
  updates: Array<WaiverUpdates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** main table for online courses */
export type OnlineCourse = Node & {
  __typename?: 'onlineCourse';
  /** An object relationship */
  coach?: Maybe<Coach>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['ID'];
  /** An array relationship */
  imageOnlineCourses: Array<ImageOnlineCourse>;
  /** An aggregate relationship */
  imageOnlineCourses_aggregate: ImageOnlineCourseAggregate;
  /** An array relationship connection */
  imageOnlineCourses_connection: ImageOnlineCourseConnection;
  /** An object relationship */
  latestRevision?: Maybe<OnlineCourseRevision>;
  latestRevisionNum: Scalars['Int'];
  /** An object relationship */
  onlineCourseOwner: OnlineCourseOwner;
  /** An object relationship */
  organizer?: Maybe<Organizer>;
  ownerCuid: Scalars['String'];
  ownerType: OnlineCourseOwnerEnum;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  revisions: Array<OnlineCourseRevision>;
  /** An aggregate relationship */
  revisions_aggregate: OnlineCourseRevisionAggregate;
  /** An array relationship connection */
  revisions_connection: OnlineCourseRevisionConnection;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userOnlineCourses: Array<UserOnlineCourse>;
  /** An aggregate relationship */
  userOnlineCourses_aggregate: UserOnlineCourseAggregate;
  /** An array relationship connection */
  userOnlineCourses_connection: UserOnlineCourseConnection;
};


/** main table for online courses */
export type OnlineCourseImageOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOnlineCourseOrderBy>>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};


/** main table for online courses */
export type OnlineCourseImageOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOnlineCourseOrderBy>>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};


/** main table for online courses */
export type OnlineCourseImageOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOnlineCourseOrderBy>>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};


/** main table for online courses */
export type OnlineCourseRevisionsArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseRevisionOrderBy>>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};


/** main table for online courses */
export type OnlineCourseRevisionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseRevisionOrderBy>>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};


/** main table for online courses */
export type OnlineCourseRevisionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseRevisionOrderBy>>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};


/** main table for online courses */
export type OnlineCourseUserOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** main table for online courses */
export type OnlineCourseUserOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** main table for online courses */
export type OnlineCourseUserOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};

/** A Relay connection object on "onlineCourse" */
export type OnlineCourseConnection = {
  __typename?: 'onlineCourseConnection';
  edges: Array<OnlineCourseEdge>;
  pageInfo: PageInfo;
};

export type OnlineCourseEdge = {
  __typename?: 'onlineCourseEdge';
  cursor: Scalars['String'];
  node: OnlineCourse;
};

/** columns and relationships of "onlineCourseOwner" */
export type OnlineCourseOwner = Node & {
  __typename?: 'onlineCourseOwner';
  description: Scalars['String'];
  id: Scalars['ID'];
  /** An array relationship */
  onlineCourses: Array<OnlineCourse>;
  /** An aggregate relationship */
  onlineCourses_aggregate: OnlineCourseAggregate;
  /** An array relationship connection */
  onlineCourses_connection: OnlineCourseConnection;
  owner: Scalars['String'];
};


/** columns and relationships of "onlineCourseOwner" */
export type OnlineCourseOwnerOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseOwner" */
export type OnlineCourseOwnerOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseOwner" */
export type OnlineCourseOwnerOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};

/** A Relay connection object on "onlineCourseOwner" */
export type OnlineCourseOwnerConnection = {
  __typename?: 'onlineCourseOwnerConnection';
  edges: Array<OnlineCourseOwnerEdge>;
  pageInfo: PageInfo;
};

export type OnlineCourseOwnerEdge = {
  __typename?: 'onlineCourseOwnerEdge';
  cursor: Scalars['String'];
  node: OnlineCourseOwner;
};

/** Boolean expression to filter rows from the table "onlineCourseOwner". All fields are combined with a logical 'AND'. */
export type OnlineCourseOwnerBoolExp = {
  _and?: InputMaybe<Array<OnlineCourseOwnerBoolExp>>;
  _not?: InputMaybe<OnlineCourseOwnerBoolExp>;
  _or?: InputMaybe<Array<OnlineCourseOwnerBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  onlineCourses?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateBoolExp>;
  owner?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "onlineCourseOwner" */
export enum OnlineCourseOwnerConstraint {
  /** unique or primary key constraint on columns "owner" */
  OnlineCourseOwnerPkey = 'onlineCourseOwner_pkey'
}

export enum OnlineCourseOwnerEnum {
  /** Coach owned online courses are only manageable by a single coach */
  Coach = 'COACH',
  /** Organizer owned online courses are manageable by all users in an organization */
  Organizer = 'ORGANIZER'
}

/** Boolean expression to compare columns of type "onlineCourseOwner_enum". All fields are combined with logical 'AND'. */
export type OnlineCourseOwnerEnumComparisonExp = {
  _eq?: InputMaybe<OnlineCourseOwnerEnum>;
  _in?: InputMaybe<Array<OnlineCourseOwnerEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OnlineCourseOwnerEnum>;
  _nin?: InputMaybe<Array<OnlineCourseOwnerEnum>>;
};

/** input type for inserting data into table "onlineCourseOwner" */
export type OnlineCourseOwnerInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  onlineCourses?: InputMaybe<OnlineCourseArrRelInsertInput>;
  owner?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "onlineCourseOwner" */
export type OnlineCourseOwnerMutationResponse = {
  __typename?: 'onlineCourseOwner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnlineCourseOwner>;
};

/** input type for inserting object relation for remote table "onlineCourseOwner" */
export type OnlineCourseOwnerObjRelInsertInput = {
  data: OnlineCourseOwnerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OnlineCourseOwnerOnConflict>;
};

/** on_conflict condition type for table "onlineCourseOwner" */
export type OnlineCourseOwnerOnConflict = {
  constraint: OnlineCourseOwnerConstraint;
  update_columns?: Array<OnlineCourseOwnerUpdateColumn>;
  where?: InputMaybe<OnlineCourseOwnerBoolExp>;
};

/** Ordering options when selecting data from "onlineCourseOwner". */
export type OnlineCourseOwnerOrderBy = {
  description?: InputMaybe<OrderBy>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateOrderBy>;
  owner?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: onlineCourseOwner */
export type OnlineCourseOwnerPkColumnsInput = {
  owner: Scalars['String'];
};

/** select columns of table "onlineCourseOwner" */
export enum OnlineCourseOwnerSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Owner = 'owner'
}

/** input type for updating data in table "onlineCourseOwner" */
export type OnlineCourseOwnerSetInput = {
  description?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

/** update columns of table "onlineCourseOwner" */
export enum OnlineCourseOwnerUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Owner = 'owner'
}

export type OnlineCourseOwnerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnlineCourseOwnerSetInput>;
  where: OnlineCourseOwnerBoolExp;
};

/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevision = Node & {
  __typename?: 'onlineCourseRevision';
  contents: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  currencyCode: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['ID'];
  /** An object relationship */
  onlineCourse?: Maybe<OnlineCourse>;
  onlineCourseCuid: Scalars['String'];
  onlineCourseDefinition: Scalars['jsonb'];
  /** An array relationship */
  onlineCourses: Array<OnlineCourse>;
  /** An aggregate relationship */
  onlineCourses_aggregate: OnlineCourseAggregate;
  /** An array relationship connection */
  onlineCourses_connection: OnlineCourseConnection;
  overviewContent?: Maybe<Scalars['jsonb']>;
  price?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  tagline?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userOnlineCourses: Array<UserOnlineCourse>;
  /** An aggregate relationship */
  userOnlineCourses_aggregate: UserOnlineCourseAggregate;
  /** An array relationship connection */
  userOnlineCourses_connection: UserOnlineCourseConnection;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionOnlineCourseDefinitionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionOverviewContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionUserOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionUserOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** columns and relationships of "onlineCourseRevision" */
export type OnlineCourseRevisionUserOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};

/** A Relay connection object on "onlineCourseRevision" */
export type OnlineCourseRevisionConnection = {
  __typename?: 'onlineCourseRevisionConnection';
  edges: Array<OnlineCourseRevisionEdge>;
  pageInfo: PageInfo;
};

export type OnlineCourseRevisionEdge = {
  __typename?: 'onlineCourseRevisionEdge';
  cursor: Scalars['String'];
  node: OnlineCourseRevision;
};

/** aggregated selection of "onlineCourseRevision" */
export type OnlineCourseRevisionAggregate = {
  __typename?: 'onlineCourseRevision_aggregate';
  aggregate?: Maybe<OnlineCourseRevisionAggregateFields>;
  nodes: Array<OnlineCourseRevision>;
};

export type OnlineCourseRevisionAggregateBoolExp = {
  count?: InputMaybe<OnlineCourseRevisionAggregateBoolExpCount>;
};

export type OnlineCourseRevisionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnlineCourseRevisionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "onlineCourseRevision" */
export type OnlineCourseRevisionAggregateFields = {
  __typename?: 'onlineCourseRevision_aggregate_fields';
  avg?: Maybe<OnlineCourseRevisionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OnlineCourseRevisionMaxFields>;
  min?: Maybe<OnlineCourseRevisionMinFields>;
  stddev?: Maybe<OnlineCourseRevisionStddevFields>;
  stddev_pop?: Maybe<OnlineCourseRevisionStddevPopFields>;
  stddev_samp?: Maybe<OnlineCourseRevisionStddevSampFields>;
  sum?: Maybe<OnlineCourseRevisionSumFields>;
  var_pop?: Maybe<OnlineCourseRevisionVarPopFields>;
  var_samp?: Maybe<OnlineCourseRevisionVarSampFields>;
  variance?: Maybe<OnlineCourseRevisionVarianceFields>;
};


/** aggregate fields of "onlineCourseRevision" */
export type OnlineCourseRevisionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onlineCourseRevision" */
export type OnlineCourseRevisionAggregateOrderBy = {
  avg?: InputMaybe<OnlineCourseRevisionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnlineCourseRevisionMaxOrderBy>;
  min?: InputMaybe<OnlineCourseRevisionMinOrderBy>;
  stddev?: InputMaybe<OnlineCourseRevisionStddevOrderBy>;
  stddev_pop?: InputMaybe<OnlineCourseRevisionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnlineCourseRevisionStddevSampOrderBy>;
  sum?: InputMaybe<OnlineCourseRevisionSumOrderBy>;
  var_pop?: InputMaybe<OnlineCourseRevisionVarPopOrderBy>;
  var_samp?: InputMaybe<OnlineCourseRevisionVarSampOrderBy>;
  variance?: InputMaybe<OnlineCourseRevisionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OnlineCourseRevisionAppendInput = {
  contents?: InputMaybe<Scalars['jsonb']>;
  onlineCourseDefinition?: InputMaybe<Scalars['jsonb']>;
  overviewContent?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "onlineCourseRevision" */
export type OnlineCourseRevisionArrRelInsertInput = {
  data: Array<OnlineCourseRevisionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OnlineCourseRevisionOnConflict>;
};

/** aggregate avg on columns */
export type OnlineCourseRevisionAvgFields = {
  __typename?: 'onlineCourseRevision_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onlineCourseRevision". All fields are combined with a logical 'AND'. */
export type OnlineCourseRevisionBoolExp = {
  _and?: InputMaybe<Array<OnlineCourseRevisionBoolExp>>;
  _not?: InputMaybe<OnlineCourseRevisionBoolExp>;
  _or?: InputMaybe<Array<OnlineCourseRevisionBoolExp>>;
  contents?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  currencyCode?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  onlineCourse?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourseCuid?: InputMaybe<StringComparisonExp>;
  onlineCourseDefinition?: InputMaybe<JsonbComparisonExp>;
  onlineCourses?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateBoolExp>;
  overviewContent?: InputMaybe<JsonbComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  revision?: InputMaybe<IntComparisonExp>;
  tagline?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseBoolExp>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateBoolExp>;
};

/** unique or primary key constraints on table "onlineCourseRevision" */
export enum OnlineCourseRevisionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  OnlineCourseRevisionCuidKey = 'onlineCourseRevision_cuid_key',
  /** unique or primary key constraint on columns "onlineCourseCuid", "revision" */
  OnlineCourseRevisionOnlineCourseCuidRevisionKey = 'onlineCourseRevision_onlineCourseCuid_revision_key',
  /** unique or primary key constraint on columns "id" */
  OnlineCourseRevisionPkey = 'onlineCourseRevision_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OnlineCourseRevisionDeleteAtPathInput = {
  contents?: InputMaybe<Array<Scalars['String']>>;
  onlineCourseDefinition?: InputMaybe<Array<Scalars['String']>>;
  overviewContent?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OnlineCourseRevisionDeleteElemInput = {
  contents?: InputMaybe<Scalars['Int']>;
  onlineCourseDefinition?: InputMaybe<Scalars['Int']>;
  overviewContent?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OnlineCourseRevisionDeleteKeyInput = {
  contents?: InputMaybe<Scalars['String']>;
  onlineCourseDefinition?: InputMaybe<Scalars['String']>;
  overviewContent?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "onlineCourseRevision" */
export type OnlineCourseRevisionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onlineCourseRevision" */
export type OnlineCourseRevisionInsertInput = {
  contents?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  onlineCourse?: InputMaybe<OnlineCourseObjRelInsertInput>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  onlineCourseDefinition?: InputMaybe<Scalars['jsonb']>;
  onlineCourses?: InputMaybe<OnlineCourseArrRelInsertInput>;
  overviewContent?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
  tagline?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseArrRelInsertInput>;
};

/** aggregate max on columns */
export type OnlineCourseRevisionMaxFields = {
  __typename?: 'onlineCourseRevision_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OnlineCourseRevisionMinFields = {
  __typename?: 'onlineCourseRevision_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "onlineCourseRevision" */
export type OnlineCourseRevisionMutationResponse = {
  __typename?: 'onlineCourseRevision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnlineCourseRevision>;
};

/** input type for inserting object relation for remote table "onlineCourseRevision" */
export type OnlineCourseRevisionObjRelInsertInput = {
  data: OnlineCourseRevisionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OnlineCourseRevisionOnConflict>;
};

/** on_conflict condition type for table "onlineCourseRevision" */
export type OnlineCourseRevisionOnConflict = {
  constraint: OnlineCourseRevisionConstraint;
  update_columns?: Array<OnlineCourseRevisionUpdateColumn>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};

/** Ordering options when selecting data from "onlineCourseRevision". */
export type OnlineCourseRevisionOrderBy = {
  contents?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onlineCourse?: InputMaybe<OnlineCourseOrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  onlineCourseDefinition?: InputMaybe<OrderBy>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateOrderBy>;
  overviewContent?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  tagline?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateOrderBy>;
};

/** primary key columns input for table: onlineCourseRevision */
export type OnlineCourseRevisionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OnlineCourseRevisionPrependInput = {
  contents?: InputMaybe<Scalars['jsonb']>;
  onlineCourseDefinition?: InputMaybe<Scalars['jsonb']>;
  overviewContent?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "onlineCourseRevision" */
export enum OnlineCourseRevisionSelectColumn {
  /** column name */
  Contents = 'contents',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  OnlineCourseDefinition = 'onlineCourseDefinition',
  /** column name */
  OverviewContent = 'overviewContent',
  /** column name */
  Price = 'price',
  /** column name */
  Revision = 'revision',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "onlineCourseRevision" */
export type OnlineCourseRevisionSetInput = {
  contents?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  onlineCourseDefinition?: InputMaybe<Scalars['jsonb']>;
  overviewContent?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
  tagline?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OnlineCourseRevisionStddevFields = {
  __typename?: 'onlineCourseRevision_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OnlineCourseRevisionStddevPopFields = {
  __typename?: 'onlineCourseRevision_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OnlineCourseRevisionStddevSampFields = {
  __typename?: 'onlineCourseRevision_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OnlineCourseRevisionSumFields = {
  __typename?: 'onlineCourseRevision_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** update columns of table "onlineCourseRevision" */
export enum OnlineCourseRevisionUpdateColumn {
  /** column name */
  Contents = 'contents',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  OnlineCourseDefinition = 'onlineCourseDefinition',
  /** column name */
  OverviewContent = 'overviewContent',
  /** column name */
  Price = 'price',
  /** column name */
  Revision = 'revision',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type OnlineCourseRevisionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<OnlineCourseRevisionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<OnlineCourseRevisionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<OnlineCourseRevisionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<OnlineCourseRevisionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnlineCourseRevisionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<OnlineCourseRevisionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnlineCourseRevisionSetInput>;
  where: OnlineCourseRevisionBoolExp;
};

/** aggregate var_pop on columns */
export type OnlineCourseRevisionVarPopFields = {
  __typename?: 'onlineCourseRevision_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OnlineCourseRevisionVarSampFields = {
  __typename?: 'onlineCourseRevision_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OnlineCourseRevisionVarianceFields = {
  __typename?: 'onlineCourseRevision_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onlineCourseRevision" */
export type OnlineCourseRevisionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregated selection of "onlineCourse" */
export type OnlineCourseAggregate = {
  __typename?: 'onlineCourse_aggregate';
  aggregate?: Maybe<OnlineCourseAggregateFields>;
  nodes: Array<OnlineCourse>;
};

export type OnlineCourseAggregateBoolExp = {
  count?: InputMaybe<OnlineCourseAggregateBoolExpCount>;
};

export type OnlineCourseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnlineCourseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "onlineCourse" */
export type OnlineCourseAggregateFields = {
  __typename?: 'onlineCourse_aggregate_fields';
  avg?: Maybe<OnlineCourseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OnlineCourseMaxFields>;
  min?: Maybe<OnlineCourseMinFields>;
  stddev?: Maybe<OnlineCourseStddevFields>;
  stddev_pop?: Maybe<OnlineCourseStddevPopFields>;
  stddev_samp?: Maybe<OnlineCourseStddevSampFields>;
  sum?: Maybe<OnlineCourseSumFields>;
  var_pop?: Maybe<OnlineCourseVarPopFields>;
  var_samp?: Maybe<OnlineCourseVarSampFields>;
  variance?: Maybe<OnlineCourseVarianceFields>;
};


/** aggregate fields of "onlineCourse" */
export type OnlineCourseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onlineCourse" */
export type OnlineCourseAggregateOrderBy = {
  avg?: InputMaybe<OnlineCourseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnlineCourseMaxOrderBy>;
  min?: InputMaybe<OnlineCourseMinOrderBy>;
  stddev?: InputMaybe<OnlineCourseStddevOrderBy>;
  stddev_pop?: InputMaybe<OnlineCourseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnlineCourseStddevSampOrderBy>;
  sum?: InputMaybe<OnlineCourseSumOrderBy>;
  var_pop?: InputMaybe<OnlineCourseVarPopOrderBy>;
  var_samp?: InputMaybe<OnlineCourseVarSampOrderBy>;
  variance?: InputMaybe<OnlineCourseVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "onlineCourse" */
export type OnlineCourseArrRelInsertInput = {
  data: Array<OnlineCourseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OnlineCourseOnConflict>;
};

/** aggregate avg on columns */
export type OnlineCourseAvgFields = {
  __typename?: 'onlineCourse_avg_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onlineCourse" */
export type OnlineCourseAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onlineCourse". All fields are combined with a logical 'AND'. */
export type OnlineCourseBoolExp = {
  _and?: InputMaybe<Array<OnlineCourseBoolExp>>;
  _not?: InputMaybe<OnlineCourseBoolExp>;
  _or?: InputMaybe<Array<OnlineCourseBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  imageOnlineCourses?: InputMaybe<ImageOnlineCourseBoolExp>;
  imageOnlineCourses_aggregate?: InputMaybe<ImageOnlineCourseAggregateBoolExp>;
  latestRevision?: InputMaybe<OnlineCourseRevisionBoolExp>;
  latestRevisionNum?: InputMaybe<IntComparisonExp>;
  onlineCourseOwner?: InputMaybe<OnlineCourseOwnerBoolExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  ownerCuid?: InputMaybe<StringComparisonExp>;
  ownerType?: InputMaybe<OnlineCourseOwnerEnumComparisonExp>;
  publishedAt?: InputMaybe<TimestamptzComparisonExp>;
  revisions?: InputMaybe<OnlineCourseRevisionBoolExp>;
  revisions_aggregate?: InputMaybe<OnlineCourseRevisionAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseBoolExp>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateBoolExp>;
};

/** unique or primary key constraints on table "onlineCourse" */
export enum OnlineCourseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  OnlineCourseCuidKey = 'onlineCourse_cuid_key',
  /** unique or primary key constraint on columns "id" */
  OnlineCoursePkey = 'onlineCourse_pkey'
}

/** input type for incrementing numeric columns in table "onlineCourse" */
export type OnlineCourseIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onlineCourse" */
export type OnlineCourseInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  imageOnlineCourses?: InputMaybe<ImageOnlineCourseArrRelInsertInput>;
  latestRevision?: InputMaybe<OnlineCourseRevisionObjRelInsertInput>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  onlineCourseOwner?: InputMaybe<OnlineCourseOwnerObjRelInsertInput>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  ownerCuid?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<OnlineCourseOwnerEnum>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  revisions?: InputMaybe<OnlineCourseRevisionArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseArrRelInsertInput>;
};

/** aggregate max on columns */
export type OnlineCourseMaxFields = {
  __typename?: 'onlineCourse_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  ownerCuid?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "onlineCourse" */
export type OnlineCourseMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OnlineCourseMinFields = {
  __typename?: 'onlineCourse_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  ownerCuid?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "onlineCourse" */
export type OnlineCourseMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "onlineCourse" */
export type OnlineCourseMutationResponse = {
  __typename?: 'onlineCourse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnlineCourse>;
};

/** input type for inserting object relation for remote table "onlineCourse" */
export type OnlineCourseObjRelInsertInput = {
  data: OnlineCourseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OnlineCourseOnConflict>;
};

/** on_conflict condition type for table "onlineCourse" */
export type OnlineCourseOnConflict = {
  constraint: OnlineCourseConstraint;
  update_columns?: Array<OnlineCourseUpdateColumn>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};

/** Ordering options when selecting data from "onlineCourse". */
export type OnlineCourseOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageOnlineCourses_aggregate?: InputMaybe<ImageOnlineCourseAggregateOrderBy>;
  latestRevision?: InputMaybe<OnlineCourseRevisionOrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  onlineCourseOwner?: InputMaybe<OnlineCourseOwnerOrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  ownerType?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  revisions_aggregate?: InputMaybe<OnlineCourseRevisionAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateOrderBy>;
};

/** primary key columns input for table: onlineCourse */
export type OnlineCoursePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "onlineCourse" */
export enum OnlineCourseSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  OwnerCuid = 'ownerCuid',
  /** column name */
  OwnerType = 'ownerType',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "onlineCourse" */
export type OnlineCourseSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  ownerCuid?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<OnlineCourseOwnerEnum>;
  publishedAt?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OnlineCourseStddevFields = {
  __typename?: 'onlineCourse_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onlineCourse" */
export type OnlineCourseStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OnlineCourseStddevPopFields = {
  __typename?: 'onlineCourse_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onlineCourse" */
export type OnlineCourseStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OnlineCourseStddevSampFields = {
  __typename?: 'onlineCourse_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onlineCourse" */
export type OnlineCourseStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OnlineCourseSumFields = {
  __typename?: 'onlineCourse_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onlineCourse" */
export type OnlineCourseSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** update columns of table "onlineCourse" */
export enum OnlineCourseUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  OwnerCuid = 'ownerCuid',
  /** column name */
  OwnerType = 'ownerType',
  /** column name */
  PublishedAt = 'publishedAt',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type OnlineCourseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnlineCourseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnlineCourseSetInput>;
  where: OnlineCourseBoolExp;
};

/** aggregate var_pop on columns */
export type OnlineCourseVarPopFields = {
  __typename?: 'onlineCourse_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onlineCourse" */
export type OnlineCourseVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OnlineCourseVarSampFields = {
  __typename?: 'onlineCourse_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onlineCourse" */
export type OnlineCourseVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OnlineCourseVarianceFields = {
  __typename?: 'onlineCourse_variance_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onlineCourse" */
export type OnlineCourseVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organizer" */
export type Organizer = Node & {
  __typename?: 'organizer';
  about?: Maybe<Scalars['String']>;
  /** An array relationship */
  coaches: Array<Coach>;
  /** An aggregate relationship */
  coaches_aggregate: CoachAggregate;
  /** An array relationship connection */
  coaches_connection: CoachConnection;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  defaultCurrencyCode: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  eventMetadata: Array<EventMetadata>;
  /** An aggregate relationship */
  eventMetadata_aggregate: EventMetadataAggregate;
  /** An array relationship connection */
  eventMetadata_connection: EventMetadataConnection;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: EventAggregate;
  /** An array relationship connection */
  events_connection: EventConnection;
  id: Scalars['ID'];
  /** An object relationship */
  image?: Maybe<Image>;
  imagePublicId?: Maybe<Scalars['String']>;
  /** An array relationship */
  images: Array<Image>;
  /** An aggregate relationship */
  images_aggregate: ImageAggregate;
  /** An array relationship connection */
  images_connection: ImageConnection;
  /** An array relationship */
  merchandises: Array<Merchandise>;
  /** An aggregate relationship */
  merchandises_aggregate: MerchandiseAggregate;
  /** An array relationship connection */
  merchandises_connection: MerchandiseConnection;
  name: Scalars['String'];
  /** An array relationship */
  onlineCourses: Array<OnlineCourse>;
  /** An aggregate relationship */
  onlineCourses_aggregate: OnlineCourseAggregate;
  /** An array relationship connection */
  onlineCourses_connection: OnlineCourseConnection;
  /** An array relationship */
  organizerChannels: Array<OrganizerChannel>;
  /** An aggregate relationship */
  organizerChannels_aggregate: OrganizerChannelAggregate;
  /** An array relationship connection */
  organizerChannels_connection: OrganizerChannelConnection;
  /** An array relationship */
  organizerInvites: Array<OrganizerInvite>;
  /** An aggregate relationship */
  organizerInvites_aggregate: OrganizerInviteAggregate;
  /** An array relationship connection */
  organizerInvites_connection: OrganizerInviteConnection;
  /** An array relationship */
  series: Array<Series>;
  /** An aggregate relationship */
  series_aggregate: SeriesAggregate;
  /** An array relationship connection */
  series_connection: SeriesConnection;
  showOnboarding: Scalars['Boolean'];
  slug: Scalars['String'];
  /** An array relationship */
  sponsors: Array<Sponsor>;
  /** An aggregate relationship */
  sponsors_aggregate: SponsorAggregate;
  /** An array relationship connection */
  sponsors_connection: SponsorConnection;
  /** An object relationship */
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
  /** An array relationship */
  stripeConnectAccounts: Array<StripeConnectAccount>;
  /** An aggregate relationship */
  stripeConnectAccounts_aggregate: StripeConnectAccountAggregate;
  /** An array relationship connection */
  stripeConnectAccounts_connection: StripeConnectAccountConnection;
  stripeConnectId?: Maybe<Scalars['String']>;
  /** An array relationship */
  tickets: Array<Ticket>;
  /** An aggregate relationship */
  tickets_aggregate: TicketAggregate;
  /** An array relationship connection */
  tickets_connection: TicketConnection;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userOrganizerRoles: Array<UserOrganizerRole>;
  /** An aggregate relationship */
  userOrganizerRoles_aggregate: UserOrganizerRoleAggregate;
  /** An array relationship connection */
  userOrganizerRoles_connection: UserOrganizerRoleConnection;
  /** An array relationship */
  venues: Array<Venue>;
  /** An aggregate relationship */
  venues_aggregate: VenueAggregate;
  /** An array relationship connection */
  venues_connection: VenueConnection;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "organizer" */
export type OrganizerCoachesArgs = {
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventMetadataArgs = {
  distinct_on?: InputMaybe<Array<EventMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMetadataOrderBy>>;
  where?: InputMaybe<EventMetadataBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventMetadataAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMetadataOrderBy>>;
  where?: InputMaybe<EventMetadataBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMetadataSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMetadataOrderBy>>;
  where?: InputMaybe<EventMetadataBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventsArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerImagesArgs = {
  distinct_on?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerMerchandisesArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerMerchandisesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerMerchandisesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerChannelsArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerInvitesArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerOrganizerInvitesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSeriesArgs = {
  distinct_on?: InputMaybe<Array<SeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesOrderBy>>;
  where?: InputMaybe<SeriesBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSeriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesOrderBy>>;
  where?: InputMaybe<SeriesBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesOrderBy>>;
  where?: InputMaybe<SeriesBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSponsorsArgs = {
  distinct_on?: InputMaybe<Array<SponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorOrderBy>>;
  where?: InputMaybe<SponsorBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSponsorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorOrderBy>>;
  where?: InputMaybe<SponsorBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerSponsorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorOrderBy>>;
  where?: InputMaybe<SponsorBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerStripeConnectAccountsArgs = {
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerStripeConnectAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerStripeConnectAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerTicketsArgs = {
  distinct_on?: InputMaybe<Array<TicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TicketOrderBy>>;
  where?: InputMaybe<TicketBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerTicketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TicketOrderBy>>;
  where?: InputMaybe<TicketBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerTicketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<TicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TicketOrderBy>>;
  where?: InputMaybe<TicketBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerUserOrganizerRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerUserOrganizerRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerUserOrganizerRolesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerVenuesArgs = {
  distinct_on?: InputMaybe<Array<VenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VenueOrderBy>>;
  where?: InputMaybe<VenueBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerVenuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<VenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VenueOrderBy>>;
  where?: InputMaybe<VenueBoolExp>;
};


/** columns and relationships of "organizer" */
export type OrganizerVenuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VenueOrderBy>>;
  where?: InputMaybe<VenueBoolExp>;
};

/** A stream channel for the organizer */
export type OrganizerChannel = Node & {
  __typename?: 'organizerChannel';
  channelId: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event?: Maybe<Event>;
  eventCuid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "organizerChannel" */
export type OrganizerChannelConnection = {
  __typename?: 'organizerChannelConnection';
  edges: Array<OrganizerChannelEdge>;
  pageInfo: PageInfo;
};

export type OrganizerChannelEdge = {
  __typename?: 'organizerChannelEdge';
  cursor: Scalars['String'];
  node: OrganizerChannel;
};

/** aggregated selection of "organizerChannel" */
export type OrganizerChannelAggregate = {
  __typename?: 'organizerChannel_aggregate';
  aggregate?: Maybe<OrganizerChannelAggregateFields>;
  nodes: Array<OrganizerChannel>;
};

export type OrganizerChannelAggregateBoolExp = {
  count?: InputMaybe<OrganizerChannelAggregateBoolExpCount>;
};

export type OrganizerChannelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizerChannelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "organizerChannel" */
export type OrganizerChannelAggregateFields = {
  __typename?: 'organizerChannel_aggregate_fields';
  avg?: Maybe<OrganizerChannelAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizerChannelMaxFields>;
  min?: Maybe<OrganizerChannelMinFields>;
  stddev?: Maybe<OrganizerChannelStddevFields>;
  stddev_pop?: Maybe<OrganizerChannelStddevPopFields>;
  stddev_samp?: Maybe<OrganizerChannelStddevSampFields>;
  sum?: Maybe<OrganizerChannelSumFields>;
  var_pop?: Maybe<OrganizerChannelVarPopFields>;
  var_samp?: Maybe<OrganizerChannelVarSampFields>;
  variance?: Maybe<OrganizerChannelVarianceFields>;
};


/** aggregate fields of "organizerChannel" */
export type OrganizerChannelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizerChannel" */
export type OrganizerChannelAggregateOrderBy = {
  avg?: InputMaybe<OrganizerChannelAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizerChannelMaxOrderBy>;
  min?: InputMaybe<OrganizerChannelMinOrderBy>;
  stddev?: InputMaybe<OrganizerChannelStddevOrderBy>;
  stddev_pop?: InputMaybe<OrganizerChannelStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrganizerChannelStddevSampOrderBy>;
  sum?: InputMaybe<OrganizerChannelSumOrderBy>;
  var_pop?: InputMaybe<OrganizerChannelVarPopOrderBy>;
  var_samp?: InputMaybe<OrganizerChannelVarSampOrderBy>;
  variance?: InputMaybe<OrganizerChannelVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "organizerChannel" */
export type OrganizerChannelArrRelInsertInput = {
  data: Array<OrganizerChannelInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizerChannelOnConflict>;
};

/** aggregate avg on columns */
export type OrganizerChannelAvgFields = {
  __typename?: 'organizerChannel_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizerChannel" */
export type OrganizerChannelAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organizerChannel". All fields are combined with a logical 'AND'. */
export type OrganizerChannelBoolExp = {
  _and?: InputMaybe<Array<OrganizerChannelBoolExp>>;
  _not?: InputMaybe<OrganizerChannelBoolExp>;
  _or?: InputMaybe<Array<OrganizerChannelBoolExp>>;
  channelId?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organizerChannel" */
export enum OrganizerChannelConstraint {
  /** unique or primary key constraint on columns "channelId" */
  OrganizerChannelChannelIdKey = 'organizerChannel_channelId_key',
  /** unique or primary key constraint on columns "cuid" */
  OrganizerChannelCuidKey = 'organizerChannel_cuid_key',
  /** unique or primary key constraint on columns "id" */
  OrganizerChannelPkey = 'organizerChannel_pkey'
}

/** input type for incrementing numeric columns in table "organizerChannel" */
export type OrganizerChannelIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "organizerChannel" */
export type OrganizerChannelInsertInput = {
  channelId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrganizerChannelMaxFields = {
  __typename?: 'organizerChannel_max_fields';
  channelId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizerChannel" */
export type OrganizerChannelMaxOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizerChannelMinFields = {
  __typename?: 'organizerChannel_min_fields';
  channelId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizerChannel" */
export type OrganizerChannelMinOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organizerChannel" */
export type OrganizerChannelMutationResponse = {
  __typename?: 'organizerChannel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizerChannel>;
};

/** on_conflict condition type for table "organizerChannel" */
export type OrganizerChannelOnConflict = {
  constraint: OrganizerChannelConstraint;
  update_columns?: Array<OrganizerChannelUpdateColumn>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};

/** Ordering options when selecting data from "organizerChannel". */
export type OrganizerChannelOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organizerChannel */
export type OrganizerChannelPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "organizerChannel" */
export enum OrganizerChannelSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "organizerChannel" */
export type OrganizerChannelSetInput = {
  channelId?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OrganizerChannelStddevFields = {
  __typename?: 'organizerChannel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizerChannel" */
export type OrganizerChannelStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrganizerChannelStddevPopFields = {
  __typename?: 'organizerChannel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizerChannel" */
export type OrganizerChannelStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrganizerChannelStddevSampFields = {
  __typename?: 'organizerChannel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizerChannel" */
export type OrganizerChannelStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrganizerChannelSumFields = {
  __typename?: 'organizerChannel_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "organizerChannel" */
export type OrganizerChannelSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "organizerChannel" */
export enum OrganizerChannelUpdateColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type OrganizerChannelUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizerChannelIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizerChannelSetInput>;
  where: OrganizerChannelBoolExp;
};

/** aggregate var_pop on columns */
export type OrganizerChannelVarPopFields = {
  __typename?: 'organizerChannel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizerChannel" */
export type OrganizerChannelVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrganizerChannelVarSampFields = {
  __typename?: 'organizerChannel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizerChannel" */
export type OrganizerChannelVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizerChannelVarianceFields = {
  __typename?: 'organizerChannel_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizerChannel" */
export type OrganizerChannelVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "organizer" */
export type OrganizerConnection = {
  __typename?: 'organizerConnection';
  edges: Array<OrganizerEdge>;
  pageInfo: PageInfo;
};

export type OrganizerEdge = {
  __typename?: 'organizerEdge';
  cursor: Scalars['String'];
  node: Organizer;
};

/** An invite to an organization */
export type OrganizerInvite = Node & {
  __typename?: 'organizerInvite';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  inviteStatus: Scalars['String'];
  inviteeUserId: Scalars['String'];
  inviterUserId: Scalars['String'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userByInvitee: User;
  /** An object relationship */
  userByInviter: User;
};

/** A Relay connection object on "organizerInvite" */
export type OrganizerInviteConnection = {
  __typename?: 'organizerInviteConnection';
  edges: Array<OrganizerInviteEdge>;
  pageInfo: PageInfo;
};

export type OrganizerInviteEdge = {
  __typename?: 'organizerInviteEdge';
  cursor: Scalars['String'];
  node: OrganizerInvite;
};

/** aggregated selection of "organizerInvite" */
export type OrganizerInviteAggregate = {
  __typename?: 'organizerInvite_aggregate';
  aggregate?: Maybe<OrganizerInviteAggregateFields>;
  nodes: Array<OrganizerInvite>;
};

export type OrganizerInviteAggregateBoolExp = {
  count?: InputMaybe<OrganizerInviteAggregateBoolExpCount>;
};

export type OrganizerInviteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizerInviteBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "organizerInvite" */
export type OrganizerInviteAggregateFields = {
  __typename?: 'organizerInvite_aggregate_fields';
  avg?: Maybe<OrganizerInviteAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizerInviteMaxFields>;
  min?: Maybe<OrganizerInviteMinFields>;
  stddev?: Maybe<OrganizerInviteStddevFields>;
  stddev_pop?: Maybe<OrganizerInviteStddevPopFields>;
  stddev_samp?: Maybe<OrganizerInviteStddevSampFields>;
  sum?: Maybe<OrganizerInviteSumFields>;
  var_pop?: Maybe<OrganizerInviteVarPopFields>;
  var_samp?: Maybe<OrganizerInviteVarSampFields>;
  variance?: Maybe<OrganizerInviteVarianceFields>;
};


/** aggregate fields of "organizerInvite" */
export type OrganizerInviteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizerInvite" */
export type OrganizerInviteAggregateOrderBy = {
  avg?: InputMaybe<OrganizerInviteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizerInviteMaxOrderBy>;
  min?: InputMaybe<OrganizerInviteMinOrderBy>;
  stddev?: InputMaybe<OrganizerInviteStddevOrderBy>;
  stddev_pop?: InputMaybe<OrganizerInviteStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrganizerInviteStddevSampOrderBy>;
  sum?: InputMaybe<OrganizerInviteSumOrderBy>;
  var_pop?: InputMaybe<OrganizerInviteVarPopOrderBy>;
  var_samp?: InputMaybe<OrganizerInviteVarSampOrderBy>;
  variance?: InputMaybe<OrganizerInviteVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "organizerInvite" */
export type OrganizerInviteArrRelInsertInput = {
  data: Array<OrganizerInviteInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizerInviteOnConflict>;
};

/** aggregate avg on columns */
export type OrganizerInviteAvgFields = {
  __typename?: 'organizerInvite_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizerInvite" */
export type OrganizerInviteAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organizerInvite". All fields are combined with a logical 'AND'. */
export type OrganizerInviteBoolExp = {
  _and?: InputMaybe<Array<OrganizerInviteBoolExp>>;
  _not?: InputMaybe<OrganizerInviteBoolExp>;
  _or?: InputMaybe<Array<OrganizerInviteBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inviteStatus?: InputMaybe<StringComparisonExp>;
  inviteeUserId?: InputMaybe<StringComparisonExp>;
  inviterUserId?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userByInvitee?: InputMaybe<UserBoolExp>;
  userByInviter?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "organizerInvite" */
export enum OrganizerInviteConstraint {
  /** unique or primary key constraint on columns "cuid" */
  OrganizerInviteCuidKey = 'organizerInvite_cuid_key',
  /** unique or primary key constraint on columns "role", "inviteeUserId", "organizerCuid" */
  OrganizerInviteOrganizerCuidInviteeUserIdRoleKey = 'organizerInvite_organizerCuid_inviteeUserId_role_key',
  /** unique or primary key constraint on columns "id" */
  OrganizerInvitePkey = 'organizerInvite_pkey'
}

/** input type for incrementing numeric columns in table "organizerInvite" */
export type OrganizerInviteIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "organizerInvite" */
export type OrganizerInviteInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteStatus?: InputMaybe<Scalars['String']>;
  inviteeUserId?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userByInvitee?: InputMaybe<UserObjRelInsertInput>;
  userByInviter?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizerInviteMaxFields = {
  __typename?: 'organizerInvite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteStatus?: Maybe<Scalars['String']>;
  inviteeUserId?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organizerInvite" */
export type OrganizerInviteMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  inviteeUserId?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizerInviteMinFields = {
  __typename?: 'organizerInvite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inviteStatus?: Maybe<Scalars['String']>;
  inviteeUserId?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organizerInvite" */
export type OrganizerInviteMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  inviteeUserId?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organizerInvite" */
export type OrganizerInviteMutationResponse = {
  __typename?: 'organizerInvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizerInvite>;
};

/** on_conflict condition type for table "organizerInvite" */
export type OrganizerInviteOnConflict = {
  constraint: OrganizerInviteConstraint;
  update_columns?: Array<OrganizerInviteUpdateColumn>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};

/** Ordering options when selecting data from "organizerInvite". */
export type OrganizerInviteOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviteStatus?: InputMaybe<OrderBy>;
  inviteeUserId?: InputMaybe<OrderBy>;
  inviterUserId?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userByInvitee?: InputMaybe<UserOrderBy>;
  userByInviter?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: organizerInvite */
export type OrganizerInvitePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "organizerInvite" */
export enum OrganizerInviteSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'inviteStatus',
  /** column name */
  InviteeUserId = 'inviteeUserId',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organizerInvite" */
export type OrganizerInviteSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  inviteStatus?: InputMaybe<Scalars['String']>;
  inviteeUserId?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OrganizerInviteStddevFields = {
  __typename?: 'organizerInvite_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizerInvite" */
export type OrganizerInviteStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrganizerInviteStddevPopFields = {
  __typename?: 'organizerInvite_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizerInvite" */
export type OrganizerInviteStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrganizerInviteStddevSampFields = {
  __typename?: 'organizerInvite_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizerInvite" */
export type OrganizerInviteStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrganizerInviteSumFields = {
  __typename?: 'organizerInvite_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "organizerInvite" */
export type OrganizerInviteSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "organizerInvite" */
export enum OrganizerInviteUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  InviteStatus = 'inviteStatus',
  /** column name */
  InviteeUserId = 'inviteeUserId',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type OrganizerInviteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizerInviteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizerInviteSetInput>;
  where: OrganizerInviteBoolExp;
};

/** aggregate var_pop on columns */
export type OrganizerInviteVarPopFields = {
  __typename?: 'organizerInvite_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizerInvite" */
export type OrganizerInviteVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrganizerInviteVarSampFields = {
  __typename?: 'organizerInvite_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizerInvite" */
export type OrganizerInviteVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizerInviteVarianceFields = {
  __typename?: 'organizerInvite_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizerInvite" */
export type OrganizerInviteVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "organizer" */
export type OrganizerAggregate = {
  __typename?: 'organizer_aggregate';
  aggregate?: Maybe<OrganizerAggregateFields>;
  nodes: Array<Organizer>;
};

export type OrganizerAggregateBoolExp = {
  bool_and?: InputMaybe<OrganizerAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<OrganizerAggregateBoolExpBoolOr>;
  count?: InputMaybe<OrganizerAggregateBoolExpCount>;
};

export type OrganizerAggregateBoolExpBoolAnd = {
  arguments: OrganizerSelectColumnOrganizerAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizerAggregateBoolExpBoolOr = {
  arguments: OrganizerSelectColumnOrganizerAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "organizer" */
export type OrganizerAggregateFields = {
  __typename?: 'organizer_aggregate_fields';
  avg?: Maybe<OrganizerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizerMaxFields>;
  min?: Maybe<OrganizerMinFields>;
  stddev?: Maybe<OrganizerStddevFields>;
  stddev_pop?: Maybe<OrganizerStddevPopFields>;
  stddev_samp?: Maybe<OrganizerStddevSampFields>;
  sum?: Maybe<OrganizerSumFields>;
  var_pop?: Maybe<OrganizerVarPopFields>;
  var_samp?: Maybe<OrganizerVarSampFields>;
  variance?: Maybe<OrganizerVarianceFields>;
};


/** aggregate fields of "organizer" */
export type OrganizerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizer" */
export type OrganizerAggregateOrderBy = {
  avg?: InputMaybe<OrganizerAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizerMaxOrderBy>;
  min?: InputMaybe<OrganizerMinOrderBy>;
  stddev?: InputMaybe<OrganizerStddevOrderBy>;
  stddev_pop?: InputMaybe<OrganizerStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrganizerStddevSampOrderBy>;
  sum?: InputMaybe<OrganizerSumOrderBy>;
  var_pop?: InputMaybe<OrganizerVarPopOrderBy>;
  var_samp?: InputMaybe<OrganizerVarSampOrderBy>;
  variance?: InputMaybe<OrganizerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "organizer" */
export type OrganizerArrRelInsertInput = {
  data: Array<OrganizerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizerOnConflict>;
};

/** aggregate avg on columns */
export type OrganizerAvgFields = {
  __typename?: 'organizer_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizer" */
export type OrganizerAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organizer". All fields are combined with a logical 'AND'. */
export type OrganizerBoolExp = {
  _and?: InputMaybe<Array<OrganizerBoolExp>>;
  _not?: InputMaybe<OrganizerBoolExp>;
  _or?: InputMaybe<Array<OrganizerBoolExp>>;
  about?: InputMaybe<StringComparisonExp>;
  coaches?: InputMaybe<CoachBoolExp>;
  coaches_aggregate?: InputMaybe<CoachAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  defaultCurrencyCode?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  eventMetadata?: InputMaybe<EventMetadataBoolExp>;
  eventMetadata_aggregate?: InputMaybe<EventMetadataAggregateBoolExp>;
  events?: InputMaybe<EventBoolExp>;
  events_aggregate?: InputMaybe<EventAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  images?: InputMaybe<ImageBoolExp>;
  images_aggregate?: InputMaybe<ImageAggregateBoolExp>;
  merchandises?: InputMaybe<MerchandiseBoolExp>;
  merchandises_aggregate?: InputMaybe<MerchandiseAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  onlineCourses?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateBoolExp>;
  organizerChannels?: InputMaybe<OrganizerChannelBoolExp>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateBoolExp>;
  organizerInvites?: InputMaybe<OrganizerInviteBoolExp>;
  organizerInvites_aggregate?: InputMaybe<OrganizerInviteAggregateBoolExp>;
  series?: InputMaybe<SeriesBoolExp>;
  series_aggregate?: InputMaybe<SeriesAggregateBoolExp>;
  showOnboarding?: InputMaybe<BooleanComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sponsors?: InputMaybe<SponsorBoolExp>;
  sponsors_aggregate?: InputMaybe<SponsorAggregateBoolExp>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountBoolExp>;
  stripeConnectAccounts?: InputMaybe<StripeConnectAccountBoolExp>;
  stripeConnectAccounts_aggregate?: InputMaybe<StripeConnectAccountAggregateBoolExp>;
  stripeConnectId?: InputMaybe<StringComparisonExp>;
  tickets?: InputMaybe<TicketBoolExp>;
  tickets_aggregate?: InputMaybe<TicketAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleBoolExp>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateBoolExp>;
  venues?: InputMaybe<VenueBoolExp>;
  venues_aggregate?: InputMaybe<VenueAggregateBoolExp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organizer" */
export enum OrganizerConstraint {
  /** unique or primary key constraint on columns "id" */
  PkOrganizer = 'PK_organizer',
  /** unique or primary key constraint on columns "cuid" */
  OrganizerCuidKey = 'organizer_cuid_key',
  /** unique or primary key constraint on columns "id" */
  OrganizerPkey = 'organizer_pkey',
  /** unique or primary key constraint on columns "slug" */
  OrganizerSlugKey = 'organizer_slug_key'
}

/** input type for incrementing numeric columns in table "organizer" */
export type OrganizerIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizer" */
export type OrganizerInsertInput = {
  about?: InputMaybe<Scalars['String']>;
  coaches?: InputMaybe<CoachArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  defaultCurrencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventMetadata?: InputMaybe<EventMetadataArrRelInsertInput>;
  events?: InputMaybe<EventArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<ImageArrRelInsertInput>;
  merchandises?: InputMaybe<MerchandiseArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  onlineCourses?: InputMaybe<OnlineCourseArrRelInsertInput>;
  organizerChannels?: InputMaybe<OrganizerChannelArrRelInsertInput>;
  organizerInvites?: InputMaybe<OrganizerInviteArrRelInsertInput>;
  series?: InputMaybe<SeriesArrRelInsertInput>;
  showOnboarding?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsors?: InputMaybe<SponsorArrRelInsertInput>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountObjRelInsertInput>;
  stripeConnectAccounts?: InputMaybe<StripeConnectAccountArrRelInsertInput>;
  stripeConnectId?: InputMaybe<Scalars['String']>;
  tickets?: InputMaybe<TicketArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleArrRelInsertInput>;
  venues?: InputMaybe<VenueArrRelInsertInput>;
  website?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrganizerMaxFields = {
  __typename?: 'organizer_max_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  defaultCurrencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imagePublicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stripeConnectId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizer" */
export type OrganizerMaxOrderBy = {
  about?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultCurrencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizerMinFields = {
  __typename?: 'organizer_min_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  defaultCurrencyCode?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  imagePublicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stripeConnectId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizer" */
export type OrganizerMinOrderBy = {
  about?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultCurrencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organizer" */
export type OrganizerMutationResponse = {
  __typename?: 'organizer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizer>;
};

/** input type for inserting object relation for remote table "organizer" */
export type OrganizerObjRelInsertInput = {
  data: OrganizerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizerOnConflict>;
};

/** on_conflict condition type for table "organizer" */
export type OrganizerOnConflict = {
  constraint: OrganizerConstraint;
  update_columns?: Array<OrganizerUpdateColumn>;
  where?: InputMaybe<OrganizerBoolExp>;
};

/** Ordering options when selecting data from "organizer". */
export type OrganizerOrderBy = {
  about?: InputMaybe<OrderBy>;
  coaches_aggregate?: InputMaybe<CoachAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  defaultCurrencyCode?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventMetadata_aggregate?: InputMaybe<EventMetadataAggregateOrderBy>;
  events_aggregate?: InputMaybe<EventAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  images_aggregate?: InputMaybe<ImageAggregateOrderBy>;
  merchandises_aggregate?: InputMaybe<MerchandiseAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  onlineCourses_aggregate?: InputMaybe<OnlineCourseAggregateOrderBy>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateOrderBy>;
  organizerInvites_aggregate?: InputMaybe<OrganizerInviteAggregateOrderBy>;
  series_aggregate?: InputMaybe<SeriesAggregateOrderBy>;
  showOnboarding?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sponsors_aggregate?: InputMaybe<SponsorAggregateOrderBy>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountOrderBy>;
  stripeConnectAccounts_aggregate?: InputMaybe<StripeConnectAccountAggregateOrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  tickets_aggregate?: InputMaybe<TicketAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateOrderBy>;
  venues_aggregate?: InputMaybe<VenueAggregateOrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organizer */
export type OrganizerPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "organizer" */
export enum OrganizerSelectColumn {
  /** column name */
  About = 'about',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DefaultCurrencyCode = 'defaultCurrencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  Name = 'name',
  /** column name */
  ShowOnboarding = 'showOnboarding',
  /** column name */
  Slug = 'slug',
  /** column name */
  StripeConnectId = 'stripeConnectId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** select "organizer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organizer" */
export enum OrganizerSelectColumnOrganizerAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  ShowOnboarding = 'showOnboarding'
}

/** select "organizer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organizer" */
export enum OrganizerSelectColumnOrganizerAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  ShowOnboarding = 'showOnboarding'
}

/** input type for updating data in table "organizer" */
export type OrganizerSetInput = {
  about?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  defaultCurrencyCode?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  showOnboarding?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  stripeConnectId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OrganizerStddevFields = {
  __typename?: 'organizer_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizer" */
export type OrganizerStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrganizerStddevPopFields = {
  __typename?: 'organizer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizer" */
export type OrganizerStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrganizerStddevSampFields = {
  __typename?: 'organizer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizer" */
export type OrganizerStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrganizerSumFields = {
  __typename?: 'organizer_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organizer" */
export type OrganizerSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "organizer" */
export enum OrganizerUpdateColumn {
  /** column name */
  About = 'about',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DefaultCurrencyCode = 'defaultCurrencyCode',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  Name = 'name',
  /** column name */
  ShowOnboarding = 'showOnboarding',
  /** column name */
  Slug = 'slug',
  /** column name */
  StripeConnectId = 'stripeConnectId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

export type OrganizerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizerSetInput>;
  where: OrganizerBoolExp;
};

/** aggregate var_pop on columns */
export type OrganizerVarPopFields = {
  __typename?: 'organizer_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizer" */
export type OrganizerVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrganizerVarSampFields = {
  __typename?: 'organizer_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizer" */
export type OrganizerVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizerVarianceFields = {
  __typename?: 'organizer_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizer" */
export type OrganizerVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "AccessName" */
  AccessName_connection: AccessNameConnection;
  /** fetch data from the table: "activityType" */
  activityType_connection: ActivityTypeConnection;
  /** fetch data from the table: "amenity" */
  amenity_connection: AmenityConnection;
  /** fetch data from the table: "basicSignatureRequest" */
  basicSignatureRequest_connection: BasicSignatureRequestConnection;
  /** fetch data from the table: "categoryCourse" */
  categoryCourse_connection: CategoryCourseConnection;
  /** fetch data from the table: "category" */
  category_connection: CategoryConnection;
  /** fetch data from the table: "changeLogDate" */
  changeLogDate_connection: ChangeLogDateConnection;
  /** fetch data from the table: "changeLogItem" */
  changeLogItem_connection: ChangeLogItemConnection;
  /** fetch data from the table: "clinicCoach" */
  clinicCoach_connection: ClinicCoachConnection;
  /** fetch data from the table: "clinicDay" */
  clinicDay_connection: ClinicDayConnection;
  /** fetch data from the table: "clinic" */
  clinic_connection: ClinicConnection;
  /** fetch data from the table: "coachAvailability" */
  coachAvailability_connection: CoachAvailabilityConnection;
  /** fetch data from the table: "coachCertification" */
  coachCertification_connection: CoachCertificationConnection;
  /** fetch data from the table: "coachCustomerLessonDateLocation" */
  coachCustomerLessonDateLocation_connection: CoachCustomerLessonDateLocationConnection;
  /** fetch data from the table: "coachCustomerLessonDateVirtualLocation" */
  coachCustomerLessonDateVirtualLocation_connection: CoachCustomerLessonDateVirtualLocationConnection;
  /** fetch data from the table: "coachCustomerLessonDate" */
  coachCustomerLessonDate_connection: CoachCustomerLessonDateConnection;
  /** fetch data from the table: "coachCustomerLessonOneTimeCharge" */
  coachCustomerLessonOneTimeCharge_connection: CoachCustomerLessonOneTimeChargeConnection;
  /** fetch data from the table: "coachCustomerLessonPauseResume" */
  coachCustomerLessonPauseResume_connection: CoachCustomerLessonPauseResumeConnection;
  /** fetch data from the table: "coachCustomerLessonTransaction" */
  coachCustomerLessonTransaction_connection: CoachCustomerLessonTransactionConnection;
  /** fetch data from the table: "coachCustomerLesson" */
  coachCustomerLesson_connection: CoachCustomerLessonConnection;
  /** fetch data from the table: "coachCustomerMessageRequest" */
  coachCustomerMessageRequest_connection: CoachCustomerMessageRequestConnection;
  /** fetch data from the table: "coachCustomerUser" */
  coachCustomerUser_connection: CoachCustomerUserConnection;
  /** fetch data from the table: "coachCustomer" */
  coachCustomer_connection: CoachCustomerConnection;
  /** fetch data from the table: "coachDiscipline" */
  coachDiscipline_connection: CoachDisciplineConnection;
  /** fetch data from the table: "coachImage" */
  coachImage_connection: CoachImageConnection;
  /** fetch data from the table: "coachLessonLocation" */
  coachLessonLocation_connection: CoachLessonLocationConnection;
  /** fetch data from the table: "coachLesson" */
  coachLesson_connection: CoachLessonConnection;
  /** fetch data from the table: "coachLocation" */
  coachLocation_connection: CoachLocationConnection;
  /** fetch data from the table: "coachModerationFlag" */
  coachModerationFlag_connection: CoachModerationFlagConnection;
  /** fetch data from the table: "coachStudent" */
  coachStudent_connection: CoachStudentConnection;
  /** fetch data from the table: "coachUnavailableDate" */
  coachUnavailableDate_connection: CoachUnavailableDateConnection;
  /** fetch data from the table: "coach" */
  coach_connection: CoachConnection;
  /** fetch data from the table: "course" */
  course_connection: CourseConnection;
  /** fetch data from the table: "creatorInvite" */
  creatorInvite_connection: CreatorInviteConnection;
  /** fetch data from the table: "eventAdditionalInfo" */
  eventAdditionalInfo_connection: EventAdditionalInfoConnection;
  /** fetch data from the table: "eventAmenity" */
  eventAmenity_connection: EventAmenityConnection;
  /** fetch data from the table: "eventBasicWaiverCategory" */
  eventBasicWaiverCategory_connection: EventBasicWaiverCategoryConnection;
  /** fetch data from the table: "eventBasicWaiver" */
  eventBasicWaiver_connection: EventBasicWaiverConnection;
  /** fetch data from the table: "eventChecklistItem" */
  eventChecklistItem_connection: EventChecklistItemConnection;
  /** fetch data from the table: "eventClinicDayDuration" */
  eventClinicDayDuration_connection: EventClinicDayDurationConnection;
  /** fetch data from the table: "eventCustomQuestionCategory" */
  eventCustomQuestionCategory_connection: EventCustomQuestionCategoryConnection;
  /** fetch data from the table: "eventCustomQuestionOption" */
  eventCustomQuestionOption_connection: EventCustomQuestionOptionConnection;
  /** fetch data from the table: "eventCustomQuestion" */
  eventCustomQuestion_connection: EventCustomQuestionConnection;
  /** fetch data from the table: "eventDiscipline" */
  eventDiscipline_connection: EventDisciplineConnection;
  /** fetch data from the table: "eventImage" */
  eventImage_connection: EventImageConnection;
  /** fetch data from the table: "eventMerchandise" */
  eventMerchandise_connection: EventMerchandiseConnection;
  /** An array relationship connection */
  eventMetadata_connection: EventMetadataConnection;
  /** fetch data from the table: "eventModerationFlag" */
  eventModerationFlag_connection: EventModerationFlagConnection;
  /** fetch data from the table: "eventPitSpaceAddOn" */
  eventPitSpaceAddOn_connection: EventPitSpaceAddOnConnection;
  /** fetch data from the table: "eventPitSpace" */
  eventPitSpace_connection: EventPitSpaceConnection;
  /** fetch data from the table: "eventPricingChange" */
  eventPricingChange_connection: EventPricingChangeConnection;
  /** fetch data from the table: "eventPricingDate" */
  eventPricingDate_connection: EventPricingDateConnection;
  /** fetch data from the table: "eventPricingGroupCategory" */
  eventPricingGroupCategory_connection: EventPricingGroupCategoryConnection;
  /** fetch data from the table: "eventPricingGroup" */
  eventPricingGroup_connection: EventPricingGroupConnection;
  /** fetch data from the table: "eventPrivateLink" */
  eventPrivateLink_connection: EventPrivateLinkConnection;
  /** fetch data from the table: "eventProgram" */
  eventProgram_connection: EventProgramConnection;
  /** fetch data from the table: "eventPromoCode" */
  eventPromoCode_connection: EventPromoCodeConnection;
  /** fetch data from the table: "eventQuestionAnswer" */
  eventQuestionAnswer_connection: EventQuestionAnswerConnection;
  /** fetch data from the table: "eventQuestion" */
  eventQuestion_connection: EventQuestionConnection;
  /** fetch data from the table: "eventSanctionCategory" */
  eventSanctionCategory_connection: EventSanctionCategoryConnection;
  /** fetch data from the table: "eventSanction" */
  eventSanction_connection: EventSanctionConnection;
  /** fetch data from the table: "eventScheduleCategory" */
  eventScheduleCategory_connection: EventScheduleCategoryConnection;
  /** fetch data from the table: "eventScheduleDate" */
  eventScheduleDate_connection: EventScheduleDateConnection;
  /** fetch data from the table: "eventScheduleTypeCustom" */
  eventScheduleTypeCustom_connection: EventScheduleTypeCustomConnection;
  /** fetch data from the table: "eventScheduleTypePreset" */
  eventScheduleTypePreset_connection: EventScheduleTypePresetConnection;
  /** fetch data from the table: "eventSchedule" */
  eventSchedule_connection: EventScheduleConnection;
  /** fetch data from the table: "eventSeriesCategory" */
  eventSeriesCategory_connection: EventSeriesCategoryConnection;
  /** An array relationship connection */
  eventSeries_connection: EventSeriesConnection;
  /** fetch data from the table: "eventSponsor" */
  eventSponsor_connection: EventSponsorConnection;
  /** fetch data from the table: "eventTicket" */
  eventTicket_connection: EventTicketConnection;
  /** fetch data from the table: "eventUpdateComment" */
  eventUpdateComment_connection: EventUpdateCommentConnection;
  /** fetch data from the table: "eventUpdate" */
  eventUpdate_connection: EventUpdateConnection;
  /** fetch data from the table: "eventVenue" */
  eventVenue_connection: EventVenueConnection;
  /** fetch data from the table: "eventVibe" */
  eventVibe_connection: EventVibeConnection;
  /** fetch data from the table: "eventVirtualVenue" */
  eventVirtualVenue_connection: EventVirtualVenueConnection;
  /** fetch data from the table: "eventVolunteerJobShiftTask" */
  eventVolunteerJobShiftTask_connection: EventVolunteerJobShiftTaskConnection;
  /** fetch data from the table: "eventVolunteerJobShift" */
  eventVolunteerJobShift_connection: EventVolunteerJobShiftConnection;
  /** fetch data from the table: "eventVolunteerJobTask" */
  eventVolunteerJobTask_connection: EventVolunteerJobTaskConnection;
  /** fetch data from the table: "eventVolunteerJob" */
  eventVolunteerJob_connection: EventVolunteerJobConnection;
  /** fetch data from the table: "eventWaiverType" */
  eventWaiverType_connection: EventWaiverTypeConnection;
  /** fetch data from the table: "eventWaiver" */
  eventWaiver_connection: EventWaiverConnection;
  /** fetch data from the table: "event" */
  event_connection: EventConnection;
  /** fetch data from the table: "imageOnlineCourse" */
  imageOnlineCourse_connection: ImageOnlineCourseConnection;
  /** fetch data from the table: "imageUser" */
  imageUser_connection: ImageUserConnection;
  /** fetch data from the table: "image" */
  image_connection: ImageConnection;
  /** fetch data from the table: "lessonLocation" */
  lessonLocation_connection: LessonLocationConnection;
  /** fetch data from the table: "merchandiseDeliveryType" */
  merchandiseDeliveryType_connection: MerchandiseDeliveryTypeConnection;
  /** fetch data from the table: "merchandiseImage" */
  merchandiseImage_connection: MerchandiseImageConnection;
  /** fetch data from the table: "merchandiseVariantOptionValue" */
  merchandiseVariantOptionValue_connection: MerchandiseVariantOptionValueConnection;
  /** fetch data from the table: "merchandiseVariantOption" */
  merchandiseVariantOption_connection: MerchandiseVariantOptionConnection;
  /** fetch data from the table: "merchandiseVariant" */
  merchandiseVariant_connection: MerchandiseVariantConnection;
  /** fetch data from the table: "merchandise" */
  merchandise_connection: MerchandiseConnection;
  node?: Maybe<Node>;
  /** fetch data from the table: "onlineCourseOwner" */
  onlineCourseOwner_connection: OnlineCourseOwnerConnection;
  /** fetch data from the table: "onlineCourseRevision" */
  onlineCourseRevision_connection: OnlineCourseRevisionConnection;
  /** fetch data from the table: "onlineCourse" */
  onlineCourse_connection: OnlineCourseConnection;
  /** fetch data from the table: "organizerChannel" */
  organizerChannel_connection: OrganizerChannelConnection;
  /** fetch data from the table: "organizerInvite" */
  organizerInvite_connection: OrganizerInviteConnection;
  /** fetch data from the table: "organizer" */
  organizer_connection: OrganizerConnection;
  /** fetch data from the table: "registrationActivity" */
  registrationActivity_connection: RegistrationActivityConnection;
  /** fetch data from the table: "registrationAdditionalPayment" */
  registrationAdditionalPayment_connection: RegistrationAdditionalPaymentConnection;
  /** fetch data from the table: "registrationCategory" */
  registrationCategory_connection: RegistrationCategoryConnection;
  /** fetch data from the table: "registrationFor" */
  registrationFor_connection: RegistrationForConnection;
  /** An array relationship connection */
  registrationSeries_connection: RegistrationSeriesConnection;
  /** fetch data from the table: "registrationStatus" */
  registrationStatus_connection: RegistrationStatusConnection;
  /** fetch data from the table: "registrationTicket" */
  registrationTicket_connection: RegistrationTicketConnection;
  /** fetch data from the table: "registration" */
  registration_connection: RegistrationConnection;
  /** fetch data from the table: "reportClinicNoShow" */
  reportClinicNoShow_connection: ReportClinicNoShowConnection;
  /** fetch data from the table: "reportLessonNoShow" */
  reportLessonNoShow_connection: ReportLessonNoShowConnection;
  /** fetch data from the table: "reviewSubjectType" */
  reviewSubjectType_connection: ReviewSubjectTypeConnection;
  /** An array relationship connection */
  reviewTotals_connection: ReviewTotalsConnection;
  /** fetch data from the table: "review" */
  review_connection: ReviewConnection;
  /** fetch data from the table: "rideDay" */
  rideDay_connection: RideDayConnection;
  /** fetch data from the table: "ride" */
  ride_connection: RideConnection;
  /** fetch data from the table: "roleOrganizer" */
  roleOrganizer_connection: RoleOrganizerConnection;
  /** An array relationship connection */
  sanctionWaivers_connection: SanctionWaiversConnection;
  /** fetch data from the table: "sanction" */
  sanction_connection: SanctionConnection;
  /** fetch data from the table: "scheduleTypePreset" */
  scheduleTypePreset_connection: ScheduleTypePresetConnection;
  /** fetch data from the table: "seriesImage" */
  seriesImage_connection: SeriesImageConnection;
  /** fetch data from the table: "seriesPointsStructureCustom" */
  seriesPointsStructureCustom_connection: SeriesPointsStructureCustomConnection;
  /** fetch data from the table: "seriesPointsStructurePreset" */
  seriesPointsStructurePreset_connection: SeriesPointsStructurePresetConnection;
  /** An array relationship connection */
  series_connection: SeriesConnection;
  /** fetch data from the table: "signatureRequestSignerType" */
  signatureRequestSignerType_connection: SignatureRequestSignerTypeConnection;
  /** fetch data from the table: "signatureRequestStatus" */
  signatureRequestStatus_connection: SignatureRequestStatusConnection;
  /** fetch data from the table: "signatureRequest" */
  signatureRequest_connection: SignatureRequestConnection;
  /** fetch data from the table: "sponsorImage" */
  sponsorImage_connection: SponsorImageConnection;
  /** fetch data from the table: "sponsor" */
  sponsor_connection: SponsorConnection;
  /** fetch data from the table: "stripeConnectAccount" */
  stripeConnectAccount_connection: StripeConnectAccountConnection;
  /** fetch data from the table: "ticket" */
  ticket_connection: TicketConnection;
  /** fetch data from the table: "userAddress" */
  userAddress_connection: UserAddressConnection;
  /** fetch data from the table: "userBikeRevision" */
  userBikeRevision_connection: UserBikeRevisionConnection;
  /** fetch data from the table: "userBike" */
  userBike_connection: UserBikeConnection;
  /** fetch data from the table: "userEmergencyContact" */
  userEmergencyContact_connection: UserEmergencyContactConnection;
  /** fetch data from the table: "userImage" */
  userImage_connection: UserImageConnection;
  /** fetch data from the table: "userOnlineCourseStatus" */
  userOnlineCourseStatus_connection: UserOnlineCourseStatusConnection;
  /** fetch data from the table: "userOnlineCourse" */
  userOnlineCourse_connection: UserOnlineCourseConnection;
  /** fetch data from the table: "userOrganizerRole" */
  userOrganizerRole_connection: UserOrganizerRoleConnection;
  /** fetch data from the table: "userPersonalization" */
  userPersonalization_connection: UserPersonalizationConnection;
  /** fetch data from the table: "userRidingInterest" */
  userRidingInterest_connection: UserRidingInterestConnection;
  /** fetch data from the table: "userSanctionLicense" */
  userSanctionLicense_connection: UserSanctionLicenseConnection;
  /** fetch data from the table: "userSavedCoach" */
  userSavedCoach_connection: UserSavedCoachConnection;
  /** fetch data from the table: "userSavedEvent" */
  userSavedEvent_connection: UserSavedEventConnection;
  /** fetch data from the table: "userSavedLesson" */
  userSavedLesson_connection: UserSavedLessonConnection;
  /** fetch data from the table: "user" */
  user_connection: UserConnection;
  /** fetch data from the table: "venue" */
  venue_connection: VenueConnection;
  /** fetch data from the table: "volunteerTaskDifficulties" */
  volunteerTaskDifficulties_connection: VolunteerTaskDifficultiesConnection;
  /** fetch data from the table: "volunteerTaskPriorities" */
  volunteerTaskPriorities_connection: VolunteerTaskPrioritiesConnection;
  /** fetch data from the table: "waiverOwner" */
  waiverOwner_connection: WaiverOwnerConnection;
  /** fetch data from the table: "waiverRevision" */
  waiverRevision_connection: WaiverRevisionConnection;
  /** fetch data from the table: "waiver" */
  waiver_connection: WaiverConnection;
};


export type QueryRootAccessNameConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<AccessNameSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessNameOrderBy>>;
  where?: InputMaybe<AccessNameBoolExp>;
};


export type QueryRootActivityTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};


export type QueryRootAmenityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<AmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AmenityOrderBy>>;
  where?: InputMaybe<AmenityBoolExp>;
};


export type QueryRootBasicSignatureRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


export type QueryRootCategoryCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


export type QueryRootCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type QueryRootChangeLogDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ChangeLogDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogDateOrderBy>>;
  where?: InputMaybe<ChangeLogDateBoolExp>;
};


export type QueryRootChangeLogItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogItemOrderBy>>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};


export type QueryRootClinicCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


export type QueryRootClinicDayConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


export type QueryRootClinicConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicOrderBy>>;
  where?: InputMaybe<ClinicBoolExp>;
};


export type QueryRootCoachAvailabilityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachAvailabilityOrderBy>>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};


export type QueryRootCoachCertificationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCertificationOrderBy>>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};


export type QueryRootCoachCustomerLessonDateLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};


export type QueryRootCoachCustomerLessonDateVirtualLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};


export type QueryRootCoachCustomerLessonDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};


export type QueryRootCoachCustomerLessonOneTimeChargeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};


export type QueryRootCoachCustomerLessonPauseResumeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonPauseResumeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};


export type QueryRootCoachCustomerLessonTransactionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


export type QueryRootCoachCustomerLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


export type QueryRootCoachCustomerMessageRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


export type QueryRootCoachCustomerUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


export type QueryRootCoachCustomerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


export type QueryRootCoachDisciplineConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachDisciplineOrderBy>>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};


export type QueryRootCoachImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


export type QueryRootCoachLessonLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


export type QueryRootCoachLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonOrderBy>>;
  where?: InputMaybe<CoachLessonBoolExp>;
};


export type QueryRootCoachLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLocationOrderBy>>;
  where?: InputMaybe<CoachLocationBoolExp>;
};


export type QueryRootCoachModerationFlagConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


export type QueryRootCoachStudentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachStudentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachStudentOrderBy>>;
  where?: InputMaybe<CoachStudentBoolExp>;
};


export type QueryRootCoachUnavailableDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachUnavailableDateOrderBy>>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};


export type QueryRootCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


export type QueryRootCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


export type QueryRootCreatorInviteConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


export type QueryRootEventAdditionalInfoConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAdditionalInfoOrderBy>>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};


export type QueryRootEventAmenityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


export type QueryRootEventBasicWaiverCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


export type QueryRootEventBasicWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverOrderBy>>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};


export type QueryRootEventChecklistItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventChecklistItemOrderBy>>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};


export type QueryRootEventClinicDayDurationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventClinicDayDurationOrderBy>>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};


export type QueryRootEventCustomQuestionCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


export type QueryRootEventCustomQuestionOptionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOptionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};


export type QueryRootEventCustomQuestionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};


export type QueryRootEventDisciplineConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDisciplineOrderBy>>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};


export type QueryRootEventImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


export type QueryRootEventMerchandiseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


export type QueryRootEventMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMetadataSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMetadataOrderBy>>;
  where?: InputMaybe<EventMetadataBoolExp>;
};


export type QueryRootEventModerationFlagConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


export type QueryRootEventPitSpaceAddOnConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceAddOnOrderBy>>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};


export type QueryRootEventPitSpaceConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


export type QueryRootEventPricingChangeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


export type QueryRootEventPricingDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingDateOrderBy>>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};


export type QueryRootEventPricingGroupCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


export type QueryRootEventPricingGroupConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupOrderBy>>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};


export type QueryRootEventPrivateLinkConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


export type QueryRootEventProgramConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventProgramSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventProgramOrderBy>>;
  where?: InputMaybe<EventProgramBoolExp>;
};


export type QueryRootEventPromoCodeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPromoCodeOrderBy>>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};


export type QueryRootEventQuestionAnswerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


export type QueryRootEventQuestionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


export type QueryRootEventSanctionCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


export type QueryRootEventSanctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


export type QueryRootEventScheduleCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


export type QueryRootEventScheduleDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleDateOrderBy>>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};


export type QueryRootEventScheduleTypeCustomConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypeCustomOrderBy>>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};


export type QueryRootEventScheduleTypePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


export type QueryRootEventScheduleConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


export type QueryRootEventSeriesCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


export type QueryRootEventSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


export type QueryRootEventSponsorConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


export type QueryRootEventTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


export type QueryRootEventUpdateCommentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


export type QueryRootEventUpdateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


export type QueryRootEventVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


export type QueryRootEventVibeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVibeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVibeOrderBy>>;
  where?: InputMaybe<EventVibeBoolExp>;
};


export type QueryRootEventVirtualVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVirtualVenueOrderBy>>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};


export type QueryRootEventVolunteerJobShiftTaskConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


export type QueryRootEventVolunteerJobShiftConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


export type QueryRootEventVolunteerJobTaskConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


export type QueryRootEventVolunteerJobConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobOrderBy>>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};


export type QueryRootEventWaiverTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverTypeOrderBy>>;
  where?: InputMaybe<EventWaiverTypeBoolExp>;
};


export type QueryRootEventWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


export type QueryRootEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


export type QueryRootImageOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOnlineCourseOrderBy>>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};


export type QueryRootImageUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageUserOrderBy>>;
  where?: InputMaybe<ImageUserBoolExp>;
};


export type QueryRootImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type QueryRootLessonLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<LessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LessonLocationOrderBy>>;
  where?: InputMaybe<LessonLocationBoolExp>;
};


export type QueryRootMerchandiseDeliveryTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseDeliveryTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseDeliveryTypeOrderBy>>;
  where?: InputMaybe<MerchandiseDeliveryTypeBoolExp>;
};


export type QueryRootMerchandiseImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


export type QueryRootMerchandiseVariantOptionValueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionValueOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};


export type QueryRootMerchandiseVariantOptionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};


export type QueryRootMerchandiseVariantConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


export type QueryRootMerchandiseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


export type QueryRootNodeArgs = {
  id: Scalars['ID'];
};


export type QueryRootOnlineCourseOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseOwnerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOwnerOrderBy>>;
  where?: InputMaybe<OnlineCourseOwnerBoolExp>;
};


export type QueryRootOnlineCourseRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseRevisionOrderBy>>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};


export type QueryRootOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


export type QueryRootOrganizerChannelConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


export type QueryRootOrganizerInviteConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


export type QueryRootOrganizerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


export type QueryRootRegistrationActivityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationActivitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationActivityOrderBy>>;
  where?: InputMaybe<RegistrationActivityBoolExp>;
};


export type QueryRootRegistrationAdditionalPaymentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


export type QueryRootRegistrationCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


export type QueryRootRegistrationForConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationForSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationForOrderBy>>;
  where?: InputMaybe<RegistrationForBoolExp>;
};


export type QueryRootRegistrationSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


export type QueryRootRegistrationStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationStatusOrderBy>>;
  where?: InputMaybe<RegistrationStatusBoolExp>;
};


export type QueryRootRegistrationTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


export type QueryRootRegistrationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


export type QueryRootReportClinicNoShowConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


export type QueryRootReportLessonNoShowConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


export type QueryRootReviewSubjectTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSubjectTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewSubjectTypeOrderBy>>;
  where?: InputMaybe<ReviewSubjectTypeBoolExp>;
};


export type QueryRootReviewTotalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewTotalsOrderBy>>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};


export type QueryRootReviewConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


export type QueryRootRideDayConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideDayOrderBy>>;
  where?: InputMaybe<RideDayBoolExp>;
};


export type QueryRootRideConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideOrderBy>>;
  where?: InputMaybe<RideBoolExp>;
};


export type QueryRootRoleOrganizerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RoleOrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrganizerOrderBy>>;
  where?: InputMaybe<RoleOrganizerBoolExp>;
};


export type QueryRootSanctionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


export type QueryRootSanctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionOrderBy>>;
  where?: InputMaybe<SanctionBoolExp>;
};


export type QueryRootScheduleTypePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduleTypePresetOrderBy>>;
  where?: InputMaybe<ScheduleTypePresetBoolExp>;
};


export type QueryRootSeriesImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


export type QueryRootSeriesPointsStructureCustomConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructureCustomOrderBy>>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};


export type QueryRootSeriesPointsStructurePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructurePresetOrderBy>>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};


export type QueryRootSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesOrderBy>>;
  where?: InputMaybe<SeriesBoolExp>;
};


export type QueryRootSignatureRequestSignerTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSignerTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestSignerTypeOrderBy>>;
  where?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
};


export type QueryRootSignatureRequestStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestStatusOrderBy>>;
  where?: InputMaybe<SignatureRequestStatusBoolExp>;
};


export type QueryRootSignatureRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


export type QueryRootSponsorImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


export type QueryRootSponsorConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorOrderBy>>;
  where?: InputMaybe<SponsorBoolExp>;
};


export type QueryRootStripeConnectAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


export type QueryRootTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<TicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TicketOrderBy>>;
  where?: InputMaybe<TicketBoolExp>;
};


export type QueryRootUserAddressConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserAddressSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddressOrderBy>>;
  where?: InputMaybe<UserAddressBoolExp>;
};


export type QueryRootUserBikeRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeRevisionOrderBy>>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};


export type QueryRootUserBikeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


export type QueryRootUserEmergencyContactConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserEmergencyContactOrderBy>>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};


export type QueryRootUserImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


export type QueryRootUserOnlineCourseStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseStatusOrderBy>>;
  where?: InputMaybe<UserOnlineCourseStatusBoolExp>;
};


export type QueryRootUserOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


export type QueryRootUserOrganizerRoleConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


export type QueryRootUserPersonalizationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPersonalizationOrderBy>>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};


export type QueryRootUserRidingInterestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserRidingInterestOrderBy>>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};


export type QueryRootUserSanctionLicenseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


export type QueryRootUserSavedCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


export type QueryRootUserSavedEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


export type QueryRootUserSavedLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


export type QueryRootUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VenueOrderBy>>;
  where?: InputMaybe<VenueBoolExp>;
};


export type QueryRootVolunteerTaskDifficultiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VolunteerTaskDifficultiesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VolunteerTaskDifficultiesOrderBy>>;
  where?: InputMaybe<VolunteerTaskDifficultiesBoolExp>;
};


export type QueryRootVolunteerTaskPrioritiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VolunteerTaskPrioritiesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VolunteerTaskPrioritiesOrderBy>>;
  where?: InputMaybe<VolunteerTaskPrioritiesBoolExp>;
};


export type QueryRootWaiverOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverOwnerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOwnerOrderBy>>;
  where?: InputMaybe<WaiverOwnerBoolExp>;
};


export type QueryRootWaiverRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


export type QueryRootWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};

/** columns and relationships of "registration" */
export type Registration = Node & {
  __typename?: 'registration';
  /** An object relationship */
  activity?: Maybe<RegistrationActivity>;
  address?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  /** An array relationship */
  basicSignatureRequests: Array<BasicSignatureRequest>;
  /** An aggregate relationship */
  basicSignatureRequests_aggregate: BasicSignatureRequestAggregate;
  /** An array relationship connection */
  basicSignatureRequests_connection: BasicSignatureRequestConnection;
  /** An object relationship */
  clinic?: Maybe<Clinic>;
  clinicCuid?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactRelationship?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid: Scalars['String'];
  customQuestions?: Maybe<Scalars['jsonb']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  for: RegistrationForEnum;
  id: Scalars['ID'];
  isCheckedIn: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  payingUser?: Maybe<User>;
  payingUserId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['jsonb']>;
  referenceNo: Scalars['String'];
  /** An array relationship */
  registrationAdditionalPayments: Array<RegistrationAdditionalPayment>;
  /** An aggregate relationship */
  registrationAdditionalPayments_aggregate: RegistrationAdditionalPaymentAggregate;
  /** An array relationship connection */
  registrationAdditionalPayments_connection: RegistrationAdditionalPaymentConnection;
  /** An array relationship */
  registrationCategories: Array<RegistrationCategory>;
  /** An aggregate relationship */
  registrationCategories_aggregate: RegistrationCategoryAggregate;
  /** An array relationship connection */
  registrationCategories_connection: RegistrationCategoryConnection;
  /** An object relationship */
  registrationFor: RegistrationFor;
  /** An array relationship */
  registrationSeries: Array<RegistrationSeries>;
  /** An aggregate relationship */
  registrationSeries_aggregate: RegistrationSeriesAggregate;
  /** An array relationship connection */
  registrationSeries_connection: RegistrationSeriesConnection;
  /** An object relationship */
  registrationStatus: RegistrationStatus;
  /** An array relationship */
  registrationTickets: Array<RegistrationTicket>;
  /** An aggregate relationship */
  registrationTickets_aggregate: RegistrationTicketAggregate;
  /** An array relationship connection */
  registrationTickets_connection: RegistrationTicketConnection;
  /** An object relationship */
  ride?: Maybe<Ride>;
  rideCuid?: Maybe<Scalars['String']>;
  sanctionLicenses?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  signatureRequests: Array<SignatureRequest>;
  /** An aggregate relationship */
  signatureRequests_aggregate: SignatureRequestAggregate;
  /** An array relationship connection */
  signatureRequests_connection: SignatureRequestConnection;
  sponsors?: Maybe<Scalars['String']>;
  status: RegistrationStatusEnum;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};


/** columns and relationships of "registration" */
export type RegistrationBasicSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationBasicSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationBasicSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationContextArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "registration" */
export type RegistrationCustomQuestionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "registration" */
export type RegistrationPromoCodeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationAdditionalPaymentsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationAdditionalPaymentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationAdditionalPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationCategoriesArgs = {
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationSeriesArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationSeriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationTicketsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationTicketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationRegistrationTicketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationSanctionLicensesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "registration" */
export type RegistrationSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "registration" */
export type RegistrationSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};

/** columns and relationships of "registrationActivity" */
export type RegistrationActivity = Node & {
  __typename?: 'registrationActivity';
  created_at: Scalars['timestamptz'];
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  log: Scalars['jsonb'];
  notes?: Maybe<Scalars['String']>;
  referenceNo: Scalars['String'];
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "registrationActivity" */
export type RegistrationActivityLogArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "registrationActivity" */
export type RegistrationActivityRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationActivity" */
export type RegistrationActivityRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationActivity" */
export type RegistrationActivityRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};

/** A Relay connection object on "registrationActivity" */
export type RegistrationActivityConnection = {
  __typename?: 'registrationActivityConnection';
  edges: Array<RegistrationActivityEdge>;
  pageInfo: PageInfo;
};

export type RegistrationActivityEdge = {
  __typename?: 'registrationActivityEdge';
  cursor: Scalars['String'];
  node: RegistrationActivity;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RegistrationActivityAppendInput = {
  log?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "registrationActivity". All fields are combined with a logical 'AND'. */
export type RegistrationActivityBoolExp = {
  _and?: InputMaybe<Array<RegistrationActivityBoolExp>>;
  _not?: InputMaybe<RegistrationActivityBoolExp>;
  _or?: InputMaybe<Array<RegistrationActivityBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  log?: InputMaybe<JsonbComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  referenceNo?: InputMaybe<StringComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "registrationActivity" */
export enum RegistrationActivityConstraint {
  /** unique or primary key constraint on columns "eventCuid", "referenceNo" */
  RegistrationActivityEventCuidReferenceNoKey = 'registrationActivity_eventCuid_referenceNo_key',
  /** unique or primary key constraint on columns "id" */
  RegistrationActivityPkey = 'registrationActivity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RegistrationActivityDeleteAtPathInput = {
  log?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RegistrationActivityDeleteElemInput = {
  log?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RegistrationActivityDeleteKeyInput = {
  log?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "registrationActivity" */
export type RegistrationActivityIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "registrationActivity" */
export type RegistrationActivityInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  log?: InputMaybe<Scalars['jsonb']>;
  notes?: InputMaybe<Scalars['String']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "registrationActivity" */
export type RegistrationActivityMutationResponse = {
  __typename?: 'registrationActivity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationActivity>;
};

/** input type for inserting object relation for remote table "registrationActivity" */
export type RegistrationActivityObjRelInsertInput = {
  data: RegistrationActivityInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationActivityOnConflict>;
};

/** on_conflict condition type for table "registrationActivity" */
export type RegistrationActivityOnConflict = {
  constraint: RegistrationActivityConstraint;
  update_columns?: Array<RegistrationActivityUpdateColumn>;
  where?: InputMaybe<RegistrationActivityBoolExp>;
};

/** Ordering options when selecting data from "registrationActivity". */
export type RegistrationActivityOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  log?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationActivity */
export type RegistrationActivityPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RegistrationActivityPrependInput = {
  log?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "registrationActivity" */
export enum RegistrationActivitySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "registrationActivity" */
export type RegistrationActivitySetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  log?: InputMaybe<Scalars['jsonb']>;
  notes?: InputMaybe<Scalars['String']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "registrationActivity" */
export enum RegistrationActivityUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RegistrationActivityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RegistrationActivityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RegistrationActivityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RegistrationActivityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RegistrationActivityDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationActivityIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RegistrationActivityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationActivitySetInput>;
  where: RegistrationActivityBoolExp;
};

/** columns and relationships of "registrationAdditionalPayment" */
export type RegistrationAdditionalPayment = Node & {
  __typename?: 'registrationAdditionalPayment';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  paymentType: Scalars['String'];
  /** An object relationship */
  registration: Registration;
  registrationCuid: Scalars['String'];
  /** An object relationship */
  registrationStatus: RegistrationStatus;
  status: RegistrationStatusEnum;
  stripeCheckoutId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  successToken: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentConnection = {
  __typename?: 'registrationAdditionalPaymentConnection';
  edges: Array<RegistrationAdditionalPaymentEdge>;
  pageInfo: PageInfo;
};

export type RegistrationAdditionalPaymentEdge = {
  __typename?: 'registrationAdditionalPaymentEdge';
  cursor: Scalars['String'];
  node: RegistrationAdditionalPayment;
};

/** aggregated selection of "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentAggregate = {
  __typename?: 'registrationAdditionalPayment_aggregate';
  aggregate?: Maybe<RegistrationAdditionalPaymentAggregateFields>;
  nodes: Array<RegistrationAdditionalPayment>;
};

export type RegistrationAdditionalPaymentAggregateBoolExp = {
  count?: InputMaybe<RegistrationAdditionalPaymentAggregateBoolExpCount>;
};

export type RegistrationAdditionalPaymentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentAggregateFields = {
  __typename?: 'registrationAdditionalPayment_aggregate_fields';
  avg?: Maybe<RegistrationAdditionalPaymentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegistrationAdditionalPaymentMaxFields>;
  min?: Maybe<RegistrationAdditionalPaymentMinFields>;
  stddev?: Maybe<RegistrationAdditionalPaymentStddevFields>;
  stddev_pop?: Maybe<RegistrationAdditionalPaymentStddevPopFields>;
  stddev_samp?: Maybe<RegistrationAdditionalPaymentStddevSampFields>;
  sum?: Maybe<RegistrationAdditionalPaymentSumFields>;
  var_pop?: Maybe<RegistrationAdditionalPaymentVarPopFields>;
  var_samp?: Maybe<RegistrationAdditionalPaymentVarSampFields>;
  variance?: Maybe<RegistrationAdditionalPaymentVarianceFields>;
};


/** aggregate fields of "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentAggregateOrderBy = {
  avg?: InputMaybe<RegistrationAdditionalPaymentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RegistrationAdditionalPaymentMaxOrderBy>;
  min?: InputMaybe<RegistrationAdditionalPaymentMinOrderBy>;
  stddev?: InputMaybe<RegistrationAdditionalPaymentStddevOrderBy>;
  stddev_pop?: InputMaybe<RegistrationAdditionalPaymentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RegistrationAdditionalPaymentStddevSampOrderBy>;
  sum?: InputMaybe<RegistrationAdditionalPaymentSumOrderBy>;
  var_pop?: InputMaybe<RegistrationAdditionalPaymentVarPopOrderBy>;
  var_samp?: InputMaybe<RegistrationAdditionalPaymentVarSampOrderBy>;
  variance?: InputMaybe<RegistrationAdditionalPaymentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentArrRelInsertInput = {
  data: Array<RegistrationAdditionalPaymentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationAdditionalPaymentOnConflict>;
};

/** aggregate avg on columns */
export type RegistrationAdditionalPaymentAvgFields = {
  __typename?: 'registrationAdditionalPayment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "registrationAdditionalPayment". All fields are combined with a logical 'AND'. */
export type RegistrationAdditionalPaymentBoolExp = {
  _and?: InputMaybe<Array<RegistrationAdditionalPaymentBoolExp>>;
  _not?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
  _or?: InputMaybe<Array<RegistrationAdditionalPaymentBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  paymentType?: InputMaybe<StringComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
  registrationStatus?: InputMaybe<RegistrationStatusBoolExp>;
  status?: InputMaybe<RegistrationStatusEnumComparisonExp>;
  stripeCheckoutId?: InputMaybe<StringComparisonExp>;
  stripePaymentIntentId?: InputMaybe<StringComparisonExp>;
  successToken?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "registrationAdditionalPayment" */
export enum RegistrationAdditionalPaymentConstraint {
  /** unique or primary key constraint on columns "cuid" */
  RegistrationAdditionalPaymentCuidKey = 'registrationAdditionalPayment_cuid_key',
  /** unique or primary key constraint on columns "id" */
  RegistrationAdditionalPaymentPkey = 'registrationAdditionalPayment_pkey'
}

/** input type for incrementing numeric columns in table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  registrationStatus?: InputMaybe<RegistrationStatusObjRelInsertInput>;
  status?: InputMaybe<RegistrationStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RegistrationAdditionalPaymentMaxFields = {
  __typename?: 'registrationAdditionalPayment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<Scalars['String']>;
  registrationCuid?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RegistrationAdditionalPaymentMinFields = {
  __typename?: 'registrationAdditionalPayment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<Scalars['String']>;
  registrationCuid?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentMutationResponse = {
  __typename?: 'registrationAdditionalPayment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationAdditionalPayment>;
};

/** on_conflict condition type for table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentOnConflict = {
  constraint: RegistrationAdditionalPaymentConstraint;
  update_columns?: Array<RegistrationAdditionalPaymentUpdateColumn>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};

/** Ordering options when selecting data from "registrationAdditionalPayment". */
export type RegistrationAdditionalPaymentOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<RegistrationStatusOrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationAdditionalPayment */
export type RegistrationAdditionalPaymentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "registrationAdditionalPayment" */
export enum RegistrationAdditionalPaymentSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['String']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RegistrationStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RegistrationAdditionalPaymentStddevFields = {
  __typename?: 'registrationAdditionalPayment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RegistrationAdditionalPaymentStddevPopFields = {
  __typename?: 'registrationAdditionalPayment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RegistrationAdditionalPaymentStddevSampFields = {
  __typename?: 'registrationAdditionalPayment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RegistrationAdditionalPaymentSumFields = {
  __typename?: 'registrationAdditionalPayment_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "registrationAdditionalPayment" */
export enum RegistrationAdditionalPaymentUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RegistrationAdditionalPaymentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationAdditionalPaymentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationAdditionalPaymentSetInput>;
  where: RegistrationAdditionalPaymentBoolExp;
};

/** aggregate var_pop on columns */
export type RegistrationAdditionalPaymentVarPopFields = {
  __typename?: 'registrationAdditionalPayment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RegistrationAdditionalPaymentVarSampFields = {
  __typename?: 'registrationAdditionalPayment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RegistrationAdditionalPaymentVarianceFields = {
  __typename?: 'registrationAdditionalPayment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registrationAdditionalPayment" */
export type RegistrationAdditionalPaymentVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "registrationCategory" */
export type RegistrationCategory = Node & {
  __typename?: 'registrationCategory';
  /** An object relationship */
  category: Category;
  categoryCuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  registration: Registration;
  registrationCuid: Scalars['String'];
};

/** A Relay connection object on "registrationCategory" */
export type RegistrationCategoryConnection = {
  __typename?: 'registrationCategoryConnection';
  edges: Array<RegistrationCategoryEdge>;
  pageInfo: PageInfo;
};

export type RegistrationCategoryEdge = {
  __typename?: 'registrationCategoryEdge';
  cursor: Scalars['String'];
  node: RegistrationCategory;
};

/** aggregated selection of "registrationCategory" */
export type RegistrationCategoryAggregate = {
  __typename?: 'registrationCategory_aggregate';
  aggregate?: Maybe<RegistrationCategoryAggregateFields>;
  nodes: Array<RegistrationCategory>;
};

export type RegistrationCategoryAggregateBoolExp = {
  count?: InputMaybe<RegistrationCategoryAggregateBoolExpCount>;
};

export type RegistrationCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "registrationCategory" */
export type RegistrationCategoryAggregateFields = {
  __typename?: 'registrationCategory_aggregate_fields';
  avg?: Maybe<RegistrationCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegistrationCategoryMaxFields>;
  min?: Maybe<RegistrationCategoryMinFields>;
  stddev?: Maybe<RegistrationCategoryStddevFields>;
  stddev_pop?: Maybe<RegistrationCategoryStddevPopFields>;
  stddev_samp?: Maybe<RegistrationCategoryStddevSampFields>;
  sum?: Maybe<RegistrationCategorySumFields>;
  var_pop?: Maybe<RegistrationCategoryVarPopFields>;
  var_samp?: Maybe<RegistrationCategoryVarSampFields>;
  variance?: Maybe<RegistrationCategoryVarianceFields>;
};


/** aggregate fields of "registrationCategory" */
export type RegistrationCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registrationCategory" */
export type RegistrationCategoryAggregateOrderBy = {
  avg?: InputMaybe<RegistrationCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RegistrationCategoryMaxOrderBy>;
  min?: InputMaybe<RegistrationCategoryMinOrderBy>;
  stddev?: InputMaybe<RegistrationCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<RegistrationCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RegistrationCategoryStddevSampOrderBy>;
  sum?: InputMaybe<RegistrationCategorySumOrderBy>;
  var_pop?: InputMaybe<RegistrationCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<RegistrationCategoryVarSampOrderBy>;
  variance?: InputMaybe<RegistrationCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "registrationCategory" */
export type RegistrationCategoryArrRelInsertInput = {
  data: Array<RegistrationCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationCategoryOnConflict>;
};

/** aggregate avg on columns */
export type RegistrationCategoryAvgFields = {
  __typename?: 'registrationCategory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registrationCategory" */
export type RegistrationCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "registrationCategory". All fields are combined with a logical 'AND'. */
export type RegistrationCategoryBoolExp = {
  _and?: InputMaybe<Array<RegistrationCategoryBoolExp>>;
  _not?: InputMaybe<RegistrationCategoryBoolExp>;
  _or?: InputMaybe<Array<RegistrationCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "registrationCategory" */
export enum RegistrationCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationCategoryPkey = 'registrationCategory_pkey',
  /** unique or primary key constraint on columns "categoryCuid", "registrationCuid" */
  RegistrationCategoryRegistrationCuidCategoryCuidKey = 'registrationCategory_registrationCuid_categoryCuid_key'
}

/** input type for incrementing numeric columns in table "registrationCategory" */
export type RegistrationCategoryIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "registrationCategory" */
export type RegistrationCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RegistrationCategoryMaxFields = {
  __typename?: 'registrationCategory_max_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registrationCategory" */
export type RegistrationCategoryMaxOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RegistrationCategoryMinFields = {
  __typename?: 'registrationCategory_min_fields';
  categoryCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registrationCategory" */
export type RegistrationCategoryMinOrderBy = {
  categoryCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "registrationCategory" */
export type RegistrationCategoryMutationResponse = {
  __typename?: 'registrationCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationCategory>;
};

/** on_conflict condition type for table "registrationCategory" */
export type RegistrationCategoryOnConflict = {
  constraint: RegistrationCategoryConstraint;
  update_columns?: Array<RegistrationCategoryUpdateColumn>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};

/** Ordering options when selecting data from "registrationCategory". */
export type RegistrationCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationCategory */
export type RegistrationCategoryPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "registrationCategory" */
export enum RegistrationCategorySelectColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

/** input type for updating data in table "registrationCategory" */
export type RegistrationCategorySetInput = {
  categoryCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RegistrationCategoryStddevFields = {
  __typename?: 'registrationCategory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registrationCategory" */
export type RegistrationCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RegistrationCategoryStddevPopFields = {
  __typename?: 'registrationCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registrationCategory" */
export type RegistrationCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RegistrationCategoryStddevSampFields = {
  __typename?: 'registrationCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registrationCategory" */
export type RegistrationCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RegistrationCategorySumFields = {
  __typename?: 'registrationCategory_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "registrationCategory" */
export type RegistrationCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "registrationCategory" */
export enum RegistrationCategoryUpdateColumn {
  /** column name */
  CategoryCuid = 'categoryCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

export type RegistrationCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationCategorySetInput>;
  where: RegistrationCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type RegistrationCategoryVarPopFields = {
  __typename?: 'registrationCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registrationCategory" */
export type RegistrationCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RegistrationCategoryVarSampFields = {
  __typename?: 'registrationCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registrationCategory" */
export type RegistrationCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RegistrationCategoryVarianceFields = {
  __typename?: 'registrationCategory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registrationCategory" */
export type RegistrationCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "registration" */
export type RegistrationConnection = {
  __typename?: 'registrationConnection';
  edges: Array<RegistrationEdge>;
  pageInfo: PageInfo;
};

export type RegistrationEdge = {
  __typename?: 'registrationEdge';
  cursor: Scalars['String'];
  node: Registration;
};

/** columns and relationships of "registrationFor" */
export type RegistrationFor = Node & {
  __typename?: 'registrationFor';
  for: Scalars['String'];
  id: Scalars['ID'];
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
};


/** columns and relationships of "registrationFor" */
export type RegistrationForRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationFor" */
export type RegistrationForRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationFor" */
export type RegistrationForRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};

/** A Relay connection object on "registrationFor" */
export type RegistrationForConnection = {
  __typename?: 'registrationForConnection';
  edges: Array<RegistrationForEdge>;
  pageInfo: PageInfo;
};

export type RegistrationForEdge = {
  __typename?: 'registrationForEdge';
  cursor: Scalars['String'];
  node: RegistrationFor;
};

/** Boolean expression to filter rows from the table "registrationFor". All fields are combined with a logical 'AND'. */
export type RegistrationForBoolExp = {
  _and?: InputMaybe<Array<RegistrationForBoolExp>>;
  _not?: InputMaybe<RegistrationForBoolExp>;
  _or?: InputMaybe<Array<RegistrationForBoolExp>>;
  for?: InputMaybe<StringComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
};

/** unique or primary key constraints on table "registrationFor" */
export enum RegistrationForConstraint {
  /** unique or primary key constraint on columns "for" */
  RegistrationForPkey = 'registrationFor_pkey'
}

export enum RegistrationForEnum {
  Minor = 'minor',
  OtherUser = 'other_user',
  Self = 'self'
}

/** Boolean expression to compare columns of type "registrationFor_enum". All fields are combined with logical 'AND'. */
export type RegistrationForEnumComparisonExp = {
  _eq?: InputMaybe<RegistrationForEnum>;
  _in?: InputMaybe<Array<RegistrationForEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RegistrationForEnum>;
  _nin?: InputMaybe<Array<RegistrationForEnum>>;
};

/** input type for inserting data into table "registrationFor" */
export type RegistrationForInsertInput = {
  for?: InputMaybe<Scalars['String']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
};

/** response of any mutation on the table "registrationFor" */
export type RegistrationForMutationResponse = {
  __typename?: 'registrationFor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationFor>;
};

/** input type for inserting object relation for remote table "registrationFor" */
export type RegistrationForObjRelInsertInput = {
  data: RegistrationForInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationForOnConflict>;
};

/** on_conflict condition type for table "registrationFor" */
export type RegistrationForOnConflict = {
  constraint: RegistrationForConstraint;
  update_columns?: Array<RegistrationForUpdateColumn>;
  where?: InputMaybe<RegistrationForBoolExp>;
};

/** Ordering options when selecting data from "registrationFor". */
export type RegistrationForOrderBy = {
  for?: InputMaybe<OrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
};

/** primary key columns input for table: registrationFor */
export type RegistrationForPkColumnsInput = {
  for: Scalars['String'];
};

/** select columns of table "registrationFor" */
export enum RegistrationForSelectColumn {
  /** column name */
  For = 'for'
}

/** input type for updating data in table "registrationFor" */
export type RegistrationForSetInput = {
  for?: InputMaybe<Scalars['String']>;
};

/** update columns of table "registrationFor" */
export enum RegistrationForUpdateColumn {
  /** column name */
  For = 'for'
}

export type RegistrationForUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationForSetInput>;
  where: RegistrationForBoolExp;
};

/** What series did this registrant sign up for */
export type RegistrationSeries = Node & {
  __typename?: 'registrationSeries';
  /** An object relationship */
  eventSeries?: Maybe<EventSeries>;
  eventSeriesCuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  registration: Registration;
  registrationCuid: Scalars['String'];
};

/** A Relay connection object on "registrationSeries" */
export type RegistrationSeriesConnection = {
  __typename?: 'registrationSeriesConnection';
  edges: Array<RegistrationSeriesEdge>;
  pageInfo: PageInfo;
};

export type RegistrationSeriesEdge = {
  __typename?: 'registrationSeriesEdge';
  cursor: Scalars['String'];
  node: RegistrationSeries;
};

/** aggregated selection of "registrationSeries" */
export type RegistrationSeriesAggregate = {
  __typename?: 'registrationSeries_aggregate';
  aggregate?: Maybe<RegistrationSeriesAggregateFields>;
  nodes: Array<RegistrationSeries>;
};

export type RegistrationSeriesAggregateBoolExp = {
  count?: InputMaybe<RegistrationSeriesAggregateBoolExpCount>;
};

export type RegistrationSeriesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationSeriesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "registrationSeries" */
export type RegistrationSeriesAggregateFields = {
  __typename?: 'registrationSeries_aggregate_fields';
  avg?: Maybe<RegistrationSeriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegistrationSeriesMaxFields>;
  min?: Maybe<RegistrationSeriesMinFields>;
  stddev?: Maybe<RegistrationSeriesStddevFields>;
  stddev_pop?: Maybe<RegistrationSeriesStddevPopFields>;
  stddev_samp?: Maybe<RegistrationSeriesStddevSampFields>;
  sum?: Maybe<RegistrationSeriesSumFields>;
  var_pop?: Maybe<RegistrationSeriesVarPopFields>;
  var_samp?: Maybe<RegistrationSeriesVarSampFields>;
  variance?: Maybe<RegistrationSeriesVarianceFields>;
};


/** aggregate fields of "registrationSeries" */
export type RegistrationSeriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registrationSeries" */
export type RegistrationSeriesAggregateOrderBy = {
  avg?: InputMaybe<RegistrationSeriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RegistrationSeriesMaxOrderBy>;
  min?: InputMaybe<RegistrationSeriesMinOrderBy>;
  stddev?: InputMaybe<RegistrationSeriesStddevOrderBy>;
  stddev_pop?: InputMaybe<RegistrationSeriesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RegistrationSeriesStddevSampOrderBy>;
  sum?: InputMaybe<RegistrationSeriesSumOrderBy>;
  var_pop?: InputMaybe<RegistrationSeriesVarPopOrderBy>;
  var_samp?: InputMaybe<RegistrationSeriesVarSampOrderBy>;
  variance?: InputMaybe<RegistrationSeriesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "registrationSeries" */
export type RegistrationSeriesArrRelInsertInput = {
  data: Array<RegistrationSeriesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationSeriesOnConflict>;
};

/** aggregate avg on columns */
export type RegistrationSeriesAvgFields = {
  __typename?: 'registrationSeries_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registrationSeries" */
export type RegistrationSeriesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "registrationSeries". All fields are combined with a logical 'AND'. */
export type RegistrationSeriesBoolExp = {
  _and?: InputMaybe<Array<RegistrationSeriesBoolExp>>;
  _not?: InputMaybe<RegistrationSeriesBoolExp>;
  _or?: InputMaybe<Array<RegistrationSeriesBoolExp>>;
  eventSeries?: InputMaybe<EventSeriesBoolExp>;
  eventSeriesCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "registrationSeries" */
export enum RegistrationSeriesConstraint {
  /** unique or primary key constraint on columns "eventSeriesCuid", "registrationCuid" */
  RegistrationSeriesEventSeriesCuidRegistrationCuidKey = 'registrationSeries_eventSeriesCuid_registrationCuid_key',
  /** unique or primary key constraint on columns "id" */
  RegistrationSeriesPkey = 'registrationSeries_pkey'
}

/** input type for incrementing numeric columns in table "registrationSeries" */
export type RegistrationSeriesIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "registrationSeries" */
export type RegistrationSeriesInsertInput = {
  eventSeries?: InputMaybe<EventSeriesObjRelInsertInput>;
  eventSeriesCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RegistrationSeriesMaxFields = {
  __typename?: 'registrationSeries_max_fields';
  eventSeriesCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registrationSeries" */
export type RegistrationSeriesMaxOrderBy = {
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RegistrationSeriesMinFields = {
  __typename?: 'registrationSeries_min_fields';
  eventSeriesCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registrationSeries" */
export type RegistrationSeriesMinOrderBy = {
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "registrationSeries" */
export type RegistrationSeriesMutationResponse = {
  __typename?: 'registrationSeries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationSeries>;
};

/** on_conflict condition type for table "registrationSeries" */
export type RegistrationSeriesOnConflict = {
  constraint: RegistrationSeriesConstraint;
  update_columns?: Array<RegistrationSeriesUpdateColumn>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};

/** Ordering options when selecting data from "registrationSeries". */
export type RegistrationSeriesOrderBy = {
  eventSeries?: InputMaybe<EventSeriesOrderBy>;
  eventSeriesCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationSeries */
export type RegistrationSeriesPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "registrationSeries" */
export enum RegistrationSeriesSelectColumn {
  /** column name */
  EventSeriesCuid = 'eventSeriesCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

/** input type for updating data in table "registrationSeries" */
export type RegistrationSeriesSetInput = {
  eventSeriesCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RegistrationSeriesStddevFields = {
  __typename?: 'registrationSeries_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registrationSeries" */
export type RegistrationSeriesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RegistrationSeriesStddevPopFields = {
  __typename?: 'registrationSeries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registrationSeries" */
export type RegistrationSeriesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RegistrationSeriesStddevSampFields = {
  __typename?: 'registrationSeries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registrationSeries" */
export type RegistrationSeriesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RegistrationSeriesSumFields = {
  __typename?: 'registrationSeries_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "registrationSeries" */
export type RegistrationSeriesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "registrationSeries" */
export enum RegistrationSeriesUpdateColumn {
  /** column name */
  EventSeriesCuid = 'eventSeriesCuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

export type RegistrationSeriesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationSeriesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationSeriesSetInput>;
  where: RegistrationSeriesBoolExp;
};

/** aggregate var_pop on columns */
export type RegistrationSeriesVarPopFields = {
  __typename?: 'registrationSeries_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registrationSeries" */
export type RegistrationSeriesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RegistrationSeriesVarSampFields = {
  __typename?: 'registrationSeries_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registrationSeries" */
export type RegistrationSeriesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RegistrationSeriesVarianceFields = {
  __typename?: 'registrationSeries_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registrationSeries" */
export type RegistrationSeriesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "registrationStatus" */
export type RegistrationStatus = Node & {
  __typename?: 'registrationStatus';
  id: Scalars['ID'];
  /** An array relationship */
  registrationAdditionalPayments: Array<RegistrationAdditionalPayment>;
  /** An aggregate relationship */
  registrationAdditionalPayments_aggregate: RegistrationAdditionalPaymentAggregate;
  /** An array relationship connection */
  registrationAdditionalPayments_connection: RegistrationAdditionalPaymentConnection;
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  status: Scalars['String'];
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationAdditionalPaymentsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationAdditionalPaymentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationAdditionalPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** columns and relationships of "registrationStatus" */
export type RegistrationStatusRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};

/** A Relay connection object on "registrationStatus" */
export type RegistrationStatusConnection = {
  __typename?: 'registrationStatusConnection';
  edges: Array<RegistrationStatusEdge>;
  pageInfo: PageInfo;
};

export type RegistrationStatusEdge = {
  __typename?: 'registrationStatusEdge';
  cursor: Scalars['String'];
  node: RegistrationStatus;
};

/** Boolean expression to filter rows from the table "registrationStatus". All fields are combined with a logical 'AND'. */
export type RegistrationStatusBoolExp = {
  _and?: InputMaybe<Array<RegistrationStatusBoolExp>>;
  _not?: InputMaybe<RegistrationStatusBoolExp>;
  _or?: InputMaybe<Array<RegistrationStatusBoolExp>>;
  registrationAdditionalPayments?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
  registrationAdditionalPayments_aggregate?: InputMaybe<RegistrationAdditionalPaymentAggregateBoolExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "registrationStatus" */
export enum RegistrationStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  RegistrationStatusPkey = 'registrationStatus_pkey'
}

export enum RegistrationStatusEnum {
  Canceled = 'canceled',
  CanceledStale = 'canceled_stale',
  Complete = 'complete',
  InProgress = 'in_progress'
}

/** Boolean expression to compare columns of type "registrationStatus_enum". All fields are combined with logical 'AND'. */
export type RegistrationStatusEnumComparisonExp = {
  _eq?: InputMaybe<RegistrationStatusEnum>;
  _in?: InputMaybe<Array<RegistrationStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RegistrationStatusEnum>;
  _nin?: InputMaybe<Array<RegistrationStatusEnum>>;
};

/** input type for inserting data into table "registrationStatus" */
export type RegistrationStatusInsertInput = {
  registrationAdditionalPayments?: InputMaybe<RegistrationAdditionalPaymentArrRelInsertInput>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "registrationStatus" */
export type RegistrationStatusMutationResponse = {
  __typename?: 'registrationStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationStatus>;
};

/** input type for inserting object relation for remote table "registrationStatus" */
export type RegistrationStatusObjRelInsertInput = {
  data: RegistrationStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationStatusOnConflict>;
};

/** on_conflict condition type for table "registrationStatus" */
export type RegistrationStatusOnConflict = {
  constraint: RegistrationStatusConstraint;
  update_columns?: Array<RegistrationStatusUpdateColumn>;
  where?: InputMaybe<RegistrationStatusBoolExp>;
};

/** Ordering options when selecting data from "registrationStatus". */
export type RegistrationStatusOrderBy = {
  registrationAdditionalPayments_aggregate?: InputMaybe<RegistrationAdditionalPaymentAggregateOrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationStatus */
export type RegistrationStatusPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "registrationStatus" */
export enum RegistrationStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "registrationStatus" */
export type RegistrationStatusSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "registrationStatus" */
export enum RegistrationStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export type RegistrationStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationStatusSetInput>;
  where: RegistrationStatusBoolExp;
};

/** columns and relationships of "registrationTicket" */
export type RegistrationTicket = Node & {
  __typename?: 'registrationTicket';
  /** An object relationship */
  eventTicket: EventTicket;
  eventTicketCuid: Scalars['String'];
  id: Scalars['ID'];
  number: Scalars['Int'];
  /** An object relationship */
  registration: Registration;
  registrationCuid: Scalars['String'];
};

/** A Relay connection object on "registrationTicket" */
export type RegistrationTicketConnection = {
  __typename?: 'registrationTicketConnection';
  edges: Array<RegistrationTicketEdge>;
  pageInfo: PageInfo;
};

export type RegistrationTicketEdge = {
  __typename?: 'registrationTicketEdge';
  cursor: Scalars['String'];
  node: RegistrationTicket;
};

/** aggregated selection of "registrationTicket" */
export type RegistrationTicketAggregate = {
  __typename?: 'registrationTicket_aggregate';
  aggregate?: Maybe<RegistrationTicketAggregateFields>;
  nodes: Array<RegistrationTicket>;
};

export type RegistrationTicketAggregateBoolExp = {
  count?: InputMaybe<RegistrationTicketAggregateBoolExpCount>;
};

export type RegistrationTicketAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationTicketBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "registrationTicket" */
export type RegistrationTicketAggregateFields = {
  __typename?: 'registrationTicket_aggregate_fields';
  avg?: Maybe<RegistrationTicketAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegistrationTicketMaxFields>;
  min?: Maybe<RegistrationTicketMinFields>;
  stddev?: Maybe<RegistrationTicketStddevFields>;
  stddev_pop?: Maybe<RegistrationTicketStddevPopFields>;
  stddev_samp?: Maybe<RegistrationTicketStddevSampFields>;
  sum?: Maybe<RegistrationTicketSumFields>;
  var_pop?: Maybe<RegistrationTicketVarPopFields>;
  var_samp?: Maybe<RegistrationTicketVarSampFields>;
  variance?: Maybe<RegistrationTicketVarianceFields>;
};


/** aggregate fields of "registrationTicket" */
export type RegistrationTicketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registrationTicket" */
export type RegistrationTicketAggregateOrderBy = {
  avg?: InputMaybe<RegistrationTicketAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RegistrationTicketMaxOrderBy>;
  min?: InputMaybe<RegistrationTicketMinOrderBy>;
  stddev?: InputMaybe<RegistrationTicketStddevOrderBy>;
  stddev_pop?: InputMaybe<RegistrationTicketStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RegistrationTicketStddevSampOrderBy>;
  sum?: InputMaybe<RegistrationTicketSumOrderBy>;
  var_pop?: InputMaybe<RegistrationTicketVarPopOrderBy>;
  var_samp?: InputMaybe<RegistrationTicketVarSampOrderBy>;
  variance?: InputMaybe<RegistrationTicketVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "registrationTicket" */
export type RegistrationTicketArrRelInsertInput = {
  data: Array<RegistrationTicketInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationTicketOnConflict>;
};

/** aggregate avg on columns */
export type RegistrationTicketAvgFields = {
  __typename?: 'registrationTicket_avg_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registrationTicket" */
export type RegistrationTicketAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "registrationTicket". All fields are combined with a logical 'AND'. */
export type RegistrationTicketBoolExp = {
  _and?: InputMaybe<Array<RegistrationTicketBoolExp>>;
  _not?: InputMaybe<RegistrationTicketBoolExp>;
  _or?: InputMaybe<Array<RegistrationTicketBoolExp>>;
  eventTicket?: InputMaybe<EventTicketBoolExp>;
  eventTicketCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  number?: InputMaybe<IntComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "registrationTicket" */
export enum RegistrationTicketConstraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationTicketPkey = 'registrationTicket_pkey',
  /** unique or primary key constraint on columns "eventTicketCuid", "registrationCuid" */
  RegistrationTicketRegistrationCuidEventTicketCuidKey = 'registrationTicket_registrationCuid_eventTicketCuid_key'
}

/** input type for incrementing numeric columns in table "registrationTicket" */
export type RegistrationTicketIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "registrationTicket" */
export type RegistrationTicketInsertInput = {
  eventTicket?: InputMaybe<EventTicketObjRelInsertInput>;
  eventTicketCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RegistrationTicketMaxFields = {
  __typename?: 'registrationTicket_max_fields';
  eventTicketCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registrationTicket" */
export type RegistrationTicketMaxOrderBy = {
  eventTicketCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RegistrationTicketMinFields = {
  __typename?: 'registrationTicket_min_fields';
  eventTicketCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registrationTicket" */
export type RegistrationTicketMinOrderBy = {
  eventTicketCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "registrationTicket" */
export type RegistrationTicketMutationResponse = {
  __typename?: 'registrationTicket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationTicket>;
};

/** on_conflict condition type for table "registrationTicket" */
export type RegistrationTicketOnConflict = {
  constraint: RegistrationTicketConstraint;
  update_columns?: Array<RegistrationTicketUpdateColumn>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};

/** Ordering options when selecting data from "registrationTicket". */
export type RegistrationTicketOrderBy = {
  eventTicket?: InputMaybe<EventTicketOrderBy>;
  eventTicketCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registrationTicket */
export type RegistrationTicketPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "registrationTicket" */
export enum RegistrationTicketSelectColumn {
  /** column name */
  EventTicketCuid = 'eventTicketCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

/** input type for updating data in table "registrationTicket" */
export type RegistrationTicketSetInput = {
  eventTicketCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RegistrationTicketStddevFields = {
  __typename?: 'registrationTicket_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registrationTicket" */
export type RegistrationTicketStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RegistrationTicketStddevPopFields = {
  __typename?: 'registrationTicket_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registrationTicket" */
export type RegistrationTicketStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RegistrationTicketStddevSampFields = {
  __typename?: 'registrationTicket_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registrationTicket" */
export type RegistrationTicketStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RegistrationTicketSumFields = {
  __typename?: 'registrationTicket_sum_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "registrationTicket" */
export type RegistrationTicketSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** update columns of table "registrationTicket" */
export enum RegistrationTicketUpdateColumn {
  /** column name */
  EventTicketCuid = 'eventTicketCuid',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  RegistrationCuid = 'registrationCuid'
}

export type RegistrationTicketUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationTicketIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationTicketSetInput>;
  where: RegistrationTicketBoolExp;
};

/** aggregate var_pop on columns */
export type RegistrationTicketVarPopFields = {
  __typename?: 'registrationTicket_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registrationTicket" */
export type RegistrationTicketVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RegistrationTicketVarSampFields = {
  __typename?: 'registrationTicket_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registrationTicket" */
export type RegistrationTicketVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RegistrationTicketVarianceFields = {
  __typename?: 'registrationTicket_variance_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registrationTicket" */
export type RegistrationTicketVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
};

/** aggregated selection of "registration" */
export type RegistrationAggregate = {
  __typename?: 'registration_aggregate';
  aggregate?: Maybe<RegistrationAggregateFields>;
  nodes: Array<Registration>;
};

export type RegistrationAggregateBoolExp = {
  bool_and?: InputMaybe<RegistrationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<RegistrationAggregateBoolExpBoolOr>;
  count?: InputMaybe<RegistrationAggregateBoolExpCount>;
};

export type RegistrationAggregateBoolExpBoolAnd = {
  arguments: RegistrationSelectColumnRegistrationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type RegistrationAggregateBoolExpBoolOr = {
  arguments: RegistrationSelectColumnRegistrationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type RegistrationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RegistrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RegistrationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "registration" */
export type RegistrationAggregateFields = {
  __typename?: 'registration_aggregate_fields';
  avg?: Maybe<RegistrationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegistrationMaxFields>;
  min?: Maybe<RegistrationMinFields>;
  stddev?: Maybe<RegistrationStddevFields>;
  stddev_pop?: Maybe<RegistrationStddevPopFields>;
  stddev_samp?: Maybe<RegistrationStddevSampFields>;
  sum?: Maybe<RegistrationSumFields>;
  var_pop?: Maybe<RegistrationVarPopFields>;
  var_samp?: Maybe<RegistrationVarSampFields>;
  variance?: Maybe<RegistrationVarianceFields>;
};


/** aggregate fields of "registration" */
export type RegistrationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegistrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registration" */
export type RegistrationAggregateOrderBy = {
  avg?: InputMaybe<RegistrationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RegistrationMaxOrderBy>;
  min?: InputMaybe<RegistrationMinOrderBy>;
  stddev?: InputMaybe<RegistrationStddevOrderBy>;
  stddev_pop?: InputMaybe<RegistrationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RegistrationStddevSampOrderBy>;
  sum?: InputMaybe<RegistrationSumOrderBy>;
  var_pop?: InputMaybe<RegistrationVarPopOrderBy>;
  var_samp?: InputMaybe<RegistrationVarSampOrderBy>;
  variance?: InputMaybe<RegistrationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RegistrationAppendInput = {
  context?: InputMaybe<Scalars['jsonb']>;
  customQuestions?: InputMaybe<Scalars['jsonb']>;
  promoCode?: InputMaybe<Scalars['jsonb']>;
  sanctionLicenses?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "registration" */
export type RegistrationArrRelInsertInput = {
  data: Array<RegistrationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationOnConflict>;
};

/** aggregate avg on columns */
export type RegistrationAvgFields = {
  __typename?: 'registration_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registration" */
export type RegistrationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "registration". All fields are combined with a logical 'AND'. */
export type RegistrationBoolExp = {
  _and?: InputMaybe<Array<RegistrationBoolExp>>;
  _not?: InputMaybe<RegistrationBoolExp>;
  _or?: InputMaybe<Array<RegistrationBoolExp>>;
  activity?: InputMaybe<RegistrationActivityBoolExp>;
  address?: InputMaybe<StringComparisonExp>;
  addressCity?: InputMaybe<StringComparisonExp>;
  addressCountry?: InputMaybe<StringComparisonExp>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  addressLine2?: InputMaybe<StringComparisonExp>;
  addressState?: InputMaybe<StringComparisonExp>;
  addressZip?: InputMaybe<StringComparisonExp>;
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestBoolExp>;
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateBoolExp>;
  clinic?: InputMaybe<ClinicBoolExp>;
  clinicCuid?: InputMaybe<StringComparisonExp>;
  completedAt?: InputMaybe<TimestamptzComparisonExp>;
  contactName?: InputMaybe<StringComparisonExp>;
  contactPhone?: InputMaybe<StringComparisonExp>;
  contactRelationship?: InputMaybe<StringComparisonExp>;
  context?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  customQuestions?: InputMaybe<JsonbComparisonExp>;
  dob?: InputMaybe<DateComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  for?: InputMaybe<RegistrationForEnumComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isCheckedIn?: InputMaybe<BooleanComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  payingUser?: InputMaybe<UserBoolExp>;
  payingUserId?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  promoCode?: InputMaybe<JsonbComparisonExp>;
  referenceNo?: InputMaybe<StringComparisonExp>;
  registrationAdditionalPayments?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
  registrationAdditionalPayments_aggregate?: InputMaybe<RegistrationAdditionalPaymentAggregateBoolExp>;
  registrationCategories?: InputMaybe<RegistrationCategoryBoolExp>;
  registrationCategories_aggregate?: InputMaybe<RegistrationCategoryAggregateBoolExp>;
  registrationFor?: InputMaybe<RegistrationForBoolExp>;
  registrationSeries?: InputMaybe<RegistrationSeriesBoolExp>;
  registrationSeries_aggregate?: InputMaybe<RegistrationSeriesAggregateBoolExp>;
  registrationStatus?: InputMaybe<RegistrationStatusBoolExp>;
  registrationTickets?: InputMaybe<RegistrationTicketBoolExp>;
  registrationTickets_aggregate?: InputMaybe<RegistrationTicketAggregateBoolExp>;
  ride?: InputMaybe<RideBoolExp>;
  rideCuid?: InputMaybe<StringComparisonExp>;
  sanctionLicenses?: InputMaybe<JsonbComparisonExp>;
  signatureRequests?: InputMaybe<SignatureRequestBoolExp>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateBoolExp>;
  sponsors?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<RegistrationStatusEnumComparisonExp>;
  stripeCheckoutId?: InputMaybe<StringComparisonExp>;
  stripePaymentIntentId?: InputMaybe<StringComparisonExp>;
  successToken?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "registration" */
export enum RegistrationConstraint {
  /** unique or primary key constraint on columns "id" */
  PkRegistration = 'PK_registration',
  /** unique or primary key constraint on columns "cuid" */
  RegistrationCuidKey = 'registration_cuid_key',
  /** unique or primary key constraint on columns "id" */
  RegistrationPkey = 'registration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RegistrationDeleteAtPathInput = {
  context?: InputMaybe<Array<Scalars['String']>>;
  customQuestions?: InputMaybe<Array<Scalars['String']>>;
  promoCode?: InputMaybe<Array<Scalars['String']>>;
  sanctionLicenses?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RegistrationDeleteElemInput = {
  context?: InputMaybe<Scalars['Int']>;
  customQuestions?: InputMaybe<Scalars['Int']>;
  promoCode?: InputMaybe<Scalars['Int']>;
  sanctionLicenses?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RegistrationDeleteKeyInput = {
  context?: InputMaybe<Scalars['String']>;
  customQuestions?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  sanctionLicenses?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "registration" */
export type RegistrationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "registration" */
export type RegistrationInsertInput = {
  activity?: InputMaybe<RegistrationActivityObjRelInsertInput>;
  address?: InputMaybe<Scalars['String']>;
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  addressState?: InputMaybe<Scalars['String']>;
  addressZip?: InputMaybe<Scalars['String']>;
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestArrRelInsertInput>;
  clinic?: InputMaybe<ClinicObjRelInsertInput>;
  clinicCuid?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactRelationship?: InputMaybe<Scalars['String']>;
  context?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  customQuestions?: InputMaybe<Scalars['jsonb']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<RegistrationForEnum>;
  id?: InputMaybe<Scalars['Int']>;
  isCheckedIn?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  payingUser?: InputMaybe<UserObjRelInsertInput>;
  payingUserId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['jsonb']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  registrationAdditionalPayments?: InputMaybe<RegistrationAdditionalPaymentArrRelInsertInput>;
  registrationCategories?: InputMaybe<RegistrationCategoryArrRelInsertInput>;
  registrationFor?: InputMaybe<RegistrationForObjRelInsertInput>;
  registrationSeries?: InputMaybe<RegistrationSeriesArrRelInsertInput>;
  registrationStatus?: InputMaybe<RegistrationStatusObjRelInsertInput>;
  registrationTickets?: InputMaybe<RegistrationTicketArrRelInsertInput>;
  ride?: InputMaybe<RideObjRelInsertInput>;
  rideCuid?: InputMaybe<Scalars['String']>;
  sanctionLicenses?: InputMaybe<Scalars['jsonb']>;
  signatureRequests?: InputMaybe<SignatureRequestArrRelInsertInput>;
  sponsors?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RegistrationStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RegistrationMaxFields = {
  __typename?: 'registration_max_fields';
  address?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  clinicCuid?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactRelationship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  payingUserId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  rideCuid?: Maybe<Scalars['String']>;
  sponsors?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registration" */
export type RegistrationMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressCity?: InputMaybe<OrderBy>;
  addressCountry?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  addressState?: InputMaybe<OrderBy>;
  addressZip?: InputMaybe<OrderBy>;
  clinicCuid?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  contactName?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  contactRelationship?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  payingUserId?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RegistrationMinFields = {
  __typename?: 'registration_min_fields';
  address?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  clinicCuid?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactRelationship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  payingUserId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  rideCuid?: Maybe<Scalars['String']>;
  sponsors?: Maybe<Scalars['String']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registration" */
export type RegistrationMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressCity?: InputMaybe<OrderBy>;
  addressCountry?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  addressState?: InputMaybe<OrderBy>;
  addressZip?: InputMaybe<OrderBy>;
  clinicCuid?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  contactName?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  contactRelationship?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  payingUserId?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "registration" */
export type RegistrationMutationResponse = {
  __typename?: 'registration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Registration>;
};

/** input type for inserting object relation for remote table "registration" */
export type RegistrationObjRelInsertInput = {
  data: RegistrationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RegistrationOnConflict>;
};

/** on_conflict condition type for table "registration" */
export type RegistrationOnConflict = {
  constraint: RegistrationConstraint;
  update_columns?: Array<RegistrationUpdateColumn>;
  where?: InputMaybe<RegistrationBoolExp>;
};

/** Ordering options when selecting data from "registration". */
export type RegistrationOrderBy = {
  activity?: InputMaybe<RegistrationActivityOrderBy>;
  address?: InputMaybe<OrderBy>;
  addressCity?: InputMaybe<OrderBy>;
  addressCountry?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  addressState?: InputMaybe<OrderBy>;
  addressZip?: InputMaybe<OrderBy>;
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateOrderBy>;
  clinic?: InputMaybe<ClinicOrderBy>;
  clinicCuid?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  contactName?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  contactRelationship?: InputMaybe<OrderBy>;
  context?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  customQuestions?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  for?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCheckedIn?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  payingUser?: InputMaybe<UserOrderBy>;
  payingUserId?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  promoCode?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  registrationAdditionalPayments_aggregate?: InputMaybe<RegistrationAdditionalPaymentAggregateOrderBy>;
  registrationCategories_aggregate?: InputMaybe<RegistrationCategoryAggregateOrderBy>;
  registrationFor?: InputMaybe<RegistrationForOrderBy>;
  registrationSeries_aggregate?: InputMaybe<RegistrationSeriesAggregateOrderBy>;
  registrationStatus?: InputMaybe<RegistrationStatusOrderBy>;
  registrationTickets_aggregate?: InputMaybe<RegistrationTicketAggregateOrderBy>;
  ride?: InputMaybe<RideOrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  sanctionLicenses?: InputMaybe<OrderBy>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateOrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: registration */
export type RegistrationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RegistrationPrependInput = {
  context?: InputMaybe<Scalars['jsonb']>;
  customQuestions?: InputMaybe<Scalars['jsonb']>;
  promoCode?: InputMaybe<Scalars['jsonb']>;
  sanctionLicenses?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "registration" */
export enum RegistrationSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressCity = 'addressCity',
  /** column name */
  AddressCountry = 'addressCountry',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  AddressState = 'addressState',
  /** column name */
  AddressZip = 'addressZip',
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhone = 'contactPhone',
  /** column name */
  ContactRelationship = 'contactRelationship',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CustomQuestions = 'customQuestions',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  For = 'for',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckedIn = 'isCheckedIn',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  PayingUserId = 'payingUserId',
  /** column name */
  Phone = 'phone',
  /** column name */
  PromoCode = 'promoCode',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  RideCuid = 'rideCuid',
  /** column name */
  SanctionLicenses = 'sanctionLicenses',
  /** column name */
  Sponsors = 'sponsors',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "registration_aggregate_bool_exp_bool_and_arguments_columns" columns of table "registration" */
export enum RegistrationSelectColumnRegistrationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCheckedIn = 'isCheckedIn'
}

/** select "registration_aggregate_bool_exp_bool_or_arguments_columns" columns of table "registration" */
export enum RegistrationSelectColumnRegistrationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCheckedIn = 'isCheckedIn'
}

/** input type for updating data in table "registration" */
export type RegistrationSetInput = {
  address?: InputMaybe<Scalars['String']>;
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  addressState?: InputMaybe<Scalars['String']>;
  addressZip?: InputMaybe<Scalars['String']>;
  clinicCuid?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactRelationship?: InputMaybe<Scalars['String']>;
  context?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  customQuestions?: InputMaybe<Scalars['jsonb']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<RegistrationForEnum>;
  id?: InputMaybe<Scalars['Int']>;
  isCheckedIn?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  payingUserId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['jsonb']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  rideCuid?: InputMaybe<Scalars['String']>;
  sanctionLicenses?: InputMaybe<Scalars['jsonb']>;
  sponsors?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RegistrationStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RegistrationStddevFields = {
  __typename?: 'registration_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registration" */
export type RegistrationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RegistrationStddevPopFields = {
  __typename?: 'registration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registration" */
export type RegistrationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RegistrationStddevSampFields = {
  __typename?: 'registration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registration" */
export type RegistrationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RegistrationSumFields = {
  __typename?: 'registration_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "registration" */
export type RegistrationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "registration" */
export enum RegistrationUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressCity = 'addressCity',
  /** column name */
  AddressCountry = 'addressCountry',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  AddressState = 'addressState',
  /** column name */
  AddressZip = 'addressZip',
  /** column name */
  ClinicCuid = 'clinicCuid',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhone = 'contactPhone',
  /** column name */
  ContactRelationship = 'contactRelationship',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  CustomQuestions = 'customQuestions',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  For = 'for',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckedIn = 'isCheckedIn',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  PayingUserId = 'payingUserId',
  /** column name */
  Phone = 'phone',
  /** column name */
  PromoCode = 'promoCode',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  RideCuid = 'rideCuid',
  /** column name */
  SanctionLicenses = 'sanctionLicenses',
  /** column name */
  Sponsors = 'sponsors',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type RegistrationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RegistrationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RegistrationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RegistrationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RegistrationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RegistrationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationSetInput>;
  where: RegistrationBoolExp;
};

/** aggregate var_pop on columns */
export type RegistrationVarPopFields = {
  __typename?: 'registration_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registration" */
export type RegistrationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RegistrationVarSampFields = {
  __typename?: 'registration_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registration" */
export type RegistrationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RegistrationVarianceFields = {
  __typename?: 'registration_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registration" */
export type RegistrationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** columns and relationships of "reportClinicNoShow" */
export type ReportClinicNoShow = Node & {
  __typename?: 'reportClinicNoShow';
  /** An object relationship */
  clinicDay: ClinicDay;
  clinicDayCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  reportedUser?: Maybe<User>;
  reportedUserId?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userDistance?: Maybe<Scalars['numeric']>;
  userId: Scalars['String'];
  userIsCoach: Scalars['Boolean'];
  /** {lat: number, lng: number} */
  userLocation?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "reportClinicNoShow" */
export type ReportClinicNoShowUserLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "reportClinicNoShow" */
export type ReportClinicNoShowConnection = {
  __typename?: 'reportClinicNoShowConnection';
  edges: Array<ReportClinicNoShowEdge>;
  pageInfo: PageInfo;
};

export type ReportClinicNoShowEdge = {
  __typename?: 'reportClinicNoShowEdge';
  cursor: Scalars['String'];
  node: ReportClinicNoShow;
};

/** aggregated selection of "reportClinicNoShow" */
export type ReportClinicNoShowAggregate = {
  __typename?: 'reportClinicNoShow_aggregate';
  aggregate?: Maybe<ReportClinicNoShowAggregateFields>;
  nodes: Array<ReportClinicNoShow>;
};

export type ReportClinicNoShowAggregateBoolExp = {
  bool_and?: InputMaybe<ReportClinicNoShowAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ReportClinicNoShowAggregateBoolExpBoolOr>;
  count?: InputMaybe<ReportClinicNoShowAggregateBoolExpCount>;
};

export type ReportClinicNoShowAggregateBoolExpBoolAnd = {
  arguments: ReportClinicNoShowSelectColumnReportClinicNoShowAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportClinicNoShowBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportClinicNoShowAggregateBoolExpBoolOr = {
  arguments: ReportClinicNoShowSelectColumnReportClinicNoShowAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportClinicNoShowBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportClinicNoShowAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportClinicNoShowBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "reportClinicNoShow" */
export type ReportClinicNoShowAggregateFields = {
  __typename?: 'reportClinicNoShow_aggregate_fields';
  avg?: Maybe<ReportClinicNoShowAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReportClinicNoShowMaxFields>;
  min?: Maybe<ReportClinicNoShowMinFields>;
  stddev?: Maybe<ReportClinicNoShowStddevFields>;
  stddev_pop?: Maybe<ReportClinicNoShowStddevPopFields>;
  stddev_samp?: Maybe<ReportClinicNoShowStddevSampFields>;
  sum?: Maybe<ReportClinicNoShowSumFields>;
  var_pop?: Maybe<ReportClinicNoShowVarPopFields>;
  var_samp?: Maybe<ReportClinicNoShowVarSampFields>;
  variance?: Maybe<ReportClinicNoShowVarianceFields>;
};


/** aggregate fields of "reportClinicNoShow" */
export type ReportClinicNoShowAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reportClinicNoShow" */
export type ReportClinicNoShowAggregateOrderBy = {
  avg?: InputMaybe<ReportClinicNoShowAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportClinicNoShowMaxOrderBy>;
  min?: InputMaybe<ReportClinicNoShowMinOrderBy>;
  stddev?: InputMaybe<ReportClinicNoShowStddevOrderBy>;
  stddev_pop?: InputMaybe<ReportClinicNoShowStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReportClinicNoShowStddevSampOrderBy>;
  sum?: InputMaybe<ReportClinicNoShowSumOrderBy>;
  var_pop?: InputMaybe<ReportClinicNoShowVarPopOrderBy>;
  var_samp?: InputMaybe<ReportClinicNoShowVarSampOrderBy>;
  variance?: InputMaybe<ReportClinicNoShowVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ReportClinicNoShowAppendInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "reportClinicNoShow" */
export type ReportClinicNoShowArrRelInsertInput = {
  data: Array<ReportClinicNoShowInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportClinicNoShowOnConflict>;
};

/** aggregate avg on columns */
export type ReportClinicNoShowAvgFields = {
  __typename?: 'reportClinicNoShow_avg_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "reportClinicNoShow". All fields are combined with a logical 'AND'. */
export type ReportClinicNoShowBoolExp = {
  _and?: InputMaybe<Array<ReportClinicNoShowBoolExp>>;
  _not?: InputMaybe<ReportClinicNoShowBoolExp>;
  _or?: InputMaybe<Array<ReportClinicNoShowBoolExp>>;
  clinicDay?: InputMaybe<ClinicDayBoolExp>;
  clinicDayCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  reportedUser?: InputMaybe<UserBoolExp>;
  reportedUserId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userDistance?: InputMaybe<NumericComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  userIsCoach?: InputMaybe<BooleanComparisonExp>;
  userLocation?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "reportClinicNoShow" */
export enum ReportClinicNoShowConstraint {
  /** unique or primary key constraint on columns "cuid" */
  ReportClinicNoShowCuidKey = 'reportClinicNoShow_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ReportClinicNoShowPkey = 'reportClinicNoShow_pkey',
  /** unique or primary key constraint on columns "reportedUserId", "clinicDayCuid", "userId" */
  ReportClinicNoShowUserIdReportedUserIdClinicDayCuidKey = 'reportClinicNoShow_userId_reportedUserId_clinicDayCuid_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ReportClinicNoShowDeleteAtPathInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ReportClinicNoShowDeleteElemInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ReportClinicNoShowDeleteKeyInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "reportClinicNoShow" */
export type ReportClinicNoShowIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  userDistance?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "reportClinicNoShow" */
export type ReportClinicNoShowInsertInput = {
  clinicDay?: InputMaybe<ClinicDayObjRelInsertInput>;
  clinicDayCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  reportedUser?: InputMaybe<UserObjRelInsertInput>;
  reportedUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userDistance?: InputMaybe<Scalars['numeric']>;
  userId?: InputMaybe<Scalars['String']>;
  userIsCoach?: InputMaybe<Scalars['Boolean']>;
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type ReportClinicNoShowMaxFields = {
  __typename?: 'reportClinicNoShow_max_fields';
  clinicDayCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reportedUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userDistance?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowMaxOrderBy = {
  clinicDayCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ReportClinicNoShowMinFields = {
  __typename?: 'reportClinicNoShow_min_fields';
  clinicDayCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  reportedUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userDistance?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowMinOrderBy = {
  clinicDayCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "reportClinicNoShow" */
export type ReportClinicNoShowMutationResponse = {
  __typename?: 'reportClinicNoShow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportClinicNoShow>;
};

/** on_conflict condition type for table "reportClinicNoShow" */
export type ReportClinicNoShowOnConflict = {
  constraint: ReportClinicNoShowConstraint;
  update_columns?: Array<ReportClinicNoShowUpdateColumn>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};

/** Ordering options when selecting data from "reportClinicNoShow". */
export type ReportClinicNoShowOrderBy = {
  clinicDay?: InputMaybe<ClinicDayOrderBy>;
  clinicDayCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reportedUser?: InputMaybe<UserOrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userIsCoach?: InputMaybe<OrderBy>;
  userLocation?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: reportClinicNoShow */
export type ReportClinicNoShowPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ReportClinicNoShowPrependInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "reportClinicNoShow" */
export enum ReportClinicNoShowSelectColumn {
  /** column name */
  ClinicDayCuid = 'clinicDayCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ReportedUserId = 'reportedUserId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserDistance = 'userDistance',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserIsCoach = 'userIsCoach',
  /** column name */
  UserLocation = 'userLocation'
}

/** select "reportClinicNoShow_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reportClinicNoShow" */
export enum ReportClinicNoShowSelectColumnReportClinicNoShowAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  UserIsCoach = 'userIsCoach'
}

/** select "reportClinicNoShow_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reportClinicNoShow" */
export enum ReportClinicNoShowSelectColumnReportClinicNoShowAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  UserIsCoach = 'userIsCoach'
}

/** input type for updating data in table "reportClinicNoShow" */
export type ReportClinicNoShowSetInput = {
  clinicDayCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  reportedUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userDistance?: InputMaybe<Scalars['numeric']>;
  userId?: InputMaybe<Scalars['String']>;
  userIsCoach?: InputMaybe<Scalars['Boolean']>;
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type ReportClinicNoShowStddevFields = {
  __typename?: 'reportClinicNoShow_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ReportClinicNoShowStddevPopFields = {
  __typename?: 'reportClinicNoShow_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ReportClinicNoShowStddevSampFields = {
  __typename?: 'reportClinicNoShow_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ReportClinicNoShowSumFields = {
  __typename?: 'reportClinicNoShow_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  userDistance?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** update columns of table "reportClinicNoShow" */
export enum ReportClinicNoShowUpdateColumn {
  /** column name */
  ClinicDayCuid = 'clinicDayCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ReportedUserId = 'reportedUserId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserDistance = 'userDistance',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserIsCoach = 'userIsCoach',
  /** column name */
  UserLocation = 'userLocation'
}

export type ReportClinicNoShowUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReportClinicNoShowAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ReportClinicNoShowDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ReportClinicNoShowDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ReportClinicNoShowDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportClinicNoShowIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReportClinicNoShowPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportClinicNoShowSetInput>;
  where: ReportClinicNoShowBoolExp;
};

/** aggregate var_pop on columns */
export type ReportClinicNoShowVarPopFields = {
  __typename?: 'reportClinicNoShow_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ReportClinicNoShowVarSampFields = {
  __typename?: 'reportClinicNoShow_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ReportClinicNoShowVarianceFields = {
  __typename?: 'reportClinicNoShow_variance_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reportClinicNoShow" */
export type ReportClinicNoShowVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** columns and relationships of "reportLessonNoShow" */
export type ReportLessonNoShow = Node & {
  __typename?: 'reportLessonNoShow';
  /** An object relationship */
  coachCustomerLessonDate: CoachCustomerLessonDate;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  lessonDateCuid: Scalars['String'];
  /** An object relationship */
  reportedUser: User;
  reportedUserId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userDistance?: Maybe<Scalars['numeric']>;
  userId: Scalars['String'];
  userIsCoach: Scalars['Boolean'];
  /** {lat: number, lng: number} */
  userLocation?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "reportLessonNoShow" */
export type ReportLessonNoShowUserLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "reportLessonNoShow" */
export type ReportLessonNoShowConnection = {
  __typename?: 'reportLessonNoShowConnection';
  edges: Array<ReportLessonNoShowEdge>;
  pageInfo: PageInfo;
};

export type ReportLessonNoShowEdge = {
  __typename?: 'reportLessonNoShowEdge';
  cursor: Scalars['String'];
  node: ReportLessonNoShow;
};

/** aggregated selection of "reportLessonNoShow" */
export type ReportLessonNoShowAggregate = {
  __typename?: 'reportLessonNoShow_aggregate';
  aggregate?: Maybe<ReportLessonNoShowAggregateFields>;
  nodes: Array<ReportLessonNoShow>;
};

export type ReportLessonNoShowAggregateBoolExp = {
  bool_and?: InputMaybe<ReportLessonNoShowAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ReportLessonNoShowAggregateBoolExpBoolOr>;
  count?: InputMaybe<ReportLessonNoShowAggregateBoolExpCount>;
};

export type ReportLessonNoShowAggregateBoolExpBoolAnd = {
  arguments: ReportLessonNoShowSelectColumnReportLessonNoShowAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportLessonNoShowBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportLessonNoShowAggregateBoolExpBoolOr = {
  arguments: ReportLessonNoShowSelectColumnReportLessonNoShowAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportLessonNoShowBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportLessonNoShowAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReportLessonNoShowBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "reportLessonNoShow" */
export type ReportLessonNoShowAggregateFields = {
  __typename?: 'reportLessonNoShow_aggregate_fields';
  avg?: Maybe<ReportLessonNoShowAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReportLessonNoShowMaxFields>;
  min?: Maybe<ReportLessonNoShowMinFields>;
  stddev?: Maybe<ReportLessonNoShowStddevFields>;
  stddev_pop?: Maybe<ReportLessonNoShowStddevPopFields>;
  stddev_samp?: Maybe<ReportLessonNoShowStddevSampFields>;
  sum?: Maybe<ReportLessonNoShowSumFields>;
  var_pop?: Maybe<ReportLessonNoShowVarPopFields>;
  var_samp?: Maybe<ReportLessonNoShowVarSampFields>;
  variance?: Maybe<ReportLessonNoShowVarianceFields>;
};


/** aggregate fields of "reportLessonNoShow" */
export type ReportLessonNoShowAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reportLessonNoShow" */
export type ReportLessonNoShowAggregateOrderBy = {
  avg?: InputMaybe<ReportLessonNoShowAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportLessonNoShowMaxOrderBy>;
  min?: InputMaybe<ReportLessonNoShowMinOrderBy>;
  stddev?: InputMaybe<ReportLessonNoShowStddevOrderBy>;
  stddev_pop?: InputMaybe<ReportLessonNoShowStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReportLessonNoShowStddevSampOrderBy>;
  sum?: InputMaybe<ReportLessonNoShowSumOrderBy>;
  var_pop?: InputMaybe<ReportLessonNoShowVarPopOrderBy>;
  var_samp?: InputMaybe<ReportLessonNoShowVarSampOrderBy>;
  variance?: InputMaybe<ReportLessonNoShowVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ReportLessonNoShowAppendInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "reportLessonNoShow" */
export type ReportLessonNoShowArrRelInsertInput = {
  data: Array<ReportLessonNoShowInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportLessonNoShowOnConflict>;
};

/** aggregate avg on columns */
export type ReportLessonNoShowAvgFields = {
  __typename?: 'reportLessonNoShow_avg_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "reportLessonNoShow". All fields are combined with a logical 'AND'. */
export type ReportLessonNoShowBoolExp = {
  _and?: InputMaybe<Array<ReportLessonNoShowBoolExp>>;
  _not?: InputMaybe<ReportLessonNoShowBoolExp>;
  _or?: InputMaybe<Array<ReportLessonNoShowBoolExp>>;
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lessonDateCuid?: InputMaybe<StringComparisonExp>;
  reportedUser?: InputMaybe<UserBoolExp>;
  reportedUserId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userDistance?: InputMaybe<NumericComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  userIsCoach?: InputMaybe<BooleanComparisonExp>;
  userLocation?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "reportLessonNoShow" */
export enum ReportLessonNoShowConstraint {
  /** unique or primary key constraint on columns "cuid" */
  ReportLessonNoShowCuidKey = 'reportLessonNoShow_cuid_key',
  /** unique or primary key constraint on columns "reportedUserId", "lessonDateCuid", "userId" */
  ReportLessonNoShowLessonDateCuidUserIdReportedUserIdKey = 'reportLessonNoShow_lessonDateCuid_userId_reportedUserId_key',
  /** unique or primary key constraint on columns "id" */
  ReportLessonNoShowPkey = 'reportLessonNoShow_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ReportLessonNoShowDeleteAtPathInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ReportLessonNoShowDeleteElemInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ReportLessonNoShowDeleteKeyInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "reportLessonNoShow" */
export type ReportLessonNoShowIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  userDistance?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "reportLessonNoShow" */
export type ReportLessonNoShowInsertInput = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lessonDateCuid?: InputMaybe<Scalars['String']>;
  reportedUser?: InputMaybe<UserObjRelInsertInput>;
  reportedUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userDistance?: InputMaybe<Scalars['numeric']>;
  userId?: InputMaybe<Scalars['String']>;
  userIsCoach?: InputMaybe<Scalars['Boolean']>;
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type ReportLessonNoShowMaxFields = {
  __typename?: 'reportLessonNoShow_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lessonDateCuid?: Maybe<Scalars['String']>;
  reportedUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userDistance?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonDateCuid?: InputMaybe<OrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ReportLessonNoShowMinFields = {
  __typename?: 'reportLessonNoShow_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lessonDateCuid?: Maybe<Scalars['String']>;
  reportedUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userDistance?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonDateCuid?: InputMaybe<OrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "reportLessonNoShow" */
export type ReportLessonNoShowMutationResponse = {
  __typename?: 'reportLessonNoShow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportLessonNoShow>;
};

/** on_conflict condition type for table "reportLessonNoShow" */
export type ReportLessonNoShowOnConflict = {
  constraint: ReportLessonNoShowConstraint;
  update_columns?: Array<ReportLessonNoShowUpdateColumn>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};

/** Ordering options when selecting data from "reportLessonNoShow". */
export type ReportLessonNoShowOrderBy = {
  coachCustomerLessonDate?: InputMaybe<CoachCustomerLessonDateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessonDateCuid?: InputMaybe<OrderBy>;
  reportedUser?: InputMaybe<UserOrderBy>;
  reportedUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userDistance?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userIsCoach?: InputMaybe<OrderBy>;
  userLocation?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: reportLessonNoShow */
export type ReportLessonNoShowPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ReportLessonNoShowPrependInput = {
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "reportLessonNoShow" */
export enum ReportLessonNoShowSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LessonDateCuid = 'lessonDateCuid',
  /** column name */
  ReportedUserId = 'reportedUserId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserDistance = 'userDistance',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserIsCoach = 'userIsCoach',
  /** column name */
  UserLocation = 'userLocation'
}

/** select "reportLessonNoShow_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reportLessonNoShow" */
export enum ReportLessonNoShowSelectColumnReportLessonNoShowAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  UserIsCoach = 'userIsCoach'
}

/** select "reportLessonNoShow_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reportLessonNoShow" */
export enum ReportLessonNoShowSelectColumnReportLessonNoShowAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  UserIsCoach = 'userIsCoach'
}

/** input type for updating data in table "reportLessonNoShow" */
export type ReportLessonNoShowSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lessonDateCuid?: InputMaybe<Scalars['String']>;
  reportedUserId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userDistance?: InputMaybe<Scalars['numeric']>;
  userId?: InputMaybe<Scalars['String']>;
  userIsCoach?: InputMaybe<Scalars['Boolean']>;
  /** {lat: number, lng: number} */
  userLocation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type ReportLessonNoShowStddevFields = {
  __typename?: 'reportLessonNoShow_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ReportLessonNoShowStddevPopFields = {
  __typename?: 'reportLessonNoShow_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ReportLessonNoShowStddevSampFields = {
  __typename?: 'reportLessonNoShow_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ReportLessonNoShowSumFields = {
  __typename?: 'reportLessonNoShow_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  userDistance?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** update columns of table "reportLessonNoShow" */
export enum ReportLessonNoShowUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LessonDateCuid = 'lessonDateCuid',
  /** column name */
  ReportedUserId = 'reportedUserId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserDistance = 'userDistance',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserIsCoach = 'userIsCoach',
  /** column name */
  UserLocation = 'userLocation'
}

export type ReportLessonNoShowUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReportLessonNoShowAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ReportLessonNoShowDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ReportLessonNoShowDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ReportLessonNoShowDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportLessonNoShowIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReportLessonNoShowPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportLessonNoShowSetInput>;
  where: ReportLessonNoShowBoolExp;
};

/** aggregate var_pop on columns */
export type ReportLessonNoShowVarPopFields = {
  __typename?: 'reportLessonNoShow_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ReportLessonNoShowVarSampFields = {
  __typename?: 'reportLessonNoShow_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ReportLessonNoShowVarianceFields = {
  __typename?: 'reportLessonNoShow_variance_fields';
  id?: Maybe<Scalars['Float']>;
  userDistance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reportLessonNoShow" */
export type ReportLessonNoShowVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  userDistance?: InputMaybe<OrderBy>;
};

/** columns and relationships of "review" */
export type Review = Node & {
  __typename?: 'review';
  /** An object relationship */
  clinic?: Maybe<EventMetadata>;
  /** An object relationship */
  clinicInstance?: Maybe<Clinic>;
  /** An object relationship */
  coach?: Maybe<Coach>;
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  review: Scalars['jsonb'];
  reviewSubjectCuid: Scalars['String'];
  reviewSubjectInstanceCuid?: Maybe<Scalars['String']>;
  reviewSubjectType: ReviewSubjectTypeEnum;
  /** An object relationship */
  reviewSubjectTypeByReviewSubjectType: ReviewSubjectType;
  /** An object relationship */
  reviewer: User;
  reviewerId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "review" */
export type ReviewReviewArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "review" */
export type ReviewConnection = {
  __typename?: 'reviewConnection';
  edges: Array<ReviewEdge>;
  pageInfo: PageInfo;
};

export type ReviewEdge = {
  __typename?: 'reviewEdge';
  cursor: Scalars['String'];
  node: Review;
};

/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectType = Node & {
  __typename?: 'reviewSubjectType';
  id: Scalars['ID'];
  /** An array relationship */
  reviewTotals: Array<ReviewTotals>;
  /** An aggregate relationship */
  reviewTotals_aggregate: ReviewTotalsAggregate;
  /** An array relationship connection */
  reviewTotals_connection: ReviewTotalsConnection;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: ReviewAggregate;
  /** An array relationship connection */
  reviews_connection: ReviewConnection;
  type: Scalars['String'];
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewTotalsArgs = {
  distinct_on?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewTotalsOrderBy>>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewTotalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewTotalsOrderBy>>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewTotalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewTotalsOrderBy>>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewsArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** columns and relationships of "reviewSubjectType" */
export type ReviewSubjectTypeReviewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};

/** A Relay connection object on "reviewSubjectType" */
export type ReviewSubjectTypeConnection = {
  __typename?: 'reviewSubjectTypeConnection';
  edges: Array<ReviewSubjectTypeEdge>;
  pageInfo: PageInfo;
};

export type ReviewSubjectTypeEdge = {
  __typename?: 'reviewSubjectTypeEdge';
  cursor: Scalars['String'];
  node: ReviewSubjectType;
};

/** Boolean expression to filter rows from the table "reviewSubjectType". All fields are combined with a logical 'AND'. */
export type ReviewSubjectTypeBoolExp = {
  _and?: InputMaybe<Array<ReviewSubjectTypeBoolExp>>;
  _not?: InputMaybe<ReviewSubjectTypeBoolExp>;
  _or?: InputMaybe<Array<ReviewSubjectTypeBoolExp>>;
  reviewTotals?: InputMaybe<ReviewTotalsBoolExp>;
  reviewTotals_aggregate?: InputMaybe<ReviewTotalsAggregateBoolExp>;
  reviews?: InputMaybe<ReviewBoolExp>;
  reviews_aggregate?: InputMaybe<ReviewAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "reviewSubjectType" */
export enum ReviewSubjectTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  ReviewSubjectTypePkey = 'reviewSubjectType_pkey'
}

export enum ReviewSubjectTypeEnum {
  Clinic = 'clinic',
  Coach = 'coach'
}

/** Boolean expression to compare columns of type "reviewSubjectType_enum". All fields are combined with logical 'AND'. */
export type ReviewSubjectTypeEnumComparisonExp = {
  _eq?: InputMaybe<ReviewSubjectTypeEnum>;
  _in?: InputMaybe<Array<ReviewSubjectTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReviewSubjectTypeEnum>;
  _nin?: InputMaybe<Array<ReviewSubjectTypeEnum>>;
};

/** input type for inserting data into table "reviewSubjectType" */
export type ReviewSubjectTypeInsertInput = {
  reviewTotals?: InputMaybe<ReviewTotalsArrRelInsertInput>;
  reviews?: InputMaybe<ReviewArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "reviewSubjectType" */
export type ReviewSubjectTypeMutationResponse = {
  __typename?: 'reviewSubjectType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewSubjectType>;
};

/** input type for inserting object relation for remote table "reviewSubjectType" */
export type ReviewSubjectTypeObjRelInsertInput = {
  data: ReviewSubjectTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewSubjectTypeOnConflict>;
};

/** on_conflict condition type for table "reviewSubjectType" */
export type ReviewSubjectTypeOnConflict = {
  constraint: ReviewSubjectTypeConstraint;
  update_columns?: Array<ReviewSubjectTypeUpdateColumn>;
  where?: InputMaybe<ReviewSubjectTypeBoolExp>;
};

/** Ordering options when selecting data from "reviewSubjectType". */
export type ReviewSubjectTypeOrderBy = {
  reviewTotals_aggregate?: InputMaybe<ReviewTotalsAggregateOrderBy>;
  reviews_aggregate?: InputMaybe<ReviewAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: reviewSubjectType */
export type ReviewSubjectTypePkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "reviewSubjectType" */
export enum ReviewSubjectTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "reviewSubjectType" */
export type ReviewSubjectTypeSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reviewSubjectType" */
export enum ReviewSubjectTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ReviewSubjectTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewSubjectTypeSetInput>;
  where: ReviewSubjectTypeBoolExp;
};

/** columns and relationships of "reviewTotals" */
export type ReviewTotals = Node & {
  __typename?: 'reviewTotals';
  count: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  recAverage: Scalars['Float'];
  recPercentage: Scalars['Float'];
  reviewSubjectCuid: Scalars['String'];
  reviewSubjectType: ReviewSubjectTypeEnum;
  /** An object relationship */
  reviewSubjectTypeByReviewSubjectType: ReviewSubjectType;
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "reviewTotals" */
export type ReviewTotalsConnection = {
  __typename?: 'reviewTotalsConnection';
  edges: Array<ReviewTotalsEdge>;
  pageInfo: PageInfo;
};

export type ReviewTotalsEdge = {
  __typename?: 'reviewTotalsEdge';
  cursor: Scalars['String'];
  node: ReviewTotals;
};

/** aggregated selection of "reviewTotals" */
export type ReviewTotalsAggregate = {
  __typename?: 'reviewTotals_aggregate';
  aggregate?: Maybe<ReviewTotalsAggregateFields>;
  nodes: Array<ReviewTotals>;
};

export type ReviewTotalsAggregateBoolExp = {
  count?: InputMaybe<ReviewTotalsAggregateBoolExpCount>;
};

export type ReviewTotalsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewTotalsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "reviewTotals" */
export type ReviewTotalsAggregateFields = {
  __typename?: 'reviewTotals_aggregate_fields';
  avg?: Maybe<ReviewTotalsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewTotalsMaxFields>;
  min?: Maybe<ReviewTotalsMinFields>;
  stddev?: Maybe<ReviewTotalsStddevFields>;
  stddev_pop?: Maybe<ReviewTotalsStddevPopFields>;
  stddev_samp?: Maybe<ReviewTotalsStddevSampFields>;
  sum?: Maybe<ReviewTotalsSumFields>;
  var_pop?: Maybe<ReviewTotalsVarPopFields>;
  var_samp?: Maybe<ReviewTotalsVarSampFields>;
  variance?: Maybe<ReviewTotalsVarianceFields>;
};


/** aggregate fields of "reviewTotals" */
export type ReviewTotalsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reviewTotals" */
export type ReviewTotalsAggregateOrderBy = {
  avg?: InputMaybe<ReviewTotalsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReviewTotalsMaxOrderBy>;
  min?: InputMaybe<ReviewTotalsMinOrderBy>;
  stddev?: InputMaybe<ReviewTotalsStddevOrderBy>;
  stddev_pop?: InputMaybe<ReviewTotalsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReviewTotalsStddevSampOrderBy>;
  sum?: InputMaybe<ReviewTotalsSumOrderBy>;
  var_pop?: InputMaybe<ReviewTotalsVarPopOrderBy>;
  var_samp?: InputMaybe<ReviewTotalsVarSampOrderBy>;
  variance?: InputMaybe<ReviewTotalsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "reviewTotals" */
export type ReviewTotalsArrRelInsertInput = {
  data: Array<ReviewTotalsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewTotalsOnConflict>;
};

/** aggregate avg on columns */
export type ReviewTotalsAvgFields = {
  __typename?: 'reviewTotals_avg_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reviewTotals" */
export type ReviewTotalsAvgOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "reviewTotals". All fields are combined with a logical 'AND'. */
export type ReviewTotalsBoolExp = {
  _and?: InputMaybe<Array<ReviewTotalsBoolExp>>;
  _not?: InputMaybe<ReviewTotalsBoolExp>;
  _or?: InputMaybe<Array<ReviewTotalsBoolExp>>;
  count?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  recAverage?: InputMaybe<FloatComparisonExp>;
  recPercentage?: InputMaybe<FloatComparisonExp>;
  reviewSubjectCuid?: InputMaybe<StringComparisonExp>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnumComparisonExp>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "reviewTotals" */
export enum ReviewTotalsConstraint {
  /** unique or primary key constraint on columns "id" */
  ReviewTotalsPkey = 'reviewTotals_pkey',
  /** unique or primary key constraint on columns "reviewSubjectCuid" */
  ReviewTotalsReviewSubjectCuidKey = 'reviewTotals_reviewSubjectCuid_key'
}

/** input type for incrementing numeric columns in table "reviewTotals" */
export type ReviewTotalsIncInput = {
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  recAverage?: InputMaybe<Scalars['Float']>;
  recPercentage?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "reviewTotals" */
export type ReviewTotalsInsertInput = {
  count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  recAverage?: InputMaybe<Scalars['Float']>;
  recPercentage?: InputMaybe<Scalars['Float']>;
  reviewSubjectCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnum>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ReviewTotalsMaxFields = {
  __typename?: 'reviewTotals_max_fields';
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
  reviewSubjectCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reviewTotals" */
export type ReviewTotalsMaxOrderBy = {
  count?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ReviewTotalsMinFields = {
  __typename?: 'reviewTotals_min_fields';
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
  reviewSubjectCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reviewTotals" */
export type ReviewTotalsMinOrderBy = {
  count?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "reviewTotals" */
export type ReviewTotalsMutationResponse = {
  __typename?: 'reviewTotals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewTotals>;
};

/** on_conflict condition type for table "reviewTotals" */
export type ReviewTotalsOnConflict = {
  constraint: ReviewTotalsConstraint;
  update_columns?: Array<ReviewTotalsUpdateColumn>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};

/** Ordering options when selecting data from "reviewTotals". */
export type ReviewTotalsOrderBy = {
  count?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  reviewSubjectType?: InputMaybe<OrderBy>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: reviewTotals */
export type ReviewTotalsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "reviewTotals" */
export enum ReviewTotalsSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecAverage = 'recAverage',
  /** column name */
  RecPercentage = 'recPercentage',
  /** column name */
  ReviewSubjectCuid = 'reviewSubjectCuid',
  /** column name */
  ReviewSubjectType = 'reviewSubjectType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reviewTotals" */
export type ReviewTotalsSetInput = {
  count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  recAverage?: InputMaybe<Scalars['Float']>;
  recPercentage?: InputMaybe<Scalars['Float']>;
  reviewSubjectCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ReviewTotalsStddevFields = {
  __typename?: 'reviewTotals_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reviewTotals" */
export type ReviewTotalsStddevOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ReviewTotalsStddevPopFields = {
  __typename?: 'reviewTotals_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reviewTotals" */
export type ReviewTotalsStddevPopOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ReviewTotalsStddevSampFields = {
  __typename?: 'reviewTotals_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reviewTotals" */
export type ReviewTotalsStddevSampOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ReviewTotalsSumFields = {
  __typename?: 'reviewTotals_sum_fields';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "reviewTotals" */
export type ReviewTotalsSumOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** update columns of table "reviewTotals" */
export enum ReviewTotalsUpdateColumn {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecAverage = 'recAverage',
  /** column name */
  RecPercentage = 'recPercentage',
  /** column name */
  ReviewSubjectCuid = 'reviewSubjectCuid',
  /** column name */
  ReviewSubjectType = 'reviewSubjectType',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReviewTotalsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewTotalsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewTotalsSetInput>;
  where: ReviewTotalsBoolExp;
};

/** aggregate var_pop on columns */
export type ReviewTotalsVarPopFields = {
  __typename?: 'reviewTotals_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reviewTotals" */
export type ReviewTotalsVarPopOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ReviewTotalsVarSampFields = {
  __typename?: 'reviewTotals_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reviewTotals" */
export type ReviewTotalsVarSampOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ReviewTotalsVarianceFields = {
  __typename?: 'reviewTotals_variance_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recAverage?: Maybe<Scalars['Float']>;
  recPercentage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reviewTotals" */
export type ReviewTotalsVarianceOrderBy = {
  count?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recAverage?: InputMaybe<OrderBy>;
  recPercentage?: InputMaybe<OrderBy>;
};

/** aggregated selection of "review" */
export type ReviewAggregate = {
  __typename?: 'review_aggregate';
  aggregate?: Maybe<ReviewAggregateFields>;
  nodes: Array<Review>;
};

export type ReviewAggregateBoolExp = {
  count?: InputMaybe<ReviewAggregateBoolExpCount>;
};

export type ReviewAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ReviewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "review" */
export type ReviewAggregateFields = {
  __typename?: 'review_aggregate_fields';
  avg?: Maybe<ReviewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewMaxFields>;
  min?: Maybe<ReviewMinFields>;
  stddev?: Maybe<ReviewStddevFields>;
  stddev_pop?: Maybe<ReviewStddevPopFields>;
  stddev_samp?: Maybe<ReviewStddevSampFields>;
  sum?: Maybe<ReviewSumFields>;
  var_pop?: Maybe<ReviewVarPopFields>;
  var_samp?: Maybe<ReviewVarSampFields>;
  variance?: Maybe<ReviewVarianceFields>;
};


/** aggregate fields of "review" */
export type ReviewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review" */
export type ReviewAggregateOrderBy = {
  avg?: InputMaybe<ReviewAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReviewMaxOrderBy>;
  min?: InputMaybe<ReviewMinOrderBy>;
  stddev?: InputMaybe<ReviewStddevOrderBy>;
  stddev_pop?: InputMaybe<ReviewStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReviewStddevSampOrderBy>;
  sum?: InputMaybe<ReviewSumOrderBy>;
  var_pop?: InputMaybe<ReviewVarPopOrderBy>;
  var_samp?: InputMaybe<ReviewVarSampOrderBy>;
  variance?: InputMaybe<ReviewVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ReviewAppendInput = {
  review?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "review" */
export type ReviewArrRelInsertInput = {
  data: Array<ReviewInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewOnConflict>;
};

/** aggregate avg on columns */
export type ReviewAvgFields = {
  __typename?: 'review_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "review" */
export type ReviewAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "review". All fields are combined with a logical 'AND'. */
export type ReviewBoolExp = {
  _and?: InputMaybe<Array<ReviewBoolExp>>;
  _not?: InputMaybe<ReviewBoolExp>;
  _or?: InputMaybe<Array<ReviewBoolExp>>;
  clinic?: InputMaybe<EventMetadataBoolExp>;
  clinicInstance?: InputMaybe<ClinicBoolExp>;
  coach?: InputMaybe<CoachBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  review?: InputMaybe<JsonbComparisonExp>;
  reviewSubjectCuid?: InputMaybe<StringComparisonExp>;
  reviewSubjectInstanceCuid?: InputMaybe<StringComparisonExp>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnumComparisonExp>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeBoolExp>;
  reviewer?: InputMaybe<UserBoolExp>;
  reviewerId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "review" */
export enum ReviewConstraint {
  /** unique or primary key constraint on columns "id" */
  ReviewPkey = 'review_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ReviewDeleteAtPathInput = {
  review?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ReviewDeleteElemInput = {
  review?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ReviewDeleteKeyInput = {
  review?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "review" */
export type ReviewIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "review" */
export type ReviewInsertInput = {
  clinic?: InputMaybe<EventMetadataObjRelInsertInput>;
  clinicInstance?: InputMaybe<ClinicObjRelInsertInput>;
  coach?: InputMaybe<CoachObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['jsonb']>;
  reviewSubjectCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectInstanceCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnum>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeObjRelInsertInput>;
  reviewer?: InputMaybe<UserObjRelInsertInput>;
  reviewerId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ReviewMaxFields = {
  __typename?: 'review_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reviewSubjectCuid?: Maybe<Scalars['String']>;
  reviewSubjectInstanceCuid?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "review" */
export type ReviewMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  reviewSubjectInstanceCuid?: InputMaybe<OrderBy>;
  reviewerId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ReviewMinFields = {
  __typename?: 'review_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reviewSubjectCuid?: Maybe<Scalars['String']>;
  reviewSubjectInstanceCuid?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "review" */
export type ReviewMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  reviewSubjectInstanceCuid?: InputMaybe<OrderBy>;
  reviewerId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "review" */
export type ReviewMutationResponse = {
  __typename?: 'review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review>;
};

/** on_conflict condition type for table "review" */
export type ReviewOnConflict = {
  constraint: ReviewConstraint;
  update_columns?: Array<ReviewUpdateColumn>;
  where?: InputMaybe<ReviewBoolExp>;
};

/** Ordering options when selecting data from "review". */
export type ReviewOrderBy = {
  clinic?: InputMaybe<EventMetadataOrderBy>;
  clinicInstance?: InputMaybe<ClinicOrderBy>;
  coach?: InputMaybe<CoachOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  review?: InputMaybe<OrderBy>;
  reviewSubjectCuid?: InputMaybe<OrderBy>;
  reviewSubjectInstanceCuid?: InputMaybe<OrderBy>;
  reviewSubjectType?: InputMaybe<OrderBy>;
  reviewSubjectTypeByReviewSubjectType?: InputMaybe<ReviewSubjectTypeOrderBy>;
  reviewer?: InputMaybe<UserOrderBy>;
  reviewerId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: review */
export type ReviewPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ReviewPrependInput = {
  review?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "review" */
export enum ReviewSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Review = 'review',
  /** column name */
  ReviewSubjectCuid = 'reviewSubjectCuid',
  /** column name */
  ReviewSubjectInstanceCuid = 'reviewSubjectInstanceCuid',
  /** column name */
  ReviewSubjectType = 'reviewSubjectType',
  /** column name */
  ReviewerId = 'reviewerId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "review" */
export type ReviewSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['jsonb']>;
  reviewSubjectCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectInstanceCuid?: InputMaybe<Scalars['String']>;
  reviewSubjectType?: InputMaybe<ReviewSubjectTypeEnum>;
  reviewerId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ReviewStddevFields = {
  __typename?: 'review_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "review" */
export type ReviewStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ReviewStddevPopFields = {
  __typename?: 'review_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "review" */
export type ReviewStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ReviewStddevSampFields = {
  __typename?: 'review_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "review" */
export type ReviewStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ReviewSumFields = {
  __typename?: 'review_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "review" */
export type ReviewSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "review" */
export enum ReviewUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Review = 'review',
  /** column name */
  ReviewSubjectCuid = 'reviewSubjectCuid',
  /** column name */
  ReviewSubjectInstanceCuid = 'reviewSubjectInstanceCuid',
  /** column name */
  ReviewSubjectType = 'reviewSubjectType',
  /** column name */
  ReviewerId = 'reviewerId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReviewUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReviewAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ReviewDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ReviewDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ReviewDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReviewPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewSetInput>;
  where: ReviewBoolExp;
};

/** aggregate var_pop on columns */
export type ReviewVarPopFields = {
  __typename?: 'review_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "review" */
export type ReviewVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ReviewVarSampFields = {
  __typename?: 'review_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "review" */
export type ReviewVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ReviewVarianceFields = {
  __typename?: 'review_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "review" */
export type ReviewVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** an instance of a ride */
export type Ride = Node & {
  __typename?: 'ride';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  dayOfContact?: Maybe<Scalars['String']>;
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  priceOverride?: Maybe<Scalars['Int']>;
  registrationStatus: Scalars['String'];
  /** An array relationship */
  registrations: Array<Registration>;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  /** An array relationship */
  rideDays: Array<RideDay>;
  /** An aggregate relationship */
  rideDays_aggregate: RideDayAggregate;
  /** An array relationship connection */
  rideDays_connection: RideDayConnection;
  spotsAvailable?: Maybe<Scalars['Int']>;
  /** calculates the spots remaining by accounting for registrations that are complete and in progress */
  spotsRemaining?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** an instance of a ride */
export type RideRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** an instance of a ride */
export type RideRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** an instance of a ride */
export type RideRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** an instance of a ride */
export type RideRideDaysArgs = {
  distinct_on?: InputMaybe<Array<RideDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideDayOrderBy>>;
  where?: InputMaybe<RideDayBoolExp>;
};


/** an instance of a ride */
export type RideRideDaysAggregateArgs = {
  distinct_on?: InputMaybe<Array<RideDaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideDayOrderBy>>;
  where?: InputMaybe<RideDayBoolExp>;
};


/** an instance of a ride */
export type RideRideDaysConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideDayOrderBy>>;
  where?: InputMaybe<RideDayBoolExp>;
};

/** A Relay connection object on "ride" */
export type RideConnection = {
  __typename?: 'rideConnection';
  edges: Array<RideEdge>;
  pageInfo: PageInfo;
};

/** a day for a ride instance */
export type RideDay = Node & {
  __typename?: 'rideDay';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  dayNumber: Scalars['Int'];
  id: Scalars['ID'];
  /** An object relationship */
  ride: Ride;
  rideCuid: Scalars['String'];
  startTime: Scalars['timestamp'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "rideDay" */
export type RideDayConnection = {
  __typename?: 'rideDayConnection';
  edges: Array<RideDayEdge>;
  pageInfo: PageInfo;
};

export type RideDayEdge = {
  __typename?: 'rideDayEdge';
  cursor: Scalars['String'];
  node: RideDay;
};

/** aggregated selection of "rideDay" */
export type RideDayAggregate = {
  __typename?: 'rideDay_aggregate';
  aggregate?: Maybe<RideDayAggregateFields>;
  nodes: Array<RideDay>;
};

export type RideDayAggregateBoolExp = {
  count?: InputMaybe<RideDayAggregateBoolExpCount>;
};

export type RideDayAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RideDaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RideDayBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "rideDay" */
export type RideDayAggregateFields = {
  __typename?: 'rideDay_aggregate_fields';
  avg?: Maybe<RideDayAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RideDayMaxFields>;
  min?: Maybe<RideDayMinFields>;
  stddev?: Maybe<RideDayStddevFields>;
  stddev_pop?: Maybe<RideDayStddevPopFields>;
  stddev_samp?: Maybe<RideDayStddevSampFields>;
  sum?: Maybe<RideDaySumFields>;
  var_pop?: Maybe<RideDayVarPopFields>;
  var_samp?: Maybe<RideDayVarSampFields>;
  variance?: Maybe<RideDayVarianceFields>;
};


/** aggregate fields of "rideDay" */
export type RideDayAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RideDaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rideDay" */
export type RideDayAggregateOrderBy = {
  avg?: InputMaybe<RideDayAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RideDayMaxOrderBy>;
  min?: InputMaybe<RideDayMinOrderBy>;
  stddev?: InputMaybe<RideDayStddevOrderBy>;
  stddev_pop?: InputMaybe<RideDayStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RideDayStddevSampOrderBy>;
  sum?: InputMaybe<RideDaySumOrderBy>;
  var_pop?: InputMaybe<RideDayVarPopOrderBy>;
  var_samp?: InputMaybe<RideDayVarSampOrderBy>;
  variance?: InputMaybe<RideDayVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "rideDay" */
export type RideDayArrRelInsertInput = {
  data: Array<RideDayInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RideDayOnConflict>;
};

/** aggregate avg on columns */
export type RideDayAvgFields = {
  __typename?: 'rideDay_avg_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rideDay" */
export type RideDayAvgOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "rideDay". All fields are combined with a logical 'AND'. */
export type RideDayBoolExp = {
  _and?: InputMaybe<Array<RideDayBoolExp>>;
  _not?: InputMaybe<RideDayBoolExp>;
  _or?: InputMaybe<Array<RideDayBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  dayNumber?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ride?: InputMaybe<RideBoolExp>;
  rideCuid?: InputMaybe<StringComparisonExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "rideDay" */
export enum RideDayConstraint {
  /** unique or primary key constraint on columns "cuid" */
  RideDayCuidKey = 'rideDay_cuid_key',
  /** unique or primary key constraint on columns "id" */
  RideDayPkey = 'rideDay_pkey'
}

/** input type for incrementing numeric columns in table "rideDay" */
export type RideDayIncInput = {
  dayNumber?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rideDay" */
export type RideDayInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  ride?: InputMaybe<RideObjRelInsertInput>;
  rideCuid?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RideDayMaxFields = {
  __typename?: 'rideDay_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  rideCuid?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "rideDay" */
export type RideDayMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RideDayMinFields = {
  __typename?: 'rideDay_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  rideCuid?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "rideDay" */
export type RideDayMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "rideDay" */
export type RideDayMutationResponse = {
  __typename?: 'rideDay_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RideDay>;
};

/** on_conflict condition type for table "rideDay" */
export type RideDayOnConflict = {
  constraint: RideDayConstraint;
  update_columns?: Array<RideDayUpdateColumn>;
  where?: InputMaybe<RideDayBoolExp>;
};

/** Ordering options when selecting data from "rideDay". */
export type RideDayOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ride?: InputMaybe<RideOrderBy>;
  rideCuid?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: rideDay */
export type RideDayPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "rideDay" */
export enum RideDaySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  Id = 'id',
  /** column name */
  RideCuid = 'rideCuid',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rideDay" */
export type RideDaySetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayNumber?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  rideCuid?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RideDayStddevFields = {
  __typename?: 'rideDay_stddev_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rideDay" */
export type RideDayStddevOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RideDayStddevPopFields = {
  __typename?: 'rideDay_stddev_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rideDay" */
export type RideDayStddevPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RideDayStddevSampFields = {
  __typename?: 'rideDay_stddev_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rideDay" */
export type RideDayStddevSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RideDaySumFields = {
  __typename?: 'rideDay_sum_fields';
  dayNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rideDay" */
export type RideDaySumOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "rideDay" */
export enum RideDayUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayNumber = 'dayNumber',
  /** column name */
  Id = 'id',
  /** column name */
  RideCuid = 'rideCuid',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RideDayUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RideDayIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RideDaySetInput>;
  where: RideDayBoolExp;
};

/** aggregate var_pop on columns */
export type RideDayVarPopFields = {
  __typename?: 'rideDay_var_pop_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rideDay" */
export type RideDayVarPopOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RideDayVarSampFields = {
  __typename?: 'rideDay_var_samp_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rideDay" */
export type RideDayVarSampOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RideDayVarianceFields = {
  __typename?: 'rideDay_variance_fields';
  dayNumber?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rideDay" */
export type RideDayVarianceOrderBy = {
  dayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export type RideEdge = {
  __typename?: 'rideEdge';
  cursor: Scalars['String'];
  node: Ride;
};

/** aggregated selection of "ride" */
export type RideAggregate = {
  __typename?: 'ride_aggregate';
  aggregate?: Maybe<RideAggregateFields>;
  nodes: Array<Ride>;
};

export type RideAggregateBoolExp = {
  count?: InputMaybe<RideAggregateBoolExpCount>;
};

export type RideAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RideSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RideBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "ride" */
export type RideAggregateFields = {
  __typename?: 'ride_aggregate_fields';
  avg?: Maybe<RideAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RideMaxFields>;
  min?: Maybe<RideMinFields>;
  stddev?: Maybe<RideStddevFields>;
  stddev_pop?: Maybe<RideStddevPopFields>;
  stddev_samp?: Maybe<RideStddevSampFields>;
  sum?: Maybe<RideSumFields>;
  var_pop?: Maybe<RideVarPopFields>;
  var_samp?: Maybe<RideVarSampFields>;
  variance?: Maybe<RideVarianceFields>;
};


/** aggregate fields of "ride" */
export type RideAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RideSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ride" */
export type RideAggregateOrderBy = {
  avg?: InputMaybe<RideAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RideMaxOrderBy>;
  min?: InputMaybe<RideMinOrderBy>;
  stddev?: InputMaybe<RideStddevOrderBy>;
  stddev_pop?: InputMaybe<RideStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RideStddevSampOrderBy>;
  sum?: InputMaybe<RideSumOrderBy>;
  var_pop?: InputMaybe<RideVarPopOrderBy>;
  var_samp?: InputMaybe<RideVarSampOrderBy>;
  variance?: InputMaybe<RideVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ride" */
export type RideArrRelInsertInput = {
  data: Array<RideInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RideOnConflict>;
};

/** aggregate avg on columns */
export type RideAvgFields = {
  __typename?: 'ride_avg_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ride" */
export type RideAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ride". All fields are combined with a logical 'AND'. */
export type RideBoolExp = {
  _and?: InputMaybe<Array<RideBoolExp>>;
  _not?: InputMaybe<RideBoolExp>;
  _or?: InputMaybe<Array<RideBoolExp>>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  cancellationReason?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  dayOfContact?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  priceOverride?: InputMaybe<IntComparisonExp>;
  registrationStatus?: InputMaybe<StringComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  rideDays?: InputMaybe<RideDayBoolExp>;
  rideDays_aggregate?: InputMaybe<RideDayAggregateBoolExp>;
  spotsAvailable?: InputMaybe<IntComparisonExp>;
  spotsRemaining?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "ride" */
export enum RideConstraint {
  /** unique or primary key constraint on columns "cuid" */
  RideCuidKey = 'ride_cuid_key',
  /** unique or primary key constraint on columns "id" */
  RidePkey = 'ride_pkey'
}

/** input type for incrementing numeric columns in table "ride" */
export type RideIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  priceOverride?: InputMaybe<Scalars['Int']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ride" */
export type RideInsertInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfContact?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  priceOverride?: InputMaybe<Scalars['Int']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  rideDays?: InputMaybe<RideDayArrRelInsertInput>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RideMaxFields = {
  __typename?: 'ride_max_fields';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfContact?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  priceOverride?: Maybe<Scalars['Int']>;
  registrationStatus?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ride" */
export type RideMaxOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RideMinFields = {
  __typename?: 'ride_min_fields';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  dayOfContact?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  priceOverride?: Maybe<Scalars['Int']>;
  registrationStatus?: Maybe<Scalars['String']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ride" */
export type RideMinOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ride" */
export type RideMutationResponse = {
  __typename?: 'ride_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ride>;
};

/** input type for inserting object relation for remote table "ride" */
export type RideObjRelInsertInput = {
  data: RideInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RideOnConflict>;
};

/** on_conflict condition type for table "ride" */
export type RideOnConflict = {
  constraint: RideConstraint;
  update_columns?: Array<RideUpdateColumn>;
  where?: InputMaybe<RideBoolExp>;
};

/** Ordering options when selecting data from "ride". */
export type RideOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  cancellationReason?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  dayOfContact?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  registrationStatus?: InputMaybe<OrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  rideDays_aggregate?: InputMaybe<RideDayAggregateOrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
  spotsRemaining?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ride */
export type RidePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "ride" */
export enum RideSelectColumn {
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfContact = 'dayOfContact',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  PriceOverride = 'priceOverride',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ride" */
export type RideSetInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  dayOfContact?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  priceOverride?: InputMaybe<Scalars['Int']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  spotsAvailable?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RideStddevFields = {
  __typename?: 'ride_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ride" */
export type RideStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RideStddevPopFields = {
  __typename?: 'ride_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ride" */
export type RideStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RideStddevSampFields = {
  __typename?: 'ride_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ride" */
export type RideStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RideSumFields = {
  __typename?: 'ride_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  priceOverride?: Maybe<Scalars['Int']>;
  spotsAvailable?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ride" */
export type RideSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** update columns of table "ride" */
export enum RideUpdateColumn {
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CancellationReason = 'cancellationReason',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DayOfContact = 'dayOfContact',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  PriceOverride = 'priceOverride',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  SpotsAvailable = 'spotsAvailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RideUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RideIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RideSetInput>;
  where: RideBoolExp;
};

/** aggregate var_pop on columns */
export type RideVarPopFields = {
  __typename?: 'ride_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ride" */
export type RideVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RideVarSampFields = {
  __typename?: 'ride_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ride" */
export type RideVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RideVarianceFields = {
  __typename?: 'ride_variance_fields';
  id?: Maybe<Scalars['Float']>;
  priceOverride?: Maybe<Scalars['Float']>;
  spotsAvailable?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ride" */
export type RideVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  priceOverride?: InputMaybe<OrderBy>;
  spotsAvailable?: InputMaybe<OrderBy>;
};

/** Role types for an organization */
export type RoleOrganizer = Node & {
  __typename?: 'roleOrganizer';
  id: Scalars['ID'];
  role: Scalars['String'];
  /** An array relationship */
  userOrganizerRoles: Array<UserOrganizerRole>;
  /** An aggregate relationship */
  userOrganizerRoles_aggregate: UserOrganizerRoleAggregate;
  /** An array relationship connection */
  userOrganizerRoles_connection: UserOrganizerRoleConnection;
};


/** Role types for an organization */
export type RoleOrganizerUserOrganizerRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** Role types for an organization */
export type RoleOrganizerUserOrganizerRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** Role types for an organization */
export type RoleOrganizerUserOrganizerRolesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};

/** A Relay connection object on "roleOrganizer" */
export type RoleOrganizerConnection = {
  __typename?: 'roleOrganizerConnection';
  edges: Array<RoleOrganizerEdge>;
  pageInfo: PageInfo;
};

export type RoleOrganizerEdge = {
  __typename?: 'roleOrganizerEdge';
  cursor: Scalars['String'];
  node: RoleOrganizer;
};

/** Boolean expression to filter rows from the table "roleOrganizer". All fields are combined with a logical 'AND'. */
export type RoleOrganizerBoolExp = {
  _and?: InputMaybe<Array<RoleOrganizerBoolExp>>;
  _not?: InputMaybe<RoleOrganizerBoolExp>;
  _or?: InputMaybe<Array<RoleOrganizerBoolExp>>;
  role?: InputMaybe<StringComparisonExp>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleBoolExp>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateBoolExp>;
};

/** unique or primary key constraints on table "roleOrganizer" */
export enum RoleOrganizerConstraint {
  /** unique or primary key constraint on columns "role" */
  RoleOrganizerPkey = 'roleOrganizer_pkey'
}

export enum RoleOrganizerEnum {
  Admin = 'Admin',
  Coach = 'Coach',
  Owner = 'Owner',
  Support = 'Support',
  Timing = 'Timing',
  Volunteer = 'Volunteer'
}

/** Boolean expression to compare columns of type "roleOrganizer_enum". All fields are combined with logical 'AND'. */
export type RoleOrganizerEnumComparisonExp = {
  _eq?: InputMaybe<RoleOrganizerEnum>;
  _in?: InputMaybe<Array<RoleOrganizerEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RoleOrganizerEnum>;
  _nin?: InputMaybe<Array<RoleOrganizerEnum>>;
};

/** input type for inserting data into table "roleOrganizer" */
export type RoleOrganizerInsertInput = {
  role?: InputMaybe<Scalars['String']>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleArrRelInsertInput>;
};

/** response of any mutation on the table "roleOrganizer" */
export type RoleOrganizerMutationResponse = {
  __typename?: 'roleOrganizer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoleOrganizer>;
};

/** input type for inserting object relation for remote table "roleOrganizer" */
export type RoleOrganizerObjRelInsertInput = {
  data: RoleOrganizerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RoleOrganizerOnConflict>;
};

/** on_conflict condition type for table "roleOrganizer" */
export type RoleOrganizerOnConflict = {
  constraint: RoleOrganizerConstraint;
  update_columns?: Array<RoleOrganizerUpdateColumn>;
  where?: InputMaybe<RoleOrganizerBoolExp>;
};

/** Ordering options when selecting data from "roleOrganizer". */
export type RoleOrganizerOrderBy = {
  role?: InputMaybe<OrderBy>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateOrderBy>;
};

/** primary key columns input for table: roleOrganizer */
export type RoleOrganizerPkColumnsInput = {
  role: Scalars['String'];
};

/** select columns of table "roleOrganizer" */
export enum RoleOrganizerSelectColumn {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "roleOrganizer" */
export type RoleOrganizerSetInput = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roleOrganizer" */
export enum RoleOrganizerUpdateColumn {
  /** column name */
  Role = 'role'
}

export type RoleOrganizerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoleOrganizerSetInput>;
  where: RoleOrganizerBoolExp;
};

/** columns and relationships of "sanction" */
export type Sanction = Node & {
  __typename?: 'sanction';
  classification?: Maybe<Scalars['jsonb']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An array relationship */
  eventSanctions: Array<EventSanction>;
  /** An aggregate relationship */
  eventSanctions_aggregate: EventSanctionAggregate;
  /** An array relationship connection */
  eventSanctions_connection: EventSanctionConnection;
  hasPermit: Scalars['Boolean'];
  id: Scalars['ID'];
  isOneDayAvailable: Scalars['Boolean'];
  name: Scalars['String'];
  oneDayFee?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  sanctionWaivers: Array<SanctionWaivers>;
  /** An aggregate relationship */
  sanctionWaivers_aggregate: SanctionWaiversAggregate;
  /** An array relationship connection */
  sanctionWaivers_connection: SanctionWaiversConnection;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userSanctionLicenses: Array<UserSanctionLicense>;
  /** An aggregate relationship */
  userSanctionLicenses_aggregate: UserSanctionLicenseAggregate;
  /** An array relationship connection */
  userSanctionLicenses_connection: UserSanctionLicenseConnection;
  waiverLink?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "sanction" */
export type SanctionClassificationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "sanction" */
export type SanctionEventSanctionsArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionEventSanctionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionEventSanctionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionSanctionWaiversArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionSanctionWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionSanctionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionUserSanctionLicensesArgs = {
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionUserSanctionLicensesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


/** columns and relationships of "sanction" */
export type SanctionUserSanctionLicensesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};

/** A Relay connection object on "sanction" */
export type SanctionConnection = {
  __typename?: 'sanctionConnection';
  edges: Array<SanctionEdge>;
  pageInfo: PageInfo;
};

export type SanctionEdge = {
  __typename?: 'sanctionEdge';
  cursor: Scalars['String'];
  node: Sanction;
};

/** stores relations between REGGY-owned waivers and official sanctions (not user-created sanctions) */
export type SanctionWaivers = Node & {
  __typename?: 'sanctionWaivers';
  id: Scalars['ID'];
  /** An object relationship */
  sanction: Sanction;
  sanctionCuid: Scalars['String'];
  /** An object relationship */
  waiver: Waiver;
  waiverCuid: Scalars['String'];
};

/** A Relay connection object on "sanctionWaivers" */
export type SanctionWaiversConnection = {
  __typename?: 'sanctionWaiversConnection';
  edges: Array<SanctionWaiversEdge>;
  pageInfo: PageInfo;
};

export type SanctionWaiversEdge = {
  __typename?: 'sanctionWaiversEdge';
  cursor: Scalars['String'];
  node: SanctionWaivers;
};

/** aggregated selection of "sanctionWaivers" */
export type SanctionWaiversAggregate = {
  __typename?: 'sanctionWaivers_aggregate';
  aggregate?: Maybe<SanctionWaiversAggregateFields>;
  nodes: Array<SanctionWaivers>;
};

export type SanctionWaiversAggregateBoolExp = {
  count?: InputMaybe<SanctionWaiversAggregateBoolExpCount>;
};

export type SanctionWaiversAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SanctionWaiversBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "sanctionWaivers" */
export type SanctionWaiversAggregateFields = {
  __typename?: 'sanctionWaivers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SanctionWaiversMaxFields>;
  min?: Maybe<SanctionWaiversMinFields>;
};


/** aggregate fields of "sanctionWaivers" */
export type SanctionWaiversAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sanctionWaivers" */
export type SanctionWaiversAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SanctionWaiversMaxOrderBy>;
  min?: InputMaybe<SanctionWaiversMinOrderBy>;
};

/** input type for inserting array relation for remote table "sanctionWaivers" */
export type SanctionWaiversArrRelInsertInput = {
  data: Array<SanctionWaiversInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SanctionWaiversOnConflict>;
};

/** Boolean expression to filter rows from the table "sanctionWaivers". All fields are combined with a logical 'AND'. */
export type SanctionWaiversBoolExp = {
  _and?: InputMaybe<Array<SanctionWaiversBoolExp>>;
  _not?: InputMaybe<SanctionWaiversBoolExp>;
  _or?: InputMaybe<Array<SanctionWaiversBoolExp>>;
  sanction?: InputMaybe<SanctionBoolExp>;
  sanctionCuid?: InputMaybe<StringComparisonExp>;
  waiver?: InputMaybe<WaiverBoolExp>;
  waiverCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "sanctionWaivers" */
export enum SanctionWaiversConstraint {
  /** unique or primary key constraint on columns "sanctionCuid", "waiverCuid" */
  SanctionWaiversPkey = 'sanctionWaivers_pkey'
}

/** input type for inserting data into table "sanctionWaivers" */
export type SanctionWaiversInsertInput = {
  sanction?: InputMaybe<SanctionObjRelInsertInput>;
  sanctionCuid?: InputMaybe<Scalars['String']>;
  waiver?: InputMaybe<WaiverObjRelInsertInput>;
  waiverCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SanctionWaiversMaxFields = {
  __typename?: 'sanctionWaivers_max_fields';
  sanctionCuid?: Maybe<Scalars['String']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sanctionWaivers" */
export type SanctionWaiversMaxOrderBy = {
  sanctionCuid?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SanctionWaiversMinFields = {
  __typename?: 'sanctionWaivers_min_fields';
  sanctionCuid?: Maybe<Scalars['String']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sanctionWaivers" */
export type SanctionWaiversMinOrderBy = {
  sanctionCuid?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "sanctionWaivers" */
export type SanctionWaiversMutationResponse = {
  __typename?: 'sanctionWaivers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SanctionWaivers>;
};

/** on_conflict condition type for table "sanctionWaivers" */
export type SanctionWaiversOnConflict = {
  constraint: SanctionWaiversConstraint;
  update_columns?: Array<SanctionWaiversUpdateColumn>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};

/** Ordering options when selecting data from "sanctionWaivers". */
export type SanctionWaiversOrderBy = {
  sanction?: InputMaybe<SanctionOrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  waiver?: InputMaybe<WaiverOrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sanctionWaivers */
export type SanctionWaiversPkColumnsInput = {
  sanctionCuid: Scalars['String'];
  waiverCuid: Scalars['String'];
};

/** select columns of table "sanctionWaivers" */
export enum SanctionWaiversSelectColumn {
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

/** input type for updating data in table "sanctionWaivers" */
export type SanctionWaiversSetInput = {
  sanctionCuid?: InputMaybe<Scalars['String']>;
  waiverCuid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sanctionWaivers" */
export enum SanctionWaiversUpdateColumn {
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

export type SanctionWaiversUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SanctionWaiversSetInput>;
  where: SanctionWaiversBoolExp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SanctionAppendInput = {
  classification?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "sanction". All fields are combined with a logical 'AND'. */
export type SanctionBoolExp = {
  _and?: InputMaybe<Array<SanctionBoolExp>>;
  _not?: InputMaybe<SanctionBoolExp>;
  _or?: InputMaybe<Array<SanctionBoolExp>>;
  classification?: InputMaybe<JsonbComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventSanctions?: InputMaybe<EventSanctionBoolExp>;
  eventSanctions_aggregate?: InputMaybe<EventSanctionAggregateBoolExp>;
  hasPermit?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isOneDayAvailable?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  oneDayFee?: InputMaybe<NumericComparisonExp>;
  sanctionWaivers?: InputMaybe<SanctionWaiversBoolExp>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userSanctionLicenses?: InputMaybe<UserSanctionLicenseBoolExp>;
  userSanctionLicenses_aggregate?: InputMaybe<UserSanctionLicenseAggregateBoolExp>;
  waiverLink?: InputMaybe<StringComparisonExp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "sanction" */
export enum SanctionConstraint {
  /** unique or primary key constraint on columns "id" */
  PkSanction = 'PK_sanction',
  /** unique or primary key constraint on columns "cuid" */
  SanctionCuidKey = 'sanction_cuid_key',
  /** unique or primary key constraint on columns "name" */
  SanctionNameKey = 'sanction_name_key',
  /** unique or primary key constraint on columns "id" */
  SanctionPkey = 'sanction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SanctionDeleteAtPathInput = {
  classification?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SanctionDeleteElemInput = {
  classification?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SanctionDeleteKeyInput = {
  classification?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "sanction" */
export type SanctionIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "sanction" */
export type SanctionInsertInput = {
  classification?: InputMaybe<Scalars['jsonb']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventSanctions?: InputMaybe<EventSanctionArrRelInsertInput>;
  hasPermit?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isOneDayAvailable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
  sanctionWaivers?: InputMaybe<SanctionWaiversArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userSanctionLicenses?: InputMaybe<UserSanctionLicenseArrRelInsertInput>;
  waiverLink?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "sanction" */
export type SanctionMutationResponse = {
  __typename?: 'sanction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sanction>;
};

/** input type for inserting object relation for remote table "sanction" */
export type SanctionObjRelInsertInput = {
  data: SanctionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SanctionOnConflict>;
};

/** on_conflict condition type for table "sanction" */
export type SanctionOnConflict = {
  constraint: SanctionConstraint;
  update_columns?: Array<SanctionUpdateColumn>;
  where?: InputMaybe<SanctionBoolExp>;
};

/** Ordering options when selecting data from "sanction". */
export type SanctionOrderBy = {
  classification?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventSanctions_aggregate?: InputMaybe<EventSanctionAggregateOrderBy>;
  hasPermit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOneDayAvailable?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oneDayFee?: InputMaybe<OrderBy>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userSanctionLicenses_aggregate?: InputMaybe<UserSanctionLicenseAggregateOrderBy>;
  waiverLink?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sanction */
export type SanctionPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SanctionPrependInput = {
  classification?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "sanction" */
export enum SanctionSelectColumn {
  /** column name */
  Classification = 'classification',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  HasPermit = 'hasPermit',
  /** column name */
  Id = 'id',
  /** column name */
  IsOneDayAvailable = 'isOneDayAvailable',
  /** column name */
  Name = 'name',
  /** column name */
  OneDayFee = 'oneDayFee',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverLink = 'waiverLink',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "sanction" */
export type SanctionSetInput = {
  classification?: InputMaybe<Scalars['jsonb']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  hasPermit?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isOneDayAvailable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  oneDayFee?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverLink?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sanction" */
export enum SanctionUpdateColumn {
  /** column name */
  Classification = 'classification',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  HasPermit = 'hasPermit',
  /** column name */
  Id = 'id',
  /** column name */
  IsOneDayAvailable = 'isOneDayAvailable',
  /** column name */
  Name = 'name',
  /** column name */
  OneDayFee = 'oneDayFee',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverLink = 'waiverLink',
  /** column name */
  Website = 'website'
}

export type SanctionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SanctionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SanctionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SanctionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SanctionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SanctionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SanctionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SanctionSetInput>;
  where: SanctionBoolExp;
};

/** Schedule Type Presets for an eventSchedule item. CUIDS CAN NOT CHANGEE! */
export type ScheduleTypePreset = Node & {
  __typename?: 'scheduleTypePreset';
  activity: Scalars['String'];
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An array relationship */
  eventScheduleTypePresets: Array<EventScheduleTypePreset>;
  /** An aggregate relationship */
  eventScheduleTypePresets_aggregate: EventScheduleTypePresetAggregate;
  /** An array relationship connection */
  eventScheduleTypePresets_connection: EventScheduleTypePresetConnection;
  id: Scalars['ID'];
  isPreset: Scalars['Boolean'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Schedule Type Presets for an eventSchedule item. CUIDS CAN NOT CHANGEE! */
export type ScheduleTypePresetEventScheduleTypePresetsArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


/** Schedule Type Presets for an eventSchedule item. CUIDS CAN NOT CHANGEE! */
export type ScheduleTypePresetEventScheduleTypePresetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


/** Schedule Type Presets for an eventSchedule item. CUIDS CAN NOT CHANGEE! */
export type ScheduleTypePresetEventScheduleTypePresetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};

/** A Relay connection object on "scheduleTypePreset" */
export type ScheduleTypePresetConnection = {
  __typename?: 'scheduleTypePresetConnection';
  edges: Array<ScheduleTypePresetEdge>;
  pageInfo: PageInfo;
};

export type ScheduleTypePresetEdge = {
  __typename?: 'scheduleTypePresetEdge';
  cursor: Scalars['String'];
  node: ScheduleTypePreset;
};

/** Boolean expression to filter rows from the table "scheduleTypePreset". All fields are combined with a logical 'AND'. */
export type ScheduleTypePresetBoolExp = {
  _and?: InputMaybe<Array<ScheduleTypePresetBoolExp>>;
  _not?: InputMaybe<ScheduleTypePresetBoolExp>;
  _or?: InputMaybe<Array<ScheduleTypePresetBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  color?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventScheduleTypePresets?: InputMaybe<EventScheduleTypePresetBoolExp>;
  eventScheduleTypePresets_aggregate?: InputMaybe<EventScheduleTypePresetAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  isPreset?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "scheduleTypePreset" */
export enum ScheduleTypePresetConstraint {
  /** unique or primary key constraint on columns "cuid" */
  ScheduleTypePresetCuidKey = 'scheduleTypePreset_cuid_key',
  /** unique or primary key constraint on columns "id" */
  ScheduleTypePresetPkey = 'scheduleTypePreset_pkey'
}

/** input type for incrementing numeric columns in table "scheduleTypePreset" */
export type ScheduleTypePresetIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "scheduleTypePreset" */
export type ScheduleTypePresetInsertInput = {
  activity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventScheduleTypePresets?: InputMaybe<EventScheduleTypePresetArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "scheduleTypePreset" */
export type ScheduleTypePresetMutationResponse = {
  __typename?: 'scheduleTypePreset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScheduleTypePreset>;
};

/** input type for inserting object relation for remote table "scheduleTypePreset" */
export type ScheduleTypePresetObjRelInsertInput = {
  data: ScheduleTypePresetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ScheduleTypePresetOnConflict>;
};

/** on_conflict condition type for table "scheduleTypePreset" */
export type ScheduleTypePresetOnConflict = {
  constraint: ScheduleTypePresetConstraint;
  update_columns?: Array<ScheduleTypePresetUpdateColumn>;
  where?: InputMaybe<ScheduleTypePresetBoolExp>;
};

/** Ordering options when selecting data from "scheduleTypePreset". */
export type ScheduleTypePresetOrderBy = {
  activity?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventScheduleTypePresets_aggregate?: InputMaybe<EventScheduleTypePresetAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isPreset?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: scheduleTypePreset */
export type ScheduleTypePresetPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "scheduleTypePreset" */
export enum ScheduleTypePresetSelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "scheduleTypePreset" */
export type ScheduleTypePresetSetInput = {
  activity?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "scheduleTypePreset" */
export enum ScheduleTypePresetUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ScheduleTypePresetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScheduleTypePresetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScheduleTypePresetSetInput>;
  where: ScheduleTypePresetBoolExp;
};

/** columns and relationships of "series" */
export type Series = Node & {
  __typename?: 'series';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  eventSeries: Array<EventSeries>;
  /** An aggregate relationship */
  eventSeries_aggregate: EventSeriesAggregate;
  /** An array relationship connection */
  eventSeries_connection: EventSeriesConnection;
  id: Scalars['ID'];
  isJoinable: Scalars['Boolean'];
  joinKey: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  seriesActivity: Scalars['String'];
  /** An array relationship */
  seriesImages: Array<SeriesImage>;
  /** An aggregate relationship */
  seriesImages_aggregate: SeriesImageAggregate;
  /** An array relationship connection */
  seriesImages_connection: SeriesImageConnection;
  /** An array relationship */
  seriesPointsStructureCustoms: Array<SeriesPointsStructureCustom>;
  /** An aggregate relationship */
  seriesPointsStructureCustoms_aggregate: SeriesPointsStructureCustomAggregate;
  /** An array relationship connection */
  seriesPointsStructureCustoms_connection: SeriesPointsStructureCustomConnection;
  /** An array relationship */
  seriesPointsStructurePresets: Array<SeriesPointsStructurePreset>;
  /** An aggregate relationship */
  seriesPointsStructurePresets_aggregate: SeriesPointsStructurePresetAggregate;
  /** An array relationship connection */
  seriesPointsStructurePresets_connection: SeriesPointsStructurePresetConnection;
  slug: Scalars['String'];
  totalRounds?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "series" */
export type SeriesEventSeriesArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesEventSeriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesEventSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesImagesArgs = {
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructureCustomsArgs = {
  distinct_on?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructureCustomOrderBy>>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructureCustomsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructureCustomOrderBy>>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructureCustomsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructureCustomOrderBy>>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructurePresetsArgs = {
  distinct_on?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructurePresetOrderBy>>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructurePresetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructurePresetOrderBy>>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};


/** columns and relationships of "series" */
export type SeriesSeriesPointsStructurePresetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructurePresetOrderBy>>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};

/** A Relay connection object on "series" */
export type SeriesConnection = {
  __typename?: 'seriesConnection';
  edges: Array<SeriesEdge>;
  pageInfo: PageInfo;
};

export type SeriesEdge = {
  __typename?: 'seriesEdge';
  cursor: Scalars['String'];
  node: Series;
};

/** Images for a series */
export type SeriesImage = Node & {
  __typename?: 'seriesImage';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image: Image;
  imagePublicId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  /** An object relationship */
  series: Series;
  seriesCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "seriesImage" */
export type SeriesImageConnection = {
  __typename?: 'seriesImageConnection';
  edges: Array<SeriesImageEdge>;
  pageInfo: PageInfo;
};

export type SeriesImageEdge = {
  __typename?: 'seriesImageEdge';
  cursor: Scalars['String'];
  node: SeriesImage;
};

/** aggregated selection of "seriesImage" */
export type SeriesImageAggregate = {
  __typename?: 'seriesImage_aggregate';
  aggregate?: Maybe<SeriesImageAggregateFields>;
  nodes: Array<SeriesImage>;
};

export type SeriesImageAggregateBoolExp = {
  bool_and?: InputMaybe<SeriesImageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SeriesImageAggregateBoolExpBoolOr>;
  count?: InputMaybe<SeriesImageAggregateBoolExpCount>;
};

export type SeriesImageAggregateBoolExpBoolAnd = {
  arguments: SeriesImageSelectColumnSeriesImageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesImageAggregateBoolExpBoolOr = {
  arguments: SeriesImageSelectColumnSeriesImageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SeriesImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "seriesImage" */
export type SeriesImageAggregateFields = {
  __typename?: 'seriesImage_aggregate_fields';
  avg?: Maybe<SeriesImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SeriesImageMaxFields>;
  min?: Maybe<SeriesImageMinFields>;
  stddev?: Maybe<SeriesImageStddevFields>;
  stddev_pop?: Maybe<SeriesImageStddevPopFields>;
  stddev_samp?: Maybe<SeriesImageStddevSampFields>;
  sum?: Maybe<SeriesImageSumFields>;
  var_pop?: Maybe<SeriesImageVarPopFields>;
  var_samp?: Maybe<SeriesImageVarSampFields>;
  variance?: Maybe<SeriesImageVarianceFields>;
};


/** aggregate fields of "seriesImage" */
export type SeriesImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeriesImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seriesImage" */
export type SeriesImageAggregateOrderBy = {
  avg?: InputMaybe<SeriesImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SeriesImageMaxOrderBy>;
  min?: InputMaybe<SeriesImageMinOrderBy>;
  stddev?: InputMaybe<SeriesImageStddevOrderBy>;
  stddev_pop?: InputMaybe<SeriesImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SeriesImageStddevSampOrderBy>;
  sum?: InputMaybe<SeriesImageSumOrderBy>;
  var_pop?: InputMaybe<SeriesImageVarPopOrderBy>;
  var_samp?: InputMaybe<SeriesImageVarSampOrderBy>;
  variance?: InputMaybe<SeriesImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "seriesImage" */
export type SeriesImageArrRelInsertInput = {
  data: Array<SeriesImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SeriesImageOnConflict>;
};

/** aggregate avg on columns */
export type SeriesImageAvgFields = {
  __typename?: 'seriesImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "seriesImage" */
export type SeriesImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "seriesImage". All fields are combined with a logical 'AND'. */
export type SeriesImageBoolExp = {
  _and?: InputMaybe<Array<SeriesImageBoolExp>>;
  _not?: InputMaybe<SeriesImageBoolExp>;
  _or?: InputMaybe<Array<SeriesImageBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  series?: InputMaybe<SeriesBoolExp>;
  seriesCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "seriesImage" */
export enum SeriesImageConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SeriesImageCuidKey = 'seriesImage_cuid_key',
  /** unique or primary key constraint on columns "id" */
  SeriesImagePkey = 'seriesImage_pkey'
}

/** input type for incrementing numeric columns in table "seriesImage" */
export type SeriesImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "seriesImage" */
export type SeriesImageInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  series?: InputMaybe<SeriesObjRelInsertInput>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SeriesImageMaxFields = {
  __typename?: 'seriesImage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "seriesImage" */
export type SeriesImageMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SeriesImageMinFields = {
  __typename?: 'seriesImage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "seriesImage" */
export type SeriesImageMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "seriesImage" */
export type SeriesImageMutationResponse = {
  __typename?: 'seriesImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeriesImage>;
};

/** on_conflict condition type for table "seriesImage" */
export type SeriesImageOnConflict = {
  constraint: SeriesImageConstraint;
  update_columns?: Array<SeriesImageUpdateColumn>;
  where?: InputMaybe<SeriesImageBoolExp>;
};

/** Ordering options when selecting data from "seriesImage". */
export type SeriesImageOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  series?: InputMaybe<SeriesOrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: seriesImage */
export type SeriesImagePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "seriesImage" */
export enum SeriesImageSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "seriesImage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "seriesImage" */
export enum SeriesImageSelectColumnSeriesImageAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "seriesImage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "seriesImage" */
export enum SeriesImageSelectColumnSeriesImageAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "seriesImage" */
export type SeriesImageSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SeriesImageStddevFields = {
  __typename?: 'seriesImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "seriesImage" */
export type SeriesImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SeriesImageStddevPopFields = {
  __typename?: 'seriesImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "seriesImage" */
export type SeriesImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SeriesImageStddevSampFields = {
  __typename?: 'seriesImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "seriesImage" */
export type SeriesImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SeriesImageSumFields = {
  __typename?: 'seriesImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "seriesImage" */
export type SeriesImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "seriesImage" */
export enum SeriesImageUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SeriesImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SeriesImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeriesImageSetInput>;
  where: SeriesImageBoolExp;
};

/** aggregate var_pop on columns */
export type SeriesImageVarPopFields = {
  __typename?: 'seriesImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "seriesImage" */
export type SeriesImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SeriesImageVarSampFields = {
  __typename?: 'seriesImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "seriesImage" */
export type SeriesImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SeriesImageVarianceFields = {
  __typename?: 'seriesImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "seriesImage" */
export type SeriesImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Points Structure for a series */
export type SeriesPointsStructureCustom = Node & {
  __typename?: 'seriesPointsStructureCustom';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  isPreset: Scalars['Boolean'];
  isQualifying: Scalars['Boolean'];
  points: Scalars['jsonb'];
  pointsName: Scalars['String'];
  /** An object relationship */
  series: Series;
  seriesCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Points Structure for a series */
export type SeriesPointsStructureCustomPointsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomConnection = {
  __typename?: 'seriesPointsStructureCustomConnection';
  edges: Array<SeriesPointsStructureCustomEdge>;
  pageInfo: PageInfo;
};

export type SeriesPointsStructureCustomEdge = {
  __typename?: 'seriesPointsStructureCustomEdge';
  cursor: Scalars['String'];
  node: SeriesPointsStructureCustom;
};

/** aggregated selection of "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomAggregate = {
  __typename?: 'seriesPointsStructureCustom_aggregate';
  aggregate?: Maybe<SeriesPointsStructureCustomAggregateFields>;
  nodes: Array<SeriesPointsStructureCustom>;
};

export type SeriesPointsStructureCustomAggregateBoolExp = {
  bool_and?: InputMaybe<SeriesPointsStructureCustomAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SeriesPointsStructureCustomAggregateBoolExpBoolOr>;
  count?: InputMaybe<SeriesPointsStructureCustomAggregateBoolExpCount>;
};

export type SeriesPointsStructureCustomAggregateBoolExpBoolAnd = {
  arguments: SeriesPointsStructureCustomSelectColumnSeriesPointsStructureCustomAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesPointsStructureCustomAggregateBoolExpBoolOr = {
  arguments: SeriesPointsStructureCustomSelectColumnSeriesPointsStructureCustomAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesPointsStructureCustomAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomAggregateFields = {
  __typename?: 'seriesPointsStructureCustom_aggregate_fields';
  avg?: Maybe<SeriesPointsStructureCustomAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SeriesPointsStructureCustomMaxFields>;
  min?: Maybe<SeriesPointsStructureCustomMinFields>;
  stddev?: Maybe<SeriesPointsStructureCustomStddevFields>;
  stddev_pop?: Maybe<SeriesPointsStructureCustomStddevPopFields>;
  stddev_samp?: Maybe<SeriesPointsStructureCustomStddevSampFields>;
  sum?: Maybe<SeriesPointsStructureCustomSumFields>;
  var_pop?: Maybe<SeriesPointsStructureCustomVarPopFields>;
  var_samp?: Maybe<SeriesPointsStructureCustomVarSampFields>;
  variance?: Maybe<SeriesPointsStructureCustomVarianceFields>;
};


/** aggregate fields of "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomAggregateOrderBy = {
  avg?: InputMaybe<SeriesPointsStructureCustomAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SeriesPointsStructureCustomMaxOrderBy>;
  min?: InputMaybe<SeriesPointsStructureCustomMinOrderBy>;
  stddev?: InputMaybe<SeriesPointsStructureCustomStddevOrderBy>;
  stddev_pop?: InputMaybe<SeriesPointsStructureCustomStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SeriesPointsStructureCustomStddevSampOrderBy>;
  sum?: InputMaybe<SeriesPointsStructureCustomSumOrderBy>;
  var_pop?: InputMaybe<SeriesPointsStructureCustomVarPopOrderBy>;
  var_samp?: InputMaybe<SeriesPointsStructureCustomVarSampOrderBy>;
  variance?: InputMaybe<SeriesPointsStructureCustomVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SeriesPointsStructureCustomAppendInput = {
  points?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomArrRelInsertInput = {
  data: Array<SeriesPointsStructureCustomInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SeriesPointsStructureCustomOnConflict>;
};

/** aggregate avg on columns */
export type SeriesPointsStructureCustomAvgFields = {
  __typename?: 'seriesPointsStructureCustom_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "seriesPointsStructureCustom". All fields are combined with a logical 'AND'. */
export type SeriesPointsStructureCustomBoolExp = {
  _and?: InputMaybe<Array<SeriesPointsStructureCustomBoolExp>>;
  _not?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
  _or?: InputMaybe<Array<SeriesPointsStructureCustomBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isPreset?: InputMaybe<BooleanComparisonExp>;
  isQualifying?: InputMaybe<BooleanComparisonExp>;
  points?: InputMaybe<JsonbComparisonExp>;
  pointsName?: InputMaybe<StringComparisonExp>;
  series?: InputMaybe<SeriesBoolExp>;
  seriesCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "seriesPointsStructureCustom" */
export enum SeriesPointsStructureCustomConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SeriesPointsStructurePkey = 'seriesPointsStructure_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SeriesPointsStructureCustomDeleteAtPathInput = {
  points?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SeriesPointsStructureCustomDeleteElemInput = {
  points?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SeriesPointsStructureCustomDeleteKeyInput = {
  points?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  isQualifying?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['jsonb']>;
  pointsName?: InputMaybe<Scalars['String']>;
  series?: InputMaybe<SeriesObjRelInsertInput>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SeriesPointsStructureCustomMaxFields = {
  __typename?: 'seriesPointsStructureCustom_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pointsName?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pointsName?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SeriesPointsStructureCustomMinFields = {
  __typename?: 'seriesPointsStructureCustom_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  pointsName?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pointsName?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomMutationResponse = {
  __typename?: 'seriesPointsStructureCustom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeriesPointsStructureCustom>;
};

/** on_conflict condition type for table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomOnConflict = {
  constraint: SeriesPointsStructureCustomConstraint;
  update_columns?: Array<SeriesPointsStructureCustomUpdateColumn>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};

/** Ordering options when selecting data from "seriesPointsStructureCustom". */
export type SeriesPointsStructureCustomOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isPreset?: InputMaybe<OrderBy>;
  isQualifying?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  pointsName?: InputMaybe<OrderBy>;
  series?: InputMaybe<SeriesOrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: seriesPointsStructureCustom */
export type SeriesPointsStructureCustomPkColumnsInput = {
  cuid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SeriesPointsStructureCustomPrependInput = {
  points?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "seriesPointsStructureCustom" */
export enum SeriesPointsStructureCustomSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  IsQualifying = 'isQualifying',
  /** column name */
  Points = 'points',
  /** column name */
  PointsName = 'pointsName',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "seriesPointsStructureCustom_aggregate_bool_exp_bool_and_arguments_columns" columns of table "seriesPointsStructureCustom" */
export enum SeriesPointsStructureCustomSelectColumnSeriesPointsStructureCustomAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  IsQualifying = 'isQualifying'
}

/** select "seriesPointsStructureCustom_aggregate_bool_exp_bool_or_arguments_columns" columns of table "seriesPointsStructureCustom" */
export enum SeriesPointsStructureCustomSelectColumnSeriesPointsStructureCustomAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  IsQualifying = 'isQualifying'
}

/** input type for updating data in table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  isQualifying?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['jsonb']>;
  pointsName?: InputMaybe<Scalars['String']>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SeriesPointsStructureCustomStddevFields = {
  __typename?: 'seriesPointsStructureCustom_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SeriesPointsStructureCustomStddevPopFields = {
  __typename?: 'seriesPointsStructureCustom_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SeriesPointsStructureCustomStddevSampFields = {
  __typename?: 'seriesPointsStructureCustom_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SeriesPointsStructureCustomSumFields = {
  __typename?: 'seriesPointsStructureCustom_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "seriesPointsStructureCustom" */
export enum SeriesPointsStructureCustomUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  IsQualifying = 'isQualifying',
  /** column name */
  Points = 'points',
  /** column name */
  PointsName = 'pointsName',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SeriesPointsStructureCustomUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SeriesPointsStructureCustomAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SeriesPointsStructureCustomDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SeriesPointsStructureCustomDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SeriesPointsStructureCustomDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SeriesPointsStructureCustomIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SeriesPointsStructureCustomPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeriesPointsStructureCustomSetInput>;
  where: SeriesPointsStructureCustomBoolExp;
};

/** aggregate var_pop on columns */
export type SeriesPointsStructureCustomVarPopFields = {
  __typename?: 'seriesPointsStructureCustom_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SeriesPointsStructureCustomVarSampFields = {
  __typename?: 'seriesPointsStructureCustom_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SeriesPointsStructureCustomVarianceFields = {
  __typename?: 'seriesPointsStructureCustom_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "seriesPointsStructureCustom" */
export type SeriesPointsStructureCustomVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Maps presets to a series. Presets are hardcoded JSON objects on the client */
export type SeriesPointsStructurePreset = Node & {
  __typename?: 'seriesPointsStructurePreset';
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  isPreset: Scalars['Boolean'];
  presetCuid: Scalars['String'];
  /** An object relationship */
  series: Series;
  seriesCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetConnection = {
  __typename?: 'seriesPointsStructurePresetConnection';
  edges: Array<SeriesPointsStructurePresetEdge>;
  pageInfo: PageInfo;
};

export type SeriesPointsStructurePresetEdge = {
  __typename?: 'seriesPointsStructurePresetEdge';
  cursor: Scalars['String'];
  node: SeriesPointsStructurePreset;
};

/** aggregated selection of "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetAggregate = {
  __typename?: 'seriesPointsStructurePreset_aggregate';
  aggregate?: Maybe<SeriesPointsStructurePresetAggregateFields>;
  nodes: Array<SeriesPointsStructurePreset>;
};

export type SeriesPointsStructurePresetAggregateBoolExp = {
  bool_and?: InputMaybe<SeriesPointsStructurePresetAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SeriesPointsStructurePresetAggregateBoolExpBoolOr>;
  count?: InputMaybe<SeriesPointsStructurePresetAggregateBoolExpCount>;
};

export type SeriesPointsStructurePresetAggregateBoolExpBoolAnd = {
  arguments: SeriesPointsStructurePresetSelectColumnSeriesPointsStructurePresetAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesPointsStructurePresetAggregateBoolExpBoolOr = {
  arguments: SeriesPointsStructurePresetSelectColumnSeriesPointsStructurePresetAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesPointsStructurePresetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetAggregateFields = {
  __typename?: 'seriesPointsStructurePreset_aggregate_fields';
  avg?: Maybe<SeriesPointsStructurePresetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SeriesPointsStructurePresetMaxFields>;
  min?: Maybe<SeriesPointsStructurePresetMinFields>;
  stddev?: Maybe<SeriesPointsStructurePresetStddevFields>;
  stddev_pop?: Maybe<SeriesPointsStructurePresetStddevPopFields>;
  stddev_samp?: Maybe<SeriesPointsStructurePresetStddevSampFields>;
  sum?: Maybe<SeriesPointsStructurePresetSumFields>;
  var_pop?: Maybe<SeriesPointsStructurePresetVarPopFields>;
  var_samp?: Maybe<SeriesPointsStructurePresetVarSampFields>;
  variance?: Maybe<SeriesPointsStructurePresetVarianceFields>;
};


/** aggregate fields of "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetAggregateOrderBy = {
  avg?: InputMaybe<SeriesPointsStructurePresetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SeriesPointsStructurePresetMaxOrderBy>;
  min?: InputMaybe<SeriesPointsStructurePresetMinOrderBy>;
  stddev?: InputMaybe<SeriesPointsStructurePresetStddevOrderBy>;
  stddev_pop?: InputMaybe<SeriesPointsStructurePresetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SeriesPointsStructurePresetStddevSampOrderBy>;
  sum?: InputMaybe<SeriesPointsStructurePresetSumOrderBy>;
  var_pop?: InputMaybe<SeriesPointsStructurePresetVarPopOrderBy>;
  var_samp?: InputMaybe<SeriesPointsStructurePresetVarSampOrderBy>;
  variance?: InputMaybe<SeriesPointsStructurePresetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetArrRelInsertInput = {
  data: Array<SeriesPointsStructurePresetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SeriesPointsStructurePresetOnConflict>;
};

/** aggregate avg on columns */
export type SeriesPointsStructurePresetAvgFields = {
  __typename?: 'seriesPointsStructurePreset_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "seriesPointsStructurePreset". All fields are combined with a logical 'AND'. */
export type SeriesPointsStructurePresetBoolExp = {
  _and?: InputMaybe<Array<SeriesPointsStructurePresetBoolExp>>;
  _not?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
  _or?: InputMaybe<Array<SeriesPointsStructurePresetBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isPreset?: InputMaybe<BooleanComparisonExp>;
  presetCuid?: InputMaybe<StringComparisonExp>;
  series?: InputMaybe<SeriesBoolExp>;
  seriesCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "seriesPointsStructurePreset" */
export enum SeriesPointsStructurePresetConstraint {
  /** unique or primary key constraint on columns "id" */
  SeriesPointsStructurePresetPkey = 'seriesPointsStructurePreset_pkey'
}

/** input type for incrementing numeric columns in table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  presetCuid?: InputMaybe<Scalars['String']>;
  series?: InputMaybe<SeriesObjRelInsertInput>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SeriesPointsStructurePresetMaxFields = {
  __typename?: 'seriesPointsStructurePreset_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  presetCuid?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  presetCuid?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SeriesPointsStructurePresetMinFields = {
  __typename?: 'seriesPointsStructurePreset_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  presetCuid?: Maybe<Scalars['String']>;
  seriesCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  presetCuid?: InputMaybe<OrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetMutationResponse = {
  __typename?: 'seriesPointsStructurePreset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeriesPointsStructurePreset>;
};

/** on_conflict condition type for table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetOnConflict = {
  constraint: SeriesPointsStructurePresetConstraint;
  update_columns?: Array<SeriesPointsStructurePresetUpdateColumn>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};

/** Ordering options when selecting data from "seriesPointsStructurePreset". */
export type SeriesPointsStructurePresetOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isPreset?: InputMaybe<OrderBy>;
  presetCuid?: InputMaybe<OrderBy>;
  series?: InputMaybe<SeriesOrderBy>;
  seriesCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: seriesPointsStructurePreset */
export type SeriesPointsStructurePresetPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "seriesPointsStructurePreset" */
export enum SeriesPointsStructurePresetSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  PresetCuid = 'presetCuid',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "seriesPointsStructurePreset_aggregate_bool_exp_bool_and_arguments_columns" columns of table "seriesPointsStructurePreset" */
export enum SeriesPointsStructurePresetSelectColumnSeriesPointsStructurePresetAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** select "seriesPointsStructurePreset_aggregate_bool_exp_bool_or_arguments_columns" columns of table "seriesPointsStructurePreset" */
export enum SeriesPointsStructurePresetSelectColumnSeriesPointsStructurePresetAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPreset = 'isPreset'
}

/** input type for updating data in table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  isPreset?: InputMaybe<Scalars['Boolean']>;
  presetCuid?: InputMaybe<Scalars['String']>;
  seriesCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SeriesPointsStructurePresetStddevFields = {
  __typename?: 'seriesPointsStructurePreset_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SeriesPointsStructurePresetStddevPopFields = {
  __typename?: 'seriesPointsStructurePreset_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SeriesPointsStructurePresetStddevSampFields = {
  __typename?: 'seriesPointsStructurePreset_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SeriesPointsStructurePresetSumFields = {
  __typename?: 'seriesPointsStructurePreset_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "seriesPointsStructurePreset" */
export enum SeriesPointsStructurePresetUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreset = 'isPreset',
  /** column name */
  PresetCuid = 'presetCuid',
  /** column name */
  SeriesCuid = 'seriesCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SeriesPointsStructurePresetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SeriesPointsStructurePresetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeriesPointsStructurePresetSetInput>;
  where: SeriesPointsStructurePresetBoolExp;
};

/** aggregate var_pop on columns */
export type SeriesPointsStructurePresetVarPopFields = {
  __typename?: 'seriesPointsStructurePreset_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SeriesPointsStructurePresetVarSampFields = {
  __typename?: 'seriesPointsStructurePreset_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SeriesPointsStructurePresetVarianceFields = {
  __typename?: 'seriesPointsStructurePreset_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "seriesPointsStructurePreset" */
export type SeriesPointsStructurePresetVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "series" */
export type SeriesAggregate = {
  __typename?: 'series_aggregate';
  aggregate?: Maybe<SeriesAggregateFields>;
  nodes: Array<Series>;
};

export type SeriesAggregateBoolExp = {
  bool_and?: InputMaybe<SeriesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SeriesAggregateBoolExpBoolOr>;
  count?: InputMaybe<SeriesAggregateBoolExpCount>;
};

export type SeriesAggregateBoolExpBoolAnd = {
  arguments: SeriesSelectColumnSeriesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesAggregateBoolExpBoolOr = {
  arguments: SeriesSelectColumnSeriesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SeriesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SeriesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "series" */
export type SeriesAggregateFields = {
  __typename?: 'series_aggregate_fields';
  avg?: Maybe<SeriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SeriesMaxFields>;
  min?: Maybe<SeriesMinFields>;
  stddev?: Maybe<SeriesStddevFields>;
  stddev_pop?: Maybe<SeriesStddevPopFields>;
  stddev_samp?: Maybe<SeriesStddevSampFields>;
  sum?: Maybe<SeriesSumFields>;
  var_pop?: Maybe<SeriesVarPopFields>;
  var_samp?: Maybe<SeriesVarSampFields>;
  variance?: Maybe<SeriesVarianceFields>;
};


/** aggregate fields of "series" */
export type SeriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "series" */
export type SeriesAggregateOrderBy = {
  avg?: InputMaybe<SeriesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SeriesMaxOrderBy>;
  min?: InputMaybe<SeriesMinOrderBy>;
  stddev?: InputMaybe<SeriesStddevOrderBy>;
  stddev_pop?: InputMaybe<SeriesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SeriesStddevSampOrderBy>;
  sum?: InputMaybe<SeriesSumOrderBy>;
  var_pop?: InputMaybe<SeriesVarPopOrderBy>;
  var_samp?: InputMaybe<SeriesVarSampOrderBy>;
  variance?: InputMaybe<SeriesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "series" */
export type SeriesArrRelInsertInput = {
  data: Array<SeriesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SeriesOnConflict>;
};

/** aggregate avg on columns */
export type SeriesAvgFields = {
  __typename?: 'series_avg_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "series" */
export type SeriesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "series". All fields are combined with a logical 'AND'. */
export type SeriesBoolExp = {
  _and?: InputMaybe<Array<SeriesBoolExp>>;
  _not?: InputMaybe<SeriesBoolExp>;
  _or?: InputMaybe<Array<SeriesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  eventSeries?: InputMaybe<EventSeriesBoolExp>;
  eventSeries_aggregate?: InputMaybe<EventSeriesAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  isJoinable?: InputMaybe<BooleanComparisonExp>;
  joinKey?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  seriesActivity?: InputMaybe<StringComparisonExp>;
  seriesImages?: InputMaybe<SeriesImageBoolExp>;
  seriesImages_aggregate?: InputMaybe<SeriesImageAggregateBoolExp>;
  seriesPointsStructureCustoms?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
  seriesPointsStructureCustoms_aggregate?: InputMaybe<SeriesPointsStructureCustomAggregateBoolExp>;
  seriesPointsStructurePresets?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
  seriesPointsStructurePresets_aggregate?: InputMaybe<SeriesPointsStructurePresetAggregateBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  totalRounds?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "series" */
export enum SeriesConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SeriesCuidKey = 'series_cuid_key',
  /** unique or primary key constraint on columns "slug", "organizerCuid" */
  SeriesOrganizerCuidSlugKey = 'series_organizerCuid_slug_key',
  /** unique or primary key constraint on columns "cuid" */
  SeriesPkey = 'series_pkey',
  /** unique or primary key constraint on columns "joinKey" */
  SeriesUniqueIdKey = 'series_uniqueId_key'
}

/** input type for incrementing numeric columns in table "series" */
export type SeriesIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  totalRounds?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "series" */
export type SeriesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  eventSeries?: InputMaybe<EventSeriesArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  isJoinable?: InputMaybe<Scalars['Boolean']>;
  joinKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  seriesActivity?: InputMaybe<Scalars['String']>;
  seriesImages?: InputMaybe<SeriesImageArrRelInsertInput>;
  seriesPointsStructureCustoms?: InputMaybe<SeriesPointsStructureCustomArrRelInsertInput>;
  seriesPointsStructurePresets?: InputMaybe<SeriesPointsStructurePresetArrRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  totalRounds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SeriesMaxFields = {
  __typename?: 'series_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  joinKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  seriesActivity?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  totalRounds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "series" */
export type SeriesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  joinKey?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  seriesActivity?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SeriesMinFields = {
  __typename?: 'series_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  joinKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  seriesActivity?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  totalRounds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "series" */
export type SeriesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  joinKey?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  seriesActivity?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "series" */
export type SeriesMutationResponse = {
  __typename?: 'series_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Series>;
};

/** input type for inserting object relation for remote table "series" */
export type SeriesObjRelInsertInput = {
  data: SeriesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SeriesOnConflict>;
};

/** on_conflict condition type for table "series" */
export type SeriesOnConflict = {
  constraint: SeriesConstraint;
  update_columns?: Array<SeriesUpdateColumn>;
  where?: InputMaybe<SeriesBoolExp>;
};

/** Ordering options when selecting data from "series". */
export type SeriesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventSeries_aggregate?: InputMaybe<EventSeriesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isJoinable?: InputMaybe<OrderBy>;
  joinKey?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  seriesActivity?: InputMaybe<OrderBy>;
  seriesImages_aggregate?: InputMaybe<SeriesImageAggregateOrderBy>;
  seriesPointsStructureCustoms_aggregate?: InputMaybe<SeriesPointsStructureCustomAggregateOrderBy>;
  seriesPointsStructurePresets_aggregate?: InputMaybe<SeriesPointsStructurePresetAggregateOrderBy>;
  slug?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: series */
export type SeriesPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "series" */
export enum SeriesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsJoinable = 'isJoinable',
  /** column name */
  JoinKey = 'joinKey',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  SeriesActivity = 'seriesActivity',
  /** column name */
  Slug = 'slug',
  /** column name */
  TotalRounds = 'totalRounds',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "series_aggregate_bool_exp_bool_and_arguments_columns" columns of table "series" */
export enum SeriesSelectColumnSeriesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsJoinable = 'isJoinable'
}

/** select "series_aggregate_bool_exp_bool_or_arguments_columns" columns of table "series" */
export enum SeriesSelectColumnSeriesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsJoinable = 'isJoinable'
}

/** input type for updating data in table "series" */
export type SeriesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isJoinable?: InputMaybe<Scalars['Boolean']>;
  joinKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  seriesActivity?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  totalRounds?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SeriesStddevFields = {
  __typename?: 'series_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "series" */
export type SeriesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SeriesStddevPopFields = {
  __typename?: 'series_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "series" */
export type SeriesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SeriesStddevSampFields = {
  __typename?: 'series_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "series" */
export type SeriesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SeriesSumFields = {
  __typename?: 'series_sum_fields';
  id?: Maybe<Scalars['Int']>;
  totalRounds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "series" */
export type SeriesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** update columns of table "series" */
export enum SeriesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsJoinable = 'isJoinable',
  /** column name */
  JoinKey = 'joinKey',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  SeriesActivity = 'seriesActivity',
  /** column name */
  Slug = 'slug',
  /** column name */
  TotalRounds = 'totalRounds',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SeriesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SeriesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeriesSetInput>;
  where: SeriesBoolExp;
};

/** aggregate var_pop on columns */
export type SeriesVarPopFields = {
  __typename?: 'series_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "series" */
export type SeriesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SeriesVarSampFields = {
  __typename?: 'series_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "series" */
export type SeriesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SeriesVarianceFields = {
  __typename?: 'series_variance_fields';
  id?: Maybe<Scalars['Float']>;
  totalRounds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "series" */
export type SeriesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  totalRounds?: InputMaybe<OrderBy>;
};

/** columns and relationships of "signatureRequest" */
export type SignatureRequest = Node & {
  __typename?: 'signatureRequest';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  registration?: Maybe<Registration>;
  registrationCuid: Scalars['String'];
  signatureId: Scalars['String'];
  signatureRequestId: Scalars['String'];
  /** An object relationship */
  signatureRequestSignerType: SignatureRequestSignerType;
  /** An object relationship */
  signatureRequestStatus: SignatureRequestStatus;
  signerEmail: Scalars['String'];
  signerName: Scalars['String'];
  signerType: SignatureRequestSignerTypeEnum;
  status: SignatureRequestStatusEnum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  waiverRevision?: Maybe<WaiverRevision>;
  waiverRevisionCuid: Scalars['String'];
};

/** A Relay connection object on "signatureRequest" */
export type SignatureRequestConnection = {
  __typename?: 'signatureRequestConnection';
  edges: Array<SignatureRequestEdge>;
  pageInfo: PageInfo;
};

export type SignatureRequestEdge = {
  __typename?: 'signatureRequestEdge';
  cursor: Scalars['String'];
  node: SignatureRequest;
};

/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerType = Node & {
  __typename?: 'signatureRequestSignerType';
  /** An array relationship */
  basicSignatureRequests: Array<BasicSignatureRequest>;
  /** An aggregate relationship */
  basicSignatureRequests_aggregate: BasicSignatureRequestAggregate;
  /** An array relationship connection */
  basicSignatureRequests_connection: BasicSignatureRequestConnection;
  id: Scalars['ID'];
  /** An array relationship */
  signatureRequests: Array<SignatureRequest>;
  /** An aggregate relationship */
  signatureRequests_aggregate: SignatureRequestAggregate;
  /** An array relationship connection */
  signatureRequests_connection: SignatureRequestConnection;
  type: Scalars['String'];
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeBasicSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeBasicSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeBasicSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestSignerType" */
export type SignatureRequestSignerTypeSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};

/** A Relay connection object on "signatureRequestSignerType" */
export type SignatureRequestSignerTypeConnection = {
  __typename?: 'signatureRequestSignerTypeConnection';
  edges: Array<SignatureRequestSignerTypeEdge>;
  pageInfo: PageInfo;
};

export type SignatureRequestSignerTypeEdge = {
  __typename?: 'signatureRequestSignerTypeEdge';
  cursor: Scalars['String'];
  node: SignatureRequestSignerType;
};

/** Boolean expression to filter rows from the table "signatureRequestSignerType". All fields are combined with a logical 'AND'. */
export type SignatureRequestSignerTypeBoolExp = {
  _and?: InputMaybe<Array<SignatureRequestSignerTypeBoolExp>>;
  _not?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
  _or?: InputMaybe<Array<SignatureRequestSignerTypeBoolExp>>;
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestBoolExp>;
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateBoolExp>;
  signatureRequests?: InputMaybe<SignatureRequestBoolExp>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "signatureRequestSignerType" */
export enum SignatureRequestSignerTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  SigatureRequestSignerTypePkey = 'sigatureRequestSignerType_pkey'
}

export enum SignatureRequestSignerTypeEnum {
  Emailed = 'EMAILED',
  EmailedParentGuardian = 'EMAILED_PARENT_GUARDIAN',
  ParentGuardian = 'PARENT_GUARDIAN',
  Signer = 'SIGNER'
}

/** Boolean expression to compare columns of type "signatureRequestSignerType_enum". All fields are combined with logical 'AND'. */
export type SignatureRequestSignerTypeEnumComparisonExp = {
  _eq?: InputMaybe<SignatureRequestSignerTypeEnum>;
  _in?: InputMaybe<Array<SignatureRequestSignerTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SignatureRequestSignerTypeEnum>;
  _nin?: InputMaybe<Array<SignatureRequestSignerTypeEnum>>;
};

/** input type for inserting data into table "signatureRequestSignerType" */
export type SignatureRequestSignerTypeInsertInput = {
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestArrRelInsertInput>;
  signatureRequests?: InputMaybe<SignatureRequestArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "signatureRequestSignerType" */
export type SignatureRequestSignerTypeMutationResponse = {
  __typename?: 'signatureRequestSignerType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignatureRequestSignerType>;
};

/** input type for inserting object relation for remote table "signatureRequestSignerType" */
export type SignatureRequestSignerTypeObjRelInsertInput = {
  data: SignatureRequestSignerTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SignatureRequestSignerTypeOnConflict>;
};

/** on_conflict condition type for table "signatureRequestSignerType" */
export type SignatureRequestSignerTypeOnConflict = {
  constraint: SignatureRequestSignerTypeConstraint;
  update_columns?: Array<SignatureRequestSignerTypeUpdateColumn>;
  where?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
};

/** Ordering options when selecting data from "signatureRequestSignerType". */
export type SignatureRequestSignerTypeOrderBy = {
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateOrderBy>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: signatureRequestSignerType */
export type SignatureRequestSignerTypePkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "signatureRequestSignerType" */
export enum SignatureRequestSignerTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "signatureRequestSignerType" */
export type SignatureRequestSignerTypeSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "signatureRequestSignerType" */
export enum SignatureRequestSignerTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export type SignatureRequestSignerTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignatureRequestSignerTypeSetInput>;
  where: SignatureRequestSignerTypeBoolExp;
};

/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatus = Node & {
  __typename?: 'signatureRequestStatus';
  /** An array relationship */
  basicSignatureRequests: Array<BasicSignatureRequest>;
  /** An aggregate relationship */
  basicSignatureRequests_aggregate: BasicSignatureRequestAggregate;
  /** An array relationship connection */
  basicSignatureRequests_connection: BasicSignatureRequestConnection;
  description: Scalars['String'];
  id: Scalars['ID'];
  /** An array relationship */
  signatureRequests: Array<SignatureRequest>;
  /** An aggregate relationship */
  signatureRequests_aggregate: SignatureRequestAggregate;
  /** An array relationship connection */
  signatureRequests_connection: SignatureRequestConnection;
  type: Scalars['String'];
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusBasicSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusBasicSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusBasicSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusSignatureRequestsArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusSignatureRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


/** columns and relationships of "signatureRequestStatus" */
export type SignatureRequestStatusSignatureRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};

/** A Relay connection object on "signatureRequestStatus" */
export type SignatureRequestStatusConnection = {
  __typename?: 'signatureRequestStatusConnection';
  edges: Array<SignatureRequestStatusEdge>;
  pageInfo: PageInfo;
};

export type SignatureRequestStatusEdge = {
  __typename?: 'signatureRequestStatusEdge';
  cursor: Scalars['String'];
  node: SignatureRequestStatus;
};

/** Boolean expression to filter rows from the table "signatureRequestStatus". All fields are combined with a logical 'AND'. */
export type SignatureRequestStatusBoolExp = {
  _and?: InputMaybe<Array<SignatureRequestStatusBoolExp>>;
  _not?: InputMaybe<SignatureRequestStatusBoolExp>;
  _or?: InputMaybe<Array<SignatureRequestStatusBoolExp>>;
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestBoolExp>;
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  signatureRequests?: InputMaybe<SignatureRequestBoolExp>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "signatureRequestStatus" */
export enum SignatureRequestStatusConstraint {
  /** unique or primary key constraint on columns "type" */
  SignatureRequestStatusPkey = 'signatureRequestStatus_pkey'
}

export enum SignatureRequestStatusEnum {
  /** signer did not sign the contract and no longer needs to do so */
  Canceled = 'CANCELED',
  /** signer has signed the contract */
  Complete = 'COMPLETE',
  /** awaiting signature from signer */
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "signatureRequestStatus_enum". All fields are combined with logical 'AND'. */
export type SignatureRequestStatusEnumComparisonExp = {
  _eq?: InputMaybe<SignatureRequestStatusEnum>;
  _in?: InputMaybe<Array<SignatureRequestStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SignatureRequestStatusEnum>;
  _nin?: InputMaybe<Array<SignatureRequestStatusEnum>>;
};

/** input type for inserting data into table "signatureRequestStatus" */
export type SignatureRequestStatusInsertInput = {
  basicSignatureRequests?: InputMaybe<BasicSignatureRequestArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  signatureRequests?: InputMaybe<SignatureRequestArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "signatureRequestStatus" */
export type SignatureRequestStatusMutationResponse = {
  __typename?: 'signatureRequestStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignatureRequestStatus>;
};

/** input type for inserting object relation for remote table "signatureRequestStatus" */
export type SignatureRequestStatusObjRelInsertInput = {
  data: SignatureRequestStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SignatureRequestStatusOnConflict>;
};

/** on_conflict condition type for table "signatureRequestStatus" */
export type SignatureRequestStatusOnConflict = {
  constraint: SignatureRequestStatusConstraint;
  update_columns?: Array<SignatureRequestStatusUpdateColumn>;
  where?: InputMaybe<SignatureRequestStatusBoolExp>;
};

/** Ordering options when selecting data from "signatureRequestStatus". */
export type SignatureRequestStatusOrderBy = {
  basicSignatureRequests_aggregate?: InputMaybe<BasicSignatureRequestAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  signatureRequests_aggregate?: InputMaybe<SignatureRequestAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: signatureRequestStatus */
export type SignatureRequestStatusPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "signatureRequestStatus" */
export enum SignatureRequestStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "signatureRequestStatus" */
export type SignatureRequestStatusSetInput = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "signatureRequestStatus" */
export enum SignatureRequestStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

export type SignatureRequestStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignatureRequestStatusSetInput>;
  where: SignatureRequestStatusBoolExp;
};

/** aggregated selection of "signatureRequest" */
export type SignatureRequestAggregate = {
  __typename?: 'signatureRequest_aggregate';
  aggregate?: Maybe<SignatureRequestAggregateFields>;
  nodes: Array<SignatureRequest>;
};

export type SignatureRequestAggregateBoolExp = {
  count?: InputMaybe<SignatureRequestAggregateBoolExpCount>;
};

export type SignatureRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SignatureRequestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "signatureRequest" */
export type SignatureRequestAggregateFields = {
  __typename?: 'signatureRequest_aggregate_fields';
  avg?: Maybe<SignatureRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SignatureRequestMaxFields>;
  min?: Maybe<SignatureRequestMinFields>;
  stddev?: Maybe<SignatureRequestStddevFields>;
  stddev_pop?: Maybe<SignatureRequestStddevPopFields>;
  stddev_samp?: Maybe<SignatureRequestStddevSampFields>;
  sum?: Maybe<SignatureRequestSumFields>;
  var_pop?: Maybe<SignatureRequestVarPopFields>;
  var_samp?: Maybe<SignatureRequestVarSampFields>;
  variance?: Maybe<SignatureRequestVarianceFields>;
};


/** aggregate fields of "signatureRequest" */
export type SignatureRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "signatureRequest" */
export type SignatureRequestAggregateOrderBy = {
  avg?: InputMaybe<SignatureRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SignatureRequestMaxOrderBy>;
  min?: InputMaybe<SignatureRequestMinOrderBy>;
  stddev?: InputMaybe<SignatureRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<SignatureRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SignatureRequestStddevSampOrderBy>;
  sum?: InputMaybe<SignatureRequestSumOrderBy>;
  var_pop?: InputMaybe<SignatureRequestVarPopOrderBy>;
  var_samp?: InputMaybe<SignatureRequestVarSampOrderBy>;
  variance?: InputMaybe<SignatureRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "signatureRequest" */
export type SignatureRequestArrRelInsertInput = {
  data: Array<SignatureRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SignatureRequestOnConflict>;
};

/** aggregate avg on columns */
export type SignatureRequestAvgFields = {
  __typename?: 'signatureRequest_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "signatureRequest" */
export type SignatureRequestAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "signatureRequest". All fields are combined with a logical 'AND'. */
export type SignatureRequestBoolExp = {
  _and?: InputMaybe<Array<SignatureRequestBoolExp>>;
  _not?: InputMaybe<SignatureRequestBoolExp>;
  _or?: InputMaybe<Array<SignatureRequestBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  registration?: InputMaybe<RegistrationBoolExp>;
  registrationCuid?: InputMaybe<StringComparisonExp>;
  signatureId?: InputMaybe<StringComparisonExp>;
  signatureRequestId?: InputMaybe<StringComparisonExp>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusBoolExp>;
  signerEmail?: InputMaybe<StringComparisonExp>;
  signerName?: InputMaybe<StringComparisonExp>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnumComparisonExp>;
  status?: InputMaybe<SignatureRequestStatusEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  waiverRevision?: InputMaybe<WaiverRevisionBoolExp>;
  waiverRevisionCuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "signatureRequest" */
export enum SignatureRequestConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SignatureRequestsCuidKey = 'signatureRequests_cuid_key',
  /** unique or primary key constraint on columns "id" */
  SignatureRequestsPkey = 'signatureRequests_pkey',
  /** unique or primary key constraint on columns "signatureId" */
  SignatureRequestsSignatureIdKey = 'signatureRequests_signatureId_key'
}

/** input type for incrementing numeric columns in table "signatureRequest" */
export type SignatureRequestIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "signatureRequest" */
export type SignatureRequestInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<RegistrationObjRelInsertInput>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  signatureId?: InputMaybe<Scalars['String']>;
  signatureRequestId?: InputMaybe<Scalars['String']>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeObjRelInsertInput>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusObjRelInsertInput>;
  signerEmail?: InputMaybe<Scalars['String']>;
  signerName?: InputMaybe<Scalars['String']>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnum>;
  status?: InputMaybe<SignatureRequestStatusEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverRevision?: InputMaybe<WaiverRevisionObjRelInsertInput>;
  waiverRevisionCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SignatureRequestMaxFields = {
  __typename?: 'signatureRequest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  signerEmail?: Maybe<Scalars['String']>;
  signerName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiverRevisionCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "signatureRequest" */
export type SignatureRequestMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  signatureRequestId?: InputMaybe<OrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverRevisionCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SignatureRequestMinFields = {
  __typename?: 'signatureRequest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  registrationCuid?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  signerEmail?: Maybe<Scalars['String']>;
  signerName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  waiverRevisionCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "signatureRequest" */
export type SignatureRequestMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  signatureRequestId?: InputMaybe<OrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverRevisionCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "signatureRequest" */
export type SignatureRequestMutationResponse = {
  __typename?: 'signatureRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignatureRequest>;
};

/** on_conflict condition type for table "signatureRequest" */
export type SignatureRequestOnConflict = {
  constraint: SignatureRequestConstraint;
  update_columns?: Array<SignatureRequestUpdateColumn>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};

/** Ordering options when selecting data from "signatureRequest". */
export type SignatureRequestOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  registration?: InputMaybe<RegistrationOrderBy>;
  registrationCuid?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  signatureRequestId?: InputMaybe<OrderBy>;
  signatureRequestSignerType?: InputMaybe<SignatureRequestSignerTypeOrderBy>;
  signatureRequestStatus?: InputMaybe<SignatureRequestStatusOrderBy>;
  signerEmail?: InputMaybe<OrderBy>;
  signerName?: InputMaybe<OrderBy>;
  signerType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverRevision?: InputMaybe<WaiverRevisionOrderBy>;
  waiverRevisionCuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: signatureRequest */
export type SignatureRequestPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "signatureRequest" */
export enum SignatureRequestSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  SignatureRequestId = 'signatureRequestId',
  /** column name */
  SignerEmail = 'signerEmail',
  /** column name */
  SignerName = 'signerName',
  /** column name */
  SignerType = 'signerType',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverRevisionCuid = 'waiverRevisionCuid'
}

/** input type for updating data in table "signatureRequest" */
export type SignatureRequestSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  registrationCuid?: InputMaybe<Scalars['String']>;
  signatureId?: InputMaybe<Scalars['String']>;
  signatureRequestId?: InputMaybe<Scalars['String']>;
  signerEmail?: InputMaybe<Scalars['String']>;
  signerName?: InputMaybe<Scalars['String']>;
  signerType?: InputMaybe<SignatureRequestSignerTypeEnum>;
  status?: InputMaybe<SignatureRequestStatusEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverRevisionCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SignatureRequestStddevFields = {
  __typename?: 'signatureRequest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "signatureRequest" */
export type SignatureRequestStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SignatureRequestStddevPopFields = {
  __typename?: 'signatureRequest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "signatureRequest" */
export type SignatureRequestStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SignatureRequestStddevSampFields = {
  __typename?: 'signatureRequest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "signatureRequest" */
export type SignatureRequestStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SignatureRequestSumFields = {
  __typename?: 'signatureRequest_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "signatureRequest" */
export type SignatureRequestSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "signatureRequest" */
export enum SignatureRequestUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationCuid = 'registrationCuid',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  SignatureRequestId = 'signatureRequestId',
  /** column name */
  SignerEmail = 'signerEmail',
  /** column name */
  SignerName = 'signerName',
  /** column name */
  SignerType = 'signerType',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WaiverRevisionCuid = 'waiverRevisionCuid'
}

export type SignatureRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignatureRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignatureRequestSetInput>;
  where: SignatureRequestBoolExp;
};

/** aggregate var_pop on columns */
export type SignatureRequestVarPopFields = {
  __typename?: 'signatureRequest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "signatureRequest" */
export type SignatureRequestVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SignatureRequestVarSampFields = {
  __typename?: 'signatureRequest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "signatureRequest" */
export type SignatureRequestVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SignatureRequestVarianceFields = {
  __typename?: 'signatureRequest_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "signatureRequest" */
export type SignatureRequestVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** A sponsor for an organization */
export type Sponsor = Node & {
  __typename?: 'sponsor';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  eventSponsors: Array<EventSponsor>;
  /** An aggregate relationship */
  eventSponsors_aggregate: EventSponsorAggregate;
  /** An array relationship connection */
  eventSponsors_connection: EventSponsorConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  /** An array relationship */
  sponsorImages: Array<SponsorImage>;
  /** An aggregate relationship */
  sponsorImages_aggregate: SponsorImageAggregate;
  /** An array relationship connection */
  sponsorImages_connection: SponsorImageConnection;
  updated_at: Scalars['timestamptz'];
  website: Scalars['String'];
};


/** A sponsor for an organization */
export type SponsorEventSponsorsArgs = {
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** A sponsor for an organization */
export type SponsorEventSponsorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** A sponsor for an organization */
export type SponsorEventSponsorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


/** A sponsor for an organization */
export type SponsorSponsorImagesArgs = {
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


/** A sponsor for an organization */
export type SponsorSponsorImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


/** A sponsor for an organization */
export type SponsorSponsorImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};

/** A Relay connection object on "sponsor" */
export type SponsorConnection = {
  __typename?: 'sponsorConnection';
  edges: Array<SponsorEdge>;
  pageInfo: PageInfo;
};

export type SponsorEdge = {
  __typename?: 'sponsorEdge';
  cursor: Scalars['String'];
  node: Sponsor;
};

/** Images for a organization sponsor */
export type SponsorImage = Node & {
  __typename?: 'sponsorImage';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image: Image;
  imagePublicId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  /** An object relationship */
  sponsor: Sponsor;
  sponsorCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** A Relay connection object on "sponsorImage" */
export type SponsorImageConnection = {
  __typename?: 'sponsorImageConnection';
  edges: Array<SponsorImageEdge>;
  pageInfo: PageInfo;
};

export type SponsorImageEdge = {
  __typename?: 'sponsorImageEdge';
  cursor: Scalars['String'];
  node: SponsorImage;
};

/** aggregated selection of "sponsorImage" */
export type SponsorImageAggregate = {
  __typename?: 'sponsorImage_aggregate';
  aggregate?: Maybe<SponsorImageAggregateFields>;
  nodes: Array<SponsorImage>;
};

export type SponsorImageAggregateBoolExp = {
  bool_and?: InputMaybe<SponsorImageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SponsorImageAggregateBoolExpBoolOr>;
  count?: InputMaybe<SponsorImageAggregateBoolExpCount>;
};

export type SponsorImageAggregateBoolExpBoolAnd = {
  arguments: SponsorImageSelectColumnSponsorImageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SponsorImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SponsorImageAggregateBoolExpBoolOr = {
  arguments: SponsorImageSelectColumnSponsorImageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SponsorImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SponsorImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SponsorImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SponsorImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "sponsorImage" */
export type SponsorImageAggregateFields = {
  __typename?: 'sponsorImage_aggregate_fields';
  avg?: Maybe<SponsorImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SponsorImageMaxFields>;
  min?: Maybe<SponsorImageMinFields>;
  stddev?: Maybe<SponsorImageStddevFields>;
  stddev_pop?: Maybe<SponsorImageStddevPopFields>;
  stddev_samp?: Maybe<SponsorImageStddevSampFields>;
  sum?: Maybe<SponsorImageSumFields>;
  var_pop?: Maybe<SponsorImageVarPopFields>;
  var_samp?: Maybe<SponsorImageVarSampFields>;
  variance?: Maybe<SponsorImageVarianceFields>;
};


/** aggregate fields of "sponsorImage" */
export type SponsorImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SponsorImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sponsorImage" */
export type SponsorImageAggregateOrderBy = {
  avg?: InputMaybe<SponsorImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SponsorImageMaxOrderBy>;
  min?: InputMaybe<SponsorImageMinOrderBy>;
  stddev?: InputMaybe<SponsorImageStddevOrderBy>;
  stddev_pop?: InputMaybe<SponsorImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SponsorImageStddevSampOrderBy>;
  sum?: InputMaybe<SponsorImageSumOrderBy>;
  var_pop?: InputMaybe<SponsorImageVarPopOrderBy>;
  var_samp?: InputMaybe<SponsorImageVarSampOrderBy>;
  variance?: InputMaybe<SponsorImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "sponsorImage" */
export type SponsorImageArrRelInsertInput = {
  data: Array<SponsorImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SponsorImageOnConflict>;
};

/** aggregate avg on columns */
export type SponsorImageAvgFields = {
  __typename?: 'sponsorImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sponsorImage" */
export type SponsorImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "sponsorImage". All fields are combined with a logical 'AND'. */
export type SponsorImageBoolExp = {
  _and?: InputMaybe<Array<SponsorImageBoolExp>>;
  _not?: InputMaybe<SponsorImageBoolExp>;
  _or?: InputMaybe<Array<SponsorImageBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  sponsor?: InputMaybe<SponsorBoolExp>;
  sponsorCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sponsorImage" */
export enum SponsorImageConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SponsorImageCuidKey = 'sponsorImage_cuid_key',
  /** unique or primary key constraint on columns "id" */
  SponsorImagePkey = 'sponsorImage_pkey'
}

/** input type for incrementing numeric columns in table "sponsorImage" */
export type SponsorImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sponsorImage" */
export type SponsorImageInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  sponsor?: InputMaybe<SponsorObjRelInsertInput>;
  sponsorCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SponsorImageMaxFields = {
  __typename?: 'sponsorImage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  sponsorCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sponsorImage" */
export type SponsorImageMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SponsorImageMinFields = {
  __typename?: 'sponsorImage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  sponsorCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sponsorImage" */
export type SponsorImageMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "sponsorImage" */
export type SponsorImageMutationResponse = {
  __typename?: 'sponsorImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SponsorImage>;
};

/** on_conflict condition type for table "sponsorImage" */
export type SponsorImageOnConflict = {
  constraint: SponsorImageConstraint;
  update_columns?: Array<SponsorImageUpdateColumn>;
  where?: InputMaybe<SponsorImageBoolExp>;
};

/** Ordering options when selecting data from "sponsorImage". */
export type SponsorImageOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  sponsor?: InputMaybe<SponsorOrderBy>;
  sponsorCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sponsorImage */
export type SponsorImagePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "sponsorImage" */
export enum SponsorImageSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  SponsorCuid = 'sponsorCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "sponsorImage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sponsorImage" */
export enum SponsorImageSelectColumnSponsorImageAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "sponsorImage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sponsorImage" */
export enum SponsorImageSelectColumnSponsorImageAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "sponsorImage" */
export type SponsorImageSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  sponsorCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SponsorImageStddevFields = {
  __typename?: 'sponsorImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sponsorImage" */
export type SponsorImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SponsorImageStddevPopFields = {
  __typename?: 'sponsorImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sponsorImage" */
export type SponsorImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SponsorImageStddevSampFields = {
  __typename?: 'sponsorImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sponsorImage" */
export type SponsorImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SponsorImageSumFields = {
  __typename?: 'sponsorImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sponsorImage" */
export type SponsorImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "sponsorImage" */
export enum SponsorImageUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  SponsorCuid = 'sponsorCuid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SponsorImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SponsorImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SponsorImageSetInput>;
  where: SponsorImageBoolExp;
};

/** aggregate var_pop on columns */
export type SponsorImageVarPopFields = {
  __typename?: 'sponsorImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sponsorImage" */
export type SponsorImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SponsorImageVarSampFields = {
  __typename?: 'sponsorImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sponsorImage" */
export type SponsorImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SponsorImageVarianceFields = {
  __typename?: 'sponsorImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sponsorImage" */
export type SponsorImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "sponsor" */
export type SponsorAggregate = {
  __typename?: 'sponsor_aggregate';
  aggregate?: Maybe<SponsorAggregateFields>;
  nodes: Array<Sponsor>;
};

export type SponsorAggregateBoolExp = {
  count?: InputMaybe<SponsorAggregateBoolExpCount>;
};

export type SponsorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SponsorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SponsorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "sponsor" */
export type SponsorAggregateFields = {
  __typename?: 'sponsor_aggregate_fields';
  avg?: Maybe<SponsorAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SponsorMaxFields>;
  min?: Maybe<SponsorMinFields>;
  stddev?: Maybe<SponsorStddevFields>;
  stddev_pop?: Maybe<SponsorStddevPopFields>;
  stddev_samp?: Maybe<SponsorStddevSampFields>;
  sum?: Maybe<SponsorSumFields>;
  var_pop?: Maybe<SponsorVarPopFields>;
  var_samp?: Maybe<SponsorVarSampFields>;
  variance?: Maybe<SponsorVarianceFields>;
};


/** aggregate fields of "sponsor" */
export type SponsorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SponsorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sponsor" */
export type SponsorAggregateOrderBy = {
  avg?: InputMaybe<SponsorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SponsorMaxOrderBy>;
  min?: InputMaybe<SponsorMinOrderBy>;
  stddev?: InputMaybe<SponsorStddevOrderBy>;
  stddev_pop?: InputMaybe<SponsorStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SponsorStddevSampOrderBy>;
  sum?: InputMaybe<SponsorSumOrderBy>;
  var_pop?: InputMaybe<SponsorVarPopOrderBy>;
  var_samp?: InputMaybe<SponsorVarSampOrderBy>;
  variance?: InputMaybe<SponsorVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "sponsor" */
export type SponsorArrRelInsertInput = {
  data: Array<SponsorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SponsorOnConflict>;
};

/** aggregate avg on columns */
export type SponsorAvgFields = {
  __typename?: 'sponsor_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sponsor" */
export type SponsorAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "sponsor". All fields are combined with a logical 'AND'. */
export type SponsorBoolExp = {
  _and?: InputMaybe<Array<SponsorBoolExp>>;
  _not?: InputMaybe<SponsorBoolExp>;
  _or?: InputMaybe<Array<SponsorBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  eventSponsors?: InputMaybe<EventSponsorBoolExp>;
  eventSponsors_aggregate?: InputMaybe<EventSponsorAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  sponsorImages?: InputMaybe<SponsorImageBoolExp>;
  sponsorImages_aggregate?: InputMaybe<SponsorImageAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "sponsor" */
export enum SponsorConstraint {
  /** unique or primary key constraint on columns "cuid" */
  SponsorCuidKey = 'sponsor_cuid_key',
  /** unique or primary key constraint on columns "id" */
  SponsorPkey = 'sponsor_pkey'
}

/** input type for incrementing numeric columns in table "sponsor" */
export type SponsorIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sponsor" */
export type SponsorInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  eventSponsors?: InputMaybe<EventSponsorArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  sponsorImages?: InputMaybe<SponsorImageArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SponsorMaxFields = {
  __typename?: 'sponsor_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sponsor" */
export type SponsorMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SponsorMinFields = {
  __typename?: 'sponsor_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sponsor" */
export type SponsorMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "sponsor" */
export type SponsorMutationResponse = {
  __typename?: 'sponsor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sponsor>;
};

/** input type for inserting object relation for remote table "sponsor" */
export type SponsorObjRelInsertInput = {
  data: SponsorInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SponsorOnConflict>;
};

/** on_conflict condition type for table "sponsor" */
export type SponsorOnConflict = {
  constraint: SponsorConstraint;
  update_columns?: Array<SponsorUpdateColumn>;
  where?: InputMaybe<SponsorBoolExp>;
};

/** Ordering options when selecting data from "sponsor". */
export type SponsorOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  eventSponsors_aggregate?: InputMaybe<EventSponsorAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  sponsorImages_aggregate?: InputMaybe<SponsorImageAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sponsor */
export type SponsorPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "sponsor" */
export enum SponsorSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "sponsor" */
export type SponsorSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SponsorStddevFields = {
  __typename?: 'sponsor_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sponsor" */
export type SponsorStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SponsorStddevPopFields = {
  __typename?: 'sponsor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sponsor" */
export type SponsorStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SponsorStddevSampFields = {
  __typename?: 'sponsor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sponsor" */
export type SponsorStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type SponsorSumFields = {
  __typename?: 'sponsor_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sponsor" */
export type SponsorSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "sponsor" */
export enum SponsorUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

export type SponsorUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SponsorIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SponsorSetInput>;
  where: SponsorBoolExp;
};

/** aggregate var_pop on columns */
export type SponsorVarPopFields = {
  __typename?: 'sponsor_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sponsor" */
export type SponsorVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SponsorVarSampFields = {
  __typename?: 'sponsor_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sponsor" */
export type SponsorVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SponsorVarianceFields = {
  __typename?: 'sponsor_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sponsor" */
export type SponsorVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccount = Node & {
  __typename?: 'stripeConnectAccount';
  accountId: Scalars['String'];
  chargesEnabled: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  /** An object relationship */
  organizer?: Maybe<Organizer>;
  /** An array relationship */
  organizers: Array<Organizer>;
  /** An aggregate relationship */
  organizers_aggregate: OrganizerAggregate;
  /** An array relationship connection */
  organizers_connection: OrganizerConnection;
  ownerOrganizerCuid?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['String']>;
  payoutsEnabled: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: UserAggregate;
  /** An array relationship connection */
  users_connection: UserConnection;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountOrganizersArgs = {
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountOrganizersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountOrganizersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountUsersArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


/** Stripe Connect Account. Charges and Payouts get updated from Stripe webhook that is triggered from "account.updated" */
export type StripeConnectAccountUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** A Relay connection object on "stripeConnectAccount" */
export type StripeConnectAccountConnection = {
  __typename?: 'stripeConnectAccountConnection';
  edges: Array<StripeConnectAccountEdge>;
  pageInfo: PageInfo;
};

export type StripeConnectAccountEdge = {
  __typename?: 'stripeConnectAccountEdge';
  cursor: Scalars['String'];
  node: StripeConnectAccount;
};

/** aggregated selection of "stripeConnectAccount" */
export type StripeConnectAccountAggregate = {
  __typename?: 'stripeConnectAccount_aggregate';
  aggregate?: Maybe<StripeConnectAccountAggregateFields>;
  nodes: Array<StripeConnectAccount>;
};

export type StripeConnectAccountAggregateBoolExp = {
  bool_and?: InputMaybe<StripeConnectAccountAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<StripeConnectAccountAggregateBoolExpBoolOr>;
  count?: InputMaybe<StripeConnectAccountAggregateBoolExpCount>;
};

export type StripeConnectAccountAggregateBoolExpBoolAnd = {
  arguments: StripeConnectAccountSelectColumnStripeConnectAccountAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StripeConnectAccountBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StripeConnectAccountAggregateBoolExpBoolOr = {
  arguments: StripeConnectAccountSelectColumnStripeConnectAccountAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StripeConnectAccountBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StripeConnectAccountAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StripeConnectAccountBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "stripeConnectAccount" */
export type StripeConnectAccountAggregateFields = {
  __typename?: 'stripeConnectAccount_aggregate_fields';
  avg?: Maybe<StripeConnectAccountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<StripeConnectAccountMaxFields>;
  min?: Maybe<StripeConnectAccountMinFields>;
  stddev?: Maybe<StripeConnectAccountStddevFields>;
  stddev_pop?: Maybe<StripeConnectAccountStddevPopFields>;
  stddev_samp?: Maybe<StripeConnectAccountStddevSampFields>;
  sum?: Maybe<StripeConnectAccountSumFields>;
  var_pop?: Maybe<StripeConnectAccountVarPopFields>;
  var_samp?: Maybe<StripeConnectAccountVarSampFields>;
  variance?: Maybe<StripeConnectAccountVarianceFields>;
};


/** aggregate fields of "stripeConnectAccount" */
export type StripeConnectAccountAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripeConnectAccount" */
export type StripeConnectAccountAggregateOrderBy = {
  avg?: InputMaybe<StripeConnectAccountAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StripeConnectAccountMaxOrderBy>;
  min?: InputMaybe<StripeConnectAccountMinOrderBy>;
  stddev?: InputMaybe<StripeConnectAccountStddevOrderBy>;
  stddev_pop?: InputMaybe<StripeConnectAccountStddevPopOrderBy>;
  stddev_samp?: InputMaybe<StripeConnectAccountStddevSampOrderBy>;
  sum?: InputMaybe<StripeConnectAccountSumOrderBy>;
  var_pop?: InputMaybe<StripeConnectAccountVarPopOrderBy>;
  var_samp?: InputMaybe<StripeConnectAccountVarSampOrderBy>;
  variance?: InputMaybe<StripeConnectAccountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "stripeConnectAccount" */
export type StripeConnectAccountArrRelInsertInput = {
  data: Array<StripeConnectAccountInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<StripeConnectAccountOnConflict>;
};

/** aggregate avg on columns */
export type StripeConnectAccountAvgFields = {
  __typename?: 'stripeConnectAccount_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "stripeConnectAccount". All fields are combined with a logical 'AND'. */
export type StripeConnectAccountBoolExp = {
  _and?: InputMaybe<Array<StripeConnectAccountBoolExp>>;
  _not?: InputMaybe<StripeConnectAccountBoolExp>;
  _or?: InputMaybe<Array<StripeConnectAccountBoolExp>>;
  accountId?: InputMaybe<StringComparisonExp>;
  chargesEnabled?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizers?: InputMaybe<OrganizerBoolExp>;
  organizers_aggregate?: InputMaybe<OrganizerAggregateBoolExp>;
  ownerOrganizerCuid?: InputMaybe<StringComparisonExp>;
  ownerUserId?: InputMaybe<StringComparisonExp>;
  payoutsEnabled?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  users?: InputMaybe<UserBoolExp>;
  users_aggregate?: InputMaybe<UserAggregateBoolExp>;
};

/** unique or primary key constraints on table "stripeConnectAccount" */
export enum StripeConnectAccountConstraint {
  /** unique or primary key constraint on columns "accountId" */
  StripeConnectAccountAccountIdKey = 'stripeConnectAccount_accountId_key',
  /** unique or primary key constraint on columns "ownerUserId", "accountId", "ownerOrganizerCuid" */
  StripeConnectAccountAccountIdOwnerUserIdOwnerOrganizerCuidK = 'stripeConnectAccount_accountId_ownerUserId_ownerOrganizerCuid_k',
  /** unique or primary key constraint on columns "id" */
  StripeConnectAccountPkey = 'stripeConnectAccount_pkey'
}

/** input type for incrementing numeric columns in table "stripeConnectAccount" */
export type StripeConnectAccountIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "stripeConnectAccount" */
export type StripeConnectAccountInsertInput = {
  accountId?: InputMaybe<Scalars['String']>;
  chargesEnabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizers?: InputMaybe<OrganizerArrRelInsertInput>;
  ownerOrganizerCuid?: InputMaybe<Scalars['String']>;
  ownerUserId?: InputMaybe<Scalars['String']>;
  payoutsEnabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  users?: InputMaybe<UserArrRelInsertInput>;
};

/** aggregate max on columns */
export type StripeConnectAccountMaxFields = {
  __typename?: 'stripeConnectAccount_max_fields';
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  ownerOrganizerCuid?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountMaxOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ownerOrganizerCuid?: InputMaybe<OrderBy>;
  ownerUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type StripeConnectAccountMinFields = {
  __typename?: 'stripeConnectAccount_min_fields';
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  ownerOrganizerCuid?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountMinOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ownerOrganizerCuid?: InputMaybe<OrderBy>;
  ownerUserId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "stripeConnectAccount" */
export type StripeConnectAccountMutationResponse = {
  __typename?: 'stripeConnectAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StripeConnectAccount>;
};

/** input type for inserting object relation for remote table "stripeConnectAccount" */
export type StripeConnectAccountObjRelInsertInput = {
  data: StripeConnectAccountInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<StripeConnectAccountOnConflict>;
};

/** on_conflict condition type for table "stripeConnectAccount" */
export type StripeConnectAccountOnConflict = {
  constraint: StripeConnectAccountConstraint;
  update_columns?: Array<StripeConnectAccountUpdateColumn>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};

/** Ordering options when selecting data from "stripeConnectAccount". */
export type StripeConnectAccountOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  chargesEnabled?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizers_aggregate?: InputMaybe<OrganizerAggregateOrderBy>;
  ownerOrganizerCuid?: InputMaybe<OrderBy>;
  ownerUserId?: InputMaybe<OrderBy>;
  payoutsEnabled?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  users_aggregate?: InputMaybe<UserAggregateOrderBy>;
};

/** primary key columns input for table: stripeConnectAccount */
export type StripeConnectAccountPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "stripeConnectAccount" */
export enum StripeConnectAccountSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ChargesEnabled = 'chargesEnabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerOrganizerCuid = 'ownerOrganizerCuid',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  PayoutsEnabled = 'payoutsEnabled',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "stripeConnectAccount_aggregate_bool_exp_bool_and_arguments_columns" columns of table "stripeConnectAccount" */
export enum StripeConnectAccountSelectColumnStripeConnectAccountAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  ChargesEnabled = 'chargesEnabled',
  /** column name */
  PayoutsEnabled = 'payoutsEnabled'
}

/** select "stripeConnectAccount_aggregate_bool_exp_bool_or_arguments_columns" columns of table "stripeConnectAccount" */
export enum StripeConnectAccountSelectColumnStripeConnectAccountAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  ChargesEnabled = 'chargesEnabled',
  /** column name */
  PayoutsEnabled = 'payoutsEnabled'
}

/** input type for updating data in table "stripeConnectAccount" */
export type StripeConnectAccountSetInput = {
  accountId?: InputMaybe<Scalars['String']>;
  chargesEnabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  ownerOrganizerCuid?: InputMaybe<Scalars['String']>;
  ownerUserId?: InputMaybe<Scalars['String']>;
  payoutsEnabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StripeConnectAccountStddevFields = {
  __typename?: 'stripeConnectAccount_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type StripeConnectAccountStddevPopFields = {
  __typename?: 'stripeConnectAccount_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type StripeConnectAccountStddevSampFields = {
  __typename?: 'stripeConnectAccount_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type StripeConnectAccountSumFields = {
  __typename?: 'stripeConnectAccount_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "stripeConnectAccount" */
export enum StripeConnectAccountUpdateColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ChargesEnabled = 'chargesEnabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerOrganizerCuid = 'ownerOrganizerCuid',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  PayoutsEnabled = 'payoutsEnabled',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type StripeConnectAccountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StripeConnectAccountIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StripeConnectAccountSetInput>;
  where: StripeConnectAccountBoolExp;
};

/** aggregate var_pop on columns */
export type StripeConnectAccountVarPopFields = {
  __typename?: 'stripeConnectAccount_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type StripeConnectAccountVarSampFields = {
  __typename?: 'stripeConnectAccount_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type StripeConnectAccountVarianceFields = {
  __typename?: 'stripeConnectAccount_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stripeConnectAccount" */
export type StripeConnectAccountVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "AccessName" */
  AccessName_connection: AccessNameConnection;
  /** fetch data from the table: "activityType" */
  activityType_connection: ActivityTypeConnection;
  /** fetch data from the table: "amenity" */
  amenity_connection: AmenityConnection;
  /** fetch data from the table: "basicSignatureRequest" */
  basicSignatureRequest_connection: BasicSignatureRequestConnection;
  /** fetch data from the table: "categoryCourse" */
  categoryCourse_connection: CategoryCourseConnection;
  /** fetch data from the table: "category" */
  category_connection: CategoryConnection;
  /** fetch data from the table: "changeLogDate" */
  changeLogDate_connection: ChangeLogDateConnection;
  /** fetch data from the table: "changeLogItem" */
  changeLogItem_connection: ChangeLogItemConnection;
  /** fetch data from the table: "clinicCoach" */
  clinicCoach_connection: ClinicCoachConnection;
  /** fetch data from the table: "clinicDay" */
  clinicDay_connection: ClinicDayConnection;
  /** fetch data from the table: "clinic" */
  clinic_connection: ClinicConnection;
  /** fetch data from the table: "coachAvailability" */
  coachAvailability_connection: CoachAvailabilityConnection;
  /** fetch data from the table: "coachCertification" */
  coachCertification_connection: CoachCertificationConnection;
  /** fetch data from the table: "coachCustomerLessonDateLocation" */
  coachCustomerLessonDateLocation_connection: CoachCustomerLessonDateLocationConnection;
  /** fetch data from the table: "coachCustomerLessonDateVirtualLocation" */
  coachCustomerLessonDateVirtualLocation_connection: CoachCustomerLessonDateVirtualLocationConnection;
  /** fetch data from the table: "coachCustomerLessonDate" */
  coachCustomerLessonDate_connection: CoachCustomerLessonDateConnection;
  /** fetch data from the table: "coachCustomerLessonOneTimeCharge" */
  coachCustomerLessonOneTimeCharge_connection: CoachCustomerLessonOneTimeChargeConnection;
  /** fetch data from the table: "coachCustomerLessonPauseResume" */
  coachCustomerLessonPauseResume_connection: CoachCustomerLessonPauseResumeConnection;
  /** fetch data from the table: "coachCustomerLessonTransaction" */
  coachCustomerLessonTransaction_connection: CoachCustomerLessonTransactionConnection;
  /** fetch data from the table: "coachCustomerLesson" */
  coachCustomerLesson_connection: CoachCustomerLessonConnection;
  /** fetch data from the table: "coachCustomerMessageRequest" */
  coachCustomerMessageRequest_connection: CoachCustomerMessageRequestConnection;
  /** fetch data from the table: "coachCustomerUser" */
  coachCustomerUser_connection: CoachCustomerUserConnection;
  /** fetch data from the table: "coachCustomer" */
  coachCustomer_connection: CoachCustomerConnection;
  /** fetch data from the table: "coachDiscipline" */
  coachDiscipline_connection: CoachDisciplineConnection;
  /** fetch data from the table: "coachImage" */
  coachImage_connection: CoachImageConnection;
  /** fetch data from the table: "coachLessonLocation" */
  coachLessonLocation_connection: CoachLessonLocationConnection;
  /** fetch data from the table: "coachLesson" */
  coachLesson_connection: CoachLessonConnection;
  /** fetch data from the table: "coachLocation" */
  coachLocation_connection: CoachLocationConnection;
  /** fetch data from the table: "coachModerationFlag" */
  coachModerationFlag_connection: CoachModerationFlagConnection;
  /** fetch data from the table: "coachStudent" */
  coachStudent_connection: CoachStudentConnection;
  /** fetch data from the table: "coachUnavailableDate" */
  coachUnavailableDate_connection: CoachUnavailableDateConnection;
  /** fetch data from the table: "coach" */
  coach_connection: CoachConnection;
  /** fetch data from the table: "course" */
  course_connection: CourseConnection;
  /** fetch data from the table: "creatorInvite" */
  creatorInvite_connection: CreatorInviteConnection;
  /** fetch data from the table: "eventAdditionalInfo" */
  eventAdditionalInfo_connection: EventAdditionalInfoConnection;
  /** fetch data from the table: "eventAmenity" */
  eventAmenity_connection: EventAmenityConnection;
  /** fetch data from the table: "eventBasicWaiverCategory" */
  eventBasicWaiverCategory_connection: EventBasicWaiverCategoryConnection;
  /** fetch data from the table: "eventBasicWaiver" */
  eventBasicWaiver_connection: EventBasicWaiverConnection;
  /** fetch data from the table: "eventChecklistItem" */
  eventChecklistItem_connection: EventChecklistItemConnection;
  /** fetch data from the table: "eventClinicDayDuration" */
  eventClinicDayDuration_connection: EventClinicDayDurationConnection;
  /** fetch data from the table: "eventCustomQuestionCategory" */
  eventCustomQuestionCategory_connection: EventCustomQuestionCategoryConnection;
  /** fetch data from the table: "eventCustomQuestionOption" */
  eventCustomQuestionOption_connection: EventCustomQuestionOptionConnection;
  /** fetch data from the table: "eventCustomQuestion" */
  eventCustomQuestion_connection: EventCustomQuestionConnection;
  /** fetch data from the table: "eventDiscipline" */
  eventDiscipline_connection: EventDisciplineConnection;
  /** fetch data from the table: "eventImage" */
  eventImage_connection: EventImageConnection;
  /** fetch data from the table: "eventMerchandise" */
  eventMerchandise_connection: EventMerchandiseConnection;
  /** An array relationship connection */
  eventMetadata_connection: EventMetadataConnection;
  /** fetch data from the table: "eventModerationFlag" */
  eventModerationFlag_connection: EventModerationFlagConnection;
  /** fetch data from the table: "eventPitSpaceAddOn" */
  eventPitSpaceAddOn_connection: EventPitSpaceAddOnConnection;
  /** fetch data from the table: "eventPitSpace" */
  eventPitSpace_connection: EventPitSpaceConnection;
  /** fetch data from the table: "eventPricingChange" */
  eventPricingChange_connection: EventPricingChangeConnection;
  /** fetch data from the table: "eventPricingDate" */
  eventPricingDate_connection: EventPricingDateConnection;
  /** fetch data from the table: "eventPricingGroupCategory" */
  eventPricingGroupCategory_connection: EventPricingGroupCategoryConnection;
  /** fetch data from the table: "eventPricingGroup" */
  eventPricingGroup_connection: EventPricingGroupConnection;
  /** fetch data from the table: "eventPrivateLink" */
  eventPrivateLink_connection: EventPrivateLinkConnection;
  /** fetch data from the table: "eventProgram" */
  eventProgram_connection: EventProgramConnection;
  /** fetch data from the table: "eventPromoCode" */
  eventPromoCode_connection: EventPromoCodeConnection;
  /** fetch data from the table: "eventQuestionAnswer" */
  eventQuestionAnswer_connection: EventQuestionAnswerConnection;
  /** fetch data from the table: "eventQuestion" */
  eventQuestion_connection: EventQuestionConnection;
  /** fetch data from the table: "eventSanctionCategory" */
  eventSanctionCategory_connection: EventSanctionCategoryConnection;
  /** fetch data from the table: "eventSanction" */
  eventSanction_connection: EventSanctionConnection;
  /** fetch data from the table: "eventScheduleCategory" */
  eventScheduleCategory_connection: EventScheduleCategoryConnection;
  /** fetch data from the table: "eventScheduleDate" */
  eventScheduleDate_connection: EventScheduleDateConnection;
  /** fetch data from the table: "eventScheduleTypeCustom" */
  eventScheduleTypeCustom_connection: EventScheduleTypeCustomConnection;
  /** fetch data from the table: "eventScheduleTypePreset" */
  eventScheduleTypePreset_connection: EventScheduleTypePresetConnection;
  /** fetch data from the table: "eventSchedule" */
  eventSchedule_connection: EventScheduleConnection;
  /** fetch data from the table: "eventSeriesCategory" */
  eventSeriesCategory_connection: EventSeriesCategoryConnection;
  /** An array relationship connection */
  eventSeries_connection: EventSeriesConnection;
  /** fetch data from the table: "eventSponsor" */
  eventSponsor_connection: EventSponsorConnection;
  /** fetch data from the table: "eventTicket" */
  eventTicket_connection: EventTicketConnection;
  /** fetch data from the table: "eventUpdateComment" */
  eventUpdateComment_connection: EventUpdateCommentConnection;
  /** fetch data from the table: "eventUpdate" */
  eventUpdate_connection: EventUpdateConnection;
  /** fetch data from the table: "eventVenue" */
  eventVenue_connection: EventVenueConnection;
  /** fetch data from the table: "eventVibe" */
  eventVibe_connection: EventVibeConnection;
  /** fetch data from the table: "eventVirtualVenue" */
  eventVirtualVenue_connection: EventVirtualVenueConnection;
  /** fetch data from the table: "eventVolunteerJobShiftTask" */
  eventVolunteerJobShiftTask_connection: EventVolunteerJobShiftTaskConnection;
  /** fetch data from the table: "eventVolunteerJobShift" */
  eventVolunteerJobShift_connection: EventVolunteerJobShiftConnection;
  /** fetch data from the table: "eventVolunteerJobTask" */
  eventVolunteerJobTask_connection: EventVolunteerJobTaskConnection;
  /** fetch data from the table: "eventVolunteerJob" */
  eventVolunteerJob_connection: EventVolunteerJobConnection;
  /** fetch data from the table: "eventWaiverType" */
  eventWaiverType_connection: EventWaiverTypeConnection;
  /** fetch data from the table: "eventWaiver" */
  eventWaiver_connection: EventWaiverConnection;
  /** fetch data from the table: "event" */
  event_connection: EventConnection;
  /** fetch data from the table: "imageOnlineCourse" */
  imageOnlineCourse_connection: ImageOnlineCourseConnection;
  /** fetch data from the table: "imageUser" */
  imageUser_connection: ImageUserConnection;
  /** fetch data from the table: "image" */
  image_connection: ImageConnection;
  /** fetch data from the table: "lessonLocation" */
  lessonLocation_connection: LessonLocationConnection;
  /** fetch data from the table: "merchandiseDeliveryType" */
  merchandiseDeliveryType_connection: MerchandiseDeliveryTypeConnection;
  /** fetch data from the table: "merchandiseImage" */
  merchandiseImage_connection: MerchandiseImageConnection;
  /** fetch data from the table: "merchandiseVariantOptionValue" */
  merchandiseVariantOptionValue_connection: MerchandiseVariantOptionValueConnection;
  /** fetch data from the table: "merchandiseVariantOption" */
  merchandiseVariantOption_connection: MerchandiseVariantOptionConnection;
  /** fetch data from the table: "merchandiseVariant" */
  merchandiseVariant_connection: MerchandiseVariantConnection;
  /** fetch data from the table: "merchandise" */
  merchandise_connection: MerchandiseConnection;
  node?: Maybe<Node>;
  /** fetch data from the table: "onlineCourseOwner" */
  onlineCourseOwner_connection: OnlineCourseOwnerConnection;
  /** fetch data from the table: "onlineCourseRevision" */
  onlineCourseRevision_connection: OnlineCourseRevisionConnection;
  /** fetch data from the table: "onlineCourse" */
  onlineCourse_connection: OnlineCourseConnection;
  /** fetch data from the table: "organizerChannel" */
  organizerChannel_connection: OrganizerChannelConnection;
  /** fetch data from the table: "organizerInvite" */
  organizerInvite_connection: OrganizerInviteConnection;
  /** fetch data from the table: "organizer" */
  organizer_connection: OrganizerConnection;
  /** fetch data from the table: "registrationActivity" */
  registrationActivity_connection: RegistrationActivityConnection;
  /** fetch data from the table: "registrationAdditionalPayment" */
  registrationAdditionalPayment_connection: RegistrationAdditionalPaymentConnection;
  /** fetch data from the table: "registrationCategory" */
  registrationCategory_connection: RegistrationCategoryConnection;
  /** fetch data from the table: "registrationFor" */
  registrationFor_connection: RegistrationForConnection;
  /** An array relationship connection */
  registrationSeries_connection: RegistrationSeriesConnection;
  /** fetch data from the table: "registrationStatus" */
  registrationStatus_connection: RegistrationStatusConnection;
  /** fetch data from the table: "registrationTicket" */
  registrationTicket_connection: RegistrationTicketConnection;
  /** fetch data from the table: "registration" */
  registration_connection: RegistrationConnection;
  /** fetch data from the table: "reportClinicNoShow" */
  reportClinicNoShow_connection: ReportClinicNoShowConnection;
  /** fetch data from the table: "reportLessonNoShow" */
  reportLessonNoShow_connection: ReportLessonNoShowConnection;
  /** fetch data from the table: "reviewSubjectType" */
  reviewSubjectType_connection: ReviewSubjectTypeConnection;
  /** An array relationship connection */
  reviewTotals_connection: ReviewTotalsConnection;
  /** fetch data from the table: "review" */
  review_connection: ReviewConnection;
  /** fetch data from the table: "rideDay" */
  rideDay_connection: RideDayConnection;
  /** fetch data from the table: "ride" */
  ride_connection: RideConnection;
  /** fetch data from the table: "roleOrganizer" */
  roleOrganizer_connection: RoleOrganizerConnection;
  /** An array relationship connection */
  sanctionWaivers_connection: SanctionWaiversConnection;
  /** fetch data from the table: "sanction" */
  sanction_connection: SanctionConnection;
  /** fetch data from the table: "scheduleTypePreset" */
  scheduleTypePreset_connection: ScheduleTypePresetConnection;
  /** fetch data from the table: "seriesImage" */
  seriesImage_connection: SeriesImageConnection;
  /** fetch data from the table: "seriesPointsStructureCustom" */
  seriesPointsStructureCustom_connection: SeriesPointsStructureCustomConnection;
  /** fetch data from the table: "seriesPointsStructurePreset" */
  seriesPointsStructurePreset_connection: SeriesPointsStructurePresetConnection;
  /** An array relationship connection */
  series_connection: SeriesConnection;
  /** fetch data from the table: "signatureRequestSignerType" */
  signatureRequestSignerType_connection: SignatureRequestSignerTypeConnection;
  /** fetch data from the table: "signatureRequestStatus" */
  signatureRequestStatus_connection: SignatureRequestStatusConnection;
  /** fetch data from the table: "signatureRequest" */
  signatureRequest_connection: SignatureRequestConnection;
  /** fetch data from the table: "sponsorImage" */
  sponsorImage_connection: SponsorImageConnection;
  /** fetch data from the table: "sponsor" */
  sponsor_connection: SponsorConnection;
  /** fetch data from the table: "stripeConnectAccount" */
  stripeConnectAccount_connection: StripeConnectAccountConnection;
  /** fetch data from the table: "ticket" */
  ticket_connection: TicketConnection;
  /** fetch data from the table: "userAddress" */
  userAddress_connection: UserAddressConnection;
  /** fetch data from the table: "userBikeRevision" */
  userBikeRevision_connection: UserBikeRevisionConnection;
  /** fetch data from the table: "userBike" */
  userBike_connection: UserBikeConnection;
  /** fetch data from the table: "userEmergencyContact" */
  userEmergencyContact_connection: UserEmergencyContactConnection;
  /** fetch data from the table: "userImage" */
  userImage_connection: UserImageConnection;
  /** fetch data from the table: "userOnlineCourseStatus" */
  userOnlineCourseStatus_connection: UserOnlineCourseStatusConnection;
  /** fetch data from the table: "userOnlineCourse" */
  userOnlineCourse_connection: UserOnlineCourseConnection;
  /** fetch data from the table: "userOrganizerRole" */
  userOrganizerRole_connection: UserOrganizerRoleConnection;
  /** fetch data from the table: "userPersonalization" */
  userPersonalization_connection: UserPersonalizationConnection;
  /** fetch data from the table: "userRidingInterest" */
  userRidingInterest_connection: UserRidingInterestConnection;
  /** fetch data from the table: "userSanctionLicense" */
  userSanctionLicense_connection: UserSanctionLicenseConnection;
  /** fetch data from the table: "userSavedCoach" */
  userSavedCoach_connection: UserSavedCoachConnection;
  /** fetch data from the table: "userSavedEvent" */
  userSavedEvent_connection: UserSavedEventConnection;
  /** fetch data from the table: "userSavedLesson" */
  userSavedLesson_connection: UserSavedLessonConnection;
  /** fetch data from the table: "user" */
  user_connection: UserConnection;
  /** fetch data from the table: "venue" */
  venue_connection: VenueConnection;
  /** fetch data from the table: "volunteerTaskDifficulties" */
  volunteerTaskDifficulties_connection: VolunteerTaskDifficultiesConnection;
  /** fetch data from the table: "volunteerTaskPriorities" */
  volunteerTaskPriorities_connection: VolunteerTaskPrioritiesConnection;
  /** fetch data from the table: "waiverOwner" */
  waiverOwner_connection: WaiverOwnerConnection;
  /** fetch data from the table: "waiverRevision" */
  waiverRevision_connection: WaiverRevisionConnection;
  /** fetch data from the table: "waiver" */
  waiver_connection: WaiverConnection;
};


export type SubscriptionRootAccessNameConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<AccessNameSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessNameOrderBy>>;
  where?: InputMaybe<AccessNameBoolExp>;
};


export type SubscriptionRootActivityTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};


export type SubscriptionRootAmenityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<AmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AmenityOrderBy>>;
  where?: InputMaybe<AmenityBoolExp>;
};


export type SubscriptionRootBasicSignatureRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<BasicSignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BasicSignatureRequestOrderBy>>;
  where?: InputMaybe<BasicSignatureRequestBoolExp>;
};


export type SubscriptionRootCategoryCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategoryCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryCourseOrderBy>>;
  where?: InputMaybe<CategoryCourseBoolExp>;
};


export type SubscriptionRootCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type SubscriptionRootChangeLogDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ChangeLogDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogDateOrderBy>>;
  where?: InputMaybe<ChangeLogDateBoolExp>;
};


export type SubscriptionRootChangeLogItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ChangeLogItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChangeLogItemOrderBy>>;
  where?: InputMaybe<ChangeLogItemBoolExp>;
};


export type SubscriptionRootClinicCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicCoachOrderBy>>;
  where?: InputMaybe<ClinicCoachBoolExp>;
};


export type SubscriptionRootClinicDayConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicDayOrderBy>>;
  where?: InputMaybe<ClinicDayBoolExp>;
};


export type SubscriptionRootClinicConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ClinicSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicOrderBy>>;
  where?: InputMaybe<ClinicBoolExp>;
};


export type SubscriptionRootCoachAvailabilityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachAvailabilitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachAvailabilityOrderBy>>;
  where?: InputMaybe<CoachAvailabilityBoolExp>;
};


export type SubscriptionRootCoachCertificationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCertificationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCertificationOrderBy>>;
  where?: InputMaybe<CoachCertificationBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonDateLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateLocationBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonDateVirtualLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateVirtualLocationOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateVirtualLocationBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonDateOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonDateBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonOneTimeChargeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOneTimeChargeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonOneTimeChargeBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonPauseResumeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonPauseResumeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonPauseResumeOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonPauseResumeBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonTransactionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonTransactionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonTransactionOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonTransactionBoolExp>;
};


export type SubscriptionRootCoachCustomerLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerLessonOrderBy>>;
  where?: InputMaybe<CoachCustomerLessonBoolExp>;
};


export type SubscriptionRootCoachCustomerMessageRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerMessageRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerMessageRequestOrderBy>>;
  where?: InputMaybe<CoachCustomerMessageRequestBoolExp>;
};


export type SubscriptionRootCoachCustomerUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


export type SubscriptionRootCoachCustomerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerOrderBy>>;
  where?: InputMaybe<CoachCustomerBoolExp>;
};


export type SubscriptionRootCoachDisciplineConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachDisciplineOrderBy>>;
  where?: InputMaybe<CoachDisciplineBoolExp>;
};


export type SubscriptionRootCoachImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachImageOrderBy>>;
  where?: InputMaybe<CoachImageBoolExp>;
};


export type SubscriptionRootCoachLessonLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonLocationOrderBy>>;
  where?: InputMaybe<CoachLessonLocationBoolExp>;
};


export type SubscriptionRootCoachLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLessonOrderBy>>;
  where?: InputMaybe<CoachLessonBoolExp>;
};


export type SubscriptionRootCoachLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachLocationOrderBy>>;
  where?: InputMaybe<CoachLocationBoolExp>;
};


export type SubscriptionRootCoachModerationFlagConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


export type SubscriptionRootCoachStudentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachStudentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachStudentOrderBy>>;
  where?: InputMaybe<CoachStudentBoolExp>;
};


export type SubscriptionRootCoachUnavailableDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachUnavailableDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachUnavailableDateOrderBy>>;
  where?: InputMaybe<CoachUnavailableDateBoolExp>;
};


export type SubscriptionRootCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


export type SubscriptionRootCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CourseOrderBy>>;
  where?: InputMaybe<CourseBoolExp>;
};


export type SubscriptionRootCreatorInviteConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


export type SubscriptionRootEventAdditionalInfoConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAdditionalInfoSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAdditionalInfoOrderBy>>;
  where?: InputMaybe<EventAdditionalInfoBoolExp>;
};


export type SubscriptionRootEventAmenityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventAmenitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventAmenityOrderBy>>;
  where?: InputMaybe<EventAmenityBoolExp>;
};


export type SubscriptionRootEventBasicWaiverCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverCategoryOrderBy>>;
  where?: InputMaybe<EventBasicWaiverCategoryBoolExp>;
};


export type SubscriptionRootEventBasicWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventBasicWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventBasicWaiverOrderBy>>;
  where?: InputMaybe<EventBasicWaiverBoolExp>;
};


export type SubscriptionRootEventChecklistItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventChecklistItemSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventChecklistItemOrderBy>>;
  where?: InputMaybe<EventChecklistItemBoolExp>;
};


export type SubscriptionRootEventClinicDayDurationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventClinicDayDurationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventClinicDayDurationOrderBy>>;
  where?: InputMaybe<EventClinicDayDurationBoolExp>;
};


export type SubscriptionRootEventCustomQuestionCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionCategoryOrderBy>>;
  where?: InputMaybe<EventCustomQuestionCategoryBoolExp>;
};


export type SubscriptionRootEventCustomQuestionOptionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOptionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionOptionBoolExp>;
};


export type SubscriptionRootEventCustomQuestionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventCustomQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventCustomQuestionOrderBy>>;
  where?: InputMaybe<EventCustomQuestionBoolExp>;
};


export type SubscriptionRootEventDisciplineConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventDisciplineSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDisciplineOrderBy>>;
  where?: InputMaybe<EventDisciplineBoolExp>;
};


export type SubscriptionRootEventImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventImageOrderBy>>;
  where?: InputMaybe<EventImageBoolExp>;
};


export type SubscriptionRootEventMerchandiseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMerchandiseOrderBy>>;
  where?: InputMaybe<EventMerchandiseBoolExp>;
};


export type SubscriptionRootEventMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventMetadataSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventMetadataOrderBy>>;
  where?: InputMaybe<EventMetadataBoolExp>;
};


export type SubscriptionRootEventModerationFlagConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


export type SubscriptionRootEventPitSpaceAddOnConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceAddOnSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceAddOnOrderBy>>;
  where?: InputMaybe<EventPitSpaceAddOnBoolExp>;
};


export type SubscriptionRootEventPitSpaceConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPitSpaceSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPitSpaceOrderBy>>;
  where?: InputMaybe<EventPitSpaceBoolExp>;
};


export type SubscriptionRootEventPricingChangeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingChangeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingChangeOrderBy>>;
  where?: InputMaybe<EventPricingChangeBoolExp>;
};


export type SubscriptionRootEventPricingDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingDateOrderBy>>;
  where?: InputMaybe<EventPricingDateBoolExp>;
};


export type SubscriptionRootEventPricingGroupCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupCategoryOrderBy>>;
  where?: InputMaybe<EventPricingGroupCategoryBoolExp>;
};


export type SubscriptionRootEventPricingGroupConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPricingGroupSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPricingGroupOrderBy>>;
  where?: InputMaybe<EventPricingGroupBoolExp>;
};


export type SubscriptionRootEventPrivateLinkConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPrivateLinkSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPrivateLinkOrderBy>>;
  where?: InputMaybe<EventPrivateLinkBoolExp>;
};


export type SubscriptionRootEventProgramConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventProgramSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventProgramOrderBy>>;
  where?: InputMaybe<EventProgramBoolExp>;
};


export type SubscriptionRootEventPromoCodeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventPromoCodeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventPromoCodeOrderBy>>;
  where?: InputMaybe<EventPromoCodeBoolExp>;
};


export type SubscriptionRootEventQuestionAnswerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


export type SubscriptionRootEventQuestionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


export type SubscriptionRootEventSanctionCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionCategoryOrderBy>>;
  where?: InputMaybe<EventSanctionCategoryBoolExp>;
};


export type SubscriptionRootEventSanctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSanctionOrderBy>>;
  where?: InputMaybe<EventSanctionBoolExp>;
};


export type SubscriptionRootEventScheduleCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleCategoryOrderBy>>;
  where?: InputMaybe<EventScheduleCategoryBoolExp>;
};


export type SubscriptionRootEventScheduleDateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleDateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleDateOrderBy>>;
  where?: InputMaybe<EventScheduleDateBoolExp>;
};


export type SubscriptionRootEventScheduleTypeCustomConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypeCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypeCustomOrderBy>>;
  where?: InputMaybe<EventScheduleTypeCustomBoolExp>;
};


export type SubscriptionRootEventScheduleTypePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleTypePresetOrderBy>>;
  where?: InputMaybe<EventScheduleTypePresetBoolExp>;
};


export type SubscriptionRootEventScheduleConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventScheduleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventScheduleOrderBy>>;
  where?: InputMaybe<EventScheduleBoolExp>;
};


export type SubscriptionRootEventSeriesCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesCategoryOrderBy>>;
  where?: InputMaybe<EventSeriesCategoryBoolExp>;
};


export type SubscriptionRootEventSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSeriesOrderBy>>;
  where?: InputMaybe<EventSeriesBoolExp>;
};


export type SubscriptionRootEventSponsorConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventSponsorOrderBy>>;
  where?: InputMaybe<EventSponsorBoolExp>;
};


export type SubscriptionRootEventTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


export type SubscriptionRootEventUpdateCommentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


export type SubscriptionRootEventUpdateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


export type SubscriptionRootEventVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


export type SubscriptionRootEventVibeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVibeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVibeOrderBy>>;
  where?: InputMaybe<EventVibeBoolExp>;
};


export type SubscriptionRootEventVirtualVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVirtualVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVirtualVenueOrderBy>>;
  where?: InputMaybe<EventVirtualVenueBoolExp>;
};


export type SubscriptionRootEventVolunteerJobShiftTaskConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftTaskBoolExp>;
};


export type SubscriptionRootEventVolunteerJobShiftConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobShiftSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobShiftOrderBy>>;
  where?: InputMaybe<EventVolunteerJobShiftBoolExp>;
};


export type SubscriptionRootEventVolunteerJobTaskConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


export type SubscriptionRootEventVolunteerJobConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobOrderBy>>;
  where?: InputMaybe<EventVolunteerJobBoolExp>;
};


export type SubscriptionRootEventWaiverTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverTypeOrderBy>>;
  where?: InputMaybe<EventWaiverTypeBoolExp>;
};


export type SubscriptionRootEventWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventWaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventWaiverOrderBy>>;
  where?: InputMaybe<EventWaiverBoolExp>;
};


export type SubscriptionRootEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventOrderBy>>;
  where?: InputMaybe<EventBoolExp>;
};


export type SubscriptionRootImageOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOnlineCourseOrderBy>>;
  where?: InputMaybe<ImageOnlineCourseBoolExp>;
};


export type SubscriptionRootImageUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageUserOrderBy>>;
  where?: InputMaybe<ImageUserBoolExp>;
};


export type SubscriptionRootImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type SubscriptionRootLessonLocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<LessonLocationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LessonLocationOrderBy>>;
  where?: InputMaybe<LessonLocationBoolExp>;
};


export type SubscriptionRootMerchandiseDeliveryTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseDeliveryTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseDeliveryTypeOrderBy>>;
  where?: InputMaybe<MerchandiseDeliveryTypeBoolExp>;
};


export type SubscriptionRootMerchandiseImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseImageOrderBy>>;
  where?: InputMaybe<MerchandiseImageBoolExp>;
};


export type SubscriptionRootMerchandiseVariantOptionValueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionValueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionValueOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionValueBoolExp>;
};


export type SubscriptionRootMerchandiseVariantOptionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantOptionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOptionOrderBy>>;
  where?: InputMaybe<MerchandiseVariantOptionBoolExp>;
};


export type SubscriptionRootMerchandiseVariantConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseVariantSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseVariantOrderBy>>;
  where?: InputMaybe<MerchandiseVariantBoolExp>;
};


export type SubscriptionRootMerchandiseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<MerchandiseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchandiseOrderBy>>;
  where?: InputMaybe<MerchandiseBoolExp>;
};


export type SubscriptionRootNodeArgs = {
  id: Scalars['ID'];
};


export type SubscriptionRootOnlineCourseOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseOwnerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOwnerOrderBy>>;
  where?: InputMaybe<OnlineCourseOwnerBoolExp>;
};


export type SubscriptionRootOnlineCourseRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseRevisionOrderBy>>;
  where?: InputMaybe<OnlineCourseRevisionBoolExp>;
};


export type SubscriptionRootOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlineCourseOrderBy>>;
  where?: InputMaybe<OnlineCourseBoolExp>;
};


export type SubscriptionRootOrganizerChannelConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


export type SubscriptionRootOrganizerInviteConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


export type SubscriptionRootOrganizerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerOrderBy>>;
  where?: InputMaybe<OrganizerBoolExp>;
};


export type SubscriptionRootRegistrationActivityConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationActivitySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationActivityOrderBy>>;
  where?: InputMaybe<RegistrationActivityBoolExp>;
};


export type SubscriptionRootRegistrationAdditionalPaymentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationAdditionalPaymentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationAdditionalPaymentOrderBy>>;
  where?: InputMaybe<RegistrationAdditionalPaymentBoolExp>;
};


export type SubscriptionRootRegistrationCategoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationCategorySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationCategoryOrderBy>>;
  where?: InputMaybe<RegistrationCategoryBoolExp>;
};


export type SubscriptionRootRegistrationForConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationForSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationForOrderBy>>;
  where?: InputMaybe<RegistrationForBoolExp>;
};


export type SubscriptionRootRegistrationSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationSeriesOrderBy>>;
  where?: InputMaybe<RegistrationSeriesBoolExp>;
};


export type SubscriptionRootRegistrationStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationStatusOrderBy>>;
  where?: InputMaybe<RegistrationStatusBoolExp>;
};


export type SubscriptionRootRegistrationTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationTicketOrderBy>>;
  where?: InputMaybe<RegistrationTicketBoolExp>;
};


export type SubscriptionRootRegistrationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


export type SubscriptionRootReportClinicNoShowConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


export type SubscriptionRootReportLessonNoShowConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


export type SubscriptionRootReviewSubjectTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSubjectTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewSubjectTypeOrderBy>>;
  where?: InputMaybe<ReviewSubjectTypeBoolExp>;
};


export type SubscriptionRootReviewTotalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewTotalsSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewTotalsOrderBy>>;
  where?: InputMaybe<ReviewTotalsBoolExp>;
};


export type SubscriptionRootReviewConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


export type SubscriptionRootRideDayConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideDaySelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideDayOrderBy>>;
  where?: InputMaybe<RideDayBoolExp>;
};


export type SubscriptionRootRideConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RideSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RideOrderBy>>;
  where?: InputMaybe<RideBoolExp>;
};


export type SubscriptionRootRoleOrganizerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RoleOrganizerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrganizerOrderBy>>;
  where?: InputMaybe<RoleOrganizerBoolExp>;
};


export type SubscriptionRootSanctionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


export type SubscriptionRootSanctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionOrderBy>>;
  where?: InputMaybe<SanctionBoolExp>;
};


export type SubscriptionRootScheduleTypePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ScheduleTypePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduleTypePresetOrderBy>>;
  where?: InputMaybe<ScheduleTypePresetBoolExp>;
};


export type SubscriptionRootSeriesImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesImageOrderBy>>;
  where?: InputMaybe<SeriesImageBoolExp>;
};


export type SubscriptionRootSeriesPointsStructureCustomConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructureCustomSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructureCustomOrderBy>>;
  where?: InputMaybe<SeriesPointsStructureCustomBoolExp>;
};


export type SubscriptionRootSeriesPointsStructurePresetConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesPointsStructurePresetSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesPointsStructurePresetOrderBy>>;
  where?: InputMaybe<SeriesPointsStructurePresetBoolExp>;
};


export type SubscriptionRootSeriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SeriesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SeriesOrderBy>>;
  where?: InputMaybe<SeriesBoolExp>;
};


export type SubscriptionRootSignatureRequestSignerTypeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSignerTypeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestSignerTypeOrderBy>>;
  where?: InputMaybe<SignatureRequestSignerTypeBoolExp>;
};


export type SubscriptionRootSignatureRequestStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestStatusOrderBy>>;
  where?: InputMaybe<SignatureRequestStatusBoolExp>;
};


export type SubscriptionRootSignatureRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SignatureRequestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SignatureRequestOrderBy>>;
  where?: InputMaybe<SignatureRequestBoolExp>;
};


export type SubscriptionRootSponsorImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorImageOrderBy>>;
  where?: InputMaybe<SponsorImageBoolExp>;
};


export type SubscriptionRootSponsorConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SponsorSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SponsorOrderBy>>;
  where?: InputMaybe<SponsorBoolExp>;
};


export type SubscriptionRootStripeConnectAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


export type SubscriptionRootTicketConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<TicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TicketOrderBy>>;
  where?: InputMaybe<TicketBoolExp>;
};


export type SubscriptionRootUserAddressConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserAddressSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddressOrderBy>>;
  where?: InputMaybe<UserAddressBoolExp>;
};


export type SubscriptionRootUserBikeRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeRevisionOrderBy>>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};


export type SubscriptionRootUserBikeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


export type SubscriptionRootUserEmergencyContactConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserEmergencyContactOrderBy>>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};


export type SubscriptionRootUserImageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


export type SubscriptionRootUserOnlineCourseStatusConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseStatusSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseStatusOrderBy>>;
  where?: InputMaybe<UserOnlineCourseStatusBoolExp>;
};


export type SubscriptionRootUserOnlineCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


export type SubscriptionRootUserOrganizerRoleConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


export type SubscriptionRootUserPersonalizationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPersonalizationOrderBy>>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};


export type SubscriptionRootUserRidingInterestConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserRidingInterestOrderBy>>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};


export type SubscriptionRootUserSanctionLicenseConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


export type SubscriptionRootUserSavedCoachConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


export type SubscriptionRootUserSavedEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


export type SubscriptionRootUserSavedLessonConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


export type SubscriptionRootUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootVenueConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VenueOrderBy>>;
  where?: InputMaybe<VenueBoolExp>;
};


export type SubscriptionRootVolunteerTaskDifficultiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VolunteerTaskDifficultiesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VolunteerTaskDifficultiesOrderBy>>;
  where?: InputMaybe<VolunteerTaskDifficultiesBoolExp>;
};


export type SubscriptionRootVolunteerTaskPrioritiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<VolunteerTaskPrioritiesSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VolunteerTaskPrioritiesOrderBy>>;
  where?: InputMaybe<VolunteerTaskPrioritiesBoolExp>;
};


export type SubscriptionRootWaiverOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverOwnerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOwnerOrderBy>>;
  where?: InputMaybe<WaiverOwnerBoolExp>;
};


export type SubscriptionRootWaiverRevisionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


export type SubscriptionRootWaiverConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};

/** columns and relationships of "ticket" */
export type Ticket = Node & {
  __typename?: 'ticket';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  eventTickets: Array<EventTicket>;
  /** An aggregate relationship */
  eventTickets_aggregate: EventTicketAggregate;
  /** An array relationship connection */
  eventTickets_connection: EventTicketConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "ticket" */
export type TicketEventTicketsArgs = {
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


/** columns and relationships of "ticket" */
export type TicketEventTicketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};


/** columns and relationships of "ticket" */
export type TicketEventTicketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventTicketSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventTicketOrderBy>>;
  where?: InputMaybe<EventTicketBoolExp>;
};

/** A Relay connection object on "ticket" */
export type TicketConnection = {
  __typename?: 'ticketConnection';
  edges: Array<TicketEdge>;
  pageInfo: PageInfo;
};

export type TicketEdge = {
  __typename?: 'ticketEdge';
  cursor: Scalars['String'];
  node: Ticket;
};

/** aggregated selection of "ticket" */
export type TicketAggregate = {
  __typename?: 'ticket_aggregate';
  aggregate?: Maybe<TicketAggregateFields>;
  nodes: Array<Ticket>;
};

export type TicketAggregateBoolExp = {
  count?: InputMaybe<TicketAggregateBoolExpCount>;
};

export type TicketAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TicketBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "ticket" */
export type TicketAggregateFields = {
  __typename?: 'ticket_aggregate_fields';
  avg?: Maybe<TicketAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TicketMaxFields>;
  min?: Maybe<TicketMinFields>;
  stddev?: Maybe<TicketStddevFields>;
  stddev_pop?: Maybe<TicketStddevPopFields>;
  stddev_samp?: Maybe<TicketStddevSampFields>;
  sum?: Maybe<TicketSumFields>;
  var_pop?: Maybe<TicketVarPopFields>;
  var_samp?: Maybe<TicketVarSampFields>;
  variance?: Maybe<TicketVarianceFields>;
};


/** aggregate fields of "ticket" */
export type TicketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ticket" */
export type TicketAggregateOrderBy = {
  avg?: InputMaybe<TicketAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TicketMaxOrderBy>;
  min?: InputMaybe<TicketMinOrderBy>;
  stddev?: InputMaybe<TicketStddevOrderBy>;
  stddev_pop?: InputMaybe<TicketStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TicketStddevSampOrderBy>;
  sum?: InputMaybe<TicketSumOrderBy>;
  var_pop?: InputMaybe<TicketVarPopOrderBy>;
  var_samp?: InputMaybe<TicketVarSampOrderBy>;
  variance?: InputMaybe<TicketVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ticket" */
export type TicketArrRelInsertInput = {
  data: Array<TicketInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TicketOnConflict>;
};

/** aggregate avg on columns */
export type TicketAvgFields = {
  __typename?: 'ticket_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ticket" */
export type TicketAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ticket". All fields are combined with a logical 'AND'. */
export type TicketBoolExp = {
  _and?: InputMaybe<Array<TicketBoolExp>>;
  _not?: InputMaybe<TicketBoolExp>;
  _or?: InputMaybe<Array<TicketBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  eventTickets?: InputMaybe<EventTicketBoolExp>;
  eventTickets_aggregate?: InputMaybe<EventTicketAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "ticket" */
export enum TicketConstraint {
  /** unique or primary key constraint on columns "cuid" */
  TicketPkey = 'ticket_pkey'
}

/** input type for incrementing numeric columns in table "ticket" */
export type TicketIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ticket" */
export type TicketInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  eventTickets?: InputMaybe<EventTicketArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TicketMaxFields = {
  __typename?: 'ticket_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ticket" */
export type TicketMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TicketMinFields = {
  __typename?: 'ticket_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ticket" */
export type TicketMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ticket" */
export type TicketMutationResponse = {
  __typename?: 'ticket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket>;
};

/** input type for inserting object relation for remote table "ticket" */
export type TicketObjRelInsertInput = {
  data: TicketInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TicketOnConflict>;
};

/** on_conflict condition type for table "ticket" */
export type TicketOnConflict = {
  constraint: TicketConstraint;
  update_columns?: Array<TicketUpdateColumn>;
  where?: InputMaybe<TicketBoolExp>;
};

/** Ordering options when selecting data from "ticket". */
export type TicketOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventTickets_aggregate?: InputMaybe<EventTicketAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ticket */
export type TicketPkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "ticket" */
export enum TicketSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ticket" */
export type TicketSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TicketStddevFields = {
  __typename?: 'ticket_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ticket" */
export type TicketStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TicketStddevPopFields = {
  __typename?: 'ticket_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ticket" */
export type TicketStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TicketStddevSampFields = {
  __typename?: 'ticket_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ticket" */
export type TicketStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TicketSumFields = {
  __typename?: 'ticket_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "ticket" */
export type TicketSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "ticket" */
export enum TicketUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TicketUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TicketIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TicketSetInput>;
  where: TicketBoolExp;
};

/** aggregate var_pop on columns */
export type TicketVarPopFields = {
  __typename?: 'ticket_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ticket" */
export type TicketVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TicketVarSampFields = {
  __typename?: 'ticket_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ticket" */
export type TicketVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TicketVarianceFields = {
  __typename?: 'ticket_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ticket" */
export type TicketVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type User = Node & {
  __typename?: 'user';
  /** An object relationship */
  coach?: Maybe<Coach>;
  /** An object relationship */
  coachById?: Maybe<Coach>;
  coachCuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  coachCustomerUsers: Array<CoachCustomerUser>;
  /** An aggregate relationship */
  coachCustomerUsers_aggregate: CoachCustomerUserAggregate;
  /** An array relationship connection */
  coachCustomerUsers_connection: CoachCustomerUserConnection;
  /** An array relationship */
  coachModerationFlags: Array<CoachModerationFlag>;
  /** An aggregate relationship */
  coachModerationFlags_aggregate: CoachModerationFlagAggregate;
  /** An array relationship connection */
  coachModerationFlags_connection: CoachModerationFlagConnection;
  coachSetupStep: Scalars['Int'];
  coachSetupType?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  creatorInvitesByAcceptor: Array<CreatorInvite>;
  /** An aggregate relationship */
  creatorInvitesByAcceptor_aggregate: CreatorInviteAggregate;
  /** An array relationship connection */
  creatorInvitesByAcceptor_connection: CreatorInviteConnection;
  /** An array relationship */
  creatorInvitesByInviter: Array<CreatorInvite>;
  /** An aggregate relationship */
  creatorInvitesByInviter_aggregate: CreatorInviteAggregate;
  /** An array relationship connection */
  creatorInvitesByInviter_connection: CreatorInviteConnection;
  currency: Scalars['String'];
  cyclingGoal?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  /** An array relationship */
  eventModerationFlags: Array<EventModerationFlag>;
  /** An aggregate relationship */
  eventModerationFlags_aggregate: EventModerationFlagAggregate;
  /** An array relationship connection */
  eventModerationFlags_connection: EventModerationFlagConnection;
  /** An array relationship */
  eventQuestionAnswers: Array<EventQuestionAnswer>;
  /** An array relationship */
  eventQuestionAnswersDeletedByUser: Array<EventQuestionAnswer>;
  /** An aggregate relationship */
  eventQuestionAnswersDeletedByUser_aggregate: EventQuestionAnswerAggregate;
  /** An array relationship connection */
  eventQuestionAnswersDeletedByUser_connection: EventQuestionAnswerConnection;
  /** An aggregate relationship */
  eventQuestionAnswers_aggregate: EventQuestionAnswerAggregate;
  /** An array relationship connection */
  eventQuestionAnswers_connection: EventQuestionAnswerConnection;
  /** An array relationship */
  eventQuestions: Array<EventQuestion>;
  /** An array relationship */
  eventQuestionsDeletedByUser: Array<EventQuestion>;
  /** An aggregate relationship */
  eventQuestionsDeletedByUser_aggregate: EventQuestionAggregate;
  /** An array relationship connection */
  eventQuestionsDeletedByUser_connection: EventQuestionConnection;
  /** An aggregate relationship */
  eventQuestions_aggregate: EventQuestionAggregate;
  /** An array relationship connection */
  eventQuestions_connection: EventQuestionConnection;
  /** An array relationship */
  eventUpdateComments: Array<EventUpdateComment>;
  /** An array relationship */
  eventUpdateCommentsDeletedByUser: Array<EventUpdateComment>;
  /** An aggregate relationship */
  eventUpdateCommentsDeletedByUser_aggregate: EventUpdateCommentAggregate;
  /** An array relationship connection */
  eventUpdateCommentsDeletedByUser_connection: EventUpdateCommentConnection;
  /** An aggregate relationship */
  eventUpdateComments_aggregate: EventUpdateCommentAggregate;
  /** An array relationship connection */
  eventUpdateComments_connection: EventUpdateCommentConnection;
  /** An array relationship */
  eventUpdates: Array<EventUpdate>;
  /** An array relationship */
  eventUpdatesDeletedByUser: Array<EventUpdate>;
  /** An aggregate relationship */
  eventUpdatesDeletedByUser_aggregate: EventUpdateAggregate;
  /** An array relationship connection */
  eventUpdatesDeletedByUser_connection: EventUpdateConnection;
  /** An aggregate relationship */
  eventUpdates_aggregate: EventUpdateAggregate;
  /** An array relationship connection */
  eventUpdates_connection: EventUpdateConnection;
  facebookProfile?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['jsonb']>;
  id: Scalars['ID'];
  idealRide?: Maybe<Scalars['String']>;
  /** An object relationship */
  image?: Maybe<Image>;
  /** An array relationship */
  imageUsers: Array<ImageUser>;
  /** An aggregate relationship */
  imageUsers_aggregate: ImageUserAggregate;
  /** An array relationship connection */
  imageUsers_connection: ImageUserConnection;
  instagramProfile?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  isCoach: Scalars['Boolean'];
  isCreator: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastSavedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  /** An array relationship */
  organizerChannels: Array<OrganizerChannel>;
  /** An aggregate relationship */
  organizerChannels_aggregate: OrganizerChannelAggregate;
  /** An array relationship connection */
  organizerChannels_connection: OrganizerChannelConnection;
  /** An array relationship */
  organizerInvitesByInvitee: Array<OrganizerInvite>;
  /** An aggregate relationship */
  organizerInvitesByInvitee_aggregate: OrganizerInviteAggregate;
  /** An array relationship connection */
  organizerInvitesByInvitee_connection: OrganizerInviteConnection;
  /** An array relationship */
  organizerInvitesByInviter: Array<OrganizerInvite>;
  /** An aggregate relationship */
  organizerInvitesByInviter_aggregate: OrganizerInviteAggregate;
  /** An array relationship connection */
  organizerInvitesByInviter_connection: OrganizerInviteConnection;
  phone?: Maybe<Scalars['String']>;
  profilePicPublicId?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  /** An array relationship */
  registrations: Array<Registration>;
  /** An array relationship */
  registrationsByPayinguserid: Array<Registration>;
  /** An aggregate relationship */
  registrationsByPayinguserid_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrationsByPayinguserid_connection: RegistrationConnection;
  /** An aggregate relationship */
  registrations_aggregate: RegistrationAggregate;
  /** An array relationship connection */
  registrations_connection: RegistrationConnection;
  /** An array relationship */
  reportClinicNoShows: Array<ReportClinicNoShow>;
  /** An aggregate relationship */
  reportClinicNoShows_aggregate: ReportClinicNoShowAggregate;
  /** An array relationship connection */
  reportClinicNoShows_connection: ReportClinicNoShowConnection;
  /** An array relationship */
  reportLessonNoShows: Array<ReportLessonNoShow>;
  /** An aggregate relationship */
  reportLessonNoShows_aggregate: ReportLessonNoShowAggregate;
  /** An array relationship connection */
  reportLessonNoShows_connection: ReportLessonNoShowConnection;
  /** An array relationship */
  reportedAsClinicNoShows: Array<ReportClinicNoShow>;
  /** An aggregate relationship */
  reportedAsClinicNoShows_aggregate: ReportClinicNoShowAggregate;
  /** An array relationship connection */
  reportedAsClinicNoShows_connection: ReportClinicNoShowConnection;
  /** An array relationship */
  reportedAsLessonNoShows: Array<ReportLessonNoShow>;
  /** An aggregate relationship */
  reportedAsLessonNoShows_aggregate: ReportLessonNoShowAggregate;
  /** An array relationship connection */
  reportedAsLessonNoShows_connection: ReportLessonNoShowConnection;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: ReviewAggregate;
  /** An array relationship connection */
  reviews_connection: ReviewConnection;
  ridingSince?: Maybe<Scalars['Int']>;
  selectedOrganizerSlug?: Maybe<Scalars['String']>;
  showSetupCoach: Scalars['Boolean'];
  slug: Scalars['String'];
  sponsors?: Maybe<Scalars['String']>;
  stravaProfile?: Maybe<Scalars['String']>;
  /** An object relationship */
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
  /** An array relationship */
  stripeConnectAccounts: Array<StripeConnectAccount>;
  /** An aggregate relationship */
  stripeConnectAccounts_aggregate: StripeConnectAccountAggregate;
  /** An array relationship connection */
  stripeConnectAccounts_connection: StripeConnectAccountConnection;
  stripeConnectId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationError?: Maybe<Scalars['String']>;
  stripeIdentityVerificationSessionId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationStatus?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  twitterProfile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  userAddresses: Array<UserAddress>;
  /** An aggregate relationship */
  userAddresses_aggregate: UserAddressAggregate;
  /** An array relationship connection */
  userAddresses_connection: UserAddressConnection;
  /** An array relationship */
  userBikes: Array<UserBike>;
  /** An aggregate relationship */
  userBikes_aggregate: UserBikeAggregate;
  /** An array relationship connection */
  userBikes_connection: UserBikeConnection;
  /** An array relationship */
  userCoaches: Array<Coach>;
  /** An aggregate relationship */
  userCoaches_aggregate: CoachAggregate;
  /** An array relationship connection */
  userCoaches_connection: CoachConnection;
  /** An array relationship */
  userEmergencyContacts: Array<UserEmergencyContact>;
  /** An aggregate relationship */
  userEmergencyContacts_aggregate: UserEmergencyContactAggregate;
  /** An array relationship connection */
  userEmergencyContacts_connection: UserEmergencyContactConnection;
  /** An array relationship */
  userImages: Array<UserImage>;
  /** An aggregate relationship */
  userImages_aggregate: UserImageAggregate;
  /** An array relationship connection */
  userImages_connection: UserImageConnection;
  /** An array relationship */
  userOnlineCourses: Array<UserOnlineCourse>;
  /** An aggregate relationship */
  userOnlineCourses_aggregate: UserOnlineCourseAggregate;
  /** An array relationship connection */
  userOnlineCourses_connection: UserOnlineCourseConnection;
  /** An array relationship */
  userOrganizerRoles: Array<UserOrganizerRole>;
  /** An aggregate relationship */
  userOrganizerRoles_aggregate: UserOrganizerRoleAggregate;
  /** An array relationship connection */
  userOrganizerRoles_connection: UserOrganizerRoleConnection;
  /** An array relationship */
  userPersonalizations: Array<UserPersonalization>;
  /** An aggregate relationship */
  userPersonalizations_aggregate: UserPersonalizationAggregate;
  /** An array relationship connection */
  userPersonalizations_connection: UserPersonalizationConnection;
  /** An array relationship */
  userRidingInterests: Array<UserRidingInterest>;
  /** An aggregate relationship */
  userRidingInterests_aggregate: UserRidingInterestAggregate;
  /** An array relationship connection */
  userRidingInterests_connection: UserRidingInterestConnection;
  /** An array relationship */
  userSanctionLicenses: Array<UserSanctionLicense>;
  /** An aggregate relationship */
  userSanctionLicenses_aggregate: UserSanctionLicenseAggregate;
  /** An array relationship connection */
  userSanctionLicenses_connection: UserSanctionLicenseConnection;
  /** An array relationship */
  userSavedCoaches: Array<UserSavedCoach>;
  /** An aggregate relationship */
  userSavedCoaches_aggregate: UserSavedCoachAggregate;
  /** An array relationship connection */
  userSavedCoaches_connection: UserSavedCoachConnection;
  /** An array relationship */
  userSavedEvents: Array<UserSavedEvent>;
  /** An aggregate relationship */
  userSavedEvents_aggregate: UserSavedEventAggregate;
  /** An array relationship connection */
  userSavedEvents_connection: UserSavedEventConnection;
  /** An array relationship */
  userSavedLessons: Array<UserSavedLesson>;
  /** An aggregate relationship */
  userSavedLessons_aggregate: UserSavedLessonAggregate;
  /** An array relationship connection */
  userSavedLessons_connection: UserSavedLessonConnection;
  /** An array relationship */
  waiverRevisions: Array<WaiverRevision>;
  /** An aggregate relationship */
  waiverRevisions_aggregate: WaiverRevisionAggregate;
  /** An array relationship connection */
  waiverRevisions_connection: WaiverRevisionConnection;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachCustomerUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachCustomerUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachCustomerUserOrderBy>>;
  where?: InputMaybe<CoachCustomerUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachModerationFlagsArgs = {
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachModerationFlagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCoachModerationFlagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachModerationFlagOrderBy>>;
  where?: InputMaybe<CoachModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByAcceptorArgs = {
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByAcceptorAggregateArgs = {
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByAcceptorConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByInviterArgs = {
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByInviterAggregateArgs = {
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserCreatorInvitesByInviterConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CreatorInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreatorInviteOrderBy>>;
  where?: InputMaybe<CreatorInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventModerationFlagsArgs = {
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventModerationFlagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventModerationFlagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventModerationFlagSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventModerationFlagOrderBy>>;
  where?: InputMaybe<EventModerationFlagBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersDeletedByUserArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersDeletedByUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersDeletedByUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionAnswerSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionAnswerOrderBy>>;
  where?: InputMaybe<EventQuestionAnswerBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsDeletedByUserArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsDeletedByUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsDeletedByUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventQuestionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventQuestionOrderBy>>;
  where?: InputMaybe<EventQuestionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsDeletedByUserArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsDeletedByUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsDeletedByUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdateCommentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateCommentSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateCommentOrderBy>>;
  where?: InputMaybe<EventUpdateCommentBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesDeletedByUserArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesDeletedByUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesDeletedByUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserEventUpdatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventUpdateSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventUpdateOrderBy>>;
  where?: InputMaybe<EventUpdateBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserGenderArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserImageUsersArgs = {
  distinct_on?: InputMaybe<Array<ImageUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageUserOrderBy>>;
  where?: InputMaybe<ImageUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserImageUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageUserOrderBy>>;
  where?: InputMaybe<ImageUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserImageUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ImageUserSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImageUserOrderBy>>;
  where?: InputMaybe<ImageUserBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerChannelsArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerChannelSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerChannelOrderBy>>;
  where?: InputMaybe<OrganizerChannelBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviteeArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviteeAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviteeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviterArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviterAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserOrganizerInvitesByInviterConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<OrganizerInviteSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizerInviteOrderBy>>;
  where?: InputMaybe<OrganizerInviteBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsByPayinguseridArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsByPayinguseridAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsByPayinguseridConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserRegistrationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<RegistrationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RegistrationOrderBy>>;
  where?: InputMaybe<RegistrationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportClinicNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportClinicNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportClinicNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportLessonNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportLessonNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportLessonNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsClinicNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsClinicNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsClinicNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportClinicNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportClinicNoShowOrderBy>>;
  where?: InputMaybe<ReportClinicNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsLessonNoShowsArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsLessonNoShowsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReportedAsLessonNoShowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReportLessonNoShowSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReportLessonNoShowOrderBy>>;
  where?: InputMaybe<ReportLessonNoShowBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReviewsArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReviewsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserReviewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<ReviewSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewOrderBy>>;
  where?: InputMaybe<ReviewBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserStripeConnectAccountsArgs = {
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserStripeConnectAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserStripeConnectAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<StripeConnectAccountSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripeConnectAccountOrderBy>>;
  where?: InputMaybe<StripeConnectAccountBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserAddressesArgs = {
  distinct_on?: InputMaybe<Array<UserAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddressOrderBy>>;
  where?: InputMaybe<UserAddressBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddressOrderBy>>;
  where?: InputMaybe<UserAddressBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserAddressesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserAddressSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddressOrderBy>>;
  where?: InputMaybe<UserAddressBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserBikesArgs = {
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserBikesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserBikesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserCoachesArgs = {
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<CoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoachOrderBy>>;
  where?: InputMaybe<CoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserEmergencyContactsArgs = {
  distinct_on?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserEmergencyContactOrderBy>>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserEmergencyContactsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserEmergencyContactOrderBy>>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserEmergencyContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserEmergencyContactOrderBy>>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserImagesArgs = {
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserImageSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserImageOrderBy>>;
  where?: InputMaybe<UserImageBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOrganizerRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOrganizerRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserOrganizerRolesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganizerRoleOrderBy>>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserPersonalizationsArgs = {
  distinct_on?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPersonalizationOrderBy>>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserPersonalizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPersonalizationOrderBy>>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserPersonalizationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPersonalizationOrderBy>>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserRidingInterestsArgs = {
  distinct_on?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserRidingInterestOrderBy>>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserRidingInterestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserRidingInterestOrderBy>>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserRidingInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserRidingInterestOrderBy>>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSanctionLicensesArgs = {
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSanctionLicensesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSanctionLicensesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSanctionLicenseOrderBy>>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedCoachesArgs = {
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedCoachesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedCoachesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedCoachOrderBy>>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedEventsArgs = {
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedEventOrderBy>>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedLessonsArgs = {
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedLessonsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserUserSavedLessonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserSavedLessonOrderBy>>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserWaiverRevisionsArgs = {
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserWaiverRevisionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


/** Add this view CREATE OR REPLACE VIEW "public"."userPublic" AS   SELECT "user".id,     "user".email,     "user"."showFullName",     "user"."firstName",     "user"."lastName",     "user"."profilePicUrl",     "user"."deletedAt"    FROM "user"; */
export type UserWaiverRevisionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};

/** columns and relationships of "userAddress" */
export type UserAddress = Node & {
  __typename?: 'userAddress';
  address: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  label: Scalars['String'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};

/** A Relay connection object on "userAddress" */
export type UserAddressConnection = {
  __typename?: 'userAddressConnection';
  edges: Array<UserAddressEdge>;
  pageInfo: PageInfo;
};

export type UserAddressEdge = {
  __typename?: 'userAddressEdge';
  cursor: Scalars['String'];
  node: UserAddress;
};

/** aggregated selection of "userAddress" */
export type UserAddressAggregate = {
  __typename?: 'userAddress_aggregate';
  aggregate?: Maybe<UserAddressAggregateFields>;
  nodes: Array<UserAddress>;
};

export type UserAddressAggregateBoolExp = {
  bool_and?: InputMaybe<UserAddressAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserAddressAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserAddressAggregateBoolExpCount>;
};

export type UserAddressAggregateBoolExpBoolAnd = {
  arguments: UserAddressSelectColumnUserAddressAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAddressAggregateBoolExpBoolOr = {
  arguments: UserAddressSelectColumnUserAddressAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAddressAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserAddressBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userAddress" */
export type UserAddressAggregateFields = {
  __typename?: 'userAddress_aggregate_fields';
  avg?: Maybe<UserAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserAddressMaxFields>;
  min?: Maybe<UserAddressMinFields>;
  stddev?: Maybe<UserAddressStddevFields>;
  stddev_pop?: Maybe<UserAddressStddevPopFields>;
  stddev_samp?: Maybe<UserAddressStddevSampFields>;
  sum?: Maybe<UserAddressSumFields>;
  var_pop?: Maybe<UserAddressVarPopFields>;
  var_samp?: Maybe<UserAddressVarSampFields>;
  variance?: Maybe<UserAddressVarianceFields>;
};


/** aggregate fields of "userAddress" */
export type UserAddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userAddress" */
export type UserAddressAggregateOrderBy = {
  avg?: InputMaybe<UserAddressAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserAddressMaxOrderBy>;
  min?: InputMaybe<UserAddressMinOrderBy>;
  stddev?: InputMaybe<UserAddressStddevOrderBy>;
  stddev_pop?: InputMaybe<UserAddressStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserAddressStddevSampOrderBy>;
  sum?: InputMaybe<UserAddressSumOrderBy>;
  var_pop?: InputMaybe<UserAddressVarPopOrderBy>;
  var_samp?: InputMaybe<UserAddressVarSampOrderBy>;
  variance?: InputMaybe<UserAddressVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userAddress" */
export type UserAddressArrRelInsertInput = {
  data: Array<UserAddressInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAddressOnConflict>;
};

/** aggregate avg on columns */
export type UserAddressAvgFields = {
  __typename?: 'userAddress_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userAddress" */
export type UserAddressAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userAddress". All fields are combined with a logical 'AND'. */
export type UserAddressBoolExp = {
  _and?: InputMaybe<Array<UserAddressBoolExp>>;
  _not?: InputMaybe<UserAddressBoolExp>;
  _or?: InputMaybe<Array<UserAddressBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  addressLine2?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userAddress" */
export enum UserAddressConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserAddressCuidKey = 'userAddress_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserAddressPkey = 'userAddress_pkey',
  /** unique or primary key constraint on columns "googlePlaceId", "userId" */
  UserAddressUserIdGooglePlaceIdKey = 'userAddress_userId_googlePlaceId_key'
}

/** input type for incrementing numeric columns in table "userAddress" */
export type UserAddressIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "userAddress" */
export type UserAddressInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserAddressMaxFields = {
  __typename?: 'userAddress_max_fields';
  address?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userAddress" */
export type UserAddressMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserAddressMinFields = {
  __typename?: 'userAddress_min_fields';
  address?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userAddress" */
export type UserAddressMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userAddress" */
export type UserAddressMutationResponse = {
  __typename?: 'userAddress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAddress>;
};

/** on_conflict condition type for table "userAddress" */
export type UserAddressOnConflict = {
  constraint: UserAddressConstraint;
  update_columns?: Array<UserAddressUpdateColumn>;
  where?: InputMaybe<UserAddressBoolExp>;
};

/** Ordering options when selecting data from "userAddress". */
export type UserAddressOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userAddress */
export type UserAddressPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "userAddress" */
export enum UserAddressSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Label = 'label',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Phone = 'phone',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZipCode = 'zipCode'
}

/** select "userAddress_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userAddress" */
export enum UserAddressSelectColumnUserAddressAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "userAddress_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userAddress" */
export enum UserAddressSelectColumnUserAddressAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "userAddress" */
export type UserAddressSetInput = {
  address?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserAddressStddevFields = {
  __typename?: 'userAddress_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userAddress" */
export type UserAddressStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserAddressStddevPopFields = {
  __typename?: 'userAddress_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userAddress" */
export type UserAddressStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserAddressStddevSampFields = {
  __typename?: 'userAddress_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userAddress" */
export type UserAddressStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserAddressSumFields = {
  __typename?: 'userAddress_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "userAddress" */
export type UserAddressSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "userAddress" */
export enum UserAddressUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  GooglePlaceId = 'googlePlaceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Label = 'label',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Phone = 'phone',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZipCode = 'zipCode'
}

export type UserAddressUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserAddressIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAddressSetInput>;
  where: UserAddressBoolExp;
};

/** aggregate var_pop on columns */
export type UserAddressVarPopFields = {
  __typename?: 'userAddress_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userAddress" */
export type UserAddressVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserAddressVarSampFields = {
  __typename?: 'userAddress_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userAddress" */
export type UserAddressVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserAddressVarianceFields = {
  __typename?: 'userAddress_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userAddress" */
export type UserAddressVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** A bike for a user */
export type UserBike = Node & {
  __typename?: 'userBike';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  latestRevision?: Maybe<UserBikeRevision>;
  latestRevisionNum: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  /** An array relationship */
  userBikeRevisions: Array<UserBikeRevision>;
  /** An aggregate relationship */
  userBikeRevisions_aggregate: UserBikeRevisionAggregate;
  /** An array relationship connection */
  userBikeRevisions_connection: UserBikeRevisionConnection;
  userId: Scalars['String'];
};


/** A bike for a user */
export type UserBikeUserBikeRevisionsArgs = {
  distinct_on?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeRevisionOrderBy>>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};


/** A bike for a user */
export type UserBikeUserBikeRevisionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeRevisionOrderBy>>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};


/** A bike for a user */
export type UserBikeUserBikeRevisionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeRevisionOrderBy>>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};

/** A Relay connection object on "userBike" */
export type UserBikeConnection = {
  __typename?: 'userBikeConnection';
  edges: Array<UserBikeEdge>;
  pageInfo: PageInfo;
};

export type UserBikeEdge = {
  __typename?: 'userBikeEdge';
  cursor: Scalars['String'];
  node: UserBike;
};

/** a revision for a bike */
export type UserBikeRevision = Node & {
  __typename?: 'userBikeRevision';
  bike?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revision: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userBike: UserBike;
  userBikeCuid: Scalars['String'];
  /** An array relationship */
  userBikes: Array<UserBike>;
  /** An aggregate relationship */
  userBikes_aggregate: UserBikeAggregate;
  /** An array relationship connection */
  userBikes_connection: UserBikeConnection;
};


/** a revision for a bike */
export type UserBikeRevisionBikeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** a revision for a bike */
export type UserBikeRevisionUserBikesArgs = {
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


/** a revision for a bike */
export type UserBikeRevisionUserBikesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};


/** a revision for a bike */
export type UserBikeRevisionUserBikesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserBikeSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserBikeOrderBy>>;
  where?: InputMaybe<UserBikeBoolExp>;
};

/** A Relay connection object on "userBikeRevision" */
export type UserBikeRevisionConnection = {
  __typename?: 'userBikeRevisionConnection';
  edges: Array<UserBikeRevisionEdge>;
  pageInfo: PageInfo;
};

export type UserBikeRevisionEdge = {
  __typename?: 'userBikeRevisionEdge';
  cursor: Scalars['String'];
  node: UserBikeRevision;
};

/** aggregated selection of "userBikeRevision" */
export type UserBikeRevisionAggregate = {
  __typename?: 'userBikeRevision_aggregate';
  aggregate?: Maybe<UserBikeRevisionAggregateFields>;
  nodes: Array<UserBikeRevision>;
};

export type UserBikeRevisionAggregateBoolExp = {
  count?: InputMaybe<UserBikeRevisionAggregateBoolExpCount>;
};

export type UserBikeRevisionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBikeRevisionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userBikeRevision" */
export type UserBikeRevisionAggregateFields = {
  __typename?: 'userBikeRevision_aggregate_fields';
  avg?: Maybe<UserBikeRevisionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserBikeRevisionMaxFields>;
  min?: Maybe<UserBikeRevisionMinFields>;
  stddev?: Maybe<UserBikeRevisionStddevFields>;
  stddev_pop?: Maybe<UserBikeRevisionStddevPopFields>;
  stddev_samp?: Maybe<UserBikeRevisionStddevSampFields>;
  sum?: Maybe<UserBikeRevisionSumFields>;
  var_pop?: Maybe<UserBikeRevisionVarPopFields>;
  var_samp?: Maybe<UserBikeRevisionVarSampFields>;
  variance?: Maybe<UserBikeRevisionVarianceFields>;
};


/** aggregate fields of "userBikeRevision" */
export type UserBikeRevisionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserBikeRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userBikeRevision" */
export type UserBikeRevisionAggregateOrderBy = {
  avg?: InputMaybe<UserBikeRevisionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserBikeRevisionMaxOrderBy>;
  min?: InputMaybe<UserBikeRevisionMinOrderBy>;
  stddev?: InputMaybe<UserBikeRevisionStddevOrderBy>;
  stddev_pop?: InputMaybe<UserBikeRevisionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserBikeRevisionStddevSampOrderBy>;
  sum?: InputMaybe<UserBikeRevisionSumOrderBy>;
  var_pop?: InputMaybe<UserBikeRevisionVarPopOrderBy>;
  var_samp?: InputMaybe<UserBikeRevisionVarSampOrderBy>;
  variance?: InputMaybe<UserBikeRevisionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserBikeRevisionAppendInput = {
  bike?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "userBikeRevision" */
export type UserBikeRevisionArrRelInsertInput = {
  data: Array<UserBikeRevisionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserBikeRevisionOnConflict>;
};

/** aggregate avg on columns */
export type UserBikeRevisionAvgFields = {
  __typename?: 'userBikeRevision_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userBikeRevision" */
export type UserBikeRevisionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userBikeRevision". All fields are combined with a logical 'AND'. */
export type UserBikeRevisionBoolExp = {
  _and?: InputMaybe<Array<UserBikeRevisionBoolExp>>;
  _not?: InputMaybe<UserBikeRevisionBoolExp>;
  _or?: InputMaybe<Array<UserBikeRevisionBoolExp>>;
  bike?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  revision?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userBike?: InputMaybe<UserBikeBoolExp>;
  userBikeCuid?: InputMaybe<StringComparisonExp>;
  userBikes?: InputMaybe<UserBikeBoolExp>;
  userBikes_aggregate?: InputMaybe<UserBikeAggregateBoolExp>;
};

/** unique or primary key constraints on table "userBikeRevision" */
export enum UserBikeRevisionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserBikeRevisionCuidKey = 'userBikeRevision_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserBikeRevisionPkey = 'userBikeRevision_pkey',
  /** unique or primary key constraint on columns "revision", "userBikeCuid" */
  UserBikeRevisionUserBikeCuidRevisionKey = 'userBikeRevision_userBikeCuid_revision_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserBikeRevisionDeleteAtPathInput = {
  bike?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserBikeRevisionDeleteElemInput = {
  bike?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserBikeRevisionDeleteKeyInput = {
  bike?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "userBikeRevision" */
export type UserBikeRevisionIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userBikeRevision" */
export type UserBikeRevisionInsertInput = {
  bike?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userBike?: InputMaybe<UserBikeObjRelInsertInput>;
  userBikeCuid?: InputMaybe<Scalars['String']>;
  userBikes?: InputMaybe<UserBikeArrRelInsertInput>;
};

/** aggregate max on columns */
export type UserBikeRevisionMaxFields = {
  __typename?: 'userBikeRevision_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userBikeCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userBikeRevision" */
export type UserBikeRevisionMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userBikeCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserBikeRevisionMinFields = {
  __typename?: 'userBikeRevision_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userBikeCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userBikeRevision" */
export type UserBikeRevisionMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userBikeCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userBikeRevision" */
export type UserBikeRevisionMutationResponse = {
  __typename?: 'userBikeRevision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserBikeRevision>;
};

/** input type for inserting object relation for remote table "userBikeRevision" */
export type UserBikeRevisionObjRelInsertInput = {
  data: UserBikeRevisionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserBikeRevisionOnConflict>;
};

/** on_conflict condition type for table "userBikeRevision" */
export type UserBikeRevisionOnConflict = {
  constraint: UserBikeRevisionConstraint;
  update_columns?: Array<UserBikeRevisionUpdateColumn>;
  where?: InputMaybe<UserBikeRevisionBoolExp>;
};

/** Ordering options when selecting data from "userBikeRevision". */
export type UserBikeRevisionOrderBy = {
  bike?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userBike?: InputMaybe<UserBikeOrderBy>;
  userBikeCuid?: InputMaybe<OrderBy>;
  userBikes_aggregate?: InputMaybe<UserBikeAggregateOrderBy>;
};

/** primary key columns input for table: userBikeRevision */
export type UserBikeRevisionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserBikeRevisionPrependInput = {
  bike?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "userBikeRevision" */
export enum UserBikeRevisionSelectColumn {
  /** column name */
  Bike = 'bike',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserBikeCuid = 'userBikeCuid'
}

/** input type for updating data in table "userBikeRevision" */
export type UserBikeRevisionSetInput = {
  bike?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userBikeCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserBikeRevisionStddevFields = {
  __typename?: 'userBikeRevision_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userBikeRevision" */
export type UserBikeRevisionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserBikeRevisionStddevPopFields = {
  __typename?: 'userBikeRevision_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userBikeRevision" */
export type UserBikeRevisionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserBikeRevisionStddevSampFields = {
  __typename?: 'userBikeRevision_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userBikeRevision" */
export type UserBikeRevisionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserBikeRevisionSumFields = {
  __typename?: 'userBikeRevision_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  revision?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userBikeRevision" */
export type UserBikeRevisionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** update columns of table "userBikeRevision" */
export enum UserBikeRevisionUpdateColumn {
  /** column name */
  Bike = 'bike',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserBikeCuid = 'userBikeCuid'
}

export type UserBikeRevisionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserBikeRevisionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserBikeRevisionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserBikeRevisionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserBikeRevisionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserBikeRevisionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserBikeRevisionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserBikeRevisionSetInput>;
  where: UserBikeRevisionBoolExp;
};

/** aggregate var_pop on columns */
export type UserBikeRevisionVarPopFields = {
  __typename?: 'userBikeRevision_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userBikeRevision" */
export type UserBikeRevisionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserBikeRevisionVarSampFields = {
  __typename?: 'userBikeRevision_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userBikeRevision" */
export type UserBikeRevisionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserBikeRevisionVarianceFields = {
  __typename?: 'userBikeRevision_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userBikeRevision" */
export type UserBikeRevisionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregated selection of "userBike" */
export type UserBikeAggregate = {
  __typename?: 'userBike_aggregate';
  aggregate?: Maybe<UserBikeAggregateFields>;
  nodes: Array<UserBike>;
};

export type UserBikeAggregateBoolExp = {
  count?: InputMaybe<UserBikeAggregateBoolExpCount>;
};

export type UserBikeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserBikeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBikeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userBike" */
export type UserBikeAggregateFields = {
  __typename?: 'userBike_aggregate_fields';
  avg?: Maybe<UserBikeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserBikeMaxFields>;
  min?: Maybe<UserBikeMinFields>;
  stddev?: Maybe<UserBikeStddevFields>;
  stddev_pop?: Maybe<UserBikeStddevPopFields>;
  stddev_samp?: Maybe<UserBikeStddevSampFields>;
  sum?: Maybe<UserBikeSumFields>;
  var_pop?: Maybe<UserBikeVarPopFields>;
  var_samp?: Maybe<UserBikeVarSampFields>;
  variance?: Maybe<UserBikeVarianceFields>;
};


/** aggregate fields of "userBike" */
export type UserBikeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserBikeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userBike" */
export type UserBikeAggregateOrderBy = {
  avg?: InputMaybe<UserBikeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserBikeMaxOrderBy>;
  min?: InputMaybe<UserBikeMinOrderBy>;
  stddev?: InputMaybe<UserBikeStddevOrderBy>;
  stddev_pop?: InputMaybe<UserBikeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserBikeStddevSampOrderBy>;
  sum?: InputMaybe<UserBikeSumOrderBy>;
  var_pop?: InputMaybe<UserBikeVarPopOrderBy>;
  var_samp?: InputMaybe<UserBikeVarSampOrderBy>;
  variance?: InputMaybe<UserBikeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userBike" */
export type UserBikeArrRelInsertInput = {
  data: Array<UserBikeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserBikeOnConflict>;
};

/** aggregate avg on columns */
export type UserBikeAvgFields = {
  __typename?: 'userBike_avg_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userBike" */
export type UserBikeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userBike". All fields are combined with a logical 'AND'. */
export type UserBikeBoolExp = {
  _and?: InputMaybe<Array<UserBikeBoolExp>>;
  _not?: InputMaybe<UserBikeBoolExp>;
  _or?: InputMaybe<Array<UserBikeBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  latestRevision?: InputMaybe<UserBikeRevisionBoolExp>;
  latestRevisionNum?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userBikeRevisions?: InputMaybe<UserBikeRevisionBoolExp>;
  userBikeRevisions_aggregate?: InputMaybe<UserBikeRevisionAggregateBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userBike" */
export enum UserBikeConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserBikeCuidKey = 'userBike_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserBikePkey = 'userBike_pkey'
}

/** input type for incrementing numeric columns in table "userBike" */
export type UserBikeIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userBike" */
export type UserBikeInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  latestRevision?: InputMaybe<UserBikeRevisionObjRelInsertInput>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userBikeRevisions?: InputMaybe<UserBikeRevisionArrRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserBikeMaxFields = {
  __typename?: 'userBike_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userBike" */
export type UserBikeMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserBikeMinFields = {
  __typename?: 'userBike_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userBike" */
export type UserBikeMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userBike" */
export type UserBikeMutationResponse = {
  __typename?: 'userBike_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserBike>;
};

/** input type for inserting object relation for remote table "userBike" */
export type UserBikeObjRelInsertInput = {
  data: UserBikeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserBikeOnConflict>;
};

/** on_conflict condition type for table "userBike" */
export type UserBikeOnConflict = {
  constraint: UserBikeConstraint;
  update_columns?: Array<UserBikeUpdateColumn>;
  where?: InputMaybe<UserBikeBoolExp>;
};

/** Ordering options when selecting data from "userBike". */
export type UserBikeOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevision?: InputMaybe<UserBikeRevisionOrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userBikeRevisions_aggregate?: InputMaybe<UserBikeRevisionAggregateOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userBike */
export type UserBikePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userBike" */
export enum UserBikeSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userBike" */
export type UserBikeSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserBikeStddevFields = {
  __typename?: 'userBike_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userBike" */
export type UserBikeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserBikeStddevPopFields = {
  __typename?: 'userBike_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userBike" */
export type UserBikeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserBikeStddevSampFields = {
  __typename?: 'userBike_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userBike" */
export type UserBikeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserBikeSumFields = {
  __typename?: 'userBike_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userBike" */
export type UserBikeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** update columns of table "userBike" */
export enum UserBikeUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserBikeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserBikeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserBikeSetInput>;
  where: UserBikeBoolExp;
};

/** aggregate var_pop on columns */
export type UserBikeVarPopFields = {
  __typename?: 'userBike_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userBike" */
export type UserBikeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserBikeVarSampFields = {
  __typename?: 'userBike_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userBike" */
export type UserBikeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserBikeVarianceFields = {
  __typename?: 'userBike_variance_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userBike" */
export type UserBikeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** A Relay connection object on "user" */
export type UserConnection = {
  __typename?: 'userConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'userEdge';
  cursor: Scalars['String'];
  node: User;
};

/** columns and relationships of "userEmergencyContact" */
export type UserEmergencyContact = Node & {
  __typename?: 'userEmergencyContact';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
  relationship: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userEmergencyContact" */
export type UserEmergencyContactConnection = {
  __typename?: 'userEmergencyContactConnection';
  edges: Array<UserEmergencyContactEdge>;
  pageInfo: PageInfo;
};

export type UserEmergencyContactEdge = {
  __typename?: 'userEmergencyContactEdge';
  cursor: Scalars['String'];
  node: UserEmergencyContact;
};

/** aggregated selection of "userEmergencyContact" */
export type UserEmergencyContactAggregate = {
  __typename?: 'userEmergencyContact_aggregate';
  aggregate?: Maybe<UserEmergencyContactAggregateFields>;
  nodes: Array<UserEmergencyContact>;
};

export type UserEmergencyContactAggregateBoolExp = {
  bool_and?: InputMaybe<UserEmergencyContactAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserEmergencyContactAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserEmergencyContactAggregateBoolExpCount>;
};

export type UserEmergencyContactAggregateBoolExpBoolAnd = {
  arguments: UserEmergencyContactSelectColumnUserEmergencyContactAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserEmergencyContactBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserEmergencyContactAggregateBoolExpBoolOr = {
  arguments: UserEmergencyContactSelectColumnUserEmergencyContactAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserEmergencyContactBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserEmergencyContactAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserEmergencyContactBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userEmergencyContact" */
export type UserEmergencyContactAggregateFields = {
  __typename?: 'userEmergencyContact_aggregate_fields';
  avg?: Maybe<UserEmergencyContactAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserEmergencyContactMaxFields>;
  min?: Maybe<UserEmergencyContactMinFields>;
  stddev?: Maybe<UserEmergencyContactStddevFields>;
  stddev_pop?: Maybe<UserEmergencyContactStddevPopFields>;
  stddev_samp?: Maybe<UserEmergencyContactStddevSampFields>;
  sum?: Maybe<UserEmergencyContactSumFields>;
  var_pop?: Maybe<UserEmergencyContactVarPopFields>;
  var_samp?: Maybe<UserEmergencyContactVarSampFields>;
  variance?: Maybe<UserEmergencyContactVarianceFields>;
};


/** aggregate fields of "userEmergencyContact" */
export type UserEmergencyContactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserEmergencyContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userEmergencyContact" */
export type UserEmergencyContactAggregateOrderBy = {
  avg?: InputMaybe<UserEmergencyContactAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserEmergencyContactMaxOrderBy>;
  min?: InputMaybe<UserEmergencyContactMinOrderBy>;
  stddev?: InputMaybe<UserEmergencyContactStddevOrderBy>;
  stddev_pop?: InputMaybe<UserEmergencyContactStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserEmergencyContactStddevSampOrderBy>;
  sum?: InputMaybe<UserEmergencyContactSumOrderBy>;
  var_pop?: InputMaybe<UserEmergencyContactVarPopOrderBy>;
  var_samp?: InputMaybe<UserEmergencyContactVarSampOrderBy>;
  variance?: InputMaybe<UserEmergencyContactVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userEmergencyContact" */
export type UserEmergencyContactArrRelInsertInput = {
  data: Array<UserEmergencyContactInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserEmergencyContactOnConflict>;
};

/** aggregate avg on columns */
export type UserEmergencyContactAvgFields = {
  __typename?: 'userEmergencyContact_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userEmergencyContact" */
export type UserEmergencyContactAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userEmergencyContact". All fields are combined with a logical 'AND'. */
export type UserEmergencyContactBoolExp = {
  _and?: InputMaybe<Array<UserEmergencyContactBoolExp>>;
  _not?: InputMaybe<UserEmergencyContactBoolExp>;
  _or?: InputMaybe<Array<UserEmergencyContactBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  relationship?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userEmergencyContact" */
export enum UserEmergencyContactConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserEmergencyContactCuidKey = 'userEmergencyContact_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserEmergencyContactPkey = 'userEmergencyContact_pkey'
}

/** input type for incrementing numeric columns in table "userEmergencyContact" */
export type UserEmergencyContactIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userEmergencyContact" */
export type UserEmergencyContactInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserEmergencyContactMaxFields = {
  __typename?: 'userEmergencyContact_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userEmergencyContact" */
export type UserEmergencyContactMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserEmergencyContactMinFields = {
  __typename?: 'userEmergencyContact_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userEmergencyContact" */
export type UserEmergencyContactMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userEmergencyContact" */
export type UserEmergencyContactMutationResponse = {
  __typename?: 'userEmergencyContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserEmergencyContact>;
};

/** on_conflict condition type for table "userEmergencyContact" */
export type UserEmergencyContactOnConflict = {
  constraint: UserEmergencyContactConstraint;
  update_columns?: Array<UserEmergencyContactUpdateColumn>;
  where?: InputMaybe<UserEmergencyContactBoolExp>;
};

/** Ordering options when selecting data from "userEmergencyContact". */
export type UserEmergencyContactOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userEmergencyContact */
export type UserEmergencyContactPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "userEmergencyContact" */
export enum UserEmergencyContactSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Relationship = 'relationship',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "userEmergencyContact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userEmergencyContact" */
export enum UserEmergencyContactSelectColumnUserEmergencyContactAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "userEmergencyContact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userEmergencyContact" */
export enum UserEmergencyContactSelectColumnUserEmergencyContactAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "userEmergencyContact" */
export type UserEmergencyContactSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserEmergencyContactStddevFields = {
  __typename?: 'userEmergencyContact_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userEmergencyContact" */
export type UserEmergencyContactStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserEmergencyContactStddevPopFields = {
  __typename?: 'userEmergencyContact_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userEmergencyContact" */
export type UserEmergencyContactStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserEmergencyContactStddevSampFields = {
  __typename?: 'userEmergencyContact_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userEmergencyContact" */
export type UserEmergencyContactStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserEmergencyContactSumFields = {
  __typename?: 'userEmergencyContact_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userEmergencyContact" */
export type UserEmergencyContactSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userEmergencyContact" */
export enum UserEmergencyContactUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Relationship = 'relationship',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserEmergencyContactUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserEmergencyContactIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserEmergencyContactSetInput>;
  where: UserEmergencyContactBoolExp;
};

/** aggregate var_pop on columns */
export type UserEmergencyContactVarPopFields = {
  __typename?: 'userEmergencyContact_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userEmergencyContact" */
export type UserEmergencyContactVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserEmergencyContactVarSampFields = {
  __typename?: 'userEmergencyContact_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userEmergencyContact" */
export type UserEmergencyContactVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserEmergencyContactVarianceFields = {
  __typename?: 'userEmergencyContact_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userEmergencyContact" */
export type UserEmergencyContactVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Image for a user */
export type UserImage = Node & {
  __typename?: 'userImage';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  image: Image;
  imagePublicId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userImage" */
export type UserImageConnection = {
  __typename?: 'userImageConnection';
  edges: Array<UserImageEdge>;
  pageInfo: PageInfo;
};

export type UserImageEdge = {
  __typename?: 'userImageEdge';
  cursor: Scalars['String'];
  node: UserImage;
};

/** aggregated selection of "userImage" */
export type UserImageAggregate = {
  __typename?: 'userImage_aggregate';
  aggregate?: Maybe<UserImageAggregateFields>;
  nodes: Array<UserImage>;
};

export type UserImageAggregateBoolExp = {
  count?: InputMaybe<UserImageAggregateBoolExpCount>;
};

export type UserImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserImageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userImage" */
export type UserImageAggregateFields = {
  __typename?: 'userImage_aggregate_fields';
  avg?: Maybe<UserImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserImageMaxFields>;
  min?: Maybe<UserImageMinFields>;
  stddev?: Maybe<UserImageStddevFields>;
  stddev_pop?: Maybe<UserImageStddevPopFields>;
  stddev_samp?: Maybe<UserImageStddevSampFields>;
  sum?: Maybe<UserImageSumFields>;
  var_pop?: Maybe<UserImageVarPopFields>;
  var_samp?: Maybe<UserImageVarSampFields>;
  variance?: Maybe<UserImageVarianceFields>;
};


/** aggregate fields of "userImage" */
export type UserImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userImage" */
export type UserImageAggregateOrderBy = {
  avg?: InputMaybe<UserImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserImageMaxOrderBy>;
  min?: InputMaybe<UserImageMinOrderBy>;
  stddev?: InputMaybe<UserImageStddevOrderBy>;
  stddev_pop?: InputMaybe<UserImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserImageStddevSampOrderBy>;
  sum?: InputMaybe<UserImageSumOrderBy>;
  var_pop?: InputMaybe<UserImageVarPopOrderBy>;
  var_samp?: InputMaybe<UserImageVarSampOrderBy>;
  variance?: InputMaybe<UserImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userImage" */
export type UserImageArrRelInsertInput = {
  data: Array<UserImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserImageOnConflict>;
};

/** aggregate avg on columns */
export type UserImageAvgFields = {
  __typename?: 'userImage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userImage" */
export type UserImageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userImage". All fields are combined with a logical 'AND'. */
export type UserImageBoolExp = {
  _and?: InputMaybe<Array<UserImageBoolExp>>;
  _not?: InputMaybe<UserImageBoolExp>;
  _or?: InputMaybe<Array<UserImageBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imagePublicId?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userImage" */
export enum UserImageConstraint {
  /** unique or primary key constraint on columns "id" */
  UserImagePkey = 'userImage_pkey'
}

/** input type for incrementing numeric columns in table "userImage" */
export type UserImageIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userImage" */
export type UserImageInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserImageMaxFields = {
  __typename?: 'userImage_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userImage" */
export type UserImageMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserImageMinFields = {
  __typename?: 'userImage_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imagePublicId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userImage" */
export type UserImageMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userImage" */
export type UserImageMutationResponse = {
  __typename?: 'userImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserImage>;
};

/** on_conflict condition type for table "userImage" */
export type UserImageOnConflict = {
  constraint: UserImageConstraint;
  update_columns?: Array<UserImageUpdateColumn>;
  where?: InputMaybe<UserImageBoolExp>;
};

/** Ordering options when selecting data from "userImage". */
export type UserImageOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imagePublicId?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userImage */
export type UserImagePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userImage" */
export enum UserImageSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userImage" */
export type UserImageSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imagePublicId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserImageStddevFields = {
  __typename?: 'userImage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userImage" */
export type UserImageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserImageStddevPopFields = {
  __typename?: 'userImage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userImage" */
export type UserImageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserImageStddevSampFields = {
  __typename?: 'userImage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userImage" */
export type UserImageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserImageSumFields = {
  __typename?: 'userImage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userImage" */
export type UserImageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userImage" */
export enum UserImageUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePublicId = 'imagePublicId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserImageSetInput>;
  where: UserImageBoolExp;
};

/** aggregate var_pop on columns */
export type UserImageVarPopFields = {
  __typename?: 'userImage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userImage" */
export type UserImageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserImageVarSampFields = {
  __typename?: 'userImage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userImage" */
export type UserImageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserImageVarianceFields = {
  __typename?: 'userImage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userImage" */
export type UserImageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Courses that the user has purchased */
export type UserOnlineCourse = Node & {
  __typename?: 'userOnlineCourse';
  completedLessons: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  lastViewedLessonCuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  onlineCourse?: Maybe<OnlineCourse>;
  onlineCourseCuid: Scalars['String'];
  /** An object relationship */
  purchasedRevision?: Maybe<OnlineCourseRevision>;
  referenceNo: Scalars['String'];
  revision: Scalars['Int'];
  status: UserOnlineCourseStatusEnum;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  /** An object relationship */
  userOnlineCourseStatus: UserOnlineCourseStatus;
};


/** Courses that the user has purchased */
export type UserOnlineCourseCompletedLessonsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "userOnlineCourse" */
export type UserOnlineCourseConnection = {
  __typename?: 'userOnlineCourseConnection';
  edges: Array<UserOnlineCourseEdge>;
  pageInfo: PageInfo;
};

export type UserOnlineCourseEdge = {
  __typename?: 'userOnlineCourseEdge';
  cursor: Scalars['String'];
  node: UserOnlineCourse;
};

/** columns and relationships of "userOnlineCourseStatus" */
export type UserOnlineCourseStatus = Node & {
  __typename?: 'userOnlineCourseStatus';
  id: Scalars['ID'];
  status: Scalars['String'];
  /** An array relationship */
  userOnlineCourses: Array<UserOnlineCourse>;
  /** An aggregate relationship */
  userOnlineCourses_aggregate: UserOnlineCourseAggregate;
  /** An array relationship connection */
  userOnlineCourses_connection: UserOnlineCourseConnection;
};


/** columns and relationships of "userOnlineCourseStatus" */
export type UserOnlineCourseStatusUserOnlineCoursesArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** columns and relationships of "userOnlineCourseStatus" */
export type UserOnlineCourseStatusUserOnlineCoursesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};


/** columns and relationships of "userOnlineCourseStatus" */
export type UserOnlineCourseStatusUserOnlineCoursesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOnlineCourseOrderBy>>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};

/** A Relay connection object on "userOnlineCourseStatus" */
export type UserOnlineCourseStatusConnection = {
  __typename?: 'userOnlineCourseStatusConnection';
  edges: Array<UserOnlineCourseStatusEdge>;
  pageInfo: PageInfo;
};

export type UserOnlineCourseStatusEdge = {
  __typename?: 'userOnlineCourseStatusEdge';
  cursor: Scalars['String'];
  node: UserOnlineCourseStatus;
};

/** Boolean expression to filter rows from the table "userOnlineCourseStatus". All fields are combined with a logical 'AND'. */
export type UserOnlineCourseStatusBoolExp = {
  _and?: InputMaybe<Array<UserOnlineCourseStatusBoolExp>>;
  _not?: InputMaybe<UserOnlineCourseStatusBoolExp>;
  _or?: InputMaybe<Array<UserOnlineCourseStatusBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseBoolExp>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateBoolExp>;
};

/** unique or primary key constraints on table "userOnlineCourseStatus" */
export enum UserOnlineCourseStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  UserOnlineCourseStatusPkey = 'userOnlineCourseStatus_pkey'
}

export enum UserOnlineCourseStatusEnum {
  Canceled = 'canceled',
  Completed = 'completed',
  InProgress = 'in_progress'
}

/** Boolean expression to compare columns of type "userOnlineCourseStatus_enum". All fields are combined with logical 'AND'. */
export type UserOnlineCourseStatusEnumComparisonExp = {
  _eq?: InputMaybe<UserOnlineCourseStatusEnum>;
  _in?: InputMaybe<Array<UserOnlineCourseStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<UserOnlineCourseStatusEnum>;
  _nin?: InputMaybe<Array<UserOnlineCourseStatusEnum>>;
};

/** input type for inserting data into table "userOnlineCourseStatus" */
export type UserOnlineCourseStatusInsertInput = {
  status?: InputMaybe<Scalars['String']>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseArrRelInsertInput>;
};

/** response of any mutation on the table "userOnlineCourseStatus" */
export type UserOnlineCourseStatusMutationResponse = {
  __typename?: 'userOnlineCourseStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOnlineCourseStatus>;
};

/** input type for inserting object relation for remote table "userOnlineCourseStatus" */
export type UserOnlineCourseStatusObjRelInsertInput = {
  data: UserOnlineCourseStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnlineCourseStatusOnConflict>;
};

/** on_conflict condition type for table "userOnlineCourseStatus" */
export type UserOnlineCourseStatusOnConflict = {
  constraint: UserOnlineCourseStatusConstraint;
  update_columns?: Array<UserOnlineCourseStatusUpdateColumn>;
  where?: InputMaybe<UserOnlineCourseStatusBoolExp>;
};

/** Ordering options when selecting data from "userOnlineCourseStatus". */
export type UserOnlineCourseStatusOrderBy = {
  status?: InputMaybe<OrderBy>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateOrderBy>;
};

/** primary key columns input for table: userOnlineCourseStatus */
export type UserOnlineCourseStatusPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "userOnlineCourseStatus" */
export enum UserOnlineCourseStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "userOnlineCourseStatus" */
export type UserOnlineCourseStatusSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "userOnlineCourseStatus" */
export enum UserOnlineCourseStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export type UserOnlineCourseStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOnlineCourseStatusSetInput>;
  where: UserOnlineCourseStatusBoolExp;
};

/** aggregated selection of "userOnlineCourse" */
export type UserOnlineCourseAggregate = {
  __typename?: 'userOnlineCourse_aggregate';
  aggregate?: Maybe<UserOnlineCourseAggregateFields>;
  nodes: Array<UserOnlineCourse>;
};

export type UserOnlineCourseAggregateBoolExp = {
  count?: InputMaybe<UserOnlineCourseAggregateBoolExpCount>;
};

export type UserOnlineCourseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserOnlineCourseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userOnlineCourse" */
export type UserOnlineCourseAggregateFields = {
  __typename?: 'userOnlineCourse_aggregate_fields';
  avg?: Maybe<UserOnlineCourseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserOnlineCourseMaxFields>;
  min?: Maybe<UserOnlineCourseMinFields>;
  stddev?: Maybe<UserOnlineCourseStddevFields>;
  stddev_pop?: Maybe<UserOnlineCourseStddevPopFields>;
  stddev_samp?: Maybe<UserOnlineCourseStddevSampFields>;
  sum?: Maybe<UserOnlineCourseSumFields>;
  var_pop?: Maybe<UserOnlineCourseVarPopFields>;
  var_samp?: Maybe<UserOnlineCourseVarSampFields>;
  variance?: Maybe<UserOnlineCourseVarianceFields>;
};


/** aggregate fields of "userOnlineCourse" */
export type UserOnlineCourseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserOnlineCourseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userOnlineCourse" */
export type UserOnlineCourseAggregateOrderBy = {
  avg?: InputMaybe<UserOnlineCourseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserOnlineCourseMaxOrderBy>;
  min?: InputMaybe<UserOnlineCourseMinOrderBy>;
  stddev?: InputMaybe<UserOnlineCourseStddevOrderBy>;
  stddev_pop?: InputMaybe<UserOnlineCourseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserOnlineCourseStddevSampOrderBy>;
  sum?: InputMaybe<UserOnlineCourseSumOrderBy>;
  var_pop?: InputMaybe<UserOnlineCourseVarPopOrderBy>;
  var_samp?: InputMaybe<UserOnlineCourseVarSampOrderBy>;
  variance?: InputMaybe<UserOnlineCourseVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserOnlineCourseAppendInput = {
  completedLessons?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "userOnlineCourse" */
export type UserOnlineCourseArrRelInsertInput = {
  data: Array<UserOnlineCourseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnlineCourseOnConflict>;
};

/** aggregate avg on columns */
export type UserOnlineCourseAvgFields = {
  __typename?: 'userOnlineCourse_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userOnlineCourse" */
export type UserOnlineCourseAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userOnlineCourse". All fields are combined with a logical 'AND'. */
export type UserOnlineCourseBoolExp = {
  _and?: InputMaybe<Array<UserOnlineCourseBoolExp>>;
  _not?: InputMaybe<UserOnlineCourseBoolExp>;
  _or?: InputMaybe<Array<UserOnlineCourseBoolExp>>;
  completedLessons?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lastViewedLessonCuid?: InputMaybe<StringComparisonExp>;
  onlineCourse?: InputMaybe<OnlineCourseBoolExp>;
  onlineCourseCuid?: InputMaybe<StringComparisonExp>;
  purchasedRevision?: InputMaybe<OnlineCourseRevisionBoolExp>;
  referenceNo?: InputMaybe<StringComparisonExp>;
  revision?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<UserOnlineCourseStatusEnumComparisonExp>;
  stripeCheckoutId?: InputMaybe<StringComparisonExp>;
  stripePaymentIntentId?: InputMaybe<StringComparisonExp>;
  successToken?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  userOnlineCourseStatus?: InputMaybe<UserOnlineCourseStatusBoolExp>;
};

/** unique or primary key constraints on table "userOnlineCourse" */
export enum UserOnlineCourseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserOnlineCourseCuidKey = 'userOnlineCourse_cuid_key',
  /** unique or primary key constraint on columns "onlineCourseCuid", "userId" */
  UserOnlineCourseOnlineCourseCuidUserIdKey = 'userOnlineCourse_onlineCourseCuid_userId_key',
  /** unique or primary key constraint on columns "id" */
  UserOnlineCoursePkey = 'userOnlineCourse_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserOnlineCourseDeleteAtPathInput = {
  completedLessons?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserOnlineCourseDeleteElemInput = {
  completedLessons?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserOnlineCourseDeleteKeyInput = {
  completedLessons?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "userOnlineCourse" */
export type UserOnlineCourseIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userOnlineCourse" */
export type UserOnlineCourseInsertInput = {
  completedLessons?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastViewedLessonCuid?: InputMaybe<Scalars['String']>;
  onlineCourse?: InputMaybe<OnlineCourseObjRelInsertInput>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  purchasedRevision?: InputMaybe<OnlineCourseRevisionObjRelInsertInput>;
  referenceNo?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<UserOnlineCourseStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  userOnlineCourseStatus?: InputMaybe<UserOnlineCourseStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type UserOnlineCourseMaxFields = {
  __typename?: 'userOnlineCourse_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastViewedLessonCuid?: Maybe<Scalars['String']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userOnlineCourse" */
export type UserOnlineCourseMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastViewedLessonCuid?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserOnlineCourseMinFields = {
  __typename?: 'userOnlineCourse_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lastViewedLessonCuid?: Maybe<Scalars['String']>;
  onlineCourseCuid?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  stripeCheckoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successToken?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userOnlineCourse" */
export type UserOnlineCourseMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastViewedLessonCuid?: InputMaybe<OrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userOnlineCourse" */
export type UserOnlineCourseMutationResponse = {
  __typename?: 'userOnlineCourse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOnlineCourse>;
};

/** on_conflict condition type for table "userOnlineCourse" */
export type UserOnlineCourseOnConflict = {
  constraint: UserOnlineCourseConstraint;
  update_columns?: Array<UserOnlineCourseUpdateColumn>;
  where?: InputMaybe<UserOnlineCourseBoolExp>;
};

/** Ordering options when selecting data from "userOnlineCourse". */
export type UserOnlineCourseOrderBy = {
  completedLessons?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastViewedLessonCuid?: InputMaybe<OrderBy>;
  onlineCourse?: InputMaybe<OnlineCourseOrderBy>;
  onlineCourseCuid?: InputMaybe<OrderBy>;
  purchasedRevision?: InputMaybe<OnlineCourseRevisionOrderBy>;
  referenceNo?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCheckoutId?: InputMaybe<OrderBy>;
  stripePaymentIntentId?: InputMaybe<OrderBy>;
  successToken?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  userOnlineCourseStatus?: InputMaybe<UserOnlineCourseStatusOrderBy>;
};

/** primary key columns input for table: userOnlineCourse */
export type UserOnlineCoursePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserOnlineCoursePrependInput = {
  completedLessons?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "userOnlineCourse" */
export enum UserOnlineCourseSelectColumn {
  /** column name */
  CompletedLessons = 'completedLessons',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LastViewedLessonCuid = 'lastViewedLessonCuid',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  Revision = 'revision',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userOnlineCourse" */
export type UserOnlineCourseSetInput = {
  completedLessons?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lastViewedLessonCuid?: InputMaybe<Scalars['String']>;
  onlineCourseCuid?: InputMaybe<Scalars['String']>;
  referenceNo?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<UserOnlineCourseStatusEnum>;
  stripeCheckoutId?: InputMaybe<Scalars['String']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']>;
  successToken?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserOnlineCourseStddevFields = {
  __typename?: 'userOnlineCourse_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userOnlineCourse" */
export type UserOnlineCourseStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserOnlineCourseStddevPopFields = {
  __typename?: 'userOnlineCourse_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userOnlineCourse" */
export type UserOnlineCourseStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserOnlineCourseStddevSampFields = {
  __typename?: 'userOnlineCourse_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userOnlineCourse" */
export type UserOnlineCourseStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserOnlineCourseSumFields = {
  __typename?: 'userOnlineCourse_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  revision?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userOnlineCourse" */
export type UserOnlineCourseSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** update columns of table "userOnlineCourse" */
export enum UserOnlineCourseUpdateColumn {
  /** column name */
  CompletedLessons = 'completedLessons',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LastViewedLessonCuid = 'lastViewedLessonCuid',
  /** column name */
  OnlineCourseCuid = 'onlineCourseCuid',
  /** column name */
  ReferenceNo = 'referenceNo',
  /** column name */
  Revision = 'revision',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutId = 'stripeCheckoutId',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  SuccessToken = 'successToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserOnlineCourseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserOnlineCourseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserOnlineCourseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserOnlineCourseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserOnlineCourseDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserOnlineCourseIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserOnlineCoursePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOnlineCourseSetInput>;
  where: UserOnlineCourseBoolExp;
};

/** aggregate var_pop on columns */
export type UserOnlineCourseVarPopFields = {
  __typename?: 'userOnlineCourse_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userOnlineCourse" */
export type UserOnlineCourseVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserOnlineCourseVarSampFields = {
  __typename?: 'userOnlineCourse_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userOnlineCourse" */
export type UserOnlineCourseVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserOnlineCourseVarianceFields = {
  __typename?: 'userOnlineCourse_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userOnlineCourse" */
export type UserOnlineCourseVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** columns and relationships of "userOrganizerRole" */
export type UserOrganizerRole = Node & {
  __typename?: 'userOrganizerRole';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid: Scalars['String'];
  id: Scalars['ID'];
  /** An object relationship */
  organizer?: Maybe<Organizer>;
  organizerCuid?: Maybe<Scalars['String']>;
  role: RoleOrganizerEnum;
  /** An object relationship */
  roleOrganizer: RoleOrganizer;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userOrganizerRole" */
export type UserOrganizerRoleConnection = {
  __typename?: 'userOrganizerRoleConnection';
  edges: Array<UserOrganizerRoleEdge>;
  pageInfo: PageInfo;
};

export type UserOrganizerRoleEdge = {
  __typename?: 'userOrganizerRoleEdge';
  cursor: Scalars['String'];
  node: UserOrganizerRole;
};

/** aggregated selection of "userOrganizerRole" */
export type UserOrganizerRoleAggregate = {
  __typename?: 'userOrganizerRole_aggregate';
  aggregate?: Maybe<UserOrganizerRoleAggregateFields>;
  nodes: Array<UserOrganizerRole>;
};

export type UserOrganizerRoleAggregateBoolExp = {
  count?: InputMaybe<UserOrganizerRoleAggregateBoolExpCount>;
};

export type UserOrganizerRoleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserOrganizerRoleBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userOrganizerRole" */
export type UserOrganizerRoleAggregateFields = {
  __typename?: 'userOrganizerRole_aggregate_fields';
  avg?: Maybe<UserOrganizerRoleAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserOrganizerRoleMaxFields>;
  min?: Maybe<UserOrganizerRoleMinFields>;
  stddev?: Maybe<UserOrganizerRoleStddevFields>;
  stddev_pop?: Maybe<UserOrganizerRoleStddevPopFields>;
  stddev_samp?: Maybe<UserOrganizerRoleStddevSampFields>;
  sum?: Maybe<UserOrganizerRoleSumFields>;
  var_pop?: Maybe<UserOrganizerRoleVarPopFields>;
  var_samp?: Maybe<UserOrganizerRoleVarSampFields>;
  variance?: Maybe<UserOrganizerRoleVarianceFields>;
};


/** aggregate fields of "userOrganizerRole" */
export type UserOrganizerRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserOrganizerRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userOrganizerRole" */
export type UserOrganizerRoleAggregateOrderBy = {
  avg?: InputMaybe<UserOrganizerRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserOrganizerRoleMaxOrderBy>;
  min?: InputMaybe<UserOrganizerRoleMinOrderBy>;
  stddev?: InputMaybe<UserOrganizerRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<UserOrganizerRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserOrganizerRoleStddevSampOrderBy>;
  sum?: InputMaybe<UserOrganizerRoleSumOrderBy>;
  var_pop?: InputMaybe<UserOrganizerRoleVarPopOrderBy>;
  var_samp?: InputMaybe<UserOrganizerRoleVarSampOrderBy>;
  variance?: InputMaybe<UserOrganizerRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userOrganizerRole" */
export type UserOrganizerRoleArrRelInsertInput = {
  data: Array<UserOrganizerRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOrganizerRoleOnConflict>;
};

/** aggregate avg on columns */
export type UserOrganizerRoleAvgFields = {
  __typename?: 'userOrganizerRole_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userOrganizerRole". All fields are combined with a logical 'AND'. */
export type UserOrganizerRoleBoolExp = {
  _and?: InputMaybe<Array<UserOrganizerRoleBoolExp>>;
  _not?: InputMaybe<UserOrganizerRoleBoolExp>;
  _or?: InputMaybe<Array<UserOrganizerRoleBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<RoleOrganizerEnumComparisonExp>;
  roleOrganizer?: InputMaybe<RoleOrganizerBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userOrganizerRole" */
export enum UserOrganizerRoleConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserOrganizerRoleCuidKey = 'userOrganizerRole_cuid_key',
  /** unique or primary key constraint on columns "userId", "role", "organizerCuid" */
  UserOrganizerRoleUserIdOrganizerCuidRoleKey = 'userOrganizerRole_userId_organizerCuid_role_key',
  /** unique or primary key constraint on columns "id" */
  UserOrganizerRolesPkey = 'userOrganizerRoles_pkey'
}

/** input type for incrementing numeric columns in table "userOrganizerRole" */
export type UserOrganizerRoleIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userOrganizerRole" */
export type UserOrganizerRoleInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleOrganizerEnum>;
  roleOrganizer?: InputMaybe<RoleOrganizerObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserOrganizerRoleMaxFields = {
  __typename?: 'userOrganizerRole_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserOrganizerRoleMinFields = {
  __typename?: 'userOrganizerRole_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  organizerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userOrganizerRole" */
export type UserOrganizerRoleMutationResponse = {
  __typename?: 'userOrganizerRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOrganizerRole>;
};

/** on_conflict condition type for table "userOrganizerRole" */
export type UserOrganizerRoleOnConflict = {
  constraint: UserOrganizerRoleConstraint;
  update_columns?: Array<UserOrganizerRoleUpdateColumn>;
  where?: InputMaybe<UserOrganizerRoleBoolExp>;
};

/** Ordering options when selecting data from "userOrganizerRole". */
export type UserOrganizerRoleOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleOrganizer?: InputMaybe<RoleOrganizerOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userOrganizerRole */
export type UserOrganizerRolePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "userOrganizerRole" */
export enum UserOrganizerRoleSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userOrganizerRole" */
export type UserOrganizerRoleSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleOrganizerEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserOrganizerRoleStddevFields = {
  __typename?: 'userOrganizerRole_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserOrganizerRoleStddevPopFields = {
  __typename?: 'userOrganizerRole_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserOrganizerRoleStddevSampFields = {
  __typename?: 'userOrganizerRole_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserOrganizerRoleSumFields = {
  __typename?: 'userOrganizerRole_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userOrganizerRole" */
export enum UserOrganizerRoleUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserOrganizerRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserOrganizerRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOrganizerRoleSetInput>;
  where: UserOrganizerRoleBoolExp;
};

/** aggregate var_pop on columns */
export type UserOrganizerRoleVarPopFields = {
  __typename?: 'userOrganizerRole_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserOrganizerRoleVarSampFields = {
  __typename?: 'userOrganizerRole_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserOrganizerRoleVarianceFields = {
  __typename?: 'userOrganizerRole_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userOrganizerRole" */
export type UserOrganizerRoleVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "userPersonalization" */
export type UserPersonalization = Node & {
  __typename?: 'userPersonalization';
  created_at: Scalars['timestamptz'];
  id: Scalars['ID'];
  preferences: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};


/** columns and relationships of "userPersonalization" */
export type UserPersonalizationPreferencesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "userPersonalization" */
export type UserPersonalizationConnection = {
  __typename?: 'userPersonalizationConnection';
  edges: Array<UserPersonalizationEdge>;
  pageInfo: PageInfo;
};

export type UserPersonalizationEdge = {
  __typename?: 'userPersonalizationEdge';
  cursor: Scalars['String'];
  node: UserPersonalization;
};

/** aggregated selection of "userPersonalization" */
export type UserPersonalizationAggregate = {
  __typename?: 'userPersonalization_aggregate';
  aggregate?: Maybe<UserPersonalizationAggregateFields>;
  nodes: Array<UserPersonalization>;
};

export type UserPersonalizationAggregateBoolExp = {
  count?: InputMaybe<UserPersonalizationAggregateBoolExpCount>;
};

export type UserPersonalizationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserPersonalizationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userPersonalization" */
export type UserPersonalizationAggregateFields = {
  __typename?: 'userPersonalization_aggregate_fields';
  avg?: Maybe<UserPersonalizationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserPersonalizationMaxFields>;
  min?: Maybe<UserPersonalizationMinFields>;
  stddev?: Maybe<UserPersonalizationStddevFields>;
  stddev_pop?: Maybe<UserPersonalizationStddevPopFields>;
  stddev_samp?: Maybe<UserPersonalizationStddevSampFields>;
  sum?: Maybe<UserPersonalizationSumFields>;
  var_pop?: Maybe<UserPersonalizationVarPopFields>;
  var_samp?: Maybe<UserPersonalizationVarSampFields>;
  variance?: Maybe<UserPersonalizationVarianceFields>;
};


/** aggregate fields of "userPersonalization" */
export type UserPersonalizationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserPersonalizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userPersonalization" */
export type UserPersonalizationAggregateOrderBy = {
  avg?: InputMaybe<UserPersonalizationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserPersonalizationMaxOrderBy>;
  min?: InputMaybe<UserPersonalizationMinOrderBy>;
  stddev?: InputMaybe<UserPersonalizationStddevOrderBy>;
  stddev_pop?: InputMaybe<UserPersonalizationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserPersonalizationStddevSampOrderBy>;
  sum?: InputMaybe<UserPersonalizationSumOrderBy>;
  var_pop?: InputMaybe<UserPersonalizationVarPopOrderBy>;
  var_samp?: InputMaybe<UserPersonalizationVarSampOrderBy>;
  variance?: InputMaybe<UserPersonalizationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserPersonalizationAppendInput = {
  preferences?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "userPersonalization" */
export type UserPersonalizationArrRelInsertInput = {
  data: Array<UserPersonalizationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserPersonalizationOnConflict>;
};

/** aggregate avg on columns */
export type UserPersonalizationAvgFields = {
  __typename?: 'userPersonalization_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userPersonalization" */
export type UserPersonalizationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userPersonalization". All fields are combined with a logical 'AND'. */
export type UserPersonalizationBoolExp = {
  _and?: InputMaybe<Array<UserPersonalizationBoolExp>>;
  _not?: InputMaybe<UserPersonalizationBoolExp>;
  _or?: InputMaybe<Array<UserPersonalizationBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  preferences?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userPersonalization" */
export enum UserPersonalizationConstraint {
  /** unique or primary key constraint on columns "id" */
  UserPersonalizationPkey = 'userPersonalization_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserPersonalizationDeleteAtPathInput = {
  preferences?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserPersonalizationDeleteElemInput = {
  preferences?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserPersonalizationDeleteKeyInput = {
  preferences?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "userPersonalization" */
export type UserPersonalizationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userPersonalization" */
export type UserPersonalizationInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  preferences?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserPersonalizationMaxFields = {
  __typename?: 'userPersonalization_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userPersonalization" */
export type UserPersonalizationMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserPersonalizationMinFields = {
  __typename?: 'userPersonalization_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userPersonalization" */
export type UserPersonalizationMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userPersonalization" */
export type UserPersonalizationMutationResponse = {
  __typename?: 'userPersonalization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserPersonalization>;
};

/** on_conflict condition type for table "userPersonalization" */
export type UserPersonalizationOnConflict = {
  constraint: UserPersonalizationConstraint;
  update_columns?: Array<UserPersonalizationUpdateColumn>;
  where?: InputMaybe<UserPersonalizationBoolExp>;
};

/** Ordering options when selecting data from "userPersonalization". */
export type UserPersonalizationOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  preferences?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userPersonalization */
export type UserPersonalizationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserPersonalizationPrependInput = {
  preferences?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "userPersonalization" */
export enum UserPersonalizationSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userPersonalization" */
export type UserPersonalizationSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  preferences?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserPersonalizationStddevFields = {
  __typename?: 'userPersonalization_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userPersonalization" */
export type UserPersonalizationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserPersonalizationStddevPopFields = {
  __typename?: 'userPersonalization_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userPersonalization" */
export type UserPersonalizationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserPersonalizationStddevSampFields = {
  __typename?: 'userPersonalization_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userPersonalization" */
export type UserPersonalizationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserPersonalizationSumFields = {
  __typename?: 'userPersonalization_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "userPersonalization" */
export type UserPersonalizationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userPersonalization" */
export enum UserPersonalizationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserPersonalizationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserPersonalizationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserPersonalizationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserPersonalizationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserPersonalizationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserPersonalizationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserPersonalizationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserPersonalizationSetInput>;
  where: UserPersonalizationBoolExp;
};

/** aggregate var_pop on columns */
export type UserPersonalizationVarPopFields = {
  __typename?: 'userPersonalization_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userPersonalization" */
export type UserPersonalizationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserPersonalizationVarSampFields = {
  __typename?: 'userPersonalization_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userPersonalization" */
export type UserPersonalizationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserPersonalizationVarianceFields = {
  __typename?: 'userPersonalization_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userPersonalization" */
export type UserPersonalizationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Riding interests for a user */
export type UserRidingInterest = Node & {
  __typename?: 'userRidingInterest';
  abilityLevel: Scalars['jsonb'];
  abilityLevelGoal: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  disciplineName: Scalars['String'];
  focus: Scalars['jsonb'];
  hasAbilityGoal: Scalars['Boolean'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};


/** Riding interests for a user */
export type UserRidingInterestAbilityLevelArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Riding interests for a user */
export type UserRidingInterestAbilityLevelGoalArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Riding interests for a user */
export type UserRidingInterestFocusArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A Relay connection object on "userRidingInterest" */
export type UserRidingInterestConnection = {
  __typename?: 'userRidingInterestConnection';
  edges: Array<UserRidingInterestEdge>;
  pageInfo: PageInfo;
};

export type UserRidingInterestEdge = {
  __typename?: 'userRidingInterestEdge';
  cursor: Scalars['String'];
  node: UserRidingInterest;
};

/** aggregated selection of "userRidingInterest" */
export type UserRidingInterestAggregate = {
  __typename?: 'userRidingInterest_aggregate';
  aggregate?: Maybe<UserRidingInterestAggregateFields>;
  nodes: Array<UserRidingInterest>;
};

export type UserRidingInterestAggregateBoolExp = {
  bool_and?: InputMaybe<UserRidingInterestAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserRidingInterestAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserRidingInterestAggregateBoolExpCount>;
};

export type UserRidingInterestAggregateBoolExpBoolAnd = {
  arguments: UserRidingInterestSelectColumnUserRidingInterestAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserRidingInterestBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserRidingInterestAggregateBoolExpBoolOr = {
  arguments: UserRidingInterestSelectColumnUserRidingInterestAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserRidingInterestBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserRidingInterestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserRidingInterestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userRidingInterest" */
export type UserRidingInterestAggregateFields = {
  __typename?: 'userRidingInterest_aggregate_fields';
  avg?: Maybe<UserRidingInterestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserRidingInterestMaxFields>;
  min?: Maybe<UserRidingInterestMinFields>;
  stddev?: Maybe<UserRidingInterestStddevFields>;
  stddev_pop?: Maybe<UserRidingInterestStddevPopFields>;
  stddev_samp?: Maybe<UserRidingInterestStddevSampFields>;
  sum?: Maybe<UserRidingInterestSumFields>;
  var_pop?: Maybe<UserRidingInterestVarPopFields>;
  var_samp?: Maybe<UserRidingInterestVarSampFields>;
  variance?: Maybe<UserRidingInterestVarianceFields>;
};


/** aggregate fields of "userRidingInterest" */
export type UserRidingInterestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserRidingInterestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userRidingInterest" */
export type UserRidingInterestAggregateOrderBy = {
  avg?: InputMaybe<UserRidingInterestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserRidingInterestMaxOrderBy>;
  min?: InputMaybe<UserRidingInterestMinOrderBy>;
  stddev?: InputMaybe<UserRidingInterestStddevOrderBy>;
  stddev_pop?: InputMaybe<UserRidingInterestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserRidingInterestStddevSampOrderBy>;
  sum?: InputMaybe<UserRidingInterestSumOrderBy>;
  var_pop?: InputMaybe<UserRidingInterestVarPopOrderBy>;
  var_samp?: InputMaybe<UserRidingInterestVarSampOrderBy>;
  variance?: InputMaybe<UserRidingInterestVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserRidingInterestAppendInput = {
  abilityLevel?: InputMaybe<Scalars['jsonb']>;
  abilityLevelGoal?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "userRidingInterest" */
export type UserRidingInterestArrRelInsertInput = {
  data: Array<UserRidingInterestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserRidingInterestOnConflict>;
};

/** aggregate avg on columns */
export type UserRidingInterestAvgFields = {
  __typename?: 'userRidingInterest_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userRidingInterest" */
export type UserRidingInterestAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userRidingInterest". All fields are combined with a logical 'AND'. */
export type UserRidingInterestBoolExp = {
  _and?: InputMaybe<Array<UserRidingInterestBoolExp>>;
  _not?: InputMaybe<UserRidingInterestBoolExp>;
  _or?: InputMaybe<Array<UserRidingInterestBoolExp>>;
  abilityLevel?: InputMaybe<JsonbComparisonExp>;
  abilityLevelGoal?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  disciplineName?: InputMaybe<StringComparisonExp>;
  focus?: InputMaybe<JsonbComparisonExp>;
  hasAbilityGoal?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userRidingInterest" */
export enum UserRidingInterestConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserRidingInterestCuidKey = 'userRidingInterest_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserRidingInterestPkey = 'userRidingInterest_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserRidingInterestDeleteAtPathInput = {
  abilityLevel?: InputMaybe<Array<Scalars['String']>>;
  abilityLevelGoal?: InputMaybe<Array<Scalars['String']>>;
  focus?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserRidingInterestDeleteElemInput = {
  abilityLevel?: InputMaybe<Scalars['Int']>;
  abilityLevelGoal?: InputMaybe<Scalars['Int']>;
  focus?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserRidingInterestDeleteKeyInput = {
  abilityLevel?: InputMaybe<Scalars['String']>;
  abilityLevelGoal?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "userRidingInterest" */
export type UserRidingInterestIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userRidingInterest" */
export type UserRidingInterestInsertInput = {
  abilityLevel?: InputMaybe<Scalars['jsonb']>;
  abilityLevelGoal?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  disciplineName?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  hasAbilityGoal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserRidingInterestMaxFields = {
  __typename?: 'userRidingInterest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  disciplineName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userRidingInterest" */
export type UserRidingInterestMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserRidingInterestMinFields = {
  __typename?: 'userRidingInterest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  disciplineName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userRidingInterest" */
export type UserRidingInterestMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userRidingInterest" */
export type UserRidingInterestMutationResponse = {
  __typename?: 'userRidingInterest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserRidingInterest>;
};

/** on_conflict condition type for table "userRidingInterest" */
export type UserRidingInterestOnConflict = {
  constraint: UserRidingInterestConstraint;
  update_columns?: Array<UserRidingInterestUpdateColumn>;
  where?: InputMaybe<UserRidingInterestBoolExp>;
};

/** Ordering options when selecting data from "userRidingInterest". */
export type UserRidingInterestOrderBy = {
  abilityLevel?: InputMaybe<OrderBy>;
  abilityLevelGoal?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  disciplineName?: InputMaybe<OrderBy>;
  focus?: InputMaybe<OrderBy>;
  hasAbilityGoal?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userRidingInterest */
export type UserRidingInterestPkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserRidingInterestPrependInput = {
  abilityLevel?: InputMaybe<Scalars['jsonb']>;
  abilityLevelGoal?: InputMaybe<Scalars['jsonb']>;
  focus?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "userRidingInterest" */
export enum UserRidingInterestSelectColumn {
  /** column name */
  AbilityLevel = 'abilityLevel',
  /** column name */
  AbilityLevelGoal = 'abilityLevelGoal',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DisciplineName = 'disciplineName',
  /** column name */
  Focus = 'focus',
  /** column name */
  HasAbilityGoal = 'hasAbilityGoal',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** select "userRidingInterest_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userRidingInterest" */
export enum UserRidingInterestSelectColumnUserRidingInterestAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasAbilityGoal = 'hasAbilityGoal'
}

/** select "userRidingInterest_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userRidingInterest" */
export enum UserRidingInterestSelectColumnUserRidingInterestAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasAbilityGoal = 'hasAbilityGoal'
}

/** input type for updating data in table "userRidingInterest" */
export type UserRidingInterestSetInput = {
  abilityLevel?: InputMaybe<Scalars['jsonb']>;
  abilityLevelGoal?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  disciplineName?: InputMaybe<Scalars['String']>;
  focus?: InputMaybe<Scalars['jsonb']>;
  hasAbilityGoal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserRidingInterestStddevFields = {
  __typename?: 'userRidingInterest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userRidingInterest" */
export type UserRidingInterestStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserRidingInterestStddevPopFields = {
  __typename?: 'userRidingInterest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userRidingInterest" */
export type UserRidingInterestStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserRidingInterestStddevSampFields = {
  __typename?: 'userRidingInterest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userRidingInterest" */
export type UserRidingInterestStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserRidingInterestSumFields = {
  __typename?: 'userRidingInterest_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userRidingInterest" */
export type UserRidingInterestSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userRidingInterest" */
export enum UserRidingInterestUpdateColumn {
  /** column name */
  AbilityLevel = 'abilityLevel',
  /** column name */
  AbilityLevelGoal = 'abilityLevelGoal',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DisciplineName = 'disciplineName',
  /** column name */
  Focus = 'focus',
  /** column name */
  HasAbilityGoal = 'hasAbilityGoal',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserRidingInterestUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserRidingInterestAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserRidingInterestDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserRidingInterestDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserRidingInterestDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserRidingInterestIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserRidingInterestPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserRidingInterestSetInput>;
  where: UserRidingInterestBoolExp;
};

/** aggregate var_pop on columns */
export type UserRidingInterestVarPopFields = {
  __typename?: 'userRidingInterest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userRidingInterest" */
export type UserRidingInterestVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserRidingInterestVarSampFields = {
  __typename?: 'userRidingInterest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userRidingInterest" */
export type UserRidingInterestVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserRidingInterestVarianceFields = {
  __typename?: 'userRidingInterest_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userRidingInterest" */
export type UserRidingInterestVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** user license numbers */
export type UserSanctionLicense = Node & {
  __typename?: 'userSanctionLicense';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  licenseNumber: Scalars['String'];
  /** An object relationship */
  sanction: Sanction;
  sanctionCuid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userSanctionLicense" */
export type UserSanctionLicenseConnection = {
  __typename?: 'userSanctionLicenseConnection';
  edges: Array<UserSanctionLicenseEdge>;
  pageInfo: PageInfo;
};

export type UserSanctionLicenseEdge = {
  __typename?: 'userSanctionLicenseEdge';
  cursor: Scalars['String'];
  node: UserSanctionLicense;
};

/** aggregated selection of "userSanctionLicense" */
export type UserSanctionLicenseAggregate = {
  __typename?: 'userSanctionLicense_aggregate';
  aggregate?: Maybe<UserSanctionLicenseAggregateFields>;
  nodes: Array<UserSanctionLicense>;
};

export type UserSanctionLicenseAggregateBoolExp = {
  count?: InputMaybe<UserSanctionLicenseAggregateBoolExpCount>;
};

export type UserSanctionLicenseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserSanctionLicenseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userSanctionLicense" */
export type UserSanctionLicenseAggregateFields = {
  __typename?: 'userSanctionLicense_aggregate_fields';
  avg?: Maybe<UserSanctionLicenseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserSanctionLicenseMaxFields>;
  min?: Maybe<UserSanctionLicenseMinFields>;
  stddev?: Maybe<UserSanctionLicenseStddevFields>;
  stddev_pop?: Maybe<UserSanctionLicenseStddevPopFields>;
  stddev_samp?: Maybe<UserSanctionLicenseStddevSampFields>;
  sum?: Maybe<UserSanctionLicenseSumFields>;
  var_pop?: Maybe<UserSanctionLicenseVarPopFields>;
  var_samp?: Maybe<UserSanctionLicenseVarSampFields>;
  variance?: Maybe<UserSanctionLicenseVarianceFields>;
};


/** aggregate fields of "userSanctionLicense" */
export type UserSanctionLicenseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSanctionLicenseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userSanctionLicense" */
export type UserSanctionLicenseAggregateOrderBy = {
  avg?: InputMaybe<UserSanctionLicenseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserSanctionLicenseMaxOrderBy>;
  min?: InputMaybe<UserSanctionLicenseMinOrderBy>;
  stddev?: InputMaybe<UserSanctionLicenseStddevOrderBy>;
  stddev_pop?: InputMaybe<UserSanctionLicenseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserSanctionLicenseStddevSampOrderBy>;
  sum?: InputMaybe<UserSanctionLicenseSumOrderBy>;
  var_pop?: InputMaybe<UserSanctionLicenseVarPopOrderBy>;
  var_samp?: InputMaybe<UserSanctionLicenseVarSampOrderBy>;
  variance?: InputMaybe<UserSanctionLicenseVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userSanctionLicense" */
export type UserSanctionLicenseArrRelInsertInput = {
  data: Array<UserSanctionLicenseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSanctionLicenseOnConflict>;
};

/** aggregate avg on columns */
export type UserSanctionLicenseAvgFields = {
  __typename?: 'userSanctionLicense_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userSanctionLicense". All fields are combined with a logical 'AND'. */
export type UserSanctionLicenseBoolExp = {
  _and?: InputMaybe<Array<UserSanctionLicenseBoolExp>>;
  _not?: InputMaybe<UserSanctionLicenseBoolExp>;
  _or?: InputMaybe<Array<UserSanctionLicenseBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  licenseNumber?: InputMaybe<StringComparisonExp>;
  sanction?: InputMaybe<SanctionBoolExp>;
  sanctionCuid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userSanctionLicense" */
export enum UserSanctionLicenseConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserSanctionLicenseCuidKey = 'userSanctionLicense_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserSanctionLicensePkey = 'userSanctionLicense_pkey',
  /** unique or primary key constraint on columns "sanctionCuid", "userId" */
  UserSanctionLicenseSanctionCuidUserIdKey = 'userSanctionLicense_sanctionCuid_userId_key'
}

/** input type for incrementing numeric columns in table "userSanctionLicense" */
export type UserSanctionLicenseIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userSanctionLicense" */
export type UserSanctionLicenseInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  sanction?: InputMaybe<SanctionObjRelInsertInput>;
  sanctionCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserSanctionLicenseMaxFields = {
  __typename?: 'userSanctionLicense_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  licenseNumber?: Maybe<Scalars['String']>;
  sanctionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licenseNumber?: InputMaybe<OrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserSanctionLicenseMinFields = {
  __typename?: 'userSanctionLicense_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  licenseNumber?: Maybe<Scalars['String']>;
  sanctionCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licenseNumber?: InputMaybe<OrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userSanctionLicense" */
export type UserSanctionLicenseMutationResponse = {
  __typename?: 'userSanctionLicense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSanctionLicense>;
};

/** on_conflict condition type for table "userSanctionLicense" */
export type UserSanctionLicenseOnConflict = {
  constraint: UserSanctionLicenseConstraint;
  update_columns?: Array<UserSanctionLicenseUpdateColumn>;
  where?: InputMaybe<UserSanctionLicenseBoolExp>;
};

/** Ordering options when selecting data from "userSanctionLicense". */
export type UserSanctionLicenseOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licenseNumber?: InputMaybe<OrderBy>;
  sanction?: InputMaybe<SanctionOrderBy>;
  sanctionCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userSanctionLicense */
export type UserSanctionLicensePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userSanctionLicense" */
export enum UserSanctionLicenseSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LicenseNumber = 'licenseNumber',
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userSanctionLicense" */
export type UserSanctionLicenseSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  sanctionCuid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserSanctionLicenseStddevFields = {
  __typename?: 'userSanctionLicense_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserSanctionLicenseStddevPopFields = {
  __typename?: 'userSanctionLicense_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserSanctionLicenseStddevSampFields = {
  __typename?: 'userSanctionLicense_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserSanctionLicenseSumFields = {
  __typename?: 'userSanctionLicense_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userSanctionLicense" */
export enum UserSanctionLicenseUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  LicenseNumber = 'licenseNumber',
  /** column name */
  SanctionCuid = 'sanctionCuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserSanctionLicenseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserSanctionLicenseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSanctionLicenseSetInput>;
  where: UserSanctionLicenseBoolExp;
};

/** aggregate var_pop on columns */
export type UserSanctionLicenseVarPopFields = {
  __typename?: 'userSanctionLicense_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserSanctionLicenseVarSampFields = {
  __typename?: 'userSanctionLicense_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserSanctionLicenseVarianceFields = {
  __typename?: 'userSanctionLicense_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userSanctionLicense" */
export type UserSanctionLicenseVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Saved Coach */
export type UserSavedCoach = Node & {
  __typename?: 'userSavedCoach';
  /** An object relationship */
  coach: Coach;
  coachCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userSavedCoach" */
export type UserSavedCoachConnection = {
  __typename?: 'userSavedCoachConnection';
  edges: Array<UserSavedCoachEdge>;
  pageInfo: PageInfo;
};

export type UserSavedCoachEdge = {
  __typename?: 'userSavedCoachEdge';
  cursor: Scalars['String'];
  node: UserSavedCoach;
};

/** aggregated selection of "userSavedCoach" */
export type UserSavedCoachAggregate = {
  __typename?: 'userSavedCoach_aggregate';
  aggregate?: Maybe<UserSavedCoachAggregateFields>;
  nodes: Array<UserSavedCoach>;
};

export type UserSavedCoachAggregateBoolExp = {
  count?: InputMaybe<UserSavedCoachAggregateBoolExpCount>;
};

export type UserSavedCoachAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserSavedCoachBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userSavedCoach" */
export type UserSavedCoachAggregateFields = {
  __typename?: 'userSavedCoach_aggregate_fields';
  avg?: Maybe<UserSavedCoachAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserSavedCoachMaxFields>;
  min?: Maybe<UserSavedCoachMinFields>;
  stddev?: Maybe<UserSavedCoachStddevFields>;
  stddev_pop?: Maybe<UserSavedCoachStddevPopFields>;
  stddev_samp?: Maybe<UserSavedCoachStddevSampFields>;
  sum?: Maybe<UserSavedCoachSumFields>;
  var_pop?: Maybe<UserSavedCoachVarPopFields>;
  var_samp?: Maybe<UserSavedCoachVarSampFields>;
  variance?: Maybe<UserSavedCoachVarianceFields>;
};


/** aggregate fields of "userSavedCoach" */
export type UserSavedCoachAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSavedCoachSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userSavedCoach" */
export type UserSavedCoachAggregateOrderBy = {
  avg?: InputMaybe<UserSavedCoachAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserSavedCoachMaxOrderBy>;
  min?: InputMaybe<UserSavedCoachMinOrderBy>;
  stddev?: InputMaybe<UserSavedCoachStddevOrderBy>;
  stddev_pop?: InputMaybe<UserSavedCoachStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserSavedCoachStddevSampOrderBy>;
  sum?: InputMaybe<UserSavedCoachSumOrderBy>;
  var_pop?: InputMaybe<UserSavedCoachVarPopOrderBy>;
  var_samp?: InputMaybe<UserSavedCoachVarSampOrderBy>;
  variance?: InputMaybe<UserSavedCoachVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userSavedCoach" */
export type UserSavedCoachArrRelInsertInput = {
  data: Array<UserSavedCoachInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSavedCoachOnConflict>;
};

/** aggregate avg on columns */
export type UserSavedCoachAvgFields = {
  __typename?: 'userSavedCoach_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userSavedCoach" */
export type UserSavedCoachAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userSavedCoach". All fields are combined with a logical 'AND'. */
export type UserSavedCoachBoolExp = {
  _and?: InputMaybe<Array<UserSavedCoachBoolExp>>;
  _not?: InputMaybe<UserSavedCoachBoolExp>;
  _or?: InputMaybe<Array<UserSavedCoachBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userSavedCoach" */
export enum UserSavedCoachConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserSavedCoachCuidKey = 'userSavedCoach_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserSavedCoachPkey = 'userSavedCoach_pkey'
}

/** input type for incrementing numeric columns in table "userSavedCoach" */
export type UserSavedCoachIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userSavedCoach" */
export type UserSavedCoachInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserSavedCoachMaxFields = {
  __typename?: 'userSavedCoach_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userSavedCoach" */
export type UserSavedCoachMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserSavedCoachMinFields = {
  __typename?: 'userSavedCoach_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userSavedCoach" */
export type UserSavedCoachMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userSavedCoach" */
export type UserSavedCoachMutationResponse = {
  __typename?: 'userSavedCoach_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSavedCoach>;
};

/** on_conflict condition type for table "userSavedCoach" */
export type UserSavedCoachOnConflict = {
  constraint: UserSavedCoachConstraint;
  update_columns?: Array<UserSavedCoachUpdateColumn>;
  where?: InputMaybe<UserSavedCoachBoolExp>;
};

/** Ordering options when selecting data from "userSavedCoach". */
export type UserSavedCoachOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userSavedCoach */
export type UserSavedCoachPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userSavedCoach" */
export enum UserSavedCoachSelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userSavedCoach" */
export type UserSavedCoachSetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserSavedCoachStddevFields = {
  __typename?: 'userSavedCoach_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userSavedCoach" */
export type UserSavedCoachStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserSavedCoachStddevPopFields = {
  __typename?: 'userSavedCoach_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userSavedCoach" */
export type UserSavedCoachStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserSavedCoachStddevSampFields = {
  __typename?: 'userSavedCoach_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userSavedCoach" */
export type UserSavedCoachStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserSavedCoachSumFields = {
  __typename?: 'userSavedCoach_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userSavedCoach" */
export type UserSavedCoachSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userSavedCoach" */
export enum UserSavedCoachUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserSavedCoachUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserSavedCoachIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSavedCoachSetInput>;
  where: UserSavedCoachBoolExp;
};

/** aggregate var_pop on columns */
export type UserSavedCoachVarPopFields = {
  __typename?: 'userSavedCoach_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userSavedCoach" */
export type UserSavedCoachVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserSavedCoachVarSampFields = {
  __typename?: 'userSavedCoach_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userSavedCoach" */
export type UserSavedCoachVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserSavedCoachVarianceFields = {
  __typename?: 'userSavedCoach_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userSavedCoach" */
export type UserSavedCoachVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** All saved user events */
export type UserSavedEvent = Node & {
  __typename?: 'userSavedEvent';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  event: Event;
  eventCuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userSavedEvent" */
export type UserSavedEventConnection = {
  __typename?: 'userSavedEventConnection';
  edges: Array<UserSavedEventEdge>;
  pageInfo: PageInfo;
};

export type UserSavedEventEdge = {
  __typename?: 'userSavedEventEdge';
  cursor: Scalars['String'];
  node: UserSavedEvent;
};

/** aggregated selection of "userSavedEvent" */
export type UserSavedEventAggregate = {
  __typename?: 'userSavedEvent_aggregate';
  aggregate?: Maybe<UserSavedEventAggregateFields>;
  nodes: Array<UserSavedEvent>;
};

export type UserSavedEventAggregateBoolExp = {
  count?: InputMaybe<UserSavedEventAggregateBoolExpCount>;
};

export type UserSavedEventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserSavedEventBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userSavedEvent" */
export type UserSavedEventAggregateFields = {
  __typename?: 'userSavedEvent_aggregate_fields';
  avg?: Maybe<UserSavedEventAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserSavedEventMaxFields>;
  min?: Maybe<UserSavedEventMinFields>;
  stddev?: Maybe<UserSavedEventStddevFields>;
  stddev_pop?: Maybe<UserSavedEventStddevPopFields>;
  stddev_samp?: Maybe<UserSavedEventStddevSampFields>;
  sum?: Maybe<UserSavedEventSumFields>;
  var_pop?: Maybe<UserSavedEventVarPopFields>;
  var_samp?: Maybe<UserSavedEventVarSampFields>;
  variance?: Maybe<UserSavedEventVarianceFields>;
};


/** aggregate fields of "userSavedEvent" */
export type UserSavedEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSavedEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userSavedEvent" */
export type UserSavedEventAggregateOrderBy = {
  avg?: InputMaybe<UserSavedEventAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserSavedEventMaxOrderBy>;
  min?: InputMaybe<UserSavedEventMinOrderBy>;
  stddev?: InputMaybe<UserSavedEventStddevOrderBy>;
  stddev_pop?: InputMaybe<UserSavedEventStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserSavedEventStddevSampOrderBy>;
  sum?: InputMaybe<UserSavedEventSumOrderBy>;
  var_pop?: InputMaybe<UserSavedEventVarPopOrderBy>;
  var_samp?: InputMaybe<UserSavedEventVarSampOrderBy>;
  variance?: InputMaybe<UserSavedEventVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userSavedEvent" */
export type UserSavedEventArrRelInsertInput = {
  data: Array<UserSavedEventInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSavedEventOnConflict>;
};

/** aggregate avg on columns */
export type UserSavedEventAvgFields = {
  __typename?: 'userSavedEvent_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userSavedEvent" */
export type UserSavedEventAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userSavedEvent". All fields are combined with a logical 'AND'. */
export type UserSavedEventBoolExp = {
  _and?: InputMaybe<Array<UserSavedEventBoolExp>>;
  _not?: InputMaybe<UserSavedEventBoolExp>;
  _or?: InputMaybe<Array<UserSavedEventBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  event?: InputMaybe<EventBoolExp>;
  eventCuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userSavedEvent" */
export enum UserSavedEventConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserSavedEventsCuidKey = 'userSavedEvents_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserSavedEventsPkey = 'userSavedEvents_pkey'
}

/** input type for incrementing numeric columns in table "userSavedEvent" */
export type UserSavedEventIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userSavedEvent" */
export type UserSavedEventInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventObjRelInsertInput>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserSavedEventMaxFields = {
  __typename?: 'userSavedEvent_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userSavedEvent" */
export type UserSavedEventMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserSavedEventMinFields = {
  __typename?: 'userSavedEvent_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  eventCuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userSavedEvent" */
export type UserSavedEventMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userSavedEvent" */
export type UserSavedEventMutationResponse = {
  __typename?: 'userSavedEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSavedEvent>;
};

/** on_conflict condition type for table "userSavedEvent" */
export type UserSavedEventOnConflict = {
  constraint: UserSavedEventConstraint;
  update_columns?: Array<UserSavedEventUpdateColumn>;
  where?: InputMaybe<UserSavedEventBoolExp>;
};

/** Ordering options when selecting data from "userSavedEvent". */
export type UserSavedEventOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventOrderBy>;
  eventCuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userSavedEvent */
export type UserSavedEventPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userSavedEvent" */
export enum UserSavedEventSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userSavedEvent" */
export type UserSavedEventSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventCuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserSavedEventStddevFields = {
  __typename?: 'userSavedEvent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userSavedEvent" */
export type UserSavedEventStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserSavedEventStddevPopFields = {
  __typename?: 'userSavedEvent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userSavedEvent" */
export type UserSavedEventStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserSavedEventStddevSampFields = {
  __typename?: 'userSavedEvent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userSavedEvent" */
export type UserSavedEventStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserSavedEventSumFields = {
  __typename?: 'userSavedEvent_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userSavedEvent" */
export type UserSavedEventSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userSavedEvent" */
export enum UserSavedEventUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  EventCuid = 'eventCuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserSavedEventUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserSavedEventIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSavedEventSetInput>;
  where: UserSavedEventBoolExp;
};

/** aggregate var_pop on columns */
export type UserSavedEventVarPopFields = {
  __typename?: 'userSavedEvent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userSavedEvent" */
export type UserSavedEventVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserSavedEventVarSampFields = {
  __typename?: 'userSavedEvent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userSavedEvent" */
export type UserSavedEventVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserSavedEventVarianceFields = {
  __typename?: 'userSavedEvent_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userSavedEvent" */
export type UserSavedEventVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Saved Lessons */
export type UserSavedLesson = Node & {
  __typename?: 'userSavedLesson';
  /** An object relationship */
  coachLesson: CoachLesson;
  coachLessonCuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** A Relay connection object on "userSavedLesson" */
export type UserSavedLessonConnection = {
  __typename?: 'userSavedLessonConnection';
  edges: Array<UserSavedLessonEdge>;
  pageInfo: PageInfo;
};

export type UserSavedLessonEdge = {
  __typename?: 'userSavedLessonEdge';
  cursor: Scalars['String'];
  node: UserSavedLesson;
};

/** aggregated selection of "userSavedLesson" */
export type UserSavedLessonAggregate = {
  __typename?: 'userSavedLesson_aggregate';
  aggregate?: Maybe<UserSavedLessonAggregateFields>;
  nodes: Array<UserSavedLesson>;
};

export type UserSavedLessonAggregateBoolExp = {
  count?: InputMaybe<UserSavedLessonAggregateBoolExpCount>;
};

export type UserSavedLessonAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserSavedLessonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "userSavedLesson" */
export type UserSavedLessonAggregateFields = {
  __typename?: 'userSavedLesson_aggregate_fields';
  avg?: Maybe<UserSavedLessonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserSavedLessonMaxFields>;
  min?: Maybe<UserSavedLessonMinFields>;
  stddev?: Maybe<UserSavedLessonStddevFields>;
  stddev_pop?: Maybe<UserSavedLessonStddevPopFields>;
  stddev_samp?: Maybe<UserSavedLessonStddevSampFields>;
  sum?: Maybe<UserSavedLessonSumFields>;
  var_pop?: Maybe<UserSavedLessonVarPopFields>;
  var_samp?: Maybe<UserSavedLessonVarSampFields>;
  variance?: Maybe<UserSavedLessonVarianceFields>;
};


/** aggregate fields of "userSavedLesson" */
export type UserSavedLessonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSavedLessonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userSavedLesson" */
export type UserSavedLessonAggregateOrderBy = {
  avg?: InputMaybe<UserSavedLessonAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserSavedLessonMaxOrderBy>;
  min?: InputMaybe<UserSavedLessonMinOrderBy>;
  stddev?: InputMaybe<UserSavedLessonStddevOrderBy>;
  stddev_pop?: InputMaybe<UserSavedLessonStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserSavedLessonStddevSampOrderBy>;
  sum?: InputMaybe<UserSavedLessonSumOrderBy>;
  var_pop?: InputMaybe<UserSavedLessonVarPopOrderBy>;
  var_samp?: InputMaybe<UserSavedLessonVarSampOrderBy>;
  variance?: InputMaybe<UserSavedLessonVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "userSavedLesson" */
export type UserSavedLessonArrRelInsertInput = {
  data: Array<UserSavedLessonInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSavedLessonOnConflict>;
};

/** aggregate avg on columns */
export type UserSavedLessonAvgFields = {
  __typename?: 'userSavedLesson_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "userSavedLesson" */
export type UserSavedLessonAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "userSavedLesson". All fields are combined with a logical 'AND'. */
export type UserSavedLessonBoolExp = {
  _and?: InputMaybe<Array<UserSavedLessonBoolExp>>;
  _not?: InputMaybe<UserSavedLessonBoolExp>;
  _or?: InputMaybe<Array<UserSavedLessonBoolExp>>;
  coachLesson?: InputMaybe<CoachLessonBoolExp>;
  coachLessonCuid?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "userSavedLesson" */
export enum UserSavedLessonConstraint {
  /** unique or primary key constraint on columns "cuid" */
  UserSavedLessonCuidKey = 'userSavedLesson_cuid_key',
  /** unique or primary key constraint on columns "id" */
  UserSavedLessonPkey = 'userSavedLesson_pkey'
}

/** input type for incrementing numeric columns in table "userSavedLesson" */
export type UserSavedLessonIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "userSavedLesson" */
export type UserSavedLessonInsertInput = {
  coachLesson?: InputMaybe<CoachLessonObjRelInsertInput>;
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserSavedLessonMaxFields = {
  __typename?: 'userSavedLesson_max_fields';
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userSavedLesson" */
export type UserSavedLessonMaxOrderBy = {
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserSavedLessonMinFields = {
  __typename?: 'userSavedLesson_min_fields';
  coachLessonCuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userSavedLesson" */
export type UserSavedLessonMinOrderBy = {
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "userSavedLesson" */
export type UserSavedLessonMutationResponse = {
  __typename?: 'userSavedLesson_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSavedLesson>;
};

/** on_conflict condition type for table "userSavedLesson" */
export type UserSavedLessonOnConflict = {
  constraint: UserSavedLessonConstraint;
  update_columns?: Array<UserSavedLessonUpdateColumn>;
  where?: InputMaybe<UserSavedLessonBoolExp>;
};

/** Ordering options when selecting data from "userSavedLesson". */
export type UserSavedLessonOrderBy = {
  coachLesson?: InputMaybe<CoachLessonOrderBy>;
  coachLessonCuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: userSavedLesson */
export type UserSavedLessonPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "userSavedLesson" */
export enum UserSavedLessonSelectColumn {
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userSavedLesson" */
export type UserSavedLessonSetInput = {
  coachLessonCuid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserSavedLessonStddevFields = {
  __typename?: 'userSavedLesson_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "userSavedLesson" */
export type UserSavedLessonStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserSavedLessonStddevPopFields = {
  __typename?: 'userSavedLesson_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "userSavedLesson" */
export type UserSavedLessonStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserSavedLessonStddevSampFields = {
  __typename?: 'userSavedLesson_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "userSavedLesson" */
export type UserSavedLessonStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserSavedLessonSumFields = {
  __typename?: 'userSavedLesson_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "userSavedLesson" */
export type UserSavedLessonSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "userSavedLesson" */
export enum UserSavedLessonUpdateColumn {
  /** column name */
  CoachLessonCuid = 'coachLessonCuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type UserSavedLessonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserSavedLessonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSavedLessonSetInput>;
  where: UserSavedLessonBoolExp;
};

/** aggregate var_pop on columns */
export type UserSavedLessonVarPopFields = {
  __typename?: 'userSavedLesson_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "userSavedLesson" */
export type UserSavedLessonVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserSavedLessonVarSampFields = {
  __typename?: 'userSavedLesson_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "userSavedLesson" */
export type UserSavedLessonVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserSavedLessonVarianceFields = {
  __typename?: 'userSavedLesson_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "userSavedLesson" */
export type UserSavedLessonVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "user" */
export type UserAggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

export type UserAggregateBoolExp = {
  bool_and?: InputMaybe<UserAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserAggregateBoolExpCount>;
};

export type UserAggregateBoolExpBoolAnd = {
  arguments: UserSelectColumnUserAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAggregateBoolExpBoolOr = {
  arguments: UserSelectColumnUserAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user" */
export type UserAggregateFields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<UserAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
  stddev?: Maybe<UserStddevFields>;
  stddev_pop?: Maybe<UserStddevPopFields>;
  stddev_samp?: Maybe<UserStddevSampFields>;
  sum?: Maybe<UserSumFields>;
  var_pop?: Maybe<UserVarPopFields>;
  var_samp?: Maybe<UserVarSampFields>;
  variance?: Maybe<UserVarianceFields>;
};


/** aggregate fields of "user" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type UserAggregateOrderBy = {
  avg?: InputMaybe<UserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMaxOrderBy>;
  min?: InputMaybe<UserMinOrderBy>;
  stddev?: InputMaybe<UserStddevOrderBy>;
  stddev_pop?: InputMaybe<UserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserStddevSampOrderBy>;
  sum?: InputMaybe<UserSumOrderBy>;
  var_pop?: InputMaybe<UserVarPopOrderBy>;
  var_samp?: InputMaybe<UserVarSampOrderBy>;
  variance?: InputMaybe<UserVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserAppendInput = {
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user" */
export type UserArrRelInsertInput = {
  data: Array<UserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnConflict>;
};

/** aggregate avg on columns */
export type UserAvgFields = {
  __typename?: 'user_avg_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user" */
export type UserAvgOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  coach?: InputMaybe<CoachBoolExp>;
  coachById?: InputMaybe<CoachBoolExp>;
  coachCuid?: InputMaybe<StringComparisonExp>;
  coachCustomerUsers?: InputMaybe<CoachCustomerUserBoolExp>;
  coachCustomerUsers_aggregate?: InputMaybe<CoachCustomerUserAggregateBoolExp>;
  coachModerationFlags?: InputMaybe<CoachModerationFlagBoolExp>;
  coachModerationFlags_aggregate?: InputMaybe<CoachModerationFlagAggregateBoolExp>;
  coachSetupStep?: InputMaybe<IntComparisonExp>;
  coachSetupType?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  creatorInvitesByAcceptor?: InputMaybe<CreatorInviteBoolExp>;
  creatorInvitesByAcceptor_aggregate?: InputMaybe<CreatorInviteAggregateBoolExp>;
  creatorInvitesByInviter?: InputMaybe<CreatorInviteBoolExp>;
  creatorInvitesByInviter_aggregate?: InputMaybe<CreatorInviteAggregateBoolExp>;
  currency?: InputMaybe<StringComparisonExp>;
  cyclingGoal?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  dob?: InputMaybe<DateComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  eventModerationFlags?: InputMaybe<EventModerationFlagBoolExp>;
  eventModerationFlags_aggregate?: InputMaybe<EventModerationFlagAggregateBoolExp>;
  eventQuestionAnswers?: InputMaybe<EventQuestionAnswerBoolExp>;
  eventQuestionAnswersDeletedByUser?: InputMaybe<EventQuestionAnswerBoolExp>;
  eventQuestionAnswersDeletedByUser_aggregate?: InputMaybe<EventQuestionAnswerAggregateBoolExp>;
  eventQuestionAnswers_aggregate?: InputMaybe<EventQuestionAnswerAggregateBoolExp>;
  eventQuestions?: InputMaybe<EventQuestionBoolExp>;
  eventQuestionsDeletedByUser?: InputMaybe<EventQuestionBoolExp>;
  eventQuestionsDeletedByUser_aggregate?: InputMaybe<EventQuestionAggregateBoolExp>;
  eventQuestions_aggregate?: InputMaybe<EventQuestionAggregateBoolExp>;
  eventUpdateComments?: InputMaybe<EventUpdateCommentBoolExp>;
  eventUpdateCommentsDeletedByUser?: InputMaybe<EventUpdateCommentBoolExp>;
  eventUpdateCommentsDeletedByUser_aggregate?: InputMaybe<EventUpdateCommentAggregateBoolExp>;
  eventUpdateComments_aggregate?: InputMaybe<EventUpdateCommentAggregateBoolExp>;
  eventUpdates?: InputMaybe<EventUpdateBoolExp>;
  eventUpdatesDeletedByUser?: InputMaybe<EventUpdateBoolExp>;
  eventUpdatesDeletedByUser_aggregate?: InputMaybe<EventUpdateAggregateBoolExp>;
  eventUpdates_aggregate?: InputMaybe<EventUpdateAggregateBoolExp>;
  facebookProfile?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  gender?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  idealRide?: InputMaybe<StringComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imageUsers?: InputMaybe<ImageUserBoolExp>;
  imageUsers_aggregate?: InputMaybe<ImageUserAggregateBoolExp>;
  instagramProfile?: InputMaybe<StringComparisonExp>;
  isAdmin?: InputMaybe<BooleanComparisonExp>;
  isCoach?: InputMaybe<BooleanComparisonExp>;
  isCreator?: InputMaybe<BooleanComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lastSavedAt?: InputMaybe<TimestamptzComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  organizerChannels?: InputMaybe<OrganizerChannelBoolExp>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateBoolExp>;
  organizerInvitesByInvitee?: InputMaybe<OrganizerInviteBoolExp>;
  organizerInvitesByInvitee_aggregate?: InputMaybe<OrganizerInviteAggregateBoolExp>;
  organizerInvitesByInviter?: InputMaybe<OrganizerInviteBoolExp>;
  organizerInvitesByInviter_aggregate?: InputMaybe<OrganizerInviteAggregateBoolExp>;
  phone?: InputMaybe<StringComparisonExp>;
  profilePicPublicId?: InputMaybe<StringComparisonExp>;
  profilePicUrl?: InputMaybe<StringComparisonExp>;
  pronouns?: InputMaybe<StringComparisonExp>;
  registrations?: InputMaybe<RegistrationBoolExp>;
  registrationsByPayinguserid?: InputMaybe<RegistrationBoolExp>;
  registrationsByPayinguserid_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateBoolExp>;
  reportClinicNoShows?: InputMaybe<ReportClinicNoShowBoolExp>;
  reportClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateBoolExp>;
  reportLessonNoShows?: InputMaybe<ReportLessonNoShowBoolExp>;
  reportLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateBoolExp>;
  reportedAsClinicNoShows?: InputMaybe<ReportClinicNoShowBoolExp>;
  reportedAsClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateBoolExp>;
  reportedAsLessonNoShows?: InputMaybe<ReportLessonNoShowBoolExp>;
  reportedAsLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateBoolExp>;
  reviews?: InputMaybe<ReviewBoolExp>;
  reviews_aggregate?: InputMaybe<ReviewAggregateBoolExp>;
  ridingSince?: InputMaybe<IntComparisonExp>;
  selectedOrganizerSlug?: InputMaybe<StringComparisonExp>;
  showSetupCoach?: InputMaybe<BooleanComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sponsors?: InputMaybe<StringComparisonExp>;
  stravaProfile?: InputMaybe<StringComparisonExp>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountBoolExp>;
  stripeConnectAccounts?: InputMaybe<StripeConnectAccountBoolExp>;
  stripeConnectAccounts_aggregate?: InputMaybe<StripeConnectAccountAggregateBoolExp>;
  stripeConnectId?: InputMaybe<StringComparisonExp>;
  stripeCustomerId?: InputMaybe<StringComparisonExp>;
  stripeIdentityVerificationError?: InputMaybe<StringComparisonExp>;
  stripeIdentityVerificationSessionId?: InputMaybe<StringComparisonExp>;
  stripeIdentityVerificationStatus?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  twitterProfile?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  userAddresses?: InputMaybe<UserAddressBoolExp>;
  userAddresses_aggregate?: InputMaybe<UserAddressAggregateBoolExp>;
  userBikes?: InputMaybe<UserBikeBoolExp>;
  userBikes_aggregate?: InputMaybe<UserBikeAggregateBoolExp>;
  userCoaches?: InputMaybe<CoachBoolExp>;
  userCoaches_aggregate?: InputMaybe<CoachAggregateBoolExp>;
  userEmergencyContacts?: InputMaybe<UserEmergencyContactBoolExp>;
  userEmergencyContacts_aggregate?: InputMaybe<UserEmergencyContactAggregateBoolExp>;
  userImages?: InputMaybe<UserImageBoolExp>;
  userImages_aggregate?: InputMaybe<UserImageAggregateBoolExp>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseBoolExp>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateBoolExp>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleBoolExp>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateBoolExp>;
  userPersonalizations?: InputMaybe<UserPersonalizationBoolExp>;
  userPersonalizations_aggregate?: InputMaybe<UserPersonalizationAggregateBoolExp>;
  userRidingInterests?: InputMaybe<UserRidingInterestBoolExp>;
  userRidingInterests_aggregate?: InputMaybe<UserRidingInterestAggregateBoolExp>;
  userSanctionLicenses?: InputMaybe<UserSanctionLicenseBoolExp>;
  userSanctionLicenses_aggregate?: InputMaybe<UserSanctionLicenseAggregateBoolExp>;
  userSavedCoaches?: InputMaybe<UserSavedCoachBoolExp>;
  userSavedCoaches_aggregate?: InputMaybe<UserSavedCoachAggregateBoolExp>;
  userSavedEvents?: InputMaybe<UserSavedEventBoolExp>;
  userSavedEvents_aggregate?: InputMaybe<UserSavedEventAggregateBoolExp>;
  userSavedLessons?: InputMaybe<UserSavedLessonBoolExp>;
  userSavedLessons_aggregate?: InputMaybe<UserSavedLessonAggregateBoolExp>;
  waiverRevisions?: InputMaybe<WaiverRevisionBoolExp>;
  waiverRevisions_aggregate?: InputMaybe<WaiverRevisionAggregateBoolExp>;
};

/** unique or primary key constraints on table "user" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "id" */
  PkUsers = 'PK_users',
  /** unique or primary key constraint on columns "id" */
  UserIdKey = 'user_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "slug" */
  UserSlugKey = 'user_slug_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserDeleteAtPathInput = {
  gender?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserDeleteElemInput = {
  gender?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserDeleteKeyInput = {
  gender?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user" */
export type UserIncInput = {
  coachSetupStep?: InputMaybe<Scalars['Int']>;
  ridingSince?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type UserInsertInput = {
  coach?: InputMaybe<CoachObjRelInsertInput>;
  coachById?: InputMaybe<CoachObjRelInsertInput>;
  coachCuid?: InputMaybe<Scalars['String']>;
  coachCustomerUsers?: InputMaybe<CoachCustomerUserArrRelInsertInput>;
  coachModerationFlags?: InputMaybe<CoachModerationFlagArrRelInsertInput>;
  coachSetupStep?: InputMaybe<Scalars['Int']>;
  coachSetupType?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creatorInvitesByAcceptor?: InputMaybe<CreatorInviteArrRelInsertInput>;
  creatorInvitesByInviter?: InputMaybe<CreatorInviteArrRelInsertInput>;
  currency?: InputMaybe<Scalars['String']>;
  cyclingGoal?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  eventModerationFlags?: InputMaybe<EventModerationFlagArrRelInsertInput>;
  eventQuestionAnswers?: InputMaybe<EventQuestionAnswerArrRelInsertInput>;
  eventQuestionAnswersDeletedByUser?: InputMaybe<EventQuestionAnswerArrRelInsertInput>;
  eventQuestions?: InputMaybe<EventQuestionArrRelInsertInput>;
  eventQuestionsDeletedByUser?: InputMaybe<EventQuestionArrRelInsertInput>;
  eventUpdateComments?: InputMaybe<EventUpdateCommentArrRelInsertInput>;
  eventUpdateCommentsDeletedByUser?: InputMaybe<EventUpdateCommentArrRelInsertInput>;
  eventUpdates?: InputMaybe<EventUpdateArrRelInsertInput>;
  eventUpdatesDeletedByUser?: InputMaybe<EventUpdateArrRelInsertInput>;
  facebookProfile?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  idealRide?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imageUsers?: InputMaybe<ImageUserArrRelInsertInput>;
  instagramProfile?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isCreator?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSavedAt?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['String']>;
  organizerChannels?: InputMaybe<OrganizerChannelArrRelInsertInput>;
  organizerInvitesByInvitee?: InputMaybe<OrganizerInviteArrRelInsertInput>;
  organizerInvitesByInviter?: InputMaybe<OrganizerInviteArrRelInsertInput>;
  phone?: InputMaybe<Scalars['String']>;
  profilePicPublicId?: InputMaybe<Scalars['String']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  registrations?: InputMaybe<RegistrationArrRelInsertInput>;
  registrationsByPayinguserid?: InputMaybe<RegistrationArrRelInsertInput>;
  reportClinicNoShows?: InputMaybe<ReportClinicNoShowArrRelInsertInput>;
  reportLessonNoShows?: InputMaybe<ReportLessonNoShowArrRelInsertInput>;
  reportedAsClinicNoShows?: InputMaybe<ReportClinicNoShowArrRelInsertInput>;
  reportedAsLessonNoShows?: InputMaybe<ReportLessonNoShowArrRelInsertInput>;
  reviews?: InputMaybe<ReviewArrRelInsertInput>;
  ridingSince?: InputMaybe<Scalars['Int']>;
  selectedOrganizerSlug?: InputMaybe<Scalars['String']>;
  showSetupCoach?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsors?: InputMaybe<Scalars['String']>;
  stravaProfile?: InputMaybe<Scalars['String']>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountObjRelInsertInput>;
  stripeConnectAccounts?: InputMaybe<StripeConnectAccountArrRelInsertInput>;
  stripeConnectId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationError?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationSessionId?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationStatus?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  twitterProfile?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userAddresses?: InputMaybe<UserAddressArrRelInsertInput>;
  userBikes?: InputMaybe<UserBikeArrRelInsertInput>;
  userCoaches?: InputMaybe<CoachArrRelInsertInput>;
  userEmergencyContacts?: InputMaybe<UserEmergencyContactArrRelInsertInput>;
  userImages?: InputMaybe<UserImageArrRelInsertInput>;
  userOnlineCourses?: InputMaybe<UserOnlineCourseArrRelInsertInput>;
  userOrganizerRoles?: InputMaybe<UserOrganizerRoleArrRelInsertInput>;
  userPersonalizations?: InputMaybe<UserPersonalizationArrRelInsertInput>;
  userRidingInterests?: InputMaybe<UserRidingInterestArrRelInsertInput>;
  userSanctionLicenses?: InputMaybe<UserSanctionLicenseArrRelInsertInput>;
  userSavedCoaches?: InputMaybe<UserSavedCoachArrRelInsertInput>;
  userSavedEvents?: InputMaybe<UserSavedEventArrRelInsertInput>;
  userSavedLessons?: InputMaybe<UserSavedLessonArrRelInsertInput>;
  waiverRevisions?: InputMaybe<WaiverRevisionArrRelInsertInput>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'user_max_fields';
  coachCuid?: Maybe<Scalars['String']>;
  coachSetupStep?: Maybe<Scalars['Int']>;
  coachSetupType?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  cyclingGoal?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  facebookProfile?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idealRide?: Maybe<Scalars['String']>;
  instagramProfile?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastSavedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePicPublicId?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  ridingSince?: Maybe<Scalars['Int']>;
  selectedOrganizerSlug?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sponsors?: Maybe<Scalars['String']>;
  stravaProfile?: Maybe<Scalars['String']>;
  stripeConnectId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationError?: Maybe<Scalars['String']>;
  stripeIdentityVerificationSessionId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationStatus?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterProfile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type UserMaxOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  coachSetupStep?: InputMaybe<OrderBy>;
  coachSetupType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  cyclingGoal?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  facebookProfile?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idealRide?: InputMaybe<OrderBy>;
  instagramProfile?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  profilePicPublicId?: InputMaybe<OrderBy>;
  profilePicUrl?: InputMaybe<OrderBy>;
  pronouns?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
  selectedOrganizerSlug?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  stravaProfile?: InputMaybe<OrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationError?: InputMaybe<OrderBy>;
  stripeIdentityVerificationSessionId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationStatus?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twitterProfile?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'user_min_fields';
  coachCuid?: Maybe<Scalars['String']>;
  coachSetupStep?: Maybe<Scalars['Int']>;
  coachSetupType?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  cyclingGoal?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  facebookProfile?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idealRide?: Maybe<Scalars['String']>;
  instagramProfile?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastSavedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePicPublicId?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  ridingSince?: Maybe<Scalars['Int']>;
  selectedOrganizerSlug?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sponsors?: Maybe<Scalars['String']>;
  stravaProfile?: Maybe<Scalars['String']>;
  stripeConnectId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationError?: Maybe<Scalars['String']>;
  stripeIdentityVerificationSessionId?: Maybe<Scalars['String']>;
  stripeIdentityVerificationStatus?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterProfile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type UserMinOrderBy = {
  coachCuid?: InputMaybe<OrderBy>;
  coachSetupStep?: InputMaybe<OrderBy>;
  coachSetupType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  cyclingGoal?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  facebookProfile?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idealRide?: InputMaybe<OrderBy>;
  instagramProfile?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  profilePicPublicId?: InputMaybe<OrderBy>;
  profilePicUrl?: InputMaybe<OrderBy>;
  pronouns?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
  selectedOrganizerSlug?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  stravaProfile?: InputMaybe<OrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationError?: InputMaybe<OrderBy>;
  stripeIdentityVerificationSessionId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationStatus?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twitterProfile?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user" */
export type UserMutationResponse = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "user" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "user". */
export type UserOrderBy = {
  coach?: InputMaybe<CoachOrderBy>;
  coachById?: InputMaybe<CoachOrderBy>;
  coachCuid?: InputMaybe<OrderBy>;
  coachCustomerUsers_aggregate?: InputMaybe<CoachCustomerUserAggregateOrderBy>;
  coachModerationFlags_aggregate?: InputMaybe<CoachModerationFlagAggregateOrderBy>;
  coachSetupStep?: InputMaybe<OrderBy>;
  coachSetupType?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  creatorInvitesByAcceptor_aggregate?: InputMaybe<CreatorInviteAggregateOrderBy>;
  creatorInvitesByInviter_aggregate?: InputMaybe<CreatorInviteAggregateOrderBy>;
  currency?: InputMaybe<OrderBy>;
  cyclingGoal?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  eventModerationFlags_aggregate?: InputMaybe<EventModerationFlagAggregateOrderBy>;
  eventQuestionAnswersDeletedByUser_aggregate?: InputMaybe<EventQuestionAnswerAggregateOrderBy>;
  eventQuestionAnswers_aggregate?: InputMaybe<EventQuestionAnswerAggregateOrderBy>;
  eventQuestionsDeletedByUser_aggregate?: InputMaybe<EventQuestionAggregateOrderBy>;
  eventQuestions_aggregate?: InputMaybe<EventQuestionAggregateOrderBy>;
  eventUpdateCommentsDeletedByUser_aggregate?: InputMaybe<EventUpdateCommentAggregateOrderBy>;
  eventUpdateComments_aggregate?: InputMaybe<EventUpdateCommentAggregateOrderBy>;
  eventUpdatesDeletedByUser_aggregate?: InputMaybe<EventUpdateAggregateOrderBy>;
  eventUpdates_aggregate?: InputMaybe<EventUpdateAggregateOrderBy>;
  facebookProfile?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idealRide?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imageUsers_aggregate?: InputMaybe<ImageUserAggregateOrderBy>;
  instagramProfile?: InputMaybe<OrderBy>;
  isAdmin?: InputMaybe<OrderBy>;
  isCoach?: InputMaybe<OrderBy>;
  isCreator?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSavedAt?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  organizerChannels_aggregate?: InputMaybe<OrganizerChannelAggregateOrderBy>;
  organizerInvitesByInvitee_aggregate?: InputMaybe<OrganizerInviteAggregateOrderBy>;
  organizerInvitesByInviter_aggregate?: InputMaybe<OrganizerInviteAggregateOrderBy>;
  phone?: InputMaybe<OrderBy>;
  profilePicPublicId?: InputMaybe<OrderBy>;
  profilePicUrl?: InputMaybe<OrderBy>;
  pronouns?: InputMaybe<OrderBy>;
  registrationsByPayinguserid_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  registrations_aggregate?: InputMaybe<RegistrationAggregateOrderBy>;
  reportClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateOrderBy>;
  reportLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateOrderBy>;
  reportedAsClinicNoShows_aggregate?: InputMaybe<ReportClinicNoShowAggregateOrderBy>;
  reportedAsLessonNoShows_aggregate?: InputMaybe<ReportLessonNoShowAggregateOrderBy>;
  reviews_aggregate?: InputMaybe<ReviewAggregateOrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
  selectedOrganizerSlug?: InputMaybe<OrderBy>;
  showSetupCoach?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sponsors?: InputMaybe<OrderBy>;
  stravaProfile?: InputMaybe<OrderBy>;
  stripeConnectAccount?: InputMaybe<StripeConnectAccountOrderBy>;
  stripeConnectAccounts_aggregate?: InputMaybe<StripeConnectAccountAggregateOrderBy>;
  stripeConnectId?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationError?: InputMaybe<OrderBy>;
  stripeIdentityVerificationSessionId?: InputMaybe<OrderBy>;
  stripeIdentityVerificationStatus?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twitterProfile?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  userAddresses_aggregate?: InputMaybe<UserAddressAggregateOrderBy>;
  userBikes_aggregate?: InputMaybe<UserBikeAggregateOrderBy>;
  userCoaches_aggregate?: InputMaybe<CoachAggregateOrderBy>;
  userEmergencyContacts_aggregate?: InputMaybe<UserEmergencyContactAggregateOrderBy>;
  userImages_aggregate?: InputMaybe<UserImageAggregateOrderBy>;
  userOnlineCourses_aggregate?: InputMaybe<UserOnlineCourseAggregateOrderBy>;
  userOrganizerRoles_aggregate?: InputMaybe<UserOrganizerRoleAggregateOrderBy>;
  userPersonalizations_aggregate?: InputMaybe<UserPersonalizationAggregateOrderBy>;
  userRidingInterests_aggregate?: InputMaybe<UserRidingInterestAggregateOrderBy>;
  userSanctionLicenses_aggregate?: InputMaybe<UserSanctionLicenseAggregateOrderBy>;
  userSavedCoaches_aggregate?: InputMaybe<UserSavedCoachAggregateOrderBy>;
  userSavedEvents_aggregate?: InputMaybe<UserSavedEventAggregateOrderBy>;
  userSavedLessons_aggregate?: InputMaybe<UserSavedLessonAggregateOrderBy>;
  waiverRevisions_aggregate?: InputMaybe<WaiverRevisionAggregateOrderBy>;
};

/** primary key columns input for table: user */
export type UserPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserPrependInput = {
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum UserSelectColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CoachSetupStep = 'coachSetupStep',
  /** column name */
  CoachSetupType = 'coachSetupType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CyclingGoal = 'cyclingGoal',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FacebookProfile = 'facebookProfile',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IdealRide = 'idealRide',
  /** column name */
  InstagramProfile = 'instagramProfile',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsCoach = 'isCoach',
  /** column name */
  IsCreator = 'isCreator',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastSavedAt = 'lastSavedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicPublicId = 'profilePicPublicId',
  /** column name */
  ProfilePicUrl = 'profilePicUrl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RidingSince = 'ridingSince',
  /** column name */
  SelectedOrganizerSlug = 'selectedOrganizerSlug',
  /** column name */
  ShowSetupCoach = 'showSetupCoach',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sponsors = 'sponsors',
  /** column name */
  StravaProfile = 'stravaProfile',
  /** column name */
  StripeConnectId = 'stripeConnectId',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripeIdentityVerificationError = 'stripeIdentityVerificationError',
  /** column name */
  StripeIdentityVerificationSessionId = 'stripeIdentityVerificationSessionId',
  /** column name */
  StripeIdentityVerificationStatus = 'stripeIdentityVerificationStatus',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TwitterProfile = 'twitterProfile',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum UserSelectColumnUserAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsCoach = 'isCoach',
  /** column name */
  IsCreator = 'isCreator',
  /** column name */
  ShowSetupCoach = 'showSetupCoach'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum UserSelectColumnUserAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsCoach = 'isCoach',
  /** column name */
  IsCreator = 'isCreator',
  /** column name */
  ShowSetupCoach = 'showSetupCoach'
}

/** input type for updating data in table "user" */
export type UserSetInput = {
  coachCuid?: InputMaybe<Scalars['String']>;
  coachSetupStep?: InputMaybe<Scalars['Int']>;
  coachSetupType?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  cyclingGoal?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  facebookProfile?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  idealRide?: InputMaybe<Scalars['String']>;
  instagramProfile?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isCreator?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSavedAt?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profilePicPublicId?: InputMaybe<Scalars['String']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  ridingSince?: InputMaybe<Scalars['Int']>;
  selectedOrganizerSlug?: InputMaybe<Scalars['String']>;
  showSetupCoach?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsors?: InputMaybe<Scalars['String']>;
  stravaProfile?: InputMaybe<Scalars['String']>;
  stripeConnectId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationError?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationSessionId?: InputMaybe<Scalars['String']>;
  stripeIdentityVerificationStatus?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  twitterProfile?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type UserStddevFields = {
  __typename?: 'user_stddev_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user" */
export type UserStddevOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserStddevPopFields = {
  __typename?: 'user_stddev_pop_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user" */
export type UserStddevPopOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserStddevSampFields = {
  __typename?: 'user_stddev_samp_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user" */
export type UserStddevSampOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type UserSumFields = {
  __typename?: 'user_sum_fields';
  coachSetupStep?: Maybe<Scalars['Int']>;
  ridingSince?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user" */
export type UserSumOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** update columns of table "user" */
export enum UserUpdateColumn {
  /** column name */
  CoachCuid = 'coachCuid',
  /** column name */
  CoachSetupStep = 'coachSetupStep',
  /** column name */
  CoachSetupType = 'coachSetupType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CyclingGoal = 'cyclingGoal',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FacebookProfile = 'facebookProfile',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IdealRide = 'idealRide',
  /** column name */
  InstagramProfile = 'instagramProfile',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsCoach = 'isCoach',
  /** column name */
  IsCreator = 'isCreator',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastSavedAt = 'lastSavedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicPublicId = 'profilePicPublicId',
  /** column name */
  ProfilePicUrl = 'profilePicUrl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RidingSince = 'ridingSince',
  /** column name */
  SelectedOrganizerSlug = 'selectedOrganizerSlug',
  /** column name */
  ShowSetupCoach = 'showSetupCoach',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sponsors = 'sponsors',
  /** column name */
  StravaProfile = 'stravaProfile',
  /** column name */
  StripeConnectId = 'stripeConnectId',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripeIdentityVerificationError = 'stripeIdentityVerificationError',
  /** column name */
  StripeIdentityVerificationSessionId = 'stripeIdentityVerificationSessionId',
  /** column name */
  StripeIdentityVerificationStatus = 'stripeIdentityVerificationStatus',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TwitterProfile = 'twitterProfile',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type UserUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** aggregate var_pop on columns */
export type UserVarPopFields = {
  __typename?: 'user_var_pop_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user" */
export type UserVarPopOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserVarSampFields = {
  __typename?: 'user_var_samp_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user" */
export type UserVarSampOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserVarianceFields = {
  __typename?: 'user_variance_fields';
  coachSetupStep?: Maybe<Scalars['Float']>;
  ridingSince?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user" */
export type UserVarianceOrderBy = {
  coachSetupStep?: InputMaybe<OrderBy>;
  ridingSince?: InputMaybe<OrderBy>;
};

/** columns and relationships of "venue" */
export type Venue = Node & {
  __typename?: 'venue';
  address?: Maybe<Scalars['String']>;
  addressWithName: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An object relationship */
  eventVenue?: Maybe<EventVenue>;
  /** An array relationship */
  eventVenues: Array<EventVenue>;
  /** An aggregate relationship */
  eventVenues_aggregate: EventVenueAggregate;
  /** An array relationship connection */
  eventVenues_connection: EventVenueConnection;
  fullAddress: Scalars['String'];
  id: Scalars['ID'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  name: Scalars['String'];
  /** An object relationship */
  organizer: Organizer;
  organizerCuid: Scalars['String'];
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "venue" */
export type VenueEventVenuesArgs = {
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


/** columns and relationships of "venue" */
export type VenueEventVenuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};


/** columns and relationships of "venue" */
export type VenueEventVenuesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVenueSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVenueOrderBy>>;
  where?: InputMaybe<EventVenueBoolExp>;
};

/** A Relay connection object on "venue" */
export type VenueConnection = {
  __typename?: 'venueConnection';
  edges: Array<VenueEdge>;
  pageInfo: PageInfo;
};

export type VenueEdge = {
  __typename?: 'venueEdge';
  cursor: Scalars['String'];
  node: Venue;
};

/** aggregated selection of "venue" */
export type VenueAggregate = {
  __typename?: 'venue_aggregate';
  aggregate?: Maybe<VenueAggregateFields>;
  nodes: Array<Venue>;
};

export type VenueAggregateBoolExp = {
  count?: InputMaybe<VenueAggregateBoolExpCount>;
};

export type VenueAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<VenueBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "venue" */
export type VenueAggregateFields = {
  __typename?: 'venue_aggregate_fields';
  avg?: Maybe<VenueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<VenueMaxFields>;
  min?: Maybe<VenueMinFields>;
  stddev?: Maybe<VenueStddevFields>;
  stddev_pop?: Maybe<VenueStddevPopFields>;
  stddev_samp?: Maybe<VenueStddevSampFields>;
  sum?: Maybe<VenueSumFields>;
  var_pop?: Maybe<VenueVarPopFields>;
  var_samp?: Maybe<VenueVarSampFields>;
  variance?: Maybe<VenueVarianceFields>;
};


/** aggregate fields of "venue" */
export type VenueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VenueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "venue" */
export type VenueAggregateOrderBy = {
  avg?: InputMaybe<VenueAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VenueMaxOrderBy>;
  min?: InputMaybe<VenueMinOrderBy>;
  stddev?: InputMaybe<VenueStddevOrderBy>;
  stddev_pop?: InputMaybe<VenueStddevPopOrderBy>;
  stddev_samp?: InputMaybe<VenueStddevSampOrderBy>;
  sum?: InputMaybe<VenueSumOrderBy>;
  var_pop?: InputMaybe<VenueVarPopOrderBy>;
  var_samp?: InputMaybe<VenueVarSampOrderBy>;
  variance?: InputMaybe<VenueVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "venue" */
export type VenueArrRelInsertInput = {
  data: Array<VenueInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<VenueOnConflict>;
};

/** aggregate avg on columns */
export type VenueAvgFields = {
  __typename?: 'venue_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "venue" */
export type VenueAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "venue". All fields are combined with a logical 'AND'. */
export type VenueBoolExp = {
  _and?: InputMaybe<Array<VenueBoolExp>>;
  _not?: InputMaybe<VenueBoolExp>;
  _or?: InputMaybe<Array<VenueBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  addressWithName?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventVenue?: InputMaybe<EventVenueBoolExp>;
  eventVenues?: InputMaybe<EventVenueBoolExp>;
  eventVenues_aggregate?: InputMaybe<EventVenueAggregateBoolExp>;
  fullAddress?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizer?: InputMaybe<OrganizerBoolExp>;
  organizerCuid?: InputMaybe<StringComparisonExp>;
  placeId?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "venue" */
export enum VenueConstraint {
  /** unique or primary key constraint on columns "cuid" */
  VenuePkey = 'venue_pkey'
}

/** input type for incrementing numeric columns in table "venue" */
export type VenueIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "venue" */
export type VenueInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  addressWithName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventVenue?: InputMaybe<EventVenueObjRelInsertInput>;
  eventVenues?: InputMaybe<EventVenueArrRelInsertInput>;
  fullAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  organizer?: InputMaybe<OrganizerObjRelInsertInput>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type VenueMaxFields = {
  __typename?: 'venue_max_fields';
  address?: Maybe<Scalars['String']>;
  addressWithName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "venue" */
export type VenueMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressWithName?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VenueMinFields = {
  __typename?: 'venue_min_fields';
  address?: Maybe<Scalars['String']>;
  addressWithName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  organizerCuid?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "venue" */
export type VenueMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressWithName?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "venue" */
export type VenueMutationResponse = {
  __typename?: 'venue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Venue>;
};

/** input type for inserting object relation for remote table "venue" */
export type VenueObjRelInsertInput = {
  data: VenueInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<VenueOnConflict>;
};

/** on_conflict condition type for table "venue" */
export type VenueOnConflict = {
  constraint: VenueConstraint;
  update_columns?: Array<VenueUpdateColumn>;
  where?: InputMaybe<VenueBoolExp>;
};

/** Ordering options when selecting data from "venue". */
export type VenueOrderBy = {
  address?: InputMaybe<OrderBy>;
  addressWithName?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVenue?: InputMaybe<EventVenueOrderBy>;
  eventVenues_aggregate?: InputMaybe<EventVenueAggregateOrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizer?: InputMaybe<OrganizerOrderBy>;
  organizerCuid?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: venue */
export type VenuePkColumnsInput = {
  cuid: Scalars['String'];
};

/** select columns of table "venue" */
export enum VenueSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressWithName = 'addressWithName',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "venue" */
export type VenueSetInput = {
  address?: InputMaybe<Scalars['String']>;
  addressWithName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  organizerCuid?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type VenueStddevFields = {
  __typename?: 'venue_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "venue" */
export type VenueStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type VenueStddevPopFields = {
  __typename?: 'venue_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "venue" */
export type VenueStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type VenueStddevSampFields = {
  __typename?: 'venue_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "venue" */
export type VenueStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type VenueSumFields = {
  __typename?: 'venue_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "venue" */
export type VenueSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** update columns of table "venue" */
export enum VenueUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AddressWithName = 'addressWithName',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FullAddress = 'fullAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizerCuid = 'organizerCuid',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  State = 'state',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type VenueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VenueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VenueSetInput>;
  where: VenueBoolExp;
};

/** aggregate var_pop on columns */
export type VenueVarPopFields = {
  __typename?: 'venue_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "venue" */
export type VenueVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type VenueVarSampFields = {
  __typename?: 'venue_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "venue" */
export type VenueVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VenueVarianceFields = {
  __typename?: 'venue_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "venue" */
export type VenueVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** What types of Volunteer Task Difficulties do we have */
export type VolunteerTaskDifficulties = Node & {
  __typename?: 'volunteerTaskDifficulties';
  chipColor?: Maybe<Scalars['String']>;
  className: Scalars['String'];
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An array relationship */
  eventVolunteerJobTasks: Array<EventVolunteerJobTask>;
  /** An aggregate relationship */
  eventVolunteerJobTasks_aggregate: EventVolunteerJobTaskAggregate;
  /** An array relationship connection */
  eventVolunteerJobTasks_connection: EventVolunteerJobTaskConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** What types of Volunteer Task Difficulties do we have */
export type VolunteerTaskDifficultiesEventVolunteerJobTasksArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** What types of Volunteer Task Difficulties do we have */
export type VolunteerTaskDifficultiesEventVolunteerJobTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** What types of Volunteer Task Difficulties do we have */
export type VolunteerTaskDifficultiesEventVolunteerJobTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};

/** A Relay connection object on "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesConnection = {
  __typename?: 'volunteerTaskDifficultiesConnection';
  edges: Array<VolunteerTaskDifficultiesEdge>;
  pageInfo: PageInfo;
};

export type VolunteerTaskDifficultiesEdge = {
  __typename?: 'volunteerTaskDifficultiesEdge';
  cursor: Scalars['String'];
  node: VolunteerTaskDifficulties;
};

/** Boolean expression to filter rows from the table "volunteerTaskDifficulties". All fields are combined with a logical 'AND'. */
export type VolunteerTaskDifficultiesBoolExp = {
  _and?: InputMaybe<Array<VolunteerTaskDifficultiesBoolExp>>;
  _not?: InputMaybe<VolunteerTaskDifficultiesBoolExp>;
  _or?: InputMaybe<Array<VolunteerTaskDifficultiesBoolExp>>;
  chipColor?: InputMaybe<StringComparisonExp>;
  className?: InputMaybe<StringComparisonExp>;
  color?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "volunteerTaskDifficulties" */
export enum VolunteerTaskDifficultiesConstraint {
  /** unique or primary key constraint on columns "cuid" */
  VolunteerTaskDifficultiesCuidKey = 'volunteerTaskDifficulties_cuid_key',
  /** unique or primary key constraint on columns "id" */
  VolunteerTaskDifficultiesPkey = 'volunteerTaskDifficulties_pkey'
}

/** input type for incrementing numeric columns in table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesInsertInput = {
  chipColor?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesMutationResponse = {
  __typename?: 'volunteerTaskDifficulties_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VolunteerTaskDifficulties>;
};

/** input type for inserting object relation for remote table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesObjRelInsertInput = {
  data: VolunteerTaskDifficultiesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<VolunteerTaskDifficultiesOnConflict>;
};

/** on_conflict condition type for table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesOnConflict = {
  constraint: VolunteerTaskDifficultiesConstraint;
  update_columns?: Array<VolunteerTaskDifficultiesUpdateColumn>;
  where?: InputMaybe<VolunteerTaskDifficultiesBoolExp>;
};

/** Ordering options when selecting data from "volunteerTaskDifficulties". */
export type VolunteerTaskDifficultiesOrderBy = {
  chipColor?: InputMaybe<OrderBy>;
  className?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: volunteerTaskDifficulties */
export type VolunteerTaskDifficultiesPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "volunteerTaskDifficulties" */
export enum VolunteerTaskDifficultiesSelectColumn {
  /** column name */
  ChipColor = 'chipColor',
  /** column name */
  ClassName = 'className',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "volunteerTaskDifficulties" */
export type VolunteerTaskDifficultiesSetInput = {
  chipColor?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "volunteerTaskDifficulties" */
export enum VolunteerTaskDifficultiesUpdateColumn {
  /** column name */
  ChipColor = 'chipColor',
  /** column name */
  ClassName = 'className',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type VolunteerTaskDifficultiesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VolunteerTaskDifficultiesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VolunteerTaskDifficultiesSetInput>;
  where: VolunteerTaskDifficultiesBoolExp;
};

/** What types of Volunteer Task Priorities do we have? */
export type VolunteerTaskPriorities = Node & {
  __typename?: 'volunteerTaskPriorities';
  chipColor?: Maybe<Scalars['String']>;
  className: Scalars['String'];
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  /** An array relationship */
  eventVolunteerJobTasks: Array<EventVolunteerJobTask>;
  /** An aggregate relationship */
  eventVolunteerJobTasks_aggregate: EventVolunteerJobTaskAggregate;
  /** An array relationship connection */
  eventVolunteerJobTasks_connection: EventVolunteerJobTaskConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** What types of Volunteer Task Priorities do we have? */
export type VolunteerTaskPrioritiesEventVolunteerJobTasksArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** What types of Volunteer Task Priorities do we have? */
export type VolunteerTaskPrioritiesEventVolunteerJobTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};


/** What types of Volunteer Task Priorities do we have? */
export type VolunteerTaskPrioritiesEventVolunteerJobTasksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<EventVolunteerJobTaskSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventVolunteerJobTaskOrderBy>>;
  where?: InputMaybe<EventVolunteerJobTaskBoolExp>;
};

/** A Relay connection object on "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesConnection = {
  __typename?: 'volunteerTaskPrioritiesConnection';
  edges: Array<VolunteerTaskPrioritiesEdge>;
  pageInfo: PageInfo;
};

export type VolunteerTaskPrioritiesEdge = {
  __typename?: 'volunteerTaskPrioritiesEdge';
  cursor: Scalars['String'];
  node: VolunteerTaskPriorities;
};

/** Boolean expression to filter rows from the table "volunteerTaskPriorities". All fields are combined with a logical 'AND'. */
export type VolunteerTaskPrioritiesBoolExp = {
  _and?: InputMaybe<Array<VolunteerTaskPrioritiesBoolExp>>;
  _not?: InputMaybe<VolunteerTaskPrioritiesBoolExp>;
  _or?: InputMaybe<Array<VolunteerTaskPrioritiesBoolExp>>;
  chipColor?: InputMaybe<StringComparisonExp>;
  className?: InputMaybe<StringComparisonExp>;
  color?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskBoolExp>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "volunteerTaskPriorities" */
export enum VolunteerTaskPrioritiesConstraint {
  /** unique or primary key constraint on columns "cuid" */
  VolunteerTaskPrioritiesCuidKey = 'volunteerTaskPriorities_cuid_key',
  /** unique or primary key constraint on columns "id" */
  VolunteerTaskPrioritiesPkey = 'volunteerTaskPriorities_pkey'
}

/** input type for incrementing numeric columns in table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesIncInput = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesInsertInput = {
  chipColor?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  eventVolunteerJobTasks?: InputMaybe<EventVolunteerJobTaskArrRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesMutationResponse = {
  __typename?: 'volunteerTaskPriorities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VolunteerTaskPriorities>;
};

/** input type for inserting object relation for remote table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesObjRelInsertInput = {
  data: VolunteerTaskPrioritiesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<VolunteerTaskPrioritiesOnConflict>;
};

/** on_conflict condition type for table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesOnConflict = {
  constraint: VolunteerTaskPrioritiesConstraint;
  update_columns?: Array<VolunteerTaskPrioritiesUpdateColumn>;
  where?: InputMaybe<VolunteerTaskPrioritiesBoolExp>;
};

/** Ordering options when selecting data from "volunteerTaskPriorities". */
export type VolunteerTaskPrioritiesOrderBy = {
  chipColor?: InputMaybe<OrderBy>;
  className?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  eventVolunteerJobTasks_aggregate?: InputMaybe<EventVolunteerJobTaskAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: volunteerTaskPriorities */
export type VolunteerTaskPrioritiesPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "volunteerTaskPriorities" */
export enum VolunteerTaskPrioritiesSelectColumn {
  /** column name */
  ChipColor = 'chipColor',
  /** column name */
  ClassName = 'className',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "volunteerTaskPriorities" */
export type VolunteerTaskPrioritiesSetInput = {
  chipColor?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "volunteerTaskPriorities" */
export enum VolunteerTaskPrioritiesUpdateColumn {
  /** column name */
  ChipColor = 'chipColor',
  /** column name */
  ClassName = 'className',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type VolunteerTaskPrioritiesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VolunteerTaskPrioritiesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VolunteerTaskPrioritiesSetInput>;
  where: VolunteerTaskPrioritiesBoolExp;
};

/** columns and relationships of "waiver" */
export type Waiver = Node & {
  __typename?: 'waiver';
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['ID'];
  /** An object relationship */
  latestRevision?: Maybe<WaiverRevision>;
  latestRevisionNum: Scalars['Int'];
  ownerCuid?: Maybe<Scalars['String']>;
  ownerType: WaiverOwnerEnum;
  /** An array relationship */
  revisions: Array<WaiverRevision>;
  /** An aggregate relationship */
  revisions_aggregate: WaiverRevisionAggregate;
  /** An array relationship connection */
  revisions_connection: WaiverRevisionConnection;
  /** An array relationship */
  sanctionWaivers: Array<SanctionWaivers>;
  /** An aggregate relationship */
  sanctionWaivers_aggregate: SanctionWaiversAggregate;
  /** An array relationship connection */
  sanctionWaivers_connection: SanctionWaiversConnection;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  waiverOwner: WaiverOwner;
};


/** columns and relationships of "waiver" */
export type WaiverRevisionsArgs = {
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


/** columns and relationships of "waiver" */
export type WaiverRevisionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


/** columns and relationships of "waiver" */
export type WaiverRevisionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverRevisionOrderBy>>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};


/** columns and relationships of "waiver" */
export type WaiverSanctionWaiversArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "waiver" */
export type WaiverSanctionWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};


/** columns and relationships of "waiver" */
export type WaiverSanctionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<SanctionWaiversSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SanctionWaiversOrderBy>>;
  where?: InputMaybe<SanctionWaiversBoolExp>;
};

/** A Relay connection object on "waiver" */
export type WaiverConnection = {
  __typename?: 'waiverConnection';
  edges: Array<WaiverEdge>;
  pageInfo: PageInfo;
};

export type WaiverEdge = {
  __typename?: 'waiverEdge';
  cursor: Scalars['String'];
  node: Waiver;
};

/** columns and relationships of "waiverOwner" */
export type WaiverOwner = Node & {
  __typename?: 'waiverOwner';
  description: Scalars['String'];
  id: Scalars['ID'];
  owner: Scalars['String'];
  /** An array relationship */
  waivers: Array<Waiver>;
  /** An aggregate relationship */
  waivers_aggregate: WaiverAggregate;
  /** An array relationship connection */
  waivers_connection: WaiverConnection;
};


/** columns and relationships of "waiverOwner" */
export type WaiverOwnerWaiversArgs = {
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};


/** columns and relationships of "waiverOwner" */
export type WaiverOwnerWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};


/** columns and relationships of "waiverOwner" */
export type WaiverOwnerWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};

/** A Relay connection object on "waiverOwner" */
export type WaiverOwnerConnection = {
  __typename?: 'waiverOwnerConnection';
  edges: Array<WaiverOwnerEdge>;
  pageInfo: PageInfo;
};

export type WaiverOwnerEdge = {
  __typename?: 'waiverOwnerEdge';
  cursor: Scalars['String'];
  node: WaiverOwner;
};

/** Boolean expression to filter rows from the table "waiverOwner". All fields are combined with a logical 'AND'. */
export type WaiverOwnerBoolExp = {
  _and?: InputMaybe<Array<WaiverOwnerBoolExp>>;
  _not?: InputMaybe<WaiverOwnerBoolExp>;
  _or?: InputMaybe<Array<WaiverOwnerBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<StringComparisonExp>;
  waivers?: InputMaybe<WaiverBoolExp>;
  waivers_aggregate?: InputMaybe<WaiverAggregateBoolExp>;
};

/** unique or primary key constraints on table "waiverOwner" */
export enum WaiverOwnerConstraint {
  /** unique or primary key constraint on columns "owner" */
  WaiverOwnerPkey = 'waiverOwner_pkey'
}

export enum WaiverOwnerEnum {
  /** Org owned waivers are private to that org. */
  Org = 'ORG',
  /** Reggy owned waivers are unmodifiable presets that all orgs can use. */
  Reggy = 'REGGY'
}

/** Boolean expression to compare columns of type "waiverOwner_enum". All fields are combined with logical 'AND'. */
export type WaiverOwnerEnumComparisonExp = {
  _eq?: InputMaybe<WaiverOwnerEnum>;
  _in?: InputMaybe<Array<WaiverOwnerEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WaiverOwnerEnum>;
  _nin?: InputMaybe<Array<WaiverOwnerEnum>>;
};

/** input type for inserting data into table "waiverOwner" */
export type WaiverOwnerInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  waivers?: InputMaybe<WaiverArrRelInsertInput>;
};

/** response of any mutation on the table "waiverOwner" */
export type WaiverOwnerMutationResponse = {
  __typename?: 'waiverOwner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WaiverOwner>;
};

/** input type for inserting object relation for remote table "waiverOwner" */
export type WaiverOwnerObjRelInsertInput = {
  data: WaiverOwnerInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WaiverOwnerOnConflict>;
};

/** on_conflict condition type for table "waiverOwner" */
export type WaiverOwnerOnConflict = {
  constraint: WaiverOwnerConstraint;
  update_columns?: Array<WaiverOwnerUpdateColumn>;
  where?: InputMaybe<WaiverOwnerBoolExp>;
};

/** Ordering options when selecting data from "waiverOwner". */
export type WaiverOwnerOrderBy = {
  description?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
  waivers_aggregate?: InputMaybe<WaiverAggregateOrderBy>;
};

/** primary key columns input for table: waiverOwner */
export type WaiverOwnerPkColumnsInput = {
  owner: Scalars['String'];
};

/** select columns of table "waiverOwner" */
export enum WaiverOwnerSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Owner = 'owner'
}

/** input type for updating data in table "waiverOwner" */
export type WaiverOwnerSetInput = {
  description?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

/** update columns of table "waiverOwner" */
export enum WaiverOwnerUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Owner = 'owner'
}

export type WaiverOwnerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WaiverOwnerSetInput>;
  where: WaiverOwnerBoolExp;
};

/** columns and relationships of "waiverRevision" */
export type WaiverRevision = Node & {
  __typename?: 'waiverRevision';
  createdBy: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cuid: Scalars['String'];
  fieldsConfiguration: Scalars['jsonb'];
  fileRef: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revision: Scalars['Int'];
  /** An object relationship */
  user: User;
  /** An object relationship */
  waiver?: Maybe<Waiver>;
  waiverCuid: Scalars['String'];
  /** An array relationship */
  waivers: Array<Waiver>;
  /** An aggregate relationship */
  waivers_aggregate: WaiverAggregate;
  /** An array relationship connection */
  waivers_connection: WaiverConnection;
};


/** columns and relationships of "waiverRevision" */
export type WaiverRevisionFieldsConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "waiverRevision" */
export type WaiverRevisionWaiversArgs = {
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};


/** columns and relationships of "waiverRevision" */
export type WaiverRevisionWaiversAggregateArgs = {
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};


/** columns and relationships of "waiverRevision" */
export type WaiverRevisionWaiversConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  distinct_on?: InputMaybe<Array<WaiverSelectColumn>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WaiverOrderBy>>;
  where?: InputMaybe<WaiverBoolExp>;
};

/** A Relay connection object on "waiverRevision" */
export type WaiverRevisionConnection = {
  __typename?: 'waiverRevisionConnection';
  edges: Array<WaiverRevisionEdge>;
  pageInfo: PageInfo;
};

export type WaiverRevisionEdge = {
  __typename?: 'waiverRevisionEdge';
  cursor: Scalars['String'];
  node: WaiverRevision;
};

/** aggregated selection of "waiverRevision" */
export type WaiverRevisionAggregate = {
  __typename?: 'waiverRevision_aggregate';
  aggregate?: Maybe<WaiverRevisionAggregateFields>;
  nodes: Array<WaiverRevision>;
};

export type WaiverRevisionAggregateBoolExp = {
  count?: InputMaybe<WaiverRevisionAggregateBoolExpCount>;
};

export type WaiverRevisionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WaiverRevisionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "waiverRevision" */
export type WaiverRevisionAggregateFields = {
  __typename?: 'waiverRevision_aggregate_fields';
  avg?: Maybe<WaiverRevisionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WaiverRevisionMaxFields>;
  min?: Maybe<WaiverRevisionMinFields>;
  stddev?: Maybe<WaiverRevisionStddevFields>;
  stddev_pop?: Maybe<WaiverRevisionStddevPopFields>;
  stddev_samp?: Maybe<WaiverRevisionStddevSampFields>;
  sum?: Maybe<WaiverRevisionSumFields>;
  var_pop?: Maybe<WaiverRevisionVarPopFields>;
  var_samp?: Maybe<WaiverRevisionVarSampFields>;
  variance?: Maybe<WaiverRevisionVarianceFields>;
};


/** aggregate fields of "waiverRevision" */
export type WaiverRevisionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WaiverRevisionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "waiverRevision" */
export type WaiverRevisionAggregateOrderBy = {
  avg?: InputMaybe<WaiverRevisionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WaiverRevisionMaxOrderBy>;
  min?: InputMaybe<WaiverRevisionMinOrderBy>;
  stddev?: InputMaybe<WaiverRevisionStddevOrderBy>;
  stddev_pop?: InputMaybe<WaiverRevisionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WaiverRevisionStddevSampOrderBy>;
  sum?: InputMaybe<WaiverRevisionSumOrderBy>;
  var_pop?: InputMaybe<WaiverRevisionVarPopOrderBy>;
  var_samp?: InputMaybe<WaiverRevisionVarSampOrderBy>;
  variance?: InputMaybe<WaiverRevisionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WaiverRevisionAppendInput = {
  fieldsConfiguration?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "waiverRevision" */
export type WaiverRevisionArrRelInsertInput = {
  data: Array<WaiverRevisionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WaiverRevisionOnConflict>;
};

/** aggregate avg on columns */
export type WaiverRevisionAvgFields = {
  __typename?: 'waiverRevision_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "waiverRevision" */
export type WaiverRevisionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "waiverRevision". All fields are combined with a logical 'AND'. */
export type WaiverRevisionBoolExp = {
  _and?: InputMaybe<Array<WaiverRevisionBoolExp>>;
  _not?: InputMaybe<WaiverRevisionBoolExp>;
  _or?: InputMaybe<Array<WaiverRevisionBoolExp>>;
  createdBy?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  fieldsConfiguration?: InputMaybe<JsonbComparisonExp>;
  fileRef?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  revision?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  waiver?: InputMaybe<WaiverBoolExp>;
  waiverCuid?: InputMaybe<StringComparisonExp>;
  waivers?: InputMaybe<WaiverBoolExp>;
  waivers_aggregate?: InputMaybe<WaiverAggregateBoolExp>;
};

/** unique or primary key constraints on table "waiverRevision" */
export enum WaiverRevisionConstraint {
  /** unique or primary key constraint on columns "cuid" */
  WaiverRevisionCuidKey = 'waiverRevision_cuid_key',
  /** unique or primary key constraint on columns "id" */
  WaiverRevisionPkey = 'waiverRevision_pkey',
  /** unique or primary key constraint on columns "revision", "waiverCuid" */
  WaiverRevisionWaiverCuidRevisionKey = 'waiverRevision_waiverCuid_revision_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WaiverRevisionDeleteAtPathInput = {
  fieldsConfiguration?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WaiverRevisionDeleteElemInput = {
  fieldsConfiguration?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WaiverRevisionDeleteKeyInput = {
  fieldsConfiguration?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "waiverRevision" */
export type WaiverRevisionIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "waiverRevision" */
export type WaiverRevisionInsertInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fieldsConfiguration?: InputMaybe<Scalars['jsonb']>;
  fileRef?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  waiver?: InputMaybe<WaiverObjRelInsertInput>;
  waiverCuid?: InputMaybe<Scalars['String']>;
  waivers?: InputMaybe<WaiverArrRelInsertInput>;
};

/** aggregate max on columns */
export type WaiverRevisionMaxFields = {
  __typename?: 'waiverRevision_max_fields';
  createdBy?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fileRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "waiverRevision" */
export type WaiverRevisionMaxOrderBy = {
  createdBy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WaiverRevisionMinFields = {
  __typename?: 'waiverRevision_min_fields';
  createdBy?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  fileRef?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  waiverCuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "waiverRevision" */
export type WaiverRevisionMinOrderBy = {
  createdBy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "waiverRevision" */
export type WaiverRevisionMutationResponse = {
  __typename?: 'waiverRevision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WaiverRevision>;
};

/** input type for inserting object relation for remote table "waiverRevision" */
export type WaiverRevisionObjRelInsertInput = {
  data: WaiverRevisionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WaiverRevisionOnConflict>;
};

/** on_conflict condition type for table "waiverRevision" */
export type WaiverRevisionOnConflict = {
  constraint: WaiverRevisionConstraint;
  update_columns?: Array<WaiverRevisionUpdateColumn>;
  where?: InputMaybe<WaiverRevisionBoolExp>;
};

/** Ordering options when selecting data from "waiverRevision". */
export type WaiverRevisionOrderBy = {
  createdBy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  fieldsConfiguration?: InputMaybe<OrderBy>;
  fileRef?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  waiver?: InputMaybe<WaiverOrderBy>;
  waiverCuid?: InputMaybe<OrderBy>;
  waivers_aggregate?: InputMaybe<WaiverAggregateOrderBy>;
};

/** primary key columns input for table: waiverRevision */
export type WaiverRevisionPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WaiverRevisionPrependInput = {
  fieldsConfiguration?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "waiverRevision" */
export enum WaiverRevisionSelectColumn {
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FieldsConfiguration = 'fieldsConfiguration',
  /** column name */
  FileRef = 'fileRef',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

/** input type for updating data in table "waiverRevision" */
export type WaiverRevisionSetInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  fieldsConfiguration?: InputMaybe<Scalars['jsonb']>;
  fileRef?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  waiverCuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type WaiverRevisionStddevFields = {
  __typename?: 'waiverRevision_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "waiverRevision" */
export type WaiverRevisionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type WaiverRevisionStddevPopFields = {
  __typename?: 'waiverRevision_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "waiverRevision" */
export type WaiverRevisionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type WaiverRevisionStddevSampFields = {
  __typename?: 'waiverRevision_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "waiverRevision" */
export type WaiverRevisionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type WaiverRevisionSumFields = {
  __typename?: 'waiverRevision_sum_fields';
  id?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "waiverRevision" */
export type WaiverRevisionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** update columns of table "waiverRevision" */
export enum WaiverRevisionUpdateColumn {
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  FieldsConfiguration = 'fieldsConfiguration',
  /** column name */
  FileRef = 'fileRef',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  WaiverCuid = 'waiverCuid'
}

export type WaiverRevisionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WaiverRevisionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WaiverRevisionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WaiverRevisionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WaiverRevisionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WaiverRevisionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WaiverRevisionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WaiverRevisionSetInput>;
  where: WaiverRevisionBoolExp;
};

/** aggregate var_pop on columns */
export type WaiverRevisionVarPopFields = {
  __typename?: 'waiverRevision_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "waiverRevision" */
export type WaiverRevisionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type WaiverRevisionVarSampFields = {
  __typename?: 'waiverRevision_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "waiverRevision" */
export type WaiverRevisionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WaiverRevisionVarianceFields = {
  __typename?: 'waiverRevision_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "waiverRevision" */
export type WaiverRevisionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
};

/** aggregated selection of "waiver" */
export type WaiverAggregate = {
  __typename?: 'waiver_aggregate';
  aggregate?: Maybe<WaiverAggregateFields>;
  nodes: Array<Waiver>;
};

export type WaiverAggregateBoolExp = {
  count?: InputMaybe<WaiverAggregateBoolExpCount>;
};

export type WaiverAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WaiverBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "waiver" */
export type WaiverAggregateFields = {
  __typename?: 'waiver_aggregate_fields';
  avg?: Maybe<WaiverAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WaiverMaxFields>;
  min?: Maybe<WaiverMinFields>;
  stddev?: Maybe<WaiverStddevFields>;
  stddev_pop?: Maybe<WaiverStddevPopFields>;
  stddev_samp?: Maybe<WaiverStddevSampFields>;
  sum?: Maybe<WaiverSumFields>;
  var_pop?: Maybe<WaiverVarPopFields>;
  var_samp?: Maybe<WaiverVarSampFields>;
  variance?: Maybe<WaiverVarianceFields>;
};


/** aggregate fields of "waiver" */
export type WaiverAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WaiverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "waiver" */
export type WaiverAggregateOrderBy = {
  avg?: InputMaybe<WaiverAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WaiverMaxOrderBy>;
  min?: InputMaybe<WaiverMinOrderBy>;
  stddev?: InputMaybe<WaiverStddevOrderBy>;
  stddev_pop?: InputMaybe<WaiverStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WaiverStddevSampOrderBy>;
  sum?: InputMaybe<WaiverSumOrderBy>;
  var_pop?: InputMaybe<WaiverVarPopOrderBy>;
  var_samp?: InputMaybe<WaiverVarSampOrderBy>;
  variance?: InputMaybe<WaiverVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "waiver" */
export type WaiverArrRelInsertInput = {
  data: Array<WaiverInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WaiverOnConflict>;
};

/** aggregate avg on columns */
export type WaiverAvgFields = {
  __typename?: 'waiver_avg_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "waiver" */
export type WaiverAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "waiver". All fields are combined with a logical 'AND'. */
export type WaiverBoolExp = {
  _and?: InputMaybe<Array<WaiverBoolExp>>;
  _not?: InputMaybe<WaiverBoolExp>;
  _or?: InputMaybe<Array<WaiverBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  cuid?: InputMaybe<StringComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  latestRevision?: InputMaybe<WaiverRevisionBoolExp>;
  latestRevisionNum?: InputMaybe<IntComparisonExp>;
  ownerCuid?: InputMaybe<StringComparisonExp>;
  ownerType?: InputMaybe<WaiverOwnerEnumComparisonExp>;
  revisions?: InputMaybe<WaiverRevisionBoolExp>;
  revisions_aggregate?: InputMaybe<WaiverRevisionAggregateBoolExp>;
  sanctionWaivers?: InputMaybe<SanctionWaiversBoolExp>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  waiverOwner?: InputMaybe<WaiverOwnerBoolExp>;
};

/** unique or primary key constraints on table "waiver" */
export enum WaiverConstraint {
  /** unique or primary key constraint on columns "cuid" */
  WaiverCuidKey = 'waiver_cuid_key',
  /** unique or primary key constraint on columns "id" */
  WaiverPkey = 'waiver_pkey'
}

/** input type for incrementing numeric columns in table "waiver" */
export type WaiverIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "waiver" */
export type WaiverInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  latestRevision?: InputMaybe<WaiverRevisionObjRelInsertInput>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  ownerCuid?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<WaiverOwnerEnum>;
  revisions?: InputMaybe<WaiverRevisionArrRelInsertInput>;
  sanctionWaivers?: InputMaybe<SanctionWaiversArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  waiverOwner?: InputMaybe<WaiverOwnerObjRelInsertInput>;
};

/** aggregate max on columns */
export type WaiverMaxFields = {
  __typename?: 'waiver_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  ownerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "waiver" */
export type WaiverMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WaiverMinFields = {
  __typename?: 'waiver_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cuid?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
  ownerCuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "waiver" */
export type WaiverMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "waiver" */
export type WaiverMutationResponse = {
  __typename?: 'waiver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Waiver>;
};

/** input type for inserting object relation for remote table "waiver" */
export type WaiverObjRelInsertInput = {
  data: WaiverInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WaiverOnConflict>;
};

/** on_conflict condition type for table "waiver" */
export type WaiverOnConflict = {
  constraint: WaiverConstraint;
  update_columns?: Array<WaiverUpdateColumn>;
  where?: InputMaybe<WaiverBoolExp>;
};

/** Ordering options when selecting data from "waiver". */
export type WaiverOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  cuid?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latestRevision?: InputMaybe<WaiverRevisionOrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
  ownerCuid?: InputMaybe<OrderBy>;
  ownerType?: InputMaybe<OrderBy>;
  revisions_aggregate?: InputMaybe<WaiverRevisionAggregateOrderBy>;
  sanctionWaivers_aggregate?: InputMaybe<SanctionWaiversAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  waiverOwner?: InputMaybe<WaiverOwnerOrderBy>;
};

/** primary key columns input for table: waiver */
export type WaiverPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "waiver" */
export enum WaiverSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  OwnerCuid = 'ownerCuid',
  /** column name */
  OwnerType = 'ownerType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "waiver" */
export type WaiverSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cuid?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  latestRevisionNum?: InputMaybe<Scalars['Int']>;
  ownerCuid?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<WaiverOwnerEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type WaiverStddevFields = {
  __typename?: 'waiver_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "waiver" */
export type WaiverStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type WaiverStddevPopFields = {
  __typename?: 'waiver_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "waiver" */
export type WaiverStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type WaiverStddevSampFields = {
  __typename?: 'waiver_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "waiver" */
export type WaiverStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type WaiverSumFields = {
  __typename?: 'waiver_sum_fields';
  id?: Maybe<Scalars['Int']>;
  latestRevisionNum?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "waiver" */
export type WaiverSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** update columns of table "waiver" */
export enum WaiverUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cuid = 'cuid',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionNum = 'latestRevisionNum',
  /** column name */
  OwnerCuid = 'ownerCuid',
  /** column name */
  OwnerType = 'ownerType',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type WaiverUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WaiverIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WaiverSetInput>;
  where: WaiverBoolExp;
};

/** aggregate var_pop on columns */
export type WaiverVarPopFields = {
  __typename?: 'waiver_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "waiver" */
export type WaiverVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type WaiverVarSampFields = {
  __typename?: 'waiver_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "waiver" */
export type WaiverVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WaiverVarianceFields = {
  __typename?: 'waiver_variance_fields';
  id?: Maybe<Scalars['Float']>;
  latestRevisionNum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "waiver" */
export type WaiverVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  latestRevisionNum?: InputMaybe<OrderBy>;
};
