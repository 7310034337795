import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFragment } from 'react-relay/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListSubheader from '@material-ui/core/ListSubheader';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { compareDesc, formatISO, formatRelative, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import { graphql } from 'relay-runtime';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import PaperBox from '@/ui/PaperBox';
import FormTextField from '@/ui/TextField/FormTextField';
import DisableToggle from '@/hoc/DisableToggle';

import DeleteConfirmClausesDialog from '@/components/Dialogs/DeleteConfirmationClausesDialog';

import { useTrackSentryError } from '@/analytics/sentry';
import { MAX_OCCURRENCE_LABEL_LENGTH } from '@/const';
import { OccurrenceInfo_event$key } from '@/containers/HostEvent/EventMetadataDialog/__generated__/OccurrenceInfo_event.graphql';
import { useUpdateOccurrenceLabelMutation } from '@/containers/HostEvent/EventMetadataDialog/eventMetadataMutations';
import { MetadataEvent } from '@/containers/HostEvent/EventMetadataDialog/OccurrencesDialog';
import { useGetEventCuid, useGetOrganizerCuid } from '@/containers/HostEvent/helpers';
import { readableDateRange } from '@/lib/date-helpers/date-utils';
import { getEventName, getHasCategoriedEventEventPassed } from '@/lib/event-info-utils';
import {
  cancelEventCF,
  deleteEventCF,
  duplicateEventCF,
  sendRegistrantMessageCF,
} from '@/lib/firebase';
import { OccurrencesLabelFormSchema } from '@/lib/form-schema/host-event';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { useHostEventData } from '@/providers/HostEventProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles(() => ({
  buttonLabel: {
    position: 'relative',
  },
  moreButtonContainer: {
    right: '-12px',
    height: ' 100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
  },
}));

/**
 * sort by last saved date
 */
export function sortEvents<T>(
  currentEvents: readonly (T & { lastSavedAt: string })[],
): (T & { lastSavedAt: string })[] {
  return R.sort(
    (a, b) => compareDesc(parseISO(a.lastSavedAt), parseISO(b.lastSavedAt)),
    currentEvents,
  );
}

interface OccurrenceInfoProps {
  event: MetadataEvent;
  isLastOccurrence: boolean;
  isCurrentOccurrence: boolean;
  setEvents: Dispatch<SetStateAction<MetadataEvent[]>>;
}
const OccurrenceInfo: React.FC<OccurrenceInfoProps> = ({
  event,
  isCurrentOccurrence,
  isLastOccurrence,
  setEvents,
}) => {
  const hostEventData = useHostEventData();
  const eventData = useFragment<OccurrenceInfo_event$key>(
    graphql`
      fragment OccurrenceInfo_event on event {
        organizerCuid
        occurrenceLabel
        endDate
        registrations_aggregate(
          where: { _and: [{ _or: [{ status: { _eq: complete } }, { status: { _eq: canceled } }] }] }
        ) {
          aggregate {
            count
          }
        }
        eventMetadata {
          name
        }
      }
    `,
    hostEventData,
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const currentEventCuid = useGetEventCuid();
  const organizerCuid = useGetOrganizerCuid();
  const trackSentryError = useTrackSentryError();
  const [isLoading, setIsLoading] = useState(false);
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const isMenuOpen = Boolean(menuAnchorEl);
  const eventDateRange = readableDateRange(event.startDate, event.endDate);
  const lastSavedText = formatRelative(parseISO(event.lastSavedAt), new Date());
  const isEventCancelled = Boolean(event.canceledAt);
  // This only applies to categoried events
  const isEventPassed = getHasCategoriedEventEventPassed(eventData);

  const allRegistrations = event.registrations?.length ?? 0;
  const hasRegistrations = allRegistrations > 0;
  const contactableRegistrations = (event.registrations ?? []).map((r) => r.userId);

  /**
   * Handle close occurrence options menu
   */
  const showOccurrenceMenuOptions = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };
  const closeOccurrenceMenuOptions = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  /**
   * Handle show/hide rename label dialog
   */
  const startEditingLabel = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchorEl(null);
    setIsEditingLabel(true);
  };
  const stopEditingLabel = (): void => {
    setIsEditingLabel(false);
  };

  /**
   * Select an event occurrence
   */
  const handleOccurrences = (): void => {
    window.location.href = AppRouteService.getAbsoluteUrl('OrganizerApp_EditEvent', {
      organizerSlug,
      eventCuid: event.cuid,
    });
  };

  /**
   * Duplicate event occurrence
   */
  const handleDuplicateEvent = async (e: React.MouseEvent<HTMLLIElement>): Promise<void> => {
    try {
      e.preventDefault();
      e.stopPropagation();
      closeOccurrenceMenuOptions(e);
      setIsLoading(true);
      const response = await duplicateEventCF({
        eventCuid: event.cuid,
        organizerCuid,
        shouldCreateNewEvent: false,
      });
      // Update local state
      setEvents((prevValues) => sortEvents([...prevValues, { ...response.data }]));
    } catch (error) {
      trackSentryError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Cancel an event occurrence
   */
  const showCancelEventConfirmationDialog = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    closeOccurrenceMenuOptions(e);
    setIsCancelDialogOpen(true);
  };
  const hideCancelEventConfirmationDialog = (): void => {
    setIsCancelDialogOpen(false);
  };
  const cancelEvent = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await cancelEventCF({ eventCuid: event.cuid });
      if (hasRegistrations) {
        await sendRegistrantMessageCF({
          eventCuid: event.cuid,
          message: `${getEventName(
            eventData,
          )} has been cancelled. Feel free to reply to this message if you have any questions.`,
          userIds: contactableRegistrations,
          organizerCuid: eventData.organizerCuid,
        });
      }
      // Update local state
      setEvents((prevValues) => {
        return prevValues.map((prevEvent) => {
          if (prevEvent.cuid === event.cuid) {
            return {
              ...prevEvent,
              canceledAt: new Date().toISOString(),
            };
          }
          return prevEvent;
        });
      });
    } catch (err) {
      trackSentryError(err);
    } finally {
      setIsLoading(false);
    }
  };
  // const unCancelEvent = async (e: React.MouseEvent<HTMLLIElement>): Promise<void> => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   closeOccurrenceMenuOptions(e);
  //   try {
  //     setIsLoading(true);
  //     await unCancelEventCF({ eventCuid: event.cuid });
  //     if (hasRegistrations) {
  //       await sendRegistrantMessageCF({
  //         eventCuid: event.cuid,
  //         message: `${getEventName(
  //           eventData,
  //         )} is no longer canceled. Feel free to reply to this message if you have any questions.`,
  //         userIds: contactableRegistrations,
  //         organizerCuid: eventData.organizerCuid,
  //       });
  //     }
  //     // Update local state
  //     setEvents((prevValues) => {
  //       return prevValues.map((prevEvent) => {
  //         if (prevEvent.cuid === event.cuid) {
  //           return {
  //             ...prevEvent,
  //             canceledAt: null,
  //           };
  //         }
  //         return prevEvent;
  //       });
  //     });
  //   } catch (err) {
  //     trackSentryError(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  /**
   * Delete an event occurrence
   */
  const showDeleteEventConfirmationDialog = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    closeOccurrenceMenuOptions(e);
    if (hasRegistrations) {
      enqueueSnackbar('Cannot delete event with registrations', { variant: 'error' });
      return;
    }
    setIsDeleteDialogOpen(true);
  };
  const hideDeleteEventConfirmationDialog = (): void => {
    setIsDeleteDialogOpen(false);
  };
  const deleteEvent = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await deleteEventCF({ eventCuid: event.cuid });

      // Deleted the currently selected event
      if (currentEventCuid === event.cuid) {
        window.location.href = AppRouteService.getAbsoluteUrl('OrganizerApp_Events', {
          organizerSlug,
        });
        return;
      }
      // Update local state
      setEvents((prevValues) => {
        return sortEvents(R.reject((ev) => ev.cuid === event.cuid, prevValues));
      });
    } catch (err) {
      trackSentryError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isEditingLabel) {
    return (
      <EditOccurrenceLabel
        eventCuid={event.cuid}
        handleDoneEditing={stopEditingLabel}
        occurrenceLabel={event.occurrenceLabel ?? ''}
        setEvents={setEvents}
      />
    );
  }
  return (
    <>
      {isDeleteDialogOpen && (
        <DeleteConfirmClausesDialog
          clauses={[
            'I will not be able to recover it.',
            'I will still be able to see my registration/revenue stats and the cyclist will still be able to see their history for this occurrence.',
            "I'm very sure I want to delete this occurrence.",
          ]}
          clausesPre="I understand that once I delete this occurrence,"
          isOpen={isDeleteDialogOpen}
          onClose={hideDeleteEventConfirmationDialog}
          onDelete={deleteEvent}
          title="Delete Occurrence"
        />
      )}
      {isCancelDialogOpen && (
        <DeleteConfirmClausesDialog
          clauses={[
            ...(hasRegistrations
              ? [
                  'I will have to manually refund all registrations for this event.',
                  `Canceling this event will affect ${allRegistrations} registrants`,
                  // 'A message will be sent to all registrants of this event letting them know it is canceled.',
                ]
              : []),
            'I will not be able to recover it.',
            'I will still be able to see my registration/revenue stats and the cyclist will still be able to see their history for this event.',
            "I'm very sure I want to cancel this event.",
          ]}
          clausesPre="I understand that once I cancel this event,"
          isOpen={isCancelDialogOpen}
          onClose={hideCancelEventConfirmationDialog}
          onDelete={cancelEvent}
          title="Cancel Event"
        />
      )}
      <DisableToggle showLoadingWhileDisabled disabled={isLoading}>
        <Button
          fullWidth
          classes={{ label: classes.buttonLabel }}
          onClick={!isCurrentOccurrence ? handleOccurrences : undefined}
        >
          <Box pr={4}>
            <Typography variant="body2">
              <b>
                {isEventCancelled ? '(Canceled) ' : ''}
                {event.occurrenceLabel} - {eventDateRange}
              </b>
            </Typography>
            <Typography variant="body2">Last Saved: {lastSavedText}</Typography>
            {(isLastOccurrence || hasRegistrations) && (
              <Typography component="div" style={{ color: Colors.Warning }} variant="caption">
                {hasRegistrations
                  ? 'Cannot delete occurrence with registrations '
                  : 'Cannot delete last occurrence '}
                {hasRegistrations && ` (${allRegistrations} Registrations)`}
              </Typography>
            )}
            {(isEventCancelled || isEventPassed) && (
              <Typography component="div" style={{ color: Colors.Warning }} variant="caption">
                Cannot change name of a passed or canceled occurrence
              </Typography>
            )}
          </Box>
          <Box className={classes.moreButtonContainer}>
            <IconButton
              aria-label="more options"
              color="inherit"
              onClick={showOccurrenceMenuOptions}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
          {isMenuOpen && (
            <Menu
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              getContentAnchorEl={null}
              id={event.cuid}
              onBackdropClick={closeOccurrenceMenuOptions}
              open={isMenuOpen}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              {!isEventPassed && !isEventCancelled && (
                <MenuItem onClick={startEditingLabel}>Change Label</MenuItem>
              )}
              <MenuItem onClick={handleDuplicateEvent}>Duplicate</MenuItem>
              {!isEventCancelled && (
                <MenuItem onClick={showCancelEventConfirmationDialog}>Cancel Occurrence</MenuItem>
              )}
              {!isLastOccurrence && !hasRegistrations && (
                <>
                  <ListSubheader component="div">
                    <Divider />
                  </ListSubheader>
                  <MenuItem onClick={showDeleteEventConfirmationDialog}>Delete</MenuItem>
                </>
              )}
            </Menu>
          )}
        </Button>
      </DisableToggle>
    </>
  );
};

interface EditOccurrenceLabelProps {
  handleDoneEditing: () => void;
  occurrenceLabel: string;
  eventCuid: string;
  setEvents: Dispatch<SetStateAction<MetadataEvent[]>>;
}
const EditOccurrenceLabel: React.FC<EditOccurrenceLabelProps> = ({
  eventCuid,
  handleDoneEditing,
  occurrenceLabel,
  setEvents,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<{ occurrenceLabel: string }>({
    defaultValues: { occurrenceLabel },
    resolver: yupResolver(OccurrencesLabelFormSchema),
  });
  const trackSentryError = useTrackSentryError();

  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const { updateOccurrenceLabelMutation } = useUpdateOccurrenceLabelMutation();

  /**
   * Save occurrence label
   */
  const saveLabel = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      await updateOccurrenceLabelMutation(eventCuid, data.occurrenceLabel);
      // Update local state
      setEvents((prevValues) =>
        sortEvents(
          prevValues.map((ev) => {
            if (ev.cuid === eventCuid) {
              return {
                ...ev,
                occurrenceLabel: data.occurrenceLabel,
                lastSavedAt: formatISO(new Date()),
              };
            }
            return ev;
          }),
        ),
      );
      handleDoneEditing();
    } catch (err) {
      trackSentryError(err);
    } finally {
      setIsLoading(false);
    }
  });

  /**
   * Cancel button was hit
   */
  const handleCancel = (): void => {
    handleDoneEditing();
  };

  return (
    <PaperBox
      display="flex"
      height="100%"
      px={1.5}
      py={1}
      style={{ backgroundColor: theme.palette.grey[50] }}
    >
      <Box width="100%">
        <FormTextField
          fullWidth
          control={control}
          error={!!errors.occurrenceLabel}
          getValues={getValues}
          helperText={errors?.occurrenceLabel?.message}
          inputProps={{ maxLength: MAX_OCCURRENCE_LABEL_LENGTH }}
          name="occurrenceLabel"
        />
      </Box>
      <Box m="auto" ml={1}>
        <ExtendedButton disabled={isLoading} onClick={handleCancel} size="small">
          Cancel
        </ExtendedButton>
      </Box>
      <Box m="auto" ml={1}>
        <ExtendedButton
          color="primary"
          isLoading={isLoading}
          onClick={saveLabel}
          size="small"
          variant="contained"
        >
          Save
        </ExtendedButton>
      </Box>
    </PaperBox>
  );
};

export default OccurrenceInfo;
