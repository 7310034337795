/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type helpers_unPublishEventOrganizer_MutationVariables = {
    eventCuid: string;
};
export type helpers_unPublishEventOrganizer_MutationResponse = {
    readonly update_event: {
        readonly returning: ReadonlyArray<{
            readonly id: string;
            readonly lastSavedAt: string;
            readonly publishedAt: string | null;
        }>;
    } | null;
};
export type helpers_unPublishEventOrganizer_Mutation = {
    readonly response: helpers_unPublishEventOrganizer_MutationResponse;
    readonly variables: helpers_unPublishEventOrganizer_MutationVariables;
};



/*
mutation helpers_unPublishEventOrganizer_Mutation(
  $eventCuid: String!
) {
  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {publishedAt: null, lastSavedAt: "now()"}) {
    returning {
      id
      lastSavedAt
      publishedAt
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventCuid"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "_set",
                    "value": {
                        "lastSavedAt": "now()",
                        "publishedAt": null
                    }
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "eventCuid"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "cuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "event_mutation_response",
            "kind": "LinkedField",
            "name": "update_event",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "event",
                    "kind": "LinkedField",
                    "name": "returning",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastSavedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publishedAt",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "helpers_unPublishEventOrganizer_Mutation",
            "selections": (v1 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "helpers_unPublishEventOrganizer_Mutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "bee7034f0e745d080261db59d39d05ee",
            "id": null,
            "metadata": {},
            "name": "helpers_unPublishEventOrganizer_Mutation",
            "operationKind": "mutation",
            "text": "mutation helpers_unPublishEventOrganizer_Mutation(\n  $eventCuid: String!\n) {\n  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {publishedAt: null, lastSavedAt: \"now()\"}) {\n    returning {\n      id\n      lastSavedAt\n      publishedAt\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '7a393b4527c985f7f20b8654a7213f77';
export default node;
