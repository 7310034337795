/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditEventNameDialog_event = {
    readonly cuid: string;
    readonly eventMetadata: {
        readonly cuid: string;
        readonly name: string;
        readonly slug: string;
        readonly isCategoriedEvent: boolean;
        readonly organizer: {
            readonly slug: string;
            readonly cuid: string;
        };
    };
    readonly " $refType": "EditEventNameDialog_event";
};
export type EditEventNameDialog_event$data = EditEventNameDialog_event;
export type EditEventNameDialog_event$key = {
    readonly " $data"?: EditEventNameDialog_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditEventNameDialog_event">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "EditEventNameDialog_event",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "eventMetadata",
                "kind": "LinkedField",
                "name": "eventMetadata",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCategoriedEvent",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "organizer",
                        "kind": "LinkedField",
                        "name": "organizer",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/),
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "event",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'b196c95cb7e9b9c6acd92da85d17ad54';
export default node;
