import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';

import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  searchResultContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  slickContainer: {
    height: '264px',
    borderRadius: theme.spacing(1),
    border: Colors.LightBorder,
    overflow: 'hidden',
  },
}));

const MobileResultPlaceholder: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classNames(classes.searchResultContainer)}>
      <Box className={classes.slickContainer}>
        <Skeleton height={264} variant="rect" width="100%" />
      </Box>
      <Box display="flex" flexDirection="column" minWidth={0} mt={1} width="100%">
        <Skeleton height={24} variant="text" width="100%" />
        <Skeleton height={20} variant="text" width="100%" />
        <Skeleton height={20} variant="text" width="100%" />
        <Skeleton height={20} variant="text" width={80} />
      </Box>
    </Box>
  );
};

export default MobileResultPlaceholder;
