/**
 * Date Filter for Search Page
 */
import React, { useState } from 'react';
import { DateUtils, RangeModifier } from 'react-day-picker';
// eslint-disable-next-line import/no-unresolved
import { DayModifiers } from 'react-day-picker/types/Modifiers';
import { useMount } from 'react-use';
import { Divider, FormControlLabel, Grid, Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import { ArrowDropDown } from '@material-ui/icons';
import { addDays, addMonths, nextSaturday, startOfMonth, subDays } from 'date-fns';

import ExtendedButton from '@/ui/Button/ExtendedButton';

import EditablePopoverWrapper from '@/components/EditablePopoverWrapper/EditablePopoverWrapper';
import SelectableDatesCalendar from '@/components/SelectableDatesCalendar/SelectableDatesCalendar';

import {
  useGetSearchFiltersGlobal,
  useSetSearchFiltersGlobal,
} from '@/containers/Search/recoilStore';
import { formatToISODateString, readableDateRange } from '@/lib/date-helpers/date-utils';
import { useSetQueryParams } from '@/lib/path-helpers';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5),
    },
  },
}));
const EventsDateFilter: React.FC = () => {
  const classes = useStyles();
  const setQueryParams = useSetQueryParams();
  const searchFiltersGlobal = useGetSearchFiltersGlobal();
  const setSearchFiltersGlobal = useSetSearchFiltersGlobal();
  const [selectedDate, setSelectedDate] = useState<RangeModifier>({
    from: searchFiltersGlobal.from,
    to: searchFiltersGlobal.to,
  });
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>();
  const [buttonText, setButtonText] = useState('Date');

  /**
   * Set our button text
   */
  useMount(() => {
    handleApply();
  });

  /**
   * Show past events
   */
  const handleShowPastEvents = (e: React.ChangeEvent<unknown>, checked: boolean): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      showPastEvents: checked,
    }));
    setQueryParams({
      showPastEvents: checked,
    });
  };

  /**
   * Date was clicked on the calendar
   */
  const handleDayClick = (day: Date, modifiers: DayModifiers): void => {
    if (modifiers?.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, selectedDate);
    setSelectedDate(range);
  };

  /**
   * Month change
   */
  const handleMonthChange = (month: Date): void => {
    setCurrentMonth(month);
  };

  /**
   * Set date filters
   */
  const handleApply = (): void => {
    setButtonText(
      !selectedDate.from && !selectedDate.to
        ? 'Date'
        : `${readableDateRange(selectedDate.from, selectedDate.to)}`,
    );
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      from: selectedDate.from,
      to: selectedDate.to,
    }));
    setQueryParams({
      from: selectedDate.from ? formatToISODateString(selectedDate.from) : undefined,
      to: selectedDate.to ? formatToISODateString(selectedDate.to) : undefined,
    });
  };

  /**
   * Clear date filters
   */
  const handleClear = (): void => {
    setButtonText('Date');
    setSelectedDate({
      from: undefined,
      to: undefined,
    });
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      from: undefined,
      to: undefined,
    }));
    setQueryParams({
      from: undefined,
      to: undefined,
    });
  };

  /**
   * Presets
   */
  const handleTomorrowClick = (): void => {
    setCurrentMonth(new Date());
    setSelectedDate({
      from: addDays(new Date(), 1),
      to: addDays(new Date(), 1),
    });
  };
  const handleThisWeekendClick = (): void => {
    const saturday = nextSaturday(new Date());
    setCurrentMonth(saturday);
    setSelectedDate({
      from: saturday,
      to: addDays(saturday, 1),
    });
  };
  const handleThisMonthClick = (): void => {
    const thisMonth = startOfMonth(new Date());
    setCurrentMonth(thisMonth);
    setSelectedDate({
      from: new Date(),
      to: subDays(addMonths(thisMonth, 1), 1),
    });
  };
  const handleNextMonthClick = (): void => {
    const thisMonth = startOfMonth(new Date());
    const nextMonth = addMonths(thisMonth, 1);
    setCurrentMonth(nextMonth);
    setSelectedDate({
      from: nextMonth,
      to: subDays(addMonths(nextMonth, 1), 1),
    });
  };

  return (
    <>
      <Box py={2}>
        <Box alignItems="center" display="flex" height={22}>
          <Typography variant="body2">
            <b>Date</b>
          </Typography>
        </Box>
        <Box mt={1}>
          <EditablePopoverWrapper
            cancelButtonTitle="Clear All"
            cancelFunction={handleClear}
            componentToWrap={
              <ExtendedButton
                fullWidth
                color="primary"
                endIcon={<ArrowDropDown />}
                minWidth="xs"
                size="small"
                variant="outlined"
              >
                {buttonText}
              </ExtendedButton>
            }
            disableBackdropClick={false}
            innerPopoverComponent={
              <Grid container justify="center" spacing={2}>
                <Grid item xs="auto">
                  <SelectableDatesCalendar
                    showOutsideDays
                    disabledDays={[{ before: new Date() }]}
                    modifiers={{
                      start: selectedDate.from ?? undefined,
                      end: selectedDate.to ?? undefined,
                    }}
                    month={currentMonth}
                    onDayClick={handleDayClick}
                    onMonthChange={handleMonthChange}
                    selectedDays={{ from: selectedDate.from, to: selectedDate.to }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={1}>
                    {/* <Grid item xs="auto"> */}
                    {/*  <ExtendedButton onClick={handleAnyDayClick} size="small"> */}
                    {/*    Any Day */}
                    {/*  </ExtendedButton> */}
                    {/* </Grid> */}
                    <Grid item xs="auto">
                      <ExtendedButton onClick={handleTomorrowClick} size="small">
                        Tomorrow
                      </ExtendedButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ExtendedButton onClick={handleThisWeekendClick} size="small">
                        This Weekend
                      </ExtendedButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ExtendedButton onClick={handleThisMonthClick} size="small">
                        This Month
                      </ExtendedButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ExtendedButton onClick={handleNextMonthClick} size="small">
                        Next Month
                      </ExtendedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            popoverProps={{ classes: { paper: classes.paper } }}
            setButtonTitle="Apply"
            setFunction={handleApply}
          />
        </Box>
        <Box mt={1}>
          <FormControlLabel
            control={
              <Switch
                checked={searchFiltersGlobal.showPastEvents}
                color="primary"
                onChange={handleShowPastEvents}
              />
            }
            label="Show past events"
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default EventsDateFilter;
