/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type helpers_IsCanceledEvent_QueryVariables = {
    eventCuid: string;
};
export type helpers_IsCanceledEvent_QueryResponse = {
    readonly event_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly canceledAt: string | null;
                readonly deleted_at: string | null;
            };
        }>;
    };
};
export type helpers_IsCanceledEvent_Query = {
    readonly response: helpers_IsCanceledEvent_QueryResponse;
    readonly variables: helpers_IsCanceledEvent_QueryVariables;
};



/*
query helpers_IsCanceledEvent_Query(
  $eventCuid: String!
) {
  event_connection(where: {cuid: {_eq: $eventCuid}}) {
    edges {
      node {
        canceledAt
        deleted_at
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventCuid"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "eventCuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "cuid"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canceledAt",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted_at",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "helpers_IsCanceledEvent_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "helpers_IsCanceledEvent_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventConnection",
                    "kind": "LinkedField",
                    "name": "event_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "34213fc58fcc12b933bb925ba11b232b",
            "id": null,
            "metadata": {},
            "name": "helpers_IsCanceledEvent_Query",
            "operationKind": "query",
            "text": "query helpers_IsCanceledEvent_Query(\n  $eventCuid: String!\n) {\n  event_connection(where: {cuid: {_eq: $eventCuid}}) {\n    edges {\n      node {\n        canceledAt\n        deleted_at\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd708b860a055a0966f57f00830371957';
export default node;
