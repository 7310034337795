/**
 * Helper hooks for all Event Profile page containers
 */

import React, { useLayoutEffect } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { add } from 'date-fns';
import format from 'date-fns/format';

import {
  BlackDiamond,
  BlueSquare,
  CourseDifficultyProps,
  DoubleBlackDiamond,
  GreenCircle,
} from '@/ui/TrailSignIcons';

import { helpers_useGetIsEventAtCapacity_public_event$key } from './__generated__/helpers_useGetIsEventAtCapacity_public_event.graphql';

import { CourseDifficultyEnum } from '@/const';
import {
  useSetCategoryCuidsToRegisterGlobal,
  useSetIsRefiningGlobal,
  useSetIsSeeDatesDialogOpenGlobal,
  useSetIsViewParticipantsDialogOpenGlobal,
  useSetRefinedCategoryCuidsGlobal,
  useSetTicketCuidsToRegisterGlobal,
} from '@/containers/EventProfile/recoilStore';
import { NonCategoriedRegistrationCard_public_event } from '@/containers/EventProfile/RefineRegister/NonCategoried/__generated__/NonCategoriedRegistrationCard_public_event.graphql';
import {
  convertDateToTimeString,
  dateFromFloatingDateString,
  getTimeRange,
} from '@/lib/date-helpers/date-utils';
import { useSetEventProfileNameGlobal } from '@/pages/EventProfile/recoilStore';
import { useEventProfileData } from '@/providers/EventProfileProvider';

/**
 * Check if we are at capacity for an event
 */
export const useGetIsEventAtCapacity = (): boolean => {
  const eventProfileData = useEventProfileData();
  const eventData = useFragment<helpers_useGetIsEventAtCapacity_public_event$key>(
    graphql`
      fragment helpers_useGetIsEventAtCapacity_public_event on event {
        spotsAvailable
        registrations_aggregate(
          where: {
            _and: [
              {
                _or: [
                  # Not our registration
                  {
                    _and: [
                      { userId: { _neq: $userId } }
                      { status: { _in: [complete, in_progress] } }
                    ]
                  }
                  # Our own registration
                  { _and: [{ userId: { _eq: $userId } }, { status: { _in: [complete] } }] }
                ]
              }
              # All Registrations have a category
              { _not: { registrationCategories: { categoryCuid: { _is_null: true } } } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    eventProfileData,
  );
  const {
    registrations_aggregate: { aggregate },
    spotsAvailable,
  } = eventData;
  // Total Registrations
  const totalRegistrations = aggregate?.count || 0;

  return !!spotsAvailable && totalRegistrations >= spotsAvailable;
};

/**
 * Get the course difficulty icon related to a courseDifficulty
 */
export const getCourseDifficultyIcon = (
  courseDifficulty: string,
): React.FC<CourseDifficultyProps> => {
  let CourseDifficultyComponent = GreenCircle;
  if (courseDifficulty === CourseDifficultyEnum.Intermediate) {
    CourseDifficultyComponent = BlueSquare;
  }
  if (courseDifficulty === CourseDifficultyEnum.Expert) {
    CourseDifficultyComponent = BlackDiamond;
  }
  if (courseDifficulty === CourseDifficultyEnum.Pro) {
    CourseDifficultyComponent = DoubleBlackDiamond;
  }
  return CourseDifficultyComponent;
};

/**
 * Clinic day text
 */
export const buildClinicDays = (
  clinic: NonCategoriedRegistrationCard_public_event['clinics'][0],
): { dayText: string; timeRange: string }[] => {
  const { clinicDays } = clinic;
  return clinicDays.map((clinicDay, index) => {
    // Build time
    const startTime = dateFromFloatingDateString(clinicDay.startTime);
    const endTime = add(startTime, {
      minutes: clinicDay.eventClinicDayDuration.duration as number,
    });
    const timeRange = getTimeRange(startTime, endTime);

    // Build day
    let dayText = format(startTime, 'EEE, MMM dd, yyyy');
    if (new Date().getFullYear() === startTime.getFullYear()) {
      dayText = format(startTime, 'EEE, MMM dd');
    }
    if (clinicDays.length > 1) {
      dayText = `Day ${index + 1}: ${dayText}`;
    }
    return { dayText, timeRange };
  });
};

/**
 * Ride day text
 */
export const buildRideDays = (
  ride: NonCategoriedRegistrationCard_public_event['rides'][0],
): { dayText: string; timeText: string }[] => {
  const { rideDays } = ride;
  return rideDays.map((rideDay, index) => {
    // Build time
    const startTime = dateFromFloatingDateString(rideDay.startTime);
    const timeText = convertDateToTimeString(startTime);

    // Build day
    let dayText = format(startTime, 'EEE, MMM dd, yyyy');
    if (new Date().getFullYear() === startTime.getFullYear()) {
      dayText = format(startTime, 'EEE, MMM dd');
    }
    if (rideDays.length > 1) {
      dayText = `Day ${index + 1}: ${dayText}`;
    }
    return { dayText, timeText };
  });
};

/**
 * Reset all global state on unmount
 */
export const useResetGlobalStateOnUnmount = (): void => {
  const setEventProfileNameGlobal = useSetEventProfileNameGlobal();
  const setCategoryCuidsToRegisterGlobal = useSetCategoryCuidsToRegisterGlobal();
  const setTicketCuidsToRegisterGlobal = useSetTicketCuidsToRegisterGlobal();
  const setRefinedCategoryCuidsGlobal = useSetRefinedCategoryCuidsGlobal();
  const setIsRefiningGlobal = useSetIsRefiningGlobal();
  const setIsSeeDatesDialogOpenGlobal = useSetIsSeeDatesDialogOpenGlobal();
  const setIsViewParticipantsDialogOpenGlobal = useSetIsViewParticipantsDialogOpenGlobal();

  /**
   * Clear global state on unmount
   */
  useLayoutEffect(() => {
    return (): void => {
      setEventProfileNameGlobal('');
      // setOccurrenceToDisplayGlobal('');
      setCategoryCuidsToRegisterGlobal([]);
      setTicketCuidsToRegisterGlobal([]);
      setRefinedCategoryCuidsGlobal(null);
      setIsRefiningGlobal(false);
      setIsSeeDatesDialogOpenGlobal(false);
      setIsViewParticipantsDialogOpenGlobal(null);
    };
  });
};
