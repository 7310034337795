/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type helpers_IsCanceledClinic_QueryVariables = {
    clinicCuid: string;
};
export type helpers_IsCanceledClinic_QueryResponse = {
    readonly clinic_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly canceledAt: string | null;
            };
        }>;
    };
};
export type helpers_IsCanceledClinic_Query = {
    readonly response: helpers_IsCanceledClinic_QueryResponse;
    readonly variables: helpers_IsCanceledClinic_QueryVariables;
};



/*
query helpers_IsCanceledClinic_Query(
  $clinicCuid: String!
) {
  clinic_connection(where: {cuid: {_eq: $clinicCuid}}) {
    edges {
      node {
        canceledAt
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "clinicCuid"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "clinicCuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "cuid"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canceledAt",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "helpers_IsCanceledClinic_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "clinicConnection",
                    "kind": "LinkedField",
                    "name": "clinic_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "clinicEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "clinic",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "helpers_IsCanceledClinic_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "clinicConnection",
                    "kind": "LinkedField",
                    "name": "clinic_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "clinicEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "clinic",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7a47f51413ab8b3184decb38c0551047",
            "id": null,
            "metadata": {},
            "name": "helpers_IsCanceledClinic_Query",
            "operationKind": "query",
            "text": "query helpers_IsCanceledClinic_Query(\n  $clinicCuid: String!\n) {\n  clinic_connection(where: {cuid: {_eq: $clinicCuid}}) {\n    edges {\n      node {\n        canceledAt\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3db9f2d30d8237dae80082a973fd617b';
export default node;
