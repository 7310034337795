/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EventsMoreFilterAmenityQueryVariables = {};
export type EventsMoreFilterAmenityQueryResponse = {
    readonly amenity_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly cuid: string;
                readonly name: string;
                readonly info: string;
                readonly imageUrl: string;
            };
        }>;
    };
};
export type EventsMoreFilterAmenityQuery = {
    readonly response: EventsMoreFilterAmenityQueryResponse;
    readonly variables: EventsMoreFilterAmenityQueryVariables;
};



/*
query EventsMoreFilterAmenityQuery {
  amenity_connection {
    edges {
      node {
        id
        cuid
        name
        info
        imageUrl
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "amenityConnection",
            "kind": "LinkedField",
            "name": "amenity_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "amenityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "amenity",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cuid",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "info",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "imageUrl",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "EventsMoreFilterAmenityQuery",
            "selections": (v0 /*: any*/),
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "EventsMoreFilterAmenityQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "37e5ebf5db7efe996b94b57bf4f75046",
            "id": null,
            "metadata": {},
            "name": "EventsMoreFilterAmenityQuery",
            "operationKind": "query",
            "text": "query EventsMoreFilterAmenityQuery {\n  amenity_connection {\n    edges {\n      node {\n        id\n        cuid\n        name\n        info\n        imageUrl\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8bc51caca2fb861fe8d7c5fdd845d2cd';
export default node;
