import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import BrandTypography from '@/ui/BrandTypography';
import PaperBox from '@/ui/PaperBox';

const useStyles = makeStyles((theme: Theme) => ({
  slack: {
    transition: 'all 300ms',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      transform: 'translateY(-4px)',
    },
  },
}));

interface BetaDialogProps {
  hideDialog: () => void;
  isOpen: boolean;
}
const BetaDialog: React.FC<BetaDialogProps> = ({ hideDialog, isOpen }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={hideDialog} open={isOpen}>
      <DialogTitle>
        <Typography align="center" variant="h1">
          Welcome to the{' '}
          <BrandTypography inline noLink variant="h1">
            Reggy Beta!
          </BrandTypography>
        </Typography>
      </DialogTitle>
      <DialogContent>
        This is a super early version (<em>maybe</em> 40% done) of{' '}
        <BrandTypography inline noLink>
          Reggy
        </BrandTypography>{' '}
        for people who want to help me test, find bugs, and give feedback/suggestions. Think of
        yourself as a V1 prototype tester, where anything and everything can change, not a final
        reviewer in a magazine.
        <br />
        <br />
        Right now, we are testing the <em>Organizer/Host Event</em> flow and the <em>Event Page</em>
        . Feel free to explore, create events and view events.
        <br />
        <br />
        There is <b>NO</b> registration and, as I work through things, I may be deleting data so{' '}
        <b>please don't use this for real races!</b>
        <br />
        <br />
        I set up a Discord server that you can join to give me feedback and/or get help on anything
        you might need. It would be great to hear your thoughts!
        <br />
        <Box display="flex" flexDirection="column" mb={4} mt={2.5} width="100%">
          <Typography gutterBottom align="center" variant="body2">
            Click the image below to join our Discord community.
          </Typography>
          <PaperBox
            border
            borderRadius={4}
            className={classes.slack}
            display="flex"
            m="auto"
            mt={2}
            shadow={24}
            width={249}
          >
            <a
              href="https://discord.gg/k6QyEV29"
              rel="noreferrer noopener"
              style={{ display: 'flex' }}
              target="_blank"
            >
              <img
                alt="Discord"
                src="https://1000logos.net/wp-content/uploads/2020/10/Discord-logo.png"
                style={{ width: '100%' }}
              />
            </a>
          </PaperBox>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="secondary" onClick={hideDialog} size="large" variant="contained">
          Great! I'm glad to help!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BetaDialog;
