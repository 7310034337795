/**
 * Discover App
 */
import React, { useEffect, useState } from 'react';
import { Configure, useInfiniteHits } from 'react-instantsearch-hooks';
import { useMount } from 'react-use';
import { Box, Grid, Hidden, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import * as R from 'ramda';

import BrandTypography from '@/ui/BrandTypography';
import ExtendedButton from '@/ui/Button/ExtendedButton';

import MobileHit from '@/components/Search/MobileHit';

import { ALGOLIA_DISCOVER_HIT_CLICKED } from '@/analytics/algolia/algolia';
import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import {
  ABILITY_LEVEL_MIN_MAX,
  AllDisciplineNamesEnum,
  ClinicFocusEnum,
  ClinicSkillsEnum,
  DisciplineNameEnum,
  LanguagesEnum,
  GenderEnum,
  RideTypeEnum,
  SearchTypeEnum,
} from '@/const';
import NoDiscoverResults, {
  makeSingularDiscoverCategoryName,
} from '@/containers/Discover/NoDiscoverResults';
import SearchMap from '@/containers/Search/Map/SearchMap';
import MobileResultPlaceholder from '@/containers/Search/ResultsList/MobileResultPlaceholder';
import { useGetClearAlgoliaCacheGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { AlgoliaSearchResult } from '@/lib/algolia';
import { useGeoSearch } from '@/lib/algolia-utils';
import { getParsedQueryParams, QueryObjectType, useSetQueryParams } from '@/lib/path-helpers';
import { ObjectIndexer } from '@/lib/type-defs/utility';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 72px)',
    position: 'relative',
    zIndex: 1,
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(0, 2),
    // },
  },
  resultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  tab: {
    minWidth: '80px',
    textTransform: 'none',
  },
  tabContainer: {
    borderBottom: Colors.LightBorder,
    display: 'flex',
    justifyContent: 'center',
  },
  flexContainer: {
    display: 'block',
  },
  mapContainer: {
    marginRight: theme.spacing(-1),
  },
}));

/**
 * https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
 * [["category:Book", "category:Movie"], "author:John Doe"]
 * translates as (category:Book OR category:Movie) AND author:"John Doe"
 */
type DiscoverCategoriesType = {
  [x: string]: {
    createMessage: string;
    personalizeMessage?: string;
    facetFilters: (string | string[])[];
    numericFilters: (string | string[])[];
  };
};
const DiscoverCategories: DiscoverCategoriesType = {
  // All: {
  //   createMessage: 'Publish a new experience',
  //   facetFilters: [],
  //   numericFilters: [],
  // },
  // New: {
  //   createMessage: 'Publish a new experience',
  //   facetFilters: [],
  //   numericFilters: [`publishedAt > ${subDays(new Date(), 7).getTime()}`],
  // },
  Coaches: {
    createMessage: 'Become a coach',
    personalizeMessage:
      'Here are some coaches that would love to help you improve your skills on the bike!',
    facetFilters: [`activity:${SearchTypeEnum.Coach}`],
    numericFilters: [],
  },
  'Private Lessons': {
    createMessage: 'Create a private lesson',
    facetFilters: [`activity:${SearchTypeEnum.PrivateLesson}`],
    numericFilters: [],
  },
  Races: {
    createMessage: 'Create a race',
    facetFilters: [`activity:${SearchTypeEnum.Race}`],
    numericFilters: [],
  },
  Clinics: {
    createMessage: 'Create a clinic',
    facetFilters: [`activity:${SearchTypeEnum.Clinic}`],
    numericFilters: [],
  },
  Rides: {
    createMessage: 'Create a ride',
    facetFilters: [`activity:${SearchTypeEnum.Ride}`],
    numericFilters: [],
  },
  Free: {
    createMessage: 'Create a free experience',
    facetFilters: [`isFree:true`],
    numericFilters: [],
  },
  Shuttles: {
    createMessage: 'Create an experience that has shuttles at the venue',
    facetFilters: [`amenities:Shuttle Service`],
    numericFilters: [],
  },
  Virtual: {
    createMessage: 'Create a virtual clinic, private lesson or online course',
    facetFilters: [[`isVirtual:true`, `activity:${SearchTypeEnum.OnlineCourse}`]],
    numericFilters: [],
  },
  'Online Courses': {
    createMessage: 'Create an online course',
    facetFilters: [`activity:${SearchTypeEnum.OnlineCourse}`],
    numericFilters: [],
  },
  'First Timer': {
    createMessage: 'Create an experience for beginners',
    facetFilters: [],
    numericFilters: [`abilityLevelMax = ${ABILITY_LEVEL_MIN_MAX[0]}`],
  },
  Sponsorship: {
    createMessage: 'Create an experience that is focused on athlete sponsorship',
    facetFilters: [
      [`skills:${ClinicSkillsEnum.SportsMarketing}`, `skills:${ClinicSkillsEnum.Sponsorships}`],
    ],
    numericFilters: [],
  },
  // 'Under 18': {
  //   createMessage: 'Create an experience for people under the age of 18',
  //   facetFilters: [],
  //   numericFilters: [`ageMax < ${MINOR_AGE}`, `ageMax > 0`],
  // },
  // 'Over 40': {
  //   createMessage: 'Create an experience for people over the age of 40',
  //   facetFilters: [],
  //   numericFilters: [`ageMin > 40`],
  // },
  Fitness: {
    createMessage: 'Create a fitness focused experience',
    facetFilters: [
      [
        `skills:${ClinicSkillsEnum.Yoga}`,
        `skills:${ClinicSkillsEnum.InjuryRecoveryTraining}`,
        `skills:${ClinicSkillsEnum.InjuryPreventionTraining}`,
        `skills:${ClinicSkillsEnum.GymTraining}`,
        `skills:${ClinicSkillsEnum.StrengthTraining}`,
        `skills:${ClinicSkillsEnum.EnduranceTraining}`,
        `skills:${ClinicSkillsEnum.PedalEfficiency}`,
        `skills:${ClinicSkillsEnum.IntervalTraining}`,
        `focus:${ClinicFocusEnum.Fitness}`,
        `coachFocus:${ClinicFocusEnum.Fitness}`,
      ],
    ],
    numericFilters: [],
  },
  MTB: {
    createMessage: 'Create an Mountain Bike focused experience',
    facetFilters: [
      [
        `disciplines:${DisciplineNameEnum.MTB}`,
        `disciplines:${DisciplineNameEnum.Downhill}`,
        `disciplines:${DisciplineNameEnum.Enduro}`,
        `disciplines:${DisciplineNameEnum.DualSlalom}`,
        `disciplines:${DisciplineNameEnum.XC}`,
        `disciplines:${DisciplineNameEnum.ShortTrack}`,
        `disciplines:${DisciplineNameEnum.DirtJumping}`,
        `coachDisciplines.name:${DisciplineNameEnum.MTB}`,
        `coachDisciplines.name:${DisciplineNameEnum.Downhill}`,
        `coachDisciplines.name:${DisciplineNameEnum.Enduro}`,
        `coachDisciplines.name:${DisciplineNameEnum.DualSlalom}`,
        `coachDisciplines.name:${DisciplineNameEnum.XC}`,
        `coachDisciplines.name:${DisciplineNameEnum.ShortTrack}`,
        `coachDisciplines.name:${DisciplineNameEnum.DirtJumping}`,
      ],
    ],
    numericFilters: [],
  },
  'Bike Maintenance': {
    createMessage: 'Create an experience that teaches "Bike Maintenance" skills',
    facetFilters: [
      [
        `skills:${ClinicSkillsEnum.BikeMaintenance}`,
        `skills:${ClinicSkillsEnum.SuspensionSetup}`,
        `skills:${ClinicSkillsEnum.BikeSetup}`,
      ],
    ],
    numericFilters: [],
  },
  'For Women': {
    createMessage: 'Create an experience specifically for women',
    facetFilters: [
      [`genders:${GenderEnum.Women}`, `coachGenders:${GenderEnum.Women}`],
      `genders:-${GenderEnum.Men}`,
      `coachGenders:-${GenderEnum.Men}`,
    ],
    numericFilters: [],
  },
  'Injury Prevention': {
    createMessage: 'Create an experience focused around injury prevention and recovery',
    facetFilters: [
      [
        `skills:${ClinicSkillsEnum.InjuryRecoveryTraining}`,
        `skills:${ClinicSkillsEnum.InjuryPreventionTraining}`,
      ],
    ],
    numericFilters: [],
  },
  'Curated Rides': {
    createMessage: 'Create a ride with the ride type "Curated"',
    facetFilters: [`rideType:${RideTypeEnum.Curated}`],
    numericFilters: [],
  },
  'Spanish Speakers': {
    createMessage: 'Create an experience for spanish speakers',
    facetFilters: [`languages:${LanguagesEnum.Spanish}`],
    numericFilters: [],
  },
  'Photo & Video': {
    createMessage: 'Create an experience focused on photography and videography',
    facetFilters: [
      [
        `disciplines:${AllDisciplineNamesEnum.Photography}`,
        `disciplines:${AllDisciplineNamesEnum.Videography}`,
        `coachDisciplines.name:${AllDisciplineNamesEnum.Photography}`,
        `coachDisciplines.name:${AllDisciplineNamesEnum.Videography}`,
        `skills:${ClinicSkillsEnum.Videography}`,
        `skills:${ClinicSkillsEnum.Photography}`,
        `skills:${ClinicSkillsEnum.Lighting}`,
        `skills:${ClinicSkillsEnum.Drones}`,
        `skills:${ClinicSkillsEnum.FilmEquipment}`,
        `skills:${ClinicSkillsEnum.PanShots}`,
      ],
    ],
    numericFilters: [],
  },
  'Road & Gravel': {
    createMessage: 'Create a road or gravel focused experience',
    facetFilters: [
      [
        `disciplines:${DisciplineNameEnum.Road}`,
        `disciplines:${DisciplineNameEnum.Cyclocross}`,
        `disciplines:${DisciplineNameEnum.Criterium}`,
        `disciplines:${DisciplineNameEnum.Gravel}`,
        `coachDisciplines.name:${DisciplineNameEnum.Road}`,
        `coachDisciplines.name:${DisciplineNameEnum.Cyclocross}`,
        `coachDisciplines.name:${DisciplineNameEnum.Criterium}`,
        `coachDisciplines.name:${DisciplineNameEnum.Gravel}`,
      ],
    ],
    numericFilters: [],
  },
  Chairlifts: {
    createMessage: 'Create an experience that has a chairlift at the venue',
    facetFilters: [`amenities:Chairlift`],
    numericFilters: [],
  },
  'Nutrition & Diet': {
    createMessage: 'Create an experience that teaches nutrition and diet skills',
    facetFilters: [[`skills:${ClinicSkillsEnum.Nutrition}`, `skills:${ClinicSkillsEnum.Diet}`]],
    numericFilters: [],
  },
  'Social Rides': {
    createMessage: 'Create a ride with the ride type "Social"',
    facetFilters: [`rideType:${RideTypeEnum.Social}`],
    numericFilters: [],
  },
  'Training Rides': {
    createMessage: 'Create a ride with the ride type "Training"',
    facetFilters: [`rideType:${RideTypeEnum.Training}`],
    numericFilters: [],
  },
  'E-Bikes': {
    createMessage: 'Create an e-bike focused experience',
    facetFilters: [
      `disciplines:${DisciplineNameEnum.EBike}`,
      `coachDisciplines.name:${DisciplineNameEnum.EBike}`,
    ],
    numericFilters: [],
  },
  Pro: {
    createMessage: 'Create an experience for pros',
    facetFilters: [],
    numericFilters: [`abilityLevelMin = ${ABILITY_LEVEL_MIN_MAX[1]}`],
  },
  'Trail Building': {
    createMessage: 'Create an experience focused on trail building',
    facetFilters: [
      [
        `disciplines:${AllDisciplineNamesEnum.TrailBuilding}`,
        `coachDisciplines.name:${AllDisciplineNamesEnum.TrailBuilding}`,
        `skills:${ClinicSkillsEnum.TrailBuilding}`,
        `skills:${ClinicSkillsEnum.TrailMaintenance}`,
        `skills:${ClinicSkillsEnum.ErosionControl}`,
      ],
    ],
    numericFilters: [],
  },
  Business: {
    createMessage: 'Create an experience focused on cycling industry skills',
    facetFilters: [
      [
        `disciplines:${AllDisciplineNamesEnum.Business}`,
        `disciplines:${AllDisciplineNamesEnum.Marketing}`,
        `coachDisciplines.name:${AllDisciplineNamesEnum.Business}`,
        `coachDisciplines.name:${AllDisciplineNamesEnum.Marketing}`,
        `skills:${ClinicSkillsEnum.ProductMarketing}`,
        `skills:${ClinicSkillsEnum.SportsMarketing}`,
        `skills:${ClinicSkillsEnum.Hiring}`,
        `skills:${ClinicSkillsEnum.ResumeBuilding}`,
        `skills:${ClinicSkillsEnum.Sponsorships}`,
      ],
    ],
    numericFilters: [],
  },
  'Series Events': {
    createMessage: 'Create an experience that is part of a series',
    facetFilters: [`isSeriesEvent:true`],
    numericFilters: [],
  },
  'Group Rides': {
    createMessage: 'Create a ride with the ride type "Group Ride"',
    facetFilters: [`rideType:${RideTypeEnum.GroupRide}`],
    numericFilters: [],
  },
  'Gran Fondo': {
    createMessage: 'Create a ride with the ride type "Gran Fondo"',
    facetFilters: [`rideType:${RideTypeEnum.GranFondo}`],
    numericFilters: [],
  },
  'UCI Races': {
    createMessage: 'Create a race that is sanctioned by the UCI',
    facetFilters: [`sanctions:UCI`],
    numericFilters: [],
  },
};
export type DiscoverCategoryType = string; // keyof typeof DiscoverCategories;

const getDefaultCategory = (
  defaultCat: QueryObjectType[keyof QueryObjectType],
): DiscoverCategoryType => {
  if (!defaultCat || !DiscoverCategories[(defaultCat as unknown) as DiscoverCategoryType]) {
    return 'Coaches';
  }
  return (defaultCat as unknown) as DiscoverCategoryType;
};
const DiscoverView: React.FC = () => {
  const classes = useStyles();
  const params = getParsedQueryParams();
  const setQueryParams = useSetQueryParams();
  const { trackDiscoverCategoryClick, trackDiscoverHitClick } = useSegmentTrack();
  const clearAlgoliaCacheGlobal = useGetClearAlgoliaCacheGlobal();
  const [isSearchLoading, setIsSearchLoading] = useState(true);
  const [categoryTabValue, setCategoryTabValue] = useState<DiscoverCategoryType>(
    getDefaultCategory(params?.category || undefined),
  );

  const { hits, isLastPage, results, sendEvent, showMore } = useInfiniteHits<
    AlgoliaSearchResult & ObjectIndexer
  >({
    // cache: createInfiniteHitsSessionStorageCache({
    //   keys: ['DiscoverView'],
    // }),
  });
  const { clearMapRefinement } = useGeoSearch();

  const [categoryCache, setCategoryCache] = useState<string[]>([]);

  const hasResults = Boolean(hits.length);

  /**
   * Update tab on mount
   */
  useMount(() => {
    if (clearAlgoliaCacheGlobal) {
      clearAlgoliaCacheGlobal().then();
    }
    if (categoryTabValue === 'New' && !hasResults) {
      setCategoryTabValue(SearchTypeEnum.All);
    }
  });

  /**
   * QueryID gets set to undefined on a new query and then updated when the
   * query comes back from network
   */
  useEffect(() => {
    if (results?.queryID) {
      setIsSearchLoading(false);
    }
  }, [results?.queryID]);

  /**
   * Update query params on tab change
   */
  useEffect(() => {
    setQueryParams({ category: categoryTabValue });
  }, [categoryTabValue]);

  /**
   * Change Tab
   */
  const onTabChange = (event: React.ChangeEvent<unknown>, newValue: DiscoverCategoryType): void => {
    if (!categoryCache.includes(newValue)) {
      setIsSearchLoading(true);
    }
    clearMapRefinement();
    setCategoryCache(R.uniq([...categoryCache, newValue]));
    trackDiscoverCategoryClick({ categoryName: newValue });
    setCategoryTabValue(newValue);
  };

  /**
   * TODO
   * https://www.algolia.com/doc/api-reference/api-parameters/optionalFilters/
   * Use user object and add gender, ability & age.
   * https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filter-scoring/
   * https://www.algolia.com/doc/api-reference/api-parameters/sortFacetValuesBy/
   * https://www.algolia.com/doc/guides/managing-results/rules/rules-overview/how-to/customize-search-results-by-platform/#creating-contextual-rules
   * https://www.algolia.com/doc/guides/managing-results/rules/rules-overview/in-depth/implementing-rules/#filters-can-trigger-rules
   */
  return (
    <Box display="flex" flexGrow={1} mb={8} overflow="hidden">
      <Configure
        aroundLatLngViaIP
        // TODO: 180th meridian still is broken when "searching this area" however,
        //   below causes infinite update
        // insideBoundingBox={
        //   currentRefinement ? fix180thMeridianForAlgolia(currentRefinement) : undefined
        // }
        facetFilters={DiscoverCategories[categoryTabValue].facetFilters}
        numericFilters={DiscoverCategories[categoryTabValue].numericFilters}
        query=""
      />
      <Box className={classes.mainContainer}>
        <Box className={classes.tabContainer}>
          <Tabs
            classes={{ flexContainer: classes.flexContainer }}
            indicatorColor="primary"
            onChange={onTabChange}
            value={categoryTabValue}
            variant="scrollable"
          >
            {Object.keys(DiscoverCategories).map((category) => (
              <Tab className={classes.tab} key={category} label={category} value={category} />
            ))}
          </Tabs>
        </Box>
        <Box className={classes.resultsContainer}>
          <Grid container spacing={3}>
            <Grid item lg={7} sm={6} xs={12}>
              {categoryTabValue !== SearchTypeEnum.All && !hasResults && !isSearchLoading && (
                <NoDiscoverResults
                  category={categoryTabValue}
                  createMessage={DiscoverCategories[categoryTabValue].createMessage}
                />
              )}
              {hasResults && (
                <Box>
                  <Box mt={1.5}>
                    <Typography align="center" variant="h5">
                      We've found some great{' '}
                      <BrandTypography inline variant="h5">
                        {makeSingularDiscoverCategoryName(categoryTabValue)}
                      </BrandTypography>{' '}
                      experiences for you!
                    </Typography>
                  </Box>
                  <Typography align="center">
                    {DiscoverCategories[categoryTabValue].personalizeMessage
                      ? DiscoverCategories[categoryTabValue].personalizeMessage
                      : 'Take a look at your top matches:'}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} />
                {isSearchLoading ? (
                  <>
                    {R.range(0, 20).map((num) => (
                      <Grid item key={num} lg={3} md={4} sm={6} xs={12}>
                        <MobileResultPlaceholder />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {hits.map((hit) => {
                      const sendClickEvent = (): void => {
                        trackDiscoverHitClick({ hit });
                        sendEvent('click', [hit], ALGOLIA_DISCOVER_HIT_CLICKED);
                      };
                      return (
                        <Grid item key={hit.objectID} lg={4} md={6} sm={12} xs={12}>
                          <MobileHit hit={hit} sendClickEvent={sendClickEvent} />
                        </Grid>
                      );
                    })}
                  </>
                )}
                {!isLastPage && (
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <ExtendedButton
                        color="primary"
                        onClick={showMore}
                        size="medium"
                        variant="outlined"
                      >
                        Show More
                      </ExtendedButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item lg={5} sm={6} xs={12}>
              <Hidden xsDown>
                <Box className={classes.mapContainer}>
                  <SearchMap height={`calc(100vh - ${72}px)`} />
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DiscoverView;
