/**
 * Container page for organizer profile page
 */
import React from 'react';
import { useParams } from 'react-router';

import Page from '../Page';

import { makeOrganizerAppTitle } from '@/containers/Organizer/helpers';
import MainLayout from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const OrganizerProfilePage: ReactFCC = ({ children }) => {
  const params = useParams();
  return (
    <Page description="A Reggy Cycling Organization" title={makeOrganizerAppTitle()}>
      <MainLayout key={params.organizerSlug} stickyHeader={false}>
        {children}
      </MainLayout>
    </Page>
  );
};

export default OrganizerProfilePage;
