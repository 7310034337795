// Relay doesn't support setting non-primitive values: https://github.com/facebook/relay/issues/2441
import pluralize from 'pluralize';
import * as R from 'ramda';

import { ABILITY_LEVEL_MIN_MAX, AGE_MIN_MAX, AVERAGE_SPEED_MIN_MAX, SpeedTypeEnum } from '@/const';

export const correctlyTypeStringArray = (stringArray: string[] | unknown): string[] => {
  const stringArrayUnknown = stringArray as string[] | string;
  let typedStringArray: string[];
  if (typeof stringArrayUnknown === 'string') {
    typedStringArray = JSON.parse(stringArrayUnknown);
  } else {
    typedStringArray = stringArrayUnknown as string[];
  }
  return typedStringArray;
};

// Relay doesn't support setting non-primitive values: https://github.com/facebook/relay/issues/2441
export const correctlyTypeRange = (range: number[] | unknown): number[] => {
  const rangeUnknown = range as number[] | string;
  let typedRange: number[];
  if (typeof rangeUnknown === 'string') {
    typedRange = JSON.parse(rangeUnknown);
  } else {
    typedRange = rangeUnknown as number[];
  }
  return typedRange;
};

/**
 * Get the label for the average speed slider
 */
export const averageSpeedValueToLabel = (value: string, speedType: SpeedTypeEnum): string => {
  if (value === String(AVERAGE_SPEED_MIN_MAX[0]) || value === String(AVERAGE_SPEED_MIN_MAX[1])) {
    return 'No Limit';
  }
  return `${value} ${speedType}`;
};

/**
 * Get the label for the age slider
 */
export const ageValueToLabel = (value: string): string => {
  if (value === String(AGE_MIN_MAX[0]) || value === String(AGE_MIN_MAX[1])) {
    return 'No Limit';
  }
  return value;
};

/**
 * Get the label for the riding time slider
 */
export const ridingTimePerDayValueToLabel = (value: string): string => {
  return `${parseFloat(value.toString())} ${pluralize('Hour', parseFloat(value))}`;
};

/**
 * Get the label for the ability level slider
 */
export const abilityLevelValueToLabel = (
  value: string | number,
  parenthesesInsteadOfDots?: boolean,
  showNumber?: boolean,
): string => {
  const intValue = typeof value === 'string' ? parseInt(value, 10) : value;
  let stringValue = '';
  if (intValue === 1) {
    stringValue = `Beginner`;
  }
  if (intValue === 2) {
    stringValue = `Intermediate`;
  }
  if (intValue === 3) {
    stringValue = `Expert`;
  }
  if (intValue === 4) {
    stringValue = `Pro`;
  }
  return `${stringValue}${
    showNumber === true ? (parenthesesInsteadOfDots ? ` (${value})` : ` · ${value}`) : ''
  }`;
};

/**
 * Get abbreviated ability labels.
 */
export const abilityLevelValueToAbbrLabel = (
  value: string | number,
  showNumber?: boolean,
): string => {
  const intValue = typeof value === 'string' ? parseInt(value, 10) : value;
  let stringValue = '';
  if (intValue === 1) {
    stringValue = `Beg`;
  }
  if (intValue === 2) {
    stringValue = `Int`;
  }
  if (intValue === 3) {
    stringValue = `Exp`;
  }
  if (intValue === 4) {
    stringValue = `Pro`;
  }
  return `${stringValue}${showNumber ? ` ${value}` : ''}`;
};

/**
 * Convert ability range to readable text
 * Open
 * Beginner
 * Intermediate - Expert
 */
export const getAbilityLabelFull = ({
  abilityLevelRange,
  longFormInsteadOfOpen,
  parenthesesInsteadOfDots,
  showNumber,
}: {
  abilityLevelRange: number[];
  longFormInsteadOfOpen?: boolean;
  parenthesesInsteadOfDots?: boolean;
  showNumber?: boolean;
}): string => {
  if (R.equals(abilityLevelRange, ABILITY_LEVEL_MIN_MAX) && !longFormInsteadOfOpen) {
    return 'Open';
  }
  if (abilityLevelRange[0] === abilityLevelRange[1]) {
    return abilityLevelValueToLabel(abilityLevelRange[0], parenthesesInsteadOfDots, showNumber);
  }
  return `${abilityLevelValueToLabel(
    abilityLevelRange[0],
    parenthesesInsteadOfDots,
  )} - ${abilityLevelValueToLabel(abilityLevelRange[1], parenthesesInsteadOfDots, showNumber)}`;
};

/**
 * Get the label for the intensity slider
 * Chill
 * Chill · 1
 * Chill (1)
 */
export const intensityValueToLabel = (
  value: string | number,
  parenthesesInsteadOfDots?: boolean,
  showNumber?: boolean,
): string => {
  const intValue = typeof value === 'string' ? parseInt(value, 10) : value;
  let stringValue = '';
  if (intValue === 1) {
    stringValue = `Chill`;
  }
  if (intValue === 2) {
    stringValue = `Moderate`;
  }
  if (intValue === 3) {
    stringValue = `Hard`;
  }
  if (intValue === 4) {
    stringValue = `All-Out`;
  }
  return `${stringValue}${
    showNumber === true ? (parenthesesInsteadOfDots ? ` (${value})` : ` · ${value}`) : ''
  }`;
};
/**
 * Convert intensity range to readable text
 * Chill
 * Moderate - Hard
 */
export const getIntensityLabelFull = ({
  intensityLevelRange,
  parenthesesInsteadOfDots,
  showNumber,
}: {
  intensityLevelRange: number[];
  parenthesesInsteadOfDots?: boolean;
  showNumber?: boolean;
}): string => {
  if (intensityLevelRange[0] === intensityLevelRange[1]) {
    return intensityValueToLabel(intensityLevelRange[0], parenthesesInsteadOfDots, showNumber);
  }
  return `${intensityValueToLabel(
    intensityLevelRange[0],
    parenthesesInsteadOfDots,
    showNumber,
  )} - ${intensityValueToLabel(intensityLevelRange[1], parenthesesInsteadOfDots)}`;
};
