/**
 * Chip used in Multiselect
 */
import React from 'react';
import { Box, Chip, makeStyles, Theme } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import ConditionalWrapper from '@/hoc/ConditionalWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    backgroundColor: 'transparent',
    border: '1px solid #c4c4c4',
    borderRadius: '14px',
    color: theme.palette.text.primary,
    // boxShadow: theme.shadows[8],
    height: '28px',
    maxWidth: '100%',
    '& .MuiChip-label': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
}));

interface MultiSelectChipProps extends ChipProps {
  onDelete?(): void;
  hideChipTooltips?: boolean;
}
const MultiSelectChip: React.FC<MultiSelectChipProps> = ({
  hideChipTooltips,
  onDelete,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <ConditionalWrapper
      WrapperComponent={Tooltip}
      condition={!hideChipTooltips}
      wrapperProps={{ title: rest?.label || '' }}
    >
      <Box display="inline-flex" maxWidth="100%" mx={1} my={0.2}>
        <Chip {...rest} className={classes.chip} color="primary" onDelete={onDelete} />
      </Box>
    </ConditionalWrapper>
  );
};

export default MultiSelectChip;
