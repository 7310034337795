import { Theme } from '@material-ui/core';

export const MuiTabs = (theme: Theme): Record<string, unknown> => ({
  root: {
    '& .PrivateTabScrollButton-root-469 svg': {
      boxShadow: '0 0 16px 36px whitesmoke',
      zIndex: 1,
    },
    '& .MuiTab-root': {
      flexGrow: 1,
    },
  },
  flexContainer: {
    // justifyContent: 'center',
    // [theme.breakpoints.down('xs')]: {
    // margin: 'auto',
    // width: 342,
    // justifyContent: 'center'
    // },
  },
});
