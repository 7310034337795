import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';

import ExtendedButton from '@/ui/Button/ExtendedButton';

interface UnPublishConfirmDialogProps {
  hideDialog: () => void;
  isOpen: boolean;
  onUnPublish: () => void;
}
const UnPublishConfirmDialog: React.FC<UnPublishConfirmDialogProps> = ({
  hideDialog,
  isOpen,
  onUnPublish,
}) => {
  const [unPublishConfirmed, setUnPublishConfirmed] = useState(false);
  const toggleUnPublishConfirmed = (): void => {
    setUnPublishConfirmed((confirmed) => !confirmed);
  };
  const onUnPublishHandler = (): void => {
    setUnPublishConfirmed(false);
    onUnPublish();
    hideDialog();
  };
  const onHideHandler = (): void => {
    setUnPublishConfirmed(false);
    hideDialog();
  };

  return (
    <Dialog onClose={hideDialog} open={isOpen}>
      <DialogTitle>Un-Publish Event</DialogTitle>
      <DialogContent>
        <p>
          If you un-publish this event, cyclists will not be able to access it or sign up for it.
        </p>

        <FormControlLabel
          control={
            <Checkbox
              checked={unPublishConfirmed}
              color="primary"
              onChange={toggleUnPublishConfirmed}
            />
          }
          label="I understand the consequences of un-publishing this event."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHideHandler} variant="text">
          Cancel
        </Button>
        <ExtendedButton
          errorColor
          disabled={!unPublishConfirmed}
          onClick={onUnPublishHandler}
          variant="contained"
        >
          Un-Publish
        </ExtendedButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnPublishConfirmDialog;
