import { Hit } from 'instantsearch.js/es/types/results';

/**
 * Algolia Search types
 */
interface ObjectIndexer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

/**
 * Algolia Indexes
 * Used for the Algolia Search API
 */
export const REGGY_SEARCH_INDEX_NAME_BASE = 'reggy-search';
export const REGGY_DISCOVER_REPLICA_NAME_BASE = 'reggy-discover';

/**
 * Events
 */
export type activityType_enum = 'Challenge' | 'Clinic' | 'Race' | 'Ride' | '%future added value';
export type UpdateAlgoliaIndexMutationVariables = {
  cuid: string;
  activity: activityType_enum;
};

interface EventVenue {
  _geoloc: {
    lat: number;
    lng: number;
  };
  country: string;
  countryCode: string;
  fullAddress: string;
  city: string;
  state: string;
  address: string;
  venueName: string;
}
export interface AlgoliaEventResult {
  objectID: string;
  activity: string;
  eventAccess: string;
  abilityGap: number;
  abilityLevelRange: number[];
  abilityMin: number;
  abilityMax: number;
  ageRange: number[];
  ageMin: number;
  ageMax: number;
  additionalInfo: string[];
  amenities: string[];
  courseNames: string[];
  courseDifficulties: string[];
  currencyCode: string;
  disciplines: string[];
  description: string;
  hasPassed: boolean;
  eventUrl: string;
  hostName: string;
  prices: number[];
  priceMin: number | null;
  priceMax: number | null;
  genders: string[];
  hasFatmapCourse: boolean;
  hasImages: boolean;
  hasRideWithGPSCourse: boolean;
  isCategoriedEvent: boolean;
  isSeriesEvent: boolean;
  isProfileComplete: boolean;
  isVirtual: boolean;
  isFree: boolean;
  images: string[];
  lastDateToRegisterTimestamp: number;
  name: string;
  numberOfAmenities: number;
  numberOfImages: number;
  numberOfSchedules: number;
  series: string[];
  startDateTimestamp: number;
}

/**
 * Clinic
 */
export interface AlgoliaClinicResult extends AlgoliaEventResult {
  focus: string[];
  overview: string;
  languages: string[];
  isVirtual: boolean;
  verifiedCoachScore: number;
  eventClinicDayDurations: { dayNumber: number; duration: number }[];
  _geoloc?: {
    lat: number;
    lng: number;
  };
  country?: string;
  countryCode?: string;
  fullAddress?: string;
  city?: string;
  state?: string;
  address?: string;
  venueName?: string;
  reviewCount: number;
  reviewAverage: number;
  reviewRecPct: number;
}

/**
 * Non Categoried Ride
 */
export interface AlgoliaNonCategoriedRideResult extends AlgoliaEventResult, EventVenue {
  ridingTimePerDayRange: number[];
  ridingTimePerDayMin: number;
  ridingTimePerDayMax: number;
  abilityLevelRange: number[];
  abilityMin: number;
  abilityMax: number;
  hasAverageSpeed: boolean;
  averageSpeedRange: number[];
  averageSpeedMin: number;
  averageSpeedMax: number;
  intensityRange: number[];
  intensityMin: number;
  intensityMax: number;
  moreInfo: string;
  rideType: string;
  speedType: string;
}

/**
 * Race
 */
export interface AlgoliaRaceResult extends AlgoliaEventResult, EventVenue {
  averageAgeGap: number;
  averageAbilityGap: number;
  categoryNames: string[];
  hasPrizes: boolean;
  numberOfCategories: number;
  sanctions: string[];
  startDate: string;
  endDate: string;
}

/**
 * Categoried Ride
 */
export interface AlgoliaCategoriedRideResult
  extends AlgoliaRaceResult,
    AlgoliaNonCategoriedRideResult {}

/**
 * Coaches
 */
export interface AlgoliaCoachResult extends ObjectIndexer {
  objectID: string;
  activity: string;
  // abilityLevelRange: number[];
  // abilityMin: number;
  // abilityMax: number;
  // abilityGap: number;
  ageRange: number[];
  ageMin: number;
  ageMax: number;
  ageGap: number;
  currencyCode: string;
  coachAbout: string;
  coachDisciplines: {
    name: string;
    abilityLevel: number[];
    skills: string[];
  }[];
  coachCertifications: string[];
  coachTagline: string;
  coachImages: string[];
  coachingVibe: string[];
  coachUrl: string;
  prices: (number | null)[];
  pricesMin: number;
  pricesMax: number;
  coachFocus: string[];
  coachGenders: string[];
  hasPassed: boolean;
  hostName: string | null;
  hasCoachImages: boolean;
  hasFreeLessons: boolean;
  hasRacingExp: boolean;
  hasVirtualLessons: boolean;
  isProfileComplete: boolean;
  isIdentityVerified: boolean;
  introVideoUrl: string;
  introVideoThumbnailUrl: string;
  languages: string[];
  name: string;
  profilePicUrl: string;
  totalCoachImages: number;
  totalCoachLessons: number;
  racingExp: string;
  _geoloc: {
    lat: number;
    lng: number;
  };
  countryCode: string;
  country: string;
  city: string;
  state: string;
  reviewCount: number;
  reviewAverage: number;
  reviewRecPct: number;
}

/**
 * Lessons
 */
export interface AlgoliaLessonResult {
  totalScore: number;
  objectID: string;
  activity: string;
  abilityLevelRange: number[];
  abilityMin: number;
  abilityMax: number;
  abilityGap: number;
  ageRange: number[];
  ageMin: number;
  ageMax: number;
  ageGap: number;
  attributeForDistinct: string;
  currencyCode: string;
  coachAbout: string;
  coachDisciplines: {
    name: string;
    abilityLevel: number[];
    skills: string[];
  }[];
  hostName: string | null;
  coachCuid: string;
  coachCertifications: string[];
  coachImages: string[];
  coachingVibe: string[];
  coachUrl: string;
  description: string;
  disciplines: string[];
  duration: number;
  name: string;
  prices: number[];
  priceMin: number;
  priceMax: number;
  coachFocus: string[];
  coachGenders: string[];
  hasCoachImages: boolean;
  hasPassed: boolean;
  highlightedPosition: number;
  isBundle: boolean;
  isFree: boolean;
  isVirtual: boolean;
  isProfileComplete: boolean;
  isIdentityVerified: boolean;
  languages: string[];
  lessonUrl: string;
  profilePicUrl: string;
  recurringInterval: string;
  recurrenceType: string;
  skills: string[];
  totalCoachImages: number;
  totalCoachLessons: number;
  lessonsPerBundle: number;
  coachTagline: string;
  _geoloc: {
    lat: number;
    lng: number;
  };
  countryCode: string;
  country: string;
  city: string;
  state: string;
}

/**
 * Online Course
 */
export interface AlgoliaOnlineCourseResult extends ObjectIndexer {
  objectID: string;
  activity: string;
  abilityLevelRange: number[];
  abilityMin: number;
  abilityMax: number;
  abilityGap: number;
  ageRange: number[];
  ageMin: number;
  ageMax: number;
  ageGap: number;
  currencyCode: string;
  hasPassed: boolean;
  hostName: string | null;
  images: string[];
  onlineCourseUrl: string;
  description: string;
  disciplines: string[];
  name: string;
  prices: number[];
  priceMin: number;
  priceMax: number;
  isFree: boolean;
  isProfileComplete: boolean;
  totalLessons: number;
  totalSections: number;
}

export type AlgoliaSearchResult = AlgoliaClinicResult &
  AlgoliaRaceResult &
  AlgoliaCategoriedRideResult &
  AlgoliaNonCategoriedRideResult &
  AlgoliaCoachResult &
  AlgoliaOnlineCourseResult &
  AlgoliaLessonResult;
export type AlgoliaSearchResultHit = Hit<AlgoliaSearchResult>;
