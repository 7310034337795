import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ErrorMessage from '@/components/ErrorMessage';

import { useGetEventCuid } from '@/containers/HostEvent/helpers';
import { useIsSmallScreenDown } from '@/lib/ui-utils';

interface GlobalSaveErrorDialogProps {
  isOpen: boolean;
}
const GlobalSaveErrorDialog: React.FC<GlobalSaveErrorDialogProps> = ({ isOpen }) => {
  const eventCuid = useGetEventCuid();
  const isSmallScreenDown = useIsSmallScreenDown();
  return (
    <Dialog
      PaperProps={{
        style: {
          margin: isSmallScreenDown ? 0 : undefined,
          maxHeight: isSmallScreenDown ? 'none' : undefined,
        },
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={isOpen}
    >
      <Box
        display={isSmallScreenDown ? 'flex' : undefined}
        height={isSmallScreenDown ? '100vh' : undefined}
        p={2.5}
      >
        <DialogContent>
          <ErrorMessage
            eventCuid={eventCuid}
            message={
              <Box my={1}>
                <Typography gutterBottom>
                  Something went wrong while we were saving your event!
                </Typography>
                <Typography variant="body2">
                  We made as many changes as we could before we hit an error. Please reload the page
                  and make any changes that did not get saved again.
                </Typography>
              </Box>
            }
            title="Error Saving Event"
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default GlobalSaveErrorDialog;
