/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_useUpdateAlgoliaSearchIndexes_event = {
    readonly publishedAt: string | null;
    readonly eventMetadata: {
        readonly isCategoriedEvent: boolean;
    };
    readonly " $refType": "helpers_useUpdateAlgoliaSearchIndexes_event";
};
export type helpers_useUpdateAlgoliaSearchIndexes_event$data = helpers_useUpdateAlgoliaSearchIndexes_event;
export type helpers_useUpdateAlgoliaSearchIndexes_event$key = {
    readonly " $data"?: helpers_useUpdateAlgoliaSearchIndexes_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_useUpdateAlgoliaSearchIndexes_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_useUpdateAlgoliaSearchIndexes_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "eventMetadata",
            "kind": "LinkedField",
            "name": "eventMetadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategoriedEvent",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'b95574faee6edd4b5d7e7e8dd9e18084';
export default node;
