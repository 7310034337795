import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import * as R from 'ramda';

import ENV from '@/env';
import { getTimeZoneFromLatLong } from '@/lib/date-helpers/date-utils';

export interface AddressParts {
  googlePlaceId: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  countryCode: string;
  lat: number;
  lng: number;
}
export async function getAddressParts(
  address: string,
  placeId?: string,
): Promise<AddressParts | undefined> {
  try {
    const addressParts: AddressParts = {
      googlePlaceId: '',
      address: '',
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      countryCode: '',
      lat: 0,
      lng: 0,
    };

    const [result] = placeId ? await geocodeByPlaceId(placeId) : await geocodeByAddress(address);
    const { address_components, geometry, place_id } = result;
    addressParts.googlePlaceId = place_id;
    addressParts.lat = geometry.location.lat() as number;
    addressParts.lng = geometry.location.lng() as number;

    let streetNumber = '';
    let street = '';
    address_components.forEach((ac) => {
      if (ac.types.includes('street_number')) {
        streetNumber = `${ac.long_name} `;
      } else if (ac.types.includes('route')) {
        street = ac.long_name;
      } else if (ac.types.includes('locality')) {
        addressParts.city = ac.long_name;
      } else if (ac.types.includes('administrative_area_level_1')) {
        addressParts.state = ac.long_name;
      } else if (ac.types.includes('postal_code')) {
        addressParts.zipCode = ac.long_name;
      } else if (ac.types.includes('country')) {
        addressParts.country = ac.long_name;
        addressParts.countryCode = ac.short_name;
      } else if (ac.types.includes('administrative_area_level_1')) {
        addressParts.state = ac.long_name;
      }
    });
    if (streetNumber.length > 0 && street.length > 0) {
      const addressLine1 = `${streetNumber}${street}`;
      addressParts.addressLine1 = addressLine1;
    }
    addressParts.address = address;

    addressParts.lat = geometry.location.lat();
    addressParts.lng = geometry.location.lng();

    return addressParts;
  } catch (err) {
    return undefined;
  }
}

/**
 * Add timezone info to address parts
 */
export async function addTimezoneInfoToAddressParts(
  addressParts: AddressParts,
): Promise<AddressParts & { timezone: string }> {
  const timezoneInfo = await getTimeZoneFromLatLong(addressParts.lat, addressParts.lng);
  return {
    ...addressParts,
    country: addressParts.country ?? timezoneInfo.countryName,
    countryCode: addressParts.countryCode ?? timezoneInfo.countryCode,
    timezone: timezoneInfo.zoneName,
  };
}

/**
 * Convert addressParts to venue structure
 */
export const addressPartsToVenue = (
  addressParts: AddressParts,
  id?: string,
): {
  readonly placeId: string;
  readonly lat: number;
  readonly lng: number;
  readonly fullAddress: string;
  readonly address: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly country: string | null;
  readonly countryCode: string | null;
  readonly postalCode: string | null;
} => {
  return {
    ...R.omit(['googlePlaceId', 'addressLine1', 'addressLine2'], addressParts),
    ...(id ? { id } : {}),
    placeId: addressParts.googlePlaceId,
    fullAddress: addressParts.address,
    address: addressParts.addressLine1,
    postalCode: addressParts.zipCode,
  };
};

/**
 * Get a Google Maps Embed url with a custom query
 */
export const getGoogleMapsEmbedUrl = (query: string): string => {
  return `https://www.google.com/maps/embed/v1/place?key=${ENV.GOOGLE_MAPS_JS_API_KEY}&q=${query}`;
};

/**
 * Convert a venue location to readable text
 */
export const getVenueLocationText = (
  venue:
    | {
        city: string | undefined | null;
        state: string | undefined | null;
        country: string | null;
        countryCode?: string | undefined | null;
      }
    | undefined,
): string => {
  if (!venue) {
    return '';
  }
  return `${venue?.city ? `${venue.city}, ` : ''}${venue?.state ? `${venue.state}, ` : ''}${
    venue.countryCode || venue.country || ''
  }`;
};
