import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Footer from '@/components/Footer';

import MainHeader from '@/containers/MainHeader/MainHeader';
import { ReactFCC } from '@/lib/type-defs/utility';

export interface IMainLayout {
  showFooter?: boolean;
  stickyHeader?: boolean;
}

export const useMainLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.pageBackground.white,
  },
  content: {
    alignItems: 'stretch',
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'center',
  },
  footer: {
    backgroundColor: 'white',
    flexShrink: 0,
    zIndex: theme.zIndex.appBar,
  },
}));

const MainLayout: ReactFCC<IMainLayout> = ({
  children,
  showFooter = true,
  stickyHeader = true,
}) => {
  const classes = useMainLayoutStyles();
  return (
    <Box className={classes.root}>
      <MainHeader shouldUsePositionRelativeHeader={!stickyHeader} />
      <div className={classes.content}>{children}</div>
      <div className={classes.footer}>{showFooter && <Footer />}</div>
    </Box>
  );
};

export default MainLayout;
