import { useNavigate } from 'react-router-dom';
import queryString, { StringifyOptions } from 'query-string';
import * as R from 'ramda';

import { AppRouteService } from '@/routes/RouteService';

/**
 * Make sure the url starts with https
 */
export const getValidUrl = (url: string | null | undefined): string => {
  if (!url) {
    return '';
  }
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');
  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }
  return newUrl;
};

/**
 * Open a link in a new tab
 */
export const openLinkInNewTab = (link: string): void => {
  const win = window.open(link, '_blank');
  if (win) {
    // Browser has allowed it to be opened
    win.focus();
  } else {
    // Browser has blocked it
    // eslint-disable-next-line no-alert
    window.alert('Please allow popups for this website so we can open a preview of your event.');
  }
};

/**
 * Set a new query parameter in the url
 */
export type QueryType =
  | string
  | number
  | Array<unknown>
  | Record<string, unknown>
  | boolean
  | null
  | undefined;
export type QueryObjectType = Record<string, QueryType>;
export const useSetQueryParams = (): ((
  queryObject: QueryObjectType,
  replace?: boolean,
) => void) => {
  const navigate = useNavigate();
  return (queryObject: QueryObjectType, replace = true): void => {
    const params = getParsedQueryParams();
    const newParams = {
      ...params,
      ...queryObject,
    };
    const newQueryString = stringifyQueryParams(newParams);
    const modifiedUrl = `${window.location.pathname}?${newQueryString}`;
    navigate(modifiedUrl, { replace, state: { keepScrollPosition: true } });
  };
};

/**
 * Clear all query parameters in the url
 */
export const useClearQueryParams = (): ((replace?: boolean) => void) => {
  const navigate = useNavigate();
  return (replace = true): void => {
    navigate(window.location.pathname, { replace, state: { keepScrollPosition: true } });
  };
};

/**
 * Stringify the query parameters
 */
export const parseQueryParams = (stringifiedParams: string): QueryObjectType => {
  const parsedParams = queryString.parse(stringifiedParams, {
    parseBooleans: true,
    arrayFormat: 'comma',
  });
  return { ...(parsedParams ?? {}) };
};

/**
 * Stringify the query parameters
 */
export const stringifyQueryParams = (
  object: QueryObjectType,
  options?: StringifyOptions,
): string => {
  const existingOptions = options || {};
  return queryString.stringify(object, { arrayFormat: 'comma', ...existingOptions });
};

/**
 * Get the current query parameters
 */
export const getParsedQueryParams = (): QueryObjectType => {
  return parseQueryParams(window.location.search);
};

/**
 * Add a query parameter to a url that optionally has  existing query parameters
 */
export const addQueryParamsToUrl = (
  url: string,
  newParams: Record<
    string,
    string | number | Array<unknown> | Record<string, unknown> | boolean | null | undefined
  >,
): string => {
  const urlParts = url.split('?');
  if (urlParts.length === 1) {
    return `${url}?${stringifyQueryParams(newParams)}`;
  }
  const existingParams = parseQueryParams(urlParts[1]);
  const newQueryString = stringifyQueryParams({ ...existingParams, ...newParams });
  return `${urlParts[0]}?${newQueryString}`;
};

/**
 * Omit a query parameter from a url
 */
export const omitQueryParamFromUrl = (url: string, paramToOmit: string): string => {
  const urlParts = url.split('?');
  if (urlParts.length === 1) {
    return url;
  }
  const existingParams = parseQueryParams(urlParts[1]);
  const newQueryString = stringifyQueryParams(R.omit([paramToOmit], existingParams));
  return `${urlParts[0]}?${newQueryString}`;
};

/**
 * Remove domain from url
 */
export const removeDomainFromUrl = (url: string): string => {
  const domain = AppRouteService.getDomain();
  return url.replace(domain, '');
};
