/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewServiceQueries_getRecentLessonsCoaches_QueryVariables = {
    twoWeeksAgoDate: string;
    userId: string;
};
export type ReviewServiceQueries_getRecentLessonsCoaches_QueryResponse = {
    readonly coachCustomerLessonDate_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly startDate: string;
                readonly coachCustomerLesson: {
                    readonly coachCuid: string;
                    readonly coachLesson: {
                        readonly cuid: string;
                        readonly title: string;
                    } | null;
                    readonly coach: {
                        readonly user: {
                            readonly firstName: string | null;
                            readonly lastName: string | null;
                            readonly profilePicUrl: string | null;
                        };
                    };
                };
            };
        }>;
    };
};
export type ReviewServiceQueries_getRecentLessonsCoaches_Query = {
    readonly response: ReviewServiceQueries_getRecentLessonsCoaches_QueryResponse;
    readonly variables: ReviewServiceQueries_getRecentLessonsCoaches_QueryVariables;
};



/*
query ReviewServiceQueries_getRecentLessonsCoaches_Query(
  $twoWeeksAgoDate: timestamp!
  $userId: String!
) {
  coachCustomerLessonDate_connection(where: {canceledDate: {_is_null: true}, startDate: {_gte: $twoWeeksAgoDate}, coachCustomerLesson: {coachCustomer: {coachCustomerUsers: {userId: {_eq: $userId}}}}}) {
    edges {
      node {
        id
        startDate
        coachCustomerLesson {
          coachCuid
          coachLesson {
            cuid
            title
            id
          }
          coach {
            user {
              firstName
              lastName
              profilePicUrl
              id
            }
            id
          }
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "twoWeeksAgoDate"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "kind": "Literal",
                    "name": "canceledDate",
                    "value": {
                        "_is_null": true
                    }
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_eq",
                                                    "variableName": "userId"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "userId"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "coachCustomerUsers"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "coachCustomer"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "coachCustomerLesson"
                },
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_gte",
                            "variableName": "twoWeeksAgoDate"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "startDate"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coachCuid",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profilePicUrl",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_getRecentLessonsCoaches_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachCustomerLessonDateConnection",
                    "kind": "LinkedField",
                    "name": "coachCustomerLessonDate_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachCustomerLessonDateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coachCustomerLessonDate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "coachCustomerLesson",
                                            "kind": "LinkedField",
                                            "name": "coachCustomerLesson",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "coachLesson",
                                                    "kind": "LinkedField",
                                                    "name": "coachLesson",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "coach",
                                                    "kind": "LinkedField",
                                                    "name": "coach",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "user",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v9 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ReviewServiceQueries_getRecentLessonsCoaches_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachCustomerLessonDateConnection",
                    "kind": "LinkedField",
                    "name": "coachCustomerLessonDate_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachCustomerLessonDateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coachCustomerLessonDate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "coachCustomerLesson",
                                            "kind": "LinkedField",
                                            "name": "coachCustomerLesson",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "coachLesson",
                                                    "kind": "LinkedField",
                                                    "name": "coachLesson",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "coach",
                                                    "kind": "LinkedField",
                                                    "name": "coach",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "user",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b629028ef473451d55dc7c5e5d7ca0bf",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_getRecentLessonsCoaches_Query",
            "operationKind": "query",
            "text": "query ReviewServiceQueries_getRecentLessonsCoaches_Query(\n  $twoWeeksAgoDate: timestamp!\n  $userId: String!\n) {\n  coachCustomerLessonDate_connection(where: {canceledDate: {_is_null: true}, startDate: {_gte: $twoWeeksAgoDate}, coachCustomerLesson: {coachCustomer: {coachCustomerUsers: {userId: {_eq: $userId}}}}}) {\n    edges {\n      node {\n        id\n        startDate\n        coachCustomerLesson {\n          coachCuid\n          coachLesson {\n            cuid\n            title\n            id\n          }\n          coach {\n            user {\n              firstName\n              lastName\n              profilePicUrl\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd7f7fe7a0584f566e87eccf1d7f83cb0';
export default node;
