/*
Helper functions for the validity checker
 */

import { HostEventSectionName } from '@/containers/HostEvent/const';
import { ValidityErrorProps } from '@/containers/HostEvent/validityChecker/const';
import { useGetValidityInfoGlobal } from '@/containers/HostEvent/validityChecker/recoilStore';

/**
 * Check to see if the current section has any errors
 */
export const useGetErrorsForSection = (sectionName: HostEventSectionName): ValidityErrorProps[] => {
  const validityInfoGlobal = useGetValidityInfoGlobal();
  const sectionErrors: ValidityErrorProps[] = [];
  Object.keys(validityInfoGlobal).forEach((topicName) => {
    Object.keys(validityInfoGlobal[topicName]).forEach((ruleName) => {
      if (
        // Check for false because null means we are still initializing
        validityInfoGlobal[topicName][ruleName].isValid === false &&
        validityInfoGlobal[topicName][ruleName].sectionNames.includes(sectionName)
      ) {
        sectionErrors.push(validityInfoGlobal[topicName][ruleName]);
      }
    });
  });
  return sectionErrors;
};
