/**
 * React router link that we can use to wrap components without the outline
 */
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  underline: {
    textDecoration: 'none !important',
  },
  grey: {
    color: '#666666 !important',
    '&:hover': {
      color: '#999999 !important',
    },
  },
  noColor: {
    color: 'inherit',
    '&:visited, &:active, &:hover': {
      color: 'inherit',
    },
  },
}));

const NoUnderlineRouterLink: React.FC<LinkProps & { noColor?: boolean; grey?: boolean }> = ({
  children,
  className,
  grey,
  noColor,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Link
      {...rest}
      className={classNames(
        { [classes.noColor]: noColor },
        { [classes.grey]: grey },
        classes.underline,
        className,
      )}
    >
      {children}
    </Link>
  );
};

export default NoUnderlineRouterLink;
