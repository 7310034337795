/*
Recoil atoms, selectors and hooks
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

/** ************************************
 * Global Event Profile Properties
 ************************************* */

/**
 *  What occurrence are we displaying?
 */
const occurrenceToDisplay = atom<string>({
  key: 'occurrenceToDisplay', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});
export const useGetOccurrenceToDisplayGlobal = (): string => {
  return useRecoilValue(occurrenceToDisplay);
};
export const useSetOccurrenceToDisplayGlobal = (): SetterOrUpdater<string> => {
  return useSetRecoilState(occurrenceToDisplay);
};

/**
 *  What categories do we want to register for?
 */
const categoryCuidsToRegisterState = atom<string[]>({
  key: 'categoryCuidsToRegisterState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  // effects_UNSTABLE: (userID) => [ historyEffect(`${userID} user info`),],
});
export const useGetCategoryCuidsToRegisterGlobal = (): string[] => {
  return useRecoilValue(categoryCuidsToRegisterState);
};
export const useSetCategoryCuidsToRegisterGlobal = (): SetterOrUpdater<string[]> => {
  return useSetRecoilState(categoryCuidsToRegisterState);
};

/**
 *  What tickets do we want to buy?
 */
const ticketCuidsToRegisterState = atom<string[]>({
  key: 'ticketCuidsToRegisterState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  // effects_UNSTABLE: (userID) => [ historyEffect(`${userID} user info`),],
});
export const useGetTicketCuidsToRegisterGlobal = (): string[] => {
  return useRecoilValue(ticketCuidsToRegisterState);
};
export const useSetTicketCuidsToRegisterGlobal = (): SetterOrUpdater<string[]> => {
  return useSetRecoilState(ticketCuidsToRegisterState);
};

/**
 * What categories are available after we have refined them?
 */
const refinedCategoryCuidsState = atom<string[] | null>({
  key: 'refinedCategoryCuidsState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const useGetRefinedCategoryCuidsGlobal = (): string[] | null => {
  return useRecoilValue(refinedCategoryCuidsState);
};
export const useSetRefinedCategoryCuidsGlobal = (): SetterOrUpdater<string[] | null> => {
  return useSetRecoilState(refinedCategoryCuidsState);
};

/**
 * Are we currently refining by anything?
 */
const isRefiningState = atom<boolean>({
  key: 'isRefiningState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsRefiningGlobal = (): boolean => {
  return useRecoilValue(isRefiningState);
};
export const useSetIsRefiningGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isRefiningState);
};

/** ************************************
 * Global Public UI Properties
 * i.e. Is a modal open
 ************************************* */
/**
 * Is the "See More Dates" modal open?
 */
const isSeeDatesDialogOpen = atom<boolean>({
  key: 'isSeeDatesDialogOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsSeeDatesDialogOpenGlobal = (): boolean => {
  return useRecoilValue(isSeeDatesDialogOpen);
};
export const useSetIsSeeDatesDialogOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isSeeDatesDialogOpen);
};
/**
 * Is the "View Participants" modal open?
 */
const isViewParticipantsDialogOpen = atom<string | null | boolean>({
  key: 'isViewParticipantsDialogOpen', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const useGetIsViewParticipantsDialogOpenGlobal = (): string | null | boolean => {
  return useRecoilValue(isViewParticipantsDialogOpen);
};
export const useSetIsViewParticipantsDialogOpenGlobal = (): SetterOrUpdater<string | null | boolean> => {
  return useSetRecoilState(isViewParticipantsDialogOpen);
};
