/**
 * Public Cyclist page
 */
import React from 'react';

import Page from '../Page';

import MainLayout from '@/layouts/MainLayout';

const CyclistProfilePage: React.FC = ({ children }) => {
  return (
    <Page description="A cyclist on Reggy" title="Cyclist • Reggy">
      <MainLayout stickyHeader={false}>{children}</MainLayout>
    </Page>
  );
};

export default CyclistProfilePage;
