import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import FadeComponent from '@/hoc/FadeComponent';

import { useCourierContext } from '@/providers/CourierProvider';

const ICON_HEIGHT = '14px';
const LINE_WIDTH = '28px';
const LINE_HEIGHT = '2px';
const TRANSITION_TIME = '400ms';
const ROTATION = '-45deg';
const TRANSLATE_Y = `calc(${ICON_HEIGHT} / 2)`;
const TRANSLATE_X = '0';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: LINE_WIDTH,
    height: '20px',
    position: 'relative',
    display: 'block',
    // marginLeft: theme.spacing(3),
    transition: `transform ${TRANSITION_TIME}`,
    '& .line': {
      display: 'block',
      background: theme.palette.grey[700],
      width: LINE_WIDTH,
      height: LINE_HEIGHT,
      position: 'absolute',
      left: '0',
      borderRadius: `calc(${LINE_HEIGHT} / 2)`,
      '&.line-1': {
        transition: `all ${TRANSITION_TIME}`,
        top: '0',
      },
      '&.line-2': {
        transition: `all ${TRANSITION_TIME}`,
        top: 'calc(50% - 3px)',
      },
      '&.line-3': {
        transition: `all ${TRANSITION_TIME}`,
        top: 'calc(100% - 6px)',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:not(.active):hover, &:not(.active):focus': {
      [theme.breakpoints.up('sm')]: {
        '& .line-1': {
          transform: `translateY(calc(${LINE_HEIGHT} / 2 * -1))`,
        },
        '& .line-3': {
          transform: `translateY(calc(${LINE_HEIGHT} / 2))`,
        },
      },
    },
    '&.active': {
      transform: 'translateY(8px)',
      '& .line-1': {
        transform: `translateY(${TRANSLATE_Y}) translateX(${TRANSLATE_X}) rotate(${ROTATION})`,
      },
      '& .line-2': {
        opacity: 0,
      },
      '& .line-3': {
        transform: `translateY(calc(${TRANSLATE_Y} * -1)) translateX(${TRANSLATE_X}) rotate(calc(${ROTATION} * -1))`,
      },
    },
  },
  menuText: {
    ...theme.typography.caption,
    fontSize: '10px',
    textAlign: 'center',
  },
  menuTextFade: {
    position: 'absolute',
    bottom: 0,
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 34,
    position: 'relative',
    cursor: 'pointer',
  },
}));

interface HamburgerProps extends BoxProps {
  isOpen: boolean;
  onHamburgerClicked(): void;
}
const HamburgerButton: React.FC<HamburgerProps> = ({ className, isOpen, onHamburgerClicked }) => {
  const classes = useStyles();
  const { isInitiated } = useCourierContext();

  return (
    <Box ml="auto" mr={0}>
      <Box
        className={classes.menuContainer}
        ml={{ xs: isInitiated ? 2.5 : 2.5, sm: 3.5 }}
        onClick={onHamburgerClicked}
      >
        <Box className={classnames(className, classes.root, { active: isOpen })}>
          <span className="line line-1" />
          <span className="line line-2" />
          <span className="line line-3" />
        </Box>
        <FadeComponent className={classes.menuTextFade} duration={200} shouldFade={!isOpen}>
          <Typography className={classes.menuText}>Menu</Typography>
        </FadeComponent>
      </Box>
    </Box>
  );
};

export default HamburgerButton;
