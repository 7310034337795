import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export {  fade, darken } from '@material-ui/core/styles/';

// Color Tool
// ------
// https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=F57C00&primary.color=04759C

// IMPORTANT: Make sure to update volunteerTaskPriorities and volunteerTaskDifficulties in the
// database as there are hard coded colors there

// Old Colors
// export enum Colors {
//   // Orange = '#f57c00',
//   Orange = '#f79a59',
//   Blue = '#04759C',
//   DarkBlue = '#004650',
//   LightBlue = 'rgb(29, 161, 242)',
//   DarkPurple = '#333e63',
//   DarkText = '#262626',
//   DarkPurpleDesaturated = '#323a45',
//   Green = '#43a047', // success
//   // Green = '#049c76', // success
//   Green2 = '#03A877',
//   Yellow = '#fddc6c', // warning
//   Yellow2 = '#F5B811', // warning
//   Red = '#cf2629', // failure  // Old. Too bright db383b
//   Black = '#161C22',
//   White = '#FFFFFF',
//   Inherit = 'inherit',
//   LightBorder = '1px solid rgba(0, 0, 0, 0.12)',
//   DashboardBackground = '#eef2f7',
// }

// "Enterprisey" colors (Before Lucy)
// export enum Colors {
//   // Orange = '#f57c00',
//   Brand = '#04759c',
//   // Orange = '#f78055', // Original
//   Orange = '#E9815C', // Lucy
//   Blue = '#427583',
//   Turquoise = '#46CEB5',
//   DarkBlue = '#004650',
//   RingCounterBlue = 'rgb(29, 161, 242)',
//   DarkText = '#262626',
//   DarkPurpleDesaturated = '#323a45',
//   Green = '#41835F',
//   Yellow = '#f1ba29', // warning
//   Red = '#c05e59', // failure
//   Purple = '#ac39a1',
//   DarkPurple = '#333e63',
//   Black = '#161C22',
//   White = '#FFFFFF',
//   Inherit = 'inherit',
//   TransparentBorder = '1px solid transparent',
//   // LightBorder = '1px solid rgba(0, 0, 0, 0.12)',
//   // LightBorderColor = 'rgba(0, 0, 0, 0.12)',
//   LightBorder = '1px solid #D8D8D8',
//   LightBorderBlue = '1px solid rgb(219, 222, 225)',
//   LightBorderColor = '#D8D8D8',
//   DashboardBackground = '#eef2f7',
//   Transparent = 'rgba(0,0,0,0)',
//   TextColor = '#262626',
//   Gold = '#C9B037',
//   Silver = '#a7a7a7',
//   Bronze = '#AD8A56',
//
//   OffWhite = '#FEFFFE',
//   AliceBlue = '#F2F7F9',
//   LightBlue = '#36b9be',
// }

// Lucy Colors
export enum Colors {
  Orange = '#e9815c',
  LightBlue = '#36b9be',
  Blue = '#4da1b8',
  DarkBlue = '#004650',
  Turquoise = '#46CEB5',
  RingCounterBlue = 'rgb(29, 161, 242)',
  DarkText = '#262626',
  DarkPurpleDesaturated = '#323a45',
  Green = '#46fcb5',
  Yellow = '#f1ba29', // warning
  Red = '#B41B11', // failure
  // Red = '#D75B5B', // failure
  Purple = '#3f3c56',
  DarkPurple = '#333e63',
  GunPowder = '#3F3C56',
  Black = '#161C22',
  White = '#FFFFFF',
  Inherit = 'inherit',
  Transparent = 'rgba(0,0,0,0)',

  Info = '#1179B4',
  InfoBackground = '#E4EFF2',
  Danger = '#B41B11',
  DangerBackground = '#F2E8E4',
  Warning = '#B48611',
  WarningBackground = '#F2F2E4',
  Success = '#1EA144',
  SuccessBackground = '#E4F2E8',

  LightBorder = '1px solid #D8D8D8',
  LightBorderBlue = '1px solid rgb(219, 222, 225)',
  LightBorderColor = '#D8D8D8',
  TransparentBorder = '1px solid transparent',
  DashboardBackground = '#F2F7F9',

  // Brand = '#04759C',
  Brand = '#4da1b8',
  TextColor = '#262626',
  LightTextColor = '#898989',
  Gold = '#C9B037',
  Silver = '#a7a7a7',
  Bronze = '#AD8A56',

  OffWhite = '#FEFFFE',
  AliceBlue = '#F2F7F9',
}

export enum StatusColors {
  Info = Colors.Info,
  Success = Colors.Success,
  Warning = Colors.Yellow,
  Error = Colors.Danger,
}

export enum StatusColorNames {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export const namesOfColors = {
  [Colors.Orange]: 'Orange',
  [Colors.Blue]: 'Blue',
  [Colors.Green]: 'Green',
  [Colors.Yellow]: 'Yellow',
  [Colors.Black]: 'Black',
  [Colors.White]: 'White',
  [Colors.Inherit]: 'N/A',
};

// BLue Chip
// height: 28px;
//     background: rgba(86,151,233,0.15);
//     /* background-color: #daf6ff; */
//     /* margin: 8px auto auto; */
//     color: #333e63;
//     /* color: #04759C; */
//     max-width: 100%;
//     /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .2), 0 2px 4px -1px rgba(0, 0, 0, .26); */
//     border-radius: 4px;
//     font-weight: 600;
//     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, .06);

// Green Chip
//     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .01), 0 2px 4px -1px rgba(0, 0, 0, .06);
//     color: #02a877;
//     font-weight: 500;
//     background-color: rgba(2, 168, 119, 0.17);

// Blue
//     box-shadow: 0 4px 6px -1px rgba(4, 116, 156, 0.12862745098039217), 0 2px 4px -1px rgba(4, 50, 70, 0.12862745098039217);
//     border-radius: 4px;
//     color: #04749c;
//     background-color: rgba(4, 116, 156, 0.16862745098039217);
//     color: rgb(216, 232, 239);

// Maybe box?
//     box-shadow: 0 4px 6px -1px hsla(195, 26%, 41%, 0.38), 0 2px 4px -1px hsla(195, 26%, 18%, 0.3);
const primary = {
  main: Colors.DarkBlue,
};

const secondary = {
  main: Colors.DarkBlue,
};

const error = {
  main: Colors.Danger,
};

export const palette: PaletteOptions = {
  primary,
  secondary,
  error,
  // text: { primary: '#3B4144' },
  text: { primary: Colors.TextColor },
  background: {
    default: '#f6f8f9',
  },
  common: { black: Colors.Black, white: Colors.White },
  grey: {
    50: '#f6f8f9',
    100: '#fbfbfb',
    200: '#edeff0',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#898989',
    600: '#6c7174', // #657786
    700: '#54575a',
    800: '#495057',
    900: '#3b4144',
    A100: '#f7fafc',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
    // Maybe?
    // Grey1 = '#2F3B43',
    // Grey2 = '#54575a',
    // Grey3 = '#8A939C',
    // Grey4 = '#D9DCDF',
    // Grey5 = '#EAEDEF',
    // Grey6 = '#F6F7F8',
  },
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 3, // Default 3
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.1, // Default 0.2
  pageBackground: {
    white: Colors.OffWhite,
    grey: Colors.AliceBlue,
  },
};
