import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { Colors } from '@/themes/colors';

// Use this places like the login. "Already have an account?"
export const useLightGreyHelperText = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
  },
  negativeBottomMargin: {
    marginBottom: -theme.spacing(2),
  },
}));

// Make an typography have the link properties
export const useLinkText = makeStyles({
  root: {
    color: Colors.Brand,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#04688e',
    },
  },
});
