/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getOrganizerCuid_event = {
    readonly organizerCuid: string;
    readonly " $refType": "helpers_getOrganizerCuid_event";
};
export type helpers_getOrganizerCuid_event$data = helpers_getOrganizerCuid_event;
export type helpers_getOrganizerCuid_event$key = {
    readonly " $data"?: helpers_getOrganizerCuid_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getOrganizerCuid_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getOrganizerCuid_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizerCuid",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '46f618026729a6b828c8229154873bb1';
export default node;
