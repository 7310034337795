/*
Site-wide Constants
 */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore No types for bad-words
import Filter from 'bad-words';

import { applyCarouselCloudinaryTransform } from '@/lib/cloudinary';
import { ONE_HOUR_IN_SECONDS, ONE_YEAR_IN_WEEKS } from '@/lib/date-helpers/date-utils';

export const TYPEFORM_CONTACT_US_FORM_ID = 'xFQNduYI';

// Airtable
export const AIRTABLE_SUGGEST_FEATURE_URL = 'https://airtable.com/shrsy1gNrlvHAfxZy';

// Social Links
export const FACEBOOK_SOCIAL_LINK = 'https://www.facebook.com/goreggy1/';
export const INSTAGRAM_SOCIAL_LINK = 'https://www.instagram.com/goreggy/';
export const TWITTER_SOCIAL_LINK = 'https://twitter.com/goreggy1';

export const profanityFilter = new Filter({ exclude: ['hells', 'hell', 'ass', 'badass'] });
// profanityFilter.removeWords('hells', 'hell')

// How long to wait before we show a loading spinner
export const DEFAULT_LOADING_SPINNER_DELAY = 150;

// Debounce
export const ALGOLIA_AUTOCOMPLETE_DEBOUNCE_DELAY = 200;
export const SAVE_MUTATION_DEBOUNCE_DELAY = 1500;

export const HTML_INFINITY_SYMBOL = <>&#8734;</>;
export const STRING_SEPARATOR = '**';
export const NON_EXISTENT_CUID = 'THIS_CUID_WILL_NEVER_EXIST';

// Distance
export const ONE_MILE_IN_METERS = 1609.34;
export const ONE_MILE_IN_KM = 1.60934;

/**
 * Stripe
 */
export enum StripeIdentityStatus {
  // Requires user input before processing can continue.
  RequiresInput = 'requires_input',
  // The session has been submitted and is being processed. Most verification checks take a few minutes to process.
  Processing = 'processing',
  // Processing of all the verification checks are complete and successfully verified.
  Verified = 'verified',
  // The VerificationSession has been invalidated for future submission attempts.
  Canceled = 'canceled',
}

export enum CreatorTypeEnum {
  Coach = 'Coach',
  Organizer = 'Organizer',
}

/**
 * Search
 */
export const SEARCH_PRICE_MIN_MAX = [0, 200];
export const SEARCH_PRICE_STEP = 5;
export const SEARCH_RADIUS_MIN_MAX = [5, 200];
export const SEARCH_RADIUS_STEP = 5;
export enum SearchTypeEnum {
  All = 'All',
  Ride = 'Ride',
  Race = 'Race',
  Clinic = 'Clinic',
  Coach = 'Coach',
  PrivateLesson = 'Private Lesson',
  OnlineCourse = 'Online Course',
}

/**
 * All
 */

export enum PromoTypeEnum {
  PERCENT = 'Percentage',
  AMOUNT = 'Amount',
}
export enum SpeedTypeEnum {
  MPH = 'mph',
  KMH = 'km/h',
}
export enum ReviewTypeEnum {
  Coach = 'Coach',
  Organizer = 'Organizer',
}
export enum DisciplineNameEnum {
  MTB = 'MTB',
  Downhill = 'Downhill',
  Enduro = 'Enduro',
  DualSlalom = 'Dual Slalom',
  DirtJumping = 'Dirt Jumping',
  BMX = 'BMX',
  XC = 'Cross Country (XC)',
  ShortTrack = 'Short Track',
  Gravel = 'Gravel',
  Cyclocross = 'Cyclocross',
  Road = 'Road',
  Criterium = 'Criterium',
  EBike = 'E-Bike',
  TrialsRiding = 'Trials Riding',
}
export enum SponsorTierNameEnum {
  TierOne = 'Tier One',
  TierTwo = 'Tier Two',
  TierThree = 'Tier Three',
}

// Site Images
// export const GUY_JUMPING_ILLUSTRATION =
//   'https://res.cloudinary.com/goreggy/image/upload/v1637542423/site/siteImages/rvui9zic31n72q0gk4l4.png';
export const STICK_FIGURE_BIKE_MOUNTAINS =
  'https://res.cloudinary.com/goreggy/image/upload/v1627018826/site/siteImages/zfo7b8hq41rzyrwh9oou.jpg';
export const BLACK_GUY_ON_BIKE_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1627019221/site/siteImages/ywbdmspwiruwb68ztbfu.jpg';
export const MOUNTAIN_BIKE_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1629716438/site/siteImages/jkzb4upxcgvmz7dma0n5.png';
export const GIRL_ON_CALENDAR_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1629725488/site/siteImages/aabpb2tzk2oknmw2dm7x.jpg';
export const WORK_FROM_HOME_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1629729999/site/siteImages/vpdgq7jsc6adcjmdps0q.jpg';
export const RECORD_VIDEO_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1638261181/site/siteImages/h7hya3lebkpkzla0dmma.jpg';
export const VIDEO_INTRODUCTION_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1638261800/site/siteImages/l2scvthyx7vaca5q7gzi.webp';
// Default Images
export const REGGY_IMAGE_RELATIVE_URL = '/v1607740451/site/siteImages/f4jypxpkzz4ezfmc3qcm.png';
export const REGGY_IMAGE = applyCarouselCloudinaryTransform(
  '/v1607740451/site/siteImages/f4jypxpkzz4ezfmc3qcm.png',
);
export const DEFAULT_PROFILE_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1640576488/site/siteImages/hojfhri80mp63k9vjar1.jpg';
// 'https://res.cloudinary.com/goreggy/image/upload/v1633988212/site/startup/oxzjyfyybal5sly9mlpr.svg'; // Rocket
export const DEFAULT_COACH_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1626981013/site/siteImages/uqckwaspe6bnwdexoy9m.png';
// 'https://res.cloudinary.com/goreggy/image/upload/v1635058383/site/siteImages/pf6wcpwutb9qg6zt1iex.webp'; // Trail
export const DEFAULT_EVENT_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1605494962/egooenxaqt8hdubxhx3l.svg';
export const DEFAULT_SERIES_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1643776103/site/siteImages/wlsuoev9inkloid2k6ar.webp';
export const DEFAULT_COURSE_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1645336794/site/siteImages/hcbc7ngkj4eiwugd7qqf.jpg';
export const DEFAULT_ONLINE_COURSE_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1651989586/site/siteImages/slmosy0leh4ldkrfyphp.jpg';
export const DEFAULT_COACH_VIDEO_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1638324030/site/siteImages/lhje8rsadp2xhttvkt5p.jpg';
// Blotch Images
export const BLOTCH_IMAGE_1 =
  'https://res.cloudinary.com/goreggy/image/upload/v1652160539/site/siteImages/uqbvpnhosmuyirp4fxlr.jpg';
export const BLOTCH_IMAGE_2 =
  'https://res.cloudinary.com/goreggy/image/upload/v1652160222/site/siteImages/ycljxxedqfqt5pfpzn0t.jpg';
export const BLOTCH_IMAGE_3 =
  'https://res.cloudinary.com/goreggy/image/upload/v1652157705/site/siteImages/gglwlwb1kjtduj9tyelv.jpg';

/**
 * MinMaxs
 * IMPORTANT: MinMax also are in cloudFunction. If you edit here. Edit there
 */
export const AGE_MIN_MAX = [0, 100];
export const AGE_STEP = 1;
export const MINOR_AGE = 18;
export const ABILITY_LEVEL_MIN_MAX = [1, 4];
export const ABILITY_LEVEL_STEP = 1;
export const INTENSITY_MIN_MAX = [1, 4];
export const INTENSITY_STEP = 1;
export const AVERAGE_SPEED_MIN_MAX = [1, 50];
export const AVERAGE_SPEED_STEP = 1;
export const RIDING_TIME_PER_DAY_MIN_MAX = [0.5, 24];
export const RIDING_TIME_PER_DAY_STEP = 0.25;
export const PARTICIPANTS_MIN_MAX = [1, 25];
export const PARTICIPANTS_STEP = 1;
/// /////////////////////////////////////////////////////////

// Schedule. These are hardcoded schedule presets in the db that we are referencing on the frontend
export const SCHEDULE_PRESET_EVENT_START_CUID = 'ckh1j5o9x000q3h6akhcei1zq';
export const SCHEDULE_PRESET_EVENT_END_CUID = 'ckh1j5o9x000r3h6aw8uo3s5n';
export const SCHEDULE_PRESET_ACTIVITY_ALL = 'All';
/**
 * Anytime we need a list of hours.
 */
export const scheduleHours: number[] = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

/**
 * Hours of the day
 * We use 23:59:59 to so we don't have to go to the next day.
 */
export const hoursOfTheDay: Record<string, number> = {
  '12am': 0,
  '1am': ONE_HOUR_IN_SECONDS,
  '2am': ONE_HOUR_IN_SECONDS * 2,
  '3am': ONE_HOUR_IN_SECONDS * 3,
  '4am': ONE_HOUR_IN_SECONDS * 4,
  '5am': ONE_HOUR_IN_SECONDS * 5,
  '6am': ONE_HOUR_IN_SECONDS * 6,
  '7am': ONE_HOUR_IN_SECONDS * 7,
  '8am': ONE_HOUR_IN_SECONDS * 8,
  '9am': ONE_HOUR_IN_SECONDS * 9,
  '10am': ONE_HOUR_IN_SECONDS * 10,
  '11am': ONE_HOUR_IN_SECONDS * 11,
  '12pm': ONE_HOUR_IN_SECONDS * 12,
  '1pm': ONE_HOUR_IN_SECONDS * 13,
  '2pm': ONE_HOUR_IN_SECONDS * 14,
  '3pm': ONE_HOUR_IN_SECONDS * 15,
  '4pm': ONE_HOUR_IN_SECONDS * 16,
  '5pm': ONE_HOUR_IN_SECONDS * 17,
  '6pm': ONE_HOUR_IN_SECONDS * 18,
  '7pm': ONE_HOUR_IN_SECONDS * 19,
  '8pm': ONE_HOUR_IN_SECONDS * 20,
  '9pm': ONE_HOUR_IN_SECONDS * 21,
  '10pm': ONE_HOUR_IN_SECONDS * 22,
  '11pm': ONE_HOUR_IN_SECONDS * 23,
  '11:59pm': ONE_HOUR_IN_SECONDS * 24 - 1, // 23:59:59
};
/**
 * Long form hours in the day
 * 12:00 pm instead of 12pm
 */
export const longHoursOfTheDay = Object.fromEntries(
  Object.entries(hoursOfTheDay).map(([key, value]) => {
    // Get the new key name
    const splitKey = key.split(/(?=[ap])/);
    // Add :00 to everything but 11:59
    const newKey = key.includes(':')
      ? `${splitKey[0]} ${splitKey[1]}`
      : `${splitKey[0]}:00 ${splitKey[1]}`;
    return [newKey, value];
  }),
);

/**
 * Moderation
 */
export enum FlagForModerationReasonEnum {
  SpamScam = 'Spam or Scam',
  Inappropriate = 'Inappropriate Content',
  FakeImposter = 'Fake or Imposter',
  Duplicate = 'Duplicate',
  LowQuality = 'Low Quality',
  Other = 'Other',
}
export enum FlagForModerationPriorityEnum {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

/**
 * Organizer
 */
export enum OrganizerInviteStatusEnum {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Accepted = 'Accepted',
}

/**
 * All
 */
export enum LanguagesEnum {
  English = 'English',
  Spanish = 'Spanish',
  French = 'French',
  German = 'German',
  Italian = 'Italian',
  Portuguese = 'Portuguese',
  Chinese = 'Chinese',
}
export enum GenderEnum {
  Open = 'Open',
  Women = 'Women',
  Men = 'Men',
  NonBinary = 'Non-Binary',
}
export enum PronounsEnum {
  SheHer = 'She/Her',
  HeHim = 'He/Him',
  TheyThem = 'They/Them',
}
export enum RegistrationStatusEnum {
  Open = 'Open',
  Paused = 'Paused',
}
export enum EventRecurrenceIntervalEnum {
  OneTime = 'One Time',
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  Monthly = 'Monthly',
}
export const MAX_EVENT_INSTANCE_COUNT = ONE_YEAR_IN_WEEKS;

/**
 * Clinics
 */
export enum ClinicDisciplineNameEnum {
  Photography = 'Photography',
  Videography = 'Videography',
  Mechanic = 'Mechanic',
  TrailBuilding = 'Trail Building',
  Marketing = 'Marketing',
  Business = 'Business',
}
export const AllDisciplineNamesEnum: typeof ClinicDisciplineNameEnum & typeof DisciplineNameEnum = {
  ...DisciplineNameEnum,
  ...ClinicDisciplineNameEnum,
};
export type AllDisciplineNamesEnumIndexer = keyof typeof AllDisciplineNamesEnum;
export enum ClinicSkillsEnum {
  Riding = 'Riding',
  Climbing = 'Climbing',
  Descending = 'Descending',
  HighSpeed = 'High Speed',
  LowSpeed = 'Low Speed',
  Braking = 'Braking',
  Cornering = 'Cornering',
  BodyPosition = 'Body Position',
  LineChoice = 'Line Choice',
  RidingStyle = 'Riding Style',
  Safety = 'Safety',

  OffRoad = 'Off-Road',
  Jumping = 'Jumping',
  Pumping = 'Pumping',
  Berms = 'Berms',
  FlatCorners = 'Flat Corners',
  TechnicalDescending = 'Technical Descending',
  TechnicalRoots = 'Technical Roots',
  TechnicalRocks = 'Technical Rocks',
  TechnicalRuts = 'Technical Ruts',
  TechnicalClimbing = 'Technical Climbing',
  RockGardens = 'Rock Gardens',
  FlowySections = 'Flowy Sections',
  AwkwardSections = 'Awkward Sections',
  BikePark = 'Bike Park',
  DryConditions = 'Dry Conditions',
  WetConditions = 'Wet Conditions',
  Steeze = 'Steeze',

  Equipment = 'Equipment',
  BikeMaintenance = 'Bike Maintenance',
  BikeMechanic = 'Bike Mechanic',
  BikeSetup = 'Bike Setup',
  SuspensionSetup = 'Suspension Setup',
  GearChoice = 'Gear Choice',

  Fitness = 'Fitness',
  PedalEfficiency = 'Pedal Efficiency',
  EnduranceTraining = 'Endurance Training',
  IntervalTraining = 'Interval Training',
  StrengthTraining = 'Strength Training',
  GymTraining = 'Gym Training',
  InjuryRecoveryTraining = 'Injury Recovery Training',
  InjuryPreventionTraining = 'Injury Prevention Training',
  Yoga = 'Yoga',
  Nutrition = 'Nutrition',
  Diet = 'Diet',

  Racing = 'Racing',
  Mindset = 'Mindset',
  Racecraft = 'Racecraft',
  Etiquette = 'Etiquette',

  Film = 'Film',
  Videography = 'Videography',
  Photography = 'Photography',
  Lighting = 'Lighting',
  Drones = 'Drones',
  FilmEquipment = 'Film Equipment',
  PanShots = 'Pan Shots',

  Business = 'Business',
  ProductMarketing = 'Product Marketing',
  SportsMarketing = 'Sports Marketing',
  Hiring = 'Hiring',
  ResumeBuilding = 'Resume Building',
  Sponsorships = 'Sponsorships',

  Other = 'Other',
  Tricks = 'Tricks',
  TrailBuilding = 'Trail Building',
  TrailMaintenance = 'Trail Maintenance',
  ErosionControl = 'Erosion Control',
}
export const SkillSubtitleOptions = [
  ClinicSkillsEnum.Riding,
  ClinicSkillsEnum.OffRoad,
  ClinicSkillsEnum.Equipment,
  ClinicSkillsEnum.Fitness,
  ClinicSkillsEnum.Racing,
  ClinicSkillsEnum.Film,
  ClinicSkillsEnum.Business,
  ClinicSkillsEnum.Other,
];
export enum ClinicFocusEnum {
  Riding = 'Riding',
  Racing = 'Racing',
  Tricks = 'Tricks',
  Fitness = 'Fitness',
}
export const CLINIC_DURATIONS_IN_MINUTES = [
  15,
  30,
  45,
  60,
  75,
  90,
  105,
  120,
  135,
  150,
  165,
  180,
  195,
  210,
  225,
  240,
  255,
  270,
  285,
  300,
  315,
  330,
  345,
  360,
  375,
  390,
  405,
  420,
  435,
  450,
  465,
  480,
  495,
  510,
  525,
  540,
  555,
  570,
  585,
  600,
  615,
  630,
  645,
  660,
  675,
  690,
  705,
  720,
];
type ChecklistNameType =
  | 'Bike'
  | 'Tires'
  | 'Gearing'
  | 'Brakes'
  | 'Helmet'
  | 'Pedals'
  | 'Shoes'
  | 'Pads'
  | 'Gloves'
  | 'Hydration/Water Bottle'
  | 'Food & Snacks'
  | 'Medical'
  | 'Tools & Spares'
  | 'Other';
export type ChecklistType = { name: ChecklistNameType; placeholder: string };
export const ChecklistItems: ChecklistType[] = [
  {
    name: 'Bike',
    placeholder: 'Mountain bike, in good working order, gears and full suspension recommended.',
  },
  {
    name: 'Tires',
    placeholder: 'We recommend a larger tire (38c-40c) for the gravel route',
  },
  {
    name: 'Gearing',
    placeholder:
      'We recommend something like a Shimano GRX crankset, or similar, with a 48/31 tooth chainrings and a 11-34 cassette.',
  },
  {
    name: 'Brakes',
    placeholder: 'Must have 2 hand brakes, no coaster brakes. (no backpedaling to stop)',
  },
  {
    name: 'Helmet',
    placeholder: 'Full-face helmet strongly recommended for gravity and technical skills.',
  },
  {
    name: 'Pedals',
    placeholder: 'No clip in pedals.',
  },
  {
    name: 'Shoes',
    placeholder: 'Closed-toed, flat soled shoes.',
  },
  {
    name: 'Pads',
    placeholder: 'Knee and elbow pads recommended.',
  },
  {
    name: 'Gloves',
    placeholder: 'Closed finger gloves recommended',
  },
  {
    name: 'Hydration/Water Bottle',
    placeholder: 'Bring a bottle. We will be making stops to grab extra water as needed.',
  },
  {
    name: 'Food & Snacks',
    placeholder: 'Pack your own lunch.',
  },
  {
    name: 'Medical',
    placeholder: 'Remember your inhaler, EPI pen, or any other medical devices needed.',
  },
  {
    name: 'Tools & Spares',
    placeholder: 'We will be close to a bike shop, but we recommend bringing a multi-tool.',
  },
  {
    name: 'Other',
    placeholder: 'Sunscreen, warm clothes, ',
  },
];

/**
 * Coaching
 */
// Private lesson group invite status
// IMPORTANT! Also lives on the backend - const/index.ts
export enum AdditionalPersonInviteStatus {
  Invited = 'Invited',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum StreamChannelLessonStatusEnum {
  All = 'All',
  NewRequest = 'New Request',
  Active = 'Active',
  Archived = 'Archived',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum StreamChannelTypeEnum {
  All = 'All',
  Coach = 'Coach',
  Organizer = 'Organizer',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum LessonStatusEnum {
  Canceled = 'Canceled',
  Paid = 'Paid',
  Rejected = 'Rejected',
  Expired = 'Expired',
  Requested = 'Requested',
  PaymentInvalid = 'Payment Invalid',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum LessonRecurrenceTypeEnum {
  OneTime = 'One Time',
  Recurring = 'Recurring',
  Bundle = 'Bundle',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum LessonRecurringIntervalEnum {
  Week = 'Week',
  Month = 'Month',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum LessonTransactionTypeEnum {
  Payment = 'Payment',
  OneTimeCharge = 'One-Time Charge',
  Free = 'Free',
  Refund = 'Refund',
  OneTimeChargeRefund = 'One-Time Charge Refund',
  CancelSubscription = 'Cancel Subscription',
  CancelLesson = 'Cancel Lesson',
}
// IMPORTANT! Also lives on the backend - const/index.ts
export enum LessonTransactionStatusEnum {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}
// IMPORTANT! Also lives on the backend - const/index.tsx
export enum LessonPauseResumeActionTypesEnum {
  Pause = 'Pause',
  Resume = 'Resume',
}
export const COACHING_CERTIFICATIONS = [
  'BICP Level 1',
  'BICP Level 2',
  'BICP Level 3',
  'PMBIA Level 1',
  'PMBIA Level 2',
  'PMBIA Level 3',
  'PMBIA Level 4',
  'USAC Level 1',
  'USAC Level 2',
  'USAC Level 3',
];
enum CoachStyleNamesEnum {
  AdaptableEmpathetic = 'Adaptable & Empathetic',
  PatientPerceptive = 'Patient & Perceptive',
  PassionateMotivating = 'Passionate & Motivating',
  StructuredDisciplined = 'Structured & Disciplined',
}
type CoachStyleType = {
  [CoachStyleNamesEnum.AdaptableEmpathetic]: ['Adaptable', 'Empathetic'];
  [CoachStyleNamesEnum.PatientPerceptive]: ['Patient', 'Perceptive'];
  [CoachStyleNamesEnum.PassionateMotivating]: ['Passionate', 'Motivating'];
  [CoachStyleNamesEnum.StructuredDisciplined]: ['Structured', 'Disciplined'];
  [key: string]: [string, string];
};
export const COACHING_STYLE: CoachStyleType = {
  [CoachStyleNamesEnum.AdaptableEmpathetic]: ['Adaptable', 'Empathetic'],
  [CoachStyleNamesEnum.PatientPerceptive]: ['Patient', 'Perceptive'],
  [CoachStyleNamesEnum.PassionateMotivating]: ['Passionate', 'Motivating'],
  [CoachStyleNamesEnum.StructuredDisciplined]: ['Structured', 'Disciplined'],
};
export const IS_NEW_COACH_QUERY_PARAM = 'isNewCoach';
export const IS_NEW_EVENT_QUERY_PARAM = 'isNewEvent';
export const IS_NEW_ONLINE_COURSE_QUERY_PARAM = 'isNewOnlineCourse';

/**
 * Courses
 */
export enum CoursePlatformEnum {
  RideWithGPS = 'Ride with GPS',
  FATMAP = 'FATMAP',
}

/**
 * Rides
 */
export enum RideTypeEnum {
  Curated = 'Curated',
  Social = 'Social',
  Training = 'Training',
  GroupRide = 'Group Ride',
  GranFondo = 'Gran Fondo/Sportive/Century',
  Other = 'Other',
}

/**
 * Races
 */
export enum CourseDifficultyEnum {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Expert = 'Expert',
  Pro = 'Pro',
}
export enum CategoryAgeAsOfEnum {
  RacingAge = 'Racing Age',
  EventDate = 'Event Date',
}
// export type AgeRangeNameType = 'Under 10' | '10-16' | '17-18' | '19-29' | '30-39' | '40-49' | '50+';
// 'Under 10': { minAge: 0, maxAge: 9 },
// '10-16': { minAge: 10, maxAge: 16 },
// '40-49': { minAge: 40, maxAge: 49 },
// '50+': { minAge: 50, maxAge: 120 },
export type AgeRangeNameType = '< 17' | '17-18' | '19-29' | '30-39' | '40+';
export const CATEGORY_AGE_RANGES: Record<AgeRangeNameType, { minAge: number; maxAge: number }> = {
  '< 17': { minAge: 0, maxAge: 16 },
  '17-18': { minAge: 17, maxAge: 18 },
  '19-29': { minAge: 19, maxAge: 29 },
  '30-39': { minAge: 30, maxAge: 39 },
  '40+': { minAge: 40, maxAge: 49 },
};
// The 'Other' sanction name.
// IMPORTANT: update this query if this name gets changes
// sanctions: sanction_connection(where: { name: { _neq: "Other" } }) {
export const SANCTION_OTHER_NAME = 'Other';
export const SANCTION_OTHER_CUID = 'ckh1j5o9x000j3h6ae48rj3mz';
export const SANCTION_USA_CYCLING_NAME = 'USA Cycling';
export const EVENT_SANCTIONING_BODIES = [
  'Cycling Canada',
  'British Cycling',
  'Cycling Australia',
  'Cycling New Zealand',
  'UEC',
];
export const COURSE_LENGTH_TYPES = [
  'Day',
  'Hour',
  'Minute',
  'Meter',
  'Kilometer',
  'Mile',
  'Lap',
  'Stage',
  'Run',
] as const;
export enum COURSE_ELEVATION_UNIT_ENUM {
  'Feet' = 'Feet',
  'Meters' = 'Meters',
}
export const SERIES_NAME_TBD = 'TBD';

/**
 * Global
 */
// What is the most earliest year we can have?
export const EARLIEST_YEAR = 1900;

export const EVENT_NAME_AVAILABILITY_ERROR =
  'Your organization already has an event with this name. Please choose a new one.';

export const ORGANIZER_NAME_AVAILABILITY_ERROR =
  'There is already an organization with this name. Please choose a new one.';

// TODO: If we want to show what kind of organization it is similar to strava
// // What types of organizers can host a event?
// export type HostType = 'Race Organizer' | 'Event Host' | 'Athlete' | 'Brand' | 'Shop';
// export const HOST_TYPES = {
//   RaceOrganizer: 'Race Organizer' as HostType,
//   EventHost: 'Event Host' as HostType,
//   Athlete: 'Athlete' as HostType,
//   Brand: 'Brand' as HostType,
//   Shop: 'Shop' as HostType,
// };

// What types of custom questions can we have for our registration question builder?
export enum QuestionTypes {
  Text = 'Text',
  Number = 'Number',
  Dropdown = 'Dropdown',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}
// What section does this question belong to?
export enum QuestionInternalPurposeEnum {
  Registration = 'registration',
  Assessment = 'assessment',
}

export const KEY_CODE = {
  Tab: 9,
  Enter: 13,
};

/**
 * Auth
 */
export const MAX_PASSWORD_LENGTH = 128;
export const MAX_FIRST_NAME_LENGTH = 40;
export const MAX_LAST_NAME_LENGTH = 40;

/** ******************************************************************************
 * Global Constants
 ******************************************************************************** */

// Global
// How many custom questions can there be?
export const MAX_CUSTOM_QUESTIONS = 20;
// How long can a custom question title be?
export const MAX_CUSTOM_QUESTION_TITLE_LENGTH = 100;
// How long can a custom question additional info be?
export const MAX_CUSTOM_QUESTION_ADDITIONAL_INFO_LENGTH = 100;
// How many question items can there be?
export const MAX_CUSTOM_QUESTION_OPTION_ITEMS = 30;
// How long can question options be?
export const MAX_CUSTOM_QUESTION_OPTION_ITEM_LENGTH = 25;
// What is the minimum length of a chip
export const DEFAULT_MIN_CHIP_LENGTH = 2;
// What is the maximum length of a chip
export const DEFAULT_MAX_CHIP_LENGTH = 20;

// Changes to MAX_EVENT_IMAGES also have to happen server-side in generateSignature and in
// Check Constraints in DB
// Max Event Length below
// aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
// How long can an event name be?
export const MAX_EVENT_NAME_LENGTH = 100;
// How long can an occurrence label be?
export const MAX_OCCURRENCE_LABEL_LENGTH = 35;

// How far out can an event be created
export const MAX_YEARS_IN_FUTURE = 20;

/** ******************************************************************************
 * Flag Constants
 ******************************************************************************** */

// How long can moderation details be?
export const MAX_FLAG_REASON_DETAILS_LENGTH = 1000;

/** ******************************************************************************
 * User Constants
 ******************************************************************************** */

// How long can a persons full name be?
export const MAX_USER_FULL_NAME_LENGTH = 100;
// How long can a persons ideal ride be?
export const MAX_USER_IDEAL_RIDE_LENGTH = 250;
// How long can a persons cycling goal be?
export const MAX_USER_CYCLING_GOAL_LENGTH = 250;
// How long can a persons sponsors be?
export const MAX_USER_SPONSORS_LENGTH = 250;
// How long can a persons pronouns be?
export const MAX_PRONOUNS_LENGTH = 20;

/** ******************************************************************************
 * Online Course Constants
 ******************************************************************************** */

// How long can an online course title be?
export const MAX_ONLINE_COURSE_TITLE_LENGTH = 100;
// How long can the online course tagline be?
export const MAX_ONLINE_COURSE_TAGLINE_LENGTH = 255;
// How many images can an online course have?
export const MAX_ONLINE_COURSE_IMAGES = 10;

/** ******************************************************************************
 * Organizer Constants
 ******************************************************************************** */

// Create Organizer
// How long can a organizer name be?
export const MAX_ORGANIZER_NAME_LENGTH = 40;
// How long can a organizer title be?
export const MAX_ORGANIZER_ABOUT_LENGTH = 2500;
// How long can a organizer website be?
export const MAX_ORGANIZER_WEBSITE_LENGTH = 100;

// Questions, Answers & Updates
// How long can a question be?
export const MAX_EVENT_QUESTION_LENGTH = 125;
// How long can an answer be?
export const MAX_EVENT_ANSWER_LENGTH = 600;
// How long can a comment be?
export const MAX_EVENT_UPDATE_COMMENT_LENGTH = 400;
// How long can an update title be?
export const MAX_EVENT_UPDATE_TITLE_LENGTH = 60;
// How long can an update body be?
export const MAX_EVENT_UPDATE_BODY_LENGTH = 6000;

// Send Messages
// How long can an organizer length be?
export const MAX_ORGANIZER_SUBJECT_LENGTH = 100;
// How long can an organizer message be?
export const MAX_ORGANIZER_MESSAGE_LENGTH = 2000;

// Private Lessons
// How long can notes be?
export const MAX_PRIVATE_LESSON_NOTES_LENGTH = 100000;

/** ******************************************************************************
 * Coach Constants
 ******************************************************************************** */

// Create Coach
// How long can a coach experience be?
export const MAX_COACH_EXPERIENCE_LENGTH = 800;
// How long can a coach certification be?
export const MAX_COACH_CERTIFICATION_LENGTH = 100;

// Coach Profile
// How long can the coaches tagline be?
export const MAX_COACH_PROFILE_TAGLINE_LENGTH = 135;
// How many images can a coach have?
export const MAX_COACH_IMAGES = 10;
// How long can the coaches custom vibe be?
export const MAX_COACH_PROFILE_CUSTOM_VIBE_LENGTH = 16;
// How long can the coaches about be?
export const MAX_COACH_PROFILE_ABOUT_LENGTH = 2500;
// How long can the coaches racing experience be?
export const MAX_COACH_PROFILE_RACING_EXP_LENGTH = 1500;
// How many languages does the coach speak?
export const MAX_COACH_LANGUAGES = 20;
// What is the maximum number of coach disciplines?
export const MAX_COACH_DISCIPLINE_SKILLS = 16;
// How long can a lesson title be?
export const MAX_COACH_LESSON_TITLE_LENGTH = 100;
// How long can a lesson description be?
export const MAX_COACH_LESSON_DESCRIPTION_LENGTH = 1000;
// What is the maximum skill character length?
export const MAX_COACH_LESSON_SKILL_LENGTH = 25;
// What is the minimum skill character length?
export const MIN_COACH_LESSON_SKILL_LENGTH = 2;
// What is the maximum number of skills?
export const MAX_COACH_LESSON_SKILLS = 8;
// TODO: What is the maximum number of disciplines?
// export const MAX_COACH_LESSON_DISCIPLINES = 4;

// Request Private Lesson
// How long can a message request be?
export const MAX_REQUEST_LESSON_LENGTH = 600;

// Private lessons
// How long can a message request be?
export const MAX_LESSON_HOURS = 12;
// How many lesson can we schedule at once?
export const MAX_RECURRING_LESSONS = 1000;
// How many lesson can we schedule at once?
export const MAX_BUNDLE_LESSONS = 1000;
// How long can a one time charge description be?
export const MAX_ONE_TIME_CHARGE_DESCRIPTION_LENGTH = 200;

/** ******************************************************************************
 * Host Event Constants
 ******************************************************************************** */

// Venue
// How long can the venue name be?
export const MAX_VENUE_NAME_LENGTH = 100;
// How long can the meetup instructions for a venue be?
export const MAX_MEETUP_INSTRUCTIONS_INFO_LENGTH = 800;
// How long can the additional info for a venue be?
export const MAX_ADDITIONAL_LOCATION_INFO_LENGTH = 800;
// How long can the camping description be?
export const MAX_CAMPING_DESCRIPTION_INFO_LENGTH = 1000;
// How long can the parking info be?
export const MAX_PARKING_INFO_LENGTH = 1000;
// How long can the meeting link be?
export const MAX_MEETING_LINK_LENGTH = 1000;
// Pit Space
// How long can a pit space name be?
export const MAX_PIT_SPACE_NAME_LENGTH = 100;
// How long can a pit space add on name be?
export const MAX_PIT_SPACE_NAME_ADD_ON_LENGTH = 50;
// What is the maximum pit space price?
export const MAX_PIT_SPACE_PRICE = 100000;
// What is the maximum pit space size?
export const MAX_PIT_SPACE_SIZE = 27878400;
// What is the maximum pit spaces?
export const MAX_PIT_SPACES_LIMIT = 1000000;
// How long can a pit space description name be?
export const MAX_PIT_SPACE_DESCRIPTION_LENGTH = 400;
// How long can a pit space length and width name be?
export const MAX_PIT_SPACE_LxW_LENGTH = 20;

// Contact Info
// How long can a phone number be?
export const MAX_PHONE_LENGTH = 50;
// How long can an email be?
export const MAX_EMAIL_LENGTH = 255;
// How long the additional contact info for a venue be?
export const MAX_ADDITIONAL_CONTACT_INFO_LENGTH = 300;

// Vibe
// How long can the more info text be?
export const MAX_VIBE_MORE_INFO_LENGTH = 1000;

// Program
// How long can the overview be?
export const MAX_PROGRAM_OVERVIEW_LENGTH = 1000;
// What is the maximum skill length?
export const MAX_PROGRAM_SKILL_LENGTH = 25;
// What is the minimum skill length?
export const MIN_PROGRAM_SKILL_LENGTH = 2;
// What is the maximum number of skills a program can teach?
export const MAX_PROGRAM_SKILLS = 16;
// How much can a clinic cost?
export const MAX_PROGRAM_CLINIC_FEE = 99999;
// What is the maximum focus length?
export const MAX_PROGRAM_FOCUS_LENGTH = 16;
// What is the minimum focus length?
export const MIN_PROGRAM_FOCUS_LENGTH = 2;
// What is the maximum number of focuses a program can have?
export const MAX_PROGRAM_FOCUSES = 3;

// Checklist
// How long can the overview be?
export const MAX_CHECKLIST_INFO_LENGTH = 300;

// Courses
// Changes to MAX_EVENT_COURSES also have to happens server-side in makeStravaCourse
// How many courses can a event have?
export const MAX_EVENT_COURSES = 20;
// How long can a course name be?
export const MAX_COURSE_NAME_LENGTH = 71;
// How long can a course be??
export const MAX_COURSE_LENGTH = 100000;
// How long can a course description be?
export const MAX_COURSE_DESCRIPTION_LENGTH = 400;

// Series'
// How many events can be in a series?
export const MAX_EVENTS_IN_A_SERIES = 50;
// How many images can we have?
export const MAX_SERIES_IMAGES = 6;
// How many series can a event be a part of?
export const MAX_SERIES_IN_EVENT = 10;
// What is the min series name length?
export const MIN_SERIES_NAME_LENGTH = 3;
// How long can a series name be?
export const MAX_SERIES_NAME_LENGTH = 100;
// How long can a series description be?
export const MAX_SERIES_DESCRIPTION_LENGTH = 500;
// How long can a points structure name be?
export const MAX_POINTS_STRUCTURE_NAME_LENGTH = 50;

// Sanction
// How many sanction can a event be a part of?
export const MAX_SANCTIONS_IN_EVENT = 10;
// What is the min series name length?
export const MIN_SANCTION_NAME_LENGTH = 3;
// How long can a sanction name be?
export const MAX_SANCTION_NAME_LENGTH = 30;
// How long can a permit # be?
export const MAX_SANCTION_PERMIT_LENGTH = 30;

// Categories
// How long can a category name name be?
export const MAX_CATEGORY_NAME_LENGTH = 65;
// What is the maximum entry fee?
export const MAX_ENTRY_FEE = 100000;
// How long can a category description name be?
export const MAX_CATEGORY_DESCRIPTION_LENGTH = 400;
// How long can a category additional prize input be?
export const MAX_CATEGORY_ADDITIONAL_PRIZES_LENGTH = 200;
// What is the maximum field limit?
export const MAX_FIELD_LIMIT = 1000000;
// What is the maximum spots available?
export const MAX_CATEGORY_SPOTS_AVAILABLE = 100000;
// What is the max age a cyclist can be?
export const MAX_AGE = 120;
// What is the max the one day fee can be?
export const MAX_ONE_DAY_FEE = 1000;

// Tickets
// How long can a ticket name  be?
export const MAX_TICKET_NAME_LENGTH = 100;
// What is the maximum ticket prize?
export const MAX_TICKET_PRICE = 100000;
// How long can a ticket description be?
export const MAX_TICKET_DESCRIPTION_LENGTH = 1000;
// How long can a ticket prize description be?
export const MAX_TICKET_PRIZE_LENGTH = 400;
// What is the maximum tickets?
export const MAX_TICKETS_LIMIT = 1000000;

// Registration
// How long can the refund info be?
export const MAX_REFUND_INFO_LENGTH = 500;
// How long can a promo code be?
export const MAX_PROMO_CODE_NAME_LENGTH = 20;

// Volunteers
// How long can a volunteer title be?
export const MAX_VOLUNTEER_TITLE_LENGTH = 70;
// How long can a volunteer location info be?
export const MAX_VOLUNTEER_LOCATION_INFO_LENGTH = 70;
// How long can a volunteer description be?
export const MAX_VOLUNTEER_DESCRIPTION_LENGTH = 500;
// How long can a task task name title  be?
export const MAX_VOLUNTEER_TASK_NAME_LENGTH = 55;
// How many volunteer tasks can we have?
export const MAX_VOLUNTEER_TASKS = 50;
// How long can a volunteer shift name be?
export const MAX_VOLUNTEER_SHIFT_NAME_LENGTH = 70;
// What is the maximum spots available for a shift?
export const MAX_VOLUNTEER_SHIFT_SPOT_LIMIT = 1000000;

// Merchandise
// How long can a merchandise title  be?
export const MAX_MERCHANDISE_TITLE_LENGTH = 70;
// How long can a merchandise description be?
export const MAX_MERCHANDISE_DESCRIPTION_LENGTH = 400;
// What is the maximum merch price?
export const MAX_MERCHANDISE_PRICE = 100000;
// How many images can we have?
export const MAX_MERCHANDISE_IMAGES = 8;
// How many values can a variant option have?
export const MAX_VARIANT_OPTION_VALUES = 200;
// How long can a variant option name be?
export const MAX_VARIANT_OPTION_NAME_LENGTH = 45;
// How long can a variant option value name be?
export const MAX_VARIANT_OPTION_VALUE_NAME_LENGTH = 45;

// Schedule
// How long can a schedule item title be?
export const MAX_SCHEDULE_TITLE_LENGTH = 70;
// How long can a schedule item description be?
export const MAX_SCHEDULE_DESCRIPTION_LENGTH = 400;
// How long can a schedule type name title  be?
export const MAX_SCHEDULE_TYPE_NAME_LENGTH = 35;
// How many schedule types elements can we have?
export const MAX_SCHEDULE_TYPE_ELEMENTS = 100;

// Images
// How many images can a event have?
export const MAX_EVENT_IMAGES = 10;

// Sponsors
// How long can a sponsor name be?
export const MAX_SPONSOR_NAME_LENGTH = 70;
// How long can a sponsor website be?
export const MAX_SPONSOR_WEBSITE_LENGTH = 400;
// How many images can a sponsor have?
export const MAX_SPONSOR_IMAGES = 1;
// How many sponsors can an event have?
export const MAX_SPONSORS_IN_EVENT = 50;

// Basic Waiver
// How long can a waiver name be
export const MAX_BASIC_WAIVER_NAME_LENGTH = 65;

// Additional Info
// How long can an additional info description be?
export const MAX_ADDITIONAL_INFO_ABOUT_LENGTH = 2500;
// How long can an additional info title be?
export const MAX_ADDITIONAL_INFO_TITLE_LENGTH = 70;
// How long can an additional info description be?
export const MAX_ADDITIONAL_INFO_DESCRIPTION_LENGTH = 1000;
// How many additional info elements can we have?
export const MAX_ADDITIONAL_INFO_ELEMENTS = 20;
// How long can an event website be?
export const MAX_EVENT_WEBSITE_LENGTH = 100;
