import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BrandTypography from '@/ui/BrandTypography';

import {
  useSetHeaderSearchValueGlobal,
  useSetSearchQueryGlobal,
} from '@/containers/Search/HeaderSearch/recoilStore';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.common.white,
    height: theme.spacing(8),
    overflow: 'hidden',
    width: theme.spacing(8),
  },
  chip: {
    transition: 'all 200ms',
    boxShadow: theme.shadows[8],
  },
}));

interface DiscoverEventsChipsProps {
  setIsSearchFocused: Dispatch<SetStateAction<boolean>>;
}
const DiscoverEventsChips: React.FC<DiscoverEventsChipsProps> = ({ setIsSearchFocused }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const setSearchQueryGlobal = useSetSearchQueryGlobal();
  const setSearchFieldValueGlobal = useSetHeaderSearchValueGlobal();

  const handleSetSearchQuery = (query: string): void => {
    setIsSearchFocused(false);
    setSearchQueryGlobal(query);
    setSearchFieldValueGlobal(query);
    const queryParams = getParsedQueryParams();
    navigate(AppRouteService.getRelativeUrl('Search', {}, { ...queryParams, query }));
  };

  return (
    <Box mx={4} my={2}>
      <Box mb={1}>
        <Typography component="div">
          <b>
            Suggested Searches on <BrandTypography inline>Reggy</BrandTypography>
          </b>
        </Typography>
      </Box>
      <Grid container alignItems="center" direction="row" spacing={2}>
        <Grid item>
          <Chip
            clickable
            avatar={<Avatar>CL</Avatar>}
            className={classes.chip}
            color="primary"
            label="Clinic"
            onClick={(): void => handleSetSearchQuery('Clinic')}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            avatar={<Avatar>DH</Avatar>}
            className={classes.chip}
            color="primary"
            label="Downhill"
            onClick={(): void => handleSetSearchQuery('Downhill')}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            avatar={<Avatar>SP</Avatar>}
            className={classes.chip}
            color="primary"
            label="Sponsorship"
            onClick={(): void => handleSetSearchQuery('Sponsorship')}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            avatar={<Avatar>CA</Avatar>}
            className={classes.chip}
            color="primary"
            label="California"
            onClick={(): void => handleSetSearchQuery('California')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiscoverEventsChips;
