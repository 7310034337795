/**
 * Desktop header container
 */
import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ButtonLink from '@/ui/Button/ButtonLink';

import CoachingMenuItem from '@/containers/MainHeader/Desktop/CoachingMenuItem';
import { useHeaderStyles } from '@/containers/MainHeader/MainHeader';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[13],
    left: '0 !important',
    maxWidth: '100%',
    top: '0px !important',
    width: '100%',
  },
  menuItemContainer: {
    background: 'white',
    borderTop: Colors.LightBorder,
    height: '100%',
    padding: theme.spacing(3.5),
  },
}));

const DesktopNavigation: React.FC = () => {
  const auth = useAuth();
  // NOTE: We are using a popover here because transitioning from an open explore menu to
  // an open search menu so we are requiring the explore menu to be closed first by
  // overlaying <Box height="72px" onClick={handleExploreClose} /> over the header
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const hideCoachingMenu = useMediaQuery(`(min-width: 1024px) and (max-width: 1125px)`);
  const [anchorExploreMenuEl, setAnchorExploreMenuEl] = useState<HTMLButtonElement | null>(null);
  const isExploreOpen = Boolean(anchorExploreMenuEl);

  const handleExploreOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorExploreMenuEl(e.currentTarget);
  };
  const handleExploreClose = (): void => {
    setAnchorExploreMenuEl(null);
  };

  return (
    <Box className={headerClasses.navigationButtons} ml={2}>
      <ButtonLink to={AppRouteService.getRelativeUrl('Discover', {}, { category: 'Coaches' })}>
        Discover Experiences
      </ButtonLink>
      {/* <ButtonLink to={AppRouteService.getRelativeUrl('User_Feed')}>My Feed</ButtonLink> */}
      {/* <ExtendedButton endIcon={<ExpandMoreIcon />} onClick={handleExploreOpen}> */}
      {/*  Discover */}
      {/* </ExtendedButton> */}
      {/* <Popover */}
      {/*  anchorEl={anchorExploreMenuEl} */}
      {/*  anchorOrigin={{ */}
      {/*    vertical: 'bottom', */}
      {/*    horizontal: 'center', */}
      {/*  }} */}
      {/*  classes={{ paper: classes.menu }} */}
      {/*  onClose={handleExploreClose} */}
      {/*  open={isExploreOpen} */}
      {/*  transformOrigin={{ */}
      {/*    vertical: 'top', */}
      {/*    horizontal: 'left', */}
      {/*  }} */}
      {/* > */}
      {/*  <Box height="72px" onClick={handleExploreClose} /> */}
      {/*  <Box className={classes.menuItemContainer}> */}
      {/*    <ExploreItems handleExploreClose={handleExploreClose} /> */}
      {/*  </Box> */}
      {/* </Popover> */}
      {auth.isLoggedIn && (auth.user?.isCoach || !hideCoachingMenu) && <CoachingMenuItem />}
    </Box>
  );
};

export default DesktopNavigation;
