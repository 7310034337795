import { Theme } from '@material-ui/core';

export const MuiListItemIcon = (theme: Theme): Record<string, unknown> => ({
  root: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
    },
  },
});
