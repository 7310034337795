/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type hostEventMutations_updateCompletedSections_MutationVariables = {
    eventCuid: string;
    completedSections: Record<string, unknown> | unknown[];
};
export type hostEventMutations_updateCompletedSections_MutationResponse = {
    readonly update_event: {
        readonly affected_rows: number;
    } | null;
};
export type hostEventMutations_updateCompletedSections_Mutation = {
    readonly response: hostEventMutations_updateCompletedSections_MutationResponse;
    readonly variables: hostEventMutations_updateCompletedSections_MutationVariables;
};



/*
mutation hostEventMutations_updateCompletedSections_Mutation(
  $eventCuid: String!
  $completedSections: jsonb!
) {
  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {completedSections: $completedSections, lastSavedAt: "now()"}) {
    affected_rows
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "completedSections"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "eventCuid"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "completedSections",
                            "variableName": "completedSections"
                        },
                        {
                            "kind": "Literal",
                            "name": "lastSavedAt",
                            "value": "now()"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "_set"
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "eventCuid"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "cuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "event_mutation_response",
            "kind": "LinkedField",
            "name": "update_event",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "affected_rows",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "hostEventMutations_updateCompletedSections_Mutation",
            "selections": (v2 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "hostEventMutations_updateCompletedSections_Mutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "e938c7bd23b24f79c8b0533239915d97",
            "id": null,
            "metadata": {},
            "name": "hostEventMutations_updateCompletedSections_Mutation",
            "operationKind": "mutation",
            "text": "mutation hostEventMutations_updateCompletedSections_Mutation(\n  $eventCuid: String!\n  $completedSections: jsonb!\n) {\n  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {completedSections: $completedSections, lastSavedAt: \"now()\"}) {\n    affected_rows\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a691a27daab54b112283ec11335fc31c';
export default node;
