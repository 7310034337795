import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const useSignStyles = makeStyles((theme: Theme) => ({
  sign: {
    // padding: '6px 16px',
    padding: '3px 0 1px 0',
    background: '#F5B811',
    width: '42px',
    borderRadius: '4px',
    textAlign: 'center',
    boxShadow: theme.shadows[1],
    boxSizing: 'border-box',
    '&.small': {
      alignItems: 'center',
      display: 'flex',
      height: theme.spacing(2),
      justifyContent: 'center',
      margin: '2px 0',
      padding: 0,
      '& > div': {
        height: '8px !important',
        width: '8px !important',
      },
    },
  },
  greenCircle: {
    width: '12px',
    height: '12px',
    background: theme.palette.success.main,
    border: '1px solid #ffffff1c',
    borderRadius: '50%',
    boxShadow: '1px 1px 4px -1px #00000066',
    display: 'inline-block',
  },
  blueSquare: {
    width: '12px',
    height: '12px',
    background: '#0078ff',
    border: '1px solid #ffffff38',
    boxShadow: '1px 1px 4px -1px #00000066',
    display: 'inline-block',
  },
  blackDiamond: {
    width: '12px',
    height: '12px',
    background: 'black',
    border: '1px solid #ffffff4a',
    boxShadow: '1px 1px 4px -1px #00000066',
    transform: 'rotate(45deg)',
    display: 'inline-block',
  },
  doubleBlackDiamondLeft: {
    width: '12px',
    height: '12px',
    background: 'black',
    border: '1px solid #ffffff4a',
    boxShadow: '1px 1px 4px -1px #00000066',
    transform: 'rotate(45deg)',
    display: 'inline-block',
  },
  doubleBlackDiamondRight: {
    width: '12px',
    height: '12px',
    background: 'black',
    border: '1px solid #ffffff4a',
    boxShadow: '1px 1px 4px -1px #00000066',
    transform: 'rotate(45deg)',
    display: 'inline-block',
    // left: '-2px',
    position: 'relative',
  },
}));

export interface CourseDifficultyProps {
  small?: boolean;
}
export const GreenCircle: React.FC<CourseDifficultyProps> = ({ small }) => {
  const classes = useSignStyles();
  return (
    <Box className={classNames(classes.sign, { small })}>
      <Box className={classes.greenCircle} />
    </Box>
  );
};
export const BlueSquare: React.FC<CourseDifficultyProps> = ({ small }) => {
  const classes = useSignStyles();
  return (
    <Box className={classNames(classes.sign, { small })}>
      <Box className={classes.blueSquare} />
    </Box>
  );
};
export const BlackDiamond: React.FC<CourseDifficultyProps> = ({ small }) => {
  const classes = useSignStyles();
  return (
    <Box className={classNames(classes.sign, { small })}>
      <Box className={classes.blackDiamond} />
    </Box>
  );
};
export const DoubleBlackDiamond: React.FC<CourseDifficultyProps> = ({ small }) => {
  const classes = useSignStyles();
  return (
    <Box className={classNames(classes.sign, { small })}>
      <Box className={classes.doubleBlackDiamondLeft} />
      <Box className={classes.doubleBlackDiamondRight} />
    </Box>
  );
};
