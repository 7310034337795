/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PersonalizationServiceQueries_insertNewPrefs_MutationVariables = {
    userId: string;
    preferences?: Record<string, unknown> | unknown[] | null | undefined;
};
export type PersonalizationServiceQueries_insertNewPrefs_MutationResponse = {
    readonly insert_userPersonalization_one: {
        readonly id: string;
        readonly preferences: Record<string, unknown> | unknown[];
    } | null;
};
export type PersonalizationServiceQueries_insertNewPrefs_Mutation = {
    readonly response: PersonalizationServiceQueries_insertNewPrefs_MutationResponse;
    readonly variables: PersonalizationServiceQueries_insertNewPrefs_MutationVariables;
};



/*
mutation PersonalizationServiceQueries_insertNewPrefs_Mutation(
  $userId: String!
  $preferences: jsonb
) {
  insert_userPersonalization_one(object: {userId: $userId, preferences: $preferences}) {
    id
    preferences
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "preferences"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "preferences",
                            "variableName": "preferences"
                        },
                        {
                            "kind": "Variable",
                            "name": "userId",
                            "variableName": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "object"
                }
            ],
            "concreteType": "userPersonalization",
            "kind": "LinkedField",
            "name": "insert_userPersonalization_one",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "preferences",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PersonalizationServiceQueries_insertNewPrefs_Mutation",
            "selections": (v2 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PersonalizationServiceQueries_insertNewPrefs_Mutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "dbc4ac0400dd72d3741ebd0a661c75df",
            "id": null,
            "metadata": {},
            "name": "PersonalizationServiceQueries_insertNewPrefs_Mutation",
            "operationKind": "mutation",
            "text": "mutation PersonalizationServiceQueries_insertNewPrefs_Mutation(\n  $userId: String!\n  $preferences: jsonb\n) {\n  insert_userPersonalization_one(object: {userId: $userId, preferences: $preferences}) {\n    id\n    preferences\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '98f21c6dd618fbd14695cdfeafe5f2d6';
export default node;
