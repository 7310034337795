import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import BrandTypography from '@/ui/BrandTypography';

import AlphaBanner from '@/components/Banner/AlphaBanner';
import BetaDialog from '@/components/BetaDialog';

import Page from './Page';

import HomeAboveFold from '@/containers/Home/HomeAboveFold';
import MainLayout from '@/layouts/MainLayout';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  heroContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  heroText: {
    width: '53%',
    display: 'flex',
    maxWidth: '434px',
    minWidth: '330px',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '32px auto',
    '& h1 > span': {
      position: 'relative',
      '& > span': {
        position: 'relative',
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        height: '4px',
        width: '100%',
        bottom: '4px',
        left: 0,
        position: 'absolute',
        backgroundColor: Colors.Turquoise,
        [theme.breakpoints.down('xs')]: {
          bottom: '7px',
          height: 6,
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 'none',
    },
  },
  heroImage: {
    maxWidth: '462px',
    width: '100%',
  },
  hostEventPreview: {
    backgroundColor: 'white',
    border: '1px solid #b5b5b5bd',
    borderRadius: theme.spacing(1),
    // boxShadow: theme.shadows[14],
    // margin: '72px auto',
    // marginBottom: '160px',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  macTopBar: {
    display: 'flex',
    height: '30px',
    width: '100%',
    // backgroundColor: '#333333',
    backgroundColor: 'white',
    '& > .btn': {
      height: '10px',
      width: '10px',
      margin: '10px 3.5px',
      borderRadius: '50%',
    },
    '& > .btn1': {
      background: '#fac536',
    },
    '& > .btn2': {
      background: '#39ea49',
    },
    '& > .btn3': {
      marginLeft: '10px',
      background: '#f25056',
    },
  },
  featureImage: {
    maxWidth: '250px',
    width: '100%',
  },
  featureGridReversed: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  featureItem: {
    height: '80vh',
    alignItems: 'center',
    padding: theme.spacing(0, 17),
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[50],
  },

  overlay: {
    minHeight: '120vh',
    minWidth: '120vw',
    background: 'linear-gradient(-45deg, #404040, rgb(255 255 255 / 30%), #3a3a3a, #4e7682)',
    backgroundSize: '400% 400%',
    animation: '$gradient 5s ease infinite',
    opacity: 0.2,
    backdropFilter: 'blur(3px)',
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: ' 0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));

const HomePage: React.FC = () => {
  const classes = useStyles();
  const [isBetaDialogOpen, setIsBetaDialogOpen] = useState(false);
  const hideBetaDialog = (): void => setIsBetaDialogOpen(false);

  return (
    <Page
      description="Learn, Ride, Race - Discover your next cycling adventure and use world-class tools to create races, coach students, and host rides"
      title="Reggy · The new standard for cycling experiences"
    >
      <MainLayout>
        <BetaDialog hideDialog={hideBetaDialog} isOpen={isBetaDialogOpen} />
        <Box width="100%">
          {/* <AlphaBanner /> */}
          <HomeAboveFold />
          {/* <Box mt={4}> */}
          {/*  <Box className={classNames(classes.featureItem, classes.greyBackground)}> */}
          {/*    /!*<div className={classes.overlay} />*!/ */}
          {/*    /!*dew*!/ */}
          {/*    /!*<div />*!/ */}
          {/*  </Box> */}
          {/*  <Grid container className={classNames(classes.featureItem)} spacing={2}> */}
          {/*    <Grid item sm xs={12}> */}
          {/*      <Typography variant="h5">Learn, Ride, Race</Typography> */}
          {/*      <Typography gutterBottom component="div" variant="body1"> */}
          {/*        From gravel rides to downhill races, from learning to jump to breaking into the */}
          {/*        cycling industry, we make it possible to discover the{' '} */}
          {/*        <i>perfect cycling experience</i>. */}
          {/*      </Typography> */}
          {/*    </Grid> */}
          {/*    <Grid item sm xs={12}> */}
          {/*      <div */}
          {/*        style={{ */}
          {/*          position: 'relative', */}
          {/*          paddingBottom: '51.145833333333336%', */}
          {/*          height: 0, */}
          {/*        }} */}
          {/*      > */}
          {/*        <iframe */}
          {/*          allowFullScreen */}
          {/*          mozallowfullscreen */}
          {/*          webkitallowfullscreen */}
          {/*          frameBorder="0" */}
          {/*          src="https://www.loom.com/embed/ab7e2b459e79405688a43dfda7bb5824" */}
          {/*          style={{ */}
          {/*            position: 'absolute', */}
          {/*            top: 0, */}
          {/*            left: 0, */}
          {/*            width: '100%', */}
          {/*            height: '100%', */}
          {/*          }} */}
          {/*        /> */}
          {/*      </div> */}
          {/*    </Grid> */}
          {/*  </Grid> */}
          {/*  <Grid container className={classes.featureItem} spacing={2}> */}
          {/*    <Grid item sm={6} xs={12}> */}
          {/*      <Typography gutterBottom variant="h1"> */}
          {/*        <BrandTypography inline noLink variant="h1"> */}
          {/*          Reggy */}
          {/*        </BrandTypography>{' '} */}
          {/*        for Creators */}
          {/*      </Typography> */}
          {/*      <Typography variant="body1"> */}
          {/*        Stop paying to finding customers, building websites, schedule clinics, and take */}
          {/*        payments. Let{' '} */}
          {/*        <BrandTypography inline noLink> */}
          {/*          Reggy */}
          {/*        </BrandTypography>{' '} */}
          {/*        do the boring stuff. It's 100% free. */}
          {/*      </Typography> */}
          {/*    </Grid> */}
          {/*    <Grid item sm={6} xs={12}> */}
          {/*      <div */}
          {/*        style={{ */}
          {/*          position: 'relative', */}
          {/*          paddingBottom: '51.145833333333336%', */}
          {/*          height: 0, */}
          {/*        }} */}
          {/*      > */}
          {/*        <iframe */}
          {/*          allowFullScreen */}
          {/*          mozallowfullscreen */}
          {/*          webkitallowfullscreen */}
          {/*          frameBorder="0" */}
          {/*          src="https://www.loom.com/embed/ab7e2b459e79405688a43dfda7bb5824" */}
          {/*          style={{ */}
          {/*            position: 'absolute', */}
          {/*            top: 0, */}
          {/*            left: 0, */}
          {/*            width: '100%', */}
          {/*            height: '100%', */}
          {/*          }} */}
          {/*        /> */}
          {/*      </div> */}
          {/*    </Grid> */}
          {/*  </Grid> */}
          {/* </Box> */}
        </Box>
      </MainLayout>
    </Page>
  );
};

// {/* <Grid item sm={6} xs={12}> */}
//                 {/*  <Box className={classes.hostEventPreview}> */}
//                 {/*    <div className={classes.macTopBar}> */}
//                 {/*      <div className="btn btn3" /> */}
//                 {/*      <div className="btn btn2" /> */}
//                 {/*      <div className="btn btn1" /> */}
//                 {/*    </div> */}
//                 {/*    <img */}
//                 {/*      alt="org preview" */}
//                 {/*      loading="lazy" */}
//                 {/*      src="https://res.cloudinary.com/goreggy/image/upload/v1646572792/site/siteImages/kwcpqtwro3ooh2exelax.png" */}
//                 {/*      style={{ width: '100%' }} */}
//                 {/*    /> */}
//                 {/*  </Box> */}
//                 {/* </Grid> */}
//                 {/* <Grid item sm={6} xs={12}> */}
//                 {/*  <Box className={classes.hostEventPreview}> */}
//                 {/*    <div className={classes.macTopBar}> */}
//                 {/*      <div className="btn btn3" /> */}
//                 {/*      <div className="btn btn2" /> */}
//                 {/*      <div className="btn btn1" /> */}
//                 {/*    </div> */}
//                 {/*    <img */}
//                 {/*      alt="org preview" */}
//                 {/*      loading="lazy" */}
//                 {/*      src="https://res.cloudinary.com/goreggy/image/upload/v1646572793/site/siteImages/oag5rsxis3ek5veuydtp.png" */}
//                 {/*      style={{ width: '100%' }} */}
//                 {/*    /> */}
//                 {/*  </Box> */}
//                 {/* </Grid> */}
//           {/* <Box py="11%"> */}
//           {/*  <FadeComponent shouldFade> */}
//           {/*    <Box className={classes.heroContainer} display="flex"> */}
//           {/*      <Box className={classes.heroText}> */}
//           {/*        <Typography component="div" style={{ color: Colors.DarkBlue }} variant="h1"> */}
//           {/*          Make a living{' '} */}
//           {/*          <Hidden smDown> */}
//           {/*            <br /> */}
//           {/*          </Hidden>{' '} */}
//           {/*          <span> */}
//           {/*            <span>doing what you love</span> */}
//           {/*          </span>{' '} */}
//           {/*          <Hidden smDown> */}
//           {/*            <br /> */}
//           {/*          </Hidden>{' '} */}
//           {/*          in the world of cycling. */}
//           {/*        </Typography> */}
//           {/*        <Box mb={3.25} mt={3}> */}
//           {/*          <Typography component="div" variant="body2"> */}
//           {/*            Stop worrying about finding customers, building websites, scheduling, and */}
//           {/*            payments. */}
//           {/*            <br /> */}
//           {/*            Let{' '} */}
//           {/*            <BrandTypography inline noLink> */}
//           {/*              Reggy */}
//           {/*            </BrandTypography>{' '} */}
//           {/*            help you with the boring stuff so you can concentrate on the things that */}
//           {/*            make you a great coach or organizer. */}
//           {/*          </Typography> */}
//           {/*          /!* <Typography variant="body2"> *!/ */}
//           {/*          /!*  Discover clinics, rides and races, connect with local{' '} *!/ */}
//           {/*          /!*  <Hidden smDown> *!/ */}
//           {/*          /!*    <br /> *!/ */}
//           {/*          /!*  </Hidden>{' '} *!/ */}
//           {/*          /!*  cycling communities, and host your own event. *!/ */}
//           {/*          /!* </Typography> *!/ */}
//           {/*        </Box> */}
//           {/*        <ButtonLink */}
//           {/*          color="primary" */}
//           {/*          endIcon={<ArrowRightAltIcon />} */}
//           {/*          size="medium" */}
//           {/*          style={{ marginRight: 'auto' }} */}
//           {/*          // to={makeUserBusinessRoute(ROUTES.User.CreateCoach)} */}
//           {/*          variant="contained" */}
//           {/*        > */}
//           {/*          Become a Coach */}
//           {/*        </ButtonLink> */}
//           {/*      </Box> */}
//           {/*      <Box m="auto"> */}
//           {/*        <img */}
//           {/*          alt="Bike" */}
//           {/*          className={classes.heroImage} */}
//           {/*          src="https://res.cloudinary.com/goreggy/image/upload/v1606353396/site/siteImages/undraw_bike_ride_7xit_qtduvd.svg" */}
//           {/*        /> */}
//           {/*      </Box> */}
//           {/*    </Box> */}
//           {/*  </FadeComponent> */}
//           {/* </Box> */}
//           {/* <Box mb={3}> */}
//           {/*  <Grid container alignItems="center" justify="center" spacing={2}> */}
//           {/*    <Grid item xs={12}> */}
//           {/*      <ExploreItems /> */}
//           {/*    </Grid> */}
//           {/*  </Grid> */}
//           {/* </Box> */}
//           {/* <Box py={7}> */}
//           {/*  <Grid container alignItems="center" justify="center" spacing={2}> */}
//           {/*    <Grid item sm={6} xs={12}> */}
//           {/*      <Typography variant="h5">Discover your next adventure</Typography> */}
//           {/*      <Typography variant="body1"> */}
//           {/*        Reggy gives you access to the best cycling events in your area. By providing */}
//           {/*        course maps, camping information and reviews, we take the guess work out of */}
//           {/*        finding your next event. */}
//           {/*      </Typography> */}
//           {/*    </Grid> */}
//           {/*    <Grid item sm={6} xs={12}> */}
//           {/*      <Box display="flex" justifyContent="center"> */}
//           {/*        <img */}
//           {/*          alt="Bike" */}
//           {/*          className={classes.featureImage} */}
//           {/*          src="https://res.cloudinary.com/goreggy/image/upload/v1646572422/site/siteImages/mlyslxicod86z7jzca2q.png" */}
//           {/*        /> */}
//           {/*      </Box> */}
//           {/*    </Grid> */}
//           {/*  </Grid> */}
//           {/* </Box> */}
//
//           {/* <Box py={7}> */}
//           {/*  <Grid */}
//           {/*    container */}
//           {/*    alignItems="center" */}
//           {/*    className={classes.featureGridReversed} */}
//           {/*    justify="center" */}
//           {/*    spacing={2} */}
//           {/*  > */}
//           {/*    <Grid item xs={12}> */}
//           {/*      <Box display="flex" justifyContent="center"> */}
//           {/*        <img */}
//           {/*          alt="Bike" */}
//           {/*          // className={classes.featureImage} */}
//           {/*          src="https://res.cloudinary.com/goreggy/image/upload/v1646572419/site/siteImages/ox52uyyzegbmln2cz9v9.png" */}
//           {/*        /> */}
//           {/*      </Box> */}
//           {/*    </Grid> */}
//           {/*    <Grid item xs={12}> */}
//           {/*      <Typography variant="h5"> */}
//           {/*        Connect with like minded cyclists around your skill level */}
//           {/*      </Typography> */}
//           {/*      <Typography variant="body1"> */}
//           {/*        Whether you’re looking for a clinic, a group ride, or a race, Reggy connects you */}
//           {/*        with the perfect people to ride your bike with. No more wondering if that group */}
//           {/*        ride is going to be too long/short/fast/slow/big/small. */}
//           {/*      </Typography> */}
//           {/*    </Grid> */}
//           {/*  </Grid> */}
//           {/* </Box> */}
//
//           {/* <Box py={7}> */}
//           {/*  <Grid container alignItems="center" justify="center" spacing={2}> */}
//           {/*    <Grid item sm={6} xs={12}> */}
//           {/*      <Typography variant="h5"> */}
//           {/*        Organize events with full visibility into your business */}
//           {/*      </Typography> */}
//           {/*      <Typography variant="body1"> */}
//           {/*        Reggy was built to bring the world of cycling into the 21st century. See every */}
//           {/*        detail of your event business, from revenue analytics to registration trends. */}
//           {/*      </Typography> */}
//           {/*    </Grid> */}
//           {/*    <Grid item sm={6} xs={12}> */}
//           {/*      <Box display="flex" justifyContent="center"> */}
//           {/*        <img */}
//           {/*          alt="Bike" */}
//           {/*          className={classes.featureImage} */}
//           {/*          src="https://res.cloudinary.com/goreggy/image/upload/v1606364838/site/siteImages/undraw_dashboard_nklg_qlcoch.svg" */}
//           {/*        /> */}
//           {/*      </Box> */}
//           {/*    </Grid> */}
//           {/*  </Grid> */}
//           {/* </Box> */}
export default HomePage;
