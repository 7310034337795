import React, { ChangeEvent, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuListSubheader from '@/ui/MenuListSubheader';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import { useMobileMenuStyles } from '@/ui/styles/mobileMenuStyles';
import DisableToggle from '@/hoc/DisableToggle';

import AuthContainer from './AuthContainer';

import ProfileAccordion from '@/containers/MainHeader/Mobile/ProfileAccordion';
import SetupCoach from '@/containers/User/UserAvatar/SetupCoach';
import { useAuth } from '@/providers/AuthProvider';
import { AppDirectories } from '@/routes/route-metadata';
import { AppRouteService } from '@/routes/RouteService';

const useStyles = makeStyles(() => ({
  summaryContent: {
    alignItems: 'center',
  },
}));

interface MobileMenuProps {
  handleMobileMenuClose: () => void;
}
const MobileMenu: React.FC<MobileMenuProps> = ({ handleMobileMenuClose }) => {
  const classes = useStyles();
  const mobileMenuClasses = useMobileMenuStyles();
  const auth = useAuth();
  const params = useParams();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string | null>(null);

  const isBusinessPage = location.pathname.includes(AppDirectories.User_Business);
  const isOnCoachingPage =
    isBusinessPage ||
    location.pathname.includes(`${AppDirectories.CoachProfile}/${auth.user?.slug}`);
  const isOnOnlineCoursesPage =
    (params.coachSlug &&
      AppRouteService.matchesRoute('CoachProfile_OnlineCourses', {
        coachSlug: params.coachSlug,
      })) ||
    (params.coachSlug &&
      params.onlineCourseCuid &&
      AppRouteService.matchesRoute('CoachProfile_OnlineCourses_Detail', {
        coachSlug: params.coachSlug,
        onlineCourseCuid: params.onlineCourseCuid,
      }));

  /**
   * Handle accordion
   */
  const handleChange = (panel: string) => (
    event: ChangeEvent<unknown>,
    isExpanded: boolean,
  ): void => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box className={mobileMenuClasses.menu}>
      <Box className={mobileMenuClasses.menuItemInnerContainer}>
        <NoUnderlineRouterLink
          onClick={handleMobileMenuClose}
          to={AppRouteService.getRelativeUrl('Search')}
        >
          <Accordion className={mobileMenuClasses.menuItem} expanded={false}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              classes={{ content: classes.summaryContent }}
              expandIcon={<Box width={24} />}
              id="panel1bh-header"
            >
              <Typography
                className={mobileMenuClasses.heading}
                style={
                  AppRouteService.matchesRoute('Search')
                    ? { textDecoration: 'underline' }
                    : undefined
                }
              >
                Search
              </Typography>
              <Typography className={mobileMenuClasses.secondaryHeading}>
                Find a clinic, race, ride or coach.
              </Typography>
            </AccordionSummary>
          </Accordion>
        </NoUnderlineRouterLink>
        <NoUnderlineRouterLink
          onClick={handleMobileMenuClose}
          to={AppRouteService.getRelativeUrl('Discover')}
        >
          <Accordion className={mobileMenuClasses.menuItem} expanded={false}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              classes={{ content: classes.summaryContent }}
              expandIcon={<Box width={24} />}
              id="panel1bh-header"
            >
              <Typography
                className={mobileMenuClasses.heading}
                style={
                  AppRouteService.matchesRoute('Discover')
                    ? { textDecoration: 'underline' }
                    : undefined
                }
              >
                Discover
              </Typography>
              <Typography className={mobileMenuClasses.secondaryHeading}>
                Explore cycling experiences
              </Typography>
            </AccordionSummary>
            {/* <AccordionDetails> */}
            {/*  <ExploreItems handleExploreClose={handleMobileMenuClose} /> */}
            {/* </AccordionDetails> */}
          </Accordion>
        </NoUnderlineRouterLink>
        <DisableToggle disabled={false}>
          {auth.isLoggedIn && (
            <>
              {auth.user?.isCoach && (
                <Accordion
                  className={mobileMenuClasses.menuItem}
                  expanded={expanded === 'coachPanel'}
                  onChange={handleChange('coachPanel')}
                >
                  <AccordionSummary
                    aria-controls="coachPanel-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="coachPanel-header"
                  >
                    <Typography
                      className={mobileMenuClasses.heading}
                      style={{ textDecoration: isOnCoachingPage ? 'underline' : undefined }}
                    >
                      Coaching
                    </Typography>
                    <Typography
                      className={mobileMenuClasses.secondaryHeading}
                      style={{ textDecoration: isOnCoachingPage ? 'underline' : undefined }}
                    >
                      Manage your business
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" width="100%">
                      <MenuListSubheader key="manage-header">Manage</MenuListSubheader>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_Dashboard')}
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_AllLessons')}
                      >
                        Lessons
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_Students')}
                      >
                        Students
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_Availability')}
                      >
                        Availability
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_Accounting')}
                      >
                        Accounting
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('User_Business_Analytics')}
                      >
                        Analytics
                      </MenuItem>
                      <MenuListSubheader key="public-header">Public</MenuListSubheader>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive && !isOnOnlineCoursesPage ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('CoachProfile', {
                          coachSlug: auth.user.slug,
                        })}
                      >
                        Coach Profile
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        onClick={handleMobileMenuClose}
                        style={({ isActive }): React.CSSProperties => ({
                          color: 'inherit',
                          fontWeight: isActive ? 'bold' : 'normal',
                        })}
                        to={AppRouteService.getRelativeUrl('CoachProfile_OnlineCourses', {
                          coachSlug: auth.user.slug,
                        })}
                      >
                        Online Courses
                      </MenuItem>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              <ProfileAccordion
                expanded={expanded}
                handleChange={handleChange}
                handleMobileMenuClose={handleMobileMenuClose}
              />
            </>
          )}
        </DisableToggle>
        {!auth.user?.isCoach && (
          <NoUnderlineRouterLink
            onClick={handleMobileMenuClose}
            to={AppRouteService.getRelativeUrl('BecomeACreator')}
          >
            <Accordion
              className={mobileMenuClasses.menuItem}
              // expanded={expanded === 'coachPanel'}
              // onChange={handleChange('coachPanel')}
            >
              <AccordionSummary
                aria-controls="coachPanel-content"
                // expandIcon={<ExpandMoreIcon />}
                id="coachPanel-header"
              >
                <Typography
                  className={mobileMenuClasses.heading}
                  style={{ textDecoration: isOnCoachingPage ? 'underline' : undefined }}
                >
                  Become a Creator
                </Typography>
                <Typography
                  className={mobileMenuClasses.secondaryHeading}
                  style={{ textDecoration: isOnCoachingPage ? 'underline' : undefined }}
                >
                  Make money doing what you love
                </Typography>
              </AccordionSummary>
            </Accordion>
          </NoUnderlineRouterLink>
          //   <AccordionDetails>
          //     <Box display="flex" flexDirection="column" width="100%">
          //       <MenuItem
          //         component={NavLink}
          //         onClick={handleMobileMenuClose}
          //         style={{ color: 'inherit' }}
          //         to={AppRouteService.getRelativeUrl('User_Business_BecomeCoach')}
          //       >
          //         Become a Coach
          //       </MenuItem>
          //       <MenuItem
          //         component={NavLink}
          //         key={3}
          //         onClick={handleMobileMenuClose}
          //         style={{ color: 'inherit' }}
          //         to={AppRouteService.getRelativeUrl('Organizer_Start')}
          //       >
          //         Become an Organizer
          //       </MenuItem>
          //     </Box>
          //   </AccordionDetails>
          // </Accordion>
        )}
        {auth.user && !auth.user.isCoach && (
          <Box mt={0.5}>
            <SetupCoach handleCloseMenu={handleMobileMenuClose} />
          </Box>
        )}
      </Box>
      <AuthContainer handleMobileMenuClose={handleMobileMenuClose} />
    </Box>
  );
};

export default MobileMenu;
