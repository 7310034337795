import React, { createContext, useContext, useState } from 'react';

import { ReactFCC } from '@/lib/type-defs/utility';

export interface IRegistrationLayoutContext {
  BackButton: React.ReactElement | null;
  setBackButton: React.Dispatch<React.SetStateAction<React.ReactElement | null>>;
  NextButton: React.ReactElement | null;
  setNextButton: React.Dispatch<React.SetStateAction<React.ReactElement | null>>;
}

export const RegistrationLayoutContext = createContext<IRegistrationLayoutContext | null>(null);

export const RegistrationLayoutProvider: ReactFCC = ({ children }) => {
  const [BackButton, setBackButton] = useState<React.ReactElement | null>(null);
  const [NextButton, setNextButton] = useState<React.ReactElement | null>(null);

  return (
    <RegistrationLayoutContext.Provider
      value={{
        BackButton,
        setBackButton,
        NextButton,
        setNextButton,
      }}
    >
      {children}
    </RegistrationLayoutContext.Provider>
  );
};

export const useRegistrationLayoutContext = (): IRegistrationLayoutContext =>
  useContext(RegistrationLayoutContext) as IRegistrationLayoutContext;
