import React, { useState } from 'react';
import { ListSubheader, makeStyles, Theme, Typography } from '@material-ui/core';
import cuid from 'cuid';

import { ReactFCC } from '@/lib/type-defs/utility';

export interface MenuListSubheaderProps {
  id?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  typography: {
    fontWeight: 600,
    color: theme.palette.grey[500],
  },
}));

const MenuListSubheader: ReactFCC<MenuListSubheaderProps> = ({ children, id: providedId }) => {
  const classes = useStyles();
  const [id] = useState(providedId || cuid());

  return (
    <ListSubheader component="div" id={id}>
      <Typography className={classes.typography} variant="overline">
        {children}
      </Typography>
    </ListSubheader>
  );
};

export default MenuListSubheader;
