/**
 * A Material UI TextField that can be controlled or uncontrolled using react-hook-form
 */
import React, { ChangeEvent, FocusEvent, ReactElement } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { CircularProgress, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';
import * as R from 'ramda';

import { useExtendedTextFieldStyles } from '@/ui/TextField/ExtendedTextField';

import RingCounter from './RingCounter';

import { pathFromString } from '@/lib/string-utils';

export interface FormTextFieldProps<T> {
  isLoading?: boolean;
  minWidth?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'none';
  control: Control<T>;
  size?: 'small';
  zeroWidth?: boolean;
  getValues?: UseFormGetValues<T>;
  rules?: RegisterOptions;
  name: string;
}

function FormTextField<T>({
  autoComplete,
  className,
  control,
  disabled,
  getValues,
  isLoading,
  minWidth,
  name,
  rules,
  size,
  zeroWidth,
  ...rest
}: FormTextFieldProps<T> & TextFieldProps): ReactElement {
  const classes = useExtendedTextFieldStyles();
  if (rest.inputProps?.maxLength && !getValues) {
    Error('Please pass getValues if you are using react-hook-form control with a maxLength');
  }

  const renderTextField = ({
    onBlur,
    onChange,
    ref,
    value,
  }: {
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
  }): ReactElement => {
    return (
      <TextField
        {...rest}
        InputProps={{
          ...rest.InputProps,
          endAdornment: isLoading ? (
            <CircularProgress className={classes.loadingIndicator} size={32} />
          ) : rest.InputProps?.endAdornment ? (
            rest.InputProps?.endAdornment
          ) : rest.inputProps?.maxLength && getValues ? (
            <RingCounter
              maxChars={rest.inputProps.maxLength}
              numChars={
                R.path<string[] | undefined>(pathFromString(name), getValues())?.length ?? 0
              }
            />
          ) : null,
        }}
        autoComplete={autoComplete || 'off'}
        className={classNames(
          {
            [classes.sizeSmall]: size === 'small',
            [classes.tinyMinWidth]: minWidth === 'tiny',
            [classes.xSmallMinWidth]: minWidth === 'xs',
            [classes.smallMinWidth]: minWidth === 'sm',
            [classes.regularMinWidth]: minWidth === 'md',
            [classes.largeMinWidth]: minWidth === 'lg',
          },
          className,
        )}
        disabled={isLoading || disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        style={zeroWidth ? { ...rest.style, width: 0 } : rest.style}
        value={value}
      />
    );
  };

  return (
    <Controller
      control={control}
      defaultValue={rest.defaultValue ?? ''}
      name={name}
      // Call normal props and react-hook-form-props
      render={({ field: { onBlur, onChange, ref, value } }): ReactElement => {
        return renderTextField({
          ref,
          onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            onBlur();
            if (rest.onBlur) {
              rest.onBlur(e);
            }
          },
          onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            onChange(e);
            if (rest.onChange) {
              rest.onChange(e);
            }
          },
          value,
        });
      }}
      rules={rules}
    />
  );
}

export default FormTextField;
