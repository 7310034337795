/**
 * A Material UI TextField that can be controlled or uncontrolled using react-hook-form
 */
import React from 'react';
import { CircularProgress, makeStyles, TextField, Theme } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';

import RingCounter from './RingCounter';

export const useExtendedTextFieldStyles = makeStyles((theme: Theme) => ({
  loadingIndicator: {
    height: theme.spacing(4),
    position: 'absolute',
    right: theme.spacing(1),
    width: theme.spacing(4),
  },
  sizeSmall: {
    '& .MuiInputBase-input': {
      // lineHeight: 0,
      paddingTop: '9.5px !important',
      paddingBottom: '9.5px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      paddingTop: '8.5px !important',
      paddingBottom: '8.5px !important',
      '& .MuiInputBase-input': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 15px)',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 10px) scale(1)',
      // transform: 'translate(14px, 12px) scale(1)',
    },
  },
  noOutline: {
    '& .MuiInputBase-root': {
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
  },
  noOuterMargin: {
    '&.MuiFormControl-marginDense': {
      margin: '0',
    },
  },
  tinyMinWidth: {
    minWidth: 80,
  },
  xSmallMinWidth: {
    minWidth: 120,
  },
  smallMinWidth: {
    minWidth: 184,
  },
  regularMinWidth: {
    minWidth: 208,
  },
  largeMinWidth: {
    minWidth: 240,
  },
}));

export interface ExtendedTextFieldProps {
  // Should we show a loading indicator?
  isLoading?: boolean;
  // If we have a max length but we don't want the ring counter
  hideRingCounter?: boolean;
  minWidth?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'none';
  // No underline or outline. A naked input
  noOutline?: boolean;
  noOuterMargin?: boolean;
  size?: 'small';
  zeroWidth?: boolean;
}
const ExtendedTextField: React.FC<ExtendedTextFieldProps & TextFieldProps> = React.forwardRef(
  (
    {
      autoComplete,
      className,
      disabled,
      hideRingCounter,
      isLoading,
      minWidth,
      name,
      noOuterMargin,
      noOutline,
      size,
      title,
      zeroWidth,
      ...rest
    },
    ref,
  ) => {
    const classes = useExtendedTextFieldStyles();


    return (
      <TextField
        ref={ref}
        {...rest}
        InputProps={{
          ...rest.InputProps,
          endAdornment: isLoading ? (
            <CircularProgress className={classes.loadingIndicator} size={32} />
          ) : rest.inputProps?.maxLength && rest.value && !hideRingCounter ? (
            <RingCounter
              maxChars={rest.inputProps.maxLength}
              numChars={
                typeof rest.value === 'string'
                  ? rest.value.length
                  : // Error
                    0
              }
            />
          ) : (
            rest.InputProps?.endAdornment
          ),
        }}
        autoComplete={autoComplete || 'off'}
        className={classNames(
          {
            [classes.sizeSmall]: size === 'small',
            [classes.tinyMinWidth]: minWidth === 'tiny',
            [classes.xSmallMinWidth]: minWidth === 'xs',
            [classes.smallMinWidth]: minWidth === 'sm',
            [classes.regularMinWidth]: minWidth === 'md',
            [classes.largeMinWidth]: minWidth === 'lg',
            [classes.noOutline]: noOutline,
            [classes.noOuterMargin]: noOuterMargin,
          },
          className,
        )}
        disabled={isLoading || disabled}
        name={name}
        style={zeroWidth ? { ...rest.style, width: 0 } : rest.style}
        title={title}
      />
    );
  },
);

export default ExtendedTextField;
