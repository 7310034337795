import { Theme } from '@material-ui/core';


export const MuiButtonBase = (theme: Theme): Record<string, unknown> => ({
  root: {
    '&.MuiPickersDay-root': {
      fontWeight: 600,
    },
    '&.MuiPickersDay-root.Mui-disabled .MuiPickersDay-dayLabel': {
      color: 'rgb(176, 176, 176) !important',
      fontWeight: 400,
      textDecoration: 'line-through',
    },
  },
});
