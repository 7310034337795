import { Theme } from '@material-ui/core';

export const MuiTable = (theme: Theme): Record<string, unknown> => ({
  root: {
    '& .MuiTableSortLabel-root': {
      whiteSpace: 'nowrap',
    },
    '& .MuiTableSortLabel-icon': {
      marginTop: '-2px',
      fontSize: '14px',
    },
    '& .MuiTableRow-root': {
      // transition: 'all 300ms ease 0s, background-color 0ms !important',
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: 'none',
    },
    '& .MuiTableRow-root:hover': {
      // backgroundColor: theme.palette.grey.A100,
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.grey.A100,
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      color: '#1a1f36',
      fontSize: '12px',
      lineHeight: '20px',
      padding: '8px 4px',
      // textTransform: 'uppercase',
    },
    '& .MuiTableCell-root:not(.MuiTableCell-head):not(.MuiTableCell-paddingNone)': {
      padding: theme.spacing(0.5),
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
});
