/*
Hooks used for the showSetupCoach toggle
 */

import { graphql, useMutation } from 'react-relay/hooks';
import { RecordSourceProxy } from 'relay-runtime';

import { setupCoachMutations_hideSetupCoach_Mutation } from '@/containers/User/UserAvatar/__generated__/setupCoachMutations_hideSetupCoach_Mutation.graphql';
import { useAuth } from '@/providers/AuthProvider';

export const useHideSetupCoachMutation = (): (() => void) => {
  const auth = useAuth();
  const [commitMutation] = useMutation<setupCoachMutations_hideSetupCoach_Mutation>(
    graphql`
      mutation setupCoachMutations_hideSetupCoach_Mutation($userId: String!) {
        update_user(where: { id: { _eq: $userId } }, _set: { showSetupCoach: false }) {
          returning {
            showSetupCoach
          }
        }
      }
    `,
  );
  return (): void => {
    commitMutation({
      variables: {
        userId: auth.firebaseUser!.uid,
      },
      optimisticUpdater: (store: RecordSourceProxy) => {
        const userRecord = store.get(auth.user.id);
        if (!userRecord) {
          return;
        }
        userRecord.setValue(false, 'showSetupCoach');
      },
    });
  };
};
