import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import '@/../public/css/bike.css';
import '@/../public/css/loading-bounce.css';

import { getRandomArrayElement } from '@/lib/object-utils';

const LOADING_MESSAGES = [
  'Doing our pre-ride checks...',
  'Pulling up to the trail head...',
  'Waiting for that last person to show up for the ride...',
  'Lubing our chain...',
  'Finding the good lines...',
  'Just resting for a second, we promise...',
  'Grabbing a few extra snacks...',
  'Doing a quick bolt check...',
  'Looking for a misplaced multitool...',
  'Getting a few leaves off the trail...',
  'Getting our helmet and pads on...',
  'Checking our tire pressure...',
  'Consulting the trail guide...',
  'Filling up our water bottle...',
  'Doing some last minute stretches...',
  'Making sure our seat height is perfect...',
  'Admiring your bike...',
];
const LoadingBounce: React.FC<{ title?: string }> = ({ title }) => {
  const [loadingMessage, setLoadingMessage] = useState(getRandomArrayElement(LOADING_MESSAGES));

  const shuffleMessage = useCallback(() => {
    setLoadingMessage(getRandomArrayElement(LOADING_MESSAGES));
  }, []);

  /**
   * Create dot dot dot loading animation
   * Uncomment to use
   */
  // const [dotAnimation, setDotAnimation] = useState('.');
  // useEffect(() => {
  //   let counter = 0;
  //   const intervalId = setInterval(() => {
  //     counter += 1;
  //     setDotAnimation('.'.repeat(counter % 4));
  //   }, 1200);
  //   return (): void => clearInterval(intervalId);
  // }, []);

  /**
   * Shuffle message
   */
  useEffect(() => {
    const intervalID = setInterval(shuffleMessage, 4500);
    return (): void => clearInterval(intervalID);
  }, [shuffleMessage]);

  return (
    <div>
      {title && (
        <Typography align="center" style={{ paddingBottom: '8px' }} variant="h5">
          {title}
        </Typography>
      )}
      <Box display="flex" flexDirection="column" m={4}>
        <div className="bounce-container">
          <div className="dash uno" />
          <div className="dash dos" />
          <div className="dash tres" />
          <div className="dash cuatro" />
        </div>
      </Box>
      <Typography align="center">{loadingMessage}</Typography>
    </div>
  );
};

export default LoadingBounce;
