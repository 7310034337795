import { matchPath } from 'react-router';
import invariant from 'invariant';
import { difference, equals } from 'ramda';

import {
  ApiRouteName,
  ApiRoutes,
  AppDirectories,
  AppDirectoryName,
  AppRole,
  AppRouteName,
  AppRoutes,
  RouteDomain,
  RouteMetadata,
  RouteMetadataLabel,
  RouterMetadata,
} from './route-metadata';

import ENV from '@/env';
import { getParsedQueryParams, stringifyQueryParams } from '@/lib/path-helpers';

const DIRECTORY_PATTERN = /^\/[\w-/:]*[\w]+$/;
const PATH_PATTERN = /^(\/(([a-zA-Z][\w-]*)|(:[\w]+))(\/(([a-zA-Z][\w-]*)|(:[\w]+)))*)|\/$/;
const PATH_VARIABLE_PATTERN = /(\/:[a-zA-Z][\w]*)/g;
const LABEL_VARIABLE_PATTERN = /{[a-zA-Z][\w]*?}/g;

const subPathArgsInUrl = (url: string, [pathArgName, pathArgValue]: [string, string]): string => {
  const variableRegex = new RegExp(`:${pathArgName}`, 'g');
  return url.replace(variableRegex, pathArgValue);
};

const subLabelArgsInLabel = (
  label: string,
  [labelArgName, labelArgValue]: [string, string],
): string => {
  const variableRegex = new RegExp(`{${labelArgName}}`);
  return label.replace(variableRegex, labelArgValue);
};

const removePathVarDelimeters = (pathVar: string): string => pathVar.replace('/:', '');
const removeLabelVarDelimeters = (labelVar: string): string =>
  labelVar.substring(1, labelVar.length - 1);

const removeTrailingSlash = (path: string): string =>
  path.endsWith('/') && path.length > 1 ? path.substring(0, path.length - 1) : path;

/**
 * Route Service
 */
export class RouteService<T extends keyof RouteDomain, U extends keyof Record<string, string>> {
  private domain: string;

  private routes: RouteDomain;

  private directories: Record<string, string>;

  constructor(domain: string, routes: RouteDomain, directories?: Record<string, string>) {
    this.domain = domain.endsWith('/') ? domain.substring(0, domain.length - 1) : domain;

    if (directories) {
      RouteService.validateDirectories(directories);
      this.directories = directories;
    } else {
      this.directories = {};
    }

    RouteService.validateRoutes(routes);
    this.routes = routes;
  }

  private static getPath(router: RouterMetadata): string {
    const { directory, path } = router;
    const fullPath = directory ? `${directory}${path}` : path;
    return removeTrailingSlash(fullPath);
  }

  private static validateDirectories(directories: Record<string, string>): void {
    const entries = Object.entries(directories);
    entries.forEach(([dirName, dirPath]) => {
      invariant(
        DIRECTORY_PATTERN.test(dirPath),
        `invalid directory path for ${dirName}: ${dirPath}`,
      );

      // validate that subdirectories are properly named and nested
      const nameParts = dirName.split('_');
      nameParts.reduce((parentDirName, dirNamePart, i): string => {
        const currentDirName = !parentDirName ? dirNamePart : `${parentDirName}_${dirNamePart}`;
        const currentDirPath = directories[currentDirName];
        invariant(
          currentDirPath,
          `expected to find a directory path specified for '${currentDirName}'`,
        );

        const isFirstPass = i === 0;
        if (!isFirstPass) {
          const parentDirPath = directories[parentDirName];
          invariant(
            parentDirPath,
            `expected to find a directory path specified for '${parentDirName}'`,
          );

          invariant(
            currentDirPath.startsWith(parentDirPath),
            `'${currentDirName}' is a subdirectory of '${parentDirName}', so expected '${currentDirPath}' to be under '${parentDirPath}`,
          );
        }

        return currentDirName;
      }, '');
    });
  }

  private static validateRoutes(routes: RouteDomain): void {
    const routeNames = Object.keys(routes);
    routeNames.forEach((routeName) => {
      const { router } = routes[routeName];
      const path = RouteService.getPath(router);

      invariant(PATH_PATTERN.test(path), `invalid path for ${routeName}: ${path}`);
    });
  }

  private static validatePathVariables(
    metadata: RouteMetadata,
    pathArgs?: Record<string, string>,
  ): void {
    const path = RouteService.getPath(metadata.router);
    const pathVars = path.match(PATH_VARIABLE_PATTERN)?.map(removePathVarDelimeters) || [];
    const pathArgKeys = Object.keys(pathArgs || {});

    const pathVarsNotFoundInArgs = difference(pathVars, pathArgKeys);
    const pathArgsNotFoundInVars = difference(pathArgKeys, pathVars);

    invariant(
      pathVarsNotFoundInArgs.length === 0,
      `Path (${path}) has missing args: ${pathVarsNotFoundInArgs}`,
    );
    invariant(
      pathArgsNotFoundInVars.length === 0,
      `Path (${path}) has args provided that don't match path variables: ${pathArgsNotFoundInVars}`,
    );
  }

  private static validateLabelVariables(label: string, labelArgs?: Record<string, string>): void {
    const labelVars = label.match(LABEL_VARIABLE_PATTERN)?.map(removeLabelVarDelimeters) || [];
    const labelArgKeys = Object.keys(labelArgs || {});

    const labelVarsNotFoundInArgs = difference(labelVars, labelArgKeys);
    const labelArgsNotFoundInVars = difference(labelArgKeys, labelVars);

    invariant(
      labelVarsNotFoundInArgs.length === 0,
      `Label (${label}) has missing args: ${labelVarsNotFoundInArgs}`,
    );
    invariant(
      labelArgsNotFoundInVars.length === 0,
      `Label (${label}) has args provided that don't match label variables: ${labelArgsNotFoundInVars}`,
    );
  }

  getDomain(): string {
    return this.domain;
  }

  getRelativeUrl(
    routeName: T,
    pathArgs?: Record<string, string>,
    queryArgs?: Record<string, boolean | number | string | string[]>,
  ): string {
    const metadata = this.routes[routeName];
    invariant(metadata, `Route '${routeName}' not found.`);
    RouteService.validatePathVariables(metadata, pathArgs);
    const path = RouteService.getPath(metadata.router);

    let url = path;
    if (pathArgs) {
      url = Object.entries(pathArgs).reduce(subPathArgsInUrl, url);
    }

    if (queryArgs) {
      const search = stringifyQueryParams(queryArgs);
      url += `?${search}`;
    }

    return url;
  }

  getAbsoluteUrl(
    routeName: T,
    pathArgs?: Record<string, string>,
    queryArgs?: Record<string, boolean | number | string | string[]>,
  ): string {
    const relativeUrl = this.getRelativeUrl(routeName, pathArgs, queryArgs);
    return `${this.domain}${relativeUrl}`;
  }

  getRouteValidRoles(routeName: T): readonly AppRole[] {
    const metadata = this.routes[routeName];
    invariant(metadata, `Route '${routeName}' not found.`);
    if (metadata.validRoles) {
      return metadata.validRoles;
    }
    return [];
  }

  matchesRoute(
    routeName: T,
    // Value of pathArg record: https://reactrouter.com/en/main/utils/match-path
    // May contain :id-style segments to indicate placeholders for dynamic parameters.
    // May also end with /* to indicate matching the rest of the URL pathname.
    pathArgs?: Record<string, string>,
    queryArgs?: Record<string, boolean | number | string | string[]>,
  ): boolean {
    const metadata = this.routes[routeName];
    invariant(metadata, `Route '${routeName}' not found.`);
    RouteService.validatePathVariables(metadata, pathArgs);
    const path = RouteService.getPath(metadata.router);

    let url = path;
    if (pathArgs) {
      url = Object.entries(pathArgs).reduce(subPathArgsInUrl, url);
    }

    const matchesPath = matchPath(url, window.location.pathname);
    if (queryArgs) {
      const parsedQueryParams = getParsedQueryParams();
      return !!matchesPath && equals(parsedQueryParams, queryArgs);
    }

    return !!matchesPath;
  }

  private getLabelHelper(
    labelType: RouteMetadataLabel,
    routeName: T,
    labelArgs?: Record<string, string>,
  ): string {
    const metadata = this.routes[routeName];
    invariant(metadata, `Route '${routeName}' not found.`);
    let label = metadata[labelType] ?? metadata.label;
    RouteService.validateLabelVariables(label, labelArgs);

    if (labelArgs) {
      label = Object.entries(labelArgs).reduce(subLabelArgsInLabel, label);
    }

    return label;
  }

  getLabel(routeName: T, labelArgs?: Record<string, string>): string {
    return this.getLabelHelper('label', routeName, labelArgs);
  }

  getBreadcrumbLabel(routeName: T, labelArgs?: Record<string, string>): string {
    return this.getLabelHelper('breadcrumbLabel', routeName, labelArgs);
  }

  getRouterDirectoryPath(dirName: U): string {
    const dirPath = this.directories[dirName];
    invariant(dirPath, `'${dirName}' not found`);

    const dirNameParts = dirName.split('_');
    let parentDirPath = '';
    const hasParentDir = dirNameParts.length > 1;
    if (hasParentDir) {
      const parentDirName = dirNameParts.slice(0, dirNameParts.length - 1).join('_');
      parentDirPath = this.directories[parentDirName];
    }

    return `${dirPath.replace(parentDirPath, '')}/*`;
  }

  getRouterRoutePath(routeName: T): string {
    const metadata = this.routes[routeName];
    invariant(metadata, `Route '${routeName}' not found.`);
    return metadata.router.path;
  }
}

export const ApiRouteService = new RouteService<ApiRouteName, never>(
  ENV.PUBLIC_FUNCTIONS_DOMAIN,
  ApiRoutes,
);
export const AppRouteService = new RouteService<AppRouteName, AppDirectoryName>(
  ENV.APP_URL,
  AppRoutes,
  AppDirectories,
);
