/*
Add global loading backdrop that pauses interaction with the page
 */
import React, { useEffect } from 'react';
import { Backdrop, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LoadingBounce from '@/ui/LoadingPlaceholders/LoadingBounce';

import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  body: {
    overflow: 'hidden',
  },
  container: {
    backgroundColor: 'white',
    alignItems: 'center',
    border: '1px solid #0000000d',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[23],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '400px',
    padding: theme.spacing(4),
    width: '100%',
    '& p': {
      color: Colors.TextColor,
    },
  },
}));

interface GlobalLoadingBackdropProps {
  isLoading: boolean;
  title?: string;
}
const GlobalLoadingBackdrop: React.FC<GlobalLoadingBackdropProps> = ({ isLoading, title }) => {
  const classes = useStyles();

  /**
   * Remove scrollbar when loading
   */
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (!body) {
      return (): void => undefined;
    }
    if (isLoading) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'initial';
    }
    return (): void => {
      if (body) {
        body.style.overflow = 'initial';
      }
    };
  }, [isLoading]);

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <div className={classes.container}>
        <LoadingBounce title={title} />
      </div>
    </Backdrop>
  );
};
export default GlobalLoadingBackdrop;
