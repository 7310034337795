import React, { ReactNode } from 'react';

import { ONE_HOUR_IN_MINUTES } from '@/lib/date-helpers/date-utils';
import {
  correctlyTypeRange,
  correctlyTypeStringArray,
  getAbilityLabelFull,
} from '@/lib/slider-utils';
import { arrayToCommaAndString } from '@/lib/string-utils';

/**
 * Get lesson summary node
 */
type LessonType = {
  duration: number;
  abilityLevelRange: [number, number] | Record<string, unknown> | unknown[];
  disciplineNames: string[] | Record<string, unknown> | unknown[] | undefined | null;
  skills: string[] | Record<string, unknown> | unknown[];
};
export const getLessonSummaryNode = (lesson: LessonType): ReactNode => {
  const shouldUseMinutes = lesson.duration < ONE_HOUR_IN_MINUTES;
  return (
    <>
      This{' '}
      <b>
        {shouldUseMinutes ? lesson.duration : lesson.duration / ONE_HOUR_IN_MINUTES}{' '}
        {shouldUseMinutes ? 'minute' : 'hour'}
      </b>{' '}
      lesson teaches{' '}
      <b>
        {getAbilityLabelFull({
          abilityLevelRange: correctlyTypeRange(lesson.abilityLevelRange),
          longFormInsteadOfOpen: true,
          parenthesesInsteadOfDots: true,
        })}
      </b>{' '}
      level
      {correctlyTypeStringArray(lesson.disciplineNames ?? []).length ? (
        <>
          {' '}
          <b>{arrayToCommaAndString(correctlyTypeStringArray(lesson.disciplineNames ?? []))}</b>
        </>
      ) : null}{' '}
      riders
      {correctlyTypeStringArray(lesson.skills).length ? (
        <>
          {' '}
          about <b>{arrayToCommaAndString(correctlyTypeStringArray(lesson.skills))}</b>
        </>
      ) : null}
      .
    </>
  );
};
