import React, { ReactNode } from 'react';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import DialogCloseButton from '@/ui/Button/DialogCloseButton';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import BoxShadowScrollIndicator from '@/hoc/BoxShadowScrollIndicator';

import { ReactFCC } from '@/lib/type-defs/utility';
import { useIsSmallScreenDown } from '@/lib/ui-utils';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    // maxWidth: 850,
    // maxWidth: 650,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      height: '100%',
      margin: 0,
      maxHeight: 'none',
      width: '100%',
    },
  },
}));

interface DialogWithSaveProps {
  closeDialog: () => void;
  isDialogOpen: boolean;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
  handleSave: () => void;
  noMarginBottom?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  title: ReactNode;
  saveText?: string;
  scrollRef?: React.MutableRefObject<HTMLDivElement | undefined>;
}
const DialogWithSave: ReactFCC<DialogWithSaveProps> = ({
  children,
  closeDialog,
  handleSave,
  isDialogOpen,
  isLoading,
  isSaveDisabled,
  maxWidth = 'sm',
  noMarginBottom,
  saveText = 'Save',
  scrollRef,
  title,
}) => {
  const classes = useStyles();
  const isSmallScreenDown = useIsSmallScreenDown();

  return (
    <Dialog
      disableBackdropClick
      classes={{ paper: classes.dialog }}
      maxWidth={maxWidth}
      onClose={closeDialog}
      open={isDialogOpen}
    >
      <Box p={3} pb={2.5}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <DialogCloseButton
        handleClose={closeDialog}
        iconButtonProps={{ style: { padding: '16px' } }}
      />
      <BoxShadowScrollIndicator
        noBorder
        refCallback={(ref): void => {
          if (ref && scrollRef) {
            scrollRef.current = ref;
          }
        }}
      >
        <DialogContent dividers>
          <Box mb={noMarginBottom ? 0 : 4}>{children}</Box>
        </DialogContent>
      </BoxShadowScrollIndicator>
      <Box display="flex" justifyContent="space-between" px={3} py={1.5}>
        <Box mr={1}>
          <ExtendedButton
            minWidth={isSmallScreenDown ? 'xs' : 'sm'}
            onClick={closeDialog}
            variant="outlined"
          >
            Cancel
          </ExtendedButton>
        </Box>
        <ExtendedButton
          color="primary"
          disabled={isSaveDisabled}
          isLoading={isLoading}
          minWidth={isSmallScreenDown ? 'xs' : 'sm'}
          onClick={handleSave}
          variant="contained"
        >
          {saveText}
        </ExtendedButton>
      </Box>
    </Dialog>
  );
};

export default DialogWithSave;
