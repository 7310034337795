import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { isPresent } from './type-defs/utility';

import { formatNumberAsCurrency } from '@/lib/currency-utils';

export const PHONE_REG_EXP = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;
// export const URL_REG_EXP = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i;
export const URL_REG_EXP = /(?:https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/i;

/**
 * Remove all whitespace and repalce it with a single space.
 * e.g. no double spaces, new lines, etc
 */
export const removeExtraWhitespace = (stringToTransform: string): string => {
  return stringToTransform.replace(/\s\s+/g, ' ');
};

/**
 * Check an array of strings against a string to see if it exists
 */
export const stringContainsArrayItem = (container: string, arrayToCheck: string[]): boolean =>
  R.any((item) => R.includes(item, container), arrayToCheck);

// ['apple'] = 'apple'
// ['apple', 'pear'] = 'apple or pear'
// ['apple', 'pear', 'orange'] = 'apple, pear, and orange'
export const arrayToCommaString = (
  arrayToConvert: (string | number)[],
  connectingWord?: string,
): string => {
  if (!R.length(arrayToConvert)) {
    return '';
  }
  if (R.length(arrayToConvert) === 1) {
    return String(arrayToConvert[0]);
  }
  if (R.length(arrayToConvert) === 2) {
    return `${arrayToConvert[0]}${connectingWord ? ` ${connectingWord} ` : ', '}${
      arrayToConvert[1]
    }`;
  }
  return `${R.join(', ', R.init(arrayToConvert))}, ${
    connectingWord ? `${connectingWord} ` : ''
  }${R.last(arrayToConvert)}`;
};

// ['apple'] = 'apple'
// ['apple', 'pear'] = 'apple and pear'
// ['apple', 'pear', 'orange'] = 'apple, pear, and orange'
export const arrayToCommaAndString = (arrayToConvert: (string | number)[]): string =>
  arrayToCommaString(arrayToConvert, 'and');

export const arrayToCommaOrString = (arrayToConvert: (string | number)[]): string =>
  arrayToCommaString(arrayToConvert, 'or');

export const trimAndRemoveNewLines = (stringToClean: string): string => {
  return (stringToClean ?? '').trim().replace(/(\r\n|\n|\r)/gm, '');
};
export const trimAndKeep2NewLines = (stringToClean: string): string => {
  return (stringToClean ?? '').trim().replace(/((?:\r\n|\n|\r){3,})/gm, '\n\n');
};

export const isValidPhoneNumber = (phoneNumber?: string | null): boolean => {
  if (!phoneNumber) {
    return false;
  }
  return PHONE_REG_EXP.test(phoneNumber);
};

/**
 * Remove a character from specific index
 */
export const removeCharAtIndex = (index: number, stringToClean: string): string => {
  return stringToClean
    .split('')
    .filter((char, idx) => {
      if (index < 0) {
        // Check from end
        return idx !== stringToClean.length - index * -1;
      }
      return idx !== index;
    }, stringToClean)
    .join('');
};

/**
 * Count the number of times a string shows up in another string
 */
export const countOccurrencesInString = (stringToCheck: string, stringToMatch: string): number => {
  return (stringToCheck.match(new RegExp(stringToMatch, 'g')) || []).length;
};

/**
 * Get the longest string in a list of strings
 */
export const getLongestString = (stringsToCheck: (string | null | undefined)[]): string => {
  const validStringsToCheck = stringsToCheck.filter(isPresent);
  const longerOfPair = (str1: string, str2: string): string =>
    str1.length >= str2.length ? str1 : str2;
  return validStringsToCheck.reduce(longerOfPair, '');
};

export const capitalizeFirstLetter = (str: string | undefined): string =>
  !str ? '' : `${str[0].toUpperCase()}${str.substring(1)}`;

const stringToBooleanOrUndefined = (
  str: 'true' | 'false' | null | undefined,
): boolean | undefined => {
  if (typeof str === 'undefined') {
    return undefined;
  }
  return str === 'true';
};

const booleanOrUndefinedToString = (
  value: boolean | null | undefined,
): 'true' | 'false' | null | undefined => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return value ? 'true' : 'false';
};

/*
 * 1000 to 1,000
 */
export function addCommasToNumber(numberToChange: string | number): string {
  return Number(numberToChange).toLocaleString();
}

/**
 * Get ordinal numbers
 */
export function getNumberWithOrdinal(numberToChange: number): string {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const endNumber = numberToChange % 100;
  return numberToChange + (suffix[(endNumber - 20) % 10] || suffix[endNumber] || suffix[0]);
}
/**
 * Change a number to a word ordinal
 * 1 = first, 2 = second, 3 = third</
 */
export function getNumberAsWordOrdinalUnder10(numberToChange: number): string {
  const word = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
  ];
  const numberAsWord = word?.[numberToChange];
  if (!numberAsWord) {
    throw new Error(`Number ${numberToChange} is not a valid number. Enter a number under 10`);
  }
  return numberAsWord;
}

/*
 * Append a number to the end of a string id we have a duplicate. If we already have a number
 * appended,
 */
export function addOrIncrementNumberOnDuplicate(stringToIncrement: string): string {
  const hasNumberAppended = /.* - \d+$/g.test(stringToIncrement);
  if (!stringToIncrement) {
    // Empty string
    return stringToIncrement;
  }
  if (stringToIncrement && !hasNumberAppended) {
    // 'Eliot' and 'Eliot'
    return `${stringToIncrement} - 2`;
  }
  // Increment number
  const increment = parseInt(R.last(stringToIncrement), 10) + 1;
  // Remove ' - X'
  const stringWithoutSuffix = stringToIncrement.substring(0, stringToIncrement.length - 4);
  return `${stringWithoutSuffix} - ${increment}`;
}

/*
 * Append a the word 'Copy' to the end of a string
 */
export function appendCopyToEndOfString(string: string): string {
  return `${string} Copy`;
}

/**
 * Convert values[1].value.yo[0][90].yo.yo.yo
 * to
 * ["values", 1, "value", "yo", 0, 90, "yo", "yo", "yo"]
 */
export const pathFromString = (path: string): (string | number)[] => {
  const newPath: (string | number)[] = [];
  const pathArray = path.split(/[[.]/);
  pathArray.forEach((item) => {
    // If we had an index ([0]) our split will return 0] so we need to drop it
    if (R.takeLast(1, item) === ']') {
      newPath.push(parseInt(R.dropLast(1, item), 10));
      return;
    }
    newPath.push(item);
  });
  return newPath;
};

/**
 * Get a range from an array of numbers
 */
export const numberArrayToPriceRange = (
  prices: (number | null)[],
  currencyCode: string,
): string => {
  let priceRange = 'Free';
  if (RA.isNilOrEmpty(prices)) {
    return priceRange;
  }
  const maxNumber = R.apply(Math.max, prices);
  const minNumber = R.apply(Math.min, prices);
  if (minNumber) {
    priceRange = formatNumberAsCurrency(minNumber, currencyCode);
  }
  if (minNumber !== maxNumber) {
    priceRange = `${priceRange} - ${formatNumberAsCurrency(maxNumber, currencyCode)}`;
  }
  return priceRange;
};

/**
 * Turn react-hook-form errors into a string
 */
// export const formErrorsToString = (
//   formState: ReturnType<typeof useForm>['formState'],
//   key: string,
// ): string | undefined => {
//   const formErrors = errors[key];
//   if (!formErrors) {
//     return;
//   }
//   if (RA.isArray(formErrors)) {
//     return arrayToCommaAndString(formErrors.map(error => error.message));
//   }
//   return formErrors.message;
// };
