/**
 * Price Filter for Search Price
 */
import React, { useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as R from 'ramda';

import LinkButton from '@/ui/Button/LinkButton';
import Slider from '@/ui/Slider';

import { SEARCH_PRICE_MIN_MAX, SEARCH_PRICE_STEP } from '@/const';
import {
  useGetSearchFiltersGlobal,
  useSetSearchFiltersGlobal,
} from '@/containers/Search/recoilStore';
import { useSetQueryParams } from '@/lib/path-helpers';

/**
 * Get the label for the price slider
 */
export const priceValueToLabel = (value: string): string => {
  if (value === String(SEARCH_PRICE_MIN_MAX[0])) {
    return 'Free';
  }
  if (value === String(SEARCH_PRICE_MIN_MAX[1])) {
    return `$${String(SEARCH_PRICE_MIN_MAX[1])}+`;
  }
  const intValue = parseInt(value, 10);
  return `$${intValue}`;
};

const EventsPriceFilter: React.FC = () => {
  const setQueryParams = useSetQueryParams();
  const searchFiltersGlobal = useGetSearchFiltersGlobal();
  const setSearchFiltersGlobal = useSetSearchFiltersGlobal();
  const [priceSliderValues, setPriceSliderValues] = useState<number[]>([
    searchFiltersGlobal.priceMin,
    searchFiltersGlobal.priceMax,
  ]);
  const isFiltering = !R.equals(priceSliderValues, SEARCH_PRICE_MIN_MAX);

  /**
   * Handle price slider change
   */
  const handlePriceSliderChange = (values: number[]): void => {
    setPriceSliderValues(values);
  };

  /**
   * Handle price slider change
   */
  const handlePriceSliderFinalChange = (values: number[]): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      priceMin: values[0],
      priceMax: values[1],
    }));
    setQueryParams({
      priceMin: values[0] !== SEARCH_PRICE_MIN_MAX[0] ? values[0] : undefined,
      priceMax: values[1] !== SEARCH_PRICE_MIN_MAX[1] ? values[1] : undefined,
    });
  };

  /**
   * Clear filters
   */
  const handleClearFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      priceMin: SEARCH_PRICE_MIN_MAX[0],
      priceMax: SEARCH_PRICE_MIN_MAX[1],
    }));
    setPriceSliderValues(SEARCH_PRICE_MIN_MAX);
    setQueryParams({
      priceMin: undefined,
      priceMax: undefined,
    });
  };

  return (
    <>
      <Box py={2}>
        <Box alignItems="center" display="flex" height={22}>
          <Typography variant="body2">
            <b>Price</b>
          </Typography>
          {isFiltering && (
            <Box display="flex" ml={1}>
              <LinkButton onClick={handleClearFilters}>Clear</LinkButton>
            </Box>
          )}
        </Box>
        <Box mt={1} px={2}>
          <Slider
            draggableTrack
            small
            max={SEARCH_PRICE_MIN_MAX[1]}
            min={SEARCH_PRICE_MIN_MAX[0]}
            onChange={handlePriceSliderChange}
            onFinalChange={handlePriceSliderFinalChange}
            step={SEARCH_PRICE_STEP}
            valueToLabel={priceValueToLabel}
            values={priceSliderValues}
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default EventsPriceFilter;
