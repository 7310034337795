import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';

import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import ExtendedRoute from '../components/ExtendedRoute';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';

import { AppRouteService } from '../RouteService';

import CyclistProfilePage from '@/pages/Profile/CyclistProfilePage';

const CyclistProfileView = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "CyclistProfileView" */
      '@/containers/CyclistProfile/CyclistProfileView'
    ),
);
const CyclistProfileRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Redirect back home if they go to /cyclist. */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <div>
              <Navigate replace to={AppRouteService.getRelativeUrl('Home')} />
            </div>
          </ExtendedRoute>
        }
        path="/"
      />

      {/* Public Cyclist App */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <CyclistProfilePage>
              <Suspense fallback={<LoadingPlaceholder disableShrink isLoading delay={200} />}>
                <CyclistProfileView />
              </Suspense>
            </CyclistProfilePage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('CyclistProfile')}
      />
    </Routes>
  );
};
export default CyclistProfileRoutes;
