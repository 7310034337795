/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewServiceQueries_getReviews_QueryVariables = {
    reviewSubjectCuid: string;
};
export type ReviewServiceQueries_getReviews_QueryResponse = {
    readonly review_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly updated_at: string;
                readonly reviewer: {
                    readonly firstName: string | null;
                    readonly lastName: string | null;
                    readonly profilePicUrl: string | null;
                    readonly slug: string;
                };
                readonly review: Record<string, unknown> | unknown[];
            };
        }>;
    };
    readonly reviewTotals_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly count: number;
                readonly recAverage: number;
                readonly recPercentage: number;
            };
        }>;
    };
};
export type ReviewServiceQueries_getReviews_Query = {
    readonly response: ReviewServiceQueries_getReviews_QueryResponse;
    readonly variables: ReviewServiceQueries_getReviews_QueryVariables;
};



/*
query ReviewServiceQueries_getReviews_Query(
  $reviewSubjectCuid: String!
) {
  review_connection(where: {reviewSubjectCuid: {_eq: $reviewSubjectCuid}}, order_by: {updated_at: desc}) {
    edges {
      node {
        id
        updated_at
        reviewer {
          firstName
          lastName
          profilePicUrl
          slug
          id
        }
        review
      }
    }
  }
  reviewTotals_connection(where: {reviewSubjectCuid: {_eq: $reviewSubjectCuid}}) {
    edges {
      node {
        count
        recAverage
        recPercentage
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "reviewSubjectCuid"
        } as any
    ], v1 = {
        "fields": [
            {
                "fields": [
                    {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "reviewSubjectCuid"
                    }
                ],
                "kind": "ObjectValue",
                "name": "reviewSubjectCuid"
            }
        ],
        "kind": "ObjectValue",
        "name": "where"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "order_by",
            "value": {
                "updated_at": "desc"
            }
        } as any,
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updated_at",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profilePicUrl",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "review",
        "storageKey": null
    } as any, v10 = [
        (v1 /*: any*/)
    ], v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recAverage",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recPercentage",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_getReviews_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "reviewConnection",
                    "kind": "LinkedField",
                    "name": "review_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "reviewEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "review",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "user",
                                            "kind": "LinkedField",
                                            "name": "reviewer",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v10 /*: any*/),
                    "concreteType": "reviewTotalsConnection",
                    "kind": "LinkedField",
                    "name": "reviewTotals_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "reviewTotalsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "reviewTotals",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ReviewServiceQueries_getReviews_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "reviewConnection",
                    "kind": "LinkedField",
                    "name": "review_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "reviewEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "review",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "user",
                                            "kind": "LinkedField",
                                            "name": "reviewer",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v8 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v10 /*: any*/),
                    "concreteType": "reviewTotalsConnection",
                    "kind": "LinkedField",
                    "name": "reviewTotals_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "reviewTotalsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "reviewTotals",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "57228ea4c1ef3801f75a6a37f9955e7f",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_getReviews_Query",
            "operationKind": "query",
            "text": "query ReviewServiceQueries_getReviews_Query(\n  $reviewSubjectCuid: String!\n) {\n  review_connection(where: {reviewSubjectCuid: {_eq: $reviewSubjectCuid}}, order_by: {updated_at: desc}) {\n    edges {\n      node {\n        id\n        updated_at\n        reviewer {\n          firstName\n          lastName\n          profilePicUrl\n          slug\n          id\n        }\n        review\n      }\n    }\n  }\n  reviewTotals_connection(where: {reviewSubjectCuid: {_eq: $reviewSubjectCuid}}) {\n    edges {\n      node {\n        count\n        recAverage\n        recPercentage\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'af77bfbf9d37b1a6f657bbad2b66bbbe';
export default node;
