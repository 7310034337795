/**
 * Footer for the bottom of the page
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Hidden, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import BrandTypography from '@/ui/BrandTypography';
import RoundTextChip from '@/ui/Chips/RoundTextChip';
import ExtendedLink from '@/ui/ExtendedLink';

import FrillButton from '@/components/Frill/FrillButton';
import TypeformContactUs from '@/components/Typeform/TypeformContactUs';

import { FACEBOOK_SOCIAL_LINK, INSTAGRAM_SOCIAL_LINK, TWITTER_SOCIAL_LINK } from '@/const';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    alignItems: 'center',
    borderTop: Colors.LightBorder,
    display: 'flex',
    flexDirection: 'column',
    padding: '28px 40px',
    marginTop: theme.spacing(4),
    '& a, & a:active, & a:visited, & a:hover': {
      color: '#666',
      textDecoration: 'none',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 0',
    },
  },
  socialLinks: {
    display: 'flex',
    alignItems: 'center',
    fontSize: `${theme.typography.caption.fontSize} !important`,
    '& a': {
      display: 'flex',
    },
  },
  linkButtonText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: `${theme.typography.body2.fontSize} !important`,
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const auth = useAuth();
  const isSmallScreenDown = useIsSmallScreenDown();

  const teachALessonLink = auth?.user?.isCoach
    ? AppRouteService.getRelativeUrl('CoachProfile', { coachSlug: auth.user.slug })
    : AppRouteService.getRelativeUrl('User_BecomeCoach');
  const createOnlineCourse = auth?.user?.isCoach
    ? AppRouteService.getRelativeUrl('User_Business_CreateOnlineCourse_New')
    : AppRouteService.getRelativeUrl('User_BecomeCoach');
  return (
    <Box className={classes.footerContainer}>
      <Container maxWidth="lg">
        <Hidden smUp>
          <Box alignItems="center" display="flex" mb={2}>
            <BrandTypography inline noLink variant="h3">
              Reggy
            </BrandTypography>
            <Box mx={0.5}>
              <Typography variant="body2">by</Typography>
            </Box>
            {/* <ExtendedLink */}
            {/*  noUnderline */}
            {/*  shouldOpenInNewTab */}
            {/*  href="https://instagram.com/eliotjackson" */}
            {/* > */}
            <RoundTextChip backgroundColor={Colors.Blue} title="Eliot Jackson" />
            {/* </ExtendedLink> */}
          </Box>
        </Hidden>
        <Grid container direction="row-reverse" spacing={2}>
          <Grid item sm={6} xs={12}>
            <Box className={classes.linkButtonText} display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column" minWidth={80}>
                <Typography gutterBottom variant="h3">
                  Learn. Ride. Race.
                </Typography>
                <Link to={AppRouteService.getRelativeUrl('Insights')}>
                  <b>Reggy Radio</b>
                </Link>
                <Link to={AppRouteService.getRelativeUrl('Discover')}>
                  {isSmallScreenDown ? 'Online Courses' : 'Online Courses & Clinics'}
                </Link>
                <Link to={AppRouteService.getRelativeUrl('Discover')}>
                  {isSmallScreenDown ? 'Private Lessons' : 'Coaches & Private Lessons'}
                </Link>
                <Link to={AppRouteService.getRelativeUrl('Discover')}>Races & Rides</Link>
                {/* <Link to={AppRouteService.getRelativeUrl('Discover', {}, { activity: 'lesson' })}> */}
                {/*  Lessons */}
                {/* </Link> */}
              </Box>
              <Box display="flex" flexDirection="column" minWidth={80} mx={1}>
                <Typography gutterBottom variant="h3">
                  Organize & Coach
                </Typography>
                <Link to={AppRouteService.getRelativeUrl('Organizer_Start')}>
                  {isSmallScreenDown ? 'Host Events' : 'Host a Clinic, Ride or Race'}
                </Link>
                <Link to={teachALessonLink}>Become a Coach</Link>
                <Link to={teachALessonLink}>Teach a Lesson</Link>
                <Link to={createOnlineCourse}>
                  {isSmallScreenDown ? 'Create a Course' : 'Create an Online Course'}
                </Link>
              </Box>
              <Box display="flex" flexDirection="column" minWidth={80}>
                <Typography gutterBottom variant="h3">
                  Support
                </Typography>
                <Link to={AppRouteService.getRelativeUrl('About')}>About</Link>
                <TypeformContactUs />
                {/* <PopupButton className={classes.typeformButton} id={TYPEFORM_CONTACT_US_FORM_ID}> */}
                {/*  Report a Bug */}
                {/* </PopupButton> */}
                <FrillButton />
                {/* <Link to={AppRouteService.getRelativeUrl('ChangeLog')}>Change Log</Link> */}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box display="flex" flexDirection="column">
              <Hidden xsDown>
                <Box alignItems="center" display="flex">
                  <BrandTypography inline noLink variant="h3">
                    Reggy
                  </BrandTypography>
                  <Box mx={0.5}>
                    <Typography variant="body2">by</Typography>
                  </Box>
                  {/* <ExtendedLink */}
                  {/*  noUnderline */}
                  {/*  shouldOpenInNewTab */}
                  {/*  href="https://instagram.com/eliotjackson" */}
                  {/* > */}
                  <RoundTextChip backgroundColor={Colors.Blue} title="Eliot Jackson" />
                  {/* </ExtendedLink> */}
                </Box>
              </Hidden>

              <Box my={1}>
                <Typography variant="body2">
                  Find the perfect coach, clinic, ride, race or online course for your skill level
                  and make a living by sharing your cycling expertise.
                </Typography>
              </Box>

              <Box className={classes.socialLinks}>
                <Typography color="textSecondary" variant="caption">
                  © {new Date().getFullYear()} Reggy All Rights Reserved.
                </Typography>
                {/* <Box mx={0.5}>·</Box> */}
                {/* <Link to="/about">FAQ</Link> */}
                <Box mx={0.5}>·</Box>
                <Link to={AppRouteService.getRelativeUrl('TermsOfService')}>Terms</Link>
                <Box mx={0.5}>·</Box>
                <Link to={AppRouteService.getRelativeUrl('PrivacyPolicy')}>Privacy</Link>
                <Box mx={0.5}>·</Box>
                <ExtendedLink noUnderline shouldOpenInNewTab href={FACEBOOK_SOCIAL_LINK}>
                  <FacebookIcon />
                </ExtendedLink>
                <Box mx={0.5}>·</Box>
                <ExtendedLink noUnderline shouldOpenInNewTab href={TWITTER_SOCIAL_LINK}>
                  <TwitterIcon />
                </ExtendedLink>
                <Box mx={0.5}>·</Box>
                <ExtendedLink noUnderline shouldOpenInNewTab href={INSTAGRAM_SOCIAL_LINK}>
                  <InstagramIcon />
                </ExtendedLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
