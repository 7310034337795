/*
Does this coach exist
 */
import React, { createContext, useContext } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Navigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { graphql } from 'relay-runtime';

import { CoachProfileCheckerProviderQuery } from '@/containers/CoachProfile/__generated__/CoachProfileCheckerProviderQuery.graphql';
import { useGetCoachSlugFromURL } from '@/containers/CoachProfile/helpers';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

interface ICoachProfileCheckerContext {
  coachCuid: string;
  isOwner: boolean;
}
export const CoachProfileCheckerContext = createContext<ICoachProfileCheckerContext | null>(null);

const CoachProfileCheckerProvider: ReactFCC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const coachSlug = useGetCoachSlugFromURL();
  const coachProfileData = useLazyLoadQuery<CoachProfileCheckerProviderQuery>(
    graphql`
      query CoachProfileCheckerProviderQuery($coachSlug: String!) {
        user_connection(
          where: {
            _and: [
              { slug: { _eq: $coachSlug } }
              { isCoach: { _eq: true } }
              { deleted_at: { _is_null: true } }
            ]
          }
        ) {
          edges {
            node {
              id
              coach {
                cuid
              }
            }
          }
        }
      }
    `,
    {
      coachSlug,
    },
  ).user_connection.edges;

  // No Coach Data
  if (!coachProfileData?.[0]?.node?.coach) {
    enqueueSnackbar('User Not Found', { variant: 'error' });
    return <Navigate replace to={AppRouteService.getRelativeUrl('Search')} />;
  }

  return (
    <CoachProfileCheckerContext.Provider
      value={{
        coachCuid: coachProfileData[0].node.coach.cuid,
        isOwner: coachProfileData[0].node.coach.cuid === auth.user?.coach?.cuid,
      }}
    >
      {children}
    </CoachProfileCheckerContext.Provider>
  );
};

export const useCoachProfileCheckerContext = (): ICoachProfileCheckerContext =>
  useContext(CoachProfileCheckerContext) as ICoachProfileCheckerContext;

export default CoachProfileCheckerProvider;
