/*
Recoil atoms, selectors and hooks for Host Event
 */
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';

import { HostEventSectionName } from './const';

/** ************************************
 * Global UI Properties
 * Is the the navigation window open, etc.
 ************************************* */
// Is the side navigation open
const isSideNavOpenState = atom({
  key: 'isSideNavOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsSideNavOpenGlobal = (): boolean => {
  return useRecoilValue(isSideNavOpenState);
};
export const useSetIsSideNavOpenGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isSideNavOpenState);
};

/** ************************************
 * Global Event Properties
 * Is the event saving, etc.
 ************************************* */
// Is the event saving - Are we writing to the database
const isEventSavingState = atom({
  key: 'isEventSaving', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetIsEventSavingGlobal = (): boolean => {
  return useRecoilValue(isEventSavingState);
};
export const useSetIsEventSavingGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(isEventSavingState);
};

// // Host Event Step - Which section are we on?
// const hostEventStepState = atom({
//   key: 'hostEventStep', // unique ID (with respect to other atoms/selectors)
//   default: 0, // default value (aka initial value)
// });
// export const useGetHostEventStepGlobal = (): number => {
//   return useRecoilValue(hostEventStepState);
// };
// export const useSetHostEventStepGlobal = (): SetterOrUpdater<number> => {
//   return useSetRecoilState(hostEventStepState);
// };

// Does the event have un-saved changes?
const eventHasUnSavedChanges = atom({
  key: 'eventHasUnSavedChanges', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const useGetEventHasUnSavedChangesGlobal = (): boolean => {
  return useRecoilValue(eventHasUnSavedChanges);
};
export const useSetEventHasUnSavedChangesGlobal = (): SetterOrUpdater<boolean> => {
  return useSetRecoilState(eventHasUnSavedChanges);
};

// Current Save Queue
const hostEventSaveQueue = atom({
  key: 'hostEventSaveQueue', // unique ID (with respect to other atoms/selectors)
  default: [] as string[], // default value (aka initial value)
});
export const useGetSaveQueueGlobal = (): string[] => {
  return useRecoilValue(hostEventSaveQueue);
};
export const useSetSaveQueueGlobal = (): SetterOrUpdater<string[]> => {
  return useSetRecoilState(hostEventSaveQueue);
};

/** ************************************
 * Global Event Section Properties
 * What sections are available, how many sections are part of that activity, is a section valid?
 ************************************* */
const SectionValidityDefault = {
  Intro: true,
};
export type SectionValidity = {
  Intro: boolean;
} & Partial<Record<HostEventSectionName, boolean>>;
type SectionInfo = {
  allSectionsForCurrentActivity: HostEventSectionName[];
  availableSections: HostEventSectionName[];
  currentSectionName: HostEventSectionName;
  totalNumberOfSections: number;
  sectionValidity: SectionValidity;
};
export const sectionInfoDefault: SectionInfo = {
  allSectionsForCurrentActivity: ['Intro'],
  availableSections: ['Intro'],
  currentSectionName: 'Intro',
  totalNumberOfSections: ['Intro'].length,
  sectionValidity: SectionValidityDefault,
};
const sectionInfoState = atom<SectionInfo>({
  key: 'sectionInfo', // unique ID (with respect to other atoms/selectors)
  default: sectionInfoDefault, // default value (aka initial value)
});

export const useGetSectionInfoGlobal = (): SectionInfo => {
  return <SectionInfo>useRecoilValue(sectionInfoState);
};
export const useSetSectionInfoGlobal = (): SetterOrUpdater<SectionInfo> => {
  return useSetRecoilState(sectionInfoState);
};
