import { Theme } from '@material-ui/core';

export const MuiAccordion = (theme: Theme): Record<string, unknown> => ({
  root: {
    '&::before': {
      content: 'none',
    },
  },
  rounded: {
    borderRadius: theme.spacing(0.5),
  },
});
