/*
Helper functions for the Coach Profile Lessons Section
 */
import * as R from 'ramda';
/**
 * We need to change a range [1-2] to a person or a group
 */
export const getParticipantTypeText = (participantRange: number[]): string => {
  return 'person'
  // return `person${
  //   R.equals(participantRange, [1, 1])
  //     ? ''
  //     : `, group of ${participantRange?.[0]}-${participantRange[1]}`
  // }`;
};
