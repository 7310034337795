/**
 * A set of hooks that lets us easily get data from the database
 */
import { useMutation } from 'react-relay/hooks';
import { graphql } from 'relay-runtime';

import { helpers_unPublishEventOrganizer_Mutation } from '@/containers/Organizer/__generated__/helpers_unPublishEventOrganizer_Mutation.graphql';
import { useTypedParams } from '@/lib/path-helpers/routing';

/**
 * Make a title for React Helmet for the organizer App
 */
export const makeOrganizerAppTitle = (viewName?: string | undefined): string => {
  return `${viewName ? `${viewName} - ` : ''}Organizer • Reggy`;
};

/**
 * What is the url slug for the current organization
 */
export const useGetOrganizerSlugFromURL = (): string => {
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  return organizerSlug;
};

/**
 * What is the url slug for the current event in the organizer App
 */
export const useGetOrganizerEventSlugFromURL = (): string => {
  const { eventSlug } = useTypedParams(['eventSlug']);
  return eventSlug;
};

/**
 * UnPublish event fired from the Events Table
 */
export const useUnPublishEventOrganizerMutation = (eventCuid: string): (() => void) => {
  const [commitMutation] = useMutation<helpers_unPublishEventOrganizer_Mutation>(
    graphql`
      mutation helpers_unPublishEventOrganizer_Mutation($eventCuid: String!) {
        update_event(
          where: { cuid: { _eq: $eventCuid } }
          _set: { publishedAt: null, lastSavedAt: "now()" }
        ) {
          returning {
            id
            lastSavedAt
            publishedAt
          }
        }
      }
    `,
  );
  return (): void => {
    // Un-Publish event :(
    commitMutation({ variables: { eventCuid } });
  };
};
