/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AuthProviderUpcomingCoachLessonsQueryVariables = {
    coachCuid: string;
};
export type AuthProviderUpcomingCoachLessonsQueryResponse = {
    readonly coachCustomerLessonDate_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly startDate: string;
            };
        }>;
    };
};
export type AuthProviderUpcomingCoachLessonsQuery = {
    readonly response: AuthProviderUpcomingCoachLessonsQueryResponse;
    readonly variables: AuthProviderUpcomingCoachLessonsQueryVariables;
};



/*
query AuthProviderUpcomingCoachLessonsQuery(
  $coachCuid: String!
) {
  coachCustomerLessonDate_connection(where: {_and: [{coachCustomerLesson: {coachCuid: {_eq: $coachCuid}}}, {startDate: {_gte: "now()"}}]}) {
    edges {
      node {
        startDate
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "coachCuid"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_eq",
                                                    "variableName": "coachCuid"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "coachCuid"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "coachCustomerLesson"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "startDate": {
                                    "_gte": "now()"
                                }
                            }
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthProviderUpcomingCoachLessonsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachCustomerLessonDateConnection",
                    "kind": "LinkedField",
                    "name": "coachCustomerLessonDate_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachCustomerLessonDateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coachCustomerLessonDate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AuthProviderUpcomingCoachLessonsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "coachCustomerLessonDateConnection",
                    "kind": "LinkedField",
                    "name": "coachCustomerLessonDate_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "coachCustomerLessonDateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "coachCustomerLessonDate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "48ef23c0a85da3e34275a07f9f68de80",
            "id": null,
            "metadata": {},
            "name": "AuthProviderUpcomingCoachLessonsQuery",
            "operationKind": "query",
            "text": "query AuthProviderUpcomingCoachLessonsQuery(\n  $coachCuid: String!\n) {\n  coachCustomerLessonDate_connection(where: {_and: [{coachCustomerLesson: {coachCuid: {_eq: $coachCuid}}}, {startDate: {_gte: \"now()\"}}]}) {\n    edges {\n      node {\n        startDate\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '780321c9620c23b063078d3d60585182';
export default node;
