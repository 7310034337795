import invariant from 'invariant';

import { MigrationRules, UnknownPartialVersioned } from './Versions';

class VersionedLoader<T extends UnknownPartialVersioned> {
  private loadedVersion: UnknownPartialVersioned;

  private latestVersion: T;

  private migrationRules: MigrationRules;

  private latestVersionNumber: number;

  constructor(
    unknownVersion: UnknownPartialVersioned,
    migrationRules: MigrationRules,
    latestVersionNumber: number,
  ) {
    this.loadedVersion = unknownVersion;
    this.migrationRules = migrationRules;
    this.latestVersionNumber = latestVersionNumber;

    if (this.loadedVersion.version === this.latestVersionNumber) {
      this.latestVersion = this.loadedVersion as T;
      return;
    }

    invariant(
      this.loadedVersion.version < this.latestVersionNumber,
      'loaded version is greater than the latest version',
    );

    let currentVersion = this.loadedVersion;
    while (currentVersion.version < this.latestVersionNumber) {
      const migrateFn = this.migrationRules[currentVersion.version];
      if (migrateFn) {
        currentVersion = migrateFn(currentVersion);
      }
    }
    this.latestVersion = currentVersion as T;
  }

  getLatestVersion(): T {
    return this.latestVersion;
  }

  isLoadedVersionLatest(): boolean {
    return this.loadedVersion.version === this.latestVersion.version;
  }
}

export default VersionedLoader;
