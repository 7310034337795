/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getEventLink_event = {
    readonly cuid: string;
    readonly eventMetadata: {
        readonly slug: string;
    };
    readonly organizer: {
        readonly slug: string;
    };
    readonly " $refType": "helpers_getEventLink_event";
};
export type helpers_getEventLink_event$data = helpers_getEventLink_event;
export type helpers_getEventLink_event$key = {
    readonly " $data"?: helpers_getEventLink_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getEventLink_event">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "helpers_getEventLink_event",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cuid",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "eventMetadata",
                "kind": "LinkedField",
                "name": "eventMetadata",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "organizer",
                "kind": "LinkedField",
                "name": "organizer",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "event",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd28719fabd12cdf52497c6f6305b96bb';
export default node;
