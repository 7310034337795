/**
 * A set of hooks that lets us easily get data from the database
 */
import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useFragment } from 'react-relay/hooks';
import { useParams } from 'react-router';
import useMount from 'react-use/lib/useMount';
import parseISO from 'date-fns/parseISO';
import * as R from 'ramda';
import { graphql } from 'relay-runtime';

import { helpers_getIsEventPublished_event$key } from './__generated__/helpers_getIsEventPublished_event.graphql';
import { helpers_useSyncSectionValidity_event$key } from './__generated__/helpers_useSyncSectionValidity_event.graphql';
import { helpers_useUpdateAlgoliaSearchIndexes_event$key } from './__generated__/helpers_useUpdateAlgoliaSearchIndexes_event.graphql';
import {
  hostCategoriedRideSectionsOrdered,
  hostClinicSectionsOrdered,
  hostClinicVirtualSectionsOrdered,
  HostEventSectionName,
  hostNonCategoriedRideSectionsOrdered,
  hostRaceSectionsOrdered,
  SCROLL_TO_SECTION_QUERY_PARAM,
} from './const';
import {
  SectionValidity,
  useGetSectionInfoGlobal,
  useSetIsEventSavingGlobal,
  useSetSectionInfoGlobal,
} from './recoilStore';

import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import { KEY_CODE, SAVE_MUTATION_DEBOUNCE_DELAY } from '@/const';
import { helpers_getEncodedEventId_event$key } from '@/containers/HostEvent/__generated__/helpers_getEncodedEventId_event.graphql';
import { helpers_getEncodedEventMetadataId_event$key } from '@/containers/HostEvent/__generated__/helpers_getEncodedEventMetadataId_event.graphql';
import { helpers_getEventActivity_event$key } from '@/containers/HostEvent/__generated__/helpers_getEventActivity_event.graphql';
import { helpers_getEventCuid_event$key } from '@/containers/HostEvent/__generated__/helpers_getEventCuid_event.graphql';
import { helpers_getEventLink_event$key } from '@/containers/HostEvent/__generated__/helpers_getEventLink_event.graphql';
import { helpers_getEventSlug_event$key } from '@/containers/HostEvent/__generated__/helpers_getEventSlug_event.graphql';
import { helpers_getOrganizerCuid_event$key } from '@/containers/HostEvent/__generated__/helpers_getOrganizerCuid_event.graphql';
import { helpers_getUserTypeFromActivity_event$key } from '@/containers/HostEvent/__generated__/helpers_getUserTypeFromActivity_event.graphql';
import { useUpdateCompletedSectionsMutation } from '@/containers/HostEvent/hostEventMutations';
import { EventProfileSectionName } from '@/containers/EventProfile/const';
import { dateFromFloatingDateString, dateToMixpanelFormat } from '@/lib/date-helpers/date-utils';
import {
  getEventMinMaxPriceAndIsFree,
  getEventName,
  isActivityClinic,
  isActivityRace,
  isActivityRide,
} from '@/lib/event-info-utils';
import {
  updateAlgoliaCategoriedRideIndexCF,
  updateAlgoliaClinicIndexCF,
  updateAlgoliaNonCategoriedRideIndexCF,
  updateAlgoliaRaceIndexCF,
} from '@/lib/firebase';
import { useDebounceState } from '@/lib/hooks/hooks';
import { getParsedQueryParams } from '@/lib/path-helpers';
import { replacePathNameWithoutReloadingPage, useTypedParams } from '@/lib/path-helpers/routing';
import { CyclistType, CyclistTypePlural, getCyclistTypeFromActivity } from '@/lib/selectors/user';
import { correctlyTypeStringArray } from '@/lib/slider-utils';
import { useHostEventData } from '@/providers/HostEventProvider';
import { activityType_enum } from '@/providers/HostEventProvider/__generated__/HostEventProviderQuery.graphql';
import { AppRouteService } from '@/routes/RouteService';

/**
 * What is the cuid for the current event taken from the url
 */
export const useGetEventCuidFromUrl = (): string => {
  const { eventCuid } = useParams();
  return eventCuid;
};

/**
 * What is the current event cuid?
 */
export const useGetEventCuid = (): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEventCuid_event$key>(
    graphql`
      fragment helpers_getEventCuid_event on event {
        cuid
      }
    `,
    hostEventData,
  );
  return data.cuid;
};

/**
 * What is the current event slug?
 */
export const useGetEventSlug = (): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEventSlug_event$key>(
    graphql`
      fragment helpers_getEventSlug_event on event {
        eventMetadata {
          slug
        }
      }
    `,
    hostEventData,
  );
  return data.eventMetadata.slug;
};

/**
 * What is the url slug in the Host Event App
 */
export const useGetOrganizerSlugFromURL = (): string => {
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  return organizerSlug;
};

/**
 * What is the current event activity?
 */
export const useGetEventActivity = (): activityType_enum => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEventActivity_event$key>(
    graphql`
      fragment helpers_getEventActivity_event on event {
        activity
      }
    `,
    hostEventData,
  );
  return data.activity as activityType_enum;
};

/**
 * Is the current activity a race
 */
export const useGetIsEventRace = (): boolean => {
  const activity = useGetEventActivity();
  return isActivityRace(activity);
};
/**
 * Is the current activity a ride
 */
export const useGetIsEventRide = (): boolean => {
  const activity = useGetEventActivity();
  return isActivityRide(activity);
};
/**
 * Is the current activity a Clinic
 */
export const useGetIsEventClinic = (): boolean => {
  const activity = useGetEventActivity();
  return isActivityClinic(activity);
};

/**
 * What is the current event link?
 * i.e. https://www.goreggy.com/org/eliot/races/usa-national-champs
 */
type AddOccurrenceType = 'add_occurrence';
export const ADD_OCCURRENCE: AddOccurrenceType = 'add_occurrence';
export const useGetEventLink = (addOccurrence?: AddOccurrenceType): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEventLink_event$key>(
    graphql`
      fragment helpers_getEventLink_event on event {
        cuid
        eventMetadata {
          slug
        }
        organizer {
          slug
        }
      }
    `,
    hostEventData,
  );
  const shouldAddOccurrence = addOccurrence === ADD_OCCURRENCE;
  return AppRouteService.getAbsoluteUrl(
    'OrganizerProfile_Event',
    {
      organizerSlug: data.organizer.slug,
      eventSlug: data.eventMetadata.slug,
    },
    shouldAddOccurrence ? { occurrence: data.cuid } : undefined,
  );
};

/**
 * What is the current ENCODED event id?
 */
export const useGetEncodedEventId = (): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEncodedEventId_event$key>(
    graphql`
      fragment helpers_getEncodedEventId_event on event {
        id
      }
    `,
    hostEventData,
  );
  return data.id;
};

/**
 * What is the current ENCODED eventMetadata id?
 */
export const useGetEncodedEventMetadataId = (): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getEncodedEventMetadataId_event$key>(
    graphql`
      fragment helpers_getEncodedEventMetadataId_event on event {
        eventMetadata {
          id
        }
      }
    `,
    hostEventData,
  );
  return data.eventMetadata.id;
};

/**
 * What is the current organizerCuid?
 */
export const useGetOrganizerCuid = (): string => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getOrganizerCuid_event$key>(
    graphql`
      fragment helpers_getOrganizerCuid_event on event {
        organizerCuid
      }
    `,
    hostEventData,
  );
  return data.organizerCuid;
};

/**
 * Is the current event published?
 */
export const useGetIsEventPublished = (): boolean => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getIsEventPublished_event$key>(
    graphql`
      fragment helpers_getIsEventPublished_event on event {
        publishedAt
      }
    `,
    hostEventData,
  );
  return !!data.publishedAt;
};

/**
 * Get user type from activity of event
 */
export const useGetHostEventCyclistType = (): {
  plural: CyclistTypePlural;
  singular: CyclistType;
} => {
  const hostEventData = useHostEventData();
  const data = useFragment<helpers_getUserTypeFromActivity_event$key>(
    graphql`
      fragment helpers_getUserTypeFromActivity_event on event {
        activity
      }
    `,
    hostEventData,
  );
  return getCyclistTypeFromActivity(data.activity);
};

/**
 * Toggle "isEventSaving" globally based on if a mutation is in flight
 */
export const useToggleIsEventSavingGlobal = (isMutationInFlight: boolean): void => {
  const setIsEventSavingGlobal = useSetIsEventSavingGlobal();
  useEffect(() => {
    if (isMutationInFlight) {
      setIsEventSavingGlobal(true);
      return;
    }
    setIsEventSavingGlobal(false);
  }, [isMutationInFlight]);
};

/**
 * Which sections should we display?
 */
function getSectionsByActivity(
  activity: activityType_enum,
  isCategoriedEvent: boolean,
  isVirtualEvent: boolean,
): HostEventSectionName[] {
  switch (activity) {
    case 'Clinic':
      if (isVirtualEvent) {
        return hostClinicVirtualSectionsOrdered;
      }
      return hostClinicSectionsOrdered;
    case 'Ride':
      if (isCategoriedEvent) {
        return hostCategoriedRideSectionsOrdered;
      }
      return hostNonCategoriedRideSectionsOrdered;
    case 'Race':
      return hostRaceSectionsOrdered;
    default:
      return [];
  }
}

/**
 * This hook lets us derive the validity state of a section on the fly.
 * sectionValidity:
 * sectionName doesnt exist = we haven't made it there yet
 * sectionName exists but is false = we have gotten there but section is now invalid
 * sectionName exists and is true = we have gotten there and section is valid
 * When we load an event for the first time, all available sections will be valid by definition
 * of us saving them to the DB
 * By doing it this way we dont have to worry about storing any state in the DB
 */
export const useSyncSectionValidity = (): ((
  isSectionValid: boolean,
  sectionName: HostEventSectionName,
) => void) => {
  const hostEventData = useHostEventData();
  const syncData = useFragment<helpers_useSyncSectionValidity_event$key>(
    graphql`
      fragment helpers_useSyncSectionValidity_event on event {
        cuid
        activity
        startDate
        regCloseDate
        isVirtualEvent
        publishedAt
        currencyCode
        completedSections
        occurrenceLabel
        eventMetadata {
          isCategoriedEvent
          name
        }
        organizer {
          cuid
        }
        categories(where: { deleted_at: { _is_null: true } }, order_by: { position: asc }) {
          entryFee
        }
        rides(where: { canceledAt: { _is_null: true } }) {
          priceOverride
        }
        clinics(where: { canceledAt: { _is_null: true } }) {
          priceOverride
        }
        eventPrograms {
          defaultPrice
        }
      }
    `,
    hostEventData,
  );
  const activity = useGetEventActivity();
  const { trackHostEventSectionCompleted } = useSegmentTrack();
  const setSectionInfoGlobal = useSetSectionInfoGlobal();
  const allSectionsForCurrentActivity = getSectionsByActivity(
    activity,
    syncData.eventMetadata.isCategoriedEvent,
    syncData.isVirtualEvent,
  );
  const updateCompletedSectionsMutation = useUpdateCompletedSectionsMutation();

  useEffect(() => {
    // Make sure this is up-to-date here to guarantee we don't have
    // race conditions when we use it below
    setSectionInfoGlobal((prevState) => ({ ...prevState, allSectionsForCurrentActivity }));
  }, [`${allSectionsForCurrentActivity}`]);

  return (isSectionValid: boolean, sectionName: HostEventSectionName): void =>
    setSectionInfoGlobal((prevState) => {
      const nextSectionName =
        prevState.allSectionsForCurrentActivity?.[
          prevState.allSectionsForCurrentActivity.indexOf(sectionName) + 1
        ];
      const nextSectionValidityExists = prevState.sectionValidity?.[nextSectionName];
      const completedSections = correctlyTypeStringArray(
        syncData.completedSections,
      ) as HostEventSectionName[];

      // Update the current section validity
      const sectionValidity = {
        ...prevState.sectionValidity,
        [sectionName]: isSectionValid,
      };
      // Show the next section if we are validating this one for the first time.
      if (nextSectionName && !nextSectionValidityExists && isSectionValid) {
        sectionValidity[nextSectionName] = false;
      }
      if (isSectionValid && !completedSections.includes(sectionName)) {
        // Update the completed sections in the database
        updateCompletedSectionsMutation([...completedSections, sectionName]);
        // Track completed section in analytics
        const { isFree, maxPrice, minPrice } = getEventMinMaxPriceAndIsFree(syncData);
        trackHostEventSectionCompleted({
          organizerCuid: syncData.organizer.cuid,
          isCategoriedEvent: syncData.eventMetadata.isCategoriedEvent,
          title: getEventName(syncData),
          eventCuid: syncData.cuid,
          activity: syncData.activity,
          minPrice,
          maxPrice,
          isFree,
          currencyCode: syncData.currencyCode,
          isVirtual: syncData.isVirtualEvent,
          startDate: syncData.startDate ? dateToMixpanelFormat(parseISO(syncData.startDate)) : null,
          regCloseDate: syncData.regCloseDate
            ? dateToMixpanelFormat(dateFromFloatingDateString(syncData.regCloseDate))
            : null,
          sectionName,
          sectionNumber: completedSections.length + 1,
        });
      }
      return {
        ...prevState,
        availableSections: Object.keys(sectionValidity) as HostEventSectionName[],
        totalNumberOfSections: Object.keys(sectionValidity).length,
        allSectionsForCurrentActivity: prevState.allSectionsForCurrentActivity,
        sectionValidity,
      };
    });
};

/**
 * Check if all sections in the event are valid
 */
export const useGetEventValidity = (): {
  invalidSections: HostEventSectionName[];
  isEventValid: boolean;
} => {
  const { sectionValidity } = useGetSectionInfoGlobal();
  // Get the validity for the currently displayed sections
  const invalidSections: HostEventSectionName[] = [];
  R.keys<SectionValidity>(sectionValidity).forEach((sectionName) => {
    if (!sectionValidity[sectionName]) {
      invalidSections.push(sectionName);
    }
  });
  // All sections are valid
  return { isEventValid: R.isEmpty(invalidSections), invalidSections };
};

/**
 * Scroll the window to a section. Scrollspy handles highlights the active nav item
 */
export const scrollToSection = (
  sectionName: HostEventSectionName | EventProfileSectionName | undefined,
  behavior?: ScrollBehavior,
): void => {
  if (!sectionName) {
    return;
  }
  const section = document.getElementById(sectionName);
  if (!section) {
    return;
  }
  window.scrollTo({
    top: section.getBoundingClientRect().top + window.scrollY,
    behavior: behavior ?? 'smooth',
  });
};

/**
 * Scroll the window to the previous section.
 */
export const useScrollToPreviousSection = (): (() => void) => {
  const { availableSections, currentSectionName } = useGetSectionInfoGlobal();
  const currentSectionIndex = availableSections.findIndex(
    (sectionName) => sectionName === currentSectionName,
  );
  const nextSectionName = availableSections[currentSectionIndex - 1];
  return (): void => scrollToSection(nextSectionName, 'auto');
};

/**
 * Scroll the window to the next section.
 */
export const useScrollToNextSection = (): (() => void) => {
  const { availableSections, currentSectionName } = useGetSectionInfoGlobal();
  const currentSectionIndex = availableSections.findIndex(
    (sectionName) => sectionName === currentSectionName,
  );
  const nextSectionName = availableSections[currentSectionIndex + 1];
  return (): void => scrollToSection(nextSectionName, 'auto');
};

/**
 * Scroll the window to the last section that is currently shown.
 */
export const useScrollToLastAvailableSection = (): (() => void) => {
  const { availableSections } = useGetSectionInfoGlobal();
  const lastSectionName = R.last(availableSections);
  return (): void => scrollToSection(lastSectionName);
};

/**
 * Scroll the window to a specific section.
 * IMPORTANT: This needs to be called in the section we want to scroll to because
 * each section mounts asynchronously
 */
export const useScrollToSectionOnMount = (sectionName: HostEventSectionName): void => {
  const { availableSections } = useGetSectionInfoGlobal();
  useMount(() => {
    const queryParams = getParsedQueryParams();
    if (
      queryParams?.[SCROLL_TO_SECTION_QUERY_PARAM] === sectionName &&
      availableSections.includes(sectionName)
    ) {
      // Check available sections just in case the section we want to scroll to is not available
      // in the current activity. i.e. Schedule Ride when we are hosting a clinic
      replacePathNameWithoutReloadingPage(window.location.pathname);
      scrollToSection(sectionName);
      return true;
    }
    return false;
  });
};

/**
 * Build keydown, blur, onChange and delayed save handlers for an input.
 */
export const useGetInputMutationHandlers = ({
  additionalOnChange,
  didValuesChangeFunction,
  mutation,
  validateOnSubmit,
  validationTrigger,
  valueForDebounce,
}: {
  // If we need to execute anything on the change handler
  additionalOnChange?:
    | ((e: ChangeEvent<HTMLInputElement>) => void)
    | ((values: NumberFormatValues) => void);
  // Did our values change?
  didValuesChangeFunction: () => boolean;
  // Mutation to execute if they did
  mutation: () => void;
  // Are our fields valid?
  // eslint-disable-next-line @typescript-eslint/ban-types
  validationTrigger: () => Promise<Boolean> | (() => void);
  // Value for debounced save. This is optional as it required 'watch' on form values. We
  // use an object so we can check for the existence to know if we are using debounce while
  // still allowing for an undefined 'value'
  valueForDebounce?: { value: unknown };
  // Should we only validate on change
  validateOnSubmit?: boolean;
}): [
  () => Promise<void>,
  (e: KeyboardEvent<HTMLInputElement>) => Promise<void>,
  (e: ChangeEvent<HTMLInputElement>) => Promise<void>,
] => {
  const shouldUseDebounce = !!valueForDebounce;

  // RHF doesn't allow us to use 'onTouched' per input so we track if this input got 'submitted'
  // but a keypress, blur or debounce event. We can then use this to see if we should revalidate
  // on change
  const didSubmit = useRef(false);

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    const code = e.which || e.keyCode || e.charCode;
    if (code === KEY_CODE.Tab || code === KEY_CODE.Enter) {
      if (code === KEY_CODE.Enter) {
        // Prevent Enter default but allow Tab
        e.preventDefault();
        e.stopPropagation();
      }
      didSubmit.current = true;
      const isValid = await validationTrigger();
      if (isValid && didValuesChangeFunction()) {
        mutation();
      }
    }
  };

  const handleBlur = async (): Promise<void> => {
    // Call on lose focus in case a user copies a value within 3 second debounce
    // Make sure our value is not the same as our starting props value
    didSubmit.current = true;
    const isValid = await validationTrigger();
    if (isValid && didValuesChangeFunction()) {
      mutation();
    }
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    // If we have submitted already, validate on change
    if (additionalOnChange) {
      additionalOnChange(e);
    }
    if (didSubmit.current && !validateOnSubmit) {
      await validationTrigger();
    }
  };

  const debouncedValue = useDebounceState(valueForDebounce?.value, SAVE_MUTATION_DEBOUNCE_DELAY);
  useEffect(() => {
    // debounce() at 3 seconds before we update name input
    // Make sure our value is not the same as our starting props value
    (async (): Promise<void> => {
      if (shouldUseDebounce && didValuesChangeFunction()) {
        didSubmit.current = true;
        const isValid = await validationTrigger();
        if (isValid) {
          mutation();
        }
      }
    })();
  }, [debouncedValue]);

  return [handleBlur, handleKeyDown, handleChange];
};

/**
 * Update algolia search indexes
 */
export const useUpdateAlgoliaSearchIndexes = (): ((eventCuid: string) => void) => {
  const isRide = useGetIsEventRide();
  const isRace = useGetIsEventRace();
  const isClinic = useGetIsEventClinic();
  const hostEventData = useHostEventData();
  const { eventMetadata } = useFragment<helpers_useUpdateAlgoliaSearchIndexes_event$key>(
    graphql`
      fragment helpers_useUpdateAlgoliaSearchIndexes_event on event {
        publishedAt
        eventMetadata {
          isCategoriedEvent
        }
      }
    `,
    hostEventData,
  );
  return (eventCuid: string): void => {
    if (isClinic) {
      updateAlgoliaClinicIndexCF({
        eventCuid,
      }).then();
    } else if (isRide) {
      if (eventMetadata.isCategoriedEvent) {
        updateAlgoliaCategoriedRideIndexCF({
          eventCuid,
        }).then();
      } else {
        updateAlgoliaNonCategoriedRideIndexCF({
          eventCuid,
        }).then();
      }
    } else if (isRace) {
      updateAlgoliaRaceIndexCF({
        eventCuid,
      }).then();
    }
  };
};
