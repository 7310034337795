/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HostEventProviderSeriesQueryVariables = {
    activity: string;
    organizerSlug: string;
};
export type HostEventProviderSeriesQueryResponse = {
    readonly series_connection: {
        readonly " $fragmentRefs": FragmentRefs<"SeriesSection_series">;
    };
};
export type HostEventProviderSeriesQuery = {
    readonly response: HostEventProviderSeriesQueryResponse;
    readonly variables: HostEventProviderSeriesQueryVariables;
};



/*
query HostEventProviderSeriesQuery(
  $activity: String!
  $organizerSlug: String!
) {
  series_connection(order_by: {created_at: asc}, where: {_and: [{deleted_at: {_is_null: true}}, {organizer: {slug: {_eq: $organizerSlug}}}, {seriesActivity: {_eq: $activity}}]}) {
    ...SeriesSection_series
  }
}

fragment SeriesSection_series on seriesConnection {
  edges {
    node {
      id
      cuid
      slug
      name
      seriesActivity
      totalRounds
      organizerCuid
      eventSeries(where: {deleted_at: {_is_null: true}}) {
        id
        cuid
        seriesCuid
        eventCuid
        appliesToAllCategories
        eventSeriesCategories {
          id
          cuid
          categoryCuid
          eventSeriesCuid
        }
        event {
          id
          cuid
          occurrenceLabel
          eventMetadata {
            name
            slug
            isCategoriedEvent
            id
          }
        }
        series {
          slug
          name
          id
        }
        roundNumber
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "activity"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "organizerSlug"
        } as any
    ], v1 = {
        "deleted_at": {
            "_is_null": true
        }
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "order_by",
            "value": {
                "created_at": "asc"
            }
        } as any,
        {
            "fields": [
                {
                    "items": [
                        {
                            "kind": "Literal",
                            "name": "_and.0",
                            "value": (v1 /*: any*/)
                        },
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_eq",
                                                    "variableName": "organizerSlug"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "slug"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "organizer"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.1"
                        },
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "kind": "Variable",
                                            "name": "_eq",
                                            "variableName": "activity"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "seriesActivity"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.2"
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "HostEventProviderSeriesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "seriesConnection",
                    "kind": "LinkedField",
                    "name": "series_connection",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SeriesSection_series"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "HostEventProviderSeriesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "seriesConnection",
                    "kind": "LinkedField",
                    "name": "series_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "seriesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "series",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "seriesActivity",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "totalRounds",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "organizerCuid",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": (v1 /*: any*/)
                                                }
                                            ],
                                            "concreteType": "eventSeries",
                                            "kind": "LinkedField",
                                            "name": "eventSeries",
                                            "plural": true,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "seriesCuid",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "eventCuid",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "appliesToAllCategories",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventSeriesCategory",
                                                    "kind": "LinkedField",
                                                    "name": "eventSeriesCategories",
                                                    "plural": true,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "categoryCuid",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "eventSeriesCuid",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "event",
                                                    "kind": "LinkedField",
                                                    "name": "event",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "occurrenceLabel",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "eventMetadata",
                                                            "kind": "LinkedField",
                                                            "name": "eventMetadata",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "isCategoriedEvent",
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "series",
                                                    "kind": "LinkedField",
                                                    "name": "series",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "roundNumber",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "eventSeries(where:{\"deleted_at\":{\"_is_null\":true}})"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a7398affd693d6ccb9737f81c2f52748",
            "id": null,
            "metadata": {},
            "name": "HostEventProviderSeriesQuery",
            "operationKind": "query",
            "text": "query HostEventProviderSeriesQuery(\n  $activity: String!\n  $organizerSlug: String!\n) {\n  series_connection(order_by: {created_at: asc}, where: {_and: [{deleted_at: {_is_null: true}}, {organizer: {slug: {_eq: $organizerSlug}}}, {seriesActivity: {_eq: $activity}}]}) {\n    ...SeriesSection_series\n  }\n}\n\nfragment SeriesSection_series on seriesConnection {\n  edges {\n    node {\n      id\n      cuid\n      slug\n      name\n      seriesActivity\n      totalRounds\n      organizerCuid\n      eventSeries(where: {deleted_at: {_is_null: true}}) {\n        id\n        cuid\n        seriesCuid\n        eventCuid\n        appliesToAllCategories\n        eventSeriesCategories {\n          id\n          cuid\n          categoryCuid\n          eventSeriesCuid\n        }\n        event {\n          id\n          cuid\n          occurrenceLabel\n          eventMetadata {\n            name\n            slug\n            isCategoriedEvent\n            id\n          }\n        }\n        series {\n          slug\n          name\n          id\n        }\n        roundNumber\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '77fce7742505398b0a29a2fdfb5df7b8';
export default node;
