/**
 * Panel that holds search results and discover event chips
 */
import React from 'react';
import { animated, useTransition } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ReactFCC } from '@/lib/type-defs/utility';

const useAutocompletePanelStyles = makeStyles((theme) => ({
  panel: {
    background: theme.palette.common.white,
    border: '2px solid #03465c45',
    borderTop: 'none',
    borderRadius: '0 0 8px 8px',
    boxShadow: theme.shadows[13],
    display: 'flex',
    minHeight: '106px',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    transformOrigin: 'top',
    transition: 'border-color 250ms',
  },
  panelHovered: {
    borderBottomColor: '#03465c95',
    borderRightColor: '#03465c95',
    borderLeftColor: '#03465c95',
  },
}));

interface AutocompletePanelProps {
  isShown: boolean;
  isHovered: boolean;
}
const AutoCompletePanel: ReactFCC<AutocompletePanelProps> = ({ children, isHovered, isShown }) => {
  const panelClasses = useAutocompletePanelStyles();

  const transition = useTransition(isShown, {
    from: { opacity: 0, transform: 'scaleY(0)' },
    enter: { opacity: 1, transform: 'scaleY(1)' },
    leave: { opacity: 0, transform: 'scaleY(0)' },
    config: { mass: 1, tension: 340, friction: 26 },
    // config: config.stiff,
  });
  return (
    <>
      {transition(
        (springProps, item) =>
          item && (
            <animated.div
              className={classNames(panelClasses.panel, { [panelClasses.panelHovered]: isHovered })}
              style={springProps}
            >
              {children}
            </animated.div>
          ),
      )}
    </>
  );
};

export default AutoCompletePanel;
