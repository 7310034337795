/*
 * Date utilities
 * Gets synced client/server
 */
import {
  addDays,
  formatISO,
  getDate,
  getMilliseconds,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameSecond,
  parse,
  set,
} from 'date-fns';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getSeconds from 'date-fns/getSeconds';
import isEqual from 'date-fns/isEqual';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import parseISO from 'date-fns/parseISO';
import parseJSON from 'date-fns/parseJSON';
import setHours from 'date-fns/setHours';
import setMilliseconds from 'date-fns/setMilliseconds';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

// Time
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_HOUR_IN_MINUTES = 60;
export const ONE_MINUTE_IN_SECONDS = 60;
// Days/Weeks
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24;
export const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7;
// Years
export const ONE_YEAR_IN_MONTHS = 12;
export const ONE_YEAR_IN_WEEKS = 52;
export const ONE_YEAR_IN_DAYS = 365;

export enum DaysOfTheWeekEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

/**
 * Round down to the nearest 30 mins
 */
export const roundDownDateToHalfHour = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(Math.floor(date.getMinutes() / 30) * 30);
  return newDate;
};

/**
 * Round up to the nearest 30 mins
 */
export const roundUpDateToHalfHour = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(Math.ceil(date.getMinutes() / 30) * 30);
  return newDate;
};

export const isSameDayMonthYear = (d1: Date, d2: Date): boolean => {
  return isSameDay(d1, d2) && isSameMonth(d1, d2) && isSameYear(d1, d2);
};
export const isSameDateOrBefore = (d1: Date, d2: Date): boolean => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2);
};
export const isSameDateOrAfter = (d1: Date, d2: Date): boolean => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2);
};
export const isDateBetweenInclusive = (
  dateToCheck: Date,
  interval: { end: Date; start: Date },
): boolean => {
  return (
    isSameDateOrAfter(dateToCheck, interval.start) && isSameDateOrBefore(dateToCheck, interval.end)
  );
};

/**
 * Get the number of years that have passed from a date until today
 */
export const getYearsToToday = (date: Date): number => {
  return new Date().getFullYear() - date.getFullYear();
};

/**
 * Convert the date coming from the database to unix time
 */
export function jsonDateToUnixTime(dateString: string): number {
  return parseJSON(dateString).getTime() / 1000;
}

/**
 * Convert a Unix timestamp to a Date object.
 */
export function unixTimeToDate(timestamp: number): Date {
  return new Date(timestamp * 1000);
}

/**
 * Get Locale
 * e.g. en-US
 */
export const getNavigatorLanguage = (): string => {
  if (navigator?.languages && navigator?.languages.length) {
    return navigator.languages[0];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Doesn't exist, but that's the point
  return navigator?.userLanguage || navigator?.language || navigator?.browserLanguage || 'en';
};

/**
 * Set the time to 0 on a date object
 */
export const dateWithoutTime = (date?: Date): Date => {
  const newDate = date ? new Date(date) : new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

/**
 * Convert date to ISO 2020-12-30
 */
export const formatToISODateString = (date: Date | string): string => {
  return formatISO(typeof date === 'string' ? parseISO(date) : date, { representation: 'date' });
};

/**
 * Convert date to 12/10/2020
 * Based on locale
 */
export const dateToString = (date: Date, options?: Intl.DateTimeFormatOptions): string => {
  return new Intl.DateTimeFormat([], options).format(date);
};

/**
 * Update the date of "d1" to the date in "dateToMatch"
 */
export const matchDate = (d1: Date, dateToMatch: Date): Date => {
  return set(d1, {
    year: getYear(dateToMatch),
    month: getMonth(dateToMatch),
    date: getDate(dateToMatch),
  });
};

/**
 * Convert date to time
 * Date object to 12:10 PM or 12:10
 */
export const convertDateToTimeString = (date: Date, shouldUse12Hour = true): string => {
  if (shouldUse12Hour) {
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
  }
  // 24 hour time
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`;
};

/**
 * Convert 13:12 to 1:12 PM
 */
export const convert24HourStringTo12HourString = (time: string): string => {
  const date = parse(time, 'HH:mm:ss', new Date());
  return getTimeFromDateToString(date, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

/**
 * Convert hour to time
 * 1 => 01:00 or 1:00 AM
 * 23 => 23:00 or 11:00 PM
 */
export const convertHourToTimeString = (hour: number, shouldUse12Hour = true): string => {
  if (hour > 24 || hour < 0) {
    throw new Error('Invalid hour');
  }
  const date = new Date(2000, 10, 10, hour, 0, 0);
  if (shouldUse12Hour) {
    return date.toLocaleTimeString([], {
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
    });
  }
  // 24 hour time
  return date.toLocaleTimeString([], {
    hour12: false,
    hour: 'numeric',
    minute: '2-digit',
  });
};

/**
 * Convert hour to time shortened
 * 1 => 1am
 * 23 => 11pm
 */
export const convertHourToTimeStringShort = (hour: number): string => {
  if (hour > 24 || hour < 0) {
    throw new Error('Invalid hour');
  }
  if (hour === 0) {
    return '12am';
  }
  if (hour === 12) {
    return '12pm';
  }
  if (hour > 12) {
    return `${hour - 12}pm`;
  }
  return `${hour}am`;
};

/**
 * Get a time range from two dates
 * 7:00 AM - 8:00 AM
 */
export const getTimeRange = (startDate: Date, endDate: Date, shouldUse12Hour = true): string => {
  return `${startDate.toLocaleTimeString([], {
    hour: shouldUse12Hour ? 'numeric' : '2-digit',
    minute: '2-digit',
    hour12: shouldUse12Hour,
  })} - ${endDate.toLocaleTimeString([], {
    hour: shouldUse12Hour ? 'numeric' : '2-digit',
    minute: '2-digit',
    hour12: shouldUse12Hour,
  })}`;
};

/**
 * Get a short time range from two dates
 * 7:00pm-8:00pm
 * 7pm-8pm
 * */
export const getShortTimeRange = (
  startDate: Date,
  endDate: Date,
  options?: { onlyHour: boolean },
): string => {
  const pattern = options?.onlyHour ? 'haaaa' : 'h:mmaaaa';
  let timeRange = `${format(startDate, pattern)}-${format(endDate, pattern)}`;
  if (isEqual(startDate, endDate)) {
    timeRange = format(startDate, pattern);
  }
  // datefns always puts periods between p.m. and a.m. Remove them. format aaa doesnt seem to work
  return timeRange.replace(/\./g, '');
};

/**
 * Set a Date to midnight
 */
export function setTimeToMidnight(date: Date): Date {
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
}

/**
 * Set a Date to 23:59
 */
export function setTimeTo2359(date: Date): Date {
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 59), 59), 23);
}

/**
 * Set the time on date one from the time on date two
 */
export function setTimeFromDate(dateOne: Date, dateTwo: Date): Date {
  return setHours(
    setMinutes(
      setSeconds(setMilliseconds(dateOne, getMilliseconds(dateTwo)), getSeconds(dateTwo)),
      getMinutes(dateTwo),
    ),
    getHours(dateTwo),
  );
}

/**
 * Set the time on date one from the time on date two
 */
export function getTimeFromDateToString(date: Date, options?: Intl.DateTimeFormatOptions): string {
  return date.toLocaleTimeString(
    [],
    options ?? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
  );
}

/**
 * Convert seconds to time
 * 600 => 10:00
 */
export const convertSecondsToDuration = (secondsAmount: number): string => {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time);

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = hours !== '00' ? `${hours}:` : '';

  return `${hoursOutput}${minutes}:${seconds}`;
};

/**
 * Convert time to seconds
 * 10:00:00 => 6000
 */
export const convertTimeToSeconds = (date: Date): number => {
  return date.getHours() * ONE_HOUR_IN_SECONDS + date.getMinutes() * 60 + date.getSeconds();
};

/**
 * Time comes from DB like "hh:mm:ss" we need it in "hh:mm"
 */
export const removeSecondsFromTime = (time: string): string => {
  return format(parse(time, 'HH:mm:ss', new Date()), 'HH:mm');
};

/**
 * Initialize date in a specific timezone
 */
export const initializeDateToTimezone = (
  date: Date | string,
  tzString = getCurrentTimezone(),
): Date => {
  return zonedTimeToUtc(date, tzString);
};

/**
 * Convert a Date to a specific timezone
 * We will still have the offset in the date but the offset will lead to the original time
 */
export const convertDateToTimezone = (date: Date, tzString = getCurrentTimezone()): Date => {
  return utcToZonedTime(date, tzString);
};

/**
 * Date to Mixpanel format
 */
export const dateToMixpanelFormat = (date: Date): string => {
  return format(date, 'yyyy-MM-ddHH:mm:ss');
};

/**
 * Date to Floating Date String
 * Converts JS Date object to a string for insertion into the DB
 */
export const dateToFloatingDateString = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

/**
 * Date from Floating Date String
 * Takes a ISO like string without the timezone and converts it into a JS Date object with that time
 */
export const dateFromFloatingDateString = (dateString: string): Date => {
  return initializeDateToTimezone(
    parseISO(dateString),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
};

/**
 * Get the current time in UTC and returns e.g. 2020-01-22T08:00:00Z
 */
export const getCurrentUTCTime = (): string => {
  return initializeDateToTimezone(
    new Date(),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  ).toJSON();
};

/**
 * Gets timezone abbreviation from timezone e.g. America/Los_Angeles -> PST
 */
export const getTimezoneAbbreviation = (timezone: string): string => {
  return tzFormat(utcToZonedTime(new Date(), timezone), 'zzz', { timeZone: timezone });
};

/**
 * Gets timezone  e.g. America/Los_Angeles
 */
export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
/**
 * Get the weekday name
 * i.e. Tuesday
 */
export const getWeekdayName = (
  date: Date,
  options: Intl.DateTimeFormatOptions = { weekday: 'long' },
): string => {
  return new Intl.DateTimeFormat([], options).format(date);
};

/**
 * Gets day abbreviation from day of the week
 * Monday => Mon
 * Tuesday => Tue
 */
export const getWeekdayAbbrev = (dayOfWeek: DaysOfTheWeekEnum): string => {
  // if (dayOfWeek === DaysOfTheWeekEnum.Tuesday) {
  //   return 'Tues';
  // }
  // if (dayOfWeek === DaysOfTheWeekEnum.Thursday) {
  //   return 'Thurs';
  // }
  return dayOfWeek.substring(0, 3);
};

/**
 * Feb 6, 2019 - Feb 6, 2019 formatted as Feb 6, 2019
 * Feb 6, 2019 - Feb 8, 2019 formatted as Feb 6 - 8, 2019
 * Feb 6, 2019 - Mar 8, 2019 formatted as Feb 6 - Mar 8, 2019
 * Feb 20, 2019 - Mar 3, 2020 formatted as Feb 20, 2019 - Mar 3, 2020
 */
export function readableDateRange(
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
): string {
  if (!startDate && !endDate) {
    return '';
  }
  const startDateMidnight =
    typeof startDate === 'string' ? parseISO(startDate) : setTimeToMidnight(parseJSON(startDate));
  const endDateMidnight =
    typeof endDate === 'string' ? parseISO(endDate) : setTimeToMidnight(parseJSON(endDate));

  if (startDate && !endDate) {
    return format(startDateMidnight, 'MMM d, y');
  }
  if (!startDate && endDate) {
    return format(endDateMidnight, 'MMM d, y');
  }

  if (!isEqual(startDateMidnight, endDateMidnight)) {
    if (isSameYear(startDateMidnight, endDateMidnight)) {
      if (isSameMonth(startDateMidnight, endDateMidnight)) {
        // Different day, use same month
        return `${format(startDateMidnight, 'MMM d')} - ${format(endDateMidnight, 'd, y')}`;
      }
      // Different months, use same year
      return `${format(startDateMidnight, 'MMM d')} - ${format(endDateMidnight, 'MMM d, y')}`;
    }
    // Different years, use full date
    return `${format(startDateMidnight, 'MMM d, y')} - ${format(endDateMidnight, 'MMM d, y')}`;
  }
  // Both dates are the same
  return format(startDateMidnight, 'MMM d, y');
}

/**
 * Format Date to 12/12/1920
 */
export function shortReadableDate(date: Date): string {
  return date.toLocaleDateString();
}

/**
 * Format Date to Monday, December 12th, 2019, 11:23pm
 */
export function readableDate(
  date: Date,
  shouldIncludeDay = true,
  shouldIncludeTime = true,
  shouldBeShort = false,
): string {
  let readable = 'MMMM do, y';
  if (shouldBeShort) {
    readable = 'MMM d, y';
  }
  if (shouldIncludeTime) {
    readable = `${readable}, h:mma`;
  }
  if (shouldIncludeDay) {
    readable = `EEEE, ${readable}`;
  }
  return format(date, readable);
}

/**
 * Format time to 1pm, or 1:01pm
 */
export function shortReadableTime(date: Date): string {
  const readable = date.getMinutes() === 0 ? 'ha' : 'h:mma';
  return format(date, readable);
}

/**
 * Format Date to 2/5/21 at 5:21pm
 */
export function formatDateAtTime(date: Date): string {
  return `${date.toLocaleDateString()} at ${format(date, 'h:mma')}`;
}

export interface TimeZoneDBReturn {
  abbreviation: string;
  countryCode: string;
  countryName: string;
  dst: '0' | '1';
  formatted: string;
  gmtOffset: number;
  message: string;
  nextAbbreviation: string;
  status: string;
  timestamp: number;
  zoneEnd: number;
  zoneName: string;
  zoneStart: number;
}

/**
 * Use timezonedb to get timezone info from lat-long. We can only request data once per
 * second, so we put in a fail-safe that retries 3 times just incase we have a situation where
 * two different users are requesting within a second of each other.
 */
export const getTimeZoneFromLatLong = async (
  lat: number,
  long: number,
): Promise<TimeZoneDBReturn> => {
  let retries = 0;
  const fetchTimezone = async (): Promise<TimeZoneDBReturn> => {
    try {
      const response = await fetch(
        `https://api.timezonedb.com/v2.1/get-time-zone?key=XYF2521ZT1BI&format=json&by=position&lat=${lat}&lng=${long}`,
      );
      return response.json();
    } catch (err) {
      if (retries < 3) {
        retries += 1;
        await sleep(1000);
        return fetchTimezone();
      }
    }
    throw Error("Couldn't get timezone info");
  };
  return fetchTimezone();
};

/**
 * Loop through a list of dates and find the first available date
 */
export const findFirstAvailableDate = (listOfDates: Date[], dateToCheck: Date): Date => {
  let availableDate = setTimeToMidnight(dateToCheck);
  const midnightDates = listOfDates.map((d) => setTimeToMidnight(d));

  if (R.isEmpty(listOfDates)) {
    // We have no dates. Use the initial date
    return availableDate;
  }

  // Does the date exist in our list?
  if (RA.isNilOrEmpty(R.find((d) => isEqual(d, availableDate), midnightDates))) {
    // First date is available
    return availableDate;
  }

  // const firstDate = listOfDates[0];
  for (let i = 0; i < midnightDates.length; i++) {
    // Check the next date in the list to see if is equal to the day after our current loop day
    const currentDate = midnightDates[i];
    const nextDate = midnightDates?.[i + 1];
    const nextSequentialDate = addDays(currentDate, 1);
    if (!isEqual(nextDate, nextSequentialDate)) {
      // Next date available
      availableDate = nextSequentialDate;
      break;
    }
  }
  // if date is next day
  return availableDate;
};

/**
 * Use an async function to mimic sleep
 * const asyncFunc = async () => {
 *     const hello = 1
 *     await sleep(1000)
 *     console.log(hello)
 * }
 */
export function sleep(ms: number): Promise<() => void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Take minutes and convert to hours and minutes text
 */
export const minutesToHoursAndMinutesText = (
  duration: number,
  removeLastPlural?: boolean,
): string => {
  const hours = Math.floor(duration / ONE_HOUR_IN_MINUTES);
  const minutes = duration % ONE_HOUR_IN_MINUTES;
  const hoursText = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const minutesText = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
  const durationText = `${hoursText} ${minutesText}`.trim();
  if (removeLastPlural && durationText.includes('s')) {
    return durationText.slice(0, -1);
  }
  return durationText;
};

/**
 * Take minutes and convert to hours or minutes text
 * 30 minutes => 30 minutes
 * 60 minutes => 1 hour
 * 90 minutes => 1.5 hours
 */
export const minutesToHoursOrMinutesText = (duration: number): string => {
  const hours = +(duration / ONE_HOUR_IN_MINUTES).toFixed(2);
  const minutes = duration % ONE_HOUR_IN_MINUTES;
  const minutesText = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
  if (minutesText) {
    return minutesText;
  }
  const hoursText = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  if (hoursText) {
    return hoursText;
  }
  return '';
};
