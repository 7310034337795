/*
React-Router utils
 */

import { useParams } from 'react-router';
import invariant from 'invariant';

/**
 * Replace the current pathname without reloading the page
 */
export const replacePathNameWithoutReloadingPage = (pathname: string): void => {
  const newUrl = `${window.location.origin}${pathname}`;
  // Replace so it only happens once (i.e. back)
  window.history.replaceState({ path: newUrl }, '', newUrl);
};

/**
 * A hook to typecheck and null check useParams.
 * We do this because react-router can never be sure of where we are calling useParams from.
 * Even if we are sure, in the future, route component could be deleted or a name could change
 * This gives us a test for that case
 */
export function useTypedParams<T extends string>(
  parameterNames: T[],
  errorOnEmpty?: boolean,
): Record<T, string> {
  const params = useParams();
  const typedParams: Record<string, string> = {};
  parameterNames.forEach((paramName) => {
    const currentParam = params[paramName];
    if (errorOnEmpty) {
      invariant(
        currentParam,
        `${paramName} not found in useParams. Check the parent route to make sure nothing changed`,
      );
    }
    typedParams[paramName] = currentParam;
  });
  return typedParams;
}
