/**
 * Load data for Create Coach
 */
import React, { createContext, useContext } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Navigate } from 'react-router';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import { graphql } from 'relay-runtime';

import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import {
  CreateCoachProviderQuery,
  CreateCoachProviderQueryResponse,
} from '@/providers/CreateCoachProvider/__generated__/CreateCoachProviderQuery.graphql';
import { AppRouteService } from '@/routes/RouteService';

const CreateCoachProvider: ReactFCC = ({ children }) => {
  const auth = useAuth();
  const userData = useLazyLoadQuery<CreateCoachProviderQuery>(
    graphql`
      query CreateCoachProviderQuery($userId: String!) {
        user_connection(where: { id: { _eq: $userId } }) {
          edges {
            node {
              id
              ...CreateCoachView_user
              ...ProfileStep_user
              ...CoachingStep_user
              ...AvailabilityLessonsStep_user
              ...VideoStep_user
              ...BusinessStep_user
            }
          }
        }
      }
    `,
    { userId: auth.firebaseUser!.uid },
  );

  // No user Data
  if (R.isEmpty(userData.user_connection.edges)) {
    return <Navigate replace to={AppRouteService.getRelativeUrl('NotFound')} />;
  }

  return (
    <createCoachContext.Provider
      value={{
        ...userData.user_connection.edges[0].node,
      }}
    >
      {children}
    </createCoachContext.Provider>
  );
};

const createCoachContext = createContext({});

// Hook for child components to get the root relay query.
export const useCreateCoachData = (): CreateCoachProviderQueryResponse['user_connection']['edges'][0]['node'] => {
  return useContext(
    createCoachContext,
  ) as CreateCoachProviderQueryResponse['user_connection']['edges'][0]['node'];
};

export default CreateCoachProvider;
