/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EditProfileDialog_OrganizerQueryVariables = {
    organizerSlug: string;
};
export type EditProfileDialog_OrganizerQueryResponse = {
    readonly organizer_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly cuid: string;
                readonly name: string;
                readonly about: string | null;
                readonly website: string | null;
                readonly slug: string;
                readonly defaultCurrencyCode: string;
                readonly image: {
                    readonly bytes: number | null;
                    readonly format: string | null;
                    readonly height: number | null;
                    readonly originalFilename: string | null;
                    readonly publicId: string;
                    readonly relativeUrl: string;
                    readonly resourceType: string | null;
                    readonly url: string;
                    readonly version: number | null;
                    readonly width: number | null;
                    readonly organizerCuid: string;
                } | null;
            };
        }>;
    };
};
export type EditProfileDialog_OrganizerQuery = {
    readonly response: EditProfileDialog_OrganizerQueryResponse;
    readonly variables: EditProfileDialog_OrganizerQueryVariables;
};



/*
query EditProfileDialog_OrganizerQuery(
  $organizerSlug: String!
) {
  organizer_connection(where: {slug: {_eq: $organizerSlug}}) {
    edges {
      node {
        id
        cuid
        name
        about
        website
        slug
        defaultCurrencyCode
        image {
          bytes
          format
          height
          originalFilename
          publicId
          relativeUrl
          resourceType
          url
          version
          width
          organizerCuid
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "organizerSlug"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "organizerSlug"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "slug"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "about",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultCurrencyCode",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bytes",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "format",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originalFilename",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicId",
        "storageKey": null
    } as any, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relativeUrl",
        "storageKey": null
    } as any, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resourceType",
        "storageKey": null
    } as any, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
    } as any, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
    } as any, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organizerCuid",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditProfileDialog_OrganizerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "organizerConnection",
                    "kind": "LinkedField",
                    "name": "organizer_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "organizerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "organizer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "image",
                                            "kind": "LinkedField",
                                            "name": "image",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/),
                                                (v10 /*: any*/),
                                                (v11 /*: any*/),
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                (v14 /*: any*/),
                                                (v15 /*: any*/),
                                                (v16 /*: any*/),
                                                (v17 /*: any*/),
                                                (v18 /*: any*/),
                                                (v19 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditProfileDialog_OrganizerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "organizerConnection",
                    "kind": "LinkedField",
                    "name": "organizer_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "organizerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "organizer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "image",
                                            "kind": "LinkedField",
                                            "name": "image",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/),
                                                (v10 /*: any*/),
                                                (v11 /*: any*/),
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                (v14 /*: any*/),
                                                (v15 /*: any*/),
                                                (v16 /*: any*/),
                                                (v17 /*: any*/),
                                                (v18 /*: any*/),
                                                (v19 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "89130ce9c26fd0156b790a8d2f154fb3",
            "id": null,
            "metadata": {},
            "name": "EditProfileDialog_OrganizerQuery",
            "operationKind": "query",
            "text": "query EditProfileDialog_OrganizerQuery(\n  $organizerSlug: String!\n) {\n  organizer_connection(where: {slug: {_eq: $organizerSlug}}) {\n    edges {\n      node {\n        id\n        cuid\n        name\n        about\n        website\n        slug\n        defaultCurrencyCode\n        image {\n          bytes\n          format\n          height\n          originalFilename\n          publicId\n          relativeUrl\n          resourceType\n          url\n          version\n          width\n          organizerCuid\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'b142d243ed13494eac6a7f6ca5c1c1e0';
export default node;
