/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type eventMetadataMutations_updateOccurrenceLabel_MutationVariables = {
    eventCuid: string;
    occurrenceLabel: string;
};
export type eventMetadataMutations_updateOccurrenceLabel_MutationResponse = {
    readonly update_event: {
        readonly returning: ReadonlyArray<{
            readonly lastSavedAt: string;
            readonly occurrenceLabel: string | null;
            readonly id: string;
        }>;
    } | null;
};
export type eventMetadataMutations_updateOccurrenceLabel_Mutation = {
    readonly response: eventMetadataMutations_updateOccurrenceLabel_MutationResponse;
    readonly variables: eventMetadataMutations_updateOccurrenceLabel_MutationVariables;
};



/*
mutation eventMetadataMutations_updateOccurrenceLabel_Mutation(
  $eventCuid: String!
  $occurrenceLabel: String!
) {
  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {lastSavedAt: "now()", occurrenceLabel: $occurrenceLabel}) {
    returning {
      lastSavedAt
      occurrenceLabel
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventCuid"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "occurrenceLabel"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Literal",
                            "name": "lastSavedAt",
                            "value": "now()"
                        },
                        {
                            "kind": "Variable",
                            "name": "occurrenceLabel",
                            "variableName": "occurrenceLabel"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "_set"
                },
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "_eq",
                                    "variableName": "eventCuid"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "cuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                }
            ],
            "concreteType": "event_mutation_response",
            "kind": "LinkedField",
            "name": "update_event",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "event",
                    "kind": "LinkedField",
                    "name": "returning",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastSavedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "occurrenceLabel",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "eventMetadataMutations_updateOccurrenceLabel_Mutation",
            "selections": (v1 /*: any*/),
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "eventMetadataMutations_updateOccurrenceLabel_Mutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ea3437ebe3012c1cbf103d6f8381432f",
            "id": null,
            "metadata": {},
            "name": "eventMetadataMutations_updateOccurrenceLabel_Mutation",
            "operationKind": "mutation",
            "text": "mutation eventMetadataMutations_updateOccurrenceLabel_Mutation(\n  $eventCuid: String!\n  $occurrenceLabel: String!\n) {\n  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {lastSavedAt: \"now()\", occurrenceLabel: $occurrenceLabel}) {\n    returning {\n      lastSavedAt\n      occurrenceLabel\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '7c68abf70b5eb5378362fabd71726849';
export default node;
