import { Theme } from '@material-ui/core';

import { Colors } from '../colors';

export const MuiFormLabel = (theme: Theme): Record<string, unknown> => ({
  root: {
    '&.bold': {
      '&:not(.Mui-error)': {
        color: Colors.Inherit,
      },
      fontWeight: 500,
    },
  },
});
