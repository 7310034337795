/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type registrationStatus_enum = "canceled" | "canceled_stale" | "complete" | "in_progress" | "%future added value";
export type AuthProviderUpcomingEventsWithRegistrationsQueryVariables = {
    userId: string;
};
export type AuthProviderUpcomingEventsWithRegistrationsQueryResponse = {
    readonly registration_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly status: registrationStatus_enum;
                readonly event: {
                    readonly occurrenceLabel: string | null;
                    readonly eventMetadata: {
                        readonly name: string;
                    };
                };
            };
        }>;
    };
};
export type AuthProviderUpcomingEventsWithRegistrationsQuery = {
    readonly response: AuthProviderUpcomingEventsWithRegistrationsQueryResponse;
    readonly variables: AuthProviderUpcomingEventsWithRegistrationsQueryVariables;
};



/*
query AuthProviderUpcomingEventsWithRegistrationsQuery(
  $userId: String!
) {
  registration_connection(where: {_and: [{clinic: {clinicCoaches: {coach: {userId: {_eq: $userId}}}}}, {clinic: {clinicDays: {startTime: {_gte: "now()"}}}}]}, distinct_on: [eventCuid]) {
    edges {
      node {
        status
        event {
          occurrenceLabel
          eventMetadata {
            name
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "kind": "Literal",
            "name": "distinct_on",
            "value": [
                "eventCuid"
            ]
        } as any,
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "fields": [
                                                        {
                                                            "fields": [
                                                                {
                                                                    "kind": "Variable",
                                                                    "name": "_eq",
                                                                    "variableName": "userId"
                                                                }
                                                            ],
                                                            "kind": "ObjectValue",
                                                            "name": "userId"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "coach"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "clinicCoaches"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "clinic"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "clinic": {
                                    "clinicDays": {
                                        "startTime": {
                                            "_gte": "now()"
                                        }
                                    }
                                }
                            }
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "occurrenceLabel",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthProviderUpcomingEventsWithRegistrationsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AuthProviderUpcomingEventsWithRegistrationsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ce56d85f416b63c6bf6f479e1f1bd362",
            "id": null,
            "metadata": {},
            "name": "AuthProviderUpcomingEventsWithRegistrationsQuery",
            "operationKind": "query",
            "text": "query AuthProviderUpcomingEventsWithRegistrationsQuery(\n  $userId: String!\n) {\n  registration_connection(where: {_and: [{clinic: {clinicCoaches: {coach: {userId: {_eq: $userId}}}}}, {clinic: {clinicDays: {startTime: {_gte: \"now()\"}}}}]}, distinct_on: [eventCuid]) {\n    edges {\n      node {\n        status\n        event {\n          occurrenceLabel\n          eventMetadata {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1b8e6d82154056ff343973f5ce9a6f9d';
export default node;
