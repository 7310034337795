/**
 * Wrapper for a scrollable container that adds a box shadow when the container is not at the bottom
 */
import React, { isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import * as RA from 'ramda-adjunct';
import { debounce } from 'throttle-debounce';
import useForceUpdate from 'use-force-update';
import { Primitive } from 'utility-types';

import { useEventListener } from '@/lib/hooks/hooks';

const useStyles = makeStyles({
  root: {
    height: '1px',
    position: 'relative',
    '&::before': {
      content: '""',
      transition: 'all 175ms',
      boxShadow: 'none',
    },
  },
  rootHorizontal: {
    position: 'relative',
    '&::before': {
      content: '""',
      transition: 'all 175ms',
      boxShadow: 'none',
    },
  },
  border: {
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px -1px 0px',
  },
  shadow: {
    '&::before': {
      content: '""',
      height: '18px',
      boxShadow: 'rgba(0, 0, 0, 0.09) 0px -9px 15px -5px',
      display: 'block',
      position: 'absolute',
      width: '100%',
      borderRadius: '75%',
    },
  },
  shadowTop: {
    '&::before': {
      content: '""',
      height: '18px',
      top: '-18px',
      boxShadow: 'rgba(0, 0, 0, 0.09) 0px 9px 15px -5px',
      display: 'block',
      position: 'absolute',
      width: '100%',
      borderRadius: '75%',
    },
  },
  shadowHorizontal: {
    '&::before': {
      content: '""',
      width: '18px',
      // boxShadow: 'rgba(0, 0, 0, 0.09) -9px 0px 15px -5px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 20px',
      display: 'block',
      position: 'absolute',
      height: '100%',
      // zIndex: -1,
      borderRadius: '75%',
    },
  },
  shadowHorizontalRight: {
    '&::before': {
      content: '""',
      left: '-18px',
      width: '18px',
      // boxShadow: 'rgba(0, 0, 0, 0.09) -9px 0px 15px -5px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 20px',
      display: 'block',
      position: 'absolute',
      height: '100%',
      // zIndex: -1,
      borderRadius: '75%',
    },
  },
});
interface BoxShadowScrollIndicatorProps {
  dependency?: Primitive | Primitive[];
  horizontal?: boolean;
  noBorder?: boolean;
  useWindowResizeDependency?: boolean;
  isAtBottomCallback?: () => void;
  isAtTopCallback?: () => void;
  refCallback?: (ref: HTMLDivElement) => void;
}
const BoxShadowScrollIndicator: React.FC<BoxShadowScrollIndicatorProps & BoxProps> = ({
  children,
  className,
  dependency,
  horizontal,
  isAtBottomCallback,
  isAtTopCallback,
  noBorder,
  refCallback,
  useWindowResizeDependency,
  ...rest
}) => {
  const classes = useStyles();
  const childRef = useRef<HTMLDivElement | undefined>();
  const forceUpdate = useForceUpdate();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const onRefSet = useCallback((ref) => {
    childRef.current = ref;
    if (refCallback) {
      refCallback(ref);
    }
    forceUpdate();
  }, []);

  const scrollHandler = (): void => {
    if (!childRef.current) {
      return;
    }
    const element = childRef.current;
    const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
    const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
    const childSize = horizontal ? element.scrollWidth : element.scrollHeight;

    const didScrollToBottom = horizontal
      ? element.scrollWidth - element.scrollLeft - element.clientWidth < 1
      : element.scrollHeight - element.scrollTop - element.clientHeight < 1;
    const didScrollToTop = horizontal ? element.scrollLeft < 1 : element.scrollTop < 1;
    const notHasScrollBar =
      (!horizontal && !hasVerticalScrollbar) || (horizontal && !hasHorizontalScrollbar);
    if (notHasScrollBar && !isAtBottom && childSize > 0) {
      // No scroll bars === no box shadow
      setIsAtBottom(true);
      setIsAtTop(true);
      if (isAtBottomCallback) {
        isAtBottomCallback();
      }
      if (isAtTopCallback) {
        isAtTopCallback();
      }
      return;
    }

    if (didScrollToBottom && !isAtBottom && childSize > 0) {
      // First time we hit bottom
      setIsAtBottom(true);
      if (isAtBottomCallback) {
        isAtBottomCallback();
      }
      return;
    }
    if (didScrollToTop && !isAtTop && childSize > 0) {
      // First time we hit top
      setIsAtTop(true);
      if (isAtTopCallback) {
        isAtTopCallback();
      }
      return;
    }

    // if (!didScrollToBottom && isAtBottom) {
    if (!didScrollToBottom) {
      // First time we leave bottom
      setIsAtBottom(false);
    }
    if (!didScrollToTop) {
      // First time we leave top
      setIsAtTop(false);
    }
  };

  // Call the scroll handler if an external dependency could change the width/height of the child
  // scrollHandler will get called on mount, but childRef.current will be undefined so
  // the rest of the function wont execute
  useEffect(scrollHandler, (RA.isArray(dependency) ? dependency : [dependency]) || [false]);
  useEventListener('scroll', scrollHandler, childRef.current, true);

  // Re-render on window resize
  useEffect(() => {
    if (!useWindowResizeDependency) {
      return (): void => undefined;
    }
    const ONE_FRAME_AT_A_TIME = 16.6667;
    const debouncedHandleResize = debounce(ONE_FRAME_AT_A_TIME, () => {
      scrollHandler();
      forceUpdate();
    });
    window.addEventListener('resize', debouncedHandleResize);
    return (): void => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [useWindowResizeDependency]);

  // If we don't just just ONE valid element, bail out
  if (!isValidElement(children)) {
    return <>{children}</>;
  }
  return (
    <>
      <Box
        className={classNames(className, {
          [classes.border]: !noBorder,
          [classes.root]: !horizontal,
          [classes.rootHorizontal]: horizontal,
          [classes.shadowTop]: !isAtTop && !horizontal,
          [classes.shadowHorizontalRight]: !isAtTop && horizontal,
        })}
        {...rest}
      />
      {React.cloneElement(children, {
        ref: onRefSet,
      })}
      <Box
        className={classNames(className, {
          [classes.border]: !noBorder,
          [classes.root]: !horizontal,
          [classes.rootHorizontal]: horizontal,
          [classes.shadow]: !isAtBottom && !horizontal,
          [classes.shadowHorizontal]: !isAtBottom && horizontal,
        })}
        {...rest}
      />
    </>
  );
};

export default BoxShadowScrollIndicator;
