import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { CreateCSSProperties } from '@material-ui/styles';

import { Colors } from '../../themes/colors';

interface RingCounterProps {
  maxChars?: number;
  numChars: number;
  radius?: number;
}
const useRingCounterStyles = makeStyles({
  ring: ({
    radius,
    stroke,
    strokeDasharray,
  }: {
    radius: number;
    stroke: string;
    strokeDasharray: string;
  }): CreateCSSProperties => ({
    width: '21px',
    height: '21px',
    '& circle': {
      cx: '50%',
      cy: '50%',
      fill: 'none',
      margin: 'auto',
      r: radius,
      strokeWidth: 3,
    },
    '& circle:first-child': {
      stroke: 'lightgray',
    },
    '& circle:last-child': {
      stroke,
      strokeDasharray,
    },
  }),
});
const RingCounter: React.FC<RingCounterProps> = ({ maxChars = 280, numChars, radius = 8 }) => {
  const circleLength = 2 * Math.PI * radius;
  const colored = (circleLength * numChars) / maxChars;
  const gray = circleLength - colored > 0 ? circleLength - colored : 0;

  const stroke =
    maxChars - numChars <= 0
      ? Colors.Red
      : maxChars - numChars <= maxChars * 0.1
      ? Colors.Orange
      : Colors.RingCounterBlue;
  const strokeDasharray = `${colored}  ${gray}`;
  const classes = useRingCounterStyles({ radius, strokeDasharray, stroke });

  return (
    <Tooltip title={`${numChars} of ${maxChars} characters used.`}>
      <svg className={classes.ring}>
        <circle />
        <circle />
      </svg>
    </Tooltip>
  );
};

export default RingCounter;
