/**
 * Discover Result Hit
 */
import React, { ReactElement, ReactNode } from 'react';
import { Box, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import PersonIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import SportsIcon from '@material-ui/icons/Sports';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import pluralize from 'pluralize';
import * as R from 'ramda';

import RoundTextChip from '@/ui/Chips/RoundTextChip';
import SVGIconBackground from '@/ui/Icons/SVGIconBackground';
import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';

import { ALGOLIA_DISCOVER_HIT_CLICKED } from '@/analytics/algolia/algolia';
import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import { DEFAULT_COACH_IMAGE, DEFAULT_EVENT_IMAGE, SearchTypeEnum } from '@/const';
import { getLessonPrice } from '@/containers/CoachProfile/helpers';
import { AlgoliaSearchResultHit } from '@/lib/algolia';
import { applyDefaultCloudinaryTransform } from '@/lib/cloudinary';
import { getAgeText, getCoachingSummary, getReadableCoachVibe } from '@/lib/coach-info-utils';
import {
  minutesToHoursAndMinutesText,
  minutesToHoursOrMinutesText,
  ONE_HOUR_IN_MINUTES,
} from '@/lib/date-helpers/date-utils';
import { getEventDates } from '@/lib/event-info-utils';
import { getLessonSummaryNode } from '@/lib/lesson-info-utils';
import { roundToNearestHundredth } from '@/lib/math-utils';
import { addQueryParamsToUrl } from '@/lib/path-helpers';
import { getVenueLocationText } from '@/lib/places-utils';
import { getAbilityLabelFull } from '@/lib/slider-utils';
import {
  arrayToCommaAndString,
  capitalizeFirstLetter,
  numberArrayToPriceRange,
} from '@/lib/string-utils';
import { textEllipsisProps } from '@/lib/ui-utils';
import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    border: Colors.LightBorder,
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(2.5),
    height: 275,
    '&:hover img': {
      transform: 'scale(1.04)',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    borderRadius: theme.spacing(0.25),
    height: '100%',
    minWidth: '280px',
    maxWidth: '280px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '240px',
    },
    '& > img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
      transition: 'transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      willChange: 'transform',
    },
  },
}));

const getActivityIcon = (activity: SearchTypeEnum): ReactElement | null => {
  switch (activity) {
    case SearchTypeEnum.Coach:
      return (
        <Tooltip title={SearchTypeEnum.Coach}>
          <PersonIcon />
        </Tooltip>
      );
    case SearchTypeEnum.PrivateLesson:
      return (
        <Tooltip title={SearchTypeEnum.PrivateLesson}>
          <SportsIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Clinic:
      return (
        <Tooltip title={SearchTypeEnum.Clinic}>
          <SchoolIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Ride:
      return (
        <Tooltip title={SearchTypeEnum.Ride}>
          <DirectionsBikeIcon />
        </Tooltip>
      );
    case SearchTypeEnum.Race:
      return (
        <Tooltip title={SearchTypeEnum.Race}>
          <svg
            aria-hidden="true"
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root"
            data-testid="MilitaryTechIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="M17 10.43V2H7v8.43c0 .35.18.68.49.86l4.18 2.51-.99 2.34-3.41.29 2.59 2.24L9.07 22 12 20.23 14.93 22l-.78-3.33 2.59-2.24-3.41-.29-.99-2.34 4.18-2.51c.3-.18.48-.5.48-.86zm-4 1.8-1 .6-1-.6V3h2v9.23z" />
          </svg>
        </Tooltip>
      );
    case SearchTypeEnum.OnlineCourse:
      return (
        <Tooltip title={SearchTypeEnum.OnlineCourse}>
          <CastForEducationIcon />
        </Tooltip>
      );
    default:
      return null;
  }
};

/**
 * Get the url for a hit
 * @param hit
 */
const getImageUrls = (hit: AlgoliaSearchResultHit): string[] => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.images.length
        ? hit.images.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_EVENT_IMAGE];
    case SearchTypeEnum.Coach:
      return hit.profilePicUrl ? [hit.profilePicUrl] : [DEFAULT_COACH_IMAGE];
    case SearchTypeEnum.PrivateLesson:
      return hit.coachImages.length
        ? hit.coachImages.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_COACH_IMAGE];
    default:
      return [''];
  }
};

/**
 * Get the hostname for a hit
 * @param hit
 */
const getHostText = (hit: AlgoliaSearchResultHit): string => {
  switch (hit.activity) {
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.PrivateLesson:
      return `${hit.hostName || hit.organizerName}`;
    case SearchTypeEnum.Coach:
      return getReadableCoachVibe(hit?.coachingVibe);
    default:
      return '';
  }
};

/**
 * Get the description for a hit
 * @param hit
 */
export const getAttributesText = (hit: AlgoliaSearchResultHit): string | ReactNode => {
  switch (hit.activity) {
    case SearchTypeEnum.Clinic:
      return capitalizeFirstLetter(arrayToCommaAndString(hit.disciplines).toLowerCase());
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Ride: {
      if (hit.isCategoriedEvent) {
        return getEventDates(hit);
      }
      return `${hit.rideType} • ${capitalizeFirstLetter(
        arrayToCommaAndString(hit.disciplines).toLowerCase(),
      )}`;
    }
    case SearchTypeEnum.Race:
      return getEventDates(hit);
    case SearchTypeEnum.Coach:
      return capitalizeFirstLetter(arrayToCommaAndString(hit.coachFocus).toLowerCase());
    case SearchTypeEnum.PrivateLesson:
      return capitalizeFirstLetter(`${getReadableCoachVibe(hit.coachingVibe)} coach`.toLowerCase());
    default:
      return <Typography variant="subtitle2" />;
  }
};

/**
 * Get the ability for a hit
 * @param hit
 */
const getAbilityText = (hit: AlgoliaSearchResultHit): string | ReactNode => {
  const abilityLabel = getAbilityLabelFull({
    abilityLevelRange: [hit.abilityMin, hit.abilityMax],
    longFormInsteadOfOpen: true,
  });
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
    case SearchTypeEnum.Coach:
      return abilityLabel;
    case SearchTypeEnum.PrivateLesson:
      return `${abilityLabel} • ${capitalizeFirstLetter(
        arrayToCommaAndString(hit.disciplines).toLowerCase(),
      )}`;
    default:
      return <Typography variant="subtitle2" />;
  }
};

/**
 * Get durations
 */
const getDurationText = (hit: AlgoliaSearchResultHit): ReactNode => {
  const categories = `${hit.categoryNames?.length} ${pluralize(
    'category',
    hit.categoryNames?.length,
  )}`;
  switch (hit.activity) {
    case SearchTypeEnum.Clinic: {
      return hit.eventClinicDayDurations?.length > 1
        ? `${hit.eventClinicDayDurations?.length} days`
        : minutesToHoursAndMinutesText(hit.eventClinicDayDurations?.[0]?.duration);
    }
    case SearchTypeEnum.Ride: {
      if (hit.isCategoriedEvent) {
        return categories;
      }
      const minHours = hit.ridingTimePerDayMin;
      const maxHours = hit.ridingTimePerDayMax;
      return R.uniq(hit.ridingTimePerDayRange).length > 1
        ? `${minHours} - ${maxHours} ${pluralize('hour', maxHours)}`
        : minutesToHoursAndMinutesText(minHours * ONE_HOUR_IN_MINUTES);
    }
    case SearchTypeEnum.Race:
      return categories;
    case SearchTypeEnum.Coach:
      return getAgeText([hit.ageMin, hit.ageMax]);
    case SearchTypeEnum.PrivateLesson: {
      const duration = minutesToHoursOrMinutesText(hit.duration);
      return duration;
    }
    case SearchTypeEnum.OnlineCourse:
      return `${hit.totalSections + pluralize('section', hit.totalSections)} • ${
        hit.totalLessons
      } ${pluralize('lesson', hit.totalLessons)}`;
    default:
      return <Typography variant="subtitle2" />;
  }
};

/**
 * Get the location for a hit
 * @param hit
 */
const getPriceText = (hit: AlgoliaSearchResultHit): ReactNode => {
  const prices = hit.entryFees || hit.prices || [hit.price];
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
    case SearchTypeEnum.Coach:
      return numberArrayToPriceRange(prices, hit.currencyCode);
    case SearchTypeEnum.PrivateLesson:
      return getLessonPrice({
        price: prices[0],
        coach: { currencyCode: hit.currencyCode },
        participantRange: hit.participantRange,
      });
    default:
      return <Typography variant="subtitle2" />;
  }
};

/**
 * Get the description for a hit
 * @param hit
 */
const getDescriptionText = (hit: AlgoliaSearchResultHit): string | ReactNode => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.description;
    case SearchTypeEnum.Coach:
      return (
        hit.coachTagline ||
        hit.coachAbout ||
        getCoachingSummary({
          coachingVibe: hit.coachingVibe,
          coachDisciplines: hit.coachDisciplines,
        })
      );
    case SearchTypeEnum.PrivateLesson:
      return getLessonSummaryNode(hit);
    default:
      return <Typography variant="subtitle2">No description</Typography>;
  }
};

/**
 * Get the hostname for a hit
 * @param hit
 */
const getReviewText = (hit: AlgoliaSearchResultHit): ReactNode => {
  if (R.isNil(hit.reviewRecPct)) {
    return <b>New</b>;
  }
  return (
    <>
      {hit.reviewRecPct}% <ThumbUpAltIcon fontSize="inherit" />
    </>
  );
};

interface DesktopHitProps {
  hit: AlgoliaSearchResultHit;
  sendClickEvent: () => void;
}
const DesktopHit: React.FC<DesktopHitProps> = ({ hit, sendClickEvent }) => {
  const classes = useStyles();
  const url = hit.onlineCourseUrl || hit.lessonUrl || hit.coachUrl || hit.eventUrl;
  // eslint-disable-next-line no-underscore-dangle
  const hitLink = addQueryParamsToUrl(url, { queryId: hit.__queryID });

  const activityIcon = getActivityIcon(hit.activity as SearchTypeEnum);
  const imageUrls = getImageUrls(hit);
  const titleText = hit.name || hit.hostName;
  const locationText = hit.isVirtual ? 'Virtual' : getVenueLocationText(hit);
  const hostText = getHostText(hit);
  const attributesText = getAttributesText(hit);
  const priceText = getPriceText(hit);
  const abilityText = getAbilityText(hit);
  const durationText = getDurationText(hit);
  const descriptionText = getDescriptionText(hit);
  const reviewText = getReviewText(hit);

  return (
    <NoUnderlineRouterLink onClick={sendClickEvent} to={hitLink}>
      <Box className={classes.root}>
        <Box className={classes.imageContainer} key={imageUrls[0]}>
          <img alt="Event Preview" src={imageUrls[0]} />
        </Box>
        <Box display="flex" flexDirection="column" minWidth={0} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box minWidth={0}>
              <Typography
                style={{
                  ...textEllipsisProps,
                  color: Colors.DarkText,
                  marginRight: '4px',
                  marginBottom: '4px',
                }}
                variant="h2"
              >
                {titleText}
              </Typography>
              <Typography style={{ ...textEllipsisProps, color: Colors.DarkText }} variant="body1">
                <b>{hostText}</b>
              </Typography>
            </Box>
            <Box alignItems="center" ml={1}>
              <RoundTextChip backgroundColor={Colors.DarkBlue} title={hit.activity} />
              {/* <SVGIconBackground>{activityIcon}</SVGIconBackground> */}
            </Box>
          </Box>

          <Box my={2}>
            <Typography
              style={{ height: 75, overflow: 'hidden', wordBreak: 'break-word' }}
              variant="body2"
            >
              {descriptionText}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={0.5}>
              <Typography style={{ ...textEllipsisProps }} variant="subtitle2">
                {attributesText}
              </Typography>
              <Typography style={{ whiteSpace: 'nowrap', marginRight: '4px' }} variant="subtitle2">
                {locationText}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={0.5}>
              <Typography style={{ ...textEllipsisProps }} variant="subtitle2">
                {abilityText}
              </Typography>
              <Typography style={{ whiteSpace: 'nowrap', marginRight: '4px' }} variant="subtitle2">
                {durationText}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
              <Typography style={{ ...textEllipsisProps }} variant="subtitle2">
                {priceText}
              </Typography>
              <Typography style={{ whiteSpace: 'nowrap', marginRight: '4px' }} variant="subtitle2">
                {reviewText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </NoUnderlineRouterLink>
  );
};

export default DesktopHit;
