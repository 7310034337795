/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type activityType_enum = "Clinic" | "Race" | "Ride" | "%future added value";
export type helpers_getEventActivity_event = {
    readonly activity: activityType_enum;
    readonly " $refType": "helpers_getEventActivity_event";
};
export type helpers_getEventActivity_event$data = helpers_getEventActivity_event;
export type helpers_getEventActivity_event$key = {
    readonly " $data"?: helpers_getEventActivity_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getEventActivity_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getEventActivity_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activity",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '4e5c28341e907bd0c7d659846a308472';
export default node;
