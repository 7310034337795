/**
 *  A wrapper that lets you set a max height and if the inner child is > max height,
 *  display a read more button
 */
import React, { useEffect, useRef, useState } from 'react';
import { Box, RootRef } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';

import LinkButton from '@/ui/Button/LinkButton';

import { ReactFCC } from '@/lib/type-defs/utility';

const GRADIENT_HEIGHT = 30;

interface ReadMoreProps {
  hideGradient?: boolean;
  maxHeight: number;
  readMoreText?: string;
  showHide?: boolean;
}
const useStyles = makeStyles(() => ({
  gradient: ({ hideGradient, maxHeight }: ReadMoreProps): CreateCSSProperties => ({
    background: hideGradient ? undefined : 'linear-gradient(rgba(255, 255, 255, 0), white)',
    height: GRADIENT_HEIGHT,
    top: maxHeight - GRADIENT_HEIGHT,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  }),
}));
const ReadMore: ReactFCC<ReadMoreProps> = ({
  children,
  hideGradient,
  maxHeight,
  readMoreText,
  showHide,
}) => {
  const classes = useStyles({ maxHeight, hideGradient });
  const [showReadMore, setShowReadMore] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    if (wrapperRef.current?.scrollHeight > maxHeight) {
      setShowReadMore(true);
    } else {
      setShowReadMore(false);
    }
  }, [wrapperRef.current, wrapperRef.current?.scrollHeight]);

  const toggleReadMore = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setShowReadMore((s) => !s);
  };

  return (
    <>
      <RootRef rootRef={wrapperRef}>
        <Box maxHeight={showReadMore ? maxHeight : undefined} overflow="hidden" position="relative">
          {children}
          {showReadMore && <Box className={classes.gradient} />}
        </Box>
      </RootRef>
      {showReadMore && (
        <Box mt={0.5}>
          <LinkButton onClick={toggleReadMore}>{readMoreText || 'Read More'}</LinkButton>
        </Box>
      )}
      {!showReadMore && wrapperRef.current?.scrollHeight > maxHeight && showHide && (
        <Box mt={0.5}>
          <LinkButton onClick={toggleReadMore}>Hide</LinkButton>
        </Box>
      )}
    </>
  );
};

export default ReadMore;
