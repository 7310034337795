/*
All mutations for the 'EventMetadata Dialog'
// https://github.com/facebook/relay/issues/1701
 */
import { graphql, useMutation } from 'react-relay/hooks';
import { RecordSourceProxy } from 'relay-runtime';

import {
  eventMetadataMutations_updateOccurrenceLabel_Mutation,
  eventMetadataMutations_updateOccurrenceLabel_MutationResponse,
} from './__generated__/eventMetadataMutations_updateOccurrenceLabel_Mutation.graphql';

import {
  eventMetadataMutations_deleteEventAndOccurrences_Mutation,
  eventMetadataMutations_deleteEventAndOccurrences_MutationResponse,
} from '@/containers/HostEvent/EventMetadataDialog/__generated__/eventMetadataMutations_deleteEventAndOccurrences_Mutation.graphql';
import {
  eventMetadataMutations_updateEventName_Mutation,
  eventMetadataMutations_updateEventName_MutationResponse,
} from '@/containers/HostEvent/EventMetadataDialog/__generated__/eventMetadataMutations_updateEventName_Mutation.graphql';
import {
  useGetEncodedEventId,
  useGetEncodedEventMetadataId,
  useGetEventCuid,
  useUpdateAlgoliaSearchIndexes,
} from '@/containers/HostEvent/helpers';
import { deleteEventCF } from '@/lib/firebase';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { AppRouteService } from '@/routes/RouteService';
/**
 * Update the occurrence labels
 */
export function useUpdateOccurrenceLabelMutation(): {
  updateOccurrenceLabelMutation: (
    eventCuid: string,
    occurrenceLabel: string,
  ) => Promise<eventMetadataMutations_updateOccurrenceLabel_MutationResponse>;
  isUpdateOccurrenceLabelMutationInFlight: boolean;
} {
  const encodedEventId = useGetEncodedEventId();
  const updateAlgoliaIndexes = useUpdateAlgoliaSearchIndexes();
  const [
    commitMutation,
    isUpdateOccurrenceLabelMutationInFlight,
  ] = useMutation<eventMetadataMutations_updateOccurrenceLabel_Mutation>(
    graphql`
      mutation eventMetadataMutations_updateOccurrenceLabel_Mutation(
        $eventCuid: String!
        $occurrenceLabel: String!
      ) {
        update_event(
          where: { cuid: { _eq: $eventCuid } }
          _set: { lastSavedAt: "now()", occurrenceLabel: $occurrenceLabel }
        ) {
          returning {
            lastSavedAt
            occurrenceLabel
            id
          }
        }
      }
    `,
  );

  function updateOccurrenceLabelMutation(
    eventCuid: string,
    occurrenceLabel: string,
  ): Promise<eventMetadataMutations_updateOccurrenceLabel_MutationResponse> {
    return new Promise((resolve, reject) => {
      commitMutation({
        variables: {
          occurrenceLabel,
          eventCuid,
        },
        onCompleted: (response) => {
          updateAlgoliaIndexes(eventCuid);
          resolve(response);
        },
        onError: (error) => reject(error),
        optimisticUpdater: (store: RecordSourceProxy) => {
          const eventRecord = store.get(encodedEventId);
          if (!eventRecord) {
            return;
          }
          eventRecord.setValue(occurrenceLabel, 'occurrenceLabel');
        },
      });
    });
  }

  return { updateOccurrenceLabelMutation, isUpdateOccurrenceLabelMutationInFlight };
}

/**
 * Update the event name
 */
export function useUpdateEventNameMutation(): {
  updateEventNameMutation: (
    name: string,
    nameSlug: string,
  ) => Promise<eventMetadataMutations_updateEventName_MutationResponse>;
  isUpdateEventNameMutationInFlight: boolean;
} {
  const eventCuid = useGetEventCuid();
  const updateAlgoliaIndexes = useUpdateAlgoliaSearchIndexes();
  const encodedEventMetadataId = useGetEncodedEventMetadataId();
  const [
    commitMutation,
    isUpdateEventNameMutationInFlight,
  ] = useMutation<eventMetadataMutations_updateEventName_Mutation>(
    graphql`
      mutation eventMetadataMutations_updateEventName_Mutation(
        $name: String!
        $slug: String!
        $eventCuid: String!
      ) {
        update_eventMetadata(
          where: { events: { cuid: { _eq: $eventCuid } } }
          _set: { name: $name, slug: $slug }
        ) {
          returning {
            name
            slug
          }
        }
        update_event(where: { cuid: { _eq: $eventCuid } }, _set: { lastSavedAt: "now()" }) {
          returning {
            lastSavedAt
            id
          }
        }
      }
    `,
  );

  function updateEventNameMutation(
    name: string,
    nameSlug: string,
  ): Promise<eventMetadataMutations_updateEventName_MutationResponse> {
    return new Promise((resolve, reject) => {
      commitMutation({
        variables: {
          name,
          eventCuid,
          slug: nameSlug,
        },
        onCompleted: (response) => {
          updateAlgoliaIndexes(eventCuid);
          resolve(response);
        },
        onError: (error) => reject(error),
        optimisticUpdater: (store: RecordSourceProxy) => {
          const eventMetadataRecord = store.get(encodedEventMetadataId);
          if (!eventMetadataRecord) {
            return;
          }
          // IMPORTANT: We do NOT update the slug in the local store. This way we have a source
          // of truth for what the currently saved slug is. The way our mutations work, we
          // have to save on every change, including overwriting the current one.
          eventMetadataRecord.setValue(nameSlug, 'slug');
          eventMetadataRecord.setValue(name, 'name');
        },
      });
    });
  }

  return { updateEventNameMutation, isUpdateEventNameMutationInFlight };
}

/**
 * Delete event
 */
export function useDeleteEventAndOccurrencesMutation(): {
  deleteEventAndOccurrencesMutation: (
    eventMetadataCuid: string,
    occurrenceCuids: string[],
  ) => Promise<eventMetadataMutations_deleteEventAndOccurrences_MutationResponse>;
  isDeleteEventAndOccurrencesMutationInFlight: boolean;
} {
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const [
    commitMutation,
    isDeleteEventAndOccurrencesMutationInFlight,
  ] = useMutation<eventMetadataMutations_deleteEventAndOccurrences_Mutation>(
    graphql`
      mutation eventMetadataMutations_deleteEventAndOccurrences_Mutation(
        $eventMetadataCuid: String!
      ) {
        update_eventMetadata(
          where: { cuid: { _eq: $eventMetadataCuid } }
          _set: { deleted_at: "now()" }
        ) {
          affected_rows
        }
      }
    `,
  );

  async function deleteEventAndOccurrencesMutation(
    eventMetadataCuid: string,
    occurrenceCuids: string[],
  ): Promise<eventMetadataMutations_deleteEventAndOccurrences_MutationResponse> {
    // Delete all occurrences
    await Promise.all(occurrenceCuids.map((eventCuid) => deleteEventCF({ eventCuid })));
    // Delete the event
    return new Promise((resolve, reject) => {
      commitMutation({
        variables: {
          eventMetadataCuid,
        },
        onCompleted: (response) => {
          // Go back to organizer page
          window.location.href = AppRouteService.getAbsoluteUrl('OrganizerApp_Events', {
            organizerSlug,
          });
          resolve(response);
        },
        onError: (error) => reject(error),
      });
    });
  }

  return { deleteEventAndOccurrencesMutation, isDeleteEventAndOccurrencesMutationInFlight };
}
