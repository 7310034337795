import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  searchResultContainer: {
    position: 'relative',
    display: 'flex',
  },
  slickContainer: {
    width: 300,
    minWidth: 300,
    height: 200,
    borderRadius: theme.spacing(1),
    border: Colors.LightBorder,
    overflow: 'hidden',
  },
}));

const DesktopResultPlaceholder: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Box className={classes.searchResultContainer}>
          <Box className={classes.slickContainer}>
            <Skeleton height={200} variant="rect" width="100%" />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minWidth={0}
            px={1.5}
            width="100%"
          >
            <Box display="flex" flexDirection="column" minWidth={0} width="100%">
              <Box display="flex" flexDirection="column" width="100%">
                <Skeleton height={20} variant="text" width="100%" />
                <Skeleton height={20} variant="text" width="100%" />
              </Box>
              <Box my={1} width={32}>
                <Divider />
              </Box>
              <Skeleton height={60} variant="text" width="100%" />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Skeleton height={20} variant="text" width={80} />
              <Skeleton height={20} variant="text" width={80} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={2.5}>
        <Divider />
      </Box>
    </>
  );
};

export default DesktopResultPlaceholder;
