/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type eventMetadataMutations_updateEventName_MutationVariables = {
    name: string;
    slug: string;
    eventCuid: string;
};
export type eventMetadataMutations_updateEventName_MutationResponse = {
    readonly update_eventMetadata: {
        readonly returning: ReadonlyArray<{
            readonly name: string;
            readonly slug: string;
        }>;
    } | null;
    readonly update_event: {
        readonly returning: ReadonlyArray<{
            readonly lastSavedAt: string;
            readonly id: string;
        }>;
    } | null;
};
export type eventMetadataMutations_updateEventName_Mutation = {
    readonly response: eventMetadataMutations_updateEventName_MutationResponse;
    readonly variables: eventMetadataMutations_updateEventName_MutationVariables;
};



/*
mutation eventMetadataMutations_updateEventName_Mutation(
  $name: String!
  $slug: String!
  $eventCuid: String!
) {
  update_eventMetadata(where: {events: {cuid: {_eq: $eventCuid}}}, _set: {name: $name, slug: $slug}) {
    returning {
      name
      slug
      id
    }
  }
  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {lastSavedAt: "now()"}) {
    returning {
      lastSavedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "eventCuid"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "name"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "slug"
    } as any, v3 = [
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "eventCuid"
                }
            ],
            "kind": "ObjectValue",
            "name": "cuid"
        } as any
    ], v4 = [
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "name",
                    "variableName": "name"
                },
                {
                    "kind": "Variable",
                    "name": "slug",
                    "variableName": "slug"
                }
            ],
            "kind": "ObjectValue",
            "name": "_set"
        } as any,
        {
            "fields": [
                {
                    "fields": (v3 /*: any*/),
                    "kind": "ObjectValue",
                    "name": "events"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "_set",
                "value": {
                    "lastSavedAt": "now()"
                }
            },
            {
                "fields": (v3 /*: any*/),
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "event_mutation_response",
        "kind": "LinkedField",
        "name": "update_event",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "event",
                "kind": "LinkedField",
                "name": "returning",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSavedAt",
                        "storageKey": null
                    },
                    (v7 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "eventMetadataMutations_updateEventName_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "eventMetadata_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_eventMetadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventMetadata",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v5 /*: any*/),
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v8 /*: any*/)
            ],
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "eventMetadataMutations_updateEventName_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "eventMetadata_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_eventMetadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventMetadata",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v8 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "5f821946cc1a43c2353eed0852b713e8",
            "id": null,
            "metadata": {},
            "name": "eventMetadataMutations_updateEventName_Mutation",
            "operationKind": "mutation",
            "text": "mutation eventMetadataMutations_updateEventName_Mutation(\n  $name: String!\n  $slug: String!\n  $eventCuid: String!\n) {\n  update_eventMetadata(where: {events: {cuid: {_eq: $eventCuid}}}, _set: {name: $name, slug: $slug}) {\n    returning {\n      name\n      slug\n      id\n    }\n  }\n  update_event(where: {cuid: {_eq: $eventCuid}}, _set: {lastSavedAt: \"now()\"}) {\n    returning {\n      lastSavedAt\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '80dc2bfc7e39c8967208864707bb38e6';
export default node;
