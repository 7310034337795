import { Theme } from '@material-ui/core';

import {  fade } from '../colors';

export const MuiInput = (theme: Theme): Record<string, unknown> => ({
  input: {
    '&::placeholder': {
      color: '#999999 !important',
    },
  },
  underline: {
    '&:before': {
      // boxShadow: 'rgba(0, 0, 0, 0.0764) 0px 5px 5px -3px',
      transition: ' border-color 150ms ease',
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: '#03465c95',
      // borderBottom: `1px solid #03465c95`,
    },
    '&.Mui-disabled.Mui-error:after': {
      // Disabled error border
      borderBottomColor:  fade(theme.palette.error.main, 0.5),
    },
    '& + .MuiFormHelperText-root.Mui-disabled.Mui-error.MuiFormHelperText-filled': {
      // Disabled error helper text
      color:  fade(theme.palette.error.main, 0.5),
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    '&.MuiInputBase-multiline.MuiInputBase-marginDense': {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },
});
