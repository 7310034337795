/*
Route that has extra props on it for authentication, tracking, page title, etc
 */
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { RoleOrganizerEnum } from '@/graphql/__generated__/graphql';
import { useOrganizerPermissions } from '@/lib/role-helpers/organizer-role-utils';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';
import { AllOrganizerAppRoles, AppRole } from '@/routes/route-metadata';
import { AppRouteService } from '@/routes/RouteService';

const validRolesContainsFactory = (validRoles: readonly AppRole[], checkType: 'all' | 'some') => (
  roleToCheck: AppRole | AppRole[],
): boolean => {
  const rolesToCheck = Array.isArray(roleToCheck) ? roleToCheck : [roleToCheck];
  if (checkType === 'all') {
    return validRoles.every((role) => rolesToCheck.includes(role));
  }
  return validRoles.some((role) => rolesToCheck.includes(role));
};

interface CheckRoutePermissionsProps {
  validRoles: readonly AppRole[] | undefined;
}
const CheckRoutePermissions: ReactFCC<CheckRoutePermissionsProps> = ({ children, validRoles }) => {
  const auth = useAuth();
  const { organizerSlug } = useParams();
  const { checkOrganizerRole } = useOrganizerPermissions();

  // No roles are required, so just render the children
  if (!validRoles?.length) {
    return <>{children}</>;
  }

  const currentUserRoles = [
    ...(auth.user?.userOrganizerRoles.map((r) => `Organizer${r.role}` as AppRole) ?? []),
    auth.user?.isCreator ? AppRole.Creator : undefined,
    auth.user?.isCoach ? AppRole.Coach : undefined,
  ].filter(Boolean) as AppRole[];

  // console.log('currentUserRoles', currentUserRoles);
  // console.log('validRoles', validRoles);
  const validRolesContainsSome = validRolesContainsFactory(validRoles, 'some');

  /// /////////////////////////////////////////////////////////////////
  // Creator Check
  // All AppRoles require a creator role
  /// /////////////////////////////////////////////////////////////////
  if (validRolesContainsSome(Object.values(AppRole)) && !auth.user?.isCreator) {
    // console.log('Creator role required');
    return <Navigate replace to={AppRouteService.getRelativeUrl('JoinWaitlist')} />;
  }

  /// /////////////////////////////////////////////////////////////////
  // All Roles Check
  // Do we have any of the valid roles?
  /// /////////////////////////////////////////////////////////////////
  const hasValidRole = validRolesContainsSome(currentUserRoles);
  if (!hasValidRole) {
    // console.log('No valid roles');
    return <NavigateToOrganizerAppOrNotFound />;
  }

  /// /////////////////////////////////////////////////////////////////
  // Coach Check
  /// /////////////////////////////////////////////////////////////////
  if (validRolesContainsSome([AppRole.Coach]) && !auth.user?.isCoach) {
    // console.log('Coach role required');
    return <Navigate replace to={AppRouteService.getRelativeUrl('User_BecomeCoach')} />;
  }

  /// /////////////////////////////////////////////////////////////////
  // Organizer Check
  /// /////////////////////////////////////////////////////////////////
  if (validRolesContainsSome(AllOrganizerAppRoles)) {
    // console.log('Organizer role required');
    // find organizer slug from userOrganizerRoles based on selectedOrganizerSlug
    const selectedOrganizerRole = auth.user?.userOrganizerRoles.find(
      (uor) => uor.organizer?.slug === auth.user?.selectedOrganizerSlug,
    );
    const currentOrganizerSlug =
      organizerSlug ||
      selectedOrganizerRole?.organizer?.slug ||
      auth.user?.userOrganizerRoles[0]?.organizer?.slug ||
      null;

    // We couldn't find the organizer slug.
    if (!currentOrganizerSlug) {
      // console.log('No organizer slug found');
      return <Navigate replace to={AppRouteService.getRelativeUrl('Organizer_Start')} />;
    }

    // Are we viewing an organization by slug?
    if (currentOrganizerSlug) {
      // console.log('Organizer slug found');
      // Can we view this org?
      const isOwner = checkOrganizerRole(RoleOrganizerEnum.Owner, currentOrganizerSlug);
      const isAdmin = checkOrganizerRole(RoleOrganizerEnum.Admin, currentOrganizerSlug);
      const isSupport = checkOrganizerRole(RoleOrganizerEnum.Support, currentOrganizerSlug);
      const isCoach = checkOrganizerRole(RoleOrganizerEnum.Coach, currentOrganizerSlug);
      // console.log('isOwner', isOwner);
      // console.log('isAdmin', isAdmin);
      // console.log('isSupport', isSupport);
      // console.log('isCoach', isCoach);
      if (
        validRolesContainsSome(AppRole.OrganizerOwner) &&
        !isOwner &&
        validRolesContainsSome(AppRole.OrganizerAdmin) &&
        !isAdmin &&
        validRolesContainsSome(AppRole.OrganizerSupport) &&
        !isSupport &&
        validRolesContainsSome(AppRole.OrganizerCoach) &&
        !isCoach
      ) {
        // console.log('Organizer role not found');
        return <Navigate replace to={AppRouteService.getRelativeUrl('NotFound')} />;
        // Bug where if you are trying access something int he org with no permissions it
        // will redirect you to the org page on a loop. Need to either check for "member" role
        // or just og not found
        // return <NavigateToOrganizerAppOrNotFound />;
      }
      // If the user can edit AND it is our first time viewing the organizer, redirect to
      // onboarding
      const canEditOrganization = isOwner || isAdmin;
      const organizerOnboardingRoute = AppRouteService.getRelativeUrl(
        'OrganizerApp_OrganizerOnboarding',
        {
          organizerSlug: currentOrganizerSlug,
        },
      );
      if (
        canEditOrganization &&
        selectedOrganizerRole?.organizer?.showOnboarding &&
        !AppRouteService.matchesRoute('OrganizerApp_OrganizerOnboarding', {
          organizerSlug: currentOrganizerSlug,
        })
      ) {
        // console.log('Redirecting to onboarding');
        return <Navigate replace to={organizerOnboardingRoute} />;
      }
    }
  }
  // console.log('Render children');
  return <>{children}</>;
};

const NavigateToOrganizerAppOrNotFound: React.FC = () => {
  const { organizerSlug } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  if (!organizerSlug) {
    return <Navigate replace to={AppRouteService.getRelativeUrl('NotFound')} />;
  }

  const isInOrganizerApp = window.location.pathname.includes(
    AppRouteService.getRelativeUrl('OrganizerApp', {
      organizerSlug,
    }),
  );

  if (isInOrganizerApp) {
    // We are in the organizer app. Do not redirect all the way home
    enqueueSnackbar('You do not have permission to visit that page.', {
      variant: 'error',
    });
    return (
      <Navigate
        replace
        to={AppRouteService.getRelativeUrl('OrganizerApp_Events', { organizerSlug })}
      />
    );
  }
  return <Navigate replace to={AppRouteService.getRelativeUrl('NotFound')} />;
};

export default CheckRoutePermissions;
