/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PersonalizationServiceQueries_updatePersonalizationPrefs_MutationVariables = {
    preferences?: Record<string, unknown> | unknown[] | null | undefined;
    id: number;
};
export type PersonalizationServiceQueries_updatePersonalizationPrefs_MutationResponse = {
    readonly update_userPersonalization_by_pk: {
        readonly preferences: Record<string, unknown> | unknown[];
    } | null;
};
export type PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation = {
    readonly response: PersonalizationServiceQueries_updatePersonalizationPrefs_MutationResponse;
    readonly variables: PersonalizationServiceQueries_updatePersonalizationPrefs_MutationVariables;
};



/*
mutation PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation(
  $preferences: jsonb
  $id: Int!
) {
  update_userPersonalization_by_pk(_set: {preferences: $preferences}, pk_columns: {id: $id}) {
    preferences
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "preferences"
    } as any, v2 = [
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "preferences",
                    "variableName": "preferences"
                }
            ],
            "kind": "ObjectValue",
            "name": "_set"
        } as any,
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "kind": "ObjectValue",
            "name": "pk_columns"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preferences",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "userPersonalization",
                    "kind": "LinkedField",
                    "name": "update_userPersonalization_by_pk",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "userPersonalization",
                    "kind": "LinkedField",
                    "name": "update_userPersonalization_by_pk",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "745a8322d24ad76a1d873622e2accfe1",
            "id": null,
            "metadata": {},
            "name": "PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation",
            "operationKind": "mutation",
            "text": "mutation PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation(\n  $preferences: jsonb\n  $id: Int!\n) {\n  update_userPersonalization_by_pk(_set: {preferences: $preferences}, pk_columns: {id: $id}) {\n    preferences\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '88d92530ad4a426af4cb0851a1b5fe4c';
export default node;
