/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AuthProvider_updateUserEmail_MutationVariables = {
    userId: string;
    email: string;
};
export type AuthProvider_updateUserEmail_MutationResponse = {
    readonly update_user: {
        readonly returning: ReadonlyArray<{
            readonly email: string;
        }>;
    } | null;
};
export type AuthProvider_updateUserEmail_Mutation = {
    readonly response: AuthProvider_updateUserEmail_MutationResponse;
    readonly variables: AuthProvider_updateUserEmail_MutationVariables;
};



/*
mutation AuthProvider_updateUserEmail_Mutation(
  $userId: String!
  $email: String!
) {
  update_user(where: {id: {_eq: $userId}}, _set: {lastSavedAt: "now()", email: $email}) {
    returning {
      email
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "email"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    } as any, v2 = [
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Literal",
                    "name": "lastSavedAt",
                    "value": "now()"
                }
            ],
            "kind": "ObjectValue",
            "name": "_set"
        } as any,
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthProvider_updateUserEmail_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "user_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AuthProvider_updateUserEmail_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "user_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "939b72e17d4479a6215c1c71769a6de8",
            "id": null,
            "metadata": {},
            "name": "AuthProvider_updateUserEmail_Mutation",
            "operationKind": "mutation",
            "text": "mutation AuthProvider_updateUserEmail_Mutation(\n  $userId: String!\n  $email: String!\n) {\n  update_user(where: {id: {_eq: $userId}}, _set: {lastSavedAt: \"now()\", email: $email}) {\n    returning {\n      email\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5e4b758c139a8c5cb2a9c11bfe5b16de';
export default node;
