import React, { createContext, useContext, useEffect, useState } from 'react';
import { CourierProvider as ReactCourierProvider } from '@trycourier/react-provider';

import { useTrackSentryError } from '@/analytics/sentry';
import ENV from '@/env';
import { getCourierHMACCF } from '@/lib/firebase';
import { ReactFCC } from '@/lib/type-defs/utility';
import { useAuth } from '@/providers/AuthProvider';

const CourierProvider: ReactFCC = ({ children }) => {
  const auth = useAuth();
  const trackSentryError = useTrackSentryError();
  const [userKey, setUserKey] = useState<string | undefined>();

  useEffect(() => {
    (async (): Promise<void> => {
      if (!auth.firebaseUser?.uid || userKey) {
        return;
      }
      try {
        const hmac = await getCourierHMACCF();
        setUserKey(hmac.data);
      } catch (err) {
        trackSentryError(err);
      }
    })();
  }, [auth.firebaseUser?.uid, userKey]);

  return (
    <ReactCourierProvider
      clientKey={ENV.COURIER_CLIENT_KEY}
      userId={auth.firebaseUser?.uid}
      userSignature={userKey}
    >
      <courierContext.Provider
        value={{
          isInitiated: Boolean(userKey),
        }}
      >
        {children}
      </courierContext.Provider>
    </ReactCourierProvider>
  );
};

const courierContext = createContext({});

export const useCourierContext = (): { isInitiated: boolean } => {
  return useContext(courierContext) as { isInitiated: boolean };
};

export default CourierProvider;
