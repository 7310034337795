/**
 * An 'or' divider to use in forms
 * e.g. ----- or -----
 */
import React from 'react';
import { Theme, Typography, TypographyVariant } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
    // ...theme.typography.caption,
    color: '#9ca6af',
    '&:after, &::before': {
      borderTop: '1px solid #d5dce0',
      content: '""',
      height: '1px',
      left: 0,
      position: 'absolute',
      top: '50%',
      width: '40%',
      zIndex: 0,
    },
    '&::before': {
      left: 'auto',
      right: '0',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      '&::before, &::after': {
        width: '35%',
      },
    },
  },
  noMargin: {
    margin: '0 !important',
  },
}));

interface FormOrDividerProps extends BoxProps {
  dividerText?: string;
  noMargin?: boolean;
  typographyVariant?: TypographyVariant;
}
const FormOrDivider: React.FC<FormOrDividerProps> = ({
  className,
  dividerText = 'or',
  my = 3,
  noMargin,
  typographyVariant,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classNames(classes.root, { [classes.noMargin]: noMargin }, className)}
      my={my}
      {...rest}
    >
      <Typography variant={typographyVariant}>{dividerText}</Typography>
    </Box>
  );
};

export default FormOrDivider;
