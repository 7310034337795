/**
 * Signup Dialog. Accessible from anywhere on teh site
 */
import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import SignupFormContainer from './SignupFormContainer';

import {
  useGetAuthRedirectURLGlobal,
  useGetIsLoginSignupDialogBlockingGlobal,
  useGetIsSignupDialogOpenGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsSignupDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useDialogStyles } from '@/pages/Auth/styles/loginSignupStyles';
import { useAuth } from '@/providers/AuthProvider';

const SignupDialog: React.FC = () => {
  const auth = useAuth();
  const dialogClasses = useDialogStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const authRedirectURLGlobal = useGetAuthRedirectURLGlobal();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const isSignupDialogOpenGlobal = useGetIsSignupDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const isLoginSignupDialogBlockingGlobal = useGetIsLoginSignupDialogBlockingGlobal();

  // Make sure we aren't loading when we close the dialog
  useEffect(() => {
    if (!isSignupDialogOpenGlobal) {
      auth.setIsLoading(false);
    } else if (!authRedirectURLGlobal) {
      // Since we are using the dialog, set the redirect URL to the current URL if we dont have one
      setAuthRedirectURLGlobal(window.location.pathname + window.location.search);
    }
  }, [isSignupDialogOpenGlobal]);

  const handleClose = (): void => {
    setIsSignupDialogOpenGlobal(false);
  };

  return (
    <Dialog
      fullWidth
      PaperProps={{
        classes: { root: classNames(dialogClasses.paper, dialogClasses.paperMinHeight) },
      }}
      aria-labelledby="customized-dialog-title"
      disableBackdropClick={isLoginSignupDialogBlockingGlobal}
      disableEscapeKeyDown={isLoginSignupDialogBlockingGlobal}
      fullScreen={isSmallScreenDown}
      onClose={handleClose}
      open={isSignupDialogOpenGlobal}
    >
      {!isLoginSignupDialogBlockingGlobal && (
        <IconButton aria-label="close" className={dialogClasses.closeButton} onClick={handleClose}>
          <CloseIcon className={dialogClasses.svg} />
        </IconButton>
      )}
      {isSignupDialogOpenGlobal && <SignupFormContainer isDialog />}
    </Dialog>
  );
};

export default SignupDialog;
