/**
 * More Filter component for Search Page
 */
import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as R from 'ramda';

import LinkButton from '@/ui/Button/LinkButton';
import Slider from '@/ui/Slider';

import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import ReadMore from '@/components/ReadMore/ReadMore';

import {
  ABILITY_LEVEL_MIN_MAX,
  ABILITY_LEVEL_STEP,
  AGE_MIN_MAX,
  AGE_STEP,
  DisciplineNameEnum,
} from '@/const';
import { EventsMoreFilterAmenityQuery } from '@/containers/Search/Filters/__generated__/EventsMoreFilterAmenityQuery.graphql';
import {
  useGetSearchFiltersGlobal,
  useSetSearchFiltersGlobal,
} from '@/containers/Search/recoilStore';
import { useSetQueryParams } from '@/lib/path-helpers';
import { abilityLevelValueToLabel, ageValueToLabel } from '@/lib/slider-utils';

const EventsMoreFilter: React.FC = () => {
  const setQueryParams = useSetQueryParams();
  const searchFiltersGlobal = useGetSearchFiltersGlobal();
  const setSearchFiltersGlobal = useSetSearchFiltersGlobal();
  const [ageSliderValues, setAgeSliderValues] = useState<number[]>([
    searchFiltersGlobal.ageMin,
    searchFiltersGlobal.ageMax,
  ]);
  const [abilityLevelSliderValues, setAbilityLevelSliderValues] = useState<number[]>([
    searchFiltersGlobal.abilityMin,
    searchFiltersGlobal.abilityMax,
  ]);

  const disciplineValues = searchFiltersGlobal.disciplines;
  const amenityValues = searchFiltersGlobal.amenities;

  const isFilteringAbility = !R.equals(abilityLevelSliderValues, ABILITY_LEVEL_MIN_MAX);
  const isFilteringAge = !R.equals(ageSliderValues, AGE_MIN_MAX);
  const isFilteringDisciplines = !R.isEmpty(disciplineValues);
  const isFilteringAmenities = !R.isEmpty(amenityValues);

  /**
   * Handle ability slider change
   */
  const handleAbilityLevelSliderChange = (values: number[]): void => {
    setAbilityLevelSliderValues(values);
  };
  const handleAbilityLevelSliderFinalChange = (values: number[]): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      abilityMin: values[0],
      abilityMax: values[1],
    }));
    setQueryParams({
      abilityMin: values[0] !== ABILITY_LEVEL_MIN_MAX[0] ? values[0] : undefined,
      abilityMax: values[1] !== ABILITY_LEVEL_MIN_MAX[1] ? values[1] : undefined,
    });
  };

  /**
   * Handle age slider change
   */
  const handleAgeSliderChange = (values: number[]): void => {
    setAgeSliderValues(values);
  };
  const handleAgeSliderFinalChange = (values: number[]): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      ageMin: values[0],
      ageMax: values[1],
    }));
    setQueryParams({
      ageMin: values[0] !== AGE_MIN_MAX[0] ? values[0] : undefined,
      ageMax: values[1] !== AGE_MIN_MAX[1] ? values[1] : undefined,
    });
  };

  /**
   * Handle Discipline change
   */
  const handleDisciplineChange = (disciplineName: string): void => {
    setSearchFiltersGlobal((prevValue) => {
      let newDisciplines = [...prevValue.disciplines, disciplineName];
      if (prevValue.disciplines.includes(disciplineName)) {
        newDisciplines = R.reject(
          (discipline) => discipline === disciplineName,
          prevValue.disciplines,
        );
      }
      setQueryParams({
        disciplines: newDisciplines,
      });
      return {
        ...prevValue,
        disciplines: newDisciplines,
      };
    });
  };

  /**
   * Handle amenity change
   */
  const handleAmenityChange = (amenityName: string): void => {
    setSearchFiltersGlobal((prevValue) => {
      let newAmenities = [...prevValue.amenities, amenityName];
      if (prevValue.amenities.includes(amenityName)) {
        newAmenities = R.reject((amenity) => amenity === amenityName, prevValue.amenities);
      }
      setQueryParams({
        amenities: newAmenities.length > 0 ? newAmenities : undefined,
      });
      return {
        ...prevValue,
        amenities: newAmenities,
      };
    });
  };

  /**
   * Clear Ability filters
   */
  const handleClearAbilityFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      abilityMin: ABILITY_LEVEL_MIN_MAX[0],
      abilityMax: ABILITY_LEVEL_MIN_MAX[1],
    }));
    setAbilityLevelSliderValues(ABILITY_LEVEL_MIN_MAX);
    setQueryParams({
      abilityMin: undefined,
      abilityMax: undefined,
    });
  };

  /**
   * Clear Age filters
   */
  const handleClearAgeFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      ageMin: AGE_MIN_MAX[0],
      ageMax: AGE_MIN_MAX[1],
    }));
    setAgeSliderValues(AGE_MIN_MAX);
    setQueryParams({
      ageMin: undefined,
      ageMax: undefined,
    });
  };

  /**
   * Clear Discipline filters
   */
  const handleClearDisciplineFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      disciplines: [],
    }));
    setQueryParams({
      disciplines: [],
    });
  };

  /**
   * Clear Amenity filters
   */
  const handleClearAmenityFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      amenities: [],
    }));
    setQueryParams({
      amenities: [],
    });
  };

  return (
    <>
      <Box py={2}>
        <Box alignItems="center" display="flex" height={22}>
          <Typography variant="body2">
            <b>Ability Level</b>
          </Typography>
          {isFilteringAbility && (
            <Box display="flex" ml={1}>
              <LinkButton onClick={handleClearAbilityFilters}>Clear</LinkButton>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box px={5} py={1.5}>
            <Slider
              draggableTrack
              small
              max={ABILITY_LEVEL_MIN_MAX[1]}
              min={ABILITY_LEVEL_MIN_MAX[0]}
              onChange={handleAbilityLevelSliderChange}
              onFinalChange={handleAbilityLevelSliderFinalChange}
              step={ABILITY_LEVEL_STEP}
              valueToLabel={abilityLevelValueToLabel}
              values={abilityLevelSliderValues}
            />
          </Box>
        </Box>
      </Box>
      <Divider />

      <Box py={2}>
        <Box alignItems="center" display="flex" height={22}>
          <Typography variant="body2">
            <b>Age</b>
          </Typography>
          {isFilteringAge && (
            <Box display="flex" ml={1}>
              <LinkButton onClick={handleClearAgeFilters}>Clear</LinkButton>
            </Box>
          )}
        </Box>
        <Box px={5} py={1.5}>
          <Slider
            draggableTrack
            small
            max={AGE_MIN_MAX[1]}
            min={AGE_MIN_MAX[0]}
            onChange={handleAgeSliderChange}
            onFinalChange={handleAgeSliderFinalChange}
            step={AGE_STEP}
            valueToLabel={ageValueToLabel}
            values={ageSliderValues}
          />
        </Box>
      </Box>
      <Divider />

      <Box py={2}>
        <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
          <Box alignItems="center" display="flex" height={22}>
            <Typography variant="body2">
              <b>Disciplines</b>
            </Typography>
            {isFilteringDisciplines && (
              <Box display="flex" ml={1}>
                <LinkButton onClick={handleClearDisciplineFilters}>Clear</LinkButton>
              </Box>
            )}
          </Box>
          <ReadMore maxHeight={300} readMoreText="Show More">
            <FormGroup>
              {Object.values(DisciplineNameEnum).map((discipline) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disciplineValues.includes(discipline)}
                      onChange={(): void => handleDisciplineChange(discipline)}
                      size="small"
                    />
                  }
                  key={discipline}
                  label={<Typography variant="body2">{discipline}</Typography>}
                  style={{ margin: 0 }}
                />
              ))}
            </FormGroup>
          </ReadMore>
        </FormControl>
      </Box>
      <Divider />
      <Box py={2}>
        <FormControl component="fieldset">
          <Box alignItems="center" display="flex" height={22}>
            <Typography variant="body2">
              <b>Amenities</b>
            </Typography>
            {isFilteringAmenities && (
              <Box display="flex" ml={1}>
                <LinkButton onClick={handleClearAmenityFilters}>Clear</LinkButton>
              </Box>
            )}
          </Box>
          <Suspense fallback={<LoadingPlaceholder isLoading verticalMargin="md" />}>
            <ReadMore maxHeight={300} readMoreText="Show More">
              <AmenityFormGroup
                amenityValues={amenityValues}
                handleAmenityChange={handleAmenityChange}
              />
            </ReadMore>
          </Suspense>
        </FormControl>
      </Box>
    </>
  );
};

interface AmenityFormGroupProps {
  amenityValues: string[];
  handleAmenityChange: (amenityName: string) => void;
}
const AmenityFormGroup: React.FC<AmenityFormGroupProps> = ({
  amenityValues,
  handleAmenityChange,
}) => {
  const amenityData = useLazyLoadQuery<EventsMoreFilterAmenityQuery>(
    graphql`
      query EventsMoreFilterAmenityQuery {
        amenity_connection {
          edges {
            node {
              id
              cuid
              name
              info
              imageUrl
            }
          }
        }
      }
    `,
    {},
  ).amenity_connection.edges.map((edge) => edge.node);
  return (
    <FormGroup>
      {amenityData.map((amenity) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={amenityValues.includes(amenity.name)}
              onChange={(): void => handleAmenityChange(amenity.name)}
              size="small"
            />
          }
          key={amenity.cuid}
          label={<Typography variant="body2">{amenity.name}</Typography>}
          style={{ margin: 0 }}
        />
      ))}
    </FormGroup>
  );
};

export default EventsMoreFilter;
