type Currency = {
  currency: string;
  code: string;
  country: string;
  symbol: string;
};
export const CURRENCY: Currency[] = [
  {
    currency: 'Australian Dollar',
    country: 'AUS',
    code: 'AUD',
    symbol: 'A$',
  },
  {
    currency: 'British Pound',
    country: 'GBR',
    code: 'GBP',
    symbol: '£',
  },
  {
    currency: 'Canadian Dollar',
    country: 'CAN',
    code: 'CAD',
    symbol: 'CA$',
  },
  {
    currency: 'Euro',
    country: '',
    code: 'EUR',
    symbol: '€',
  },
  {
    currency: 'New Zealand Dollar',
    country: 'NZL',
    code: 'NZD',
    symbol: 'NZ$',
  },
  {
    currency: 'Swiss Franc',
    country: 'CHE',
    code: 'CHF',
    symbol: 'CHF',
  },
  {
    currency: 'United States Dollar',
    country: 'USA',
    code: 'USD',
    symbol: '$',
  },
  // {
  //   currency: 'SelectDivider',
  //   code: 'SelectDivider',
  //   symbol: 'SelectDivider',
  // },
  // {
  //   currency: 'Afghan Afghani',
  //   code: 'AFN',
  //   symbol: 'AFN',
  // },
  // {
  //   currency: 'Albanian Lek',
  //   code: 'ALL',
  //   symbol: 'ALL',
  // },
  // {
  //   currency: 'Algerian Dinar',
  //   code: 'DZD',
  //   symbol: 'DZD',
  // },
  // {
  //   currency: 'Angolan Kwanza',
  //   code: 'AOA',
  //   symbol: 'AOA',
  // },
  // {
  //   currency: 'Argentine Peso',
  //   code: 'ARS',
  //   symbol: 'ARS',
  // },
  // {
  //   currency: 'Armenian Dram',
  //   code: 'AMD',
  //   symbol: 'AMD',
  // },
  // {
  //   currency: 'Aruban Florin',
  //   code: 'AWG',
  //   symbol: 'AWG',
  // },
  // {
  //   currency: 'Azerbaijani Manat',
  //   code: 'AZN',
  //   symbol: 'AZN',
  // },
  // {
  //   currency: 'Bahamian Dollar',
  //   code: 'BSD',
  //   symbol: 'BSD',
  // },
  // {
  //   currency: 'Bangladeshi Taka',
  //   code: 'BDT',
  //   symbol: 'BDT',
  // },
  // {
  //   currency: 'Barbadian Dollar',
  //   code: 'BBD',
  //   symbol: 'BBD',
  // },
  // {
  //   currency: 'Belize Dollar',
  //   code: 'BZD',
  //   symbol: 'BZD',
  // },
  // {
  //   currency: 'Bermudian Dollar',
  //   code: 'BMD',
  //   symbol: 'BMD',
  // },
  // {
  //   currency: 'Bolivian Boliviano',
  //   code: 'BOB',
  //   symbol: 'BOB',
  // },
  // {
  //   currency: 'Bosnia & Herzegovina Convertible Mark',
  //   code: 'BAM',
  //   symbol: 'BAM',
  // },
  // {
  //   currency: 'Botswana Pula',
  //   code: 'BWP',
  //   symbol: 'BWP',
  // },
  // {
  //   currency: 'Brazilian Real',
  //   code: 'BRL',
  //   symbol: 'R$',
  // },
  // {
  //   currency: 'Brunei Dollar',
  //   code: 'BND',
  //   symbol: 'BND',
  // },
  // {
  //   currency: 'Bulgarian Lev',
  //   code: 'BGN',
  //   symbol: 'BGN',
  // },
  // {
  //   currency: 'Burundian Franc',
  //   code: 'BIF',
  //   symbol: 'BIF',
  // },
  // {
  //   currency: 'Cambodian Riel',
  //   code: 'KHR',
  //   symbol: 'KHR',
  // },
  // {
  //   currency: 'Cape Verdean Escudo',
  //   code: 'CVE',
  //   symbol: 'CVE',
  // },
  // {
  //   currency: 'Cayman Islands Dollar',
  //   code: 'KYD',
  //   symbol: 'KYD',
  // },
  // {
  //   currency: 'Central African Cfa Franc',
  //   code: 'XAF',
  //   symbol: 'FCFA',
  // },
  // {
  //   currency: 'Cfp Franc',
  //   code: 'XPF',
  //   symbol: 'CFPF',
  // },
  // {
  //   currency: 'Chilean Peso',
  //   code: 'CLP',
  //   symbol: 'CLP',
  // },
  // {
  //   currency: 'Chinese Renminbi Yuan',
  //   code: 'CNY',
  //   symbol: 'CN¥',
  // },
  // {
  //   currency: 'Colombian Peso',
  //   code: 'COP',
  //   symbol: 'COP',
  // },
  // {
  //   currency: 'Comorian Franc',
  //   code: 'KMF',
  //   symbol: 'KMF',
  // },
  // {
  //   currency: 'Congolese Franc',
  //   code: 'CDF',
  //   symbol: 'CDF',
  // },
  // {
  //   currency: 'Costa Rican Colón',
  //   code: 'CRC',
  //   symbol: 'CRC',
  // },
  // {
  //   currency: 'Croatian Kuna',
  //   code: 'HRK',
  //   symbol: 'HRK',
  // },
  // {
  //   currency: 'Czech Koruna',
  //   code: 'CZK',
  //   symbol: 'CZK',
  // },
  // {
  //   currency: 'Danish Krone',
  //   code: 'DKK',
  //   symbol: 'DKK',
  // },
  // {
  //   currency: 'Djiboutian Franc',
  //   code: 'DJF',
  //   symbol: 'DJF',
  // },
  // {
  //   currency: 'Dominican Peso',
  //   code: 'DOP',
  //   symbol: 'DOP',
  // },
  // {
  //   currency: 'East Caribbean Dollar',
  //   code: 'XCD',
  //   symbol: 'EC$',
  // },
  // {
  //   currency: 'Egyptian Pound',
  //   code: 'EGP',
  //   symbol: 'EGP',
  // },
  // {
  //   currency: 'Ethiopian Birr',
  //   code: 'ETB',
  //   symbol: 'ETB',
  // },
  // {
  //   currency: 'Falkland Islands Pound',
  //   code: 'FKP',
  //   symbol: 'FKP',
  // },
  // {
  //   currency: 'Fijian Dollar',
  //   code: 'FJD',
  //   symbol: 'FJD',
  // },
  // {
  //   currency: 'Gambian Dalasi',
  //   code: 'GMD',
  //   symbol: 'GMD',
  // },
  // {
  //   currency: 'Georgian Lari',
  //   code: 'GEL',
  //   symbol: 'GEL',
  // },
  // {
  //   currency: 'Gibraltar Pound',
  //   code: 'GIP',
  //   symbol: 'GIP',
  // },
  // {
  //   currency: 'Guatemalan Quetzal',
  //   code: 'GTQ',
  //   symbol: 'GTQ',
  // },
  // {
  //   currency: 'Guinean Franc',
  //   code: 'GNF',
  //   symbol: 'GNF',
  // },
  // {
  //   currency: 'Guyanese Dollar',
  //   code: 'GYD',
  //   symbol: 'GYD',
  // },
  // {
  //   currency: 'Haitian Gourde',
  //   code: 'HTG',
  //   symbol: 'HTG',
  // },
  // {
  //   currency: 'Honduran Lempira',
  //   code: 'HNL',
  //   symbol: 'HNL',
  // },
  // {
  //   currency: 'Hong Kong Dollar',
  //   code: 'HKD',
  //   symbol: 'HK$',
  // },
  // {
  //   currency: 'Hungarian Forint',
  //   code: 'HUF',
  //   symbol: 'HUF',
  // },
  // {
  //   currency: 'Icelandic Króna',
  //   code: 'ISK',
  //   symbol: 'ISK',
  // },
  // {
  //   currency: 'Indian Rupee',
  //   code: 'INR',
  //   symbol: '₹',
  // },
  // {
  //   currency: 'Indonesian Rupiah',
  //   code: 'IDR',
  //   symbol: 'IDR',
  // },
  // {
  //   currency: 'Israeli New Sheqel',
  //   code: 'ILS',
  //   symbol: '₪',
  // },
  // {
  //   currency: 'Jamaican Dollar',
  //   code: 'JMD',
  //   symbol: 'JMD',
  // },
  // {
  //   currency: 'Japanese Yen',
  //   code: 'JPY',
  //   symbol: '¥',
  // },
  // {
  //   currency: 'Kazakhstani Tenge',
  //   code: 'KZT',
  //   symbol: 'KZT',
  // },
  // {
  //   currency: 'Kenyan Shilling',
  //   code: 'KES',
  //   symbol: 'KES',
  // },
  // {
  //   currency: 'Kyrgyzstani Som',
  //   code: 'KGS',
  //   symbol: 'KGS',
  // },
  // {
  //   currency: 'Lao Kip',
  //   code: 'LAK',
  //   symbol: 'LAK',
  // },
  // {
  //   currency: 'Lebanese Pound',
  //   code: 'LBP',
  //   symbol: 'LBP',
  // },
  // {
  //   currency: 'Lesotho Loti',
  //   code: 'LSL',
  //   symbol: 'LSL',
  // },
  // {
  //   currency: 'Liberian Dollar',
  //   code: 'LRD',
  //   symbol: 'LRD',
  // },
  // {
  //   currency: 'Macanese Pataca',
  //   code: 'MOP',
  //   symbol: 'MOP',
  // },
  // {
  //   currency: 'Macedonian Denar',
  //   code: 'MKD',
  //   symbol: 'MKD',
  // },
  // {
  //   currency: 'Malagasy Ariary',
  //   code: 'MGA',
  //   symbol: 'MGA',
  // },
  // {
  //   currency: 'Malawian Kwacha',
  //   code: 'MWK',
  //   symbol: 'MWK',
  // },
  // {
  //   currency: 'Malaysian Ringgit',
  //   code: 'MYR',
  //   symbol: 'MYR',
  // },
  // {
  //   currency: 'Maldivian Rufiyaa',
  //   code: 'MVR',
  //   symbol: 'MVR',
  // },
  // {
  //   currency: 'Mauritanian Ouguiya',
  //   code: 'MRO',
  //   symbol: 'MRO',
  // },
  // {
  //   currency: 'Mauritian Rupee',
  //   code: 'MUR',
  //   symbol: 'MUR',
  // },
  // {
  //   currency: 'Mexican Peso',
  //   code: 'MXN',
  //   symbol: 'MX$',
  // },
  // {
  //   currency: 'Moldovan Leu',
  //   code: 'MDL',
  //   symbol: 'MDL',
  // },
  // {
  //   currency: 'Mongolian Tögrög',
  //   code: 'MNT',
  //   symbol: 'MNT',
  // },
  // {
  //   currency: 'Moroccan Dirham',
  //   code: 'MAD',
  //   symbol: 'MAD',
  // },
  // {
  //   currency: 'Mozambican Metical',
  //   code: 'MZN',
  //   symbol: 'MZN',
  // },
  // {
  //   currency: 'Myanmar Kyat',
  //   code: 'MMK',
  //   symbol: 'MMK',
  // },
  // {
  //   currency: 'Namibian Dollar',
  //   code: 'NAD',
  //   symbol: 'NAD',
  // },
  // {
  //   currency: 'Nepalese Rupee',
  //   code: 'NPR',
  //   symbol: 'NPR',
  // },
  // {
  //   currency: 'Netherlands Antillean Gulden',
  //   code: 'ANG',
  //   symbol: 'ANG',
  // },
  // {
  //   currency: 'New Taiwan Dollar',
  //   code: 'TWD',
  //   symbol: 'NT$',
  // },
  // {
  //   currency: 'Nicaraguan Córdoba',
  //   code: 'NIO',
  //   symbol: 'NIO',
  // },
  // {
  //   currency: 'Nigerian Naira',
  //   code: 'NGN',
  //   symbol: 'NGN',
  // },
  // {
  //   currency: 'Norwegian Krone',
  //   code: 'NOK',
  //   symbol: 'NOK',
  // },
  // {
  //   currency: 'Pakistani Rupee',
  //   code: 'PKR',
  //   symbol: 'PKR',
  // },
  // {
  //   currency: 'Panamanian Balboa',
  //   code: 'PAB',
  //   symbol: 'PAB',
  // },
  // {
  //   currency: 'Papua New Guinean Kina',
  //   code: 'PGK',
  //   symbol: 'PGK',
  // },
  // {
  //   currency: 'Paraguayan Guaraní',
  //   code: 'PYG',
  //   symbol: 'PYG',
  // },
  // {
  //   currency: 'Peruvian Nuevo Sol',
  //   code: 'PEN',
  //   symbol: 'PEN',
  // },
  // {
  //   currency: 'Philippine Peso',
  //   code: 'PHP',
  //   symbol: 'PHP',
  // },
  // {
  //   currency: 'Polish Złoty',
  //   code: 'PLN',
  //   symbol: 'PLN',
  // },
  // {
  //   currency: 'Qatari Riyal',
  //   code: 'QAR',
  //   symbol: 'QAR',
  // },
  // {
  //   currency: 'Romanian Leu',
  //   code: 'RON',
  //   symbol: 'RON',
  // },
  // {
  //   currency: 'Russian Ruble',
  //   code: 'RUB',
  //   symbol: 'RUB',
  // },
  // {
  //   currency: 'Rwandan Franc',
  //   code: 'RWF',
  //   symbol: 'RWF',
  // },
  // {
  //   currency: 'São Tomé and Príncipe Dobra',
  //   code: 'STD',
  //   symbol: 'STD',
  // },
  // {
  //   currency: 'Saint Helenian Pound',
  //   code: 'SHP',
  //   symbol: 'SHP',
  // },
  // {
  //   currency: 'Salvadoran Colón',
  //   code: 'SVC',
  //   symbol: 'SVC',
  // },
  // {
  //   currency: 'Samoan Tala',
  //   code: 'WST',
  //   symbol: 'WST',
  // },
  // {
  //   currency: 'Saudi Riyal',
  //   code: 'SAR',
  //   symbol: 'SAR',
  // },
  // {
  //   currency: 'Serbian Dinar',
  //   code: 'RSD',
  //   symbol: 'RSD',
  // },
  // {
  //   currency: 'Seychellois Rupee',
  //   code: 'SCR',
  //   symbol: 'SCR',
  // },
  // {
  //   currency: 'Sierra Leonean Leone',
  //   code: 'SLL',
  //   symbol: 'SLL',
  // },
  // {
  //   currency: 'Singapore Dollar',
  //   code: 'SGD',
  //   symbol: 'SGD',
  // },
  // {
  //   currency: 'Solomon Islands Dollar',
  //   code: 'SBD',
  //   symbol: 'SBD',
  // },
  // {
  //   currency: 'Somali Shilling',
  //   code: 'SOS',
  //   symbol: 'SOS',
  // },
  // {
  //   currency: 'South African Rand',
  //   code: 'ZAR',
  //   symbol: 'ZAR',
  // },
  // {
  //   currency: 'South Korean Won',
  //   code: 'KRW',
  //   symbol: '₩',
  // },
  // {
  //   currency: 'Sri Lankan Rupee',
  //   code: 'LKR',
  //   symbol: 'LKR',
  // },
  // {
  //   currency: 'Surinamese Dollar',
  //   code: 'SRD',
  //   symbol: 'SRD',
  // },
  // {
  //   currency: 'Swazi Lilangeni',
  //   code: 'SZL',
  //   symbol: 'SZL',
  // },
  // {
  //   currency: 'Swedish Krona',
  //   code: 'SEK',
  //   symbol: 'SEK',
  // },
  // {
  //   currency: 'Tajikistani Somoni',
  //   code: 'TJS',
  //   symbol: 'TJS',
  // },
  // {
  //   currency: 'Tanzanian Shilling',
  //   code: 'TZS',
  //   symbol: 'TZS',
  // },
  // {
  //   currency: 'Thai Baht',
  //   code: 'THB',
  //   symbol: 'THB',
  // },
  // {
  //   currency: 'Tongan Paʻanga',
  //   code: 'TOP',
  //   symbol: 'TOP',
  // },
  // {
  //   currency: 'Trinidad and Tobago Dollar',
  //   code: 'TTD',
  //   symbol: 'TTD',
  // },
  // {
  //   currency: 'Turkish Lira',
  //   code: 'TRY',
  //   symbol: 'TRY',
  // },
  // {
  //   currency: 'Ugandan Shilling',
  //   code: 'UGX',
  //   symbol: 'UGX',
  // },
  // {
  //   currency: 'Ukrainian Hryvnia',
  //   code: 'UAH',
  //   symbol: 'UAH',
  // },
  // {
  //   currency: 'United Arab Emirates Dirham',
  //   code: 'AED',
  //   symbol: 'AED',
  // },
  // {
  //   currency: 'Uruguayan Peso',
  //   code: 'UYU',
  //   symbol: 'UYU',
  // },
  // {
  //   currency: 'Uzbekistani Som',
  //   code: 'UZS',
  //   symbol: 'UZS',
  // },
  // {
  //   currency: 'Vanuatu Vatu',
  //   code: 'VUV',
  //   symbol: 'VUV',
  // },
  // {
  //   currency: 'Vietnamese Đồng',
  //   code: 'VND',
  //   symbol: '₫',
  // },
  // {
  //   currency: 'West African Cfa Franc',
  //   code: 'XOF',
  //   symbol: 'CFA',
  // },
  // {
  //   currency: 'Yemeni Rial',
  //   code: 'YER',
  //   symbol: 'YER',
  // },
  // {
  //   currency: 'Zambian Kwacha',
  //   code: 'ZMW',
  //   symbol: 'ZMW',
  // },
];
