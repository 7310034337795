import { graphql } from 'relay-runtime';

export const LOAD_USER_PREFERENCES_QUERY = graphql`
  query PersonalizationServiceQueries_loadUserPreferences_Query($userId: String!) {
    userPersonalization_connection(
      where: { userId: { _eq: $userId } }
      order_by: { updated_at: desc }
      first: 1
    ) {
      edges {
        node {
          id
          preferences
        }
      }
    }
  }
`;

export const UPDATE_PREFS_TO_LATEST_VERSION_MUTATION = graphql`
  mutation PersonalizationServiceQueries_updatePersonalizationPrefs_Mutation(
    $preferences: jsonb
    $id: Int!
  ) {
    update_userPersonalization_by_pk(_set: { preferences: $preferences }, pk_columns: { id: $id }) {
      preferences
    }
  }
`;

export const INSERT_NEW_PREFS_MUTATION = graphql`
  mutation PersonalizationServiceQueries_insertNewPrefs_Mutation(
    $userId: String!
    $preferences: jsonb
  ) {
    insert_userPersonalization_one(object: { userId: $userId, preferences: $preferences }) {
      id
      preferences
    }
  }
`;
