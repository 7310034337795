/*
Relay Utilities
 */
import * as R from 'ramda';
import { RecordProxy } from 'relay-runtime';
import { Primitive } from 'relay-runtime/lib/store/RelayStoreTypes';

import { useGetEventCuid } from '@/containers/HostEvent/helpers';
import { getRandomInteger } from '@/lib/math-utils';

export interface MutationId {
  nodeId: string;
  cuid: string;
}

export type StringId = 'StringId';
export const STRING_ID: StringId = 'StringId';

/**
 * Remove an id before we add to the DB
 */
export const removeID = <T>(objectToRemoveFrom: T): Omit<T, 'id'> => {
  return R.omit(['id'], objectToRemoveFrom);
};
/**
 * Parse encoded relay id from database.
 * All relay ids need to be globally unique so we encode with schema - table - id
 * [1, "public", "event", 63]
 */
export function decodeRelayId(id: string): number;
export function decodeRelayId(id: string, stringId: StringId): string;
export function decodeRelayId(id: string, stringId?: StringId): number | string {
  const decodedId = R.last(JSON.parse(atob(id)));
  return stringId ? decodedId : parseInt(decodedId, 10);
}

/**
 * Encode a relay id
 * All relay ids need to be globally unique so we encode with schema - table - id
 * [1, "public", "event", 63]
 */
export const encodeRelayId = (tableName: string, id: string | number): string => {
  return window.btoa(`[1, "public", "${tableName}", ${id}]`);
};

/**
 * Create a temp id for inserting into the DB
 */
export const createTempRelayID = (tableName: string): string => {
  return encodeRelayId(tableName, getRandomInteger());
};

/**
 * Create a temp client prefix. We use this when we are adding items to the cache and to identify
 * those items
 */
export const createTempClientPrefix = (tableName: string): string => {
  return `client:temp:${tableName}`;
};

/**
 * Returns true if the Relay Node ID is generated by createTempClientPrefix()
 */
export const isTempClientId = (id: string): boolean => {
  return id.startsWith('client:temp');
};

/**
 * Create a temp relay id for use in a component
 */
export const useCreateTempClientID = (): ((tableName: string, cuid: string) => string) => {
  const eventCuid = useGetEventCuid();
  return (tableName: string, cuid: string): string =>
    `${createTempClientPrefix(tableName)}:${cuid}:${eventCuid}`;
};

/**
 * Map edges object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapEdgesToArray = (edges: readonly { readonly node: any }[]): any[] => {
  return edges.map((edge) => edge.node);
};

/**
 * Take a list of RecordProxys and add a new one/update existing based on a uniqueField shared by both
 */
export const updateOrAddLinkedRecords = <T>(
  existingRecords: RecordProxy<T>[] | null | undefined,
  newRecord: RecordProxy<T>,
  uniqueField: string,
): RecordProxy<T>[] => {
  let didUpdate = false;
  const updatedRecords = (existingRecords ?? []).map((existingRecord) => {
    if (existingRecord.getValue(uniqueField) === newRecord.getValue(uniqueField)) {
      didUpdate = true;
      return newRecord;
    }
    return existingRecord;
  });
  if (didUpdate) {
    return updatedRecords;
  }
  // No records updated. Add new
  return [...(existingRecords ?? []), newRecord];
};

/**
 * Take a list of RecordProxys and update an existing record based on a function
 */
export const updateFieldsLinkedRecordsByCuid = <T>(
  existingRecords: RecordProxy<T>[] | null | undefined,
  fieldsToUpdate: { [key: string]: Primitive },
  cuidToUpdate: string,
): RecordProxy<T>[] => {
  return (existingRecords ?? []).map((existingRecord) => {
    if (existingRecord.getValue('cuid') === cuidToUpdate) {
      Object.keys(fieldsToUpdate).forEach((field) => {
        existingRecord.setValue(fieldsToUpdate[field], field);
      });
      return existingRecord;
    }
    return existingRecord;
  });
};

/**
 * Take a list of RecordProxys and remove an item
 */
export const filterLinkedRecords = <T>(
  existingRecords: RecordProxy<T>[] | null | undefined,
  valueToFilter: string,
  uniqueField: string,
): RecordProxy<T>[] => {
  return (existingRecords ?? []).filter((existingRecord) => {
    return existingRecord.getValue(uniqueField) !== valueToFilter;
  });
};
