/*
Helper math functions. We all need math help. Well. I do. Badly.
 */

import * as R from 'ramda';

/**
 * Assure a variable is a integer or null
 */
export const toNumberOrNullIfEmpty = (value: string | number | null | undefined): number | null => {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return value;
};

/**
 * Sort a list of numbers from smallest to largest and then find and return the first gap in the list
 * if there is no gap, return null. Vanilla JS.
 * @param numbers a list of numbers
 * @returns {number}
 */
export const findFirstMissingNumber = (numbers: number[]): number | null => {
  const sortedNumbers = R.sort((a, b) => a - b, numbers);
  for (let i = 0; i < sortedNumbers.length; i++) {
    // if (!sortedNumbers[i + 1]) {
    //   return null;
    // }
    if (sortedNumbers[i + 1] && sortedNumbers[i + 1] !== sortedNumbers[i] + 1) {
      return sortedNumbers[i] + 1;
    }
  }
  return null;
};

// Rounding
const roundTo = (numberToRound: number, decimalPlaces = 0): number => {
  let negative = false;
  let roundedNumber: number | string = numberToRound;
  if (numberToRound < 0) {
    negative = true;
    roundedNumber *= -1;
  }
  const multiplicator = Math.pow(10, decimalPlaces);
  roundedNumber = parseFloat((roundedNumber * multiplicator).toFixed(11));
  roundedNumber = (Math.round(roundedNumber) / multiplicator).toFixed(decimalPlaces);
  if (negative) {
    roundedNumber = (Number(roundedNumber) * -1).toFixed(decimalPlaces);
  }
  return Number(roundedNumber);
};
export const roundToNearestTenth = (numberToRound: number): number => roundTo(numberToRound, 1);
export const roundToNearestHundredth = (numberToRound: number): number => roundTo(numberToRound, 2);
export const roundToNearestThousandth = (numberToRound: number): number =>
  roundTo(numberToRound, 3);

// Random
export const getRandomInteger = (): number => Math.floor(Math.random() * 10);

/**
 * Returns a random base 36 number from 0 to 36 ^ `maxDigits`, left-padded with 0 to
 * a string length of `maxDigits`.
 * @param maxDigits specifies the max place value of the base 36 number
 */
const BASE_36 = 36;
export const getRandomBase36Number = (maxDigits = 5): string => {
  const max = BASE_36 ** maxDigits;
  const number = Math.round(Math.random() * max);
  let numberAsString = Number(number).toString(BASE_36);
  const leftPad = maxDigits - numberAsString.length;
  for (let i = 0; i < leftPad; i++) {
    numberAsString = `0${numberAsString}`;
  }
  return numberAsString.toUpperCase();
};

/**
 * Get the average of all of the numbers in an array that are not empty
 */
export const getAverageOfNonEmptyNumbers = (numbers: number[]): number => {
  const nonEmptyNumbers = numbers.filter((n) => Boolean(n));
  const sum = nonEmptyNumbers.reduce((a, b) => a + b, 0);
  return sum / nonEmptyNumbers.length;
};

/**
 * Apply a promo code to a cost
 */
export const applyPromoCodeToCost = (
  promoCode: { amountOff: number | null | undefined; percentOff: number | null | undefined },
  cost: number | null | undefined,
): number | null | undefined => {
  if (!cost || !promoCode || (!promoCode.amountOff && !promoCode.percentOff)) {
    return cost;
  }
  if (promoCode.percentOff) {
    return Math.max(0, (100 - promoCode.percentOff) * 0.01 * cost);
  }
  if (promoCode.amountOff) {
    return Math.max(0, cost - promoCode.amountOff);
  }
  return cost;
};
