import { useEffect, useState } from 'react';
import { useConnector } from 'react-instantsearch-hooks';
import { useLocation } from 'react-router-dom';
import type { BaseHit } from 'instantsearch.js';
import type {
  GeoSearchConnector,
  GeoSearchConnectorParams,
  GeoSearchWidgetDescription,
} from 'instantsearch.js/es/connectors/geo-search/connectGeoSearch';
// eslint-disable-next-line import/no-extraneous-dependencies
import connectGeoSearch, {
  GeoSearchRenderState,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'instantsearch.js/es/connectors/geo-search/connectGeoSearch';

import { REGGY_DISCOVER_REPLICA_NAME_BASE, REGGY_SEARCH_INDEX_NAME_BASE } from './algolia';

import env from '@/env';
import { AppRouteService } from '@/routes/RouteService';

/**
 * Prefix comes from the environment we are in
 * Base comes from the page that we are on
 */
export const useGetSearchIndexName = (): string => {
  const localSearchIndexPrefix = 'local-{LOCALID}_'.replace(
    '{LOCALID}',
    env.LOCAL_ALGOLIA_INDEX_ID || '',
  );
  // TODO default should be `${process.env.ENV}_`
  const indexPrefix =
    env.USE_LOCAL_DB_AND_FIREBASE_EMULATORS && env.LOCAL_ALGOLIA_INDEX_ID
      ? localSearchIndexPrefix
      : '';
  const searchIndexName = `${indexPrefix}${REGGY_SEARCH_INDEX_NAME_BASE}`;
  const discoverIndexName = `${indexPrefix}${REGGY_DISCOVER_REPLICA_NAME_BASE}`;

  const { pathname } = useLocation();
  const [indexName, setIndexName] = useState(searchIndexName);

  useEffect(() => {
    if (AppRouteService.matchesRoute('Discover')) {
      setIndexName(discoverIndexName);
    } else {
      setIndexName(searchIndexName);
    }
  }, [pathname]);

  return indexName;
};

type UseGeoSearchProps<THit extends BaseHit> = GeoSearchConnectorParams<THit>;
export function useGeoSearch<THit extends BaseHit>(
  props?: UseGeoSearchProps<THit>,
): GeoSearchWidgetDescription<THit>['renderState'] {
  return useConnector<GeoSearchConnectorParams<THit>, GeoSearchWidgetDescription<THit>>(
    connectGeoSearch as GeoSearchConnector<THit>,
    props,
  );
}

/**
 * solves the 180th meridian problem
 * [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
 * [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
 */
export const fix180thMeridianForAlgolia = (
  bounds:
    | [[number, number], [number, number]]
    | NonNullable<GeoSearchRenderState['currentRefinement']>,
):
  | [[number, number, number, number]]
  | [[number, number, number, number], [number, number, number, number]] => {
  let swLat;
  let swLng;
  let neLat;
  let neLng;
  if (Array.isArray(bounds)) {
    const [sw, ne] = bounds;
    [swLat, swLng] = sw;
    [neLat, neLng] = ne;
  } else {
    const { northEast, southWest } = bounds;
    swLat = southWest.lat;
    swLng = southWest.lng;
    neLat = northEast.lat;
    neLng = northEast.lng;
  }
  // sw always be negative, check for less than 180
  // If it is we need to return two arrays, one for each side of the 180th meridian
  if (swLng > -180 && neLng < 180) {
    return [[swLat, swLng, neLat, neLng]];
  }
  if (swLng < -180) {
    const eastSideOfMeridian = [swLat, -180, neLat, neLng] as [number, number, number, number];
    const westSideOfMeridian = [swLat, swLng + 360, neLat, 180] as [number, number, number, number];
    return [eastSideOfMeridian, westSideOfMeridian];
  }
  if (neLng > 180) {
    const eastSideOfMeridian = [swLat, swLng, neLat, 180] as [number, number, number, number];
    const westSideOfMeridian = [swLat, -180, neLat, neLng - 360] as [
      number,
      number,
      number,
      number,
    ];
    return [eastSideOfMeridian, westSideOfMeridian];
  }
  return [[swLat, swLng, neLat, neLng]];
};
