import React from 'react';
import { Box } from '@material-ui/core';

import { useMainLayoutStyles } from '@/layouts/MainLayout';
import { ReactFCC } from '@/lib/type-defs/utility';

const BlankLayout: ReactFCC = ({ children }) => {
  const mainLayoutClasses = useMainLayoutStyles();
  return (
    <Box className={mainLayoutClasses.root}>
      <div className={mainLayoutClasses.content}>{children}</div>
    </Box>
  );
};

export default BlankLayout;
