import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { AGE_MIN_MAX } from '@/const';
import { correctlyTypeStringArray } from '@/lib/slider-utils';
import { arrayToCommaAndString } from '@/lib/string-utils';

/**
 * Summary of coach
 */
export const getReadableCoachVibe = (coachVibe: string[] | unknown): string => {
  const coachingVibe = correctlyTypeStringArray(coachVibe);
  return RA.isNotNilOrEmpty(coachingVibe)
    ? `${coachingVibe[0]} & ${coachingVibe[1].toLowerCase()}`
    : '';
};

/**
 * Summary of coach
 */
export const getCoachingSummary = (coachData: {
  coachingVibe: string[] | undefined;
  coachDisciplines: { name: string }[] | readonly { readonly name: string }[] | undefined;
}): string | null => {
  // Get coaching vibe
  const readableCoachingVibe = getReadableCoachVibe(coachData?.coachingVibe);

  // Get disciplines
  const hasDisciplines = RA.isNotNilOrEmpty(coachData?.coachDisciplines);
  if (readableCoachingVibe && !hasDisciplines) {
    return `${readableCoachingVibe} coach`;
  }
  if (!readableCoachingVibe && hasDisciplines) {
    return `Coach who teaches ${arrayToCommaAndString(
      (coachData?.coachDisciplines ?? []).map(R.prop('name')),
    )}`;
  }
  if (readableCoachingVibe && hasDisciplines) {
    return `${readableCoachingVibe} coach who teaches ${arrayToCommaAndString(
      (coachData?.coachDisciplines ?? []).map(R.prop('name')),
    )}`;
  }
  return '';
};

/**
 * Convert a coach location to readable text
 */
export const getCoachLocationText = (
  coachLocation:
    | {
        city: string | undefined | null;
        state: string | undefined | null;
        country: string;
        countryCode: string | undefined | null;
      }
    | undefined,
): string => {
  if (!coachLocation) {
    return '';
  }
  return `${coachLocation?.city ? `${coachLocation.city}, ` : ''}${
    coachLocation?.state ? `${coachLocation.state}, ` : ''
  }${coachLocation.countryCode || coachLocation.country}`;
};

/**
 * Get age in readable text
 */
export const getAgeText = (ageRange: number[]): string => {
  const [minAge, maxAge] = ageRange;
  if (minAge === AGE_MIN_MAX[0] && maxAge === AGE_MIN_MAX[1]) {
    return 'All ages';
  }
  if (minAge === AGE_MIN_MAX[0] && maxAge !== AGE_MIN_MAX[1]) {
    return `${maxAge} years and younger`;
  }
  if (minAge !== AGE_MIN_MAX[0] && maxAge === AGE_MIN_MAX[1]) {
    return `${minAge} years and older`;
  }
  return `${minAge}-${maxAge} years old`;
};
