/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getEncodedEventId_event = {
    readonly id: string;
    readonly " $refType": "helpers_getEncodedEventId_event";
};
export type helpers_getEncodedEventId_event$data = helpers_getEncodedEventId_event;
export type helpers_getEncodedEventId_event$key = {
    readonly " $data"?: helpers_getEncodedEventId_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getEncodedEventId_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getEncodedEventId_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = 'd1d26ae886f2cec3425b1e2a8cd48276';
export default node;
