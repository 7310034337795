/*
Tracking for page views
 */
import React, { useCallback, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import cuid from 'cuid';
import * as R from 'ramda';
import { createOperationDescriptor, fetchQuery, getRequest, graphql } from 'relay-runtime';

import { RouteAnalytics_GetCoachCuidQuery } from '@/routes/components/__generated__/RouteAnalytics_GetCoachCuidQuery.graphql';
import { RouteAnalytics_GetEventMetadataCuidQuery } from '@/routes/components/__generated__/RouteAnalytics_GetEventMetadataCuidQuery.graphql';
import { RouteAnalytics_GetOrganizerCuidQuery } from '@/routes/components/__generated__/RouteAnalytics_GetOrganizerCuidQuery.graphql';

import { useSegmentAnalyticsContext } from '@/analytics/segment/SegmentAnalyticsProvider';
import { useGetIsAdBlockerRunningGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { sendSegmentEventCF } from '@/lib/firebase';
import { getParsedQueryParams } from '@/lib/path-helpers';

const GetOrganizerCuidQuery = graphql`
  query RouteAnalytics_GetOrganizerCuidQuery($organizerSlug: String!) {
    organizer_connection(where: { slug: { _eq: $organizerSlug } }) {
      edges {
        node {
          cuid
        }
      }
    }
  }
`;
const GetCoachCuidQuery = graphql`
  query RouteAnalytics_GetCoachCuidQuery($coachSlug: String!) {
    coach_connection(where: { user: { slug: { _eq: $coachSlug } } }) {
      edges {
        node {
          cuid
        }
      }
    }
  }
`;
const GetEventMetadataCuidQuery = graphql`
  query RouteAnalytics_GetEventMetadataCuidQuery($eventSlug: String!) {
    eventMetadata_connection(where: { slug: { _eq: $eventSlug } }) {
      edges {
        node {
          cuid
        }
      }
    }
  }
`;

const RouteAnalytics: React.FC = () => {
  const location = useLocation();
  // console.log('RouteAnalytics location', location);
  const routeParams = useParams();
  const environment = useRelayEnvironment();
  const segmentAnalytics = useSegmentAnalyticsContext();
  const isAdBlockRunning = useGetIsAdBlockerRunningGlobal();
  const [reggyAnonymousId] = useLocalStorage('reggy-anonymousId', cuid());

  /**
   * Get organizerCuid from DB based on the organizerSlug
   */
  const fetchOrganizerCuid = useCallback((refreshVariables: { organizerSlug: string }) => {
    return new Promise<string | undefined>((resolve, reject) => {
      // Cache this query.
      const queryRequest = getRequest(GetOrganizerCuidQuery);
      const queryDescriptor = createOperationDescriptor(queryRequest, refreshVariables);
      environment.retain(queryDescriptor);
      // See if we can get the data from the cache otherwise fetch it from the server.
      const response = environment.lookup(queryDescriptor.fragment);
      const organizerCuid = response?.data?.organizer_connection?.edges?.[0]?.node?.cuid;
      if (organizerCuid) {
        resolve(organizerCuid);
        return;
      }
      // Fetch the data from the server.
      fetchQuery<RouteAnalytics_GetOrganizerCuidQuery>(
        environment,
        GetOrganizerCuidQuery,
        refreshVariables,
      ).subscribe({
        next: (data) => {
          resolve(data.organizer_connection?.edges?.[0]?.node?.cuid);
        },
        error: (err: Error) => {
          reject(err);
        },
      });
    });
  }, []);
  /**
   * Get coachCuid from DB based on the coachSlug
   */
  const fetchCoachCuid = useCallback((refreshVariables: { coachSlug: string }) => {
    return new Promise<string | undefined>((resolve, reject) => {
      // Cache this query.
      const queryRequest = getRequest(GetCoachCuidQuery);
      const queryDescriptor = createOperationDescriptor(queryRequest, refreshVariables);
      environment.retain(queryDescriptor);
      // See if we can get the data from the cache otherwise fetch it from the server.
      const response = environment.lookup(queryDescriptor.fragment);
      const coachCuid = response?.data?.coach_connection?.edges?.[0]?.node?.cuid;
      if (coachCuid) {
        resolve(coachCuid);
        return;
      }
      // Fetch the data from the server.
      fetchQuery<RouteAnalytics_GetCoachCuidQuery>(
        environment,
        GetCoachCuidQuery,
        refreshVariables,
      ).subscribe({
        next: (data) => {
          resolve(data.coach_connection?.edges?.[0]?.node?.cuid);
        },
        error: (err: Error) => {
          reject(err);
        },
      });
    });
  }, []);
  /**
   * Get eventMetadataCuid from DB based on the eventSlug
   */
  const fetchEventMetadataCuid = useCallback((refreshVariables: { eventSlug: string }) => {
    return new Promise<string | undefined>((resolve, reject) => {
      // Cache this query.
      const queryRequest = getRequest(GetEventMetadataCuidQuery);
      const queryDescriptor = createOperationDescriptor(queryRequest, refreshVariables);
      environment.retain(queryDescriptor);
      // See if we can get the data from the cache otherwise fetch it from the server.
      const response = environment.lookup(queryDescriptor.fragment);
      const eventMetadataCuid = response?.data?.eventMetadata_connection?.edges?.[0]?.node?.cuid;
      if (eventMetadataCuid) {
        resolve(eventMetadataCuid);
        return;
      }
      // Fetch the data from the server.
      fetchQuery<RouteAnalytics_GetEventMetadataCuidQuery>(
        environment,
        GetEventMetadataCuidQuery,
        refreshVariables,
      ).subscribe({
        next: (data) => {
          resolve(data.eventMetadata_connection?.edges?.[0]?.node?.cuid);
        },
        error: (err: Error) => {
          reject(err);
        },
      });
    });
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      // Segment
      if (segmentAnalytics) {
        let organizerCuid;
        let coachCuid;
        let eventMetadataCuid;
        if (routeParams?.organizerSlug) {
          organizerCuid = await fetchOrganizerCuid({
            organizerSlug: routeParams.organizerSlug as string,
          });
        }
        if (routeParams?.coachSlug) {
          coachCuid = await fetchCoachCuid({ coachSlug: routeParams.coachSlug as string });
        }
        if (routeParams?.eventSlug) {
          eventMetadataCuid = await fetchEventMetadataCuid({
            eventSlug: routeParams.eventSlug as string,
          });
        }
        const props = {
          autotracked: true,
          routeParams,
          urlParams: getParsedQueryParams(),
          isAdBlockRunning,
          organizerCuid,
          eventMetadataCuid,
          coachCuid,
          isServer: false,
        };

        const name = location.pathname;
        if (isAdBlockRunning) {
          sendSegmentEventCF({
            functionName: 'page',
            message: {
              anonymousId: segmentAnalytics.user().anonymousId() || reggyAnonymousId,
              name,
              properties: props,
            },
          }).then();
        } else if (isAdBlockRunning === false) {
          segmentAnalytics.page(name, props).then();
        }
      }
    })();
  }, [
    JSON.stringify(R.omit(['key'], location)),
    Boolean(segmentAnalytics),
    JSON.stringify(routeParams),
  ]);

  return null;
};

export default RouteAnalytics;
