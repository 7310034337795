/*
Show or hide a component based on a condition
 */
import React, { ReactNode } from 'react';
import * as ReactIs from 'react-is';
import * as R from 'ramda';

import { ReactFCC } from '@/lib/type-defs/utility';

/**
 * Recursively loop through the children and remove any fragments so we can add "display: 'none'"
 */
const getValidChildren = (children: ReactNode | ReactNode[]): ReactNode[] => {
  const validChildren = React.Children.toArray(children).map((child) => {
    if (ReactIs.isFragment(child) && child.props.children) {
      return getValidChildren(child.props.children);
    }
    return child;
  });
  return R.flatten(validChildren);
};

interface ShowHideProps {
  show: boolean | null | undefined | number;
  useCSS?: boolean;
  useOpacity?: boolean;
}
const ShowHide: ReactFCC<ShowHideProps> = ({ children, show, useCSS, useOpacity }) => {
  if (useCSS) {
    return (
      <>
        {getValidChildren(children).map((child, i) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              key: child.key || i,
              style: !show
                ? { ...child?.props?.style, display: 'none' }
                : { ...child?.props?.style },
            });
          }
          return child;
        })}
      </>
    );
  }
  if (useOpacity) {
    return <div style={!show ? { opacity: 0, pointerEvents: 'none' } : undefined}>{children}</div>;
  }
  return <>{Boolean(show) && children}</>;
};

export default ShowHide;
