import React from 'react';
import { Box, Button, Container, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import * as RA from 'ramda-adjunct';

import NoUnderlineRouterLink from '@/ui/NoUnderlineRouterLink';
import FadeComponent from '@/hoc/FadeComponent';

import {
  useSetIsLoginDialogOpenGlobal,
  useSetIsSignupDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

export const useAuthContainerStyles = makeStyles((theme) => ({
  authContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[24],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 3),
    minHeight: theme.spacing(12),
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  dashboardItem: {
    minWidth: 101,
  },
}));

interface AuthContainerProps {
  handleMobileMenuClose: () => void;
}
const AuthContainer: React.FC<AuthContainerProps> = ({ handleMobileMenuClose }) => {
  const classes = useAuthContainerStyles();
  const auth = useAuth();
  const isSmallScreenDown = useIsSmallScreenDown();
  const haveButtonsWrapped = useMediaQuery('(max-width:507px)');

  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();

  const partOfOrganization = RA.isNotNilOrEmpty(auth.user?.userOrganizerRoles);

  /**
   * Open the login dialog
   */
  const openLoginDialog = (): void => {
    setIsLoginDialogOpenGlobal(true);
    handleMobileMenuClose();
  };
  /**
   * Open the signup dialog
   */
  const openSignupDialog = (): void => {
    setIsSignupDialogOpenGlobal(true);
    handleMobileMenuClose();
  };

  if (auth.isInitializing) {
    return (
      <Box className={classes.authContainer}>
        <Container maxWidth="sm">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={2.5}
            mt={2}
            width="100%"
          >
            <Box display="flex" justifyContent="center">
              <Skeleton variant="text" width="30%" />
            </Box>
            {haveButtonsWrapped ? (
              <>
                <Box display="flex" justifyContent="center" mb={2} mt={1}>
                  <Skeleton variant="text" width="85%" />
                </Box>
                <Box display="flex" justifyContent="center" mb={1}>
                  <Skeleton variant="text" width="85%" />
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="center" mb={1}>
                <Skeleton variant="text" width="100%" />
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Skeleton variant="text" width="10%" />
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box className={classes.authContainer}>
      <FadeComponent shouldFade>
        {partOfOrganization ? (
          <Box alignItems="center" display="flex" justifyContent="center">
            <NoUnderlineRouterLink to={AppRouteService.getRelativeUrl('Organizer_Start')}>
              <Button
                className={classes.dashboardItem}
                color="primary"
                // Refetch to make sure we will have permission
                onClick={(): void => auth.fetchUser({ userId: auth.firebaseUser?.uid })}
                size="medium"
                variant="outlined"
              >
                Switch to Organizer
              </Button>
            </NoUnderlineRouterLink>
          </Box>
        ) : !auth.isLoggedIn ? (
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Button
                fullWidth
                color="primary"
                onClick={openLoginDialog}
                size={isSmallScreenDown ? 'medium' : 'large'}
                variant="outlined"
              >
                Log in
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                fullWidth
                color="primary"
                onClick={openSignupDialog}
                size={isSmallScreenDown ? 'medium' : 'large'}
                variant="contained"
              >
                Sign up
              </Button>
            </Grid>
          </Grid>
        ) : undefined}
      </FadeComponent>
    </Box>
  );
};

export default AuthContainer;
