/**
 * Clinic Duration
 */
import React, { Dispatch, SetStateAction } from 'react';
import isEqual from 'react-fast-compare';
import { Box, IconButton, MenuItem, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import cuid from 'cuid';
import pluralize from 'pluralize';

import ExtendedSelect from '@/ui/TextField/ExtendedSelect';

import { CLINIC_DURATIONS_IN_MINUTES } from '@/const';
import {ONE_HOUR_IN_MINUTES} from "@/lib/date-helpers/date-utils";

const useStyles = makeStyles((theme: Theme) => ({
  deleteIconContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    '&:hover svg': {
      color: 'black',
    },
  },
  selectContainer: {
    top: '7px',
    position: 'relative',
    paddingRight: theme.spacing(4.5),
    margin: theme.spacing(0, 1),
    flexGrow: 1,
  },
}));

export interface ClinicDayDurationType {
  id?: string;
  cuid: string;
  dayNumber: number;
  duration: number | unknown;
}
interface ClinicDayDurationsProps {
  clinicDayDuration?: ClinicDayDurationType;
  hasError?: boolean;
  isFirstItem: boolean;
  setClinicDayDurations: Dispatch<SetStateAction<ClinicDayDurationType[]>>;
}
const ClinicDayDuration = React.memo<ClinicDayDurationsProps>(
  ({ clinicDayDuration, hasError, isFirstItem, setClinicDayDurations }) => {
    const classes = useStyles();
    const isEditing = Boolean(clinicDayDuration);

    /**
     * Add a duration
     */
    const handleAddDuration = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      setClinicDayDurations((durations) => [
        ...durations,
        {
          cuid: cuid(),
          dayNumber: durations.length + 1,
          duration: parseInt(e.target.value, 10),
        },
      ]);

      // Blur the current dropdown
      setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }, 0);
    };

    /**
     * Edit an existing duration
     */
    const handleEditDuration = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      setClinicDayDurations((durations) =>
        durations.map((duration) => {
          if (duration.cuid === clinicDayDuration?.cuid) {
            return { ...duration, duration: parseInt(e.target.value, 10) };
          }
          return duration;
        }),
      );
    };

    /**
     * Delete an existing duration
     */
    const handleDeleteDuration = (): void => {
      setClinicDayDurations((durations) =>
        durations.filter((duration) => {
          return duration.cuid !== clinicDayDuration!.cuid;
        }),
      );
    };

    return (
      <Box alignItems="center" display="flex" mb={1} position="relative">
        <Box minWidth={83} position="relative" top={clinicDayDuration ? 9 : 5}>
          {clinicDayDuration ? (
            <Typography align="right">Day {clinicDayDuration.dayNumber}</Typography>
          ) : (
            <Typography align="right" color={hasError ? 'error' : undefined}>
              Add Day
            </Typography>
          )}
        </Box>
        <Box className={classes.selectContainer}>
          <ExtendedSelect
            fullWidth
            error={hasError}
            helperText={hasError ? 'Please select a duration' : undefined}
            // label={isFirstItem ? 'Duration' : 'Duration (Optional)'}
            margin="dense"
            minWidth="md"
            onChange={isEditing ? handleEditDuration : handleAddDuration}
            placeholder="Select duration..."
            value={isEditing ? clinicDayDuration?.duration : ''}
          >
            {CLINIC_DURATIONS_IN_MINUTES.map((minutes) => (
              <MenuItem key={`clinic-duration-${minutes}`} value={minutes}>
                {minutes / ONE_HOUR_IN_MINUTES} {pluralize('Hour', minutes / ONE_HOUR_IN_MINUTES)}
              </MenuItem>
            ))}
          </ExtendedSelect>
        </Box>
        {clinicDayDuration && (
          <Box className={classes.deleteIconContainer}>
            <IconButton onClick={handleDeleteDuration} size="small">
              <CancelIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  },
  isEqual,
);

export default ClinicDayDuration;
