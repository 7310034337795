import { ClinicCategory, CoachCategory, Question, V3 } from '../types/V3';

/**
 * Private Lesson Questions
 */
const PL_RESPONSE_TIME: Question<CoachCategory> = {
  id: 'coach_responseTime',
  category: {
    subjectType: 'coach',
    category: 'communication',
  },
  responseType: 'y/n',
  attribute: 'response time',
  alwaysAsk: false,
  label: 'Did the coach respond to you on Reggy in a reasonable amount of time?',
};

const PL_ARRIVAL_TIME: Question<CoachCategory> = {
  id: 'coach_arrivalTime',
  category: { subjectType: 'coach', category: 'communication' },
  responseType: 'y/n',
  attribute: 'arrival time',
  alwaysAsk: false,
  label: 'Did the coach arrive on time?',
};

const PL_LESSON_PLAN: Question<CoachCategory> = {
  id: 'coach_lessonPlan',
  category: { subjectType: 'coach', category: 'expertise' },
  responseType: 'y/n',
  attribute: 'lesson plan',
  alwaysAsk: false,
  label: 'Did what the coach teach you match up with the stated lesson plan on Reggy?',
};

const PL_SKILL_ASSESSMENT_ACCURACY: Question<CoachCategory> = {
  id: 'coach_skillAssessmentAccuracy',
  category: { subjectType: 'coach', category: 'expertise' },
  responseType: 'y/n',
  attribute: 'skill assessment accuracy',
  alwaysAsk: false,
  label: 'Did the coach assess your skill level accurately?',
};

const PL_PROGRESS: Question<CoachCategory> = {
  id: 'coach_progress',
  category: { subjectType: 'coach', category: 'expertise' },
  responseType: 'y/n',
  attribute: 'progress',
  alwaysAsk: false,
  label: 'Did you feel like the coach helped you make progress?',
};

const PL_ENERGY_LEVEL: Question<CoachCategory> = {
  id: 'coach_energyLevel',
  category: { subjectType: 'coach', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'energy level',
  alwaysAsk: false,
  label: 'Did you like the energy level of the coach?',
};

const PL_PACING: Question<CoachCategory> = {
  id: 'coach_pacing',
  category: { subjectType: 'coach', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'pacing',
  alwaysAsk: false,
  label: 'Did you like the pacing of the lesson?',
};

const PL_COACH_TOUGHNESS: Question<CoachCategory> = {
  id: 'coach_coachToughness',
  category: { subjectType: 'coach', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'coach toughness',
  alwaysAsk: false,
  label: 'Was the coach tough enough?',
};

const PL_FRIENDLINESS: Question<CoachCategory> = {
  id: 'coach_friendliness',
  category: { subjectType: 'coach', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'friendliness',
  alwaysAsk: false,
  label: 'Was the coach friendly?',
};

const PL_EASYGOINGNESS: Question<CoachCategory> = {
  id: 'coach_easygoingness',
  category: { subjectType: 'coach', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'easygoingness',
  alwaysAsk: false,
  label: 'Did working with the coach feel easy going?',
};

const PL_RECOMMENDATION: Question<CoachCategory> = {
  id: 'coach_recommendation',
  category: { subjectType: 'coach', category: 'general' },
  responseType: 'recommendation',
  attribute: 'recommendation',
  alwaysAsk: true,
  label: 'Would you recommend this coach to your friends?',
};

export const PRIVATE_LESSON_QUESTIONS: V3['contents']['coachQuestions'] = [
  PL_RESPONSE_TIME,
  PL_ARRIVAL_TIME,
  PL_LESSON_PLAN,
  PL_SKILL_ASSESSMENT_ACCURACY,
  PL_PROGRESS,
  PL_ENERGY_LEVEL,
  PL_PACING,
  PL_COACH_TOUGHNESS,
  PL_FRIENDLINESS,
  PL_EASYGOINGNESS,
  PL_RECOMMENDATION,
];

/**
 * Clinic Questions
 */
const CLINIC_LESSON_PLAN: Question<ClinicCategory> = {
  id: 'clinic_lessonPlan',
  category: { subjectType: 'clinic', category: 'content' },
  responseType: 'y/n',
  attribute: 'lesson plan',
  alwaysAsk: false,
  label: 'Did what you learn at the clinic match up with the stated curriculumn on Reggy?',
};

const CLINIC_LEARNING: Question<ClinicCategory> = {
  id: 'clinic_learning',
  category: { subjectType: 'clinic', category: 'content' },
  responseType: 'y/n',
  attribute: 'learning',
  alwaysAsk: true,
  label: 'Did you learn something new?',
};

const CLINIC_FRIENDLINESS: Question<ClinicCategory> = {
  id: 'clinic_friendliness',
  category: { subjectType: 'clinic', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'friendliness',
  alwaysAsk: false,
  label: 'Was the coach friendly?',
};

const CLINIC_ENERGY_LEVEL: Question<ClinicCategory> = {
  id: 'coach_energyLevel',
  category: { subjectType: 'clinic', category: 'vibe' },
  responseType: 'y/n',
  attribute: 'energy level',
  alwaysAsk: false,
  label: 'Did you like the energy level of the clinic?',
};

const CLINIC_TERRAIN: Question<ClinicCategory> = {
  id: 'clinic_terrain',
  category: { subjectType: 'clinic', category: 'organization' },
  responseType: 'y/n',
  attribute: 'terrain',
  alwaysAsk: false,
  label: 'Was the terrain well suited for the clinic? (e.g. trail, track, obstacle, park)',
};

const CLINIC_LOCATION: Question<ClinicCategory> = {
  id: 'clinic_location',
  category: { subjectType: 'clinic', category: 'organization' },
  responseType: 'y/n',
  attribute: 'terrain',
  alwaysAsk: false,
  label: 'Was the location convenient for meeting up and parking?',
};

const CLINIC_SMOOTHNESS: Question<ClinicCategory> = {
  id: 'clinic_smoothness',
  category: { subjectType: 'clinic', category: 'organization' },
  responseType: 'y/n',
  attribute: 'smoothness',
  alwaysAsk: false,
  label: 'Did the clinic run smoothly?',
};

const CLINIC_INDIVIDUAL_ATTENTION: Question<ClinicCategory> = {
  id: 'clinic_individualAttention',
  category: { subjectType: 'clinic', category: 'general' },
  responseType: 'y/n',
  attribute: 'individual attention',
  alwaysAsk: true,
  label: 'Did you feel that the coach was able to pay individual attention to you?',
};

const CLINIC_RECOMMENDATION: Question<ClinicCategory> = {
  id: 'clinic_recommendation',
  category: { subjectType: 'clinic', category: 'general' },
  responseType: 'recommendation',
  attribute: 'recommendation',
  alwaysAsk: true,
  label: 'Would you recommend this clinic to your friends?',
};

export const CLINIC_QUESTIONS: V3['contents']['clinicQuestions'] = [
  CLINIC_LESSON_PLAN,
  CLINIC_LEARNING,
  CLINIC_FRIENDLINESS,
  CLINIC_ENERGY_LEVEL,
  CLINIC_TERRAIN,
  CLINIC_LOCATION,
  CLINIC_SMOOTHNESS,
  CLINIC_INDIVIDUAL_ATTENTION,
  CLINIC_RECOMMENDATION,
];

export default {
  PRIVATE_LESSON_QUESTIONS,
  CLINIC_QUESTIONS,
};
