// Unpack an array of values

import { FC, PropsWithChildren } from 'react';
import { Mutable } from 'utility-types';

export type Unpacked<T> = T extends (infer U)[] ? U : T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Unpromise<T extends Promise<any>> = T extends Promise<infer U> ? U : T;

export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export type PartialWithRequired<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export interface ObjectIndexer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export type Maybe<T> = null | undefined | T;

interface NextInt {
  0: 1;
  1: 2;
  2: 3;
  3: 4;
  4: 5;
  [rest: number]: number;
}

export type PathType<T, P extends string[], Index extends keyof P & number = 0> = {
  [K in keyof P & number & Index]: P[K] extends undefined
    ? T
    : P[K] extends keyof T
    ? NextInt[K] extends keyof P & number
      ? PathType<T[P[K]], P, Extract<NextInt[K], keyof P & number>>
      : T[P[K]]
    : never;
}[Index];

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export function isDefined<T>(t: T | undefined): t is T {
  return t !== undefined;
}

export function isFilled<T>(t: T | null): t is T {
  return t !== null;
}

export type DeepMutable<T> = T extends (...args: any[]) => any
  ? T
  : T extends any[]
  ? DeepMutableArray<T[number]>
  : T extends object
  ? DeepMutableObject<T>
  : T;
/** @private */
export type DeepMutableArray<T> = Array<DeepMutable<Mutable<T>>>;
/** @private */
export declare type DeepMutableObject<T> = {
  [P in keyof T]-?: DeepMutable<Mutable<T[P]>>;
};

export type ReactFCC<T = unknown> = FC<PropsWithChildren<T>>;
