/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RouteAnalytics_GetEventMetadataCuidQueryVariables = {
    eventSlug: string;
};
export type RouteAnalytics_GetEventMetadataCuidQueryResponse = {
    readonly eventMetadata_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly cuid: string;
            };
        }>;
    };
};
export type RouteAnalytics_GetEventMetadataCuidQuery = {
    readonly response: RouteAnalytics_GetEventMetadataCuidQueryResponse;
    readonly variables: RouteAnalytics_GetEventMetadataCuidQueryVariables;
};



/*
query RouteAnalytics_GetEventMetadataCuidQuery(
  $eventSlug: String!
) {
  eventMetadata_connection(where: {slug: {_eq: $eventSlug}}) {
    edges {
      node {
        cuid
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "eventSlug"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "eventSlug"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "slug"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RouteAnalytics_GetEventMetadataCuidQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventMetadataConnection",
                    "kind": "LinkedField",
                    "name": "eventMetadata_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventMetadataEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "eventMetadata",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RouteAnalytics_GetEventMetadataCuidQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "eventMetadataConnection",
                    "kind": "LinkedField",
                    "name": "eventMetadata_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "eventMetadataEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "eventMetadata",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "064713564339073be591c826cfd3d460",
            "id": null,
            "metadata": {},
            "name": "RouteAnalytics_GetEventMetadataCuidQuery",
            "operationKind": "query",
            "text": "query RouteAnalytics_GetEventMetadataCuidQuery(\n  $eventSlug: String!\n) {\n  eventMetadata_connection(where: {slug: {_eq: $eventSlug}}) {\n    edges {\n      node {\n        cuid\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1b9a7e84ed14406b4558d3f920e3b1f1';
export default node;
