/**
 * Signup Controls
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@material-ui/core';
import Apple from '@material-ui/icons/Apple';
import Facebook from '@material-ui/icons/Facebook';
import classNames from 'classnames';
import * as RA from 'ramda-adjunct';

import BrandTypography from '@/ui/BrandTypography';
import ButtonLink from '@/ui/Button/ButtonLink';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import LinkButton from '@/ui/Button/LinkButton';
import FormOrDivider from '@/ui/FormOrDivider';
import GlobalLoadingBackdrop from '@/ui/GlobalLoadingBackdrop';
import { useLightGreyHelperText } from '@/ui/styles/typographyStyles';
import FormTextField from '@/ui/TextField/FormTextField';
import PasswordField from '@/ui/TextField/PasswordField';
import DisableToggle from '@/hoc/DisableToggle';

import {
  useAuthButtonStyles,
  useAuthErrorContainerStyles,
  useAuthPageHeader,
  useEmailControls,
  useShowEmailButton,
} from '../styles';

import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import {
  TeaserMessage,
  TeaserMessageProps,
  useGetIsLoginSignupDialogBlockingGlobal,
  useGetLoginSignupTeaserMessageGlobal,
  useGetLoginSignupTeaserMessagePropsGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsLoginDialogOpenGlobal,
  useSetIsSignupDialogOpenGlobal,
  useSetLoginSignupDialogBlockingModeGlobal,
} from '@/containers/Auth/recoilStore';
import { Firebase } from '@/lib/firebase';
import { SignupSchema } from '@/lib/form-schema/auth';
import { EmailPasswordProps, useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

interface SignupFormProps {
  isDialog?: boolean | undefined;
}

const EmptyTeaserMessage: React.FC = () => {
  return null;
};
const DefaultTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Sign up for a <BrandTypography inline>Reggy</BrandTypography> account to get started
        organizing, discovering, and registering for experiences!
      </Typography>
    </Box>
  );
};
interface RegistrationTeaserMessageProps {
  eventName: string;
}
const RegistrationTeaserMessage: React.FC<RegistrationTeaserMessageProps> = ({ eventName }) => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Sign up for a <BrandTypography inline>Reggy</BrandTypography> account to continue
        registration for <b>{eventName}</b>.
      </Typography>
    </Box>
  );
};
interface OnlineCourseTeaserMessageProps {
  onlineCourseTitle: string;
}
const OnlineCourseTeaserMessage: React.FC<OnlineCourseTeaserMessageProps> = ({
  onlineCourseTitle,
}) => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Sign up for a <BrandTypography inline>Reggy</BrandTypography> account to start learning with{' '}
        <b>{onlineCourseTitle}</b>.
      </Typography>
    </Box>
  );
};
const WaitlistTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Thanks for being interested in{' '}
        <BrandTypography inline noLink variant="body2">
          Reggy
        </BrandTypography>
        ! Let's get you an account so we know who to make a creator once you're off the waitlist!
      </Typography>
    </Box>
  );
};
const WaitlistCodeTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Thanks for being interested in{' '}
        <BrandTypography inline noLink variant="body2">
          Reggy
        </BrandTypography>
        ! Let's get you an account so we can activate your account with your code.
      </Typography>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TeaserMessageToComponent: Record<TeaserMessage, React.FC<any>> = {
  empty: EmptyTeaserMessage,
  default: DefaultTeaserMessage,
  registration: RegistrationTeaserMessage,
  onlineCourse: OnlineCourseTeaserMessage,
  waitlist: WaitlistTeaserMessage,
  waitlistCode: WaitlistCodeTeaserMessage,
};

const getTeaserMessage = (tm: TeaserMessage, p: TeaserMessageProps = null): React.ReactElement => {
  const Component = TeaserMessageToComponent[tm];
  const props = p ?? {};
  return <Component {...props} />;
};

const SignupFormContainer: React.FC<SignupFormProps> = ({ isDialog }) => {
  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<EmailPasswordProps>({
    resolver: yupResolver(SignupSchema),
    reValidateMode: 'onSubmit',
  });
  const authButtonClasses = useAuthButtonStyles();
  const emailControlsClasses = useEmailControls();
  const authPageHeaderClasses = useAuthPageHeader();
  const helperTextClasses = useLightGreyHelperText();
  const showEmailButtonClasses = useShowEmailButton();
  const authErrorClasses = useAuthErrorContainerStyles();

  const auth = useAuth();
  const navigate = useNavigate();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const { trackUserSignUpViewed, trackUserSignUpWithEmailClick } = useSegmentTrack();
  const isLoginSignupDialogBlockingGlobal = useGetIsLoginSignupDialogBlockingGlobal();
  const setLoginSignupDialogBlockingModeGlobal = useSetLoginSignupDialogBlockingModeGlobal();

  const [showEmailControls, setShowEmailControls] = useState(false);

  const teaserMessage = useGetLoginSignupTeaserMessageGlobal();
  const teaserMessageProps = useGetLoginSignupTeaserMessagePropsGlobal();

  useMount(() => {
    trackUserSignUpViewed({ teaserMessage });
  });

  // Remove error and redirectUrl on unmount
  useEffect(() => {
    return (): void => {
      auth.setFirebaseError(null);
      setAuthRedirectURLGlobal(null);
    };
  }, []);

  const signupWithGoogle = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Google, true);
  };
  const signupWithFacebook = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Facebook, true);
  };
  const signupWithApple = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Apple, true);
  };
  const signupWithEmail = handleSubmit((data) => {
    auth.setFirebaseError(null);
    auth.signupWithEmail(data).then();
  });

  const handleShowEmailControls = (): void => {
    trackUserSignUpWithEmailClick({ teaserMessage });
    setShowEmailControls(true);
  };

  const goToLogin = (): void => {
    if (isDialog) {
      if (isLoginSignupDialogBlockingGlobal) {
        setLoginSignupDialogBlockingModeGlobal('login');
        return;
      }
      setIsLoginDialogOpenGlobal(true);
      setIsSignupDialogOpenGlobal(false);
      return;
    }
    navigate(AppRouteService.getRelativeUrl('Login'));
  };

  const closeDialog = (): void => {
    if (isDialog) {
      setIsSignupDialogOpenGlobal(false);
    }
  };

  const goToRoute = (route: string): (() => void) => {
    return (): void => {
      closeDialog();
      navigate(route);
    };
  };

  return (
    <Box marginBottom="auto" marginTop="auto">
      <GlobalLoadingBackdrop isLoading={auth.isLoading} title="Creating Account" />
      <Box margin="auto" px={3}>
        {!isDialog && !showEmailControls && !auth.firebaseError && (
          <Link to={AppRouteService.getRelativeUrl('Home')}>
            <h2 className={authPageHeaderClasses.root}>Reggy</h2>
          </Link>
        )}
        <Box mb={4}>
          <Typography gutterBottom align="center" variant="h1">
            Create Account
          </Typography>
          {getTeaserMessage(teaserMessage, teaserMessageProps)}
        </Box>
        <DisableToggle showLoadingWhileDisabled disabled={auth.isLoading}>
          <div>
            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.appleRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={signupWithApple}
                size="large"
                startIcon={<Apple />}
                variant="contained"
              >
                Continue with Apple
              </ExtendedButton>
            </Box>

            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.facebookRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={signupWithFacebook}
                size="large"
                startIcon={<Facebook />}
                variant="contained"
              >
                Continue with Facebook
              </ExtendedButton>
            </Box>

            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.googleRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={signupWithGoogle}
                size="large"
                variant="contained"
              >
                <span className={authButtonClasses.googleIcon}>
                  <img
                    alt="G"
                    height="100%"
                    src="https://res.cloudinary.com/goreggy/image/upload/v1575154125/site/icons/google-g_htsv6t.svg"
                    width="100%"
                  />
                </span>
                <div />
                Continue with Google
              </ExtendedButton>
            </Box>

            <FormOrDivider />

            {auth.firebaseError && (
              <div className={authErrorClasses.root}>
                <div>{auth.firebaseError?.errorMessage}</div>
              </div>
            )}

            <Box
              className={classNames(showEmailButtonClasses.root, {
                [showEmailButtonClasses.hidden]: showEmailControls,
              })}
            >
              <ExtendedButton
                fullWidth
                color="primary"
                onClick={handleShowEmailControls}
                size="large"
                variant="outlined"
              >
                Sign up with Email
              </ExtendedButton>
            </Box>
            <form
              className={classNames(emailControlsClasses.root, {
                open: showEmailControls,
                signup: showEmailControls && RA.isNotEmpty(errors),
              })}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    fullWidth
                    control={control}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    label="First Name"
                    name="firstName"
                    onChange={(e): void => {
                      // Handle auto-fill not registering
                      setValue('firstName', e.target.value);
                      auth.setFirebaseError(null);
                      clearErrors('firstName');
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    fullWidth
                    control={control}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    label="Last Name"
                    name="lastName"
                    onChange={(e): void => {
                      // Handle auto-fill not registering
                      setValue('lastName', e.target.value);
                      auth.setFirebaseError(null);
                      clearErrors('lastName');
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <FormTextField
                  fullWidth
                  autoComplete="email"
                  control={control}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  label="Email Address"
                  name="email"
                  onChange={(e): void => {
                    // Handle auto-fill not registering
                    setValue('email', e.target.value);
                    auth.setFirebaseError(null);
                    clearErrors('email');
                  }}
                  onKeyUp={(e): void => {
                    if (e.key === 'Enter') {
                      signupWithEmail().then();
                    }
                  }}
                  variant="outlined"
                />
              </Box>
              <Box mt={2}>
                <PasswordField
                  fullWidth
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  {...register('password')}
                  label="Password"
                  onChange={(): void => {
                    auth.setFirebaseError(null);
                    clearErrors('password');
                  }}
                  variant="outlined"
                />
              </Box>
              <Box mt={3}>
                <ExtendedButton
                  fullWidth
                  color="primary"
                  onClick={signupWithEmail}
                  size="large"
                  variant="contained"
                >
                  Sign Up
                </ExtendedButton>
              </Box>
            </form>

            <Box alignItems="center" display="flex" justifyContent="center" mt={2}>
              <Typography align="center" variant="body2">
                Already have an account?
              </Typography>
              <Box ml={0.5}>
                <LinkButton onClick={goToLogin}>Log in</LinkButton>
              </Box>
            </Box>
            <Box mt={-1}>
              <Typography align="center" className={helperTextClasses.root} variant="body2">
                By creating an account, you agree to our{' '}
                <LinkButton onClick={goToRoute(AppRouteService.getRelativeUrl('PrivacyPolicy'))}>
                  Privacy Policy
                </LinkButton>{' '}
                and{' '}
                <LinkButton onClick={goToRoute(AppRouteService.getRelativeUrl('TermsOfService'))}>
                  Terms of Service
                </LinkButton>
              </Typography>
            </Box>
          </div>
          <Box display="flex" justifyContent="center" mt={2}>
            <ButtonLink
              onClick={closeDialog}
              size="small"
              to={AppRouteService.getRelativeUrl('Home')}
            >
              Home
            </ButtonLink>
          </Box>
        </DisableToggle>
      </Box>
    </Box>
  );
};
export default SignupFormContainer;
