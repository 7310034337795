/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type setupCoachMutations_hideSetupCoach_MutationVariables = {
    userId: string;
};
export type setupCoachMutations_hideSetupCoach_MutationResponse = {
    readonly update_user: {
        readonly returning: ReadonlyArray<{
            readonly showSetupCoach: boolean;
        }>;
    } | null;
};
export type setupCoachMutations_hideSetupCoach_Mutation = {
    readonly response: setupCoachMutations_hideSetupCoach_MutationResponse;
    readonly variables: setupCoachMutations_hideSetupCoach_MutationVariables;
};



/*
mutation setupCoachMutations_hideSetupCoach_Mutation(
  $userId: String!
) {
  update_user(where: {id: {_eq: $userId}}, _set: {showSetupCoach: false}) {
    returning {
      showSetupCoach
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "kind": "Literal",
            "name": "_set",
            "value": {
                "showSetupCoach": false
            }
        } as any,
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showSetupCoach",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "setupCoachMutations_hideSetupCoach_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "user_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "mutation_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "setupCoachMutations_hideSetupCoach_Mutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "user_mutation_response",
                    "kind": "LinkedField",
                    "name": "update_user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "returning",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "eb5f54d04721eb8a28cb6d2cd32154fe",
            "id": null,
            "metadata": {},
            "name": "setupCoachMutations_hideSetupCoach_Mutation",
            "operationKind": "mutation",
            "text": "mutation setupCoachMutations_hideSetupCoach_Mutation(\n  $userId: String!\n) {\n  update_user(where: {id: {_eq: $userId}}, _set: {showSetupCoach: false}) {\n    returning {\n      showSetupCoach\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '51c183dae8d1ad42d2a5045415e6dfbc';
export default node;
