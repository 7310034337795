import { Theme } from '@material-ui/core';

export const MuiMenu = (theme: Theme): Record<string, unknown> => ({
  list: {
    '& .MuiListSubheader-root': {
      lineHeight: `${theme.spacing(3)}px`,
    },
    '& .MuiListItem-root': {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      minWidth: '141px',
      '&:hover': {
        background: '#36404c1c',
      },
    },
  },
  paper: {
    border: '1px solid #D8D8D8',
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    // '& .MuiListItem-root.MuiMenuItem-root ': {
    //   minWidth: '141px',
    // },
  },
});
