/*
UserOrganizerRole utils
 */
import { useParams } from 'react-router';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { roleOrganizer_enum } from '@/containers/User/Registrations/RegistrationDetail/EditRegistrant/__generated__/editRegistrantMutations_saveRegistrantUser_Mutation.graphql';
import { RoleOrganizerEnum } from '@/graphql/__generated__/graphql';
import { getUserOrganizerRoleObjectsBySlug } from '@/lib/selectors/user';
import { AuthContextProps, useAuth } from '@/providers/AuthProvider';

/**
 * Check if a user has a organizer role for an event
 */
export function userHasOrganizationRole(
  user: AuthContextProps['user'] | undefined,
  roleToCheck: RoleOrganizerEnum,
  organizerSlug: string | undefined,
): boolean {
  if (!user || !organizerSlug) {
    return false;
  }
  const roles = user?.userOrganizerRoles || [];
  const hasRoles = roles.filter((role) => {
    if (role.role !== roleToCheck) {
      return false;
    }
    if (!role.organizer) {
      return false;
    }
    return R.propEq('slug', organizerSlug, role.organizer);
  });
  return RA.isNotNilOrEmpty(hasRoles);
}

export const useOrganizerPermissions = (): {
  checkOrganizerRole: (roleToCheck: RoleOrganizerEnum, slugToCheck: string) => boolean;
  isCurrentOrgOwner: boolean;
  isCurrentOrgAdmin: boolean;
  isCurrentOrgSupport: boolean;
  isCurrentOrgCoach: boolean;
  isCurrentOrgCoachTeachingEventClinic: boolean;
  isCurrentOrgMember: boolean;
} => {
  const { user } = useAuth();
  const { eventCuid, eventSlug, organizerSlug } = useParams();

  const isCurrentOrgOwner = userHasOrganizationRole(user, RoleOrganizerEnum.Owner, organizerSlug);
  const isCurrentOrgAdmin = userHasOrganizationRole(user, RoleOrganizerEnum.Admin, organizerSlug);
  const isCurrentOrgSupport = userHasOrganizationRole(
    user,
    RoleOrganizerEnum.Support,
    organizerSlug,
  );
  const isCurrentOrgCoach = userHasOrganizationRole(user, RoleOrganizerEnum.Coach, organizerSlug);
  const allOrganizerEvents =
    getUserOrganizerRoleObjectsBySlug(user, organizerSlug)?.flatMap(
      (organizerRole) => organizerRole?.organizer?.events ?? [],
    ) ?? [];
  const isCurrentOrgCoachTeachingEventClinic =
    userHasOrganizationRole(user, RoleOrganizerEnum.Coach, organizerSlug) &&
    allOrganizerEvents.some(
      (event) => event.cuid === eventCuid || event.eventMetadata.slug === eventSlug,
    );

  const checkOrganizerRole = (roleToCheck: RoleOrganizerEnum, slugToCheck: string): boolean => {
    return userHasOrganizationRole(user, roleToCheck, slugToCheck);
  };
  return {
    isCurrentOrgOwner,
    isCurrentOrgAdmin,
    isCurrentOrgCoach,
    isCurrentOrgSupport,
    isCurrentOrgCoachTeachingEventClinic,
    isCurrentOrgMember:
      isCurrentOrgOwner || isCurrentOrgAdmin || isCurrentOrgCoach || isCurrentOrgSupport,
    checkOrganizerRole,
  };
};

/**
 * Check if the user has any organizer roles
 */
export const userHasSomeOrganizerRoles = (
  roles: roleOrganizer_enum[] | undefined,
  rolesToCheck: RoleOrganizerEnum[],
): boolean => {
  if (!roles) {
    return false;
  }
  return roles.some((role) => rolesToCheck.includes(role));
};

/**
 * Check if the user has all organizer roles
 */
export const userHasAllOrganizerRoles = (
  roles: roleOrganizer_enum[] | undefined,
  rolesToCheck: RoleOrganizerEnum[],
): boolean => {
  if (!roles) {
    return false;
  }
  return rolesToCheck.every((role) => roles.includes(role));
};
