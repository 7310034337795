/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AuthProviderCoachScheduledForUpcomingClinicsQueryVariables = {
    userId: string;
};
export type AuthProviderCoachScheduledForUpcomingClinicsQueryResponse = {
    readonly clinic_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly event: {
                    readonly occurrenceLabel: string | null;
                    readonly eventMetadata: {
                        readonly name: string;
                    };
                };
            };
        }>;
    };
};
export type AuthProviderCoachScheduledForUpcomingClinicsQuery = {
    readonly response: AuthProviderCoachScheduledForUpcomingClinicsQueryResponse;
    readonly variables: AuthProviderCoachScheduledForUpcomingClinicsQueryVariables;
};



/*
query AuthProviderCoachScheduledForUpcomingClinicsQuery(
  $userId: String!
) {
  clinic_connection(where: {_and: [{clinicCoaches: {coach: {userId: {_eq: $userId}}}}, {clinicDays: {startTime: {_gte: "now()"}}}, {event: {deleted_at: {_is_null: true}}}, {event: {publishedAt: {_is_null: false}}}]}) {
    edges {
      node {
        event {
          occurrenceLabel
          eventMetadata {
            name
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "items": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "fields": [
                                                        {
                                                            "kind": "Variable",
                                                            "name": "_eq",
                                                            "variableName": "userId"
                                                        }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "userId"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "coach"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "clinicCoaches"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_and.0"
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.1",
                            "value": {
                                "clinicDays": {
                                    "startTime": {
                                        "_gte": "now()"
                                    }
                                }
                            }
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.2",
                            "value": {
                                "event": {
                                    "deleted_at": {
                                        "_is_null": true
                                    }
                                }
                            }
                        },
                        {
                            "kind": "Literal",
                            "name": "_and.3",
                            "value": {
                                "event": {
                                    "publishedAt": {
                                        "_is_null": false
                                    }
                                }
                            }
                        }
                    ],
                    "kind": "ListValue",
                    "name": "_and"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "occurrenceLabel",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthProviderCoachScheduledForUpcomingClinicsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "clinicConnection",
                    "kind": "LinkedField",
                    "name": "clinic_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "clinicEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "clinic",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AuthProviderCoachScheduledForUpcomingClinicsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "clinicConnection",
                    "kind": "LinkedField",
                    "name": "clinic_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "clinicEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "clinic",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2a8c784f3ac68d69b02f25545b0e4552",
            "id": null,
            "metadata": {},
            "name": "AuthProviderCoachScheduledForUpcomingClinicsQuery",
            "operationKind": "query",
            "text": "query AuthProviderCoachScheduledForUpcomingClinicsQuery(\n  $userId: String!\n) {\n  clinic_connection(where: {_and: [{clinicCoaches: {coach: {userId: {_eq: $userId}}}}, {clinicDays: {startTime: {_gte: \"now()\"}}}, {event: {deleted_at: {_is_null: true}}}, {event: {publishedAt: {_is_null: false}}}]}) {\n    edges {\n      node {\n        event {\n          occurrenceLabel\n          eventMetadata {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '22057006b74920c2334df0706fb85bc2';
export default node;
