/**
 * Search Result Hit
 */
import React, { ReactNode, useCallback } from 'react';
import { Hit } from 'react-instantsearch-core';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import * as RA from 'ramda-adjunct';

import DesktopHit from '@/components/Search/DesktopHit';
import DesktopSearchResult from '@/components/Search/unUsed/DesktopSearchResult';
import MobileHit from '@/components/Search/MobileHit';
import MobileSearchResult from '@/components/Search/unUsed/MobileSearchResult';

import { ALGOLIA_SEARCH_HIT_CLICKED } from '@/analytics/algolia/algolia';
import { useSegmentTrack } from '@/analytics/segment/segmentHooks';
import {
  DEFAULT_COACH_IMAGE,
  DEFAULT_EVENT_IMAGE,
  LessonRecurrenceTypeEnum,
  GenderEnum,
  SearchTypeEnum,
} from '@/const';
import { AlgoliaSearchResult, AlgoliaSearchResultHit } from '@/lib/algolia';
import { applyDefaultCloudinaryTransform } from '@/lib/cloudinary';
import { getAgeText, getCoachingSummary, getCoachLocationText } from '@/lib/coach-info-utils';
import { ONE_HOUR_IN_MINUTES } from '@/lib/date-helpers/date-utils';
import { addQueryParamsToUrl } from '@/lib/path-helpers';
import { correctlyTypeRange, getAbilityLabelFull } from '@/lib/slider-utils';
import { arrayToCommaAndString, numberArrayToPriceRange } from '@/lib/string-utils';
import { useIsSmallScreenDown } from '@/lib/ui-utils';

/**
 * Get the url for a hit
 * @param hit
 */
export const getHitImageUrls = (hit: AlgoliaSearchResultHit): string[] => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.images.length
        ? hit.images.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_EVENT_IMAGE];
    case SearchTypeEnum.Coach:
      return hit.profilePicUrl ? [hit.profilePicUrl] : [DEFAULT_COACH_IMAGE];
    case SearchTypeEnum.PrivateLesson:
      return hit.coachImages.length
        ? hit.coachImages.map((relativeUrl: string) => applyDefaultCloudinaryTransform(relativeUrl))
        : [DEFAULT_COACH_IMAGE];
    default:
      return [''];
  }
};

/**
 * Convert a hit location to readable text
 */
export const getHitSubtitleText = (hit: AlgoliaSearchResultHit): ReactNode => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return `${arrayToCommaAndString(hit.disciplines)} ${hit.activity} by ${
        hit.hostName || hit.organizerName
      }`;
    case SearchTypeEnum.Coach:
      return (
        getCoachingSummary({
          coachingVibe: hit.coachingVibe,
          coachDisciplines: hit.coachDisciplines,
        }) ?? ''
      );
    case SearchTypeEnum.PrivateLesson: {
      const shouldUseMinutes = hit.duration < ONE_HOUR_IN_MINUTES;
      return (
        <>
          This{' '}
          <b>
            {shouldUseMinutes ? hit.duration : hit.duration / ONE_HOUR_IN_MINUTES}{' '}
            {shouldUseMinutes ? 'minute' : 'hour'}
          </b>{' '}
          lesson teaches{' '}
          <b>
            {getAbilityLabelFull({
              abilityLevelRange: [hit.abilityMin, hit.abilityMax],
              longFormInsteadOfOpen: true,
              parenthesesInsteadOfDots: true,
            })}
          </b>{' '}
          level <b>{hit.disciplineName}</b> riders
          {correctlyTypeRange(hit.skills).length ? (
            <>
              {' '}
              about <b>{arrayToCommaAndString(correctlyTypeRange(hit.skills))}</b>
            </>
          ) : null}
          .
        </>
      );
    }
    default:
      return '';
  }
};

/**
 * Get the hostname for a hit
 * @param hit
 */
const getHostText = (hit: AlgoliaSearchResultHit): string => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
    case SearchTypeEnum.Clinic:
    case SearchTypeEnum.Ride:
    case SearchTypeEnum.Race:
      return hit.hostName || hit.organizerName;
    case SearchTypeEnum.Coach:
      return hit.coachTagline;
    case SearchTypeEnum.PrivateLesson:
      return hit.hostName || hit.coachName;
    default:
      return '';
  }
};

/**
 * Get the location for a hit
 * @param hit
 */
const getPriceText = (hit: AlgoliaSearchResultHit): string => {
  const prices = hit.entryFees || hit.prices || [hit.price];
  return numberArrayToPriceRange(prices, hit.currencyCode);
};

/**
 * Get the reviews for a hit
 */
const getReviews = (
  hit: AlgoliaSearchResultHit,
  classes: ReturnType<typeof useStyles>,
): ReactNode => {
  if (hit.activity !== 'Coach' && hit.activity !== 'Clinic') {
    return undefined;
  }

  if (hit.reviewCount >= 3) {
    return (
      <span>
        {hit.reviewRecPct}% <ThumbUpAltIcon className={classes.thumbsUpIcon} />
      </span>
    );
  }
  return <span className="strong">New</span>;
};

/**
 * Get the description for a hit
 * @param hit
 */
const getContent = (hit: AlgoliaSearchResultHitAlgoliaSearchResultHit): string | ReactNode => {
  switch (hit.activity) {
    case SearchTypeEnum.OnlineCourse:
      return hit.description;
    case SearchTypeEnum.Ride: {
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {hit.fullAddress}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {hit.rideType}
            {hit.hasAverageSpeed && (
              <>
                {' '}
                - {hit.averageSpeedMin} to {hit.averageSpeedMax} {hit.speedType}
              </>
            )}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {hit.abilityLevelRange
              ? getAbilityLabelFull({
                  abilityLevelRange: [hit.abilityMin, hit.abilityMax],
                  longFormInsteadOfOpen: true,
                })
              : ''}
          </Typography>
        </>
      );
    }
    case SearchTypeEnum.Clinic: {
      const whoITeachText = `${arrayToCommaAndString(hit?.focus ?? [])} · ${getAgeText([
        hit.ageMin,
        hit.ageMax,
      ])}${
        hit.genders?.[0] !== GenderEnum.Open
          ? ` · ${arrayToCommaAndString(hit.genders)}`
          : ''
      }`;

      let amenitiesText = 'No Amenities';
      if (RA.isNotNilOrEmpty(hit.amenities)) {
        if (hit.amenities.length > 4) {
          amenitiesText = `${hit.amenities.slice(0, 2).join(' · ')} and ${
            hit.amenities.slice(3).length
          } more amenities`;
        } else {
          amenitiesText = hit.amenities.join(' · ');
        }
      }
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {hit.fullAddress}
          </Typography>
          <Tooltip title="Which students will be most successful with this coach?">
            <Typography color="textSecondary" variant="body2">
              {whoITeachText}
            </Typography>
          </Tooltip>
          <Typography color="textSecondary" variant="body2">
            {hit.abilityLevelRange
              ? getAbilityLabelFull({
                  abilityLevelRange: [hit.abilityMin, hit.abilityMax],
                  longFormInsteadOfOpen: true,
                })
              : ''}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {amenitiesText}
          </Typography>
        </>
      );
    }
    case SearchTypeEnum.Race: {
      let amenitiesText = 'No Amenities';
      if (RA.isNotNilOrEmpty(hit.amenities)) {
        if (hit.amenities.length > 4) {
          amenitiesText = `${hit.amenities.slice(0, 2).join(' · ')} and ${
            hit.amenities.slice(3).length
          } more amenities`;
        } else {
          amenitiesText = hit.amenities.join(' · ');
        }
      }
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {hit.fullAddress}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {hit.abilityLevelRange
              ? getAbilityLabelFull({
                  abilityLevelRange: [hit.abilityMin, hit.abilityMax],
                  longFormInsteadOfOpen: true,
                })
              : ''}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {amenitiesText}
          </Typography>
        </>
      );
    }
    case SearchTypeEnum.Coach: {
      const coachLocationText = getCoachLocationText(hit);
      const whoITeachText = `${arrayToCommaAndString(hit.coachFocus)} · ${getAgeText([
        hit.ageMin,
        hit.ageMax,
      ])}${
        hit.coachGenders[0] !== GenderEnum.Open
          ? ` · ${arrayToCommaAndString(hit.coachGenders)}`
          : ''
      }`;
      return (
        <>
          <Typography style={{ wordBreak: 'break-word' }} variant="body2">
            {hit.coachTagline}
          </Typography>
          <Tooltip title="Which students will be most successful with this coach?">
            <Typography color="textSecondary" variant="body2">
              {whoITeachText}
            </Typography>
          </Tooltip>
          <Tooltip title="Where is this coach based?">
            <Typography color="textSecondary" variant="body2">
              {coachLocationText}
            </Typography>
          </Tooltip>
        </>
      );
    }
    case SearchTypeEnum.PrivateLesson: {
      const coachSummaryText = getCoachingSummary({
        coachingVibe: hit.coachingVibe,
        coachDisciplines: hit.coachDisciplines,
      });
      const coachLocationText = getCoachLocationText(hit);
      const isRecurring = hit.recurrenceType === LessonRecurrenceTypeEnum.Recurring;
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {hit.coachName}
          </Typography>
          <Tooltip title="How does this coach like to teach?">
            <Typography color="textSecondary" variant="body2">
              {coachSummaryText}
            </Typography>
          </Tooltip>
          <Tooltip title="Where is this coach based?">
            <Typography color="textSecondary" variant="body2">
              {coachLocationText}
            </Typography>
          </Tooltip>
          {hit.isVirtual && (
            <Typography color="textSecondary" variant="body2">
              Virtual
              {(isRecurring || hit.isBundle) &&
                ` · ${
                  isRecurring
                    ? `Recurring ${hit.recurringInterval}ly`
                    : `${hit.lessonsPerBundle} Lesson Bundle`
                }`}
            </Typography>
          )}
        </>
      );
    }
    default:
      return '';
  }
};

const useStyles = makeStyles(() => ({
  thumbsUpIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
  },
}));

interface SearchResultHitProps {
  hit: AlgoliaSearchResultHit;
  onlyMobile?: boolean;
  sendEvent: SendEventForHits;
}
const SearchResultHit: React.FC<SearchResultHitProps> = ({ hit, onlyMobile, sendEvent }) => {
  const classes = useStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const { trackSearchHitClick } = useSegmentTrack();

  const url = hit.onlineCourseUrl || hit.lessonUrl || hit.coachUrl || hit.eventUrl;
  // eslint-disable-next-line no-underscore-dangle
  const hitLink = addQueryParamsToUrl(url, { queryId: hit.__queryID });
  const imageUrls = getHitImageUrls(hit);
  const titleText = hit.name || hit.coachName;
  const priceText = getPriceText(hit);
  const subtitleText = getHitSubtitleText(hit);
  const hostText = getHostText(hit);
  const reviews = getReviews(hit, classes);
  const content = getContent(hit);

  const sendClickEvent = useCallback(
    (eventName: string): void => {
      trackSearchHitClick({ hit });
      sendEvent('click', hit, eventName);
    },
    [sendEvent],
  );

  return (
    <>
      {isSmallScreenDown || onlyMobile ? (
        <MobileHit
          hit={hit}
          sendClickEvent={(): void => sendClickEvent(ALGOLIA_SEARCH_HIT_CLICKED)}
        />
      ) : (
        <DesktopHit
          hit={hit}
          sendClickEvent={(): void => sendClickEvent(ALGOLIA_SEARCH_HIT_CLICKED)}
        />
      )}
    </>
  );
};

export default SearchResultHit;
