import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react';
import { useMount } from 'react-use';
import { BoxProps, PopoverProps, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { PopoverActions } from '@material-ui/core/Popover/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import ExtendedButton from '@/ui/Button/ExtendedButton';

import { useIsSmallScreenDown } from '@/lib/ui-utils';

export const useEditablePopoverWrapperStyles = makeStyles((theme: Theme) => ({
  popoverContainer: {
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    // padding: theme.spacing(1),
    width: '100%',
  },
  hoverStyles: {
    border: '1px solid transparent',
    borderRadius: theme.spacing(0.5),
    backgroundColor: 'rgba(59, 65, 68, 0.04)',
    textDecoration: 'none',
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '& > svg': {
      // display: 'none',
      display: 'block',
      fontSize: '1rem',
      margin: 'auto',
      position: 'absolute',
      right: 0,
      // top: 'calc(50% - 2px)',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  hoverStylesOnHover: {
    border: '1px solid #dcdee1',
  },
  disabled: {
    pointerEvents: 'none',
    '& > p': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  disabledUnderline: {
    borderBottomStyle: 'dotted',
  },
}));

export type EditablePopoverWrapperCloseType =
  | 'parentFunctionCall'
  | 'set'
  | 'cancel'
  | 'clickOutside';
interface EditablePopoverWrapperProps {
  // If we need to hook into popover actions. i.e. close, open, etc
  action?: React.Ref<PopoverActions>;
  // Function to call on "Cancel"
  cancelFunction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // Title for the "Cancel" button
  cancelButtonTitle?: string;
  // Component that we click to open the popover
  componentToWrap: ReactNode;
  // Does a click outside close the popover?
  disableBackdropClick?: boolean;
  // When we hover over the 'componentToWrap' do we show a endAdorment?
  hoverIconComponent?: ReactElement;
  // Can we open the popover?
  isDisabled?: boolean;
  // Remove the dotted underline
  shouldHideDisabledStyles?: boolean;
  // Component that is inside the popover
  innerPopoverComponent: ReactNode | ((handleClose: () => void) => ReactNode);
  // Box props for the wrapper of the inner component
  innerPopoverComponentWrapperProps?: BoxProps;
  // MaxWidth of the inner popover
  maxWidthPopoverWidth?: BoxProps['width'];
  // Should we add extra styles to the wrapped component on hover?
  shouldUseHoverStyles?: boolean;
  // Extra function to call on close
  onClose?: (closeType: EditablePopoverWrapperCloseType) => void;
  // Extra function to call on open
  onOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // Popover props
  popoverProps?: Partial<PopoverProps>;
  // Margin at the top of the set button
  setButtonMarginTop?: number;
  // Title for the "Set" button
  setButtonTitle?: string;
  // Function to call on "Set"
  setFunction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // Lift the popover close function up to the parent
  setPopoverCloseFunction?: Dispatch<SetStateAction<(() => () => void) | null>>;
  // Is the set button disabled?
  isSetButtonDisabled?: boolean;
  // Should we hide the cancel button?
  shouldHideCancelButton?: boolean;
  // Hide all buttons
  shouldHideAllButtons?: boolean;
  // Outer wrapper props
  wrapperBoxProps?: BoxProps;
}
const EditablePopoverWrapper: React.FC<EditablePopoverWrapperProps> = ({
  action,
  cancelButtonTitle = 'Cancel',
  cancelFunction,
  componentToWrap,
  disableBackdropClick = true,
  hoverIconComponent,
  innerPopoverComponent,
  innerPopoverComponentWrapperProps,
  isDisabled,
  isSetButtonDisabled,
  maxWidthPopoverWidth = 500,
  onClose,
  onOpen,
  popoverProps,
  setButtonMarginTop,
  setButtonTitle = 'Set',
  setFunction,
  setPopoverCloseFunction,
  shouldHideAllButtons = false,
  shouldHideCancelButton,
  shouldHideDisabledStyles,
  shouldUseHoverStyles,
  wrapperBoxProps,
}) => {
  const classes = useEditablePopoverWrapperStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
    if (onOpen) {
      onOpen(e);
    }
  };
  const handleClosePopover = (closeType: EditablePopoverWrapperCloseType): void => {
    setAnchorEl(null);
    if (onClose) {
      onClose(closeType);
    }
  };
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (cancelFunction) {
      cancelFunction(e);
    }
    handleClosePopover('cancel');
  };

  const handleSet = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (setFunction) {
      setFunction(e);
    }
    handleClosePopover('set');
  };

  useMount(() => {
    if (setPopoverCloseFunction) {
      setPopoverCloseFunction(() => () => handleClosePopover('parentFunctionCall'));
    }
  });
  return (
    <>
      <Box
        className={classNames(classes.popoverContainer, {
          [classes.disabled]: isDisabled,
          [classes.disabledUnderline]: isDisabled && !shouldHideDisabledStyles,
          [classes.hoverStyles]: shouldUseHoverStyles,
          [classes.hoverStylesOnHover]: shouldUseHoverStyles && isHovered,
        })}
        onClick={handleOpenPopover}
        onMouseEnter={(): void => setIsHovered(true)}
        onMouseLeave={(): void => setIsHovered(false)}
        {...wrapperBoxProps}
      >
        {componentToWrap}
        {hoverIconComponent}
      </Box>
      {isPopoverOpen && (
        <Popover
          disablePortal
          action={action}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{ paper: 'nicePopover' }}
          disableBackdropClick={disableBackdropClick}
          onClose={(): void => handleClosePopover('clickOutside')}
          open={isPopoverOpen && !isDisabled}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...popoverProps}
        >
          <Box maxWidth={maxWidthPopoverWidth} width={isSmallScreenDown ? 290 : undefined}>
            <Box display="flex" {...innerPopoverComponentWrapperProps}>
              {innerPopoverComponent}
            </Box>
            {!shouldHideAllButtons && (
              <Box display="flex" justifyContent="space-evenly" mt={setButtonMarginTop ?? 2}>
                {!shouldHideCancelButton && (
                  <ExtendedButton
                    minWidth="tiny"
                    onClick={handleCancel}
                    size="small"
                    variant="outlined"
                  >
                    {cancelButtonTitle}
                  </ExtendedButton>
                )}
                <ExtendedButton
                  color="primary"
                  disabled={isSetButtonDisabled}
                  minWidth="tiny"
                  onClick={handleSet}
                  size="small"
                  variant="contained"
                >
                  {setButtonTitle}
                </ExtendedButton>
              </Box>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default EditablePopoverWrapper;
