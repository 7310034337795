/**
 * Fade component in and out on condition
 */
import React from 'react';
import { animated, useTransition } from 'react-spring';
import { Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';

import { ReactFCC } from '@/lib/type-defs/utility';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: FadeComponentProps): CreateCSSProperties<FadeComponentProps> => ({
    display: props.display ?? '',
    margin: props.m ? (props.m === 'auto' ? 'auto' : theme.spacing(props.m)) : '',
    marginTop: props.mt ? theme.spacing(props.mt) : '',
    marginRight: props.mr ? theme.spacing(props.mr) : '',
    marginBottom: props.mb ? theme.spacing(props.mb) : '',
    marginLeft: props.ml ? theme.spacing(props.ml) : '',
    position: props.position ?? 'initial',
    width: props.width ?? '',
  }),
}));

interface FadeComponentProps {
  display?: 'flex' | 'block';
  duration?: number;
  // Margin. Use the shorthand to be consistent with our use with Box
  m?: number | 'auto';
  mb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
  position?: 'absolute' | 'relative';
  shouldExpand?: number;
  shouldFade: boolean;
  shouldSlide?: boolean;
  tension?: number;
  width?: string;
}
const FadeComponent: ReactFCC<React.HTMLAttributes<HTMLDivElement> & FadeComponentProps> = (
  componentProps,
) => {
  const {
    children,
    duration,
    shouldExpand,
    shouldFade,
    shouldSlide,
    tension = 170,
  } = componentProps;
  const classes = useStyles(componentProps);
  const transition = useTransition(shouldFade, {
    from: {
      opacity: 0,
      transform: shouldSlide ? 'translateY(-20px)' : '',
      maxHeight: shouldExpand ? 0 : 'auto',
    },
    enter: {
      opacity: 1,
      transform: shouldSlide ? 'translateY(0px)' : '',
      maxHeight: shouldExpand || 'auto',
    },
    leave: {
      opacity: 0,
      transform: shouldSlide ? 'translateY(-20px)' : '',
      maxHeight: shouldExpand ? 0 : 'auto',
    },
    config: {
      duration,
      tension,
    },
  });

  return (
    <>
      {transition(
        (springProps, item) =>
          item && (
            <animated.div
              className={classnames(classes.root, componentProps.className)}
              style={springProps}
            >
              {children}
            </animated.div>
          ),
      )}
    </>
  );
};

export default FadeComponent;
