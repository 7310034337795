import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';

import LazyloadComponentWithRetry from '@/hoc/LazyloadComponentWithRetry';

import ExtendedRoute from '../components/ExtendedRoute';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import FullScreenLoadingPlaceholder from '@/components/LoadingPlaceholder/FullScreenLoadingPlaceholder';

import { AppRouteService } from '../RouteService';

import { RegistrationLayoutProvider } from '@/layouts/RegistrationLayoutProvider';
import PageNotFound from '@/pages/ErrorPages/PageNotFound';
import OrganizerProfilePage from '@/pages/Profile/OrganizerProfilePage';
import EventProfilePage from '@/pages/EventProfile/EventProfilePage';
import RegistrationPage from '@/pages/EventProfile/RegistrationPage';
import EventProfileProvider from '@/providers/EventProfileProvider';

const EventProfileView = LazyloadComponentWithRetry(
  () =>
    import(/* webpackChunkName: "EventProfileView" */ '@/containers/EventProfile/EventProfileView'),
);
const ProfileView = LazyloadComponentWithRetry(
  () => import(/* webpackChunkName: "ProfileView" */ '@/containers/OrganizerProfile/ProfileView'),
);
const RegistrationLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegistrationLoader" */ '@/containers/Registration/RegistrationLoader'
    ),
);
const SeriesDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "SeriesDetailLoader" */ '@/containers/OrganizerProfile/SeriesDetail/SeriesDetailLoader'
    ),
);
const OnlineCoursesLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCoursesLoader" */ '@/containers/OrganizerProfile/OnlineCourses/OnlineCoursesLoader'
    ),
);

const OnlineCourseDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseDetail/OnlineCourseDetailLoader'
    ),
);
const OnlineCourseOverviewDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseOverviewDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseOverviewDetail/OnlineCourseOverviewDetailLoader'
    ),
);
const OnlineCourseSectionDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseSectionDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseSectionDetail/OnlineCourseSectionDetailLoader'
    ),
);
const OnlineCourseLessonDetailLoader = LazyloadComponentWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnlineCourseLessonDetailLoader" */ '@/containers/OnlineCourse/OnlineCourseLessonDetail/OnlineCourseLessonDetailLoader'
    ),
);

const OrganizerProfileRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <ExtendedRoute trackPageView>
            <OrganizerProfilePage>
              <ProfileView />
            </OrganizerProfilePage>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterRoutePath('OrganizerProfile')}
      />

      <Route
        element={
          <ExtendedRoute>
            <Routes>
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <Navigate replace to={AppRouteService.getRouterRoutePath('OrganizerProfile')} />
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_Series')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <OrganizerProfilePage>
                      <SeriesDetailLoader />
                    </OrganizerProfilePage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_SeriesDetail')}
              />

              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <PageNotFound />
                  </ExtendedRoute>
                }
                path="*"
              />
            </Routes>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('OrganizerProfile_Series')}
      />

      <Route
        element={
          <ExtendedRoute>
            <Routes>
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <Suspense fallback={<FullScreenLoadingPlaceholder />}>
                      <EventProfileProvider>
                        <EventProfilePage>
                          <EventProfileView />
                        </EventProfilePage>
                      </EventProfileProvider>
                    </Suspense>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_Event')}
              />
              <Route
                element={
                  <ExtendedRoute blockWithSignup loginRequired loginViaDialog trackPageView>
                    <RegistrationLayoutProvider>
                      <RegistrationPage>
                        <RegistrationLoader />
                      </RegistrationPage>
                    </RegistrationLayoutProvider>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_Event_Registration')}
              />

              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <PageNotFound />
                  </ExtendedRoute>
                }
                path="*"
              />
            </Routes>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('OrganizerProfile_Event')}
      />

      <Route
        element={
          <ExtendedRoute>
            <Routes>
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <Suspense
                      fallback={
                        <LoadingPlaceholder absoluteCenter disableShrink isLoading delay={200} />
                      }
                    >
                      <OrganizerProfilePage>
                        <OnlineCoursesLoader />
                      </OrganizerProfilePage>
                    </Suspense>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_OnlineCourses')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <OrganizerProfilePage>
                      <OnlineCourseDetailLoader />
                    </OrganizerProfilePage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath('OrganizerProfile_OnlineCourses_Detail')}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <OrganizerProfilePage>
                      <OnlineCourseOverviewDetailLoader />
                    </OrganizerProfilePage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath(
                  'OrganizerProfile_OnlineCourses_OverviewDetail',
                )}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <OrganizerProfilePage>
                      <OnlineCourseSectionDetailLoader />
                    </OrganizerProfilePage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath(
                  'OrganizerProfile_OnlineCourses_SectionDetail',
                )}
              />
              <Route
                element={
                  <ExtendedRoute trackPageView>
                    <OrganizerProfilePage>
                      <OnlineCourseLessonDetailLoader />
                    </OrganizerProfilePage>
                  </ExtendedRoute>
                }
                path={AppRouteService.getRouterRoutePath(
                  'OrganizerProfile_OnlineCourses_LessonDetail',
                )}
              />
            </Routes>
          </ExtendedRoute>
        }
        path={AppRouteService.getRouterDirectoryPath('OrganizerProfile_OnlineCourses')}
      />

      {/* 404 page */}
      <Route
        element={
          <ExtendedRoute trackPageView>
            <PageNotFound />
          </ExtendedRoute>
        }
        path="*"
      />
    </Routes>
  );
};

export default OrganizerProfileRoutes;
