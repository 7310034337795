import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Inbox } from '@trycourier/react-inbox';
import { Toast } from '@trycourier/react-toast';

import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useCourierContext } from '@/providers/CourierProvider';
import { Colors } from '@/themes/colors';

const brand = {
  colors: {
    primary: Colors.DarkBlue,
    secondary: Colors.White,
  },
};
const toastTheme = {
  progressBar: {
    backgroundColor: Colors.DarkBlue,
  },
  message: {
    actionBlock: {
      '& a': {
        backgroundColor: Colors.DarkBlue,
        '&:hover': {
          color: Colors.White,
          backgroundColor: '#195861',
        },
      },
    },
  },
};
const inboxTheme = {
  icon: {
    '& path': {
      fill: Colors.DarkBlue,
    },
  },
  container: {
    backgroundColor: 'white',
    padding: '0px',
  },
  messageList: {
    container: {
      overflow: 'auto',
      // height: 'Calc(100vh - 191px)',
      // maxHeight: 'Calc(100vh - 191px)',
    },
  },
  message: {
    container: {
      '&:not(.read):hover': {
        backgroundColor: '#edeff0',
      },
      '&.read': {
        backgroundColor: 'white',
      },
    },
    actionBlock: {
      '& a': {
        '&:hover': {
          color: Colors.White,
          backgroundColor: '#195861',
        },
      },
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  inbox: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.between(481, 'sm')]: {
      '& div[class=""],div:not([class])': {
        transform: 'none !important',
        width: '100vw',
        position: 'fixed',
        left: '0 !important',
        top: '12px !important',
        '& > div': {
          width: '100%',
        },
      },
    },
  },
}));

const CourierNotifications: React.FC = () => {
  const classes = useStyles();
  const { isInitiated } = useCourierContext();
  const isSmallScreenDown = useIsSmallScreenDown();

  if (!isInitiated) {
    return null;
  }

  const combinedInboxTheme = {
    ...inboxTheme,
    ...(isSmallScreenDown
      ? {
          header: {
            marginTop: '40px',
          },
        }
      : {}),
  };
  return (
    <div>
      <Toast
        brand={brand}
        config={{ openLinksInNewTab: false }}
        defaultIcon={false}
        theme={toastTheme}
      />
      <div className={classes.inbox}>
        <Inbox
          showUnreadMessageCount
          brand={brand}
          defaultIcon={false}
          openLinksInNewTab={false}
          theme={combinedInboxTheme}
        />
      </div>
    </div>
  );
};

/**
 * Ignore slider errors
 */
class CourierErrorBoundary extends React.Component {
  componentDidCatch(): void {
    // console.log(error);
    // throw error;
  }

  render(): ReactNode {
    return <CourierNotifications />;
  }
}

export default CourierErrorBoundary;
