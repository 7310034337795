/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewServiceQueries_getRecentClinics_QueryVariables = {
    todayDate: string;
    twoWeeksAgoDate: string;
    userId: string;
};
export type ReviewServiceQueries_getRecentClinics_QueryResponse = {
    readonly registration_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly clinic: {
                    readonly cuid: string;
                    readonly clinicDays: ReadonlyArray<{
                        readonly startTime: string;
                    }>;
                } | null;
                readonly event: {
                    readonly eventMetadata: {
                        readonly cuid: string;
                        readonly name: string;
                    };
                    readonly eventImages: ReadonlyArray<{
                        readonly image: {
                            readonly relativeUrl: string;
                        };
                    }>;
                };
            };
        }>;
    };
};
export type ReviewServiceQueries_getRecentClinics_Query = {
    readonly response: ReviewServiceQueries_getRecentClinics_QueryResponse;
    readonly variables: ReviewServiceQueries_getRecentClinics_QueryVariables;
};



/*
query ReviewServiceQueries_getRecentClinics_Query(
  $todayDate: timestamp!
  $twoWeeksAgoDate: timestamp!
  $userId: String!
) {
  registration_connection(where: {userId: {_eq: $userId}, clinic: {canceledAt: {_is_null: true}, _not: {clinicDays: {startTime: {_gt: $todayDate}}}, clinicDays: {startTime: {_gte: $twoWeeksAgoDate}}}}) {
    edges {
      node {
        clinic {
          cuid
          clinicDays(order_by: {startTime: asc}, limit: 1) {
            startTime
            id
          }
          id
        }
        event {
          eventMetadata {
            cuid
            name
            id
          }
          eventImages(where: {position: {_eq: 1}}) {
            image {
              relativeUrl
              id
            }
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "todayDate"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "twoWeeksAgoDate"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "fields": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "_gt",
                                                    "variableName": "todayDate"
                                                }
                                            ],
                                            "kind": "ObjectValue",
                                            "name": "startTime"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "clinicDays"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "_not"
                        },
                        {
                            "kind": "Literal",
                            "name": "canceledAt",
                            "value": {
                                "_is_null": true
                            }
                        },
                        {
                            "fields": [
                                {
                                    "fields": [
                                        {
                                            "kind": "Variable",
                                            "name": "_gte",
                                            "variableName": "twoWeeksAgoDate"
                                        }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "startTime"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "clinicDays"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "clinic"
                },
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "userId"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "userId"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cuid",
        "storageKey": null
    } as any, v3 = [
        {
            "kind": "Literal",
            "name": "limit",
            "value": 1
        } as any,
        {
            "kind": "Literal",
            "name": "order_by",
            "value": {
                "startTime": "asc"
            }
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTime",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = [
        {
            "kind": "Literal",
            "name": "where",
            "value": {
                "position": {
                    "_eq": 1
                }
            }
        } as any
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relativeUrl",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ReviewServiceQueries_getRecentClinics_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "clinic",
                                            "kind": "LinkedField",
                                            "name": "clinic",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": (v3 /*: any*/),
                                                    "concreteType": "clinicDay",
                                                    "kind": "LinkedField",
                                                    "name": "clinicDays",
                                                    "plural": true,
                                                    "selections": [
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": "clinicDays(limit:1,order_by:{\"startTime\":\"asc\"})"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v6 /*: any*/),
                                                    "concreteType": "eventImage",
                                                    "kind": "LinkedField",
                                                    "name": "eventImages",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "image",
                                                            "kind": "LinkedField",
                                                            "name": "image",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "eventImages(where:{\"position\":{\"_eq\":1}})"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ReviewServiceQueries_getRecentClinics_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "registrationConnection",
                    "kind": "LinkedField",
                    "name": "registration_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "registrationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "registration",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "clinic",
                                            "kind": "LinkedField",
                                            "name": "clinic",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": (v3 /*: any*/),
                                                    "concreteType": "clinicDay",
                                                    "kind": "LinkedField",
                                                    "name": "clinicDays",
                                                    "plural": true,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": "clinicDays(limit:1,order_by:{\"startTime\":\"asc\"})"
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "event",
                                            "kind": "LinkedField",
                                            "name": "event",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "eventMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "eventMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v6 /*: any*/),
                                                    "concreteType": "eventImage",
                                                    "kind": "LinkedField",
                                                    "name": "eventImages",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "image",
                                                            "kind": "LinkedField",
                                                            "name": "image",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": "eventImages(where:{\"position\":{\"_eq\":1}})"
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d49e3b188e8352a80dc369bd38fe7a77",
            "id": null,
            "metadata": {},
            "name": "ReviewServiceQueries_getRecentClinics_Query",
            "operationKind": "query",
            "text": "query ReviewServiceQueries_getRecentClinics_Query(\n  $todayDate: timestamp!\n  $twoWeeksAgoDate: timestamp!\n  $userId: String!\n) {\n  registration_connection(where: {userId: {_eq: $userId}, clinic: {canceledAt: {_is_null: true}, _not: {clinicDays: {startTime: {_gt: $todayDate}}}, clinicDays: {startTime: {_gte: $twoWeeksAgoDate}}}}) {\n    edges {\n      node {\n        clinic {\n          cuid\n          clinicDays(order_by: {startTime: asc}, limit: 1) {\n            startTime\n            id\n          }\n          id\n        }\n        event {\n          eventMetadata {\n            cuid\n            name\n            id\n          }\n          eventImages(where: {position: {_eq: 1}}) {\n            image {\n              relativeUrl\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '10d8e4176d3e9016a2bcb40c442b8fc8';
export default node;
