/*
 * Stripe utilities
 * Gets synced client/server
 */
import * as R from 'ramda';
import Stripe from 'stripe';

import { roundToNearestHundredth } from './math-utils';

export const SERVICE_FEE_NAME = 'Payment Processing & Platform Fee';
export const SERVICE_FEE_NON_REFUNDABLE = 'Non-refundable';
export const STRIPE_PAYOUT_WEEKLY_ANCHOR = 'wednesday';
export const STRIPE_MINIMUM_REFUND_AMOUNT_IN_CENTS = 50;

export enum StripeConnectOwnerType {
  Coach = 'Coach',
  Organizer = 'Organizer',
}

// Reggy Take Rates
export const REGGY_TAKE_PERCENTAGE = 0.1;
export const REGGY_TAKE_FLAT_AMOUNT_IN_DOLLARS = 1;
export const ONE_DOLLAR_IN_CENTS = 100;

/**
 * Calculate the Reggy Fee from a dollar amount
 */
export const calculateServiceFeeInDollars = (amount: number): number => {
  const fee = amount * REGGY_TAKE_PERCENTAGE + REGGY_TAKE_FLAT_AMOUNT_IN_DOLLARS;
  return roundToNearestHundredth(fee);
};

/**
 * Calculate the Reggy Fee from a dollar amount
 * Subscriptions only take percentages so we need to get the service fee as a percentage of price
 */
export const calculateServiceFeeInPercentage = (
  priceInCents: number,
  serviceFeeInCents: number,
): number => {
  return Math.round(parseFloat((serviceFeeInCents / priceInCents).toFixed(10)) * 100);
};

/**
 * Convert dollars to cents
 */
export const convertDollarsToCents = (amount: number): number => {
  return Math.round(amount * ONE_DOLLAR_IN_CENTS);
};

/**
 * Convert cents to dollars
 */
export const convertCentsToDollars = (amount: number): number => {
  return Math.round(parseFloat((amount / ONE_DOLLAR_IN_CENTS).toFixed(10)) * 100) / 100;
};

// const getStripeAccountRequirements = (): void => {
//   const requirements = {
//     alternatives: [],
//     current_deadline: null,
//     currently_due: ['external_account', 'tos_acceptance.date', 'tos_acceptance.ip'],
//     disabled_reason: 'requirements.past_due',
//     errors: [],
//     eventually_due: ['external_account', 'tos_acceptance.date', 'tos_acceptance.ip'],
//     past_due: ['external_account', 'tos_acceptance.date', 'tos_acceptance.ip'],
//     pending_verification: [],
//   };
// };

/**
 * Does a stripe account have charges and payouts enabled
 */
export const getIsStripeConnectAccountValid = (
  stripeConnectAccount: { payoutsEnabled: boolean; chargesEnabled: boolean } | undefined | null,
): boolean => {
  if (!stripeConnectAccount) {
    return false;
  }
  return stripeConnectAccount?.payoutsEnabled && stripeConnectAccount?.chargesEnabled;
};

/**
 * Does this Stripe account have any available or pending payouts across all currencies
 */
export const hasAccountBalance = (accountBalance: Stripe.Balance): boolean => {
  const available = R.sum(accountBalance.available.map(({ amount }) => amount));
  const pending = R.sum(accountBalance.pending.map(({ amount }) => amount));
  return Boolean(pending + available);
};
