import { Theme } from '@material-ui/core';
import { darken, fade } from '@material-ui/core/styles';

import { Colors } from '../colors';

export const MuiButton = (theme: Theme): Record<string, unknown> => ({
  contained: {
    boxShadow: 'none',
    // boxShadow: '0 3px 10px -4px rgba(36, 50, 66, 0.5), 0 6px 7px -7px #518090f0',
    whiteSpace: 'nowrap',
    '&$focusVisible': {
      // boxShadow: '0 3px 10px -4px rgba(36, 50, 66, 0.1), 0 7px 7px -7px #518090b0',
    },
    '&:active': {
      // boxShadow: '0 3px 10px -4px rgba(36, 50, 66, 0.1), 0 7px 7px -7px #518090b0',
    },
    '&:hover': {
      boxShadow: 'none',
      // boxShadow: '0 3px 10px -4px rgba(36, 50, 66, 0.2), 0 6px 7px -7px #518090b0',
    },
    '&.errorColor:not(.Mui-disabled)': {
      // boxShadow: '0 3px 10px -4px rgba(66, 41, 36, 0.5), 0 6px 7px -7px hsla(3, 55%, 44%, 0.94)',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
      '&:active': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    '&.editColor:not(.Mui-disabled)': {
      // boxShadow:
      //   '0 3px 10px -4px hsla(44, 58%, 38%, 0.75), 0 6px 7px -7px hsla(46, 67%, 34%, 0.41)',
      backgroundColor: Colors.Yellow,
      '&:hover': {
        backgroundColor: fade(Colors.Yellow, 0.9),
      },
      '&:active': {
        backgroundColor: darken(Colors.Yellow, 0.1),
      },
    },
  },
  containedPrimary: {
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
  containedSecondary: {
    // boxShadow:
    //   '0 3px 10px -4px hsla(147, 34%, 38%, 0.5), 0 6px 7px -7px hsla(147, 34%, 38%, 0.9411764705882353)',
    '&:active': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    fontWeight: '600',
    letterSpacing: '0.0367rem',
  },
  outlined: {
    padding: '8px 15px',
    backgroundColor: theme.palette.common.white,
    '&.errorColor:not(.Mui-disabled)': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    '&.editColor:not(.Mui-disabled)': {
      color: Colors.Yellow,
      borderColor: Colors.Yellow,
      backgroundColor: Colors.Yellow,
    },
    '&.Mui-disabled': {
      boxShadow: 'none',
    },
    '& .MuiButton-startIcon': {
      paddingBottom: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
    },
  },
  root: {
    padding: '9px 16px',
    // fontFamily: 'proxima-nova',
    '&:focus': {
      // outline: '-webkit-focus-ring-color auto 1px',
      // outlineOffset: '-3px !important',
      // outlineColor: '#e59700 !important',
    },
    textTransform: 'none',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  startIcon: {
    paddingBottom: '2px',
    '&.MuiButton-iconSizeSmall': {
      marginRight: '6px',
      paddingBottom: '0px',
    },
  },
  sizeSmall: {
    '& .MuiButton-endIcon': {
      marginLeft: theme.spacing(0.5),
    },
  },
  sizeLarge: {
    // minWidth: '205px',
    minWidth: '229px',
    padding: '10px 22px',
    fontSize: '1.0625rem',
  },
  text: {
    padding: '9px 16px',
    '& .MuiButton-label': {
      // alignItems: 'end',
    },
    '&.errorColor:not(.Mui-disabled)': {
      color: theme.palette.error.main,
      // borderColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: 'rgb(192 94 89 / 0.08)',
      },
    },
    '&.editColor:not(.Mui-disabled)': {
      color: Colors.Yellow,
      // borderColor: Colors.Yellow,
      backgroundColor: Colors.Yellow,
    },
  },
});
