import { Theme } from '@material-ui/core';

export const MuiListItem = (theme: Theme): Record<string, unknown> => ({
  button: {
    '&:hover': {
      backgroundColor: 'rgba(54, 64, 76, 0.11)',
    },
  },
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(54, 64, 76, 0.21)',
    },
  },
});