import React, { createContext, useContext, useEffect, useState } from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

import { useTrackSentryError } from '@/analytics/sentry';
import ENV from '@/env';

const SegmentAnalyticsContext = createContext<Analytics | undefined>(undefined);

interface SegmentAnalyticsProviderProps {
  children: React.ReactNode;
}
export const SegmentAnalyticsProvider: React.FC<SegmentAnalyticsProviderProps> = ({ children }) => {
  const WRITE_KEY = ENV.SEGMENT_WRITE_KEY;
  const trackSentryError = useTrackSentryError();
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  const loadAnalytics = async (): Promise<void> => {
    if (!WRITE_KEY || analytics) {
      return;
    }
    const [response] = await AnalyticsBrowser.load({ writeKey: WRITE_KEY });
    setAnalytics(response);
  };

  useEffect(() => {
    loadAnalytics()
      .then()
      .catch((err) => {
        trackSentryError(err);
      });
  }, [WRITE_KEY]);

  return (
    <SegmentAnalyticsContext.Provider value={analytics}>
      {children}
    </SegmentAnalyticsContext.Provider>
  );
};

export const useSegmentAnalyticsContext = (): Analytics | undefined =>
  useContext(SegmentAnalyticsContext);
