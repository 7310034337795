/**
 * Styles for the LogIn and Signup pages/dialogs
 */
import { makeStyles } from '@material-ui/core/styles';

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.grey[50],
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up('sm')]: {
      padding: `36px`,
      borderRadius: '6px',
      maxWidth: '648px',
    },
  },
  paperMinHeight: {
    // minHeight: 630,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
  },
  svg: {
    fontSize: theme.spacing(4),
  },
}));

export const useLoginSignupStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    minHeight: '100%',
    minWidth: '100vw',
    padding: '4%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
  container: {
    borderRadius: '6px',
    boxShadow: '0 37.125px 70px -12.125px rgba(0,0,0,0.7)',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: `648px`,
    minHeight: '549px',
    padding: '36px',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0 !important',
      boxShadow: 'none !important',
      padding: `${theme.spacing(3)}px 0 !important`,
      minHeight: '100vh',
    },
  },
}));
