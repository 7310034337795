/**
 * Container page for organizer app
 */
import React from 'react';
import { useLocation } from 'react-router';

import Page from '../Page';

import OrganizerLayout from '@/layouts/OrganizerLayout';
import { useTypedParams } from '@/lib/path-helpers/routing';
import { ReactFCC } from '@/lib/type-defs/utility';
import { AppRouteService } from '@/routes/RouteService';

const OrganizerPage: ReactFCC = ({ children }) => {
  const location = useLocation();
  const { organizerSlug } = useTypedParams(['organizerSlug']);
  const messagesUrl = AppRouteService.getRelativeUrl('OrganizerApp_Messages', { organizerSlug });

  // Messages page shouldn't have any padding around it, and it should take up the whole view
  return (
    <Page noIndex title="Organizer • Reggy">
      <OrganizerLayout
        key={organizerSlug}
        removeContainer={location.pathname.includes(messagesUrl)}
      >
        {children}
      </OrganizerLayout>
    </Page>
  );
};

export default OrganizerPage;
