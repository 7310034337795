/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type helpers_getEventSlug_event = {
    readonly eventMetadata: {
        readonly slug: string;
    };
    readonly " $refType": "helpers_getEventSlug_event";
};
export type helpers_getEventSlug_event$data = helpers_getEventSlug_event;
export type helpers_getEventSlug_event$key = {
    readonly " $data"?: helpers_getEventSlug_event$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"helpers_getEventSlug_event">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpers_getEventSlug_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "eventMetadata",
            "kind": "LinkedField",
            "name": "eventMetadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "event",
    "abstractKey": null
} as any;
(node as any).hash = '3fab10fa572ca8421235e7be063e2d59';
export default node;
