import { Theme } from '@material-ui/core';

export const MuiToolbar = (theme: Theme): Record<string, unknown> => ({
  root: {
    minHeight: `${theme.spacing(9)}px !important`,
    '&[class*="MTableToolbar-root"]': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    '& [class^="MTableToolbar-actions"]': {
      order: 0,
      width: '100%',
      '& span': {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        '& > .MuiIconButton-root:last-child': {
          margin: theme.spacing(2, 0),
          flexGrow: 1,
          display: 'flex',
          '& > span': {
            cursor: 'initial',
          },
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
          },
          [theme.breakpoints.down(506)]: {
            justifyContent: 'flex-start',
          },
        },
      },
      '& .MuiIconButton-root': {
        padding: 0,
        '&:hover': {
          // Material Table add button is always an IconButton. Remove the hover backgroundColor change
          backgroundColor: 'inherit',
        },
      },
    },
    '& [class*="MTableToolbar-spacer"]': {
      // order: 1,
      display: 'none',
    },
    '& [class*="MTableToolbar-searchField"]': {
      order: 2,
      paddingLeft: '0 !important',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  dense: {
    minHeight: `${theme.spacing(6)}px !important`,
  },
});
