/**
 * Coaching Menu item for header
 */
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { Menu, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import MenuListSubheader from '@/ui/MenuListSubheader';

import SetupCoach from '@/containers/User/UserAvatar/SetupCoach';
import { useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

export const NO_COACH_ANIMATE_DELAY = 5500;
export const useCoachingMenuStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '& .MuiListItem-root': {
      fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiMenu-paper': {
      width: 262,
    },
  },
  noPadding: {
    padding: 0,
    transition: `all 200ms ${NO_COACH_ANIMATE_DELAY}ms`,
  },
}));
const CoachingMenuItem: React.FC = () => {
  const classes = useCoachingMenuStyles();
  const auth = useAuth();
  const params = useParams();
  const location = useLocation();
  const [showSetup, setShowSetup] = useState(auth.user?.showSetupCoach);
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLButtonElement | null>(null);

  const showMenuItem = auth.user?.isCoach ? true : showSetup;
  const isCoachingOpen = Boolean(anchorMenuEl);
  const isOnCoachingPage =
    location.pathname.includes(
      AppRouteService.getRouterDirectoryPath('User_Business').replace('/*', ''),
    ) ||
    location.pathname.includes(
      AppRouteService.getRelativeUrl('CoachProfile', { coachSlug: auth.user?.slug ?? '' }),
    );
  const isOnOnlineCoursesPage =
    (params.coachSlug &&
      AppRouteService.matchesRoute('CoachProfile_OnlineCourses', {
        coachSlug: params.coachSlug,
      })) ||
    (params.coachSlug &&
      params.onlineCourseCuid &&
      AppRouteService.matchesRoute('CoachProfile_OnlineCourses_Detail', {
        coachSlug: params.coachSlug,
        onlineCourseCuid: params.onlineCourseCuid,
      }));

  /**
   * Don't hide right away so the user can see the message
   * set to true so if our auth.user?.showSetupCoach on load
   */
  useUpdateEffect(() => {
    if (!auth.user?.showSetupCoach) {
      const timeout = setTimeout(() => setShowSetup(false), NO_COACH_ANIMATE_DELAY + 200);
      return (): void => {
        clearTimeout(timeout);
      };
    }
    setShowSetup(true);
    return (): void => undefined;
  }, [auth.user?.showSetupCoach]);

  /**
   * If the user closes the menu before the "No problem" animation has finished
   */
  useUpdateEffect(() => {
    if (!auth.user?.showSetupCoach && !isCoachingOpen) {
      setShowSetup(false);
    }
  }, [isCoachingOpen]);

  const handleCoachingOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorMenuEl(e.currentTarget);
  };
  const handleCoachingClose = (): void => {
    setAnchorMenuEl(null);
  };

  if (!showMenuItem) {
    return null;
  }
  return (
    <>
      <ExtendedButton
        endIcon={<ExpandMoreIcon />}
        onClick={handleCoachingOpen}
        style={{ textDecoration: isOnCoachingPage ? 'underline' : undefined }}
      >
        Coaching
      </ExtendedButton>
      <Menu
        anchorEl={anchorMenuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classNames(classes.menuItem, { [classes.noPadding]: auth.user?.isCoach })}
        getContentAnchorEl={null}
        onClose={handleCoachingClose}
        open={isCoachingOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!auth.user?.isCoach ? (
          <SetupCoach handleCloseMenu={handleCoachingClose} />
        ) : (
          [
            <MenuListSubheader key="manage-header">Manage</MenuListSubheader>,
            <MenuItem
              component={NavLink}
              key={1}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_Dashboard')}
            >
              Dashboard
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={2}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_AllLessons')}
            >
              Lessons
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={3}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_Students')}
            >
              Students
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={4}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_Availability')}
            >
              Availability
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={5}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_Accounting')}
            >
              Accounting
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={6}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('User_Business_Analytics')}
            >
              Analytics
            </MenuItem>,
            <MenuListSubheader key="public-header">Public</MenuListSubheader>,
            <MenuItem
              component={NavLink}
              key={7}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive && !isOnOnlineCoursesPage ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('CoachProfile', { coachSlug: auth.user?.slug })}
            >
              Coach Profile
            </MenuItem>,
            <MenuItem
              component={NavLink}
              key={8}
              onClick={handleCoachingClose}
              style={({ isActive }): React.CSSProperties => ({
                color: 'inherit',
                fontWeight: isActive ? 'bold' : 'normal',
              })}
              to={AppRouteService.getRelativeUrl('CoachProfile_OnlineCourses', {
                coachSlug: auth.user?.slug,
              })}
            >
              Online Courses
            </MenuItem>,
          ]
        )}
      </Menu>
    </>
  );
};

export default CoachingMenuItem;
