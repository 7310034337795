/**
 * Re-Authenticate Dialog. Accessible from anywhere in the app
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Input, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Apple from '@material-ui/icons/Apple';
import CloseIcon from '@material-ui/icons/Close';
import Facebook from '@material-ui/icons/Facebook';
import classNames from 'classnames';

import ExtendedButton from '@/ui/Button/ExtendedButton';
import PasswordField from '@/ui/TextField/PasswordField';
import DisableToggle from '@/hoc/DisableToggle';

import {
  useGetIsReAuthDialogOpenGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsReAuthDialogOpenGlobal,
} from '@/containers/Auth/recoilStore';
import { useAuthButtonStyles } from '@/containers/Auth/styles';
import { Firebase, firebaseAuth, ProviderNames } from '@/lib/firebase';
import { LoginSchema } from '@/lib/form-schema/auth';
import { useIsSmallScreenDown } from '@/lib/ui-utils';
import { useDialogStyles } from '@/pages/Auth/styles/loginSignupStyles';
import { EmailPasswordProps, useAuth } from '@/providers/AuthProvider';

const ReAuthDialog: React.FC = () => {
  const dialogClasses = useDialogStyles();
  const isSmallScreenDown = useIsSmallScreenDown();
  const setIsReAuthDialogOpenGlobal = useSetIsReAuthDialogOpenGlobal();
  const isReAuthDialogOpenGlobal = useGetIsReAuthDialogOpenGlobal();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<EmailPasswordProps>({
    resolver: yupResolver(LoginSchema),
    reValidateMode: 'onSubmit',
  });
  const authButtonClasses = useAuthButtonStyles();
  const auth = useAuth();
  const location = useLocation();
  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const [currentProvider, setCurrentProvider] = useState();

  const { currentUser } = firebaseAuth;

  const isProviderApple = currentProvider === ProviderNames.Apple;
  const isProviderFacebook = currentProvider === ProviderNames.Facebook;
  const isProviderGoogle = currentProvider === ProviderNames.Google;
  const isProviderPassword = currentProvider === ProviderNames.Password;

  /**
   * Get the the provider the user signed in with.
   * Have to get it from the token because we could have multiple providers
   */
  useEffect(() => {
    (async (): Promise<void> => {
      const token = await currentUser?.getIdToken();
      if (!token) {
        return;
      }
      const jsonToken = JSON.parse(atob(token.split('.')[1]));
      setCurrentProvider(jsonToken.firebase.sign_in_provider);
      if (currentUser?.email) {
        setValue('email', currentUser.email);
      }
    })();
  }, [currentUser]);

  const loginWithGoogle = (): Promise<void> => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Google, false);
  };
  const loginWithFacebook = (): Promise<void> => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Facebook, false);
  };
  const loginWithApple = (): Promise<void> => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Apple, false);
  };
  const loginWithEmail = handleSubmit((data) => {
    setAuthRedirectURLGlobal(location.pathname + location.search);
    auth.loginWithEmail({ ...data, shouldRedirect: false }).then();
  });

  const handleClose = (): void => {
    setIsReAuthDialogOpenGlobal(false);
  };

  return (
    <Dialog
      fullWidth
      PaperProps={{ classes: { root: dialogClasses.paper } }}
      aria-labelledby="login-dialog"
      fullScreen={isSmallScreenDown}
      onClose={handleClose}
      open={isReAuthDialogOpenGlobal}
    >
      <IconButton aria-label="close" className={dialogClasses.closeButton} onClick={handleClose}>
        <CloseIcon className={dialogClasses.svg} />
      </IconButton>

      <Box marginBottom="auto" marginTop="auto">
        <Box margin="auto" px={3}>
          <Box mb={4}>
            <Typography align="center" variant="h1">
              Re-Authenticate
            </Typography>
          </Box>
          <DisableToggle showLoadingWhileDisabled disabled={auth.isLoading}>
            <div>
              <Box my={2}>
                <Typography align="center">
                  This is a security sensitive operation. To keep your account safe, we require a
                  recent login.
                </Typography>
              </Box>
              {isProviderApple && (
                <Box mb={3}>
                  <ExtendedButton
                    fullWidth
                    className={classNames(authButtonClasses.root, authButtonClasses.appleRoot)}
                    classes={{
                      startIcon: authButtonClasses.startIcon,
                    }}
                    onClick={loginWithApple}
                    size="large"
                    startIcon={<Apple />}
                    variant="contained"
                  >
                    Continue with Apple
                  </ExtendedButton>
                </Box>
              )}

              {isProviderFacebook && (
                <Box mb={3}>
                  <ExtendedButton
                    fullWidth
                    className={classNames(authButtonClasses.root, authButtonClasses.facebookRoot)}
                    classes={{
                      startIcon: authButtonClasses.startIcon,
                    }}
                    onClick={loginWithFacebook}
                    size="large"
                    startIcon={<Facebook />}
                    variant="contained"
                  >
                    Continue with Facebook
                  </ExtendedButton>
                </Box>
              )}

              {isProviderGoogle && (
                <Box mb={3}>
                  <ExtendedButton
                    fullWidth
                    className={classNames(authButtonClasses.root, authButtonClasses.googleRoot)}
                    classes={{
                      startIcon: authButtonClasses.startIcon,
                    }}
                    onClick={loginWithGoogle}
                    size="large"
                    variant="contained"
                  >
                    <span className={authButtonClasses.googleIcon}>
                      <img
                        alt="G"
                        height="100%"
                        src="https://res.cloudinary.com/goreggy/image/upload/v1575154125/site/icons/google-g_htsv6t.svg"
                        width="100%"
                      />
                    </span>
                    <div />
                    Continue with Google
                  </ExtendedButton>
                </Box>
              )}

              {isProviderPassword && (
                <form>
                  <Input
                    style={{ display: 'none' }}
                    type="hidden"
                    {...register('email')}
                    value={currentUser?.email}
                  />
                  <Box mt={3}>
                    <PasswordField
                      fullWidth
                      autoComplete="current-password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      {...register('password')}
                      label="Password"
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={3}>
                    <ExtendedButton
                      fullWidth
                      color="primary"
                      onClick={loginWithEmail}
                      size="large"
                      variant="contained"
                    >
                      Re-Authenticate
                    </ExtendedButton>
                  </Box>
                </form>
              )}
            </div>
          </DisableToggle>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ReAuthDialog;
