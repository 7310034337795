/**
 * Material-UI Button with the ability to set a loading spinner
 */
import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import { useExtendedTextFieldStyles } from '../TextField/ExtendedTextField';

import { useIsSmallScreenDown } from '@/lib/ui-utils';

export interface ExtendedButtonProps extends ButtonProps {
  errorColor?: boolean;
  editColor?: boolean;
  isLoading?: boolean;
  minWidth?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg';
  responsive?: boolean;
}
const ExtendedButton: React.FC<ExtendedButtonProps> = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      editColor,
      errorColor,
      isLoading,
      minWidth,
      responsive,
      size,
      ...rest
    },
    ref,
  ) => {
    const textFieldClasses = useExtendedTextFieldStyles();
    const isSmallScreenDown = useIsSmallScreenDown();

    // Spinner size based on button size to keep button from resizing on loading
    const loadingSize = size === 'large' ? 29 : size === 'small' ? 22 : 24;

    let buttonSize = size;
    if (buttonSize === 'large' && responsive && isSmallScreenDown) {
      buttonSize = 'medium';
    }
    return (
      <Button
        {...rest}
        className={classNames(className, {
          errorColor,
          editColor,
          [textFieldClasses.tinyMinWidth]: minWidth === 'tiny',
          [textFieldClasses.xSmallMinWidth]: minWidth === 'xs',
          [textFieldClasses.smallMinWidth]: minWidth === 'sm',
          [textFieldClasses.regularMinWidth]: minWidth === 'md',
          [textFieldClasses.largeMinWidth]: minWidth === 'lg',
        })}
        disabled={isLoading || disabled}
        endIcon={isLoading ? undefined : rest.endIcon}
        innerRef={ref}
        size={buttonSize}
        startIcon={isLoading ? undefined : rest.startIcon}
      >
        {isLoading && <CircularProgress size={loadingSize} />}
        {!isLoading && children}
      </Button>
    );
  },
);

export default ExtendedButton;
