/**
 * Another entrypoint for the join waitlist page.
 */
import React from 'react';

import CreatorCheckContainer from '@/containers/User/CreatorCheckContainer';
import MainLayout from '@/layouts/MainLayout';
import Page from '@/pages/Page';

const JoinWaitlistPage: React.FC = () => {
  return (
    <Page noIndex title="Join Waitlist • Reggy">
      <MainLayout>
        <CreatorCheckContainer />
      </MainLayout>
    </Page>
  );
};

export default JoinWaitlistPage;
