/**
 * Activity Filter for Search Activity
 */
import React from 'react';
import { Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import LinkButton from '@/ui/Button/LinkButton';

import { SearchTypeEnum } from '@/const';
import {
  useGetSearchFiltersGlobal,
  useSetSearchFiltersGlobal,
} from '@/containers/Search/recoilStore';
import { useSetQueryParams } from '@/lib/path-helpers';

const EventsActivityFilter: React.FC = () => {
  const setQueryParams = useSetQueryParams();
  const searchFiltersGlobal = useGetSearchFiltersGlobal();
  const setSearchFiltersGlobal = useSetSearchFiltersGlobal();

  const activitiesValue = searchFiltersGlobal.activities;
  const isFiltering = RA.isNotEmpty(activitiesValue);

  /**
   * Handle activities change
   */
  const handleActivityChange = (activity: SearchTypeEnum) => (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setSearchFiltersGlobal((prevValue) => {
      let newValue;
      if (!checked) {
        newValue = R.without([activity], prevValue.activities);
      } else {
        newValue = [activity, ...prevValue.activities];
      }
      setQueryParams({ activities: newValue });
      return {
        ...prevValue,
        activities: newValue as SearchTypeEnum[],
      };
    });
  };

  /**
   * Clear activities filters
   */
  const handleClearFilters = (): void => {
    setSearchFiltersGlobal((prevValue) => ({
      ...prevValue,
      activities: [],
    }));
    setQueryParams({ activities: undefined });
  };

  /**
   * Show virtual events
   */
  const handleShowVirtualEvents = (e: React.ChangeEvent<unknown>, checked: boolean): void => {
    setSearchFiltersGlobal((prevValue) => {
      setQueryParams({
        showVirtualEvents: checked,
      });
      return {
        ...prevValue,
        showVirtualEvents: checked,
      };
    });
  };

  return (
    <>
      <Box py={2}>
        <Box alignItems="center" display="flex" height={22}>
          <Typography variant="body2">
            <b>Activity</b>
          </Typography>
          {isFiltering && (
            <Box display="flex" ml={1}>
              <LinkButton onClick={handleClearFilters}>Clear</LinkButton>
            </Box>
          )}
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.Clinic)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.Clinic)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.Clinic)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.Coach)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.Coach)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.Coach)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.PrivateLesson)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.PrivateLesson)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.PrivateLesson)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.OnlineCourse)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.OnlineCourse)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.OnlineCourse)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.Ride)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.Ride)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.Ride)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activitiesValue.includes(SearchTypeEnum.Race)}
              color="primary"
              onChange={handleActivityChange(SearchTypeEnum.Race)}
              size="small"
            />
          }
          label={pluralize(SearchTypeEnum.Race)}
        />
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={searchFiltersGlobal.showVirtualEvents}
                color="primary"
                onChange={handleShowVirtualEvents}
              />
            }
            label="Include virtual events"
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default EventsActivityFilter;
