/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EditProfileDialogOrganizerStripeAccountQueryVariables = {
    organizerCuid: string;
};
export type EditProfileDialogOrganizerStripeAccountQueryResponse = {
    readonly organizer_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly stripeConnectAccount: {
                    readonly accountId: string;
                } | null;
            };
        }>;
    };
};
export type EditProfileDialogOrganizerStripeAccountQuery = {
    readonly response: EditProfileDialogOrganizerStripeAccountQueryResponse;
    readonly variables: EditProfileDialogOrganizerStripeAccountQueryVariables;
};



/*
query EditProfileDialogOrganizerStripeAccountQuery(
  $organizerCuid: String!
) {
  organizer_connection(where: {cuid: {_eq: $organizerCuid}}) {
    edges {
      node {
        stripeConnectAccount {
          accountId
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "organizerCuid"
        } as any
    ], v1 = [
        {
            "fields": [
                {
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "organizerCuid"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "cuid"
                }
            ],
            "kind": "ObjectValue",
            "name": "where"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountId",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditProfileDialogOrganizerStripeAccountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "organizerConnection",
                    "kind": "LinkedField",
                    "name": "organizer_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "organizerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "organizer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "stripeConnectAccount",
                                            "kind": "LinkedField",
                                            "name": "stripeConnectAccount",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "query_root",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditProfileDialogOrganizerStripeAccountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "organizerConnection",
                    "kind": "LinkedField",
                    "name": "organizer_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "organizerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "organizer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "stripeConnectAccount",
                                            "kind": "LinkedField",
                                            "name": "stripeConnectAccount",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "489f78d50c403753032cd4453d86291e",
            "id": null,
            "metadata": {},
            "name": "EditProfileDialogOrganizerStripeAccountQuery",
            "operationKind": "query",
            "text": "query EditProfileDialogOrganizerStripeAccountQuery(\n  $organizerCuid: String!\n) {\n  organizer_connection(where: {cuid: {_eq: $organizerCuid}}) {\n    edges {\n      node {\n        stripeConnectAccount {\n          accountId\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3deaf6d77b7378a5d30c134cfbf943b7';
export default node;
