/**
 * Login controls
 */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import Apple from '@material-ui/icons/Apple';
import Facebook from '@material-ui/icons/Facebook';
import classNames from 'classnames';

import BrandTypography from '@/ui/BrandTypography';
import ButtonLink from '@/ui/Button/ButtonLink';
import ExtendedButton from '@/ui/Button/ExtendedButton';
import LinkButton from '@/ui/Button/LinkButton';
import FormOrDivider from '@/ui/FormOrDivider';
import { useLightGreyHelperText } from '@/ui/styles/typographyStyles';
import ExtendedTextField from '@/ui/TextField/ExtendedTextField';
import PasswordField from '@/ui/TextField/PasswordField';
import DisableToggle from '@/hoc/DisableToggle';

import {
  useAuthButtonStyles,
  useAuthErrorContainerStyles,
  useAuthPageHeader,
  useEmailControls,
  useShowEmailButton,
} from '../styles';

import {
  TeaserMessage,
  TeaserMessageProps,
  useGetIsLoginSignupDialogBlockingGlobal,
  useGetLoginSignupTeaserMessageGlobal,
  useGetLoginSignupTeaserMessagePropsGlobal,
  useSetAuthRedirectURLGlobal,
  useSetIsLoginDialogOpenGlobal,
  useSetIsSignupDialogOpenGlobal,
  useSetLoginSignupDialogBlockingModeGlobal,
} from '@/containers/Auth/recoilStore';
import { Firebase } from '@/lib/firebase';
import { LoginSchema } from '@/lib/form-schema/auth';
import { EmailPasswordProps, useAuth } from '@/providers/AuthProvider';
import { AppRouteService } from '@/routes/RouteService';

interface LoginFormContainerProps {
  isDialog?: boolean;
}

const EmptyTeaserMessage: React.FC = () => {
  return null;
};
const DefaultTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        The conditions are perfect! Log in to <BrandTypography inline>Reggy</BrandTypography> and
        hit the trails.
      </Typography>
    </Box>
  );
};
interface RegistrationTeaserMessageProps {
  eventName: string;
}
const RegistrationTeaserMessage: React.FC<RegistrationTeaserMessageProps> = ({ eventName }) => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Log in to <BrandTypography inline>Reggy</BrandTypography> to continue registration for{' '}
        <b>{eventName}</b>.
      </Typography>
    </Box>
  );
};
interface OnlineCourseTeaserMessageProps {
  onlineCourseTitle: string;
}
const OnlineCourseTeaserMessage: React.FC<OnlineCourseTeaserMessageProps> = ({
  onlineCourseTitle,
}) => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Log in to <BrandTypography inline>Reggy</BrandTypography> to start learning with{' '}
        <b>{onlineCourseTitle}</b>.
      </Typography>
    </Box>
  );
};
const WaitlistTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Thanks for being interested in{' '}
        <BrandTypography inline noLink variant="body2">
          Reggy
        </BrandTypography>
        ! Let's get you logged in so we know who to make a creator once you're off the waitlist!
      </Typography>
    </Box>
  );
};
const WaitlistCodeTeaserMessage: React.FC = () => {
  return (
    <Box pl={2} pr={2}>
      <Typography align="center" variant="subtitle2">
        Thanks for being interested in{' '}
        <BrandTypography inline noLink variant="body2">
          Reggy
        </BrandTypography>
        ! Let's get you logged in so we can activate your account with your code.
      </Typography>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TeaserMessageToComponent: Record<TeaserMessage, React.FC<any>> = {
  empty: EmptyTeaserMessage,
  default: DefaultTeaserMessage,
  registration: RegistrationTeaserMessage,
  onlineCourse: OnlineCourseTeaserMessage,
  waitlist: WaitlistTeaserMessage,
  waitlistCode: WaitlistCodeTeaserMessage,
};

const getTeaserMessage = (tm: TeaserMessage, p: TeaserMessageProps = null): React.ReactElement => {
  const Component = TeaserMessageToComponent[tm];
  const props = p ?? {};
  return <Component {...props} />;
};

const LoginFormContainer: React.FC<LoginFormContainerProps> = ({ isDialog }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<EmailPasswordProps>({
    resolver: yupResolver(LoginSchema),
    reValidateMode: 'onSubmit',
  });
  const helperTextClasses = useLightGreyHelperText();
  const authButtonClasses = useAuthButtonStyles();
  const showEmailButtonClasses = useShowEmailButton();
  const emailControlsClasses = useEmailControls();
  const authPageHeaderClasses = useAuthPageHeader();
  const authErrorClasses = useAuthErrorContainerStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const [showEmailControls, setShowEmailControls] = useState(false);

  const setAuthRedirectURLGlobal = useSetAuthRedirectURLGlobal();
  const setIsLoginDialogOpenGlobal = useSetIsLoginDialogOpenGlobal();
  const setIsSignupDialogOpenGlobal = useSetIsSignupDialogOpenGlobal();
  const isLoginSignupDialogBlockingGlobal = useGetIsLoginSignupDialogBlockingGlobal();
  const setLoginSignupDialogBlockingModeGlobal = useSetLoginSignupDialogBlockingModeGlobal();

  const teaserMessage = useGetLoginSignupTeaserMessageGlobal();
  const teaserMessageProps = useGetLoginSignupTeaserMessagePropsGlobal();

  // Remove error and redirectUrl on unmount
  useEffect(() => {
    return (): void => {
      auth.setFirebaseError(null);
      setAuthRedirectURLGlobal(null);
    };
  }, []);

  const loginWithGoogle = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Google, !isDialog);
  };
  const loginWithFacebook = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Facebook, !isDialog);
  };
  const loginWithApple = (): Promise<void> => {
    auth.setFirebaseError(null);
    return auth.loginSignupWithProvider(Firebase.ProviderNames.Apple, !isDialog);
  };
  const loginWithEmail = handleSubmit((data) => {
    auth.setFirebaseError(null);
    auth.loginWithEmail({ ...data, shouldRedirect: !isDialog }).then();
  });

  const goToSignup = (): void => {
    if (isDialog) {
      if (isLoginSignupDialogBlockingGlobal) {
        setLoginSignupDialogBlockingModeGlobal('signup');
        return;
      }
      setIsLoginDialogOpenGlobal(false);
      setIsSignupDialogOpenGlobal(true);
      return;
    }
    navigate(AppRouteService.getRelativeUrl('Signup'));
  };

  const closeDialog = (): void => {
    if (isDialog) {
      setIsLoginDialogOpenGlobal(false);
    }
  };

  const goToRoute = (route: string): (() => void) => {
    return (): void => {
      closeDialog();
      navigate(route);
    };
  };

  return (
    <Box marginBottom="auto" marginTop="auto">
      <Box margin="auto" px={3}>
        {!isDialog && !showEmailControls && !auth.firebaseError && (
          <Link to={AppRouteService.getRelativeUrl('Home')}>
            <h2 className={authPageHeaderClasses.root}>Reggy</h2>
          </Link>
        )}
        <Box mb={4}>
          <Typography align="center" variant="h1">
            Log in
          </Typography>
          {getTeaserMessage(teaserMessage, teaserMessageProps)}
        </Box>
        <DisableToggle showLoadingWhileDisabled disabled={auth.isLoading}>
          <div>
            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.appleRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={loginWithApple}
                size="large"
                startIcon={<Apple />}
                variant="contained"
              >
                Continue with Apple
              </ExtendedButton>
            </Box>

            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.facebookRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={loginWithFacebook}
                size="large"
                startIcon={<Facebook />}
                variant="contained"
              >
                Continue with Facebook
              </ExtendedButton>
            </Box>

            <Box mb={3}>
              <ExtendedButton
                fullWidth
                className={classNames(authButtonClasses.root, authButtonClasses.googleRoot)}
                classes={{
                  startIcon: authButtonClasses.startIcon,
                }}
                onClick={loginWithGoogle}
                size="large"
                variant="contained"
              >
                <span className={authButtonClasses.googleIcon}>
                  <img
                    alt="G"
                    height="100%"
                    src="https://res.cloudinary.com/goreggy/image/upload/v1575154125/site/icons/google-g_htsv6t.svg"
                    width="100%"
                  />
                </span>
                <div />
                Continue with Google
              </ExtendedButton>
            </Box>

            <FormOrDivider />

            {auth.firebaseError && (
              <div className={authErrorClasses.root}>
                <div>{auth.firebaseError?.errorMessage}</div>
                {auth.firebaseError?.errorCode.toLocaleLowerCase() === 'auth/user-not-found' ? (
                  <Box mt={1}>
                    <ExtendedButton
                      autoFocus
                      color="primary"
                      onClick={goToSignup}
                      variant="contained"
                    >
                      Go to Sign Up
                    </ExtendedButton>
                  </Box>
                ) : null}
              </div>
            )}

            <Box
              className={classNames(showEmailButtonClasses.root, {
                [showEmailButtonClasses.hidden]: showEmailControls,
              })}
            >
              <ExtendedButton
                fullWidth
                color="primary"
                onClick={(): void => setShowEmailControls(true)}
                size="large"
                variant="outlined"
              >
                Log in with Email
              </ExtendedButton>
            </Box>
            <form
              className={classNames(emailControlsClasses.root, {
                open: showEmailControls,
              })}
            >
              <div>
                <ExtendedTextField
                  fullWidth
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  // onChange={() => {
                  //   auth.setFirebaseError(null);
                  // }}
                  {...register('email')}
                  label="Email Address"
                  variant="outlined"
                />
              </div>
              <Box mt={3}>
                <PasswordField
                  fullWidth
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  onKeyUp={(e): void => {
                    if (e.key === 'Enter') {
                      loginWithEmail().then();
                    }
                  }}
                  {...register('password')}
                  // onChange={() => {
                  //   auth.setFirebaseError(null);
                  // }}
                  {...register('password')}
                  label="Password"
                  variant="outlined"
                />
              </Box>
              <Box mt={3}>
                <ExtendedButton
                  fullWidth
                  color="primary"
                  onClick={loginWithEmail}
                  size="large"
                  variant="contained"
                >
                  Log in
                </ExtendedButton>
              </Box>
              <Typography
                align="center"
                className={classNames(
                  helperTextClasses.root,
                  helperTextClasses.negativeBottomMargin,
                )}
                variant="body2"
              >
                <ButtonLink
                  onClick={closeDialog}
                  size="small"
                  to={AppRouteService.getRelativeUrl('ForgotPassword')}
                >
                  Forgot Password?
                </ButtonLink>
              </Typography>
            </form>

            <Box alignItems="center" display="flex" justifyContent="center" mt={2}>
              <Typography align="center" variant="body2">
                Don’t have an account?
              </Typography>
              <Box ml={0.5}>
                <LinkButton onClick={goToSignup}>Sign Up</LinkButton>
              </Box>
            </Box>
            {auth.firebaseError?.errorCode === 'Auth/user-not-found' && (
              <Box mt={-2}>
                <Typography align="center" className={helperTextClasses.root} variant="body2">
                  By creating an account, you agree to our{' '}
                  <LinkButton onClick={goToRoute(AppRouteService.getRelativeUrl('PrivacyPolicy'))}>
                    Privacy Policy
                  </LinkButton>{' '}
                  and{' '}
                  <LinkButton onClick={goToRoute(AppRouteService.getRelativeUrl('TermsOfService'))}>
                    Terms of Service
                  </LinkButton>
                </Typography>
              </Box>
            )}
          </div>
          <Box display="flex" justifyContent="center" mt={2}>
            <ButtonLink
              onClick={closeDialog}
              size="small"
              to={AppRouteService.getRelativeUrl('Home')}
            >
              Home
            </ButtonLink>
          </Box>
        </DisableToggle>
      </Box>
    </Box>
  );
};

export default LoginFormContainer;
