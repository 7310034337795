/*
 Utilities to generate and manipulate Cloudinary URLs
 */
import { useEffect, useRef } from 'react';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import useForceUpdate from 'use-force-update';

import ENV from '../env';

import {
  generateImageUploadSignatureCF,
  generateOnlineCourseImageUploadSignatureCF,
  generateUserImageUploadSignatureCF,
} from '@/lib/firebase';
import {
  CloudinaryUploadSource,
  CreateUploadWidgetOptions,
  Widget,
  WidgetError,
  WidgetResult,
  WidgetSuccessResult,
} from '@/lib/type-defs/cloudinary';
import { Colors } from '@/themes/colors';
import { theme } from '@/themes/theme';

enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

type Transforms = {
  [key: string]: string | number;
};

/*
  We can use iPhone sizes to match our image widths.
  small: 5
  med: 6 - 11
  large: 6+ - 11
  https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions
*/
export const IPHONE_SIZE: { [key: string]: number } = {
  small: 320,
  med: 375,
  large: 414,
  default: 600,
};

/**
 * Given a width. What size should the image be
 */
export const getSmallestWidth = (width: number): number => {
  if (width <= IPHONE_SIZE.small) {
    return IPHONE_SIZE.small;
  }
  if (width <= IPHONE_SIZE.med) {
    return IPHONE_SIZE.med;
  }
  if (width <= IPHONE_SIZE.large) {
    return IPHONE_SIZE.large;
  }
  return IPHONE_SIZE.default;
};

export const getLightboxImageWidth = (breakpointKey: Breakpoint): number => {
  let width;
  switch (breakpointKey) {
    case Breakpoints.xs:
      width = getSmallestWidth(window.innerWidth);
      break;
    case Breakpoints.sm:
      width = theme.breakpoints.values.sm;
      break;
    case Breakpoints.md:
      width = theme.breakpoints.values.md;
      break;
    case Breakpoints.lg:
      width = theme.breakpoints.values.lg;
      break;
    case Breakpoints.xl:
      width = theme.breakpoints.values.xl;
      break;
    default:
      width = theme.breakpoints.values.xl;
  }
  return width;
};

/**
 * Is the url a relative url
 */
export const isRelativeCloudinaryUrl = (url: string): boolean => {
  return url.startsWith('/');
};

/**
 * Transforms a Cloudinary upload URL to a relativeUrl
 */
export const cloudinaryUrlToRelativeUrl = (url: string | undefined): string => {
  if (!url) {
    return '';
  }
  try {
    if (!url.match(ENV.CLOUDINARY_URL)) {
      throw new Error(`Please supply an image the domain - ${ENV.CLOUDINARY_URL}`);
    }
    const urlParts = url.split('upload/');
    if (urlParts.length >= 2) {
      return `/${url.split('upload/')[1]}`;
    }

    // https://res.cloudinary.com/goreggy//q_auto,f_auto/user_images/yr0pkxfodarjpcx7bigz.jpg
    if (url.includes('user_images/')) {
      //  Coach profile image
      return `/user_images/${url.split('user_images/')[1]}`;
    }
    if (url.includes('event_images/')) {
      //  Event image
      return `/event_images/${url.split('event_images/')[1]}`;
    }
    if (url.includes('organizer_images/')) {
      //  Organizer image
      return `/organizer_images/${url.split('organizer_images/')[1]}`;
    }
    if (url.includes('coach_images/')) {
      //  Coach image
      return `/coach_images/${url.split('coach_images/')[1]}`;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return '';
  }
  return '';
};

/**
 * Add transforms to given image url
 * v1595298560/site/icons/fatmap/drmogdm1pajxpi6vygwf.png
 */
export const applyCloudinaryTransforms = (
  relativeUrl: string | undefined,
  transforms: Transforms,
): string => {
  if (R.isEmpty(relativeUrl) || !relativeUrl) {
    return '';
  }
  if (relativeUrl.match(ENV.CLOUDINARY_URL)) {
    throw new Error(`Please supply an image without the domain - ${ENV.CLOUDINARY_URL}`);
  }
  if (!relativeUrl.startsWith('/')) {
    throw new Error('Please supply a relative url');
  }
  const transformString = toTransformString(transforms);
  return `${ENV.CLOUDINARY_URL}${
    R.isEmpty(transformString) ? '' : '/'
  }${transformString}${relativeUrl}`;
};
const toTransformString = (transforms: Transforms): string => {
  return R.toPairs(transforms)
    .map(([key, value]) => `${key}_${value}`)
    .join(',');
};

/** *****************************
 Transforms
 ******************************* */

const carouselImage = {
  c: 'fill',
  dpr: '2.0',
  f: 'auto',
  h: '100',
  q: 'auto:low',
  w: '100',
};

const mediaCardImage = {
  dpr: '2.0',
  f: 'auto',
  q: 'auto:good',
  w: '600',
};

const lightboxImage = (breakpointKey: Breakpoint): Transforms => ({
  q: 'auto',
  f: 'auto',
  w: getLightboxImageWidth(breakpointKey),
  dpr: 2,
  c: 'limit',
});

const sortableImage = {
  c: 'fill',
  dpr: '2.0',
  f: 'auto',
  q: 'auto',
  w: '200',
};

const profileImage = {
  dpr: '2.0',
  f: 'auto',
  q: 'auto:good',
  w: '300',
};

// const stravaCourseImage = {
//   c: 'fill',
//   // dpr: '2.0',
//   f: 'auto',
//   q: 'auto',
//   w: '700',
// };

// Low Quality Image Placeholder
const lqipImage = {
  c: 'fill',
  // dpr: '2.0',
  f: 'auto',
  q: 'auto:low',
  w: '67',
  h: '50',
};

export const applyDefaultCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, { q: 'auto', f: 'auto' });

export const applyCarouselCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, carouselImage);

export const applyMediaCardCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, mediaCardImage);

export const applyLightboxCloudinaryTransform = (
  relativeUrl: string | undefined,
  currentBreakpoint: Breakpoint,
): string => applyCloudinaryTransforms(relativeUrl, lightboxImage(currentBreakpoint));

export const applySortableCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, sortableImage);

export const applyProfileCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, profileImage);

export const applyInlinePreviewImageCloudinaryTransform = (
  relativeUrl: string | undefined,
): string => applyCloudinaryTransforms(relativeUrl, { q: 'auto', f: 'auto', w: '100' });

export const applyLqipCloudinaryTransform = (relativeUrl: string | undefined): string =>
  applyCloudinaryTransforms(relativeUrl, lqipImage);

/**
 * Transform a relative url, absolute url, or non cloudinary url to a usable image src
 */
export const getTransformedImageUrl = (
  imageUrl: string | undefined | null,
  transformFunction: (relativeUrl: string | undefined) => string,
  defaultImage?: string,
): string => {
  if (!imageUrl && !defaultImage) {
    return '';
  }
  if (!imageUrl) {
    return transformFunction(cloudinaryUrlToRelativeUrl(defaultImage));
  }
  if (imageUrl.match(ENV.CLOUDINARY_URL)) {
    return transformFunction(cloudinaryUrlToRelativeUrl(imageUrl));
  }
  if (isRelativeCloudinaryUrl(imageUrl)) {
    return transformFunction(imageUrl);
  }
  // Non cloudinary url and non relative url
  return imageUrl;
};

/** *******************************************
 * Upload Widget
 ********************************************* */
type getUploadWidgetProps = Pick<
  CreateUploadWidgetOptions,
  'uploadPreset' | 'maxFiles' | 'uploadSignature'
>;
export type widgetSuccessCallback = ({
  data,
  err,
}: {
  data: WidgetSuccessResult;
  err: WidgetError;
}) => void;
export type widgetErrorCallback = (err: WidgetError) => void;

const WIDGET_DEFAULT_OPTIONS = {
  cloudName: 'goreggy',
  sources: [
    'local',
    'url',
    'camera',
    'dropbox',
    'google_drive',
    'facebook',
    'instagram',
    'image_search',
  ] as CloudinaryUploadSource[],
  cropping: true,
  croppingCoordinatesMode: 'custom',
  croppingDefaultSelectionRatio: 1,
  croppingShowDimensions: true,
  maxFileSize: 9500000,
  clientAllowedFormats: ['png', 'jpg', 'jpeg'],
  apiKey: ENV.CLOUDINARY_PUBLIC_KEY,
  showAdvancedOptions: false,
  resourceType: 'image',
  defaultSource: 'local',
  text: {
    en: {
      crop: {
        title: 'Crop your image (Optional)',
        skip_btn: 'Upload',
        crop_btn: 'Crop & Upload',
      },
    },
  },
  styles: {
    palette: {
      window: '#F5F5F5',
      sourceBg: '#FFFFFF',
      windowBorder: '#90a0b3',
      tabIcon: '#0094c7',
      inactiveTabIcon: '#69778A',
      menuIcons: '#0094C7',
      link: Colors.DarkBlue,
      action: Colors.Turquoise,
      inProgress: '#0194c7',
      complete: Colors.Success,
      error: Colors.DangerBackground,
      textDark: '#000000',
      textLight: '#FFFFFF',
    },
    fonts: {
      default: null,
      "'Poppins', sans-serif": {
        url: 'https://fonts.googleapis.com/css?family=Poppins',
        active: true,
      },
    },
  },
} as Omit<CreateUploadWidgetOptions, 'uploadPreset' | 'maxFiles' | 'uploadSignature'>;

/**
 * Upload file to cloudinary
 */
// export const uploadFile = async (file: File, options: getUploadWidgetProps): Promise<void> => {
//   const signature = await generateUserImageUploadSignatureCF({
//     paramsToSign: {},
//   });
//   // https://glitch.com/edit/#!/shore-ubiquitous-street?path=signed-uploads%2Fpublic%2Fjs%2Fuploadclientform.js%3A1%3A0
//   // https://cloudinary.com/documentation/image_upload_api_reference#upload_examples
//   const url = 'https://api.cloudinary.com/v2/goreggy/upload';
//   const fileData = {
//     api_key: ENV.CLOUDINARY_PUBLIC_KEY,
//     timestamp: signature.timestamp,
//     signature: signature.signature,
//     upload_preset: options.uploadPreset,
//     file,
//   };
//   fetch({ body: fileData, method: 'POST', url }).then((data) => {
//     console.log(JSON.parse(data));
//     const str = JSON.stringify(JSON.parse(data), null, 4);
//     document.getElementById('formdata').innerHTML += str;
//   });
// };

/**
 * Create Cloudinary Upload Widget.
 * We have to make a new one every time our list of images changes because successCallback
 * changes
 */
interface UseCreateCloudinaryUploadWidgetProps {
  maxFiles: number;
  uploadPreset: string;
  onSuccess: (data: FormattedCloudinaryImageDataProps) => void;
  // If we are uploading an organizer owned image
  organizerCuid?: string;
  // If we are uploading a user owned image
  userId?: string;
  // If we are uploading a onlineCourse owned image
  onlineCourseCuid?: string;
}
export const useCreateCloudinaryUploadWidget = ({
  maxFiles,
  onlineCourseCuid,
  onSuccess,
  organizerCuid,
  uploadPreset,
  userId,
}: UseCreateCloudinaryUploadWidgetProps): Widget | null => {
  const forceUpdate = useForceUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const widgetRef = useRef<Widget | null>(null);

  // Gets called on any error we get from Cloudinary
  const errorCallback = (err: WidgetError): void => {
    enqueueSnackbar(err, { variant: 'error', persist: true });
    removeCloudinaryWidgetFromDOM();
  };
  // Gets called on successful image upload
  const successCallback = async ({
    data,
    err,
  }: {
    data: WidgetSuccessResult;
    err: WidgetError;
  }): Promise<void> => {
    // Cloudinary sends an error to the success callback for upload errors
    if (err) {
      errorCallback(err);
      return;
    }
    onSuccess(formatCloudinaryImageData(data, organizerCuid, userId, onlineCourseCuid));
  };

  // Sign parameters to prevent un-authorized uploads
  const generateSignature = async (
    callback: (data: unknown) => unknown,
    paramsToSign: Record<string, unknown>,
  ): Promise<void> => {
    try {
      let signature;
      if (organizerCuid && !onlineCourseCuid) {
        signature = await generateImageUploadSignatureCF({
          organizerCuid,
          paramsToSign,
        });
      } else if (userId && !onlineCourseCuid) {
        signature = await generateUserImageUploadSignatureCF({
          paramsToSign,
        });
      } else if (onlineCourseCuid) {
        signature = await generateOnlineCourseImageUploadSignatureCF({
          onlineCourseCuid,
          organizerCuid,
          paramsToSign,
        });
      } else {
        throw new Error('No onlineCourseCuid, userId or organizerCuid');
      }
      callback(signature.data);
    } catch (err) {
      // Generate signature is only called on upload,
      // so by definition widget has to be defined
      // uploadWidget!.close({ quiet: true });
      errorCallback(err.message);
    }
  };

  useEffect(() => {
    if (!widgetRef.current && window.cloudinary) {
      widgetRef.current = createCloudinaryUploadWidget({
        errorCallback,
        maxFiles,
        uploadPreset,
        successCallback,
        uploadSignature: generateSignature,
      });
      // We have to force an update to let everyone know we now have a widget
      forceUpdate();
    }
    return (): void => {
      removeCloudinaryWidgetFromDOM();
    };
  }, [!!window.cloudinary]);
  return widgetRef.current;
};

// Create a Cloudinary upload widget
export function createCloudinaryUploadWidget({
  errorCallback,
  maxFiles,
  successCallback,
  uploadPreset,
  uploadSignature,
}: getUploadWidgetProps & {
  successCallback: widgetSuccessCallback;
  errorCallback: widgetErrorCallback;
}): Widget | null {
  const widgetCallback = (err: WidgetError, result: WidgetResult): void => {
    if (result?.event === 'success') {
      successCallback({ data: result.info, err });
    }
    // Cloudinary errors live on the err object. If the Error is a string it is something
    // unexpected or a signature error
    if (err && typeof err === 'string') {
      errorCallback(err);
    }
  };
  if (!window.cloudinary) {
    return null;
  }
  return window.cloudinary.createUploadWidget(
    { ...WIDGET_DEFAULT_OPTIONS, maxFiles, uploadPreset, uploadSignature },
    widgetCallback,
  ) as Widget;
}

// Take upload return data and format it be ready for upload into our Hasura database
export interface FormattedCloudinaryImageDataProps {
  bytes: number | null;
  format: string | null;
  height: number | null;
  originalFilename: string | null;
  publicId: string;
  relativeUrl: string;
  resourceType: string | null;
  url: string;
  version: number | null;
  width: number | null;
  organizerCuid?: string;
  userId?: string;
  onlineCourseCuid?: string;
}
export function formatCloudinaryImageData(
  data: WidgetSuccessResult,
  organizerCuid?: string,
  userId?: string,
  onlineCourseCuid?: string,
): FormattedCloudinaryImageDataProps {
  const imageData = {
    bytes: data.bytes,
    format: data.format,
    height: data.height,
    originalFilename: data.original_filename,
    publicId: data.public_id,
    relativeUrl: `/${data.public_id}.${data.format}`,
    resourceType: data.resource_type,
    url: data.url,
    version: data.version,
    width: data.width,
  };
  if (organizerCuid && !onlineCourseCuid) {
    return { ...imageData, organizerCuid };
  }
  if (userId && !onlineCourseCuid) {
    return { ...imageData, userId };
  }
  return { ...imageData, onlineCourseCuid };
}

// Remove a Cloudinary widget from the DOM
export function removeCloudinaryWidgetFromDOM(): void {
  const currentWidget = document.querySelector('iframe[src^="https://widget.cloudinary.com"]');
  if (currentWidget?.parentNode) {
    currentWidget.parentNode.removeChild(currentWidget);
  }
}
