import * as V2 from './V2';

import { Migration, Versioned } from '../../../Versions';

/**
 * Unchanged
 */
export type ResponseType = V2.ResponseType;
export type YesNoResponse = V2.YesNoResponse;
export type RecommendationResponse = V2.RecommendationResponse;
export type OpenResponse = V2.OpenResponse;
export type Response = V2.Response;

export type QuestionId = V2.QuestionId;

/**
 * Questions
 */
export type SubjectType = 'coach' | 'clinic' | 'race' | 'ride' | 'online course';
export interface Category {
  subjectType: SubjectType;
  category: string;
}
export interface CoachCategory extends Category {
  subjectType: 'coach';
  category: 'communication' | 'expertise' | 'vibe' | 'general';
}
export interface ClinicCategory extends Category {
  subjectType: 'clinic';
  category: 'content' | 'organization' | 'vibe' | 'general';
}
export interface Question<T extends Category> {
  id: QuestionId;
  category: T;
  responseType: ResponseType;
  attribute: string;
  alwaysAsk: boolean;
  label: string;
}

export type UserPreferences = V2.UserPreferences;

/**
 * Archive
 */
export type ArchivedCoachQuestion = V2.CoachQuestion;
export type ArchivedCoachResponses = Record<V2.QuestionId, V2.Response>;
export interface Archive {
  coach: {
    questions: ArchivedCoachQuestion[];
    responses: ArchivedCoachResponses;
  };
}

/**
 * Version
 */

export type V3 = Versioned<
  3,
  {
    clinicQuestions: Question<ClinicCategory>[];
    coachQuestions: Question<CoachCategory>[];
    responses: Record<QuestionId, Response>;
    studentPrefs: UserPreferences;
    archive: Archive;
  }
>;

/**
 * Migration
 *
 * Type Changes:
 * - remove coach leaving reviews for students
 *    - remove 'user' field on questions
 * - questions now categorized by subject type
 * - introduce 'archive' data shape
 *
 * Question Changes:
 * - archive coach questions
 * - convert existing questions into categorized coach questions
 * - convert id syntax from `sq_<name>` to `<category>_<name>`
 *
 * Response Changes:
 * - archive coach responses
 */
export const migrateFromV2ToV3: Migration = (lastVersion) => {
  const version2 = lastVersion as V2.V2;
  const contents: Record<string, unknown> = {};

  const coachArchive: Archive['coach'] = { questions: [], responses: {} };

  // questions
  if (version2.contents.questions) {
    const coachQuestions: Question<CoachCategory>[] = [];
    version2.contents.questions.forEach((q): void => {
      const isCoachQuestion = q.user === 'coach';
      if (isCoachQuestion) {
        coachArchive.questions.push(q);
        return;
      }

      // convert to categorized coach question
      const id = q.id.replace('sq_', 'coach_');
      const question: Question<CoachCategory> = {
        id,
        category: {
          subjectType: 'coach',
          category: q.category,
        },
        attribute: q.attribute,
        responseType: q.responseType,
        alwaysAsk: q.alwaysAsk,
        label: q.label,
      };
      coachQuestions.push(question);
    });
    contents.coachQuestions = coachQuestions;
  }

  // responses
  if (version2.contents.responses) {
    const responseEntries = Object.entries(version2.contents.responses);
    const responses: Record<QuestionId, Response> = {};

    responseEntries.forEach(([qId, response]): void => {
      const isResponseToCoachQuestion = qId.startsWith('cq_');
      if (isResponseToCoachQuestion) {
        coachArchive.responses[qId] = response;
        return;
      }
      responses[qId] = response;
    });
    contents.responses = responses;
  }

  // archive coach questions and responses if found
  if (coachArchive.questions.length !== 0 || Object.entries(coachArchive.responses).length !== 0) {
    contents.archive = { coach: coachArchive };
  }

  contents.studentPrefs = version2.contents.studentPrefs;

  // drop coachPreferences data

  return {
    version: 3,
    contents,
  };
};
