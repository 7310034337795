/**
 * @overview a component that catches and logs errors thrown in its children.
 */

import React, { PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { Extras, Scope } from '@sentry/types';

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  eventCuid?: string;
}
interface ErrorBoundaryProps {
  /**
   * Fallback component to display when uncaught exceptions occur in a component tree:
   */
  errorComponent: React.FC<{
    errorComponentStack: string | undefined;
    errorMessage?: string | undefined;
    [key: string]: unknown;
  }>;
  /**
   * Callback for handling any additional logging or async behavior. It does not impact component rendering:
   * ```
   * function(error, errorInfo) {}
   * ```
   */
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  /**
   * Should we change the title of the page to reflect that there was an error
   * ```
   * string
   * ```
   */
  changeTitle?: boolean;
}
class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });

    // Use Sentry to track the error
    try {
      Sentry.withScope((scope: Scope) => {
        scope.setExtras((errorInfo as unknown) as Extras);
        Sentry.captureException(error);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    // Handle any additional logic we need
    const { onError } = this.props;
    if (typeof onError === 'function') {
      // Wrapped in a promise so we can do this in a non-blocking way
      Promise.resolve().then(() => onError(error, errorInfo));
      // .catch(sentryTrackError);
    }
  }

  render(): ReactNode {
    const { changeTitle = false, children, errorComponent: ErrorComponent } = this.props;
    const { error, errorInfo } = this.state;
    return (
      <>
        {error && changeTitle && (
          <Helmet>
            <title>Error - Reggy</title>
          </Helmet>
        )}
        {error ? (
          <Box m="auto">
            {ErrorComponent ? (
              <ErrorComponent
                errorComponentStack={errorInfo?.componentStack}
                errorMessage={error?.message}
              />
            ) : (
              <div>Error</div>
            )}
          </Box>
        ) : (
          children
        )}
      </>
    );
  }
}

export default ErrorBoundary;
