/**
 * Search page
 */
import React from 'react';

import Page from '../Page';

import SearchView from '@/containers/Search/SearchView';
import MainLayout from '@/layouts/MainLayout';

const SearchPage: React.FC = () => {
  return (
    <Page
      description="Find MTB, road, gravel, and all cycling experiences and events."
      title="Find Cycling Experiences • Reggy"
    >
      <MainLayout>
        <SearchView />
      </MainLayout>
    </Page>
  );
};

export default SearchPage;
