import React, { useEffect } from 'react';
import { InstantSearch, useConnector } from 'react-instantsearch-hooks';
// eslint-disable-next-line import/no-unresolved
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import algoliasearch from 'algoliasearch/lite';
import { Connector } from 'instantsearch.js';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import * as RA from 'ramda-adjunct';
import aa from 'search-insights';

import ENV from '@/env';
import { useSetClearAlgoliaCacheGlobal } from '@/globalRecoilStore/globalRecoilStore';
import { useGetSearchIndexName } from '@/lib/algolia-utils';
import { ReactFCC } from '@/lib/type-defs/utility';

export const algoliaSearchClient = algoliasearch(ENV.ALGOLIA_APP_ID, ENV.ALGOLIA_PUBLIC_KEY);

const searchClient: SearchClient = {
  ...algoliaSearchClient,
  // Algolia searches on first load. This is a hack to prevent that.
  // We need at least an empty string
  search(requests, requestOptions) {
    if (requests.every(({ params }) => RA.isUndefined(params?.page))) {
      return Promise.resolve({
        results: requests.map(() => ({
          exhaustiveNbHits: false,
          hitsPerPage: 0,
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return algoliaSearchClient.search(requests, requestOptions);
  },
};

const connectMiddleware: Connector = (renderFn, unmountFn) => (
  widgetParams,
): {
  init(initOptions): void;
  dispose(): void;
  render(renderOptions): void;
  getWidgetRenderState(renderOptions): void;
} => ({
  init(initOptions): void {
    renderFn(
      {
        ...this.getWidgetRenderState(initOptions),
        instantSearchInstance: initOptions.instantSearchInstance,
      },
      true,
    );
  },

  render(renderOptions): void {
    const renderState = this.getWidgetRenderState(renderOptions);

    renderFn(
      {
        ...renderState,
        instantSearchInstance: renderOptions.instantSearchInstance,
      },
      false,
    );
  },

  getWidgetRenderState(
    renderOptions,
  ): {
    use: (...args) => void;
    widgetParams: any;
    unuse: (...args) => void;
  } {
    return {
      use: (...args): void => renderOptions.instantSearchInstance.use(...args),
      unuse: (...args): void => renderOptions.instantSearchInstance.unuse(...args),
      widgetParams,
    };
  },

  dispose(): void {
    unmountFn();
  },
});

const Insights: ReactFCC = ({ children }) => {
  const { unuse, use } = useConnector(connectMiddleware);
  useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: aa,
      insightsInitParams: {
        useCookie: false,
      },
      onEvent: (event, client) => {
        const { eventType, insightsMethod, payload, widgetType } = event;
        // Send the event to Algolia
        if (insightsMethod && client) {
          client(insightsMethod, payload);
        }
        // Send the event to a third-party tracker
        if (eventType === 'click' || eventType === 'conversion') {
          // thirdPartyTracker.send('Product Clicked', payload);
        }
      },
    });

    use(middleware);

    return (): void => unuse(middleware);
  }, []);

  return <>{children}</>;
};

const AlgoliaSearchProvider: ReactFCC = ({ children }) => {
  const indexName = useGetSearchIndexName();
  const setClearAlgoliaCacheGlobal = useSetClearAlgoliaCacheGlobal();

  useEffect(() => {
    setClearAlgoliaCacheGlobal(() => searchClient.clearCache);
  }, []);

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Insights>{children}</Insights>
    </InstantSearch>
  );
};

export default AlgoliaSearchProvider;
