/**
 * Video with hero text
 */
import React from 'react';
import { Box, Hidden, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Colors } from '@/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  overlayImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'absolute',
    opacity: 0.0,
    transition: 'opacity 0.15s ease-in-out',
    background:
      'url("https://s7d2.scene7.com/is/image/nu/HPUpdate_SpringPreview_5-4-1920x1536-static?scl=1&amp;qlt=80&amp;fmt=pjpg") 50% 50% / cover no-repeat transparent',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  video: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'absolute',
    zIndex: 2,
  },
  videoContainer: {
    overflow: 'hidden',
    minHeight: '300px',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  roundedBorder: {
    borderRadius: theme.spacing(1),
  },
  heroText: {
    width: '53%',
    display: 'flex',
    minWidth: '330px',
    maxWidth: '434px',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '32px auto',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    zIndex: 2,
    '& *': {
      fontSize: '3.3rem',
      color: Colors.OffWhite,
      [theme.breakpoints.down('xs')]: {
        fontSize: '3rem',
      },
    },
    '& h1 > span': {
      position: 'relative',
      '& > span': {
        position: 'relative',
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        height: 8,
        width: '100%',
        bottom: '9px',
        left: 0,
        position: 'absolute',
        backgroundColor: '#46CEB5',
        [theme.breakpoints.down('xs')]: {
          bottom: '7px',
          height: 6,
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      maxWidth: 'none',
      width: 'auto',
    },
  },
}));

interface VideoWithHeroTextProps {
  roundedBorder?: boolean;
  imageUrl?: string;
}
const VideoWithHeroText: React.FC<VideoWithHeroTextProps> = ({ imageUrl, roundedBorder }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.videoContainer, { [classes.roundedBorder]: roundedBorder })}>
      <video
        autoPlay
        disableRemotePlayback
        loop
        muted
        playsInline
        className={classes.video}
        // style='background: url("https://s7d2.scene7.com/is/image/nu/HPUpdate_SpringPreview_5-4-1920x1536-static?scl=1&amp;qlt=80&amp;fmt=pjpg") 50% 50% / cover no-repeat transparent;'
      >
        <source
          src="https://storage.googleapis.com/reggy-public/GrowNoAudio.mp4"
          type="video/mp4"
        />
      </video>
      <div
        className={classes.overlay}
        style={
          imageUrl ? { backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' } : undefined
        }
      />
      <Box className={classes.heroText}>
        <Typography variant="h1">
          The{' '}
          <span>
            <span style={{ whiteSpace: 'nowrap' }}>new standard</span>
          </span>{' '}
          <Hidden smDown>
            <br />
          </Hidden>{' '}
          for cycling experiences
        </Typography>
      </Box>
    </div>
  );
};

export default VideoWithHeroText;
